import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { SelectColumns } from 'src/app/services/csv.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-data-filter-columns',
  templateUrl: './data-filter-columns.component.html',
  styleUrls: ['./data-filter-columns.component.sass']
})
export class DataFilterColumnsComponent implements OnInit {
  amountofdata = 10;
  alldomain = '';
  alldomains: any;
  selecteddomains = [];
  domainTypes = ['All domains', 'Top 150', 'Top 100', 'Top 50', 'Top 25', 'Top 10', 'Custom'];
  domvisibility = 'vis_hidden';
  modaltype = '';
  checkdomains: any;

  closeResult = '';
  showingrecords = '';
  heading = 'Data Filter Custom';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  cities: any;
  selectedcities = [];

  states: any;
  selectedstates = [];

  zips: any;


  vendorVal = '';

  selectedColumns: SelectColumns[] = [];
  selectedCols: SelectColumns[] = [];
  all_columns: any;

  from = '';
  to = '';
  totalrec = 0;
  records = [];
  constructor(private SpinnerService: NgxSpinnerService, private modalService: NgbModal, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }


  ngOnInit(): void {

    this.cities = this.csvUpdateService.citiesStates.map(({ city }) => ({ city }));
    this.cities = [...new Map(this.cities.map(item =>
      [item['city'], item])).values()];

    this.states = this.csvUpdateService.citiesStates.map(({ state }) => ({ state }));
    this.states = [...new Map(this.states.map(item =>
      [item['state'], item])).values()];

    this.csvUpdateService.GetZipcodes()
      .subscribe(
        response => {
          this.zips = response;
        },
        error => {
        });

    this.totalrec = 0;
    this.GetDomains();
  }

  ChangeDomainType(event) {
    var v = event.target.value.toLowerCase();
    if (v == 'custom') {
      this.domvisibility = 'vis_visible';
    }
    else {
      this.domvisibility = 'vis_hidden';
    }
  }
  CheckDomainsModal(content: any) {
    this.SpinnerService.show();
    this.csvUpdateService.CheckDomains()
      .subscribe(
        response => {
          this.checkdomains = response;
          this.SpinnerService.hide();
          this.LoadModal(content, 'check domains');
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  loadColumns(event, content: any) {
    this.selectedColumns = [];
    if (event.target.value != '') {
      const data = {
        vendor: event.target.value,
      };
      this.SpinnerService.show();
      this.csvUpdateService.getvendorcolumns(data)
        .subscribe(
          response => {
            this.all_columns = response.data;
            this.all_columns.forEach((row) => {
              this.selectedColumns.push(
                {
                  column: row.name,
                  selectedValue: false,
                }
              );
            })
            this.SpinnerService.hide();
            //this.LoadModal(content);
          },
          error => {
            this.SpinnerService.hide();
          });
    }
  }
  LoadModal(content: any, type: string) {
    this.modaltype = type;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: "myCustomModalClass",
      size: 'md'
    };
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  HideModal() {
    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  RemoveDomain(val) {
    this.selecteddomains = this.selecteddomains.filter(item => item != val);
  }
  selectDomain(event) {
    if (this.selecteddomains.length == 5) {
      this.toastr.error('You can select multiple domains upto 5 max.', 'Error!');
    }
    else {
      var len = this.selecteddomains.filter(item => item == event.domain).length;
      if (len == 0) {
        this.selecteddomains.push(event.domain);
      }
    }
  }

  RemoveState(val) {
    this.selectedstates = this.selectedstates.filter(item => item != val);
  }
  selectState(event) {
    var len = this.selectedstates.filter(item => item == event.state).length;
    if (len == 0) {
      this.selectedstates.push(event.state);
    }
  }

  RemoveCity(val) {
    this.selectedcities = this.selectedcities.filter(item => item != val);
  }
  selectCity(event) {
    var len = this.selectedcities.filter(item => item == event.city).length;
    if (len == 0) {
      this.selectedcities.push(event.city);
    }
  }

  GetDomains() {
    this.csvUpdateService.getdomains()
      .subscribe(
        response => {
          this.alldomains = response;
        },
        error => {
        });
  }

  GetRecords() {
    if (this.alldomain.toLowerCase() == 'custom' && this.selecteddomains.length == 0) {
      this.toastr.error('Please select atleast 1 domain.', 'Error!');
    }
    else {
      var domain = this.GetStrFromArr(this.selecteddomains);
      const userid = localStorage.getItem("token");
      const data = {
        domain: domain,
        amountofdata: this.amountofdata,
        selecteddomains: this.selecteddomains,
        alldomain: this.alldomain.toLowerCase(),
        userid: userid,
        type: 'filter'
      };
      this.SpinnerService.show();
      this.csvUpdateService.GetRecords(data)
        .subscribe(
          items => {
            this.selectedCols = items.selectedCols;
            this.SpinnerService.hide();
            this.records = items.recordsets[0];
            this.totalrec = items.recordsets[0].length;
            if (this.totalrec > 10) {
              this.showingrecords = "showing first 10 records as sample";
            }
            else {
              this.showingrecords = "showing all " + this.totalrec + " records";
            }
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }

  ExportCsv() {
    if (this.alldomain.toLowerCase() == 'custom' && this.selecteddomains.length == 0) {
      this.toastr.error('Please select atleast 1 domain.', 'Error!');
    }
    else {
      var domain = this.GetStrFromArr(this.selecteddomains);
      const userid = localStorage.getItem("token");
      const data = {
        domain: domain,
        amountofdata: this.amountofdata,
        selecteddomains: this.selecteddomains,
        alldomain: this.alldomain.toLowerCase(),
        userid: userid,
        type: 'export'
      };
      this.SpinnerService.show();
      this.csvUpdateService.GetRecords(data)
        .subscribe(
          records => {
            this.modalService.dismissAll();
            var items = records.recordsets[0];
            this.SpinnerService.hide();
            let csv

            var l = items.length;
            if (l > 0) {
              l = 1;
            }
            // Loop the array of objects
            for (let row = 0; row < 1; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0

              // If this is the first row, generate the headings
              if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {
                  console.log(key);
                  // This is to not add a comma at the last cell
                  // The '\r\n' adds a new line
                  csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                  keysCounter++
                }
                csv = csv.replace("undefined", '');
              }
              keysCounter = 0
            }
            for (let row = 0; row < items.length; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0


              for (let key in items[row]) {
                csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }

              keysCounter = 0
            }
            var link = document.createElement('a');
            link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
            link.target = '_blank';
            link.download = 'export.csv';
            link.click();
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  GetStrFromArr(Arr) {
    if (Arr.length == 0) {
      return '';
    }
    else {
      var arr2 = [];
      for (let i = 0; i < Arr.length; i++) {
        var value = "'[val]'";
        value = value.replace("[val]", Arr[i]);
        arr2.push(value);
      }
      return arr2.toString()
    }
  }

}
