import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../DemoPages/common/global';

@Injectable({
  providedIn: 'root'
})
export class CsvUpdateService {
  baseUrl: string;

  public vendors = [
    {vendor: 'lyson'},
  ];

  public citiesStates = [
    {
      city: "AARONSBURG",
      state: "PA"
    },
    {
      city: "ABBEVILLE",
      state: "AL"
    },
    {
      city: "ABBEVILLE",
      state: "GA"
    },
    {
      city: "ABBEVILLE",
      state: "LA"
    },
    {
      city: "ABBEVILLE",
      state: "MS"
    },
    {
      city: "ABBEVILLE",
      state: "SC"
    },
    {
      city: "ABBOT",
      state: "ME"
    },
    {
      city: "ABBOTSFORD",
      state: "WI"
    },
    {
      city: "ABBOTT",
      state: "TX"
    },
    {
      city: "ABBOTTSTOWN",
      state: "PA"
    },
    {
      city: "ABBYVILLE",
      state: "KS"
    },
    {
      city: "ABERCROMBIE",
      state: "ND"
    },
    {
      city: "ABERDEEN",
      state: "ID"
    },
    {
      city: "ABERDEEN",
      state: "KY"
    },
    {
      city: "ABERDEEN",
      state: "MD"
    },
    {
      city: "ABERDEEN",
      state: "MS"
    },
    {
      city: "ABERDEEN",
      state: "NC"
    },
    {
      city: "ABERDEEN",
      state: "OH"
    },
    {
      city: "ABERDEEN",
      state: "SD"
    },
    {
      city: "ABERDEEN",
      state: "WA"
    },
    {
      city: "ABERDEEN PROVING GROUND",
      state: "MD"
    },
    {
      city: "ABERNATHY",
      state: "TX"
    },
    {
      city: "ABIE",
      state: "NE"
    },
    {
      city: "ABILENE",
      state: "KS"
    },
    {
      city: "ABILENE",
      state: "TX"
    },
    {
      city: "ABINGDON",
      state: "IL"
    },
    {
      city: "ABINGDON",
      state: "MD"
    },
    {
      city: "ABINGDON",
      state: "VA"
    },
    {
      city: "ABINGTON",
      state: "CT"
    },
    {
      city: "ABINGTON",
      state: "MA"
    },
    {
      city: "ABINGTON",
      state: "PA"
    },
    {
      city: "ABIQUIU",
      state: "NM"
    },
    {
      city: "ABITA SPRINGS",
      state: "LA"
    },
    {
      city: "ABRAMS",
      state: "WI"
    },
    {
      city: "ABSAROKEE",
      state: "MT"
    },
    {
      city: "ABSECON",
      state: "NJ"
    },
    {
      city: "ACAMPO",
      state: "CA"
    },
    {
      city: "ACCIDENT",
      state: "MD"
    },
    {
      city: "ACCOKEEK",
      state: "MD"
    },
    {
      city: "ACCOMAC",
      state: "VA"
    },
    {
      city: "ACCORD",
      state: "MA"
    },
    {
      city: "ACCORD",
      state: "NY"
    },
    {
      city: "ACCOVILLE",
      state: "WV"
    },
    {
      city: "ACE",
      state: "TX"
    },
    {
      city: "ACHILLE",
      state: "OK"
    },
    {
      city: "ACHILLES",
      state: "VA"
    },
    {
      city: "ACKERLY",
      state: "TX"
    },
    {
      city: "ACKERMAN",
      state: "MS"
    },
    {
      city: "ACKERMANVILLE",
      state: "PA"
    },
    {
      city: "ACKLEY",
      state: "IA"
    },
    {
      city: "ACKWORTH",
      state: "IA"
    },
    {
      city: "ACME",
      state: "MI"
    },
    {
      city: "ACME",
      state: "PA"
    },
    {
      city: "ACME",
      state: "WA"
    },
    {
      city: "ACOSTA",
      state: "PA"
    },
    {
      city: "ACRA",
      state: "NY"
    },
    {
      city: "ACTON",
      state: "CA"
    },
    {
      city: "ACTON",
      state: "MA"
    },
    {
      city: "ACTON",
      state: "ME"
    },
    {
      city: "ACTON",
      state: "MT"
    },
    {
      city: "ACUSHNET",
      state: "MA"
    },
    {
      city: "ACWORTH",
      state: "GA"
    },
    {
      city: "ACWORTH",
      state: "NH"
    },
    {
      city: "ADA",
      state: "MI"
    },
    {
      city: "ADA",
      state: "MN"
    },
    {
      city: "ADA",
      state: "OH"
    },
    {
      city: "ADA",
      state: "OK"
    },
    {
      city: "ADAH",
      state: "PA"
    },
    {
      city: "ADAIR",
      state: "IA"
    },
    {
      city: "ADAIR",
      state: "IL"
    },
    {
      city: "ADAIR",
      state: "OK"
    },
    {
      city: "ADAIRSVILLE",
      state: "GA"
    },
    {
      city: "ADAIRVILLE",
      state: "KY"
    },
    {
      city: "ADAK",
      state: "AK"
    },
    {
      city: "ADAMANT",
      state: "VT"
    },
    {
      city: "ADAMS",
      state: "KY"
    },
    {
      city: "ADAMS",
      state: "MA"
    },
    {
      city: "ADAMS",
      state: "MN"
    },
    {
      city: "ADAMS",
      state: "ND"
    },
    {
      city: "ADAMS",
      state: "NE"
    },
    {
      city: "ADAMS",
      state: "NY"
    },
    {
      city: "ADAMS",
      state: "OR"
    },
    {
      city: "ADAMS",
      state: "TN"
    },
    {
      city: "ADAMS",
      state: "WI"
    },
    {
      city: "ADAMS BASIN",
      state: "NY"
    },
    {
      city: "ADAMS CENTER",
      state: "NY"
    },
    {
      city: "ADAMS RUN",
      state: "SC"
    },
    {
      city: "ADAMSBURG",
      state: "PA"
    },
    {
      city: "ADAMSTOWN",
      state: "MD"
    },
    {
      city: "ADAMSTOWN",
      state: "PA"
    },
    {
      city: "ADAMSVILLE",
      state: "AL"
    },
    {
      city: "ADAMSVILLE",
      state: "OH"
    },
    {
      city: "ADAMSVILLE",
      state: "PA"
    },
    {
      city: "ADAMSVILLE",
      state: "RI"
    },
    {
      city: "ADAMSVILLE",
      state: "TN"
    },
    {
      city: "ADDIEVILLE",
      state: "IL"
    },
    {
      city: "ADDINGTON",
      state: "OK"
    },
    {
      city: "ADDIS",
      state: "LA"
    },
    {
      city: "ADDISON",
      state: "AL"
    },
    {
      city: "ADDISON",
      state: "IL"
    },
    {
      city: "ADDISON",
      state: "ME"
    },
    {
      city: "ADDISON",
      state: "MI"
    },
    {
      city: "ADDISON",
      state: "NY"
    },
    {
      city: "ADDISON",
      state: "PA"
    },
    {
      city: "ADDISON",
      state: "TX"
    },
    {
      city: "ADDY",
      state: "WA"
    },
    {
      city: "ADDYSTON",
      state: "OH"
    },
    {
      city: "ADEL",
      state: "GA"
    },
    {
      city: "ADEL",
      state: "IA"
    },
    {
      city: "ADELANTO",
      state: "CA"
    },
    {
      city: "ADELL",
      state: "WI"
    },
    {
      city: "ADELPHI",
      state: "OH"
    },
    {
      city: "ADELPHIA",
      state: "NJ"
    },
    {
      city: "ADENA",
      state: "OH"
    },
    {
      city: "ADGER",
      state: "AL"
    },
    {
      city: "ADIN",
      state: "CA"
    },
    {
      city: "ADIRONDACK",
      state: "NY"
    },
    {
      city: "ADKINS",
      state: "TX"
    },
    {
      city: "ADMIRE",
      state: "KS"
    },
    {
      city: "ADNA",
      state: "WA"
    },
    {
      city: "ADOLPH",
      state: "MN"
    },
    {
      city: "ADOLPHUS",
      state: "KY"
    },
    {
      city: "ADONA",
      state: "AR"
    },
    {
      city: "ADRIAN",
      state: "GA"
    },
    {
      city: "ADRIAN",
      state: "MI"
    },
    {
      city: "ADRIAN",
      state: "MN"
    },
    {
      city: "ADRIAN",
      state: "MO"
    },
    {
      city: "ADRIAN",
      state: "OR"
    },
    {
      city: "ADRIAN",
      state: "PA"
    },
    {
      city: "ADRIAN",
      state: "TX"
    },
    {
      city: "ADRIAN",
      state: "WV"
    },
    {
      city: "ADVANCE",
      state: "IN"
    },
    {
      city: "ADVANCE",
      state: "MO"
    },
    {
      city: "ADVANCE",
      state: "NC"
    },
    {
      city: "AFTON",
      state: "IA"
    },
    {
      city: "AFTON",
      state: "MI"
    },
    {
      city: "AFTON",
      state: "MN"
    },
    {
      city: "AFTON",
      state: "NY"
    },
    {
      city: "AFTON",
      state: "OK"
    },
    {
      city: "AFTON",
      state: "TN"
    },
    {
      city: "AFTON",
      state: "TX"
    },
    {
      city: "AFTON",
      state: "VA"
    },
    {
      city: "AFTON",
      state: "WI"
    },
    {
      city: "AFTON",
      state: "WY"
    },
    {
      city: "AGAR",
      state: "SD"
    },
    {
      city: "AGATE",
      state: "CO"
    },
    {
      city: "AGAWAM",
      state: "MA"
    },
    {
      city: "AGENCY",
      state: "IA"
    },
    {
      city: "AGENCY",
      state: "MO"
    },
    {
      city: "AGENDA",
      state: "KS"
    },
    {
      city: "AGNESS",
      state: "OR"
    },
    {
      city: "AGOURA HILLS",
      state: "CA"
    },
    {
      city: "AGRA",
      state: "KS"
    },
    {
      city: "AGRA",
      state: "OK"
    },
    {
      city: "AGUA DULCE",
      state: "TX"
    },
    {
      city: "AGUANGA",
      state: "CA"
    },
    {
      city: "AGUILA",
      state: "AZ"
    },
    {
      city: "AGUILAR",
      state: "CO"
    },
    {
      city: "AHMEEK",
      state: "MI"
    },
    {
      city: "AHOSKIE",
      state: "NC"
    },
    {
      city: "AHSAHKA",
      state: "ID"
    },
    {
      city: "AHWAHNEE",
      state: "CA"
    },
    {
      city: "AIEA",
      state: "HI"
    },
    {
      city: "AIKEN",
      state: "SC"
    },
    {
      city: "AIKEN",
      state: "TX"
    },
    {
      city: "AILEY",
      state: "GA"
    },
    {
      city: "AIMWELL",
      state: "LA"
    },
    {
      city: "AINSWORTH",
      state: "IA"
    },
    {
      city: "AINSWORTH",
      state: "NE"
    },
    {
      city: "AIRVILLE",
      state: "PA"
    },
    {
      city: "AIRWAY HEIGHTS",
      state: "WA"
    },
    {
      city: "AITKIN",
      state: "MN"
    },
    {
      city: "AJO",
      state: "AZ"
    },
    {
      city: "AKASKA",
      state: "SD"
    },
    {
      city: "AKELEY",
      state: "MN"
    },
    {
      city: "AKERS",
      state: "LA"
    },
    {
      city: "AKIACHAK",
      state: "AK"
    },
    {
      city: "AKIAK",
      state: "AK"
    },
    {
      city: "AKRON",
      state: "AL"
    },
    {
      city: "AKRON",
      state: "CO"
    },
    {
      city: "AKRON",
      state: "IA"
    },
    {
      city: "AKRON",
      state: "IN"
    },
    {
      city: "AKRON",
      state: "MI"
    },
    {
      city: "AKRON",
      state: "NY"
    },
    {
      city: "AKRON",
      state: "OH"
    },
    {
      city: "AKRON",
      state: "PA"
    },
    {
      city: "AKUTAN",
      state: "AK"
    },
    {
      city: "ALABASTER",
      state: "AL"
    },
    {
      city: "ALACHUA",
      state: "FL"
    },
    {
      city: "ALADDIN",
      state: "WY"
    },
    {
      city: "ALAKANUK",
      state: "AK"
    },
    {
      city: "ALAMANCE",
      state: "NC"
    },
    {
      city: "ALAMEDA",
      state: "CA"
    },
    {
      city: "ALAMO",
      state: "CA"
    },
    {
      city: "ALAMO",
      state: "GA"
    },
    {
      city: "ALAMO",
      state: "ND"
    },
    {
      city: "ALAMO",
      state: "NV"
    },
    {
      city: "ALAMO",
      state: "TN"
    },
    {
      city: "ALAMO",
      state: "TX"
    },
    {
      city: "ALAMOGORDO",
      state: "NM"
    },
    {
      city: "ALAMOSA",
      state: "CO"
    },
    {
      city: "ALANREED",
      state: "TX"
    },
    {
      city: "ALANSON",
      state: "MI"
    },
    {
      city: "ALAPAHA",
      state: "GA"
    },
    {
      city: "ALBA",
      state: "MI"
    },
    {
      city: "ALBA",
      state: "MO"
    },
    {
      city: "ALBA",
      state: "TX"
    },
    {
      city: "ALBANY",
      state: "CA"
    },
    {
      city: "ALBANY",
      state: "GA"
    },
    {
      city: "ALBANY",
      state: "IL"
    },
    {
      city: "ALBANY",
      state: "IN"
    },
    {
      city: "ALBANY",
      state: "KY"
    },
    {
      city: "ALBANY",
      state: "LA"
    },
    {
      city: "ALBANY",
      state: "MN"
    },
    {
      city: "ALBANY",
      state: "MO"
    },
    {
      city: "ALBANY",
      state: "NY"
    },
    {
      city: "ALBANY",
      state: "OH"
    },
    {
      city: "ALBANY",
      state: "OR"
    },
    {
      city: "ALBANY",
      state: "TX"
    },
    {
      city: "ALBANY",
      state: "VT"
    },
    {
      city: "ALBANY",
      state: "WI"
    },
    {
      city: "ALBEMARLE",
      state: "NC"
    },
    {
      city: "ALBERS",
      state: "IL"
    },
    {
      city: "ALBERT",
      state: "KS"
    },
    {
      city: "ALBERT",
      state: "OK"
    },
    {
      city: "ALBERT CITY",
      state: "IA"
    },
    {
      city: "ALBERT LEA",
      state: "MN"
    },
    {
      city: "ALBERTA",
      state: "AL"
    },
    {
      city: "ALBERTA",
      state: "MN"
    },
    {
      city: "ALBERTA",
      state: "VA"
    },
    {
      city: "ALBERTON",
      state: "MT"
    },
    {
      city: "ALBERTSON",
      state: "NC"
    },
    {
      city: "ALBERTSON",
      state: "NY"
    },
    {
      city: "ALBERTVILLE",
      state: "AL"
    },
    {
      city: "ALBERTVILLE",
      state: "MN"
    },
    {
      city: "ALBIA",
      state: "IA"
    },
    {
      city: "ALBIN",
      state: "WY"
    },
    {
      city: "ALBION",
      state: "CA"
    },
    {
      city: "ALBION",
      state: "IA"
    },
    {
      city: "ALBION",
      state: "ID"
    },
    {
      city: "ALBION",
      state: "IL"
    },
    {
      city: "ALBION",
      state: "IN"
    },
    {
      city: "ALBION",
      state: "ME"
    },
    {
      city: "ALBION",
      state: "MI"
    },
    {
      city: "ALBION",
      state: "NE"
    },
    {
      city: "ALBION",
      state: "NY"
    },
    {
      city: "ALBION",
      state: "OK"
    },
    {
      city: "ALBION",
      state: "PA"
    },
    {
      city: "ALBION",
      state: "RI"
    },
    {
      city: "ALBION",
      state: "WA"
    },
    {
      city: "ALBORN",
      state: "MN"
    },
    {
      city: "ALBRIGHT",
      state: "WV"
    },
    {
      city: "ALBRIGHTSVILLE",
      state: "PA"
    },
    {
      city: "ALBUQUERQUE",
      state: "NM"
    },
    {
      city: "ALBURGH",
      state: "VT"
    },
    {
      city: "ALBURNETT",
      state: "IA"
    },
    {
      city: "ALBURTIS",
      state: "PA"
    },
    {
      city: "ALCALDE",
      state: "NM"
    },
    {
      city: "ALCESTER",
      state: "SD"
    },
    {
      city: "ALCOA",
      state: "TN"
    },
    {
      city: "ALCOLU",
      state: "SC"
    },
    {
      city: "ALCOVA",
      state: "WY"
    },
    {
      city: "ALCOVE",
      state: "NY"
    },
    {
      city: "ALDA",
      state: "NE"
    },
    {
      city: "ALDEN",
      state: "IA"
    },
    {
      city: "ALDEN",
      state: "IL"
    },
    {
      city: "ALDEN",
      state: "KS"
    },
    {
      city: "ALDEN",
      state: "MI"
    },
    {
      city: "ALDEN",
      state: "MN"
    },
    {
      city: "ALDEN",
      state: "NY"
    },
    {
      city: "ALDER",
      state: "MT"
    },
    {
      city: "ALDER CREEK",
      state: "NY"
    },
    {
      city: "ALDERPOINT",
      state: "CA"
    },
    {
      city: "ALDERSON",
      state: "OK"
    },
    {
      city: "ALDERSON",
      state: "WV"
    },
    {
      city: "ALDIE",
      state: "VA"
    },
    {
      city: "ALDRICH",
      state: "MN"
    },
    {
      city: "ALDRICH",
      state: "MO"
    },
    {
      city: "ALEDO",
      state: "IL"
    },
    {
      city: "ALEDO",
      state: "TX"
    },
    {
      city: "ALEKNAGIK",
      state: "AK"
    },
    {
      city: "ALEPPO",
      state: "PA"
    },
    {
      city: "ALEX",
      state: "OK"
    },
    {
      city: "ALEXANDER",
      state: "AR"
    },
    {
      city: "ALEXANDER",
      state: "IA"
    },
    {
      city: "ALEXANDER",
      state: "IL"
    },
    {
      city: "ALEXANDER",
      state: "KS"
    },
    {
      city: "ALEXANDER",
      state: "NC"
    },
    {
      city: "ALEXANDER",
      state: "ND"
    },
    {
      city: "ALEXANDER",
      state: "NY"
    },
    {
      city: "ALEXANDER CITY",
      state: "AL"
    },
    {
      city: "ALEXANDRIA",
      state: "AL"
    },
    {
      city: "ALEXANDRIA",
      state: "IN"
    },
    {
      city: "ALEXANDRIA",
      state: "KY"
    },
    {
      city: "ALEXANDRIA",
      state: "LA"
    },
    {
      city: "ALEXANDRIA",
      state: "MN"
    },
    {
      city: "ALEXANDRIA",
      state: "MO"
    },
    {
      city: "ALEXANDRIA",
      state: "NE"
    },
    {
      city: "ALEXANDRIA",
      state: "OH"
    },
    {
      city: "ALEXANDRIA",
      state: "PA"
    },
    {
      city: "ALEXANDRIA",
      state: "SD"
    },
    {
      city: "ALEXANDRIA",
      state: "TN"
    },
    {
      city: "ALEXANDRIA",
      state: "VA"
    },
    {
      city: "ALEXANDRIA BAY",
      state: "NY"
    },
    {
      city: "ALEXIS",
      state: "IL"
    },
    {
      city: "ALEXIS",
      state: "NC"
    },
    {
      city: "ALFORD",
      state: "FL"
    },
    {
      city: "ALFRED",
      state: "ME"
    },
    {
      city: "ALFRED",
      state: "NY"
    },
    {
      city: "ALFRED STATION",
      state: "NY"
    },
    {
      city: "ALGER",
      state: "MI"
    },
    {
      city: "ALGER",
      state: "OH"
    },
    {
      city: "ALGODONES",
      state: "NM"
    },
    {
      city: "ALGOMA",
      state: "MS"
    },
    {
      city: "ALGOMA",
      state: "WI"
    },
    {
      city: "ALGONA",
      state: "IA"
    },
    {
      city: "ALGONAC",
      state: "MI"
    },
    {
      city: "ALGONQUIN",
      state: "IL"
    },
    {
      city: "ALHAMBRA",
      state: "CA"
    },
    {
      city: "ALHAMBRA",
      state: "IL"
    },
    {
      city: "ALICE",
      state: "TX"
    },
    {
      city: "ALICEVILLE",
      state: "AL"
    },
    {
      city: "ALICIA",
      state: "AR"
    },
    {
      city: "ALIEF",
      state: "TX"
    },
    {
      city: "ALINE",
      state: "OK"
    },
    {
      city: "ALIQUIPPA",
      state: "PA"
    },
    {
      city: "ALISO VIEJO",
      state: "CA"
    },
    {
      city: "ALIX",
      state: "AR"
    },
    {
      city: "ALKOL",
      state: "WV"
    },
    {
      city: "ALLAKAKET",
      state: "AK"
    },
    {
      city: "ALLAMUCHY",
      state: "NJ"
    },
    {
      city: "ALLARDT",
      state: "TN"
    },
    {
      city: "ALLEDONIA",
      state: "OH"
    },
    {
      city: "ALLEGAN",
      state: "MI"
    },
    {
      city: "ALLEGANY",
      state: "NY"
    },
    {
      city: "ALLEGANY",
      state: "OR"
    },
    {
      city: "ALLEGHANY",
      state: "CA"
    },
    {
      city: "ALLEMAN",
      state: "IA"
    },
    {
      city: "ALLEN",
      state: "KS"
    },
    {
      city: "ALLEN",
      state: "KY"
    },
    {
      city: "ALLEN",
      state: "MD"
    },
    {
      city: "ALLEN",
      state: "MI"
    },
    {
      city: "ALLEN",
      state: "NE"
    },
    {
      city: "ALLEN",
      state: "OK"
    },
    {
      city: "ALLEN",
      state: "SD"
    },
    {
      city: "ALLEN",
      state: "TX"
    },
    {
      city: "ALLEN JUNCTION",
      state: "WV"
    },
    {
      city: "ALLEN PARK",
      state: "MI"
    },
    {
      city: "ALLENDALE",
      state: "IL"
    },
    {
      city: "ALLENDALE",
      state: "MI"
    },
    {
      city: "ALLENDALE",
      state: "MO"
    },
    {
      city: "ALLENDALE",
      state: "NJ"
    },
    {
      city: "ALLENDALE",
      state: "SC"
    },
    {
      city: "ALLENHURST",
      state: "GA"
    },
    {
      city: "ALLENHURST",
      state: "NJ"
    },
    {
      city: "ALLENPORT",
      state: "PA"
    },
    {
      city: "ALLENSPARK",
      state: "CO"
    },
    {
      city: "ALLENSVILLE",
      state: "KY"
    },
    {
      city: "ALLENSVILLE",
      state: "PA"
    },
    {
      city: "ALLENTON",
      state: "MI"
    },
    {
      city: "ALLENTON",
      state: "WI"
    },
    {
      city: "ALLENTOWN",
      state: "GA"
    },
    {
      city: "ALLENTOWN",
      state: "NJ"
    },
    {
      city: "ALLENTOWN",
      state: "PA"
    },
    {
      city: "ALLENWOOD",
      state: "NJ"
    },
    {
      city: "ALLENWOOD",
      state: "PA"
    },
    {
      city: "ALLERTON",
      state: "IA"
    },
    {
      city: "ALLERTON",
      state: "IL"
    },
    {
      city: "ALLEYTON",
      state: "TX"
    },
    {
      city: "ALLGOOD",
      state: "AL"
    },
    {
      city: "ALLIANCE",
      state: "NC"
    },
    {
      city: "ALLIANCE",
      state: "NE"
    },
    {
      city: "ALLIANCE",
      state: "OH"
    },
    {
      city: "ALLIGATOR",
      state: "MS"
    },
    {
      city: "ALLISON",
      state: "IA"
    },
    {
      city: "ALLISON",
      state: "PA"
    },
    {
      city: "ALLISON",
      state: "TX"
    },
    {
      city: "ALLISON PARK",
      state: "PA"
    },
    {
      city: "ALLONS",
      state: "TN"
    },
    {
      city: "ALLOUEZ",
      state: "MI"
    },
    {
      city: "ALLOWAY",
      state: "NJ"
    },
    {
      city: "ALLOY",
      state: "WV"
    },
    {
      city: "ALLPORT",
      state: "PA"
    },
    {
      city: "ALLSTON",
      state: "MA"
    },
    {
      city: "ALLYN",
      state: "WA"
    },
    {
      city: "ALMA",
      state: "AR"
    },
    {
      city: "ALMA",
      state: "CO"
    },
    {
      city: "ALMA",
      state: "GA"
    },
    {
      city: "ALMA",
      state: "IL"
    },
    {
      city: "ALMA",
      state: "KS"
    },
    {
      city: "ALMA",
      state: "MI"
    },
    {
      city: "ALMA",
      state: "MO"
    },
    {
      city: "ALMA",
      state: "NE"
    },
    {
      city: "ALMA",
      state: "WI"
    },
    {
      city: "ALMA",
      state: "WV"
    },
    {
      city: "ALMA CENTER",
      state: "WI"
    },
    {
      city: "ALMELUND",
      state: "MN"
    },
    {
      city: "ALMENA",
      state: "KS"
    },
    {
      city: "ALMENA",
      state: "WI"
    },
    {
      city: "ALMIRA",
      state: "WA"
    },
    {
      city: "ALMO",
      state: "ID"
    },
    {
      city: "ALMO",
      state: "KY"
    },
    {
      city: "ALMOND",
      state: "NC"
    },
    {
      city: "ALMOND",
      state: "NY"
    },
    {
      city: "ALMOND",
      state: "WI"
    },
    {
      city: "ALMONT",
      state: "CO"
    },
    {
      city: "ALMONT",
      state: "MI"
    },
    {
      city: "ALMONT",
      state: "ND"
    },
    {
      city: "ALMYRA",
      state: "AR"
    },
    {
      city: "ALNA",
      state: "ME"
    },
    {
      city: "ALPAUGH",
      state: "CA"
    },
    {
      city: "ALPENA",
      state: "AR"
    },
    {
      city: "ALPENA",
      state: "MI"
    },
    {
      city: "ALPENA",
      state: "SD"
    },
    {
      city: "ALPHA",
      state: "IL"
    },
    {
      city: "ALPHA",
      state: "KY"
    },
    {
      city: "ALPHA",
      state: "MI"
    },
    {
      city: "ALPHA",
      state: "MN"
    },
    {
      city: "ALPHA",
      state: "OH"
    },
    {
      city: "ALPHARETTA",
      state: "GA"
    },
    {
      city: "ALPINE",
      state: "AL"
    },
    {
      city: "ALPINE",
      state: "AZ"
    },
    {
      city: "ALPINE",
      state: "CA"
    },
    {
      city: "ALPINE",
      state: "NJ"
    },
    {
      city: "ALPINE",
      state: "NY"
    },
    {
      city: "ALPINE",
      state: "TN"
    },
    {
      city: "ALPINE",
      state: "TX"
    },
    {
      city: "ALPINE",
      state: "UT"
    },
    {
      city: "ALPINE",
      state: "WY"
    },
    {
      city: "ALPLAUS",
      state: "NY"
    },
    {
      city: "ALSEA",
      state: "OR"
    },
    {
      city: "ALSEN",
      state: "ND"
    },
    {
      city: "ALSEY",
      state: "IL"
    },
    {
      city: "ALSIP",
      state: "IL"
    },
    {
      city: "ALSTEAD",
      state: "NH"
    },
    {
      city: "ALSTON",
      state: "GA"
    },
    {
      city: "ALTA",
      state: "CA"
    },
    {
      city: "ALTA",
      state: "IA"
    },
    {
      city: "ALTA",
      state: "WY"
    },
    {
      city: "ALTA VISTA",
      state: "IA"
    },
    {
      city: "ALTA VISTA",
      state: "KS"
    },
    {
      city: "ALTADENA",
      state: "CA"
    },
    {
      city: "ALTAIR",
      state: "TX"
    },
    {
      city: "ALTAMAHAW",
      state: "NC"
    },
    {
      city: "ALTAMONT",
      state: "IL"
    },
    {
      city: "ALTAMONT",
      state: "KS"
    },
    {
      city: "ALTAMONT",
      state: "MO"
    },
    {
      city: "ALTAMONT",
      state: "NY"
    },
    {
      city: "ALTAMONT",
      state: "TN"
    },
    {
      city: "ALTAMONT",
      state: "UT"
    },
    {
      city: "ALTAMONTE SPRINGS",
      state: "FL"
    },
    {
      city: "ALTAVILLE",
      state: "CA"
    },
    {
      city: "ALTAVISTA",
      state: "VA"
    },
    {
      city: "ALTENBURG",
      state: "MO"
    },
    {
      city: "ALTHA",
      state: "FL"
    },
    {
      city: "ALTHEIMER",
      state: "AR"
    },
    {
      city: "ALTMAR",
      state: "NY"
    },
    {
      city: "ALTO",
      state: "GA"
    },
    {
      city: "ALTO",
      state: "MI"
    },
    {
      city: "ALTO",
      state: "NM"
    },
    {
      city: "ALTO",
      state: "TX"
    },
    {
      city: "ALTO PASS",
      state: "IL"
    },
    {
      city: "ALTON",
      state: "AL"
    },
    {
      city: "ALTON",
      state: "IA"
    },
    {
      city: "ALTON",
      state: "IL"
    },
    {
      city: "ALTON",
      state: "KS"
    },
    {
      city: "ALTON",
      state: "MO"
    },
    {
      city: "ALTON",
      state: "NH"
    },
    {
      city: "ALTON",
      state: "NY"
    },
    {
      city: "ALTON",
      state: "UT"
    },
    {
      city: "ALTON",
      state: "VA"
    },
    {
      city: "ALTON BAY",
      state: "NH"
    },
    {
      city: "ALTONA",
      state: "IL"
    },
    {
      city: "ALTONA",
      state: "NY"
    },
    {
      city: "ALTONAH",
      state: "UT"
    },
    {
      city: "ALTOONA",
      state: "AL"
    },
    {
      city: "ALTOONA",
      state: "FL"
    },
    {
      city: "ALTOONA",
      state: "IA"
    },
    {
      city: "ALTOONA",
      state: "KS"
    },
    {
      city: "ALTOONA",
      state: "PA"
    },
    {
      city: "ALTOONA",
      state: "WI"
    },
    {
      city: "ALTURA",
      state: "MN"
    },
    {
      city: "ALTURAS",
      state: "CA"
    },
    {
      city: "ALTURAS",
      state: "FL"
    },
    {
      city: "ALTUS",
      state: "AR"
    },
    {
      city: "ALTUS",
      state: "OK"
    },
    {
      city: "ALTUS AFB",
      state: "OK"
    },
    {
      city: "ALUM BANK",
      state: "PA"
    },
    {
      city: "ALUM BRIDGE",
      state: "WV"
    },
    {
      city: "ALUM CREEK",
      state: "WV"
    },
    {
      city: "ALVA",
      state: "FL"
    },
    {
      city: "ALVA",
      state: "OK"
    },
    {
      city: "ALVA",
      state: "WY"
    },
    {
      city: "ALVADA",
      state: "OH"
    },
    {
      city: "ALVADORE",
      state: "OR"
    },
    {
      city: "ALVARADO",
      state: "MN"
    },
    {
      city: "ALVARADO",
      state: "TX"
    },
    {
      city: "ALVATON",
      state: "KY"
    },
    {
      city: "ALVERDA",
      state: "PA"
    },
    {
      city: "ALVERTON",
      state: "PA"
    },
    {
      city: "ALVIN",
      state: "IL"
    },
    {
      city: "ALVIN",
      state: "TX"
    },
    {
      city: "ALVISO",
      state: "CA"
    },
    {
      city: "ALVO",
      state: "NE"
    },
    {
      city: "ALVORD",
      state: "IA"
    },
    {
      city: "ALVORD",
      state: "TX"
    },
    {
      city: "ALVORDTON",
      state: "OH"
    },
    {
      city: "ALZADA",
      state: "MT"
    },
    {
      city: "AMA",
      state: "LA"
    },
    {
      city: "AMADO",
      state: "AZ"
    },
    {
      city: "AMADOR CITY",
      state: "CA"
    },
    {
      city: "AMAGANSETT",
      state: "NY"
    },
    {
      city: "AMALIA",
      state: "NM"
    },
    {
      city: "AMANA",
      state: "IA"
    },
    {
      city: "AMANDA",
      state: "OH"
    },
    {
      city: "AMANDA PARK",
      state: "WA"
    },
    {
      city: "AMARGOSA VALLEY",
      state: "NV"
    },
    {
      city: "AMARILLO",
      state: "TX"
    },
    {
      city: "AMASA",
      state: "MI"
    },
    {
      city: "AMAWALK",
      state: "NY"
    },
    {
      city: "AMAZONIA",
      state: "MO"
    },
    {
      city: "AMBER",
      state: "OK"
    },
    {
      city: "AMBERG",
      state: "WI"
    },
    {
      city: "AMBERSON",
      state: "PA"
    },
    {
      city: "AMBIA",
      state: "IN"
    },
    {
      city: "AMBLER",
      state: "AK"
    },
    {
      city: "AMBLER",
      state: "PA"
    },
    {
      city: "AMBOY",
      state: "CA"
    },
    {
      city: "AMBOY",
      state: "IL"
    },
    {
      city: "AMBOY",
      state: "IN"
    },
    {
      city: "AMBOY",
      state: "MN"
    },
    {
      city: "AMBOY",
      state: "WA"
    },
    {
      city: "AMBRIDGE",
      state: "PA"
    },
    {
      city: "AMBROSE",
      state: "GA"
    },
    {
      city: "AMBROSE",
      state: "ND"
    },
    {
      city: "AMELIA",
      state: "LA"
    },
    {
      city: "AMELIA",
      state: "OH"
    },
    {
      city: "AMELIA COURT HOUSE",
      state: "VA"
    },
    {
      city: "AMENIA",
      state: "ND"
    },
    {
      city: "AMENIA",
      state: "NY"
    },
    {
      city: "AMERICAN CANYON",
      state: "CA"
    },
    {
      city: "AMERICAN FALLS",
      state: "ID"
    },
    {
      city: "AMERICAN FORK",
      state: "UT"
    },
    {
      city: "AMERICUS",
      state: "GA"
    },
    {
      city: "AMERICUS",
      state: "KS"
    },
    {
      city: "AMERY",
      state: "WI"
    },
    {
      city: "AMES",
      state: "IA"
    },
    {
      city: "AMES",
      state: "NE"
    },
    {
      city: "AMES",
      state: "OK"
    },
    {
      city: "AMESBURY",
      state: "MA"
    },
    {
      city: "AMESVILLE",
      state: "OH"
    },
    {
      city: "AMHERST",
      state: "CO"
    },
    {
      city: "AMHERST",
      state: "MA"
    },
    {
      city: "AMHERST",
      state: "NE"
    },
    {
      city: "AMHERST",
      state: "NH"
    },
    {
      city: "AMHERST",
      state: "OH"
    },
    {
      city: "AMHERST",
      state: "SD"
    },
    {
      city: "AMHERST",
      state: "TX"
    },
    {
      city: "AMHERST",
      state: "VA"
    },
    {
      city: "AMHERST",
      state: "WI"
    },
    {
      city: "AMHERST JUNCTION",
      state: "WI"
    },
    {
      city: "AMHERSTDALE",
      state: "WV"
    },
    {
      city: "AMIDON",
      state: "ND"
    },
    {
      city: "AMIGO",
      state: "WV"
    },
    {
      city: "AMISSVILLE",
      state: "VA"
    },
    {
      city: "AMISTAD",
      state: "NM"
    },
    {
      city: "AMITE",
      state: "LA"
    },
    {
      city: "AMITY",
      state: "AR"
    },
    {
      city: "AMITY",
      state: "MO"
    },
    {
      city: "AMITY",
      state: "OR"
    },
    {
      city: "AMITY",
      state: "PA"
    },
    {
      city: "AMITYVILLE",
      state: "NY"
    },
    {
      city: "AMLIN",
      state: "OH"
    },
    {
      city: "AMMA",
      state: "WV"
    },
    {
      city: "AMO",
      state: "IN"
    },
    {
      city: "AMONATE",
      state: "VA"
    },
    {
      city: "AMORET",
      state: "MO"
    },
    {
      city: "AMORITA",
      state: "OK"
    },
    {
      city: "AMORY",
      state: "MS"
    },
    {
      city: "AMSTERDAM",
      state: "MO"
    },
    {
      city: "AMSTERDAM",
      state: "NY"
    },
    {
      city: "AMSTERDAM",
      state: "OH"
    },
    {
      city: "AMSTON",
      state: "CT"
    },
    {
      city: "ANABEL",
      state: "MO"
    },
    {
      city: "ANACOCO",
      state: "LA"
    },
    {
      city: "ANACONDA",
      state: "MT"
    },
    {
      city: "ANACORTES",
      state: "WA"
    },
    {
      city: "ANADARKO",
      state: "OK"
    },
    {
      city: "ANAHEIM",
      state: "CA"
    },
    {
      city: "ANAHOLA",
      state: "HI"
    },
    {
      city: "ANAHUAC",
      state: "TX"
    },
    {
      city: "ANAKTUVUK PASS",
      state: "AK"
    },
    {
      city: "ANALOMINK",
      state: "PA"
    },
    {
      city: "ANAMOOSE",
      state: "ND"
    },
    {
      city: "ANAMOSA",
      state: "IA"
    },
    {
      city: "ANATONE",
      state: "WA"
    },
    {
      city: "ANAWALT",
      state: "WV"
    },
    {
      city: "ANCHOR",
      state: "IL"
    },
    {
      city: "ANCHOR POINT",
      state: "AK"
    },
    {
      city: "ANCHORAGE",
      state: "AK"
    },
    {
      city: "ANCHORVILLE",
      state: "MI"
    },
    {
      city: "ANCRAM",
      state: "NY"
    },
    {
      city: "ANCRAMDALE",
      state: "NY"
    },
    {
      city: "ANDALE",
      state: "KS"
    },
    {
      city: "ANDALUSIA",
      state: "AL"
    },
    {
      city: "ANDALUSIA",
      state: "IL"
    },
    {
      city: "ANDERSON",
      state: "AK"
    },
    {
      city: "ANDERSON",
      state: "AL"
    },
    {
      city: "ANDERSON",
      state: "CA"
    },
    {
      city: "ANDERSON",
      state: "IN"
    },
    {
      city: "ANDERSON",
      state: "MO"
    },
    {
      city: "ANDERSON",
      state: "SC"
    },
    {
      city: "ANDERSON",
      state: "TX"
    },
    {
      city: "ANDERSON ISLAND",
      state: "WA"
    },
    {
      city: "ANDERSONVILLE",
      state: "GA"
    },
    {
      city: "ANDERSONVILLE",
      state: "TN"
    },
    {
      city: "ANDES",
      state: "NY"
    },
    {
      city: "ANDOVER",
      state: "CT"
    },
    {
      city: "ANDOVER",
      state: "IA"
    },
    {
      city: "ANDOVER",
      state: "IL"
    },
    {
      city: "ANDOVER",
      state: "KS"
    },
    {
      city: "ANDOVER",
      state: "MA"
    },
    {
      city: "ANDOVER",
      state: "ME"
    },
    {
      city: "ANDOVER",
      state: "MN"
    },
    {
      city: "ANDOVER",
      state: "NH"
    },
    {
      city: "ANDOVER",
      state: "NJ"
    },
    {
      city: "ANDOVER",
      state: "NY"
    },
    {
      city: "ANDOVER",
      state: "OH"
    },
    {
      city: "ANDOVER",
      state: "SD"
    },
    {
      city: "ANDREAS",
      state: "PA"
    },
    {
      city: "ANDREW",
      state: "IA"
    },
    {
      city: "ANDREWS",
      state: "IN"
    },
    {
      city: "ANDREWS",
      state: "NC"
    },
    {
      city: "ANDREWS",
      state: "SC"
    },
    {
      city: "ANDREWS",
      state: "TX"
    },
    {
      city: "ANDREWS AIR FORCE BASE",
      state: "MD"
    },
    {
      city: "ANETA",
      state: "ND"
    },
    {
      city: "ANETH",
      state: "UT"
    },
    {
      city: "ANGEL FIRE",
      state: "NM"
    },
    {
      city: "ANGELICA",
      state: "NY"
    },
    {
      city: "ANGELS CAMP",
      state: "CA"
    },
    {
      city: "ANGELUS OAKS",
      state: "CA"
    },
    {
      city: "ANGIE",
      state: "LA"
    },
    {
      city: "ANGIER",
      state: "NC"
    },
    {
      city: "ANGLE INLET",
      state: "MN"
    },
    {
      city: "ANGLETON",
      state: "TX"
    },
    {
      city: "ANGOLA",
      state: "IN"
    },
    {
      city: "ANGOLA",
      state: "LA"
    },
    {
      city: "ANGOLA",
      state: "NY"
    },
    {
      city: "ANGOON",
      state: "AK"
    },
    {
      city: "ANGORA",
      state: "MN"
    },
    {
      city: "ANGORA",
      state: "NE"
    },
    {
      city: "ANGUILLA",
      state: "MS"
    },
    {
      city: "ANGWIN",
      state: "CA"
    },
    {
      city: "ANIAK",
      state: "AK"
    },
    {
      city: "ANIMAS",
      state: "NM"
    },
    {
      city: "ANITA",
      state: "IA"
    },
    {
      city: "ANITA",
      state: "PA"
    },
    {
      city: "ANIWA",
      state: "WI"
    },
    {
      city: "ANKENY",
      state: "IA"
    },
    {
      city: "ANMOORE",
      state: "WV"
    },
    {
      city: "ANN ARBOR",
      state: "MI"
    },
    {
      city: "ANNA",
      state: "IL"
    },
    {
      city: "ANNA",
      state: "OH"
    },
    {
      city: "ANNA",
      state: "TX"
    },
    {
      city: "ANNA MARIA",
      state: "FL"
    },
    {
      city: "ANNABELLA",
      state: "UT"
    },
    {
      city: "ANNANDALE",
      state: "MN"
    },
    {
      city: "ANNANDALE",
      state: "NJ"
    },
    {
      city: "ANNANDALE",
      state: "VA"
    },
    {
      city: "ANNANDALE ON HUDSON",
      state: "NY"
    },
    {
      city: "ANNAPOLIS",
      state: "CA"
    },
    {
      city: "ANNAPOLIS",
      state: "IL"
    },
    {
      city: "ANNAPOLIS",
      state: "MD"
    },
    {
      city: "ANNAPOLIS",
      state: "MO"
    },
    {
      city: "ANNAPOLIS JUNCTION",
      state: "MD"
    },
    {
      city: "ANNAWAN",
      state: "IL"
    },
    {
      city: "ANNISTON",
      state: "AL"
    },
    {
      city: "ANNISTON",
      state: "MO"
    },
    {
      city: "ANNONA",
      state: "TX"
    },
    {
      city: "ANNVILLE",
      state: "KY"
    },
    {
      city: "ANNVILLE",
      state: "PA"
    },
    {
      city: "ANOKA",
      state: "MN"
    },
    {
      city: "ANSELMO",
      state: "NE"
    },
    {
      city: "ANSLEY",
      state: "NE"
    },
    {
      city: "ANSON",
      state: "ME"
    },
    {
      city: "ANSON",
      state: "TX"
    },
    {
      city: "ANSONIA",
      state: "CT"
    },
    {
      city: "ANSONIA",
      state: "OH"
    },
    {
      city: "ANSONVILLE",
      state: "NC"
    },
    {
      city: "ANSTED",
      state: "WV"
    },
    {
      city: "ANTELOPE",
      state: "CA"
    },
    {
      city: "ANTELOPE",
      state: "MT"
    },
    {
      city: "ANTELOPE",
      state: "OR"
    },
    {
      city: "ANTES FORT",
      state: "PA"
    },
    {
      city: "ANTHON",
      state: "IA"
    },
    {
      city: "ANTHONY",
      state: "FL"
    },
    {
      city: "ANTHONY",
      state: "KS"
    },
    {
      city: "ANTHONY",
      state: "NM"
    },
    {
      city: "ANTHONY",
      state: "TX"
    },
    {
      city: "ANTIGO",
      state: "WI"
    },
    {
      city: "ANTIMONY",
      state: "UT"
    },
    {
      city: "ANTIOCH",
      state: "CA"
    },
    {
      city: "ANTIOCH",
      state: "IL"
    },
    {
      city: "ANTIOCH",
      state: "TN"
    },
    {
      city: "ANTLER",
      state: "ND"
    },
    {
      city: "ANTLERS",
      state: "OK"
    },
    {
      city: "ANTOINE",
      state: "AR"
    },
    {
      city: "ANTON",
      state: "CO"
    },
    {
      city: "ANTON",
      state: "TX"
    },
    {
      city: "ANTON CHICO",
      state: "NM"
    },
    {
      city: "ANTONITO",
      state: "CO"
    },
    {
      city: "ANTRIM",
      state: "NH"
    },
    {
      city: "ANTWERP",
      state: "NY"
    },
    {
      city: "ANTWERP",
      state: "OH"
    },
    {
      city: "ANVIK",
      state: "AK"
    },
    {
      city: "ANZA",
      state: "CA"
    },
    {
      city: "APACHE",
      state: "OK"
    },
    {
      city: "APACHE JUNCTION",
      state: "AZ"
    },
    {
      city: "APALACHICOLA",
      state: "FL"
    },
    {
      city: "APALACHIN",
      state: "NY"
    },
    {
      city: "APEX",
      state: "NC"
    },
    {
      city: "APISON",
      state: "TN"
    },
    {
      city: "APLINGTON",
      state: "IA"
    },
    {
      city: "APOLLO",
      state: "PA"
    },
    {
      city: "APOLLO BEACH",
      state: "FL"
    },
    {
      city: "APOPKA",
      state: "FL"
    },
    {
      city: "APPALACHIA",
      state: "VA"
    },
    {
      city: "APPLE CREEK",
      state: "OH"
    },
    {
      city: "APPLE GROVE",
      state: "WV"
    },
    {
      city: "APPLE RIVER",
      state: "IL"
    },
    {
      city: "APPLE SPRINGS",
      state: "TX"
    },
    {
      city: "APPLE VALLEY",
      state: "CA"
    },
    {
      city: "APPLEGATE",
      state: "CA"
    },
    {
      city: "APPLEGATE",
      state: "MI"
    },
    {
      city: "APPLETON",
      state: "MN"
    },
    {
      city: "APPLETON",
      state: "NY"
    },
    {
      city: "APPLETON",
      state: "WA"
    },
    {
      city: "APPLETON",
      state: "WI"
    },
    {
      city: "APPLETON CITY",
      state: "MO"
    },
    {
      city: "APPLING",
      state: "GA"
    },
    {
      city: "APPOMATTOX",
      state: "VA"
    },
    {
      city: "APTOS",
      state: "CA"
    },
    {
      city: "APULIA STATION",
      state: "NY"
    },
    {
      city: "AQUASCO",
      state: "MD"
    },
    {
      city: "AQUEBOGUE",
      state: "NY"
    },
    {
      city: "AQUILLA",
      state: "TX"
    },
    {
      city: "ARAB",
      state: "AL"
    },
    {
      city: "ARABI",
      state: "GA"
    },
    {
      city: "ARABI",
      state: "LA"
    },
    {
      city: "ARAGON",
      state: "GA"
    },
    {
      city: "ARAGON",
      state: "NM"
    },
    {
      city: "ARANSAS PASS",
      state: "TX"
    },
    {
      city: "ARAPAHO",
      state: "OK"
    },
    {
      city: "ARAPAHOE",
      state: "CO"
    },
    {
      city: "ARAPAHOE",
      state: "NC"
    },
    {
      city: "ARAPAHOE",
      state: "NE"
    },
    {
      city: "ARAPAHOE",
      state: "WY"
    },
    {
      city: "ARARAT",
      state: "NC"
    },
    {
      city: "ARARAT",
      state: "VA"
    },
    {
      city: "ARBELA",
      state: "MO"
    },
    {
      city: "ARBOLES",
      state: "CO"
    },
    {
      city: "ARBON",
      state: "ID"
    },
    {
      city: "ARBOVALE",
      state: "WV"
    },
    {
      city: "ARBUCKLE",
      state: "CA"
    },
    {
      city: "ARBYRD",
      state: "MO"
    },
    {
      city: "ARCADE",
      state: "NY"
    },
    {
      city: "ARCADIA",
      state: "CA"
    },
    {
      city: "ARCADIA",
      state: "FL"
    },
    {
      city: "ARCADIA",
      state: "IA"
    },
    {
      city: "ARCADIA",
      state: "IN"
    },
    {
      city: "ARCADIA",
      state: "KS"
    },
    {
      city: "ARCADIA",
      state: "LA"
    },
    {
      city: "ARCADIA",
      state: "MI"
    },
    {
      city: "ARCADIA",
      state: "MO"
    },
    {
      city: "ARCADIA",
      state: "NE"
    },
    {
      city: "ARCADIA",
      state: "OH"
    },
    {
      city: "ARCADIA",
      state: "OK"
    },
    {
      city: "ARCADIA",
      state: "PA"
    },
    {
      city: "ARCADIA",
      state: "SC"
    },
    {
      city: "ARCADIA",
      state: "WI"
    },
    {
      city: "ARCANUM",
      state: "OH"
    },
    {
      city: "ARCATA",
      state: "CA"
    },
    {
      city: "ARCH CAPE",
      state: "OR"
    },
    {
      city: "ARCHBALD",
      state: "PA"
    },
    {
      city: "ARCHBOLD",
      state: "OH"
    },
    {
      city: "ARCHER",
      state: "FL"
    },
    {
      city: "ARCHER",
      state: "IA"
    },
    {
      city: "ARCHER",
      state: "NE"
    },
    {
      city: "ARCHER CITY",
      state: "TX"
    },
    {
      city: "ARCHIBALD",
      state: "LA"
    },
    {
      city: "ARCHIE",
      state: "MO"
    },
    {
      city: "ARCO",
      state: "ID"
    },
    {
      city: "ARCO",
      state: "MN"
    },
    {
      city: "ARCOLA",
      state: "IL"
    },
    {
      city: "ARCOLA",
      state: "IN"
    },
    {
      city: "ARCOLA",
      state: "MO"
    },
    {
      city: "ARCOLA",
      state: "MS"
    },
    {
      city: "ARCTIC VILLAGE",
      state: "AK"
    },
    {
      city: "ARDARA",
      state: "PA"
    },
    {
      city: "ARDEN",
      state: "NC"
    },
    {
      city: "ARDEN",
      state: "NY"
    },
    {
      city: "ARDENVOIR",
      state: "WA"
    },
    {
      city: "ARDMORE",
      state: "AL"
    },
    {
      city: "ARDMORE",
      state: "OK"
    },
    {
      city: "ARDMORE",
      state: "PA"
    },
    {
      city: "ARDMORE",
      state: "TN"
    },
    {
      city: "ARDSLEY",
      state: "NY"
    },
    {
      city: "ARDSLEY ON HUDSON",
      state: "NY"
    },
    {
      city: "AREDALE",
      state: "IA"
    },
    {
      city: "ARENA",
      state: "WI"
    },
    {
      city: "ARENAS VALLEY",
      state: "NM"
    },
    {
      city: "ARENDTSVILLE",
      state: "PA"
    },
    {
      city: "ARENZVILLE",
      state: "IL"
    },
    {
      city: "ARGENTA",
      state: "IL"
    },
    {
      city: "ARGILLITE",
      state: "KY"
    },
    {
      city: "ARGONIA",
      state: "KS"
    },
    {
      city: "ARGONNE",
      state: "WI"
    },
    {
      city: "ARGOS",
      state: "IN"
    },
    {
      city: "ARGUSVILLE",
      state: "ND"
    },
    {
      city: "ARGYLE",
      state: "FL"
    },
    {
      city: "ARGYLE",
      state: "GA"
    },
    {
      city: "ARGYLE",
      state: "IA"
    },
    {
      city: "ARGYLE",
      state: "MI"
    },
    {
      city: "ARGYLE",
      state: "MN"
    },
    {
      city: "ARGYLE",
      state: "MO"
    },
    {
      city: "ARGYLE",
      state: "NY"
    },
    {
      city: "ARGYLE",
      state: "TX"
    },
    {
      city: "ARGYLE",
      state: "WI"
    },
    {
      city: "ARIEL",
      state: "WA"
    },
    {
      city: "ARIMO",
      state: "ID"
    },
    {
      city: "ARION",
      state: "IA"
    },
    {
      city: "ARIPEKA",
      state: "FL"
    },
    {
      city: "ARISPE",
      state: "IA"
    },
    {
      city: "ARISTES",
      state: "PA"
    },
    {
      city: "ARITON",
      state: "AL"
    },
    {
      city: "ARIVACA",
      state: "AZ"
    },
    {
      city: "ARIZONA CITY",
      state: "AZ"
    },
    {
      city: "ARJAY",
      state: "KY"
    },
    {
      city: "ARK",
      state: "VA"
    },
    {
      city: "ARKADELPHIA",
      state: "AR"
    },
    {
      city: "ARKANSAS CITY",
      state: "AR"
    },
    {
      city: "ARKANSAS CITY",
      state: "KS"
    },
    {
      city: "ARKANSAW",
      state: "WI"
    },
    {
      city: "ARKDALE",
      state: "WI"
    },
    {
      city: "ARKOMA",
      state: "OK"
    },
    {
      city: "ARKPORT",
      state: "NY"
    },
    {
      city: "ARKVILLE",
      state: "NY"
    },
    {
      city: "ARLEE",
      state: "MT"
    },
    {
      city: "ARLEY",
      state: "AL"
    },
    {
      city: "ARLINGTON",
      state: "AL"
    },
    {
      city: "ARLINGTON",
      state: "AZ"
    },
    {
      city: "ARLINGTON",
      state: "GA"
    },
    {
      city: "ARLINGTON",
      state: "IA"
    },
    {
      city: "ARLINGTON",
      state: "IL"
    },
    {
      city: "ARLINGTON",
      state: "IN"
    },
    {
      city: "ARLINGTON",
      state: "KS"
    },
    {
      city: "ARLINGTON",
      state: "KY"
    },
    {
      city: "ARLINGTON",
      state: "MA"
    },
    {
      city: "ARLINGTON",
      state: "MN"
    },
    {
      city: "ARLINGTON",
      state: "NE"
    },
    {
      city: "ARLINGTON",
      state: "OH"
    },
    {
      city: "ARLINGTON",
      state: "OR"
    },
    {
      city: "ARLINGTON",
      state: "SD"
    },
    {
      city: "ARLINGTON",
      state: "TN"
    },
    {
      city: "ARLINGTON",
      state: "TX"
    },
    {
      city: "ARLINGTON",
      state: "VA"
    },
    {
      city: "ARLINGTON",
      state: "VT"
    },
    {
      city: "ARLINGTON",
      state: "WA"
    },
    {
      city: "ARLINGTON",
      state: "WI"
    },
    {
      city: "ARLINGTON HEIGHTS",
      state: "IL"
    },
    {
      city: "ARLINGTON HEIGHTS",
      state: "MA"
    },
    {
      city: "ARMA",
      state: "KS"
    },
    {
      city: "ARMADA",
      state: "MI"
    },
    {
      city: "ARMAGH",
      state: "PA"
    },
    {
      city: "ARMBRUST",
      state: "PA"
    },
    {
      city: "ARMINGTON",
      state: "IL"
    },
    {
      city: "ARMINTO",
      state: "WY"
    },
    {
      city: "ARMONA",
      state: "CA"
    },
    {
      city: "ARMONK",
      state: "NY"
    },
    {
      city: "ARMOREL",
      state: "AR"
    },
    {
      city: "ARMOUR",
      state: "SD"
    },
    {
      city: "ARMSTRONG",
      state: "IA"
    },
    {
      city: "ARMSTRONG",
      state: "IL"
    },
    {
      city: "ARMSTRONG",
      state: "MO"
    },
    {
      city: "ARMSTRONG",
      state: "TX"
    },
    {
      city: "ARMSTRONG CREEK",
      state: "WI"
    },
    {
      city: "ARMUCHEE",
      state: "GA"
    },
    {
      city: "ARNAUDVILLE",
      state: "LA"
    },
    {
      city: "ARNEGARD",
      state: "ND"
    },
    {
      city: "ARNETT",
      state: "OK"
    },
    {
      city: "ARNOLD",
      state: "CA"
    },
    {
      city: "ARNOLD",
      state: "KS"
    },
    {
      city: "ARNOLD",
      state: "MD"
    },
    {
      city: "ARNOLD",
      state: "MI"
    },
    {
      city: "ARNOLD",
      state: "MO"
    },
    {
      city: "ARNOLD",
      state: "NE"
    },
    {
      city: "ARNOLD AFB",
      state: "TN"
    },
    {
      city: "ARNOLDS PARK",
      state: "IA"
    },
    {
      city: "ARNOLDSBURG",
      state: "WV"
    },
    {
      city: "ARNOLDSVILLE",
      state: "GA"
    },
    {
      city: "ARNOT",
      state: "PA"
    },
    {
      city: "ARODA",
      state: "VA"
    },
    {
      city: "AROMA PARK",
      state: "IL"
    },
    {
      city: "AROMAS",
      state: "CA"
    },
    {
      city: "ARONA",
      state: "PA"
    },
    {
      city: "ARP",
      state: "TX"
    },
    {
      city: "ARPIN",
      state: "WI"
    },
    {
      city: "ARREY",
      state: "NM"
    },
    {
      city: "ARRIBA",
      state: "CO"
    },
    {
      city: "ARRINGTON",
      state: "TN"
    },
    {
      city: "ARRINGTON",
      state: "VA"
    },
    {
      city: "ARROW ROCK",
      state: "MO"
    },
    {
      city: "ARROWSMITH",
      state: "IL"
    },
    {
      city: "ARROYO GRANDE",
      state: "CA"
    },
    {
      city: "ARROYO HONDO",
      state: "NM"
    },
    {
      city: "ARROYO SECO",
      state: "NM"
    },
    {
      city: "ARTEMAS",
      state: "PA"
    },
    {
      city: "ARTEMUS",
      state: "KY"
    },
    {
      city: "ARTESIA",
      state: "CA"
    },
    {
      city: "ARTESIA",
      state: "MS"
    },
    {
      city: "ARTESIA",
      state: "NM"
    },
    {
      city: "ARTESIA WELLS",
      state: "TX"
    },
    {
      city: "ARTESIAN",
      state: "SD"
    },
    {
      city: "ARTHUR",
      state: "IA"
    },
    {
      city: "ARTHUR",
      state: "IL"
    },
    {
      city: "ARTHUR",
      state: "ND"
    },
    {
      city: "ARTHUR",
      state: "NE"
    },
    {
      city: "ARTHUR",
      state: "TN"
    },
    {
      city: "ARTHUR CITY",
      state: "TX"
    },
    {
      city: "ARTHURDALE",
      state: "WV"
    },
    {
      city: "ARTOIS",
      state: "CA"
    },
    {
      city: "ARVADA",
      state: "CO"
    },
    {
      city: "ARVADA",
      state: "WY"
    },
    {
      city: "ARVERNE",
      state: "NY"
    },
    {
      city: "ARVILLA",
      state: "ND"
    },
    {
      city: "ARVIN",
      state: "CA"
    },
    {
      city: "ARVONIA",
      state: "VA"
    },
    {
      city: "ARY",
      state: "KY"
    },
    {
      city: "ASBURY",
      state: "MO"
    },
    {
      city: "ASBURY",
      state: "NJ"
    },
    {
      city: "ASBURY",
      state: "WV"
    },
    {
      city: "ASBURY PARK",
      state: "NJ"
    },
    {
      city: "ASCUTNEY",
      state: "VT"
    },
    {
      city: "ASH",
      state: "NC"
    },
    {
      city: "ASH FLAT",
      state: "AR"
    },
    {
      city: "ASH FORK",
      state: "AZ"
    },
    {
      city: "ASH GROVE",
      state: "MO"
    },
    {
      city: "ASHAWAY",
      state: "RI"
    },
    {
      city: "ASHBURN",
      state: "GA"
    },
    {
      city: "ASHBURN",
      state: "VA"
    },
    {
      city: "ASHBURNHAM",
      state: "MA"
    },
    {
      city: "ASHBY",
      state: "MA"
    },
    {
      city: "ASHBY",
      state: "MN"
    },
    {
      city: "ASHBY",
      state: "NE"
    },
    {
      city: "ASHCAMP",
      state: "KY"
    },
    {
      city: "ASHDOWN",
      state: "AR"
    },
    {
      city: "ASHEBORO",
      state: "NC"
    },
    {
      city: "ASHER",
      state: "KY"
    },
    {
      city: "ASHER",
      state: "OK"
    },
    {
      city: "ASHERTON",
      state: "TX"
    },
    {
      city: "ASHEVILLE",
      state: "NC"
    },
    {
      city: "ASHFIELD",
      state: "MA"
    },
    {
      city: "ASHFIELD",
      state: "PA"
    },
    {
      city: "ASHFORD",
      state: "AL"
    },
    {
      city: "ASHFORD",
      state: "CT"
    },
    {
      city: "ASHFORD",
      state: "WA"
    },
    {
      city: "ASHFORD",
      state: "WV"
    },
    {
      city: "ASHIPPUN",
      state: "WI"
    },
    {
      city: "ASHKUM",
      state: "IL"
    },
    {
      city: "ASHLAND",
      state: "AL"
    },
    {
      city: "ASHLAND",
      state: "IL"
    },
    {
      city: "ASHLAND",
      state: "KS"
    },
    {
      city: "ASHLAND",
      state: "KY"
    },
    {
      city: "ASHLAND",
      state: "LA"
    },
    {
      city: "ASHLAND",
      state: "MA"
    },
    {
      city: "ASHLAND",
      state: "ME"
    },
    {
      city: "ASHLAND",
      state: "MO"
    },
    {
      city: "ASHLAND",
      state: "MS"
    },
    {
      city: "ASHLAND",
      state: "MT"
    },
    {
      city: "ASHLAND",
      state: "NE"
    },
    {
      city: "ASHLAND",
      state: "NH"
    },
    {
      city: "ASHLAND",
      state: "NY"
    },
    {
      city: "ASHLAND",
      state: "OH"
    },
    {
      city: "ASHLAND",
      state: "OR"
    },
    {
      city: "ASHLAND",
      state: "PA"
    },
    {
      city: "ASHLAND",
      state: "VA"
    },
    {
      city: "ASHLAND",
      state: "WI"
    },
    {
      city: "ASHLAND CITY",
      state: "TN"
    },
    {
      city: "ASHLEY",
      state: "IL"
    },
    {
      city: "ASHLEY",
      state: "IN"
    },
    {
      city: "ASHLEY",
      state: "MI"
    },
    {
      city: "ASHLEY",
      state: "ND"
    },
    {
      city: "ASHLEY",
      state: "OH"
    },
    {
      city: "ASHLEY FALLS",
      state: "MA"
    },
    {
      city: "ASHMORE",
      state: "IL"
    },
    {
      city: "ASHTABULA",
      state: "OH"
    },
    {
      city: "ASHTON",
      state: "IA"
    },
    {
      city: "ASHTON",
      state: "ID"
    },
    {
      city: "ASHTON",
      state: "IL"
    },
    {
      city: "ASHTON",
      state: "MD"
    },
    {
      city: "ASHTON",
      state: "NE"
    },
    {
      city: "ASHTON",
      state: "SD"
    },
    {
      city: "ASHTON",
      state: "WV"
    },
    {
      city: "ASHUELOT",
      state: "NH"
    },
    {
      city: "ASHVILLE",
      state: "AL"
    },
    {
      city: "ASHVILLE",
      state: "NY"
    },
    {
      city: "ASHVILLE",
      state: "OH"
    },
    {
      city: "ASHVILLE",
      state: "PA"
    },
    {
      city: "ASKOV",
      state: "MN"
    },
    {
      city: "ASOTIN",
      state: "WA"
    },
    {
      city: "ASPEN",
      state: "CO"
    },
    {
      city: "ASPERMONT",
      state: "TX"
    },
    {
      city: "ASPERS",
      state: "PA"
    },
    {
      city: "ASPINWALL",
      state: "IA"
    },
    {
      city: "ASSARIA",
      state: "KS"
    },
    {
      city: "ASSAWOMAN",
      state: "VA"
    },
    {
      city: "ASSONET",
      state: "MA"
    },
    {
      city: "ASSUMPTION",
      state: "IL"
    },
    {
      city: "ASTATULA",
      state: "FL"
    },
    {
      city: "ASTON",
      state: "PA"
    },
    {
      city: "ASTOR",
      state: "FL"
    },
    {
      city: "ASTORIA",
      state: "IL"
    },
    {
      city: "ASTORIA",
      state: "NY"
    },
    {
      city: "ASTORIA",
      state: "OR"
    },
    {
      city: "ASTORIA",
      state: "SD"
    },
    {
      city: "ATALISSA",
      state: "IA"
    },
    {
      city: "ATASCADERO",
      state: "CA"
    },
    {
      city: "ATASCOSA",
      state: "TX"
    },
    {
      city: "ATCHISON",
      state: "KS"
    },
    {
      city: "ATCO",
      state: "NJ"
    },
    {
      city: "ATGLEN",
      state: "PA"
    },
    {
      city: "ATHELSTANE",
      state: "WI"
    },
    {
      city: "ATHENA",
      state: "OR"
    },
    {
      city: "ATHENS",
      state: "AL"
    },
    {
      city: "ATHENS",
      state: "GA"
    },
    {
      city: "ATHENS",
      state: "IL"
    },
    {
      city: "ATHENS",
      state: "IN"
    },
    {
      city: "ATHENS",
      state: "LA"
    },
    {
      city: "ATHENS",
      state: "ME"
    },
    {
      city: "ATHENS",
      state: "MI"
    },
    {
      city: "ATHENS",
      state: "NY"
    },
    {
      city: "ATHENS",
      state: "OH"
    },
    {
      city: "ATHENS",
      state: "PA"
    },
    {
      city: "ATHENS",
      state: "TN"
    },
    {
      city: "ATHENS",
      state: "TX"
    },
    {
      city: "ATHENS",
      state: "WI"
    },
    {
      city: "ATHENS",
      state: "WV"
    },
    {
      city: "ATHERTON",
      state: "CA"
    },
    {
      city: "ATHOL",
      state: "ID"
    },
    {
      city: "ATHOL",
      state: "KS"
    },
    {
      city: "ATHOL",
      state: "MA"
    },
    {
      city: "ATHOL",
      state: "NY"
    },
    {
      city: "ATHOL SPRINGS",
      state: "NY"
    },
    {
      city: "ATKA",
      state: "AK"
    },
    {
      city: "ATKINS",
      state: "AR"
    },
    {
      city: "ATKINS",
      state: "IA"
    },
    {
      city: "ATKINS",
      state: "VA"
    },
    {
      city: "ATKINSON",
      state: "IL"
    },
    {
      city: "ATKINSON",
      state: "NC"
    },
    {
      city: "ATKINSON",
      state: "NE"
    },
    {
      city: "ATKINSON",
      state: "NH"
    },
    {
      city: "ATLANTA",
      state: "GA"
    },
    {
      city: "ATLANTA",
      state: "ID"
    },
    {
      city: "ATLANTA",
      state: "IL"
    },
    {
      city: "ATLANTA",
      state: "IN"
    },
    {
      city: "ATLANTA",
      state: "KS"
    },
    {
      city: "ATLANTA",
      state: "LA"
    },
    {
      city: "ATLANTA",
      state: "MI"
    },
    {
      city: "ATLANTA",
      state: "MO"
    },
    {
      city: "ATLANTA",
      state: "NE"
    },
    {
      city: "ATLANTA",
      state: "NY"
    },
    {
      city: "ATLANTA",
      state: "TX"
    },
    {
      city: "ATLANTIC",
      state: "IA"
    },
    {
      city: "ATLANTIC",
      state: "NC"
    },
    {
      city: "ATLANTIC",
      state: "PA"
    },
    {
      city: "ATLANTIC",
      state: "VA"
    },
    {
      city: "ATLANTIC BEACH",
      state: "FL"
    },
    {
      city: "ATLANTIC BEACH",
      state: "NC"
    },
    {
      city: "ATLANTIC BEACH",
      state: "NY"
    },
    {
      city: "ATLANTIC CITY",
      state: "NJ"
    },
    {
      city: "ATLANTIC HIGHLANDS",
      state: "NJ"
    },
    {
      city: "ATLANTIC MINE",
      state: "MI"
    },
    {
      city: "ATLAS",
      state: "MI"
    },
    {
      city: "ATLASBURG",
      state: "PA"
    },
    {
      city: "ATMORE",
      state: "AL"
    },
    {
      city: "ATOKA",
      state: "OK"
    },
    {
      city: "ATOKA",
      state: "TN"
    },
    {
      city: "ATQASUK",
      state: "AK"
    },
    {
      city: "ATTALLA",
      state: "AL"
    },
    {
      city: "ATTAPULGUS",
      state: "GA"
    },
    {
      city: "ATTICA",
      state: "IN"
    },
    {
      city: "ATTICA",
      state: "KS"
    },
    {
      city: "ATTICA",
      state: "MI"
    },
    {
      city: "ATTICA",
      state: "NY"
    },
    {
      city: "ATTICA",
      state: "OH"
    },
    {
      city: "ATTLEBORO",
      state: "MA"
    },
    {
      city: "ATTLEBORO FALLS",
      state: "MA"
    },
    {
      city: "ATWATER",
      state: "CA"
    },
    {
      city: "ATWATER",
      state: "MN"
    },
    {
      city: "ATWATER",
      state: "OH"
    },
    {
      city: "ATWOOD",
      state: "CA"
    },
    {
      city: "ATWOOD",
      state: "CO"
    },
    {
      city: "ATWOOD",
      state: "IL"
    },
    {
      city: "ATWOOD",
      state: "IN"
    },
    {
      city: "ATWOOD",
      state: "KS"
    },
    {
      city: "ATWOOD",
      state: "OK"
    },
    {
      city: "ATWOOD",
      state: "TN"
    },
    {
      city: "AU GRES",
      state: "MI"
    },
    {
      city: "AU SABLE FORKS",
      state: "NY"
    },
    {
      city: "AU TRAIN",
      state: "MI"
    },
    {
      city: "AUBERRY",
      state: "CA"
    },
    {
      city: "AUBREY",
      state: "AR"
    },
    {
      city: "AUBREY",
      state: "TX"
    },
    {
      city: "AUBURN",
      state: "AL"
    },
    {
      city: "AUBURN",
      state: "CA"
    },
    {
      city: "AUBURN",
      state: "GA"
    },
    {
      city: "AUBURN",
      state: "IA"
    },
    {
      city: "AUBURN",
      state: "IL"
    },
    {
      city: "AUBURN",
      state: "IN"
    },
    {
      city: "AUBURN",
      state: "KS"
    },
    {
      city: "AUBURN",
      state: "KY"
    },
    {
      city: "AUBURN",
      state: "MA"
    },
    {
      city: "AUBURN",
      state: "ME"
    },
    {
      city: "AUBURN",
      state: "MI"
    },
    {
      city: "AUBURN",
      state: "NE"
    },
    {
      city: "AUBURN",
      state: "NH"
    },
    {
      city: "AUBURN",
      state: "NY"
    },
    {
      city: "AUBURN",
      state: "PA"
    },
    {
      city: "AUBURN",
      state: "WA"
    },
    {
      city: "AUBURN",
      state: "WV"
    },
    {
      city: "AUBURN",
      state: "WY"
    },
    {
      city: "AUBURN HILLS",
      state: "MI"
    },
    {
      city: "AUBURN UNIVERSITY",
      state: "AL"
    },
    {
      city: "AUBURNDALE",
      state: "FL"
    },
    {
      city: "AUBURNDALE",
      state: "MA"
    },
    {
      city: "AUBURNDALE",
      state: "WI"
    },
    {
      city: "AUBURNTOWN",
      state: "TN"
    },
    {
      city: "AUDUBON",
      state: "IA"
    },
    {
      city: "AUDUBON",
      state: "MN"
    },
    {
      city: "AUDUBON",
      state: "NJ"
    },
    {
      city: "AUDUBON",
      state: "PA"
    },
    {
      city: "AUGUSTA",
      state: "AR"
    },
    {
      city: "AUGUSTA",
      state: "GA"
    },
    {
      city: "AUGUSTA",
      state: "IL"
    },
    {
      city: "AUGUSTA",
      state: "KS"
    },
    {
      city: "AUGUSTA",
      state: "KY"
    },
    {
      city: "AUGUSTA",
      state: "ME"
    },
    {
      city: "AUGUSTA",
      state: "MI"
    },
    {
      city: "AUGUSTA",
      state: "MO"
    },
    {
      city: "AUGUSTA",
      state: "MT"
    },
    {
      city: "AUGUSTA",
      state: "NJ"
    },
    {
      city: "AUGUSTA",
      state: "WI"
    },
    {
      city: "AUGUSTA",
      state: "WV"
    },
    {
      city: "AUGUSTA SPRINGS",
      state: "VA"
    },
    {
      city: "AUKE BAY",
      state: "AK"
    },
    {
      city: "AULANDER",
      state: "NC"
    },
    {
      city: "AULT",
      state: "CO"
    },
    {
      city: "AULTMAN",
      state: "PA"
    },
    {
      city: "AUMSVILLE",
      state: "OR"
    },
    {
      city: "AURELIA",
      state: "IA"
    },
    {
      city: "AURORA",
      state: "CO"
    },
    {
      city: "AURORA",
      state: "IA"
    },
    {
      city: "AURORA",
      state: "IL"
    },
    {
      city: "AURORA",
      state: "IN"
    },
    {
      city: "AURORA",
      state: "KS"
    },
    {
      city: "AURORA",
      state: "ME"
    },
    {
      city: "AURORA",
      state: "MN"
    },
    {
      city: "AURORA",
      state: "MO"
    },
    {
      city: "AURORA",
      state: "NC"
    },
    {
      city: "AURORA",
      state: "NE"
    },
    {
      city: "AURORA",
      state: "NY"
    },
    {
      city: "AURORA",
      state: "OH"
    },
    {
      city: "AURORA",
      state: "OR"
    },
    {
      city: "AURORA",
      state: "SD"
    },
    {
      city: "AURORA",
      state: "UT"
    },
    {
      city: "AURORA",
      state: "WV"
    },
    {
      city: "AUSTELL",
      state: "GA"
    },
    {
      city: "AUSTERLITZ",
      state: "NY"
    },
    {
      city: "AUSTIN",
      state: "AR"
    },
    {
      city: "AUSTIN",
      state: "CO"
    },
    {
      city: "AUSTIN",
      state: "IN"
    },
    {
      city: "AUSTIN",
      state: "KY"
    },
    {
      city: "AUSTIN",
      state: "MN"
    },
    {
      city: "AUSTIN",
      state: "NV"
    },
    {
      city: "AUSTIN",
      state: "PA"
    },
    {
      city: "AUSTIN",
      state: "TX"
    },
    {
      city: "AUSTINBURG",
      state: "OH"
    },
    {
      city: "AUSTINVILLE",
      state: "IA"
    },
    {
      city: "AUSTINVILLE",
      state: "VA"
    },
    {
      city: "AUSTWELL",
      state: "TX"
    },
    {
      city: "AUTAUGAVILLE",
      state: "AL"
    },
    {
      city: "AUTRYVILLE",
      state: "NC"
    },
    {
      city: "AUXIER",
      state: "KY"
    },
    {
      city: "AUXVASSE",
      state: "MO"
    },
    {
      city: "AVA",
      state: "IL"
    },
    {
      city: "AVA",
      state: "MO"
    },
    {
      city: "AVA",
      state: "NY"
    },
    {
      city: "AVA",
      state: "OH"
    },
    {
      city: "AVALON",
      state: "CA"
    },
    {
      city: "AVALON",
      state: "NJ"
    },
    {
      city: "AVALON",
      state: "TX"
    },
    {
      city: "AVALON",
      state: "WI"
    },
    {
      city: "AVANT",
      state: "OK"
    },
    {
      city: "AVAWAM",
      state: "KY"
    },
    {
      city: "AVELLA",
      state: "PA"
    },
    {
      city: "AVENAL",
      state: "CA"
    },
    {
      city: "AVENEL",
      state: "NJ"
    },
    {
      city: "AVENUE",
      state: "MD"
    },
    {
      city: "AVERA",
      state: "GA"
    },
    {
      city: "AVERILL",
      state: "VT"
    },
    {
      city: "AVERILL PARK",
      state: "NY"
    },
    {
      city: "AVERY",
      state: "CA"
    },
    {
      city: "AVERY",
      state: "ID"
    },
    {
      city: "AVERY",
      state: "TX"
    },
    {
      city: "AVERY ISLAND",
      state: "LA"
    },
    {
      city: "AVILA BEACH",
      state: "CA"
    },
    {
      city: "AVILLA",
      state: "IN"
    },
    {
      city: "AVILLA",
      state: "MO"
    },
    {
      city: "AVINGER",
      state: "TX"
    },
    {
      city: "AVIS",
      state: "PA"
    },
    {
      city: "AVISTON",
      state: "IL"
    },
    {
      city: "AVOCA",
      state: "IA"
    },
    {
      city: "AVOCA",
      state: "IN"
    },
    {
      city: "AVOCA",
      state: "MI"
    },
    {
      city: "AVOCA",
      state: "MN"
    },
    {
      city: "AVOCA",
      state: "NE"
    },
    {
      city: "AVOCA",
      state: "NY"
    },
    {
      city: "AVOCA",
      state: "TX"
    },
    {
      city: "AVOCA",
      state: "WI"
    },
    {
      city: "AVON",
      state: "CO"
    },
    {
      city: "AVON",
      state: "CT"
    },
    {
      city: "AVON",
      state: "IL"
    },
    {
      city: "AVON",
      state: "IN"
    },
    {
      city: "AVON",
      state: "MA"
    },
    {
      city: "AVON",
      state: "MN"
    },
    {
      city: "AVON",
      state: "MS"
    },
    {
      city: "AVON",
      state: "MT"
    },
    {
      city: "AVON",
      state: "NC"
    },
    {
      city: "AVON",
      state: "NY"
    },
    {
      city: "AVON",
      state: "OH"
    },
    {
      city: "AVON",
      state: "SD"
    },
    {
      city: "AVON BY THE SEA",
      state: "NJ"
    },
    {
      city: "AVON LAKE",
      state: "OH"
    },
    {
      city: "AVON PARK",
      state: "FL"
    },
    {
      city: "AVONDALE",
      state: "AZ"
    },
    {
      city: "AVONDALE",
      state: "CO"
    },
    {
      city: "AVONDALE",
      state: "PA"
    },
    {
      city: "AVONDALE",
      state: "WV"
    },
    {
      city: "AVONDALE ESTATES",
      state: "GA"
    },
    {
      city: "AVONMORE",
      state: "PA"
    },
    {
      city: "AWENDAW",
      state: "SC"
    },
    {
      city: "AXIS",
      state: "AL"
    },
    {
      city: "AXSON",
      state: "GA"
    },
    {
      city: "AXTELL",
      state: "KS"
    },
    {
      city: "AXTELL",
      state: "NE"
    },
    {
      city: "AXTELL",
      state: "TX"
    },
    {
      city: "AXTELL",
      state: "UT"
    },
    {
      city: "AXTON",
      state: "VA"
    },
    {
      city: "AYDEN",
      state: "NC"
    },
    {
      city: "AYDLETT",
      state: "NC"
    },
    {
      city: "AYER",
      state: "MA"
    },
    {
      city: "AYLETT",
      state: "VA"
    },
    {
      city: "AYNOR",
      state: "SC"
    },
    {
      city: "AYR",
      state: "ND"
    },
    {
      city: "AYR",
      state: "NE"
    },
    {
      city: "AYRSHIRE",
      state: "IA"
    },
    {
      city: "AZALEA",
      state: "OR"
    },
    {
      city: "AZALIA",
      state: "MI"
    },
    {
      city: "AZLE",
      state: "TX"
    },
    {
      city: "AZTEC",
      state: "NM"
    },
    {
      city: "AZUSA",
      state: "CA"
    },
    {
      city: "BABB",
      state: "MT"
    },
    {
      city: "BABBITT",
      state: "MN"
    },
    {
      city: "BABCOCK",
      state: "WI"
    },
    {
      city: "BABSON PARK",
      state: "FL"
    },
    {
      city: "BABSON PARK",
      state: "MA"
    },
    {
      city: "BABYLON",
      state: "NY"
    },
    {
      city: "BACKUS",
      state: "MN"
    },
    {
      city: "BACLIFF",
      state: "TX"
    },
    {
      city: "BACONTON",
      state: "GA"
    },
    {
      city: "BAD AXE",
      state: "MI"
    },
    {
      city: "BADEN",
      state: "PA"
    },
    {
      city: "BADGER",
      state: "CA"
    },
    {
      city: "BADGER",
      state: "IA"
    },
    {
      city: "BADGER",
      state: "MN"
    },
    {
      city: "BADGER",
      state: "SD"
    },
    {
      city: "BADIN",
      state: "NC"
    },
    {
      city: "BAGDAD",
      state: "AZ"
    },
    {
      city: "BAGDAD",
      state: "FL"
    },
    {
      city: "BAGDAD",
      state: "KY"
    },
    {
      city: "BAGGS",
      state: "WY"
    },
    {
      city: "BAGLEY",
      state: "IA"
    },
    {
      city: "BAGLEY",
      state: "MN"
    },
    {
      city: "BAGLEY",
      state: "WI"
    },
    {
      city: "BAGWELL",
      state: "TX"
    },
    {
      city: "BAHAMA",
      state: "NC"
    },
    {
      city: "BAILEY",
      state: "CO"
    },
    {
      city: "BAILEY",
      state: "MI"
    },
    {
      city: "BAILEY",
      state: "MS"
    },
    {
      city: "BAILEY",
      state: "NC"
    },
    {
      city: "BAILEY",
      state: "TX"
    },
    {
      city: "BAILEY ISLAND",
      state: "ME"
    },
    {
      city: "BAILEYS HARBOR",
      state: "WI"
    },
    {
      city: "BAILEYTON",
      state: "AL"
    },
    {
      city: "BAILEYVILLE",
      state: "IL"
    },
    {
      city: "BAILEYVILLE",
      state: "KS"
    },
    {
      city: "BAILEYVILLE",
      state: "ME"
    },
    {
      city: "BAINBRIDGE",
      state: "GA"
    },
    {
      city: "BAINBRIDGE",
      state: "IN"
    },
    {
      city: "BAINBRIDGE",
      state: "NY"
    },
    {
      city: "BAINBRIDGE",
      state: "OH"
    },
    {
      city: "BAINBRIDGE",
      state: "PA"
    },
    {
      city: "BAINBRIDGE ISLAND",
      state: "WA"
    },
    {
      city: "BAINVILLE",
      state: "MT"
    },
    {
      city: "BAIRD",
      state: "TX"
    },
    {
      city: "BAIRDFORD",
      state: "PA"
    },
    {
      city: "BAIROIL",
      state: "WY"
    },
    {
      city: "BAKER",
      state: "CA"
    },
    {
      city: "BAKER",
      state: "FL"
    },
    {
      city: "BAKER",
      state: "LA"
    },
    {
      city: "BAKER",
      state: "MT"
    },
    {
      city: "BAKER",
      state: "NV"
    },
    {
      city: "BAKER",
      state: "WV"
    },
    {
      city: "BAKER CITY",
      state: "OR"
    },
    {
      city: "BAKERS MILLS",
      state: "NY"
    },
    {
      city: "BAKERSFIELD",
      state: "CA"
    },
    {
      city: "BAKERSFIELD",
      state: "MO"
    },
    {
      city: "BAKERSFIELD",
      state: "VT"
    },
    {
      city: "BAKERSTOWN",
      state: "PA"
    },
    {
      city: "BAKERSVILLE",
      state: "NC"
    },
    {
      city: "BAKERSVILLE",
      state: "OH"
    },
    {
      city: "BAKEWELL",
      state: "TN"
    },
    {
      city: "BALA CYNWYD",
      state: "PA"
    },
    {
      city: "BALATON",
      state: "MN"
    },
    {
      city: "BALCH SPRINGS",
      state: "TX"
    },
    {
      city: "BALD KNOB",
      state: "AR"
    },
    {
      city: "BALDWIN",
      state: "GA"
    },
    {
      city: "BALDWIN",
      state: "IA"
    },
    {
      city: "BALDWIN",
      state: "IL"
    },
    {
      city: "BALDWIN",
      state: "LA"
    },
    {
      city: "BALDWIN",
      state: "MD"
    },
    {
      city: "BALDWIN",
      state: "MI"
    },
    {
      city: "BALDWIN",
      state: "ND"
    },
    {
      city: "BALDWIN",
      state: "NY"
    },
    {
      city: "BALDWIN",
      state: "WI"
    },
    {
      city: "BALDWIN CITY",
      state: "KS"
    },
    {
      city: "BALDWIN PARK",
      state: "CA"
    },
    {
      city: "BALDWIN PLACE",
      state: "NY"
    },
    {
      city: "BALDWINSVILLE",
      state: "NY"
    },
    {
      city: "BALDWINVILLE",
      state: "MA"
    },
    {
      city: "BALDWYN",
      state: "MS"
    },
    {
      city: "BALFOUR",
      state: "ND"
    },
    {
      city: "BALKO",
      state: "OK"
    },
    {
      city: "BALL",
      state: "LA"
    },
    {
      city: "BALL GROUND",
      state: "GA"
    },
    {
      city: "BALLANTINE",
      state: "MT"
    },
    {
      city: "BALLARD",
      state: "WV"
    },
    {
      city: "BALLENTINE",
      state: "SC"
    },
    {
      city: "BALLICO",
      state: "CA"
    },
    {
      city: "BALLINGER",
      state: "TX"
    },
    {
      city: "BALLSTON LAKE",
      state: "NY"
    },
    {
      city: "BALLSTON SPA",
      state: "NY"
    },
    {
      city: "BALLWIN",
      state: "MO"
    },
    {
      city: "BALLY",
      state: "PA"
    },
    {
      city: "BALM",
      state: "FL"
    },
    {
      city: "BALMORHEA",
      state: "TX"
    },
    {
      city: "BALSAM",
      state: "NC"
    },
    {
      city: "BALSAM GROVE",
      state: "NC"
    },
    {
      city: "BALSAM LAKE",
      state: "WI"
    },
    {
      city: "BALTA",
      state: "ND"
    },
    {
      city: "BALTIC",
      state: "CT"
    },
    {
      city: "BALTIC",
      state: "OH"
    },
    {
      city: "BALTIC",
      state: "SD"
    },
    {
      city: "BALTIMORE",
      state: "MD"
    },
    {
      city: "BALTIMORE",
      state: "OH"
    },
    {
      city: "BAMBERG",
      state: "SC"
    },
    {
      city: "BANCROFT",
      state: "IA"
    },
    {
      city: "BANCROFT",
      state: "ID"
    },
    {
      city: "BANCROFT",
      state: "MI"
    },
    {
      city: "BANCROFT",
      state: "NE"
    },
    {
      city: "BANCROFT",
      state: "WI"
    },
    {
      city: "BANCROFT",
      state: "WV"
    },
    {
      city: "BANDANA",
      state: "KY"
    },
    {
      city: "BANDERA",
      state: "TX"
    },
    {
      city: "BANDON",
      state: "OR"
    },
    {
      city: "BANDY",
      state: "VA"
    },
    {
      city: "BANGALL",
      state: "NY"
    },
    {
      city: "BANGOR",
      state: "CA"
    },
    {
      city: "BANGOR",
      state: "ME"
    },
    {
      city: "BANGOR",
      state: "MI"
    },
    {
      city: "BANGOR",
      state: "PA"
    },
    {
      city: "BANGOR",
      state: "WI"
    },
    {
      city: "BANGS",
      state: "TX"
    },
    {
      city: "BANKS",
      state: "AL"
    },
    {
      city: "BANKS",
      state: "AR"
    },
    {
      city: "BANKS",
      state: "ID"
    },
    {
      city: "BANKS",
      state: "OR"
    },
    {
      city: "BANKSTON",
      state: "AL"
    },
    {
      city: "BANNER",
      state: "KY"
    },
    {
      city: "BANNER",
      state: "MS"
    },
    {
      city: "BANNER",
      state: "WY"
    },
    {
      city: "BANNER ELK",
      state: "NC"
    },
    {
      city: "BANNING",
      state: "CA"
    },
    {
      city: "BANNISTER",
      state: "MI"
    },
    {
      city: "BANNOCK",
      state: "OH"
    },
    {
      city: "BANQUETE",
      state: "TX"
    },
    {
      city: "BANTAM",
      state: "CT"
    },
    {
      city: "BAPCHULE",
      state: "AZ"
    },
    {
      city: "BAPTISTOWN",
      state: "NJ"
    },
    {
      city: "BAR HARBOR",
      state: "ME"
    },
    {
      city: "BAR MILLS",
      state: "ME"
    },
    {
      city: "BARABOO",
      state: "WI"
    },
    {
      city: "BARAGA",
      state: "MI"
    },
    {
      city: "BARATARIA",
      state: "LA"
    },
    {
      city: "BARBEAU",
      state: "MI"
    },
    {
      city: "BARBERTON",
      state: "OH"
    },
    {
      city: "BARBERVILLE",
      state: "FL"
    },
    {
      city: "BARBOURSVILLE",
      state: "VA"
    },
    {
      city: "BARBOURSVILLE",
      state: "WV"
    },
    {
      city: "BARBOURVILLE",
      state: "KY"
    },
    {
      city: "BARCLAY",
      state: "MD"
    },
    {
      city: "BARCO",
      state: "NC"
    },
    {
      city: "BARD",
      state: "CA"
    },
    {
      city: "BARD",
      state: "NM"
    },
    {
      city: "BARDSTOWN",
      state: "KY"
    },
    {
      city: "BARDWELL",
      state: "KY"
    },
    {
      city: "BARDWELL",
      state: "TX"
    },
    {
      city: "BARGERSVILLE",
      state: "IN"
    },
    {
      city: "BARHAMSVILLE",
      state: "VA"
    },
    {
      city: "BARING",
      state: "MO"
    },
    {
      city: "BARIUM SPRINGS",
      state: "NC"
    },
    {
      city: "BARK RIVER",
      state: "MI"
    },
    {
      city: "BARKER",
      state: "NY"
    },
    {
      city: "BARKER",
      state: "TX"
    },
    {
      city: "BARKHAMSTED",
      state: "CT"
    },
    {
      city: "BARKSDALE",
      state: "TX"
    },
    {
      city: "BARKSDALE AFB",
      state: "LA"
    },
    {
      city: "BARLING",
      state: "AR"
    },
    {
      city: "BARLOW",
      state: "KY"
    },
    {
      city: "BARLOW",
      state: "OH"
    },
    {
      city: "BARNARD",
      state: "KS"
    },
    {
      city: "BARNARD",
      state: "MO"
    },
    {
      city: "BARNARD",
      state: "VT"
    },
    {
      city: "BARNARDSVILLE",
      state: "NC"
    },
    {
      city: "BARNEGAT",
      state: "NJ"
    },
    {
      city: "BARNEGAT LIGHT",
      state: "NJ"
    },
    {
      city: "BARNES",
      state: "KS"
    },
    {
      city: "BARNES CITY",
      state: "IA"
    },
    {
      city: "BARNESTON",
      state: "NE"
    },
    {
      city: "BARNESVILLE",
      state: "GA"
    },
    {
      city: "BARNESVILLE",
      state: "MD"
    },
    {
      city: "BARNESVILLE",
      state: "MN"
    },
    {
      city: "BARNESVILLE",
      state: "NC"
    },
    {
      city: "BARNESVILLE",
      state: "OH"
    },
    {
      city: "BARNESVILLE",
      state: "PA"
    },
    {
      city: "BARNET",
      state: "VT"
    },
    {
      city: "BARNETT",
      state: "MO"
    },
    {
      city: "BARNEVELD",
      state: "NY"
    },
    {
      city: "BARNEVELD",
      state: "WI"
    },
    {
      city: "BARNEY",
      state: "GA"
    },
    {
      city: "BARNEY",
      state: "ND"
    },
    {
      city: "BARNHART",
      state: "MO"
    },
    {
      city: "BARNHART",
      state: "TX"
    },
    {
      city: "BARNHILL",
      state: "IL"
    },
    {
      city: "BARNSDALL",
      state: "OK"
    },
    {
      city: "BARNSTABLE",
      state: "MA"
    },
    {
      city: "BARNSTEAD",
      state: "NH"
    },
    {
      city: "BARNUM",
      state: "IA"
    },
    {
      city: "BARNUM",
      state: "MN"
    },
    {
      city: "BARNWELL",
      state: "SC"
    },
    {
      city: "BARODA",
      state: "MI"
    },
    {
      city: "BARRACKVILLE",
      state: "WV"
    },
    {
      city: "BARRE",
      state: "MA"
    },
    {
      city: "BARRE",
      state: "VT"
    },
    {
      city: "BARREN SPRINGS",
      state: "VA"
    },
    {
      city: "BARRETT",
      state: "MN"
    },
    {
      city: "BARRINGTON",
      state: "IL"
    },
    {
      city: "BARRINGTON",
      state: "NH"
    },
    {
      city: "BARRINGTON",
      state: "NJ"
    },
    {
      city: "BARRINGTON",
      state: "RI"
    },
    {
      city: "BARRON",
      state: "WI"
    },
    {
      city: "BARRONETT",
      state: "WI"
    },
    {
      city: "BARROW",
      state: "AK"
    },
    {
      city: "BARRY",
      state: "IL"
    },
    {
      city: "BARRY",
      state: "MN"
    },
    {
      city: "BARRY",
      state: "TX"
    },
    {
      city: "BARRYTON",
      state: "MI"
    },
    {
      city: "BARRYTOWN",
      state: "NY"
    },
    {
      city: "BARRYVILLE",
      state: "NY"
    },
    {
      city: "BARSTOW",
      state: "CA"
    },
    {
      city: "BARSTOW",
      state: "MD"
    },
    {
      city: "BARSTOW",
      state: "TX"
    },
    {
      city: "BART",
      state: "PA"
    },
    {
      city: "BARTELSO",
      state: "IL"
    },
    {
      city: "BARTLESVILLE",
      state: "OK"
    },
    {
      city: "BARTLETT",
      state: "IL"
    },
    {
      city: "BARTLETT",
      state: "KS"
    },
    {
      city: "BARTLETT",
      state: "NE"
    },
    {
      city: "BARTLETT",
      state: "NH"
    },
    {
      city: "BARTLETT",
      state: "OH"
    },
    {
      city: "BARTLETT",
      state: "TX"
    },
    {
      city: "BARTLEY",
      state: "NE"
    },
    {
      city: "BARTLEY",
      state: "WV"
    },
    {
      city: "BARTO",
      state: "PA"
    },
    {
      city: "BARTON",
      state: "AR"
    },
    {
      city: "BARTON",
      state: "MD"
    },
    {
      city: "BARTON",
      state: "NY"
    },
    {
      city: "BARTON",
      state: "OH"
    },
    {
      city: "BARTON",
      state: "VT"
    },
    {
      city: "BARTON CITY",
      state: "MI"
    },
    {
      city: "BARTONSVILLE",
      state: "PA"
    },
    {
      city: "BARTOW",
      state: "FL"
    },
    {
      city: "BARTOW",
      state: "GA"
    },
    {
      city: "BARTOW",
      state: "WV"
    },
    {
      city: "BARWICK",
      state: "GA"
    },
    {
      city: "BASALT",
      state: "CO"
    },
    {
      city: "BASCO",
      state: "IL"
    },
    {
      city: "BASCOM",
      state: "FL"
    },
    {
      city: "BASCOM",
      state: "OH"
    },
    {
      city: "BASEHOR",
      state: "KS"
    },
    {
      city: "BASILE",
      state: "LA"
    },
    {
      city: "BASIN",
      state: "MT"
    },
    {
      city: "BASIN",
      state: "WY"
    },
    {
      city: "BASKERVILLE",
      state: "VA"
    },
    {
      city: "BASKIN",
      state: "LA"
    },
    {
      city: "BASKING RIDGE",
      state: "NJ"
    },
    {
      city: "BASOM",
      state: "NY"
    },
    {
      city: "BASS HARBOR",
      state: "ME"
    },
    {
      city: "BASS LAKE",
      state: "CA"
    },
    {
      city: "BASSETT",
      state: "AR"
    },
    {
      city: "BASSETT",
      state: "NE"
    },
    {
      city: "BASSETT",
      state: "VA"
    },
    {
      city: "BASSETT",
      state: "WI"
    },
    {
      city: "BASSFIELD",
      state: "MS"
    },
    {
      city: "BASTIAN",
      state: "VA"
    },
    {
      city: "BASTROP",
      state: "LA"
    },
    {
      city: "BASTROP",
      state: "TX"
    },
    {
      city: "BASYE",
      state: "VA"
    },
    {
      city: "BAT CAVE",
      state: "NC"
    },
    {
      city: "BATAVIA",
      state: "IA"
    },
    {
      city: "BATAVIA",
      state: "IL"
    },
    {
      city: "BATAVIA",
      state: "NY"
    },
    {
      city: "BATAVIA",
      state: "OH"
    },
    {
      city: "BATCHELOR",
      state: "LA"
    },
    {
      city: "BATCHTOWN",
      state: "IL"
    },
    {
      city: "BATES",
      state: "OR"
    },
    {
      city: "BATES CITY",
      state: "MO"
    },
    {
      city: "BATESBURG",
      state: "SC"
    },
    {
      city: "BATESLAND",
      state: "SD"
    },
    {
      city: "BATESVILLE",
      state: "AR"
    },
    {
      city: "BATESVILLE",
      state: "IN"
    },
    {
      city: "BATESVILLE",
      state: "MS"
    },
    {
      city: "BATESVILLE",
      state: "TX"
    },
    {
      city: "BATESVILLE",
      state: "VA"
    },
    {
      city: "BATH",
      state: "IL"
    },
    {
      city: "BATH",
      state: "IN"
    },
    {
      city: "BATH",
      state: "ME"
    },
    {
      city: "BATH",
      state: "MI"
    },
    {
      city: "BATH",
      state: "NC"
    },
    {
      city: "BATH",
      state: "NH"
    },
    {
      city: "BATH",
      state: "NY"
    },
    {
      city: "BATH",
      state: "OH"
    },
    {
      city: "BATH",
      state: "PA"
    },
    {
      city: "BATH",
      state: "SC"
    },
    {
      city: "BATH",
      state: "SD"
    },
    {
      city: "BATH SPRINGS",
      state: "TN"
    },
    {
      city: "BATHGATE",
      state: "ND"
    },
    {
      city: "BATON ROUGE",
      state: "LA"
    },
    {
      city: "BATSON",
      state: "TX"
    },
    {
      city: "BATTERY PARK",
      state: "VA"
    },
    {
      city: "BATTIEST",
      state: "OK"
    },
    {
      city: "BATTLE CREEK",
      state: "IA"
    },
    {
      city: "BATTLE CREEK",
      state: "MI"
    },
    {
      city: "BATTLE CREEK",
      state: "NE"
    },
    {
      city: "BATTLE GROUND",
      state: "IN"
    },
    {
      city: "BATTLE GROUND",
      state: "WA"
    },
    {
      city: "BATTLE LAKE",
      state: "MN"
    },
    {
      city: "BATTLE MOUNTAIN",
      state: "NV"
    },
    {
      city: "BATTLEBORO",
      state: "NC"
    },
    {
      city: "BATTLEMENT MESA",
      state: "CO"
    },
    {
      city: "BATTLETOWN",
      state: "KY"
    },
    {
      city: "BAUDETTE",
      state: "MN"
    },
    {
      city: "BAUXITE",
      state: "AR"
    },
    {
      city: "BAXLEY",
      state: "GA"
    },
    {
      city: "BAXTER",
      state: "IA"
    },
    {
      city: "BAXTER",
      state: "KY"
    },
    {
      city: "BAXTER",
      state: "MN"
    },
    {
      city: "BAXTER",
      state: "TN"
    },
    {
      city: "BAXTER",
      state: "WV"
    },
    {
      city: "BAXTER SPRINGS",
      state: "KS"
    },
    {
      city: "BAY",
      state: "AR"
    },
    {
      city: "BAY CENTER",
      state: "WA"
    },
    {
      city: "BAY CITY",
      state: "MI"
    },
    {
      city: "BAY CITY",
      state: "OR"
    },
    {
      city: "BAY CITY",
      state: "TX"
    },
    {
      city: "BAY CITY",
      state: "WI"
    },
    {
      city: "BAY MINETTE",
      state: "AL"
    },
    {
      city: "BAY PINES",
      state: "FL"
    },
    {
      city: "BAY PORT",
      state: "MI"
    },
    {
      city: "BAY SAINT LOUIS",
      state: "MS"
    },
    {
      city: "BAY SHORE",
      state: "MI"
    },
    {
      city: "BAY SHORE",
      state: "NY"
    },
    {
      city: "BAY SPRINGS",
      state: "MS"
    },
    {
      city: "BAY VILLAGE",
      state: "OH"
    },
    {
      city: "BAYARD",
      state: "IA"
    },
    {
      city: "BAYARD",
      state: "NE"
    },
    {
      city: "BAYARD",
      state: "NM"
    },
    {
      city: "BAYARD",
      state: "WV"
    },
    {
      city: "BAYBORO",
      state: "NC"
    },
    {
      city: "BAYFIELD",
      state: "CO"
    },
    {
      city: "BAYFIELD",
      state: "WI"
    },
    {
      city: "BAYLIS",
      state: "IL"
    },
    {
      city: "BAYONNE",
      state: "NJ"
    },
    {
      city: "BAYOU LA BATRE",
      state: "AL"
    },
    {
      city: "BAYPORT",
      state: "MN"
    },
    {
      city: "BAYPORT",
      state: "NY"
    },
    {
      city: "BAYSIDE",
      state: "CA"
    },
    {
      city: "BAYSIDE",
      state: "NY"
    },
    {
      city: "BAYSIDE",
      state: "TX"
    },
    {
      city: "BAYTOWN",
      state: "TX"
    },
    {
      city: "BAYVIEW",
      state: "ID"
    },
    {
      city: "BAYVILLE",
      state: "NJ"
    },
    {
      city: "BAYVILLE",
      state: "NY"
    },
    {
      city: "BAZINE",
      state: "KS"
    },
    {
      city: "BEACH",
      state: "ND"
    },
    {
      city: "BEACH CITY",
      state: "OH"
    },
    {
      city: "BEACH HAVEN",
      state: "NJ"
    },
    {
      city: "BEACH HAVEN",
      state: "PA"
    },
    {
      city: "BEACH LAKE",
      state: "PA"
    },
    {
      city: "BEACHWOOD",
      state: "NJ"
    },
    {
      city: "BEACHWOOD",
      state: "OH"
    },
    {
      city: "BEACON",
      state: "IA"
    },
    {
      city: "BEACON",
      state: "NY"
    },
    {
      city: "BEACON FALLS",
      state: "CT"
    },
    {
      city: "BEALE AFB",
      state: "CA"
    },
    {
      city: "BEALETON",
      state: "VA"
    },
    {
      city: "BEALLSVILLE",
      state: "MD"
    },
    {
      city: "BEALLSVILLE",
      state: "OH"
    },
    {
      city: "BEALLSVILLE",
      state: "PA"
    },
    {
      city: "BEALS",
      state: "ME"
    },
    {
      city: "BEAMAN",
      state: "IA"
    },
    {
      city: "BEAN STATION",
      state: "TN"
    },
    {
      city: "BEAR",
      state: "DE"
    },
    {
      city: "BEAR BRANCH",
      state: "KY"
    },
    {
      city: "BEAR CREEK",
      state: "AL"
    },
    {
      city: "BEAR CREEK",
      state: "NC"
    },
    {
      city: "BEAR CREEK",
      state: "PA"
    },
    {
      city: "BEAR CREEK",
      state: "WI"
    },
    {
      city: "BEAR LAKE",
      state: "MI"
    },
    {
      city: "BEAR LAKE",
      state: "PA"
    },
    {
      city: "BEAR MOUNTAIN",
      state: "NY"
    },
    {
      city: "BEAR RIVER CITY",
      state: "UT"
    },
    {
      city: "BEARCREEK",
      state: "MT"
    },
    {
      city: "BEARDEN",
      state: "AR"
    },
    {
      city: "BEARDSLEY",
      state: "MN"
    },
    {
      city: "BEARDSTOWN",
      state: "IL"
    },
    {
      city: "BEARSVILLE",
      state: "NY"
    },
    {
      city: "BEASLEY",
      state: "TX"
    },
    {
      city: "BEASON",
      state: "IL"
    },
    {
      city: "BEATRICE",
      state: "AL"
    },
    {
      city: "BEATRICE",
      state: "NE"
    },
    {
      city: "BEATTIE",
      state: "KS"
    },
    {
      city: "BEATTY",
      state: "NV"
    },
    {
      city: "BEATTY",
      state: "OR"
    },
    {
      city: "BEATTYVILLE",
      state: "KY"
    },
    {
      city: "BEAUFORT",
      state: "MO"
    },
    {
      city: "BEAUFORT",
      state: "NC"
    },
    {
      city: "BEAUFORT",
      state: "SC"
    },
    {
      city: "BEAUMONT",
      state: "CA"
    },
    {
      city: "BEAUMONT",
      state: "KS"
    },
    {
      city: "BEAUMONT",
      state: "KY"
    },
    {
      city: "BEAUMONT",
      state: "MS"
    },
    {
      city: "BEAUMONT",
      state: "TX"
    },
    {
      city: "BEAUTY",
      state: "KY"
    },
    {
      city: "BEAVER",
      state: "IA"
    },
    {
      city: "BEAVER",
      state: "KY"
    },
    {
      city: "BEAVER",
      state: "OH"
    },
    {
      city: "BEAVER",
      state: "OK"
    },
    {
      city: "BEAVER",
      state: "OR"
    },
    {
      city: "BEAVER",
      state: "PA"
    },
    {
      city: "BEAVER",
      state: "UT"
    },
    {
      city: "BEAVER",
      state: "WA"
    },
    {
      city: "BEAVER",
      state: "WV"
    },
    {
      city: "BEAVER BAY",
      state: "MN"
    },
    {
      city: "BEAVER CITY",
      state: "NE"
    },
    {
      city: "BEAVER CREEK",
      state: "MN"
    },
    {
      city: "BEAVER CROSSING",
      state: "NE"
    },
    {
      city: "BEAVER DAM",
      state: "KY"
    },
    {
      city: "BEAVER DAM",
      state: "WI"
    },
    {
      city: "BEAVER DAMS",
      state: "NY"
    },
    {
      city: "BEAVER FALLS",
      state: "NY"
    },
    {
      city: "BEAVER FALLS",
      state: "PA"
    },
    {
      city: "BEAVER ISLAND",
      state: "MI"
    },
    {
      city: "BEAVER MEADOWS",
      state: "PA"
    },
    {
      city: "BEAVER SPRINGS",
      state: "PA"
    },
    {
      city: "BEAVERCREEK",
      state: "OR"
    },
    {
      city: "BEAVERDALE",
      state: "PA"
    },
    {
      city: "BEAVERDAM",
      state: "OH"
    },
    {
      city: "BEAVERDAM",
      state: "VA"
    },
    {
      city: "BEAVERTON",
      state: "AL"
    },
    {
      city: "BEAVERTON",
      state: "MI"
    },
    {
      city: "BEAVERTON",
      state: "OR"
    },
    {
      city: "BEAVERTOWN",
      state: "PA"
    },
    {
      city: "BEAVERVILLE",
      state: "IL"
    },
    {
      city: "BECCARIA",
      state: "PA"
    },
    {
      city: "BECHTELSVILLE",
      state: "PA"
    },
    {
      city: "BECKEMEYER",
      state: "IL"
    },
    {
      city: "BECKER",
      state: "MN"
    },
    {
      city: "BECKER",
      state: "MS"
    },
    {
      city: "BECKET",
      state: "MA"
    },
    {
      city: "BECKLEY",
      state: "WV"
    },
    {
      city: "BECKVILLE",
      state: "TX"
    },
    {
      city: "BECKWOURTH",
      state: "CA"
    },
    {
      city: "BEDFORD",
      state: "IA"
    },
    {
      city: "BEDFORD",
      state: "IN"
    },
    {
      city: "BEDFORD",
      state: "KY"
    },
    {
      city: "BEDFORD",
      state: "MA"
    },
    {
      city: "BEDFORD",
      state: "NH"
    },
    {
      city: "BEDFORD",
      state: "NY"
    },
    {
      city: "BEDFORD",
      state: "OH"
    },
    {
      city: "BEDFORD",
      state: "PA"
    },
    {
      city: "BEDFORD",
      state: "TX"
    },
    {
      city: "BEDFORD",
      state: "VA"
    },
    {
      city: "BEDFORD",
      state: "WY"
    },
    {
      city: "BEDFORD HILLS",
      state: "NY"
    },
    {
      city: "BEDFORD PARK",
      state: "IL"
    },
    {
      city: "BEDIAS",
      state: "TX"
    },
    {
      city: "BEDMINSTER",
      state: "NJ"
    },
    {
      city: "BEDMINSTER",
      state: "PA"
    },
    {
      city: "BEE",
      state: "NE"
    },
    {
      city: "BEE",
      state: "VA"
    },
    {
      city: "BEE BRANCH",
      state: "AR"
    },
    {
      city: "BEE SPRING",
      state: "KY"
    },
    {
      city: "BEEBE",
      state: "AR"
    },
    {
      city: "BEECH BLUFF",
      state: "TN"
    },
    {
      city: "BEECH BOTTOM",
      state: "WV"
    },
    {
      city: "BEECH CREEK",
      state: "KY"
    },
    {
      city: "BEECH CREEK",
      state: "PA"
    },
    {
      city: "BEECH GROVE",
      state: "AR"
    },
    {
      city: "BEECH GROVE",
      state: "IN"
    },
    {
      city: "BEECH GROVE",
      state: "KY"
    },
    {
      city: "BEECH ISLAND",
      state: "SC"
    },
    {
      city: "BEECHER",
      state: "IL"
    },
    {
      city: "BEECHER CITY",
      state: "IL"
    },
    {
      city: "BEECHER FALLS",
      state: "VT"
    },
    {
      city: "BEECHGROVE",
      state: "TN"
    },
    {
      city: "BEECHMONT",
      state: "KY"
    },
    {
      city: "BEEDEVILLE",
      state: "AR"
    },
    {
      city: "BEELER",
      state: "KS"
    },
    {
      city: "BEEMER",
      state: "NE"
    },
    {
      city: "BEERSHEBA SPRINGS",
      state: "TN"
    },
    {
      city: "BEESON",
      state: "WV"
    },
    {
      city: "BEETOWN",
      state: "WI"
    },
    {
      city: "BEEVILLE",
      state: "TX"
    },
    {
      city: "BEGGS",
      state: "OK"
    },
    {
      city: "BEIRNE",
      state: "AR"
    },
    {
      city: "BEJOU",
      state: "MN"
    },
    {
      city: "BEL AIR",
      state: "MD"
    },
    {
      city: "BEL ALTON",
      state: "MD"
    },
    {
      city: "BELCAMP",
      state: "MD"
    },
    {
      city: "BELCHER",
      state: "KY"
    },
    {
      city: "BELCHER",
      state: "LA"
    },
    {
      city: "BELCHERTOWN",
      state: "MA"
    },
    {
      city: "BELCOURT",
      state: "ND"
    },
    {
      city: "BELDEN",
      state: "CA"
    },
    {
      city: "BELDEN",
      state: "MS"
    },
    {
      city: "BELDEN",
      state: "NE"
    },
    {
      city: "BELDENVILLE",
      state: "WI"
    },
    {
      city: "BELDING",
      state: "MI"
    },
    {
      city: "BELEN",
      state: "NM"
    },
    {
      city: "BELEWS CREEK",
      state: "NC"
    },
    {
      city: "BELFAIR",
      state: "WA"
    },
    {
      city: "BELFAST",
      state: "ME"
    },
    {
      city: "BELFAST",
      state: "NY"
    },
    {
      city: "BELFAST",
      state: "TN"
    },
    {
      city: "BELFIELD",
      state: "ND"
    },
    {
      city: "BELFORD",
      state: "NJ"
    },
    {
      city: "BELFRY",
      state: "KY"
    },
    {
      city: "BELFRY",
      state: "MT"
    },
    {
      city: "BELGIUM",
      state: "WI"
    },
    {
      city: "BELGRADE",
      state: "ME"
    },
    {
      city: "BELGRADE",
      state: "MN"
    },
    {
      city: "BELGRADE",
      state: "MO"
    },
    {
      city: "BELGRADE",
      state: "MT"
    },
    {
      city: "BELGRADE",
      state: "NE"
    },
    {
      city: "BELGRADE LAKES",
      state: "ME"
    },
    {
      city: "BELHAVEN",
      state: "NC"
    },
    {
      city: "BELINGTON",
      state: "WV"
    },
    {
      city: "BELK",
      state: "AL"
    },
    {
      city: "BELKNAP",
      state: "IL"
    },
    {
      city: "BELL",
      state: "CA"
    },
    {
      city: "BELL",
      state: "FL"
    },
    {
      city: "BELL BUCKLE",
      state: "TN"
    },
    {
      city: "BELL CITY",
      state: "LA"
    },
    {
      city: "BELL CITY",
      state: "MO"
    },
    {
      city: "BELL GARDENS",
      state: "CA"
    },
    {
      city: "BELLA VISTA",
      state: "AR"
    },
    {
      city: "BELLA VISTA",
      state: "CA"
    },
    {
      city: "BELLAIRE",
      state: "MI"
    },
    {
      city: "BELLAIRE",
      state: "OH"
    },
    {
      city: "BELLAIRE",
      state: "TX"
    },
    {
      city: "BELLAMY",
      state: "AL"
    },
    {
      city: "BELLARTHUR",
      state: "NC"
    },
    {
      city: "BELLBROOK",
      state: "OH"
    },
    {
      city: "BELLE",
      state: "MO"
    },
    {
      city: "BELLE",
      state: "WV"
    },
    {
      city: "BELLE CENTER",
      state: "OH"
    },
    {
      city: "BELLE CHASSE",
      state: "LA"
    },
    {
      city: "BELLE FOURCHE",
      state: "SD"
    },
    {
      city: "BELLE GLADE",
      state: "FL"
    },
    {
      city: "BELLE HAVEN",
      state: "VA"
    },
    {
      city: "BELLE MEAD",
      state: "NJ"
    },
    {
      city: "BELLE MINA",
      state: "AL"
    },
    {
      city: "BELLE PLAINE",
      state: "IA"
    },
    {
      city: "BELLE PLAINE",
      state: "KS"
    },
    {
      city: "BELLE PLAINE",
      state: "MN"
    },
    {
      city: "BELLE RIVE",
      state: "IL"
    },
    {
      city: "BELLE ROSE",
      state: "LA"
    },
    {
      city: "BELLE VALLEY",
      state: "OH"
    },
    {
      city: "BELLE VERNON",
      state: "PA"
    },
    {
      city: "BELLEAIR BEACH",
      state: "FL"
    },
    {
      city: "BELLEFONTAINE",
      state: "MS"
    },
    {
      city: "BELLEFONTAINE",
      state: "OH"
    },
    {
      city: "BELLEFONTE",
      state: "PA"
    },
    {
      city: "BELLEMONT",
      state: "AZ"
    },
    {
      city: "BELLEROSE",
      state: "NY"
    },
    {
      city: "BELLEVIEW",
      state: "FL"
    },
    {
      city: "BELLEVIEW",
      state: "MO"
    },
    {
      city: "BELLEVILLE",
      state: "AR"
    },
    {
      city: "BELLEVILLE",
      state: "IL"
    },
    {
      city: "BELLEVILLE",
      state: "KS"
    },
    {
      city: "BELLEVILLE",
      state: "MI"
    },
    {
      city: "BELLEVILLE",
      state: "NJ"
    },
    {
      city: "BELLEVILLE",
      state: "NY"
    },
    {
      city: "BELLEVILLE",
      state: "PA"
    },
    {
      city: "BELLEVILLE",
      state: "WI"
    },
    {
      city: "BELLEVILLE",
      state: "WV"
    },
    {
      city: "BELLEVUE",
      state: "IA"
    },
    {
      city: "BELLEVUE",
      state: "ID"
    },
    {
      city: "BELLEVUE",
      state: "KY"
    },
    {
      city: "BELLEVUE",
      state: "MI"
    },
    {
      city: "BELLEVUE",
      state: "NE"
    },
    {
      city: "BELLEVUE",
      state: "OH"
    },
    {
      city: "BELLEVUE",
      state: "TX"
    },
    {
      city: "BELLEVUE",
      state: "WA"
    },
    {
      city: "BELLFLOWER",
      state: "CA"
    },
    {
      city: "BELLFLOWER",
      state: "IL"
    },
    {
      city: "BELLFLOWER",
      state: "MO"
    },
    {
      city: "BELLINGHAM",
      state: "MA"
    },
    {
      city: "BELLINGHAM",
      state: "MN"
    },
    {
      city: "BELLINGHAM",
      state: "WA"
    },
    {
      city: "BELLMAWR",
      state: "NJ"
    },
    {
      city: "BELLMONT",
      state: "IL"
    },
    {
      city: "BELLMORE",
      state: "NY"
    },
    {
      city: "BELLONA",
      state: "NY"
    },
    {
      city: "BELLOWS FALLS",
      state: "VT"
    },
    {
      city: "BELLPORT",
      state: "NY"
    },
    {
      city: "BELLS",
      state: "TN"
    },
    {
      city: "BELLS",
      state: "TX"
    },
    {
      city: "BELLVALE",
      state: "NY"
    },
    {
      city: "BELLVILLE",
      state: "GA"
    },
    {
      city: "BELLVILLE",
      state: "OH"
    },
    {
      city: "BELLVILLE",
      state: "TX"
    },
    {
      city: "BELLVUE",
      state: "CO"
    },
    {
      city: "BELLWOOD",
      state: "AL"
    },
    {
      city: "BELLWOOD",
      state: "IL"
    },
    {
      city: "BELLWOOD",
      state: "NE"
    },
    {
      city: "BELLWOOD",
      state: "PA"
    },
    {
      city: "BELMAR",
      state: "NJ"
    },
    {
      city: "BELMOND",
      state: "IA"
    },
    {
      city: "BELMONT",
      state: "CA"
    },
    {
      city: "BELMONT",
      state: "LA"
    },
    {
      city: "BELMONT",
      state: "MA"
    },
    {
      city: "BELMONT",
      state: "MI"
    },
    {
      city: "BELMONT",
      state: "MS"
    },
    {
      city: "BELMONT",
      state: "NC"
    },
    {
      city: "BELMONT",
      state: "NH"
    },
    {
      city: "BELMONT",
      state: "NY"
    },
    {
      city: "BELMONT",
      state: "OH"
    },
    {
      city: "BELMONT",
      state: "TX"
    },
    {
      city: "BELMONT",
      state: "VT"
    },
    {
      city: "BELMONT",
      state: "WA"
    },
    {
      city: "BELMONT",
      state: "WI"
    },
    {
      city: "BELMONT",
      state: "WV"
    },
    {
      city: "BELMORE",
      state: "OH"
    },
    {
      city: "BELOIT",
      state: "KS"
    },
    {
      city: "BELOIT",
      state: "OH"
    },
    {
      city: "BELOIT",
      state: "WI"
    },
    {
      city: "BELPRE",
      state: "KS"
    },
    {
      city: "BELPRE",
      state: "OH"
    },
    {
      city: "BELSANO",
      state: "PA"
    },
    {
      city: "BELSPRING",
      state: "VA"
    },
    {
      city: "BELT",
      state: "MT"
    },
    {
      city: "BELTON",
      state: "KY"
    },
    {
      city: "BELTON",
      state: "MO"
    },
    {
      city: "BELTON",
      state: "SC"
    },
    {
      city: "BELTON",
      state: "TX"
    },
    {
      city: "BELTRAMI",
      state: "MN"
    },
    {
      city: "BELTSVILLE",
      state: "MD"
    },
    {
      city: "BELVA",
      state: "WV"
    },
    {
      city: "BELVEDERE TIBURON",
      state: "CA"
    },
    {
      city: "BELVIDERE",
      state: "IL"
    },
    {
      city: "BELVIDERE",
      state: "NC"
    },
    {
      city: "BELVIDERE",
      state: "NE"
    },
    {
      city: "BELVIDERE",
      state: "NJ"
    },
    {
      city: "BELVIDERE",
      state: "SD"
    },
    {
      city: "BELVIDERE",
      state: "TN"
    },
    {
      city: "BELVIDERE CENTER",
      state: "VT"
    },
    {
      city: "BELVIEW",
      state: "MN"
    },
    {
      city: "BELVUE",
      state: "KS"
    },
    {
      city: "BELZONI",
      state: "MS"
    },
    {
      city: "BEMENT",
      state: "IL"
    },
    {
      city: "BEMIDJI",
      state: "MN"
    },
    {
      city: "BEMUS POINT",
      state: "NY"
    },
    {
      city: "BEN BOLT",
      state: "TX"
    },
    {
      city: "BEN HUR",
      state: "VA"
    },
    {
      city: "BEN LOMOND",
      state: "AR"
    },
    {
      city: "BEN LOMOND",
      state: "CA"
    },
    {
      city: "BEN WHEELER",
      state: "TX"
    },
    {
      city: "BENA",
      state: "MN"
    },
    {
      city: "BENA",
      state: "VA"
    },
    {
      city: "BENAVIDES",
      state: "TX"
    },
    {
      city: "BEND",
      state: "OR"
    },
    {
      city: "BEND",
      state: "TX"
    },
    {
      city: "BENDENA",
      state: "KS"
    },
    {
      city: "BENDERSVILLE",
      state: "PA"
    },
    {
      city: "BENEDICT",
      state: "KS"
    },
    {
      city: "BENEDICT",
      state: "MD"
    },
    {
      city: "BENEDICT",
      state: "MN"
    },
    {
      city: "BENEDICT",
      state: "ND"
    },
    {
      city: "BENEDICT",
      state: "NE"
    },
    {
      city: "BENEDICTA",
      state: "ME"
    },
    {
      city: "BENET LAKE",
      state: "WI"
    },
    {
      city: "BENEZETT",
      state: "PA"
    },
    {
      city: "BENHAM",
      state: "KY"
    },
    {
      city: "BENICIA",
      state: "CA"
    },
    {
      city: "BENJAMIN",
      state: "TX"
    },
    {
      city: "BENKELMAN",
      state: "NE"
    },
    {
      city: "BENLD",
      state: "IL"
    },
    {
      city: "BENNET",
      state: "NE"
    },
    {
      city: "BENNETT",
      state: "CO"
    },
    {
      city: "BENNETT",
      state: "IA"
    },
    {
      city: "BENNETT",
      state: "NC"
    },
    {
      city: "BENNETTSVILLE",
      state: "SC"
    },
    {
      city: "BENNINGTON",
      state: "IN"
    },
    {
      city: "BENNINGTON",
      state: "KS"
    },
    {
      city: "BENNINGTON",
      state: "NE"
    },
    {
      city: "BENNINGTON",
      state: "NH"
    },
    {
      city: "BENNINGTON",
      state: "OK"
    },
    {
      city: "BENNINGTON",
      state: "VT"
    },
    {
      city: "BENOIT",
      state: "MS"
    },
    {
      city: "BENOIT",
      state: "WI"
    },
    {
      city: "BENSALEM",
      state: "PA"
    },
    {
      city: "BENSENVILLE",
      state: "IL"
    },
    {
      city: "BENSON",
      state: "AZ"
    },
    {
      city: "BENSON",
      state: "IL"
    },
    {
      city: "BENSON",
      state: "MN"
    },
    {
      city: "BENSON",
      state: "NC"
    },
    {
      city: "BENSON",
      state: "VT"
    },
    {
      city: "BENT",
      state: "NM"
    },
    {
      city: "BENT MOUNTAIN",
      state: "VA"
    },
    {
      city: "BENTLEY",
      state: "KS"
    },
    {
      city: "BENTLEY",
      state: "LA"
    },
    {
      city: "BENTLEY",
      state: "MI"
    },
    {
      city: "BENTLEYVILLE",
      state: "PA"
    },
    {
      city: "BENTON",
      state: "AR"
    },
    {
      city: "BENTON",
      state: "CA"
    },
    {
      city: "BENTON",
      state: "IA"
    },
    {
      city: "BENTON",
      state: "IL"
    },
    {
      city: "BENTON",
      state: "KS"
    },
    {
      city: "BENTON",
      state: "KY"
    },
    {
      city: "BENTON",
      state: "LA"
    },
    {
      city: "BENTON",
      state: "MO"
    },
    {
      city: "BENTON",
      state: "MS"
    },
    {
      city: "BENTON",
      state: "PA"
    },
    {
      city: "BENTON",
      state: "TN"
    },
    {
      city: "BENTON",
      state: "WI"
    },
    {
      city: "BENTON CITY",
      state: "MO"
    },
    {
      city: "BENTON CITY",
      state: "WA"
    },
    {
      city: "BENTON HARBOR",
      state: "MI"
    },
    {
      city: "BENTON RIDGE",
      state: "OH"
    },
    {
      city: "BENTONIA",
      state: "MS"
    },
    {
      city: "BENTONVILLE",
      state: "AR"
    },
    {
      city: "BENTONVILLE",
      state: "OH"
    },
    {
      city: "BENTONVILLE",
      state: "VA"
    },
    {
      city: "BENWOOD",
      state: "WV"
    },
    {
      city: "BENZONIA",
      state: "MI"
    },
    {
      city: "BERCLAIR",
      state: "TX"
    },
    {
      city: "BEREA",
      state: "KY"
    },
    {
      city: "BEREA",
      state: "OH"
    },
    {
      city: "BEREA",
      state: "WV"
    },
    {
      city: "BERESFORD",
      state: "SD"
    },
    {
      city: "BERGEN",
      state: "NY"
    },
    {
      city: "BERGENFIELD",
      state: "NJ"
    },
    {
      city: "BERGER",
      state: "MO"
    },
    {
      city: "BERGHEIM",
      state: "TX"
    },
    {
      city: "BERGHOLZ",
      state: "OH"
    },
    {
      city: "BERGLAND",
      state: "MI"
    },
    {
      city: "BERGMAN",
      state: "AR"
    },
    {
      city: "BERGTON",
      state: "VA"
    },
    {
      city: "BERINO",
      state: "NM"
    },
    {
      city: "BERKELEY",
      state: "CA"
    },
    {
      city: "BERKELEY",
      state: "IL"
    },
    {
      city: "BERKELEY HEIGHTS",
      state: "NJ"
    },
    {
      city: "BERKELEY SPRINGS",
      state: "WV"
    },
    {
      city: "BERKEY",
      state: "OH"
    },
    {
      city: "BERKLEY",
      state: "MA"
    },
    {
      city: "BERKLEY",
      state: "MI"
    },
    {
      city: "BERKSHIRE",
      state: "MA"
    },
    {
      city: "BERKSHIRE",
      state: "NY"
    },
    {
      city: "BERLIN",
      state: "CT"
    },
    {
      city: "BERLIN",
      state: "GA"
    },
    {
      city: "BERLIN",
      state: "MA"
    },
    {
      city: "BERLIN",
      state: "MD"
    },
    {
      city: "BERLIN",
      state: "ND"
    },
    {
      city: "BERLIN",
      state: "NH"
    },
    {
      city: "BERLIN",
      state: "NJ"
    },
    {
      city: "BERLIN",
      state: "NY"
    },
    {
      city: "BERLIN",
      state: "OH"
    },
    {
      city: "BERLIN",
      state: "PA"
    },
    {
      city: "BERLIN",
      state: "WI"
    },
    {
      city: "BERLIN CENTER",
      state: "OH"
    },
    {
      city: "BERLIN HEIGHTS",
      state: "OH"
    },
    {
      city: "BERN",
      state: "ID"
    },
    {
      city: "BERN",
      state: "KS"
    },
    {
      city: "BERNALILLO",
      state: "NM"
    },
    {
      city: "BERNARD",
      state: "IA"
    },
    {
      city: "BERNARD",
      state: "ME"
    },
    {
      city: "BERNARDSTON",
      state: "MA"
    },
    {
      city: "BERNARDSVILLE",
      state: "NJ"
    },
    {
      city: "BERNE",
      state: "IN"
    },
    {
      city: "BERNE",
      state: "NY"
    },
    {
      city: "BERNHARDS BAY",
      state: "NY"
    },
    {
      city: "BERNICE",
      state: "LA"
    },
    {
      city: "BERNIE",
      state: "MO"
    },
    {
      city: "BERNVILLE",
      state: "PA"
    },
    {
      city: "BERRIEN CENTER",
      state: "MI"
    },
    {
      city: "BERRIEN SPRINGS",
      state: "MI"
    },
    {
      city: "BERRY",
      state: "AL"
    },
    {
      city: "BERRY",
      state: "KY"
    },
    {
      city: "BERRY CREEK",
      state: "CA"
    },
    {
      city: "BERRYSBURG",
      state: "PA"
    },
    {
      city: "BERRYTON",
      state: "KS"
    },
    {
      city: "BERRYVILLE",
      state: "AR"
    },
    {
      city: "BERRYVILLE",
      state: "VA"
    },
    {
      city: "BERTHA",
      state: "MN"
    },
    {
      city: "BERTHOLD",
      state: "ND"
    },
    {
      city: "BERTHOUD",
      state: "CO"
    },
    {
      city: "BERTRAM",
      state: "TX"
    },
    {
      city: "BERTRAND",
      state: "MO"
    },
    {
      city: "BERTRAND",
      state: "NE"
    },
    {
      city: "BERWICK",
      state: "IA"
    },
    {
      city: "BERWICK",
      state: "IL"
    },
    {
      city: "BERWICK",
      state: "LA"
    },
    {
      city: "BERWICK",
      state: "ME"
    },
    {
      city: "BERWICK",
      state: "PA"
    },
    {
      city: "BERWIND",
      state: "WV"
    },
    {
      city: "BERWYN",
      state: "IL"
    },
    {
      city: "BERWYN",
      state: "PA"
    },
    {
      city: "BERYL",
      state: "UT"
    },
    {
      city: "BESSEMER",
      state: "AL"
    },
    {
      city: "BESSEMER",
      state: "MI"
    },
    {
      city: "BESSEMER",
      state: "PA"
    },
    {
      city: "BESSEMER CITY",
      state: "NC"
    },
    {
      city: "BESSIE",
      state: "OK"
    },
    {
      city: "BETHALTO",
      state: "IL"
    },
    {
      city: "BETHANIA",
      state: "NC"
    },
    {
      city: "BETHANY",
      state: "CT"
    },
    {
      city: "BETHANY",
      state: "IL"
    },
    {
      city: "BETHANY",
      state: "LA"
    },
    {
      city: "BETHANY",
      state: "MO"
    },
    {
      city: "BETHANY",
      state: "OK"
    },
    {
      city: "BETHANY",
      state: "WV"
    },
    {
      city: "BETHANY BEACH",
      state: "DE"
    },
    {
      city: "BETHEL",
      state: "AK"
    },
    {
      city: "BETHEL",
      state: "CT"
    },
    {
      city: "BETHEL",
      state: "DE"
    },
    {
      city: "BETHEL",
      state: "ME"
    },
    {
      city: "BETHEL",
      state: "MN"
    },
    {
      city: "BETHEL",
      state: "MO"
    },
    {
      city: "BETHEL",
      state: "NC"
    },
    {
      city: "BETHEL",
      state: "NY"
    },
    {
      city: "BETHEL",
      state: "OH"
    },
    {
      city: "BETHEL",
      state: "OK"
    },
    {
      city: "BETHEL",
      state: "PA"
    },
    {
      city: "BETHEL",
      state: "VT"
    },
    {
      city: "BETHEL ISLAND",
      state: "CA"
    },
    {
      city: "BETHEL PARK",
      state: "PA"
    },
    {
      city: "BETHEL SPRINGS",
      state: "TN"
    },
    {
      city: "BETHELRIDGE",
      state: "KY"
    },
    {
      city: "BETHESDA",
      state: "MD"
    },
    {
      city: "BETHESDA",
      state: "OH"
    },
    {
      city: "BETHLEHEM",
      state: "CT"
    },
    {
      city: "BETHLEHEM",
      state: "GA"
    },
    {
      city: "BETHLEHEM",
      state: "KY"
    },
    {
      city: "BETHLEHEM",
      state: "MD"
    },
    {
      city: "BETHLEHEM",
      state: "NH"
    },
    {
      city: "BETHLEHEM",
      state: "PA"
    },
    {
      city: "BETHPAGE",
      state: "NY"
    },
    {
      city: "BETHPAGE",
      state: "TN"
    },
    {
      city: "BETHUNE",
      state: "CO"
    },
    {
      city: "BETHUNE",
      state: "SC"
    },
    {
      city: "BETSY LAYNE",
      state: "KY"
    },
    {
      city: "BETTENDORF",
      state: "IA"
    },
    {
      city: "BETTERTON",
      state: "MD"
    },
    {
      city: "BETTLES FIELD",
      state: "AK"
    },
    {
      city: "BETTSVILLE",
      state: "OH"
    },
    {
      city: "BEULAH",
      state: "CO"
    },
    {
      city: "BEULAH",
      state: "MI"
    },
    {
      city: "BEULAH",
      state: "MS"
    },
    {
      city: "BEULAH",
      state: "ND"
    },
    {
      city: "BEULAH",
      state: "WY"
    },
    {
      city: "BEULAVILLE",
      state: "NC"
    },
    {
      city: "BEVERLY",
      state: "KS"
    },
    {
      city: "BEVERLY",
      state: "KY"
    },
    {
      city: "BEVERLY",
      state: "MA"
    },
    {
      city: "BEVERLY",
      state: "NJ"
    },
    {
      city: "BEVERLY",
      state: "OH"
    },
    {
      city: "BEVERLY",
      state: "WA"
    },
    {
      city: "BEVERLY",
      state: "WV"
    },
    {
      city: "BEVERLY HILLS",
      state: "CA"
    },
    {
      city: "BEVERLY HILLS",
      state: "FL"
    },
    {
      city: "BEVERLY SHORES",
      state: "IN"
    },
    {
      city: "BEVIER",
      state: "MO"
    },
    {
      city: "BEVINGTON",
      state: "IA"
    },
    {
      city: "BEVINSVILLE",
      state: "KY"
    },
    {
      city: "BEYER",
      state: "PA"
    },
    {
      city: "BICKLETON",
      state: "WA"
    },
    {
      city: "BICKMORE",
      state: "WV"
    },
    {
      city: "BICKNELL",
      state: "IN"
    },
    {
      city: "BICKNELL",
      state: "UT"
    },
    {
      city: "BIDDEFORD",
      state: "ME"
    },
    {
      city: "BIDDEFORD POOL",
      state: "ME"
    },
    {
      city: "BIDDLE",
      state: "MT"
    },
    {
      city: "BIDWELL",
      state: "OH"
    },
    {
      city: "BIEBER",
      state: "CA"
    },
    {
      city: "BIENVILLE",
      state: "LA"
    },
    {
      city: "BIG ARM",
      state: "MT"
    },
    {
      city: "BIG BAR",
      state: "CA"
    },
    {
      city: "BIG BAY",
      state: "MI"
    },
    {
      city: "BIG BEAR CITY",
      state: "CA"
    },
    {
      city: "BIG BEAR LAKE",
      state: "CA"
    },
    {
      city: "BIG BEND",
      state: "CA"
    },
    {
      city: "BIG BEND",
      state: "WI"
    },
    {
      city: "BIG BEND",
      state: "WV"
    },
    {
      city: "BIG BEND NATIONAL PARK",
      state: "TX"
    },
    {
      city: "BIG CABIN",
      state: "OK"
    },
    {
      city: "BIG CLIFTY",
      state: "KY"
    },
    {
      city: "BIG COVE TANNERY",
      state: "PA"
    },
    {
      city: "BIG CREEK",
      state: "CA"
    },
    {
      city: "BIG CREEK",
      state: "KY"
    },
    {
      city: "BIG CREEK",
      state: "MS"
    },
    {
      city: "BIG CREEK",
      state: "WV"
    },
    {
      city: "BIG FALLS",
      state: "MN"
    },
    {
      city: "BIG FALLS",
      state: "WI"
    },
    {
      city: "BIG FLAT",
      state: "AR"
    },
    {
      city: "BIG FLATS",
      state: "NY"
    },
    {
      city: "BIG HORN",
      state: "WY"
    },
    {
      city: "BIG INDIAN",
      state: "NY"
    },
    {
      city: "BIG ISLAND",
      state: "VA"
    },
    {
      city: "BIG LAKE",
      state: "AK"
    },
    {
      city: "BIG LAKE",
      state: "MN"
    },
    {
      city: "BIG LAKE",
      state: "TX"
    },
    {
      city: "BIG LAUREL",
      state: "KY"
    },
    {
      city: "BIG OAK FLAT",
      state: "CA"
    },
    {
      city: "BIG PINE",
      state: "CA"
    },
    {
      city: "BIG PINE KEY",
      state: "FL"
    },
    {
      city: "BIG PINEY",
      state: "WY"
    },
    {
      city: "BIG POOL",
      state: "MD"
    },
    {
      city: "BIG PRAIRIE",
      state: "OH"
    },
    {
      city: "BIG RAPIDS",
      state: "MI"
    },
    {
      city: "BIG ROCK",
      state: "IL"
    },
    {
      city: "BIG ROCK",
      state: "TN"
    },
    {
      city: "BIG ROCK",
      state: "VA"
    },
    {
      city: "BIG RUN",
      state: "PA"
    },
    {
      city: "BIG SANDY",
      state: "MT"
    },
    {
      city: "BIG SANDY",
      state: "TN"
    },
    {
      city: "BIG SANDY",
      state: "TX"
    },
    {
      city: "BIG SANDY",
      state: "WV"
    },
    {
      city: "BIG SKY",
      state: "MT"
    },
    {
      city: "BIG SPRING",
      state: "TX"
    },
    {
      city: "BIG SPRINGS",
      state: "NE"
    },
    {
      city: "BIG SPRINGS",
      state: "WV"
    },
    {
      city: "BIG STONE CITY",
      state: "SD"
    },
    {
      city: "BIG STONE GAP",
      state: "VA"
    },
    {
      city: "BIG SUR",
      state: "CA"
    },
    {
      city: "BIG TIMBER",
      state: "MT"
    },
    {
      city: "BIG WELLS",
      state: "TX"
    },
    {
      city: "BIGELOW",
      state: "AR"
    },
    {
      city: "BIGELOW",
      state: "MN"
    },
    {
      city: "BIGFOOT",
      state: "TX"
    },
    {
      city: "BIGFORK",
      state: "MN"
    },
    {
      city: "BIGFORK",
      state: "MT"
    },
    {
      city: "BIGGERS",
      state: "AR"
    },
    {
      city: "BIGGS",
      state: "CA"
    },
    {
      city: "BIGGSVILLE",
      state: "IL"
    },
    {
      city: "BIGHILL",
      state: "KY"
    },
    {
      city: "BIGHORN",
      state: "MT"
    },
    {
      city: "BIGLER",
      state: "PA"
    },
    {
      city: "BIGLERVILLE",
      state: "PA"
    },
    {
      city: "BILLERICA",
      state: "MA"
    },
    {
      city: "BILLINGS",
      state: "MO"
    },
    {
      city: "BILLINGS",
      state: "MT"
    },
    {
      city: "BILLINGS",
      state: "NY"
    },
    {
      city: "BILLINGS",
      state: "OK"
    },
    {
      city: "BILLINGSLEY",
      state: "AL"
    },
    {
      city: "BILOXI",
      state: "MS"
    },
    {
      city: "BIM",
      state: "WV"
    },
    {
      city: "BIMBLE",
      state: "KY"
    },
    {
      city: "BINFORD",
      state: "ND"
    },
    {
      city: "BINGEN",
      state: "WA"
    },
    {
      city: "BINGER",
      state: "OK"
    },
    {
      city: "BINGHAM",
      state: "IL"
    },
    {
      city: "BINGHAM",
      state: "ME"
    },
    {
      city: "BINGHAM CANYON",
      state: "UT"
    },
    {
      city: "BINGHAM LAKE",
      state: "MN"
    },
    {
      city: "BINGHAMTON",
      state: "NY"
    },
    {
      city: "BIOLA",
      state: "CA"
    },
    {
      city: "BIPPUS",
      state: "IN"
    },
    {
      city: "BIRCH HARBOR",
      state: "ME"
    },
    {
      city: "BIRCH RIVER",
      state: "WV"
    },
    {
      city: "BIRCH RUN",
      state: "MI"
    },
    {
      city: "BIRCH TREE",
      state: "MO"
    },
    {
      city: "BIRCHDALE",
      state: "MN"
    },
    {
      city: "BIRCHLEAF",
      state: "VA"
    },
    {
      city: "BIRCHRUNVILLE",
      state: "PA"
    },
    {
      city: "BIRCHWOOD",
      state: "TN"
    },
    {
      city: "BIRCHWOOD",
      state: "WI"
    },
    {
      city: "BIRD CITY",
      state: "KS"
    },
    {
      city: "BIRD IN HAND",
      state: "PA"
    },
    {
      city: "BIRD ISLAND",
      state: "MN"
    },
    {
      city: "BIRDS LANDING",
      state: "CA"
    },
    {
      city: "BIRDSBORO",
      state: "PA"
    },
    {
      city: "BIRDSEYE",
      state: "IN"
    },
    {
      city: "BIRDSNEST",
      state: "VA"
    },
    {
      city: "BIRMINGHAM",
      state: "AL"
    },
    {
      city: "BIRMINGHAM",
      state: "IA"
    },
    {
      city: "BIRMINGHAM",
      state: "MI"
    },
    {
      city: "BIRMINGHAM",
      state: "NJ"
    },
    {
      city: "BIRMINGHAM",
      state: "OH"
    },
    {
      city: "BIRNAMWOOD",
      state: "WI"
    },
    {
      city: "BIRNEY",
      state: "MT"
    },
    {
      city: "BISBEE",
      state: "AZ"
    },
    {
      city: "BISBEE",
      state: "ND"
    },
    {
      city: "BISCOE",
      state: "AR"
    },
    {
      city: "BISCOE",
      state: "NC"
    },
    {
      city: "BISHOP",
      state: "CA"
    },
    {
      city: "BISHOP",
      state: "GA"
    },
    {
      city: "BISHOP",
      state: "TX"
    },
    {
      city: "BISHOP",
      state: "VA"
    },
    {
      city: "BISHOP HILL",
      state: "IL"
    },
    {
      city: "BISHOPVILLE",
      state: "MD"
    },
    {
      city: "BISHOPVILLE",
      state: "SC"
    },
    {
      city: "BISMARCK",
      state: "AR"
    },
    {
      city: "BISMARCK",
      state: "IL"
    },
    {
      city: "BISMARCK",
      state: "MO"
    },
    {
      city: "BISMARCK",
      state: "ND"
    },
    {
      city: "BISON",
      state: "KS"
    },
    {
      city: "BISON",
      state: "OK"
    },
    {
      city: "BISON",
      state: "SD"
    },
    {
      city: "BITELY",
      state: "MI"
    },
    {
      city: "BITTINGER",
      state: "MD"
    },
    {
      city: "BIVALVE",
      state: "MD"
    },
    {
      city: "BIVINS",
      state: "TX"
    },
    {
      city: "BIWABIK",
      state: "MN"
    },
    {
      city: "BIXBY",
      state: "MO"
    },
    {
      city: "BIXBY",
      state: "OK"
    },
    {
      city: "BLACHLY",
      state: "OR"
    },
    {
      city: "BLACK",
      state: "AL"
    },
    {
      city: "BLACK",
      state: "MO"
    },
    {
      city: "BLACK CANYON CITY",
      state: "AZ"
    },
    {
      city: "BLACK CREEK",
      state: "NC"
    },
    {
      city: "BLACK CREEK",
      state: "NY"
    },
    {
      city: "BLACK CREEK",
      state: "WI"
    },
    {
      city: "BLACK DIAMOND",
      state: "WA"
    },
    {
      city: "BLACK EAGLE",
      state: "MT"
    },
    {
      city: "BLACK EARTH",
      state: "WI"
    },
    {
      city: "BLACK HAWK",
      state: "CO"
    },
    {
      city: "BLACK HAWK",
      state: "SD"
    },
    {
      city: "BLACK LICK",
      state: "PA"
    },
    {
      city: "BLACK MOUNTAIN",
      state: "NC"
    },
    {
      city: "BLACK OAK",
      state: "AR"
    },
    {
      city: "BLACK RIVER",
      state: "MI"
    },
    {
      city: "BLACK RIVER",
      state: "NY"
    },
    {
      city: "BLACK RIVER FALLS",
      state: "WI"
    },
    {
      city: "BLACK ROCK",
      state: "AR"
    },
    {
      city: "BLACKBURN",
      state: "MO"
    },
    {
      city: "BLACKDUCK",
      state: "MN"
    },
    {
      city: "BLACKEY",
      state: "KY"
    },
    {
      city: "BLACKFOOT",
      state: "ID"
    },
    {
      city: "BLACKLICK",
      state: "OH"
    },
    {
      city: "BLACKSBURG",
      state: "SC"
    },
    {
      city: "BLACKSBURG",
      state: "VA"
    },
    {
      city: "BLACKSHEAR",
      state: "GA"
    },
    {
      city: "BLACKSTOCK",
      state: "SC"
    },
    {
      city: "BLACKSTONE",
      state: "IL"
    },
    {
      city: "BLACKSTONE",
      state: "MA"
    },
    {
      city: "BLACKSTONE",
      state: "VA"
    },
    {
      city: "BLACKSVILLE",
      state: "WV"
    },
    {
      city: "BLACKVILLE",
      state: "SC"
    },
    {
      city: "BLACKWATER",
      state: "MO"
    },
    {
      city: "BLACKWATER",
      state: "VA"
    },
    {
      city: "BLACKWELL",
      state: "MO"
    },
    {
      city: "BLACKWELL",
      state: "OK"
    },
    {
      city: "BLACKWELL",
      state: "TX"
    },
    {
      city: "BLACKWOOD",
      state: "NJ"
    },
    {
      city: "BLADEN",
      state: "NE"
    },
    {
      city: "BLADENBORO",
      state: "NC"
    },
    {
      city: "BLADENSBURG",
      state: "MD"
    },
    {
      city: "BLADENSBURG",
      state: "OH"
    },
    {
      city: "BLAIN",
      state: "PA"
    },
    {
      city: "BLAINE",
      state: "KY"
    },
    {
      city: "BLAINE",
      state: "ME"
    },
    {
      city: "BLAINE",
      state: "OH"
    },
    {
      city: "BLAINE",
      state: "TN"
    },
    {
      city: "BLAINE",
      state: "WA"
    },
    {
      city: "BLAIR",
      state: "NE"
    },
    {
      city: "BLAIR",
      state: "OK"
    },
    {
      city: "BLAIR",
      state: "SC"
    },
    {
      city: "BLAIR",
      state: "WI"
    },
    {
      city: "BLAIRS",
      state: "VA"
    },
    {
      city: "BLAIRS MILLS",
      state: "PA"
    },
    {
      city: "BLAIRSBURG",
      state: "IA"
    },
    {
      city: "BLAIRSDEN GRAEAGLE",
      state: "CA"
    },
    {
      city: "BLAIRSTOWN",
      state: "IA"
    },
    {
      city: "BLAIRSTOWN",
      state: "MO"
    },
    {
      city: "BLAIRSTOWN",
      state: "NJ"
    },
    {
      city: "BLAIRSVILLE",
      state: "GA"
    },
    {
      city: "BLAIRSVILLE",
      state: "PA"
    },
    {
      city: "BLAKELY",
      state: "GA"
    },
    {
      city: "BLAKELY ISLAND",
      state: "WA"
    },
    {
      city: "BLAKESBURG",
      state: "IA"
    },
    {
      city: "BLAKESLEE",
      state: "OH"
    },
    {
      city: "BLAKESLEE",
      state: "PA"
    },
    {
      city: "BLANCA",
      state: "CO"
    },
    {
      city: "BLANCH",
      state: "NC"
    },
    {
      city: "BLANCHARD",
      state: "IA"
    },
    {
      city: "BLANCHARD",
      state: "ID"
    },
    {
      city: "BLANCHARD",
      state: "LA"
    },
    {
      city: "BLANCHARD",
      state: "MI"
    },
    {
      city: "BLANCHARD",
      state: "ND"
    },
    {
      city: "BLANCHARD",
      state: "OK"
    },
    {
      city: "BLANCHARD",
      state: "PA"
    },
    {
      city: "BLANCHARDVILLE",
      state: "WI"
    },
    {
      city: "BLANCHESTER",
      state: "OH"
    },
    {
      city: "BLANCO",
      state: "NM"
    },
    {
      city: "BLANCO",
      state: "OK"
    },
    {
      city: "BLANCO",
      state: "TX"
    },
    {
      city: "BLAND",
      state: "MO"
    },
    {
      city: "BLAND",
      state: "VA"
    },
    {
      city: "BLANDBURG",
      state: "PA"
    },
    {
      city: "BLANDFORD",
      state: "MA"
    },
    {
      city: "BLANDING",
      state: "UT"
    },
    {
      city: "BLANDINSVILLE",
      state: "IL"
    },
    {
      city: "BLANDON",
      state: "PA"
    },
    {
      city: "BLANFORD",
      state: "IN"
    },
    {
      city: "BLANKET",
      state: "TX"
    },
    {
      city: "BLAUVELT",
      state: "NY"
    },
    {
      city: "BLAWENBURG",
      state: "NJ"
    },
    {
      city: "BLEDSOE",
      state: "KY"
    },
    {
      city: "BLEDSOE",
      state: "TX"
    },
    {
      city: "BLEIBLERVILLE",
      state: "TX"
    },
    {
      city: "BLENCOE",
      state: "IA"
    },
    {
      city: "BLENHEIM",
      state: "SC"
    },
    {
      city: "BLENKER",
      state: "WI"
    },
    {
      city: "BLESSING",
      state: "TX"
    },
    {
      city: "BLEVINS",
      state: "AR"
    },
    {
      city: "BLISS",
      state: "ID"
    },
    {
      city: "BLISS",
      state: "NY"
    },
    {
      city: "BLISSFIELD",
      state: "MI"
    },
    {
      city: "BLISSFIELD",
      state: "OH"
    },
    {
      city: "BLOCK ISLAND",
      state: "RI"
    },
    {
      city: "BLOCKSBURG",
      state: "CA"
    },
    {
      city: "BLOCKTON",
      state: "IA"
    },
    {
      city: "BLODGETT",
      state: "OR"
    },
    {
      city: "BLODGETT MILLS",
      state: "NY"
    },
    {
      city: "BLOMKEST",
      state: "MN"
    },
    {
      city: "BLOOMBURG",
      state: "TX"
    },
    {
      city: "BLOOMDALE",
      state: "OH"
    },
    {
      city: "BLOOMER",
      state: "WI"
    },
    {
      city: "BLOOMERY",
      state: "WV"
    },
    {
      city: "BLOOMFIELD",
      state: "CT"
    },
    {
      city: "BLOOMFIELD",
      state: "IA"
    },
    {
      city: "BLOOMFIELD",
      state: "IN"
    },
    {
      city: "BLOOMFIELD",
      state: "KY"
    },
    {
      city: "BLOOMFIELD",
      state: "MO"
    },
    {
      city: "BLOOMFIELD",
      state: "MT"
    },
    {
      city: "BLOOMFIELD",
      state: "NE"
    },
    {
      city: "BLOOMFIELD",
      state: "NJ"
    },
    {
      city: "BLOOMFIELD",
      state: "NM"
    },
    {
      city: "BLOOMFIELD",
      state: "NY"
    },
    {
      city: "BLOOMFIELD HILLS",
      state: "MI"
    },
    {
      city: "BLOOMING GLEN",
      state: "PA"
    },
    {
      city: "BLOOMING GROVE",
      state: "NY"
    },
    {
      city: "BLOOMING GROVE",
      state: "TX"
    },
    {
      city: "BLOOMING PRAIRIE",
      state: "MN"
    },
    {
      city: "BLOOMINGBURG",
      state: "NY"
    },
    {
      city: "BLOOMINGBURG",
      state: "OH"
    },
    {
      city: "BLOOMINGDALE",
      state: "GA"
    },
    {
      city: "BLOOMINGDALE",
      state: "IL"
    },
    {
      city: "BLOOMINGDALE",
      state: "IN"
    },
    {
      city: "BLOOMINGDALE",
      state: "MI"
    },
    {
      city: "BLOOMINGDALE",
      state: "NJ"
    },
    {
      city: "BLOOMINGDALE",
      state: "NY"
    },
    {
      city: "BLOOMINGDALE",
      state: "OH"
    },
    {
      city: "BLOOMINGROSE",
      state: "WV"
    },
    {
      city: "BLOOMINGTON",
      state: "CA"
    },
    {
      city: "BLOOMINGTON",
      state: "ID"
    },
    {
      city: "BLOOMINGTON",
      state: "IL"
    },
    {
      city: "BLOOMINGTON",
      state: "IN"
    },
    {
      city: "BLOOMINGTON",
      state: "MD"
    },
    {
      city: "BLOOMINGTON",
      state: "NY"
    },
    {
      city: "BLOOMINGTON",
      state: "TX"
    },
    {
      city: "BLOOMINGTON",
      state: "WI"
    },
    {
      city: "BLOOMINGTON SPRINGS",
      state: "TN"
    },
    {
      city: "BLOOMSBURG",
      state: "PA"
    },
    {
      city: "BLOOMSBURY",
      state: "NJ"
    },
    {
      city: "BLOOMSDALE",
      state: "MO"
    },
    {
      city: "BLOOMVILLE",
      state: "NY"
    },
    {
      city: "BLOOMVILLE",
      state: "OH"
    },
    {
      city: "BLOSSBURG",
      state: "PA"
    },
    {
      city: "BLOSSOM",
      state: "TX"
    },
    {
      city: "BLOSSVALE",
      state: "NY"
    },
    {
      city: "BLOUNTS CREEK",
      state: "NC"
    },
    {
      city: "BLOUNTSTOWN",
      state: "FL"
    },
    {
      city: "BLOUNTSVILLE",
      state: "AL"
    },
    {
      city: "BLOUNTVILLE",
      state: "TN"
    },
    {
      city: "BLOWING ROCK",
      state: "NC"
    },
    {
      city: "BLOXOM",
      state: "VA"
    },
    {
      city: "BLUE",
      state: "AZ"
    },
    {
      city: "BLUE BALL",
      state: "PA"
    },
    {
      city: "BLUE BELL",
      state: "PA"
    },
    {
      city: "BLUE CREEK",
      state: "OH"
    },
    {
      city: "BLUE CREEK",
      state: "WV"
    },
    {
      city: "BLUE DIAMOND",
      state: "NV"
    },
    {
      city: "BLUE EARTH",
      state: "MN"
    },
    {
      city: "BLUE EYE",
      state: "MO"
    },
    {
      city: "BLUE GAP",
      state: "AZ"
    },
    {
      city: "BLUE GRASS",
      state: "IA"
    },
    {
      city: "BLUE GRASS",
      state: "VA"
    },
    {
      city: "BLUE HILL",
      state: "ME"
    },
    {
      city: "BLUE HILL",
      state: "NE"
    },
    {
      city: "BLUE ISLAND",
      state: "IL"
    },
    {
      city: "BLUE JAY",
      state: "CA"
    },
    {
      city: "BLUE LAKE",
      state: "CA"
    },
    {
      city: "BLUE MOUND",
      state: "IL"
    },
    {
      city: "BLUE MOUND",
      state: "KS"
    },
    {
      city: "BLUE MOUNDS",
      state: "WI"
    },
    {
      city: "BLUE MOUNTAIN",
      state: "MS"
    },
    {
      city: "BLUE MOUNTAIN LAKE",
      state: "NY"
    },
    {
      city: "BLUE POINT",
      state: "NY"
    },
    {
      city: "BLUE RAPIDS",
      state: "KS"
    },
    {
      city: "BLUE RIDGE",
      state: "GA"
    },
    {
      city: "BLUE RIDGE",
      state: "TX"
    },
    {
      city: "BLUE RIDGE",
      state: "VA"
    },
    {
      city: "BLUE RIDGE SUMMIT",
      state: "PA"
    },
    {
      city: "BLUE RIVER",
      state: "KY"
    },
    {
      city: "BLUE RIVER",
      state: "OR"
    },
    {
      city: "BLUE RIVER",
      state: "WI"
    },
    {
      city: "BLUE ROCK",
      state: "OH"
    },
    {
      city: "BLUE SPRINGS",
      state: "MO"
    },
    {
      city: "BLUE SPRINGS",
      state: "MS"
    },
    {
      city: "BLUE SPRINGS",
      state: "NE"
    },
    {
      city: "BLUEBELL",
      state: "UT"
    },
    {
      city: "BLUEFIELD",
      state: "VA"
    },
    {
      city: "BLUEFIELD",
      state: "WV"
    },
    {
      city: "BLUEJACKET",
      state: "OK"
    },
    {
      city: "BLUEMONT",
      state: "VA"
    },
    {
      city: "BLUEWATER",
      state: "NM"
    },
    {
      city: "BLUFF",
      state: "UT"
    },
    {
      city: "BLUFF CITY",
      state: "AR"
    },
    {
      city: "BLUFF CITY",
      state: "KS"
    },
    {
      city: "BLUFF CITY",
      state: "TN"
    },
    {
      city: "BLUFF DALE",
      state: "TX"
    },
    {
      city: "BLUFF SPRINGS",
      state: "IL"
    },
    {
      city: "BLUFFS",
      state: "IL"
    },
    {
      city: "BLUFFTON",
      state: "GA"
    },
    {
      city: "BLUFFTON",
      state: "IN"
    },
    {
      city: "BLUFFTON",
      state: "MN"
    },
    {
      city: "BLUFFTON",
      state: "OH"
    },
    {
      city: "BLUFFTON",
      state: "SC"
    },
    {
      city: "BLUFFTON",
      state: "TX"
    },
    {
      city: "BLUFORD",
      state: "IL"
    },
    {
      city: "BLUM",
      state: "TX"
    },
    {
      city: "BLUNT",
      state: "SD"
    },
    {
      city: "BLY",
      state: "OR"
    },
    {
      city: "BLYTHE",
      state: "CA"
    },
    {
      city: "BLYTHE",
      state: "GA"
    },
    {
      city: "BLYTHEDALE",
      state: "MO"
    },
    {
      city: "BLYTHEVILLE",
      state: "AR"
    },
    {
      city: "BLYTHEWOOD",
      state: "SC"
    },
    {
      city: "BOALSBURG",
      state: "PA"
    },
    {
      city: "BOARDMAN",
      state: "OR"
    },
    {
      city: "BOAZ",
      state: "AL"
    },
    {
      city: "BOAZ",
      state: "KY"
    },
    {
      city: "BOBTOWN",
      state: "PA"
    },
    {
      city: "BOCA GRANDE",
      state: "FL"
    },
    {
      city: "BOCA RATON",
      state: "FL"
    },
    {
      city: "BOCK",
      state: "MN"
    },
    {
      city: "BODE",
      state: "IA"
    },
    {
      city: "BODEGA",
      state: "CA"
    },
    {
      city: "BODEGA BAY",
      state: "CA"
    },
    {
      city: "BODFISH",
      state: "CA"
    },
    {
      city: "BOELUS",
      state: "NE"
    },
    {
      city: "BOERNE",
      state: "TX"
    },
    {
      city: "BOGALUSA",
      state: "LA"
    },
    {
      city: "BOGARD",
      state: "MO"
    },
    {
      city: "BOGART",
      state: "GA"
    },
    {
      city: "BOGATA",
      state: "TX"
    },
    {
      city: "BOGGSTOWN",
      state: "IN"
    },
    {
      city: "BOGOTA",
      state: "NJ"
    },
    {
      city: "BOGOTA",
      state: "TN"
    },
    {
      city: "BOGUE",
      state: "KS"
    },
    {
      city: "BOGUE CHITTO",
      state: "MS"
    },
    {
      city: "BOHANNON",
      state: "VA"
    },
    {
      city: "BOHEMIA",
      state: "NY"
    },
    {
      city: "BOICEVILLE",
      state: "NY"
    },
    {
      city: "BOILING SPRINGS",
      state: "NC"
    },
    {
      city: "BOILING SPRINGS",
      state: "PA"
    },
    {
      city: "BOILING SPRINGS",
      state: "SC"
    },
    {
      city: "BOIS D ARC",
      state: "MO"
    },
    {
      city: "BOISE",
      state: "ID"
    },
    {
      city: "BOISE CITY",
      state: "OK"
    },
    {
      city: "BOISSEVAIN",
      state: "VA"
    },
    {
      city: "BOKCHITO",
      state: "OK"
    },
    {
      city: "BOKEELIA",
      state: "FL"
    },
    {
      city: "BOKOSHE",
      state: "OK"
    },
    {
      city: "BOLCKOW",
      state: "MO"
    },
    {
      city: "BOLES",
      state: "AR"
    },
    {
      city: "BOLES",
      state: "IL"
    },
    {
      city: "BOLEY",
      state: "OK"
    },
    {
      city: "BOLIGEE",
      state: "AL"
    },
    {
      city: "BOLINAS",
      state: "CA"
    },
    {
      city: "BOLING",
      state: "TX"
    },
    {
      city: "BOLINGBROKE",
      state: "GA"
    },
    {
      city: "BOLINGBROOK",
      state: "IL"
    },
    {
      city: "BOLIVAR",
      state: "MO"
    },
    {
      city: "BOLIVAR",
      state: "NY"
    },
    {
      city: "BOLIVAR",
      state: "OH"
    },
    {
      city: "BOLIVAR",
      state: "PA"
    },
    {
      city: "BOLIVAR",
      state: "TN"
    },
    {
      city: "BOLIVIA",
      state: "NC"
    },
    {
      city: "BOLT",
      state: "WV"
    },
    {
      city: "BOLTON",
      state: "CT"
    },
    {
      city: "BOLTON",
      state: "MA"
    },
    {
      city: "BOLTON",
      state: "MS"
    },
    {
      city: "BOLTON",
      state: "NC"
    },
    {
      city: "BOLTON LANDING",
      state: "NY"
    },
    {
      city: "BOMBAY",
      state: "NY"
    },
    {
      city: "BOMONT",
      state: "WV"
    },
    {
      city: "BOMOSEEN",
      state: "VT"
    },
    {
      city: "BON AIR",
      state: "AL"
    },
    {
      city: "BON AQUA",
      state: "TN"
    },
    {
      city: "BON SECOUR",
      state: "AL"
    },
    {
      city: "BON WIER",
      state: "TX"
    },
    {
      city: "BONAIRE",
      state: "GA"
    },
    {
      city: "BONANZA",
      state: "OR"
    },
    {
      city: "BONANZA",
      state: "UT"
    },
    {
      city: "BONAPARTE",
      state: "IA"
    },
    {
      city: "BONCARBO",
      state: "CO"
    },
    {
      city: "BOND",
      state: "CO"
    },
    {
      city: "BONDSVILLE",
      state: "MA"
    },
    {
      city: "BONDUEL",
      state: "WI"
    },
    {
      city: "BONDURANT",
      state: "IA"
    },
    {
      city: "BONDURANT",
      state: "WY"
    },
    {
      city: "BONDVILLE",
      state: "IL"
    },
    {
      city: "BONDVILLE",
      state: "VT"
    },
    {
      city: "BONE GAP",
      state: "IL"
    },
    {
      city: "BONESTEEL",
      state: "SD"
    },
    {
      city: "BONEVILLE",
      state: "GA"
    },
    {
      city: "BONFIELD",
      state: "IL"
    },
    {
      city: "BONHAM",
      state: "TX"
    },
    {
      city: "BONIFAY",
      state: "FL"
    },
    {
      city: "BONITA",
      state: "CA"
    },
    {
      city: "BONITA",
      state: "LA"
    },
    {
      city: "BONITA SPRINGS",
      state: "FL"
    },
    {
      city: "BONLEE",
      state: "NC"
    },
    {
      city: "BONNE TERRE",
      state: "MO"
    },
    {
      city: "BONNEAU",
      state: "SC"
    },
    {
      city: "BONNER",
      state: "MT"
    },
    {
      city: "BONNER SPRINGS",
      state: "KS"
    },
    {
      city: "BONNERDALE",
      state: "AR"
    },
    {
      city: "BONNERS FERRY",
      state: "ID"
    },
    {
      city: "BONNEY LAKE",
      state: "WA"
    },
    {
      city: "BONNIE",
      state: "IL"
    },
    {
      city: "BONNIEVILLE",
      state: "KY"
    },
    {
      city: "BONNOTS MILL",
      state: "MO"
    },
    {
      city: "BONNYMAN",
      state: "KY"
    },
    {
      city: "BONO",
      state: "AR"
    },
    {
      city: "BONSALL",
      state: "CA"
    },
    {
      city: "BOODY",
      state: "IL"
    },
    {
      city: "BOOKER",
      state: "TX"
    },
    {
      city: "BOOMER",
      state: "NC"
    },
    {
      city: "BOOMER",
      state: "WV"
    },
    {
      city: "BOON",
      state: "MI"
    },
    {
      city: "BOONE",
      state: "CO"
    },
    {
      city: "BOONE",
      state: "IA"
    },
    {
      city: "BOONE",
      state: "NC"
    },
    {
      city: "BOONE GROVE",
      state: "IN"
    },
    {
      city: "BOONES MILL",
      state: "VA"
    },
    {
      city: "BOONEVILLE",
      state: "AR"
    },
    {
      city: "BOONEVILLE",
      state: "IA"
    },
    {
      city: "BOONEVILLE",
      state: "KY"
    },
    {
      city: "BOONEVILLE",
      state: "MS"
    },
    {
      city: "BOONS CAMP",
      state: "KY"
    },
    {
      city: "BOONSBORO",
      state: "MD"
    },
    {
      city: "BOONTON",
      state: "NJ"
    },
    {
      city: "BOONVILLE",
      state: "CA"
    },
    {
      city: "BOONVILLE",
      state: "IN"
    },
    {
      city: "BOONVILLE",
      state: "MO"
    },
    {
      city: "BOONVILLE",
      state: "NC"
    },
    {
      city: "BOONVILLE",
      state: "NY"
    },
    {
      city: "BOOTH",
      state: "AL"
    },
    {
      city: "BOOTHBAY",
      state: "ME"
    },
    {
      city: "BOOTHBAY HARBOR",
      state: "ME"
    },
    {
      city: "BOOTHVILLE",
      state: "LA"
    },
    {
      city: "BORDELONVILLE",
      state: "LA"
    },
    {
      city: "BORDEN",
      state: "IN"
    },
    {
      city: "BORDENTOWN",
      state: "NJ"
    },
    {
      city: "BORGER",
      state: "TX"
    },
    {
      city: "BORING",
      state: "MD"
    },
    {
      city: "BORING",
      state: "OR"
    },
    {
      city: "BORON",
      state: "CA"
    },
    {
      city: "BORREGO SPRINGS",
      state: "CA"
    },
    {
      city: "BORUP",
      state: "MN"
    },
    {
      city: "BOSCOBEL",
      state: "WI"
    },
    {
      city: "BOSQUE",
      state: "NM"
    },
    {
      city: "BOSQUE FARMS",
      state: "NM"
    },
    {
      city: "BOSS",
      state: "MO"
    },
    {
      city: "BOSSIER CITY",
      state: "LA"
    },
    {
      city: "BOSTIC",
      state: "NC"
    },
    {
      city: "BOSTON",
      state: "GA"
    },
    {
      city: "BOSTON",
      state: "IN"
    },
    {
      city: "BOSTON",
      state: "KY"
    },
    {
      city: "BOSTON",
      state: "MA"
    },
    {
      city: "BOSTON",
      state: "NY"
    },
    {
      city: "BOSTON",
      state: "VA"
    },
    {
      city: "BOSTWICK",
      state: "FL"
    },
    {
      city: "BOSTWICK",
      state: "GA"
    },
    {
      city: "BOSWELL",
      state: "IN"
    },
    {
      city: "BOSWELL",
      state: "OK"
    },
    {
      city: "BOSWELL",
      state: "PA"
    },
    {
      city: "BOSWORTH",
      state: "MO"
    },
    {
      city: "BOTHELL",
      state: "WA"
    },
    {
      city: "BOTKINS",
      state: "OH"
    },
    {
      city: "BOTSFORD",
      state: "CT"
    },
    {
      city: "BOTTINEAU",
      state: "ND"
    },
    {
      city: "BOUCKVILLE",
      state: "NY"
    },
    {
      city: "BOULDER",
      state: "CO"
    },
    {
      city: "BOULDER",
      state: "MT"
    },
    {
      city: "BOULDER",
      state: "UT"
    },
    {
      city: "BOULDER",
      state: "WY"
    },
    {
      city: "BOULDER CITY",
      state: "NV"
    },
    {
      city: "BOULDER CREEK",
      state: "CA"
    },
    {
      city: "BOULDER JUNCTION",
      state: "WI"
    },
    {
      city: "BOULEVARD",
      state: "CA"
    },
    {
      city: "BOUND BROOK",
      state: "NJ"
    },
    {
      city: "BOUNTIFUL",
      state: "UT"
    },
    {
      city: "BOURBON",
      state: "IN"
    },
    {
      city: "BOURBON",
      state: "MO"
    },
    {
      city: "BOURBONNAIS",
      state: "IL"
    },
    {
      city: "BOURG",
      state: "LA"
    },
    {
      city: "BOURNEVILLE",
      state: "OH"
    },
    {
      city: "BOUSE",
      state: "AZ"
    },
    {
      city: "BOUTON",
      state: "IA"
    },
    {
      city: "BOUTTE",
      state: "LA"
    },
    {
      city: "BOVARD",
      state: "PA"
    },
    {
      city: "BOVEY",
      state: "MN"
    },
    {
      city: "BOVILL",
      state: "ID"
    },
    {
      city: "BOVINA",
      state: "TX"
    },
    {
      city: "BOVINA CENTER",
      state: "NY"
    },
    {
      city: "BOW",
      state: "NH"
    },
    {
      city: "BOW",
      state: "WA"
    },
    {
      city: "BOWBELLS",
      state: "ND"
    },
    {
      city: "BOWDEN",
      state: "WV"
    },
    {
      city: "BOWDLE",
      state: "SD"
    },
    {
      city: "BOWDOIN",
      state: "ME"
    },
    {
      city: "BOWDOINHAM",
      state: "ME"
    },
    {
      city: "BOWDON",
      state: "GA"
    },
    {
      city: "BOWDON",
      state: "ND"
    },
    {
      city: "BOWDON JUNCTION",
      state: "GA"
    },
    {
      city: "BOWEN",
      state: "IL"
    },
    {
      city: "BOWERS",
      state: "PA"
    },
    {
      city: "BOWERSTON",
      state: "OH"
    },
    {
      city: "BOWERSVILLE",
      state: "GA"
    },
    {
      city: "BOWERSVILLE",
      state: "OH"
    },
    {
      city: "BOWIE",
      state: "AZ"
    },
    {
      city: "BOWIE",
      state: "MD"
    },
    {
      city: "BOWIE",
      state: "TX"
    },
    {
      city: "BOWLEGS",
      state: "OK"
    },
    {
      city: "BOWLER",
      state: "WI"
    },
    {
      city: "BOWLING GREEN",
      state: "FL"
    },
    {
      city: "BOWLING GREEN",
      state: "IN"
    },
    {
      city: "BOWLING GREEN",
      state: "KY"
    },
    {
      city: "BOWLING GREEN",
      state: "MO"
    },
    {
      city: "BOWLING GREEN",
      state: "OH"
    },
    {
      city: "BOWLING GREEN",
      state: "SC"
    },
    {
      city: "BOWLING GREEN",
      state: "VA"
    },
    {
      city: "BOWLUS",
      state: "MN"
    },
    {
      city: "BOWMAN",
      state: "GA"
    },
    {
      city: "BOWMAN",
      state: "ND"
    },
    {
      city: "BOWMAN",
      state: "SC"
    },
    {
      city: "BOWMANSTOWN",
      state: "PA"
    },
    {
      city: "BOWMANSVILLE",
      state: "NY"
    },
    {
      city: "BOWMANSVILLE",
      state: "PA"
    },
    {
      city: "BOWSTRING",
      state: "MN"
    },
    {
      city: "BOX ELDER",
      state: "MT"
    },
    {
      city: "BOX ELDER",
      state: "SD"
    },
    {
      city: "BOX SPRINGS",
      state: "GA"
    },
    {
      city: "BOXBOROUGH",
      state: "MA"
    },
    {
      city: "BOXFORD",
      state: "MA"
    },
    {
      city: "BOXHOLM",
      state: "IA"
    },
    {
      city: "BOYCE",
      state: "LA"
    },
    {
      city: "BOYCE",
      state: "VA"
    },
    {
      city: "BOYCEVILLE",
      state: "WI"
    },
    {
      city: "BOYD",
      state: "MN"
    },
    {
      city: "BOYD",
      state: "MT"
    },
    {
      city: "BOYD",
      state: "TX"
    },
    {
      city: "BOYD",
      state: "WI"
    },
    {
      city: "BOYDEN",
      state: "IA"
    },
    {
      city: "BOYDS",
      state: "MD"
    },
    {
      city: "BOYDS",
      state: "WA"
    },
    {
      city: "BOYDTON",
      state: "VA"
    },
    {
      city: "BOYERS",
      state: "PA"
    },
    {
      city: "BOYERTOWN",
      state: "PA"
    },
    {
      city: "BOYES",
      state: "MT"
    },
    {
      city: "BOYES HOT SPRINGS",
      state: "CA"
    },
    {
      city: "BOYKINS",
      state: "VA"
    },
    {
      city: "BOYLE",
      state: "MS"
    },
    {
      city: "BOYLSTON",
      state: "MA"
    },
    {
      city: "BOYNE CITY",
      state: "MI"
    },
    {
      city: "BOYNE FALLS",
      state: "MI"
    },
    {
      city: "BOYNTON",
      state: "OK"
    },
    {
      city: "BOYNTON",
      state: "PA"
    },
    {
      city: "BOYNTON BEACH",
      state: "FL"
    },
    {
      city: "BOYS RANCH",
      state: "TX"
    },
    {
      city: "BOYS TOWN",
      state: "NE"
    },
    {
      city: "BOZEMAN",
      state: "MT"
    },
    {
      city: "BOZMAN",
      state: "MD"
    },
    {
      city: "BOZRAH",
      state: "CT"
    },
    {
      city: "BRACEVILLE",
      state: "IL"
    },
    {
      city: "BRACEY",
      state: "VA"
    },
    {
      city: "BRACKENRIDGE",
      state: "PA"
    },
    {
      city: "BRACKETTVILLE",
      state: "TX"
    },
    {
      city: "BRACKNEY",
      state: "PA"
    },
    {
      city: "BRADDOCK",
      state: "ND"
    },
    {
      city: "BRADDOCK",
      state: "PA"
    },
    {
      city: "BRADDOCK HEIGHTS",
      state: "MD"
    },
    {
      city: "BRADDYVILLE",
      state: "IA"
    },
    {
      city: "BRADENTON",
      state: "FL"
    },
    {
      city: "BRADENTON BEACH",
      state: "FL"
    },
    {
      city: "BRADENVILLE",
      state: "PA"
    },
    {
      city: "BRADFORD",
      state: "AR"
    },
    {
      city: "BRADFORD",
      state: "IA"
    },
    {
      city: "BRADFORD",
      state: "IL"
    },
    {
      city: "BRADFORD",
      state: "ME"
    },
    {
      city: "BRADFORD",
      state: "NH"
    },
    {
      city: "BRADFORD",
      state: "NY"
    },
    {
      city: "BRADFORD",
      state: "OH"
    },
    {
      city: "BRADFORD",
      state: "PA"
    },
    {
      city: "BRADFORD",
      state: "RI"
    },
    {
      city: "BRADFORD",
      state: "TN"
    },
    {
      city: "BRADFORD",
      state: "VT"
    },
    {
      city: "BRADFORDSVILLE",
      state: "KY"
    },
    {
      city: "BRADFORDWOODS",
      state: "PA"
    },
    {
      city: "BRADGATE",
      state: "IA"
    },
    {
      city: "BRADLEY",
      state: "AR"
    },
    {
      city: "BRADLEY",
      state: "CA"
    },
    {
      city: "BRADLEY",
      state: "FL"
    },
    {
      city: "BRADLEY",
      state: "IL"
    },
    {
      city: "BRADLEY",
      state: "ME"
    },
    {
      city: "BRADLEY",
      state: "MI"
    },
    {
      city: "BRADLEY",
      state: "OK"
    },
    {
      city: "BRADLEY",
      state: "SC"
    },
    {
      city: "BRADLEY",
      state: "SD"
    },
    {
      city: "BRADLEY",
      state: "WV"
    },
    {
      city: "BRADLEY BEACH",
      state: "NJ"
    },
    {
      city: "BRADLEYVILLE",
      state: "MO"
    },
    {
      city: "BRADNER",
      state: "OH"
    },
    {
      city: "BRADSHAW",
      state: "NE"
    },
    {
      city: "BRADSHAW",
      state: "WV"
    },
    {
      city: "BRADY",
      state: "MT"
    },
    {
      city: "BRADY",
      state: "NE"
    },
    {
      city: "BRADY",
      state: "TX"
    },
    {
      city: "BRADY LAKE",
      state: "OH"
    },
    {
      city: "BRADYVILLE",
      state: "TN"
    },
    {
      city: "BRAGG CITY",
      state: "MO"
    },
    {
      city: "BRAGGADOCIO",
      state: "MO"
    },
    {
      city: "BRAGGS",
      state: "OK"
    },
    {
      city: "BRAHAM",
      state: "MN"
    },
    {
      city: "BRAIDWOOD",
      state: "IL"
    },
    {
      city: "BRAINARD",
      state: "NE"
    },
    {
      city: "BRAINARD",
      state: "NY"
    },
    {
      city: "BRAINARDSVILLE",
      state: "NY"
    },
    {
      city: "BRAINERD",
      state: "MN"
    },
    {
      city: "BRAINTREE",
      state: "MA"
    },
    {
      city: "BRAITHWAITE",
      state: "LA"
    },
    {
      city: "BRAMAN",
      state: "OK"
    },
    {
      city: "BRAMWELL",
      state: "WV"
    },
    {
      city: "BRANCH",
      state: "AR"
    },
    {
      city: "BRANCH",
      state: "LA"
    },
    {
      city: "BRANCH",
      state: "MI"
    },
    {
      city: "BRANCHDALE",
      state: "PA"
    },
    {
      city: "BRANCHLAND",
      state: "WV"
    },
    {
      city: "BRANCHPORT",
      state: "NY"
    },
    {
      city: "BRANCHTON",
      state: "PA"
    },
    {
      city: "BRANCHVILLE",
      state: "IN"
    },
    {
      city: "BRANCHVILLE",
      state: "NJ"
    },
    {
      city: "BRANCHVILLE",
      state: "SC"
    },
    {
      city: "BRANCHVILLE",
      state: "VA"
    },
    {
      city: "BRANDAMORE",
      state: "PA"
    },
    {
      city: "BRANDEIS",
      state: "CA"
    },
    {
      city: "BRANDENBURG",
      state: "KY"
    },
    {
      city: "BRANDON",
      state: "FL"
    },
    {
      city: "BRANDON",
      state: "IA"
    },
    {
      city: "BRANDON",
      state: "MN"
    },
    {
      city: "BRANDON",
      state: "MS"
    },
    {
      city: "BRANDON",
      state: "SD"
    },
    {
      city: "BRANDON",
      state: "TX"
    },
    {
      city: "BRANDON",
      state: "VT"
    },
    {
      city: "BRANDON",
      state: "WI"
    },
    {
      city: "BRANDSVILLE",
      state: "MO"
    },
    {
      city: "BRANDT",
      state: "SD"
    },
    {
      city: "BRANDY CAMP",
      state: "PA"
    },
    {
      city: "BRANDY STATION",
      state: "VA"
    },
    {
      city: "BRANDYWINE",
      state: "MD"
    },
    {
      city: "BRANDYWINE",
      state: "WV"
    },
    {
      city: "BRANFORD",
      state: "CT"
    },
    {
      city: "BRANFORD",
      state: "FL"
    },
    {
      city: "BRANSCOMB",
      state: "CA"
    },
    {
      city: "BRANSON",
      state: "CO"
    },
    {
      city: "BRANSON",
      state: "MO"
    },
    {
      city: "BRANT",
      state: "MI"
    },
    {
      city: "BRANT",
      state: "NY"
    },
    {
      city: "BRANT LAKE",
      state: "NY"
    },
    {
      city: "BRANT ROCK",
      state: "MA"
    },
    {
      city: "BRANTINGHAM",
      state: "NY"
    },
    {
      city: "BRANTLEY",
      state: "AL"
    },
    {
      city: "BRANTWOOD",
      state: "WI"
    },
    {
      city: "BRASELTON",
      state: "GA"
    },
    {
      city: "BRASHEAR",
      state: "MO"
    },
    {
      city: "BRASHEAR",
      state: "TX"
    },
    {
      city: "BRASHER FALLS",
      state: "NY"
    },
    {
      city: "BRASSTOWN",
      state: "NC"
    },
    {
      city: "BRATTLEBORO",
      state: "VT"
    },
    {
      city: "BRAVE",
      state: "PA"
    },
    {
      city: "BRAWLEY",
      state: "CA"
    },
    {
      city: "BRAXTON",
      state: "MS"
    },
    {
      city: "BRAYMER",
      state: "MO"
    },
    {
      city: "BRAYTON",
      state: "IA"
    },
    {
      city: "BRAZIL",
      state: "IN"
    },
    {
      city: "BRAZORIA",
      state: "TX"
    },
    {
      city: "BREA",
      state: "CA"
    },
    {
      city: "BREAKS",
      state: "VA"
    },
    {
      city: "BREAUX BRIDGE",
      state: "LA"
    },
    {
      city: "BRECKENRIDGE",
      state: "CO"
    },
    {
      city: "BRECKENRIDGE",
      state: "MI"
    },
    {
      city: "BRECKENRIDGE",
      state: "MN"
    },
    {
      city: "BRECKENRIDGE",
      state: "MO"
    },
    {
      city: "BRECKENRIDGE",
      state: "TX"
    },
    {
      city: "BRECKSVILLE",
      state: "OH"
    },
    {
      city: "BREDA",
      state: "IA"
    },
    {
      city: "BREEDEN",
      state: "WV"
    },
    {
      city: "BREEDING",
      state: "KY"
    },
    {
      city: "BREEDSVILLE",
      state: "MI"
    },
    {
      city: "BREESE",
      state: "IL"
    },
    {
      city: "BREESPORT",
      state: "NY"
    },
    {
      city: "BREEZEWOOD",
      state: "PA"
    },
    {
      city: "BREEZY POINT",
      state: "NY"
    },
    {
      city: "BREINIGSVILLE",
      state: "PA"
    },
    {
      city: "BREMEN",
      state: "AL"
    },
    {
      city: "BREMEN",
      state: "GA"
    },
    {
      city: "BREMEN",
      state: "IN"
    },
    {
      city: "BREMEN",
      state: "KS"
    },
    {
      city: "BREMEN",
      state: "KY"
    },
    {
      city: "BREMEN",
      state: "ME"
    },
    {
      city: "BREMEN",
      state: "OH"
    },
    {
      city: "BREMERTON",
      state: "WA"
    },
    {
      city: "BREMO BLUFF",
      state: "VA"
    },
    {
      city: "BREMOND",
      state: "TX"
    },
    {
      city: "BRENHAM",
      state: "TX"
    },
    {
      city: "BRENT",
      state: "AL"
    },
    {
      city: "BRENTFORD",
      state: "SD"
    },
    {
      city: "BRENTON",
      state: "WV"
    },
    {
      city: "BRENTWOOD",
      state: "CA"
    },
    {
      city: "BRENTWOOD",
      state: "MD"
    },
    {
      city: "BRENTWOOD",
      state: "NY"
    },
    {
      city: "BRENTWOOD",
      state: "TN"
    },
    {
      city: "BRETHREN",
      state: "MI"
    },
    {
      city: "BRETTON WOODS",
      state: "NH"
    },
    {
      city: "BRETZ",
      state: "WV"
    },
    {
      city: "BREVARD",
      state: "NC"
    },
    {
      city: "BREVIG MISSION",
      state: "AK"
    },
    {
      city: "BREWER",
      state: "ME"
    },
    {
      city: "BREWERTON",
      state: "NY"
    },
    {
      city: "BREWSTER",
      state: "KS"
    },
    {
      city: "BREWSTER",
      state: "MA"
    },
    {
      city: "BREWSTER",
      state: "MN"
    },
    {
      city: "BREWSTER",
      state: "NE"
    },
    {
      city: "BREWSTER",
      state: "NY"
    },
    {
      city: "BREWSTER",
      state: "OH"
    },
    {
      city: "BREWSTER",
      state: "WA"
    },
    {
      city: "BREWTON",
      state: "AL"
    },
    {
      city: "BRIAN HEAD",
      state: "UT"
    },
    {
      city: "BRIARCLIFF MANOR",
      state: "NY"
    },
    {
      city: "BRICE",
      state: "OH"
    },
    {
      city: "BRICELYN",
      state: "MN"
    },
    {
      city: "BRICEVILLE",
      state: "TN"
    },
    {
      city: "BRICK",
      state: "NJ"
    },
    {
      city: "BRICKEYS",
      state: "AR"
    },
    {
      city: "BRIDAL VEIL",
      state: "OR"
    },
    {
      city: "BRIDGE CITY",
      state: "TX"
    },
    {
      city: "BRIDGEHAMPTON",
      state: "NY"
    },
    {
      city: "BRIDGEPORT",
      state: "AL"
    },
    {
      city: "BRIDGEPORT",
      state: "CA"
    },
    {
      city: "BRIDGEPORT",
      state: "CT"
    },
    {
      city: "BRIDGEPORT",
      state: "IL"
    },
    {
      city: "BRIDGEPORT",
      state: "MI"
    },
    {
      city: "BRIDGEPORT",
      state: "NE"
    },
    {
      city: "BRIDGEPORT",
      state: "NJ"
    },
    {
      city: "BRIDGEPORT",
      state: "NY"
    },
    {
      city: "BRIDGEPORT",
      state: "OH"
    },
    {
      city: "BRIDGEPORT",
      state: "OR"
    },
    {
      city: "BRIDGEPORT",
      state: "PA"
    },
    {
      city: "BRIDGEPORT",
      state: "TX"
    },
    {
      city: "BRIDGEPORT",
      state: "WA"
    },
    {
      city: "BRIDGEPORT",
      state: "WV"
    },
    {
      city: "BRIDGER",
      state: "MT"
    },
    {
      city: "BRIDGETON",
      state: "IN"
    },
    {
      city: "BRIDGETON",
      state: "MO"
    },
    {
      city: "BRIDGETON",
      state: "NC"
    },
    {
      city: "BRIDGETON",
      state: "NJ"
    },
    {
      city: "BRIDGEVIEW",
      state: "IL"
    },
    {
      city: "BRIDGEVILLE",
      state: "CA"
    },
    {
      city: "BRIDGEVILLE",
      state: "DE"
    },
    {
      city: "BRIDGEVILLE",
      state: "PA"
    },
    {
      city: "BRIDGEWATER",
      state: "CT"
    },
    {
      city: "BRIDGEWATER",
      state: "IA"
    },
    {
      city: "BRIDGEWATER",
      state: "MA"
    },
    {
      city: "BRIDGEWATER",
      state: "ME"
    },
    {
      city: "BRIDGEWATER",
      state: "MI"
    },
    {
      city: "BRIDGEWATER",
      state: "NJ"
    },
    {
      city: "BRIDGEWATER",
      state: "NY"
    },
    {
      city: "BRIDGEWATER",
      state: "SD"
    },
    {
      city: "BRIDGEWATER",
      state: "VA"
    },
    {
      city: "BRIDGEWATER",
      state: "VT"
    },
    {
      city: "BRIDGEWATER CORNERS",
      state: "VT"
    },
    {
      city: "BRIDGMAN",
      state: "MI"
    },
    {
      city: "BRIDGTON",
      state: "ME"
    },
    {
      city: "BRIDPORT",
      state: "VT"
    },
    {
      city: "BRIELLE",
      state: "NJ"
    },
    {
      city: "BRIER HILL",
      state: "NY"
    },
    {
      city: "BRIER HILL",
      state: "PA"
    },
    {
      city: "BRIERFIELD",
      state: "AL"
    },
    {
      city: "BRIGANTINE",
      state: "NJ"
    },
    {
      city: "BRIGGS",
      state: "TX"
    },
    {
      city: "BRIGGSDALE",
      state: "CO"
    },
    {
      city: "BRIGGSVILLE",
      state: "AR"
    },
    {
      city: "BRIGGSVILLE",
      state: "WI"
    },
    {
      city: "BRIGHAM CITY",
      state: "UT"
    },
    {
      city: "BRIGHTON",
      state: "CO"
    },
    {
      city: "BRIGHTON",
      state: "IA"
    },
    {
      city: "BRIGHTON",
      state: "IL"
    },
    {
      city: "BRIGHTON",
      state: "MA"
    },
    {
      city: "BRIGHTON",
      state: "MI"
    },
    {
      city: "BRIGHTON",
      state: "MO"
    },
    {
      city: "BRIGHTON",
      state: "TN"
    },
    {
      city: "BRIGHTWATERS",
      state: "NY"
    },
    {
      city: "BRIGHTWOOD",
      state: "OR"
    },
    {
      city: "BRIGHTWOOD",
      state: "VA"
    },
    {
      city: "BRILL",
      state: "WI"
    },
    {
      city: "BRILLIANT",
      state: "AL"
    },
    {
      city: "BRILLIANT",
      state: "OH"
    },
    {
      city: "BRILLION",
      state: "WI"
    },
    {
      city: "BRIMFIELD",
      state: "IL"
    },
    {
      city: "BRIMFIELD",
      state: "MA"
    },
    {
      city: "BRIMHALL",
      state: "NM"
    },
    {
      city: "BRIMLEY",
      state: "MI"
    },
    {
      city: "BRIMSON",
      state: "MN"
    },
    {
      city: "BRINGHURST",
      state: "IN"
    },
    {
      city: "BRINKHAVEN",
      state: "OH"
    },
    {
      city: "BRINKLEY",
      state: "AR"
    },
    {
      city: "BRINKLOW",
      state: "MD"
    },
    {
      city: "BRINKTOWN",
      state: "MO"
    },
    {
      city: "BRINNON",
      state: "WA"
    },
    {
      city: "BRINSON",
      state: "GA"
    },
    {
      city: "BRISBANE",
      state: "CA"
    },
    {
      city: "BRISBIN",
      state: "PA"
    },
    {
      city: "BRISCOE",
      state: "TX"
    },
    {
      city: "BRISTOL",
      state: "CT"
    },
    {
      city: "BRISTOL",
      state: "FL"
    },
    {
      city: "BRISTOL",
      state: "GA"
    },
    {
      city: "BRISTOL",
      state: "IL"
    },
    {
      city: "BRISTOL",
      state: "IN"
    },
    {
      city: "BRISTOL",
      state: "ME"
    },
    {
      city: "BRISTOL",
      state: "NH"
    },
    {
      city: "BRISTOL",
      state: "PA"
    },
    {
      city: "BRISTOL",
      state: "RI"
    },
    {
      city: "BRISTOL",
      state: "SD"
    },
    {
      city: "BRISTOL",
      state: "TN"
    },
    {
      city: "BRISTOL",
      state: "VA"
    },
    {
      city: "BRISTOL",
      state: "VT"
    },
    {
      city: "BRISTOL",
      state: "WI"
    },
    {
      city: "BRISTOLVILLE",
      state: "OH"
    },
    {
      city: "BRISTOW",
      state: "IA"
    },
    {
      city: "BRISTOW",
      state: "IN"
    },
    {
      city: "BRISTOW",
      state: "NE"
    },
    {
      city: "BRISTOW",
      state: "OK"
    },
    {
      city: "BRISTOW",
      state: "VA"
    },
    {
      city: "BRITT",
      state: "IA"
    },
    {
      city: "BRITT",
      state: "MN"
    },
    {
      city: "BRITTANY",
      state: "LA"
    },
    {
      city: "BRITTON",
      state: "MI"
    },
    {
      city: "BRITTON",
      state: "SD"
    },
    {
      city: "BRIXEY",
      state: "MO"
    },
    {
      city: "BROAD BROOK",
      state: "CT"
    },
    {
      city: "BROAD RUN",
      state: "VA"
    },
    {
      city: "BROAD TOP",
      state: "PA"
    },
    {
      city: "BROADALBIN",
      state: "NY"
    },
    {
      city: "BROADBENT",
      state: "OR"
    },
    {
      city: "BROADDUS",
      state: "TX"
    },
    {
      city: "BROADLANDS",
      state: "IL"
    },
    {
      city: "BROADUS",
      state: "MT"
    },
    {
      city: "BROADVIEW",
      state: "IL"
    },
    {
      city: "BROADVIEW",
      state: "MT"
    },
    {
      city: "BROADVIEW",
      state: "NM"
    },
    {
      city: "BROADVIEW HEIGHTS",
      state: "OH"
    },
    {
      city: "BROADWATER",
      state: "NE"
    },
    {
      city: "BROADWAY",
      state: "NC"
    },
    {
      city: "BROADWAY",
      state: "NJ"
    },
    {
      city: "BROADWAY",
      state: "OH"
    },
    {
      city: "BROADWAY",
      state: "VA"
    },
    {
      city: "BROCK",
      state: "NE"
    },
    {
      city: "BROCKET",
      state: "ND"
    },
    {
      city: "BROCKPORT",
      state: "NY"
    },
    {
      city: "BROCKPORT",
      state: "PA"
    },
    {
      city: "BROCKTON",
      state: "MA"
    },
    {
      city: "BROCKTON",
      state: "MT"
    },
    {
      city: "BROCKTON",
      state: "PA"
    },
    {
      city: "BROCKWAY",
      state: "PA"
    },
    {
      city: "BROCKWELL",
      state: "AR"
    },
    {
      city: "BROCTON",
      state: "IL"
    },
    {
      city: "BROCTON",
      state: "NY"
    },
    {
      city: "BRODHEAD",
      state: "KY"
    },
    {
      city: "BRODHEAD",
      state: "WI"
    },
    {
      city: "BRODHEADSVILLE",
      state: "PA"
    },
    {
      city: "BRODNAX",
      state: "VA"
    },
    {
      city: "BROGAN",
      state: "OR"
    },
    {
      city: "BROGUE",
      state: "PA"
    },
    {
      city: "BROHMAN",
      state: "MI"
    },
    {
      city: "BROKAW",
      state: "WI"
    },
    {
      city: "BROKEN ARROW",
      state: "OK"
    },
    {
      city: "BROKEN BOW",
      state: "NE"
    },
    {
      city: "BROKEN BOW",
      state: "OK"
    },
    {
      city: "BRONAUGH",
      state: "MO"
    },
    {
      city: "BRONSON",
      state: "FL"
    },
    {
      city: "BRONSON",
      state: "IA"
    },
    {
      city: "BRONSON",
      state: "KS"
    },
    {
      city: "BRONSON",
      state: "MI"
    },
    {
      city: "BRONSON",
      state: "TX"
    },
    {
      city: "BRONSTON",
      state: "KY"
    },
    {
      city: "BRONTE",
      state: "TX"
    },
    {
      city: "BRONWOOD",
      state: "GA"
    },
    {
      city: "BRONX",
      state: "NY"
    },
    {
      city: "BRONXVILLE",
      state: "NY"
    },
    {
      city: "BROOK",
      state: "IN"
    },
    {
      city: "BROOK PARK",
      state: "MN"
    },
    {
      city: "BROOKDALE",
      state: "CA"
    },
    {
      city: "BROOKELAND",
      state: "TX"
    },
    {
      city: "BROOKER",
      state: "FL"
    },
    {
      city: "BROOKESMITH",
      state: "TX"
    },
    {
      city: "BROOKEVILLE",
      state: "MD"
    },
    {
      city: "BROOKFIELD",
      state: "CT"
    },
    {
      city: "BROOKFIELD",
      state: "GA"
    },
    {
      city: "BROOKFIELD",
      state: "IL"
    },
    {
      city: "BROOKFIELD",
      state: "MA"
    },
    {
      city: "BROOKFIELD",
      state: "MO"
    },
    {
      city: "BROOKFIELD",
      state: "NY"
    },
    {
      city: "BROOKFIELD",
      state: "OH"
    },
    {
      city: "BROOKFIELD",
      state: "VT"
    },
    {
      city: "BROOKFIELD",
      state: "WI"
    },
    {
      city: "BROOKHAVEN",
      state: "MS"
    },
    {
      city: "BROOKHAVEN",
      state: "NY"
    },
    {
      city: "BROOKHAVEN",
      state: "PA"
    },
    {
      city: "BROOKINGS",
      state: "OR"
    },
    {
      city: "BROOKINGS",
      state: "SD"
    },
    {
      city: "BROOKLAND",
      state: "AR"
    },
    {
      city: "BROOKLANDVILLE",
      state: "MD"
    },
    {
      city: "BROOKLET",
      state: "GA"
    },
    {
      city: "BROOKLIN",
      state: "ME"
    },
    {
      city: "BROOKLINE",
      state: "MA"
    },
    {
      city: "BROOKLINE",
      state: "MO"
    },
    {
      city: "BROOKLINE",
      state: "NH"
    },
    {
      city: "BROOKLINE VILLAGE",
      state: "MA"
    },
    {
      city: "BROOKLYN",
      state: "AL"
    },
    {
      city: "BROOKLYN",
      state: "CT"
    },
    {
      city: "BROOKLYN",
      state: "IA"
    },
    {
      city: "BROOKLYN",
      state: "IN"
    },
    {
      city: "BROOKLYN",
      state: "MD"
    },
    {
      city: "BROOKLYN",
      state: "MI"
    },
    {
      city: "BROOKLYN",
      state: "MS"
    },
    {
      city: "BROOKLYN",
      state: "NY"
    },
    {
      city: "BROOKLYN",
      state: "PA"
    },
    {
      city: "BROOKLYN",
      state: "WI"
    },
    {
      city: "BROOKNEAL",
      state: "VA"
    },
    {
      city: "BROOKPARK",
      state: "OH"
    },
    {
      city: "BROOKPORT",
      state: "IL"
    },
    {
      city: "BROOKS",
      state: "CA"
    },
    {
      city: "BROOKS",
      state: "GA"
    },
    {
      city: "BROOKS",
      state: "KY"
    },
    {
      city: "BROOKS",
      state: "ME"
    },
    {
      city: "BROOKS",
      state: "MN"
    },
    {
      city: "BROOKSHIRE",
      state: "TX"
    },
    {
      city: "BROOKSIDE",
      state: "AL"
    },
    {
      city: "BROOKSIDE",
      state: "NJ"
    },
    {
      city: "BROOKSTON",
      state: "IN"
    },
    {
      city: "BROOKSTON",
      state: "MN"
    },
    {
      city: "BROOKSTON",
      state: "TX"
    },
    {
      city: "BROOKSVILLE",
      state: "FL"
    },
    {
      city: "BROOKSVILLE",
      state: "KY"
    },
    {
      city: "BROOKSVILLE",
      state: "ME"
    },
    {
      city: "BROOKSVILLE",
      state: "MS"
    },
    {
      city: "BROOKTON",
      state: "ME"
    },
    {
      city: "BROOKTONDALE",
      state: "NY"
    },
    {
      city: "BROOKVILLE",
      state: "IN"
    },
    {
      city: "BROOKVILLE",
      state: "KS"
    },
    {
      city: "BROOKVILLE",
      state: "OH"
    },
    {
      city: "BROOKVILLE",
      state: "PA"
    },
    {
      city: "BROOKWOOD",
      state: "AL"
    },
    {
      city: "BROOMALL",
      state: "PA"
    },
    {
      city: "BROOMES ISLAND",
      state: "MD"
    },
    {
      city: "BROOMFIELD",
      state: "CO"
    },
    {
      city: "BROOTEN",
      state: "MN"
    },
    {
      city: "BROSELEY",
      state: "MO"
    },
    {
      city: "BROUGHTON",
      state: "IL"
    },
    {
      city: "BROUSSARD",
      state: "LA"
    },
    {
      city: "BROWERVILLE",
      state: "MN"
    },
    {
      city: "BROWN CITY",
      state: "MI"
    },
    {
      city: "BROWNELL",
      state: "KS"
    },
    {
      city: "BROWNFIELD",
      state: "ME"
    },
    {
      city: "BROWNFIELD",
      state: "PA"
    },
    {
      city: "BROWNFIELD",
      state: "TX"
    },
    {
      city: "BROWNING",
      state: "IL"
    },
    {
      city: "BROWNING",
      state: "MO"
    },
    {
      city: "BROWNING",
      state: "MT"
    },
    {
      city: "BROWNS",
      state: "IL"
    },
    {
      city: "BROWNS MILLS",
      state: "NJ"
    },
    {
      city: "BROWNS SUMMIT",
      state: "NC"
    },
    {
      city: "BROWNS VALLEY",
      state: "CA"
    },
    {
      city: "BROWNS VALLEY",
      state: "MN"
    },
    {
      city: "BROWNSBORO",
      state: "AL"
    },
    {
      city: "BROWNSBORO",
      state: "TX"
    },
    {
      city: "BROWNSBURG",
      state: "IN"
    },
    {
      city: "BROWNSBURG",
      state: "VA"
    },
    {
      city: "BROWNSDALE",
      state: "MN"
    },
    {
      city: "BROWNSTOWN",
      state: "IL"
    },
    {
      city: "BROWNSTOWN",
      state: "IN"
    },
    {
      city: "BROWNSTOWN",
      state: "PA"
    },
    {
      city: "BROWNSVILLE",
      state: "CA"
    },
    {
      city: "BROWNSVILLE",
      state: "IN"
    },
    {
      city: "BROWNSVILLE",
      state: "KY"
    },
    {
      city: "BROWNSVILLE",
      state: "MD"
    },
    {
      city: "BROWNSVILLE",
      state: "MN"
    },
    {
      city: "BROWNSVILLE",
      state: "OH"
    },
    {
      city: "BROWNSVILLE",
      state: "OR"
    },
    {
      city: "BROWNSVILLE",
      state: "PA"
    },
    {
      city: "BROWNSVILLE",
      state: "TN"
    },
    {
      city: "BROWNSVILLE",
      state: "TX"
    },
    {
      city: "BROWNSVILLE",
      state: "VT"
    },
    {
      city: "BROWNSVILLE",
      state: "WI"
    },
    {
      city: "BROWNTON",
      state: "MN"
    },
    {
      city: "BROWNTOWN",
      state: "WI"
    },
    {
      city: "BROWNVILLE",
      state: "ME"
    },
    {
      city: "BROWNVILLE",
      state: "NE"
    },
    {
      city: "BROWNVILLE",
      state: "NY"
    },
    {
      city: "BROWNVILLE JUNCTION",
      state: "ME"
    },
    {
      city: "BROWNWOOD",
      state: "TX"
    },
    {
      city: "BROXTON",
      state: "GA"
    },
    {
      city: "BRUCE",
      state: "MS"
    },
    {
      city: "BRUCE",
      state: "SD"
    },
    {
      city: "BRUCE",
      state: "WI"
    },
    {
      city: "BRUCE CROSSING",
      state: "MI"
    },
    {
      city: "BRUCETON",
      state: "TN"
    },
    {
      city: "BRUCETON MILLS",
      state: "WV"
    },
    {
      city: "BRUCEVILLE",
      state: "IN"
    },
    {
      city: "BRUCEVILLE",
      state: "TX"
    },
    {
      city: "BRUIN",
      state: "PA"
    },
    {
      city: "BRUINGTON",
      state: "VA"
    },
    {
      city: "BRULE",
      state: "NE"
    },
    {
      city: "BRULE",
      state: "WI"
    },
    {
      city: "BRUMLEY",
      state: "MO"
    },
    {
      city: "BRUNDIDGE",
      state: "AL"
    },
    {
      city: "BRUNEAU",
      state: "ID"
    },
    {
      city: "BRUNER",
      state: "MO"
    },
    {
      city: "BRUNI",
      state: "TX"
    },
    {
      city: "BRUNING",
      state: "NE"
    },
    {
      city: "BRUNO",
      state: "MN"
    },
    {
      city: "BRUNO",
      state: "NE"
    },
    {
      city: "BRUNO",
      state: "WV"
    },
    {
      city: "BRUNSON",
      state: "SC"
    },
    {
      city: "BRUNSVILLE",
      state: "IA"
    },
    {
      city: "BRUNSWICK",
      state: "GA"
    },
    {
      city: "BRUNSWICK",
      state: "MD"
    },
    {
      city: "BRUNSWICK",
      state: "ME"
    },
    {
      city: "BRUNSWICK",
      state: "MO"
    },
    {
      city: "BRUNSWICK",
      state: "NC"
    },
    {
      city: "BRUNSWICK",
      state: "NE"
    },
    {
      city: "BRUNSWICK",
      state: "OH"
    },
    {
      city: "BRUNSWICK",
      state: "TN"
    },
    {
      city: "BRUSH",
      state: "CO"
    },
    {
      city: "BRUSH CREEK",
      state: "TN"
    },
    {
      city: "BRUSH PRAIRIE",
      state: "WA"
    },
    {
      city: "BRUSH VALLEY",
      state: "PA"
    },
    {
      city: "BRUSHTON",
      state: "NY"
    },
    {
      city: "BRUSLY",
      state: "LA"
    },
    {
      city: "BRUSSELS",
      state: "IL"
    },
    {
      city: "BRUSSELS",
      state: "WI"
    },
    {
      city: "BRUTUS",
      state: "MI"
    },
    {
      city: "BRYAN",
      state: "OH"
    },
    {
      city: "BRYAN",
      state: "TX"
    },
    {
      city: "BRYANS ROAD",
      state: "MD"
    },
    {
      city: "BRYANT",
      state: "AL"
    },
    {
      city: "BRYANT",
      state: "AR"
    },
    {
      city: "BRYANT",
      state: "IA"
    },
    {
      city: "BRYANT",
      state: "IL"
    },
    {
      city: "BRYANT",
      state: "IN"
    },
    {
      city: "BRYANT",
      state: "SD"
    },
    {
      city: "BRYANT",
      state: "WI"
    },
    {
      city: "BRYANT POND",
      state: "ME"
    },
    {
      city: "BRYANTOWN",
      state: "MD"
    },
    {
      city: "BRYANTS STORE",
      state: "KY"
    },
    {
      city: "BRYANTSVILLE",
      state: "KY"
    },
    {
      city: "BRYANTVILLE",
      state: "MA"
    },
    {
      city: "BRYCE",
      state: "UT"
    },
    {
      city: "BRYCEVILLE",
      state: "FL"
    },
    {
      city: "BRYN ATHYN",
      state: "PA"
    },
    {
      city: "BRYN MAWR",
      state: "CA"
    },
    {
      city: "BRYN MAWR",
      state: "PA"
    },
    {
      city: "BRYSON",
      state: "TX"
    },
    {
      city: "BRYSON CITY",
      state: "NC"
    },
    {
      city: "BUCHANAN",
      state: "GA"
    },
    {
      city: "BUCHANAN",
      state: "MI"
    },
    {
      city: "BUCHANAN",
      state: "ND"
    },
    {
      city: "BUCHANAN",
      state: "NY"
    },
    {
      city: "BUCHANAN",
      state: "TN"
    },
    {
      city: "BUCHANAN",
      state: "VA"
    },
    {
      city: "BUCHANAN DAM",
      state: "TX"
    },
    {
      city: "BUCHTEL",
      state: "OH"
    },
    {
      city: "BUCK CREEK",
      state: "IN"
    },
    {
      city: "BUCK HILL FALLS",
      state: "PA"
    },
    {
      city: "BUCKATUNNA",
      state: "MS"
    },
    {
      city: "BUCKEYE",
      state: "AZ"
    },
    {
      city: "BUCKEYE",
      state: "IA"
    },
    {
      city: "BUCKEYE",
      state: "WV"
    },
    {
      city: "BUCKEYE LAKE",
      state: "OH"
    },
    {
      city: "BUCKEYSTOWN",
      state: "MD"
    },
    {
      city: "BUCKFIELD",
      state: "ME"
    },
    {
      city: "BUCKHANNON",
      state: "WV"
    },
    {
      city: "BUCKHEAD",
      state: "GA"
    },
    {
      city: "BUCKHOLTS",
      state: "TX"
    },
    {
      city: "BUCKHORN",
      state: "KY"
    },
    {
      city: "BUCKHORN",
      state: "NM"
    },
    {
      city: "BUCKINGHAM",
      state: "IA"
    },
    {
      city: "BUCKINGHAM",
      state: "IL"
    },
    {
      city: "BUCKINGHAM",
      state: "PA"
    },
    {
      city: "BUCKINGHAM",
      state: "VA"
    },
    {
      city: "BUCKLAND",
      state: "AK"
    },
    {
      city: "BUCKLAND",
      state: "MA"
    },
    {
      city: "BUCKLAND",
      state: "OH"
    },
    {
      city: "BUCKLEY",
      state: "IL"
    },
    {
      city: "BUCKLEY",
      state: "MI"
    },
    {
      city: "BUCKLEY",
      state: "WA"
    },
    {
      city: "BUCKLIN",
      state: "KS"
    },
    {
      city: "BUCKLIN",
      state: "MO"
    },
    {
      city: "BUCKMAN",
      state: "MN"
    },
    {
      city: "BUCKNER",
      state: "AR"
    },
    {
      city: "BUCKNER",
      state: "IL"
    },
    {
      city: "BUCKNER",
      state: "KY"
    },
    {
      city: "BUCKNER",
      state: "MO"
    },
    {
      city: "BUCKS",
      state: "AL"
    },
    {
      city: "BUCKSKIN",
      state: "IN"
    },
    {
      city: "BUCKSPORT",
      state: "ME"
    },
    {
      city: "BUCODA",
      state: "WA"
    },
    {
      city: "BUCYRUS",
      state: "KS"
    },
    {
      city: "BUCYRUS",
      state: "MO"
    },
    {
      city: "BUCYRUS",
      state: "OH"
    },
    {
      city: "BUD",
      state: "WV"
    },
    {
      city: "BUDA",
      state: "IL"
    },
    {
      city: "BUDA",
      state: "TX"
    },
    {
      city: "BUDD LAKE",
      state: "NJ"
    },
    {
      city: "BUDE",
      state: "MS"
    },
    {
      city: "BUELLTON",
      state: "CA"
    },
    {
      city: "BUENA",
      state: "NJ"
    },
    {
      city: "BUENA",
      state: "WA"
    },
    {
      city: "BUENA PARK",
      state: "CA"
    },
    {
      city: "BUENA VISTA",
      state: "CO"
    },
    {
      city: "BUENA VISTA",
      state: "GA"
    },
    {
      city: "BUENA VISTA",
      state: "NM"
    },
    {
      city: "BUENA VISTA",
      state: "PA"
    },
    {
      city: "BUENA VISTA",
      state: "VA"
    },
    {
      city: "BUFFALO",
      state: "IA"
    },
    {
      city: "BUFFALO",
      state: "IL"
    },
    {
      city: "BUFFALO",
      state: "IN"
    },
    {
      city: "BUFFALO",
      state: "KS"
    },
    {
      city: "BUFFALO",
      state: "KY"
    },
    {
      city: "BUFFALO",
      state: "MN"
    },
    {
      city: "BUFFALO",
      state: "MO"
    },
    {
      city: "BUFFALO",
      state: "ND"
    },
    {
      city: "BUFFALO",
      state: "NY"
    },
    {
      city: "BUFFALO",
      state: "OH"
    },
    {
      city: "BUFFALO",
      state: "OK"
    },
    {
      city: "BUFFALO",
      state: "SC"
    },
    {
      city: "BUFFALO",
      state: "SD"
    },
    {
      city: "BUFFALO",
      state: "TX"
    },
    {
      city: "BUFFALO",
      state: "WV"
    },
    {
      city: "BUFFALO",
      state: "WY"
    },
    {
      city: "BUFFALO CENTER",
      state: "IA"
    },
    {
      city: "BUFFALO CREEK",
      state: "CO"
    },
    {
      city: "BUFFALO GAP",
      state: "SD"
    },
    {
      city: "BUFFALO GAP",
      state: "TX"
    },
    {
      city: "BUFFALO GROVE",
      state: "IL"
    },
    {
      city: "BUFFALO JUNCTION",
      state: "VA"
    },
    {
      city: "BUFFALO LAKE",
      state: "MN"
    },
    {
      city: "BUFFALO MILLS",
      state: "PA"
    },
    {
      city: "BUFFALO PRAIRIE",
      state: "IL"
    },
    {
      city: "BUFFALO VALLEY",
      state: "TN"
    },
    {
      city: "BUFORD",
      state: "GA"
    },
    {
      city: "BUFORD",
      state: "WY"
    },
    {
      city: "BUHL",
      state: "AL"
    },
    {
      city: "BUHL",
      state: "ID"
    },
    {
      city: "BUHL",
      state: "MN"
    },
    {
      city: "BUHLER",
      state: "KS"
    },
    {
      city: "BUIES CREEK",
      state: "NC"
    },
    {
      city: "BULAN",
      state: "KY"
    },
    {
      city: "BULGER",
      state: "PA"
    },
    {
      city: "BULL SHOALS",
      state: "AR"
    },
    {
      city: "BULLARD",
      state: "TX"
    },
    {
      city: "BULLHEAD",
      state: "SD"
    },
    {
      city: "BULLHEAD CITY",
      state: "AZ"
    },
    {
      city: "BULLOCK",
      state: "NC"
    },
    {
      city: "BULLS GAP",
      state: "TN"
    },
    {
      city: "BULLVILLE",
      state: "NY"
    },
    {
      city: "BULPITT",
      state: "IL"
    },
    {
      city: "BULVERDE",
      state: "TX"
    },
    {
      city: "BUMPASS",
      state: "VA"
    },
    {
      city: "BUMPUS MILLS",
      state: "TN"
    },
    {
      city: "BUNA",
      state: "TX"
    },
    {
      city: "BUNCETON",
      state: "MO"
    },
    {
      city: "BUNCH",
      state: "OK"
    },
    {
      city: "BUNCOMBE",
      state: "IL"
    },
    {
      city: "BUNKER",
      state: "MO"
    },
    {
      city: "BUNKER HILL",
      state: "IL"
    },
    {
      city: "BUNKER HILL",
      state: "IN"
    },
    {
      city: "BUNKER HILL",
      state: "KS"
    },
    {
      city: "BUNKER HILL",
      state: "WV"
    },
    {
      city: "BUNKERVILLE",
      state: "NV"
    },
    {
      city: "BUNKIE",
      state: "LA"
    },
    {
      city: "BUNN",
      state: "NC"
    },
    {
      city: "BUNNELL",
      state: "FL"
    },
    {
      city: "BUNNLEVEL",
      state: "NC"
    },
    {
      city: "BUNOLA",
      state: "PA"
    },
    {
      city: "BURAS",
      state: "LA"
    },
    {
      city: "BURBANK",
      state: "CA"
    },
    {
      city: "BURBANK",
      state: "IL"
    },
    {
      city: "BURBANK",
      state: "OH"
    },
    {
      city: "BURBANK",
      state: "OK"
    },
    {
      city: "BURBANK",
      state: "SD"
    },
    {
      city: "BURBANK",
      state: "WA"
    },
    {
      city: "BURCHARD",
      state: "NE"
    },
    {
      city: "BURDEN",
      state: "KS"
    },
    {
      city: "BURDETT",
      state: "KS"
    },
    {
      city: "BURDETT",
      state: "NY"
    },
    {
      city: "BURDETTE",
      state: "AR"
    },
    {
      city: "BURDICK",
      state: "KS"
    },
    {
      city: "BURDINE",
      state: "KY"
    },
    {
      city: "BUREAU",
      state: "IL"
    },
    {
      city: "BURFORDVILLE",
      state: "MO"
    },
    {
      city: "BURGAW",
      state: "NC"
    },
    {
      city: "BURGESS",
      state: "VA"
    },
    {
      city: "BURGETTSTOWN",
      state: "PA"
    },
    {
      city: "BURGHILL",
      state: "OH"
    },
    {
      city: "BURGIN",
      state: "KY"
    },
    {
      city: "BURGOON",
      state: "OH"
    },
    {
      city: "BURKBURNETT",
      state: "TX"
    },
    {
      city: "BURKE",
      state: "NY"
    },
    {
      city: "BURKE",
      state: "SD"
    },
    {
      city: "BURKE",
      state: "VA"
    },
    {
      city: "BURKES GARDEN",
      state: "VA"
    },
    {
      city: "BURKESVILLE",
      state: "KY"
    },
    {
      city: "BURKET",
      state: "IN"
    },
    {
      city: "BURKETTSVILLE",
      state: "OH"
    },
    {
      city: "BURKEVILLE",
      state: "TX"
    },
    {
      city: "BURKEVILLE",
      state: "VA"
    },
    {
      city: "BURKITTSVILLE",
      state: "MD"
    },
    {
      city: "BURLESON",
      state: "TX"
    },
    {
      city: "BURLEY",
      state: "ID"
    },
    {
      city: "BURLEY",
      state: "WA"
    },
    {
      city: "BURLINGAME",
      state: "CA"
    },
    {
      city: "BURLINGAME",
      state: "KS"
    },
    {
      city: "BURLINGHAM",
      state: "NY"
    },
    {
      city: "BURLINGTON",
      state: "CO"
    },
    {
      city: "BURLINGTON",
      state: "CT"
    },
    {
      city: "BURLINGTON",
      state: "IA"
    },
    {
      city: "BURLINGTON",
      state: "IL"
    },
    {
      city: "BURLINGTON",
      state: "IN"
    },
    {
      city: "BURLINGTON",
      state: "KS"
    },
    {
      city: "BURLINGTON",
      state: "KY"
    },
    {
      city: "BURLINGTON",
      state: "MA"
    },
    {
      city: "BURLINGTON",
      state: "ME"
    },
    {
      city: "BURLINGTON",
      state: "MI"
    },
    {
      city: "BURLINGTON",
      state: "NC"
    },
    {
      city: "BURLINGTON",
      state: "ND"
    },
    {
      city: "BURLINGTON",
      state: "NJ"
    },
    {
      city: "BURLINGTON",
      state: "OK"
    },
    {
      city: "BURLINGTON",
      state: "PA"
    },
    {
      city: "BURLINGTON",
      state: "TX"
    },
    {
      city: "BURLINGTON",
      state: "VT"
    },
    {
      city: "BURLINGTON",
      state: "WA"
    },
    {
      city: "BURLINGTON",
      state: "WI"
    },
    {
      city: "BURLINGTON",
      state: "WV"
    },
    {
      city: "BURLINGTON",
      state: "WY"
    },
    {
      city: "BURLINGTON FLATS",
      state: "NY"
    },
    {
      city: "BURLINGTON JUNCTION",
      state: "MO"
    },
    {
      city: "BURLISON",
      state: "TN"
    },
    {
      city: "BURNA",
      state: "KY"
    },
    {
      city: "BURNET",
      state: "TX"
    },
    {
      city: "BURNETT",
      state: "WI"
    },
    {
      city: "BURNETTSVILLE",
      state: "IN"
    },
    {
      city: "BURNEY",
      state: "CA"
    },
    {
      city: "BURNEYVILLE",
      state: "OK"
    },
    {
      city: "BURNHAM",
      state: "ME"
    },
    {
      city: "BURNHAM",
      state: "PA"
    },
    {
      city: "BURNIPS",
      state: "MI"
    },
    {
      city: "BURNS",
      state: "CO"
    },
    {
      city: "BURNS",
      state: "KS"
    },
    {
      city: "BURNS",
      state: "OR"
    },
    {
      city: "BURNS",
      state: "TN"
    },
    {
      city: "BURNS",
      state: "WY"
    },
    {
      city: "BURNS FLAT",
      state: "OK"
    },
    {
      city: "BURNSIDE",
      state: "IA"
    },
    {
      city: "BURNSIDE",
      state: "KY"
    },
    {
      city: "BURNSIDE",
      state: "LA"
    },
    {
      city: "BURNSIDE",
      state: "PA"
    },
    {
      city: "BURNSVILLE",
      state: "MN"
    },
    {
      city: "BURNSVILLE",
      state: "MS"
    },
    {
      city: "BURNSVILLE",
      state: "NC"
    },
    {
      city: "BURNSVILLE",
      state: "WV"
    },
    {
      city: "BURNT CABINS",
      state: "PA"
    },
    {
      city: "BURNT HILLS",
      state: "NY"
    },
    {
      city: "BURNT PRAIRIE",
      state: "IL"
    },
    {
      city: "BURNT RANCH",
      state: "CA"
    },
    {
      city: "BURR",
      state: "NE"
    },
    {
      city: "BURR HILL",
      state: "VA"
    },
    {
      city: "BURR OAK",
      state: "KS"
    },
    {
      city: "BURR OAK",
      state: "MI"
    },
    {
      city: "BURREL",
      state: "CA"
    },
    {
      city: "BURROWS",
      state: "IN"
    },
    {
      city: "BURRTON",
      state: "KS"
    },
    {
      city: "BURSON",
      state: "CA"
    },
    {
      city: "BURT",
      state: "IA"
    },
    {
      city: "BURT",
      state: "MI"
    },
    {
      city: "BURT",
      state: "NY"
    },
    {
      city: "BURT LAKE",
      state: "MI"
    },
    {
      city: "BURTON",
      state: "MI"
    },
    {
      city: "BURTON",
      state: "OH"
    },
    {
      city: "BURTON",
      state: "TX"
    },
    {
      city: "BURTON",
      state: "WA"
    },
    {
      city: "BURTON",
      state: "WV"
    },
    {
      city: "BURTONSVILLE",
      state: "MD"
    },
    {
      city: "BURTRUM",
      state: "MN"
    },
    {
      city: "BURWELL",
      state: "NE"
    },
    {
      city: "BUSBY",
      state: "MT"
    },
    {
      city: "BUSH",
      state: "LA"
    },
    {
      city: "BUSHKILL",
      state: "PA"
    },
    {
      city: "BUSHLAND",
      state: "TX"
    },
    {
      city: "BUSHNELL",
      state: "FL"
    },
    {
      city: "BUSHNELL",
      state: "IL"
    },
    {
      city: "BUSHNELL",
      state: "NE"
    },
    {
      city: "BUSHTON",
      state: "KS"
    },
    {
      city: "BUSHWOOD",
      state: "MD"
    },
    {
      city: "BUSKIRK",
      state: "NY"
    },
    {
      city: "BUSSEY",
      state: "IA"
    },
    {
      city: "BUSTINS ISLAND",
      state: "ME"
    },
    {
      city: "BUSY",
      state: "KY"
    },
    {
      city: "BUTLER",
      state: "AL"
    },
    {
      city: "BUTLER",
      state: "GA"
    },
    {
      city: "BUTLER",
      state: "IL"
    },
    {
      city: "BUTLER",
      state: "IN"
    },
    {
      city: "BUTLER",
      state: "KY"
    },
    {
      city: "BUTLER",
      state: "MD"
    },
    {
      city: "BUTLER",
      state: "MO"
    },
    {
      city: "BUTLER",
      state: "NJ"
    },
    {
      city: "BUTLER",
      state: "OH"
    },
    {
      city: "BUTLER",
      state: "OK"
    },
    {
      city: "BUTLER",
      state: "PA"
    },
    {
      city: "BUTLER",
      state: "TN"
    },
    {
      city: "BUTLER",
      state: "WI"
    },
    {
      city: "BUTLERVILLE",
      state: "IN"
    },
    {
      city: "BUTNER",
      state: "NC"
    },
    {
      city: "BUTTE",
      state: "MT"
    },
    {
      city: "BUTTE",
      state: "ND"
    },
    {
      city: "BUTTE",
      state: "NE"
    },
    {
      city: "BUTTE CITY",
      state: "CA"
    },
    {
      city: "BUTTE DES MORTS",
      state: "WI"
    },
    {
      city: "BUTTE FALLS",
      state: "OR"
    },
    {
      city: "BUTTERFIELD",
      state: "MN"
    },
    {
      city: "BUTTERFIELD",
      state: "MO"
    },
    {
      city: "BUTTERNUT",
      state: "WI"
    },
    {
      city: "BUTTONWILLOW",
      state: "CA"
    },
    {
      city: "BUTTZVILLE",
      state: "NJ"
    },
    {
      city: "BUXTON",
      state: "ME"
    },
    {
      city: "BUXTON",
      state: "NC"
    },
    {
      city: "BUXTON",
      state: "ND"
    },
    {
      city: "BUXTON",
      state: "OR"
    },
    {
      city: "BUZZARDS BAY",
      state: "MA"
    },
    {
      city: "BYARS",
      state: "OK"
    },
    {
      city: "BYBEE",
      state: "TN"
    },
    {
      city: "BYERS",
      state: "CO"
    },
    {
      city: "BYERS",
      state: "KS"
    },
    {
      city: "BYERS",
      state: "TX"
    },
    {
      city: "BYESVILLE",
      state: "OH"
    },
    {
      city: "BYFIELD",
      state: "MA"
    },
    {
      city: "BYHALIA",
      state: "MS"
    },
    {
      city: "BYLAS",
      state: "AZ"
    },
    {
      city: "BYNUM",
      state: "AL"
    },
    {
      city: "BYNUM",
      state: "MT"
    },
    {
      city: "BYNUM",
      state: "TX"
    },
    {
      city: "BYPRO",
      state: "KY"
    },
    {
      city: "BYRAM",
      state: "MS"
    },
    {
      city: "BYRDSTOWN",
      state: "TN"
    },
    {
      city: "BYRNEDALE",
      state: "PA"
    },
    {
      city: "BYROMVILLE",
      state: "GA"
    },
    {
      city: "BYRON",
      state: "CA"
    },
    {
      city: "BYRON",
      state: "GA"
    },
    {
      city: "BYRON",
      state: "IL"
    },
    {
      city: "BYRON",
      state: "MI"
    },
    {
      city: "BYRON",
      state: "MN"
    },
    {
      city: "BYRON",
      state: "NE"
    },
    {
      city: "BYRON",
      state: "NY"
    },
    {
      city: "BYRON",
      state: "WY"
    },
    {
      city: "BYRON CENTER",
      state: "MI"
    },
    {
      city: "CABALLO",
      state: "NM"
    },
    {
      city: "CABAZON",
      state: "CA"
    },
    {
      city: "CABERY",
      state: "IL"
    },
    {
      city: "CABIN CREEK",
      state: "WV"
    },
    {
      city: "CABIN JOHN",
      state: "MD"
    },
    {
      city: "CABINS",
      state: "WV"
    },
    {
      city: "CABLE",
      state: "OH"
    },
    {
      city: "CABLE",
      state: "WI"
    },
    {
      city: "CABOOL",
      state: "MO"
    },
    {
      city: "CABOT",
      state: "AR"
    },
    {
      city: "CABOT",
      state: "PA"
    },
    {
      city: "CABOT",
      state: "VT"
    },
    {
      city: "CACHE",
      state: "OK"
    },
    {
      city: "CACHE JUNCTION",
      state: "UT"
    },
    {
      city: "CACTUS",
      state: "TX"
    },
    {
      city: "CADDO",
      state: "OK"
    },
    {
      city: "CADDO",
      state: "TX"
    },
    {
      city: "CADDO GAP",
      state: "AR"
    },
    {
      city: "CADDO MILLS",
      state: "TX"
    },
    {
      city: "CADE",
      state: "LA"
    },
    {
      city: "CADES",
      state: "SC"
    },
    {
      city: "CADET",
      state: "MO"
    },
    {
      city: "CADILLAC",
      state: "MI"
    },
    {
      city: "CADIZ",
      state: "KY"
    },
    {
      city: "CADIZ",
      state: "OH"
    },
    {
      city: "CADOGAN",
      state: "PA"
    },
    {
      city: "CADOTT",
      state: "WI"
    },
    {
      city: "CADWELL",
      state: "GA"
    },
    {
      city: "CADYVILLE",
      state: "NY"
    },
    {
      city: "CAHONE",
      state: "CO"
    },
    {
      city: "CAINSVILLE",
      state: "MO"
    },
    {
      city: "CAIRNBROOK",
      state: "PA"
    },
    {
      city: "CAIRO",
      state: "GA"
    },
    {
      city: "CAIRO",
      state: "IL"
    },
    {
      city: "CAIRO",
      state: "MO"
    },
    {
      city: "CAIRO",
      state: "NE"
    },
    {
      city: "CAIRO",
      state: "NY"
    },
    {
      city: "CAIRO",
      state: "OH"
    },
    {
      city: "CAIRO",
      state: "WV"
    },
    {
      city: "CAL NEV ARI",
      state: "NV"
    },
    {
      city: "CALABASAS",
      state: "CA"
    },
    {
      city: "CALABASH",
      state: "NC"
    },
    {
      city: "CALAIS",
      state: "ME"
    },
    {
      city: "CALAIS",
      state: "VT"
    },
    {
      city: "CALAMUS",
      state: "IA"
    },
    {
      city: "CALCIUM",
      state: "NY"
    },
    {
      city: "CALDER",
      state: "ID"
    },
    {
      city: "CALDWELL",
      state: "AR"
    },
    {
      city: "CALDWELL",
      state: "ID"
    },
    {
      city: "CALDWELL",
      state: "KS"
    },
    {
      city: "CALDWELL",
      state: "NJ"
    },
    {
      city: "CALDWELL",
      state: "OH"
    },
    {
      city: "CALDWELL",
      state: "TX"
    },
    {
      city: "CALDWELL",
      state: "WV"
    },
    {
      city: "CALEDONIA",
      state: "IL"
    },
    {
      city: "CALEDONIA",
      state: "MI"
    },
    {
      city: "CALEDONIA",
      state: "MN"
    },
    {
      city: "CALEDONIA",
      state: "MO"
    },
    {
      city: "CALEDONIA",
      state: "MS"
    },
    {
      city: "CALEDONIA",
      state: "ND"
    },
    {
      city: "CALEDONIA",
      state: "NY"
    },
    {
      city: "CALEDONIA",
      state: "OH"
    },
    {
      city: "CALEDONIA",
      state: "WI"
    },
    {
      city: "CALERA",
      state: "AL"
    },
    {
      city: "CALERA",
      state: "OK"
    },
    {
      city: "CALEXICO",
      state: "CA"
    },
    {
      city: "CALHAN",
      state: "CO"
    },
    {
      city: "CALHOUN",
      state: "GA"
    },
    {
      city: "CALHOUN",
      state: "IL"
    },
    {
      city: "CALHOUN",
      state: "KY"
    },
    {
      city: "CALHOUN",
      state: "LA"
    },
    {
      city: "CALHOUN",
      state: "MO"
    },
    {
      city: "CALHOUN",
      state: "TN"
    },
    {
      city: "CALHOUN CITY",
      state: "MS"
    },
    {
      city: "CALHOUN FALLS",
      state: "SC"
    },
    {
      city: "CALICO ROCK",
      state: "AR"
    },
    {
      city: "CALIENTE",
      state: "CA"
    },
    {
      city: "CALIENTE",
      state: "NV"
    },
    {
      city: "CALIFON",
      state: "NJ"
    },
    {
      city: "CALIFORNIA",
      state: "KY"
    },
    {
      city: "CALIFORNIA",
      state: "MD"
    },
    {
      city: "CALIFORNIA",
      state: "MO"
    },
    {
      city: "CALIFORNIA",
      state: "PA"
    },
    {
      city: "CALIFORNIA CITY",
      state: "CA"
    },
    {
      city: "CALIFORNIA HOT SPRINGS",
      state: "CA"
    },
    {
      city: "CALIMESA",
      state: "CA"
    },
    {
      city: "CALION",
      state: "AR"
    },
    {
      city: "CALIPATRIA",
      state: "CA"
    },
    {
      city: "CALISTOGA",
      state: "CA"
    },
    {
      city: "CALL",
      state: "TX"
    },
    {
      city: "CALLAHAN",
      state: "CA"
    },
    {
      city: "CALLAHAN",
      state: "FL"
    },
    {
      city: "CALLANDS",
      state: "VA"
    },
    {
      city: "CALLAO",
      state: "MO"
    },
    {
      city: "CALLAO",
      state: "VA"
    },
    {
      city: "CALLAWAY",
      state: "MD"
    },
    {
      city: "CALLAWAY",
      state: "MN"
    },
    {
      city: "CALLAWAY",
      state: "NE"
    },
    {
      city: "CALLAWAY",
      state: "VA"
    },
    {
      city: "CALLENDER",
      state: "IA"
    },
    {
      city: "CALLENSBURG",
      state: "PA"
    },
    {
      city: "CALLERY",
      state: "PA"
    },
    {
      city: "CALLICOON",
      state: "NY"
    },
    {
      city: "CALLICOON CENTER",
      state: "NY"
    },
    {
      city: "CALLIHAM",
      state: "TX"
    },
    {
      city: "CALMAR",
      state: "IA"
    },
    {
      city: "CALPELLA",
      state: "CA"
    },
    {
      city: "CALPINE",
      state: "CA"
    },
    {
      city: "CALUMET",
      state: "IA"
    },
    {
      city: "CALUMET",
      state: "MI"
    },
    {
      city: "CALUMET",
      state: "MN"
    },
    {
      city: "CALUMET",
      state: "OK"
    },
    {
      city: "CALUMET",
      state: "PA"
    },
    {
      city: "CALUMET CITY",
      state: "IL"
    },
    {
      city: "CALVARY",
      state: "GA"
    },
    {
      city: "CALVERT",
      state: "AL"
    },
    {
      city: "CALVERT",
      state: "TX"
    },
    {
      city: "CALVERT CITY",
      state: "KY"
    },
    {
      city: "CALVERTON",
      state: "NY"
    },
    {
      city: "CALVERTON",
      state: "VA"
    },
    {
      city: "CALVIN",
      state: "KY"
    },
    {
      city: "CALVIN",
      state: "LA"
    },
    {
      city: "CALVIN",
      state: "ND"
    },
    {
      city: "CALVIN",
      state: "OK"
    },
    {
      city: "CALVIN",
      state: "PA"
    },
    {
      city: "CALVIN",
      state: "WV"
    },
    {
      city: "CALYPSO",
      state: "NC"
    },
    {
      city: "CAMAK",
      state: "GA"
    },
    {
      city: "CAMANCHE",
      state: "IA"
    },
    {
      city: "CAMANO ISLAND",
      state: "WA"
    },
    {
      city: "CAMARGO",
      state: "IL"
    },
    {
      city: "CAMARGO",
      state: "OK"
    },
    {
      city: "CAMARILLO",
      state: "CA"
    },
    {
      city: "CAMAS",
      state: "WA"
    },
    {
      city: "CAMAS VALLEY",
      state: "OR"
    },
    {
      city: "CAMBRA",
      state: "PA"
    },
    {
      city: "CAMBRIA",
      state: "CA"
    },
    {
      city: "CAMBRIA",
      state: "IL"
    },
    {
      city: "CAMBRIA",
      state: "WI"
    },
    {
      city: "CAMBRIA HEIGHTS",
      state: "NY"
    },
    {
      city: "CAMBRIDGE",
      state: "IA"
    },
    {
      city: "CAMBRIDGE",
      state: "ID"
    },
    {
      city: "CAMBRIDGE",
      state: "IL"
    },
    {
      city: "CAMBRIDGE",
      state: "KS"
    },
    {
      city: "CAMBRIDGE",
      state: "MA"
    },
    {
      city: "CAMBRIDGE",
      state: "MD"
    },
    {
      city: "CAMBRIDGE",
      state: "ME"
    },
    {
      city: "CAMBRIDGE",
      state: "MN"
    },
    {
      city: "CAMBRIDGE",
      state: "NE"
    },
    {
      city: "CAMBRIDGE",
      state: "NY"
    },
    {
      city: "CAMBRIDGE",
      state: "OH"
    },
    {
      city: "CAMBRIDGE",
      state: "VT"
    },
    {
      city: "CAMBRIDGE",
      state: "WI"
    },
    {
      city: "CAMBRIDGE CITY",
      state: "IN"
    },
    {
      city: "CAMBRIDGE SPRINGS",
      state: "PA"
    },
    {
      city: "CAMBRIDGEPORT",
      state: "VT"
    },
    {
      city: "CAMBY",
      state: "IN"
    },
    {
      city: "CAMDEN",
      state: "AL"
    },
    {
      city: "CAMDEN",
      state: "AR"
    },
    {
      city: "CAMDEN",
      state: "IL"
    },
    {
      city: "CAMDEN",
      state: "IN"
    },
    {
      city: "CAMDEN",
      state: "ME"
    },
    {
      city: "CAMDEN",
      state: "MI"
    },
    {
      city: "CAMDEN",
      state: "MO"
    },
    {
      city: "CAMDEN",
      state: "MS"
    },
    {
      city: "CAMDEN",
      state: "NC"
    },
    {
      city: "CAMDEN",
      state: "NJ"
    },
    {
      city: "CAMDEN",
      state: "NY"
    },
    {
      city: "CAMDEN",
      state: "OH"
    },
    {
      city: "CAMDEN",
      state: "SC"
    },
    {
      city: "CAMDEN",
      state: "TN"
    },
    {
      city: "CAMDEN",
      state: "TX"
    },
    {
      city: "CAMDEN",
      state: "WV"
    },
    {
      city: "CAMDEN ON GAULEY",
      state: "WV"
    },
    {
      city: "CAMDEN POINT",
      state: "MO"
    },
    {
      city: "CAMDEN WYOMING",
      state: "DE"
    },
    {
      city: "CAMDENTON",
      state: "MO"
    },
    {
      city: "CAMERON",
      state: "AZ"
    },
    {
      city: "CAMERON",
      state: "IL"
    },
    {
      city: "CAMERON",
      state: "LA"
    },
    {
      city: "CAMERON",
      state: "MO"
    },
    {
      city: "CAMERON",
      state: "MT"
    },
    {
      city: "CAMERON",
      state: "NC"
    },
    {
      city: "CAMERON",
      state: "OH"
    },
    {
      city: "CAMERON",
      state: "OK"
    },
    {
      city: "CAMERON",
      state: "SC"
    },
    {
      city: "CAMERON",
      state: "TX"
    },
    {
      city: "CAMERON",
      state: "WI"
    },
    {
      city: "CAMERON",
      state: "WV"
    },
    {
      city: "CAMERON MILLS",
      state: "NY"
    },
    {
      city: "CAMILLA",
      state: "GA"
    },
    {
      city: "CAMILLUS",
      state: "NY"
    },
    {
      city: "CAMINO",
      state: "CA"
    },
    {
      city: "CAMP",
      state: "AR"
    },
    {
      city: "CAMP CREEK",
      state: "WV"
    },
    {
      city: "CAMP CROOK",
      state: "SD"
    },
    {
      city: "CAMP DENNISON",
      state: "OH"
    },
    {
      city: "CAMP DOUGLAS",
      state: "WI"
    },
    {
      city: "CAMP GROVE",
      state: "IL"
    },
    {
      city: "CAMP H M SMITH",
      state: "HI"
    },
    {
      city: "CAMP HILL",
      state: "AL"
    },
    {
      city: "CAMP HILL",
      state: "PA"
    },
    {
      city: "CAMP LAKE",
      state: "WI"
    },
    {
      city: "CAMP LEJEUNE",
      state: "NC"
    },
    {
      city: "CAMP MEEKER",
      state: "CA"
    },
    {
      city: "CAMP MURRAY",
      state: "WA"
    },
    {
      city: "CAMP NELSON",
      state: "CA"
    },
    {
      city: "CAMP PENDLETON",
      state: "CA"
    },
    {
      city: "CAMP POINT",
      state: "IL"
    },
    {
      city: "CAMP SHERMAN",
      state: "OR"
    },
    {
      city: "CAMP VERDE",
      state: "AZ"
    },
    {
      city: "CAMP WOOD",
      state: "TX"
    },
    {
      city: "CAMPAIGN",
      state: "TN"
    },
    {
      city: "CAMPBELL",
      state: "CA"
    },
    {
      city: "CAMPBELL",
      state: "MN"
    },
    {
      city: "CAMPBELL",
      state: "MO"
    },
    {
      city: "CAMPBELL",
      state: "NE"
    },
    {
      city: "CAMPBELL",
      state: "NY"
    },
    {
      city: "CAMPBELL",
      state: "OH"
    },
    {
      city: "CAMPBELL",
      state: "TX"
    },
    {
      city: "CAMPBELL HALL",
      state: "NY"
    },
    {
      city: "CAMPBELL HILL",
      state: "IL"
    },
    {
      city: "CAMPBELLSBURG",
      state: "IN"
    },
    {
      city: "CAMPBELLSBURG",
      state: "KY"
    },
    {
      city: "CAMPBELLSPORT",
      state: "WI"
    },
    {
      city: "CAMPBELLSVILLE",
      state: "KY"
    },
    {
      city: "CAMPBELLTON",
      state: "FL"
    },
    {
      city: "CAMPBELLTON",
      state: "TX"
    },
    {
      city: "CAMPBELLTOWN",
      state: "PA"
    },
    {
      city: "CAMPO",
      state: "CA"
    },
    {
      city: "CAMPO",
      state: "CO"
    },
    {
      city: "CAMPO SECO",
      state: "CA"
    },
    {
      city: "CAMPOBELLO",
      state: "SC"
    },
    {
      city: "CAMPTI",
      state: "LA"
    },
    {
      city: "CAMPTON",
      state: "KY"
    },
    {
      city: "CAMPTON",
      state: "NH"
    },
    {
      city: "CAMPTONVILLE",
      state: "CA"
    },
    {
      city: "CAMPTOWN",
      state: "PA"
    },
    {
      city: "CAMPUS",
      state: "IL"
    },
    {
      city: "CANA",
      state: "VA"
    },
    {
      city: "CANAAN",
      state: "CT"
    },
    {
      city: "CANAAN",
      state: "IN"
    },
    {
      city: "CANAAN",
      state: "ME"
    },
    {
      city: "CANAAN",
      state: "NH"
    },
    {
      city: "CANAAN",
      state: "NY"
    },
    {
      city: "CANAAN",
      state: "VT"
    },
    {
      city: "CANADA",
      state: "KY"
    },
    {
      city: "CANADENSIS",
      state: "PA"
    },
    {
      city: "CANADIAN",
      state: "OK"
    },
    {
      city: "CANADIAN",
      state: "TX"
    },
    {
      city: "CANADYS",
      state: "SC"
    },
    {
      city: "CANAJOHARIE",
      state: "NY"
    },
    {
      city: "CANAL FULTON",
      state: "OH"
    },
    {
      city: "CANAL POINT",
      state: "FL"
    },
    {
      city: "CANAL WINCHESTER",
      state: "OH"
    },
    {
      city: "CANALOU",
      state: "MO"
    },
    {
      city: "CANANDAIGUA",
      state: "NY"
    },
    {
      city: "CANASERAGA",
      state: "NY"
    },
    {
      city: "CANASTOTA",
      state: "NY"
    },
    {
      city: "CANBY",
      state: "CA"
    },
    {
      city: "CANBY",
      state: "MN"
    },
    {
      city: "CANBY",
      state: "OR"
    },
    {
      city: "CANDIA",
      state: "NH"
    },
    {
      city: "CANDLER",
      state: "FL"
    },
    {
      city: "CANDLER",
      state: "NC"
    },
    {
      city: "CANDO",
      state: "ND"
    },
    {
      city: "CANDOR",
      state: "NC"
    },
    {
      city: "CANDOR",
      state: "NY"
    },
    {
      city: "CANEADEA",
      state: "NY"
    },
    {
      city: "CANEHILL",
      state: "AR"
    },
    {
      city: "CANEY",
      state: "KS"
    },
    {
      city: "CANEY",
      state: "OK"
    },
    {
      city: "CANEYVILLE",
      state: "KY"
    },
    {
      city: "CANFIELD",
      state: "OH"
    },
    {
      city: "CANISTEO",
      state: "NY"
    },
    {
      city: "CANISTOTA",
      state: "SD"
    },
    {
      city: "CANJILON",
      state: "NM"
    },
    {
      city: "CANMER",
      state: "KY"
    },
    {
      city: "CANNELBURG",
      state: "IN"
    },
    {
      city: "CANNELTON",
      state: "IN"
    },
    {
      city: "CANNELTON",
      state: "WV"
    },
    {
      city: "CANNON",
      state: "KY"
    },
    {
      city: "CANNON AFB",
      state: "NM"
    },
    {
      city: "CANNON BALL",
      state: "ND"
    },
    {
      city: "CANNON BEACH",
      state: "OR"
    },
    {
      city: "CANNON FALLS",
      state: "MN"
    },
    {
      city: "CANNONSBURG",
      state: "MI"
    },
    {
      city: "CANNONVILLE",
      state: "UT"
    },
    {
      city: "CANOGA PARK",
      state: "CA"
    },
    {
      city: "CANON",
      state: "GA"
    },
    {
      city: "CANON CITY",
      state: "CO"
    },
    {
      city: "CANONES",
      state: "NM"
    },
    {
      city: "CANONSBURG",
      state: "PA"
    },
    {
      city: "CANOVA",
      state: "SD"
    },
    {
      city: "CANTERBURY",
      state: "CT"
    },
    {
      city: "CANTERBURY",
      state: "NH"
    },
    {
      city: "CANTON",
      state: "CT"
    },
    {
      city: "CANTON",
      state: "GA"
    },
    {
      city: "CANTON",
      state: "IL"
    },
    {
      city: "CANTON",
      state: "KS"
    },
    {
      city: "CANTON",
      state: "MA"
    },
    {
      city: "CANTON",
      state: "ME"
    },
    {
      city: "CANTON",
      state: "MI"
    },
    {
      city: "CANTON",
      state: "MN"
    },
    {
      city: "CANTON",
      state: "MO"
    },
    {
      city: "CANTON",
      state: "MS"
    },
    {
      city: "CANTON",
      state: "NC"
    },
    {
      city: "CANTON",
      state: "NY"
    },
    {
      city: "CANTON",
      state: "OH"
    },
    {
      city: "CANTON",
      state: "OK"
    },
    {
      city: "CANTON",
      state: "PA"
    },
    {
      city: "CANTON",
      state: "SD"
    },
    {
      city: "CANTON",
      state: "TX"
    },
    {
      city: "CANTON CENTER",
      state: "CT"
    },
    {
      city: "CANTONMENT",
      state: "FL"
    },
    {
      city: "CANTRALL",
      state: "IL"
    },
    {
      city: "CANTRIL",
      state: "IA"
    },
    {
      city: "CANTUA CREEK",
      state: "CA"
    },
    {
      city: "CANTWELL",
      state: "AK"
    },
    {
      city: "CANUTE",
      state: "OK"
    },
    {
      city: "CANUTILLO",
      state: "TX"
    },
    {
      city: "CANVAS",
      state: "WV"
    },
    {
      city: "CANYON",
      state: "CA"
    },
    {
      city: "CANYON",
      state: "MN"
    },
    {
      city: "CANYON",
      state: "TX"
    },
    {
      city: "CANYON CITY",
      state: "OR"
    },
    {
      city: "CANYON COUNTRY",
      state: "CA"
    },
    {
      city: "CANYON CREEK",
      state: "MT"
    },
    {
      city: "CANYON DAM",
      state: "CA"
    },
    {
      city: "CANYON LAKE",
      state: "TX"
    },
    {
      city: "CANYONVILLE",
      state: "OR"
    },
    {
      city: "CAPAC",
      state: "MI"
    },
    {
      city: "CAPAY",
      state: "CA"
    },
    {
      city: "CAPE CANAVERAL",
      state: "FL"
    },
    {
      city: "CAPE CHARLES",
      state: "VA"
    },
    {
      city: "CAPE CORAL",
      state: "FL"
    },
    {
      city: "CAPE ELIZABETH",
      state: "ME"
    },
    {
      city: "CAPE FAIR",
      state: "MO"
    },
    {
      city: "CAPE GIRARDEAU",
      state: "MO"
    },
    {
      city: "CAPE MAY",
      state: "NJ"
    },
    {
      city: "CAPE MAY COURT HOUSE",
      state: "NJ"
    },
    {
      city: "CAPE MAY POINT",
      state: "NJ"
    },
    {
      city: "CAPE NEDDICK",
      state: "ME"
    },
    {
      city: "CAPE PORPOISE",
      state: "ME"
    },
    {
      city: "CAPE VINCENT",
      state: "NY"
    },
    {
      city: "CAPEVILLE",
      state: "VA"
    },
    {
      city: "CAPISTRANO BEACH",
      state: "CA"
    },
    {
      city: "CAPITAN",
      state: "NM"
    },
    {
      city: "CAPITOL",
      state: "MT"
    },
    {
      city: "CAPITOL HEIGHTS",
      state: "MD"
    },
    {
      city: "CAPITOLA",
      state: "CA"
    },
    {
      city: "CAPON BRIDGE",
      state: "WV"
    },
    {
      city: "CAPON SPRINGS",
      state: "WV"
    },
    {
      city: "CAPROCK",
      state: "NM"
    },
    {
      city: "CAPRON",
      state: "IL"
    },
    {
      city: "CAPRON",
      state: "VA"
    },
    {
      city: "CAPSHAW",
      state: "AL"
    },
    {
      city: "CAPTAIN COOK",
      state: "HI"
    },
    {
      city: "CAPTIVA",
      state: "FL"
    },
    {
      city: "CAPULIN",
      state: "CO"
    },
    {
      city: "CAPULIN",
      state: "NM"
    },
    {
      city: "CAPUTA",
      state: "SD"
    },
    {
      city: "CARATUNK",
      state: "ME"
    },
    {
      city: "CARAWAY",
      state: "AR"
    },
    {
      city: "CARBON",
      state: "IA"
    },
    {
      city: "CARBON",
      state: "IN"
    },
    {
      city: "CARBON",
      state: "TX"
    },
    {
      city: "CARBON CLIFF",
      state: "IL"
    },
    {
      city: "CARBON HILL",
      state: "AL"
    },
    {
      city: "CARBON HILL",
      state: "OH"
    },
    {
      city: "CARBONADO",
      state: "WA"
    },
    {
      city: "CARBONDALE",
      state: "CO"
    },
    {
      city: "CARBONDALE",
      state: "IL"
    },
    {
      city: "CARBONDALE",
      state: "KS"
    },
    {
      city: "CARBONDALE",
      state: "PA"
    },
    {
      city: "CARDALE",
      state: "PA"
    },
    {
      city: "CARDIFF BY THE SEA",
      state: "CA"
    },
    {
      city: "CARDIN",
      state: "OK"
    },
    {
      city: "CARDINAL",
      state: "VA"
    },
    {
      city: "CARDINGTON",
      state: "OH"
    },
    {
      city: "CARDWELL",
      state: "MO"
    },
    {
      city: "CARDWELL",
      state: "MT"
    },
    {
      city: "CAREFREE",
      state: "AZ"
    },
    {
      city: "CARENCRO",
      state: "LA"
    },
    {
      city: "CARET",
      state: "VA"
    },
    {
      city: "CAREY",
      state: "ID"
    },
    {
      city: "CAREY",
      state: "OH"
    },
    {
      city: "CAREYWOOD",
      state: "ID"
    },
    {
      city: "CARIBOU",
      state: "ME"
    },
    {
      city: "CARL JUNCTION",
      state: "MO"
    },
    {
      city: "CARLE PLACE",
      state: "NY"
    },
    {
      city: "CARLETON",
      state: "MI"
    },
    {
      city: "CARLETON",
      state: "NE"
    },
    {
      city: "CARLIN",
      state: "NV"
    },
    {
      city: "CARLINVILLE",
      state: "IL"
    },
    {
      city: "CARLISLE",
      state: "AR"
    },
    {
      city: "CARLISLE",
      state: "IA"
    },
    {
      city: "CARLISLE",
      state: "IN"
    },
    {
      city: "CARLISLE",
      state: "KY"
    },
    {
      city: "CARLISLE",
      state: "MA"
    },
    {
      city: "CARLISLE",
      state: "NY"
    },
    {
      city: "CARLISLE",
      state: "PA"
    },
    {
      city: "CARLISLE",
      state: "SC"
    },
    {
      city: "CARLOCK",
      state: "IL"
    },
    {
      city: "CARLOS",
      state: "MN"
    },
    {
      city: "CARLOTTA",
      state: "CA"
    },
    {
      city: "CARLSBAD",
      state: "CA"
    },
    {
      city: "CARLSBAD",
      state: "NM"
    },
    {
      city: "CARLSBAD",
      state: "TX"
    },
    {
      city: "CARLSBORG",
      state: "WA"
    },
    {
      city: "CARLSTADT",
      state: "NJ"
    },
    {
      city: "CARLTON",
      state: "AL"
    },
    {
      city: "CARLTON",
      state: "GA"
    },
    {
      city: "CARLTON",
      state: "MN"
    },
    {
      city: "CARLTON",
      state: "OR"
    },
    {
      city: "CARLTON",
      state: "PA"
    },
    {
      city: "CARLTON",
      state: "TX"
    },
    {
      city: "CARLTON",
      state: "WA"
    },
    {
      city: "CARLYLE",
      state: "IL"
    },
    {
      city: "CARMAN",
      state: "IL"
    },
    {
      city: "CARMEL",
      state: "CA"
    },
    {
      city: "CARMEL",
      state: "IN"
    },
    {
      city: "CARMEL",
      state: "ME"
    },
    {
      city: "CARMEL",
      state: "NY"
    },
    {
      city: "CARMEL BY THE SEA",
      state: "CA"
    },
    {
      city: "CARMEL VALLEY",
      state: "CA"
    },
    {
      city: "CARMEN",
      state: "ID"
    },
    {
      city: "CARMEN",
      state: "OK"
    },
    {
      city: "CARMI",
      state: "IL"
    },
    {
      city: "CARMICHAEL",
      state: "CA"
    },
    {
      city: "CARMICHAELS",
      state: "PA"
    },
    {
      city: "CARMINE",
      state: "TX"
    },
    {
      city: "CARNATION",
      state: "WA"
    },
    {
      city: "CARNEGIE",
      state: "OK"
    },
    {
      city: "CARNEGIE",
      state: "PA"
    },
    {
      city: "CARNELIAN BAY",
      state: "CA"
    },
    {
      city: "CARNESVILLE",
      state: "GA"
    },
    {
      city: "CARNEY",
      state: "MI"
    },
    {
      city: "CARNEY",
      state: "OK"
    },
    {
      city: "CARO",
      state: "MI"
    },
    {
      city: "CAROGA LAKE",
      state: "NY"
    },
    {
      city: "CAROL STREAM",
      state: "IL"
    },
    {
      city: "CAROLEEN",
      state: "NC"
    },
    {
      city: "CAROLINA",
      state: "RI"
    },
    {
      city: "CAROLINA",
      state: "WV"
    },
    {
      city: "CAROLINA BEACH",
      state: "NC"
    },
    {
      city: "CAROLINE",
      state: "WI"
    },
    {
      city: "CARP LAKE",
      state: "MI"
    },
    {
      city: "CARPENTER",
      state: "IA"
    },
    {
      city: "CARPENTER",
      state: "SD"
    },
    {
      city: "CARPENTER",
      state: "WY"
    },
    {
      city: "CARPENTERSVILLE",
      state: "IL"
    },
    {
      city: "CARPINTERIA",
      state: "CA"
    },
    {
      city: "CARPIO",
      state: "ND"
    },
    {
      city: "CARR",
      state: "CO"
    },
    {
      city: "CARRABELLE",
      state: "FL"
    },
    {
      city: "CARRBORO",
      state: "NC"
    },
    {
      city: "CARRIE",
      state: "KY"
    },
    {
      city: "CARRIER",
      state: "OK"
    },
    {
      city: "CARRIER MILLS",
      state: "IL"
    },
    {
      city: "CARRIERE",
      state: "MS"
    },
    {
      city: "CARRINGTON",
      state: "ND"
    },
    {
      city: "CARRIZO SPRINGS",
      state: "TX"
    },
    {
      city: "CARRIZOZO",
      state: "NM"
    },
    {
      city: "CARROLL",
      state: "IA"
    },
    {
      city: "CARROLL",
      state: "NE"
    },
    {
      city: "CARROLL",
      state: "OH"
    },
    {
      city: "CARROLLTON",
      state: "AL"
    },
    {
      city: "CARROLLTON",
      state: "GA"
    },
    {
      city: "CARROLLTON",
      state: "IL"
    },
    {
      city: "CARROLLTON",
      state: "KY"
    },
    {
      city: "CARROLLTON",
      state: "MI"
    },
    {
      city: "CARROLLTON",
      state: "MO"
    },
    {
      city: "CARROLLTON",
      state: "MS"
    },
    {
      city: "CARROLLTON",
      state: "OH"
    },
    {
      city: "CARROLLTON",
      state: "TX"
    },
    {
      city: "CARROLLTON",
      state: "VA"
    },
    {
      city: "CARROLLTOWN",
      state: "PA"
    },
    {
      city: "CARRSVILLE",
      state: "VA"
    },
    {
      city: "CARSON",
      state: "CA"
    },
    {
      city: "CARSON",
      state: "IA"
    },
    {
      city: "CARSON",
      state: "MS"
    },
    {
      city: "CARSON",
      state: "ND"
    },
    {
      city: "CARSON",
      state: "NM"
    },
    {
      city: "CARSON",
      state: "VA"
    },
    {
      city: "CARSON",
      state: "WA"
    },
    {
      city: "CARSON CITY",
      state: "MI"
    },
    {
      city: "CARSON CITY",
      state: "NV"
    },
    {
      city: "CARSONVILLE",
      state: "MI"
    },
    {
      city: "CARTER",
      state: "KY"
    },
    {
      city: "CARTER",
      state: "MT"
    },
    {
      city: "CARTER",
      state: "OK"
    },
    {
      city: "CARTER LAKE",
      state: "IA"
    },
    {
      city: "CARTERET",
      state: "NJ"
    },
    {
      city: "CARTERSVILLE",
      state: "GA"
    },
    {
      city: "CARTERSVILLE",
      state: "VA"
    },
    {
      city: "CARTERVILLE",
      state: "IL"
    },
    {
      city: "CARTERVILLE",
      state: "MO"
    },
    {
      city: "CARTHAGE",
      state: "AR"
    },
    {
      city: "CARTHAGE",
      state: "IL"
    },
    {
      city: "CARTHAGE",
      state: "IN"
    },
    {
      city: "CARTHAGE",
      state: "MO"
    },
    {
      city: "CARTHAGE",
      state: "MS"
    },
    {
      city: "CARTHAGE",
      state: "NC"
    },
    {
      city: "CARTHAGE",
      state: "NY"
    },
    {
      city: "CARTHAGE",
      state: "SD"
    },
    {
      city: "CARTHAGE",
      state: "TN"
    },
    {
      city: "CARTHAGE",
      state: "TX"
    },
    {
      city: "CARTWRIGHT",
      state: "ND"
    },
    {
      city: "CARTWRIGHT",
      state: "OK"
    },
    {
      city: "CARUTHERS",
      state: "CA"
    },
    {
      city: "CARUTHERSVILLE",
      state: "MO"
    },
    {
      city: "CARVER",
      state: "MA"
    },
    {
      city: "CARVER",
      state: "MN"
    },
    {
      city: "CARVERSVILLE",
      state: "PA"
    },
    {
      city: "CARVILLE",
      state: "LA"
    },
    {
      city: "CARY",
      state: "IL"
    },
    {
      city: "CARY",
      state: "MS"
    },
    {
      city: "CARY",
      state: "NC"
    },
    {
      city: "CARYVILLE",
      state: "FL"
    },
    {
      city: "CARYVILLE",
      state: "TN"
    },
    {
      city: "CASA",
      state: "AR"
    },
    {
      city: "CASA BLANCA",
      state: "NM"
    },
    {
      city: "CASA GRANDE",
      state: "AZ"
    },
    {
      city: "CASANOVA",
      state: "VA"
    },
    {
      city: "CASAR",
      state: "NC"
    },
    {
      city: "CASCADE",
      state: "CO"
    },
    {
      city: "CASCADE",
      state: "IA"
    },
    {
      city: "CASCADE",
      state: "ID"
    },
    {
      city: "CASCADE",
      state: "MD"
    },
    {
      city: "CASCADE",
      state: "MT"
    },
    {
      city: "CASCADE",
      state: "VA"
    },
    {
      city: "CASCADE",
      state: "WI"
    },
    {
      city: "CASCADE LOCKS",
      state: "OR"
    },
    {
      city: "CASCADIA",
      state: "OR"
    },
    {
      city: "CASCILLA",
      state: "MS"
    },
    {
      city: "CASCO",
      state: "ME"
    },
    {
      city: "CASCO",
      state: "MI"
    },
    {
      city: "CASCO",
      state: "WI"
    },
    {
      city: "CASEVILLE",
      state: "MI"
    },
    {
      city: "CASEY",
      state: "IA"
    },
    {
      city: "CASEY",
      state: "IL"
    },
    {
      city: "CASEYVILLE",
      state: "IL"
    },
    {
      city: "CASH",
      state: "AR"
    },
    {
      city: "CASHIERS",
      state: "NC"
    },
    {
      city: "CASHION",
      state: "AZ"
    },
    {
      city: "CASHION",
      state: "OK"
    },
    {
      city: "CASHMERE",
      state: "WA"
    },
    {
      city: "CASHTON",
      state: "WI"
    },
    {
      city: "CASHTOWN",
      state: "PA"
    },
    {
      city: "CASMALIA",
      state: "CA"
    },
    {
      city: "CASNOVIA",
      state: "MI"
    },
    {
      city: "CASON",
      state: "TX"
    },
    {
      city: "CASPAR",
      state: "CA"
    },
    {
      city: "CASPER",
      state: "WY"
    },
    {
      city: "CASPIAN",
      state: "MI"
    },
    {
      city: "CASS",
      state: "WV"
    },
    {
      city: "CASS CITY",
      state: "MI"
    },
    {
      city: "CASS LAKE",
      state: "MN"
    },
    {
      city: "CASSADAGA",
      state: "FL"
    },
    {
      city: "CASSADAGA",
      state: "NY"
    },
    {
      city: "CASSATT",
      state: "SC"
    },
    {
      city: "CASSCOE",
      state: "AR"
    },
    {
      city: "CASSEL",
      state: "CA"
    },
    {
      city: "CASSELBERRY",
      state: "FL"
    },
    {
      city: "CASSELTON",
      state: "ND"
    },
    {
      city: "CASSODAY",
      state: "KS"
    },
    {
      city: "CASSOPOLIS",
      state: "MI"
    },
    {
      city: "CASSTOWN",
      state: "OH"
    },
    {
      city: "CASSVILLE",
      state: "GA"
    },
    {
      city: "CASSVILLE",
      state: "MO"
    },
    {
      city: "CASSVILLE",
      state: "NY"
    },
    {
      city: "CASSVILLE",
      state: "PA"
    },
    {
      city: "CASSVILLE",
      state: "WI"
    },
    {
      city: "CASSVILLE",
      state: "WV"
    },
    {
      city: "CASTAIC",
      state: "CA"
    },
    {
      city: "CASTALIA",
      state: "IA"
    },
    {
      city: "CASTALIA",
      state: "NC"
    },
    {
      city: "CASTALIA",
      state: "OH"
    },
    {
      city: "CASTALIAN SPRINGS",
      state: "TN"
    },
    {
      city: "CASTANA",
      state: "IA"
    },
    {
      city: "CASTANEA",
      state: "PA"
    },
    {
      city: "CASTELL",
      state: "TX"
    },
    {
      city: "CASTELLA",
      state: "CA"
    },
    {
      city: "CASTILE",
      state: "NY"
    },
    {
      city: "CASTINE",
      state: "ME"
    },
    {
      city: "CASTLE",
      state: "OK"
    },
    {
      city: "CASTLE CREEK",
      state: "NY"
    },
    {
      city: "CASTLE DALE",
      state: "UT"
    },
    {
      city: "CASTLE HAYNE",
      state: "NC"
    },
    {
      city: "CASTLE POINT",
      state: "NY"
    },
    {
      city: "CASTLE ROCK",
      state: "CO"
    },
    {
      city: "CASTLE ROCK",
      state: "MN"
    },
    {
      city: "CASTLE ROCK",
      state: "WA"
    },
    {
      city: "CASTLEBERRY",
      state: "AL"
    },
    {
      city: "CASTLEFORD",
      state: "ID"
    },
    {
      city: "CASTLETON",
      state: "VA"
    },
    {
      city: "CASTLETON",
      state: "VT"
    },
    {
      city: "CASTLETON ON HUDSON",
      state: "NY"
    },
    {
      city: "CASTLEWOOD",
      state: "SD"
    },
    {
      city: "CASTLEWOOD",
      state: "VA"
    },
    {
      city: "CASTOR",
      state: "LA"
    },
    {
      city: "CASTORLAND",
      state: "NY"
    },
    {
      city: "CASTRO VALLEY",
      state: "CA"
    },
    {
      city: "CASTROVILLE",
      state: "CA"
    },
    {
      city: "CASTROVILLE",
      state: "TX"
    },
    {
      city: "CAT SPRING",
      state: "TX"
    },
    {
      city: "CATALDO",
      state: "ID"
    },
    {
      city: "CATALINA",
      state: "AZ"
    },
    {
      city: "CATARINA",
      state: "TX"
    },
    {
      city: "CATASAUQUA",
      state: "PA"
    },
    {
      city: "CATAULA",
      state: "GA"
    },
    {
      city: "CATAUMET",
      state: "MA"
    },
    {
      city: "CATAWBA",
      state: "NC"
    },
    {
      city: "CATAWBA",
      state: "OH"
    },
    {
      city: "CATAWBA",
      state: "SC"
    },
    {
      city: "CATAWBA",
      state: "VA"
    },
    {
      city: "CATAWBA",
      state: "WI"
    },
    {
      city: "CATAWISSA",
      state: "MO"
    },
    {
      city: "CATAWISSA",
      state: "PA"
    },
    {
      city: "CATHARINE",
      state: "KS"
    },
    {
      city: "CATHARPIN",
      state: "VA"
    },
    {
      city: "CATHAY",
      state: "ND"
    },
    {
      city: "CATHEDRAL CITY",
      state: "CA"
    },
    {
      city: "CATHERINE",
      state: "AL"
    },
    {
      city: "CATHEYS VALLEY",
      state: "CA"
    },
    {
      city: "CATHLAMET",
      state: "WA"
    },
    {
      city: "CATLETT",
      state: "VA"
    },
    {
      city: "CATLETTSBURG",
      state: "KY"
    },
    {
      city: "CATLIN",
      state: "IL"
    },
    {
      city: "CATO",
      state: "NY"
    },
    {
      city: "CATONSVILLE",
      state: "MD"
    },
    {
      city: "CATOOSA",
      state: "OK"
    },
    {
      city: "CATRON",
      state: "MO"
    },
    {
      city: "CATSKILL",
      state: "NY"
    },
    {
      city: "CATTARAUGUS",
      state: "NY"
    },
    {
      city: "CAULFIELD",
      state: "MO"
    },
    {
      city: "CAUSEY",
      state: "NM"
    },
    {
      city: "CAVALIER",
      state: "ND"
    },
    {
      city: "CAVE CITY",
      state: "AR"
    },
    {
      city: "CAVE CITY",
      state: "KY"
    },
    {
      city: "CAVE CREEK",
      state: "AZ"
    },
    {
      city: "CAVE IN ROCK",
      state: "IL"
    },
    {
      city: "CAVE JUNCTION",
      state: "OR"
    },
    {
      city: "CAVE SPRING",
      state: "GA"
    },
    {
      city: "CAVE SPRINGS",
      state: "AR"
    },
    {
      city: "CAVENDISH",
      state: "VT"
    },
    {
      city: "CAVETOWN",
      state: "MD"
    },
    {
      city: "CAVOUR",
      state: "SD"
    },
    {
      city: "CAWKER CITY",
      state: "KS"
    },
    {
      city: "CAWOOD",
      state: "KY"
    },
    {
      city: "CAYCE",
      state: "SC"
    },
    {
      city: "CAYUCOS",
      state: "CA"
    },
    {
      city: "CAYUGA",
      state: "IN"
    },
    {
      city: "CAYUGA",
      state: "ND"
    },
    {
      city: "CAYUGA",
      state: "NY"
    },
    {
      city: "CAYUGA",
      state: "TX"
    },
    {
      city: "CAYUTA",
      state: "NY"
    },
    {
      city: "CAZADERO",
      state: "CA"
    },
    {
      city: "CAZENOVIA",
      state: "NY"
    },
    {
      city: "CAZENOVIA",
      state: "WI"
    },
    {
      city: "CEBOLLA",
      state: "NM"
    },
    {
      city: "CECIL",
      state: "AL"
    },
    {
      city: "CECIL",
      state: "AR"
    },
    {
      city: "CECIL",
      state: "GA"
    },
    {
      city: "CECIL",
      state: "OH"
    },
    {
      city: "CECIL",
      state: "PA"
    },
    {
      city: "CECIL",
      state: "WI"
    },
    {
      city: "CECILIA",
      state: "KY"
    },
    {
      city: "CECILIA",
      state: "LA"
    },
    {
      city: "CECILTON",
      state: "MD"
    },
    {
      city: "CEDAR",
      state: "IA"
    },
    {
      city: "CEDAR",
      state: "KS"
    },
    {
      city: "CEDAR",
      state: "MI"
    },
    {
      city: "CEDAR",
      state: "MN"
    },
    {
      city: "CEDAR BLUFF",
      state: "AL"
    },
    {
      city: "CEDAR BLUFF",
      state: "VA"
    },
    {
      city: "CEDAR BLUFFS",
      state: "NE"
    },
    {
      city: "CEDAR BROOK",
      state: "NJ"
    },
    {
      city: "CEDAR CITY",
      state: "UT"
    },
    {
      city: "CEDAR CREEK",
      state: "NE"
    },
    {
      city: "CEDAR CREEK",
      state: "TX"
    },
    {
      city: "CEDAR CREST",
      state: "NM"
    },
    {
      city: "CEDAR FALLS",
      state: "IA"
    },
    {
      city: "CEDAR FALLS",
      state: "NC"
    },
    {
      city: "CEDAR GLEN",
      state: "CA"
    },
    {
      city: "CEDAR GROVE",
      state: "IN"
    },
    {
      city: "CEDAR GROVE",
      state: "NC"
    },
    {
      city: "CEDAR GROVE",
      state: "NJ"
    },
    {
      city: "CEDAR GROVE",
      state: "TN"
    },
    {
      city: "CEDAR GROVE",
      state: "WI"
    },
    {
      city: "CEDAR GROVE",
      state: "WV"
    },
    {
      city: "CEDAR HILL",
      state: "MO"
    },
    {
      city: "CEDAR HILL",
      state: "TN"
    },
    {
      city: "CEDAR HILL",
      state: "TX"
    },
    {
      city: "CEDAR ISLAND",
      state: "NC"
    },
    {
      city: "CEDAR KEY",
      state: "FL"
    },
    {
      city: "CEDAR KNOLLS",
      state: "NJ"
    },
    {
      city: "CEDAR LAKE",
      state: "IN"
    },
    {
      city: "CEDAR LAKE",
      state: "MI"
    },
    {
      city: "CEDAR LANE",
      state: "TX"
    },
    {
      city: "CEDAR MOUNTAIN",
      state: "NC"
    },
    {
      city: "CEDAR PARK",
      state: "TX"
    },
    {
      city: "CEDAR POINT",
      state: "KS"
    },
    {
      city: "CEDAR RAPIDS",
      state: "IA"
    },
    {
      city: "CEDAR RAPIDS",
      state: "NE"
    },
    {
      city: "CEDAR RIDGE",
      state: "CA"
    },
    {
      city: "CEDAR RUN",
      state: "PA"
    },
    {
      city: "CEDAR SPRINGS",
      state: "GA"
    },
    {
      city: "CEDAR SPRINGS",
      state: "MI"
    },
    {
      city: "CEDAR VALE",
      state: "KS"
    },
    {
      city: "CEDAR VALLEY",
      state: "UT"
    },
    {
      city: "CEDARBLUFF",
      state: "MS"
    },
    {
      city: "CEDARBURG",
      state: "WI"
    },
    {
      city: "CEDARCREEK",
      state: "MO"
    },
    {
      city: "CEDAREDGE",
      state: "CO"
    },
    {
      city: "CEDARHURST",
      state: "NY"
    },
    {
      city: "CEDARPINES PARK",
      state: "CA"
    },
    {
      city: "CEDARS",
      state: "PA"
    },
    {
      city: "CEDARTOWN",
      state: "GA"
    },
    {
      city: "CEDARVILLE",
      state: "AR"
    },
    {
      city: "CEDARVILLE",
      state: "CA"
    },
    {
      city: "CEDARVILLE",
      state: "IL"
    },
    {
      city: "CEDARVILLE",
      state: "MI"
    },
    {
      city: "CEDARVILLE",
      state: "NJ"
    },
    {
      city: "CEDARVILLE",
      state: "OH"
    },
    {
      city: "CEDARVILLE",
      state: "WV"
    },
    {
      city: "CELESTE",
      state: "TX"
    },
    {
      city: "CELESTINE",
      state: "IN"
    },
    {
      city: "CELINA",
      state: "OH"
    },
    {
      city: "CELINA",
      state: "TN"
    },
    {
      city: "CELINA",
      state: "TX"
    },
    {
      city: "CELORON",
      state: "NY"
    },
    {
      city: "CEMENT",
      state: "OK"
    },
    {
      city: "CEMENT CITY",
      state: "MI"
    },
    {
      city: "CENTENARY",
      state: "SC"
    },
    {
      city: "CENTENNIAL",
      state: "WY"
    },
    {
      city: "CENTER",
      state: "CO"
    },
    {
      city: "CENTER",
      state: "KY"
    },
    {
      city: "CENTER",
      state: "MO"
    },
    {
      city: "CENTER",
      state: "ND"
    },
    {
      city: "CENTER",
      state: "NE"
    },
    {
      city: "CENTER",
      state: "TX"
    },
    {
      city: "CENTER BARNSTEAD",
      state: "NH"
    },
    {
      city: "CENTER CITY",
      state: "MN"
    },
    {
      city: "CENTER CONWAY",
      state: "NH"
    },
    {
      city: "CENTER CROSS",
      state: "VA"
    },
    {
      city: "CENTER HARBOR",
      state: "NH"
    },
    {
      city: "CENTER HILL",
      state: "FL"
    },
    {
      city: "CENTER JUNCTION",
      state: "IA"
    },
    {
      city: "CENTER LINE",
      state: "MI"
    },
    {
      city: "CENTER LOVELL",
      state: "ME"
    },
    {
      city: "CENTER MORICHES",
      state: "NY"
    },
    {
      city: "CENTER OSSIPEE",
      state: "NH"
    },
    {
      city: "CENTER POINT",
      state: "IA"
    },
    {
      city: "CENTER POINT",
      state: "LA"
    },
    {
      city: "CENTER POINT",
      state: "TX"
    },
    {
      city: "CENTER POINT",
      state: "WV"
    },
    {
      city: "CENTER RIDGE",
      state: "AR"
    },
    {
      city: "CENTER RUTLAND",
      state: "VT"
    },
    {
      city: "CENTER SANDWICH",
      state: "NH"
    },
    {
      city: "CENTER STRAFFORD",
      state: "NH"
    },
    {
      city: "CENTER TUFTONBORO",
      state: "NH"
    },
    {
      city: "CENTER VALLEY",
      state: "PA"
    },
    {
      city: "CENTERBROOK",
      state: "CT"
    },
    {
      city: "CENTERBURG",
      state: "OH"
    },
    {
      city: "CENTEREACH",
      state: "NY"
    },
    {
      city: "CENTERFIELD",
      state: "UT"
    },
    {
      city: "CENTERPOINT",
      state: "IN"
    },
    {
      city: "CENTERPORT",
      state: "NY"
    },
    {
      city: "CENTERPORT",
      state: "PA"
    },
    {
      city: "CENTERTON",
      state: "AR"
    },
    {
      city: "CENTERTOWN",
      state: "KY"
    },
    {
      city: "CENTERTOWN",
      state: "MO"
    },
    {
      city: "CENTERVIEW",
      state: "MO"
    },
    {
      city: "CENTERVILLE",
      state: "AR"
    },
    {
      city: "CENTERVILLE",
      state: "GA"
    },
    {
      city: "CENTERVILLE",
      state: "IA"
    },
    {
      city: "CENTERVILLE",
      state: "IN"
    },
    {
      city: "CENTERVILLE",
      state: "KS"
    },
    {
      city: "CENTERVILLE",
      state: "LA"
    },
    {
      city: "CENTERVILLE",
      state: "MA"
    },
    {
      city: "CENTERVILLE",
      state: "MO"
    },
    {
      city: "CENTERVILLE",
      state: "NY"
    },
    {
      city: "CENTERVILLE",
      state: "PA"
    },
    {
      city: "CENTERVILLE",
      state: "SD"
    },
    {
      city: "CENTERVILLE",
      state: "TN"
    },
    {
      city: "CENTERVILLE",
      state: "TX"
    },
    {
      city: "CENTERVILLE",
      state: "UT"
    },
    {
      city: "CENTERVILLE",
      state: "WA"
    },
    {
      city: "CENTRAHOMA",
      state: "OK"
    },
    {
      city: "CENTRAL",
      state: "AK"
    },
    {
      city: "CENTRAL",
      state: "AZ"
    },
    {
      city: "CENTRAL",
      state: "IN"
    },
    {
      city: "CENTRAL",
      state: "SC"
    },
    {
      city: "CENTRAL",
      state: "UT"
    },
    {
      city: "CENTRAL BRIDGE",
      state: "NY"
    },
    {
      city: "CENTRAL CITY",
      state: "CO"
    },
    {
      city: "CENTRAL CITY",
      state: "IA"
    },
    {
      city: "CENTRAL CITY",
      state: "KY"
    },
    {
      city: "CENTRAL CITY",
      state: "NE"
    },
    {
      city: "CENTRAL CITY",
      state: "PA"
    },
    {
      city: "CENTRAL FALLS",
      state: "RI"
    },
    {
      city: "CENTRAL ISLIP",
      state: "NY"
    },
    {
      city: "CENTRAL LAKE",
      state: "MI"
    },
    {
      city: "CENTRAL POINT",
      state: "OR"
    },
    {
      city: "CENTRAL SQUARE",
      state: "NY"
    },
    {
      city: "CENTRAL VALLEY",
      state: "NY"
    },
    {
      city: "CENTRAL VILLAGE",
      state: "CT"
    },
    {
      city: "CENTRALIA",
      state: "IL"
    },
    {
      city: "CENTRALIA",
      state: "KS"
    },
    {
      city: "CENTRALIA",
      state: "MO"
    },
    {
      city: "CENTRALIA",
      state: "WA"
    },
    {
      city: "CENTRE",
      state: "AL"
    },
    {
      city: "CENTRE HALL",
      state: "PA"
    },
    {
      city: "CENTREVILLE",
      state: "AL"
    },
    {
      city: "CENTREVILLE",
      state: "MD"
    },
    {
      city: "CENTREVILLE",
      state: "MI"
    },
    {
      city: "CENTREVILLE",
      state: "MS"
    },
    {
      city: "CENTREVILLE",
      state: "VA"
    },
    {
      city: "CENTURIA",
      state: "WI"
    },
    {
      city: "CENTURY",
      state: "FL"
    },
    {
      city: "CEREDO",
      state: "WV"
    },
    {
      city: "CERES",
      state: "CA"
    },
    {
      city: "CERES",
      state: "NY"
    },
    {
      city: "CERES",
      state: "VA"
    },
    {
      city: "CERESCO",
      state: "MI"
    },
    {
      city: "CERESCO",
      state: "NE"
    },
    {
      city: "CERRILLOS",
      state: "NM"
    },
    {
      city: "CERRITOS",
      state: "CA"
    },
    {
      city: "CERRO GORDO",
      state: "IL"
    },
    {
      city: "CERRO GORDO",
      state: "NC"
    },
    {
      city: "CERULEAN",
      state: "KY"
    },
    {
      city: "CEYLON",
      state: "MN"
    },
    {
      city: "CHACON",
      state: "NM"
    },
    {
      city: "CHADBOURN",
      state: "NC"
    },
    {
      city: "CHADDS FORD",
      state: "PA"
    },
    {
      city: "CHADRON",
      state: "NE"
    },
    {
      city: "CHADWICK",
      state: "IL"
    },
    {
      city: "CHADWICK",
      state: "MO"
    },
    {
      city: "CHADWICKS",
      state: "NY"
    },
    {
      city: "CHAFFEE",
      state: "MO"
    },
    {
      city: "CHAFFEE",
      state: "NY"
    },
    {
      city: "CHAGRIN FALLS",
      state: "OH"
    },
    {
      city: "CHALFONT",
      state: "PA"
    },
    {
      city: "CHALK HILL",
      state: "PA"
    },
    {
      city: "CHALKYITSIK",
      state: "AK"
    },
    {
      city: "CHALLENGE",
      state: "CA"
    },
    {
      city: "CHALLIS",
      state: "ID"
    },
    {
      city: "CHALMERS",
      state: "IN"
    },
    {
      city: "CHALMETTE",
      state: "LA"
    },
    {
      city: "CHAMA",
      state: "NM"
    },
    {
      city: "CHAMBERINO",
      state: "NM"
    },
    {
      city: "CHAMBERLAIN",
      state: "ME"
    },
    {
      city: "CHAMBERLAIN",
      state: "SD"
    },
    {
      city: "CHAMBERS",
      state: "AZ"
    },
    {
      city: "CHAMBERS",
      state: "NE"
    },
    {
      city: "CHAMBERSBURG",
      state: "IL"
    },
    {
      city: "CHAMBERSBURG",
      state: "PA"
    },
    {
      city: "CHAMBERSVILLE",
      state: "PA"
    },
    {
      city: "CHAMISAL",
      state: "NM"
    },
    {
      city: "CHAMOIS",
      state: "MO"
    },
    {
      city: "CHAMPAIGN",
      state: "IL"
    },
    {
      city: "CHAMPION",
      state: "MI"
    },
    {
      city: "CHAMPION",
      state: "NE"
    },
    {
      city: "CHAMPION",
      state: "PA"
    },
    {
      city: "CHAMPLAIN",
      state: "NY"
    },
    {
      city: "CHAMPLAIN",
      state: "VA"
    },
    {
      city: "CHAMPLIN",
      state: "MN"
    },
    {
      city: "CHANA",
      state: "IL"
    },
    {
      city: "CHANCELLOR",
      state: "AL"
    },
    {
      city: "CHANCELLOR",
      state: "SD"
    },
    {
      city: "CHANDLER",
      state: "AZ"
    },
    {
      city: "CHANDLER",
      state: "IN"
    },
    {
      city: "CHANDLER",
      state: "MN"
    },
    {
      city: "CHANDLER",
      state: "OK"
    },
    {
      city: "CHANDLER",
      state: "TX"
    },
    {
      city: "CHANDLER HEIGHTS",
      state: "AZ"
    },
    {
      city: "CHANDLERS VALLEY",
      state: "PA"
    },
    {
      city: "CHANDLERSVILLE",
      state: "OH"
    },
    {
      city: "CHANDLERVILLE",
      state: "IL"
    },
    {
      city: "CHANGEWATER",
      state: "NJ"
    },
    {
      city: "CHANHASSEN",
      state: "MN"
    },
    {
      city: "CHANNAHON",
      state: "IL"
    },
    {
      city: "CHANNELVIEW",
      state: "TX"
    },
    {
      city: "CHANNING",
      state: "MI"
    },
    {
      city: "CHANNING",
      state: "TX"
    },
    {
      city: "CHANTILLY",
      state: "VA"
    },
    {
      city: "CHANUTE",
      state: "KS"
    },
    {
      city: "CHAPARRAL",
      state: "NM"
    },
    {
      city: "CHAPEL HILL",
      state: "NC"
    },
    {
      city: "CHAPEL HILL",
      state: "TN"
    },
    {
      city: "CHAPIN",
      state: "IA"
    },
    {
      city: "CHAPIN",
      state: "IL"
    },
    {
      city: "CHAPIN",
      state: "SC"
    },
    {
      city: "CHAPLIN",
      state: "CT"
    },
    {
      city: "CHAPLIN",
      state: "KY"
    },
    {
      city: "CHAPMAN",
      state: "AL"
    },
    {
      city: "CHAPMAN",
      state: "KS"
    },
    {
      city: "CHAPMAN",
      state: "NE"
    },
    {
      city: "CHAPMANSBORO",
      state: "TN"
    },
    {
      city: "CHAPMANVILLE",
      state: "WV"
    },
    {
      city: "CHAPPAQUA",
      state: "NY"
    },
    {
      city: "CHAPPELL",
      state: "NE"
    },
    {
      city: "CHAPPELL HILL",
      state: "TX"
    },
    {
      city: "CHAPPELLS",
      state: "SC"
    },
    {
      city: "CHAPTICO",
      state: "MD"
    },
    {
      city: "CHARDON",
      state: "OH"
    },
    {
      city: "CHARENTON",
      state: "LA"
    },
    {
      city: "CHARITON",
      state: "IA"
    },
    {
      city: "CHARLEMONT",
      state: "MA"
    },
    {
      city: "CHARLEROI",
      state: "PA"
    },
    {
      city: "CHARLES CITY",
      state: "IA"
    },
    {
      city: "CHARLES CITY",
      state: "VA"
    },
    {
      city: "CHARLES TOWN",
      state: "WV"
    },
    {
      city: "CHARLESTON",
      state: "AR"
    },
    {
      city: "CHARLESTON",
      state: "IL"
    },
    {
      city: "CHARLESTON",
      state: "ME"
    },
    {
      city: "CHARLESTON",
      state: "MO"
    },
    {
      city: "CHARLESTON",
      state: "MS"
    },
    {
      city: "CHARLESTON",
      state: "SC"
    },
    {
      city: "CHARLESTON",
      state: "TN"
    },
    {
      city: "CHARLESTON",
      state: "WV"
    },
    {
      city: "CHARLESTON AFB",
      state: "SC"
    },
    {
      city: "CHARLESTOWN",
      state: "IN"
    },
    {
      city: "CHARLESTOWN",
      state: "MA"
    },
    {
      city: "CHARLESTOWN",
      state: "MD"
    },
    {
      city: "CHARLESTOWN",
      state: "NH"
    },
    {
      city: "CHARLESTOWN",
      state: "RI"
    },
    {
      city: "CHARLEVOIX",
      state: "MI"
    },
    {
      city: "CHARLO",
      state: "MT"
    },
    {
      city: "CHARLOTTE",
      state: "AR"
    },
    {
      city: "CHARLOTTE",
      state: "IA"
    },
    {
      city: "CHARLOTTE",
      state: "MI"
    },
    {
      city: "CHARLOTTE",
      state: "NC"
    },
    {
      city: "CHARLOTTE",
      state: "TN"
    },
    {
      city: "CHARLOTTE",
      state: "TX"
    },
    {
      city: "CHARLOTTE",
      state: "VT"
    },
    {
      city: "CHARLOTTE COURT HOUSE",
      state: "VA"
    },
    {
      city: "CHARLOTTE HALL",
      state: "MD"
    },
    {
      city: "CHARLOTTESVILLE",
      state: "IN"
    },
    {
      city: "CHARLOTTESVILLE",
      state: "VA"
    },
    {
      city: "CHARLOTTEVILLE",
      state: "NY"
    },
    {
      city: "CHARLTON",
      state: "MA"
    },
    {
      city: "CHARLTON CITY",
      state: "MA"
    },
    {
      city: "CHARLTON DEPOT",
      state: "MA"
    },
    {
      city: "CHARLTON HEIGHTS",
      state: "WV"
    },
    {
      city: "CHARM",
      state: "OH"
    },
    {
      city: "CHARMCO",
      state: "WV"
    },
    {
      city: "CHARTER OAK",
      state: "IA"
    },
    {
      city: "CHARTLEY",
      state: "MA"
    },
    {
      city: "CHASE",
      state: "KS"
    },
    {
      city: "CHASE",
      state: "LA"
    },
    {
      city: "CHASE",
      state: "MD"
    },
    {
      city: "CHASE",
      state: "MI"
    },
    {
      city: "CHASE CITY",
      state: "VA"
    },
    {
      city: "CHASE MILLS",
      state: "NY"
    },
    {
      city: "CHASEBURG",
      state: "WI"
    },
    {
      city: "CHASKA",
      state: "MN"
    },
    {
      city: "CHASSELL",
      state: "MI"
    },
    {
      city: "CHATAIGNIER",
      state: "LA"
    },
    {
      city: "CHATAWA",
      state: "MS"
    },
    {
      city: "CHATEAUGAY",
      state: "NY"
    },
    {
      city: "CHATFIELD",
      state: "MN"
    },
    {
      city: "CHATFIELD",
      state: "OH"
    },
    {
      city: "CHATFIELD",
      state: "TX"
    },
    {
      city: "CHATHAM",
      state: "IL"
    },
    {
      city: "CHATHAM",
      state: "LA"
    },
    {
      city: "CHATHAM",
      state: "MA"
    },
    {
      city: "CHATHAM",
      state: "MI"
    },
    {
      city: "CHATHAM",
      state: "MS"
    },
    {
      city: "CHATHAM",
      state: "NJ"
    },
    {
      city: "CHATHAM",
      state: "NY"
    },
    {
      city: "CHATHAM",
      state: "PA"
    },
    {
      city: "CHATHAM",
      state: "VA"
    },
    {
      city: "CHATOM",
      state: "AL"
    },
    {
      city: "CHATSWORTH",
      state: "CA"
    },
    {
      city: "CHATSWORTH",
      state: "GA"
    },
    {
      city: "CHATSWORTH",
      state: "IL"
    },
    {
      city: "CHATSWORTH",
      state: "NJ"
    },
    {
      city: "CHATTAHOOCHEE",
      state: "FL"
    },
    {
      city: "CHATTANOOGA",
      state: "OK"
    },
    {
      city: "CHATTANOOGA",
      state: "TN"
    },
    {
      city: "CHATTAROY",
      state: "WA"
    },
    {
      city: "CHATTAROY",
      state: "WV"
    },
    {
      city: "CHAUMONT",
      state: "NY"
    },
    {
      city: "CHAUNCEY",
      state: "GA"
    },
    {
      city: "CHAUNCEY",
      state: "OH"
    },
    {
      city: "CHAUNCEY",
      state: "WV"
    },
    {
      city: "CHAUTAUQUA",
      state: "NY"
    },
    {
      city: "CHAUVIN",
      state: "LA"
    },
    {
      city: "CHAVIES",
      state: "KY"
    },
    {
      city: "CHAZY",
      state: "NY"
    },
    {
      city: "CHEBANSE",
      state: "IL"
    },
    {
      city: "CHEBEAGUE ISLAND",
      state: "ME"
    },
    {
      city: "CHEBOYGAN",
      state: "MI"
    },
    {
      city: "CHECK",
      state: "VA"
    },
    {
      city: "CHECOTAH",
      state: "OK"
    },
    {
      city: "CHEFORNAK",
      state: "AK"
    },
    {
      city: "CHEHALIS",
      state: "WA"
    },
    {
      city: "CHELAN",
      state: "WA"
    },
    {
      city: "CHELAN FALLS",
      state: "WA"
    },
    {
      city: "CHELMSFORD",
      state: "MA"
    },
    {
      city: "CHELSEA",
      state: "AL"
    },
    {
      city: "CHELSEA",
      state: "IA"
    },
    {
      city: "CHELSEA",
      state: "MA"
    },
    {
      city: "CHELSEA",
      state: "MI"
    },
    {
      city: "CHELSEA",
      state: "NY"
    },
    {
      city: "CHELSEA",
      state: "OK"
    },
    {
      city: "CHELSEA",
      state: "VT"
    },
    {
      city: "CHELTENHAM",
      state: "MD"
    },
    {
      city: "CHELTENHAM",
      state: "PA"
    },
    {
      city: "CHEMULT",
      state: "OR"
    },
    {
      city: "CHEMUNG",
      state: "NY"
    },
    {
      city: "CHENANGO BRIDGE",
      state: "NY"
    },
    {
      city: "CHENANGO FORKS",
      state: "NY"
    },
    {
      city: "CHENEY",
      state: "KS"
    },
    {
      city: "CHENEY",
      state: "WA"
    },
    {
      city: "CHENEYVILLE",
      state: "LA"
    },
    {
      city: "CHENOA",
      state: "IL"
    },
    {
      city: "CHEPACHET",
      state: "RI"
    },
    {
      city: "CHERAW",
      state: "CO"
    },
    {
      city: "CHERAW",
      state: "SC"
    },
    {
      city: "CHERITON",
      state: "VA"
    },
    {
      city: "CHEROKEE",
      state: "AL"
    },
    {
      city: "CHEROKEE",
      state: "IA"
    },
    {
      city: "CHEROKEE",
      state: "KS"
    },
    {
      city: "CHEROKEE",
      state: "NC"
    },
    {
      city: "CHEROKEE",
      state: "OK"
    },
    {
      city: "CHEROKEE",
      state: "TX"
    },
    {
      city: "CHEROKEE VILLAGE",
      state: "AR"
    },
    {
      city: "CHERRY",
      state: "IL"
    },
    {
      city: "CHERRY CREEK",
      state: "NY"
    },
    {
      city: "CHERRY FORK",
      state: "OH"
    },
    {
      city: "CHERRY HILL",
      state: "NJ"
    },
    {
      city: "CHERRY LOG",
      state: "GA"
    },
    {
      city: "CHERRY PLAIN",
      state: "NY"
    },
    {
      city: "CHERRY POINT",
      state: "NC"
    },
    {
      city: "CHERRY TREE",
      state: "PA"
    },
    {
      city: "CHERRY VALLEY",
      state: "AR"
    },
    {
      city: "CHERRY VALLEY",
      state: "IL"
    },
    {
      city: "CHERRY VALLEY",
      state: "MA"
    },
    {
      city: "CHERRY VALLEY",
      state: "NY"
    },
    {
      city: "CHERRYFIELD",
      state: "ME"
    },
    {
      city: "CHERRYVALE",
      state: "KS"
    },
    {
      city: "CHERRYVILLE",
      state: "MO"
    },
    {
      city: "CHERRYVILLE",
      state: "NC"
    },
    {
      city: "CHERRYVILLE",
      state: "PA"
    },
    {
      city: "CHESANING",
      state: "MI"
    },
    {
      city: "CHESAPEAKE",
      state: "OH"
    },
    {
      city: "CHESAPEAKE",
      state: "VA"
    },
    {
      city: "CHESAPEAKE BEACH",
      state: "MD"
    },
    {
      city: "CHESAPEAKE CITY",
      state: "MD"
    },
    {
      city: "CHESHIRE",
      state: "CT"
    },
    {
      city: "CHESHIRE",
      state: "MA"
    },
    {
      city: "CHESHIRE",
      state: "OH"
    },
    {
      city: "CHESHIRE",
      state: "OR"
    },
    {
      city: "CHESNEE",
      state: "SC"
    },
    {
      city: "CHEST SPRINGS",
      state: "PA"
    },
    {
      city: "CHESTER",
      state: "AR"
    },
    {
      city: "CHESTER",
      state: "CA"
    },
    {
      city: "CHESTER",
      state: "CT"
    },
    {
      city: "CHESTER",
      state: "GA"
    },
    {
      city: "CHESTER",
      state: "IA"
    },
    {
      city: "CHESTER",
      state: "ID"
    },
    {
      city: "CHESTER",
      state: "IL"
    },
    {
      city: "CHESTER",
      state: "MA"
    },
    {
      city: "CHESTER",
      state: "MD"
    },
    {
      city: "CHESTER",
      state: "MT"
    },
    {
      city: "CHESTER",
      state: "NE"
    },
    {
      city: "CHESTER",
      state: "NH"
    },
    {
      city: "CHESTER",
      state: "NJ"
    },
    {
      city: "CHESTER",
      state: "NY"
    },
    {
      city: "CHESTER",
      state: "OH"
    },
    {
      city: "CHESTER",
      state: "OK"
    },
    {
      city: "CHESTER",
      state: "PA"
    },
    {
      city: "CHESTER",
      state: "SC"
    },
    {
      city: "CHESTER",
      state: "SD"
    },
    {
      city: "CHESTER",
      state: "TX"
    },
    {
      city: "CHESTER",
      state: "UT"
    },
    {
      city: "CHESTER",
      state: "VA"
    },
    {
      city: "CHESTER",
      state: "VT"
    },
    {
      city: "CHESTER",
      state: "WV"
    },
    {
      city: "CHESTER GAP",
      state: "VA"
    },
    {
      city: "CHESTER HEIGHTS",
      state: "PA"
    },
    {
      city: "CHESTER SPRINGS",
      state: "PA"
    },
    {
      city: "CHESTERFIELD",
      state: "IL"
    },
    {
      city: "CHESTERFIELD",
      state: "MA"
    },
    {
      city: "CHESTERFIELD",
      state: "MO"
    },
    {
      city: "CHESTERFIELD",
      state: "NH"
    },
    {
      city: "CHESTERFIELD",
      state: "NJ"
    },
    {
      city: "CHESTERFIELD",
      state: "SC"
    },
    {
      city: "CHESTERFIELD",
      state: "VA"
    },
    {
      city: "CHESTERHILL",
      state: "OH"
    },
    {
      city: "CHESTERLAND",
      state: "OH"
    },
    {
      city: "CHESTERTON",
      state: "IN"
    },
    {
      city: "CHESTERTOWN",
      state: "MD"
    },
    {
      city: "CHESTERTOWN",
      state: "NY"
    },
    {
      city: "CHESTERVILLE",
      state: "OH"
    },
    {
      city: "CHESTNUT",
      state: "IL"
    },
    {
      city: "CHESTNUT HILL",
      state: "MA"
    },
    {
      city: "CHESTNUT MOUND",
      state: "TN"
    },
    {
      city: "CHESTNUT MOUNTAIN",
      state: "GA"
    },
    {
      city: "CHESTNUT RIDGE",
      state: "PA"
    },
    {
      city: "CHESTNUTRIDGE",
      state: "MO"
    },
    {
      city: "CHESWICK",
      state: "PA"
    },
    {
      city: "CHESWOLD",
      state: "DE"
    },
    {
      city: "CHETEK",
      state: "WI"
    },
    {
      city: "CHETOPA",
      state: "KS"
    },
    {
      city: "CHEVAK",
      state: "AK"
    },
    {
      city: "CHEVY CHASE",
      state: "MD"
    },
    {
      city: "CHEWELAH",
      state: "WA"
    },
    {
      city: "CHEWSVILLE",
      state: "MD"
    },
    {
      city: "CHEYENNE",
      state: "OK"
    },
    {
      city: "CHEYENNE",
      state: "WY"
    },
    {
      city: "CHEYENNE WELLS",
      state: "CO"
    },
    {
      city: "CHEYNEY",
      state: "PA"
    },
    {
      city: "CHICAGO",
      state: "IL"
    },
    {
      city: "CHICAGO HEIGHTS",
      state: "IL"
    },
    {
      city: "CHICAGO PARK",
      state: "CA"
    },
    {
      city: "CHICAGO RIDGE",
      state: "IL"
    },
    {
      city: "CHICHESTER",
      state: "NH"
    },
    {
      city: "CHICHESTER",
      state: "NY"
    },
    {
      city: "CHICKAMAUGA",
      state: "GA"
    },
    {
      city: "CHICKASAW",
      state: "OH"
    },
    {
      city: "CHICKASHA",
      state: "OK"
    },
    {
      city: "CHICKEN",
      state: "AK"
    },
    {
      city: "CHICO",
      state: "CA"
    },
    {
      city: "CHICO",
      state: "TX"
    },
    {
      city: "CHICOPEE",
      state: "MA"
    },
    {
      city: "CHICORA",
      state: "PA"
    },
    {
      city: "CHIDESTER",
      state: "AR"
    },
    {
      city: "CHIEFLAND",
      state: "FL"
    },
    {
      city: "CHIGNIK",
      state: "AK"
    },
    {
      city: "CHIGNIK LAGOON",
      state: "AK"
    },
    {
      city: "CHILCOOT",
      state: "CA"
    },
    {
      city: "CHILDERSBURG",
      state: "AL"
    },
    {
      city: "CHILDRESS",
      state: "TX"
    },
    {
      city: "CHILDS",
      state: "MD"
    },
    {
      city: "CHILDWOLD",
      state: "NY"
    },
    {
      city: "CHILHOWEE",
      state: "MO"
    },
    {
      city: "CHILHOWIE",
      state: "VA"
    },
    {
      city: "CHILI",
      state: "WI"
    },
    {
      city: "CHILLICOTHE",
      state: "IL"
    },
    {
      city: "CHILLICOTHE",
      state: "MO"
    },
    {
      city: "CHILLICOTHE",
      state: "OH"
    },
    {
      city: "CHILLICOTHE",
      state: "TX"
    },
    {
      city: "CHILMARK",
      state: "MA"
    },
    {
      city: "CHILO",
      state: "OH"
    },
    {
      city: "CHILOQUIN",
      state: "OR"
    },
    {
      city: "CHILTON",
      state: "TX"
    },
    {
      city: "CHILTON",
      state: "WI"
    },
    {
      city: "CHIMACUM",
      state: "WA"
    },
    {
      city: "CHIMAYO",
      state: "NM"
    },
    {
      city: "CHIMNEY ROCK",
      state: "NC"
    },
    {
      city: "CHINA",
      state: "TX"
    },
    {
      city: "CHINA GROVE",
      state: "NC"
    },
    {
      city: "CHINA SPRING",
      state: "TX"
    },
    {
      city: "CHINA VILLAGE",
      state: "ME"
    },
    {
      city: "CHINCHILLA",
      state: "PA"
    },
    {
      city: "CHINCOTEAGUE ISLAND",
      state: "VA"
    },
    {
      city: "CHINESE CAMP",
      state: "CA"
    },
    {
      city: "CHINLE",
      state: "AZ"
    },
    {
      city: "CHINO",
      state: "CA"
    },
    {
      city: "CHINO HILLS",
      state: "CA"
    },
    {
      city: "CHINO VALLEY",
      state: "AZ"
    },
    {
      city: "CHINOOK",
      state: "MT"
    },
    {
      city: "CHINOOK",
      state: "WA"
    },
    {
      city: "CHINQUAPIN",
      state: "NC"
    },
    {
      city: "CHIPLEY",
      state: "FL"
    },
    {
      city: "CHIPPEWA BAY",
      state: "NY"
    },
    {
      city: "CHIPPEWA FALLS",
      state: "WI"
    },
    {
      city: "CHIPPEWA LAKE",
      state: "MI"
    },
    {
      city: "CHIPPEWA LAKE",
      state: "OH"
    },
    {
      city: "CHIRENO",
      state: "TX"
    },
    {
      city: "CHISAGO CITY",
      state: "MN"
    },
    {
      city: "CHISHOLM",
      state: "MN"
    },
    {
      city: "CHITINA",
      state: "AK"
    },
    {
      city: "CHITTENANGO",
      state: "NY"
    },
    {
      city: "CHITTENDEN",
      state: "VT"
    },
    {
      city: "CHLOE",
      state: "WV"
    },
    {
      city: "CHLORIDE",
      state: "AZ"
    },
    {
      city: "CHOCCOLOCCO",
      state: "AL"
    },
    {
      city: "CHOCORUA",
      state: "NH"
    },
    {
      city: "CHOCOWINITY",
      state: "NC"
    },
    {
      city: "CHOCTAW",
      state: "AR"
    },
    {
      city: "CHOCTAW",
      state: "OK"
    },
    {
      city: "CHOKIO",
      state: "MN"
    },
    {
      city: "CHOKOLOSKEE",
      state: "FL"
    },
    {
      city: "CHOTEAU",
      state: "MT"
    },
    {
      city: "CHOUDRANT",
      state: "LA"
    },
    {
      city: "CHOUTEAU",
      state: "OK"
    },
    {
      city: "CHOWCHILLA",
      state: "CA"
    },
    {
      city: "CHRIESMAN",
      state: "TX"
    },
    {
      city: "CHRISMAN",
      state: "IL"
    },
    {
      city: "CHRISNEY",
      state: "IN"
    },
    {
      city: "CHRISTCHURCH",
      state: "VA"
    },
    {
      city: "CHRISTIANA",
      state: "PA"
    },
    {
      city: "CHRISTIANA",
      state: "TN"
    },
    {
      city: "CHRISTIANSBURG",
      state: "OH"
    },
    {
      city: "CHRISTIANSBURG",
      state: "VA"
    },
    {
      city: "CHRISTINE",
      state: "ND"
    },
    {
      city: "CHRISTINE",
      state: "TX"
    },
    {
      city: "CHRISTMAS",
      state: "FL"
    },
    {
      city: "CHRISTMAS VALLEY",
      state: "OR"
    },
    {
      city: "CHRISTOPHER",
      state: "IL"
    },
    {
      city: "CHRISTOVAL",
      state: "TX"
    },
    {
      city: "CHROMO",
      state: "CO"
    },
    {
      city: "CHUALAR",
      state: "CA"
    },
    {
      city: "CHUCKEY",
      state: "TN"
    },
    {
      city: "CHUGIAK",
      state: "AK"
    },
    {
      city: "CHUGWATER",
      state: "WY"
    },
    {
      city: "CHULA",
      state: "GA"
    },
    {
      city: "CHULA",
      state: "MO"
    },
    {
      city: "CHULA VISTA",
      state: "CA"
    },
    {
      city: "CHUNCHULA",
      state: "AL"
    },
    {
      city: "CHUNKY",
      state: "MS"
    },
    {
      city: "CHURCH CREEK",
      state: "MD"
    },
    {
      city: "CHURCH HILL",
      state: "MD"
    },
    {
      city: "CHURCH HILL",
      state: "TN"
    },
    {
      city: "CHURCH POINT",
      state: "LA"
    },
    {
      city: "CHURCH ROAD",
      state: "VA"
    },
    {
      city: "CHURCH ROCK",
      state: "NM"
    },
    {
      city: "CHURCH VIEW",
      state: "VA"
    },
    {
      city: "CHURCHS FERRY",
      state: "ND"
    },
    {
      city: "CHURCHTON",
      state: "MD"
    },
    {
      city: "CHURCHVILLE",
      state: "MD"
    },
    {
      city: "CHURCHVILLE",
      state: "NY"
    },
    {
      city: "CHURCHVILLE",
      state: "VA"
    },
    {
      city: "CHURDAN",
      state: "IA"
    },
    {
      city: "CHURUBUSCO",
      state: "IN"
    },
    {
      city: "CHURUBUSCO",
      state: "NY"
    },
    {
      city: "CIBECUE",
      state: "AZ"
    },
    {
      city: "CIBOLA",
      state: "AZ"
    },
    {
      city: "CIBOLO",
      state: "TX"
    },
    {
      city: "CICERO",
      state: "IL"
    },
    {
      city: "CICERO",
      state: "IN"
    },
    {
      city: "CICERO",
      state: "NY"
    },
    {
      city: "CIMA",
      state: "CA"
    },
    {
      city: "CIMARRON",
      state: "CO"
    },
    {
      city: "CIMARRON",
      state: "KS"
    },
    {
      city: "CIMARRON",
      state: "NM"
    },
    {
      city: "CINCINNATI",
      state: "IA"
    },
    {
      city: "CINCINNATI",
      state: "OH"
    },
    {
      city: "CINCINNATUS",
      state: "NY"
    },
    {
      city: "CINEBAR",
      state: "WA"
    },
    {
      city: "CIRCLE",
      state: "MT"
    },
    {
      city: "CIRCLE PINES",
      state: "MN"
    },
    {
      city: "CIRCLEVILLE",
      state: "KS"
    },
    {
      city: "CIRCLEVILLE",
      state: "NY"
    },
    {
      city: "CIRCLEVILLE",
      state: "OH"
    },
    {
      city: "CIRCLEVILLE",
      state: "UT"
    },
    {
      city: "CIRCLEVILLE",
      state: "WV"
    },
    {
      city: "CISCO",
      state: "GA"
    },
    {
      city: "CISCO",
      state: "IL"
    },
    {
      city: "CISCO",
      state: "TX"
    },
    {
      city: "CISCO",
      state: "UT"
    },
    {
      city: "CISNE",
      state: "IL"
    },
    {
      city: "CISSNA PARK",
      state: "IL"
    },
    {
      city: "CITRA",
      state: "FL"
    },
    {
      city: "CITRONELLE",
      state: "AL"
    },
    {
      city: "CITRUS HEIGHTS",
      state: "CA"
    },
    {
      city: "CITY OF INDUSTRY",
      state: "CA"
    },
    {
      city: "CLACKAMAS",
      state: "OR"
    },
    {
      city: "CLAFLIN",
      state: "KS"
    },
    {
      city: "CLAIBORNE",
      state: "MD"
    },
    {
      city: "CLAIRE CITY",
      state: "SD"
    },
    {
      city: "CLAIRFIELD",
      state: "TN"
    },
    {
      city: "CLAIRTON",
      state: "PA"
    },
    {
      city: "CLALLAM BAY",
      state: "WA"
    },
    {
      city: "CLAM GULCH",
      state: "AK"
    },
    {
      city: "CLAM LAKE",
      state: "WI"
    },
    {
      city: "CLANCY",
      state: "MT"
    },
    {
      city: "CLANTON",
      state: "AL"
    },
    {
      city: "CLARA",
      state: "MS"
    },
    {
      city: "CLARA CITY",
      state: "MN"
    },
    {
      city: "CLARCONA",
      state: "FL"
    },
    {
      city: "CLARE",
      state: "IA"
    },
    {
      city: "CLARE",
      state: "IL"
    },
    {
      city: "CLARE",
      state: "MI"
    },
    {
      city: "CLAREMONT",
      state: "CA"
    },
    {
      city: "CLAREMONT",
      state: "IL"
    },
    {
      city: "CLAREMONT",
      state: "MN"
    },
    {
      city: "CLAREMONT",
      state: "NC"
    },
    {
      city: "CLAREMONT",
      state: "NH"
    },
    {
      city: "CLAREMONT",
      state: "SD"
    },
    {
      city: "CLAREMONT",
      state: "VA"
    },
    {
      city: "CLAREMORE",
      state: "OK"
    },
    {
      city: "CLARENCE",
      state: "IA"
    },
    {
      city: "CLARENCE",
      state: "LA"
    },
    {
      city: "CLARENCE",
      state: "MO"
    },
    {
      city: "CLARENCE",
      state: "NY"
    },
    {
      city: "CLARENCE",
      state: "PA"
    },
    {
      city: "CLARENCE CENTER",
      state: "NY"
    },
    {
      city: "CLARENDON",
      state: "AR"
    },
    {
      city: "CLARENDON",
      state: "NC"
    },
    {
      city: "CLARENDON",
      state: "NY"
    },
    {
      city: "CLARENDON",
      state: "PA"
    },
    {
      city: "CLARENDON",
      state: "TX"
    },
    {
      city: "CLARENDON HILLS",
      state: "IL"
    },
    {
      city: "CLARIDGE",
      state: "PA"
    },
    {
      city: "CLARINDA",
      state: "IA"
    },
    {
      city: "CLARINGTON",
      state: "OH"
    },
    {
      city: "CLARINGTON",
      state: "PA"
    },
    {
      city: "CLARION",
      state: "IA"
    },
    {
      city: "CLARION",
      state: "PA"
    },
    {
      city: "CLARISSA",
      state: "MN"
    },
    {
      city: "CLARITA",
      state: "OK"
    },
    {
      city: "CLARK",
      state: "CO"
    },
    {
      city: "CLARK",
      state: "MO"
    },
    {
      city: "CLARK",
      state: "NJ"
    },
    {
      city: "CLARK",
      state: "PA"
    },
    {
      city: "CLARK",
      state: "SD"
    },
    {
      city: "CLARK FORK",
      state: "ID"
    },
    {
      city: "CLARK MILLS",
      state: "NY"
    },
    {
      city: "CLARKDALE",
      state: "AZ"
    },
    {
      city: "CLARKDALE",
      state: "GA"
    },
    {
      city: "CLARKEDALE",
      state: "AR"
    },
    {
      city: "CLARKESVILLE",
      state: "GA"
    },
    {
      city: "CLARKFIELD",
      state: "MN"
    },
    {
      city: "CLARKLAKE",
      state: "MI"
    },
    {
      city: "CLARKRANGE",
      state: "TN"
    },
    {
      city: "CLARKRIDGE",
      state: "AR"
    },
    {
      city: "CLARKS",
      state: "LA"
    },
    {
      city: "CLARKS",
      state: "NE"
    },
    {
      city: "CLARKS GROVE",
      state: "MN"
    },
    {
      city: "CLARKS HILL",
      state: "IN"
    },
    {
      city: "CLARKS HILL",
      state: "SC"
    },
    {
      city: "CLARKS MILLS",
      state: "PA"
    },
    {
      city: "CLARKS POINT",
      state: "AK"
    },
    {
      city: "CLARKS SUMMIT",
      state: "PA"
    },
    {
      city: "CLARKSBORO",
      state: "NJ"
    },
    {
      city: "CLARKSBURG",
      state: "CA"
    },
    {
      city: "CLARKSBURG",
      state: "IN"
    },
    {
      city: "CLARKSBURG",
      state: "MD"
    },
    {
      city: "CLARKSBURG",
      state: "MO"
    },
    {
      city: "CLARKSBURG",
      state: "OH"
    },
    {
      city: "CLARKSBURG",
      state: "PA"
    },
    {
      city: "CLARKSBURG",
      state: "TN"
    },
    {
      city: "CLARKSBURG",
      state: "WV"
    },
    {
      city: "CLARKSDALE",
      state: "MO"
    },
    {
      city: "CLARKSDALE",
      state: "MS"
    },
    {
      city: "CLARKSON",
      state: "KY"
    },
    {
      city: "CLARKSON",
      state: "NE"
    },
    {
      city: "CLARKSON",
      state: "NY"
    },
    {
      city: "CLARKSTON",
      state: "GA"
    },
    {
      city: "CLARKSTON",
      state: "MI"
    },
    {
      city: "CLARKSTON",
      state: "UT"
    },
    {
      city: "CLARKSTON",
      state: "WA"
    },
    {
      city: "CLARKSVILLE",
      state: "AR"
    },
    {
      city: "CLARKSVILLE",
      state: "FL"
    },
    {
      city: "CLARKSVILLE",
      state: "IA"
    },
    {
      city: "CLARKSVILLE",
      state: "IN"
    },
    {
      city: "CLARKSVILLE",
      state: "MD"
    },
    {
      city: "CLARKSVILLE",
      state: "MI"
    },
    {
      city: "CLARKSVILLE",
      state: "MO"
    },
    {
      city: "CLARKSVILLE",
      state: "NY"
    },
    {
      city: "CLARKSVILLE",
      state: "OH"
    },
    {
      city: "CLARKSVILLE",
      state: "PA"
    },
    {
      city: "CLARKSVILLE",
      state: "TN"
    },
    {
      city: "CLARKSVILLE",
      state: "TX"
    },
    {
      city: "CLARKSVILLE",
      state: "VA"
    },
    {
      city: "CLARKTON",
      state: "MO"
    },
    {
      city: "CLARKTON",
      state: "NC"
    },
    {
      city: "CLARYVILLE",
      state: "NY"
    },
    {
      city: "CLATONIA",
      state: "NE"
    },
    {
      city: "CLATSKANIE",
      state: "OR"
    },
    {
      city: "CLAUDE",
      state: "TX"
    },
    {
      city: "CLAUDVILLE",
      state: "VA"
    },
    {
      city: "CLAUNCH",
      state: "NM"
    },
    {
      city: "CLAVERACK",
      state: "NY"
    },
    {
      city: "CLAWSON",
      state: "MI"
    },
    {
      city: "CLAXTON",
      state: "GA"
    },
    {
      city: "CLAY",
      state: "AL"
    },
    {
      city: "CLAY",
      state: "KY"
    },
    {
      city: "CLAY",
      state: "NY"
    },
    {
      city: "CLAY",
      state: "WV"
    },
    {
      city: "CLAY CENTER",
      state: "KS"
    },
    {
      city: "CLAY CENTER",
      state: "NE"
    },
    {
      city: "CLAY CENTER",
      state: "OH"
    },
    {
      city: "CLAY CITY",
      state: "IL"
    },
    {
      city: "CLAY CITY",
      state: "IN"
    },
    {
      city: "CLAY CITY",
      state: "KY"
    },
    {
      city: "CLAY SPRINGS",
      state: "AZ"
    },
    {
      city: "CLAYHOLE",
      state: "KY"
    },
    {
      city: "CLAYMONT",
      state: "DE"
    },
    {
      city: "CLAYPOOL",
      state: "AZ"
    },
    {
      city: "CLAYPOOL",
      state: "IN"
    },
    {
      city: "CLAYSBURG",
      state: "PA"
    },
    {
      city: "CLAYSVILLE",
      state: "PA"
    },
    {
      city: "CLAYTON",
      state: "AL"
    },
    {
      city: "CLAYTON",
      state: "CA"
    },
    {
      city: "CLAYTON",
      state: "DE"
    },
    {
      city: "CLAYTON",
      state: "GA"
    },
    {
      city: "CLAYTON",
      state: "ID"
    },
    {
      city: "CLAYTON",
      state: "IL"
    },
    {
      city: "CLAYTON",
      state: "IN"
    },
    {
      city: "CLAYTON",
      state: "KS"
    },
    {
      city: "CLAYTON",
      state: "LA"
    },
    {
      city: "CLAYTON",
      state: "MI"
    },
    {
      city: "CLAYTON",
      state: "NC"
    },
    {
      city: "CLAYTON",
      state: "NJ"
    },
    {
      city: "CLAYTON",
      state: "NM"
    },
    {
      city: "CLAYTON",
      state: "NY"
    },
    {
      city: "CLAYTON",
      state: "OH"
    },
    {
      city: "CLAYTON",
      state: "OK"
    },
    {
      city: "CLAYTON",
      state: "TX"
    },
    {
      city: "CLAYTON",
      state: "WA"
    },
    {
      city: "CLAYTON",
      state: "WI"
    },
    {
      city: "CLAYTON LAKE",
      state: "ME"
    },
    {
      city: "CLAYVILLE",
      state: "NY"
    },
    {
      city: "CLAYVILLE",
      state: "RI"
    },
    {
      city: "CLE ELUM",
      state: "WA"
    },
    {
      city: "CLEAR",
      state: "AK"
    },
    {
      city: "CLEAR BROOK",
      state: "VA"
    },
    {
      city: "CLEAR CREEK",
      state: "IN"
    },
    {
      city: "CLEAR CREEK",
      state: "WV"
    },
    {
      city: "CLEAR FORK",
      state: "WV"
    },
    {
      city: "CLEAR LAKE",
      state: "IA"
    },
    {
      city: "CLEAR LAKE",
      state: "MN"
    },
    {
      city: "CLEAR LAKE",
      state: "SD"
    },
    {
      city: "CLEAR LAKE",
      state: "WI"
    },
    {
      city: "CLEAR SPRING",
      state: "MD"
    },
    {
      city: "CLEARBROOK",
      state: "MN"
    },
    {
      city: "CLEARFIELD",
      state: "IA"
    },
    {
      city: "CLEARFIELD",
      state: "KY"
    },
    {
      city: "CLEARFIELD",
      state: "PA"
    },
    {
      city: "CLEARFIELD",
      state: "UT"
    },
    {
      city: "CLEARLAKE",
      state: "CA"
    },
    {
      city: "CLEARLAKE",
      state: "WA"
    },
    {
      city: "CLEARLAKE OAKS",
      state: "CA"
    },
    {
      city: "CLEARLAKE PARK",
      state: "CA"
    },
    {
      city: "CLEARMONT",
      state: "MO"
    },
    {
      city: "CLEARMONT",
      state: "WY"
    },
    {
      city: "CLEARVIEW CITY",
      state: "KS"
    },
    {
      city: "CLEARVILLE",
      state: "PA"
    },
    {
      city: "CLEARWATER",
      state: "FL"
    },
    {
      city: "CLEARWATER",
      state: "KS"
    },
    {
      city: "CLEARWATER",
      state: "MN"
    },
    {
      city: "CLEARWATER",
      state: "NE"
    },
    {
      city: "CLEARWATER",
      state: "SC"
    },
    {
      city: "CLEARWATER BEACH",
      state: "FL"
    },
    {
      city: "CLEATON",
      state: "KY"
    },
    {
      city: "CLEBURNE",
      state: "TX"
    },
    {
      city: "CLEGHORN",
      state: "IA"
    },
    {
      city: "CLEMENTON",
      state: "NJ"
    },
    {
      city: "CLEMENTS",
      state: "CA"
    },
    {
      city: "CLEMENTS",
      state: "MD"
    },
    {
      city: "CLEMENTS",
      state: "MN"
    },
    {
      city: "CLEMMONS",
      state: "NC"
    },
    {
      city: "CLEMONS",
      state: "IA"
    },
    {
      city: "CLEMONS",
      state: "NY"
    },
    {
      city: "CLEMSON",
      state: "SC"
    },
    {
      city: "CLENDENIN",
      state: "WV"
    },
    {
      city: "CLEO SPRINGS",
      state: "OK"
    },
    {
      city: "CLERMONT",
      state: "FL"
    },
    {
      city: "CLERMONT",
      state: "GA"
    },
    {
      city: "CLERMONT",
      state: "IA"
    },
    {
      city: "CLERMONT",
      state: "KY"
    },
    {
      city: "CLEVELAND",
      state: "AL"
    },
    {
      city: "CLEVELAND",
      state: "AR"
    },
    {
      city: "CLEVELAND",
      state: "GA"
    },
    {
      city: "CLEVELAND",
      state: "MN"
    },
    {
      city: "CLEVELAND",
      state: "MO"
    },
    {
      city: "CLEVELAND",
      state: "MS"
    },
    {
      city: "CLEVELAND",
      state: "NC"
    },
    {
      city: "CLEVELAND",
      state: "ND"
    },
    {
      city: "CLEVELAND",
      state: "NM"
    },
    {
      city: "CLEVELAND",
      state: "NY"
    },
    {
      city: "CLEVELAND",
      state: "OH"
    },
    {
      city: "CLEVELAND",
      state: "OK"
    },
    {
      city: "CLEVELAND",
      state: "SC"
    },
    {
      city: "CLEVELAND",
      state: "TN"
    },
    {
      city: "CLEVELAND",
      state: "TX"
    },
    {
      city: "CLEVELAND",
      state: "UT"
    },
    {
      city: "CLEVELAND",
      state: "VA"
    },
    {
      city: "CLEVELAND",
      state: "WI"
    },
    {
      city: "CLEVELAND",
      state: "WV"
    },
    {
      city: "CLEVER",
      state: "MO"
    },
    {
      city: "CLEVERDALE",
      state: "NY"
    },
    {
      city: "CLEVES",
      state: "OH"
    },
    {
      city: "CLEWISTON",
      state: "FL"
    },
    {
      city: "CLIFF",
      state: "NM"
    },
    {
      city: "CLIFF ISLAND",
      state: "ME"
    },
    {
      city: "CLIFFORD",
      state: "IN"
    },
    {
      city: "CLIFFORD",
      state: "MI"
    },
    {
      city: "CLIFFORD",
      state: "ND"
    },
    {
      city: "CLIFFORD",
      state: "PA"
    },
    {
      city: "CLIFFORD",
      state: "VA"
    },
    {
      city: "CLIFFSIDE",
      state: "NC"
    },
    {
      city: "CLIFFSIDE PARK",
      state: "NJ"
    },
    {
      city: "CLIFFWOOD",
      state: "NJ"
    },
    {
      city: "CLIFTON",
      state: "AZ"
    },
    {
      city: "CLIFTON",
      state: "CO"
    },
    {
      city: "CLIFTON",
      state: "ID"
    },
    {
      city: "CLIFTON",
      state: "IL"
    },
    {
      city: "CLIFTON",
      state: "KS"
    },
    {
      city: "CLIFTON",
      state: "NJ"
    },
    {
      city: "CLIFTON",
      state: "OH"
    },
    {
      city: "CLIFTON",
      state: "SC"
    },
    {
      city: "CLIFTON",
      state: "TN"
    },
    {
      city: "CLIFTON",
      state: "TX"
    },
    {
      city: "CLIFTON",
      state: "VA"
    },
    {
      city: "CLIFTON FORGE",
      state: "VA"
    },
    {
      city: "CLIFTON HEIGHTS",
      state: "PA"
    },
    {
      city: "CLIFTON HILL",
      state: "MO"
    },
    {
      city: "CLIFTON PARK",
      state: "NY"
    },
    {
      city: "CLIFTON SPRINGS",
      state: "NY"
    },
    {
      city: "CLIFTY",
      state: "KY"
    },
    {
      city: "CLIMAX",
      state: "CO"
    },
    {
      city: "CLIMAX",
      state: "GA"
    },
    {
      city: "CLIMAX",
      state: "MI"
    },
    {
      city: "CLIMAX",
      state: "MN"
    },
    {
      city: "CLIMAX",
      state: "NC"
    },
    {
      city: "CLIMAX",
      state: "NY"
    },
    {
      city: "CLIMAX SPRINGS",
      state: "MO"
    },
    {
      city: "CLIMBING HILL",
      state: "IA"
    },
    {
      city: "CLINCHCO",
      state: "VA"
    },
    {
      city: "CLINCHFIELD",
      state: "GA"
    },
    {
      city: "CLINES CORNERS",
      state: "NM"
    },
    {
      city: "CLINT",
      state: "TX"
    },
    {
      city: "CLINTON",
      state: "AL"
    },
    {
      city: "CLINTON",
      state: "AR"
    },
    {
      city: "CLINTON",
      state: "CT"
    },
    {
      city: "CLINTON",
      state: "IA"
    },
    {
      city: "CLINTON",
      state: "IL"
    },
    {
      city: "CLINTON",
      state: "IN"
    },
    {
      city: "CLINTON",
      state: "KY"
    },
    {
      city: "CLINTON",
      state: "LA"
    },
    {
      city: "CLINTON",
      state: "MA"
    },
    {
      city: "CLINTON",
      state: "MD"
    },
    {
      city: "CLINTON",
      state: "ME"
    },
    {
      city: "CLINTON",
      state: "MI"
    },
    {
      city: "CLINTON",
      state: "MN"
    },
    {
      city: "CLINTON",
      state: "MO"
    },
    {
      city: "CLINTON",
      state: "MS"
    },
    {
      city: "CLINTON",
      state: "MT"
    },
    {
      city: "CLINTON",
      state: "NC"
    },
    {
      city: "CLINTON",
      state: "NJ"
    },
    {
      city: "CLINTON",
      state: "NY"
    },
    {
      city: "CLINTON",
      state: "OH"
    },
    {
      city: "CLINTON",
      state: "OK"
    },
    {
      city: "CLINTON",
      state: "PA"
    },
    {
      city: "CLINTON",
      state: "SC"
    },
    {
      city: "CLINTON",
      state: "TN"
    },
    {
      city: "CLINTON",
      state: "WA"
    },
    {
      city: "CLINTON",
      state: "WI"
    },
    {
      city: "CLINTON CORNERS",
      state: "NY"
    },
    {
      city: "CLINTON TOWNSHIP",
      state: "MI"
    },
    {
      city: "CLINTONDALE",
      state: "NY"
    },
    {
      city: "CLINTONVILLE",
      state: "PA"
    },
    {
      city: "CLINTONVILLE",
      state: "WI"
    },
    {
      city: "CLINTWOOD",
      state: "VA"
    },
    {
      city: "CLIO",
      state: "AL"
    },
    {
      city: "CLIO",
      state: "CA"
    },
    {
      city: "CLIO",
      state: "IA"
    },
    {
      city: "CLIO",
      state: "MI"
    },
    {
      city: "CLIO",
      state: "SC"
    },
    {
      city: "CLIPPER MILLS",
      state: "CA"
    },
    {
      city: "CLITHERALL",
      state: "MN"
    },
    {
      city: "CLIVE",
      state: "IA"
    },
    {
      city: "CLONTARF",
      state: "MN"
    },
    {
      city: "CLOQUET",
      state: "MN"
    },
    {
      city: "CLOSPLINT",
      state: "KY"
    },
    {
      city: "CLOSTER",
      state: "NJ"
    },
    {
      city: "CLOTHIER",
      state: "WV"
    },
    {
      city: "CLOUDCROFT",
      state: "NM"
    },
    {
      city: "CLOUTIERVILLE",
      state: "LA"
    },
    {
      city: "CLOVER",
      state: "SC"
    },
    {
      city: "CLOVER",
      state: "VA"
    },
    {
      city: "CLOVERDALE",
      state: "AL"
    },
    {
      city: "CLOVERDALE",
      state: "CA"
    },
    {
      city: "CLOVERDALE",
      state: "IN"
    },
    {
      city: "CLOVERDALE",
      state: "MI"
    },
    {
      city: "CLOVERDALE",
      state: "OH"
    },
    {
      city: "CLOVERDALE",
      state: "OR"
    },
    {
      city: "CLOVERDALE",
      state: "VA"
    },
    {
      city: "CLOVERPORT",
      state: "KY"
    },
    {
      city: "CLOVIS",
      state: "CA"
    },
    {
      city: "CLOVIS",
      state: "NM"
    },
    {
      city: "CLUBB",
      state: "MO"
    },
    {
      city: "CLUSTER SPRINGS",
      state: "VA"
    },
    {
      city: "CLUTE",
      state: "TX"
    },
    {
      city: "CLUTIER",
      state: "IA"
    },
    {
      city: "CLYDE",
      state: "KS"
    },
    {
      city: "CLYDE",
      state: "MO"
    },
    {
      city: "CLYDE",
      state: "NC"
    },
    {
      city: "CLYDE",
      state: "NY"
    },
    {
      city: "CLYDE",
      state: "OH"
    },
    {
      city: "CLYDE",
      state: "TX"
    },
    {
      city: "CLYDE PARK",
      state: "MT"
    },
    {
      city: "CLYMAN",
      state: "WI"
    },
    {
      city: "CLYMER",
      state: "NY"
    },
    {
      city: "CLYMER",
      state: "PA"
    },
    {
      city: "CLYO",
      state: "GA"
    },
    {
      city: "COACHELLA",
      state: "CA"
    },
    {
      city: "COAHOMA",
      state: "MS"
    },
    {
      city: "COAHOMA",
      state: "TX"
    },
    {
      city: "COAL CENTER",
      state: "PA"
    },
    {
      city: "COAL CITY",
      state: "IL"
    },
    {
      city: "COAL CITY",
      state: "IN"
    },
    {
      city: "COAL CITY",
      state: "WV"
    },
    {
      city: "COAL HILL",
      state: "AR"
    },
    {
      city: "COAL MOUNTAIN",
      state: "WV"
    },
    {
      city: "COAL RUN",
      state: "OH"
    },
    {
      city: "COAL TOWNSHIP",
      state: "PA"
    },
    {
      city: "COAL VALLEY",
      state: "IL"
    },
    {
      city: "COALDALE",
      state: "CO"
    },
    {
      city: "COALDALE",
      state: "PA"
    },
    {
      city: "COALFIELD",
      state: "TN"
    },
    {
      city: "COALGATE",
      state: "OK"
    },
    {
      city: "COALING",
      state: "AL"
    },
    {
      city: "COALINGA",
      state: "CA"
    },
    {
      city: "COALMONT",
      state: "CO"
    },
    {
      city: "COALMONT",
      state: "TN"
    },
    {
      city: "COALPORT",
      state: "PA"
    },
    {
      city: "COALTON",
      state: "OH"
    },
    {
      city: "COALTON",
      state: "WV"
    },
    {
      city: "COALVILLE",
      state: "UT"
    },
    {
      city: "COARSEGOLD",
      state: "CA"
    },
    {
      city: "COATESVILLE",
      state: "IN"
    },
    {
      city: "COATESVILLE",
      state: "PA"
    },
    {
      city: "COATS",
      state: "KS"
    },
    {
      city: "COATS",
      state: "NC"
    },
    {
      city: "COATSBURG",
      state: "IL"
    },
    {
      city: "COATSVILLE",
      state: "MO"
    },
    {
      city: "COBALT",
      state: "CT"
    },
    {
      city: "COBB",
      state: "CA"
    },
    {
      city: "COBB",
      state: "GA"
    },
    {
      city: "COBB",
      state: "WI"
    },
    {
      city: "COBB ISLAND",
      state: "MD"
    },
    {
      city: "COBBS CREEK",
      state: "VA"
    },
    {
      city: "COBBTOWN",
      state: "GA"
    },
    {
      city: "COBDEN",
      state: "IL"
    },
    {
      city: "COBLESKILL",
      state: "NY"
    },
    {
      city: "COBURN",
      state: "PA"
    },
    {
      city: "COCHECTON",
      state: "NY"
    },
    {
      city: "COCHECTON CENTER",
      state: "NY"
    },
    {
      city: "COCHISE",
      state: "AZ"
    },
    {
      city: "COCHITI LAKE",
      state: "NM"
    },
    {
      city: "COCHITI PUEBLO",
      state: "NM"
    },
    {
      city: "COCHRAN",
      state: "GA"
    },
    {
      city: "COCHRANE",
      state: "WI"
    },
    {
      city: "COCHRANTON",
      state: "PA"
    },
    {
      city: "COCHRANVILLE",
      state: "PA"
    },
    {
      city: "COCKEYSVILLE",
      state: "MD"
    },
    {
      city: "COCOA",
      state: "FL"
    },
    {
      city: "COCOA BEACH",
      state: "FL"
    },
    {
      city: "COCOLALLA",
      state: "ID"
    },
    {
      city: "COCOLAMUS",
      state: "PA"
    },
    {
      city: "COCONUT CREEK",
      state: "FL"
    },
    {
      city: "CODEN",
      state: "AL"
    },
    {
      city: "CODORUS",
      state: "PA"
    },
    {
      city: "CODY",
      state: "NE"
    },
    {
      city: "CODY",
      state: "WY"
    },
    {
      city: "COEBURN",
      state: "VA"
    },
    {
      city: "COELLO",
      state: "IL"
    },
    {
      city: "COEUR D ALENE",
      state: "ID"
    },
    {
      city: "COEYMANS",
      state: "NY"
    },
    {
      city: "COEYMANS HOLLOW",
      state: "NY"
    },
    {
      city: "COFFEE CREEK",
      state: "MT"
    },
    {
      city: "COFFEE SPRINGS",
      state: "AL"
    },
    {
      city: "COFFEEN",
      state: "IL"
    },
    {
      city: "COFFEEVILLE",
      state: "AL"
    },
    {
      city: "COFFEEVILLE",
      state: "MS"
    },
    {
      city: "COFFEYVILLE",
      state: "KS"
    },
    {
      city: "COFFMAN COVE",
      state: "AK"
    },
    {
      city: "COFIELD",
      state: "NC"
    },
    {
      city: "COGAN STATION",
      state: "PA"
    },
    {
      city: "COGGON",
      state: "IA"
    },
    {
      city: "COGSWELL",
      state: "ND"
    },
    {
      city: "COHASSET",
      state: "MA"
    },
    {
      city: "COHASSET",
      state: "MN"
    },
    {
      city: "COHOCTAH",
      state: "MI"
    },
    {
      city: "COHOCTON",
      state: "NY"
    },
    {
      city: "COHOES",
      state: "NY"
    },
    {
      city: "COHUTTA",
      state: "GA"
    },
    {
      city: "COILA",
      state: "MS"
    },
    {
      city: "COIN",
      state: "IA"
    },
    {
      city: "COINJOCK",
      state: "NC"
    },
    {
      city: "COKATO",
      state: "MN"
    },
    {
      city: "COKEBURG",
      state: "PA"
    },
    {
      city: "COKER",
      state: "AL"
    },
    {
      city: "COKER CREEK",
      state: "TN"
    },
    {
      city: "COKEVILLE",
      state: "WY"
    },
    {
      city: "COLBERT",
      state: "GA"
    },
    {
      city: "COLBERT",
      state: "OK"
    },
    {
      city: "COLBERT",
      state: "WA"
    },
    {
      city: "COLBURN",
      state: "ID"
    },
    {
      city: "COLBY",
      state: "KS"
    },
    {
      city: "COLBY",
      state: "WI"
    },
    {
      city: "COLCHESTER",
      state: "CT"
    },
    {
      city: "COLCHESTER",
      state: "IL"
    },
    {
      city: "COLCHESTER",
      state: "VT"
    },
    {
      city: "COLCORD",
      state: "OK"
    },
    {
      city: "COLCORD",
      state: "WV"
    },
    {
      city: "COLD BAY",
      state: "AK"
    },
    {
      city: "COLD BROOK",
      state: "NY"
    },
    {
      city: "COLD SPRING",
      state: "MN"
    },
    {
      city: "COLD SPRING",
      state: "NY"
    },
    {
      city: "COLD SPRING HARBOR",
      state: "NY"
    },
    {
      city: "COLDEN",
      state: "NY"
    },
    {
      city: "COLDIRON",
      state: "KY"
    },
    {
      city: "COLDSPRING",
      state: "TX"
    },
    {
      city: "COLDWATER",
      state: "KS"
    },
    {
      city: "COLDWATER",
      state: "MI"
    },
    {
      city: "COLDWATER",
      state: "MS"
    },
    {
      city: "COLDWATER",
      state: "OH"
    },
    {
      city: "COLE CAMP",
      state: "MO"
    },
    {
      city: "COLEBROOK",
      state: "CT"
    },
    {
      city: "COLEBROOK",
      state: "NH"
    },
    {
      city: "COLEHARBOR",
      state: "ND"
    },
    {
      city: "COLEMAN",
      state: "FL"
    },
    {
      city: "COLEMAN",
      state: "GA"
    },
    {
      city: "COLEMAN",
      state: "MI"
    },
    {
      city: "COLEMAN",
      state: "OK"
    },
    {
      city: "COLEMAN",
      state: "TX"
    },
    {
      city: "COLEMAN",
      state: "WI"
    },
    {
      city: "COLEMAN FALLS",
      state: "VA"
    },
    {
      city: "COLERAIN",
      state: "NC"
    },
    {
      city: "COLERAIN",
      state: "OH"
    },
    {
      city: "COLERAINE",
      state: "MN"
    },
    {
      city: "COLERIDGE",
      state: "NE"
    },
    {
      city: "COLES POINT",
      state: "VA"
    },
    {
      city: "COLESBURG",
      state: "IA"
    },
    {
      city: "COLEVILLE",
      state: "CA"
    },
    {
      city: "COLFAX",
      state: "CA"
    },
    {
      city: "COLFAX",
      state: "IA"
    },
    {
      city: "COLFAX",
      state: "IL"
    },
    {
      city: "COLFAX",
      state: "IN"
    },
    {
      city: "COLFAX",
      state: "LA"
    },
    {
      city: "COLFAX",
      state: "NC"
    },
    {
      city: "COLFAX",
      state: "ND"
    },
    {
      city: "COLFAX",
      state: "WA"
    },
    {
      city: "COLFAX",
      state: "WI"
    },
    {
      city: "COLGATE",
      state: "WI"
    },
    {
      city: "COLLBRAN",
      state: "CO"
    },
    {
      city: "COLLEGE CORNER",
      state: "OH"
    },
    {
      city: "COLLEGE GROVE",
      state: "TN"
    },
    {
      city: "COLLEGE PARK",
      state: "MD"
    },
    {
      city: "COLLEGE PLACE",
      state: "WA"
    },
    {
      city: "COLLEGE POINT",
      state: "NY"
    },
    {
      city: "COLLEGE SPRINGS",
      state: "IA"
    },
    {
      city: "COLLEGE STATION",
      state: "AR"
    },
    {
      city: "COLLEGE STATION",
      state: "TX"
    },
    {
      city: "COLLEGEDALE",
      state: "TN"
    },
    {
      city: "COLLEGEVILLE",
      state: "MN"
    },
    {
      city: "COLLEGEVILLE",
      state: "PA"
    },
    {
      city: "COLLETTSVILLE",
      state: "NC"
    },
    {
      city: "COLLEYVILLE",
      state: "TX"
    },
    {
      city: "COLLIERS",
      state: "WV"
    },
    {
      city: "COLLIERSVILLE",
      state: "NY"
    },
    {
      city: "COLLIERVILLE",
      state: "TN"
    },
    {
      city: "COLLINGSWOOD",
      state: "NJ"
    },
    {
      city: "COLLINS",
      state: "GA"
    },
    {
      city: "COLLINS",
      state: "IA"
    },
    {
      city: "COLLINS",
      state: "MO"
    },
    {
      city: "COLLINS",
      state: "MS"
    },
    {
      city: "COLLINS",
      state: "NY"
    },
    {
      city: "COLLINS",
      state: "OH"
    },
    {
      city: "COLLINS",
      state: "WI"
    },
    {
      city: "COLLINS CENTER",
      state: "NY"
    },
    {
      city: "COLLINSTON",
      state: "LA"
    },
    {
      city: "COLLINSTON",
      state: "UT"
    },
    {
      city: "COLLINSVILLE",
      state: "AL"
    },
    {
      city: "COLLINSVILLE",
      state: "CT"
    },
    {
      city: "COLLINSVILLE",
      state: "IL"
    },
    {
      city: "COLLINSVILLE",
      state: "MS"
    },
    {
      city: "COLLINSVILLE",
      state: "OH"
    },
    {
      city: "COLLINSVILLE",
      state: "OK"
    },
    {
      city: "COLLINSVILLE",
      state: "TX"
    },
    {
      city: "COLLINSVILLE",
      state: "VA"
    },
    {
      city: "COLLINWOOD",
      state: "TN"
    },
    {
      city: "COLLISON",
      state: "IL"
    },
    {
      city: "COLLYER",
      state: "KS"
    },
    {
      city: "COLMAN",
      state: "SD"
    },
    {
      city: "COLMAR",
      state: "PA"
    },
    {
      city: "COLMESNEIL",
      state: "TX"
    },
    {
      city: "COLO",
      state: "IA"
    },
    {
      city: "COLOGNE",
      state: "MN"
    },
    {
      city: "COLOGNE",
      state: "NJ"
    },
    {
      city: "COLOMA",
      state: "CA"
    },
    {
      city: "COLOMA",
      state: "MI"
    },
    {
      city: "COLOMA",
      state: "WI"
    },
    {
      city: "COLOME",
      state: "SD"
    },
    {
      city: "COLON",
      state: "MI"
    },
    {
      city: "COLON",
      state: "NE"
    },
    {
      city: "COLONA",
      state: "IL"
    },
    {
      city: "COLONIA",
      state: "NJ"
    },
    {
      city: "COLONIAL BEACH",
      state: "VA"
    },
    {
      city: "COLONIAL HEIGHTS",
      state: "VA"
    },
    {
      city: "COLONY",
      state: "KS"
    },
    {
      city: "COLONY",
      state: "OK"
    },
    {
      city: "COLORA",
      state: "MD"
    },
    {
      city: "COLORADO CITY",
      state: "AZ"
    },
    {
      city: "COLORADO CITY",
      state: "CO"
    },
    {
      city: "COLORADO CITY",
      state: "TX"
    },
    {
      city: "COLORADO SPRINGS",
      state: "CO"
    },
    {
      city: "COLP",
      state: "IL"
    },
    {
      city: "COLQUITT",
      state: "GA"
    },
    {
      city: "COLRAIN",
      state: "MA"
    },
    {
      city: "COLSTRIP",
      state: "MT"
    },
    {
      city: "COLT",
      state: "AR"
    },
    {
      city: "COLTON",
      state: "CA"
    },
    {
      city: "COLTON",
      state: "NY"
    },
    {
      city: "COLTON",
      state: "OR"
    },
    {
      city: "COLTON",
      state: "SD"
    },
    {
      city: "COLTON",
      state: "WA"
    },
    {
      city: "COLTONS POINT",
      state: "MD"
    },
    {
      city: "COLTS NECK",
      state: "NJ"
    },
    {
      city: "COLUMBIA",
      state: "AL"
    },
    {
      city: "COLUMBIA",
      state: "CA"
    },
    {
      city: "COLUMBIA",
      state: "CT"
    },
    {
      city: "COLUMBIA",
      state: "IA"
    },
    {
      city: "COLUMBIA",
      state: "IL"
    },
    {
      city: "COLUMBIA",
      state: "KY"
    },
    {
      city: "COLUMBIA",
      state: "LA"
    },
    {
      city: "COLUMBIA",
      state: "MD"
    },
    {
      city: "COLUMBIA",
      state: "MO"
    },
    {
      city: "COLUMBIA",
      state: "MS"
    },
    {
      city: "COLUMBIA",
      state: "NC"
    },
    {
      city: "COLUMBIA",
      state: "NJ"
    },
    {
      city: "COLUMBIA",
      state: "PA"
    },
    {
      city: "COLUMBIA",
      state: "SC"
    },
    {
      city: "COLUMBIA",
      state: "SD"
    },
    {
      city: "COLUMBIA",
      state: "TN"
    },
    {
      city: "COLUMBIA",
      state: "VA"
    },
    {
      city: "COLUMBIA CITY",
      state: "IN"
    },
    {
      city: "COLUMBIA CITY",
      state: "OR"
    },
    {
      city: "COLUMBIA CROSS ROADS",
      state: "PA"
    },
    {
      city: "COLUMBIA FALLS",
      state: "ME"
    },
    {
      city: "COLUMBIA FALLS",
      state: "MT"
    },
    {
      city: "COLUMBIA STATION",
      state: "OH"
    },
    {
      city: "COLUMBIANA",
      state: "AL"
    },
    {
      city: "COLUMBIANA",
      state: "OH"
    },
    {
      city: "COLUMBIAVILLE",
      state: "MI"
    },
    {
      city: "COLUMBIAVILLE",
      state: "NY"
    },
    {
      city: "COLUMBUS",
      state: "GA"
    },
    {
      city: "COLUMBUS",
      state: "IN"
    },
    {
      city: "COLUMBUS",
      state: "KS"
    },
    {
      city: "COLUMBUS",
      state: "KY"
    },
    {
      city: "COLUMBUS",
      state: "MI"
    },
    {
      city: "COLUMBUS",
      state: "MS"
    },
    {
      city: "COLUMBUS",
      state: "MT"
    },
    {
      city: "COLUMBUS",
      state: "NC"
    },
    {
      city: "COLUMBUS",
      state: "ND"
    },
    {
      city: "COLUMBUS",
      state: "NE"
    },
    {
      city: "COLUMBUS",
      state: "NJ"
    },
    {
      city: "COLUMBUS",
      state: "NM"
    },
    {
      city: "COLUMBUS",
      state: "OH"
    },
    {
      city: "COLUMBUS",
      state: "PA"
    },
    {
      city: "COLUMBUS",
      state: "TX"
    },
    {
      city: "COLUMBUS",
      state: "WI"
    },
    {
      city: "COLUMBUS CITY",
      state: "IA"
    },
    {
      city: "COLUMBUS GROVE",
      state: "OH"
    },
    {
      city: "COLUMBUS JUNCTION",
      state: "IA"
    },
    {
      city: "COLUSA",
      state: "CA"
    },
    {
      city: "COLUSA",
      state: "IL"
    },
    {
      city: "COLVER",
      state: "PA"
    },
    {
      city: "COLVILLE",
      state: "WA"
    },
    {
      city: "COLWELL",
      state: "IA"
    },
    {
      city: "COLWICH",
      state: "KS"
    },
    {
      city: "COMANCHE",
      state: "OK"
    },
    {
      city: "COMANCHE",
      state: "TX"
    },
    {
      city: "COMBES",
      state: "TX"
    },
    {
      city: "COMBINED LOCKS",
      state: "WI"
    },
    {
      city: "COMBS",
      state: "AR"
    },
    {
      city: "COMBS",
      state: "KY"
    },
    {
      city: "COMER",
      state: "GA"
    },
    {
      city: "COMFORT",
      state: "TX"
    },
    {
      city: "COMFORT",
      state: "WV"
    },
    {
      city: "COMFREY",
      state: "MN"
    },
    {
      city: "COMINS",
      state: "MI"
    },
    {
      city: "COMMACK",
      state: "NY"
    },
    {
      city: "COMMERCE",
      state: "GA"
    },
    {
      city: "COMMERCE",
      state: "MO"
    },
    {
      city: "COMMERCE",
      state: "OK"
    },
    {
      city: "COMMERCE",
      state: "TX"
    },
    {
      city: "COMMERCE CITY",
      state: "CO"
    },
    {
      city: "COMMERCE TOWNSHIP",
      state: "MI"
    },
    {
      city: "COMMERCIAL POINT",
      state: "OH"
    },
    {
      city: "COMMISKEY",
      state: "IN"
    },
    {
      city: "COMMODORE",
      state: "PA"
    },
    {
      city: "COMO",
      state: "CO"
    },
    {
      city: "COMO",
      state: "MS"
    },
    {
      city: "COMO",
      state: "NC"
    },
    {
      city: "COMO",
      state: "TN"
    },
    {
      city: "COMO",
      state: "TX"
    },
    {
      city: "COMPTCHE",
      state: "CA"
    },
    {
      city: "COMPTON",
      state: "AR"
    },
    {
      city: "COMPTON",
      state: "CA"
    },
    {
      city: "COMPTON",
      state: "IL"
    },
    {
      city: "COMPTON",
      state: "MD"
    },
    {
      city: "COMSTOCK",
      state: "MI"
    },
    {
      city: "COMSTOCK",
      state: "MN"
    },
    {
      city: "COMSTOCK",
      state: "NE"
    },
    {
      city: "COMSTOCK",
      state: "NY"
    },
    {
      city: "COMSTOCK",
      state: "WI"
    },
    {
      city: "COMSTOCK PARK",
      state: "MI"
    },
    {
      city: "CONASAUGA",
      state: "TN"
    },
    {
      city: "CONCAN",
      state: "TX"
    },
    {
      city: "CONCEPTION",
      state: "MO"
    },
    {
      city: "CONCEPTION JUNCTION",
      state: "MO"
    },
    {
      city: "CONCHAS DAM",
      state: "NM"
    },
    {
      city: "CONCHO",
      state: "AZ"
    },
    {
      city: "CONCHO",
      state: "OK"
    },
    {
      city: "CONCONULLY",
      state: "WA"
    },
    {
      city: "CONCORD",
      state: "AR"
    },
    {
      city: "CONCORD",
      state: "CA"
    },
    {
      city: "CONCORD",
      state: "GA"
    },
    {
      city: "CONCORD",
      state: "IL"
    },
    {
      city: "CONCORD",
      state: "MA"
    },
    {
      city: "CONCORD",
      state: "MI"
    },
    {
      city: "CONCORD",
      state: "NC"
    },
    {
      city: "CONCORD",
      state: "NE"
    },
    {
      city: "CONCORD",
      state: "NH"
    },
    {
      city: "CONCORD",
      state: "PA"
    },
    {
      city: "CONCORD",
      state: "TX"
    },
    {
      city: "CONCORD",
      state: "VA"
    },
    {
      city: "CONCORD",
      state: "VT"
    },
    {
      city: "CONCORDIA",
      state: "KS"
    },
    {
      city: "CONCORDIA",
      state: "MO"
    },
    {
      city: "CONCORDVILLE",
      state: "PA"
    },
    {
      city: "CONCRETE",
      state: "WA"
    },
    {
      city: "CONDE",
      state: "SD"
    },
    {
      city: "CONDON",
      state: "MT"
    },
    {
      city: "CONDON",
      state: "OR"
    },
    {
      city: "CONEHATTA",
      state: "MS"
    },
    {
      city: "CONEJOS",
      state: "CO"
    },
    {
      city: "CONESTEE",
      state: "SC"
    },
    {
      city: "CONESTOGA",
      state: "PA"
    },
    {
      city: "CONESUS",
      state: "NY"
    },
    {
      city: "CONESVILLE",
      state: "IA"
    },
    {
      city: "CONESVILLE",
      state: "OH"
    },
    {
      city: "CONETOE",
      state: "NC"
    },
    {
      city: "CONEWANGO VALLEY",
      state: "NY"
    },
    {
      city: "CONFLUENCE",
      state: "PA"
    },
    {
      city: "CONGER",
      state: "MN"
    },
    {
      city: "CONGERS",
      state: "NY"
    },
    {
      city: "CONGERVILLE",
      state: "IL"
    },
    {
      city: "CONGRESS",
      state: "AZ"
    },
    {
      city: "CONIFER",
      state: "CO"
    },
    {
      city: "CONKLIN",
      state: "MI"
    },
    {
      city: "CONKLIN",
      state: "NY"
    },
    {
      city: "CONLEY",
      state: "GA"
    },
    {
      city: "CONNEAUT",
      state: "OH"
    },
    {
      city: "CONNEAUT LAKE",
      state: "PA"
    },
    {
      city: "CONNEAUTVILLE",
      state: "PA"
    },
    {
      city: "CONNELL",
      state: "WA"
    },
    {
      city: "CONNELLSVILLE",
      state: "PA"
    },
    {
      city: "CONNELLY",
      state: "NY"
    },
    {
      city: "CONNELLY SPRINGS",
      state: "NC"
    },
    {
      city: "CONNER",
      state: "MT"
    },
    {
      city: "CONNERSVILLE",
      state: "IN"
    },
    {
      city: "CONNOQUENESSING",
      state: "PA"
    },
    {
      city: "CONOVER",
      state: "NC"
    },
    {
      city: "CONOVER",
      state: "OH"
    },
    {
      city: "CONOVER",
      state: "WI"
    },
    {
      city: "CONOWINGO",
      state: "MD"
    },
    {
      city: "CONRAD",
      state: "IA"
    },
    {
      city: "CONRAD",
      state: "MT"
    },
    {
      city: "CONRATH",
      state: "WI"
    },
    {
      city: "CONROE",
      state: "TX"
    },
    {
      city: "CONROY",
      state: "IA"
    },
    {
      city: "CONSHOHOCKEN",
      state: "PA"
    },
    {
      city: "CONSTABLE",
      state: "NY"
    },
    {
      city: "CONSTABLEVILLE",
      state: "NY"
    },
    {
      city: "CONSTANTIA",
      state: "NY"
    },
    {
      city: "CONSTANTINE",
      state: "MI"
    },
    {
      city: "CONTINENTAL",
      state: "OH"
    },
    {
      city: "CONTINENTAL DIVIDE",
      state: "NM"
    },
    {
      city: "CONTOOCOOK",
      state: "NH"
    },
    {
      city: "CONVENT",
      state: "LA"
    },
    {
      city: "CONVENT STATION",
      state: "NJ"
    },
    {
      city: "CONVERSE",
      state: "IN"
    },
    {
      city: "CONVERSE",
      state: "LA"
    },
    {
      city: "CONVERSE",
      state: "SC"
    },
    {
      city: "CONVERSE",
      state: "TX"
    },
    {
      city: "CONVOY",
      state: "OH"
    },
    {
      city: "CONWAY",
      state: "AR"
    },
    {
      city: "CONWAY",
      state: "MA"
    },
    {
      city: "CONWAY",
      state: "MI"
    },
    {
      city: "CONWAY",
      state: "MO"
    },
    {
      city: "CONWAY",
      state: "NC"
    },
    {
      city: "CONWAY",
      state: "NH"
    },
    {
      city: "CONWAY",
      state: "PA"
    },
    {
      city: "CONWAY",
      state: "SC"
    },
    {
      city: "CONWAY",
      state: "WA"
    },
    {
      city: "CONWAY SPRINGS",
      state: "KS"
    },
    {
      city: "CONYERS",
      state: "GA"
    },
    {
      city: "CONYNGHAM",
      state: "PA"
    },
    {
      city: "COOK",
      state: "MN"
    },
    {
      city: "COOK",
      state: "NE"
    },
    {
      city: "COOK SPRINGS",
      state: "AL"
    },
    {
      city: "COOK STA",
      state: "MO"
    },
    {
      city: "COOKE CITY",
      state: "MT"
    },
    {
      city: "COOKEVILLE",
      state: "TN"
    },
    {
      city: "COOKS",
      state: "MI"
    },
    {
      city: "COOKSBURG",
      state: "PA"
    },
    {
      city: "COOKSON",
      state: "OK"
    },
    {
      city: "COOKSTOWN",
      state: "NJ"
    },
    {
      city: "COOKSVILLE",
      state: "IL"
    },
    {
      city: "COOKSVILLE",
      state: "MD"
    },
    {
      city: "COOKVILLE",
      state: "TX"
    },
    {
      city: "COOL",
      state: "CA"
    },
    {
      city: "COOL RIDGE",
      state: "WV"
    },
    {
      city: "COOLEEMEE",
      state: "NC"
    },
    {
      city: "COOLIDGE",
      state: "AZ"
    },
    {
      city: "COOLIDGE",
      state: "GA"
    },
    {
      city: "COOLIDGE",
      state: "KS"
    },
    {
      city: "COOLIDGE",
      state: "TX"
    },
    {
      city: "COOLIN",
      state: "ID"
    },
    {
      city: "COOLSPRING",
      state: "PA"
    },
    {
      city: "COOLVILLE",
      state: "OH"
    },
    {
      city: "COON RAPIDS",
      state: "IA"
    },
    {
      city: "COON VALLEY",
      state: "WI"
    },
    {
      city: "COOPER",
      state: "IA"
    },
    {
      city: "COOPER",
      state: "TX"
    },
    {
      city: "COOPER LANDING",
      state: "AK"
    },
    {
      city: "COOPERS MILLS",
      state: "ME"
    },
    {
      city: "COOPERS PLAINS",
      state: "NY"
    },
    {
      city: "COOPERSBURG",
      state: "PA"
    },
    {
      city: "COOPERSTOWN",
      state: "ND"
    },
    {
      city: "COOPERSTOWN",
      state: "NY"
    },
    {
      city: "COOPERSTOWN",
      state: "PA"
    },
    {
      city: "COOPERSVILLE",
      state: "MI"
    },
    {
      city: "COOS BAY",
      state: "OR"
    },
    {
      city: "COOSA",
      state: "GA"
    },
    {
      city: "COOSADA",
      state: "AL"
    },
    {
      city: "COOSAWHATCHIE",
      state: "SC"
    },
    {
      city: "COOTER",
      state: "MO"
    },
    {
      city: "COPAKE",
      state: "NY"
    },
    {
      city: "COPAKE FALLS",
      state: "NY"
    },
    {
      city: "COPALIS BEACH",
      state: "WA"
    },
    {
      city: "COPALIS CROSSING",
      state: "WA"
    },
    {
      city: "COPAN",
      state: "OK"
    },
    {
      city: "COPE",
      state: "CO"
    },
    {
      city: "COPE",
      state: "SC"
    },
    {
      city: "COPELAND",
      state: "FL"
    },
    {
      city: "COPELAND",
      state: "KS"
    },
    {
      city: "COPEMISH",
      state: "MI"
    },
    {
      city: "COPEN",
      state: "WV"
    },
    {
      city: "COPENHAGEN",
      state: "NY"
    },
    {
      city: "COPEVILLE",
      state: "TX"
    },
    {
      city: "COPIAGUE",
      state: "NY"
    },
    {
      city: "COPLAY",
      state: "PA"
    },
    {
      city: "COPPELL",
      state: "TX"
    },
    {
      city: "COPPER CENTER",
      state: "AK"
    },
    {
      city: "COPPER CITY",
      state: "MI"
    },
    {
      city: "COPPER HARBOR",
      state: "MI"
    },
    {
      city: "COPPER HILL",
      state: "VA"
    },
    {
      city: "COPPERAS COVE",
      state: "TX"
    },
    {
      city: "COPPERHILL",
      state: "TN"
    },
    {
      city: "COPPEROPOLIS",
      state: "CA"
    },
    {
      city: "COQUILLE",
      state: "OR"
    },
    {
      city: "CORA",
      state: "WV"
    },
    {
      city: "CORA",
      state: "WY"
    },
    {
      city: "CORAL",
      state: "MI"
    },
    {
      city: "CORAL",
      state: "PA"
    },
    {
      city: "CORAL GABLES",
      state: "FL"
    },
    {
      city: "CORAL SPRINGS",
      state: "FL"
    },
    {
      city: "CORALVILLE",
      state: "IA"
    },
    {
      city: "CORAM",
      state: "MT"
    },
    {
      city: "CORAM",
      state: "NY"
    },
    {
      city: "CORAOPOLIS",
      state: "PA"
    },
    {
      city: "CORAPEAKE",
      state: "NC"
    },
    {
      city: "CORBETT",
      state: "OR"
    },
    {
      city: "CORBIN",
      state: "KY"
    },
    {
      city: "CORBIN",
      state: "VA"
    },
    {
      city: "CORCORAN",
      state: "CA"
    },
    {
      city: "CORD",
      state: "AR"
    },
    {
      city: "CORDELE",
      state: "GA"
    },
    {
      city: "CORDELL",
      state: "OK"
    },
    {
      city: "CORDER",
      state: "MO"
    },
    {
      city: "CORDESVILLE",
      state: "SC"
    },
    {
      city: "CORDOVA",
      state: "AK"
    },
    {
      city: "CORDOVA",
      state: "AL"
    },
    {
      city: "CORDOVA",
      state: "IL"
    },
    {
      city: "CORDOVA",
      state: "MD"
    },
    {
      city: "CORDOVA",
      state: "NC"
    },
    {
      city: "CORDOVA",
      state: "NE"
    },
    {
      city: "CORDOVA",
      state: "NM"
    },
    {
      city: "CORDOVA",
      state: "SC"
    },
    {
      city: "CORDOVA",
      state: "TN"
    },
    {
      city: "COREA",
      state: "ME"
    },
    {
      city: "CORFU",
      state: "NY"
    },
    {
      city: "CORINNA",
      state: "ME"
    },
    {
      city: "CORINNE",
      state: "UT"
    },
    {
      city: "CORINNE",
      state: "WV"
    },
    {
      city: "CORINTH",
      state: "KY"
    },
    {
      city: "CORINTH",
      state: "ME"
    },
    {
      city: "CORINTH",
      state: "MS"
    },
    {
      city: "CORINTH",
      state: "NY"
    },
    {
      city: "CORINTH",
      state: "VT"
    },
    {
      city: "CORN",
      state: "OK"
    },
    {
      city: "CORNELIA",
      state: "GA"
    },
    {
      city: "CORNELIUS",
      state: "NC"
    },
    {
      city: "CORNELIUS",
      state: "OR"
    },
    {
      city: "CORNELL",
      state: "IL"
    },
    {
      city: "CORNELL",
      state: "MI"
    },
    {
      city: "CORNELL",
      state: "WI"
    },
    {
      city: "CORNERSVILLE",
      state: "TN"
    },
    {
      city: "CORNETTSVILLE",
      state: "KY"
    },
    {
      city: "CORNING",
      state: "AR"
    },
    {
      city: "CORNING",
      state: "CA"
    },
    {
      city: "CORNING",
      state: "IA"
    },
    {
      city: "CORNING",
      state: "KS"
    },
    {
      city: "CORNING",
      state: "NY"
    },
    {
      city: "CORNING",
      state: "OH"
    },
    {
      city: "CORNISH",
      state: "ME"
    },
    {
      city: "CORNISH",
      state: "NH"
    },
    {
      city: "CORNISH",
      state: "UT"
    },
    {
      city: "CORNISH FLAT",
      state: "NH"
    },
    {
      city: "CORNLAND",
      state: "IL"
    },
    {
      city: "CORNUCOPIA",
      state: "WI"
    },
    {
      city: "CORNVILLE",
      state: "AZ"
    },
    {
      city: "CORNWALL",
      state: "CT"
    },
    {
      city: "CORNWALL",
      state: "NY"
    },
    {
      city: "CORNWALL",
      state: "PA"
    },
    {
      city: "CORNWALL BRIDGE",
      state: "CT"
    },
    {
      city: "CORNWALL ON HUDSON",
      state: "NY"
    },
    {
      city: "CORNWALLVILLE",
      state: "NY"
    },
    {
      city: "COROLLA",
      state: "NC"
    },
    {
      city: "CORONA",
      state: "CA"
    },
    {
      city: "CORONA",
      state: "NM"
    },
    {
      city: "CORONA",
      state: "NY"
    },
    {
      city: "CORONA",
      state: "SD"
    },
    {
      city: "CORONA DEL MAR",
      state: "CA"
    },
    {
      city: "CORONADO",
      state: "CA"
    },
    {
      city: "CORPUS CHRISTI",
      state: "TX"
    },
    {
      city: "CORRAL",
      state: "ID"
    },
    {
      city: "CORRALES",
      state: "NM"
    },
    {
      city: "CORRECTIONVILLE",
      state: "IA"
    },
    {
      city: "CORRELL",
      state: "MN"
    },
    {
      city: "CORRIGAN",
      state: "TX"
    },
    {
      city: "CORRIGANVILLE",
      state: "MD"
    },
    {
      city: "CORRY",
      state: "PA"
    },
    {
      city: "CORRYTON",
      state: "TN"
    },
    {
      city: "CORSICA",
      state: "PA"
    },
    {
      city: "CORSICA",
      state: "SD"
    },
    {
      city: "CORSICANA",
      state: "TX"
    },
    {
      city: "CORTARO",
      state: "AZ"
    },
    {
      city: "CORTE MADERA",
      state: "CA"
    },
    {
      city: "CORTEZ",
      state: "CO"
    },
    {
      city: "CORTEZ",
      state: "FL"
    },
    {
      city: "CORTLAND",
      state: "IL"
    },
    {
      city: "CORTLAND",
      state: "NE"
    },
    {
      city: "CORTLAND",
      state: "NY"
    },
    {
      city: "CORTLAND",
      state: "OH"
    },
    {
      city: "CORTLANDT MANOR",
      state: "NY"
    },
    {
      city: "CORUNNA",
      state: "IN"
    },
    {
      city: "CORUNNA",
      state: "MI"
    },
    {
      city: "CORVALLIS",
      state: "MT"
    },
    {
      city: "CORVALLIS",
      state: "OR"
    },
    {
      city: "CORWITH",
      state: "IA"
    },
    {
      city: "CORY",
      state: "CO"
    },
    {
      city: "CORY",
      state: "IN"
    },
    {
      city: "CORYDON",
      state: "IA"
    },
    {
      city: "CORYDON",
      state: "IN"
    },
    {
      city: "CORYDON",
      state: "KY"
    },
    {
      city: "COS COB",
      state: "CT"
    },
    {
      city: "COSBY",
      state: "MO"
    },
    {
      city: "COSBY",
      state: "TN"
    },
    {
      city: "COSHOCTON",
      state: "OH"
    },
    {
      city: "COSMOPOLIS",
      state: "WA"
    },
    {
      city: "COSMOS",
      state: "MN"
    },
    {
      city: "COSSAYUNA",
      state: "NY"
    },
    {
      city: "COST",
      state: "TX"
    },
    {
      city: "COSTA MESA",
      state: "CA"
    },
    {
      city: "COSTILLA",
      state: "NM"
    },
    {
      city: "COTATI",
      state: "CA"
    },
    {
      city: "COTOPAXI",
      state: "CO"
    },
    {
      city: "COTTAGE GROVE",
      state: "MN"
    },
    {
      city: "COTTAGE GROVE",
      state: "OR"
    },
    {
      city: "COTTAGE GROVE",
      state: "TN"
    },
    {
      city: "COTTAGE GROVE",
      state: "WI"
    },
    {
      city: "COTTAGE HILLS",
      state: "IL"
    },
    {
      city: "COTTAGEVILLE",
      state: "SC"
    },
    {
      city: "COTTAGEVILLE",
      state: "WV"
    },
    {
      city: "COTTEKILL",
      state: "NY"
    },
    {
      city: "COTTER",
      state: "AR"
    },
    {
      city: "COTTLEVILLE",
      state: "MO"
    },
    {
      city: "COTTON",
      state: "MN"
    },
    {
      city: "COTTON CENTER",
      state: "TX"
    },
    {
      city: "COTTON PLANT",
      state: "AR"
    },
    {
      city: "COTTON VALLEY",
      state: "LA"
    },
    {
      city: "COTTONDALE",
      state: "AL"
    },
    {
      city: "COTTONDALE",
      state: "FL"
    },
    {
      city: "COTTONPORT",
      state: "LA"
    },
    {
      city: "COTTONTON",
      state: "AL"
    },
    {
      city: "COTTONTOWN",
      state: "TN"
    },
    {
      city: "COTTONWOOD",
      state: "AL"
    },
    {
      city: "COTTONWOOD",
      state: "AZ"
    },
    {
      city: "COTTONWOOD",
      state: "CA"
    },
    {
      city: "COTTONWOOD",
      state: "ID"
    },
    {
      city: "COTTONWOOD",
      state: "MN"
    },
    {
      city: "COTTONWOOD FALLS",
      state: "KS"
    },
    {
      city: "COTUIT",
      state: "MA"
    },
    {
      city: "COTULLA",
      state: "TX"
    },
    {
      city: "COUCH",
      state: "MO"
    },
    {
      city: "COUDERAY",
      state: "WI"
    },
    {
      city: "COUDERSPORT",
      state: "PA"
    },
    {
      city: "COUGAR",
      state: "WA"
    },
    {
      city: "COULEE CITY",
      state: "WA"
    },
    {
      city: "COULEE DAM",
      state: "WA"
    },
    {
      city: "COULTER",
      state: "IA"
    },
    {
      city: "COULTERS",
      state: "PA"
    },
    {
      city: "COULTERVILLE",
      state: "CA"
    },
    {
      city: "COULTERVILLE",
      state: "IL"
    },
    {
      city: "COUNCE",
      state: "TN"
    },
    {
      city: "COUNCIL",
      state: "ID"
    },
    {
      city: "COUNCIL",
      state: "NC"
    },
    {
      city: "COUNCIL BLUFFS",
      state: "IA"
    },
    {
      city: "COUNCIL GROVE",
      state: "KS"
    },
    {
      city: "COUNCIL HILL",
      state: "OK"
    },
    {
      city: "COUNTRY CLUB HILLS",
      state: "IL"
    },
    {
      city: "COUNTYLINE",
      state: "OK"
    },
    {
      city: "COUPEVILLE",
      state: "WA"
    },
    {
      city: "COUPLAND",
      state: "TX"
    },
    {
      city: "COUPON",
      state: "PA"
    },
    {
      city: "COURTENAY",
      state: "ND"
    },
    {
      city: "COURTLAND",
      state: "AL"
    },
    {
      city: "COURTLAND",
      state: "CA"
    },
    {
      city: "COURTLAND",
      state: "KS"
    },
    {
      city: "COURTLAND",
      state: "MN"
    },
    {
      city: "COURTLAND",
      state: "MS"
    },
    {
      city: "COURTLAND",
      state: "VA"
    },
    {
      city: "COUSHATTA",
      state: "LA"
    },
    {
      city: "COVE",
      state: "AR"
    },
    {
      city: "COVE",
      state: "OR"
    },
    {
      city: "COVE CITY",
      state: "NC"
    },
    {
      city: "COVELO",
      state: "CA"
    },
    {
      city: "COVENTRY",
      state: "CT"
    },
    {
      city: "COVENTRY",
      state: "RI"
    },
    {
      city: "COVENTRY",
      state: "VT"
    },
    {
      city: "COVERT",
      state: "MI"
    },
    {
      city: "COVESVILLE",
      state: "VA"
    },
    {
      city: "COVINA",
      state: "CA"
    },
    {
      city: "COVINGTON",
      state: "GA"
    },
    {
      city: "COVINGTON",
      state: "IN"
    },
    {
      city: "COVINGTON",
      state: "KY"
    },
    {
      city: "COVINGTON",
      state: "LA"
    },
    {
      city: "COVINGTON",
      state: "MI"
    },
    {
      city: "COVINGTON",
      state: "OH"
    },
    {
      city: "COVINGTON",
      state: "OK"
    },
    {
      city: "COVINGTON",
      state: "PA"
    },
    {
      city: "COVINGTON",
      state: "TN"
    },
    {
      city: "COVINGTON",
      state: "TX"
    },
    {
      city: "COVINGTON",
      state: "VA"
    },
    {
      city: "COWAN",
      state: "TN"
    },
    {
      city: "COWANSVILLE",
      state: "PA"
    },
    {
      city: "COWARD",
      state: "SC"
    },
    {
      city: "COWARTS",
      state: "AL"
    },
    {
      city: "COWDEN",
      state: "IL"
    },
    {
      city: "COWDREY",
      state: "CO"
    },
    {
      city: "COWEN",
      state: "WV"
    },
    {
      city: "COWETA",
      state: "OK"
    },
    {
      city: "COWGILL",
      state: "MO"
    },
    {
      city: "COWICHE",
      state: "WA"
    },
    {
      city: "COWLESVILLE",
      state: "NY"
    },
    {
      city: "COWLEY",
      state: "WY"
    },
    {
      city: "COWPENS",
      state: "SC"
    },
    {
      city: "COXS CREEK",
      state: "KY"
    },
    {
      city: "COXS MILLS",
      state: "WV"
    },
    {
      city: "COXSACKIE",
      state: "NY"
    },
    {
      city: "COY",
      state: "AL"
    },
    {
      city: "COY",
      state: "AR"
    },
    {
      city: "COYANOSA",
      state: "TX"
    },
    {
      city: "COYLE",
      state: "OK"
    },
    {
      city: "COYOTE",
      state: "CA"
    },
    {
      city: "COYOTE",
      state: "NM"
    },
    {
      city: "COYOTE SPRINGS",
      state: "NV"
    },
    {
      city: "COZAD",
      state: "NE"
    },
    {
      city: "CRAB ORCHARD",
      state: "KY"
    },
    {
      city: "CRAB ORCHARD",
      state: "NE"
    },
    {
      city: "CRAB ORCHARD",
      state: "TN"
    },
    {
      city: "CRAB ORCHARD",
      state: "WV"
    },
    {
      city: "CRABTREE",
      state: "OR"
    },
    {
      city: "CRABTREE",
      state: "PA"
    },
    {
      city: "CRADDOCKVILLE",
      state: "VA"
    },
    {
      city: "CRAFTSBURY",
      state: "VT"
    },
    {
      city: "CRAFTSBURY COMMON",
      state: "VT"
    },
    {
      city: "CRAGFORD",
      state: "AL"
    },
    {
      city: "CRAGSMOOR",
      state: "NY"
    },
    {
      city: "CRAIG",
      state: "AK"
    },
    {
      city: "CRAIG",
      state: "CO"
    },
    {
      city: "CRAIG",
      state: "MO"
    },
    {
      city: "CRAIG",
      state: "NE"
    },
    {
      city: "CRAIGMONT",
      state: "ID"
    },
    {
      city: "CRAIGSVILLE",
      state: "VA"
    },
    {
      city: "CRAIGSVILLE",
      state: "WV"
    },
    {
      city: "CRAIGVILLE",
      state: "IN"
    },
    {
      city: "CRALEY",
      state: "PA"
    },
    {
      city: "CRAMERTON",
      state: "NC"
    },
    {
      city: "CRANBERRY",
      state: "PA"
    },
    {
      city: "CRANBERRY ISLES",
      state: "ME"
    },
    {
      city: "CRANBERRY LAKE",
      state: "NY"
    },
    {
      city: "CRANBERRY TOWNSHIP",
      state: "PA"
    },
    {
      city: "CRANBURY",
      state: "NJ"
    },
    {
      city: "CRANDALL",
      state: "GA"
    },
    {
      city: "CRANDALL",
      state: "IN"
    },
    {
      city: "CRANDALL",
      state: "TX"
    },
    {
      city: "CRANDON",
      state: "WI"
    },
    {
      city: "CRANE",
      state: "IN"
    },
    {
      city: "CRANE",
      state: "MO"
    },
    {
      city: "CRANE",
      state: "MT"
    },
    {
      city: "CRANE",
      state: "OR"
    },
    {
      city: "CRANE",
      state: "TX"
    },
    {
      city: "CRANE HILL",
      state: "AL"
    },
    {
      city: "CRANE LAKE",
      state: "MN"
    },
    {
      city: "CRANESVILLE",
      state: "PA"
    },
    {
      city: "CRANFILLS GAP",
      state: "TX"
    },
    {
      city: "CRANFORD",
      state: "NJ"
    },
    {
      city: "CRANKS",
      state: "KY"
    },
    {
      city: "CRANSTON",
      state: "RI"
    },
    {
      city: "CRARY",
      state: "ND"
    },
    {
      city: "CRARYVILLE",
      state: "NY"
    },
    {
      city: "CRATER LAKE",
      state: "OR"
    },
    {
      city: "CRAWFORD",
      state: "CO"
    },
    {
      city: "CRAWFORD",
      state: "GA"
    },
    {
      city: "CRAWFORD",
      state: "MS"
    },
    {
      city: "CRAWFORD",
      state: "NE"
    },
    {
      city: "CRAWFORD",
      state: "OK"
    },
    {
      city: "CRAWFORD",
      state: "TX"
    },
    {
      city: "CRAWFORD",
      state: "WV"
    },
    {
      city: "CRAWFORDSVILLE",
      state: "AR"
    },
    {
      city: "CRAWFORDSVILLE",
      state: "IA"
    },
    {
      city: "CRAWFORDSVILLE",
      state: "IN"
    },
    {
      city: "CRAWFORDSVILLE",
      state: "OR"
    },
    {
      city: "CRAWFORDVILLE",
      state: "FL"
    },
    {
      city: "CRAWFORDVILLE",
      state: "GA"
    },
    {
      city: "CRAWLEY",
      state: "WV"
    },
    {
      city: "CRAYNE",
      state: "KY"
    },
    {
      city: "CREAL SPRINGS",
      state: "IL"
    },
    {
      city: "CREAM RIDGE",
      state: "NJ"
    },
    {
      city: "CREAMERY",
      state: "PA"
    },
    {
      city: "CREEDE",
      state: "CO"
    },
    {
      city: "CREEDMOOR",
      state: "NC"
    },
    {
      city: "CREEKSIDE",
      state: "PA"
    },
    {
      city: "CREIGHTON",
      state: "MO"
    },
    {
      city: "CREIGHTON",
      state: "NE"
    },
    {
      city: "CREIGHTON",
      state: "PA"
    },
    {
      city: "CRENSHAW",
      state: "MS"
    },
    {
      city: "CREOLA",
      state: "AL"
    },
    {
      city: "CREOLA",
      state: "OH"
    },
    {
      city: "CREOLE",
      state: "LA"
    },
    {
      city: "CRESBARD",
      state: "SD"
    },
    {
      city: "CRESCENT",
      state: "GA"
    },
    {
      city: "CRESCENT",
      state: "IA"
    },
    {
      city: "CRESCENT",
      state: "OK"
    },
    {
      city: "CRESCENT",
      state: "OR"
    },
    {
      city: "CRESCENT",
      state: "PA"
    },
    {
      city: "CRESCENT CITY",
      state: "CA"
    },
    {
      city: "CRESCENT CITY",
      state: "FL"
    },
    {
      city: "CRESCENT CITY",
      state: "IL"
    },
    {
      city: "CRESCENT MILLS",
      state: "CA"
    },
    {
      city: "CRESCENT VALLEY",
      state: "NV"
    },
    {
      city: "CRESCO",
      state: "IA"
    },
    {
      city: "CRESCO",
      state: "PA"
    },
    {
      city: "CRESSEY",
      state: "CA"
    },
    {
      city: "CRESSKILL",
      state: "NJ"
    },
    {
      city: "CRESSON",
      state: "PA"
    },
    {
      city: "CRESSON",
      state: "TX"
    },
    {
      city: "CRESSONA",
      state: "PA"
    },
    {
      city: "CREST HILL",
      state: "IL"
    },
    {
      city: "CREST PARK",
      state: "CA"
    },
    {
      city: "CRESTED BUTTE",
      state: "CO"
    },
    {
      city: "CRESTLINE",
      state: "CA"
    },
    {
      city: "CRESTLINE",
      state: "KS"
    },
    {
      city: "CRESTLINE",
      state: "OH"
    },
    {
      city: "CRESTON",
      state: "CA"
    },
    {
      city: "CRESTON",
      state: "IA"
    },
    {
      city: "CRESTON",
      state: "IL"
    },
    {
      city: "CRESTON",
      state: "NC"
    },
    {
      city: "CRESTON",
      state: "NE"
    },
    {
      city: "CRESTON",
      state: "OH"
    },
    {
      city: "CRESTON",
      state: "WA"
    },
    {
      city: "CRESTON",
      state: "WV"
    },
    {
      city: "CRESTONE",
      state: "CO"
    },
    {
      city: "CRESTVIEW",
      state: "FL"
    },
    {
      city: "CRESTWOOD",
      state: "KY"
    },
    {
      city: "CRESWELL",
      state: "NC"
    },
    {
      city: "CRESWELL",
      state: "OR"
    },
    {
      city: "CRETE",
      state: "IL"
    },
    {
      city: "CRETE",
      state: "NE"
    },
    {
      city: "CREVE COEUR",
      state: "IL"
    },
    {
      city: "CREWE",
      state: "VA"
    },
    {
      city: "CRIDERS",
      state: "VA"
    },
    {
      city: "CRIMORA",
      state: "VA"
    },
    {
      city: "CRIPPLE CREEK",
      state: "CO"
    },
    {
      city: "CRISFIELD",
      state: "MD"
    },
    {
      city: "CRITTENDEN",
      state: "KY"
    },
    {
      city: "CRITTENDEN",
      state: "NY"
    },
    {
      city: "CRITZ",
      state: "VA"
    },
    {
      city: "CRIVITZ",
      state: "WI"
    },
    {
      city: "CROCKER",
      state: "MO"
    },
    {
      city: "CROCKETT",
      state: "CA"
    },
    {
      city: "CROCKETT",
      state: "KY"
    },
    {
      city: "CROCKETT",
      state: "TX"
    },
    {
      city: "CROCKETT",
      state: "VA"
    },
    {
      city: "CROCKETT MILLS",
      state: "TN"
    },
    {
      city: "CROCKETTS BLUFF",
      state: "AR"
    },
    {
      city: "CROFTON",
      state: "KY"
    },
    {
      city: "CROFTON",
      state: "MD"
    },
    {
      city: "CROFTON",
      state: "NE"
    },
    {
      city: "CROGHAN",
      state: "NY"
    },
    {
      city: "CROMONA",
      state: "KY"
    },
    {
      city: "CROMPOND",
      state: "NY"
    },
    {
      city: "CROMWELL",
      state: "CT"
    },
    {
      city: "CROMWELL",
      state: "IN"
    },
    {
      city: "CROMWELL",
      state: "KY"
    },
    {
      city: "CROMWELL",
      state: "MN"
    },
    {
      city: "CROMWELL",
      state: "OK"
    },
    {
      city: "CROOK",
      state: "CO"
    },
    {
      city: "CROOKED CREEK",
      state: "AK"
    },
    {
      city: "CROOKS",
      state: "SD"
    },
    {
      city: "CROOKSTON",
      state: "MN"
    },
    {
      city: "CROOKSTON",
      state: "NE"
    },
    {
      city: "CROOKSVILLE",
      state: "OH"
    },
    {
      city: "CROPSEY",
      state: "IL"
    },
    {
      city: "CROPSEYVILLE",
      state: "NY"
    },
    {
      city: "CROPWELL",
      state: "AL"
    },
    {
      city: "CROSBY",
      state: "MN"
    },
    {
      city: "CROSBY",
      state: "MS"
    },
    {
      city: "CROSBY",
      state: "ND"
    },
    {
      city: "CROSBY",
      state: "PA"
    },
    {
      city: "CROSBY",
      state: "TX"
    },
    {
      city: "CROSBYTON",
      state: "TX"
    },
    {
      city: "CROSS",
      state: "SC"
    },
    {
      city: "CROSS ANCHOR",
      state: "SC"
    },
    {
      city: "CROSS CITY",
      state: "FL"
    },
    {
      city: "CROSS FORK",
      state: "PA"
    },
    {
      city: "CROSS HILL",
      state: "SC"
    },
    {
      city: "CROSS JUNCTION",
      state: "VA"
    },
    {
      city: "CROSS PLAINS",
      state: "IN"
    },
    {
      city: "CROSS PLAINS",
      state: "TN"
    },
    {
      city: "CROSS PLAINS",
      state: "TX"
    },
    {
      city: "CROSS PLAINS",
      state: "WI"
    },
    {
      city: "CROSS RIVER",
      state: "NY"
    },
    {
      city: "CROSS TIMBERS",
      state: "MO"
    },
    {
      city: "CROSS VILLAGE",
      state: "MI"
    },
    {
      city: "CROSSETT",
      state: "AR"
    },
    {
      city: "CROSSLAKE",
      state: "MN"
    },
    {
      city: "CROSSNORE",
      state: "NC"
    },
    {
      city: "CROSSROADS",
      state: "NM"
    },
    {
      city: "CROSSVILLE",
      state: "AL"
    },
    {
      city: "CROSSVILLE",
      state: "IL"
    },
    {
      city: "CROSSVILLE",
      state: "TN"
    },
    {
      city: "CROSWELL",
      state: "MI"
    },
    {
      city: "CROTHERSVILLE",
      state: "IN"
    },
    {
      city: "CROTON",
      state: "OH"
    },
    {
      city: "CROTON FALLS",
      state: "NY"
    },
    {
      city: "CROTON ON HUDSON",
      state: "NY"
    },
    {
      city: "CROUSE",
      state: "NC"
    },
    {
      city: "CROUSEVILLE",
      state: "ME"
    },
    {
      city: "CROW AGENCY",
      state: "MT"
    },
    {
      city: "CROWDER",
      state: "MS"
    },
    {
      city: "CROWDER",
      state: "OK"
    },
    {
      city: "CROWELL",
      state: "TX"
    },
    {
      city: "CROWHEART",
      state: "WY"
    },
    {
      city: "CROWLEY",
      state: "CO"
    },
    {
      city: "CROWLEY",
      state: "LA"
    },
    {
      city: "CROWLEY",
      state: "TX"
    },
    {
      city: "CROWN",
      state: "PA"
    },
    {
      city: "CROWN CITY",
      state: "OH"
    },
    {
      city: "CROWN KING",
      state: "AZ"
    },
    {
      city: "CROWN POINT",
      state: "IN"
    },
    {
      city: "CROWN POINT",
      state: "NY"
    },
    {
      city: "CROWNPOINT",
      state: "NM"
    },
    {
      city: "CROWNSVILLE",
      state: "MD"
    },
    {
      city: "CROWS LANDING",
      state: "CA"
    },
    {
      city: "CROWVILLE",
      state: "LA"
    },
    {
      city: "CROYDON",
      state: "PA"
    },
    {
      city: "CROYDON",
      state: "UT"
    },
    {
      city: "CROZET",
      state: "VA"
    },
    {
      city: "CROZIER",
      state: "VA"
    },
    {
      city: "CRUCIBLE",
      state: "PA"
    },
    {
      city: "CRUGER",
      state: "MS"
    },
    {
      city: "CRUM",
      state: "WV"
    },
    {
      city: "CRUM LYNNE",
      state: "PA"
    },
    {
      city: "CRUMP",
      state: "TN"
    },
    {
      city: "CRUMPLER",
      state: "NC"
    },
    {
      city: "CRUMPTON",
      state: "MD"
    },
    {
      city: "CRUMROD",
      state: "AR"
    },
    {
      city: "CRYSTAL",
      state: "MI"
    },
    {
      city: "CRYSTAL",
      state: "ND"
    },
    {
      city: "CRYSTAL BAY",
      state: "MN"
    },
    {
      city: "CRYSTAL BAY",
      state: "NV"
    },
    {
      city: "CRYSTAL BEACH",
      state: "FL"
    },
    {
      city: "CRYSTAL CITY",
      state: "MO"
    },
    {
      city: "CRYSTAL CITY",
      state: "TX"
    },
    {
      city: "CRYSTAL FALLS",
      state: "MI"
    },
    {
      city: "CRYSTAL HILL",
      state: "VA"
    },
    {
      city: "CRYSTAL LAKE",
      state: "IA"
    },
    {
      city: "CRYSTAL LAKE",
      state: "IL"
    },
    {
      city: "CRYSTAL RIVER",
      state: "FL"
    },
    {
      city: "CRYSTAL SPRING",
      state: "PA"
    },
    {
      city: "CRYSTAL SPRINGS",
      state: "FL"
    },
    {
      city: "CRYSTAL SPRINGS",
      state: "MS"
    },
    {
      city: "CUB RUN",
      state: "KY"
    },
    {
      city: "CUBA",
      state: "AL"
    },
    {
      city: "CUBA",
      state: "IL"
    },
    {
      city: "CUBA",
      state: "KS"
    },
    {
      city: "CUBA",
      state: "MO"
    },
    {
      city: "CUBA",
      state: "NM"
    },
    {
      city: "CUBA",
      state: "NY"
    },
    {
      city: "CUBA",
      state: "OH"
    },
    {
      city: "CUBA CITY",
      state: "WI"
    },
    {
      city: "CUBERO",
      state: "NM"
    },
    {
      city: "CUCUMBER",
      state: "WV"
    },
    {
      city: "CUDAHY",
      state: "WI"
    },
    {
      city: "CUDDEBACKVILLE",
      state: "NY"
    },
    {
      city: "CUDDY",
      state: "PA"
    },
    {
      city: "CUERO",
      state: "TX"
    },
    {
      city: "CULBERTSON",
      state: "MT"
    },
    {
      city: "CULBERTSON",
      state: "NE"
    },
    {
      city: "CULDESAC",
      state: "ID"
    },
    {
      city: "CULLEN",
      state: "LA"
    },
    {
      city: "CULLEN",
      state: "VA"
    },
    {
      city: "CULLEOKA",
      state: "TN"
    },
    {
      city: "CULLMAN",
      state: "AL"
    },
    {
      city: "CULLODEN",
      state: "GA"
    },
    {
      city: "CULLODEN",
      state: "WV"
    },
    {
      city: "CULLOM",
      state: "IL"
    },
    {
      city: "CULLOWHEE",
      state: "NC"
    },
    {
      city: "CULPEPER",
      state: "VA"
    },
    {
      city: "CULVER",
      state: "IN"
    },
    {
      city: "CULVER",
      state: "OR"
    },
    {
      city: "CULVER CITY",
      state: "CA"
    },
    {
      city: "CUMBERLAND",
      state: "IA"
    },
    {
      city: "CUMBERLAND",
      state: "KY"
    },
    {
      city: "CUMBERLAND",
      state: "MD"
    },
    {
      city: "CUMBERLAND",
      state: "NC"
    },
    {
      city: "CUMBERLAND",
      state: "OH"
    },
    {
      city: "CUMBERLAND",
      state: "RI"
    },
    {
      city: "CUMBERLAND",
      state: "VA"
    },
    {
      city: "CUMBERLAND",
      state: "WI"
    },
    {
      city: "CUMBERLAND CENTER",
      state: "ME"
    },
    {
      city: "CUMBERLAND CITY",
      state: "TN"
    },
    {
      city: "CUMBERLAND FORESIDE",
      state: "ME"
    },
    {
      city: "CUMBERLAND FURNACE",
      state: "TN"
    },
    {
      city: "CUMBERLAND GAP",
      state: "TN"
    },
    {
      city: "CUMBOLA",
      state: "PA"
    },
    {
      city: "CUMBY",
      state: "TX"
    },
    {
      city: "CUMMAQUID",
      state: "MA"
    },
    {
      city: "CUMMING",
      state: "GA"
    },
    {
      city: "CUMMING",
      state: "IA"
    },
    {
      city: "CUMMINGS",
      state: "ND"
    },
    {
      city: "CUMMINGTON",
      state: "MA"
    },
    {
      city: "CUMNOCK",
      state: "NC"
    },
    {
      city: "CUNEY",
      state: "TX"
    },
    {
      city: "CUNNINGHAM",
      state: "KS"
    },
    {
      city: "CUNNINGHAM",
      state: "KY"
    },
    {
      city: "CUNNINGHAM",
      state: "TN"
    },
    {
      city: "CUNNINGHAM",
      state: "TX"
    },
    {
      city: "CUPERTINO",
      state: "CA"
    },
    {
      city: "CURLEW",
      state: "IA"
    },
    {
      city: "CURLEW",
      state: "WA"
    },
    {
      city: "CURRAN",
      state: "MI"
    },
    {
      city: "CURRIE",
      state: "MN"
    },
    {
      city: "CURRIE",
      state: "NC"
    },
    {
      city: "CURRITUCK",
      state: "NC"
    },
    {
      city: "CURRYVILLE",
      state: "MO"
    },
    {
      city: "CURRYVILLE",
      state: "PA"
    },
    {
      city: "CURTICE",
      state: "OH"
    },
    {
      city: "CURTIS",
      state: "AR"
    },
    {
      city: "CURTIS",
      state: "MI"
    },
    {
      city: "CURTIS",
      state: "NE"
    },
    {
      city: "CURTIS",
      state: "WA"
    },
    {
      city: "CURTIS BAY",
      state: "MD"
    },
    {
      city: "CURTISS",
      state: "WI"
    },
    {
      city: "CURTISVILLE",
      state: "PA"
    },
    {
      city: "CURWENSVILLE",
      state: "PA"
    },
    {
      city: "CUSHING",
      state: "IA"
    },
    {
      city: "CUSHING",
      state: "ME"
    },
    {
      city: "CUSHING",
      state: "MN"
    },
    {
      city: "CUSHING",
      state: "OK"
    },
    {
      city: "CUSHING",
      state: "TX"
    },
    {
      city: "CUSHING",
      state: "WI"
    },
    {
      city: "CUSHMAN",
      state: "AR"
    },
    {
      city: "CUSICK",
      state: "WA"
    },
    {
      city: "CUSSETA",
      state: "AL"
    },
    {
      city: "CUSSETA",
      state: "GA"
    },
    {
      city: "CUSTAR",
      state: "OH"
    },
    {
      city: "CUSTER",
      state: "KY"
    },
    {
      city: "CUSTER",
      state: "MI"
    },
    {
      city: "CUSTER",
      state: "MT"
    },
    {
      city: "CUSTER",
      state: "SD"
    },
    {
      city: "CUSTER",
      state: "WA"
    },
    {
      city: "CUSTER",
      state: "WI"
    },
    {
      city: "CUSTER CITY",
      state: "OK"
    },
    {
      city: "CUSTER CITY",
      state: "PA"
    },
    {
      city: "CUT BANK",
      state: "MT"
    },
    {
      city: "CUT OFF",
      state: "LA"
    },
    {
      city: "CUTCHOGUE",
      state: "NY"
    },
    {
      city: "CUTHBERT",
      state: "GA"
    },
    {
      city: "CUTLER",
      state: "CA"
    },
    {
      city: "CUTLER",
      state: "IL"
    },
    {
      city: "CUTLER",
      state: "IN"
    },
    {
      city: "CUTLER",
      state: "ME"
    },
    {
      city: "CUTLER",
      state: "OH"
    },
    {
      city: "CUTTEN",
      state: "CA"
    },
    {
      city: "CUTTINGSVILLE",
      state: "VT"
    },
    {
      city: "CUTTYHUNK",
      state: "MA"
    },
    {
      city: "CUYAHOGA FALLS",
      state: "OH"
    },
    {
      city: "CYCLONE",
      state: "PA"
    },
    {
      city: "CYCLONE",
      state: "WV"
    },
    {
      city: "CYGNET",
      state: "OH"
    },
    {
      city: "CYLINDER",
      state: "IA"
    },
    {
      city: "CYNTHIANA",
      state: "IN"
    },
    {
      city: "CYNTHIANA",
      state: "KY"
    },
    {
      city: "CYNTHIANA",
      state: "OH"
    },
    {
      city: "CYPRESS",
      state: "CA"
    },
    {
      city: "CYPRESS",
      state: "IL"
    },
    {
      city: "CYPRESS",
      state: "TX"
    },
    {
      city: "CYPRESS INN",
      state: "TN"
    },
    {
      city: "CYRIL",
      state: "OK"
    },
    {
      city: "CYRUS",
      state: "MN"
    },
    {
      city: "D HANIS",
      state: "TX"
    },
    {
      city: "D LO",
      state: "MS"
    },
    {
      city: "DACOMA",
      state: "OK"
    },
    {
      city: "DACONO",
      state: "CO"
    },
    {
      city: "DACULA",
      state: "GA"
    },
    {
      city: "DADE CITY",
      state: "FL"
    },
    {
      city: "DADEVILLE",
      state: "AL"
    },
    {
      city: "DADEVILLE",
      state: "MO"
    },
    {
      city: "DAFTER",
      state: "MI"
    },
    {
      city: "DAGGETT",
      state: "CA"
    },
    {
      city: "DAGGETT",
      state: "MI"
    },
    {
      city: "DAGMAR",
      state: "MT"
    },
    {
      city: "DAGSBORO",
      state: "DE"
    },
    {
      city: "DAGUS MINES",
      state: "PA"
    },
    {
      city: "DAHINDA",
      state: "IL"
    },
    {
      city: "DAHLEN",
      state: "ND"
    },
    {
      city: "DAHLGREN",
      state: "IL"
    },
    {
      city: "DAHLGREN",
      state: "VA"
    },
    {
      city: "DAHLONEGA",
      state: "GA"
    },
    {
      city: "DAILEY",
      state: "WV"
    },
    {
      city: "DAINGERFIELD",
      state: "TX"
    },
    {
      city: "DAIRY",
      state: "OR"
    },
    {
      city: "DAISETTA",
      state: "TX"
    },
    {
      city: "DAISY",
      state: "GA"
    },
    {
      city: "DAISY",
      state: "MO"
    },
    {
      city: "DAISY",
      state: "OK"
    },
    {
      city: "DAISYTOWN",
      state: "PA"
    },
    {
      city: "DAKOTA",
      state: "IL"
    },
    {
      city: "DAKOTA",
      state: "MN"
    },
    {
      city: "DAKOTA CITY",
      state: "IA"
    },
    {
      city: "DAKOTA CITY",
      state: "NE"
    },
    {
      city: "DALBO",
      state: "MN"
    },
    {
      city: "DALE",
      state: "IL"
    },
    {
      city: "DALE",
      state: "IN"
    },
    {
      city: "DALE",
      state: "NY"
    },
    {
      city: "DALE",
      state: "SC"
    },
    {
      city: "DALE",
      state: "TX"
    },
    {
      city: "DALE",
      state: "WI"
    },
    {
      city: "DALEVILLE",
      state: "AL"
    },
    {
      city: "DALEVILLE",
      state: "IN"
    },
    {
      city: "DALEVILLE",
      state: "MS"
    },
    {
      city: "DALEVILLE",
      state: "VA"
    },
    {
      city: "DALHART",
      state: "TX"
    },
    {
      city: "DALLARDSVILLE",
      state: "TX"
    },
    {
      city: "DALLAS",
      state: "GA"
    },
    {
      city: "DALLAS",
      state: "NC"
    },
    {
      city: "DALLAS",
      state: "OR"
    },
    {
      city: "DALLAS",
      state: "PA"
    },
    {
      city: "DALLAS",
      state: "SD"
    },
    {
      city: "DALLAS",
      state: "TX"
    },
    {
      city: "DALLAS",
      state: "WI"
    },
    {
      city: "DALLAS",
      state: "WV"
    },
    {
      city: "DALLAS CENTER",
      state: "IA"
    },
    {
      city: "DALLAS CITY",
      state: "IL"
    },
    {
      city: "DALLASTOWN",
      state: "PA"
    },
    {
      city: "DALLESPORT",
      state: "WA"
    },
    {
      city: "DALMATIA",
      state: "PA"
    },
    {
      city: "DALTON",
      state: "GA"
    },
    {
      city: "DALTON",
      state: "MA"
    },
    {
      city: "DALTON",
      state: "MN"
    },
    {
      city: "DALTON",
      state: "NE"
    },
    {
      city: "DALTON",
      state: "NY"
    },
    {
      city: "DALTON",
      state: "OH"
    },
    {
      city: "DALTON",
      state: "PA"
    },
    {
      city: "DALTON",
      state: "WI"
    },
    {
      city: "DALTON CITY",
      state: "IL"
    },
    {
      city: "DALY CITY",
      state: "CA"
    },
    {
      city: "DALZELL",
      state: "IL"
    },
    {
      city: "DALZELL",
      state: "SC"
    },
    {
      city: "DAMAR",
      state: "KS"
    },
    {
      city: "DAMARISCOTTA",
      state: "ME"
    },
    {
      city: "DAMASCUS",
      state: "AR"
    },
    {
      city: "DAMASCUS",
      state: "GA"
    },
    {
      city: "DAMASCUS",
      state: "MD"
    },
    {
      city: "DAMASCUS",
      state: "OH"
    },
    {
      city: "DAMASCUS",
      state: "OR"
    },
    {
      city: "DAMASCUS",
      state: "PA"
    },
    {
      city: "DAMASCUS",
      state: "VA"
    },
    {
      city: "DAMERON",
      state: "MD"
    },
    {
      city: "DAMMERON VALLEY",
      state: "UT"
    },
    {
      city: "DAMON",
      state: "TX"
    },
    {
      city: "DANA",
      state: "IA"
    },
    {
      city: "DANA",
      state: "IL"
    },
    {
      city: "DANA",
      state: "IN"
    },
    {
      city: "DANA",
      state: "KY"
    },
    {
      city: "DANA",
      state: "NC"
    },
    {
      city: "DANA POINT",
      state: "CA"
    },
    {
      city: "DANBORO",
      state: "PA"
    },
    {
      city: "DANBURY",
      state: "CT"
    },
    {
      city: "DANBURY",
      state: "IA"
    },
    {
      city: "DANBURY",
      state: "NC"
    },
    {
      city: "DANBURY",
      state: "NE"
    },
    {
      city: "DANBURY",
      state: "NH"
    },
    {
      city: "DANBURY",
      state: "TX"
    },
    {
      city: "DANBURY",
      state: "WI"
    },
    {
      city: "DANBY",
      state: "VT"
    },
    {
      city: "DANCIGER",
      state: "TX"
    },
    {
      city: "DANDRIDGE",
      state: "TN"
    },
    {
      city: "DANE",
      state: "WI"
    },
    {
      city: "DANESE",
      state: "WV"
    },
    {
      city: "DANEVANG",
      state: "TX"
    },
    {
      city: "DANFORTH",
      state: "IL"
    },
    {
      city: "DANFORTH",
      state: "ME"
    },
    {
      city: "DANIA",
      state: "FL"
    },
    {
      city: "DANIEL",
      state: "WY"
    },
    {
      city: "DANIELS",
      state: "WV"
    },
    {
      city: "DANIELSON",
      state: "CT"
    },
    {
      city: "DANIELSVILLE",
      state: "GA"
    },
    {
      city: "DANIELSVILLE",
      state: "PA"
    },
    {
      city: "DANNEBROG",
      state: "NE"
    },
    {
      city: "DANNEMORA",
      state: "NY"
    },
    {
      city: "DANSVILLE",
      state: "MI"
    },
    {
      city: "DANSVILLE",
      state: "NY"
    },
    {
      city: "DANTE",
      state: "SD"
    },
    {
      city: "DANTE",
      state: "VA"
    },
    {
      city: "DANUBE",
      state: "MN"
    },
    {
      city: "DANVERS",
      state: "IL"
    },
    {
      city: "DANVERS",
      state: "MA"
    },
    {
      city: "DANVERS",
      state: "MN"
    },
    {
      city: "DANVILLE",
      state: "AL"
    },
    {
      city: "DANVILLE",
      state: "AR"
    },
    {
      city: "DANVILLE",
      state: "CA"
    },
    {
      city: "DANVILLE",
      state: "GA"
    },
    {
      city: "DANVILLE",
      state: "IA"
    },
    {
      city: "DANVILLE",
      state: "IL"
    },
    {
      city: "DANVILLE",
      state: "IN"
    },
    {
      city: "DANVILLE",
      state: "KS"
    },
    {
      city: "DANVILLE",
      state: "KY"
    },
    {
      city: "DANVILLE",
      state: "ME"
    },
    {
      city: "DANVILLE",
      state: "NH"
    },
    {
      city: "DANVILLE",
      state: "OH"
    },
    {
      city: "DANVILLE",
      state: "PA"
    },
    {
      city: "DANVILLE",
      state: "VA"
    },
    {
      city: "DANVILLE",
      state: "VT"
    },
    {
      city: "DANVILLE",
      state: "WA"
    },
    {
      city: "DANVILLE",
      state: "WV"
    },
    {
      city: "DAPHNE",
      state: "AL"
    },
    {
      city: "DARBY",
      state: "MT"
    },
    {
      city: "DARBY",
      state: "PA"
    },
    {
      city: "DARDANELLE",
      state: "AR"
    },
    {
      city: "DARDEN",
      state: "TN"
    },
    {
      city: "DARFUR",
      state: "MN"
    },
    {
      city: "DARIEN",
      state: "CT"
    },
    {
      city: "DARIEN",
      state: "GA"
    },
    {
      city: "DARIEN",
      state: "IL"
    },
    {
      city: "DARIEN",
      state: "WI"
    },
    {
      city: "DARIEN CENTER",
      state: "NY"
    },
    {
      city: "DARLING",
      state: "MS"
    },
    {
      city: "DARLINGTON",
      state: "IN"
    },
    {
      city: "DARLINGTON",
      state: "MD"
    },
    {
      city: "DARLINGTON",
      state: "MO"
    },
    {
      city: "DARLINGTON",
      state: "PA"
    },
    {
      city: "DARLINGTON",
      state: "SC"
    },
    {
      city: "DARLINGTON",
      state: "WI"
    },
    {
      city: "DARRAGH",
      state: "PA"
    },
    {
      city: "DARRINGTON",
      state: "WA"
    },
    {
      city: "DARROUZETT",
      state: "TX"
    },
    {
      city: "DARROW",
      state: "LA"
    },
    {
      city: "DARTMOUTH",
      state: "MA"
    },
    {
      city: "DARWIN",
      state: "CA"
    },
    {
      city: "DARWIN",
      state: "MN"
    },
    {
      city: "DASSEL",
      state: "MN"
    },
    {
      city: "DATELAND",
      state: "AZ"
    },
    {
      city: "DATIL",
      state: "NM"
    },
    {
      city: "DAUFUSKIE ISLAND",
      state: "SC"
    },
    {
      city: "DAUPHIN",
      state: "PA"
    },
    {
      city: "DAUPHIN ISLAND",
      state: "AL"
    },
    {
      city: "DAVENPORT",
      state: "CA"
    },
    {
      city: "DAVENPORT",
      state: "FL"
    },
    {
      city: "DAVENPORT",
      state: "IA"
    },
    {
      city: "DAVENPORT",
      state: "ND"
    },
    {
      city: "DAVENPORT",
      state: "NE"
    },
    {
      city: "DAVENPORT",
      state: "NY"
    },
    {
      city: "DAVENPORT",
      state: "OK"
    },
    {
      city: "DAVENPORT",
      state: "VA"
    },
    {
      city: "DAVENPORT",
      state: "WA"
    },
    {
      city: "DAVENPORT CENTER",
      state: "NY"
    },
    {
      city: "DAVEY",
      state: "NE"
    },
    {
      city: "DAVID",
      state: "KY"
    },
    {
      city: "DAVID CITY",
      state: "NE"
    },
    {
      city: "DAVIDSON",
      state: "NC"
    },
    {
      city: "DAVIDSON",
      state: "OK"
    },
    {
      city: "DAVIDSONVILLE",
      state: "MD"
    },
    {
      city: "DAVIDSVILLE",
      state: "PA"
    },
    {
      city: "DAVILLA",
      state: "TX"
    },
    {
      city: "DAVIN",
      state: "WV"
    },
    {
      city: "DAVIS",
      state: "CA"
    },
    {
      city: "DAVIS",
      state: "IL"
    },
    {
      city: "DAVIS",
      state: "NC"
    },
    {
      city: "DAVIS",
      state: "OK"
    },
    {
      city: "DAVIS",
      state: "SD"
    },
    {
      city: "DAVIS",
      state: "WV"
    },
    {
      city: "DAVIS CITY",
      state: "IA"
    },
    {
      city: "DAVIS CREEK",
      state: "CA"
    },
    {
      city: "DAVIS JUNCTION",
      state: "IL"
    },
    {
      city: "DAVIS STATION",
      state: "SC"
    },
    {
      city: "DAVISBORO",
      state: "GA"
    },
    {
      city: "DAVISBURG",
      state: "MI"
    },
    {
      city: "DAVISON",
      state: "MI"
    },
    {
      city: "DAVISTON",
      state: "AL"
    },
    {
      city: "DAVISVILLE",
      state: "MO"
    },
    {
      city: "DAVISVILLE",
      state: "WV"
    },
    {
      city: "DAVY",
      state: "WV"
    },
    {
      city: "DAWES",
      state: "WV"
    },
    {
      city: "DAWN",
      state: "MO"
    },
    {
      city: "DAWN",
      state: "TX"
    },
    {
      city: "DAWSON",
      state: "AL"
    },
    {
      city: "DAWSON",
      state: "GA"
    },
    {
      city: "DAWSON",
      state: "IA"
    },
    {
      city: "DAWSON",
      state: "IL"
    },
    {
      city: "DAWSON",
      state: "MN"
    },
    {
      city: "DAWSON",
      state: "ND"
    },
    {
      city: "DAWSON",
      state: "NE"
    },
    {
      city: "DAWSON",
      state: "PA"
    },
    {
      city: "DAWSON",
      state: "TX"
    },
    {
      city: "DAWSON SPRINGS",
      state: "KY"
    },
    {
      city: "DAWSONVILLE",
      state: "GA"
    },
    {
      city: "DAY",
      state: "FL"
    },
    {
      city: "DAYHOIT",
      state: "KY"
    },
    {
      city: "DAYKIN",
      state: "NE"
    },
    {
      city: "DAYS CREEK",
      state: "OR"
    },
    {
      city: "DAYTON",
      state: "IA"
    },
    {
      city: "DAYTON",
      state: "ID"
    },
    {
      city: "DAYTON",
      state: "IN"
    },
    {
      city: "DAYTON",
      state: "KY"
    },
    {
      city: "DAYTON",
      state: "MD"
    },
    {
      city: "DAYTON",
      state: "MN"
    },
    {
      city: "DAYTON",
      state: "MT"
    },
    {
      city: "DAYTON",
      state: "NJ"
    },
    {
      city: "DAYTON",
      state: "NV"
    },
    {
      city: "DAYTON",
      state: "NY"
    },
    {
      city: "DAYTON",
      state: "OH"
    },
    {
      city: "DAYTON",
      state: "OR"
    },
    {
      city: "DAYTON",
      state: "PA"
    },
    {
      city: "DAYTON",
      state: "TN"
    },
    {
      city: "DAYTON",
      state: "TX"
    },
    {
      city: "DAYTON",
      state: "VA"
    },
    {
      city: "DAYTON",
      state: "WA"
    },
    {
      city: "DAYTON",
      state: "WY"
    },
    {
      city: "DAYTONA BEACH",
      state: "FL"
    },
    {
      city: "DAYVILLE",
      state: "CT"
    },
    {
      city: "DAYVILLE",
      state: "OR"
    },
    {
      city: "DAZEY",
      state: "ND"
    },
    {
      city: "DE ARMANVILLE",
      state: "AL"
    },
    {
      city: "DE BEQUE",
      state: "CO"
    },
    {
      city: "DE BERRY",
      state: "TX"
    },
    {
      city: "DE BORGIA",
      state: "MT"
    },
    {
      city: "DE FOREST",
      state: "WI"
    },
    {
      city: "DE GRAFF",
      state: "OH"
    },
    {
      city: "DE KALB",
      state: "MO"
    },
    {
      city: "DE KALB",
      state: "MS"
    },
    {
      city: "DE KALB",
      state: "TX"
    },
    {
      city: "DE KALB JUNCTION",
      state: "NY"
    },
    {
      city: "DE LANCEY",
      state: "PA"
    },
    {
      city: "DE LAND",
      state: "IL"
    },
    {
      city: "DE LEON",
      state: "TX"
    },
    {
      city: "DE LEON SPRINGS",
      state: "FL"
    },
    {
      city: "DE MOSSVILLE",
      state: "KY"
    },
    {
      city: "DE PERE",
      state: "WI"
    },
    {
      city: "DE PEYSTER",
      state: "NY"
    },
    {
      city: "DE QUEEN",
      state: "AR"
    },
    {
      city: "DE RUYTER",
      state: "NY"
    },
    {
      city: "DE SMET",
      state: "SD"
    },
    {
      city: "DE SOTO",
      state: "GA"
    },
    {
      city: "DE SOTO",
      state: "IA"
    },
    {
      city: "DE SOTO",
      state: "IL"
    },
    {
      city: "DE SOTO",
      state: "KS"
    },
    {
      city: "DE SOTO",
      state: "MO"
    },
    {
      city: "DE SOTO",
      state: "WI"
    },
    {
      city: "DE TOUR VILLAGE",
      state: "MI"
    },
    {
      city: "DE VALLS BLUFF",
      state: "AR"
    },
    {
      city: "DE WITT",
      state: "AR"
    },
    {
      city: "DE WITT",
      state: "IA"
    },
    {
      city: "DE WITT",
      state: "MO"
    },
    {
      city: "DE WITT",
      state: "NE"
    },
    {
      city: "DEADWOOD",
      state: "OR"
    },
    {
      city: "DEADWOOD",
      state: "SD"
    },
    {
      city: "DEAL",
      state: "NJ"
    },
    {
      city: "DEAL ISLAND",
      state: "MD"
    },
    {
      city: "DEALE",
      state: "MD"
    },
    {
      city: "DEANE",
      state: "KY"
    },
    {
      city: "DEANSBORO",
      state: "NY"
    },
    {
      city: "DEANVILLE",
      state: "TX"
    },
    {
      city: "DEARBORN",
      state: "MI"
    },
    {
      city: "DEARBORN",
      state: "MO"
    },
    {
      city: "DEARBORN HEIGHTS",
      state: "MI"
    },
    {
      city: "DEARING",
      state: "GA"
    },
    {
      city: "DEARING",
      state: "KS"
    },
    {
      city: "DEARY",
      state: "ID"
    },
    {
      city: "DEATH VALLEY",
      state: "CA"
    },
    {
      city: "DEATSVILLE",
      state: "AL"
    },
    {
      city: "DEAVER",
      state: "WY"
    },
    {
      city: "DEBARY",
      state: "FL"
    },
    {
      city: "DEBORD",
      state: "KY"
    },
    {
      city: "DECATUR",
      state: "AL"
    },
    {
      city: "DECATUR",
      state: "AR"
    },
    {
      city: "DECATUR",
      state: "GA"
    },
    {
      city: "DECATUR",
      state: "IA"
    },
    {
      city: "DECATUR",
      state: "IL"
    },
    {
      city: "DECATUR",
      state: "IN"
    },
    {
      city: "DECATUR",
      state: "MI"
    },
    {
      city: "DECATUR",
      state: "MS"
    },
    {
      city: "DECATUR",
      state: "NE"
    },
    {
      city: "DECATUR",
      state: "TN"
    },
    {
      city: "DECATUR",
      state: "TX"
    },
    {
      city: "DECATURVILLE",
      state: "TN"
    },
    {
      city: "DECHERD",
      state: "TN"
    },
    {
      city: "DECKER",
      state: "IN"
    },
    {
      city: "DECKER",
      state: "MI"
    },
    {
      city: "DECKER",
      state: "MT"
    },
    {
      city: "DECKERVILLE",
      state: "MI"
    },
    {
      city: "DECLO",
      state: "ID"
    },
    {
      city: "DECORAH",
      state: "IA"
    },
    {
      city: "DEDHAM",
      state: "IA"
    },
    {
      city: "DEDHAM",
      state: "MA"
    },
    {
      city: "DEEP GAP",
      state: "NC"
    },
    {
      city: "DEEP RIVER",
      state: "CT"
    },
    {
      city: "DEEP RIVER",
      state: "IA"
    },
    {
      city: "DEEP RUN",
      state: "NC"
    },
    {
      city: "DEEPWATER",
      state: "MO"
    },
    {
      city: "DEEPWATER",
      state: "NJ"
    },
    {
      city: "DEER",
      state: "AR"
    },
    {
      city: "DEER CREEK",
      state: "IL"
    },
    {
      city: "DEER CREEK",
      state: "MN"
    },
    {
      city: "DEER CREEK",
      state: "OK"
    },
    {
      city: "DEER GROVE",
      state: "IL"
    },
    {
      city: "DEER HARBOR",
      state: "WA"
    },
    {
      city: "DEER ISLAND",
      state: "OR"
    },
    {
      city: "DEER ISLE",
      state: "ME"
    },
    {
      city: "DEER LODGE",
      state: "MT"
    },
    {
      city: "DEER LODGE",
      state: "TN"
    },
    {
      city: "DEER PARK",
      state: "AL"
    },
    {
      city: "DEER PARK",
      state: "CA"
    },
    {
      city: "DEER PARK",
      state: "NY"
    },
    {
      city: "DEER PARK",
      state: "TX"
    },
    {
      city: "DEER PARK",
      state: "WA"
    },
    {
      city: "DEER PARK",
      state: "WI"
    },
    {
      city: "DEER RIVER",
      state: "MN"
    },
    {
      city: "DEER RIVER",
      state: "NY"
    },
    {
      city: "DEER TRAIL",
      state: "CO"
    },
    {
      city: "DEERBROOK",
      state: "WI"
    },
    {
      city: "DEERFIELD",
      state: "IL"
    },
    {
      city: "DEERFIELD",
      state: "KS"
    },
    {
      city: "DEERFIELD",
      state: "MA"
    },
    {
      city: "DEERFIELD",
      state: "MI"
    },
    {
      city: "DEERFIELD",
      state: "MO"
    },
    {
      city: "DEERFIELD",
      state: "NH"
    },
    {
      city: "DEERFIELD",
      state: "OH"
    },
    {
      city: "DEERFIELD",
      state: "VA"
    },
    {
      city: "DEERFIELD",
      state: "WI"
    },
    {
      city: "DEERFIELD BEACH",
      state: "FL"
    },
    {
      city: "DEERFIELD STREET",
      state: "NJ"
    },
    {
      city: "DEERING",
      state: "MO"
    },
    {
      city: "DEERING",
      state: "ND"
    },
    {
      city: "DEERWOOD",
      state: "MN"
    },
    {
      city: "DEETH",
      state: "NV"
    },
    {
      city: "DEFERIET",
      state: "NY"
    },
    {
      city: "DEFIANCE",
      state: "IA"
    },
    {
      city: "DEFIANCE",
      state: "MO"
    },
    {
      city: "DEFIANCE",
      state: "OH"
    },
    {
      city: "DEFIANCE",
      state: "PA"
    },
    {
      city: "DEFORD",
      state: "MI"
    },
    {
      city: "DEFUNIAK SPRINGS",
      state: "FL"
    },
    {
      city: "DEKALB",
      state: "IL"
    },
    {
      city: "DEL MAR",
      state: "CA"
    },
    {
      city: "DEL NORTE",
      state: "CO"
    },
    {
      city: "DEL REY",
      state: "CA"
    },
    {
      city: "DEL RIO",
      state: "TN"
    },
    {
      city: "DEL RIO",
      state: "TX"
    },
    {
      city: "DEL VALLE",
      state: "TX"
    },
    {
      city: "DELAFIELD",
      state: "WI"
    },
    {
      city: "DELANCEY",
      state: "NY"
    },
    {
      city: "DELAND",
      state: "FL"
    },
    {
      city: "DELANO",
      state: "CA"
    },
    {
      city: "DELANO",
      state: "MN"
    },
    {
      city: "DELANO",
      state: "PA"
    },
    {
      city: "DELANO",
      state: "TN"
    },
    {
      city: "DELANSON",
      state: "NY"
    },
    {
      city: "DELAPLAINE",
      state: "AR"
    },
    {
      city: "DELAPLANE",
      state: "VA"
    },
    {
      city: "DELAVAN",
      state: "IL"
    },
    {
      city: "DELAVAN",
      state: "MN"
    },
    {
      city: "DELAVAN",
      state: "WI"
    },
    {
      city: "DELAWARE",
      state: "AR"
    },
    {
      city: "DELAWARE",
      state: "IA"
    },
    {
      city: "DELAWARE",
      state: "NJ"
    },
    {
      city: "DELAWARE",
      state: "OH"
    },
    {
      city: "DELAWARE",
      state: "OK"
    },
    {
      city: "DELAWARE CITY",
      state: "DE"
    },
    {
      city: "DELAWARE WATER GAP",
      state: "PA"
    },
    {
      city: "DELBARTON",
      state: "WV"
    },
    {
      city: "DELCAMBRE",
      state: "LA"
    },
    {
      city: "DELCO",
      state: "NC"
    },
    {
      city: "DELEVAN",
      state: "NY"
    },
    {
      city: "DELHI",
      state: "CA"
    },
    {
      city: "DELHI",
      state: "IA"
    },
    {
      city: "DELHI",
      state: "LA"
    },
    {
      city: "DELHI",
      state: "NY"
    },
    {
      city: "DELIA",
      state: "KS"
    },
    {
      city: "DELIGHT",
      state: "AR"
    },
    {
      city: "DELL",
      state: "AR"
    },
    {
      city: "DELL",
      state: "MT"
    },
    {
      city: "DELL CITY",
      state: "TX"
    },
    {
      city: "DELL RAPIDS",
      state: "SD"
    },
    {
      city: "DELLROSE",
      state: "TN"
    },
    {
      city: "DELLROY",
      state: "OH"
    },
    {
      city: "DELLSLOW",
      state: "WV"
    },
    {
      city: "DELLWOOD",
      state: "WI"
    },
    {
      city: "DELMAR",
      state: "AL"
    },
    {
      city: "DELMAR",
      state: "DE"
    },
    {
      city: "DELMAR",
      state: "IA"
    },
    {
      city: "DELMAR",
      state: "MD"
    },
    {
      city: "DELMAR",
      state: "NY"
    },
    {
      city: "DELMITA",
      state: "TX"
    },
    {
      city: "DELMONT",
      state: "NJ"
    },
    {
      city: "DELMONT",
      state: "PA"
    },
    {
      city: "DELMONT",
      state: "SD"
    },
    {
      city: "DELONG",
      state: "IN"
    },
    {
      city: "DELPHI",
      state: "IN"
    },
    {
      city: "DELPHI FALLS",
      state: "NY"
    },
    {
      city: "DELPHIA",
      state: "KY"
    },
    {
      city: "DELPHOS",
      state: "KS"
    },
    {
      city: "DELPHOS",
      state: "OH"
    },
    {
      city: "DELRAY",
      state: "WV"
    },
    {
      city: "DELRAY BEACH",
      state: "FL"
    },
    {
      city: "DELTA",
      state: "AL"
    },
    {
      city: "DELTA",
      state: "CO"
    },
    {
      city: "DELTA",
      state: "IA"
    },
    {
      city: "DELTA",
      state: "LA"
    },
    {
      city: "DELTA",
      state: "MO"
    },
    {
      city: "DELTA",
      state: "OH"
    },
    {
      city: "DELTA",
      state: "PA"
    },
    {
      city: "DELTA",
      state: "UT"
    },
    {
      city: "DELTA CITY",
      state: "MS"
    },
    {
      city: "DELTA JUNCTION",
      state: "AK"
    },
    {
      city: "DELTAVILLE",
      state: "VA"
    },
    {
      city: "DELTON",
      state: "MI"
    },
    {
      city: "DELTONA",
      state: "FL"
    },
    {
      city: "DEMA",
      state: "KY"
    },
    {
      city: "DEMAREST",
      state: "NJ"
    },
    {
      city: "DEMING",
      state: "NM"
    },
    {
      city: "DEMING",
      state: "WA"
    },
    {
      city: "DEMOPOLIS",
      state: "AL"
    },
    {
      city: "DEMOREST",
      state: "GA"
    },
    {
      city: "DEMOTTE",
      state: "IN"
    },
    {
      city: "DENAIR",
      state: "CA"
    },
    {
      city: "DENALI NATIONAL PARK",
      state: "AK"
    },
    {
      city: "DENDRON",
      state: "VA"
    },
    {
      city: "DENHAM SPRINGS",
      state: "LA"
    },
    {
      city: "DENISON",
      state: "IA"
    },
    {
      city: "DENISON",
      state: "KS"
    },
    {
      city: "DENISON",
      state: "TX"
    },
    {
      city: "DENMARK",
      state: "IA"
    },
    {
      city: "DENMARK",
      state: "ME"
    },
    {
      city: "DENMARK",
      state: "NY"
    },
    {
      city: "DENMARK",
      state: "SC"
    },
    {
      city: "DENMARK",
      state: "TN"
    },
    {
      city: "DENMARK",
      state: "WI"
    },
    {
      city: "DENNARD",
      state: "AR"
    },
    {
      city: "DENNIS",
      state: "KS"
    },
    {
      city: "DENNIS",
      state: "MA"
    },
    {
      city: "DENNIS",
      state: "MS"
    },
    {
      city: "DENNIS",
      state: "TX"
    },
    {
      city: "DENNIS PORT",
      state: "MA"
    },
    {
      city: "DENNISON",
      state: "IL"
    },
    {
      city: "DENNISON",
      state: "MN"
    },
    {
      city: "DENNISON",
      state: "OH"
    },
    {
      city: "DENNISTON",
      state: "KY"
    },
    {
      city: "DENNISVILLE",
      state: "NJ"
    },
    {
      city: "DENNYSVILLE",
      state: "ME"
    },
    {
      city: "DENT",
      state: "MN"
    },
    {
      city: "DENTON",
      state: "GA"
    },
    {
      city: "DENTON",
      state: "KS"
    },
    {
      city: "DENTON",
      state: "MD"
    },
    {
      city: "DENTON",
      state: "MT"
    },
    {
      city: "DENTON",
      state: "NC"
    },
    {
      city: "DENTON",
      state: "NE"
    },
    {
      city: "DENTON",
      state: "TX"
    },
    {
      city: "DENVER",
      state: "CO"
    },
    {
      city: "DENVER",
      state: "IA"
    },
    {
      city: "DENVER",
      state: "IN"
    },
    {
      city: "DENVER",
      state: "MO"
    },
    {
      city: "DENVER",
      state: "NC"
    },
    {
      city: "DENVER",
      state: "NY"
    },
    {
      city: "DENVER",
      state: "PA"
    },
    {
      city: "DENVER CITY",
      state: "TX"
    },
    {
      city: "DENVILLE",
      state: "NJ"
    },
    {
      city: "DEPAUVILLE",
      state: "NY"
    },
    {
      city: "DEPAUW",
      state: "IN"
    },
    {
      city: "DEPEW",
      state: "NY"
    },
    {
      city: "DEPEW",
      state: "OK"
    },
    {
      city: "DEPOE BAY",
      state: "OR"
    },
    {
      city: "DEPORT",
      state: "TX"
    },
    {
      city: "DEPOSIT",
      state: "NY"
    },
    {
      city: "DEPTFORD",
      state: "NJ"
    },
    {
      city: "DEPUE",
      state: "IL"
    },
    {
      city: "DEPUTY",
      state: "IN"
    },
    {
      city: "DEQUINCY",
      state: "LA"
    },
    {
      city: "DERBY",
      state: "CT"
    },
    {
      city: "DERBY",
      state: "IA"
    },
    {
      city: "DERBY",
      state: "IN"
    },
    {
      city: "DERBY",
      state: "KS"
    },
    {
      city: "DERBY",
      state: "NY"
    },
    {
      city: "DERBY",
      state: "VT"
    },
    {
      city: "DERBY LINE",
      state: "VT"
    },
    {
      city: "DERIDDER",
      state: "LA"
    },
    {
      city: "DERMA",
      state: "MS"
    },
    {
      city: "DERMOTT",
      state: "AR"
    },
    {
      city: "DERRICK CITY",
      state: "PA"
    },
    {
      city: "DERRY",
      state: "NH"
    },
    {
      city: "DERRY",
      state: "NM"
    },
    {
      city: "DERRY",
      state: "PA"
    },
    {
      city: "DERWENT",
      state: "OH"
    },
    {
      city: "DERWOOD",
      state: "MD"
    },
    {
      city: "DES ALLEMANDS",
      state: "LA"
    },
    {
      city: "DES ARC",
      state: "AR"
    },
    {
      city: "DES ARC",
      state: "MO"
    },
    {
      city: "DES LACS",
      state: "ND"
    },
    {
      city: "DES MOINES",
      state: "IA"
    },
    {
      city: "DES MOINES",
      state: "NM"
    },
    {
      city: "DES PLAINES",
      state: "IL"
    },
    {
      city: "DESCANSO",
      state: "CA"
    },
    {
      city: "DESDEMONA",
      state: "TX"
    },
    {
      city: "DESERT CENTER",
      state: "CA"
    },
    {
      city: "DESERT HOT SPRINGS",
      state: "CA"
    },
    {
      city: "DESHA",
      state: "AR"
    },
    {
      city: "DESHLER",
      state: "NE"
    },
    {
      city: "DESHLER",
      state: "OH"
    },
    {
      city: "DESMET",
      state: "ID"
    },
    {
      city: "DESOTO",
      state: "TX"
    },
    {
      city: "DESTIN",
      state: "FL"
    },
    {
      city: "DESTREHAN",
      state: "LA"
    },
    {
      city: "DETROIT",
      state: "AL"
    },
    {
      city: "DETROIT",
      state: "ME"
    },
    {
      city: "DETROIT",
      state: "MI"
    },
    {
      city: "DETROIT",
      state: "OR"
    },
    {
      city: "DETROIT",
      state: "TX"
    },
    {
      city: "DETROIT LAKES",
      state: "MN"
    },
    {
      city: "DEVAULT",
      state: "PA"
    },
    {
      city: "DEVENS",
      state: "MA"
    },
    {
      city: "DEVERS",
      state: "TX"
    },
    {
      city: "DEVILLE",
      state: "LA"
    },
    {
      city: "DEVILS LAKE",
      state: "ND"
    },
    {
      city: "DEVILS TOWER",
      state: "WY"
    },
    {
      city: "DEVINE",
      state: "TX"
    },
    {
      city: "DEVOL",
      state: "OK"
    },
    {
      city: "DEVON",
      state: "PA"
    },
    {
      city: "DEWAR",
      state: "IA"
    },
    {
      city: "DEWAR",
      state: "OK"
    },
    {
      city: "DEWART",
      state: "PA"
    },
    {
      city: "DEWEESE",
      state: "NE"
    },
    {
      city: "DEWEY",
      state: "AZ"
    },
    {
      city: "DEWEY",
      state: "IL"
    },
    {
      city: "DEWEY",
      state: "OK"
    },
    {
      city: "DEWEYVILLE",
      state: "TX"
    },
    {
      city: "DEWEYVILLE",
      state: "UT"
    },
    {
      city: "DEWITT",
      state: "IL"
    },
    {
      city: "DEWITT",
      state: "MI"
    },
    {
      city: "DEWITT",
      state: "VA"
    },
    {
      city: "DEWITTVILLE",
      state: "NY"
    },
    {
      city: "DEWY ROSE",
      state: "GA"
    },
    {
      city: "DEXTER",
      state: "GA"
    },
    {
      city: "DEXTER",
      state: "IA"
    },
    {
      city: "DEXTER",
      state: "KS"
    },
    {
      city: "DEXTER",
      state: "KY"
    },
    {
      city: "DEXTER",
      state: "ME"
    },
    {
      city: "DEXTER",
      state: "MI"
    },
    {
      city: "DEXTER",
      state: "MN"
    },
    {
      city: "DEXTER",
      state: "MO"
    },
    {
      city: "DEXTER",
      state: "NM"
    },
    {
      city: "DEXTER",
      state: "NY"
    },
    {
      city: "DEXTER",
      state: "OR"
    },
    {
      city: "DEXTER CITY",
      state: "OH"
    },
    {
      city: "DHS",
      state: "VA"
    },
    {
      city: "DIABLO",
      state: "CA"
    },
    {
      city: "DIAGONAL",
      state: "IA"
    },
    {
      city: "DIAMOND",
      state: "MO"
    },
    {
      city: "DIAMOND",
      state: "OH"
    },
    {
      city: "DIAMOND",
      state: "OR"
    },
    {
      city: "DIAMOND BAR",
      state: "CA"
    },
    {
      city: "DIAMOND CITY",
      state: "AR"
    },
    {
      city: "DIAMOND POINT",
      state: "NY"
    },
    {
      city: "DIAMOND SPRINGS",
      state: "CA"
    },
    {
      city: "DIAMONDHEAD",
      state: "MS"
    },
    {
      city: "DIAMONDVILLE",
      state: "WY"
    },
    {
      city: "DIANA",
      state: "TX"
    },
    {
      city: "DIANA",
      state: "WV"
    },
    {
      city: "DIAZ",
      state: "AR"
    },
    {
      city: "DIBBLE",
      state: "OK"
    },
    {
      city: "DIBERVILLE",
      state: "MS"
    },
    {
      city: "DIBOLL",
      state: "TX"
    },
    {
      city: "DICKENS",
      state: "IA"
    },
    {
      city: "DICKENS",
      state: "TX"
    },
    {
      city: "DICKERSON",
      state: "MD"
    },
    {
      city: "DICKERSON RUN",
      state: "PA"
    },
    {
      city: "DICKEY",
      state: "ND"
    },
    {
      city: "DICKEYVILLE",
      state: "WI"
    },
    {
      city: "DICKINSON",
      state: "AL"
    },
    {
      city: "DICKINSON",
      state: "ND"
    },
    {
      city: "DICKINSON",
      state: "TX"
    },
    {
      city: "DICKINSON CENTER",
      state: "NY"
    },
    {
      city: "DICKSON",
      state: "TN"
    },
    {
      city: "DIERKS",
      state: "AR"
    },
    {
      city: "DIETERICH",
      state: "IL"
    },
    {
      city: "DIETRICH",
      state: "ID"
    },
    {
      city: "DIGGINS",
      state: "MO"
    },
    {
      city: "DIGGS",
      state: "VA"
    },
    {
      city: "DIGHTON",
      state: "KS"
    },
    {
      city: "DIGHTON",
      state: "MA"
    },
    {
      city: "DIKE",
      state: "IA"
    },
    {
      city: "DIKE",
      state: "TX"
    },
    {
      city: "DILL CITY",
      state: "OK"
    },
    {
      city: "DILLARD",
      state: "GA"
    },
    {
      city: "DILLARD",
      state: "OR"
    },
    {
      city: "DILLER",
      state: "NE"
    },
    {
      city: "DILLEY",
      state: "TX"
    },
    {
      city: "DILLINER",
      state: "PA"
    },
    {
      city: "DILLINGHAM",
      state: "AK"
    },
    {
      city: "DILLON",
      state: "CO"
    },
    {
      city: "DILLON",
      state: "MT"
    },
    {
      city: "DILLON",
      state: "SC"
    },
    {
      city: "DILLON BEACH",
      state: "CA"
    },
    {
      city: "DILLONVALE",
      state: "OH"
    },
    {
      city: "DILLSBORO",
      state: "IN"
    },
    {
      city: "DILLSBORO",
      state: "NC"
    },
    {
      city: "DILLSBURG",
      state: "PA"
    },
    {
      city: "DILLTOWN",
      state: "PA"
    },
    {
      city: "DILLWYN",
      state: "VA"
    },
    {
      city: "DILWORTH",
      state: "MN"
    },
    {
      city: "DIME BOX",
      state: "TX"
    },
    {
      city: "DIMMITT",
      state: "TX"
    },
    {
      city: "DIMOCK",
      state: "PA"
    },
    {
      city: "DIMOCK",
      state: "SD"
    },
    {
      city: "DIMONDALE",
      state: "MI"
    },
    {
      city: "DINERO",
      state: "TX"
    },
    {
      city: "DINGESS",
      state: "WV"
    },
    {
      city: "DINGMANS FERRY",
      state: "PA"
    },
    {
      city: "DINOSAUR",
      state: "CO"
    },
    {
      city: "DINUBA",
      state: "CA"
    },
    {
      city: "DINWIDDIE",
      state: "VA"
    },
    {
      city: "DISCOVERY BAY",
      state: "CA"
    },
    {
      city: "DISNEY",
      state: "OK"
    },
    {
      city: "DISPUTANTA",
      state: "VA"
    },
    {
      city: "DISTANT",
      state: "PA"
    },
    {
      city: "DISTRICT HEIGHTS",
      state: "MD"
    },
    {
      city: "DITTMER",
      state: "MO"
    },
    {
      city: "DIVERNON",
      state: "IL"
    },
    {
      city: "DIVIDE",
      state: "CO"
    },
    {
      city: "DIVIDE",
      state: "MT"
    },
    {
      city: "DIVIDING CREEK",
      state: "NJ"
    },
    {
      city: "DIX",
      state: "IL"
    },
    {
      city: "DIX",
      state: "NE"
    },
    {
      city: "DIXFIELD",
      state: "ME"
    },
    {
      city: "DIXIE",
      state: "GA"
    },
    {
      city: "DIXIE",
      state: "WA"
    },
    {
      city: "DIXIE",
      state: "WV"
    },
    {
      city: "DIXMONT",
      state: "ME"
    },
    {
      city: "DIXON",
      state: "CA"
    },
    {
      city: "DIXON",
      state: "IA"
    },
    {
      city: "DIXON",
      state: "IL"
    },
    {
      city: "DIXON",
      state: "KY"
    },
    {
      city: "DIXON",
      state: "MO"
    },
    {
      city: "DIXON",
      state: "MT"
    },
    {
      city: "DIXON",
      state: "NE"
    },
    {
      city: "DIXON",
      state: "NM"
    },
    {
      city: "DIXON",
      state: "WY"
    },
    {
      city: "DIXON SPRINGS",
      state: "TN"
    },
    {
      city: "DIXONS MILLS",
      state: "AL"
    },
    {
      city: "DIXONVILLE",
      state: "PA"
    },
    {
      city: "DOBBIN",
      state: "TX"
    },
    {
      city: "DOBBINS",
      state: "CA"
    },
    {
      city: "DOBBS FERRY",
      state: "NY"
    },
    {
      city: "DOBSON",
      state: "NC"
    },
    {
      city: "DOCENA",
      state: "AL"
    },
    {
      city: "DODD CITY",
      state: "TX"
    },
    {
      city: "DODDRIDGE",
      state: "AR"
    },
    {
      city: "DODGE",
      state: "ND"
    },
    {
      city: "DODGE",
      state: "NE"
    },
    {
      city: "DODGE",
      state: "TX"
    },
    {
      city: "DODGE",
      state: "WI"
    },
    {
      city: "DODGE CENTER",
      state: "MN"
    },
    {
      city: "DODGE CITY",
      state: "KS"
    },
    {
      city: "DODGERTOWN",
      state: "CA"
    },
    {
      city: "DODGEVILLE",
      state: "MI"
    },
    {
      city: "DODGEVILLE",
      state: "WI"
    },
    {
      city: "DODSON",
      state: "LA"
    },
    {
      city: "DODSON",
      state: "MT"
    },
    {
      city: "DODSON",
      state: "TX"
    },
    {
      city: "DOE HILL",
      state: "VA"
    },
    {
      city: "DOE RUN",
      state: "MO"
    },
    {
      city: "DOERUN",
      state: "GA"
    },
    {
      city: "DOLA",
      state: "OH"
    },
    {
      city: "DOLAN SPRINGS",
      state: "AZ"
    },
    {
      city: "DOLAND",
      state: "SD"
    },
    {
      city: "DOLGEVILLE",
      state: "NY"
    },
    {
      city: "DOLLAR BAY",
      state: "MI"
    },
    {
      city: "DOLLIVER",
      state: "IA"
    },
    {
      city: "DOLOMITE",
      state: "AL"
    },
    {
      city: "DOLORES",
      state: "CO"
    },
    {
      city: "DOLPH",
      state: "AR"
    },
    {
      city: "DOLPHIN",
      state: "VA"
    },
    {
      city: "DOLTON",
      state: "IL"
    },
    {
      city: "DONA ANA",
      state: "NM"
    },
    {
      city: "DONAHUE",
      state: "IA"
    },
    {
      city: "DONALD",
      state: "OR"
    },
    {
      city: "DONALDS",
      state: "SC"
    },
    {
      city: "DONALDSON",
      state: "AR"
    },
    {
      city: "DONALDSON",
      state: "IN"
    },
    {
      city: "DONALDSON",
      state: "MN"
    },
    {
      city: "DONALDSONVILLE",
      state: "LA"
    },
    {
      city: "DONALSONVILLE",
      state: "GA"
    },
    {
      city: "DONEGAL",
      state: "PA"
    },
    {
      city: "DONGOLA",
      state: "IL"
    },
    {
      city: "DONIE",
      state: "TX"
    },
    {
      city: "DONIPHAN",
      state: "MO"
    },
    {
      city: "DONIPHAN",
      state: "NE"
    },
    {
      city: "DONNA",
      state: "TX"
    },
    {
      city: "DONNELLSON",
      state: "IA"
    },
    {
      city: "DONNELLSON",
      state: "IL"
    },
    {
      city: "DONNELLY",
      state: "ID"
    },
    {
      city: "DONNELLY",
      state: "MN"
    },
    {
      city: "DONNELSVILLE",
      state: "OH"
    },
    {
      city: "DONNER",
      state: "LA"
    },
    {
      city: "DONNYBROOK",
      state: "ND"
    },
    {
      city: "DONORA",
      state: "PA"
    },
    {
      city: "DONOVAN",
      state: "IL"
    },
    {
      city: "DOON",
      state: "IA"
    },
    {
      city: "DORA",
      state: "AL"
    },
    {
      city: "DORA",
      state: "MO"
    },
    {
      city: "DORA",
      state: "NM"
    },
    {
      city: "DORAN",
      state: "VA"
    },
    {
      city: "DORCHESTER",
      state: "IA"
    },
    {
      city: "DORCHESTER",
      state: "MA"
    },
    {
      city: "DORCHESTER",
      state: "NE"
    },
    {
      city: "DORCHESTER",
      state: "NJ"
    },
    {
      city: "DORCHESTER",
      state: "SC"
    },
    {
      city: "DORCHESTER",
      state: "WI"
    },
    {
      city: "DORCHESTER CENTER",
      state: "MA"
    },
    {
      city: "DORENA",
      state: "OR"
    },
    {
      city: "DORMANSVILLE",
      state: "NY"
    },
    {
      city: "DORNSIFE",
      state: "PA"
    },
    {
      city: "DOROTHY",
      state: "NJ"
    },
    {
      city: "DORR",
      state: "MI"
    },
    {
      city: "DORRANCE",
      state: "KS"
    },
    {
      city: "DORRIS",
      state: "CA"
    },
    {
      city: "DORSET",
      state: "OH"
    },
    {
      city: "DORSET",
      state: "VT"
    },
    {
      city: "DORSEY",
      state: "IL"
    },
    {
      city: "DORTON",
      state: "KY"
    },
    {
      city: "DOS PALOS",
      state: "CA"
    },
    {
      city: "DOS RIOS",
      state: "CA"
    },
    {
      city: "DOSS",
      state: "TX"
    },
    {
      city: "DOSWELL",
      state: "VA"
    },
    {
      city: "DOTHAN",
      state: "AL"
    },
    {
      city: "DOTHAN",
      state: "WV"
    },
    {
      city: "DOTY",
      state: "WA"
    },
    {
      city: "DOUBLE SPRINGS",
      state: "AL"
    },
    {
      city: "DOUCETTE",
      state: "TX"
    },
    {
      city: "DOUDS",
      state: "IA"
    },
    {
      city: "DOUGHERTY",
      state: "IA"
    },
    {
      city: "DOUGHERTY",
      state: "OK"
    },
    {
      city: "DOUGHERTY",
      state: "TX"
    },
    {
      city: "DOUGLAS",
      state: "AK"
    },
    {
      city: "DOUGLAS",
      state: "AL"
    },
    {
      city: "DOUGLAS",
      state: "AZ"
    },
    {
      city: "DOUGLAS",
      state: "GA"
    },
    {
      city: "DOUGLAS",
      state: "MA"
    },
    {
      city: "DOUGLAS",
      state: "MI"
    },
    {
      city: "DOUGLAS",
      state: "ND"
    },
    {
      city: "DOUGLAS",
      state: "NE"
    },
    {
      city: "DOUGLAS",
      state: "OK"
    },
    {
      city: "DOUGLAS",
      state: "WY"
    },
    {
      city: "DOUGLAS CITY",
      state: "CA"
    },
    {
      city: "DOUGLAS FLAT",
      state: "CA"
    },
    {
      city: "DOUGLASS",
      state: "KS"
    },
    {
      city: "DOUGLASS",
      state: "TX"
    },
    {
      city: "DOUGLASSVILLE",
      state: "PA"
    },
    {
      city: "DOUGLASSVILLE",
      state: "TX"
    },
    {
      city: "DOUGLASVILLE",
      state: "GA"
    },
    {
      city: "DOUSMAN",
      state: "WI"
    },
    {
      city: "DOVE CREEK",
      state: "CO"
    },
    {
      city: "DOVER",
      state: "AR"
    },
    {
      city: "DOVER",
      state: "DE"
    },
    {
      city: "DOVER",
      state: "FL"
    },
    {
      city: "DOVER",
      state: "GA"
    },
    {
      city: "DOVER",
      state: "ID"
    },
    {
      city: "DOVER",
      state: "IL"
    },
    {
      city: "DOVER",
      state: "KS"
    },
    {
      city: "DOVER",
      state: "KY"
    },
    {
      city: "DOVER",
      state: "MA"
    },
    {
      city: "DOVER",
      state: "MN"
    },
    {
      city: "DOVER",
      state: "MO"
    },
    {
      city: "DOVER",
      state: "NC"
    },
    {
      city: "DOVER",
      state: "NH"
    },
    {
      city: "DOVER",
      state: "NJ"
    },
    {
      city: "DOVER",
      state: "OH"
    },
    {
      city: "DOVER",
      state: "OK"
    },
    {
      city: "DOVER",
      state: "PA"
    },
    {
      city: "DOVER",
      state: "TN"
    },
    {
      city: "DOVER AFB",
      state: "DE"
    },
    {
      city: "DOVER FOXCROFT",
      state: "ME"
    },
    {
      city: "DOVER PLAINS",
      state: "NY"
    },
    {
      city: "DOVRAY",
      state: "MN"
    },
    {
      city: "DOW",
      state: "IL"
    },
    {
      city: "DOW CITY",
      state: "IA"
    },
    {
      city: "DOWAGIAC",
      state: "MI"
    },
    {
      city: "DOWELL",
      state: "IL"
    },
    {
      city: "DOWELL",
      state: "MD"
    },
    {
      city: "DOWELLTOWN",
      state: "TN"
    },
    {
      city: "DOWLING",
      state: "MI"
    },
    {
      city: "DOWNERS GROVE",
      state: "IL"
    },
    {
      city: "DOWNEY",
      state: "CA"
    },
    {
      city: "DOWNEY",
      state: "ID"
    },
    {
      city: "DOWNIEVILLE",
      state: "CA"
    },
    {
      city: "DOWNING",
      state: "MO"
    },
    {
      city: "DOWNING",
      state: "WI"
    },
    {
      city: "DOWNINGTOWN",
      state: "PA"
    },
    {
      city: "DOWNS",
      state: "IL"
    },
    {
      city: "DOWNS",
      state: "KS"
    },
    {
      city: "DOWNSVILLE",
      state: "LA"
    },
    {
      city: "DOWNSVILLE",
      state: "NY"
    },
    {
      city: "DOWNSVILLE",
      state: "WI"
    },
    {
      city: "DOWS",
      state: "IA"
    },
    {
      city: "DOYLE",
      state: "CA"
    },
    {
      city: "DOYLE",
      state: "TN"
    },
    {
      city: "DOYLESBURG",
      state: "PA"
    },
    {
      city: "DOYLESTOWN",
      state: "OH"
    },
    {
      city: "DOYLESTOWN",
      state: "PA"
    },
    {
      city: "DOYLESTOWN",
      state: "WI"
    },
    {
      city: "DOYLINE",
      state: "LA"
    },
    {
      city: "DOZIER",
      state: "AL"
    },
    {
      city: "DRACUT",
      state: "MA"
    },
    {
      city: "DRAGOON",
      state: "AZ"
    },
    {
      city: "DRAIN",
      state: "OR"
    },
    {
      city: "DRAKE",
      state: "CO"
    },
    {
      city: "DRAKE",
      state: "ND"
    },
    {
      city: "DRAKES BRANCH",
      state: "VA"
    },
    {
      city: "DRAKESBORO",
      state: "KY"
    },
    {
      city: "DRAKESVILLE",
      state: "IA"
    },
    {
      city: "DRAPER",
      state: "SD"
    },
    {
      city: "DRAPER",
      state: "UT"
    },
    {
      city: "DRAPER",
      state: "VA"
    },
    {
      city: "DRASCO",
      state: "AR"
    },
    {
      city: "DRAVOSBURG",
      state: "PA"
    },
    {
      city: "DRAYDEN",
      state: "MD"
    },
    {
      city: "DRAYTON",
      state: "ND"
    },
    {
      city: "DRAYTON",
      state: "SC"
    },
    {
      city: "DRAYTON PLAINS",
      state: "MI"
    },
    {
      city: "DRENNEN",
      state: "WV"
    },
    {
      city: "DRESDEN",
      state: "KS"
    },
    {
      city: "DRESDEN",
      state: "ME"
    },
    {
      city: "DRESDEN",
      state: "NY"
    },
    {
      city: "DRESDEN",
      state: "OH"
    },
    {
      city: "DRESDEN",
      state: "TN"
    },
    {
      city: "DRESHER",
      state: "PA"
    },
    {
      city: "DRESSER",
      state: "WI"
    },
    {
      city: "DREW",
      state: "MS"
    },
    {
      city: "DREWRYVILLE",
      state: "VA"
    },
    {
      city: "DREWSEY",
      state: "OR"
    },
    {
      city: "DREWSVILLE",
      state: "NH"
    },
    {
      city: "DREXEL",
      state: "MO"
    },
    {
      city: "DREXEL",
      state: "NC"
    },
    {
      city: "DREXEL HILL",
      state: "PA"
    },
    {
      city: "DRIFT",
      state: "KY"
    },
    {
      city: "DRIFTING",
      state: "PA"
    },
    {
      city: "DRIFTON",
      state: "PA"
    },
    {
      city: "DRIFTWOOD",
      state: "PA"
    },
    {
      city: "DRIFTWOOD",
      state: "TX"
    },
    {
      city: "DRIGGS",
      state: "ID"
    },
    {
      city: "DRIPPING SPRINGS",
      state: "TX"
    },
    {
      city: "DRISCOLL",
      state: "ND"
    },
    {
      city: "DRISCOLL",
      state: "TX"
    },
    {
      city: "DRUMMOND",
      state: "MT"
    },
    {
      city: "DRUMMOND",
      state: "OK"
    },
    {
      city: "DRUMMOND",
      state: "WI"
    },
    {
      city: "DRUMMOND ISLAND",
      state: "MI"
    },
    {
      city: "DRUMMONDS",
      state: "TN"
    },
    {
      city: "DRUMORE",
      state: "PA"
    },
    {
      city: "DRUMRIGHT",
      state: "OK"
    },
    {
      city: "DRUMS",
      state: "PA"
    },
    {
      city: "DRURY",
      state: "MA"
    },
    {
      city: "DRURY",
      state: "MO"
    },
    {
      city: "DRY BRANCH",
      state: "GA"
    },
    {
      city: "DRY CREEK",
      state: "LA"
    },
    {
      city: "DRY CREEK",
      state: "WV"
    },
    {
      city: "DRY FORK",
      state: "VA"
    },
    {
      city: "DRY PRONG",
      state: "LA"
    },
    {
      city: "DRY RIDGE",
      state: "KY"
    },
    {
      city: "DRY RUN",
      state: "PA"
    },
    {
      city: "DRYDEN",
      state: "ME"
    },
    {
      city: "DRYDEN",
      state: "MI"
    },
    {
      city: "DRYDEN",
      state: "NY"
    },
    {
      city: "DRYDEN",
      state: "TX"
    },
    {
      city: "DRYDEN",
      state: "VA"
    },
    {
      city: "DRYDEN",
      state: "WA"
    },
    {
      city: "DRYFORK",
      state: "WV"
    },
    {
      city: "DRYTOWN",
      state: "CA"
    },
    {
      city: "DU BOIS",
      state: "IL"
    },
    {
      city: "DU BOIS",
      state: "NE"
    },
    {
      city: "DU BOIS",
      state: "PA"
    },
    {
      city: "DU PONT",
      state: "GA"
    },
    {
      city: "DU QUOIN",
      state: "IL"
    },
    {
      city: "DUANESBURG",
      state: "NY"
    },
    {
      city: "DUARTE",
      state: "CA"
    },
    {
      city: "DUBACH",
      state: "LA"
    },
    {
      city: "DUBBERLY",
      state: "LA"
    },
    {
      city: "DUBLIN",
      state: "CA"
    },
    {
      city: "DUBLIN",
      state: "GA"
    },
    {
      city: "DUBLIN",
      state: "IN"
    },
    {
      city: "DUBLIN",
      state: "MS"
    },
    {
      city: "DUBLIN",
      state: "NC"
    },
    {
      city: "DUBLIN",
      state: "NH"
    },
    {
      city: "DUBLIN",
      state: "OH"
    },
    {
      city: "DUBLIN",
      state: "PA"
    },
    {
      city: "DUBLIN",
      state: "TX"
    },
    {
      city: "DUBLIN",
      state: "VA"
    },
    {
      city: "DUBOIS",
      state: "ID"
    },
    {
      city: "DUBOIS",
      state: "IN"
    },
    {
      city: "DUBOIS",
      state: "WY"
    },
    {
      city: "DUBUQUE",
      state: "IA"
    },
    {
      city: "DUCHESNE",
      state: "UT"
    },
    {
      city: "DUCK",
      state: "WV"
    },
    {
      city: "DUCK CREEK VILLAGE",
      state: "UT"
    },
    {
      city: "DUCK HILL",
      state: "MS"
    },
    {
      city: "DUCK RIVER",
      state: "TN"
    },
    {
      city: "DUCKTOWN",
      state: "TN"
    },
    {
      city: "DUCKWATER",
      state: "NV"
    },
    {
      city: "DUCOR",
      state: "CA"
    },
    {
      city: "DUDLEY",
      state: "GA"
    },
    {
      city: "DUDLEY",
      state: "MA"
    },
    {
      city: "DUDLEY",
      state: "MO"
    },
    {
      city: "DUDLEY",
      state: "NC"
    },
    {
      city: "DUDLEY",
      state: "PA"
    },
    {
      city: "DUE WEST",
      state: "SC"
    },
    {
      city: "DUENWEG",
      state: "MO"
    },
    {
      city: "DUFF",
      state: "TN"
    },
    {
      city: "DUFFIELD",
      state: "VA"
    },
    {
      city: "DUFUR",
      state: "OR"
    },
    {
      city: "DUGGER",
      state: "IN"
    },
    {
      city: "DUGSPUR",
      state: "VA"
    },
    {
      city: "DUGWAY",
      state: "UT"
    },
    {
      city: "DUKE",
      state: "MO"
    },
    {
      city: "DUKE",
      state: "OK"
    },
    {
      city: "DUKE CENTER",
      state: "PA"
    },
    {
      city: "DUKEDOM",
      state: "TN"
    },
    {
      city: "DULAC",
      state: "LA"
    },
    {
      city: "DULCE",
      state: "NM"
    },
    {
      city: "DULLES",
      state: "VA"
    },
    {
      city: "DULUTH",
      state: "GA"
    },
    {
      city: "DULUTH",
      state: "MN"
    },
    {
      city: "DULZURA",
      state: "CA"
    },
    {
      city: "DUMAS",
      state: "AR"
    },
    {
      city: "DUMAS",
      state: "MS"
    },
    {
      city: "DUMAS",
      state: "TX"
    },
    {
      city: "DUMFRIES",
      state: "VA"
    },
    {
      city: "DUMONT",
      state: "CO"
    },
    {
      city: "DUMONT",
      state: "IA"
    },
    {
      city: "DUMONT",
      state: "MN"
    },
    {
      city: "DUMONT",
      state: "NJ"
    },
    {
      city: "DUNBAR",
      state: "KY"
    },
    {
      city: "DUNBAR",
      state: "NE"
    },
    {
      city: "DUNBAR",
      state: "PA"
    },
    {
      city: "DUNBAR",
      state: "WI"
    },
    {
      city: "DUNBAR",
      state: "WV"
    },
    {
      city: "DUNBARTON",
      state: "NH"
    },
    {
      city: "DUNBRIDGE",
      state: "OH"
    },
    {
      city: "DUNCAN",
      state: "AZ"
    },
    {
      city: "DUNCAN",
      state: "MS"
    },
    {
      city: "DUNCAN",
      state: "NE"
    },
    {
      city: "DUNCAN",
      state: "OK"
    },
    {
      city: "DUNCAN",
      state: "SC"
    },
    {
      city: "DUNCAN FALLS",
      state: "OH"
    },
    {
      city: "DUNCANNON",
      state: "PA"
    },
    {
      city: "DUNCANS MILLS",
      state: "CA"
    },
    {
      city: "DUNCANSVILLE",
      state: "PA"
    },
    {
      city: "DUNCANVILLE",
      state: "AL"
    },
    {
      city: "DUNCANVILLE",
      state: "TX"
    },
    {
      city: "DUNCOMBE",
      state: "IA"
    },
    {
      city: "DUNDALK",
      state: "MD"
    },
    {
      city: "DUNDAS",
      state: "IL"
    },
    {
      city: "DUNDAS",
      state: "MN"
    },
    {
      city: "DUNDAS",
      state: "VA"
    },
    {
      city: "DUNDEE",
      state: "FL"
    },
    {
      city: "DUNDEE",
      state: "IA"
    },
    {
      city: "DUNDEE",
      state: "IL"
    },
    {
      city: "DUNDEE",
      state: "KY"
    },
    {
      city: "DUNDEE",
      state: "MI"
    },
    {
      city: "DUNDEE",
      state: "MS"
    },
    {
      city: "DUNDEE",
      state: "NY"
    },
    {
      city: "DUNDEE",
      state: "OH"
    },
    {
      city: "DUNDEE",
      state: "OR"
    },
    {
      city: "DUNEDIN",
      state: "FL"
    },
    {
      city: "DUNELLEN",
      state: "NJ"
    },
    {
      city: "DUNFERMLINE",
      state: "IL"
    },
    {
      city: "DUNGANNON",
      state: "VA"
    },
    {
      city: "DUNKERTON",
      state: "IA"
    },
    {
      city: "DUNKIRK",
      state: "IN"
    },
    {
      city: "DUNKIRK",
      state: "MD"
    },
    {
      city: "DUNKIRK",
      state: "NY"
    },
    {
      city: "DUNKIRK",
      state: "OH"
    },
    {
      city: "DUNLAP",
      state: "CA"
    },
    {
      city: "DUNLAP",
      state: "IA"
    },
    {
      city: "DUNLAP",
      state: "IL"
    },
    {
      city: "DUNLAP",
      state: "TN"
    },
    {
      city: "DUNLEVY",
      state: "PA"
    },
    {
      city: "DUNLO",
      state: "PA"
    },
    {
      city: "DUNLOW",
      state: "WV"
    },
    {
      city: "DUNMOR",
      state: "KY"
    },
    {
      city: "DUNMORE",
      state: "WV"
    },
    {
      city: "DUNN",
      state: "NC"
    },
    {
      city: "DUNN CENTER",
      state: "ND"
    },
    {
      city: "DUNN LORING",
      state: "VA"
    },
    {
      city: "DUNNEGAN",
      state: "MO"
    },
    {
      city: "DUNNELL",
      state: "MN"
    },
    {
      city: "DUNNELLON",
      state: "FL"
    },
    {
      city: "DUNNIGAN",
      state: "CA"
    },
    {
      city: "DUNNING",
      state: "NE"
    },
    {
      city: "DUNNSVILLE",
      state: "VA"
    },
    {
      city: "DUNNVILLE",
      state: "KY"
    },
    {
      city: "DUNREITH",
      state: "IN"
    },
    {
      city: "DUNSEITH",
      state: "ND"
    },
    {
      city: "DUNSMUIR",
      state: "CA"
    },
    {
      city: "DUNSTABLE",
      state: "MA"
    },
    {
      city: "DUPO",
      state: "IL"
    },
    {
      city: "DUPONT",
      state: "CO"
    },
    {
      city: "DUPONT",
      state: "IN"
    },
    {
      city: "DUPONT",
      state: "LA"
    },
    {
      city: "DUPONT",
      state: "OH"
    },
    {
      city: "DUPONT",
      state: "WA"
    },
    {
      city: "DUPREE",
      state: "SD"
    },
    {
      city: "DUPUYER",
      state: "MT"
    },
    {
      city: "DUQUESNE",
      state: "PA"
    },
    {
      city: "DURAND",
      state: "IL"
    },
    {
      city: "DURAND",
      state: "MI"
    },
    {
      city: "DURAND",
      state: "WI"
    },
    {
      city: "DURANGO",
      state: "CO"
    },
    {
      city: "DURANGO",
      state: "IA"
    },
    {
      city: "DURANT",
      state: "FL"
    },
    {
      city: "DURANT",
      state: "IA"
    },
    {
      city: "DURANT",
      state: "MS"
    },
    {
      city: "DURANT",
      state: "OK"
    },
    {
      city: "DURBIN",
      state: "WV"
    },
    {
      city: "DURHAM",
      state: "CA"
    },
    {
      city: "DURHAM",
      state: "CT"
    },
    {
      city: "DURHAM",
      state: "KS"
    },
    {
      city: "DURHAM",
      state: "ME"
    },
    {
      city: "DURHAM",
      state: "MO"
    },
    {
      city: "DURHAM",
      state: "NC"
    },
    {
      city: "DURHAM",
      state: "NH"
    },
    {
      city: "DURHAM",
      state: "NY"
    },
    {
      city: "DURHAM",
      state: "OK"
    },
    {
      city: "DURHAM",
      state: "PA"
    },
    {
      city: "DURHAMVILLE",
      state: "NY"
    },
    {
      city: "DURKEE",
      state: "OR"
    },
    {
      city: "DURYEA",
      state: "PA"
    },
    {
      city: "DUSHORE",
      state: "PA"
    },
    {
      city: "DUSON",
      state: "LA"
    },
    {
      city: "DUSTIN",
      state: "OK"
    },
    {
      city: "DUTCH FLAT",
      state: "CA"
    },
    {
      city: "DUTCH HARBOR",
      state: "AK"
    },
    {
      city: "DUTCH JOHN",
      state: "UT"
    },
    {
      city: "DUTCHTOWN",
      state: "MO"
    },
    {
      city: "DUTTON",
      state: "AL"
    },
    {
      city: "DUTTON",
      state: "MT"
    },
    {
      city: "DUTTON",
      state: "VA"
    },
    {
      city: "DUTZOW",
      state: "MO"
    },
    {
      city: "DUVALL",
      state: "WA"
    },
    {
      city: "DUXBURY",
      state: "MA"
    },
    {
      city: "DWALE",
      state: "KY"
    },
    {
      city: "DWARF",
      state: "KY"
    },
    {
      city: "DWIGHT",
      state: "IL"
    },
    {
      city: "DWIGHT",
      state: "KS"
    },
    {
      city: "DWIGHT",
      state: "NE"
    },
    {
      city: "DYER",
      state: "AR"
    },
    {
      city: "DYER",
      state: "IN"
    },
    {
      city: "DYER",
      state: "NV"
    },
    {
      city: "DYER",
      state: "TN"
    },
    {
      city: "DYERSBURG",
      state: "TN"
    },
    {
      city: "DYERSVILLE",
      state: "IA"
    },
    {
      city: "DYESS",
      state: "AR"
    },
    {
      city: "DYESS AFB",
      state: "TX"
    },
    {
      city: "DYKE",
      state: "VA"
    },
    {
      city: "DYSART",
      state: "IA"
    },
    {
      city: "DYSART",
      state: "PA"
    },
    {
      city: "EADS",
      state: "CO"
    },
    {
      city: "EADS",
      state: "TN"
    },
    {
      city: "EAGAN",
      state: "TN"
    },
    {
      city: "EAGAR",
      state: "AZ"
    },
    {
      city: "EAGARVILLE",
      state: "IL"
    },
    {
      city: "EAGLE",
      state: "AK"
    },
    {
      city: "EAGLE",
      state: "CO"
    },
    {
      city: "EAGLE",
      state: "ID"
    },
    {
      city: "EAGLE",
      state: "MI"
    },
    {
      city: "EAGLE",
      state: "NE"
    },
    {
      city: "EAGLE",
      state: "WI"
    },
    {
      city: "EAGLE BAY",
      state: "NY"
    },
    {
      city: "EAGLE BEND",
      state: "MN"
    },
    {
      city: "EAGLE BRIDGE",
      state: "NY"
    },
    {
      city: "EAGLE BUTTE",
      state: "SD"
    },
    {
      city: "EAGLE CREEK",
      state: "OR"
    },
    {
      city: "EAGLE GROVE",
      state: "IA"
    },
    {
      city: "EAGLE LAKE",
      state: "FL"
    },
    {
      city: "EAGLE LAKE",
      state: "ME"
    },
    {
      city: "EAGLE LAKE",
      state: "MN"
    },
    {
      city: "EAGLE LAKE",
      state: "TX"
    },
    {
      city: "EAGLE MOUNTAIN",
      state: "UT"
    },
    {
      city: "EAGLE NEST",
      state: "NM"
    },
    {
      city: "EAGLE PASS",
      state: "TX"
    },
    {
      city: "EAGLE POINT",
      state: "OR"
    },
    {
      city: "EAGLE RIVER",
      state: "AK"
    },
    {
      city: "EAGLE RIVER",
      state: "WI"
    },
    {
      city: "EAGLE ROCK",
      state: "MO"
    },
    {
      city: "EAGLE ROCK",
      state: "VA"
    },
    {
      city: "EAGLE SPRINGS",
      state: "NC"
    },
    {
      city: "EAGLES MERE",
      state: "PA"
    },
    {
      city: "EAGLETOWN",
      state: "OK"
    },
    {
      city: "EAGLEVILLE",
      state: "MO"
    },
    {
      city: "EAGLEVILLE",
      state: "PA"
    },
    {
      city: "EAGLEVILLE",
      state: "TN"
    },
    {
      city: "EAKLY",
      state: "OK"
    },
    {
      city: "EARL",
      state: "NC"
    },
    {
      city: "EARL PARK",
      state: "IN"
    },
    {
      city: "EARLE",
      state: "AR"
    },
    {
      city: "EARLETON",
      state: "FL"
    },
    {
      city: "EARLEVILLE",
      state: "MD"
    },
    {
      city: "EARLHAM",
      state: "IA"
    },
    {
      city: "EARLIMART",
      state: "CA"
    },
    {
      city: "EARLING",
      state: "IA"
    },
    {
      city: "EARLINGTON",
      state: "KY"
    },
    {
      city: "EARLINGTON",
      state: "PA"
    },
    {
      city: "EARLSBORO",
      state: "OK"
    },
    {
      city: "EARLTON",
      state: "NY"
    },
    {
      city: "EARLVILLE",
      state: "IA"
    },
    {
      city: "EARLVILLE",
      state: "IL"
    },
    {
      city: "EARLVILLE",
      state: "NY"
    },
    {
      city: "EARLVILLE",
      state: "PA"
    },
    {
      city: "EARLY",
      state: "IA"
    },
    {
      city: "EARLY",
      state: "TX"
    },
    {
      city: "EARLY BRANCH",
      state: "SC"
    },
    {
      city: "EARLYSVILLE",
      state: "VA"
    },
    {
      city: "EARP",
      state: "CA"
    },
    {
      city: "EARTH",
      state: "TX"
    },
    {
      city: "EARTH CITY",
      state: "MO"
    },
    {
      city: "EASLEY",
      state: "SC"
    },
    {
      city: "EAST ALTON",
      state: "IL"
    },
    {
      city: "EAST AMHERST",
      state: "NY"
    },
    {
      city: "EAST ANDOVER",
      state: "ME"
    },
    {
      city: "EAST ANDOVER",
      state: "NH"
    },
    {
      city: "EAST ARLINGTON",
      state: "VT"
    },
    {
      city: "EAST AURORA",
      state: "NY"
    },
    {
      city: "EAST BALDWIN",
      state: "ME"
    },
    {
      city: "EAST BANK",
      state: "WV"
    },
    {
      city: "EAST BARRE",
      state: "VT"
    },
    {
      city: "EAST BEND",
      state: "NC"
    },
    {
      city: "EAST BERKSHIRE",
      state: "VT"
    },
    {
      city: "EAST BERLIN",
      state: "CT"
    },
    {
      city: "EAST BERLIN",
      state: "PA"
    },
    {
      city: "EAST BERNARD",
      state: "TX"
    },
    {
      city: "EAST BERNE",
      state: "NY"
    },
    {
      city: "EAST BERNSTADT",
      state: "KY"
    },
    {
      city: "EAST BETHANY",
      state: "NY"
    },
    {
      city: "EAST BLOOMFIELD",
      state: "NY"
    },
    {
      city: "EAST BLUE HILL",
      state: "ME"
    },
    {
      city: "EAST BOOTHBAY",
      state: "ME"
    },
    {
      city: "EAST BOSTON",
      state: "MA"
    },
    {
      city: "EAST BRADY",
      state: "PA"
    },
    {
      city: "EAST BRANCH",
      state: "NY"
    },
    {
      city: "EAST BRIDGEWATER",
      state: "MA"
    },
    {
      city: "EAST BROOKFIELD",
      state: "MA"
    },
    {
      city: "EAST BRUNSWICK",
      state: "NJ"
    },
    {
      city: "EAST BURKE",
      state: "VT"
    },
    {
      city: "EAST BUTLER",
      state: "PA"
    },
    {
      city: "EAST CALAIS",
      state: "VT"
    },
    {
      city: "EAST CANAAN",
      state: "CT"
    },
    {
      city: "EAST CANTON",
      state: "OH"
    },
    {
      city: "EAST CARBON",
      state: "UT"
    },
    {
      city: "EAST CARONDELET",
      state: "IL"
    },
    {
      city: "EAST CHARLESTON",
      state: "VT"
    },
    {
      city: "EAST CHATHAM",
      state: "NY"
    },
    {
      city: "EAST CHICAGO",
      state: "IN"
    },
    {
      city: "EAST CHINA",
      state: "MI"
    },
    {
      city: "EAST CLARIDON",
      state: "OH"
    },
    {
      city: "EAST CONCORD",
      state: "NY"
    },
    {
      city: "EAST CORINTH",
      state: "VT"
    },
    {
      city: "EAST DENNIS",
      state: "MA"
    },
    {
      city: "EAST DERRY",
      state: "NH"
    },
    {
      city: "EAST DIXFIELD",
      state: "ME"
    },
    {
      city: "EAST DORSET",
      state: "VT"
    },
    {
      city: "EAST DOVER",
      state: "VT"
    },
    {
      city: "EAST DUBLIN",
      state: "GA"
    },
    {
      city: "EAST DUBUQUE",
      state: "IL"
    },
    {
      city: "EAST DURHAM",
      state: "NY"
    },
    {
      city: "EAST EARL",
      state: "PA"
    },
    {
      city: "EAST ELLIJAY",
      state: "GA"
    },
    {
      city: "EAST ELLSWORTH",
      state: "WI"
    },
    {
      city: "EAST ELMHURST",
      state: "NY"
    },
    {
      city: "EAST ENTERPRISE",
      state: "IN"
    },
    {
      city: "EAST FAIRFIELD",
      state: "VT"
    },
    {
      city: "EAST FALMOUTH",
      state: "MA"
    },
    {
      city: "EAST FLAT ROCK",
      state: "NC"
    },
    {
      city: "EAST FREEDOM",
      state: "PA"
    },
    {
      city: "EAST FREETOWN",
      state: "MA"
    },
    {
      city: "EAST FULTONHAM",
      state: "OH"
    },
    {
      city: "EAST GALESBURG",
      state: "IL"
    },
    {
      city: "EAST GLACIER PARK",
      state: "MT"
    },
    {
      city: "EAST GLASTONBURY",
      state: "CT"
    },
    {
      city: "EAST GRANBY",
      state: "CT"
    },
    {
      city: "EAST GRAND FORKS",
      state: "MN"
    },
    {
      city: "EAST GREENBUSH",
      state: "NY"
    },
    {
      city: "EAST GREENVILLE",
      state: "PA"
    },
    {
      city: "EAST GREENWICH",
      state: "RI"
    },
    {
      city: "EAST HADDAM",
      state: "CT"
    },
    {
      city: "EAST HAMPSTEAD",
      state: "NH"
    },
    {
      city: "EAST HAMPTON",
      state: "CT"
    },
    {
      city: "EAST HAMPTON",
      state: "NY"
    },
    {
      city: "EAST HANOVER",
      state: "NJ"
    },
    {
      city: "EAST HARDWICK",
      state: "VT"
    },
    {
      city: "EAST HARTFORD",
      state: "CT"
    },
    {
      city: "EAST HARTLAND",
      state: "CT"
    },
    {
      city: "EAST HAVEN",
      state: "VT"
    },
    {
      city: "EAST HELENA",
      state: "MT"
    },
    {
      city: "EAST HICKORY",
      state: "PA"
    },
    {
      city: "EAST IRVINE",
      state: "CA"
    },
    {
      city: "EAST ISLIP",
      state: "NY"
    },
    {
      city: "EAST JEWETT",
      state: "NY"
    },
    {
      city: "EAST JORDAN",
      state: "MI"
    },
    {
      city: "EAST KILLINGLY",
      state: "CT"
    },
    {
      city: "EAST KINGSTON",
      state: "NH"
    },
    {
      city: "EAST LANSING",
      state: "MI"
    },
    {
      city: "EAST LEROY",
      state: "MI"
    },
    {
      city: "EAST LIBERTY",
      state: "OH"
    },
    {
      city: "EAST LIVERMORE",
      state: "ME"
    },
    {
      city: "EAST LIVERPOOL",
      state: "OH"
    },
    {
      city: "EAST LONGMEADOW",
      state: "MA"
    },
    {
      city: "EAST LYME",
      state: "CT"
    },
    {
      city: "EAST LYNN",
      state: "IL"
    },
    {
      city: "EAST LYNN",
      state: "WV"
    },
    {
      city: "EAST LYNNE",
      state: "MO"
    },
    {
      city: "EAST MACHIAS",
      state: "ME"
    },
    {
      city: "EAST MARION",
      state: "NY"
    },
    {
      city: "EAST MC KEESPORT",
      state: "PA"
    },
    {
      city: "EAST MEADOW",
      state: "NY"
    },
    {
      city: "EAST MEREDITH",
      state: "NY"
    },
    {
      city: "EAST MIDDLEBURY",
      state: "VT"
    },
    {
      city: "EAST MILLINOCKET",
      state: "ME"
    },
    {
      city: "EAST MILLSBORO",
      state: "PA"
    },
    {
      city: "EAST MOLINE",
      state: "IL"
    },
    {
      city: "EAST MONTPELIER",
      state: "VT"
    },
    {
      city: "EAST MORICHES",
      state: "NY"
    },
    {
      city: "EAST NASSAU",
      state: "NY"
    },
    {
      city: "EAST NEW MARKET",
      state: "MD"
    },
    {
      city: "EAST NEWPORT",
      state: "ME"
    },
    {
      city: "EAST NORTHPORT",
      state: "NY"
    },
    {
      city: "EAST NORWICH",
      state: "NY"
    },
    {
      city: "EAST OLYMPIA",
      state: "WA"
    },
    {
      city: "EAST ORANGE",
      state: "NJ"
    },
    {
      city: "EAST ORLAND",
      state: "ME"
    },
    {
      city: "EAST ORLEANS",
      state: "MA"
    },
    {
      city: "EAST OTIS",
      state: "MA"
    },
    {
      city: "EAST OTTO",
      state: "NY"
    },
    {
      city: "EAST PALATKA",
      state: "FL"
    },
    {
      city: "EAST PALESTINE",
      state: "OH"
    },
    {
      city: "EAST PARSONSFIELD",
      state: "ME"
    },
    {
      city: "EAST PEMBROKE",
      state: "NY"
    },
    {
      city: "EAST PEORIA",
      state: "IL"
    },
    {
      city: "EAST PETERSBURG",
      state: "PA"
    },
    {
      city: "EAST PITTSBURGH",
      state: "PA"
    },
    {
      city: "EAST POINT",
      state: "KY"
    },
    {
      city: "EAST POLAND",
      state: "ME"
    },
    {
      city: "EAST POULTNEY",
      state: "VT"
    },
    {
      city: "EAST PRAIRIE",
      state: "MO"
    },
    {
      city: "EAST PROSPECT",
      state: "PA"
    },
    {
      city: "EAST PROVIDENCE",
      state: "RI"
    },
    {
      city: "EAST QUOGUE",
      state: "NY"
    },
    {
      city: "EAST RANDOLPH",
      state: "NY"
    },
    {
      city: "EAST RANDOLPH",
      state: "VT"
    },
    {
      city: "EAST ROCHESTER",
      state: "NY"
    },
    {
      city: "EAST ROCHESTER",
      state: "OH"
    },
    {
      city: "EAST ROCKAWAY",
      state: "NY"
    },
    {
      city: "EAST RUTHERFORD",
      state: "NJ"
    },
    {
      city: "EAST RYEGATE",
      state: "VT"
    },
    {
      city: "EAST SAINT JOHNSBURY",
      state: "VT"
    },
    {
      city: "EAST SAINT LOUIS",
      state: "IL"
    },
    {
      city: "EAST SANDWICH",
      state: "MA"
    },
    {
      city: "EAST SCHODACK",
      state: "NY"
    },
    {
      city: "EAST SETAUKET",
      state: "NY"
    },
    {
      city: "EAST SMETHPORT",
      state: "PA"
    },
    {
      city: "EAST SMITHFIELD",
      state: "PA"
    },
    {
      city: "EAST SPARTA",
      state: "OH"
    },
    {
      city: "EAST SPENCER",
      state: "NC"
    },
    {
      city: "EAST SPRINGFIELD",
      state: "NY"
    },
    {
      city: "EAST SPRINGFIELD",
      state: "OH"
    },
    {
      city: "EAST SPRINGFIELD",
      state: "PA"
    },
    {
      city: "EAST STONE GAP",
      state: "VA"
    },
    {
      city: "EAST STROUDSBURG",
      state: "PA"
    },
    {
      city: "EAST SYRACUSE",
      state: "NY"
    },
    {
      city: "EAST TALLASSEE",
      state: "AL"
    },
    {
      city: "EAST TAUNTON",
      state: "MA"
    },
    {
      city: "EAST TAWAS",
      state: "MI"
    },
    {
      city: "EAST TEMPLETON",
      state: "MA"
    },
    {
      city: "EAST TEXAS",
      state: "PA"
    },
    {
      city: "EAST THETFORD",
      state: "VT"
    },
    {
      city: "EAST TROY",
      state: "WI"
    },
    {
      city: "EAST VANDERGRIFT",
      state: "PA"
    },
    {
      city: "EAST VASSALBORO",
      state: "ME"
    },
    {
      city: "EAST WAKEFIELD",
      state: "NH"
    },
    {
      city: "EAST WALLINGFORD",
      state: "VT"
    },
    {
      city: "EAST WALPOLE",
      state: "MA"
    },
    {
      city: "EAST WAREHAM",
      state: "MA"
    },
    {
      city: "EAST WATERBORO",
      state: "ME"
    },
    {
      city: "EAST WATERFORD",
      state: "PA"
    },
    {
      city: "EAST WENATCHEE",
      state: "WA"
    },
    {
      city: "EAST WEYMOUTH",
      state: "MA"
    },
    {
      city: "EAST WILLIAMSON",
      state: "NY"
    },
    {
      city: "EAST WILTON",
      state: "ME"
    },
    {
      city: "EAST WINDSOR",
      state: "CT"
    },
    {
      city: "EAST WINDSOR HILL",
      state: "CT"
    },
    {
      city: "EAST WINTHROP",
      state: "ME"
    },
    {
      city: "EAST WOODSTOCK",
      state: "CT"
    },
    {
      city: "EAST WORCESTER",
      state: "NY"
    },
    {
      city: "EASTABOGA",
      state: "AL"
    },
    {
      city: "EASTANOLLEE",
      state: "GA"
    },
    {
      city: "EASTCHESTER",
      state: "NY"
    },
    {
      city: "EASTERN",
      state: "KY"
    },
    {
      city: "EASTFORD",
      state: "CT"
    },
    {
      city: "EASTHAM",
      state: "MA"
    },
    {
      city: "EASTHAMPTON",
      state: "MA"
    },
    {
      city: "EASTLAKE",
      state: "CO"
    },
    {
      city: "EASTLAKE",
      state: "MI"
    },
    {
      city: "EASTLAKE",
      state: "OH"
    },
    {
      city: "EASTLAKE WEIR",
      state: "FL"
    },
    {
      city: "EASTLAND",
      state: "TX"
    },
    {
      city: "EASTMAN",
      state: "GA"
    },
    {
      city: "EASTMAN",
      state: "WI"
    },
    {
      city: "EASTON",
      state: "CT"
    },
    {
      city: "EASTON",
      state: "IL"
    },
    {
      city: "EASTON",
      state: "KS"
    },
    {
      city: "EASTON",
      state: "MA"
    },
    {
      city: "EASTON",
      state: "MD"
    },
    {
      city: "EASTON",
      state: "ME"
    },
    {
      city: "EASTON",
      state: "MN"
    },
    {
      city: "EASTON",
      state: "MO"
    },
    {
      city: "EASTON",
      state: "PA"
    },
    {
      city: "EASTON",
      state: "TX"
    },
    {
      city: "EASTON",
      state: "WA"
    },
    {
      city: "EASTOVER",
      state: "SC"
    },
    {
      city: "EASTPOINT",
      state: "FL"
    },
    {
      city: "EASTPOINTE",
      state: "MI"
    },
    {
      city: "EASTPORT",
      state: "ID"
    },
    {
      city: "EASTPORT",
      state: "ME"
    },
    {
      city: "EASTPORT",
      state: "MI"
    },
    {
      city: "EASTPORT",
      state: "NY"
    },
    {
      city: "EASTSOUND",
      state: "WA"
    },
    {
      city: "EASTVIEW",
      state: "KY"
    },
    {
      city: "EASTVILLE",
      state: "VA"
    },
    {
      city: "EASTWOOD",
      state: "KY"
    },
    {
      city: "EATON",
      state: "CO"
    },
    {
      city: "EATON",
      state: "IN"
    },
    {
      city: "EATON",
      state: "NY"
    },
    {
      city: "EATON",
      state: "OH"
    },
    {
      city: "EATON CENTER",
      state: "NH"
    },
    {
      city: "EATON PARK",
      state: "FL"
    },
    {
      city: "EATON RAPIDS",
      state: "MI"
    },
    {
      city: "EATONTON",
      state: "GA"
    },
    {
      city: "EATONTOWN",
      state: "NJ"
    },
    {
      city: "EATONVILLE",
      state: "WA"
    },
    {
      city: "EAU CLAIRE",
      state: "MI"
    },
    {
      city: "EAU CLAIRE",
      state: "PA"
    },
    {
      city: "EAU CLAIRE",
      state: "WI"
    },
    {
      city: "EAU GALLE",
      state: "WI"
    },
    {
      city: "EBEN JUNCTION",
      state: "MI"
    },
    {
      city: "EBENSBURG",
      state: "PA"
    },
    {
      city: "EBERVALE",
      state: "PA"
    },
    {
      city: "EBONY",
      state: "VA"
    },
    {
      city: "EBRO",
      state: "FL"
    },
    {
      city: "ECCLES",
      state: "WV"
    },
    {
      city: "ECHO",
      state: "LA"
    },
    {
      city: "ECHO",
      state: "MN"
    },
    {
      city: "ECHO",
      state: "OR"
    },
    {
      city: "ECHO",
      state: "UT"
    },
    {
      city: "ECHO LAKE",
      state: "CA"
    },
    {
      city: "ECHOLA",
      state: "AL"
    },
    {
      city: "ECKERMAN",
      state: "MI"
    },
    {
      city: "ECKERT",
      state: "CO"
    },
    {
      city: "ECKERTY",
      state: "IN"
    },
    {
      city: "ECKHART MINES",
      state: "MD"
    },
    {
      city: "ECKLEY",
      state: "CO"
    },
    {
      city: "ECKMAN",
      state: "WV"
    },
    {
      city: "ECLECTIC",
      state: "AL"
    },
    {
      city: "ECLETO",
      state: "TX"
    },
    {
      city: "ECONOMY",
      state: "IN"
    },
    {
      city: "ECORSE",
      state: "MI"
    },
    {
      city: "ECRU",
      state: "MS"
    },
    {
      city: "ECTOR",
      state: "TX"
    },
    {
      city: "EDCOUCH",
      state: "TX"
    },
    {
      city: "EDDINGTON",
      state: "ME"
    },
    {
      city: "EDDY",
      state: "TX"
    },
    {
      city: "EDDYVILLE",
      state: "IA"
    },
    {
      city: "EDDYVILLE",
      state: "IL"
    },
    {
      city: "EDDYVILLE",
      state: "KY"
    },
    {
      city: "EDDYVILLE",
      state: "NE"
    },
    {
      city: "EDDYVILLE",
      state: "OR"
    },
    {
      city: "EDELSTEIN",
      state: "IL"
    },
    {
      city: "EDEN",
      state: "AZ"
    },
    {
      city: "EDEN",
      state: "GA"
    },
    {
      city: "EDEN",
      state: "ID"
    },
    {
      city: "EDEN",
      state: "MD"
    },
    {
      city: "EDEN",
      state: "NC"
    },
    {
      city: "EDEN",
      state: "NY"
    },
    {
      city: "EDEN",
      state: "SD"
    },
    {
      city: "EDEN",
      state: "TX"
    },
    {
      city: "EDEN",
      state: "UT"
    },
    {
      city: "EDEN",
      state: "VT"
    },
    {
      city: "EDEN",
      state: "WI"
    },
    {
      city: "EDEN MILLS",
      state: "VT"
    },
    {
      city: "EDEN PRAIRIE",
      state: "MN"
    },
    {
      city: "EDEN VALLEY",
      state: "MN"
    },
    {
      city: "EDENTON",
      state: "NC"
    },
    {
      city: "EDENVILLE",
      state: "MI"
    },
    {
      city: "EDGAR",
      state: "MT"
    },
    {
      city: "EDGAR",
      state: "NE"
    },
    {
      city: "EDGAR",
      state: "WI"
    },
    {
      city: "EDGAR SPRINGS",
      state: "MO"
    },
    {
      city: "EDGARD",
      state: "LA"
    },
    {
      city: "EDGARTON",
      state: "WV"
    },
    {
      city: "EDGARTOWN",
      state: "MA"
    },
    {
      city: "EDGECOMB",
      state: "ME"
    },
    {
      city: "EDGEFIELD",
      state: "SC"
    },
    {
      city: "EDGELEY",
      state: "ND"
    },
    {
      city: "EDGEMONT",
      state: "AR"
    },
    {
      city: "EDGEMONT",
      state: "PA"
    },
    {
      city: "EDGEMONT",
      state: "SD"
    },
    {
      city: "EDGEMOOR",
      state: "SC"
    },
    {
      city: "EDGERTON",
      state: "KS"
    },
    {
      city: "EDGERTON",
      state: "MN"
    },
    {
      city: "EDGERTON",
      state: "MO"
    },
    {
      city: "EDGERTON",
      state: "OH"
    },
    {
      city: "EDGERTON",
      state: "WI"
    },
    {
      city: "EDGERTON",
      state: "WY"
    },
    {
      city: "EDGEWATER",
      state: "FL"
    },
    {
      city: "EDGEWATER",
      state: "MD"
    },
    {
      city: "EDGEWATER",
      state: "NJ"
    },
    {
      city: "EDGEWOOD",
      state: "IA"
    },
    {
      city: "EDGEWOOD",
      state: "IL"
    },
    {
      city: "EDGEWOOD",
      state: "MD"
    },
    {
      city: "EDGEWOOD",
      state: "NM"
    },
    {
      city: "EDGEWOOD",
      state: "TX"
    },
    {
      city: "EDINA",
      state: "MO"
    },
    {
      city: "EDINBORO",
      state: "PA"
    },
    {
      city: "EDINBURG",
      state: "IL"
    },
    {
      city: "EDINBURG",
      state: "ND"
    },
    {
      city: "EDINBURG",
      state: "PA"
    },
    {
      city: "EDINBURG",
      state: "TX"
    },
    {
      city: "EDINBURG",
      state: "VA"
    },
    {
      city: "EDINBURGH",
      state: "IN"
    },
    {
      city: "EDISON",
      state: "CA"
    },
    {
      city: "EDISON",
      state: "GA"
    },
    {
      city: "EDISON",
      state: "NE"
    },
    {
      city: "EDISON",
      state: "NJ"
    },
    {
      city: "EDISON",
      state: "OH"
    },
    {
      city: "EDISTO ISLAND",
      state: "SC"
    },
    {
      city: "EDMESTON",
      state: "NY"
    },
    {
      city: "EDMOND",
      state: "OK"
    },
    {
      city: "EDMOND",
      state: "WV"
    },
    {
      city: "EDMONDS",
      state: "WA"
    },
    {
      city: "EDMONDSON",
      state: "AR"
    },
    {
      city: "EDMONSON",
      state: "TX"
    },
    {
      city: "EDMONTON",
      state: "KY"
    },
    {
      city: "EDMORE",
      state: "MI"
    },
    {
      city: "EDMORE",
      state: "ND"
    },
    {
      city: "EDMUND",
      state: "WI"
    },
    {
      city: "EDNA",
      state: "KS"
    },
    {
      city: "EDNA",
      state: "TX"
    },
    {
      city: "EDNEYVILLE",
      state: "NC"
    },
    {
      city: "EDON",
      state: "OH"
    },
    {
      city: "EDROY",
      state: "TX"
    },
    {
      city: "EDSON",
      state: "KS"
    },
    {
      city: "EDWALL",
      state: "WA"
    },
    {
      city: "EDWARD",
      state: "NC"
    },
    {
      city: "EDWARDS",
      state: "CA"
    },
    {
      city: "EDWARDS",
      state: "CO"
    },
    {
      city: "EDWARDS",
      state: "IL"
    },
    {
      city: "EDWARDS",
      state: "MO"
    },
    {
      city: "EDWARDS",
      state: "MS"
    },
    {
      city: "EDWARDS",
      state: "NY"
    },
    {
      city: "EDWARDSBURG",
      state: "MI"
    },
    {
      city: "EDWARDSPORT",
      state: "IN"
    },
    {
      city: "EDWARDSVILLE",
      state: "IL"
    },
    {
      city: "EDWARDSVILLE",
      state: "KS"
    },
    {
      city: "EDWARDSVILLE",
      state: "VA"
    },
    {
      city: "EEK",
      state: "AK"
    },
    {
      city: "EFFIE",
      state: "LA"
    },
    {
      city: "EFFIE",
      state: "MN"
    },
    {
      city: "EFFINGHAM",
      state: "IL"
    },
    {
      city: "EFFINGHAM",
      state: "KS"
    },
    {
      city: "EFFINGHAM",
      state: "NH"
    },
    {
      city: "EFFINGHAM",
      state: "SC"
    },
    {
      city: "EFFORT",
      state: "PA"
    },
    {
      city: "EFLAND",
      state: "NC"
    },
    {
      city: "EGAN",
      state: "LA"
    },
    {
      city: "EGAN",
      state: "SD"
    },
    {
      city: "EGEGIK",
      state: "AK"
    },
    {
      city: "EGELAND",
      state: "ND"
    },
    {
      city: "EGG HARBOR",
      state: "WI"
    },
    {
      city: "EGG HARBOR CITY",
      state: "NJ"
    },
    {
      city: "EGG HARBOR TOWNSHIP",
      state: "NJ"
    },
    {
      city: "EGGLESTON",
      state: "VA"
    },
    {
      city: "EGLIN AFB",
      state: "FL"
    },
    {
      city: "EGLON",
      state: "WV"
    },
    {
      city: "EGNAR",
      state: "CO"
    },
    {
      city: "EGYPT",
      state: "TX"
    },
    {
      city: "EHRENBERG",
      state: "AZ"
    },
    {
      city: "EHRHARDT",
      state: "SC"
    },
    {
      city: "EIDSON",
      state: "TN"
    },
    {
      city: "EIELSON AFB",
      state: "AK"
    },
    {
      city: "EIGHT MILE",
      state: "AL"
    },
    {
      city: "EIGHTY EIGHT",
      state: "KY"
    },
    {
      city: "EIGHTY FOUR",
      state: "PA"
    },
    {
      city: "EITZEN",
      state: "MN"
    },
    {
      city: "EKALAKA",
      state: "MT"
    },
    {
      city: "EKRON",
      state: "KY"
    },
    {
      city: "EKWOK",
      state: "AK"
    },
    {
      city: "EL CAJON",
      state: "CA"
    },
    {
      city: "EL CAMPO",
      state: "TX"
    },
    {
      city: "EL CENTRO",
      state: "CA"
    },
    {
      city: "EL CERRITO",
      state: "CA"
    },
    {
      city: "EL DORADO",
      state: "AR"
    },
    {
      city: "EL DORADO",
      state: "CA"
    },
    {
      city: "EL DORADO",
      state: "KS"
    },
    {
      city: "EL DORADO HILLS",
      state: "CA"
    },
    {
      city: "EL DORADO SPRINGS",
      state: "MO"
    },
    {
      city: "EL GRANADA",
      state: "CA"
    },
    {
      city: "EL INDIO",
      state: "TX"
    },
    {
      city: "EL MIRAGE",
      state: "AZ"
    },
    {
      city: "EL MONTE",
      state: "CA"
    },
    {
      city: "EL NIDO",
      state: "CA"
    },
    {
      city: "EL PASO",
      state: "AR"
    },
    {
      city: "EL PASO",
      state: "IL"
    },
    {
      city: "EL PASO",
      state: "TX"
    },
    {
      city: "EL PORTAL",
      state: "CA"
    },
    {
      city: "EL PRADO",
      state: "NM"
    },
    {
      city: "EL RENO",
      state: "OK"
    },
    {
      city: "EL RITO",
      state: "NM"
    },
    {
      city: "EL SEGUNDO",
      state: "CA"
    },
    {
      city: "EL SOBRANTE",
      state: "CA"
    },
    {
      city: "EL TORO",
      state: "CA"
    },
    {
      city: "EL VERANO",
      state: "CA"
    },
    {
      city: "ELAINE",
      state: "AR"
    },
    {
      city: "ELAND",
      state: "WI"
    },
    {
      city: "ELBA",
      state: "AL"
    },
    {
      city: "ELBA",
      state: "NE"
    },
    {
      city: "ELBA",
      state: "NY"
    },
    {
      city: "ELBE",
      state: "WA"
    },
    {
      city: "ELBERFELD",
      state: "IN"
    },
    {
      city: "ELBERON",
      state: "IA"
    },
    {
      city: "ELBERON",
      state: "VA"
    },
    {
      city: "ELBERT",
      state: "CO"
    },
    {
      city: "ELBERTA",
      state: "AL"
    },
    {
      city: "ELBERTA",
      state: "MI"
    },
    {
      city: "ELBERTA",
      state: "UT"
    },
    {
      city: "ELBERTON",
      state: "GA"
    },
    {
      city: "ELBING",
      state: "KS"
    },
    {
      city: "ELBOW LAKE",
      state: "MN"
    },
    {
      city: "ELBRIDGE",
      state: "NY"
    },
    {
      city: "ELBURN",
      state: "IL"
    },
    {
      city: "ELCHO",
      state: "WI"
    },
    {
      city: "ELCO",
      state: "PA"
    },
    {
      city: "ELDERON",
      state: "WI"
    },
    {
      city: "ELDERTON",
      state: "PA"
    },
    {
      city: "ELDON",
      state: "IA"
    },
    {
      city: "ELDON",
      state: "MO"
    },
    {
      city: "ELDORA",
      state: "IA"
    },
    {
      city: "ELDORADO",
      state: "IL"
    },
    {
      city: "ELDORADO",
      state: "OH"
    },
    {
      city: "ELDORADO",
      state: "OK"
    },
    {
      city: "ELDORADO",
      state: "TX"
    },
    {
      city: "ELDORADO",
      state: "WI"
    },
    {
      city: "ELDORADO SPRINGS",
      state: "CO"
    },
    {
      city: "ELDRED",
      state: "IL"
    },
    {
      city: "ELDRED",
      state: "NY"
    },
    {
      city: "ELDRED",
      state: "PA"
    },
    {
      city: "ELDRIDGE",
      state: "AL"
    },
    {
      city: "ELDRIDGE",
      state: "CA"
    },
    {
      city: "ELDRIDGE",
      state: "IA"
    },
    {
      city: "ELDRIDGE",
      state: "MO"
    },
    {
      city: "ELEANOR",
      state: "WV"
    },
    {
      city: "ELECTRA",
      state: "TX"
    },
    {
      city: "ELECTRIC CITY",
      state: "WA"
    },
    {
      city: "ELEELE",
      state: "HI"
    },
    {
      city: "ELEPHANT BUTTE",
      state: "NM"
    },
    {
      city: "ELEROY",
      state: "IL"
    },
    {
      city: "ELEVA",
      state: "WI"
    },
    {
      city: "ELFERS",
      state: "FL"
    },
    {
      city: "ELFIN COVE",
      state: "AK"
    },
    {
      city: "ELFRIDA",
      state: "AZ"
    },
    {
      city: "ELGIN",
      state: "AZ"
    },
    {
      city: "ELGIN",
      state: "IA"
    },
    {
      city: "ELGIN",
      state: "IL"
    },
    {
      city: "ELGIN",
      state: "MN"
    },
    {
      city: "ELGIN",
      state: "ND"
    },
    {
      city: "ELGIN",
      state: "NE"
    },
    {
      city: "ELGIN",
      state: "OH"
    },
    {
      city: "ELGIN",
      state: "OK"
    },
    {
      city: "ELGIN",
      state: "OR"
    },
    {
      city: "ELGIN",
      state: "SC"
    },
    {
      city: "ELGIN",
      state: "TN"
    },
    {
      city: "ELGIN",
      state: "TX"
    },
    {
      city: "ELIDA",
      state: "NM"
    },
    {
      city: "ELIM",
      state: "AK"
    },
    {
      city: "ELIOT",
      state: "ME"
    },
    {
      city: "ELIZABETH",
      state: "AR"
    },
    {
      city: "ELIZABETH",
      state: "CO"
    },
    {
      city: "ELIZABETH",
      state: "IL"
    },
    {
      city: "ELIZABETH",
      state: "IN"
    },
    {
      city: "ELIZABETH",
      state: "LA"
    },
    {
      city: "ELIZABETH",
      state: "MN"
    },
    {
      city: "ELIZABETH",
      state: "NJ"
    },
    {
      city: "ELIZABETH",
      state: "PA"
    },
    {
      city: "ELIZABETH",
      state: "WV"
    },
    {
      city: "ELIZABETH CITY",
      state: "NC"
    },
    {
      city: "ELIZABETHPORT",
      state: "NJ"
    },
    {
      city: "ELIZABETHTON",
      state: "TN"
    },
    {
      city: "ELIZABETHTOWN",
      state: "IL"
    },
    {
      city: "ELIZABETHTOWN",
      state: "IN"
    },
    {
      city: "ELIZABETHTOWN",
      state: "KY"
    },
    {
      city: "ELIZABETHTOWN",
      state: "NC"
    },
    {
      city: "ELIZABETHTOWN",
      state: "NY"
    },
    {
      city: "ELIZABETHTOWN",
      state: "PA"
    },
    {
      city: "ELIZABETHVILLE",
      state: "PA"
    },
    {
      city: "ELIZAVILLE",
      state: "KY"
    },
    {
      city: "ELIZAVILLE",
      state: "NY"
    },
    {
      city: "ELK",
      state: "CA"
    },
    {
      city: "ELK",
      state: "WA"
    },
    {
      city: "ELK CITY",
      state: "ID"
    },
    {
      city: "ELK CITY",
      state: "KS"
    },
    {
      city: "ELK CITY",
      state: "OK"
    },
    {
      city: "ELK CREEK",
      state: "CA"
    },
    {
      city: "ELK CREEK",
      state: "MO"
    },
    {
      city: "ELK CREEK",
      state: "NE"
    },
    {
      city: "ELK CREEK",
      state: "VA"
    },
    {
      city: "ELK FALLS",
      state: "KS"
    },
    {
      city: "ELK GARDEN",
      state: "WV"
    },
    {
      city: "ELK GROVE",
      state: "CA"
    },
    {
      city: "ELK GROVE VILLAGE",
      state: "IL"
    },
    {
      city: "ELK HORN",
      state: "IA"
    },
    {
      city: "ELK HORN",
      state: "KY"
    },
    {
      city: "ELK MILLS",
      state: "MD"
    },
    {
      city: "ELK MOUND",
      state: "WI"
    },
    {
      city: "ELK MOUNTAIN",
      state: "WY"
    },
    {
      city: "ELK PARK",
      state: "NC"
    },
    {
      city: "ELK POINT",
      state: "SD"
    },
    {
      city: "ELK RAPIDS",
      state: "MI"
    },
    {
      city: "ELK RIVER",
      state: "ID"
    },
    {
      city: "ELK RIVER",
      state: "MN"
    },
    {
      city: "ELKA PARK",
      state: "NY"
    },
    {
      city: "ELKADER",
      state: "IA"
    },
    {
      city: "ELKHART",
      state: "IA"
    },
    {
      city: "ELKHART",
      state: "IL"
    },
    {
      city: "ELKHART",
      state: "IN"
    },
    {
      city: "ELKHART",
      state: "KS"
    },
    {
      city: "ELKHART",
      state: "TX"
    },
    {
      city: "ELKHART LAKE",
      state: "WI"
    },
    {
      city: "ELKHORN",
      state: "NE"
    },
    {
      city: "ELKHORN",
      state: "WI"
    },
    {
      city: "ELKHORN",
      state: "WV"
    },
    {
      city: "ELKHORN CITY",
      state: "KY"
    },
    {
      city: "ELKIN",
      state: "NC"
    },
    {
      city: "ELKINS",
      state: "AR"
    },
    {
      city: "ELKINS",
      state: "NH"
    },
    {
      city: "ELKINS",
      state: "WV"
    },
    {
      city: "ELKINS PARK",
      state: "PA"
    },
    {
      city: "ELKLAND",
      state: "MO"
    },
    {
      city: "ELKLAND",
      state: "PA"
    },
    {
      city: "ELKMONT",
      state: "AL"
    },
    {
      city: "ELKO",
      state: "GA"
    },
    {
      city: "ELKO",
      state: "NV"
    },
    {
      city: "ELKO",
      state: "SC"
    },
    {
      city: "ELKO NEW MARKET",
      state: "MN"
    },
    {
      city: "ELKPORT",
      state: "IA"
    },
    {
      city: "ELKRIDGE",
      state: "MD"
    },
    {
      city: "ELKTON",
      state: "FL"
    },
    {
      city: "ELKTON",
      state: "KY"
    },
    {
      city: "ELKTON",
      state: "MD"
    },
    {
      city: "ELKTON",
      state: "MI"
    },
    {
      city: "ELKTON",
      state: "MN"
    },
    {
      city: "ELKTON",
      state: "OH"
    },
    {
      city: "ELKTON",
      state: "OR"
    },
    {
      city: "ELKTON",
      state: "SD"
    },
    {
      city: "ELKTON",
      state: "TN"
    },
    {
      city: "ELKTON",
      state: "VA"
    },
    {
      city: "ELKVIEW",
      state: "WV"
    },
    {
      city: "ELKVILLE",
      state: "IL"
    },
    {
      city: "ELKWOOD",
      state: "VA"
    },
    {
      city: "ELLABELL",
      state: "GA"
    },
    {
      city: "ELLAMORE",
      state: "WV"
    },
    {
      city: "ELLAVILLE",
      state: "GA"
    },
    {
      city: "ELLENBORO",
      state: "NC"
    },
    {
      city: "ELLENBORO",
      state: "WV"
    },
    {
      city: "ELLENBURG",
      state: "NY"
    },
    {
      city: "ELLENBURG CENTER",
      state: "NY"
    },
    {
      city: "ELLENBURG DEPOT",
      state: "NY"
    },
    {
      city: "ELLENDALE",
      state: "DE"
    },
    {
      city: "ELLENDALE",
      state: "MN"
    },
    {
      city: "ELLENDALE",
      state: "ND"
    },
    {
      city: "ELLENDALE",
      state: "TN"
    },
    {
      city: "ELLENSBURG",
      state: "WA"
    },
    {
      city: "ELLENTON",
      state: "FL"
    },
    {
      city: "ELLENTON",
      state: "GA"
    },
    {
      city: "ELLENVILLE",
      state: "NY"
    },
    {
      city: "ELLENWOOD",
      state: "GA"
    },
    {
      city: "ELLERBE",
      state: "NC"
    },
    {
      city: "ELLERSLIE",
      state: "GA"
    },
    {
      city: "ELLERSLIE",
      state: "MD"
    },
    {
      city: "ELLERY",
      state: "IL"
    },
    {
      city: "ELLETTSVILLE",
      state: "IN"
    },
    {
      city: "ELLICOTT CITY",
      state: "MD"
    },
    {
      city: "ELLICOTTVILLE",
      state: "NY"
    },
    {
      city: "ELLIJAY",
      state: "GA"
    },
    {
      city: "ELLINGER",
      state: "TX"
    },
    {
      city: "ELLINGTON",
      state: "CT"
    },
    {
      city: "ELLINGTON",
      state: "MO"
    },
    {
      city: "ELLINGTON",
      state: "NY"
    },
    {
      city: "ELLINWOOD",
      state: "KS"
    },
    {
      city: "ELLIOTT",
      state: "IA"
    },
    {
      city: "ELLIOTT",
      state: "IL"
    },
    {
      city: "ELLIOTTSBURG",
      state: "PA"
    },
    {
      city: "ELLIS",
      state: "ID"
    },
    {
      city: "ELLIS",
      state: "KS"
    },
    {
      city: "ELLIS GROVE",
      state: "IL"
    },
    {
      city: "ELLISBURG",
      state: "NY"
    },
    {
      city: "ELLISON BAY",
      state: "WI"
    },
    {
      city: "ELLISTON",
      state: "MT"
    },
    {
      city: "ELLISTON",
      state: "VA"
    },
    {
      city: "ELLISVILLE",
      state: "IL"
    },
    {
      city: "ELLISVILLE",
      state: "MS"
    },
    {
      city: "ELLOREE",
      state: "SC"
    },
    {
      city: "ELLSINORE",
      state: "MO"
    },
    {
      city: "ELLSTON",
      state: "IA"
    },
    {
      city: "ELLSWORTH",
      state: "IA"
    },
    {
      city: "ELLSWORTH",
      state: "IL"
    },
    {
      city: "ELLSWORTH",
      state: "KS"
    },
    {
      city: "ELLSWORTH",
      state: "ME"
    },
    {
      city: "ELLSWORTH",
      state: "MI"
    },
    {
      city: "ELLSWORTH",
      state: "MN"
    },
    {
      city: "ELLSWORTH",
      state: "NE"
    },
    {
      city: "ELLSWORTH",
      state: "OH"
    },
    {
      city: "ELLSWORTH",
      state: "PA"
    },
    {
      city: "ELLSWORTH",
      state: "WI"
    },
    {
      city: "ELLSWORTH AFB",
      state: "SD"
    },
    {
      city: "ELLWOOD CITY",
      state: "PA"
    },
    {
      city: "ELM",
      state: "PA"
    },
    {
      city: "ELM CITY",
      state: "NC"
    },
    {
      city: "ELM CREEK",
      state: "NE"
    },
    {
      city: "ELM GROVE",
      state: "LA"
    },
    {
      city: "ELM GROVE",
      state: "WI"
    },
    {
      city: "ELM HALL",
      state: "MI"
    },
    {
      city: "ELM MOTT",
      state: "TX"
    },
    {
      city: "ELM SPRINGS",
      state: "AR"
    },
    {
      city: "ELMA",
      state: "IA"
    },
    {
      city: "ELMA",
      state: "NY"
    },
    {
      city: "ELMA",
      state: "WA"
    },
    {
      city: "ELMATON",
      state: "TX"
    },
    {
      city: "ELMDALE",
      state: "KS"
    },
    {
      city: "ELMENDORF",
      state: "TX"
    },
    {
      city: "ELMER",
      state: "LA"
    },
    {
      city: "ELMER",
      state: "MO"
    },
    {
      city: "ELMER",
      state: "NJ"
    },
    {
      city: "ELMER",
      state: "OK"
    },
    {
      city: "ELMER CITY",
      state: "WA"
    },
    {
      city: "ELMHURST",
      state: "IL"
    },
    {
      city: "ELMHURST",
      state: "NY"
    },
    {
      city: "ELMHURST",
      state: "PA"
    },
    {
      city: "ELMIRA",
      state: "CA"
    },
    {
      city: "ELMIRA",
      state: "MI"
    },
    {
      city: "ELMIRA",
      state: "NY"
    },
    {
      city: "ELMIRA",
      state: "OR"
    },
    {
      city: "ELMO",
      state: "MO"
    },
    {
      city: "ELMO",
      state: "MT"
    },
    {
      city: "ELMO",
      state: "TX"
    },
    {
      city: "ELMO",
      state: "UT"
    },
    {
      city: "ELMONT",
      state: "NY"
    },
    {
      city: "ELMORA",
      state: "PA"
    },
    {
      city: "ELMORE",
      state: "AL"
    },
    {
      city: "ELMORE",
      state: "MN"
    },
    {
      city: "ELMORE",
      state: "OH"
    },
    {
      city: "ELMORE CITY",
      state: "OK"
    },
    {
      city: "ELMSFORD",
      state: "NY"
    },
    {
      city: "ELMWOOD",
      state: "IL"
    },
    {
      city: "ELMWOOD",
      state: "MA"
    },
    {
      city: "ELMWOOD",
      state: "NE"
    },
    {
      city: "ELMWOOD",
      state: "TN"
    },
    {
      city: "ELMWOOD",
      state: "WI"
    },
    {
      city: "ELMWOOD PARK",
      state: "IL"
    },
    {
      city: "ELMWOOD PARK",
      state: "NJ"
    },
    {
      city: "ELNORA",
      state: "IN"
    },
    {
      city: "ELON",
      state: "NC"
    },
    {
      city: "ELORA",
      state: "TN"
    },
    {
      city: "ELOY",
      state: "AZ"
    },
    {
      city: "ELRAMA",
      state: "PA"
    },
    {
      city: "ELROD",
      state: "AL"
    },
    {
      city: "ELROSA",
      state: "MN"
    },
    {
      city: "ELROY",
      state: "WI"
    },
    {
      city: "ELSA",
      state: "TX"
    },
    {
      city: "ELSAH",
      state: "IL"
    },
    {
      city: "ELSBERRY",
      state: "MO"
    },
    {
      city: "ELSIE",
      state: "MI"
    },
    {
      city: "ELSIE",
      state: "NE"
    },
    {
      city: "ELSINORE",
      state: "UT"
    },
    {
      city: "ELSMORE",
      state: "KS"
    },
    {
      city: "ELTON",
      state: "LA"
    },
    {
      city: "ELTON",
      state: "PA"
    },
    {
      city: "ELTON",
      state: "WI"
    },
    {
      city: "ELTOPIA",
      state: "WA"
    },
    {
      city: "ELVASTON",
      state: "IL"
    },
    {
      city: "ELVERSON",
      state: "PA"
    },
    {
      city: "ELVERTA",
      state: "CA"
    },
    {
      city: "ELWELL",
      state: "MI"
    },
    {
      city: "ELWIN",
      state: "IL"
    },
    {
      city: "ELWOOD",
      state: "IL"
    },
    {
      city: "ELWOOD",
      state: "IN"
    },
    {
      city: "ELWOOD",
      state: "KS"
    },
    {
      city: "ELWOOD",
      state: "NE"
    },
    {
      city: "ELWOOD",
      state: "NJ"
    },
    {
      city: "ELY",
      state: "IA"
    },
    {
      city: "ELY",
      state: "MN"
    },
    {
      city: "ELY",
      state: "NV"
    },
    {
      city: "ELYRIA",
      state: "NE"
    },
    {
      city: "ELYRIA",
      state: "OH"
    },
    {
      city: "ELYSBURG",
      state: "PA"
    },
    {
      city: "ELYSIAN",
      state: "MN"
    },
    {
      city: "ELYSIAN FIELDS",
      state: "TX"
    },
    {
      city: "EMBARRASS",
      state: "MN"
    },
    {
      city: "EMBARRASS",
      state: "WI"
    },
    {
      city: "EMBUDO",
      state: "NM"
    },
    {
      city: "EMDEN",
      state: "IL"
    },
    {
      city: "EMEIGH",
      state: "PA"
    },
    {
      city: "EMELLE",
      state: "AL"
    },
    {
      city: "EMERADO",
      state: "ND"
    },
    {
      city: "EMERALD ISLE",
      state: "NC"
    },
    {
      city: "EMERSON",
      state: "AR"
    },
    {
      city: "EMERSON",
      state: "GA"
    },
    {
      city: "EMERSON",
      state: "IA"
    },
    {
      city: "EMERSON",
      state: "KY"
    },
    {
      city: "EMERSON",
      state: "NE"
    },
    {
      city: "EMERSON",
      state: "NJ"
    },
    {
      city: "EMERY",
      state: "SD"
    },
    {
      city: "EMERY",
      state: "UT"
    },
    {
      city: "EMERYVILLE",
      state: "CA"
    },
    {
      city: "EMIGRANT",
      state: "MT"
    },
    {
      city: "EMIGRANT GAP",
      state: "CA"
    },
    {
      city: "EMIGSVILLE",
      state: "PA"
    },
    {
      city: "EMILY",
      state: "MN"
    },
    {
      city: "EMINENCE",
      state: "IN"
    },
    {
      city: "EMINENCE",
      state: "KY"
    },
    {
      city: "EMINENCE",
      state: "MO"
    },
    {
      city: "EMINGTON",
      state: "IL"
    },
    {
      city: "EMLENTON",
      state: "PA"
    },
    {
      city: "EMMA",
      state: "MO"
    },
    {
      city: "EMMALENA",
      state: "KY"
    },
    {
      city: "EMMAUS",
      state: "PA"
    },
    {
      city: "EMMET",
      state: "AR"
    },
    {
      city: "EMMET",
      state: "NE"
    },
    {
      city: "EMMETSBURG",
      state: "IA"
    },
    {
      city: "EMMETT",
      state: "ID"
    },
    {
      city: "EMMETT",
      state: "KS"
    },
    {
      city: "EMMETT",
      state: "MI"
    },
    {
      city: "EMMITSBURG",
      state: "MD"
    },
    {
      city: "EMMONAK",
      state: "AK"
    },
    {
      city: "EMMONS",
      state: "MN"
    },
    {
      city: "EMORY",
      state: "TX"
    },
    {
      city: "EMORY",
      state: "VA"
    },
    {
      city: "EMPIRE",
      state: "AL"
    },
    {
      city: "EMPIRE",
      state: "CA"
    },
    {
      city: "EMPIRE",
      state: "CO"
    },
    {
      city: "EMPIRE",
      state: "LA"
    },
    {
      city: "EMPIRE",
      state: "MI"
    },
    {
      city: "EMPIRE",
      state: "NV"
    },
    {
      city: "EMPIRE",
      state: "OH"
    },
    {
      city: "EMPORIA",
      state: "KS"
    },
    {
      city: "EMPORIA",
      state: "VA"
    },
    {
      city: "EMPORIUM",
      state: "PA"
    },
    {
      city: "ENCAMPMENT",
      state: "WY"
    },
    {
      city: "ENCINAL",
      state: "TX"
    },
    {
      city: "ENCINITAS",
      state: "CA"
    },
    {
      city: "ENCINO",
      state: "CA"
    },
    {
      city: "ENCINO",
      state: "NM"
    },
    {
      city: "ENCINO",
      state: "TX"
    },
    {
      city: "ENDEAVOR",
      state: "PA"
    },
    {
      city: "ENDEAVOR",
      state: "WI"
    },
    {
      city: "ENDERLIN",
      state: "ND"
    },
    {
      city: "ENDERS",
      state: "NE"
    },
    {
      city: "ENDICOTT",
      state: "NE"
    },
    {
      city: "ENDICOTT",
      state: "NY"
    },
    {
      city: "ENDICOTT",
      state: "WA"
    },
    {
      city: "ENDWELL",
      state: "NY"
    },
    {
      city: "ENERGY",
      state: "IL"
    },
    {
      city: "ENERGY",
      state: "TX"
    },
    {
      city: "ENFIELD",
      state: "CT"
    },
    {
      city: "ENFIELD",
      state: "IL"
    },
    {
      city: "ENFIELD",
      state: "NC"
    },
    {
      city: "ENFIELD",
      state: "NH"
    },
    {
      city: "ENFIELD CENTER",
      state: "NH"
    },
    {
      city: "ENGADINE",
      state: "MI"
    },
    {
      city: "ENGELHARD",
      state: "NC"
    },
    {
      city: "ENGLAND",
      state: "AR"
    },
    {
      city: "ENGLEWOOD",
      state: "CO"
    },
    {
      city: "ENGLEWOOD",
      state: "FL"
    },
    {
      city: "ENGLEWOOD",
      state: "KS"
    },
    {
      city: "ENGLEWOOD",
      state: "NJ"
    },
    {
      city: "ENGLEWOOD",
      state: "OH"
    },
    {
      city: "ENGLEWOOD",
      state: "TN"
    },
    {
      city: "ENGLEWOOD CLIFFS",
      state: "NJ"
    },
    {
      city: "ENGLISH",
      state: "IN"
    },
    {
      city: "ENGLISHTOWN",
      state: "NJ"
    },
    {
      city: "ENID",
      state: "MS"
    },
    {
      city: "ENID",
      state: "OK"
    },
    {
      city: "ENIGMA",
      state: "GA"
    },
    {
      city: "ENKA",
      state: "NC"
    },
    {
      city: "ENLOE",
      state: "TX"
    },
    {
      city: "ENNICE",
      state: "NC"
    },
    {
      city: "ENNING",
      state: "SD"
    },
    {
      city: "ENNIS",
      state: "MT"
    },
    {
      city: "ENNIS",
      state: "TX"
    },
    {
      city: "ENOLA",
      state: "AR"
    },
    {
      city: "ENOLA",
      state: "PA"
    },
    {
      city: "ENON",
      state: "OH"
    },
    {
      city: "ENON VALLEY",
      state: "PA"
    },
    {
      city: "ENOREE",
      state: "SC"
    },
    {
      city: "ENOSBURG FALLS",
      state: "VT"
    },
    {
      city: "ENSIGN",
      state: "KS"
    },
    {
      city: "ENTERPRISE",
      state: "AL"
    },
    {
      city: "ENTERPRISE",
      state: "KS"
    },
    {
      city: "ENTERPRISE",
      state: "LA"
    },
    {
      city: "ENTERPRISE",
      state: "MS"
    },
    {
      city: "ENTERPRISE",
      state: "OR"
    },
    {
      city: "ENTERPRISE",
      state: "UT"
    },
    {
      city: "ENTERPRISE",
      state: "WV"
    },
    {
      city: "ENTIAT",
      state: "WA"
    },
    {
      city: "ENTRIKEN",
      state: "PA"
    },
    {
      city: "ENUMCLAW",
      state: "WA"
    },
    {
      city: "ENVILLE",
      state: "TN"
    },
    {
      city: "EOLA",
      state: "IL"
    },
    {
      city: "EOLA",
      state: "TX"
    },
    {
      city: "EOLIA",
      state: "KY"
    },
    {
      city: "EOLIA",
      state: "MO"
    },
    {
      city: "EPES",
      state: "AL"
    },
    {
      city: "EPHRAIM",
      state: "UT"
    },
    {
      city: "EPHRAIM",
      state: "WI"
    },
    {
      city: "EPHRATA",
      state: "PA"
    },
    {
      city: "EPHRATA",
      state: "WA"
    },
    {
      city: "EPPING",
      state: "ND"
    },
    {
      city: "EPPING",
      state: "NH"
    },
    {
      city: "EPPS",
      state: "LA"
    },
    {
      city: "EPSOM",
      state: "NH"
    },
    {
      city: "EPWORTH",
      state: "GA"
    },
    {
      city: "EPWORTH",
      state: "IA"
    },
    {
      city: "EQUALITY",
      state: "AL"
    },
    {
      city: "EQUALITY",
      state: "IL"
    },
    {
      city: "EQUINUNK",
      state: "PA"
    },
    {
      city: "ERA",
      state: "TX"
    },
    {
      city: "ERATH",
      state: "LA"
    },
    {
      city: "ERBACON",
      state: "WV"
    },
    {
      city: "ERHARD",
      state: "MN"
    },
    {
      city: "ERICK",
      state: "OK"
    },
    {
      city: "ERICSON",
      state: "NE"
    },
    {
      city: "ERIE",
      state: "CO"
    },
    {
      city: "ERIE",
      state: "IL"
    },
    {
      city: "ERIE",
      state: "KS"
    },
    {
      city: "ERIE",
      state: "MI"
    },
    {
      city: "ERIE",
      state: "ND"
    },
    {
      city: "ERIE",
      state: "PA"
    },
    {
      city: "ERIEVILLE",
      state: "NY"
    },
    {
      city: "ERIN",
      state: "NY"
    },
    {
      city: "ERIN",
      state: "TN"
    },
    {
      city: "ERLANGER",
      state: "KY"
    },
    {
      city: "ERMINE",
      state: "KY"
    },
    {
      city: "ERNUL",
      state: "NC"
    },
    {
      city: "EROS",
      state: "LA"
    },
    {
      city: "ERROL",
      state: "NH"
    },
    {
      city: "ERSKINE",
      state: "MN"
    },
    {
      city: "ERVING",
      state: "MA"
    },
    {
      city: "ERWIN",
      state: "NC"
    },
    {
      city: "ERWIN",
      state: "SD"
    },
    {
      city: "ERWIN",
      state: "TN"
    },
    {
      city: "ERWINNA",
      state: "PA"
    },
    {
      city: "ERWINVILLE",
      state: "LA"
    },
    {
      city: "ESBON",
      state: "KS"
    },
    {
      city: "ESCALANTE",
      state: "UT"
    },
    {
      city: "ESCALON",
      state: "CA"
    },
    {
      city: "ESCANABA",
      state: "MI"
    },
    {
      city: "ESCATAWPA",
      state: "MS"
    },
    {
      city: "ESCONDIDO",
      state: "CA"
    },
    {
      city: "ESKDALE",
      state: "WV"
    },
    {
      city: "ESKO",
      state: "MN"
    },
    {
      city: "ESKRIDGE",
      state: "KS"
    },
    {
      city: "ESMOND",
      state: "IL"
    },
    {
      city: "ESMOND",
      state: "ND"
    },
    {
      city: "ESMONT",
      state: "VA"
    },
    {
      city: "ESOM HILL",
      state: "GA"
    },
    {
      city: "ESOPUS",
      state: "NY"
    },
    {
      city: "ESPANOLA",
      state: "NM"
    },
    {
      city: "ESPARTO",
      state: "CA"
    },
    {
      city: "ESPERANCE",
      state: "NY"
    },
    {
      city: "ESSEX",
      state: "CA"
    },
    {
      city: "ESSEX",
      state: "CT"
    },
    {
      city: "ESSEX",
      state: "IA"
    },
    {
      city: "ESSEX",
      state: "IL"
    },
    {
      city: "ESSEX",
      state: "MA"
    },
    {
      city: "ESSEX",
      state: "MD"
    },
    {
      city: "ESSEX",
      state: "MO"
    },
    {
      city: "ESSEX",
      state: "MT"
    },
    {
      city: "ESSEX",
      state: "NY"
    },
    {
      city: "ESSEX",
      state: "VT"
    },
    {
      city: "ESSEX FELLS",
      state: "NJ"
    },
    {
      city: "ESSEX JUNCTION",
      state: "VT"
    },
    {
      city: "ESSEXVILLE",
      state: "MI"
    },
    {
      city: "ESSIE",
      state: "KY"
    },
    {
      city: "ESSIG",
      state: "MN"
    },
    {
      city: "ESSINGTON",
      state: "PA"
    },
    {
      city: "ESTACADA",
      state: "OR"
    },
    {
      city: "ESTANCIA",
      state: "NM"
    },
    {
      city: "ESTELL MANOR",
      state: "NJ"
    },
    {
      city: "ESTELLINE",
      state: "SD"
    },
    {
      city: "ESTELLINE",
      state: "TX"
    },
    {
      city: "ESTER",
      state: "AK"
    },
    {
      city: "ESTERO",
      state: "FL"
    },
    {
      city: "ESTES PARK",
      state: "CO"
    },
    {
      city: "ESTHERVILLE",
      state: "IA"
    },
    {
      city: "ESTHERWOOD",
      state: "LA"
    },
    {
      city: "ESTILL",
      state: "SC"
    },
    {
      city: "ESTILL SPRINGS",
      state: "TN"
    },
    {
      city: "ESTILLFORK",
      state: "AL"
    },
    {
      city: "ETHAN",
      state: "SD"
    },
    {
      city: "ETHEL",
      state: "LA"
    },
    {
      city: "ETHEL",
      state: "MO"
    },
    {
      city: "ETHEL",
      state: "MS"
    },
    {
      city: "ETHEL",
      state: "WA"
    },
    {
      city: "ETHEL",
      state: "WV"
    },
    {
      city: "ETHELSVILLE",
      state: "AL"
    },
    {
      city: "ETHER",
      state: "NC"
    },
    {
      city: "ETHRIDGE",
      state: "MT"
    },
    {
      city: "ETHRIDGE",
      state: "TN"
    },
    {
      city: "ETLAN",
      state: "VA"
    },
    {
      city: "ETNA",
      state: "CA"
    },
    {
      city: "ETNA",
      state: "ME"
    },
    {
      city: "ETNA",
      state: "NH"
    },
    {
      city: "ETNA",
      state: "OH"
    },
    {
      city: "ETNA",
      state: "WY"
    },
    {
      city: "ETNA GREEN",
      state: "IN"
    },
    {
      city: "ETOILE",
      state: "KY"
    },
    {
      city: "ETOILE",
      state: "TX"
    },
    {
      city: "ETON",
      state: "GA"
    },
    {
      city: "ETOWAH",
      state: "AR"
    },
    {
      city: "ETOWAH",
      state: "NC"
    },
    {
      city: "ETOWAH",
      state: "TN"
    },
    {
      city: "ETTA",
      state: "MS"
    },
    {
      city: "ETTERS",
      state: "PA"
    },
    {
      city: "ETTRICK",
      state: "WI"
    },
    {
      city: "EUBANK",
      state: "KY"
    },
    {
      city: "EUCHA",
      state: "OK"
    },
    {
      city: "EUCLID",
      state: "MN"
    },
    {
      city: "EUCLID",
      state: "OH"
    },
    {
      city: "EUDORA",
      state: "AR"
    },
    {
      city: "EUDORA",
      state: "KS"
    },
    {
      city: "EUFAULA",
      state: "AL"
    },
    {
      city: "EUFAULA",
      state: "OK"
    },
    {
      city: "EUGENE",
      state: "MO"
    },
    {
      city: "EUGENE",
      state: "OR"
    },
    {
      city: "EULESS",
      state: "TX"
    },
    {
      city: "EUNICE",
      state: "LA"
    },
    {
      city: "EUNICE",
      state: "NM"
    },
    {
      city: "EUPORA",
      state: "MS"
    },
    {
      city: "EURE",
      state: "NC"
    },
    {
      city: "EUREKA",
      state: "CA"
    },
    {
      city: "EUREKA",
      state: "IL"
    },
    {
      city: "EUREKA",
      state: "KS"
    },
    {
      city: "EUREKA",
      state: "MI"
    },
    {
      city: "EUREKA",
      state: "MO"
    },
    {
      city: "EUREKA",
      state: "MT"
    },
    {
      city: "EUREKA",
      state: "NV"
    },
    {
      city: "EUREKA",
      state: "SD"
    },
    {
      city: "EUREKA",
      state: "UT"
    },
    {
      city: "EUREKA",
      state: "WI"
    },
    {
      city: "EUREKA SPRINGS",
      state: "AR"
    },
    {
      city: "EUSTACE",
      state: "TX"
    },
    {
      city: "EUSTIS",
      state: "FL"
    },
    {
      city: "EUSTIS",
      state: "ME"
    },
    {
      city: "EUSTIS",
      state: "NE"
    },
    {
      city: "EUTAW",
      state: "AL"
    },
    {
      city: "EUTAWVILLE",
      state: "SC"
    },
    {
      city: "EVA",
      state: "AL"
    },
    {
      city: "EVA",
      state: "TN"
    },
    {
      city: "EVADALE",
      state: "TX"
    },
    {
      city: "EVANGELINE",
      state: "LA"
    },
    {
      city: "EVANS",
      state: "CO"
    },
    {
      city: "EVANS",
      state: "GA"
    },
    {
      city: "EVANS",
      state: "LA"
    },
    {
      city: "EVANS",
      state: "WA"
    },
    {
      city: "EVANS",
      state: "WV"
    },
    {
      city: "EVANS CITY",
      state: "PA"
    },
    {
      city: "EVANS MILLS",
      state: "NY"
    },
    {
      city: "EVANSDALE",
      state: "IA"
    },
    {
      city: "EVANSPORT",
      state: "OH"
    },
    {
      city: "EVANSTON",
      state: "IL"
    },
    {
      city: "EVANSTON",
      state: "IN"
    },
    {
      city: "EVANSTON",
      state: "WY"
    },
    {
      city: "EVANSVILLE",
      state: "AR"
    },
    {
      city: "EVANSVILLE",
      state: "IL"
    },
    {
      city: "EVANSVILLE",
      state: "IN"
    },
    {
      city: "EVANSVILLE",
      state: "MN"
    },
    {
      city: "EVANSVILLE",
      state: "WI"
    },
    {
      city: "EVANSVILLE",
      state: "WY"
    },
    {
      city: "EVANT",
      state: "TX"
    },
    {
      city: "EVART",
      state: "MI"
    },
    {
      city: "EVARTS",
      state: "KY"
    },
    {
      city: "EVELETH",
      state: "MN"
    },
    {
      city: "EVENING SHADE",
      state: "AR"
    },
    {
      city: "EVENSVILLE",
      state: "TN"
    },
    {
      city: "EVEREST",
      state: "KS"
    },
    {
      city: "EVERETT",
      state: "MA"
    },
    {
      city: "EVERETT",
      state: "PA"
    },
    {
      city: "EVERETT",
      state: "WA"
    },
    {
      city: "EVERETTS",
      state: "NC"
    },
    {
      city: "EVERGLADES CITY",
      state: "FL"
    },
    {
      city: "EVERGREEN",
      state: "AL"
    },
    {
      city: "EVERGREEN",
      state: "CO"
    },
    {
      city: "EVERGREEN",
      state: "LA"
    },
    {
      city: "EVERGREEN",
      state: "NC"
    },
    {
      city: "EVERGREEN",
      state: "VA"
    },
    {
      city: "EVERGREEN PARK",
      state: "IL"
    },
    {
      city: "EVERLY",
      state: "IA"
    },
    {
      city: "EVERSON",
      state: "PA"
    },
    {
      city: "EVERSON",
      state: "WA"
    },
    {
      city: "EVERTON",
      state: "AR"
    },
    {
      city: "EVERTON",
      state: "MO"
    },
    {
      city: "EVINGTON",
      state: "VA"
    },
    {
      city: "EVINSTON",
      state: "FL"
    },
    {
      city: "EWA BEACH",
      state: "HI"
    },
    {
      city: "EWAN",
      state: "NJ"
    },
    {
      city: "EWELL",
      state: "MD"
    },
    {
      city: "EWEN",
      state: "MI"
    },
    {
      city: "EWING",
      state: "IL"
    },
    {
      city: "EWING",
      state: "KY"
    },
    {
      city: "EWING",
      state: "MO"
    },
    {
      city: "EWING",
      state: "NE"
    },
    {
      city: "EWING",
      state: "VA"
    },
    {
      city: "EXCEL",
      state: "AL"
    },
    {
      city: "EXCELLO",
      state: "MO"
    },
    {
      city: "EXCELSIOR",
      state: "MN"
    },
    {
      city: "EXCELSIOR SPRINGS",
      state: "MO"
    },
    {
      city: "EXCHANGE",
      state: "WV"
    },
    {
      city: "EXELAND",
      state: "WI"
    },
    {
      city: "EXETER",
      state: "CA"
    },
    {
      city: "EXETER",
      state: "ME"
    },
    {
      city: "EXETER",
      state: "MO"
    },
    {
      city: "EXETER",
      state: "NE"
    },
    {
      city: "EXETER",
      state: "NH"
    },
    {
      city: "EXETER",
      state: "RI"
    },
    {
      city: "EXIRA",
      state: "IA"
    },
    {
      city: "EXLINE",
      state: "IA"
    },
    {
      city: "EXMORE",
      state: "VA"
    },
    {
      city: "EXPERIMENT",
      state: "GA"
    },
    {
      city: "EXPORT",
      state: "PA"
    },
    {
      city: "EXTON",
      state: "PA"
    },
    {
      city: "EYOTA",
      state: "MN"
    },
    {
      city: "EZEL",
      state: "KY"
    },
    {
      city: "FABENS",
      state: "TX"
    },
    {
      city: "FABER",
      state: "VA"
    },
    {
      city: "FABIUS",
      state: "NY"
    },
    {
      city: "FACKLER",
      state: "AL"
    },
    {
      city: "FACTORYVILLE",
      state: "PA"
    },
    {
      city: "FAGUS",
      state: "MO"
    },
    {
      city: "FAIR BLUFF",
      state: "NC"
    },
    {
      city: "FAIR GROVE",
      state: "MO"
    },
    {
      city: "FAIR HAVEN",
      state: "MI"
    },
    {
      city: "FAIR HAVEN",
      state: "NJ"
    },
    {
      city: "FAIR HAVEN",
      state: "NY"
    },
    {
      city: "FAIR HAVEN",
      state: "VT"
    },
    {
      city: "FAIR LAWN",
      state: "NJ"
    },
    {
      city: "FAIR OAKS",
      state: "CA"
    },
    {
      city: "FAIR OAKS",
      state: "IN"
    },
    {
      city: "FAIR PLAY",
      state: "MO"
    },
    {
      city: "FAIR PLAY",
      state: "SC"
    },
    {
      city: "FAIRACRES",
      state: "NM"
    },
    {
      city: "FAIRBANK",
      state: "IA"
    },
    {
      city: "FAIRBANK",
      state: "PA"
    },
    {
      city: "FAIRBANKS",
      state: "AK"
    },
    {
      city: "FAIRBANKS",
      state: "IN"
    },
    {
      city: "FAIRBANKS",
      state: "LA"
    },
    {
      city: "FAIRBORN",
      state: "OH"
    },
    {
      city: "FAIRBURN",
      state: "GA"
    },
    {
      city: "FAIRBURN",
      state: "SD"
    },
    {
      city: "FAIRBURY",
      state: "IL"
    },
    {
      city: "FAIRBURY",
      state: "NE"
    },
    {
      city: "FAIRCHANCE",
      state: "PA"
    },
    {
      city: "FAIRCHILD",
      state: "WI"
    },
    {
      city: "FAIRCHILD AIR FORCE BASE",
      state: "WA"
    },
    {
      city: "FAIRDALE",
      state: "KY"
    },
    {
      city: "FAIRDALE",
      state: "ND"
    },
    {
      city: "FAIRDALE",
      state: "WV"
    },
    {
      city: "FAIRDEALING",
      state: "MO"
    },
    {
      city: "FAIRFAX",
      state: "CA"
    },
    {
      city: "FAIRFAX",
      state: "IA"
    },
    {
      city: "FAIRFAX",
      state: "MN"
    },
    {
      city: "FAIRFAX",
      state: "MO"
    },
    {
      city: "FAIRFAX",
      state: "OK"
    },
    {
      city: "FAIRFAX",
      state: "SC"
    },
    {
      city: "FAIRFAX",
      state: "SD"
    },
    {
      city: "FAIRFAX",
      state: "VA"
    },
    {
      city: "FAIRFAX",
      state: "VT"
    },
    {
      city: "FAIRFAX STATION",
      state: "VA"
    },
    {
      city: "FAIRFIELD",
      state: "AL"
    },
    {
      city: "FAIRFIELD",
      state: "CA"
    },
    {
      city: "FAIRFIELD",
      state: "CT"
    },
    {
      city: "FAIRFIELD",
      state: "FL"
    },
    {
      city: "FAIRFIELD",
      state: "IA"
    },
    {
      city: "FAIRFIELD",
      state: "ID"
    },
    {
      city: "FAIRFIELD",
      state: "IL"
    },
    {
      city: "FAIRFIELD",
      state: "KY"
    },
    {
      city: "FAIRFIELD",
      state: "ME"
    },
    {
      city: "FAIRFIELD",
      state: "MT"
    },
    {
      city: "FAIRFIELD",
      state: "NC"
    },
    {
      city: "FAIRFIELD",
      state: "ND"
    },
    {
      city: "FAIRFIELD",
      state: "NE"
    },
    {
      city: "FAIRFIELD",
      state: "NJ"
    },
    {
      city: "FAIRFIELD",
      state: "OH"
    },
    {
      city: "FAIRFIELD",
      state: "PA"
    },
    {
      city: "FAIRFIELD",
      state: "TX"
    },
    {
      city: "FAIRFIELD",
      state: "VA"
    },
    {
      city: "FAIRFIELD",
      state: "VT"
    },
    {
      city: "FAIRFIELD",
      state: "WA"
    },
    {
      city: "FAIRFIELD BAY",
      state: "AR"
    },
    {
      city: "FAIRFOREST",
      state: "SC"
    },
    {
      city: "FAIRGROVE",
      state: "MI"
    },
    {
      city: "FAIRHAVEN",
      state: "MA"
    },
    {
      city: "FAIRHOPE",
      state: "AL"
    },
    {
      city: "FAIRHOPE",
      state: "PA"
    },
    {
      city: "FAIRLAND",
      state: "IN"
    },
    {
      city: "FAIRLAND",
      state: "OK"
    },
    {
      city: "FAIRLAWN",
      state: "OH"
    },
    {
      city: "FAIRLEA",
      state: "WV"
    },
    {
      city: "FAIRLEE",
      state: "VT"
    },
    {
      city: "FAIRLESS HILLS",
      state: "PA"
    },
    {
      city: "FAIRMONT",
      state: "MN"
    },
    {
      city: "FAIRMONT",
      state: "NC"
    },
    {
      city: "FAIRMONT",
      state: "NE"
    },
    {
      city: "FAIRMONT",
      state: "OK"
    },
    {
      city: "FAIRMONT",
      state: "WV"
    },
    {
      city: "FAIRMOUNT",
      state: "GA"
    },
    {
      city: "FAIRMOUNT",
      state: "IL"
    },
    {
      city: "FAIRMOUNT",
      state: "IN"
    },
    {
      city: "FAIRMOUNT",
      state: "ND"
    },
    {
      city: "FAIRMOUNT CITY",
      state: "PA"
    },
    {
      city: "FAIRPLAY",
      state: "CO"
    },
    {
      city: "FAIRPLAY",
      state: "MD"
    },
    {
      city: "FAIRPOINT",
      state: "OH"
    },
    {
      city: "FAIRPORT",
      state: "NY"
    },
    {
      city: "FAIRTON",
      state: "NJ"
    },
    {
      city: "FAIRVIEW",
      state: "IL"
    },
    {
      city: "FAIRVIEW",
      state: "KS"
    },
    {
      city: "FAIRVIEW",
      state: "KY"
    },
    {
      city: "FAIRVIEW",
      state: "MI"
    },
    {
      city: "FAIRVIEW",
      state: "MO"
    },
    {
      city: "FAIRVIEW",
      state: "MT"
    },
    {
      city: "FAIRVIEW",
      state: "NC"
    },
    {
      city: "FAIRVIEW",
      state: "NJ"
    },
    {
      city: "FAIRVIEW",
      state: "OK"
    },
    {
      city: "FAIRVIEW",
      state: "OR"
    },
    {
      city: "FAIRVIEW",
      state: "PA"
    },
    {
      city: "FAIRVIEW",
      state: "SD"
    },
    {
      city: "FAIRVIEW",
      state: "TN"
    },
    {
      city: "FAIRVIEW",
      state: "UT"
    },
    {
      city: "FAIRVIEW",
      state: "WV"
    },
    {
      city: "FAIRVIEW",
      state: "WY"
    },
    {
      city: "FAIRVIEW HEIGHTS",
      state: "IL"
    },
    {
      city: "FAIRVIEW VILLAGE",
      state: "PA"
    },
    {
      city: "FAIRWATER",
      state: "WI"
    },
    {
      city: "FAISON",
      state: "NC"
    },
    {
      city: "FAITH",
      state: "NC"
    },
    {
      city: "FAITH",
      state: "SD"
    },
    {
      city: "FALCON",
      state: "MO"
    },
    {
      city: "FALCON",
      state: "NC"
    },
    {
      city: "FALCONER",
      state: "NY"
    },
    {
      city: "FALFURRIAS",
      state: "TX"
    },
    {
      city: "FALKLAND",
      state: "NC"
    },
    {
      city: "FALKNER",
      state: "MS"
    },
    {
      city: "FALKVILLE",
      state: "AL"
    },
    {
      city: "FALL BRANCH",
      state: "TN"
    },
    {
      city: "FALL CITY",
      state: "WA"
    },
    {
      city: "FALL CREEK",
      state: "OR"
    },
    {
      city: "FALL CREEK",
      state: "WI"
    },
    {
      city: "FALL RIVER",
      state: "KS"
    },
    {
      city: "FALL RIVER",
      state: "MA"
    },
    {
      city: "FALL RIVER",
      state: "WI"
    },
    {
      city: "FALL RIVER MILLS",
      state: "CA"
    },
    {
      city: "FALL ROCK",
      state: "KY"
    },
    {
      city: "FALLBROOK",
      state: "CA"
    },
    {
      city: "FALLENTIMBER",
      state: "PA"
    },
    {
      city: "FALLING WATERS",
      state: "WV"
    },
    {
      city: "FALLON",
      state: "MT"
    },
    {
      city: "FALLON",
      state: "NV"
    },
    {
      city: "FALLS",
      state: "PA"
    },
    {
      city: "FALLS CHURCH",
      state: "VA"
    },
    {
      city: "FALLS CITY",
      state: "NE"
    },
    {
      city: "FALLS CITY",
      state: "OR"
    },
    {
      city: "FALLS CITY",
      state: "TX"
    },
    {
      city: "FALLS CREEK",
      state: "PA"
    },
    {
      city: "FALLS MILLS",
      state: "VA"
    },
    {
      city: "FALLS OF ROUGH",
      state: "KY"
    },
    {
      city: "FALLS VILLAGE",
      state: "CT"
    },
    {
      city: "FALLSBURG",
      state: "NY"
    },
    {
      city: "FALLSTON",
      state: "MD"
    },
    {
      city: "FALLSTON",
      state: "NC"
    },
    {
      city: "FALMOUTH",
      state: "IN"
    },
    {
      city: "FALMOUTH",
      state: "KY"
    },
    {
      city: "FALMOUTH",
      state: "MA"
    },
    {
      city: "FALMOUTH",
      state: "ME"
    },
    {
      city: "FALMOUTH",
      state: "MI"
    },
    {
      city: "FALSE PASS",
      state: "AK"
    },
    {
      city: "FALUN",
      state: "KS"
    },
    {
      city: "FANCHER",
      state: "NY"
    },
    {
      city: "FANCY FARM",
      state: "KY"
    },
    {
      city: "FANCY GAP",
      state: "VA"
    },
    {
      city: "FANNETTSBURG",
      state: "PA"
    },
    {
      city: "FANNIN",
      state: "TX"
    },
    {
      city: "FANWOOD",
      state: "NJ"
    },
    {
      city: "FAR HILLS",
      state: "NJ"
    },
    {
      city: "FAR ROCKAWAY",
      state: "NY"
    },
    {
      city: "FARBER",
      state: "MO"
    },
    {
      city: "FARGO",
      state: "GA"
    },
    {
      city: "FARGO",
      state: "ND"
    },
    {
      city: "FARGO",
      state: "OK"
    },
    {
      city: "FARIBAULT",
      state: "MN"
    },
    {
      city: "FARINA",
      state: "IL"
    },
    {
      city: "FARLEY",
      state: "IA"
    },
    {
      city: "FARLEY",
      state: "MO"
    },
    {
      city: "FARLINGTON",
      state: "KS"
    },
    {
      city: "FARMDALE",
      state: "OH"
    },
    {
      city: "FARMER",
      state: "OH"
    },
    {
      city: "FARMER CITY",
      state: "IL"
    },
    {
      city: "FARMERS",
      state: "KY"
    },
    {
      city: "FARMERSBURG",
      state: "IA"
    },
    {
      city: "FARMERSBURG",
      state: "IN"
    },
    {
      city: "FARMERSVILLE",
      state: "CA"
    },
    {
      city: "FARMERSVILLE",
      state: "IL"
    },
    {
      city: "FARMERSVILLE",
      state: "OH"
    },
    {
      city: "FARMERSVILLE",
      state: "TX"
    },
    {
      city: "FARMERSVILLE STATION",
      state: "NY"
    },
    {
      city: "FARMERVILLE",
      state: "LA"
    },
    {
      city: "FARMINGDALE",
      state: "ME"
    },
    {
      city: "FARMINGDALE",
      state: "NJ"
    },
    {
      city: "FARMINGDALE",
      state: "NY"
    },
    {
      city: "FARMINGTON",
      state: "AR"
    },
    {
      city: "FARMINGTON",
      state: "CA"
    },
    {
      city: "FARMINGTON",
      state: "CT"
    },
    {
      city: "FARMINGTON",
      state: "GA"
    },
    {
      city: "FARMINGTON",
      state: "IA"
    },
    {
      city: "FARMINGTON",
      state: "IL"
    },
    {
      city: "FARMINGTON",
      state: "KY"
    },
    {
      city: "FARMINGTON",
      state: "ME"
    },
    {
      city: "FARMINGTON",
      state: "MI"
    },
    {
      city: "FARMINGTON",
      state: "MN"
    },
    {
      city: "FARMINGTON",
      state: "MO"
    },
    {
      city: "FARMINGTON",
      state: "NH"
    },
    {
      city: "FARMINGTON",
      state: "NM"
    },
    {
      city: "FARMINGTON",
      state: "NY"
    },
    {
      city: "FARMINGTON",
      state: "PA"
    },
    {
      city: "FARMINGTON",
      state: "UT"
    },
    {
      city: "FARMINGTON",
      state: "WA"
    },
    {
      city: "FARMINGTON",
      state: "WV"
    },
    {
      city: "FARMINGVILLE",
      state: "NY"
    },
    {
      city: "FARMLAND",
      state: "IN"
    },
    {
      city: "FARMVILLE",
      state: "NC"
    },
    {
      city: "FARMVILLE",
      state: "VA"
    },
    {
      city: "FARNAM",
      state: "NE"
    },
    {
      city: "FARNER",
      state: "TN"
    },
    {
      city: "FARNHAM",
      state: "NY"
    },
    {
      city: "FARNHAM",
      state: "VA"
    },
    {
      city: "FARNHAMVILLE",
      state: "IA"
    },
    {
      city: "FARNSWORTH",
      state: "TX"
    },
    {
      city: "FARRAGUT",
      state: "IA"
    },
    {
      city: "FARRAR",
      state: "MO"
    },
    {
      city: "FARRELL",
      state: "PA"
    },
    {
      city: "FARSON",
      state: "WY"
    },
    {
      city: "FARWELL",
      state: "MI"
    },
    {
      city: "FARWELL",
      state: "MN"
    },
    {
      city: "FARWELL",
      state: "NE"
    },
    {
      city: "FARWELL",
      state: "TX"
    },
    {
      city: "FATE",
      state: "TX"
    },
    {
      city: "FAUCETT",
      state: "MO"
    },
    {
      city: "FAULKNER",
      state: "MD"
    },
    {
      city: "FAULKTON",
      state: "SD"
    },
    {
      city: "FAUNSDALE",
      state: "AL"
    },
    {
      city: "FAWN GROVE",
      state: "PA"
    },
    {
      city: "FAWNSKIN",
      state: "CA"
    },
    {
      city: "FAXON",
      state: "OK"
    },
    {
      city: "FAY",
      state: "OK"
    },
    {
      city: "FAYETTE",
      state: "AL"
    },
    {
      city: "FAYETTE",
      state: "IA"
    },
    {
      city: "FAYETTE",
      state: "MO"
    },
    {
      city: "FAYETTE",
      state: "MS"
    },
    {
      city: "FAYETTE",
      state: "NY"
    },
    {
      city: "FAYETTE",
      state: "OH"
    },
    {
      city: "FAYETTE",
      state: "UT"
    },
    {
      city: "FAYETTE CITY",
      state: "PA"
    },
    {
      city: "FAYETTEVILLE",
      state: "AR"
    },
    {
      city: "FAYETTEVILLE",
      state: "GA"
    },
    {
      city: "FAYETTEVILLE",
      state: "NC"
    },
    {
      city: "FAYETTEVILLE",
      state: "NY"
    },
    {
      city: "FAYETTEVILLE",
      state: "OH"
    },
    {
      city: "FAYETTEVILLE",
      state: "PA"
    },
    {
      city: "FAYETTEVILLE",
      state: "TN"
    },
    {
      city: "FAYETTEVILLE",
      state: "TX"
    },
    {
      city: "FAYETTEVILLE",
      state: "WV"
    },
    {
      city: "FAYVILLE",
      state: "MA"
    },
    {
      city: "FE WARREN AFB",
      state: "WY"
    },
    {
      city: "FEASTERVILLE TREVOSE",
      state: "PA"
    },
    {
      city: "FEDERAL DAM",
      state: "MN"
    },
    {
      city: "FEDERAL WAY",
      state: "WA"
    },
    {
      city: "FEDERALSBURG",
      state: "MD"
    },
    {
      city: "FEDORA",
      state: "SD"
    },
    {
      city: "FEDSCREEK",
      state: "KY"
    },
    {
      city: "FEEDING HILLS",
      state: "MA"
    },
    {
      city: "FEESBURG",
      state: "OH"
    },
    {
      city: "FELCH",
      state: "MI"
    },
    {
      city: "FELDA",
      state: "FL"
    },
    {
      city: "FELICITY",
      state: "OH"
    },
    {
      city: "FELLOWS",
      state: "CA"
    },
    {
      city: "FELLSMERE",
      state: "FL"
    },
    {
      city: "FELT",
      state: "ID"
    },
    {
      city: "FELT",
      state: "OK"
    },
    {
      city: "FELTON",
      state: "CA"
    },
    {
      city: "FELTON",
      state: "DE"
    },
    {
      city: "FELTON",
      state: "GA"
    },
    {
      city: "FELTON",
      state: "MN"
    },
    {
      city: "FELTON",
      state: "PA"
    },
    {
      city: "FELTS MILLS",
      state: "NY"
    },
    {
      city: "FENCE",
      state: "WI"
    },
    {
      city: "FENCE LAKE",
      state: "NM"
    },
    {
      city: "FENELTON",
      state: "PA"
    },
    {
      city: "FENN",
      state: "ID"
    },
    {
      city: "FENNIMORE",
      state: "WI"
    },
    {
      city: "FENNVILLE",
      state: "MI"
    },
    {
      city: "FENTON",
      state: "IA"
    },
    {
      city: "FENTON",
      state: "IL"
    },
    {
      city: "FENTON",
      state: "LA"
    },
    {
      city: "FENTON",
      state: "MI"
    },
    {
      city: "FENTON",
      state: "MO"
    },
    {
      city: "FENTRESS",
      state: "TX"
    },
    {
      city: "FENWICK",
      state: "MI"
    },
    {
      city: "FENWICK",
      state: "WV"
    },
    {
      city: "FENWICK ISLAND",
      state: "DE"
    },
    {
      city: "FERDINAND",
      state: "ID"
    },
    {
      city: "FERDINAND",
      state: "IN"
    },
    {
      city: "FERGUS FALLS",
      state: "MN"
    },
    {
      city: "FERGUSON",
      state: "IA"
    },
    {
      city: "FERGUSON",
      state: "KY"
    },
    {
      city: "FERGUSON",
      state: "NC"
    },
    {
      city: "FERNANDINA BEACH",
      state: "FL"
    },
    {
      city: "FERNDALE",
      state: "CA"
    },
    {
      city: "FERNDALE",
      state: "FL"
    },
    {
      city: "FERNDALE",
      state: "MI"
    },
    {
      city: "FERNDALE",
      state: "NY"
    },
    {
      city: "FERNDALE",
      state: "PA"
    },
    {
      city: "FERNDALE",
      state: "WA"
    },
    {
      city: "FERNEY",
      state: "SD"
    },
    {
      city: "FERNLEY",
      state: "NV"
    },
    {
      city: "FERNWOOD",
      state: "ID"
    },
    {
      city: "FERNWOOD",
      state: "MS"
    },
    {
      city: "FERRIDAY",
      state: "LA"
    },
    {
      city: "FERRIS",
      state: "IL"
    },
    {
      city: "FERRIS",
      state: "TX"
    },
    {
      city: "FERRISBURGH",
      state: "VT"
    },
    {
      city: "FERRON",
      state: "UT"
    },
    {
      city: "FERRUM",
      state: "VA"
    },
    {
      city: "FERRYSBURG",
      state: "MI"
    },
    {
      city: "FERRYVILLE",
      state: "WI"
    },
    {
      city: "FERTILE",
      state: "IA"
    },
    {
      city: "FERTILE",
      state: "MN"
    },
    {
      city: "FESSENDEN",
      state: "ND"
    },
    {
      city: "FESTUS",
      state: "MO"
    },
    {
      city: "FEURA BUSH",
      state: "NY"
    },
    {
      city: "FIATT",
      state: "IL"
    },
    {
      city: "FIDDLETOWN",
      state: "CA"
    },
    {
      city: "FIELDALE",
      state: "VA"
    },
    {
      city: "FIELDING",
      state: "UT"
    },
    {
      city: "FIELDON",
      state: "IL"
    },
    {
      city: "FIELDS",
      state: "OR"
    },
    {
      city: "FIELDS LANDING",
      state: "CA"
    },
    {
      city: "FIELDTON",
      state: "TX"
    },
    {
      city: "FIFE LAKE",
      state: "MI"
    },
    {
      city: "FIFIELD",
      state: "WI"
    },
    {
      city: "FIFTY LAKES",
      state: "MN"
    },
    {
      city: "FIFTY SIX",
      state: "AR"
    },
    {
      city: "FILER",
      state: "ID"
    },
    {
      city: "FILER CITY",
      state: "MI"
    },
    {
      city: "FILION",
      state: "MI"
    },
    {
      city: "FILLEY",
      state: "NE"
    },
    {
      city: "FILLMORE",
      state: "CA"
    },
    {
      city: "FILLMORE",
      state: "IL"
    },
    {
      city: "FILLMORE",
      state: "IN"
    },
    {
      city: "FILLMORE",
      state: "MO"
    },
    {
      city: "FILLMORE",
      state: "NY"
    },
    {
      city: "FILLMORE",
      state: "UT"
    },
    {
      city: "FINCASTLE",
      state: "VA"
    },
    {
      city: "FINCHVILLE",
      state: "KY"
    },
    {
      city: "FINDLAY",
      state: "IL"
    },
    {
      city: "FINDLAY",
      state: "OH"
    },
    {
      city: "FINDLEY LAKE",
      state: "NY"
    },
    {
      city: "FINE",
      state: "NY"
    },
    {
      city: "FINGAL",
      state: "ND"
    },
    {
      city: "FINGER",
      state: "TN"
    },
    {
      city: "FINGERVILLE",
      state: "SC"
    },
    {
      city: "FINKSBURG",
      state: "MD"
    },
    {
      city: "FINLAND",
      state: "MN"
    },
    {
      city: "FINLAYSON",
      state: "MN"
    },
    {
      city: "FINLEY",
      state: "CA"
    },
    {
      city: "FINLEY",
      state: "ND"
    },
    {
      city: "FINLEY",
      state: "OK"
    },
    {
      city: "FINLEY",
      state: "TN"
    },
    {
      city: "FINLEYVILLE",
      state: "PA"
    },
    {
      city: "FINLY",
      state: "IN"
    },
    {
      city: "FIREBAUGH",
      state: "CA"
    },
    {
      city: "FIRESTONE",
      state: "CO"
    },
    {
      city: "FIRTH",
      state: "ID"
    },
    {
      city: "FIRTH",
      state: "NE"
    },
    {
      city: "FISCHER",
      state: "TX"
    },
    {
      city: "FISH CAMP",
      state: "CA"
    },
    {
      city: "FISH CREEK",
      state: "WI"
    },
    {
      city: "FISH HAVEN",
      state: "ID"
    },
    {
      city: "FISHER",
      state: "AR"
    },
    {
      city: "FISHER",
      state: "IL"
    },
    {
      city: "FISHER",
      state: "LA"
    },
    {
      city: "FISHER",
      state: "MN"
    },
    {
      city: "FISHER",
      state: "WV"
    },
    {
      city: "FISHERS",
      state: "IN"
    },
    {
      city: "FISHERS",
      state: "NY"
    },
    {
      city: "FISHERS HILL",
      state: "VA"
    },
    {
      city: "FISHERS ISLAND",
      state: "NY"
    },
    {
      city: "FISHERS LANDING",
      state: "NY"
    },
    {
      city: "FISHERSVILLE",
      state: "VA"
    },
    {
      city: "FISHERTOWN",
      state: "PA"
    },
    {
      city: "FISHERVILLE",
      state: "KY"
    },
    {
      city: "FISHING CREEK",
      state: "MD"
    },
    {
      city: "FISHKILL",
      state: "NY"
    },
    {
      city: "FISHS EDDY",
      state: "NY"
    },
    {
      city: "FISHTAIL",
      state: "MT"
    },
    {
      city: "FISK",
      state: "MO"
    },
    {
      city: "FISKDALE",
      state: "MA"
    },
    {
      city: "FISKEVILLE",
      state: "RI"
    },
    {
      city: "FISTY",
      state: "KY"
    },
    {
      city: "FITCHBURG",
      state: "MA"
    },
    {
      city: "FITHIAN",
      state: "IL"
    },
    {
      city: "FITTSTOWN",
      state: "OK"
    },
    {
      city: "FITZGERALD",
      state: "GA"
    },
    {
      city: "FITZHUGH",
      state: "OK"
    },
    {
      city: "FITZPATRICK",
      state: "AL"
    },
    {
      city: "FITZWILLIAM",
      state: "NH"
    },
    {
      city: "FIVE POINTS",
      state: "AL"
    },
    {
      city: "FIVE POINTS",
      state: "CA"
    },
    {
      city: "FIVE POINTS",
      state: "TN"
    },
    {
      city: "FLAG POND",
      state: "TN"
    },
    {
      city: "FLAGLER",
      state: "CO"
    },
    {
      city: "FLAGLER BEACH",
      state: "FL"
    },
    {
      city: "FLAGSTAFF",
      state: "AZ"
    },
    {
      city: "FLAGTOWN",
      state: "NJ"
    },
    {
      city: "FLANAGAN",
      state: "IL"
    },
    {
      city: "FLANDERS",
      state: "NJ"
    },
    {
      city: "FLANDREAU",
      state: "SD"
    },
    {
      city: "FLASHER",
      state: "ND"
    },
    {
      city: "FLAT",
      state: "TX"
    },
    {
      city: "FLAT LICK",
      state: "KY"
    },
    {
      city: "FLAT ROCK",
      state: "AL"
    },
    {
      city: "FLAT ROCK",
      state: "IL"
    },
    {
      city: "FLAT ROCK",
      state: "IN"
    },
    {
      city: "FLAT ROCK",
      state: "MI"
    },
    {
      city: "FLAT ROCK",
      state: "NC"
    },
    {
      city: "FLAT ROCK",
      state: "OH"
    },
    {
      city: "FLAT TOP",
      state: "WV"
    },
    {
      city: "FLATGAP",
      state: "KY"
    },
    {
      city: "FLATONIA",
      state: "TX"
    },
    {
      city: "FLATWOODS",
      state: "KY"
    },
    {
      city: "FLATWOODS",
      state: "LA"
    },
    {
      city: "FLATWOODS",
      state: "WV"
    },
    {
      city: "FLAXTON",
      state: "ND"
    },
    {
      city: "FLAXVILLE",
      state: "MT"
    },
    {
      city: "FLEETVILLE",
      state: "PA"
    },
    {
      city: "FLEETWOOD",
      state: "NC"
    },
    {
      city: "FLEETWOOD",
      state: "PA"
    },
    {
      city: "FLEISCHMANNS",
      state: "NY"
    },
    {
      city: "FLEMING",
      state: "CO"
    },
    {
      city: "FLEMING",
      state: "GA"
    },
    {
      city: "FLEMING",
      state: "OH"
    },
    {
      city: "FLEMING",
      state: "PA"
    },
    {
      city: "FLEMING ISLAND",
      state: "FL"
    },
    {
      city: "FLEMINGSBURG",
      state: "KY"
    },
    {
      city: "FLEMINGTON",
      state: "MO"
    },
    {
      city: "FLEMINGTON",
      state: "NJ"
    },
    {
      city: "FLEMINGTON",
      state: "WV"
    },
    {
      city: "FLENSBURG",
      state: "MN"
    },
    {
      city: "FLETCHER",
      state: "MO"
    },
    {
      city: "FLETCHER",
      state: "NC"
    },
    {
      city: "FLETCHER",
      state: "OH"
    },
    {
      city: "FLETCHER",
      state: "OK"
    },
    {
      city: "FLICKSVILLE",
      state: "PA"
    },
    {
      city: "FLINT",
      state: "MI"
    },
    {
      city: "FLINT",
      state: "TX"
    },
    {
      city: "FLINT HILL",
      state: "VA"
    },
    {
      city: "FLINTHILL",
      state: "MO"
    },
    {
      city: "FLINTON",
      state: "PA"
    },
    {
      city: "FLINTSTONE",
      state: "GA"
    },
    {
      city: "FLINTSTONE",
      state: "MD"
    },
    {
      city: "FLINTVILLE",
      state: "TN"
    },
    {
      city: "FLIPPIN",
      state: "AR"
    },
    {
      city: "FLOM",
      state: "MN"
    },
    {
      city: "FLOMATON",
      state: "AL"
    },
    {
      city: "FLOMOT",
      state: "TX"
    },
    {
      city: "FLOODWOOD",
      state: "MN"
    },
    {
      city: "FLORA",
      state: "IL"
    },
    {
      city: "FLORA",
      state: "IN"
    },
    {
      city: "FLORA",
      state: "LA"
    },
    {
      city: "FLORA",
      state: "MS"
    },
    {
      city: "FLORA VISTA",
      state: "NM"
    },
    {
      city: "FLORAHOME",
      state: "FL"
    },
    {
      city: "FLORAL",
      state: "AR"
    },
    {
      city: "FLORAL CITY",
      state: "FL"
    },
    {
      city: "FLORAL PARK",
      state: "NY"
    },
    {
      city: "FLORALA",
      state: "AL"
    },
    {
      city: "FLORENCE",
      state: "AL"
    },
    {
      city: "FLORENCE",
      state: "AZ"
    },
    {
      city: "FLORENCE",
      state: "CO"
    },
    {
      city: "FLORENCE",
      state: "IN"
    },
    {
      city: "FLORENCE",
      state: "KS"
    },
    {
      city: "FLORENCE",
      state: "KY"
    },
    {
      city: "FLORENCE",
      state: "MA"
    },
    {
      city: "FLORENCE",
      state: "MO"
    },
    {
      city: "FLORENCE",
      state: "MS"
    },
    {
      city: "FLORENCE",
      state: "MT"
    },
    {
      city: "FLORENCE",
      state: "NJ"
    },
    {
      city: "FLORENCE",
      state: "OR"
    },
    {
      city: "FLORENCE",
      state: "SC"
    },
    {
      city: "FLORENCE",
      state: "SD"
    },
    {
      city: "FLORENCE",
      state: "TX"
    },
    {
      city: "FLORENCE",
      state: "VT"
    },
    {
      city: "FLORENCE",
      state: "WI"
    },
    {
      city: "FLORESVILLE",
      state: "TX"
    },
    {
      city: "FLORHAM PARK",
      state: "NJ"
    },
    {
      city: "FLORIDA",
      state: "NY"
    },
    {
      city: "FLORIEN",
      state: "LA"
    },
    {
      city: "FLORIS",
      state: "IA"
    },
    {
      city: "FLORISSANT",
      state: "CO"
    },
    {
      city: "FLORISSANT",
      state: "MO"
    },
    {
      city: "FLORISTON",
      state: "CA"
    },
    {
      city: "FLOSSMOOR",
      state: "IL"
    },
    {
      city: "FLOURNOY",
      state: "CA"
    },
    {
      city: "FLOURTOWN",
      state: "PA"
    },
    {
      city: "FLOVILLA",
      state: "GA"
    },
    {
      city: "FLOWER MOUND",
      state: "TX"
    },
    {
      city: "FLOWEREE",
      state: "MT"
    },
    {
      city: "FLOWERY BRANCH",
      state: "GA"
    },
    {
      city: "FLOWOOD",
      state: "MS"
    },
    {
      city: "FLOYD",
      state: "IA"
    },
    {
      city: "FLOYD",
      state: "NM"
    },
    {
      city: "FLOYD",
      state: "VA"
    },
    {
      city: "FLOYDADA",
      state: "TX"
    },
    {
      city: "FLOYDS KNOBS",
      state: "IN"
    },
    {
      city: "FLUKER",
      state: "LA"
    },
    {
      city: "FLUSHING",
      state: "MI"
    },
    {
      city: "FLUSHING",
      state: "NY"
    },
    {
      city: "FLUSHING",
      state: "OH"
    },
    {
      city: "FLUVANNA",
      state: "TX"
    },
    {
      city: "FLY CREEK",
      state: "NY"
    },
    {
      city: "FLYNN",
      state: "TX"
    },
    {
      city: "FOGELSVILLE",
      state: "PA"
    },
    {
      city: "FOLCROFT",
      state: "PA"
    },
    {
      city: "FOLEY",
      state: "AL"
    },
    {
      city: "FOLEY",
      state: "MN"
    },
    {
      city: "FOLEY",
      state: "MO"
    },
    {
      city: "FOLKSTON",
      state: "GA"
    },
    {
      city: "FOLLANSBEE",
      state: "WV"
    },
    {
      city: "FOLLETT",
      state: "TX"
    },
    {
      city: "FOLLY BEACH",
      state: "SC"
    },
    {
      city: "FOLSOM",
      state: "CA"
    },
    {
      city: "FOLSOM",
      state: "LA"
    },
    {
      city: "FOLSOM",
      state: "NM"
    },
    {
      city: "FOLSOM",
      state: "PA"
    },
    {
      city: "FOLSOM",
      state: "WV"
    },
    {
      city: "FOMBELL",
      state: "PA"
    },
    {
      city: "FOND DU LAC",
      state: "WI"
    },
    {
      city: "FONDA",
      state: "IA"
    },
    {
      city: "FONDA",
      state: "NY"
    },
    {
      city: "FONTANA",
      state: "CA"
    },
    {
      city: "FONTANA",
      state: "KS"
    },
    {
      city: "FONTANA",
      state: "WI"
    },
    {
      city: "FONTANA DAM",
      state: "NC"
    },
    {
      city: "FONTANELLE",
      state: "IA"
    },
    {
      city: "FONTANET",
      state: "IN"
    },
    {
      city: "FOOSLAND",
      state: "IL"
    },
    {
      city: "FOOTHILL RANCH",
      state: "CA"
    },
    {
      city: "FOOTVILLE",
      state: "WI"
    },
    {
      city: "FORBES",
      state: "MN"
    },
    {
      city: "FORBES",
      state: "ND"
    },
    {
      city: "FORBES ROAD",
      state: "PA"
    },
    {
      city: "FORBESTOWN",
      state: "CA"
    },
    {
      city: "FORCE",
      state: "PA"
    },
    {
      city: "FORD",
      state: "KS"
    },
    {
      city: "FORD",
      state: "VA"
    },
    {
      city: "FORD",
      state: "WA"
    },
    {
      city: "FORD CITY",
      state: "PA"
    },
    {
      city: "FORD CLIFF",
      state: "PA"
    },
    {
      city: "FORDLAND",
      state: "MO"
    },
    {
      city: "FORDOCHE",
      state: "LA"
    },
    {
      city: "FORDS",
      state: "NJ"
    },
    {
      city: "FORDSVILLE",
      state: "KY"
    },
    {
      city: "FORDVILLE",
      state: "ND"
    },
    {
      city: "FORDYCE",
      state: "AR"
    },
    {
      city: "FORDYCE",
      state: "NE"
    },
    {
      city: "FOREMAN",
      state: "AR"
    },
    {
      city: "FOREST",
      state: "IN"
    },
    {
      city: "FOREST",
      state: "LA"
    },
    {
      city: "FOREST",
      state: "MS"
    },
    {
      city: "FOREST",
      state: "OH"
    },
    {
      city: "FOREST",
      state: "VA"
    },
    {
      city: "FOREST CITY",
      state: "IA"
    },
    {
      city: "FOREST CITY",
      state: "IL"
    },
    {
      city: "FOREST CITY",
      state: "MO"
    },
    {
      city: "FOREST CITY",
      state: "NC"
    },
    {
      city: "FOREST CITY",
      state: "PA"
    },
    {
      city: "FOREST DALE",
      state: "VT"
    },
    {
      city: "FOREST FALLS",
      state: "CA"
    },
    {
      city: "FOREST GROVE",
      state: "MT"
    },
    {
      city: "FOREST GROVE",
      state: "OR"
    },
    {
      city: "FOREST GROVE",
      state: "PA"
    },
    {
      city: "FOREST HILL",
      state: "LA"
    },
    {
      city: "FOREST HILL",
      state: "MD"
    },
    {
      city: "FOREST HILL",
      state: "WV"
    },
    {
      city: "FOREST HILLS",
      state: "KY"
    },
    {
      city: "FOREST HILLS",
      state: "NY"
    },
    {
      city: "FOREST HOME",
      state: "AL"
    },
    {
      city: "FOREST JUNCTION",
      state: "WI"
    },
    {
      city: "FOREST KNOLLS",
      state: "CA"
    },
    {
      city: "FOREST LAKE",
      state: "MN"
    },
    {
      city: "FOREST LAKES",
      state: "AZ"
    },
    {
      city: "FOREST PARK",
      state: "GA"
    },
    {
      city: "FOREST PARK",
      state: "IL"
    },
    {
      city: "FOREST RANCH",
      state: "CA"
    },
    {
      city: "FOREST RIVER",
      state: "ND"
    },
    {
      city: "FORESTBURG",
      state: "TX"
    },
    {
      city: "FORESTBURGH",
      state: "NY"
    },
    {
      city: "FORESTDALE",
      state: "MA"
    },
    {
      city: "FORESTDALE",
      state: "RI"
    },
    {
      city: "FORESTHILL",
      state: "CA"
    },
    {
      city: "FORESTON",
      state: "MN"
    },
    {
      city: "FORESTPORT",
      state: "NY"
    },
    {
      city: "FORESTVILLE",
      state: "CA"
    },
    {
      city: "FORESTVILLE",
      state: "MI"
    },
    {
      city: "FORESTVILLE",
      state: "NY"
    },
    {
      city: "FORESTVILLE",
      state: "PA"
    },
    {
      city: "FORESTVILLE",
      state: "WI"
    },
    {
      city: "FORGAN",
      state: "OK"
    },
    {
      city: "FORISTELL",
      state: "MO"
    },
    {
      city: "FORK",
      state: "MD"
    },
    {
      city: "FORK",
      state: "SC"
    },
    {
      city: "FORK UNION",
      state: "VA"
    },
    {
      city: "FORKED RIVER",
      state: "NJ"
    },
    {
      city: "FORKS",
      state: "WA"
    },
    {
      city: "FORKS OF SALMON",
      state: "CA"
    },
    {
      city: "FORKSVILLE",
      state: "PA"
    },
    {
      city: "FORMAN",
      state: "ND"
    },
    {
      city: "FORMOSO",
      state: "KS"
    },
    {
      city: "FORNEY",
      state: "TX"
    },
    {
      city: "FORREST",
      state: "IL"
    },
    {
      city: "FORREST CITY",
      state: "AR"
    },
    {
      city: "FORRESTON",
      state: "IL"
    },
    {
      city: "FORRESTON",
      state: "TX"
    },
    {
      city: "FORSAN",
      state: "TX"
    },
    {
      city: "FORSYTH",
      state: "GA"
    },
    {
      city: "FORSYTH",
      state: "IL"
    },
    {
      city: "FORSYTH",
      state: "MO"
    },
    {
      city: "FORSYTH",
      state: "MT"
    },
    {
      city: "FORT ANN",
      state: "NY"
    },
    {
      city: "FORT APACHE",
      state: "AZ"
    },
    {
      city: "FORT ASHBY",
      state: "WV"
    },
    {
      city: "FORT ATKINSON",
      state: "IA"
    },
    {
      city: "FORT ATKINSON",
      state: "WI"
    },
    {
      city: "FORT BELVOIR",
      state: "VA"
    },
    {
      city: "FORT BENNING",
      state: "GA"
    },
    {
      city: "FORT BENTON",
      state: "MT"
    },
    {
      city: "FORT BIDWELL",
      state: "CA"
    },
    {
      city: "FORT BLACKMORE",
      state: "VA"
    },
    {
      city: "FORT BLISS",
      state: "TX"
    },
    {
      city: "FORT BRAGG",
      state: "CA"
    },
    {
      city: "FORT BRAGG",
      state: "NC"
    },
    {
      city: "FORT BRANCH",
      state: "IN"
    },
    {
      city: "FORT BRIDGER",
      state: "WY"
    },
    {
      city: "FORT CALHOUN",
      state: "NE"
    },
    {
      city: "FORT CAMPBELL",
      state: "KY"
    },
    {
      city: "FORT COBB",
      state: "OK"
    },
    {
      city: "FORT COLLINS",
      state: "CO"
    },
    {
      city: "FORT COVINGTON",
      state: "NY"
    },
    {
      city: "FORT DAVIS",
      state: "TX"
    },
    {
      city: "FORT DEFIANCE",
      state: "AZ"
    },
    {
      city: "FORT DEFIANCE",
      state: "VA"
    },
    {
      city: "FORT DEPOSIT",
      state: "AL"
    },
    {
      city: "FORT DICK",
      state: "CA"
    },
    {
      city: "FORT DIX",
      state: "NJ"
    },
    {
      city: "FORT DODGE",
      state: "IA"
    },
    {
      city: "FORT DRUM",
      state: "NY"
    },
    {
      city: "FORT DUCHESNE",
      state: "UT"
    },
    {
      city: "FORT EDWARD",
      state: "NY"
    },
    {
      city: "FORT EUSTIS",
      state: "VA"
    },
    {
      city: "FORT FAIRFIELD",
      state: "ME"
    },
    {
      city: "FORT GAINES",
      state: "GA"
    },
    {
      city: "FORT GARLAND",
      state: "CO"
    },
    {
      city: "FORT GAY",
      state: "WV"
    },
    {
      city: "FORT GEORGE G MEADE",
      state: "MD"
    },
    {
      city: "FORT GIBSON",
      state: "OK"
    },
    {
      city: "FORT GRATIOT",
      state: "MI"
    },
    {
      city: "FORT GREELY",
      state: "AK"
    },
    {
      city: "FORT HALL",
      state: "ID"
    },
    {
      city: "FORT HANCOCK",
      state: "TX"
    },
    {
      city: "FORT HARRISON",
      state: "MT"
    },
    {
      city: "FORT HILL",
      state: "PA"
    },
    {
      city: "FORT HOOD",
      state: "TX"
    },
    {
      city: "FORT HOWARD",
      state: "MD"
    },
    {
      city: "FORT HUACHUCA",
      state: "AZ"
    },
    {
      city: "FORT HUNTER",
      state: "NY"
    },
    {
      city: "FORT IRWIN",
      state: "CA"
    },
    {
      city: "FORT JENNINGS",
      state: "OH"
    },
    {
      city: "FORT JOHNSON",
      state: "NY"
    },
    {
      city: "FORT JONES",
      state: "CA"
    },
    {
      city: "FORT KENT",
      state: "ME"
    },
    {
      city: "FORT KENT MILLS",
      state: "ME"
    },
    {
      city: "FORT KLAMATH",
      state: "OR"
    },
    {
      city: "FORT KNOX",
      state: "KY"
    },
    {
      city: "FORT LARAMIE",
      state: "WY"
    },
    {
      city: "FORT LAUDERDALE",
      state: "FL"
    },
    {
      city: "FORT LAWN",
      state: "SC"
    },
    {
      city: "FORT LEAVENWORTH",
      state: "KS"
    },
    {
      city: "FORT LEE",
      state: "NJ"
    },
    {
      city: "FORT LEE",
      state: "VA"
    },
    {
      city: "FORT LEONARD WOOD",
      state: "MO"
    },
    {
      city: "FORT LITTLETON",
      state: "PA"
    },
    {
      city: "FORT LORAMIE",
      state: "OH"
    },
    {
      city: "FORT LOUDON",
      state: "PA"
    },
    {
      city: "FORT LUPTON",
      state: "CO"
    },
    {
      city: "FORT LYON",
      state: "CO"
    },
    {
      city: "FORT MADISON",
      state: "IA"
    },
    {
      city: "FORT MC COY",
      state: "FL"
    },
    {
      city: "FORT MCDOWELL",
      state: "AZ"
    },
    {
      city: "FORT MEADE",
      state: "FL"
    },
    {
      city: "FORT MEADE",
      state: "SD"
    },
    {
      city: "FORT MILL",
      state: "SC"
    },
    {
      city: "FORT MITCHELL",
      state: "AL"
    },
    {
      city: "FORT MOHAVE",
      state: "AZ"
    },
    {
      city: "FORT MONMOUTH",
      state: "NJ"
    },
    {
      city: "FORT MONROE",
      state: "VA"
    },
    {
      city: "FORT MONTGOMERY",
      state: "NY"
    },
    {
      city: "FORT MORGAN",
      state: "CO"
    },
    {
      city: "FORT MYER",
      state: "VA"
    },
    {
      city: "FORT MYERS",
      state: "FL"
    },
    {
      city: "FORT MYERS BEACH",
      state: "FL"
    },
    {
      city: "FORT NECESSITY",
      state: "LA"
    },
    {
      city: "FORT OGDEN",
      state: "FL"
    },
    {
      city: "FORT OGLETHORPE",
      state: "GA"
    },
    {
      city: "FORT PAYNE",
      state: "AL"
    },
    {
      city: "FORT PECK",
      state: "MT"
    },
    {
      city: "FORT PIERCE",
      state: "FL"
    },
    {
      city: "FORT PIERRE",
      state: "SD"
    },
    {
      city: "FORT PLAIN",
      state: "NY"
    },
    {
      city: "FORT POLK",
      state: "LA"
    },
    {
      city: "FORT RANSOM",
      state: "ND"
    },
    {
      city: "FORT RECOVERY",
      state: "OH"
    },
    {
      city: "FORT RILEY",
      state: "KS"
    },
    {
      city: "FORT RIPLEY",
      state: "MN"
    },
    {
      city: "FORT ROCK",
      state: "OR"
    },
    {
      city: "FORT RUCKER",
      state: "AL"
    },
    {
      city: "FORT SCOTT",
      state: "KS"
    },
    {
      city: "FORT SHAFTER",
      state: "HI"
    },
    {
      city: "FORT SHAW",
      state: "MT"
    },
    {
      city: "FORT SHERIDAN",
      state: "IL"
    },
    {
      city: "FORT SILL",
      state: "OK"
    },
    {
      city: "FORT SMITH",
      state: "AR"
    },
    {
      city: "FORT SMITH",
      state: "MT"
    },
    {
      city: "FORT STANTON",
      state: "NM"
    },
    {
      city: "FORT STEWART",
      state: "GA"
    },
    {
      city: "FORT STOCKTON",
      state: "TX"
    },
    {
      city: "FORT SUMNER",
      state: "NM"
    },
    {
      city: "FORT SUPPLY",
      state: "OK"
    },
    {
      city: "FORT THOMAS",
      state: "AZ"
    },
    {
      city: "FORT THOMAS",
      state: "KY"
    },
    {
      city: "FORT THOMPSON",
      state: "SD"
    },
    {
      city: "FORT TOTTEN",
      state: "ND"
    },
    {
      city: "FORT TOWSON",
      state: "OK"
    },
    {
      city: "FORT VALLEY",
      state: "GA"
    },
    {
      city: "FORT VALLEY",
      state: "VA"
    },
    {
      city: "FORT WAINWRIGHT",
      state: "AK"
    },
    {
      city: "FORT WALTON BEACH",
      state: "FL"
    },
    {
      city: "FORT WASHAKIE",
      state: "WY"
    },
    {
      city: "FORT WASHINGTON",
      state: "MD"
    },
    {
      city: "FORT WASHINGTON",
      state: "PA"
    },
    {
      city: "FORT WAYNE",
      state: "IN"
    },
    {
      city: "FORT WHITE",
      state: "FL"
    },
    {
      city: "FORT WINGATE",
      state: "NM"
    },
    {
      city: "FORT WORTH",
      state: "TX"
    },
    {
      city: "FORT YATES",
      state: "ND"
    },
    {
      city: "FORT YUKON",
      state: "AK"
    },
    {
      city: "FORTESCUE",
      state: "NJ"
    },
    {
      city: "FORTINE",
      state: "MT"
    },
    {
      city: "FORTSON",
      state: "GA"
    },
    {
      city: "FORTUNA",
      state: "CA"
    },
    {
      city: "FORTUNA",
      state: "MO"
    },
    {
      city: "FORTUNA",
      state: "ND"
    },
    {
      city: "FORTVILLE",
      state: "IN"
    },
    {
      city: "FOSS",
      state: "OK"
    },
    {
      city: "FOSSIL",
      state: "OR"
    },
    {
      city: "FOSSTON",
      state: "MN"
    },
    {
      city: "FOSTER",
      state: "KY"
    },
    {
      city: "FOSTER",
      state: "MO"
    },
    {
      city: "FOSTER",
      state: "OK"
    },
    {
      city: "FOSTER",
      state: "OR"
    },
    {
      city: "FOSTER",
      state: "RI"
    },
    {
      city: "FOSTER",
      state: "VA"
    },
    {
      city: "FOSTER",
      state: "WV"
    },
    {
      city: "FOSTER CITY",
      state: "MI"
    },
    {
      city: "FOSTERS",
      state: "AL"
    },
    {
      city: "FOSTERVILLE",
      state: "TN"
    },
    {
      city: "FOSTORIA",
      state: "IA"
    },
    {
      city: "FOSTORIA",
      state: "MI"
    },
    {
      city: "FOSTORIA",
      state: "OH"
    },
    {
      city: "FOUKE",
      state: "AR"
    },
    {
      city: "FOUNTAIN",
      state: "CO"
    },
    {
      city: "FOUNTAIN",
      state: "FL"
    },
    {
      city: "FOUNTAIN",
      state: "MI"
    },
    {
      city: "FOUNTAIN",
      state: "MN"
    },
    {
      city: "FOUNTAIN",
      state: "NC"
    },
    {
      city: "FOUNTAIN CITY",
      state: "IN"
    },
    {
      city: "FOUNTAIN CITY",
      state: "WI"
    },
    {
      city: "FOUNTAIN GREEN",
      state: "UT"
    },
    {
      city: "FOUNTAIN HILL",
      state: "AR"
    },
    {
      city: "FOUNTAIN HILLS",
      state: "AZ"
    },
    {
      city: "FOUNTAIN INN",
      state: "SC"
    },
    {
      city: "FOUNTAIN RUN",
      state: "KY"
    },
    {
      city: "FOUNTAIN VALLEY",
      state: "CA"
    },
    {
      city: "FOUNTAINTOWN",
      state: "IN"
    },
    {
      city: "FOUNTAINVILLE",
      state: "PA"
    },
    {
      city: "FOUR LAKES",
      state: "WA"
    },
    {
      city: "FOUR OAKS",
      state: "NC"
    },
    {
      city: "FOURMILE",
      state: "KY"
    },
    {
      city: "FOWLER",
      state: "CA"
    },
    {
      city: "FOWLER",
      state: "CO"
    },
    {
      city: "FOWLER",
      state: "IL"
    },
    {
      city: "FOWLER",
      state: "IN"
    },
    {
      city: "FOWLER",
      state: "KS"
    },
    {
      city: "FOWLER",
      state: "MI"
    },
    {
      city: "FOWLER",
      state: "OH"
    },
    {
      city: "FOWLERTON",
      state: "IN"
    },
    {
      city: "FOWLERTON",
      state: "TX"
    },
    {
      city: "FOWLERVILLE",
      state: "MI"
    },
    {
      city: "FOX",
      state: "AR"
    },
    {
      city: "FOX",
      state: "OK"
    },
    {
      city: "FOX ISLAND",
      state: "WA"
    },
    {
      city: "FOX LAKE",
      state: "IL"
    },
    {
      city: "FOX LAKE",
      state: "WI"
    },
    {
      city: "FOX RIVER GROVE",
      state: "IL"
    },
    {
      city: "FOXBORO",
      state: "MA"
    },
    {
      city: "FOXBORO",
      state: "WI"
    },
    {
      city: "FOXBURG",
      state: "PA"
    },
    {
      city: "FOXHOME",
      state: "MN"
    },
    {
      city: "FOXWORTH",
      state: "MS"
    },
    {
      city: "FOYIL",
      state: "OK"
    },
    {
      city: "FRACKVILLE",
      state: "PA"
    },
    {
      city: "FRAKES",
      state: "KY"
    },
    {
      city: "FRAMETOWN",
      state: "WV"
    },
    {
      city: "FRAMINGHAM",
      state: "MA"
    },
    {
      city: "FRANCESTOWN",
      state: "NH"
    },
    {
      city: "FRANCESVILLE",
      state: "IN"
    },
    {
      city: "FRANCIS",
      state: "OK"
    },
    {
      city: "FRANCIS CREEK",
      state: "WI"
    },
    {
      city: "FRANCISCO",
      state: "IN"
    },
    {
      city: "FRANCITAS",
      state: "TX"
    },
    {
      city: "FRANCONIA",
      state: "NH"
    },
    {
      city: "FRANCONIA",
      state: "PA"
    },
    {
      city: "FRANKENMUTH",
      state: "MI"
    },
    {
      city: "FRANKEWING",
      state: "TN"
    },
    {
      city: "FRANKFORD",
      state: "DE"
    },
    {
      city: "FRANKFORD",
      state: "MO"
    },
    {
      city: "FRANKFORD",
      state: "WV"
    },
    {
      city: "FRANKFORT",
      state: "IL"
    },
    {
      city: "FRANKFORT",
      state: "IN"
    },
    {
      city: "FRANKFORT",
      state: "KS"
    },
    {
      city: "FRANKFORT",
      state: "KY"
    },
    {
      city: "FRANKFORT",
      state: "ME"
    },
    {
      city: "FRANKFORT",
      state: "MI"
    },
    {
      city: "FRANKFORT",
      state: "NY"
    },
    {
      city: "FRANKFORT",
      state: "OH"
    },
    {
      city: "FRANKFORT",
      state: "SD"
    },
    {
      city: "FRANKLIN",
      state: "AL"
    },
    {
      city: "FRANKLIN",
      state: "AR"
    },
    {
      city: "FRANKLIN",
      state: "GA"
    },
    {
      city: "FRANKLIN",
      state: "ID"
    },
    {
      city: "FRANKLIN",
      state: "IL"
    },
    {
      city: "FRANKLIN",
      state: "IN"
    },
    {
      city: "FRANKLIN",
      state: "KS"
    },
    {
      city: "FRANKLIN",
      state: "KY"
    },
    {
      city: "FRANKLIN",
      state: "LA"
    },
    {
      city: "FRANKLIN",
      state: "MA"
    },
    {
      city: "FRANKLIN",
      state: "ME"
    },
    {
      city: "FRANKLIN",
      state: "MI"
    },
    {
      city: "FRANKLIN",
      state: "MN"
    },
    {
      city: "FRANKLIN",
      state: "MO"
    },
    {
      city: "FRANKLIN",
      state: "NC"
    },
    {
      city: "FRANKLIN",
      state: "NE"
    },
    {
      city: "FRANKLIN",
      state: "NH"
    },
    {
      city: "FRANKLIN",
      state: "NJ"
    },
    {
      city: "FRANKLIN",
      state: "NY"
    },
    {
      city: "FRANKLIN",
      state: "OH"
    },
    {
      city: "FRANKLIN",
      state: "PA"
    },
    {
      city: "FRANKLIN",
      state: "TN"
    },
    {
      city: "FRANKLIN",
      state: "TX"
    },
    {
      city: "FRANKLIN",
      state: "VA"
    },
    {
      city: "FRANKLIN",
      state: "VT"
    },
    {
      city: "FRANKLIN",
      state: "WI"
    },
    {
      city: "FRANKLIN",
      state: "WV"
    },
    {
      city: "FRANKLIN FURNACE",
      state: "OH"
    },
    {
      city: "FRANKLIN GROVE",
      state: "IL"
    },
    {
      city: "FRANKLIN LAKES",
      state: "NJ"
    },
    {
      city: "FRANKLIN PARK",
      state: "IL"
    },
    {
      city: "FRANKLIN PARK",
      state: "NJ"
    },
    {
      city: "FRANKLIN SPRINGS",
      state: "GA"
    },
    {
      city: "FRANKLIN SPRINGS",
      state: "NY"
    },
    {
      city: "FRANKLIN SQUARE",
      state: "NY"
    },
    {
      city: "FRANKLINTON",
      state: "LA"
    },
    {
      city: "FRANKLINTON",
      state: "NC"
    },
    {
      city: "FRANKLINTOWN",
      state: "PA"
    },
    {
      city: "FRANKLINVILLE",
      state: "NC"
    },
    {
      city: "FRANKLINVILLE",
      state: "NJ"
    },
    {
      city: "FRANKLINVILLE",
      state: "NY"
    },
    {
      city: "FRANKSTON",
      state: "TX"
    },
    {
      city: "FRANKSVILLE",
      state: "WI"
    },
    {
      city: "FRANKTON",
      state: "IN"
    },
    {
      city: "FRANKTOWN",
      state: "CO"
    },
    {
      city: "FRANKTOWN",
      state: "VA"
    },
    {
      city: "FRANKVILLE",
      state: "AL"
    },
    {
      city: "FRANNIE",
      state: "WY"
    },
    {
      city: "FRASER",
      state: "CO"
    },
    {
      city: "FRASER",
      state: "MI"
    },
    {
      city: "FRAZEE",
      state: "MN"
    },
    {
      city: "FRAZER",
      state: "MT"
    },
    {
      city: "FRAZEYSBURG",
      state: "OH"
    },
    {
      city: "FRAZIER PARK",
      state: "CA"
    },
    {
      city: "FRAZIERS BOTTOM",
      state: "WV"
    },
    {
      city: "FRED",
      state: "TX"
    },
    {
      city: "FREDERIC",
      state: "MI"
    },
    {
      city: "FREDERIC",
      state: "WI"
    },
    {
      city: "FREDERICA",
      state: "DE"
    },
    {
      city: "FREDERICK",
      state: "CO"
    },
    {
      city: "FREDERICK",
      state: "MD"
    },
    {
      city: "FREDERICK",
      state: "OK"
    },
    {
      city: "FREDERICK",
      state: "PA"
    },
    {
      city: "FREDERICK",
      state: "SD"
    },
    {
      city: "FREDERICKSBURG",
      state: "IA"
    },
    {
      city: "FREDERICKSBURG",
      state: "IN"
    },
    {
      city: "FREDERICKSBURG",
      state: "OH"
    },
    {
      city: "FREDERICKSBURG",
      state: "PA"
    },
    {
      city: "FREDERICKSBURG",
      state: "TX"
    },
    {
      city: "FREDERICKSBURG",
      state: "VA"
    },
    {
      city: "FREDERICKTOWN",
      state: "MO"
    },
    {
      city: "FREDERICKTOWN",
      state: "OH"
    },
    {
      city: "FREDERICKTOWN",
      state: "PA"
    },
    {
      city: "FREDERIKA",
      state: "IA"
    },
    {
      city: "FREDONIA",
      state: "AZ"
    },
    {
      city: "FREDONIA",
      state: "KS"
    },
    {
      city: "FREDONIA",
      state: "KY"
    },
    {
      city: "FREDONIA",
      state: "ND"
    },
    {
      city: "FREDONIA",
      state: "NY"
    },
    {
      city: "FREDONIA",
      state: "PA"
    },
    {
      city: "FREDONIA",
      state: "TX"
    },
    {
      city: "FREDONIA",
      state: "WI"
    },
    {
      city: "FREE SOIL",
      state: "MI"
    },
    {
      city: "FREE UNION",
      state: "VA"
    },
    {
      city: "FREEBORN",
      state: "MN"
    },
    {
      city: "FREEBURG",
      state: "IL"
    },
    {
      city: "FREEBURG",
      state: "MO"
    },
    {
      city: "FREEBURG",
      state: "PA"
    },
    {
      city: "FREEBURN",
      state: "KY"
    },
    {
      city: "FREEDOM",
      state: "CA"
    },
    {
      city: "FREEDOM",
      state: "IN"
    },
    {
      city: "FREEDOM",
      state: "ME"
    },
    {
      city: "FREEDOM",
      state: "NH"
    },
    {
      city: "FREEDOM",
      state: "NY"
    },
    {
      city: "FREEDOM",
      state: "OK"
    },
    {
      city: "FREEDOM",
      state: "PA"
    },
    {
      city: "FREEDOM",
      state: "WI"
    },
    {
      city: "FREEDOM",
      state: "WY"
    },
    {
      city: "FREEHOLD",
      state: "NJ"
    },
    {
      city: "FREEHOLD",
      state: "NY"
    },
    {
      city: "FREELAND",
      state: "MD"
    },
    {
      city: "FREELAND",
      state: "MI"
    },
    {
      city: "FREELAND",
      state: "PA"
    },
    {
      city: "FREELAND",
      state: "WA"
    },
    {
      city: "FREELANDVILLE",
      state: "IN"
    },
    {
      city: "FREEMAN",
      state: "MO"
    },
    {
      city: "FREEMAN",
      state: "SD"
    },
    {
      city: "FREEMAN",
      state: "VA"
    },
    {
      city: "FREEMAN",
      state: "WV"
    },
    {
      city: "FREEPORT",
      state: "FL"
    },
    {
      city: "FREEPORT",
      state: "IL"
    },
    {
      city: "FREEPORT",
      state: "ME"
    },
    {
      city: "FREEPORT",
      state: "MI"
    },
    {
      city: "FREEPORT",
      state: "MN"
    },
    {
      city: "FREEPORT",
      state: "NY"
    },
    {
      city: "FREEPORT",
      state: "OH"
    },
    {
      city: "FREEPORT",
      state: "PA"
    },
    {
      city: "FREEPORT",
      state: "TX"
    },
    {
      city: "FREER",
      state: "TX"
    },
    {
      city: "FREETOWN",
      state: "IN"
    },
    {
      city: "FREEVILLE",
      state: "NY"
    },
    {
      city: "FREISTATT",
      state: "MO"
    },
    {
      city: "FREMONT",
      state: "CA"
    },
    {
      city: "FREMONT",
      state: "IA"
    },
    {
      city: "FREMONT",
      state: "IN"
    },
    {
      city: "FREMONT",
      state: "MI"
    },
    {
      city: "FREMONT",
      state: "MO"
    },
    {
      city: "FREMONT",
      state: "NC"
    },
    {
      city: "FREMONT",
      state: "NE"
    },
    {
      city: "FREMONT",
      state: "NH"
    },
    {
      city: "FREMONT",
      state: "OH"
    },
    {
      city: "FREMONT",
      state: "WI"
    },
    {
      city: "FREMONT CENTER",
      state: "NY"
    },
    {
      city: "FRENCH CAMP",
      state: "CA"
    },
    {
      city: "FRENCH CAMP",
      state: "MS"
    },
    {
      city: "FRENCH CREEK",
      state: "WV"
    },
    {
      city: "FRENCH GULCH",
      state: "CA"
    },
    {
      city: "FRENCH LICK",
      state: "IN"
    },
    {
      city: "FRENCH SETTLEMENT",
      state: "LA"
    },
    {
      city: "FRENCH VILLAGE",
      state: "MO"
    },
    {
      city: "FRENCHBORO",
      state: "ME"
    },
    {
      city: "FRENCHBURG",
      state: "KY"
    },
    {
      city: "FRENCHGLEN",
      state: "OR"
    },
    {
      city: "FRENCHMANS BAYOU",
      state: "AR"
    },
    {
      city: "FRENCHTOWN",
      state: "MT"
    },
    {
      city: "FRENCHTOWN",
      state: "NJ"
    },
    {
      city: "FRENCHVILLE",
      state: "ME"
    },
    {
      city: "FRENCHVILLE",
      state: "PA"
    },
    {
      city: "FRESH MEADOWS",
      state: "NY"
    },
    {
      city: "FRESNO",
      state: "CA"
    },
    {
      city: "FRESNO",
      state: "OH"
    },
    {
      city: "FRESNO",
      state: "TX"
    },
    {
      city: "FREWSBURG",
      state: "NY"
    },
    {
      city: "FRIANT",
      state: "CA"
    },
    {
      city: "FRIARS POINT",
      state: "MS"
    },
    {
      city: "FRIDAY HARBOR",
      state: "WA"
    },
    {
      city: "FRIEDENS",
      state: "PA"
    },
    {
      city: "FRIEDENSBURG",
      state: "PA"
    },
    {
      city: "FRIEDHEIM",
      state: "MO"
    },
    {
      city: "FRIEND",
      state: "NE"
    },
    {
      city: "FRIENDLY",
      state: "WV"
    },
    {
      city: "FRIENDSHIP",
      state: "IN"
    },
    {
      city: "FRIENDSHIP",
      state: "MD"
    },
    {
      city: "FRIENDSHIP",
      state: "ME"
    },
    {
      city: "FRIENDSHIP",
      state: "NY"
    },
    {
      city: "FRIENDSHIP",
      state: "OH"
    },
    {
      city: "FRIENDSHIP",
      state: "TN"
    },
    {
      city: "FRIENDSHIP",
      state: "WI"
    },
    {
      city: "FRIENDSVILLE",
      state: "MD"
    },
    {
      city: "FRIENDSVILLE",
      state: "PA"
    },
    {
      city: "FRIENDSVILLE",
      state: "TN"
    },
    {
      city: "FRIENDSWOOD",
      state: "TX"
    },
    {
      city: "FRIERSON",
      state: "LA"
    },
    {
      city: "FRIES",
      state: "VA"
    },
    {
      city: "FRIESLAND",
      state: "WI"
    },
    {
      city: "FRIONA",
      state: "TX"
    },
    {
      city: "FRISCO",
      state: "CO"
    },
    {
      city: "FRISCO",
      state: "NC"
    },
    {
      city: "FRISCO",
      state: "TX"
    },
    {
      city: "FRISCO CITY",
      state: "AL"
    },
    {
      city: "FRITCH",
      state: "TX"
    },
    {
      city: "FROHNA",
      state: "MO"
    },
    {
      city: "FROID",
      state: "MT"
    },
    {
      city: "FROMBERG",
      state: "MT"
    },
    {
      city: "FRONT ROYAL",
      state: "VA"
    },
    {
      city: "FRONTENAC",
      state: "KS"
    },
    {
      city: "FRONTENAC",
      state: "MN"
    },
    {
      city: "FRONTIER",
      state: "MI"
    },
    {
      city: "FRONTIER",
      state: "WY"
    },
    {
      city: "FROST",
      state: "MN"
    },
    {
      city: "FROST",
      state: "TX"
    },
    {
      city: "FROSTBURG",
      state: "MD"
    },
    {
      city: "FROSTPROOF",
      state: "FL"
    },
    {
      city: "FRUITA",
      state: "CO"
    },
    {
      city: "FRUITDALE",
      state: "AL"
    },
    {
      city: "FRUITHURST",
      state: "AL"
    },
    {
      city: "FRUITLAND",
      state: "IA"
    },
    {
      city: "FRUITLAND",
      state: "ID"
    },
    {
      city: "FRUITLAND",
      state: "MD"
    },
    {
      city: "FRUITLAND",
      state: "NM"
    },
    {
      city: "FRUITLAND",
      state: "UT"
    },
    {
      city: "FRUITLAND",
      state: "WA"
    },
    {
      city: "FRUITLAND PARK",
      state: "FL"
    },
    {
      city: "FRUITPORT",
      state: "MI"
    },
    {
      city: "FRUITVALE",
      state: "TX"
    },
    {
      city: "FRYBURG",
      state: "PA"
    },
    {
      city: "FRYEBURG",
      state: "ME"
    },
    {
      city: "FT MITCHELL",
      state: "KY"
    },
    {
      city: "FULDA",
      state: "IN"
    },
    {
      city: "FULDA",
      state: "MN"
    },
    {
      city: "FULKS RUN",
      state: "VA"
    },
    {
      city: "FULLERTON",
      state: "CA"
    },
    {
      city: "FULLERTON",
      state: "ND"
    },
    {
      city: "FULLERTON",
      state: "NE"
    },
    {
      city: "FULSHEAR",
      state: "TX"
    },
    {
      city: "FULTON",
      state: "AL"
    },
    {
      city: "FULTON",
      state: "AR"
    },
    {
      city: "FULTON",
      state: "CA"
    },
    {
      city: "FULTON",
      state: "IL"
    },
    {
      city: "FULTON",
      state: "IN"
    },
    {
      city: "FULTON",
      state: "KS"
    },
    {
      city: "FULTON",
      state: "KY"
    },
    {
      city: "FULTON",
      state: "MD"
    },
    {
      city: "FULTON",
      state: "MI"
    },
    {
      city: "FULTON",
      state: "MO"
    },
    {
      city: "FULTON",
      state: "MS"
    },
    {
      city: "FULTON",
      state: "NY"
    },
    {
      city: "FULTON",
      state: "OH"
    },
    {
      city: "FULTON",
      state: "SD"
    },
    {
      city: "FULTON",
      state: "TX"
    },
    {
      city: "FULTONDALE",
      state: "AL"
    },
    {
      city: "FULTONHAM",
      state: "NY"
    },
    {
      city: "FULTONHAM",
      state: "OH"
    },
    {
      city: "FULTONVILLE",
      state: "NY"
    },
    {
      city: "FULTS",
      state: "IL"
    },
    {
      city: "FUNK",
      state: "NE"
    },
    {
      city: "FUNKSTOWN",
      state: "MD"
    },
    {
      city: "FUNSTON",
      state: "GA"
    },
    {
      city: "FUQUAY VARINA",
      state: "NC"
    },
    {
      city: "FURLONG",
      state: "PA"
    },
    {
      city: "FURMAN",
      state: "AL"
    },
    {
      city: "FURMAN",
      state: "SC"
    },
    {
      city: "FYFFE",
      state: "AL"
    },
    {
      city: "GAASTRA",
      state: "MI"
    },
    {
      city: "GABBS",
      state: "NV"
    },
    {
      city: "GABLE",
      state: "SC"
    },
    {
      city: "GABRIELS",
      state: "NY"
    },
    {
      city: "GACKLE",
      state: "ND"
    },
    {
      city: "GADSDEN",
      state: "AL"
    },
    {
      city: "GADSDEN",
      state: "AZ"
    },
    {
      city: "GADSDEN",
      state: "SC"
    },
    {
      city: "GADSDEN",
      state: "TN"
    },
    {
      city: "GAFFNEY",
      state: "SC"
    },
    {
      city: "GAGE",
      state: "OK"
    },
    {
      city: "GAGETOWN",
      state: "MI"
    },
    {
      city: "GAIL",
      state: "TX"
    },
    {
      city: "GAINES",
      state: "MI"
    },
    {
      city: "GAINES",
      state: "PA"
    },
    {
      city: "GAINESBORO",
      state: "TN"
    },
    {
      city: "GAINESTOWN",
      state: "AL"
    },
    {
      city: "GAINESVILLE",
      state: "AL"
    },
    {
      city: "GAINESVILLE",
      state: "FL"
    },
    {
      city: "GAINESVILLE",
      state: "GA"
    },
    {
      city: "GAINESVILLE",
      state: "MO"
    },
    {
      city: "GAINESVILLE",
      state: "NY"
    },
    {
      city: "GAINESVILLE",
      state: "TX"
    },
    {
      city: "GAINESVILLE",
      state: "VA"
    },
    {
      city: "GAITHERSBURG",
      state: "MD"
    },
    {
      city: "GAKONA",
      state: "AK"
    },
    {
      city: "GALATA",
      state: "MT"
    },
    {
      city: "GALATIA",
      state: "IL"
    },
    {
      city: "GALAX",
      state: "VA"
    },
    {
      city: "GALENA",
      state: "AK"
    },
    {
      city: "GALENA",
      state: "IL"
    },
    {
      city: "GALENA",
      state: "KS"
    },
    {
      city: "GALENA",
      state: "MD"
    },
    {
      city: "GALENA",
      state: "MO"
    },
    {
      city: "GALENA",
      state: "OH"
    },
    {
      city: "GALENA PARK",
      state: "TX"
    },
    {
      city: "GALES CREEK",
      state: "OR"
    },
    {
      city: "GALES FERRY",
      state: "CT"
    },
    {
      city: "GALESBURG",
      state: "IL"
    },
    {
      city: "GALESBURG",
      state: "KS"
    },
    {
      city: "GALESBURG",
      state: "MI"
    },
    {
      city: "GALESBURG",
      state: "ND"
    },
    {
      city: "GALESVILLE",
      state: "MD"
    },
    {
      city: "GALESVILLE",
      state: "WI"
    },
    {
      city: "GALETON",
      state: "CO"
    },
    {
      city: "GALETON",
      state: "PA"
    },
    {
      city: "GALIEN",
      state: "MI"
    },
    {
      city: "GALION",
      state: "OH"
    },
    {
      city: "GALIVANTS FERRY",
      state: "SC"
    },
    {
      city: "GALLAGHER",
      state: "WV"
    },
    {
      city: "GALLANT",
      state: "AL"
    },
    {
      city: "GALLATIN",
      state: "MO"
    },
    {
      city: "GALLATIN",
      state: "TN"
    },
    {
      city: "GALLATIN",
      state: "TX"
    },
    {
      city: "GALLATIN GATEWAY",
      state: "MT"
    },
    {
      city: "GALLAWAY",
      state: "TN"
    },
    {
      city: "GALLIANO",
      state: "LA"
    },
    {
      city: "GALLION",
      state: "AL"
    },
    {
      city: "GALLIPOLIS",
      state: "OH"
    },
    {
      city: "GALLIPOLIS FERRY",
      state: "WV"
    },
    {
      city: "GALLITZIN",
      state: "PA"
    },
    {
      city: "GALLMAN",
      state: "MS"
    },
    {
      city: "GALLOWAY",
      state: "OH"
    },
    {
      city: "GALLOWAY",
      state: "WV"
    },
    {
      city: "GALLUP",
      state: "NM"
    },
    {
      city: "GALLUPVILLE",
      state: "NY"
    },
    {
      city: "GALT",
      state: "CA"
    },
    {
      city: "GALT",
      state: "IA"
    },
    {
      city: "GALT",
      state: "IL"
    },
    {
      city: "GALT",
      state: "MO"
    },
    {
      city: "GALVA",
      state: "IA"
    },
    {
      city: "GALVA",
      state: "IL"
    },
    {
      city: "GALVA",
      state: "KS"
    },
    {
      city: "GALVESTON",
      state: "IN"
    },
    {
      city: "GALVESTON",
      state: "TX"
    },
    {
      city: "GALWAY",
      state: "NY"
    },
    {
      city: "GAMALIEL",
      state: "AR"
    },
    {
      city: "GAMALIEL",
      state: "KY"
    },
    {
      city: "GAMBELL",
      state: "AK"
    },
    {
      city: "GAMBIER",
      state: "OH"
    },
    {
      city: "GAMBRILLS",
      state: "MD"
    },
    {
      city: "GAMERCO",
      state: "NM"
    },
    {
      city: "GANADO",
      state: "AZ"
    },
    {
      city: "GANADO",
      state: "TX"
    },
    {
      city: "GANDEEVILLE",
      state: "WV"
    },
    {
      city: "GANN VALLEY",
      state: "SD"
    },
    {
      city: "GANS",
      state: "OK"
    },
    {
      city: "GANSEVOORT",
      state: "NY"
    },
    {
      city: "GANTT",
      state: "AL"
    },
    {
      city: "GAP",
      state: "PA"
    },
    {
      city: "GAP MILLS",
      state: "WV"
    },
    {
      city: "GARARDS FORT",
      state: "PA"
    },
    {
      city: "GARBER",
      state: "IA"
    },
    {
      city: "GARBER",
      state: "OK"
    },
    {
      city: "GARBERVILLE",
      state: "CA"
    },
    {
      city: "GARCIASVILLE",
      state: "TX"
    },
    {
      city: "GARDEN",
      state: "MI"
    },
    {
      city: "GARDEN CITY",
      state: "AL"
    },
    {
      city: "GARDEN CITY",
      state: "IA"
    },
    {
      city: "GARDEN CITY",
      state: "ID"
    },
    {
      city: "GARDEN CITY",
      state: "KS"
    },
    {
      city: "GARDEN CITY",
      state: "LA"
    },
    {
      city: "GARDEN CITY",
      state: "MI"
    },
    {
      city: "GARDEN CITY",
      state: "MN"
    },
    {
      city: "GARDEN CITY",
      state: "MO"
    },
    {
      city: "GARDEN CITY",
      state: "NY"
    },
    {
      city: "GARDEN CITY",
      state: "SD"
    },
    {
      city: "GARDEN CITY",
      state: "TX"
    },
    {
      city: "GARDEN CITY",
      state: "UT"
    },
    {
      city: "GARDEN GROVE",
      state: "CA"
    },
    {
      city: "GARDEN GROVE",
      state: "IA"
    },
    {
      city: "GARDEN PLAIN",
      state: "KS"
    },
    {
      city: "GARDEN PRAIRIE",
      state: "IL"
    },
    {
      city: "GARDEN VALLEY",
      state: "CA"
    },
    {
      city: "GARDEN VALLEY",
      state: "ID"
    },
    {
      city: "GARDENA",
      state: "CA"
    },
    {
      city: "GARDENDALE",
      state: "AL"
    },
    {
      city: "GARDENDALE",
      state: "TX"
    },
    {
      city: "GARDINER",
      state: "ME"
    },
    {
      city: "GARDINER",
      state: "MT"
    },
    {
      city: "GARDINER",
      state: "NY"
    },
    {
      city: "GARDINER",
      state: "OR"
    },
    {
      city: "GARDNER",
      state: "CO"
    },
    {
      city: "GARDNER",
      state: "IL"
    },
    {
      city: "GARDNER",
      state: "KS"
    },
    {
      city: "GARDNER",
      state: "MA"
    },
    {
      city: "GARDNER",
      state: "ND"
    },
    {
      city: "GARDNERS",
      state: "PA"
    },
    {
      city: "GARDNERVILLE",
      state: "NV"
    },
    {
      city: "GARFIELD",
      state: "AR"
    },
    {
      city: "GARFIELD",
      state: "GA"
    },
    {
      city: "GARFIELD",
      state: "KS"
    },
    {
      city: "GARFIELD",
      state: "KY"
    },
    {
      city: "GARFIELD",
      state: "MN"
    },
    {
      city: "GARFIELD",
      state: "NJ"
    },
    {
      city: "GARFIELD",
      state: "NM"
    },
    {
      city: "GARFIELD",
      state: "WA"
    },
    {
      city: "GARIBALDI",
      state: "OR"
    },
    {
      city: "GARLAND",
      state: "KS"
    },
    {
      city: "GARLAND",
      state: "ME"
    },
    {
      city: "GARLAND",
      state: "NC"
    },
    {
      city: "GARLAND",
      state: "NE"
    },
    {
      city: "GARLAND",
      state: "PA"
    },
    {
      city: "GARLAND",
      state: "TX"
    },
    {
      city: "GARLAND",
      state: "UT"
    },
    {
      city: "GARLAND CITY",
      state: "AR"
    },
    {
      city: "GARNAVILLO",
      state: "IA"
    },
    {
      city: "GARNER",
      state: "AR"
    },
    {
      city: "GARNER",
      state: "IA"
    },
    {
      city: "GARNER",
      state: "KY"
    },
    {
      city: "GARNER",
      state: "NC"
    },
    {
      city: "GARNERVILLE",
      state: "NY"
    },
    {
      city: "GARNET VALLEY",
      state: "PA"
    },
    {
      city: "GARNETT",
      state: "KS"
    },
    {
      city: "GARNETT",
      state: "SC"
    },
    {
      city: "GARRARD",
      state: "KY"
    },
    {
      city: "GARRATTSVILLE",
      state: "NY"
    },
    {
      city: "GARRETSON",
      state: "SD"
    },
    {
      city: "GARRETT",
      state: "IN"
    },
    {
      city: "GARRETT",
      state: "KY"
    },
    {
      city: "GARRETT",
      state: "PA"
    },
    {
      city: "GARRETT PARK",
      state: "MD"
    },
    {
      city: "GARRETTSVILLE",
      state: "OH"
    },
    {
      city: "GARRISON",
      state: "IA"
    },
    {
      city: "GARRISON",
      state: "KY"
    },
    {
      city: "GARRISON",
      state: "MN"
    },
    {
      city: "GARRISON",
      state: "MT"
    },
    {
      city: "GARRISON",
      state: "ND"
    },
    {
      city: "GARRISON",
      state: "NY"
    },
    {
      city: "GARRISON",
      state: "TX"
    },
    {
      city: "GARRISON",
      state: "UT"
    },
    {
      city: "GARRISONVILLE",
      state: "VA"
    },
    {
      city: "GARRYOWEN",
      state: "MT"
    },
    {
      city: "GARVIN",
      state: "MN"
    },
    {
      city: "GARVIN",
      state: "OK"
    },
    {
      city: "GARWIN",
      state: "IA"
    },
    {
      city: "GARWOOD",
      state: "NJ"
    },
    {
      city: "GARWOOD",
      state: "TX"
    },
    {
      city: "GARY",
      state: "IN"
    },
    {
      city: "GARY",
      state: "MN"
    },
    {
      city: "GARY",
      state: "SD"
    },
    {
      city: "GARY",
      state: "TX"
    },
    {
      city: "GARY",
      state: "WV"
    },
    {
      city: "GARYSBURG",
      state: "NC"
    },
    {
      city: "GARYVILLE",
      state: "LA"
    },
    {
      city: "GAS",
      state: "KS"
    },
    {
      city: "GAS CITY",
      state: "IN"
    },
    {
      city: "GASBURG",
      state: "VA"
    },
    {
      city: "GASPORT",
      state: "NY"
    },
    {
      city: "GASQUET",
      state: "CA"
    },
    {
      city: "GASSAWAY",
      state: "WV"
    },
    {
      city: "GASSVILLE",
      state: "AR"
    },
    {
      city: "GASTON",
      state: "IN"
    },
    {
      city: "GASTON",
      state: "NC"
    },
    {
      city: "GASTON",
      state: "OR"
    },
    {
      city: "GASTON",
      state: "SC"
    },
    {
      city: "GASTONIA",
      state: "NC"
    },
    {
      city: "GASTONVILLE",
      state: "PA"
    },
    {
      city: "GATE",
      state: "OK"
    },
    {
      city: "GATE CITY",
      state: "VA"
    },
    {
      city: "GATES",
      state: "NC"
    },
    {
      city: "GATES",
      state: "OR"
    },
    {
      city: "GATES",
      state: "TN"
    },
    {
      city: "GATES MILLS",
      state: "OH"
    },
    {
      city: "GATESVILLE",
      state: "NC"
    },
    {
      city: "GATESVILLE",
      state: "TX"
    },
    {
      city: "GATEWAY",
      state: "AR"
    },
    {
      city: "GATEWAY",
      state: "CO"
    },
    {
      city: "GATEWOOD",
      state: "MO"
    },
    {
      city: "GATLINBURG",
      state: "TN"
    },
    {
      city: "GATTMAN",
      state: "MS"
    },
    {
      city: "GATZKE",
      state: "MN"
    },
    {
      city: "GAULEY BRIDGE",
      state: "WV"
    },
    {
      city: "GAUSE",
      state: "TX"
    },
    {
      city: "GAUTIER",
      state: "MS"
    },
    {
      city: "GAY",
      state: "GA"
    },
    {
      city: "GAY",
      state: "WV"
    },
    {
      city: "GAYLESVILLE",
      state: "AL"
    },
    {
      city: "GAYLORD",
      state: "KS"
    },
    {
      city: "GAYLORD",
      state: "MI"
    },
    {
      city: "GAYLORD",
      state: "MN"
    },
    {
      city: "GAYLORDSVILLE",
      state: "CT"
    },
    {
      city: "GAYS",
      state: "IL"
    },
    {
      city: "GAYS CREEK",
      state: "KY"
    },
    {
      city: "GAYS MILLS",
      state: "WI"
    },
    {
      city: "GAYSVILLE",
      state: "VT"
    },
    {
      city: "GAYVILLE",
      state: "SD"
    },
    {
      city: "GAZELLE",
      state: "CA"
    },
    {
      city: "GEARY",
      state: "OK"
    },
    {
      city: "GEDDES",
      state: "SD"
    },
    {
      city: "GEFF",
      state: "IL"
    },
    {
      city: "GEIGERTOWN",
      state: "PA"
    },
    {
      city: "GEISMAR",
      state: "LA"
    },
    {
      city: "GEM",
      state: "KS"
    },
    {
      city: "GENE AUTRY",
      state: "OK"
    },
    {
      city: "GENESEE",
      state: "ID"
    },
    {
      city: "GENESEE",
      state: "MI"
    },
    {
      city: "GENESEE",
      state: "PA"
    },
    {
      city: "GENESEE DEPOT",
      state: "WI"
    },
    {
      city: "GENESEO",
      state: "IL"
    },
    {
      city: "GENESEO",
      state: "KS"
    },
    {
      city: "GENESEO",
      state: "NY"
    },
    {
      city: "GENEVA",
      state: "AL"
    },
    {
      city: "GENEVA",
      state: "FL"
    },
    {
      city: "GENEVA",
      state: "GA"
    },
    {
      city: "GENEVA",
      state: "IA"
    },
    {
      city: "GENEVA",
      state: "ID"
    },
    {
      city: "GENEVA",
      state: "IL"
    },
    {
      city: "GENEVA",
      state: "IN"
    },
    {
      city: "GENEVA",
      state: "MN"
    },
    {
      city: "GENEVA",
      state: "NE"
    },
    {
      city: "GENEVA",
      state: "NY"
    },
    {
      city: "GENEVA",
      state: "OH"
    },
    {
      city: "GENOA",
      state: "AR"
    },
    {
      city: "GENOA",
      state: "CO"
    },
    {
      city: "GENOA",
      state: "IL"
    },
    {
      city: "GENOA",
      state: "NE"
    },
    {
      city: "GENOA",
      state: "NV"
    },
    {
      city: "GENOA",
      state: "NY"
    },
    {
      city: "GENOA",
      state: "OH"
    },
    {
      city: "GENOA",
      state: "WI"
    },
    {
      city: "GENOA",
      state: "WV"
    },
    {
      city: "GENOA CITY",
      state: "WI"
    },
    {
      city: "GENTRY",
      state: "AR"
    },
    {
      city: "GENTRY",
      state: "MO"
    },
    {
      city: "GENTRYVILLE",
      state: "IN"
    },
    {
      city: "GEORGE",
      state: "IA"
    },
    {
      city: "GEORGE",
      state: "WA"
    },
    {
      city: "GEORGE WEST",
      state: "TX"
    },
    {
      city: "GEORGES MILLS",
      state: "NH"
    },
    {
      city: "GEORGETOWN",
      state: "CA"
    },
    {
      city: "GEORGETOWN",
      state: "CO"
    },
    {
      city: "GEORGETOWN",
      state: "CT"
    },
    {
      city: "GEORGETOWN",
      state: "DE"
    },
    {
      city: "GEORGETOWN",
      state: "FL"
    },
    {
      city: "GEORGETOWN",
      state: "GA"
    },
    {
      city: "GEORGETOWN",
      state: "ID"
    },
    {
      city: "GEORGETOWN",
      state: "IL"
    },
    {
      city: "GEORGETOWN",
      state: "IN"
    },
    {
      city: "GEORGETOWN",
      state: "KY"
    },
    {
      city: "GEORGETOWN",
      state: "LA"
    },
    {
      city: "GEORGETOWN",
      state: "MA"
    },
    {
      city: "GEORGETOWN",
      state: "MD"
    },
    {
      city: "GEORGETOWN",
      state: "ME"
    },
    {
      city: "GEORGETOWN",
      state: "MN"
    },
    {
      city: "GEORGETOWN",
      state: "MS"
    },
    {
      city: "GEORGETOWN",
      state: "NY"
    },
    {
      city: "GEORGETOWN",
      state: "OH"
    },
    {
      city: "GEORGETOWN",
      state: "PA"
    },
    {
      city: "GEORGETOWN",
      state: "SC"
    },
    {
      city: "GEORGETOWN",
      state: "TN"
    },
    {
      city: "GEORGETOWN",
      state: "TX"
    },
    {
      city: "GEORGIANA",
      state: "AL"
    },
    {
      city: "GEPP",
      state: "AR"
    },
    {
      city: "GERALD",
      state: "MO"
    },
    {
      city: "GERALDINE",
      state: "AL"
    },
    {
      city: "GERALDINE",
      state: "MT"
    },
    {
      city: "GERBER",
      state: "CA"
    },
    {
      city: "GERING",
      state: "NE"
    },
    {
      city: "GERLACH",
      state: "NV"
    },
    {
      city: "GERLAW",
      state: "IL"
    },
    {
      city: "GERMAN VALLEY",
      state: "IL"
    },
    {
      city: "GERMANSVILLE",
      state: "PA"
    },
    {
      city: "GERMANTON",
      state: "NC"
    },
    {
      city: "GERMANTOWN",
      state: "IL"
    },
    {
      city: "GERMANTOWN",
      state: "KY"
    },
    {
      city: "GERMANTOWN",
      state: "MD"
    },
    {
      city: "GERMANTOWN",
      state: "NY"
    },
    {
      city: "GERMANTOWN",
      state: "OH"
    },
    {
      city: "GERMANTOWN",
      state: "TN"
    },
    {
      city: "GERMANTOWN",
      state: "WI"
    },
    {
      city: "GERMFASK",
      state: "MI"
    },
    {
      city: "GERONIMO",
      state: "OK"
    },
    {
      city: "GERONIMO",
      state: "TX"
    },
    {
      city: "GERRARDSTOWN",
      state: "WV"
    },
    {
      city: "GERRY",
      state: "NY"
    },
    {
      city: "GERTON",
      state: "NC"
    },
    {
      city: "GERVAIS",
      state: "OR"
    },
    {
      city: "GETTYSBURG",
      state: "OH"
    },
    {
      city: "GETTYSBURG",
      state: "PA"
    },
    {
      city: "GETTYSBURG",
      state: "SD"
    },
    {
      city: "GETZVILLE",
      state: "NY"
    },
    {
      city: "GEUDA SPRINGS",
      state: "KS"
    },
    {
      city: "GEYSER",
      state: "MT"
    },
    {
      city: "GEYSERVILLE",
      state: "CA"
    },
    {
      city: "GHEENS",
      state: "LA"
    },
    {
      city: "GHENT",
      state: "KY"
    },
    {
      city: "GHENT",
      state: "MN"
    },
    {
      city: "GHENT",
      state: "NY"
    },
    {
      city: "GHENT",
      state: "WV"
    },
    {
      city: "GIBBON",
      state: "MN"
    },
    {
      city: "GIBBON",
      state: "NE"
    },
    {
      city: "GIBBON GLADE",
      state: "PA"
    },
    {
      city: "GIBBONSVILLE",
      state: "ID"
    },
    {
      city: "GIBBSBORO",
      state: "NJ"
    },
    {
      city: "GIBBSTOWN",
      state: "NJ"
    },
    {
      city: "GIBSLAND",
      state: "LA"
    },
    {
      city: "GIBSON",
      state: "GA"
    },
    {
      city: "GIBSON",
      state: "IA"
    },
    {
      city: "GIBSON",
      state: "LA"
    },
    {
      city: "GIBSON",
      state: "NC"
    },
    {
      city: "GIBSON",
      state: "TN"
    },
    {
      city: "GIBSON CITY",
      state: "IL"
    },
    {
      city: "GIBSON ISLAND",
      state: "MD"
    },
    {
      city: "GIBSONBURG",
      state: "OH"
    },
    {
      city: "GIBSONIA",
      state: "PA"
    },
    {
      city: "GIBSONTON",
      state: "FL"
    },
    {
      city: "GIBSONVILLE",
      state: "NC"
    },
    {
      city: "GIDDINGS",
      state: "TX"
    },
    {
      city: "GIDEON",
      state: "MO"
    },
    {
      city: "GIFFORD",
      state: "IA"
    },
    {
      city: "GIFFORD",
      state: "IL"
    },
    {
      city: "GIFFORD",
      state: "PA"
    },
    {
      city: "GIFFORD",
      state: "SC"
    },
    {
      city: "GIG HARBOR",
      state: "WA"
    },
    {
      city: "GILA",
      state: "NM"
    },
    {
      city: "GILA BEND",
      state: "AZ"
    },
    {
      city: "GILBERT",
      state: "AR"
    },
    {
      city: "GILBERT",
      state: "AZ"
    },
    {
      city: "GILBERT",
      state: "IA"
    },
    {
      city: "GILBERT",
      state: "LA"
    },
    {
      city: "GILBERT",
      state: "MN"
    },
    {
      city: "GILBERT",
      state: "PA"
    },
    {
      city: "GILBERT",
      state: "SC"
    },
    {
      city: "GILBERT",
      state: "WV"
    },
    {
      city: "GILBERTON",
      state: "PA"
    },
    {
      city: "GILBERTOWN",
      state: "AL"
    },
    {
      city: "GILBERTS",
      state: "IL"
    },
    {
      city: "GILBERTSVILLE",
      state: "KY"
    },
    {
      city: "GILBERTSVILLE",
      state: "NY"
    },
    {
      city: "GILBERTSVILLE",
      state: "PA"
    },
    {
      city: "GILBERTVILLE",
      state: "IA"
    },
    {
      city: "GILBERTVILLE",
      state: "MA"
    },
    {
      city: "GILBOA",
      state: "NY"
    },
    {
      city: "GILBOA",
      state: "WV"
    },
    {
      city: "GILBY",
      state: "ND"
    },
    {
      city: "GILCHRIST",
      state: "OR"
    },
    {
      city: "GILCHRIST",
      state: "TX"
    },
    {
      city: "GILCREST",
      state: "CO"
    },
    {
      city: "GILDFORD",
      state: "MT"
    },
    {
      city: "GILE",
      state: "WI"
    },
    {
      city: "GILEAD",
      state: "NE"
    },
    {
      city: "GILFORD",
      state: "NH"
    },
    {
      city: "GILL",
      state: "CO"
    },
    {
      city: "GILL",
      state: "MA"
    },
    {
      city: "GILLESPIE",
      state: "IL"
    },
    {
      city: "GILLETT",
      state: "AR"
    },
    {
      city: "GILLETT",
      state: "PA"
    },
    {
      city: "GILLETT",
      state: "TX"
    },
    {
      city: "GILLETT",
      state: "WI"
    },
    {
      city: "GILLETTE",
      state: "NJ"
    },
    {
      city: "GILLETTE",
      state: "WY"
    },
    {
      city: "GILLHAM",
      state: "AR"
    },
    {
      city: "GILLIAM",
      state: "LA"
    },
    {
      city: "GILLIAM",
      state: "MO"
    },
    {
      city: "GILLSVILLE",
      state: "GA"
    },
    {
      city: "GILMAN",
      state: "CT"
    },
    {
      city: "GILMAN",
      state: "IA"
    },
    {
      city: "GILMAN",
      state: "IL"
    },
    {
      city: "GILMAN",
      state: "MN"
    },
    {
      city: "GILMAN",
      state: "VT"
    },
    {
      city: "GILMAN",
      state: "WI"
    },
    {
      city: "GILMAN CITY",
      state: "MO"
    },
    {
      city: "GILMANTON",
      state: "NH"
    },
    {
      city: "GILMANTON",
      state: "WI"
    },
    {
      city: "GILMANTON IRON WORKS",
      state: "NH"
    },
    {
      city: "GILMER",
      state: "TX"
    },
    {
      city: "GILMORE CITY",
      state: "IA"
    },
    {
      city: "GILROY",
      state: "CA"
    },
    {
      city: "GILSON",
      state: "IL"
    },
    {
      city: "GILSUM",
      state: "NH"
    },
    {
      city: "GILTNER",
      state: "NE"
    },
    {
      city: "GIPSY",
      state: "PA"
    },
    {
      city: "GIRARD",
      state: "GA"
    },
    {
      city: "GIRARD",
      state: "IL"
    },
    {
      city: "GIRARD",
      state: "KS"
    },
    {
      city: "GIRARD",
      state: "OH"
    },
    {
      city: "GIRARD",
      state: "PA"
    },
    {
      city: "GIRARDVILLE",
      state: "PA"
    },
    {
      city: "GIRDLER",
      state: "KY"
    },
    {
      city: "GIRDLETREE",
      state: "MD"
    },
    {
      city: "GIRDWOOD",
      state: "AK"
    },
    {
      city: "GIVEN",
      state: "WV"
    },
    {
      city: "GLADBROOK",
      state: "IA"
    },
    {
      city: "GLADE",
      state: "KS"
    },
    {
      city: "GLADE HILL",
      state: "VA"
    },
    {
      city: "GLADE PARK",
      state: "CO"
    },
    {
      city: "GLADE SPRING",
      state: "VA"
    },
    {
      city: "GLADE VALLEY",
      state: "NC"
    },
    {
      city: "GLADEVILLE",
      state: "TN"
    },
    {
      city: "GLADEWATER",
      state: "TX"
    },
    {
      city: "GLADSTONE",
      state: "IL"
    },
    {
      city: "GLADSTONE",
      state: "MI"
    },
    {
      city: "GLADSTONE",
      state: "ND"
    },
    {
      city: "GLADSTONE",
      state: "NJ"
    },
    {
      city: "GLADSTONE",
      state: "OR"
    },
    {
      city: "GLADSTONE",
      state: "VA"
    },
    {
      city: "GLADWIN",
      state: "MI"
    },
    {
      city: "GLADWYNE",
      state: "PA"
    },
    {
      city: "GLADY",
      state: "WV"
    },
    {
      city: "GLADYS",
      state: "VA"
    },
    {
      city: "GLANDORF",
      state: "OH"
    },
    {
      city: "GLASCO",
      state: "KS"
    },
    {
      city: "GLASCO",
      state: "NY"
    },
    {
      city: "GLASFORD",
      state: "IL"
    },
    {
      city: "GLASGOW",
      state: "KY"
    },
    {
      city: "GLASGOW",
      state: "MO"
    },
    {
      city: "GLASGOW",
      state: "MT"
    },
    {
      city: "GLASGOW",
      state: "PA"
    },
    {
      city: "GLASGOW",
      state: "VA"
    },
    {
      city: "GLASGOW",
      state: "WV"
    },
    {
      city: "GLASSBORO",
      state: "NJ"
    },
    {
      city: "GLASSPORT",
      state: "PA"
    },
    {
      city: "GLASSTON",
      state: "ND"
    },
    {
      city: "GLASTONBURY",
      state: "CT"
    },
    {
      city: "GLEASON",
      state: "TN"
    },
    {
      city: "GLEASON",
      state: "WI"
    },
    {
      city: "GLEN",
      state: "MS"
    },
    {
      city: "GLEN",
      state: "MT"
    },
    {
      city: "GLEN",
      state: "NH"
    },
    {
      city: "GLEN",
      state: "WV"
    },
    {
      city: "GLEN ALLAN",
      state: "MS"
    },
    {
      city: "GLEN ALLEN",
      state: "AL"
    },
    {
      city: "GLEN ALLEN",
      state: "VA"
    },
    {
      city: "GLEN ALPINE",
      state: "NC"
    },
    {
      city: "GLEN ARBOR",
      state: "MI"
    },
    {
      city: "GLEN ARM",
      state: "MD"
    },
    {
      city: "GLEN AUBREY",
      state: "NY"
    },
    {
      city: "GLEN BURNIE",
      state: "MD"
    },
    {
      city: "GLEN CAMPBELL",
      state: "PA"
    },
    {
      city: "GLEN CARBON",
      state: "IL"
    },
    {
      city: "GLEN COVE",
      state: "NY"
    },
    {
      city: "GLEN DALE",
      state: "WV"
    },
    {
      city: "GLEN DANIEL",
      state: "WV"
    },
    {
      city: "GLEN EASTON",
      state: "WV"
    },
    {
      city: "GLEN ECHO",
      state: "MD"
    },
    {
      city: "GLEN ELDER",
      state: "KS"
    },
    {
      city: "GLEN ELLEN",
      state: "CA"
    },
    {
      city: "GLEN ELLYN",
      state: "IL"
    },
    {
      city: "GLEN FERRIS",
      state: "WV"
    },
    {
      city: "GLEN FLORA",
      state: "TX"
    },
    {
      city: "GLEN FLORA",
      state: "WI"
    },
    {
      city: "GLEN FORK",
      state: "WV"
    },
    {
      city: "GLEN GARDNER",
      state: "NJ"
    },
    {
      city: "GLEN HAVEN",
      state: "CO"
    },
    {
      city: "GLEN HAVEN",
      state: "WI"
    },
    {
      city: "GLEN HEAD",
      state: "NY"
    },
    {
      city: "GLEN HOPE",
      state: "PA"
    },
    {
      city: "GLEN JEAN",
      state: "WV"
    },
    {
      city: "GLEN LYN",
      state: "VA"
    },
    {
      city: "GLEN LYON",
      state: "PA"
    },
    {
      city: "GLEN MILLS",
      state: "PA"
    },
    {
      city: "GLEN OAKS",
      state: "NY"
    },
    {
      city: "GLEN RICHEY",
      state: "PA"
    },
    {
      city: "GLEN RIDDLE LIMA",
      state: "PA"
    },
    {
      city: "GLEN RIDGE",
      state: "NJ"
    },
    {
      city: "GLEN ROCK",
      state: "NJ"
    },
    {
      city: "GLEN ROCK",
      state: "PA"
    },
    {
      city: "GLEN ROGERS",
      state: "WV"
    },
    {
      city: "GLEN ROSE",
      state: "TX"
    },
    {
      city: "GLEN SAINT MARY",
      state: "FL"
    },
    {
      city: "GLEN SPEY",
      state: "NY"
    },
    {
      city: "GLEN ULLIN",
      state: "ND"
    },
    {
      city: "GLEN WHITE",
      state: "WV"
    },
    {
      city: "GLEN WILD",
      state: "NY"
    },
    {
      city: "GLENALLEN",
      state: "MO"
    },
    {
      city: "GLENARM",
      state: "IL"
    },
    {
      city: "GLENBEULAH",
      state: "WI"
    },
    {
      city: "GLENBROOK",
      state: "NV"
    },
    {
      city: "GLENBURN",
      state: "ND"
    },
    {
      city: "GLENCOE",
      state: "AR"
    },
    {
      city: "GLENCOE",
      state: "IL"
    },
    {
      city: "GLENCOE",
      state: "KY"
    },
    {
      city: "GLENCOE",
      state: "MN"
    },
    {
      city: "GLENCOE",
      state: "MO"
    },
    {
      city: "GLENCOE",
      state: "NM"
    },
    {
      city: "GLENCOE",
      state: "OH"
    },
    {
      city: "GLENCOE",
      state: "OK"
    },
    {
      city: "GLENCROSS",
      state: "SD"
    },
    {
      city: "GLENDALE",
      state: "AZ"
    },
    {
      city: "GLENDALE",
      state: "CA"
    },
    {
      city: "GLENDALE",
      state: "KY"
    },
    {
      city: "GLENDALE",
      state: "MA"
    },
    {
      city: "GLENDALE",
      state: "OR"
    },
    {
      city: "GLENDALE",
      state: "RI"
    },
    {
      city: "GLENDALE",
      state: "SC"
    },
    {
      city: "GLENDALE",
      state: "UT"
    },
    {
      city: "GLENDALE HEIGHTS",
      state: "IL"
    },
    {
      city: "GLENDALE SPRINGS",
      state: "NC"
    },
    {
      city: "GLENDIVE",
      state: "MT"
    },
    {
      city: "GLENDO",
      state: "WY"
    },
    {
      city: "GLENDORA",
      state: "CA"
    },
    {
      city: "GLENDORA",
      state: "MS"
    },
    {
      city: "GLENDORA",
      state: "NJ"
    },
    {
      city: "GLENEDEN BEACH",
      state: "OR"
    },
    {
      city: "GLENELG",
      state: "MD"
    },
    {
      city: "GLENFIELD",
      state: "ND"
    },
    {
      city: "GLENFIELD",
      state: "NY"
    },
    {
      city: "GLENFORD",
      state: "NY"
    },
    {
      city: "GLENFORD",
      state: "OH"
    },
    {
      city: "GLENGARY",
      state: "WV"
    },
    {
      city: "GLENHAM",
      state: "NY"
    },
    {
      city: "GLENHAM",
      state: "SD"
    },
    {
      city: "GLENHAVEN",
      state: "CA"
    },
    {
      city: "GLENMONT",
      state: "NY"
    },
    {
      city: "GLENMONT",
      state: "OH"
    },
    {
      city: "GLENMOORE",
      state: "PA"
    },
    {
      city: "GLENMORA",
      state: "LA"
    },
    {
      city: "GLENN",
      state: "CA"
    },
    {
      city: "GLENN",
      state: "MI"
    },
    {
      city: "GLENN DALE",
      state: "MD"
    },
    {
      city: "GLENNALLEN",
      state: "AK"
    },
    {
      city: "GLENNIE",
      state: "MI"
    },
    {
      city: "GLENNS FERRY",
      state: "ID"
    },
    {
      city: "GLENNVILLE",
      state: "CA"
    },
    {
      city: "GLENNVILLE",
      state: "GA"
    },
    {
      city: "GLENOLDEN",
      state: "PA"
    },
    {
      city: "GLENOMA",
      state: "WA"
    },
    {
      city: "GLENPOOL",
      state: "OK"
    },
    {
      city: "GLENROCK",
      state: "WY"
    },
    {
      city: "GLENS FALLS",
      state: "NY"
    },
    {
      city: "GLENS FORK",
      state: "KY"
    },
    {
      city: "GLENSHAW",
      state: "PA"
    },
    {
      city: "GLENSIDE",
      state: "PA"
    },
    {
      city: "GLENVIEW",
      state: "IL"
    },
    {
      city: "GLENVIEW",
      state: "KY"
    },
    {
      city: "GLENVIL",
      state: "NE"
    },
    {
      city: "GLENVILLE",
      state: "MN"
    },
    {
      city: "GLENVILLE",
      state: "NC"
    },
    {
      city: "GLENVILLE",
      state: "PA"
    },
    {
      city: "GLENVILLE",
      state: "WV"
    },
    {
      city: "GLENWOOD",
      state: "AL"
    },
    {
      city: "GLENWOOD",
      state: "AR"
    },
    {
      city: "GLENWOOD",
      state: "FL"
    },
    {
      city: "GLENWOOD",
      state: "GA"
    },
    {
      city: "GLENWOOD",
      state: "IA"
    },
    {
      city: "GLENWOOD",
      state: "IL"
    },
    {
      city: "GLENWOOD",
      state: "IN"
    },
    {
      city: "GLENWOOD",
      state: "MD"
    },
    {
      city: "GLENWOOD",
      state: "MN"
    },
    {
      city: "GLENWOOD",
      state: "MO"
    },
    {
      city: "GLENWOOD",
      state: "NJ"
    },
    {
      city: "GLENWOOD",
      state: "NM"
    },
    {
      city: "GLENWOOD",
      state: "NY"
    },
    {
      city: "GLENWOOD",
      state: "UT"
    },
    {
      city: "GLENWOOD",
      state: "WA"
    },
    {
      city: "GLENWOOD",
      state: "WV"
    },
    {
      city: "GLENWOOD CITY",
      state: "WI"
    },
    {
      city: "GLENWOOD LANDING",
      state: "NY"
    },
    {
      city: "GLENWOOD SPRINGS",
      state: "CO"
    },
    {
      city: "GLIDDEN",
      state: "IA"
    },
    {
      city: "GLIDDEN",
      state: "TX"
    },
    {
      city: "GLIDDEN",
      state: "WI"
    },
    {
      city: "GLIDE",
      state: "OR"
    },
    {
      city: "GLOBE",
      state: "AZ"
    },
    {
      city: "GLORIETA",
      state: "NM"
    },
    {
      city: "GLOSTER",
      state: "LA"
    },
    {
      city: "GLOSTER",
      state: "MS"
    },
    {
      city: "GLOUCESTER",
      state: "MA"
    },
    {
      city: "GLOUCESTER",
      state: "NC"
    },
    {
      city: "GLOUCESTER",
      state: "VA"
    },
    {
      city: "GLOUCESTER CITY",
      state: "NJ"
    },
    {
      city: "GLOUCESTER POINT",
      state: "VA"
    },
    {
      city: "GLOUSTER",
      state: "OH"
    },
    {
      city: "GLOVER",
      state: "VT"
    },
    {
      city: "GLOVERSVILLE",
      state: "NY"
    },
    {
      city: "GLOVERVILLE",
      state: "SC"
    },
    {
      city: "GLYNDON",
      state: "MD"
    },
    {
      city: "GLYNDON",
      state: "MN"
    },
    {
      city: "GLYNN",
      state: "LA"
    },
    {
      city: "GNADENHUTTEN",
      state: "OH"
    },
    {
      city: "GOBER",
      state: "TX"
    },
    {
      city: "GOBLER",
      state: "MO"
    },
    {
      city: "GOBLES",
      state: "MI"
    },
    {
      city: "GODDARD",
      state: "KS"
    },
    {
      city: "GODFREY",
      state: "IL"
    },
    {
      city: "GODLEY",
      state: "TX"
    },
    {
      city: "GODWIN",
      state: "NC"
    },
    {
      city: "GOEHNER",
      state: "NE"
    },
    {
      city: "GOESSEL",
      state: "KS"
    },
    {
      city: "GOETZVILLE",
      state: "MI"
    },
    {
      city: "GOFF",
      state: "KS"
    },
    {
      city: "GOFFSTOWN",
      state: "NH"
    },
    {
      city: "GOLCONDA",
      state: "IL"
    },
    {
      city: "GOLCONDA",
      state: "NV"
    },
    {
      city: "GOLD BAR",
      state: "WA"
    },
    {
      city: "GOLD BEACH",
      state: "OR"
    },
    {
      city: "GOLD CANYON",
      state: "AZ"
    },
    {
      city: "GOLD CREEK",
      state: "MT"
    },
    {
      city: "GOLD HILL",
      state: "NC"
    },
    {
      city: "GOLD HILL",
      state: "OR"
    },
    {
      city: "GOLD RUN",
      state: "CA"
    },
    {
      city: "GOLDEN",
      state: "CO"
    },
    {
      city: "GOLDEN",
      state: "IL"
    },
    {
      city: "GOLDEN",
      state: "MO"
    },
    {
      city: "GOLDEN",
      state: "MS"
    },
    {
      city: "GOLDEN",
      state: "TX"
    },
    {
      city: "GOLDEN CITY",
      state: "MO"
    },
    {
      city: "GOLDEN EAGLE",
      state: "IL"
    },
    {
      city: "GOLDEN GATE",
      state: "IL"
    },
    {
      city: "GOLDEN MEADOW",
      state: "LA"
    },
    {
      city: "GOLDEN VALLEY",
      state: "AZ"
    },
    {
      city: "GOLDEN VALLEY",
      state: "ND"
    },
    {
      city: "GOLDENDALE",
      state: "WA"
    },
    {
      city: "GOLDENROD",
      state: "FL"
    },
    {
      city: "GOLDENS BRIDGE",
      state: "NY"
    },
    {
      city: "GOLDFIELD",
      state: "IA"
    },
    {
      city: "GOLDFIELD",
      state: "NV"
    },
    {
      city: "GOLDONNA",
      state: "LA"
    },
    {
      city: "GOLDSBORO",
      state: "MD"
    },
    {
      city: "GOLDSBORO",
      state: "NC"
    },
    {
      city: "GOLDSBORO",
      state: "TX"
    },
    {
      city: "GOLDSMITH",
      state: "IN"
    },
    {
      city: "GOLDSMITH",
      state: "TX"
    },
    {
      city: "GOLDSTON",
      state: "NC"
    },
    {
      city: "GOLDTHWAITE",
      state: "TX"
    },
    {
      city: "GOLDVEIN",
      state: "VA"
    },
    {
      city: "GOLETA",
      state: "CA"
    },
    {
      city: "GOLF",
      state: "IL"
    },
    {
      city: "GOLIAD",
      state: "TX"
    },
    {
      city: "GOLTRY",
      state: "OK"
    },
    {
      city: "GOLVA",
      state: "ND"
    },
    {
      city: "GOMER",
      state: "OH"
    },
    {
      city: "GONVICK",
      state: "MN"
    },
    {
      city: "GONZALES",
      state: "CA"
    },
    {
      city: "GONZALES",
      state: "LA"
    },
    {
      city: "GONZALES",
      state: "TX"
    },
    {
      city: "GONZALEZ",
      state: "FL"
    },
    {
      city: "GOOCHLAND",
      state: "VA"
    },
    {
      city: "GOOD HART",
      state: "MI"
    },
    {
      city: "GOOD HOPE",
      state: "GA"
    },
    {
      city: "GOOD HOPE",
      state: "IL"
    },
    {
      city: "GOOD THUNDER",
      state: "MN"
    },
    {
      city: "GOODE",
      state: "VA"
    },
    {
      city: "GOODELL",
      state: "IA"
    },
    {
      city: "GOODELLS",
      state: "MI"
    },
    {
      city: "GOODFELLOW AFB",
      state: "TX"
    },
    {
      city: "GOODFIELD",
      state: "IL"
    },
    {
      city: "GOODHUE",
      state: "MN"
    },
    {
      city: "GOODING",
      state: "ID"
    },
    {
      city: "GOODLAND",
      state: "FL"
    },
    {
      city: "GOODLAND",
      state: "IN"
    },
    {
      city: "GOODLAND",
      state: "KS"
    },
    {
      city: "GOODLAND",
      state: "MN"
    },
    {
      city: "GOODLETTSVILLE",
      state: "TN"
    },
    {
      city: "GOODMAN",
      state: "MO"
    },
    {
      city: "GOODMAN",
      state: "MS"
    },
    {
      city: "GOODMAN",
      state: "WI"
    },
    {
      city: "GOODNEWS BAY",
      state: "AK"
    },
    {
      city: "GOODRICH",
      state: "MI"
    },
    {
      city: "GOODRICH",
      state: "ND"
    },
    {
      city: "GOODRICH",
      state: "TX"
    },
    {
      city: "GOODRIDGE",
      state: "MN"
    },
    {
      city: "GOODSPRING",
      state: "TN"
    },
    {
      city: "GOODSPRINGS",
      state: "AL"
    },
    {
      city: "GOODVIEW",
      state: "VA"
    },
    {
      city: "GOODVILLE",
      state: "PA"
    },
    {
      city: "GOODWATER",
      state: "AL"
    },
    {
      city: "GOODWELL",
      state: "OK"
    },
    {
      city: "GOODWIN",
      state: "SD"
    },
    {
      city: "GOODWINE",
      state: "IL"
    },
    {
      city: "GOODYEAR",
      state: "AZ"
    },
    {
      city: "GOODYEARS BAR",
      state: "CA"
    },
    {
      city: "GOOSE CREEK",
      state: "SC"
    },
    {
      city: "GOOSE LAKE",
      state: "IA"
    },
    {
      city: "GORDO",
      state: "AL"
    },
    {
      city: "GORDON",
      state: "AL"
    },
    {
      city: "GORDON",
      state: "GA"
    },
    {
      city: "GORDON",
      state: "KY"
    },
    {
      city: "GORDON",
      state: "NE"
    },
    {
      city: "GORDON",
      state: "PA"
    },
    {
      city: "GORDON",
      state: "TX"
    },
    {
      city: "GORDON",
      state: "WI"
    },
    {
      city: "GORDONSVILLE",
      state: "TN"
    },
    {
      city: "GORDONSVILLE",
      state: "VA"
    },
    {
      city: "GORDONVILLE",
      state: "MO"
    },
    {
      city: "GORDONVILLE",
      state: "PA"
    },
    {
      city: "GORDONVILLE",
      state: "TX"
    },
    {
      city: "GORE",
      state: "OK"
    },
    {
      city: "GORE",
      state: "VA"
    },
    {
      city: "GORE SPRINGS",
      state: "MS"
    },
    {
      city: "GOREE",
      state: "TX"
    },
    {
      city: "GOREVILLE",
      state: "IL"
    },
    {
      city: "GORHAM",
      state: "IL"
    },
    {
      city: "GORHAM",
      state: "KS"
    },
    {
      city: "GORHAM",
      state: "ME"
    },
    {
      city: "GORHAM",
      state: "NH"
    },
    {
      city: "GORHAM",
      state: "NY"
    },
    {
      city: "GORIN",
      state: "MO"
    },
    {
      city: "GORMAN",
      state: "TX"
    },
    {
      city: "GORMANIA",
      state: "WV"
    },
    {
      city: "GOSHEN",
      state: "AL"
    },
    {
      city: "GOSHEN",
      state: "AR"
    },
    {
      city: "GOSHEN",
      state: "CA"
    },
    {
      city: "GOSHEN",
      state: "CT"
    },
    {
      city: "GOSHEN",
      state: "IN"
    },
    {
      city: "GOSHEN",
      state: "KY"
    },
    {
      city: "GOSHEN",
      state: "MA"
    },
    {
      city: "GOSHEN",
      state: "NH"
    },
    {
      city: "GOSHEN",
      state: "NJ"
    },
    {
      city: "GOSHEN",
      state: "NY"
    },
    {
      city: "GOSHEN",
      state: "OH"
    },
    {
      city: "GOSHEN",
      state: "UT"
    },
    {
      city: "GOSHEN",
      state: "VA"
    },
    {
      city: "GOSNELL",
      state: "AR"
    },
    {
      city: "GOSPORT",
      state: "IN"
    },
    {
      city: "GOTEBO",
      state: "OK"
    },
    {
      city: "GOTHA",
      state: "FL"
    },
    {
      city: "GOTHAM",
      state: "WI"
    },
    {
      city: "GOTHENBURG",
      state: "NE"
    },
    {
      city: "GOULD",
      state: "AR"
    },
    {
      city: "GOULD",
      state: "OK"
    },
    {
      city: "GOULD CITY",
      state: "MI"
    },
    {
      city: "GOULDBUSK",
      state: "TX"
    },
    {
      city: "GOULDSBORO",
      state: "ME"
    },
    {
      city: "GOULDSBORO",
      state: "PA"
    },
    {
      city: "GOUVERNEUR",
      state: "NY"
    },
    {
      city: "GOVE",
      state: "KS"
    },
    {
      city: "GOVERNMENT CAMP",
      state: "OR"
    },
    {
      city: "GOWANDA",
      state: "NY"
    },
    {
      city: "GOWEN",
      state: "MI"
    },
    {
      city: "GOWEN",
      state: "OK"
    },
    {
      city: "GOWER",
      state: "MO"
    },
    {
      city: "GOWRIE",
      state: "IA"
    },
    {
      city: "GRABILL",
      state: "IN"
    },
    {
      city: "GRACE",
      state: "ID"
    },
    {
      city: "GRACE",
      state: "MS"
    },
    {
      city: "GRACE CITY",
      state: "ND"
    },
    {
      city: "GRACEMONT",
      state: "OK"
    },
    {
      city: "GRACEVILLE",
      state: "FL"
    },
    {
      city: "GRACEVILLE",
      state: "MN"
    },
    {
      city: "GRACEWOOD",
      state: "GA"
    },
    {
      city: "GRACEY",
      state: "KY"
    },
    {
      city: "GRADY",
      state: "AL"
    },
    {
      city: "GRADY",
      state: "AR"
    },
    {
      city: "GRADY",
      state: "NM"
    },
    {
      city: "GRADYVILLE",
      state: "PA"
    },
    {
      city: "GRAETTINGER",
      state: "IA"
    },
    {
      city: "GRAFF",
      state: "MO"
    },
    {
      city: "GRAFORD",
      state: "TX"
    },
    {
      city: "GRAFTON",
      state: "IA"
    },
    {
      city: "GRAFTON",
      state: "IL"
    },
    {
      city: "GRAFTON",
      state: "MA"
    },
    {
      city: "GRAFTON",
      state: "ND"
    },
    {
      city: "GRAFTON",
      state: "NE"
    },
    {
      city: "GRAFTON",
      state: "NH"
    },
    {
      city: "GRAFTON",
      state: "NY"
    },
    {
      city: "GRAFTON",
      state: "OH"
    },
    {
      city: "GRAFTON",
      state: "VT"
    },
    {
      city: "GRAFTON",
      state: "WI"
    },
    {
      city: "GRAFTON",
      state: "WV"
    },
    {
      city: "GRAHAM",
      state: "AL"
    },
    {
      city: "GRAHAM",
      state: "FL"
    },
    {
      city: "GRAHAM",
      state: "KY"
    },
    {
      city: "GRAHAM",
      state: "MO"
    },
    {
      city: "GRAHAM",
      state: "NC"
    },
    {
      city: "GRAHAM",
      state: "TX"
    },
    {
      city: "GRAHAM",
      state: "WA"
    },
    {
      city: "GRAHAMSVILLE",
      state: "NY"
    },
    {
      city: "GRAHN",
      state: "KY"
    },
    {
      city: "GRAIN VALLEY",
      state: "MO"
    },
    {
      city: "GRAINFIELD",
      state: "KS"
    },
    {
      city: "GRAMBLING",
      state: "LA"
    },
    {
      city: "GRAMERCY",
      state: "LA"
    },
    {
      city: "GRAMLING",
      state: "SC"
    },
    {
      city: "GRAMMER",
      state: "IN"
    },
    {
      city: "GRAMPIAN",
      state: "PA"
    },
    {
      city: "GRANADA",
      state: "CO"
    },
    {
      city: "GRANADA",
      state: "MN"
    },
    {
      city: "GRANADA HILLS",
      state: "CA"
    },
    {
      city: "GRANBURY",
      state: "TX"
    },
    {
      city: "GRANBY",
      state: "CO"
    },
    {
      city: "GRANBY",
      state: "CT"
    },
    {
      city: "GRANBY",
      state: "MA"
    },
    {
      city: "GRANBY",
      state: "MO"
    },
    {
      city: "GRANBY",
      state: "VT"
    },
    {
      city: "GRAND BAY",
      state: "AL"
    },
    {
      city: "GRAND BLANC",
      state: "MI"
    },
    {
      city: "GRAND CANE",
      state: "LA"
    },
    {
      city: "GRAND CANYON",
      state: "AZ"
    },
    {
      city: "GRAND CHAIN",
      state: "IL"
    },
    {
      city: "GRAND CHENIER",
      state: "LA"
    },
    {
      city: "GRAND COTEAU",
      state: "LA"
    },
    {
      city: "GRAND COULEE",
      state: "WA"
    },
    {
      city: "GRAND FORKS",
      state: "ND"
    },
    {
      city: "GRAND FORKS AFB",
      state: "ND"
    },
    {
      city: "GRAND GORGE",
      state: "NY"
    },
    {
      city: "GRAND HAVEN",
      state: "MI"
    },
    {
      city: "GRAND ISLAND",
      state: "FL"
    },
    {
      city: "GRAND ISLAND",
      state: "NE"
    },
    {
      city: "GRAND ISLAND",
      state: "NY"
    },
    {
      city: "GRAND ISLE",
      state: "LA"
    },
    {
      city: "GRAND ISLE",
      state: "ME"
    },
    {
      city: "GRAND ISLE",
      state: "VT"
    },
    {
      city: "GRAND JUNCTION",
      state: "CO"
    },
    {
      city: "GRAND JUNCTION",
      state: "IA"
    },
    {
      city: "GRAND JUNCTION",
      state: "MI"
    },
    {
      city: "GRAND JUNCTION",
      state: "TN"
    },
    {
      city: "GRAND LAKE",
      state: "CO"
    },
    {
      city: "GRAND LAKE STREAM",
      state: "ME"
    },
    {
      city: "GRAND LEDGE",
      state: "MI"
    },
    {
      city: "GRAND MARAIS",
      state: "MI"
    },
    {
      city: "GRAND MARAIS",
      state: "MN"
    },
    {
      city: "GRAND MARSH",
      state: "WI"
    },
    {
      city: "GRAND MEADOW",
      state: "MN"
    },
    {
      city: "GRAND MOUND",
      state: "IA"
    },
    {
      city: "GRAND PORTAGE",
      state: "MN"
    },
    {
      city: "GRAND PRAIRIE",
      state: "TX"
    },
    {
      city: "GRAND RAPIDS",
      state: "MI"
    },
    {
      city: "GRAND RAPIDS",
      state: "MN"
    },
    {
      city: "GRAND RAPIDS",
      state: "OH"
    },
    {
      city: "GRAND RIDGE",
      state: "FL"
    },
    {
      city: "GRAND RIDGE",
      state: "IL"
    },
    {
      city: "GRAND RIVER",
      state: "IA"
    },
    {
      city: "GRAND RIVER",
      state: "OH"
    },
    {
      city: "GRAND RIVERS",
      state: "KY"
    },
    {
      city: "GRAND RONDE",
      state: "OR"
    },
    {
      city: "GRAND SALINE",
      state: "TX"
    },
    {
      city: "GRAND TERRACE",
      state: "CA"
    },
    {
      city: "GRAND TOWER",
      state: "IL"
    },
    {
      city: "GRAND VALLEY",
      state: "PA"
    },
    {
      city: "GRAND VIEW",
      state: "ID"
    },
    {
      city: "GRANDFALLS",
      state: "TX"
    },
    {
      city: "GRANDFIELD",
      state: "OK"
    },
    {
      city: "GRANDIN",
      state: "FL"
    },
    {
      city: "GRANDIN",
      state: "MO"
    },
    {
      city: "GRANDIN",
      state: "ND"
    },
    {
      city: "GRANDVIEW",
      state: "IA"
    },
    {
      city: "GRANDVIEW",
      state: "IN"
    },
    {
      city: "GRANDVIEW",
      state: "MO"
    },
    {
      city: "GRANDVIEW",
      state: "TN"
    },
    {
      city: "GRANDVIEW",
      state: "TX"
    },
    {
      city: "GRANDVIEW",
      state: "WA"
    },
    {
      city: "GRANDVILLE",
      state: "MI"
    },
    {
      city: "GRANDY",
      state: "MN"
    },
    {
      city: "GRANDY",
      state: "NC"
    },
    {
      city: "GRANGER",
      state: "IA"
    },
    {
      city: "GRANGER",
      state: "IN"
    },
    {
      city: "GRANGER",
      state: "MO"
    },
    {
      city: "GRANGER",
      state: "TX"
    },
    {
      city: "GRANGER",
      state: "WA"
    },
    {
      city: "GRANGER",
      state: "WY"
    },
    {
      city: "GRANGEVILLE",
      state: "ID"
    },
    {
      city: "GRANITE",
      state: "OK"
    },
    {
      city: "GRANITE BAY",
      state: "CA"
    },
    {
      city: "GRANITE CANON",
      state: "WY"
    },
    {
      city: "GRANITE CITY",
      state: "IL"
    },
    {
      city: "GRANITE FALLS",
      state: "MN"
    },
    {
      city: "GRANITE FALLS",
      state: "NC"
    },
    {
      city: "GRANITE FALLS",
      state: "WA"
    },
    {
      city: "GRANITE QUARRY",
      state: "NC"
    },
    {
      city: "GRANITE SPRINGS",
      state: "NY"
    },
    {
      city: "GRANITEVILLE",
      state: "SC"
    },
    {
      city: "GRANITEVILLE",
      state: "VT"
    },
    {
      city: "GRANNIS",
      state: "AR"
    },
    {
      city: "GRANT",
      state: "AL"
    },
    {
      city: "GRANT",
      state: "CO"
    },
    {
      city: "GRANT",
      state: "FL"
    },
    {
      city: "GRANT",
      state: "IA"
    },
    {
      city: "GRANT",
      state: "LA"
    },
    {
      city: "GRANT",
      state: "MI"
    },
    {
      city: "GRANT",
      state: "NE"
    },
    {
      city: "GRANT",
      state: "OK"
    },
    {
      city: "GRANT CITY",
      state: "MO"
    },
    {
      city: "GRANT PARK",
      state: "IL"
    },
    {
      city: "GRANT TOWN",
      state: "WV"
    },
    {
      city: "GRANTHAM",
      state: "NH"
    },
    {
      city: "GRANTHAM",
      state: "PA"
    },
    {
      city: "GRANTON",
      state: "WI"
    },
    {
      city: "GRANTS",
      state: "NM"
    },
    {
      city: "GRANTS PASS",
      state: "OR"
    },
    {
      city: "GRANTSBORO",
      state: "NC"
    },
    {
      city: "GRANTSBURG",
      state: "IL"
    },
    {
      city: "GRANTSBURG",
      state: "WI"
    },
    {
      city: "GRANTSVILLE",
      state: "MD"
    },
    {
      city: "GRANTSVILLE",
      state: "UT"
    },
    {
      city: "GRANTSVILLE",
      state: "WV"
    },
    {
      city: "GRANTVILLE",
      state: "GA"
    },
    {
      city: "GRANTVILLE",
      state: "KS"
    },
    {
      city: "GRANTVILLE",
      state: "PA"
    },
    {
      city: "GRANVILLE",
      state: "IA"
    },
    {
      city: "GRANVILLE",
      state: "IL"
    },
    {
      city: "GRANVILLE",
      state: "MA"
    },
    {
      city: "GRANVILLE",
      state: "ND"
    },
    {
      city: "GRANVILLE",
      state: "NY"
    },
    {
      city: "GRANVILLE",
      state: "OH"
    },
    {
      city: "GRANVILLE",
      state: "PA"
    },
    {
      city: "GRANVILLE",
      state: "TN"
    },
    {
      city: "GRANVILLE",
      state: "VT"
    },
    {
      city: "GRANVILLE",
      state: "WV"
    },
    {
      city: "GRANVILLE SUMMIT",
      state: "PA"
    },
    {
      city: "GRAPELAND",
      state: "TX"
    },
    {
      city: "GRAPEVIEW",
      state: "WA"
    },
    {
      city: "GRAPEVILLE",
      state: "PA"
    },
    {
      city: "GRAPEVINE",
      state: "AR"
    },
    {
      city: "GRAPEVINE",
      state: "TX"
    },
    {
      city: "GRASONVILLE",
      state: "MD"
    },
    {
      city: "GRASS LAKE",
      state: "MI"
    },
    {
      city: "GRASS RANGE",
      state: "MT"
    },
    {
      city: "GRASS VALLEY",
      state: "CA"
    },
    {
      city: "GRASS VALLEY",
      state: "OR"
    },
    {
      city: "GRASSFLAT",
      state: "PA"
    },
    {
      city: "GRASSTON",
      state: "MN"
    },
    {
      city: "GRASSY BUTTE",
      state: "ND"
    },
    {
      city: "GRASSY CREEK",
      state: "NC"
    },
    {
      city: "GRATIOT",
      state: "OH"
    },
    {
      city: "GRATIOT",
      state: "WI"
    },
    {
      city: "GRATIS",
      state: "OH"
    },
    {
      city: "GRATON",
      state: "CA"
    },
    {
      city: "GRATZ",
      state: "PA"
    },
    {
      city: "GRAVEL SWITCH",
      state: "KY"
    },
    {
      city: "GRAVETTE",
      state: "AR"
    },
    {
      city: "GRAVITY",
      state: "IA"
    },
    {
      city: "GRAVOIS MILLS",
      state: "MO"
    },
    {
      city: "GRAWN",
      state: "MI"
    },
    {
      city: "GRAY",
      state: "GA"
    },
    {
      city: "GRAY",
      state: "IA"
    },
    {
      city: "GRAY",
      state: "KY"
    },
    {
      city: "GRAY",
      state: "LA"
    },
    {
      city: "GRAY",
      state: "ME"
    },
    {
      city: "GRAY COURT",
      state: "SC"
    },
    {
      city: "GRAY HAWK",
      state: "KY"
    },
    {
      city: "GRAY MOUNTAIN",
      state: "AZ"
    },
    {
      city: "GRAY SUMMIT",
      state: "MO"
    },
    {
      city: "GRAYLAND",
      state: "WA"
    },
    {
      city: "GRAYLING",
      state: "AK"
    },
    {
      city: "GRAYLING",
      state: "MI"
    },
    {
      city: "GRAYMONT",
      state: "IL"
    },
    {
      city: "GRAYS KNOB",
      state: "KY"
    },
    {
      city: "GRAYS RIVER",
      state: "WA"
    },
    {
      city: "GRAYSLAKE",
      state: "IL"
    },
    {
      city: "GRAYSON",
      state: "GA"
    },
    {
      city: "GRAYSON",
      state: "KY"
    },
    {
      city: "GRAYSON",
      state: "LA"
    },
    {
      city: "GRAYSVILLE",
      state: "AL"
    },
    {
      city: "GRAYSVILLE",
      state: "GA"
    },
    {
      city: "GRAYSVILLE",
      state: "IN"
    },
    {
      city: "GRAYSVILLE",
      state: "OH"
    },
    {
      city: "GRAYSVILLE",
      state: "PA"
    },
    {
      city: "GRAYSVILLE",
      state: "TN"
    },
    {
      city: "GRAYTOWN",
      state: "OH"
    },
    {
      city: "GRAYVILLE",
      state: "IL"
    },
    {
      city: "GREAT BARRINGTON",
      state: "MA"
    },
    {
      city: "GREAT BEND",
      state: "KS"
    },
    {
      city: "GREAT BEND",
      state: "NY"
    },
    {
      city: "GREAT BEND",
      state: "PA"
    },
    {
      city: "GREAT CACAPON",
      state: "WV"
    },
    {
      city: "GREAT FALLS",
      state: "MT"
    },
    {
      city: "GREAT FALLS",
      state: "SC"
    },
    {
      city: "GREAT FALLS",
      state: "VA"
    },
    {
      city: "GREAT LAKES",
      state: "IL"
    },
    {
      city: "GREAT MEADOWS",
      state: "NJ"
    },
    {
      city: "GREAT MILLS",
      state: "MD"
    },
    {
      city: "GREAT NECK",
      state: "NY"
    },
    {
      city: "GREAT RIVER",
      state: "NY"
    },
    {
      city: "GREAT VALLEY",
      state: "NY"
    },
    {
      city: "GREELEY",
      state: "CO"
    },
    {
      city: "GREELEY",
      state: "IA"
    },
    {
      city: "GREELEY",
      state: "KS"
    },
    {
      city: "GREELEY",
      state: "NE"
    },
    {
      city: "GREELEY",
      state: "PA"
    },
    {
      city: "GREELEYVILLE",
      state: "SC"
    },
    {
      city: "GREEN",
      state: "KS"
    },
    {
      city: "GREEN",
      state: "OH"
    },
    {
      city: "GREEN BANK",
      state: "WV"
    },
    {
      city: "GREEN BAY",
      state: "VA"
    },
    {
      city: "GREEN BAY",
      state: "WI"
    },
    {
      city: "GREEN CAMP",
      state: "OH"
    },
    {
      city: "GREEN CASTLE",
      state: "MO"
    },
    {
      city: "GREEN CITY",
      state: "MO"
    },
    {
      city: "GREEN COVE SPRINGS",
      state: "FL"
    },
    {
      city: "GREEN CREEK",
      state: "NJ"
    },
    {
      city: "GREEN FOREST",
      state: "AR"
    },
    {
      city: "GREEN HARBOR",
      state: "MA"
    },
    {
      city: "GREEN ISLE",
      state: "MN"
    },
    {
      city: "GREEN LAKE",
      state: "WI"
    },
    {
      city: "GREEN LANE",
      state: "PA"
    },
    {
      city: "GREEN MOUNTAIN",
      state: "NC"
    },
    {
      city: "GREEN MOUNTAIN FALLS",
      state: "CO"
    },
    {
      city: "GREEN POND",
      state: "AL"
    },
    {
      city: "GREEN POND",
      state: "SC"
    },
    {
      city: "GREEN RIDGE",
      state: "MO"
    },
    {
      city: "GREEN RIVER",
      state: "UT"
    },
    {
      city: "GREEN RIVER",
      state: "WY"
    },
    {
      city: "GREEN ROAD",
      state: "KY"
    },
    {
      city: "GREEN SEA",
      state: "SC"
    },
    {
      city: "GREEN SPRING",
      state: "WV"
    },
    {
      city: "GREEN SPRINGS",
      state: "OH"
    },
    {
      city: "GREEN SULPHUR SPRINGS",
      state: "WV"
    },
    {
      city: "GREEN VALLEY",
      state: "AZ"
    },
    {
      city: "GREEN VALLEY",
      state: "IL"
    },
    {
      city: "GREEN VALLEY",
      state: "WI"
    },
    {
      city: "GREEN VALLEY LAKE",
      state: "CA"
    },
    {
      city: "GREEN VILLAGE",
      state: "NJ"
    },
    {
      city: "GREENACRES",
      state: "FL"
    },
    {
      city: "GREENACRES",
      state: "WA"
    },
    {
      city: "GREENBACK",
      state: "TN"
    },
    {
      city: "GREENBACKVILLE",
      state: "VA"
    },
    {
      city: "GREENBANK",
      state: "WA"
    },
    {
      city: "GREENBELT",
      state: "MD"
    },
    {
      city: "GREENBRAE",
      state: "CA"
    },
    {
      city: "GREENBRIER",
      state: "AR"
    },
    {
      city: "GREENBRIER",
      state: "TN"
    },
    {
      city: "GREENBUSH",
      state: "MA"
    },
    {
      city: "GREENBUSH",
      state: "ME"
    },
    {
      city: "GREENBUSH",
      state: "MI"
    },
    {
      city: "GREENBUSH",
      state: "MN"
    },
    {
      city: "GREENBUSH",
      state: "VA"
    },
    {
      city: "GREENBUSH",
      state: "WI"
    },
    {
      city: "GREENCASTLE",
      state: "IN"
    },
    {
      city: "GREENCASTLE",
      state: "PA"
    },
    {
      city: "GREENCREEK",
      state: "ID"
    },
    {
      city: "GREENDALE",
      state: "WI"
    },
    {
      city: "GREENDELL",
      state: "NJ"
    },
    {
      city: "GREENE",
      state: "IA"
    },
    {
      city: "GREENE",
      state: "ME"
    },
    {
      city: "GREENE",
      state: "NY"
    },
    {
      city: "GREENE",
      state: "RI"
    },
    {
      city: "GREENEVILLE",
      state: "TN"
    },
    {
      city: "GREENFIELD",
      state: "CA"
    },
    {
      city: "GREENFIELD",
      state: "IA"
    },
    {
      city: "GREENFIELD",
      state: "IL"
    },
    {
      city: "GREENFIELD",
      state: "IN"
    },
    {
      city: "GREENFIELD",
      state: "MA"
    },
    {
      city: "GREENFIELD",
      state: "MO"
    },
    {
      city: "GREENFIELD",
      state: "NH"
    },
    {
      city: "GREENFIELD",
      state: "OH"
    },
    {
      city: "GREENFIELD",
      state: "OK"
    },
    {
      city: "GREENFIELD",
      state: "TN"
    },
    {
      city: "GREENFIELD CENTER",
      state: "NY"
    },
    {
      city: "GREENFIELD PARK",
      state: "NY"
    },
    {
      city: "GREENFORD",
      state: "OH"
    },
    {
      city: "GREENHURST",
      state: "NY"
    },
    {
      city: "GREENLAND",
      state: "AR"
    },
    {
      city: "GREENLAND",
      state: "MI"
    },
    {
      city: "GREENLAND",
      state: "NH"
    },
    {
      city: "GREENLAWN",
      state: "NY"
    },
    {
      city: "GREENLEAF",
      state: "ID"
    },
    {
      city: "GREENLEAF",
      state: "KS"
    },
    {
      city: "GREENLEAF",
      state: "WI"
    },
    {
      city: "GREENOCK",
      state: "PA"
    },
    {
      city: "GREENPORT",
      state: "NY"
    },
    {
      city: "GREENS FARMS",
      state: "CT"
    },
    {
      city: "GREENS FORK",
      state: "IN"
    },
    {
      city: "GREENSBORO",
      state: "AL"
    },
    {
      city: "GREENSBORO",
      state: "FL"
    },
    {
      city: "GREENSBORO",
      state: "GA"
    },
    {
      city: "GREENSBORO",
      state: "IN"
    },
    {
      city: "GREENSBORO",
      state: "MD"
    },
    {
      city: "GREENSBORO",
      state: "NC"
    },
    {
      city: "GREENSBORO",
      state: "PA"
    },
    {
      city: "GREENSBORO",
      state: "VT"
    },
    {
      city: "GREENSBORO BEND",
      state: "VT"
    },
    {
      city: "GREENSBURG",
      state: "IN"
    },
    {
      city: "GREENSBURG",
      state: "KS"
    },
    {
      city: "GREENSBURG",
      state: "KY"
    },
    {
      city: "GREENSBURG",
      state: "LA"
    },
    {
      city: "GREENSBURG",
      state: "PA"
    },
    {
      city: "GREENTOP",
      state: "MO"
    },
    {
      city: "GREENTOWN",
      state: "IN"
    },
    {
      city: "GREENTOWN",
      state: "OH"
    },
    {
      city: "GREENTOWN",
      state: "PA"
    },
    {
      city: "GREENUP",
      state: "IL"
    },
    {
      city: "GREENUP",
      state: "KY"
    },
    {
      city: "GREENVALE",
      state: "NY"
    },
    {
      city: "GREENVIEW",
      state: "CA"
    },
    {
      city: "GREENVIEW",
      state: "IL"
    },
    {
      city: "GREENVILLE",
      state: "AL"
    },
    {
      city: "GREENVILLE",
      state: "CA"
    },
    {
      city: "GREENVILLE",
      state: "FL"
    },
    {
      city: "GREENVILLE",
      state: "GA"
    },
    {
      city: "GREENVILLE",
      state: "IA"
    },
    {
      city: "GREENVILLE",
      state: "IL"
    },
    {
      city: "GREENVILLE",
      state: "IN"
    },
    {
      city: "GREENVILLE",
      state: "KY"
    },
    {
      city: "GREENVILLE",
      state: "ME"
    },
    {
      city: "GREENVILLE",
      state: "MI"
    },
    {
      city: "GREENVILLE",
      state: "MO"
    },
    {
      city: "GREENVILLE",
      state: "MS"
    },
    {
      city: "GREENVILLE",
      state: "NC"
    },
    {
      city: "GREENVILLE",
      state: "NH"
    },
    {
      city: "GREENVILLE",
      state: "NY"
    },
    {
      city: "GREENVILLE",
      state: "OH"
    },
    {
      city: "GREENVILLE",
      state: "PA"
    },
    {
      city: "GREENVILLE",
      state: "RI"
    },
    {
      city: "GREENVILLE",
      state: "SC"
    },
    {
      city: "GREENVILLE",
      state: "TX"
    },
    {
      city: "GREENVILLE",
      state: "UT"
    },
    {
      city: "GREENVILLE",
      state: "VA"
    },
    {
      city: "GREENVILLE",
      state: "WI"
    },
    {
      city: "GREENVILLE",
      state: "WV"
    },
    {
      city: "GREENVILLE JUNCTION",
      state: "ME"
    },
    {
      city: "GREENWALD",
      state: "MN"
    },
    {
      city: "GREENWAY",
      state: "AR"
    },
    {
      city: "GREENWELL SPRINGS",
      state: "LA"
    },
    {
      city: "GREENWICH",
      state: "CT"
    },
    {
      city: "GREENWICH",
      state: "NJ"
    },
    {
      city: "GREENWICH",
      state: "NY"
    },
    {
      city: "GREENWICH",
      state: "OH"
    },
    {
      city: "GREENWOOD",
      state: "AR"
    },
    {
      city: "GREENWOOD",
      state: "CA"
    },
    {
      city: "GREENWOOD",
      state: "DE"
    },
    {
      city: "GREENWOOD",
      state: "FL"
    },
    {
      city: "GREENWOOD",
      state: "IN"
    },
    {
      city: "GREENWOOD",
      state: "LA"
    },
    {
      city: "GREENWOOD",
      state: "ME"
    },
    {
      city: "GREENWOOD",
      state: "MO"
    },
    {
      city: "GREENWOOD",
      state: "MS"
    },
    {
      city: "GREENWOOD",
      state: "NE"
    },
    {
      city: "GREENWOOD",
      state: "NY"
    },
    {
      city: "GREENWOOD",
      state: "SC"
    },
    {
      city: "GREENWOOD",
      state: "TX"
    },
    {
      city: "GREENWOOD",
      state: "VA"
    },
    {
      city: "GREENWOOD",
      state: "WI"
    },
    {
      city: "GREENWOOD LAKE",
      state: "NY"
    },
    {
      city: "GREENWOOD SPRINGS",
      state: "MS"
    },
    {
      city: "GREER",
      state: "AZ"
    },
    {
      city: "GREER",
      state: "SC"
    },
    {
      city: "GREGORY",
      state: "MI"
    },
    {
      city: "GREGORY",
      state: "SD"
    },
    {
      city: "GREGORY",
      state: "TX"
    },
    {
      city: "GREIG",
      state: "NY"
    },
    {
      city: "GRELTON",
      state: "OH"
    },
    {
      city: "GRENADA",
      state: "CA"
    },
    {
      city: "GRENADA",
      state: "MS"
    },
    {
      city: "GRENLOCH",
      state: "NJ"
    },
    {
      city: "GRENOLA",
      state: "KS"
    },
    {
      city: "GRENORA",
      state: "ND"
    },
    {
      city: "GRENVILLE",
      state: "SD"
    },
    {
      city: "GRESHAM",
      state: "NE"
    },
    {
      city: "GRESHAM",
      state: "OR"
    },
    {
      city: "GRESHAM",
      state: "SC"
    },
    {
      city: "GRESHAM",
      state: "WI"
    },
    {
      city: "GRETHEL",
      state: "KY"
    },
    {
      city: "GRETNA",
      state: "FL"
    },
    {
      city: "GRETNA",
      state: "LA"
    },
    {
      city: "GRETNA",
      state: "NE"
    },
    {
      city: "GRETNA",
      state: "VA"
    },
    {
      city: "GREY EAGLE",
      state: "MN"
    },
    {
      city: "GREYBULL",
      state: "WY"
    },
    {
      city: "GREYCLIFF",
      state: "MT"
    },
    {
      city: "GRIDLEY",
      state: "CA"
    },
    {
      city: "GRIDLEY",
      state: "IL"
    },
    {
      city: "GRIDLEY",
      state: "KS"
    },
    {
      city: "GRIFFIN",
      state: "GA"
    },
    {
      city: "GRIFFIN",
      state: "IN"
    },
    {
      city: "GRIFFITH",
      state: "IN"
    },
    {
      city: "GRIFFITHSVILLE",
      state: "WV"
    },
    {
      city: "GRIFFITHVILLE",
      state: "AR"
    },
    {
      city: "GRIFTON",
      state: "NC"
    },
    {
      city: "GRIGGSVILLE",
      state: "IL"
    },
    {
      city: "GRIMES",
      state: "CA"
    },
    {
      city: "GRIMES",
      state: "IA"
    },
    {
      city: "GRIMESLAND",
      state: "NC"
    },
    {
      city: "GRIMSLEY",
      state: "TN"
    },
    {
      city: "GRIMSTEAD",
      state: "VA"
    },
    {
      city: "GRINDSTONE",
      state: "PA"
    },
    {
      city: "GRINNELL",
      state: "IA"
    },
    {
      city: "GRINNELL",
      state: "KS"
    },
    {
      city: "GRISSOM ARB",
      state: "IN"
    },
    {
      city: "GRISWOLD",
      state: "IA"
    },
    {
      city: "GRIZZLY FLATS",
      state: "CA"
    },
    {
      city: "GROESBECK",
      state: "TX"
    },
    {
      city: "GROOM",
      state: "TX"
    },
    {
      city: "GROSSE ILE",
      state: "MI"
    },
    {
      city: "GROSSE POINTE",
      state: "MI"
    },
    {
      city: "GROSSE TETE",
      state: "LA"
    },
    {
      city: "GROSVENOR DALE",
      state: "CT"
    },
    {
      city: "GROTON",
      state: "CT"
    },
    {
      city: "GROTON",
      state: "MA"
    },
    {
      city: "GROTON",
      state: "NY"
    },
    {
      city: "GROTON",
      state: "SD"
    },
    {
      city: "GROTON",
      state: "VT"
    },
    {
      city: "GROTTOES",
      state: "VA"
    },
    {
      city: "GROVE",
      state: "OK"
    },
    {
      city: "GROVE CITY",
      state: "MN"
    },
    {
      city: "GROVE CITY",
      state: "OH"
    },
    {
      city: "GROVE CITY",
      state: "PA"
    },
    {
      city: "GROVE HILL",
      state: "AL"
    },
    {
      city: "GROVELAND",
      state: "CA"
    },
    {
      city: "GROVELAND",
      state: "FL"
    },
    {
      city: "GROVELAND",
      state: "IL"
    },
    {
      city: "GROVELAND",
      state: "MA"
    },
    {
      city: "GROVELAND",
      state: "NY"
    },
    {
      city: "GROVEOAK",
      state: "AL"
    },
    {
      city: "GROVEPORT",
      state: "OH"
    },
    {
      city: "GROVER",
      state: "CO"
    },
    {
      city: "GROVER",
      state: "MO"
    },
    {
      city: "GROVER",
      state: "NC"
    },
    {
      city: "GROVER",
      state: "WY"
    },
    {
      city: "GROVER BEACH",
      state: "CA"
    },
    {
      city: "GROVER HILL",
      state: "OH"
    },
    {
      city: "GROVERTOWN",
      state: "IN"
    },
    {
      city: "GROVES",
      state: "TX"
    },
    {
      city: "GROVESPRING",
      state: "MO"
    },
    {
      city: "GROVETON",
      state: "NH"
    },
    {
      city: "GROVETON",
      state: "TX"
    },
    {
      city: "GROVETOWN",
      state: "GA"
    },
    {
      city: "GRUBBS",
      state: "AR"
    },
    {
      city: "GRUBVILLE",
      state: "MO"
    },
    {
      city: "GRUETLI LAAGER",
      state: "TN"
    },
    {
      city: "GRULLA",
      state: "TX"
    },
    {
      city: "GRUNDY",
      state: "VA"
    },
    {
      city: "GRUNDY CENTER",
      state: "IA"
    },
    {
      city: "GRUVER",
      state: "TX"
    },
    {
      city: "GRYGLA",
      state: "MN"
    },
    {
      city: "GUADALUPE",
      state: "CA"
    },
    {
      city: "GUADALUPITA",
      state: "NM"
    },
    {
      city: "GUALALA",
      state: "CA"
    },
    {
      city: "GUASTI",
      state: "CA"
    },
    {
      city: "GUATAY",
      state: "CA"
    },
    {
      city: "GUERNEVILLE",
      state: "CA"
    },
    {
      city: "GUERNSEY",
      state: "IA"
    },
    {
      city: "GUERNSEY",
      state: "WY"
    },
    {
      city: "GUEYDAN",
      state: "LA"
    },
    {
      city: "GUFFEY",
      state: "CO"
    },
    {
      city: "GUIDE ROCK",
      state: "NE"
    },
    {
      city: "GUILD",
      state: "NH"
    },
    {
      city: "GUILD",
      state: "TN"
    },
    {
      city: "GUILDERLAND",
      state: "NY"
    },
    {
      city: "GUILDERLAND CENTER",
      state: "NY"
    },
    {
      city: "GUILDHALL",
      state: "VT"
    },
    {
      city: "GUILFORD",
      state: "CT"
    },
    {
      city: "GUILFORD",
      state: "IN"
    },
    {
      city: "GUILFORD",
      state: "ME"
    },
    {
      city: "GUILFORD",
      state: "MO"
    },
    {
      city: "GUILFORD",
      state: "NY"
    },
    {
      city: "GUIN",
      state: "AL"
    },
    {
      city: "GUINDA",
      state: "CA"
    },
    {
      city: "GUION",
      state: "AR"
    },
    {
      city: "GULF",
      state: "NC"
    },
    {
      city: "GULF BREEZE",
      state: "FL"
    },
    {
      city: "GULF HAMMOCK",
      state: "FL"
    },
    {
      city: "GULF SHORES",
      state: "AL"
    },
    {
      city: "GULFPORT",
      state: "MS"
    },
    {
      city: "GULLIVER",
      state: "MI"
    },
    {
      city: "GULLY",
      state: "MN"
    },
    {
      city: "GULSTON",
      state: "KY"
    },
    {
      city: "GUM SPRING",
      state: "VA"
    },
    {
      city: "GUNLOCK",
      state: "KY"
    },
    {
      city: "GUNLOCK",
      state: "UT"
    },
    {
      city: "GUNNISON",
      state: "CO"
    },
    {
      city: "GUNNISON",
      state: "MS"
    },
    {
      city: "GUNNISON",
      state: "UT"
    },
    {
      city: "GUNPOWDER",
      state: "MD"
    },
    {
      city: "GUNTER",
      state: "TX"
    },
    {
      city: "GUNTERSVILLE",
      state: "AL"
    },
    {
      city: "GUNTOWN",
      state: "MS"
    },
    {
      city: "GURDON",
      state: "AR"
    },
    {
      city: "GURLEY",
      state: "AL"
    },
    {
      city: "GURLEY",
      state: "NE"
    },
    {
      city: "GURNEE",
      state: "IL"
    },
    {
      city: "GUSTAVUS",
      state: "AK"
    },
    {
      city: "GUSTINE",
      state: "CA"
    },
    {
      city: "GUSTINE",
      state: "TX"
    },
    {
      city: "GUSTON",
      state: "KY"
    },
    {
      city: "GUTHRIE",
      state: "KY"
    },
    {
      city: "GUTHRIE",
      state: "OK"
    },
    {
      city: "GUTHRIE CENTER",
      state: "IA"
    },
    {
      city: "GUTTENBERG",
      state: "IA"
    },
    {
      city: "GUY",
      state: "AR"
    },
    {
      city: "GUY",
      state: "TX"
    },
    {
      city: "GUYMON",
      state: "OK"
    },
    {
      city: "GUYS",
      state: "TN"
    },
    {
      city: "GUYS MILLS",
      state: "PA"
    },
    {
      city: "GUYSVILLE",
      state: "OH"
    },
    {
      city: "GUYTON",
      state: "GA"
    },
    {
      city: "GWINN",
      state: "MI"
    },
    {
      city: "GWINNER",
      state: "ND"
    },
    {
      city: "GWYNEDD",
      state: "PA"
    },
    {
      city: "GWYNEDD VALLEY",
      state: "PA"
    },
    {
      city: "GWYNN",
      state: "VA"
    },
    {
      city: "GWYNN OAK",
      state: "MD"
    },
    {
      city: "GWYNNEVILLE",
      state: "IN"
    },
    {
      city: "GYPSUM",
      state: "CO"
    },
    {
      city: "GYPSUM",
      state: "KS"
    },
    {
      city: "GYPSUM",
      state: "OH"
    },
    {
      city: "GYPSY",
      state: "WV"
    },
    {
      city: "HACHITA",
      state: "NM"
    },
    {
      city: "HACIENDA HEIGHTS",
      state: "CA"
    },
    {
      city: "HACKBERRY",
      state: "LA"
    },
    {
      city: "HACKENSACK",
      state: "MN"
    },
    {
      city: "HACKENSACK",
      state: "NJ"
    },
    {
      city: "HACKER VALLEY",
      state: "WV"
    },
    {
      city: "HACKETT",
      state: "AR"
    },
    {
      city: "HACKETTSTOWN",
      state: "NJ"
    },
    {
      city: "HACKLEBURG",
      state: "AL"
    },
    {
      city: "HACKSNECK",
      state: "VA"
    },
    {
      city: "HADAR",
      state: "NE"
    },
    {
      city: "HADDAM",
      state: "CT"
    },
    {
      city: "HADDAM",
      state: "KS"
    },
    {
      city: "HADDOCK",
      state: "GA"
    },
    {
      city: "HADDON HEIGHTS",
      state: "NJ"
    },
    {
      city: "HADDONFIELD",
      state: "NJ"
    },
    {
      city: "HADENSVILLE",
      state: "VA"
    },
    {
      city: "HADLEY",
      state: "MA"
    },
    {
      city: "HADLEY",
      state: "MI"
    },
    {
      city: "HADLEY",
      state: "NY"
    },
    {
      city: "HADLEY",
      state: "PA"
    },
    {
      city: "HADLYME",
      state: "CT"
    },
    {
      city: "HAGAMAN",
      state: "NY"
    },
    {
      city: "HAGAN",
      state: "GA"
    },
    {
      city: "HAGARSTOWN",
      state: "IL"
    },
    {
      city: "HAGARVILLE",
      state: "AR"
    },
    {
      city: "HAGER CITY",
      state: "WI"
    },
    {
      city: "HAGERHILL",
      state: "KY"
    },
    {
      city: "HAGERMAN",
      state: "ID"
    },
    {
      city: "HAGERMAN",
      state: "NM"
    },
    {
      city: "HAGERSTOWN",
      state: "IN"
    },
    {
      city: "HAGERSTOWN",
      state: "MD"
    },
    {
      city: "HAGUE",
      state: "ND"
    },
    {
      city: "HAGUE",
      state: "NY"
    },
    {
      city: "HAGUE",
      state: "VA"
    },
    {
      city: "HAHIRA",
      state: "GA"
    },
    {
      city: "HAHNVILLE",
      state: "LA"
    },
    {
      city: "HAIGLER",
      state: "NE"
    },
    {
      city: "HAIKU",
      state: "HI"
    },
    {
      city: "HAILESBORO",
      state: "NY"
    },
    {
      city: "HAILEY",
      state: "ID"
    },
    {
      city: "HAILEYVILLE",
      state: "OK"
    },
    {
      city: "HAINES",
      state: "AK"
    },
    {
      city: "HAINES",
      state: "OR"
    },
    {
      city: "HAINES CITY",
      state: "FL"
    },
    {
      city: "HAINES FALLS",
      state: "NY"
    },
    {
      city: "HAINESPORT",
      state: "NJ"
    },
    {
      city: "HAKALAU",
      state: "HI"
    },
    {
      city: "HALBUR",
      state: "IA"
    },
    {
      city: "HALCOTTSVILLE",
      state: "NY"
    },
    {
      city: "HALE",
      state: "MI"
    },
    {
      city: "HALE",
      state: "MO"
    },
    {
      city: "HALE CENTER",
      state: "TX"
    },
    {
      city: "HALEDON",
      state: "NJ"
    },
    {
      city: "HALEIWA",
      state: "HI"
    },
    {
      city: "HALES CORNERS",
      state: "WI"
    },
    {
      city: "HALETHORPE",
      state: "MD"
    },
    {
      city: "HALEYVILLE",
      state: "AL"
    },
    {
      city: "HALF MOON BAY",
      state: "CA"
    },
    {
      city: "HALF WAY",
      state: "MO"
    },
    {
      city: "HALFWAY",
      state: "OR"
    },
    {
      city: "HALIFAX",
      state: "MA"
    },
    {
      city: "HALIFAX",
      state: "NC"
    },
    {
      city: "HALIFAX",
      state: "PA"
    },
    {
      city: "HALIFAX",
      state: "VA"
    },
    {
      city: "HALL",
      state: "MT"
    },
    {
      city: "HALL",
      state: "NY"
    },
    {
      city: "HALL SUMMIT",
      state: "LA"
    },
    {
      city: "HALLAM",
      state: "NE"
    },
    {
      city: "HALLANDALE",
      state: "FL"
    },
    {
      city: "HALLETT",
      state: "OK"
    },
    {
      city: "HALLETTSVILLE",
      state: "TX"
    },
    {
      city: "HALLIDAY",
      state: "ND"
    },
    {
      city: "HALLIE",
      state: "KY"
    },
    {
      city: "HALLOCK",
      state: "MN"
    },
    {
      city: "HALLOWELL",
      state: "ME"
    },
    {
      city: "HALLS",
      state: "TN"
    },
    {
      city: "HALLSBORO",
      state: "NC"
    },
    {
      city: "HALLSTEAD",
      state: "PA"
    },
    {
      city: "HALLSVILLE",
      state: "MO"
    },
    {
      city: "HALLSVILLE",
      state: "TX"
    },
    {
      city: "HALLTOWN",
      state: "MO"
    },
    {
      city: "HALLTOWN",
      state: "WV"
    },
    {
      city: "HALLWOOD",
      state: "VA"
    },
    {
      city: "HALMA",
      state: "MN"
    },
    {
      city: "HALSEY",
      state: "NE"
    },
    {
      city: "HALSEY",
      state: "OR"
    },
    {
      city: "HALSTAD",
      state: "MN"
    },
    {
      city: "HALSTEAD",
      state: "KS"
    },
    {
      city: "HALTOM CITY",
      state: "TX"
    },
    {
      city: "HAMBLETON",
      state: "WV"
    },
    {
      city: "HAMBURG",
      state: "AR"
    },
    {
      city: "HAMBURG",
      state: "IA"
    },
    {
      city: "HAMBURG",
      state: "IL"
    },
    {
      city: "HAMBURG",
      state: "LA"
    },
    {
      city: "HAMBURG",
      state: "MI"
    },
    {
      city: "HAMBURG",
      state: "MN"
    },
    {
      city: "HAMBURG",
      state: "NJ"
    },
    {
      city: "HAMBURG",
      state: "NY"
    },
    {
      city: "HAMBURG",
      state: "PA"
    },
    {
      city: "HAMDEN",
      state: "CT"
    },
    {
      city: "HAMDEN",
      state: "NY"
    },
    {
      city: "HAMDEN",
      state: "OH"
    },
    {
      city: "HAMEL",
      state: "IL"
    },
    {
      city: "HAMEL",
      state: "MN"
    },
    {
      city: "HAMER",
      state: "ID"
    },
    {
      city: "HAMER",
      state: "SC"
    },
    {
      city: "HAMERSVILLE",
      state: "OH"
    },
    {
      city: "HAMILTON",
      state: "AL"
    },
    {
      city: "HAMILTON",
      state: "CO"
    },
    {
      city: "HAMILTON",
      state: "GA"
    },
    {
      city: "HAMILTON",
      state: "IA"
    },
    {
      city: "HAMILTON",
      state: "IL"
    },
    {
      city: "HAMILTON",
      state: "IN"
    },
    {
      city: "HAMILTON",
      state: "KS"
    },
    {
      city: "HAMILTON",
      state: "MA"
    },
    {
      city: "HAMILTON",
      state: "MI"
    },
    {
      city: "HAMILTON",
      state: "MO"
    },
    {
      city: "HAMILTON",
      state: "MS"
    },
    {
      city: "HAMILTON",
      state: "MT"
    },
    {
      city: "HAMILTON",
      state: "NC"
    },
    {
      city: "HAMILTON",
      state: "ND"
    },
    {
      city: "HAMILTON",
      state: "NY"
    },
    {
      city: "HAMILTON",
      state: "OH"
    },
    {
      city: "HAMILTON",
      state: "PA"
    },
    {
      city: "HAMILTON",
      state: "TX"
    },
    {
      city: "HAMILTON",
      state: "VA"
    },
    {
      city: "HAMILTON",
      state: "WA"
    },
    {
      city: "HAMILTON CITY",
      state: "CA"
    },
    {
      city: "HAMLER",
      state: "OH"
    },
    {
      city: "HAMLET",
      state: "IN"
    },
    {
      city: "HAMLET",
      state: "NC"
    },
    {
      city: "HAMLIN",
      state: "IA"
    },
    {
      city: "HAMLIN",
      state: "NY"
    },
    {
      city: "HAMLIN",
      state: "PA"
    },
    {
      city: "HAMLIN",
      state: "TX"
    },
    {
      city: "HAMLIN",
      state: "WV"
    },
    {
      city: "HAMMETT",
      state: "ID"
    },
    {
      city: "HAMMON",
      state: "OK"
    },
    {
      city: "HAMMOND",
      state: "IL"
    },
    {
      city: "HAMMOND",
      state: "IN"
    },
    {
      city: "HAMMOND",
      state: "LA"
    },
    {
      city: "HAMMOND",
      state: "MT"
    },
    {
      city: "HAMMOND",
      state: "NY"
    },
    {
      city: "HAMMOND",
      state: "OR"
    },
    {
      city: "HAMMOND",
      state: "WI"
    },
    {
      city: "HAMMONDSPORT",
      state: "NY"
    },
    {
      city: "HAMMONDSVILLE",
      state: "OH"
    },
    {
      city: "HAMMONTON",
      state: "NJ"
    },
    {
      city: "HAMPDEN",
      state: "MA"
    },
    {
      city: "HAMPDEN",
      state: "ME"
    },
    {
      city: "HAMPDEN",
      state: "ND"
    },
    {
      city: "HAMPDEN SYDNEY",
      state: "VA"
    },
    {
      city: "HAMPSHIRE",
      state: "IL"
    },
    {
      city: "HAMPSHIRE",
      state: "TN"
    },
    {
      city: "HAMPSTEAD",
      state: "MD"
    },
    {
      city: "HAMPSTEAD",
      state: "NC"
    },
    {
      city: "HAMPSTEAD",
      state: "NH"
    },
    {
      city: "HAMPTON",
      state: "AR"
    },
    {
      city: "HAMPTON",
      state: "CT"
    },
    {
      city: "HAMPTON",
      state: "FL"
    },
    {
      city: "HAMPTON",
      state: "GA"
    },
    {
      city: "HAMPTON",
      state: "IA"
    },
    {
      city: "HAMPTON",
      state: "IL"
    },
    {
      city: "HAMPTON",
      state: "KY"
    },
    {
      city: "HAMPTON",
      state: "MN"
    },
    {
      city: "HAMPTON",
      state: "NE"
    },
    {
      city: "HAMPTON",
      state: "NH"
    },
    {
      city: "HAMPTON",
      state: "NJ"
    },
    {
      city: "HAMPTON",
      state: "NY"
    },
    {
      city: "HAMPTON",
      state: "SC"
    },
    {
      city: "HAMPTON",
      state: "TN"
    },
    {
      city: "HAMPTON",
      state: "VA"
    },
    {
      city: "HAMPTON BAYS",
      state: "NY"
    },
    {
      city: "HAMPTON FALLS",
      state: "NH"
    },
    {
      city: "HAMPTONVILLE",
      state: "NC"
    },
    {
      city: "HAMSHIRE",
      state: "TX"
    },
    {
      city: "HAMTRAMCK",
      state: "MI"
    },
    {
      city: "HANA",
      state: "HI"
    },
    {
      city: "HANAHAN",
      state: "SC"
    },
    {
      city: "HANALEI",
      state: "HI"
    },
    {
      city: "HANAMAULU",
      state: "HI"
    },
    {
      city: "HANAPEPE",
      state: "HI"
    },
    {
      city: "HANCEVILLE",
      state: "AL"
    },
    {
      city: "HANCOCK",
      state: "IA"
    },
    {
      city: "HANCOCK",
      state: "MD"
    },
    {
      city: "HANCOCK",
      state: "ME"
    },
    {
      city: "HANCOCK",
      state: "MI"
    },
    {
      city: "HANCOCK",
      state: "MN"
    },
    {
      city: "HANCOCK",
      state: "NH"
    },
    {
      city: "HANCOCK",
      state: "NY"
    },
    {
      city: "HANCOCK",
      state: "VT"
    },
    {
      city: "HANCOCK",
      state: "WI"
    },
    {
      city: "HANCOCKS BRIDGE",
      state: "NJ"
    },
    {
      city: "HANDLEY",
      state: "WV"
    },
    {
      city: "HANFORD",
      state: "CA"
    },
    {
      city: "HANKAMER",
      state: "TX"
    },
    {
      city: "HANKINS",
      state: "NY"
    },
    {
      city: "HANKINSON",
      state: "ND"
    },
    {
      city: "HANKSVILLE",
      state: "UT"
    },
    {
      city: "HANLEY FALLS",
      state: "MN"
    },
    {
      city: "HANLONTOWN",
      state: "IA"
    },
    {
      city: "HANNA",
      state: "IN"
    },
    {
      city: "HANNA",
      state: "UT"
    },
    {
      city: "HANNA",
      state: "WY"
    },
    {
      city: "HANNA CITY",
      state: "IL"
    },
    {
      city: "HANNACROIX",
      state: "NY"
    },
    {
      city: "HANNAFORD",
      state: "ND"
    },
    {
      city: "HANNAH",
      state: "ND"
    },
    {
      city: "HANNASTOWN",
      state: "PA"
    },
    {
      city: "HANNAWA FALLS",
      state: "NY"
    },
    {
      city: "HANNIBAL",
      state: "MO"
    },
    {
      city: "HANNIBAL",
      state: "NY"
    },
    {
      city: "HANNIBAL",
      state: "OH"
    },
    {
      city: "HANOVER",
      state: "CT"
    },
    {
      city: "HANOVER",
      state: "IL"
    },
    {
      city: "HANOVER",
      state: "IN"
    },
    {
      city: "HANOVER",
      state: "KS"
    },
    {
      city: "HANOVER",
      state: "MA"
    },
    {
      city: "HANOVER",
      state: "MD"
    },
    {
      city: "HANOVER",
      state: "ME"
    },
    {
      city: "HANOVER",
      state: "MI"
    },
    {
      city: "HANOVER",
      state: "MN"
    },
    {
      city: "HANOVER",
      state: "NH"
    },
    {
      city: "HANOVER",
      state: "NM"
    },
    {
      city: "HANOVER",
      state: "PA"
    },
    {
      city: "HANOVER",
      state: "VA"
    },
    {
      city: "HANOVER",
      state: "WI"
    },
    {
      city: "HANOVER",
      state: "WV"
    },
    {
      city: "HANOVER PARK",
      state: "IL"
    },
    {
      city: "HANOVERTON",
      state: "OH"
    },
    {
      city: "HANSBORO",
      state: "ND"
    },
    {
      city: "HANSCOM AFB",
      state: "MA"
    },
    {
      city: "HANSEN",
      state: "ID"
    },
    {
      city: "HANSFORD",
      state: "WV"
    },
    {
      city: "HANSKA",
      state: "MN"
    },
    {
      city: "HANSON",
      state: "KY"
    },
    {
      city: "HANSON",
      state: "MA"
    },
    {
      city: "HANSTON",
      state: "KS"
    },
    {
      city: "HANSVILLE",
      state: "WA"
    },
    {
      city: "HAPPY",
      state: "KY"
    },
    {
      city: "HAPPY",
      state: "TX"
    },
    {
      city: "HAPPY CAMP",
      state: "CA"
    },
    {
      city: "HAPPY JACK",
      state: "AZ"
    },
    {
      city: "HAPPY VALLEY",
      state: "OR"
    },
    {
      city: "HARALSON",
      state: "GA"
    },
    {
      city: "HARBERT",
      state: "MI"
    },
    {
      city: "HARBESON",
      state: "DE"
    },
    {
      city: "HARBINGER",
      state: "NC"
    },
    {
      city: "HARBOR BEACH",
      state: "MI"
    },
    {
      city: "HARBOR CITY",
      state: "CA"
    },
    {
      city: "HARBOR SPRINGS",
      state: "MI"
    },
    {
      city: "HARBORCREEK",
      state: "PA"
    },
    {
      city: "HARBORSIDE",
      state: "ME"
    },
    {
      city: "HARBORTON",
      state: "VA"
    },
    {
      city: "HARCOURT",
      state: "IA"
    },
    {
      city: "HARDAWAY",
      state: "AL"
    },
    {
      city: "HARDEEVILLE",
      state: "SC"
    },
    {
      city: "HARDENVILLE",
      state: "MO"
    },
    {
      city: "HARDESTY",
      state: "OK"
    },
    {
      city: "HARDIN",
      state: "IL"
    },
    {
      city: "HARDIN",
      state: "KY"
    },
    {
      city: "HARDIN",
      state: "MO"
    },
    {
      city: "HARDIN",
      state: "MT"
    },
    {
      city: "HARDIN",
      state: "TX"
    },
    {
      city: "HARDINSBURG",
      state: "IN"
    },
    {
      city: "HARDINSBURG",
      state: "KY"
    },
    {
      city: "HARDTNER",
      state: "KS"
    },
    {
      city: "HARDWICK",
      state: "GA"
    },
    {
      city: "HARDWICK",
      state: "MA"
    },
    {
      city: "HARDWICK",
      state: "MN"
    },
    {
      city: "HARDWICK",
      state: "VT"
    },
    {
      city: "HARDY",
      state: "AR"
    },
    {
      city: "HARDY",
      state: "IA"
    },
    {
      city: "HARDY",
      state: "KY"
    },
    {
      city: "HARDY",
      state: "NE"
    },
    {
      city: "HARDY",
      state: "VA"
    },
    {
      city: "HARDYVILLE",
      state: "KY"
    },
    {
      city: "HARDYVILLE",
      state: "VA"
    },
    {
      city: "HARFORD",
      state: "NY"
    },
    {
      city: "HARFORD",
      state: "PA"
    },
    {
      city: "HARGILL",
      state: "TX"
    },
    {
      city: "HARKER HEIGHTS",
      state: "TX"
    },
    {
      city: "HARKERS ISLAND",
      state: "NC"
    },
    {
      city: "HARLAN",
      state: "IA"
    },
    {
      city: "HARLAN",
      state: "IN"
    },
    {
      city: "HARLAN",
      state: "KY"
    },
    {
      city: "HARLEIGH",
      state: "PA"
    },
    {
      city: "HARLEM",
      state: "GA"
    },
    {
      city: "HARLEM",
      state: "MT"
    },
    {
      city: "HARLETON",
      state: "TX"
    },
    {
      city: "HARLEYSVILLE",
      state: "PA"
    },
    {
      city: "HARLEYVILLE",
      state: "SC"
    },
    {
      city: "HARLINGEN",
      state: "TX"
    },
    {
      city: "HARLOWTON",
      state: "MT"
    },
    {
      city: "HARMAN",
      state: "WV"
    },
    {
      city: "HARMANS",
      state: "MD"
    },
    {
      city: "HARMON",
      state: "IL"
    },
    {
      city: "HARMONSBURG",
      state: "PA"
    },
    {
      city: "HARMONY",
      state: "CA"
    },
    {
      city: "HARMONY",
      state: "IN"
    },
    {
      city: "HARMONY",
      state: "ME"
    },
    {
      city: "HARMONY",
      state: "MN"
    },
    {
      city: "HARMONY",
      state: "NC"
    },
    {
      city: "HARMONY",
      state: "PA"
    },
    {
      city: "HARMONY",
      state: "RI"
    },
    {
      city: "HARNED",
      state: "KY"
    },
    {
      city: "HAROLD",
      state: "KY"
    },
    {
      city: "HARPER",
      state: "IA"
    },
    {
      city: "HARPER",
      state: "KS"
    },
    {
      city: "HARPER",
      state: "TX"
    },
    {
      city: "HARPER",
      state: "WV"
    },
    {
      city: "HARPER WOODS",
      state: "MI"
    },
    {
      city: "HARPERS FERRY",
      state: "IA"
    },
    {
      city: "HARPERS FERRY",
      state: "WV"
    },
    {
      city: "HARPERSFIELD",
      state: "NY"
    },
    {
      city: "HARPERSVILLE",
      state: "AL"
    },
    {
      city: "HARPERVILLE",
      state: "MS"
    },
    {
      city: "HARPSTER",
      state: "OH"
    },
    {
      city: "HARPSWELL",
      state: "ME"
    },
    {
      city: "HARPURSVILLE",
      state: "NY"
    },
    {
      city: "HARRAH",
      state: "OK"
    },
    {
      city: "HARRAH",
      state: "WA"
    },
    {
      city: "HARRELL",
      state: "AR"
    },
    {
      city: "HARRELLS",
      state: "NC"
    },
    {
      city: "HARRELLSVILLE",
      state: "NC"
    },
    {
      city: "HARRIET",
      state: "AR"
    },
    {
      city: "HARRIETTA",
      state: "MI"
    },
    {
      city: "HARRIMAN",
      state: "NY"
    },
    {
      city: "HARRIMAN",
      state: "TN"
    },
    {
      city: "HARRINGTON",
      state: "DE"
    },
    {
      city: "HARRINGTON",
      state: "ME"
    },
    {
      city: "HARRINGTON",
      state: "WA"
    },
    {
      city: "HARRINGTON PARK",
      state: "NJ"
    },
    {
      city: "HARRIS",
      state: "IA"
    },
    {
      city: "HARRIS",
      state: "MN"
    },
    {
      city: "HARRIS",
      state: "MO"
    },
    {
      city: "HARRIS",
      state: "NC"
    },
    {
      city: "HARRIS",
      state: "NY"
    },
    {
      city: "HARRISBURG",
      state: "AR"
    },
    {
      city: "HARRISBURG",
      state: "IL"
    },
    {
      city: "HARRISBURG",
      state: "MO"
    },
    {
      city: "HARRISBURG",
      state: "NC"
    },
    {
      city: "HARRISBURG",
      state: "NE"
    },
    {
      city: "HARRISBURG",
      state: "OH"
    },
    {
      city: "HARRISBURG",
      state: "OR"
    },
    {
      city: "HARRISBURG",
      state: "PA"
    },
    {
      city: "HARRISBURG",
      state: "SD"
    },
    {
      city: "HARRISON",
      state: "AR"
    },
    {
      city: "HARRISON",
      state: "GA"
    },
    {
      city: "HARRISON",
      state: "ID"
    },
    {
      city: "HARRISON",
      state: "ME"
    },
    {
      city: "HARRISON",
      state: "MI"
    },
    {
      city: "HARRISON",
      state: "MT"
    },
    {
      city: "HARRISON",
      state: "NE"
    },
    {
      city: "HARRISON",
      state: "NJ"
    },
    {
      city: "HARRISON",
      state: "NY"
    },
    {
      city: "HARRISON",
      state: "OH"
    },
    {
      city: "HARRISON",
      state: "SD"
    },
    {
      city: "HARRISON",
      state: "TN"
    },
    {
      city: "HARRISON CITY",
      state: "PA"
    },
    {
      city: "HARRISON TOWNSHIP",
      state: "MI"
    },
    {
      city: "HARRISON VALLEY",
      state: "PA"
    },
    {
      city: "HARRISONBURG",
      state: "LA"
    },
    {
      city: "HARRISONBURG",
      state: "VA"
    },
    {
      city: "HARRISONVILLE",
      state: "MO"
    },
    {
      city: "HARRISONVILLE",
      state: "NJ"
    },
    {
      city: "HARRISONVILLE",
      state: "PA"
    },
    {
      city: "HARRISTOWN",
      state: "IL"
    },
    {
      city: "HARRISVILLE",
      state: "MI"
    },
    {
      city: "HARRISVILLE",
      state: "MS"
    },
    {
      city: "HARRISVILLE",
      state: "NH"
    },
    {
      city: "HARRISVILLE",
      state: "NY"
    },
    {
      city: "HARRISVILLE",
      state: "OH"
    },
    {
      city: "HARRISVILLE",
      state: "PA"
    },
    {
      city: "HARRISVILLE",
      state: "RI"
    },
    {
      city: "HARRISVILLE",
      state: "WV"
    },
    {
      city: "HARROD",
      state: "OH"
    },
    {
      city: "HARRODS CREEK",
      state: "KY"
    },
    {
      city: "HARRODSBURG",
      state: "IN"
    },
    {
      city: "HARRODSBURG",
      state: "KY"
    },
    {
      city: "HARROGATE",
      state: "TN"
    },
    {
      city: "HARROLD",
      state: "SD"
    },
    {
      city: "HARROLD",
      state: "TX"
    },
    {
      city: "HARSENS ISLAND",
      state: "MI"
    },
    {
      city: "HARSHAW",
      state: "WI"
    },
    {
      city: "HART",
      state: "MI"
    },
    {
      city: "HART",
      state: "TX"
    },
    {
      city: "HARTFIELD",
      state: "VA"
    },
    {
      city: "HARTFORD",
      state: "AL"
    },
    {
      city: "HARTFORD",
      state: "AR"
    },
    {
      city: "HARTFORD",
      state: "CT"
    },
    {
      city: "HARTFORD",
      state: "IA"
    },
    {
      city: "HARTFORD",
      state: "IL"
    },
    {
      city: "HARTFORD",
      state: "KS"
    },
    {
      city: "HARTFORD",
      state: "KY"
    },
    {
      city: "HARTFORD",
      state: "MI"
    },
    {
      city: "HARTFORD",
      state: "NY"
    },
    {
      city: "HARTFORD",
      state: "OH"
    },
    {
      city: "HARTFORD",
      state: "SD"
    },
    {
      city: "HARTFORD",
      state: "TN"
    },
    {
      city: "HARTFORD",
      state: "VT"
    },
    {
      city: "HARTFORD",
      state: "WI"
    },
    {
      city: "HARTFORD",
      state: "WV"
    },
    {
      city: "HARTFORD CITY",
      state: "IN"
    },
    {
      city: "HARTINGTON",
      state: "NE"
    },
    {
      city: "HARTLAND",
      state: "ME"
    },
    {
      city: "HARTLAND",
      state: "MI"
    },
    {
      city: "HARTLAND",
      state: "MN"
    },
    {
      city: "HARTLAND",
      state: "VT"
    },
    {
      city: "HARTLAND",
      state: "WI"
    },
    {
      city: "HARTLAND FOUR CORNERS",
      state: "VT"
    },
    {
      city: "HARTLETON",
      state: "PA"
    },
    {
      city: "HARTLEY",
      state: "IA"
    },
    {
      city: "HARTLEY",
      state: "TX"
    },
    {
      city: "HARTLINE",
      state: "WA"
    },
    {
      city: "HARTLY",
      state: "DE"
    },
    {
      city: "HARTMAN",
      state: "AR"
    },
    {
      city: "HARTMAN",
      state: "CO"
    },
    {
      city: "HARTS",
      state: "WV"
    },
    {
      city: "HARTSBURG",
      state: "IL"
    },
    {
      city: "HARTSBURG",
      state: "MO"
    },
    {
      city: "HARTSDALE",
      state: "NY"
    },
    {
      city: "HARTSEL",
      state: "CO"
    },
    {
      city: "HARTSELLE",
      state: "AL"
    },
    {
      city: "HARTSFIELD",
      state: "GA"
    },
    {
      city: "HARTSHORNE",
      state: "OK"
    },
    {
      city: "HARTSTOWN",
      state: "PA"
    },
    {
      city: "HARTSVILLE",
      state: "IN"
    },
    {
      city: "HARTSVILLE",
      state: "SC"
    },
    {
      city: "HARTSVILLE",
      state: "TN"
    },
    {
      city: "HARTVILLE",
      state: "MO"
    },
    {
      city: "HARTVILLE",
      state: "OH"
    },
    {
      city: "HARTVILLE",
      state: "WY"
    },
    {
      city: "HARTWELL",
      state: "GA"
    },
    {
      city: "HARTWICK",
      state: "IA"
    },
    {
      city: "HARTWICK",
      state: "NY"
    },
    {
      city: "HARTWOOD",
      state: "VA"
    },
    {
      city: "HARVARD",
      state: "ID"
    },
    {
      city: "HARVARD",
      state: "IL"
    },
    {
      city: "HARVARD",
      state: "MA"
    },
    {
      city: "HARVARD",
      state: "NE"
    },
    {
      city: "HARVEL",
      state: "IL"
    },
    {
      city: "HARVEST",
      state: "AL"
    },
    {
      city: "HARVEY",
      state: "AR"
    },
    {
      city: "HARVEY",
      state: "IA"
    },
    {
      city: "HARVEY",
      state: "IL"
    },
    {
      city: "HARVEY",
      state: "LA"
    },
    {
      city: "HARVEY",
      state: "ND"
    },
    {
      city: "HARVEYS LAKE",
      state: "PA"
    },
    {
      city: "HARVEYSBURG",
      state: "OH"
    },
    {
      city: "HARVEYVILLE",
      state: "KS"
    },
    {
      city: "HARVIELL",
      state: "MO"
    },
    {
      city: "HARWICH",
      state: "MA"
    },
    {
      city: "HARWICH PORT",
      state: "MA"
    },
    {
      city: "HARWICK",
      state: "PA"
    },
    {
      city: "HARWINTON",
      state: "CT"
    },
    {
      city: "HARWOOD",
      state: "MD"
    },
    {
      city: "HARWOOD",
      state: "MO"
    },
    {
      city: "HARWOOD",
      state: "ND"
    },
    {
      city: "HARWOOD",
      state: "TX"
    },
    {
      city: "HARWOOD HEIGHTS",
      state: "IL"
    },
    {
      city: "HASBROUCK HEIGHTS",
      state: "NJ"
    },
    {
      city: "HASKELL",
      state: "NJ"
    },
    {
      city: "HASKELL",
      state: "OK"
    },
    {
      city: "HASKELL",
      state: "TX"
    },
    {
      city: "HASKINS",
      state: "OH"
    },
    {
      city: "HASLET",
      state: "TX"
    },
    {
      city: "HASLETT",
      state: "MI"
    },
    {
      city: "HASSELL",
      state: "NC"
    },
    {
      city: "HASTINGS",
      state: "FL"
    },
    {
      city: "HASTINGS",
      state: "IA"
    },
    {
      city: "HASTINGS",
      state: "MI"
    },
    {
      city: "HASTINGS",
      state: "MN"
    },
    {
      city: "HASTINGS",
      state: "NE"
    },
    {
      city: "HASTINGS",
      state: "NY"
    },
    {
      city: "HASTINGS",
      state: "OK"
    },
    {
      city: "HASTINGS",
      state: "PA"
    },
    {
      city: "HASTINGS ON HUDSON",
      state: "NY"
    },
    {
      city: "HASTY",
      state: "AR"
    },
    {
      city: "HASTY",
      state: "CO"
    },
    {
      city: "HASWELL",
      state: "CO"
    },
    {
      city: "HAT CREEK",
      state: "CA"
    },
    {
      city: "HATBORO",
      state: "PA"
    },
    {
      city: "HATCH",
      state: "NM"
    },
    {
      city: "HATCH",
      state: "UT"
    },
    {
      city: "HATCHECHUBBEE",
      state: "AL"
    },
    {
      city: "HATFIELD",
      state: "AR"
    },
    {
      city: "HATFIELD",
      state: "IN"
    },
    {
      city: "HATFIELD",
      state: "MA"
    },
    {
      city: "HATFIELD",
      state: "MO"
    },
    {
      city: "HATFIELD",
      state: "PA"
    },
    {
      city: "HATHAWAY",
      state: "MT"
    },
    {
      city: "HATHAWAY PINES",
      state: "CA"
    },
    {
      city: "HATHORNE",
      state: "MA"
    },
    {
      city: "HATLEY",
      state: "WI"
    },
    {
      city: "HATTERAS",
      state: "NC"
    },
    {
      city: "HATTIESBURG",
      state: "MS"
    },
    {
      city: "HATTIEVILLE",
      state: "AR"
    },
    {
      city: "HATTON",
      state: "ND"
    },
    {
      city: "HAUBSTADT",
      state: "IN"
    },
    {
      city: "HAUGAN",
      state: "MT"
    },
    {
      city: "HAUGEN",
      state: "WI"
    },
    {
      city: "HAUGHTON",
      state: "LA"
    },
    {
      city: "HAUPPAUGE",
      state: "NY"
    },
    {
      city: "HAUULA",
      state: "HI"
    },
    {
      city: "HAVANA",
      state: "AR"
    },
    {
      city: "HAVANA",
      state: "FL"
    },
    {
      city: "HAVANA",
      state: "IL"
    },
    {
      city: "HAVANA",
      state: "KS"
    },
    {
      city: "HAVANA",
      state: "ND"
    },
    {
      city: "HAVELOCK",
      state: "IA"
    },
    {
      city: "HAVELOCK",
      state: "NC"
    },
    {
      city: "HAVEN",
      state: "KS"
    },
    {
      city: "HAVENSVILLE",
      state: "KS"
    },
    {
      city: "HAVERFORD",
      state: "PA"
    },
    {
      city: "HAVERHILL",
      state: "IA"
    },
    {
      city: "HAVERHILL",
      state: "MA"
    },
    {
      city: "HAVERHILL",
      state: "NH"
    },
    {
      city: "HAVERHILL",
      state: "OH"
    },
    {
      city: "HAVERSTRAW",
      state: "NY"
    },
    {
      city: "HAVERTOWN",
      state: "PA"
    },
    {
      city: "HAVILAND",
      state: "KS"
    },
    {
      city: "HAVILAND",
      state: "OH"
    },
    {
      city: "HAVRE",
      state: "MT"
    },
    {
      city: "HAVRE DE GRACE",
      state: "MD"
    },
    {
      city: "HAW RIVER",
      state: "NC"
    },
    {
      city: "HAWAII NATIONAL PARK",
      state: "HI"
    },
    {
      city: "HAWAIIAN GARDENS",
      state: "CA"
    },
    {
      city: "HAWARDEN",
      state: "IA"
    },
    {
      city: "HAWESVILLE",
      state: "KY"
    },
    {
      city: "HAWI",
      state: "HI"
    },
    {
      city: "HAWK POINT",
      state: "MO"
    },
    {
      city: "HAWK RUN",
      state: "PA"
    },
    {
      city: "HAWK SPRINGS",
      state: "WY"
    },
    {
      city: "HAWKEYE",
      state: "IA"
    },
    {
      city: "HAWKINS",
      state: "TX"
    },
    {
      city: "HAWKINS",
      state: "WI"
    },
    {
      city: "HAWKINSVILLE",
      state: "GA"
    },
    {
      city: "HAWKS",
      state: "MI"
    },
    {
      city: "HAWLEY",
      state: "MN"
    },
    {
      city: "HAWLEY",
      state: "PA"
    },
    {
      city: "HAWLEY",
      state: "TX"
    },
    {
      city: "HAWLEYVILLE",
      state: "CT"
    },
    {
      city: "HAWORTH",
      state: "NJ"
    },
    {
      city: "HAWORTH",
      state: "OK"
    },
    {
      city: "HAWTHORN",
      state: "PA"
    },
    {
      city: "HAWTHORNE",
      state: "CA"
    },
    {
      city: "HAWTHORNE",
      state: "FL"
    },
    {
      city: "HAWTHORNE",
      state: "NJ"
    },
    {
      city: "HAWTHORNE",
      state: "NV"
    },
    {
      city: "HAWTHORNE",
      state: "NY"
    },
    {
      city: "HAWTHORNE",
      state: "WI"
    },
    {
      city: "HAXTUN",
      state: "CO"
    },
    {
      city: "HAY SPRINGS",
      state: "NE"
    },
    {
      city: "HAYDEN",
      state: "AL"
    },
    {
      city: "HAYDEN",
      state: "AZ"
    },
    {
      city: "HAYDEN",
      state: "CO"
    },
    {
      city: "HAYDEN",
      state: "ID"
    },
    {
      city: "HAYDEN",
      state: "IN"
    },
    {
      city: "HAYDENVILLE",
      state: "MA"
    },
    {
      city: "HAYDENVILLE",
      state: "OH"
    },
    {
      city: "HAYES",
      state: "LA"
    },
    {
      city: "HAYES",
      state: "SD"
    },
    {
      city: "HAYES",
      state: "VA"
    },
    {
      city: "HAYES CENTER",
      state: "NE"
    },
    {
      city: "HAYESVILLE",
      state: "NC"
    },
    {
      city: "HAYESVILLE",
      state: "OH"
    },
    {
      city: "HAYFIELD",
      state: "MN"
    },
    {
      city: "HAYFORK",
      state: "CA"
    },
    {
      city: "HAYMARKET",
      state: "VA"
    },
    {
      city: "HAYNESVILLE",
      state: "LA"
    },
    {
      city: "HAYNESVILLE",
      state: "VA"
    },
    {
      city: "HAYNEVILLE",
      state: "AL"
    },
    {
      city: "HAYS",
      state: "KS"
    },
    {
      city: "HAYS",
      state: "MT"
    },
    {
      city: "HAYS",
      state: "NC"
    },
    {
      city: "HAYSI",
      state: "VA"
    },
    {
      city: "HAYSVILLE",
      state: "KS"
    },
    {
      city: "HAYTI",
      state: "MO"
    },
    {
      city: "HAYTI",
      state: "SD"
    },
    {
      city: "HAYWARD",
      state: "CA"
    },
    {
      city: "HAYWARD",
      state: "MN"
    },
    {
      city: "HAYWARD",
      state: "WI"
    },
    {
      city: "HAYWOOD",
      state: "VA"
    },
    {
      city: "HAYWOOD",
      state: "WV"
    },
    {
      city: "HAZARD",
      state: "KY"
    },
    {
      city: "HAZARD",
      state: "NE"
    },
    {
      city: "HAZEL",
      state: "KY"
    },
    {
      city: "HAZEL",
      state: "SD"
    },
    {
      city: "HAZEL CREST",
      state: "IL"
    },
    {
      city: "HAZEL GREEN",
      state: "AL"
    },
    {
      city: "HAZEL GREEN",
      state: "KY"
    },
    {
      city: "HAZEL GREEN",
      state: "WI"
    },
    {
      city: "HAZEL HURST",
      state: "PA"
    },
    {
      city: "HAZEL PARK",
      state: "MI"
    },
    {
      city: "HAZELHURST",
      state: "WI"
    },
    {
      city: "HAZELTON",
      state: "ID"
    },
    {
      city: "HAZELTON",
      state: "KS"
    },
    {
      city: "HAZELTON",
      state: "ND"
    },
    {
      city: "HAZELWOOD",
      state: "MO"
    },
    {
      city: "HAZELWOOD",
      state: "NC"
    },
    {
      city: "HAZEN",
      state: "AR"
    },
    {
      city: "HAZEN",
      state: "ND"
    },
    {
      city: "HAZLEHURST",
      state: "GA"
    },
    {
      city: "HAZLEHURST",
      state: "MS"
    },
    {
      city: "HAZLET",
      state: "NJ"
    },
    {
      city: "HAZLETON",
      state: "IA"
    },
    {
      city: "HAZLETON",
      state: "IN"
    },
    {
      city: "HAZLETON",
      state: "PA"
    },
    {
      city: "HEAD WATERS",
      state: "VA"
    },
    {
      city: "HEADLAND",
      state: "AL"
    },
    {
      city: "HEADRICK",
      state: "OK"
    },
    {
      city: "HEAFFORD JUNCTION",
      state: "WI"
    },
    {
      city: "HEALDSBURG",
      state: "CA"
    },
    {
      city: "HEALDTON",
      state: "OK"
    },
    {
      city: "HEALY",
      state: "AK"
    },
    {
      city: "HEALY",
      state: "KS"
    },
    {
      city: "HEARNE",
      state: "TX"
    },
    {
      city: "HEART BUTTE",
      state: "MT"
    },
    {
      city: "HEARTWELL",
      state: "NE"
    },
    {
      city: "HEATERS",
      state: "WV"
    },
    {
      city: "HEATH",
      state: "MA"
    },
    {
      city: "HEATH",
      state: "OH"
    },
    {
      city: "HEATH SPRINGS",
      state: "SC"
    },
    {
      city: "HEATHSVILLE",
      state: "VA"
    },
    {
      city: "HEAVENER",
      state: "OK"
    },
    {
      city: "HEBBRONVILLE",
      state: "TX"
    },
    {
      city: "HEBER",
      state: "AZ"
    },
    {
      city: "HEBER",
      state: "CA"
    },
    {
      city: "HEBER CITY",
      state: "UT"
    },
    {
      city: "HEBER SPRINGS",
      state: "AR"
    },
    {
      city: "HEBO",
      state: "OR"
    },
    {
      city: "HEBRON",
      state: "CT"
    },
    {
      city: "HEBRON",
      state: "IL"
    },
    {
      city: "HEBRON",
      state: "IN"
    },
    {
      city: "HEBRON",
      state: "KY"
    },
    {
      city: "HEBRON",
      state: "MD"
    },
    {
      city: "HEBRON",
      state: "ME"
    },
    {
      city: "HEBRON",
      state: "ND"
    },
    {
      city: "HEBRON",
      state: "NE"
    },
    {
      city: "HEBRON",
      state: "NH"
    },
    {
      city: "HEBRON",
      state: "OH"
    },
    {
      city: "HECKER",
      state: "IL"
    },
    {
      city: "HECLA",
      state: "SD"
    },
    {
      city: "HECTOR",
      state: "AR"
    },
    {
      city: "HECTOR",
      state: "MN"
    },
    {
      city: "HECTOR",
      state: "NY"
    },
    {
      city: "HEDGESVILLE",
      state: "WV"
    },
    {
      city: "HEDLEY",
      state: "TX"
    },
    {
      city: "HEDRICK",
      state: "IA"
    },
    {
      city: "HEFLIN",
      state: "AL"
    },
    {
      city: "HEFLIN",
      state: "LA"
    },
    {
      city: "HEGINS",
      state: "PA"
    },
    {
      city: "HEIDELBERG",
      state: "MS"
    },
    {
      city: "HEIDENHEIMER",
      state: "TX"
    },
    {
      city: "HEIDRICK",
      state: "KY"
    },
    {
      city: "HEILWOOD",
      state: "PA"
    },
    {
      city: "HEISKELL",
      state: "TN"
    },
    {
      city: "HEISLERVILLE",
      state: "NJ"
    },
    {
      city: "HEISSON",
      state: "WA"
    },
    {
      city: "HELEN",
      state: "GA"
    },
    {
      city: "HELEN",
      state: "MD"
    },
    {
      city: "HELENA",
      state: "AL"
    },
    {
      city: "HELENA",
      state: "AR"
    },
    {
      city: "HELENA",
      state: "GA"
    },
    {
      city: "HELENA",
      state: "MO"
    },
    {
      city: "HELENA",
      state: "MT"
    },
    {
      city: "HELENA",
      state: "NY"
    },
    {
      city: "HELENA",
      state: "OH"
    },
    {
      city: "HELENA",
      state: "OK"
    },
    {
      city: "HELENDALE",
      state: "CA"
    },
    {
      city: "HELENVILLE",
      state: "WI"
    },
    {
      city: "HELENWOOD",
      state: "TN"
    },
    {
      city: "HELIX",
      state: "OR"
    },
    {
      city: "HELLERTOWN",
      state: "PA"
    },
    {
      city: "HELLIER",
      state: "KY"
    },
    {
      city: "HELM",
      state: "CA"
    },
    {
      city: "HELMETTA",
      state: "NJ"
    },
    {
      city: "HELMSBURG",
      state: "IN"
    },
    {
      city: "HELMVILLE",
      state: "MT"
    },
    {
      city: "HELOTES",
      state: "TX"
    },
    {
      city: "HELPER",
      state: "UT"
    },
    {
      city: "HELTON",
      state: "KY"
    },
    {
      city: "HELTONVILLE",
      state: "IN"
    },
    {
      city: "HELVETIA",
      state: "WV"
    },
    {
      city: "HEMATITE",
      state: "MO"
    },
    {
      city: "HEMET",
      state: "CA"
    },
    {
      city: "HEMINGFORD",
      state: "NE"
    },
    {
      city: "HEMINGWAY",
      state: "SC"
    },
    {
      city: "HEMLOCK",
      state: "IN"
    },
    {
      city: "HEMLOCK",
      state: "MI"
    },
    {
      city: "HEMLOCK",
      state: "NY"
    },
    {
      city: "HEMPHILL",
      state: "TX"
    },
    {
      city: "HEMPSTEAD",
      state: "NY"
    },
    {
      city: "HEMPSTEAD",
      state: "TX"
    },
    {
      city: "HENAGAR",
      state: "AL"
    },
    {
      city: "HENDERSON",
      state: "AR"
    },
    {
      city: "HENDERSON",
      state: "CO"
    },
    {
      city: "HENDERSON",
      state: "IA"
    },
    {
      city: "HENDERSON",
      state: "IL"
    },
    {
      city: "HENDERSON",
      state: "KY"
    },
    {
      city: "HENDERSON",
      state: "MD"
    },
    {
      city: "HENDERSON",
      state: "MI"
    },
    {
      city: "HENDERSON",
      state: "MN"
    },
    {
      city: "HENDERSON",
      state: "NC"
    },
    {
      city: "HENDERSON",
      state: "NE"
    },
    {
      city: "HENDERSON",
      state: "NV"
    },
    {
      city: "HENDERSON",
      state: "NY"
    },
    {
      city: "HENDERSON",
      state: "TN"
    },
    {
      city: "HENDERSON",
      state: "TX"
    },
    {
      city: "HENDERSON",
      state: "WV"
    },
    {
      city: "HENDERSON HARBOR",
      state: "NY"
    },
    {
      city: "HENDERSONVILLE",
      state: "NC"
    },
    {
      city: "HENDERSONVILLE",
      state: "PA"
    },
    {
      city: "HENDERSONVILLE",
      state: "TN"
    },
    {
      city: "HENDLEY",
      state: "NE"
    },
    {
      city: "HENDRICKS",
      state: "MN"
    },
    {
      city: "HENDRICKS",
      state: "WV"
    },
    {
      city: "HENDRIX",
      state: "OK"
    },
    {
      city: "HENDRUM",
      state: "MN"
    },
    {
      city: "HENEFER",
      state: "UT"
    },
    {
      city: "HENLEY",
      state: "MO"
    },
    {
      city: "HENNEPIN",
      state: "IL"
    },
    {
      city: "HENNEPIN",
      state: "OK"
    },
    {
      city: "HENNESSEY",
      state: "OK"
    },
    {
      city: "HENNIKER",
      state: "NH"
    },
    {
      city: "HENNING",
      state: "IL"
    },
    {
      city: "HENNING",
      state: "MN"
    },
    {
      city: "HENNING",
      state: "TN"
    },
    {
      city: "HENRICO",
      state: "NC"
    },
    {
      city: "HENRICO",
      state: "VA"
    },
    {
      city: "HENRIETTA",
      state: "MO"
    },
    {
      city: "HENRIETTA",
      state: "NC"
    },
    {
      city: "HENRIETTA",
      state: "NY"
    },
    {
      city: "HENRIETTA",
      state: "TX"
    },
    {
      city: "HENRIETTE",
      state: "MN"
    },
    {
      city: "HENRIEVILLE",
      state: "UT"
    },
    {
      city: "HENRY",
      state: "IL"
    },
    {
      city: "HENRY",
      state: "SD"
    },
    {
      city: "HENRY",
      state: "TN"
    },
    {
      city: "HENRY",
      state: "VA"
    },
    {
      city: "HENRYETTA",
      state: "OK"
    },
    {
      city: "HENRYVILLE",
      state: "IN"
    },
    {
      city: "HENRYVILLE",
      state: "PA"
    },
    {
      city: "HENSEL",
      state: "ND"
    },
    {
      city: "HENSLEY",
      state: "AR"
    },
    {
      city: "HENSONVILLE",
      state: "NY"
    },
    {
      city: "HEPHZIBAH",
      state: "GA"
    },
    {
      city: "HEPLER",
      state: "KS"
    },
    {
      city: "HEPPNER",
      state: "OR"
    },
    {
      city: "HEPZIBAH",
      state: "WV"
    },
    {
      city: "HERALD",
      state: "CA"
    },
    {
      city: "HERBSTER",
      state: "WI"
    },
    {
      city: "HERCULANEUM",
      state: "MO"
    },
    {
      city: "HERCULES",
      state: "CA"
    },
    {
      city: "HEREFORD",
      state: "AZ"
    },
    {
      city: "HEREFORD",
      state: "CO"
    },
    {
      city: "HEREFORD",
      state: "OR"
    },
    {
      city: "HEREFORD",
      state: "PA"
    },
    {
      city: "HEREFORD",
      state: "TX"
    },
    {
      city: "HERINGTON",
      state: "KS"
    },
    {
      city: "HERKIMER",
      state: "NY"
    },
    {
      city: "HERLONG",
      state: "CA"
    },
    {
      city: "HERMAN",
      state: "MN"
    },
    {
      city: "HERMAN",
      state: "NE"
    },
    {
      city: "HERMAN",
      state: "PA"
    },
    {
      city: "HERMANN",
      state: "MO"
    },
    {
      city: "HERMANSVILLE",
      state: "MI"
    },
    {
      city: "HERMANVILLE",
      state: "MS"
    },
    {
      city: "HERMINIE",
      state: "PA"
    },
    {
      city: "HERMISTON",
      state: "OR"
    },
    {
      city: "HERMITAGE",
      state: "AR"
    },
    {
      city: "HERMITAGE",
      state: "MO"
    },
    {
      city: "HERMITAGE",
      state: "PA"
    },
    {
      city: "HERMITAGE",
      state: "TN"
    },
    {
      city: "HERMLEIGH",
      state: "TX"
    },
    {
      city: "HERMON",
      state: "NY"
    },
    {
      city: "HERMOSA",
      state: "SD"
    },
    {
      city: "HERMOSA BEACH",
      state: "CA"
    },
    {
      city: "HERNANDEZ",
      state: "NM"
    },
    {
      city: "HERNANDO",
      state: "FL"
    },
    {
      city: "HERNANDO",
      state: "MS"
    },
    {
      city: "HERNDON",
      state: "KS"
    },
    {
      city: "HERNDON",
      state: "KY"
    },
    {
      city: "HERNDON",
      state: "PA"
    },
    {
      city: "HERNDON",
      state: "VA"
    },
    {
      city: "HERNDON",
      state: "WV"
    },
    {
      city: "HERNSHAW",
      state: "WV"
    },
    {
      city: "HEROD",
      state: "IL"
    },
    {
      city: "HERON",
      state: "MT"
    },
    {
      city: "HERON LAKE",
      state: "MN"
    },
    {
      city: "HERREID",
      state: "SD"
    },
    {
      city: "HERRICK",
      state: "IL"
    },
    {
      city: "HERRICK",
      state: "SD"
    },
    {
      city: "HERRIMAN",
      state: "UT"
    },
    {
      city: "HERRIN",
      state: "IL"
    },
    {
      city: "HERRON",
      state: "MI"
    },
    {
      city: "HERSCHER",
      state: "IL"
    },
    {
      city: "HERSEY",
      state: "MI"
    },
    {
      city: "HERSHEY",
      state: "NE"
    },
    {
      city: "HERSHEY",
      state: "PA"
    },
    {
      city: "HERTFORD",
      state: "NC"
    },
    {
      city: "HESPERIA",
      state: "CA"
    },
    {
      city: "HESPERIA",
      state: "MI"
    },
    {
      city: "HESPERUS",
      state: "CO"
    },
    {
      city: "HESSEL",
      state: "MI"
    },
    {
      city: "HESSMER",
      state: "LA"
    },
    {
      city: "HESSTON",
      state: "KS"
    },
    {
      city: "HESSTON",
      state: "PA"
    },
    {
      city: "HESTAND",
      state: "KY"
    },
    {
      city: "HESTER",
      state: "LA"
    },
    {
      city: "HETH",
      state: "AR"
    },
    {
      city: "HETTICK",
      state: "IL"
    },
    {
      city: "HETTINGER",
      state: "ND"
    },
    {
      city: "HEUVELTON",
      state: "NY"
    },
    {
      city: "HEWETT",
      state: "WV"
    },
    {
      city: "HEWITT",
      state: "MN"
    },
    {
      city: "HEWITT",
      state: "NJ"
    },
    {
      city: "HEWITT",
      state: "TX"
    },
    {
      city: "HEWITT",
      state: "WI"
    },
    {
      city: "HEWLETT",
      state: "NY"
    },
    {
      city: "HEYBURN",
      state: "ID"
    },
    {
      city: "HEYWORTH",
      state: "IL"
    },
    {
      city: "HI HAT",
      state: "KY"
    },
    {
      city: "HIALEAH",
      state: "FL"
    },
    {
      city: "HIAWASSEE",
      state: "GA"
    },
    {
      city: "HIAWATHA",
      state: "IA"
    },
    {
      city: "HIAWATHA",
      state: "KS"
    },
    {
      city: "HIAWATHA",
      state: "WV"
    },
    {
      city: "HIBBING",
      state: "MN"
    },
    {
      city: "HIBBS",
      state: "PA"
    },
    {
      city: "HIBERNIA",
      state: "NJ"
    },
    {
      city: "HICKMAN",
      state: "CA"
    },
    {
      city: "HICKMAN",
      state: "KY"
    },
    {
      city: "HICKMAN",
      state: "NE"
    },
    {
      city: "HICKMAN",
      state: "TN"
    },
    {
      city: "HICKORY",
      state: "KY"
    },
    {
      city: "HICKORY",
      state: "MS"
    },
    {
      city: "HICKORY",
      state: "NC"
    },
    {
      city: "HICKORY",
      state: "PA"
    },
    {
      city: "HICKORY CORNERS",
      state: "MI"
    },
    {
      city: "HICKORY FLAT",
      state: "MS"
    },
    {
      city: "HICKORY GROVE",
      state: "SC"
    },
    {
      city: "HICKORY HILLS",
      state: "IL"
    },
    {
      city: "HICKORY PLAINS",
      state: "AR"
    },
    {
      city: "HICKORY RIDGE",
      state: "AR"
    },
    {
      city: "HICKORY VALLEY",
      state: "TN"
    },
    {
      city: "HICKSVILLE",
      state: "NY"
    },
    {
      city: "HICKSVILLE",
      state: "OH"
    },
    {
      city: "HICO",
      state: "TX"
    },
    {
      city: "HICO",
      state: "WV"
    },
    {
      city: "HIDALGO",
      state: "IL"
    },
    {
      city: "HIDALGO",
      state: "TX"
    },
    {
      city: "HIDDEN VALLEY",
      state: "PA"
    },
    {
      city: "HIDDEN VALLEY LAKE",
      state: "CA"
    },
    {
      city: "HIDDENITE",
      state: "NC"
    },
    {
      city: "HIGBEE",
      state: "MO"
    },
    {
      city: "HIGDEN",
      state: "AR"
    },
    {
      city: "HIGDON",
      state: "AL"
    },
    {
      city: "HIGGANUM",
      state: "CT"
    },
    {
      city: "HIGGINS",
      state: "TX"
    },
    {
      city: "HIGGINS LAKE",
      state: "MI"
    },
    {
      city: "HIGGINSON",
      state: "AR"
    },
    {
      city: "HIGGINSPORT",
      state: "OH"
    },
    {
      city: "HIGGINSVILLE",
      state: "MO"
    },
    {
      city: "HIGH BRIDGE",
      state: "NJ"
    },
    {
      city: "HIGH BRIDGE",
      state: "WI"
    },
    {
      city: "HIGH FALLS",
      state: "NY"
    },
    {
      city: "HIGH HILL",
      state: "MO"
    },
    {
      city: "HIGH ISLAND",
      state: "TX"
    },
    {
      city: "HIGH POINT",
      state: "MO"
    },
    {
      city: "HIGH POINT",
      state: "NC"
    },
    {
      city: "HIGH RIDGE",
      state: "MO"
    },
    {
      city: "HIGH ROLLS MOUNTAIN PARK",
      state: "NM"
    },
    {
      city: "HIGH SHOALS",
      state: "GA"
    },
    {
      city: "HIGH SHOALS",
      state: "NC"
    },
    {
      city: "HIGH SPRINGS",
      state: "FL"
    },
    {
      city: "HIGH VIEW",
      state: "WV"
    },
    {
      city: "HIGHFALLS",
      state: "NC"
    },
    {
      city: "HIGHGATE CENTER",
      state: "VT"
    },
    {
      city: "HIGHGATE SPRINGS",
      state: "VT"
    },
    {
      city: "HIGHLAND",
      state: "CA"
    },
    {
      city: "HIGHLAND",
      state: "IL"
    },
    {
      city: "HIGHLAND",
      state: "IN"
    },
    {
      city: "HIGHLAND",
      state: "KS"
    },
    {
      city: "HIGHLAND",
      state: "MD"
    },
    {
      city: "HIGHLAND",
      state: "MI"
    },
    {
      city: "HIGHLAND",
      state: "NY"
    },
    {
      city: "HIGHLAND",
      state: "WI"
    },
    {
      city: "HIGHLAND CITY",
      state: "FL"
    },
    {
      city: "HIGHLAND FALLS",
      state: "NY"
    },
    {
      city: "HIGHLAND HOME",
      state: "AL"
    },
    {
      city: "HIGHLAND LAKE",
      state: "NY"
    },
    {
      city: "HIGHLAND LAKES",
      state: "NJ"
    },
    {
      city: "HIGHLAND MILLS",
      state: "NY"
    },
    {
      city: "HIGHLAND PARK",
      state: "IL"
    },
    {
      city: "HIGHLAND PARK",
      state: "MI"
    },
    {
      city: "HIGHLAND PARK",
      state: "NJ"
    },
    {
      city: "HIGHLANDS",
      state: "NC"
    },
    {
      city: "HIGHLANDS",
      state: "NJ"
    },
    {
      city: "HIGHLANDS",
      state: "TX"
    },
    {
      city: "HIGHLANDVILLE",
      state: "IA"
    },
    {
      city: "HIGHLANDVILLE",
      state: "MO"
    },
    {
      city: "HIGHMORE",
      state: "SD"
    },
    {
      city: "HIGHMOUNT",
      state: "NY"
    },
    {
      city: "HIGHSPIRE",
      state: "PA"
    },
    {
      city: "HIGHTSTOWN",
      state: "NJ"
    },
    {
      city: "HIGHWOOD",
      state: "IL"
    },
    {
      city: "HIGHWOOD",
      state: "MT"
    },
    {
      city: "HIGLEY",
      state: "AZ"
    },
    {
      city: "HIKO",
      state: "NV"
    },
    {
      city: "HILAND",
      state: "WY"
    },
    {
      city: "HILBERT",
      state: "WI"
    },
    {
      city: "HILDA",
      state: "SC"
    },
    {
      city: "HILDALE",
      state: "UT"
    },
    {
      city: "HILDEBRAN",
      state: "NC"
    },
    {
      city: "HILDRETH",
      state: "NE"
    },
    {
      city: "HILGER",
      state: "MT"
    },
    {
      city: "HILHAM",
      state: "TN"
    },
    {
      city: "HILL",
      state: "NH"
    },
    {
      city: "HILL AFB",
      state: "UT"
    },
    {
      city: "HILL CITY",
      state: "KS"
    },
    {
      city: "HILL CITY",
      state: "MN"
    },
    {
      city: "HILL CITY",
      state: "SD"
    },
    {
      city: "HILLBURN",
      state: "NY"
    },
    {
      city: "HILLER",
      state: "PA"
    },
    {
      city: "HILLIARD",
      state: "FL"
    },
    {
      city: "HILLIARD",
      state: "OH"
    },
    {
      city: "HILLIARDS",
      state: "PA"
    },
    {
      city: "HILLISTER",
      state: "TX"
    },
    {
      city: "HILLMAN",
      state: "MI"
    },
    {
      city: "HILLMAN",
      state: "MN"
    },
    {
      city: "HILLPOINT",
      state: "WI"
    },
    {
      city: "HILLROSE",
      state: "CO"
    },
    {
      city: "HILLS",
      state: "IA"
    },
    {
      city: "HILLS",
      state: "MN"
    },
    {
      city: "HILLSBORO",
      state: "AL"
    },
    {
      city: "HILLSBORO",
      state: "GA"
    },
    {
      city: "HILLSBORO",
      state: "IA"
    },
    {
      city: "HILLSBORO",
      state: "IL"
    },
    {
      city: "HILLSBORO",
      state: "IN"
    },
    {
      city: "HILLSBORO",
      state: "KS"
    },
    {
      city: "HILLSBORO",
      state: "KY"
    },
    {
      city: "HILLSBORO",
      state: "MD"
    },
    {
      city: "HILLSBORO",
      state: "MO"
    },
    {
      city: "HILLSBORO",
      state: "MS"
    },
    {
      city: "HILLSBORO",
      state: "ND"
    },
    {
      city: "HILLSBORO",
      state: "NM"
    },
    {
      city: "HILLSBORO",
      state: "OH"
    },
    {
      city: "HILLSBORO",
      state: "OR"
    },
    {
      city: "HILLSBORO",
      state: "TN"
    },
    {
      city: "HILLSBORO",
      state: "TX"
    },
    {
      city: "HILLSBORO",
      state: "WI"
    },
    {
      city: "HILLSBORO",
      state: "WV"
    },
    {
      city: "HILLSBOROUGH",
      state: "NC"
    },
    {
      city: "HILLSBOROUGH",
      state: "NH"
    },
    {
      city: "HILLSBOROUGH",
      state: "NJ"
    },
    {
      city: "HILLSDALE",
      state: "IL"
    },
    {
      city: "HILLSDALE",
      state: "IN"
    },
    {
      city: "HILLSDALE",
      state: "KS"
    },
    {
      city: "HILLSDALE",
      state: "MI"
    },
    {
      city: "HILLSDALE",
      state: "NJ"
    },
    {
      city: "HILLSDALE",
      state: "NY"
    },
    {
      city: "HILLSDALE",
      state: "OK"
    },
    {
      city: "HILLSDALE",
      state: "PA"
    },
    {
      city: "HILLSDALE",
      state: "WY"
    },
    {
      city: "HILLSGROVE",
      state: "PA"
    },
    {
      city: "HILLSIDE",
      state: "CO"
    },
    {
      city: "HILLSIDE",
      state: "IL"
    },
    {
      city: "HILLSIDE",
      state: "NJ"
    },
    {
      city: "HILLSVILLE",
      state: "PA"
    },
    {
      city: "HILLSVILLE",
      state: "VA"
    },
    {
      city: "HILLTOP",
      state: "WV"
    },
    {
      city: "HILLTOWN",
      state: "PA"
    },
    {
      city: "HILLVIEW",
      state: "IL"
    },
    {
      city: "HILLVIEW",
      state: "KY"
    },
    {
      city: "HILMAR",
      state: "CA"
    },
    {
      city: "HILO",
      state: "HI"
    },
    {
      city: "HILTON",
      state: "NY"
    },
    {
      city: "HILTON HEAD ISLAND",
      state: "SC"
    },
    {
      city: "HILTONS",
      state: "VA"
    },
    {
      city: "HIMA",
      state: "KY"
    },
    {
      city: "HIMROD",
      state: "NY"
    },
    {
      city: "HINCKLEY",
      state: "IL"
    },
    {
      city: "HINCKLEY",
      state: "ME"
    },
    {
      city: "HINCKLEY",
      state: "MN"
    },
    {
      city: "HINCKLEY",
      state: "NY"
    },
    {
      city: "HINCKLEY",
      state: "OH"
    },
    {
      city: "HINCKLEY",
      state: "UT"
    },
    {
      city: "HINDMAN",
      state: "KY"
    },
    {
      city: "HINDSBORO",
      state: "IL"
    },
    {
      city: "HINDSVILLE",
      state: "AR"
    },
    {
      city: "HINES",
      state: "IL"
    },
    {
      city: "HINES",
      state: "MN"
    },
    {
      city: "HINES",
      state: "OR"
    },
    {
      city: "HINESBURG",
      state: "VT"
    },
    {
      city: "HINESTON",
      state: "LA"
    },
    {
      city: "HINESVILLE",
      state: "GA"
    },
    {
      city: "HINGHAM",
      state: "MA"
    },
    {
      city: "HINGHAM",
      state: "MT"
    },
    {
      city: "HINGHAM",
      state: "WI"
    },
    {
      city: "HINKLE",
      state: "KY"
    },
    {
      city: "HINKLEY",
      state: "CA"
    },
    {
      city: "HINSDALE",
      state: "IL"
    },
    {
      city: "HINSDALE",
      state: "MA"
    },
    {
      city: "HINSDALE",
      state: "MT"
    },
    {
      city: "HINSDALE",
      state: "NH"
    },
    {
      city: "HINSDALE",
      state: "NY"
    },
    {
      city: "HINTON",
      state: "IA"
    },
    {
      city: "HINTON",
      state: "OK"
    },
    {
      city: "HINTON",
      state: "VA"
    },
    {
      city: "HINTON",
      state: "WV"
    },
    {
      city: "HIRAM",
      state: "GA"
    },
    {
      city: "HIRAM",
      state: "ME"
    },
    {
      city: "HIRAM",
      state: "OH"
    },
    {
      city: "HISEVILLE",
      state: "KY"
    },
    {
      city: "HITCHCOCK",
      state: "OK"
    },
    {
      city: "HITCHCOCK",
      state: "SD"
    },
    {
      city: "HITCHCOCK",
      state: "TX"
    },
    {
      city: "HITCHINS",
      state: "KY"
    },
    {
      city: "HITTERDAL",
      state: "MN"
    },
    {
      city: "HIWASSE",
      state: "AR"
    },
    {
      city: "HIWASSEE",
      state: "VA"
    },
    {
      city: "HIXSON",
      state: "TN"
    },
    {
      city: "HIXTON",
      state: "WI"
    },
    {
      city: "HO HO KUS",
      state: "NJ"
    },
    {
      city: "HOAGLAND",
      state: "IN"
    },
    {
      city: "HOBART",
      state: "IN"
    },
    {
      city: "HOBART",
      state: "NY"
    },
    {
      city: "HOBART",
      state: "OK"
    },
    {
      city: "HOBART",
      state: "WA"
    },
    {
      city: "HOBBS",
      state: "IN"
    },
    {
      city: "HOBBS",
      state: "NM"
    },
    {
      city: "HOBBSVILLE",
      state: "NC"
    },
    {
      city: "HOBE SOUND",
      state: "FL"
    },
    {
      city: "HOBGOOD",
      state: "NC"
    },
    {
      city: "HOBOKEN",
      state: "GA"
    },
    {
      city: "HOBOKEN",
      state: "NJ"
    },
    {
      city: "HOBSON",
      state: "MT"
    },
    {
      city: "HOBSON",
      state: "TX"
    },
    {
      city: "HOBUCKEN",
      state: "NC"
    },
    {
      city: "HOCKESSIN",
      state: "DE"
    },
    {
      city: "HOCKINGPORT",
      state: "OH"
    },
    {
      city: "HOCKLEY",
      state: "TX"
    },
    {
      city: "HODGE",
      state: "LA"
    },
    {
      city: "HODGEN",
      state: "OK"
    },
    {
      city: "HODGENVILLE",
      state: "KY"
    },
    {
      city: "HODGES",
      state: "AL"
    },
    {
      city: "HODGES",
      state: "SC"
    },
    {
      city: "HOEHNE",
      state: "CO"
    },
    {
      city: "HOFFMAN",
      state: "IL"
    },
    {
      city: "HOFFMAN",
      state: "MN"
    },
    {
      city: "HOFFMAN",
      state: "NC"
    },
    {
      city: "HOFFMAN ESTATES",
      state: "IL"
    },
    {
      city: "HOFFMEISTER",
      state: "NY"
    },
    {
      city: "HOGANSBURG",
      state: "NY"
    },
    {
      city: "HOGANSVILLE",
      state: "GA"
    },
    {
      city: "HOGELAND",
      state: "MT"
    },
    {
      city: "HOHENWALD",
      state: "TN"
    },
    {
      city: "HOISINGTON",
      state: "KS"
    },
    {
      city: "HOKAH",
      state: "MN"
    },
    {
      city: "HOLABIRD",
      state: "SD"
    },
    {
      city: "HOLBROOK",
      state: "AZ"
    },
    {
      city: "HOLBROOK",
      state: "ID"
    },
    {
      city: "HOLBROOK",
      state: "MA"
    },
    {
      city: "HOLBROOK",
      state: "NE"
    },
    {
      city: "HOLBROOK",
      state: "NY"
    },
    {
      city: "HOLBROOK",
      state: "PA"
    },
    {
      city: "HOLCOMB",
      state: "IL"
    },
    {
      city: "HOLCOMB",
      state: "KS"
    },
    {
      city: "HOLCOMB",
      state: "MO"
    },
    {
      city: "HOLCOMB",
      state: "MS"
    },
    {
      city: "HOLCOMBE",
      state: "WI"
    },
    {
      city: "HOLDEN",
      state: "LA"
    },
    {
      city: "HOLDEN",
      state: "MA"
    },
    {
      city: "HOLDEN",
      state: "ME"
    },
    {
      city: "HOLDEN",
      state: "MO"
    },
    {
      city: "HOLDEN",
      state: "UT"
    },
    {
      city: "HOLDEN",
      state: "WV"
    },
    {
      city: "HOLDENVILLE",
      state: "OK"
    },
    {
      city: "HOLDER",
      state: "FL"
    },
    {
      city: "HOLDERNESS",
      state: "NH"
    },
    {
      city: "HOLDINGFORD",
      state: "MN"
    },
    {
      city: "HOLDREGE",
      state: "NE"
    },
    {
      city: "HOLGATE",
      state: "OH"
    },
    {
      city: "HOLICONG",
      state: "PA"
    },
    {
      city: "HOLIDAY",
      state: "FL"
    },
    {
      city: "HOLLADAY",
      state: "TN"
    },
    {
      city: "HOLLAND",
      state: "IA"
    },
    {
      city: "HOLLAND",
      state: "IN"
    },
    {
      city: "HOLLAND",
      state: "KY"
    },
    {
      city: "HOLLAND",
      state: "MA"
    },
    {
      city: "HOLLAND",
      state: "MI"
    },
    {
      city: "HOLLAND",
      state: "MN"
    },
    {
      city: "HOLLAND",
      state: "MO"
    },
    {
      city: "HOLLAND",
      state: "NY"
    },
    {
      city: "HOLLAND",
      state: "OH"
    },
    {
      city: "HOLLAND",
      state: "TX"
    },
    {
      city: "HOLLAND PATENT",
      state: "NY"
    },
    {
      city: "HOLLANDALE",
      state: "MN"
    },
    {
      city: "HOLLANDALE",
      state: "MS"
    },
    {
      city: "HOLLANDALE",
      state: "WI"
    },
    {
      city: "HOLLANSBURG",
      state: "OH"
    },
    {
      city: "HOLLENBERG",
      state: "KS"
    },
    {
      city: "HOLLEY",
      state: "NY"
    },
    {
      city: "HOLLIDAY",
      state: "MO"
    },
    {
      city: "HOLLIDAY",
      state: "TX"
    },
    {
      city: "HOLLIDAYSBURG",
      state: "PA"
    },
    {
      city: "HOLLINS",
      state: "AL"
    },
    {
      city: "HOLLIS",
      state: "NH"
    },
    {
      city: "HOLLIS",
      state: "NY"
    },
    {
      city: "HOLLIS",
      state: "OK"
    },
    {
      city: "HOLLIS CENTER",
      state: "ME"
    },
    {
      city: "HOLLISTER",
      state: "CA"
    },
    {
      city: "HOLLISTER",
      state: "FL"
    },
    {
      city: "HOLLISTER",
      state: "MO"
    },
    {
      city: "HOLLISTER",
      state: "NC"
    },
    {
      city: "HOLLISTON",
      state: "MA"
    },
    {
      city: "HOLLOMAN AIR FORCE BASE",
      state: "NM"
    },
    {
      city: "HOLLOW ROCK",
      state: "TN"
    },
    {
      city: "HOLLOWAY",
      state: "MN"
    },
    {
      city: "HOLLOWVILLE",
      state: "NY"
    },
    {
      city: "HOLLSOPPLE",
      state: "PA"
    },
    {
      city: "HOLLY",
      state: "CO"
    },
    {
      city: "HOLLY",
      state: "MI"
    },
    {
      city: "HOLLY BLUFF",
      state: "MS"
    },
    {
      city: "HOLLY GROVE",
      state: "AR"
    },
    {
      city: "HOLLY HILL",
      state: "SC"
    },
    {
      city: "HOLLY POND",
      state: "AL"
    },
    {
      city: "HOLLY RIDGE",
      state: "NC"
    },
    {
      city: "HOLLY SPRINGS",
      state: "GA"
    },
    {
      city: "HOLLY SPRINGS",
      state: "MS"
    },
    {
      city: "HOLLY SPRINGS",
      state: "NC"
    },
    {
      city: "HOLLYTREE",
      state: "AL"
    },
    {
      city: "HOLLYWOOD",
      state: "AL"
    },
    {
      city: "HOLLYWOOD",
      state: "FL"
    },
    {
      city: "HOLLYWOOD",
      state: "MD"
    },
    {
      city: "HOLLYWOOD",
      state: "SC"
    },
    {
      city: "HOLMAN",
      state: "NM"
    },
    {
      city: "HOLMDEL",
      state: "NJ"
    },
    {
      city: "HOLMEN",
      state: "WI"
    },
    {
      city: "HOLMES",
      state: "NY"
    },
    {
      city: "HOLMES",
      state: "PA"
    },
    {
      city: "HOLMES BEACH",
      state: "FL"
    },
    {
      city: "HOLMES CITY",
      state: "MN"
    },
    {
      city: "HOLMES MILL",
      state: "KY"
    },
    {
      city: "HOLMESVILLE",
      state: "OH"
    },
    {
      city: "HOLSTEIN",
      state: "IA"
    },
    {
      city: "HOLSTEIN",
      state: "NE"
    },
    {
      city: "HOLT",
      state: "CA"
    },
    {
      city: "HOLT",
      state: "FL"
    },
    {
      city: "HOLT",
      state: "MI"
    },
    {
      city: "HOLT",
      state: "MO"
    },
    {
      city: "HOLTON",
      state: "IN"
    },
    {
      city: "HOLTON",
      state: "KS"
    },
    {
      city: "HOLTON",
      state: "MI"
    },
    {
      city: "HOLTS SUMMIT",
      state: "MO"
    },
    {
      city: "HOLTSVILLE",
      state: "NY"
    },
    {
      city: "HOLTVILLE",
      state: "CA"
    },
    {
      city: "HOLTWOOD",
      state: "PA"
    },
    {
      city: "HOLUALOA",
      state: "HI"
    },
    {
      city: "HOLY CITY",
      state: "CA"
    },
    {
      city: "HOLY CROSS",
      state: "AK"
    },
    {
      city: "HOLY CROSS",
      state: "IA"
    },
    {
      city: "HOLY TRINITY",
      state: "AL"
    },
    {
      city: "HOLYOKE",
      state: "CO"
    },
    {
      city: "HOLYOKE",
      state: "MA"
    },
    {
      city: "HOLYOKE",
      state: "MN"
    },
    {
      city: "HOLYROOD",
      state: "KS"
    },
    {
      city: "HOME",
      state: "KS"
    },
    {
      city: "HOME",
      state: "PA"
    },
    {
      city: "HOMEDALE",
      state: "ID"
    },
    {
      city: "HOMELAND",
      state: "CA"
    },
    {
      city: "HOMELAND",
      state: "FL"
    },
    {
      city: "HOMER",
      state: "AK"
    },
    {
      city: "HOMER",
      state: "GA"
    },
    {
      city: "HOMER",
      state: "IL"
    },
    {
      city: "HOMER",
      state: "IN"
    },
    {
      city: "HOMER",
      state: "LA"
    },
    {
      city: "HOMER",
      state: "MI"
    },
    {
      city: "HOMER",
      state: "MN"
    },
    {
      city: "HOMER",
      state: "NE"
    },
    {
      city: "HOMER",
      state: "NY"
    },
    {
      city: "HOMER",
      state: "OH"
    },
    {
      city: "HOMER CITY",
      state: "PA"
    },
    {
      city: "HOMER GLEN",
      state: "IL"
    },
    {
      city: "HOMERVILLE",
      state: "GA"
    },
    {
      city: "HOMERVILLE",
      state: "OH"
    },
    {
      city: "HOMESTEAD",
      state: "FL"
    },
    {
      city: "HOMESTEAD",
      state: "IA"
    },
    {
      city: "HOMESTEAD",
      state: "MT"
    },
    {
      city: "HOMESTEAD",
      state: "PA"
    },
    {
      city: "HOMETOWN",
      state: "IL"
    },
    {
      city: "HOMETOWN",
      state: "WV"
    },
    {
      city: "HOMEWOOD",
      state: "CA"
    },
    {
      city: "HOMEWOOD",
      state: "IL"
    },
    {
      city: "HOMEWORTH",
      state: "OH"
    },
    {
      city: "HOMINY",
      state: "OK"
    },
    {
      city: "HOMOSASSA",
      state: "FL"
    },
    {
      city: "HOMOSASSA SPRINGS",
      state: "FL"
    },
    {
      city: "HONAKER",
      state: "VA"
    },
    {
      city: "HONAUNAU",
      state: "HI"
    },
    {
      city: "HONDO",
      state: "NM"
    },
    {
      city: "HONDO",
      state: "TX"
    },
    {
      city: "HONEA PATH",
      state: "SC"
    },
    {
      city: "HONEOYE",
      state: "NY"
    },
    {
      city: "HONEOYE FALLS",
      state: "NY"
    },
    {
      city: "HONESDALE",
      state: "PA"
    },
    {
      city: "HONEY BROOK",
      state: "PA"
    },
    {
      city: "HONEY CREEK",
      state: "IA"
    },
    {
      city: "HONEY CREEK",
      state: "WI"
    },
    {
      city: "HONEY GROVE",
      state: "PA"
    },
    {
      city: "HONEY GROVE",
      state: "TX"
    },
    {
      city: "HONEYDEW",
      state: "CA"
    },
    {
      city: "HONEYVILLE",
      state: "UT"
    },
    {
      city: "HONOBIA",
      state: "OK"
    },
    {
      city: "HONOKAA",
      state: "HI"
    },
    {
      city: "HONOLULU",
      state: "HI"
    },
    {
      city: "HONOMU",
      state: "HI"
    },
    {
      city: "HONOR",
      state: "MI"
    },
    {
      city: "HONORAVILLE",
      state: "AL"
    },
    {
      city: "HOOD",
      state: "CA"
    },
    {
      city: "HOOD",
      state: "VA"
    },
    {
      city: "HOOD RIVER",
      state: "OR"
    },
    {
      city: "HOODSPORT",
      state: "WA"
    },
    {
      city: "HOOKER",
      state: "OK"
    },
    {
      city: "HOOKERTON",
      state: "NC"
    },
    {
      city: "HOOKS",
      state: "TX"
    },
    {
      city: "HOOKSETT",
      state: "NH"
    },
    {
      city: "HOOKSTOWN",
      state: "PA"
    },
    {
      city: "HOOLEHUA",
      state: "HI"
    },
    {
      city: "HOONAH",
      state: "AK"
    },
    {
      city: "HOOPA",
      state: "CA"
    },
    {
      city: "HOOPER",
      state: "CO"
    },
    {
      city: "HOOPER",
      state: "NE"
    },
    {
      city: "HOOPER",
      state: "UT"
    },
    {
      city: "HOOPER BAY",
      state: "AK"
    },
    {
      city: "HOOPESTON",
      state: "IL"
    },
    {
      city: "HOOPLE",
      state: "ND"
    },
    {
      city: "HOOPPOLE",
      state: "IL"
    },
    {
      city: "HOOSICK",
      state: "NY"
    },
    {
      city: "HOOSICK FALLS",
      state: "NY"
    },
    {
      city: "HOOVEN",
      state: "OH"
    },
    {
      city: "HOOVERSVILLE",
      state: "PA"
    },
    {
      city: "HOP BOTTOM",
      state: "PA"
    },
    {
      city: "HOPATCONG",
      state: "NJ"
    },
    {
      city: "HOPE",
      state: "AK"
    },
    {
      city: "HOPE",
      state: "AR"
    },
    {
      city: "HOPE",
      state: "ID"
    },
    {
      city: "HOPE",
      state: "IN"
    },
    {
      city: "HOPE",
      state: "KS"
    },
    {
      city: "HOPE",
      state: "KY"
    },
    {
      city: "HOPE",
      state: "ME"
    },
    {
      city: "HOPE",
      state: "MI"
    },
    {
      city: "HOPE",
      state: "MN"
    },
    {
      city: "HOPE",
      state: "ND"
    },
    {
      city: "HOPE",
      state: "NJ"
    },
    {
      city: "HOPE",
      state: "NM"
    },
    {
      city: "HOPE",
      state: "RI"
    },
    {
      city: "HOPE HULL",
      state: "AL"
    },
    {
      city: "HOPE MILLS",
      state: "NC"
    },
    {
      city: "HOPE VALLEY",
      state: "RI"
    },
    {
      city: "HOPEDALE",
      state: "IL"
    },
    {
      city: "HOPEDALE",
      state: "MA"
    },
    {
      city: "HOPEDALE",
      state: "OH"
    },
    {
      city: "HOPELAND",
      state: "PA"
    },
    {
      city: "HOPETON",
      state: "OK"
    },
    {
      city: "HOPEWELL",
      state: "NJ"
    },
    {
      city: "HOPEWELL",
      state: "OH"
    },
    {
      city: "HOPEWELL",
      state: "PA"
    },
    {
      city: "HOPEWELL",
      state: "VA"
    },
    {
      city: "HOPEWELL JUNCTION",
      state: "NY"
    },
    {
      city: "HOPKINS",
      state: "MI"
    },
    {
      city: "HOPKINS",
      state: "MN"
    },
    {
      city: "HOPKINS",
      state: "MO"
    },
    {
      city: "HOPKINS",
      state: "SC"
    },
    {
      city: "HOPKINS PARK",
      state: "IL"
    },
    {
      city: "HOPKINSVILLE",
      state: "KY"
    },
    {
      city: "HOPKINTON",
      state: "IA"
    },
    {
      city: "HOPKINTON",
      state: "MA"
    },
    {
      city: "HOPKINTON",
      state: "RI"
    },
    {
      city: "HOPLAND",
      state: "CA"
    },
    {
      city: "HOPWOOD",
      state: "PA"
    },
    {
      city: "HOQUIAM",
      state: "WA"
    },
    {
      city: "HORACE",
      state: "ND"
    },
    {
      city: "HORATIO",
      state: "AR"
    },
    {
      city: "HORATIO",
      state: "SC"
    },
    {
      city: "HORDVILLE",
      state: "NE"
    },
    {
      city: "HORICON",
      state: "WI"
    },
    {
      city: "HORN LAKE",
      state: "MS"
    },
    {
      city: "HORNBEAK",
      state: "TN"
    },
    {
      city: "HORNBECK",
      state: "LA"
    },
    {
      city: "HORNBROOK",
      state: "CA"
    },
    {
      city: "HORNELL",
      state: "NY"
    },
    {
      city: "HORNER",
      state: "WV"
    },
    {
      city: "HORNERSVILLE",
      state: "MO"
    },
    {
      city: "HORNICK",
      state: "IA"
    },
    {
      city: "HORNITOS",
      state: "CA"
    },
    {
      city: "HORNSBY",
      state: "TN"
    },
    {
      city: "HORNTOWN",
      state: "VA"
    },
    {
      city: "HORSE BRANCH",
      state: "KY"
    },
    {
      city: "HORSE CAVE",
      state: "KY"
    },
    {
      city: "HORSE CREEK",
      state: "WY"
    },
    {
      city: "HORSE SHOE",
      state: "NC"
    },
    {
      city: "HORSEHEADS",
      state: "NY"
    },
    {
      city: "HORSESHOE BAY",
      state: "TX"
    },
    {
      city: "HORSESHOE BEACH",
      state: "FL"
    },
    {
      city: "HORSESHOE BEND",
      state: "AR"
    },
    {
      city: "HORSESHOE BEND",
      state: "ID"
    },
    {
      city: "HORSHAM",
      state: "PA"
    },
    {
      city: "HORTENSE",
      state: "GA"
    },
    {
      city: "HORTON",
      state: "AL"
    },
    {
      city: "HORTON",
      state: "KS"
    },
    {
      city: "HORTON",
      state: "MI"
    },
    {
      city: "HORTONVILLE",
      state: "NY"
    },
    {
      city: "HORTONVILLE",
      state: "WI"
    },
    {
      city: "HOSCHTON",
      state: "GA"
    },
    {
      city: "HOSFORD",
      state: "FL"
    },
    {
      city: "HOSKINS",
      state: "NE"
    },
    {
      city: "HOSKINSTON",
      state: "KY"
    },
    {
      city: "HOSMER",
      state: "SD"
    },
    {
      city: "HOSPERS",
      state: "IA"
    },
    {
      city: "HOSSTON",
      state: "LA"
    },
    {
      city: "HOSTETTER",
      state: "PA"
    },
    {
      city: "HOT SPRINGS",
      state: "MT"
    },
    {
      city: "HOT SPRINGS",
      state: "NC"
    },
    {
      city: "HOT SPRINGS",
      state: "SD"
    },
    {
      city: "HOT SPRINGS",
      state: "VA"
    },
    {
      city: "HOT SPRINGS NATIONAL PARK",
      state: "AR"
    },
    {
      city: "HOT SPRINGS VILLAGE",
      state: "AR"
    },
    {
      city: "HOT SULPHUR SPRINGS",
      state: "CO"
    },
    {
      city: "HOTCHKISS",
      state: "CO"
    },
    {
      city: "HOTEVILLA",
      state: "AZ"
    },
    {
      city: "HOUCK",
      state: "AZ"
    },
    {
      city: "HOUGHTON",
      state: "IA"
    },
    {
      city: "HOUGHTON",
      state: "MI"
    },
    {
      city: "HOUGHTON",
      state: "NY"
    },
    {
      city: "HOUGHTON",
      state: "SD"
    },
    {
      city: "HOUGHTON LAKE",
      state: "MI"
    },
    {
      city: "HOUGHTON LAKE HEIGHTS",
      state: "MI"
    },
    {
      city: "HOULKA",
      state: "MS"
    },
    {
      city: "HOULTON",
      state: "ME"
    },
    {
      city: "HOULTON",
      state: "WI"
    },
    {
      city: "HOUMA",
      state: "LA"
    },
    {
      city: "HOUSATONIC",
      state: "MA"
    },
    {
      city: "HOUSE",
      state: "NM"
    },
    {
      city: "HOUSE SPRINGS",
      state: "MO"
    },
    {
      city: "HOUSTON",
      state: "AK"
    },
    {
      city: "HOUSTON",
      state: "AL"
    },
    {
      city: "HOUSTON",
      state: "AR"
    },
    {
      city: "HOUSTON",
      state: "DE"
    },
    {
      city: "HOUSTON",
      state: "MN"
    },
    {
      city: "HOUSTON",
      state: "MO"
    },
    {
      city: "HOUSTON",
      state: "MS"
    },
    {
      city: "HOUSTON",
      state: "OH"
    },
    {
      city: "HOUSTON",
      state: "PA"
    },
    {
      city: "HOUSTON",
      state: "TX"
    },
    {
      city: "HOUSTONIA",
      state: "MO"
    },
    {
      city: "HOUTZDALE",
      state: "PA"
    },
    {
      city: "HOVEN",
      state: "SD"
    },
    {
      city: "HOVLAND",
      state: "MN"
    },
    {
      city: "HOWARD",
      state: "CO"
    },
    {
      city: "HOWARD",
      state: "GA"
    },
    {
      city: "HOWARD",
      state: "KS"
    },
    {
      city: "HOWARD",
      state: "OH"
    },
    {
      city: "HOWARD",
      state: "PA"
    },
    {
      city: "HOWARD",
      state: "SD"
    },
    {
      city: "HOWARD BEACH",
      state: "NY"
    },
    {
      city: "HOWARD CITY",
      state: "MI"
    },
    {
      city: "HOWARD LAKE",
      state: "MN"
    },
    {
      city: "HOWARDSVILLE",
      state: "VA"
    },
    {
      city: "HOWE",
      state: "ID"
    },
    {
      city: "HOWE",
      state: "IN"
    },
    {
      city: "HOWE",
      state: "OK"
    },
    {
      city: "HOWE",
      state: "TX"
    },
    {
      city: "HOWELL",
      state: "MI"
    },
    {
      city: "HOWELL",
      state: "NJ"
    },
    {
      city: "HOWELL",
      state: "UT"
    },
    {
      city: "HOWELLS",
      state: "NE"
    },
    {
      city: "HOWELLS",
      state: "NY"
    },
    {
      city: "HOWES",
      state: "SD"
    },
    {
      city: "HOWES CAVE",
      state: "NY"
    },
    {
      city: "HOWEY IN THE HILLS",
      state: "FL"
    },
    {
      city: "HOWLAND",
      state: "ME"
    },
    {
      city: "HOXIE",
      state: "AR"
    },
    {
      city: "HOXIE",
      state: "KS"
    },
    {
      city: "HOYLETON",
      state: "IL"
    },
    {
      city: "HOYT",
      state: "KS"
    },
    {
      city: "HOYT LAKES",
      state: "MN"
    },
    {
      city: "HOYTVILLE",
      state: "OH"
    },
    {
      city: "HUACHUCA CITY",
      state: "AZ"
    },
    {
      city: "HUBBARD",
      state: "IA"
    },
    {
      city: "HUBBARD",
      state: "NE"
    },
    {
      city: "HUBBARD",
      state: "OH"
    },
    {
      city: "HUBBARD",
      state: "OR"
    },
    {
      city: "HUBBARD",
      state: "TX"
    },
    {
      city: "HUBBARD LAKE",
      state: "MI"
    },
    {
      city: "HUBBARDSTON",
      state: "MA"
    },
    {
      city: "HUBBARDSTON",
      state: "MI"
    },
    {
      city: "HUBBARDSVILLE",
      state: "NY"
    },
    {
      city: "HUBBELL",
      state: "MI"
    },
    {
      city: "HUBBELL",
      state: "NE"
    },
    {
      city: "HUBERT",
      state: "NC"
    },
    {
      city: "HUBERTUS",
      state: "WI"
    },
    {
      city: "HUDDLESTON",
      state: "VA"
    },
    {
      city: "HUDDY",
      state: "KY"
    },
    {
      city: "HUDGINS",
      state: "VA"
    },
    {
      city: "HUDSON",
      state: "CO"
    },
    {
      city: "HUDSON",
      state: "FL"
    },
    {
      city: "HUDSON",
      state: "IA"
    },
    {
      city: "HUDSON",
      state: "IL"
    },
    {
      city: "HUDSON",
      state: "IN"
    },
    {
      city: "HUDSON",
      state: "KS"
    },
    {
      city: "HUDSON",
      state: "KY"
    },
    {
      city: "HUDSON",
      state: "MA"
    },
    {
      city: "HUDSON",
      state: "ME"
    },
    {
      city: "HUDSON",
      state: "MI"
    },
    {
      city: "HUDSON",
      state: "NC"
    },
    {
      city: "HUDSON",
      state: "NH"
    },
    {
      city: "HUDSON",
      state: "NY"
    },
    {
      city: "HUDSON",
      state: "OH"
    },
    {
      city: "HUDSON",
      state: "SD"
    },
    {
      city: "HUDSON",
      state: "WI"
    },
    {
      city: "HUDSON",
      state: "WY"
    },
    {
      city: "HUDSON FALLS",
      state: "NY"
    },
    {
      city: "HUDSONVILLE",
      state: "MI"
    },
    {
      city: "HUEY",
      state: "IL"
    },
    {
      city: "HUEYSVILLE",
      state: "KY"
    },
    {
      city: "HUFFMAN",
      state: "TX"
    },
    {
      city: "HUFSMITH",
      state: "TX"
    },
    {
      city: "HUGER",
      state: "SC"
    },
    {
      city: "HUGGINS",
      state: "MO"
    },
    {
      city: "HUGHES",
      state: "AK"
    },
    {
      city: "HUGHES",
      state: "AR"
    },
    {
      city: "HUGHES SPRINGS",
      state: "TX"
    },
    {
      city: "HUGHESTON",
      state: "WV"
    },
    {
      city: "HUGHESVILLE",
      state: "MD"
    },
    {
      city: "HUGHESVILLE",
      state: "MO"
    },
    {
      city: "HUGHESVILLE",
      state: "PA"
    },
    {
      city: "HUGHSON",
      state: "CA"
    },
    {
      city: "HUGHSONVILLE",
      state: "NY"
    },
    {
      city: "HUGO",
      state: "CO"
    },
    {
      city: "HUGO",
      state: "MN"
    },
    {
      city: "HUGO",
      state: "OK"
    },
    {
      city: "HUGOTON",
      state: "KS"
    },
    {
      city: "HUGUENOT",
      state: "NY"
    },
    {
      city: "HULBERT",
      state: "MI"
    },
    {
      city: "HULBERT",
      state: "OK"
    },
    {
      city: "HULEN",
      state: "KY"
    },
    {
      city: "HULETT",
      state: "WY"
    },
    {
      city: "HULETTS LANDING",
      state: "NY"
    },
    {
      city: "HULL",
      state: "GA"
    },
    {
      city: "HULL",
      state: "IA"
    },
    {
      city: "HULL",
      state: "IL"
    },
    {
      city: "HULL",
      state: "MA"
    },
    {
      city: "HULL",
      state: "TX"
    },
    {
      city: "HULLS COVE",
      state: "ME"
    },
    {
      city: "HUMANSVILLE",
      state: "MO"
    },
    {
      city: "HUMAROCK",
      state: "MA"
    },
    {
      city: "HUMBIRD",
      state: "WI"
    },
    {
      city: "HUMBLE",
      state: "TX"
    },
    {
      city: "HUMBOLDT",
      state: "AZ"
    },
    {
      city: "HUMBOLDT",
      state: "IA"
    },
    {
      city: "HUMBOLDT",
      state: "IL"
    },
    {
      city: "HUMBOLDT",
      state: "KS"
    },
    {
      city: "HUMBOLDT",
      state: "MN"
    },
    {
      city: "HUMBOLDT",
      state: "NE"
    },
    {
      city: "HUMBOLDT",
      state: "SD"
    },
    {
      city: "HUMBOLDT",
      state: "TN"
    },
    {
      city: "HUME",
      state: "CA"
    },
    {
      city: "HUME",
      state: "IL"
    },
    {
      city: "HUME",
      state: "MO"
    },
    {
      city: "HUME",
      state: "NY"
    },
    {
      city: "HUME",
      state: "VA"
    },
    {
      city: "HUMESTON",
      state: "IA"
    },
    {
      city: "HUMMELS WHARF",
      state: "PA"
    },
    {
      city: "HUMMELSTOWN",
      state: "PA"
    },
    {
      city: "HUMNOKE",
      state: "AR"
    },
    {
      city: "HUMPHREY",
      state: "AR"
    },
    {
      city: "HUMPHREY",
      state: "NE"
    },
    {
      city: "HUMPHREYS",
      state: "MO"
    },
    {
      city: "HUMPTULIPS",
      state: "WA"
    },
    {
      city: "HUNDRED",
      state: "WV"
    },
    {
      city: "HUNGERFORD",
      state: "TX"
    },
    {
      city: "HUNGRY HORSE",
      state: "MT"
    },
    {
      city: "HUNKER",
      state: "PA"
    },
    {
      city: "HUNLOCK CREEK",
      state: "PA"
    },
    {
      city: "HUNNEWELL",
      state: "MO"
    },
    {
      city: "HUNT",
      state: "NY"
    },
    {
      city: "HUNT",
      state: "TX"
    },
    {
      city: "HUNT VALLEY",
      state: "MD"
    },
    {
      city: "HUNTER",
      state: "AR"
    },
    {
      city: "HUNTER",
      state: "KS"
    },
    {
      city: "HUNTER",
      state: "ND"
    },
    {
      city: "HUNTER",
      state: "NY"
    },
    {
      city: "HUNTER",
      state: "OK"
    },
    {
      city: "HUNTERS",
      state: "WA"
    },
    {
      city: "HUNTERSVILLE",
      state: "NC"
    },
    {
      city: "HUNTERTOWN",
      state: "IN"
    },
    {
      city: "HUNTINGBURG",
      state: "IN"
    },
    {
      city: "HUNTINGDON",
      state: "PA"
    },
    {
      city: "HUNTINGDON",
      state: "TN"
    },
    {
      city: "HUNTINGDON VALLEY",
      state: "PA"
    },
    {
      city: "HUNTINGTON",
      state: "AR"
    },
    {
      city: "HUNTINGTON",
      state: "IN"
    },
    {
      city: "HUNTINGTON",
      state: "MA"
    },
    {
      city: "HUNTINGTON",
      state: "NY"
    },
    {
      city: "HUNTINGTON",
      state: "OR"
    },
    {
      city: "HUNTINGTON",
      state: "TX"
    },
    {
      city: "HUNTINGTON",
      state: "UT"
    },
    {
      city: "HUNTINGTON",
      state: "VT"
    },
    {
      city: "HUNTINGTON",
      state: "WV"
    },
    {
      city: "HUNTINGTON BEACH",
      state: "CA"
    },
    {
      city: "HUNTINGTON MILLS",
      state: "PA"
    },
    {
      city: "HUNTINGTON PARK",
      state: "CA"
    },
    {
      city: "HUNTINGTON STATION",
      state: "NY"
    },
    {
      city: "HUNTINGTON WOODS",
      state: "MI"
    },
    {
      city: "HUNTINGTOWN",
      state: "MD"
    },
    {
      city: "HUNTLAND",
      state: "TN"
    },
    {
      city: "HUNTLEY",
      state: "IL"
    },
    {
      city: "HUNTLEY",
      state: "MN"
    },
    {
      city: "HUNTLEY",
      state: "MT"
    },
    {
      city: "HUNTLEY",
      state: "WY"
    },
    {
      city: "HUNTLY",
      state: "VA"
    },
    {
      city: "HUNTSBURG",
      state: "OH"
    },
    {
      city: "HUNTSVILLE",
      state: "AL"
    },
    {
      city: "HUNTSVILLE",
      state: "AR"
    },
    {
      city: "HUNTSVILLE",
      state: "IL"
    },
    {
      city: "HUNTSVILLE",
      state: "MO"
    },
    {
      city: "HUNTSVILLE",
      state: "OH"
    },
    {
      city: "HUNTSVILLE",
      state: "TN"
    },
    {
      city: "HUNTSVILLE",
      state: "TX"
    },
    {
      city: "HUNTSVILLE",
      state: "UT"
    },
    {
      city: "HURDLAND",
      state: "MO"
    },
    {
      city: "HURDLE MILLS",
      state: "NC"
    },
    {
      city: "HURDSFIELD",
      state: "ND"
    },
    {
      city: "HURLBURT FIELD",
      state: "FL"
    },
    {
      city: "HURLEY",
      state: "MO"
    },
    {
      city: "HURLEY",
      state: "MS"
    },
    {
      city: "HURLEY",
      state: "NM"
    },
    {
      city: "HURLEY",
      state: "NY"
    },
    {
      city: "HURLEY",
      state: "SD"
    },
    {
      city: "HURLEY",
      state: "VA"
    },
    {
      city: "HURLEY",
      state: "WI"
    },
    {
      city: "HURLEYVILLE",
      state: "NY"
    },
    {
      city: "HURLOCK",
      state: "MD"
    },
    {
      city: "HURON",
      state: "CA"
    },
    {
      city: "HURON",
      state: "IN"
    },
    {
      city: "HURON",
      state: "OH"
    },
    {
      city: "HURON",
      state: "SD"
    },
    {
      city: "HURON",
      state: "TN"
    },
    {
      city: "HURRICANE",
      state: "UT"
    },
    {
      city: "HURRICANE",
      state: "WV"
    },
    {
      city: "HURRICANE MILLS",
      state: "TN"
    },
    {
      city: "HURST",
      state: "IL"
    },
    {
      city: "HURST",
      state: "TX"
    },
    {
      city: "HURT",
      state: "VA"
    },
    {
      city: "HURTSBORO",
      state: "AL"
    },
    {
      city: "HUSLIA",
      state: "AK"
    },
    {
      city: "HUSON",
      state: "MT"
    },
    {
      city: "HUSSER",
      state: "LA"
    },
    {
      city: "HUSTISFORD",
      state: "WI"
    },
    {
      city: "HUSTLE",
      state: "VA"
    },
    {
      city: "HUSTLER",
      state: "WI"
    },
    {
      city: "HUSTON",
      state: "ID"
    },
    {
      city: "HUSTONTOWN",
      state: "PA"
    },
    {
      city: "HUSTONVILLE",
      state: "KY"
    },
    {
      city: "HUSUM",
      state: "WA"
    },
    {
      city: "HUTCHINS",
      state: "TX"
    },
    {
      city: "HUTCHINSON",
      state: "KS"
    },
    {
      city: "HUTCHINSON",
      state: "MN"
    },
    {
      city: "HUTSONVILLE",
      state: "IL"
    },
    {
      city: "HUTTIG",
      state: "AR"
    },
    {
      city: "HUTTO",
      state: "TX"
    },
    {
      city: "HUTTONSVILLE",
      state: "WV"
    },
    {
      city: "HUXFORD",
      state: "AL"
    },
    {
      city: "HUXLEY",
      state: "IA"
    },
    {
      city: "HYAMPOM",
      state: "CA"
    },
    {
      city: "HYANNIS",
      state: "MA"
    },
    {
      city: "HYANNIS",
      state: "NE"
    },
    {
      city: "HYANNIS PORT",
      state: "MA"
    },
    {
      city: "HYATTSVILLE",
      state: "MD"
    },
    {
      city: "HYATTVILLE",
      state: "WY"
    },
    {
      city: "HYDABURG",
      state: "AK"
    },
    {
      city: "HYDE",
      state: "PA"
    },
    {
      city: "HYDE PARK",
      state: "MA"
    },
    {
      city: "HYDE PARK",
      state: "NY"
    },
    {
      city: "HYDE PARK",
      state: "PA"
    },
    {
      city: "HYDE PARK",
      state: "UT"
    },
    {
      city: "HYDE PARK",
      state: "VT"
    },
    {
      city: "HYDEN",
      state: "KY"
    },
    {
      city: "HYDER",
      state: "AK"
    },
    {
      city: "HYDES",
      state: "MD"
    },
    {
      city: "HYDESVILLE",
      state: "CA"
    },
    {
      city: "HYDETOWN",
      state: "PA"
    },
    {
      city: "HYDEVILLE",
      state: "VT"
    },
    {
      city: "HYDRO",
      state: "OK"
    },
    {
      city: "HYE",
      state: "TX"
    },
    {
      city: "HYGIENE",
      state: "CO"
    },
    {
      city: "HYMERA",
      state: "IN"
    },
    {
      city: "HYNDMAN",
      state: "PA"
    },
    {
      city: "HYRUM",
      state: "UT"
    },
    {
      city: "HYSHAM",
      state: "MT"
    },
    {
      city: "IAEGER",
      state: "WV"
    },
    {
      city: "IBAPAH",
      state: "UT"
    },
    {
      city: "IBERIA",
      state: "MO"
    },
    {
      city: "IBERIA",
      state: "OH"
    },
    {
      city: "ICARD",
      state: "NC"
    },
    {
      city: "ICKESBURG",
      state: "PA"
    },
    {
      city: "IDA",
      state: "AR"
    },
    {
      city: "IDA",
      state: "LA"
    },
    {
      city: "IDA",
      state: "MI"
    },
    {
      city: "IDA GROVE",
      state: "IA"
    },
    {
      city: "IDABEL",
      state: "OK"
    },
    {
      city: "IDAHO CITY",
      state: "ID"
    },
    {
      city: "IDAHO FALLS",
      state: "ID"
    },
    {
      city: "IDAHO SPRINGS",
      state: "CO"
    },
    {
      city: "IDALIA",
      state: "CO"
    },
    {
      city: "IDALOU",
      state: "TX"
    },
    {
      city: "IDAMAY",
      state: "WV"
    },
    {
      city: "IDANHA",
      state: "OR"
    },
    {
      city: "IDAVILLE",
      state: "IN"
    },
    {
      city: "IDEAL",
      state: "GA"
    },
    {
      city: "IDER",
      state: "AL"
    },
    {
      city: "IDLEDALE",
      state: "CO"
    },
    {
      city: "IDLEWILD",
      state: "MI"
    },
    {
      city: "IDLEWILD",
      state: "TN"
    },
    {
      city: "IDLEYLD PARK",
      state: "OR"
    },
    {
      city: "IDYLLWILD",
      state: "CA"
    },
    {
      city: "IGNACIO",
      state: "CO"
    },
    {
      city: "IGO",
      state: "CA"
    },
    {
      city: "IHLEN",
      state: "MN"
    },
    {
      city: "IJAMSVILLE",
      state: "MD"
    },
    {
      city: "IKES FORK",
      state: "WV"
    },
    {
      city: "ILA",
      state: "GA"
    },
    {
      city: "ILFELD",
      state: "NM"
    },
    {
      city: "ILIAMNA",
      state: "AK"
    },
    {
      city: "ILIFF",
      state: "CO"
    },
    {
      city: "ILION",
      state: "NY"
    },
    {
      city: "ILLINOIS CITY",
      state: "IL"
    },
    {
      city: "ILLIOPOLIS",
      state: "IL"
    },
    {
      city: "ILWACO",
      state: "WA"
    },
    {
      city: "IMBLER",
      state: "OR"
    },
    {
      city: "IMBODEN",
      state: "AR"
    },
    {
      city: "IMLAY",
      state: "NV"
    },
    {
      city: "IMLAY CITY",
      state: "MI"
    },
    {
      city: "IMLAYSTOWN",
      state: "NJ"
    },
    {
      city: "IMLER",
      state: "PA"
    },
    {
      city: "IMMACULATA",
      state: "PA"
    },
    {
      city: "IMMOKALEE",
      state: "FL"
    },
    {
      city: "IMNAHA",
      state: "OR"
    },
    {
      city: "IMOGENE",
      state: "IA"
    },
    {
      city: "IMPERIAL",
      state: "CA"
    },
    {
      city: "IMPERIAL",
      state: "MO"
    },
    {
      city: "IMPERIAL",
      state: "NE"
    },
    {
      city: "IMPERIAL",
      state: "PA"
    },
    {
      city: "IMPERIAL",
      state: "TX"
    },
    {
      city: "IMPERIAL BEACH",
      state: "CA"
    },
    {
      city: "INA",
      state: "IL"
    },
    {
      city: "INAVALE",
      state: "NE"
    },
    {
      city: "INCHELIUM",
      state: "WA"
    },
    {
      city: "INCLINE VILLAGE",
      state: "NV"
    },
    {
      city: "INDEPENDENCE",
      state: "CA"
    },
    {
      city: "INDEPENDENCE",
      state: "IA"
    },
    {
      city: "INDEPENDENCE",
      state: "KS"
    },
    {
      city: "INDEPENDENCE",
      state: "KY"
    },
    {
      city: "INDEPENDENCE",
      state: "LA"
    },
    {
      city: "INDEPENDENCE",
      state: "MO"
    },
    {
      city: "INDEPENDENCE",
      state: "MS"
    },
    {
      city: "INDEPENDENCE",
      state: "OH"
    },
    {
      city: "INDEPENDENCE",
      state: "OR"
    },
    {
      city: "INDEPENDENCE",
      state: "VA"
    },
    {
      city: "INDEPENDENCE",
      state: "WI"
    },
    {
      city: "INDEPENDENCE",
      state: "WV"
    },
    {
      city: "INDEX",
      state: "WA"
    },
    {
      city: "INDIAHOMA",
      state: "OK"
    },
    {
      city: "INDIALANTIC",
      state: "FL"
    },
    {
      city: "INDIAN",
      state: "AK"
    },
    {
      city: "INDIAN HEAD",
      state: "MD"
    },
    {
      city: "INDIAN HEAD",
      state: "PA"
    },
    {
      city: "INDIAN HILLS",
      state: "CO"
    },
    {
      city: "INDIAN LAKE",
      state: "NY"
    },
    {
      city: "INDIAN LAKE ESTATES",
      state: "FL"
    },
    {
      city: "INDIAN MOUND",
      state: "TN"
    },
    {
      city: "INDIAN ORCHARD",
      state: "MA"
    },
    {
      city: "INDIAN RIVER",
      state: "MI"
    },
    {
      city: "INDIAN ROCKS BEACH",
      state: "FL"
    },
    {
      city: "INDIAN SPRINGS",
      state: "NV"
    },
    {
      city: "INDIAN TRAIL",
      state: "NC"
    },
    {
      city: "INDIAN VALLEY",
      state: "ID"
    },
    {
      city: "INDIAN VALLEY",
      state: "VA"
    },
    {
      city: "INDIAN WELLS",
      state: "AZ"
    },
    {
      city: "INDIAN WELLS",
      state: "CA"
    },
    {
      city: "INDIANA",
      state: "PA"
    },
    {
      city: "INDIANAPOLIS",
      state: "IN"
    },
    {
      city: "INDIANOLA",
      state: "IA"
    },
    {
      city: "INDIANOLA",
      state: "IL"
    },
    {
      city: "INDIANOLA",
      state: "MS"
    },
    {
      city: "INDIANOLA",
      state: "NE"
    },
    {
      city: "INDIANOLA",
      state: "OK"
    },
    {
      city: "INDIANOLA",
      state: "PA"
    },
    {
      city: "INDIANOLA",
      state: "WA"
    },
    {
      city: "INDIANTOWN",
      state: "FL"
    },
    {
      city: "INDIO",
      state: "CA"
    },
    {
      city: "INDORE",
      state: "WV"
    },
    {
      city: "INDUSTRY",
      state: "IL"
    },
    {
      city: "INDUSTRY",
      state: "PA"
    },
    {
      city: "INDUSTRY",
      state: "TX"
    },
    {
      city: "INEZ",
      state: "KY"
    },
    {
      city: "INEZ",
      state: "TX"
    },
    {
      city: "INGALLS",
      state: "IN"
    },
    {
      city: "INGALLS",
      state: "KS"
    },
    {
      city: "INGALLS",
      state: "MI"
    },
    {
      city: "INGLEFIELD",
      state: "IN"
    },
    {
      city: "INGLESIDE",
      state: "IL"
    },
    {
      city: "INGLESIDE",
      state: "MD"
    },
    {
      city: "INGLESIDE",
      state: "TX"
    },
    {
      city: "INGLEWOOD",
      state: "CA"
    },
    {
      city: "INGLIS",
      state: "FL"
    },
    {
      city: "INGOMAR",
      state: "MT"
    },
    {
      city: "INGOMAR",
      state: "PA"
    },
    {
      city: "INGRAHAM",
      state: "IL"
    },
    {
      city: "INGRAM",
      state: "KY"
    },
    {
      city: "INGRAM",
      state: "TX"
    },
    {
      city: "INKOM",
      state: "ID"
    },
    {
      city: "INKSTER",
      state: "MI"
    },
    {
      city: "INKSTER",
      state: "ND"
    },
    {
      city: "INLAND",
      state: "NE"
    },
    {
      city: "INLET",
      state: "NY"
    },
    {
      city: "INMAN",
      state: "KS"
    },
    {
      city: "INMAN",
      state: "NE"
    },
    {
      city: "INMAN",
      state: "SC"
    },
    {
      city: "INNIS",
      state: "LA"
    },
    {
      city: "INOLA",
      state: "OK"
    },
    {
      city: "INSTITUTE",
      state: "WV"
    },
    {
      city: "INTERCESSION CITY",
      state: "FL"
    },
    {
      city: "INTERCOURSE",
      state: "PA"
    },
    {
      city: "INTERIOR",
      state: "SD"
    },
    {
      city: "INTERLACHEN",
      state: "FL"
    },
    {
      city: "INTERLAKEN",
      state: "NY"
    },
    {
      city: "INTERLOCHEN",
      state: "MI"
    },
    {
      city: "INTERNATIONAL FALLS",
      state: "MN"
    },
    {
      city: "INTERVALE",
      state: "NH"
    },
    {
      city: "INVER GROVE HEIGHTS",
      state: "MN"
    },
    {
      city: "INVERNESS",
      state: "CA"
    },
    {
      city: "INVERNESS",
      state: "FL"
    },
    {
      city: "INVERNESS",
      state: "MS"
    },
    {
      city: "INVERNESS",
      state: "MT"
    },
    {
      city: "INWOOD",
      state: "IA"
    },
    {
      city: "INWOOD",
      state: "NY"
    },
    {
      city: "INWOOD",
      state: "WV"
    },
    {
      city: "INYOKERN",
      state: "CA"
    },
    {
      city: "IOLA",
      state: "KS"
    },
    {
      city: "IOLA",
      state: "TX"
    },
    {
      city: "IOLA",
      state: "WI"
    },
    {
      city: "IONA",
      state: "ID"
    },
    {
      city: "IONA",
      state: "MN"
    },
    {
      city: "IONE",
      state: "CA"
    },
    {
      city: "IONE",
      state: "OR"
    },
    {
      city: "IONE",
      state: "WA"
    },
    {
      city: "IONIA",
      state: "IA"
    },
    {
      city: "IONIA",
      state: "MI"
    },
    {
      city: "IONIA",
      state: "MO"
    },
    {
      city: "IONIA",
      state: "NY"
    },
    {
      city: "IOTA",
      state: "LA"
    },
    {
      city: "IOWA",
      state: "LA"
    },
    {
      city: "IOWA CITY",
      state: "IA"
    },
    {
      city: "IOWA FALLS",
      state: "IA"
    },
    {
      city: "IOWA PARK",
      state: "TX"
    },
    {
      city: "IPAVA",
      state: "IL"
    },
    {
      city: "IPSWICH",
      state: "MA"
    },
    {
      city: "IPSWICH",
      state: "SD"
    },
    {
      city: "IRA",
      state: "IA"
    },
    {
      city: "IRA",
      state: "TX"
    },
    {
      city: "IRAAN",
      state: "TX"
    },
    {
      city: "IRASBURG",
      state: "VT"
    },
    {
      city: "IREDELL",
      state: "TX"
    },
    {
      city: "IRELAND",
      state: "IN"
    },
    {
      city: "IRELAND",
      state: "WV"
    },
    {
      city: "IRENE",
      state: "SD"
    },
    {
      city: "IRENE",
      state: "TX"
    },
    {
      city: "IRETON",
      state: "IA"
    },
    {
      city: "IRMA",
      state: "WI"
    },
    {
      city: "IRMO",
      state: "SC"
    },
    {
      city: "IRON",
      state: "MN"
    },
    {
      city: "IRON BELT",
      state: "WI"
    },
    {
      city: "IRON CITY",
      state: "GA"
    },
    {
      city: "IRON CITY",
      state: "TN"
    },
    {
      city: "IRON GATE",
      state: "VA"
    },
    {
      city: "IRON MOUNTAIN",
      state: "MI"
    },
    {
      city: "IRON RIDGE",
      state: "WI"
    },
    {
      city: "IRON RIVER",
      state: "MI"
    },
    {
      city: "IRON RIVER",
      state: "WI"
    },
    {
      city: "IRON STATION",
      state: "NC"
    },
    {
      city: "IRONDALE",
      state: "MO"
    },
    {
      city: "IRONDALE",
      state: "OH"
    },
    {
      city: "IRONIA",
      state: "NJ"
    },
    {
      city: "IRONS",
      state: "MI"
    },
    {
      city: "IRONTON",
      state: "MN"
    },
    {
      city: "IRONTON",
      state: "MO"
    },
    {
      city: "IRONTON",
      state: "OH"
    },
    {
      city: "IRONWOOD",
      state: "MI"
    },
    {
      city: "IROQUOIS",
      state: "IL"
    },
    {
      city: "IROQUOIS",
      state: "SD"
    },
    {
      city: "IRRIGON",
      state: "OR"
    },
    {
      city: "IRVINE",
      state: "CA"
    },
    {
      city: "IRVINE",
      state: "KY"
    },
    {
      city: "IRVINE",
      state: "PA"
    },
    {
      city: "IRVING",
      state: "IL"
    },
    {
      city: "IRVING",
      state: "NY"
    },
    {
      city: "IRVING",
      state: "TX"
    },
    {
      city: "IRVINGTON",
      state: "AL"
    },
    {
      city: "IRVINGTON",
      state: "IL"
    },
    {
      city: "IRVINGTON",
      state: "KY"
    },
    {
      city: "IRVINGTON",
      state: "NJ"
    },
    {
      city: "IRVINGTON",
      state: "NY"
    },
    {
      city: "IRVINGTON",
      state: "VA"
    },
    {
      city: "IRVONA",
      state: "PA"
    },
    {
      city: "IRWIN",
      state: "IA"
    },
    {
      city: "IRWIN",
      state: "ID"
    },
    {
      city: "IRWIN",
      state: "OH"
    },
    {
      city: "IRWIN",
      state: "PA"
    },
    {
      city: "IRWINTON",
      state: "GA"
    },
    {
      city: "IRWINVILLE",
      state: "GA"
    },
    {
      city: "ISABEL",
      state: "KS"
    },
    {
      city: "ISABEL",
      state: "SD"
    },
    {
      city: "ISABELLA",
      state: "MN"
    },
    {
      city: "ISABELLA",
      state: "MO"
    },
    {
      city: "ISABELLA",
      state: "OK"
    },
    {
      city: "ISANTI",
      state: "MN"
    },
    {
      city: "ISELIN",
      state: "NJ"
    },
    {
      city: "ISHPEMING",
      state: "MI"
    },
    {
      city: "ISLAMORADA",
      state: "FL"
    },
    {
      city: "ISLAND",
      state: "KY"
    },
    {
      city: "ISLAND FALLS",
      state: "ME"
    },
    {
      city: "ISLAND GROVE",
      state: "FL"
    },
    {
      city: "ISLAND HEIGHTS",
      state: "NJ"
    },
    {
      city: "ISLAND LAKE",
      state: "IL"
    },
    {
      city: "ISLAND PARK",
      state: "ID"
    },
    {
      city: "ISLAND PARK",
      state: "NY"
    },
    {
      city: "ISLAND POND",
      state: "VT"
    },
    {
      city: "ISLANDIA",
      state: "NY"
    },
    {
      city: "ISLANDTON",
      state: "SC"
    },
    {
      city: "ISLE",
      state: "MN"
    },
    {
      city: "ISLE AU HAUT",
      state: "ME"
    },
    {
      city: "ISLE LA MOTTE",
      state: "VT"
    },
    {
      city: "ISLE OF PALMS",
      state: "SC"
    },
    {
      city: "ISLE OF SPRINGS",
      state: "ME"
    },
    {
      city: "ISLE OF WIGHT",
      state: "VA"
    },
    {
      city: "ISLESBORO",
      state: "ME"
    },
    {
      city: "ISLESFORD",
      state: "ME"
    },
    {
      city: "ISLETA",
      state: "NM"
    },
    {
      city: "ISLETON",
      state: "CA"
    },
    {
      city: "ISLIP",
      state: "NY"
    },
    {
      city: "ISLIP TERRACE",
      state: "NY"
    },
    {
      city: "ISMAY",
      state: "MT"
    },
    {
      city: "ISOLA",
      state: "MS"
    },
    {
      city: "ISOM",
      state: "KY"
    },
    {
      city: "ISONVILLE",
      state: "KY"
    },
    {
      city: "ISSAQUAH",
      state: "WA"
    },
    {
      city: "ISSUE",
      state: "MD"
    },
    {
      city: "ISTACHATTA",
      state: "FL"
    },
    {
      city: "ITALY",
      state: "TX"
    },
    {
      city: "ITASCA",
      state: "IL"
    },
    {
      city: "ITASCA",
      state: "TX"
    },
    {
      city: "ITHACA",
      state: "MI"
    },
    {
      city: "ITHACA",
      state: "NE"
    },
    {
      city: "ITHACA",
      state: "NY"
    },
    {
      city: "ITMANN",
      state: "WV"
    },
    {
      city: "ITTA BENA",
      state: "MS"
    },
    {
      city: "IUKA",
      state: "IL"
    },
    {
      city: "IUKA",
      state: "KS"
    },
    {
      city: "IUKA",
      state: "MS"
    },
    {
      city: "IVA",
      state: "SC"
    },
    {
      city: "IVAN",
      state: "AR"
    },
    {
      city: "IVANHOE",
      state: "CA"
    },
    {
      city: "IVANHOE",
      state: "MN"
    },
    {
      city: "IVANHOE",
      state: "NC"
    },
    {
      city: "IVANHOE",
      state: "TX"
    },
    {
      city: "IVANHOE",
      state: "VA"
    },
    {
      city: "IVEL",
      state: "KY"
    },
    {
      city: "IVESDALE",
      state: "IL"
    },
    {
      city: "IVINS",
      state: "UT"
    },
    {
      city: "IVOR",
      state: "VA"
    },
    {
      city: "IVORYTON",
      state: "CT"
    },
    {
      city: "IVY",
      state: "VA"
    },
    {
      city: "IVYDALE",
      state: "WV"
    },
    {
      city: "IXONIA",
      state: "WI"
    },
    {
      city: "JACHIN",
      state: "AL"
    },
    {
      city: "JACK",
      state: "AL"
    },
    {
      city: "JACKMAN",
      state: "ME"
    },
    {
      city: "JACKPOT",
      state: "NV"
    },
    {
      city: "JACKS CREEK",
      state: "TN"
    },
    {
      city: "JACKSBORO",
      state: "TN"
    },
    {
      city: "JACKSBORO",
      state: "TX"
    },
    {
      city: "JACKSON",
      state: "AL"
    },
    {
      city: "JACKSON",
      state: "CA"
    },
    {
      city: "JACKSON",
      state: "GA"
    },
    {
      city: "JACKSON",
      state: "KY"
    },
    {
      city: "JACKSON",
      state: "LA"
    },
    {
      city: "JACKSON",
      state: "MI"
    },
    {
      city: "JACKSON",
      state: "MN"
    },
    {
      city: "JACKSON",
      state: "MO"
    },
    {
      city: "JACKSON",
      state: "MS"
    },
    {
      city: "JACKSON",
      state: "MT"
    },
    {
      city: "JACKSON",
      state: "NC"
    },
    {
      city: "JACKSON",
      state: "NE"
    },
    {
      city: "JACKSON",
      state: "NH"
    },
    {
      city: "JACKSON",
      state: "NJ"
    },
    {
      city: "JACKSON",
      state: "OH"
    },
    {
      city: "JACKSON",
      state: "PA"
    },
    {
      city: "JACKSON",
      state: "SC"
    },
    {
      city: "JACKSON",
      state: "TN"
    },
    {
      city: "JACKSON",
      state: "WI"
    },
    {
      city: "JACKSON",
      state: "WY"
    },
    {
      city: "JACKSON CENTER",
      state: "OH"
    },
    {
      city: "JACKSON CENTER",
      state: "PA"
    },
    {
      city: "JACKSON HEIGHTS",
      state: "NY"
    },
    {
      city: "JACKSON SPRINGS",
      state: "NC"
    },
    {
      city: "JACKSONBORO",
      state: "SC"
    },
    {
      city: "JACKSONBURG",
      state: "WV"
    },
    {
      city: "JACKSONS GAP",
      state: "AL"
    },
    {
      city: "JACKSONTOWN",
      state: "OH"
    },
    {
      city: "JACKSONVILLE",
      state: "AL"
    },
    {
      city: "JACKSONVILLE",
      state: "AR"
    },
    {
      city: "JACKSONVILLE",
      state: "FL"
    },
    {
      city: "JACKSONVILLE",
      state: "GA"
    },
    {
      city: "JACKSONVILLE",
      state: "IL"
    },
    {
      city: "JACKSONVILLE",
      state: "MO"
    },
    {
      city: "JACKSONVILLE",
      state: "NC"
    },
    {
      city: "JACKSONVILLE",
      state: "NY"
    },
    {
      city: "JACKSONVILLE",
      state: "OH"
    },
    {
      city: "JACKSONVILLE",
      state: "OR"
    },
    {
      city: "JACKSONVILLE",
      state: "TX"
    },
    {
      city: "JACKSONVILLE",
      state: "VT"
    },
    {
      city: "JACKSONVILLE BEACH",
      state: "FL"
    },
    {
      city: "JACOB",
      state: "IL"
    },
    {
      city: "JACOBS CREEK",
      state: "PA"
    },
    {
      city: "JACOBSBURG",
      state: "OH"
    },
    {
      city: "JACOBSON",
      state: "MN"
    },
    {
      city: "JACUMBA",
      state: "CA"
    },
    {
      city: "JADWIN",
      state: "MO"
    },
    {
      city: "JAFFREY",
      state: "NH"
    },
    {
      city: "JAKIN",
      state: "GA"
    },
    {
      city: "JAL",
      state: "NM"
    },
    {
      city: "JAMAICA",
      state: "IA"
    },
    {
      city: "JAMAICA",
      state: "NY"
    },
    {
      city: "JAMAICA",
      state: "VA"
    },
    {
      city: "JAMAICA",
      state: "VT"
    },
    {
      city: "JAMAICA PLAIN",
      state: "MA"
    },
    {
      city: "JAMES CITY",
      state: "PA"
    },
    {
      city: "JAMES CREEK",
      state: "PA"
    },
    {
      city: "JAMESON",
      state: "MO"
    },
    {
      city: "JAMESPORT",
      state: "MO"
    },
    {
      city: "JAMESPORT",
      state: "NY"
    },
    {
      city: "JAMESTOWN",
      state: "CA"
    },
    {
      city: "JAMESTOWN",
      state: "CO"
    },
    {
      city: "JAMESTOWN",
      state: "IN"
    },
    {
      city: "JAMESTOWN",
      state: "KS"
    },
    {
      city: "JAMESTOWN",
      state: "KY"
    },
    {
      city: "JAMESTOWN",
      state: "LA"
    },
    {
      city: "JAMESTOWN",
      state: "MI"
    },
    {
      city: "JAMESTOWN",
      state: "MO"
    },
    {
      city: "JAMESTOWN",
      state: "NC"
    },
    {
      city: "JAMESTOWN",
      state: "ND"
    },
    {
      city: "JAMESTOWN",
      state: "NM"
    },
    {
      city: "JAMESTOWN",
      state: "NY"
    },
    {
      city: "JAMESTOWN",
      state: "OH"
    },
    {
      city: "JAMESTOWN",
      state: "PA"
    },
    {
      city: "JAMESTOWN",
      state: "RI"
    },
    {
      city: "JAMESTOWN",
      state: "SC"
    },
    {
      city: "JAMESTOWN",
      state: "TN"
    },
    {
      city: "JAMESTOWN",
      state: "VA"
    },
    {
      city: "JAMESVILLE",
      state: "NC"
    },
    {
      city: "JAMESVILLE",
      state: "NY"
    },
    {
      city: "JAMESVILLE",
      state: "VA"
    },
    {
      city: "JAMIESON",
      state: "OR"
    },
    {
      city: "JAMISON",
      state: "PA"
    },
    {
      city: "JAMUL",
      state: "CA"
    },
    {
      city: "JANE LEW",
      state: "WV"
    },
    {
      city: "JANESVILLE",
      state: "CA"
    },
    {
      city: "JANESVILLE",
      state: "IA"
    },
    {
      city: "JANESVILLE",
      state: "MN"
    },
    {
      city: "JANESVILLE",
      state: "WI"
    },
    {
      city: "JANSEN",
      state: "NE"
    },
    {
      city: "JARALES",
      state: "NM"
    },
    {
      city: "JARBIDGE",
      state: "NV"
    },
    {
      city: "JARRATT",
      state: "VA"
    },
    {
      city: "JARREAU",
      state: "LA"
    },
    {
      city: "JARRELL",
      state: "TX"
    },
    {
      city: "JARRETTSVILLE",
      state: "MD"
    },
    {
      city: "JARVISBURG",
      state: "NC"
    },
    {
      city: "JASONVILLE",
      state: "IN"
    },
    {
      city: "JASPER",
      state: "AL"
    },
    {
      city: "JASPER",
      state: "AR"
    },
    {
      city: "JASPER",
      state: "FL"
    },
    {
      city: "JASPER",
      state: "GA"
    },
    {
      city: "JASPER",
      state: "IN"
    },
    {
      city: "JASPER",
      state: "MI"
    },
    {
      city: "JASPER",
      state: "MN"
    },
    {
      city: "JASPER",
      state: "MO"
    },
    {
      city: "JASPER",
      state: "NY"
    },
    {
      city: "JASPER",
      state: "TN"
    },
    {
      city: "JASPER",
      state: "TX"
    },
    {
      city: "JAVA",
      state: "SD"
    },
    {
      city: "JAVA",
      state: "VA"
    },
    {
      city: "JAVA CENTER",
      state: "NY"
    },
    {
      city: "JAVA VILLAGE",
      state: "NY"
    },
    {
      city: "JAY",
      state: "FL"
    },
    {
      city: "JAY",
      state: "ME"
    },
    {
      city: "JAY",
      state: "NY"
    },
    {
      city: "JAY",
      state: "OK"
    },
    {
      city: "JAYESS",
      state: "MS"
    },
    {
      city: "JAYTON",
      state: "TX"
    },
    {
      city: "JBER",
      state: "AK"
    },
    {
      city: "JBPHH",
      state: "HI"
    },
    {
      city: "JBSA FT SAM HOUSTON",
      state: "TX"
    },
    {
      city: "JBSA LACKLAND",
      state: "TX"
    },
    {
      city: "JBSA RANDOLPH",
      state: "TX"
    },
    {
      city: "JEAN",
      state: "NV"
    },
    {
      city: "JEANERETTE",
      state: "LA"
    },
    {
      city: "JEANNETTE",
      state: "PA"
    },
    {
      city: "JEDDO",
      state: "MI"
    },
    {
      city: "JEFF",
      state: "KY"
    },
    {
      city: "JEFFERS",
      state: "MN"
    },
    {
      city: "JEFFERSON",
      state: "AL"
    },
    {
      city: "JEFFERSON",
      state: "AR"
    },
    {
      city: "JEFFERSON",
      state: "CO"
    },
    {
      city: "JEFFERSON",
      state: "GA"
    },
    {
      city: "JEFFERSON",
      state: "IA"
    },
    {
      city: "JEFFERSON",
      state: "MA"
    },
    {
      city: "JEFFERSON",
      state: "MD"
    },
    {
      city: "JEFFERSON",
      state: "ME"
    },
    {
      city: "JEFFERSON",
      state: "NC"
    },
    {
      city: "JEFFERSON",
      state: "NH"
    },
    {
      city: "JEFFERSON",
      state: "NY"
    },
    {
      city: "JEFFERSON",
      state: "OH"
    },
    {
      city: "JEFFERSON",
      state: "OR"
    },
    {
      city: "JEFFERSON",
      state: "PA"
    },
    {
      city: "JEFFERSON",
      state: "SC"
    },
    {
      city: "JEFFERSON",
      state: "SD"
    },
    {
      city: "JEFFERSON",
      state: "TX"
    },
    {
      city: "JEFFERSON",
      state: "WI"
    },
    {
      city: "JEFFERSON CITY",
      state: "MO"
    },
    {
      city: "JEFFERSON CITY",
      state: "MT"
    },
    {
      city: "JEFFERSON CITY",
      state: "TN"
    },
    {
      city: "JEFFERSON VALLEY",
      state: "NY"
    },
    {
      city: "JEFFERSONTON",
      state: "VA"
    },
    {
      city: "JEFFERSONVILLE",
      state: "GA"
    },
    {
      city: "JEFFERSONVILLE",
      state: "IN"
    },
    {
      city: "JEFFERSONVILLE",
      state: "KY"
    },
    {
      city: "JEFFERSONVILLE",
      state: "NY"
    },
    {
      city: "JEFFERSONVILLE",
      state: "OH"
    },
    {
      city: "JEFFERSONVILLE",
      state: "VT"
    },
    {
      city: "JEFFREY",
      state: "WV"
    },
    {
      city: "JEKYLL ISLAND",
      state: "GA"
    },
    {
      city: "JELLICO",
      state: "TN"
    },
    {
      city: "JELM",
      state: "WY"
    },
    {
      city: "JEMEZ PUEBLO",
      state: "NM"
    },
    {
      city: "JEMEZ SPRINGS",
      state: "NM"
    },
    {
      city: "JEMISON",
      state: "AL"
    },
    {
      city: "JENA",
      state: "LA"
    },
    {
      city: "JENERA",
      state: "OH"
    },
    {
      city: "JENISON",
      state: "MI"
    },
    {
      city: "JENKINS",
      state: "KY"
    },
    {
      city: "JENKINS",
      state: "MN"
    },
    {
      city: "JENKINSBURG",
      state: "GA"
    },
    {
      city: "JENKINSVILLE",
      state: "SC"
    },
    {
      city: "JENKINTOWN",
      state: "PA"
    },
    {
      city: "JENKS",
      state: "OK"
    },
    {
      city: "JENNER",
      state: "CA"
    },
    {
      city: "JENNERS",
      state: "PA"
    },
    {
      city: "JENNERSTOWN",
      state: "PA"
    },
    {
      city: "JENNINGS",
      state: "FL"
    },
    {
      city: "JENNINGS",
      state: "KS"
    },
    {
      city: "JENNINGS",
      state: "LA"
    },
    {
      city: "JENNINGS",
      state: "OK"
    },
    {
      city: "JENSEN",
      state: "UT"
    },
    {
      city: "JENSEN BEACH",
      state: "FL"
    },
    {
      city: "JEREMIAH",
      state: "KY"
    },
    {
      city: "JERICHO",
      state: "NY"
    },
    {
      city: "JERICHO",
      state: "VT"
    },
    {
      city: "JERICO SPRINGS",
      state: "MO"
    },
    {
      city: "JERMYN",
      state: "PA"
    },
    {
      city: "JERMYN",
      state: "TX"
    },
    {
      city: "JEROME",
      state: "AZ"
    },
    {
      city: "JEROME",
      state: "ID"
    },
    {
      city: "JEROME",
      state: "MI"
    },
    {
      city: "JEROME",
      state: "MO"
    },
    {
      city: "JEROME",
      state: "PA"
    },
    {
      city: "JEROMESVILLE",
      state: "OH"
    },
    {
      city: "JERRY CITY",
      state: "OH"
    },
    {
      city: "JERSEY",
      state: "AR"
    },
    {
      city: "JERSEY",
      state: "GA"
    },
    {
      city: "JERSEY",
      state: "VA"
    },
    {
      city: "JERSEY CITY",
      state: "NJ"
    },
    {
      city: "JERSEY SHORE",
      state: "PA"
    },
    {
      city: "JERSEYVILLE",
      state: "IL"
    },
    {
      city: "JERUSALEM",
      state: "AR"
    },
    {
      city: "JERUSALEM",
      state: "OH"
    },
    {
      city: "JESSE",
      state: "WV"
    },
    {
      city: "JESSIEVILLE",
      state: "AR"
    },
    {
      city: "JESSUP",
      state: "MD"
    },
    {
      city: "JESSUP",
      state: "PA"
    },
    {
      city: "JESUP",
      state: "GA"
    },
    {
      city: "JESUP",
      state: "IA"
    },
    {
      city: "JET",
      state: "OK"
    },
    {
      city: "JETERSVILLE",
      state: "VA"
    },
    {
      city: "JETMORE",
      state: "KS"
    },
    {
      city: "JEWELL",
      state: "GA"
    },
    {
      city: "JEWELL",
      state: "IA"
    },
    {
      city: "JEWELL",
      state: "KS"
    },
    {
      city: "JEWELL",
      state: "OH"
    },
    {
      city: "JEWELL RIDGE",
      state: "VA"
    },
    {
      city: "JEWETT",
      state: "IL"
    },
    {
      city: "JEWETT",
      state: "NY"
    },
    {
      city: "JEWETT",
      state: "OH"
    },
    {
      city: "JEWETT",
      state: "TX"
    },
    {
      city: "JEWETT CITY",
      state: "CT"
    },
    {
      city: "JIGGER",
      state: "LA"
    },
    {
      city: "JIM FALLS",
      state: "WI"
    },
    {
      city: "JIM THORPE",
      state: "PA"
    },
    {
      city: "JOANNA",
      state: "SC"
    },
    {
      city: "JOAQUIN",
      state: "TX"
    },
    {
      city: "JOBSTOWN",
      state: "NJ"
    },
    {
      city: "JOELTON",
      state: "TN"
    },
    {
      city: "JOES",
      state: "CO"
    },
    {
      city: "JOFFRE",
      state: "PA"
    },
    {
      city: "JOHANNESBURG",
      state: "MI"
    },
    {
      city: "JOHN DAY",
      state: "OR"
    },
    {
      city: "JOHNS ISLAND",
      state: "SC"
    },
    {
      city: "JOHNSBURG",
      state: "NY"
    },
    {
      city: "JOHNSON",
      state: "AR"
    },
    {
      city: "JOHNSON",
      state: "KS"
    },
    {
      city: "JOHNSON",
      state: "NE"
    },
    {
      city: "JOHNSON",
      state: "NY"
    },
    {
      city: "JOHNSON",
      state: "VT"
    },
    {
      city: "JOHNSON CITY",
      state: "NY"
    },
    {
      city: "JOHNSON CITY",
      state: "TN"
    },
    {
      city: "JOHNSON CITY",
      state: "TX"
    },
    {
      city: "JOHNSON CREEK",
      state: "WI"
    },
    {
      city: "JOHNSONBURG",
      state: "NJ"
    },
    {
      city: "JOHNSONBURG",
      state: "PA"
    },
    {
      city: "JOHNSONVILLE",
      state: "IL"
    },
    {
      city: "JOHNSONVILLE",
      state: "NY"
    },
    {
      city: "JOHNSONVILLE",
      state: "SC"
    },
    {
      city: "JOHNSTON",
      state: "IA"
    },
    {
      city: "JOHNSTON",
      state: "RI"
    },
    {
      city: "JOHNSTON",
      state: "SC"
    },
    {
      city: "JOHNSTON CITY",
      state: "IL"
    },
    {
      city: "JOHNSTOWN",
      state: "CO"
    },
    {
      city: "JOHNSTOWN",
      state: "NE"
    },
    {
      city: "JOHNSTOWN",
      state: "NY"
    },
    {
      city: "JOHNSTOWN",
      state: "OH"
    },
    {
      city: "JOHNSTOWN",
      state: "PA"
    },
    {
      city: "JOICE",
      state: "IA"
    },
    {
      city: "JOINER",
      state: "AR"
    },
    {
      city: "JOLIET",
      state: "IL"
    },
    {
      city: "JOLIET",
      state: "MT"
    },
    {
      city: "JOLLEY",
      state: "IA"
    },
    {
      city: "JOLO",
      state: "WV"
    },
    {
      city: "JOLON",
      state: "CA"
    },
    {
      city: "JONANCY",
      state: "KY"
    },
    {
      city: "JONAS RIDGE",
      state: "NC"
    },
    {
      city: "JONES",
      state: "AL"
    },
    {
      city: "JONES",
      state: "LA"
    },
    {
      city: "JONES",
      state: "MI"
    },
    {
      city: "JONES",
      state: "OK"
    },
    {
      city: "JONES MILL",
      state: "AR"
    },
    {
      city: "JONES MILLS",
      state: "PA"
    },
    {
      city: "JONESBORO",
      state: "AR"
    },
    {
      city: "JONESBORO",
      state: "GA"
    },
    {
      city: "JONESBORO",
      state: "IL"
    },
    {
      city: "JONESBORO",
      state: "IN"
    },
    {
      city: "JONESBORO",
      state: "LA"
    },
    {
      city: "JONESBORO",
      state: "ME"
    },
    {
      city: "JONESBORO",
      state: "TX"
    },
    {
      city: "JONESBOROUGH",
      state: "TN"
    },
    {
      city: "JONESBURG",
      state: "MO"
    },
    {
      city: "JONESPORT",
      state: "ME"
    },
    {
      city: "JONESTOWN",
      state: "MS"
    },
    {
      city: "JONESTOWN",
      state: "PA"
    },
    {
      city: "JONESVILLE",
      state: "IN"
    },
    {
      city: "JONESVILLE",
      state: "KY"
    },
    {
      city: "JONESVILLE",
      state: "LA"
    },
    {
      city: "JONESVILLE",
      state: "MI"
    },
    {
      city: "JONESVILLE",
      state: "NC"
    },
    {
      city: "JONESVILLE",
      state: "SC"
    },
    {
      city: "JONESVILLE",
      state: "TX"
    },
    {
      city: "JONESVILLE",
      state: "VA"
    },
    {
      city: "JONESVILLE",
      state: "VT"
    },
    {
      city: "JOPLIN",
      state: "MO"
    },
    {
      city: "JOPLIN",
      state: "MT"
    },
    {
      city: "JOPPA",
      state: "AL"
    },
    {
      city: "JOPPA",
      state: "IL"
    },
    {
      city: "JOPPA",
      state: "MD"
    },
    {
      city: "JORDAN",
      state: "MN"
    },
    {
      city: "JORDAN",
      state: "MT"
    },
    {
      city: "JORDAN",
      state: "NY"
    },
    {
      city: "JORDAN VALLEY",
      state: "OR"
    },
    {
      city: "JORDANVILLE",
      state: "NY"
    },
    {
      city: "JOSEPH",
      state: "OR"
    },
    {
      city: "JOSEPH",
      state: "UT"
    },
    {
      city: "JOSEPH CITY",
      state: "AZ"
    },
    {
      city: "JOSEPHINE",
      state: "PA"
    },
    {
      city: "JOSEPHINE",
      state: "TX"
    },
    {
      city: "JOSHUA",
      state: "TX"
    },
    {
      city: "JOSHUA TREE",
      state: "CA"
    },
    {
      city: "JOURDANTON",
      state: "TX"
    },
    {
      city: "JOY",
      state: "IL"
    },
    {
      city: "JOYCE",
      state: "LA"
    },
    {
      city: "JOYCE",
      state: "WA"
    },
    {
      city: "JUD",
      state: "ND"
    },
    {
      city: "JUDA",
      state: "WI"
    },
    {
      city: "JUDITH GAP",
      state: "MT"
    },
    {
      city: "JUDSON",
      state: "TX"
    },
    {
      city: "JUDSONIA",
      state: "AR"
    },
    {
      city: "JULESBURG",
      state: "CO"
    },
    {
      city: "JULIAETTA",
      state: "ID"
    },
    {
      city: "JULIAN",
      state: "CA"
    },
    {
      city: "JULIAN",
      state: "NC"
    },
    {
      city: "JULIAN",
      state: "PA"
    },
    {
      city: "JULIAN",
      state: "WV"
    },
    {
      city: "JULIETTE",
      state: "GA"
    },
    {
      city: "JULIUSTOWN",
      state: "NJ"
    },
    {
      city: "JUMP RIVER",
      state: "WI"
    },
    {
      city: "JUMPING BRANCH",
      state: "WV"
    },
    {
      city: "JUNCTION",
      state: "IL"
    },
    {
      city: "JUNCTION",
      state: "TX"
    },
    {
      city: "JUNCTION",
      state: "UT"
    },
    {
      city: "JUNCTION CITY",
      state: "AR"
    },
    {
      city: "JUNCTION CITY",
      state: "CA"
    },
    {
      city: "JUNCTION CITY",
      state: "GA"
    },
    {
      city: "JUNCTION CITY",
      state: "KS"
    },
    {
      city: "JUNCTION CITY",
      state: "KY"
    },
    {
      city: "JUNCTION CITY",
      state: "OH"
    },
    {
      city: "JUNCTION CITY",
      state: "OR"
    },
    {
      city: "JUNCTION CITY",
      state: "WI"
    },
    {
      city: "JUNE LAKE",
      state: "CA"
    },
    {
      city: "JUNEAU",
      state: "AK"
    },
    {
      city: "JUNEAU",
      state: "WI"
    },
    {
      city: "JUNIATA",
      state: "NE"
    },
    {
      city: "JUNIOR",
      state: "WV"
    },
    {
      city: "JUNTURA",
      state: "OR"
    },
    {
      city: "JUPITER",
      state: "FL"
    },
    {
      city: "JUSTICE",
      state: "IL"
    },
    {
      city: "JUSTICE",
      state: "WV"
    },
    {
      city: "JUSTICEBURG",
      state: "TX"
    },
    {
      city: "JUSTIN",
      state: "TX"
    },
    {
      city: "KAAAWA",
      state: "HI"
    },
    {
      city: "KABETOGAMA",
      state: "MN"
    },
    {
      city: "KADOKA",
      state: "SD"
    },
    {
      city: "KAHOKA",
      state: "MO"
    },
    {
      city: "KAHUKU",
      state: "HI"
    },
    {
      city: "KAHULUI",
      state: "HI"
    },
    {
      city: "KAIBETO",
      state: "AZ"
    },
    {
      city: "KAILUA",
      state: "HI"
    },
    {
      city: "KAILUA KONA",
      state: "HI"
    },
    {
      city: "KAISER",
      state: "MO"
    },
    {
      city: "KAKE",
      state: "AK"
    },
    {
      city: "KAKTOVIK",
      state: "AK"
    },
    {
      city: "KALAHEO",
      state: "HI"
    },
    {
      city: "KALAMA",
      state: "WA"
    },
    {
      city: "KALAMAZOO",
      state: "MI"
    },
    {
      city: "KALAUPAPA",
      state: "HI"
    },
    {
      city: "KALEVA",
      state: "MI"
    },
    {
      city: "KALIDA",
      state: "OH"
    },
    {
      city: "KALISPELL",
      state: "MT"
    },
    {
      city: "KALKASKA",
      state: "MI"
    },
    {
      city: "KALONA",
      state: "IA"
    },
    {
      city: "KALSKAG",
      state: "AK"
    },
    {
      city: "KALTAG",
      state: "AK"
    },
    {
      city: "KAMAS",
      state: "UT"
    },
    {
      city: "KAMAY",
      state: "TX"
    },
    {
      city: "KAMIAH",
      state: "ID"
    },
    {
      city: "KAMPSVILLE",
      state: "IL"
    },
    {
      city: "KAMRAR",
      state: "IA"
    },
    {
      city: "KAMUELA",
      state: "HI"
    },
    {
      city: "KANAB",
      state: "UT"
    },
    {
      city: "KANARANZI",
      state: "MN"
    },
    {
      city: "KANARRAVILLE",
      state: "UT"
    },
    {
      city: "KANAWHA",
      state: "IA"
    },
    {
      city: "KANAWHA FALLS",
      state: "WV"
    },
    {
      city: "KANAWHA HEAD",
      state: "WV"
    },
    {
      city: "KANDIYOHI",
      state: "MN"
    },
    {
      city: "KANE",
      state: "IL"
    },
    {
      city: "KANE",
      state: "PA"
    },
    {
      city: "KANEOHE",
      state: "HI"
    },
    {
      city: "KANEVILLE",
      state: "IL"
    },
    {
      city: "KANKAKEE",
      state: "IL"
    },
    {
      city: "KANNAPOLIS",
      state: "NC"
    },
    {
      city: "KANONA",
      state: "NY"
    },
    {
      city: "KANOPOLIS",
      state: "KS"
    },
    {
      city: "KANORADO",
      state: "KS"
    },
    {
      city: "KANOSH",
      state: "UT"
    },
    {
      city: "KANSAS",
      state: "AL"
    },
    {
      city: "KANSAS",
      state: "IL"
    },
    {
      city: "KANSAS",
      state: "OH"
    },
    {
      city: "KANSAS",
      state: "OK"
    },
    {
      city: "KANSAS CITY",
      state: "KS"
    },
    {
      city: "KANSAS CITY",
      state: "MO"
    },
    {
      city: "KANSASVILLE",
      state: "WI"
    },
    {
      city: "KAPAA",
      state: "HI"
    },
    {
      city: "KAPAAU",
      state: "HI"
    },
    {
      city: "KAPLAN",
      state: "LA"
    },
    {
      city: "KAPOLEI",
      state: "HI"
    },
    {
      city: "KAPOWSIN",
      state: "WA"
    },
    {
      city: "KARLSRUHE",
      state: "ND"
    },
    {
      city: "KARLSTAD",
      state: "MN"
    },
    {
      city: "KARLUK",
      state: "AK"
    },
    {
      city: "KARNACK",
      state: "TX"
    },
    {
      city: "KARNAK",
      state: "IL"
    },
    {
      city: "KARNES CITY",
      state: "TX"
    },
    {
      city: "KARNS CITY",
      state: "PA"
    },
    {
      city: "KARTHAUS",
      state: "PA"
    },
    {
      city: "KARVAL",
      state: "CO"
    },
    {
      city: "KASBEER",
      state: "IL"
    },
    {
      city: "KASIGLUK",
      state: "AK"
    },
    {
      city: "KASILOF",
      state: "AK"
    },
    {
      city: "KASOTA",
      state: "MN"
    },
    {
      city: "KASSON",
      state: "MN"
    },
    {
      city: "KATHLEEN",
      state: "FL"
    },
    {
      city: "KATHLEEN",
      state: "GA"
    },
    {
      city: "KATHRYN",
      state: "ND"
    },
    {
      city: "KATONAH",
      state: "NY"
    },
    {
      city: "KATTSKILL BAY",
      state: "NY"
    },
    {
      city: "KATY",
      state: "TX"
    },
    {
      city: "KAUFMAN",
      state: "TX"
    },
    {
      city: "KAUKAUNA",
      state: "WI"
    },
    {
      city: "KAUMAKANI",
      state: "HI"
    },
    {
      city: "KAUNAKAKAI",
      state: "HI"
    },
    {
      city: "KAUNEONGA LAKE",
      state: "NY"
    },
    {
      city: "KAW CITY",
      state: "OK"
    },
    {
      city: "KAWKAWLIN",
      state: "MI"
    },
    {
      city: "KAYCEE",
      state: "WY"
    },
    {
      city: "KAYENTA",
      state: "AZ"
    },
    {
      city: "KAYLOR",
      state: "SD"
    },
    {
      city: "KAYSVILLE",
      state: "UT"
    },
    {
      city: "KEAAU",
      state: "HI"
    },
    {
      city: "KEALAKEKUA",
      state: "HI"
    },
    {
      city: "KEALIA",
      state: "HI"
    },
    {
      city: "KEAMS CANYON",
      state: "AZ"
    },
    {
      city: "KEANSBURG",
      state: "NJ"
    },
    {
      city: "KEARNEY",
      state: "MO"
    },
    {
      city: "KEARNEY",
      state: "NE"
    },
    {
      city: "KEARNEYSVILLE",
      state: "WV"
    },
    {
      city: "KEARNY",
      state: "AZ"
    },
    {
      city: "KEARNY",
      state: "NJ"
    },
    {
      city: "KEARSARGE",
      state: "NH"
    },
    {
      city: "KEASBEY",
      state: "NJ"
    },
    {
      city: "KEATCHIE",
      state: "LA"
    },
    {
      city: "KEATON",
      state: "KY"
    },
    {
      city: "KEAUHOU",
      state: "HI"
    },
    {
      city: "KEAVY",
      state: "KY"
    },
    {
      city: "KECHI",
      state: "KS"
    },
    {
      city: "KEEDYSVILLE",
      state: "MD"
    },
    {
      city: "KEEGO HARBOR",
      state: "MI"
    },
    {
      city: "KEELER",
      state: "CA"
    },
    {
      city: "KEELING",
      state: "VA"
    },
    {
      city: "KEEN MOUNTAIN",
      state: "VA"
    },
    {
      city: "KEENE",
      state: "CA"
    },
    {
      city: "KEENE",
      state: "KY"
    },
    {
      city: "KEENE",
      state: "ND"
    },
    {
      city: "KEENE",
      state: "NH"
    },
    {
      city: "KEENE",
      state: "NY"
    },
    {
      city: "KEENE",
      state: "TX"
    },
    {
      city: "KEENE",
      state: "VA"
    },
    {
      city: "KEENE VALLEY",
      state: "NY"
    },
    {
      city: "KEENES",
      state: "IL"
    },
    {
      city: "KEENESBURG",
      state: "CO"
    },
    {
      city: "KEESEVILLE",
      state: "NY"
    },
    {
      city: "KEEWATIN",
      state: "MN"
    },
    {
      city: "KEEZLETOWN",
      state: "VA"
    },
    {
      city: "KEGLEY",
      state: "WV"
    },
    {
      city: "KEISER",
      state: "AR"
    },
    {
      city: "KEISTERVILLE",
      state: "PA"
    },
    {
      city: "KEITHSBURG",
      state: "IL"
    },
    {
      city: "KEITHVILLE",
      state: "LA"
    },
    {
      city: "KEIZER",
      state: "OR"
    },
    {
      city: "KEKAHA",
      state: "HI"
    },
    {
      city: "KELAYRES",
      state: "PA"
    },
    {
      city: "KELFORD",
      state: "NC"
    },
    {
      city: "KELL",
      state: "IL"
    },
    {
      city: "KELLER",
      state: "TX"
    },
    {
      city: "KELLER",
      state: "VA"
    },
    {
      city: "KELLER",
      state: "WA"
    },
    {
      city: "KELLERTON",
      state: "IA"
    },
    {
      city: "KELLEY",
      state: "IA"
    },
    {
      city: "KELLEYS ISLAND",
      state: "OH"
    },
    {
      city: "KELLIHER",
      state: "MN"
    },
    {
      city: "KELLNERSVILLE",
      state: "WI"
    },
    {
      city: "KELLOGG",
      state: "IA"
    },
    {
      city: "KELLOGG",
      state: "ID"
    },
    {
      city: "KELLOGG",
      state: "MN"
    },
    {
      city: "KELLY",
      state: "LA"
    },
    {
      city: "KELLY",
      state: "NC"
    },
    {
      city: "KELLY",
      state: "WY"
    },
    {
      city: "KELLYSVILLE",
      state: "WV"
    },
    {
      city: "KELLYTON",
      state: "AL"
    },
    {
      city: "KELLYVILLE",
      state: "OK"
    },
    {
      city: "KELSEYVILLE",
      state: "CA"
    },
    {
      city: "KELSO",
      state: "MO"
    },
    {
      city: "KELSO",
      state: "TN"
    },
    {
      city: "KELSO",
      state: "WA"
    },
    {
      city: "KELTON",
      state: "PA"
    },
    {
      city: "KEMAH",
      state: "TX"
    },
    {
      city: "KEMBLESVILLE",
      state: "PA"
    },
    {
      city: "KEMMERER",
      state: "WY"
    },
    {
      city: "KEMP",
      state: "TX"
    },
    {
      city: "KEMPNER",
      state: "TX"
    },
    {
      city: "KEMPTON",
      state: "IL"
    },
    {
      city: "KEMPTON",
      state: "IN"
    },
    {
      city: "KEMPTON",
      state: "PA"
    },
    {
      city: "KENAI",
      state: "AK"
    },
    {
      city: "KENANSVILLE",
      state: "FL"
    },
    {
      city: "KENANSVILLE",
      state: "NC"
    },
    {
      city: "KENBRIDGE",
      state: "VA"
    },
    {
      city: "KENDALIA",
      state: "TX"
    },
    {
      city: "KENDALL",
      state: "KS"
    },
    {
      city: "KENDALL",
      state: "MI"
    },
    {
      city: "KENDALL",
      state: "NY"
    },
    {
      city: "KENDALL",
      state: "WI"
    },
    {
      city: "KENDALL PARK",
      state: "NJ"
    },
    {
      city: "KENDALLVILLE",
      state: "IN"
    },
    {
      city: "KENDLETON",
      state: "TX"
    },
    {
      city: "KENDRICK",
      state: "ID"
    },
    {
      city: "KENDUSKEAG",
      state: "ME"
    },
    {
      city: "KENEDY",
      state: "TX"
    },
    {
      city: "KENEFIC",
      state: "OK"
    },
    {
      city: "KENESAW",
      state: "NE"
    },
    {
      city: "KENILWORTH",
      state: "IL"
    },
    {
      city: "KENILWORTH",
      state: "NJ"
    },
    {
      city: "KENLY",
      state: "NC"
    },
    {
      city: "KENMARE",
      state: "ND"
    },
    {
      city: "KENMORE",
      state: "WA"
    },
    {
      city: "KENNA",
      state: "WV"
    },
    {
      city: "KENNAN",
      state: "WI"
    },
    {
      city: "KENNARD",
      state: "IN"
    },
    {
      city: "KENNARD",
      state: "NE"
    },
    {
      city: "KENNARD",
      state: "TX"
    },
    {
      city: "KENNEBEC",
      state: "SD"
    },
    {
      city: "KENNEBUNK",
      state: "ME"
    },
    {
      city: "KENNEBUNKPORT",
      state: "ME"
    },
    {
      city: "KENNEDALE",
      state: "TX"
    },
    {
      city: "KENNEDY",
      state: "AL"
    },
    {
      city: "KENNEDY",
      state: "MN"
    },
    {
      city: "KENNEDY",
      state: "NY"
    },
    {
      city: "KENNEDYVILLE",
      state: "MD"
    },
    {
      city: "KENNER",
      state: "LA"
    },
    {
      city: "KENNERDELL",
      state: "PA"
    },
    {
      city: "KENNESAW",
      state: "GA"
    },
    {
      city: "KENNETH",
      state: "MN"
    },
    {
      city: "KENNETT",
      state: "MO"
    },
    {
      city: "KENNETT SQUARE",
      state: "PA"
    },
    {
      city: "KENNEWICK",
      state: "WA"
    },
    {
      city: "KENNEY",
      state: "IL"
    },
    {
      city: "KENNEY",
      state: "TX"
    },
    {
      city: "KENO",
      state: "OR"
    },
    {
      city: "KENOSHA",
      state: "WI"
    },
    {
      city: "KENOVA",
      state: "WV"
    },
    {
      city: "KENOZA LAKE",
      state: "NY"
    },
    {
      city: "KENSAL",
      state: "ND"
    },
    {
      city: "KENSETT",
      state: "AR"
    },
    {
      city: "KENSETT",
      state: "IA"
    },
    {
      city: "KENSINGTON",
      state: "KS"
    },
    {
      city: "KENSINGTON",
      state: "MD"
    },
    {
      city: "KENSINGTON",
      state: "MN"
    },
    {
      city: "KENSINGTON",
      state: "OH"
    },
    {
      city: "KENT",
      state: "AL"
    },
    {
      city: "KENT",
      state: "CT"
    },
    {
      city: "KENT",
      state: "IL"
    },
    {
      city: "KENT",
      state: "MN"
    },
    {
      city: "KENT",
      state: "NY"
    },
    {
      city: "KENT",
      state: "OH"
    },
    {
      city: "KENT",
      state: "OR"
    },
    {
      city: "KENT",
      state: "PA"
    },
    {
      city: "KENT",
      state: "WA"
    },
    {
      city: "KENT CITY",
      state: "MI"
    },
    {
      city: "KENTFIELD",
      state: "CA"
    },
    {
      city: "KENTLAND",
      state: "IN"
    },
    {
      city: "KENTON",
      state: "DE"
    },
    {
      city: "KENTON",
      state: "KY"
    },
    {
      city: "KENTON",
      state: "OH"
    },
    {
      city: "KENTON",
      state: "OK"
    },
    {
      city: "KENTON",
      state: "TN"
    },
    {
      city: "KENTS HILL",
      state: "ME"
    },
    {
      city: "KENTS STORE",
      state: "VA"
    },
    {
      city: "KENTWOOD",
      state: "LA"
    },
    {
      city: "KENVIL",
      state: "NJ"
    },
    {
      city: "KENVIR",
      state: "KY"
    },
    {
      city: "KENWOOD",
      state: "CA"
    },
    {
      city: "KENYON",
      state: "MN"
    },
    {
      city: "KENYON",
      state: "RI"
    },
    {
      city: "KEO",
      state: "AR"
    },
    {
      city: "KEOKEE",
      state: "VA"
    },
    {
      city: "KEOKUK",
      state: "IA"
    },
    {
      city: "KEOSAUQUA",
      state: "IA"
    },
    {
      city: "KEOTA",
      state: "IA"
    },
    {
      city: "KEOTA",
      state: "OK"
    },
    {
      city: "KERBY",
      state: "OR"
    },
    {
      city: "KERENS",
      state: "TX"
    },
    {
      city: "KERENS",
      state: "WV"
    },
    {
      city: "KERHONKSON",
      state: "NY"
    },
    {
      city: "KERKHOVEN",
      state: "MN"
    },
    {
      city: "KERMAN",
      state: "CA"
    },
    {
      city: "KERMIT",
      state: "TX"
    },
    {
      city: "KERMIT",
      state: "WV"
    },
    {
      city: "KERNERSVILLE",
      state: "NC"
    },
    {
      city: "KERNVILLE",
      state: "CA"
    },
    {
      city: "KERRICK",
      state: "MN"
    },
    {
      city: "KERRICK",
      state: "TX"
    },
    {
      city: "KERRVILLE",
      state: "TX"
    },
    {
      city: "KERSEY",
      state: "CO"
    },
    {
      city: "KERSEY",
      state: "PA"
    },
    {
      city: "KERSHAW",
      state: "SC"
    },
    {
      city: "KESHENA",
      state: "WI"
    },
    {
      city: "KESLERS CROSS LANES",
      state: "WV"
    },
    {
      city: "KESLEY",
      state: "IA"
    },
    {
      city: "KESWICK",
      state: "IA"
    },
    {
      city: "KESWICK",
      state: "VA"
    },
    {
      city: "KETCHIKAN",
      state: "AK"
    },
    {
      city: "KETCHUM",
      state: "ID"
    },
    {
      city: "KETCHUM",
      state: "OK"
    },
    {
      city: "KETTLE FALLS",
      state: "WA"
    },
    {
      city: "KETTLE RIVER",
      state: "MN"
    },
    {
      city: "KETTLEMAN CITY",
      state: "CA"
    },
    {
      city: "KETTLERSVILLE",
      state: "OH"
    },
    {
      city: "KEUKA PARK",
      state: "NY"
    },
    {
      city: "KEVIL",
      state: "KY"
    },
    {
      city: "KEVIN",
      state: "MT"
    },
    {
      city: "KEW GARDENS",
      state: "NY"
    },
    {
      city: "KEWADIN",
      state: "MI"
    },
    {
      city: "KEWANEE",
      state: "IL"
    },
    {
      city: "KEWANEE",
      state: "MO"
    },
    {
      city: "KEWANNA",
      state: "IN"
    },
    {
      city: "KEWASKUM",
      state: "WI"
    },
    {
      city: "KEWAUNEE",
      state: "WI"
    },
    {
      city: "KEY BISCAYNE",
      state: "FL"
    },
    {
      city: "KEY COLONY BEACH",
      state: "FL"
    },
    {
      city: "KEY LARGO",
      state: "FL"
    },
    {
      city: "KEY WEST",
      state: "FL"
    },
    {
      city: "KEYES",
      state: "CA"
    },
    {
      city: "KEYES",
      state: "OK"
    },
    {
      city: "KEYESPORT",
      state: "IL"
    },
    {
      city: "KEYMAR",
      state: "MD"
    },
    {
      city: "KEYPORT",
      state: "NJ"
    },
    {
      city: "KEYPORT",
      state: "WA"
    },
    {
      city: "KEYSER",
      state: "WV"
    },
    {
      city: "KEYSTONE",
      state: "IA"
    },
    {
      city: "KEYSTONE",
      state: "IN"
    },
    {
      city: "KEYSTONE",
      state: "NE"
    },
    {
      city: "KEYSTONE",
      state: "SD"
    },
    {
      city: "KEYSTONE HEIGHTS",
      state: "FL"
    },
    {
      city: "KEYSVILLE",
      state: "GA"
    },
    {
      city: "KEYSVILLE",
      state: "VA"
    },
    {
      city: "KEYTESVILLE",
      state: "MO"
    },
    {
      city: "KIAHSVILLE",
      state: "WV"
    },
    {
      city: "KIAMESHA LAKE",
      state: "NY"
    },
    {
      city: "KIANA",
      state: "AK"
    },
    {
      city: "KIDDER",
      state: "MO"
    },
    {
      city: "KIDRON",
      state: "OH"
    },
    {
      city: "KIEFER",
      state: "OK"
    },
    {
      city: "KIEL",
      state: "WI"
    },
    {
      city: "KIELER",
      state: "WI"
    },
    {
      city: "KIESTER",
      state: "MN"
    },
    {
      city: "KIHEI",
      state: "HI"
    },
    {
      city: "KILA",
      state: "MT"
    },
    {
      city: "KILAUEA",
      state: "HI"
    },
    {
      city: "KILBOURNE",
      state: "IL"
    },
    {
      city: "KILBOURNE",
      state: "LA"
    },
    {
      city: "KILBOURNE",
      state: "OH"
    },
    {
      city: "KILGORE",
      state: "NE"
    },
    {
      city: "KILGORE",
      state: "TX"
    },
    {
      city: "KILKENNY",
      state: "MN"
    },
    {
      city: "KILL BUCK",
      state: "NY"
    },
    {
      city: "KILL DEVIL HILLS",
      state: "NC"
    },
    {
      city: "KILLARNEY",
      state: "FL"
    },
    {
      city: "KILLAWOG",
      state: "NY"
    },
    {
      city: "KILLBUCK",
      state: "OH"
    },
    {
      city: "KILLDEER",
      state: "ND"
    },
    {
      city: "KILLDUFF",
      state: "IA"
    },
    {
      city: "KILLEEN",
      state: "TX"
    },
    {
      city: "KILLEN",
      state: "AL"
    },
    {
      city: "KILLINGTON",
      state: "VT"
    },
    {
      city: "KILLINGWORTH",
      state: "CT"
    },
    {
      city: "KILMARNOCK",
      state: "VA"
    },
    {
      city: "KILMICHAEL",
      state: "MS"
    },
    {
      city: "KILN",
      state: "MS"
    },
    {
      city: "KIM",
      state: "CO"
    },
    {
      city: "KIMBALL",
      state: "MN"
    },
    {
      city: "KIMBALL",
      state: "NE"
    },
    {
      city: "KIMBALL",
      state: "SD"
    },
    {
      city: "KIMBALL",
      state: "WV"
    },
    {
      city: "KIMBALLTON",
      state: "IA"
    },
    {
      city: "KIMBERLING CITY",
      state: "MO"
    },
    {
      city: "KIMBERLY",
      state: "AL"
    },
    {
      city: "KIMBERLY",
      state: "ID"
    },
    {
      city: "KIMBERLY",
      state: "OR"
    },
    {
      city: "KIMBERLY",
      state: "WI"
    },
    {
      city: "KIMBERLY",
      state: "WV"
    },
    {
      city: "KIMBERTON",
      state: "PA"
    },
    {
      city: "KIMBOLTON",
      state: "OH"
    },
    {
      city: "KIMMELL",
      state: "IN"
    },
    {
      city: "KIMMSWICK",
      state: "MO"
    },
    {
      city: "KIMPER",
      state: "KY"
    },
    {
      city: "KINARDS",
      state: "SC"
    },
    {
      city: "KINCAID",
      state: "IL"
    },
    {
      city: "KINCAID",
      state: "KS"
    },
    {
      city: "KINCAID",
      state: "WV"
    },
    {
      city: "KINCHELOE",
      state: "MI"
    },
    {
      city: "KINDE",
      state: "MI"
    },
    {
      city: "KINDER",
      state: "LA"
    },
    {
      city: "KINDERHOOK",
      state: "IL"
    },
    {
      city: "KINDERHOOK",
      state: "NY"
    },
    {
      city: "KINDRED",
      state: "ND"
    },
    {
      city: "KING",
      state: "NC"
    },
    {
      city: "KING",
      state: "WI"
    },
    {
      city: "KING AND QUEEN COURT HOUSE",
      state: "VA"
    },
    {
      city: "KING CITY",
      state: "CA"
    },
    {
      city: "KING CITY",
      state: "MO"
    },
    {
      city: "KING COVE",
      state: "AK"
    },
    {
      city: "KING FERRY",
      state: "NY"
    },
    {
      city: "KING GEORGE",
      state: "VA"
    },
    {
      city: "KING HILL",
      state: "ID"
    },
    {
      city: "KING OF PRUSSIA",
      state: "PA"
    },
    {
      city: "KING SALMON",
      state: "AK"
    },
    {
      city: "KING WILLIAM",
      state: "VA"
    },
    {
      city: "KINGDOM CITY",
      state: "MO"
    },
    {
      city: "KINGFIELD",
      state: "ME"
    },
    {
      city: "KINGFISHER",
      state: "OK"
    },
    {
      city: "KINGMAN",
      state: "AZ"
    },
    {
      city: "KINGMAN",
      state: "IN"
    },
    {
      city: "KINGMAN",
      state: "KS"
    },
    {
      city: "KINGMAN",
      state: "ME"
    },
    {
      city: "KINGMONT",
      state: "WV"
    },
    {
      city: "KINGS BAY",
      state: "GA"
    },
    {
      city: "KINGS BEACH",
      state: "CA"
    },
    {
      city: "KINGS CANYON NATIONAL PK",
      state: "CA"
    },
    {
      city: "KINGS MILLS",
      state: "OH"
    },
    {
      city: "KINGS MOUNTAIN",
      state: "KY"
    },
    {
      city: "KINGS MOUNTAIN",
      state: "NC"
    },
    {
      city: "KINGS PARK",
      state: "NY"
    },
    {
      city: "KINGSBURG",
      state: "CA"
    },
    {
      city: "KINGSBURY",
      state: "IN"
    },
    {
      city: "KINGSBURY",
      state: "TX"
    },
    {
      city: "KINGSFORD",
      state: "MI"
    },
    {
      city: "KINGSFORD HEIGHTS",
      state: "IN"
    },
    {
      city: "KINGSLAND",
      state: "AR"
    },
    {
      city: "KINGSLAND",
      state: "GA"
    },
    {
      city: "KINGSLAND",
      state: "TX"
    },
    {
      city: "KINGSLEY",
      state: "IA"
    },
    {
      city: "KINGSLEY",
      state: "MI"
    },
    {
      city: "KINGSLEY",
      state: "PA"
    },
    {
      city: "KINGSPORT",
      state: "TN"
    },
    {
      city: "KINGSTON",
      state: "AR"
    },
    {
      city: "KINGSTON",
      state: "GA"
    },
    {
      city: "KINGSTON",
      state: "ID"
    },
    {
      city: "KINGSTON",
      state: "IL"
    },
    {
      city: "KINGSTON",
      state: "MA"
    },
    {
      city: "KINGSTON",
      state: "MI"
    },
    {
      city: "KINGSTON",
      state: "MO"
    },
    {
      city: "KINGSTON",
      state: "NH"
    },
    {
      city: "KINGSTON",
      state: "NJ"
    },
    {
      city: "KINGSTON",
      state: "NY"
    },
    {
      city: "KINGSTON",
      state: "OH"
    },
    {
      city: "KINGSTON",
      state: "OK"
    },
    {
      city: "KINGSTON",
      state: "PA"
    },
    {
      city: "KINGSTON",
      state: "RI"
    },
    {
      city: "KINGSTON",
      state: "TN"
    },
    {
      city: "KINGSTON",
      state: "WA"
    },
    {
      city: "KINGSTON",
      state: "WI"
    },
    {
      city: "KINGSTON MINES",
      state: "IL"
    },
    {
      city: "KINGSTON SPRINGS",
      state: "TN"
    },
    {
      city: "KINGSTREE",
      state: "SC"
    },
    {
      city: "KINGSVILLE",
      state: "MD"
    },
    {
      city: "KINGSVILLE",
      state: "MO"
    },
    {
      city: "KINGSVILLE",
      state: "OH"
    },
    {
      city: "KINGSVILLE",
      state: "TX"
    },
    {
      city: "KINGWOOD",
      state: "TX"
    },
    {
      city: "KINGWOOD",
      state: "WV"
    },
    {
      city: "KINMUNDY",
      state: "IL"
    },
    {
      city: "KINNEAR",
      state: "WY"
    },
    {
      city: "KINNEY",
      state: "MN"
    },
    {
      city: "KINROSS",
      state: "MI"
    },
    {
      city: "KINSALE",
      state: "VA"
    },
    {
      city: "KINSEY",
      state: "MT"
    },
    {
      city: "KINSLEY",
      state: "KS"
    },
    {
      city: "KINSMAN",
      state: "IL"
    },
    {
      city: "KINSMAN",
      state: "OH"
    },
    {
      city: "KINSTON",
      state: "AL"
    },
    {
      city: "KINSTON",
      state: "NC"
    },
    {
      city: "KINTA",
      state: "OK"
    },
    {
      city: "KINTNERSVILLE",
      state: "PA"
    },
    {
      city: "KINTYRE",
      state: "ND"
    },
    {
      city: "KINZERS",
      state: "PA"
    },
    {
      city: "KIOWA",
      state: "CO"
    },
    {
      city: "KIOWA",
      state: "KS"
    },
    {
      city: "KIOWA",
      state: "OK"
    },
    {
      city: "KIPLING",
      state: "NC"
    },
    {
      city: "KIPLING",
      state: "OH"
    },
    {
      city: "KIPNUK",
      state: "AK"
    },
    {
      city: "KIPTON",
      state: "OH"
    },
    {
      city: "KIRBY",
      state: "AR"
    },
    {
      city: "KIRBY",
      state: "OH"
    },
    {
      city: "KIRBY",
      state: "WY"
    },
    {
      city: "KIRBYVILLE",
      state: "MO"
    },
    {
      city: "KIRBYVILLE",
      state: "TX"
    },
    {
      city: "KIRK",
      state: "CO"
    },
    {
      city: "KIRKERSVILLE",
      state: "OH"
    },
    {
      city: "KIRKLAND",
      state: "AZ"
    },
    {
      city: "KIRKLAND",
      state: "IL"
    },
    {
      city: "KIRKLAND",
      state: "WA"
    },
    {
      city: "KIRKLIN",
      state: "IN"
    },
    {
      city: "KIRKMAN",
      state: "IA"
    },
    {
      city: "KIRKSEY",
      state: "KY"
    },
    {
      city: "KIRKSVILLE",
      state: "MO"
    },
    {
      city: "KIRKVILLE",
      state: "NY"
    },
    {
      city: "KIRKWOOD",
      state: "CA"
    },
    {
      city: "KIRKWOOD",
      state: "DE"
    },
    {
      city: "KIRKWOOD",
      state: "IL"
    },
    {
      city: "KIRKWOOD",
      state: "NY"
    },
    {
      city: "KIRKWOOD",
      state: "PA"
    },
    {
      city: "KIRON",
      state: "IA"
    },
    {
      city: "KIRTLAND",
      state: "NM"
    },
    {
      city: "KIRTLAND AFB",
      state: "NM"
    },
    {
      city: "KIRVIN",
      state: "TX"
    },
    {
      city: "KIRWIN",
      state: "KS"
    },
    {
      city: "KISMET",
      state: "KS"
    },
    {
      city: "KISSEE MILLS",
      state: "MO"
    },
    {
      city: "KISSIMMEE",
      state: "FL"
    },
    {
      city: "KISTLER",
      state: "WV"
    },
    {
      city: "KIT CARSON",
      state: "CO"
    },
    {
      city: "KITE",
      state: "GA"
    },
    {
      city: "KITE",
      state: "KY"
    },
    {
      city: "KITTANNING",
      state: "PA"
    },
    {
      city: "KITTERY",
      state: "ME"
    },
    {
      city: "KITTERY POINT",
      state: "ME"
    },
    {
      city: "KITTITAS",
      state: "WA"
    },
    {
      city: "KITTREDGE",
      state: "CO"
    },
    {
      city: "KITTRELL",
      state: "NC"
    },
    {
      city: "KITTS HILL",
      state: "OH"
    },
    {
      city: "KITTY HAWK",
      state: "NC"
    },
    {
      city: "KITZMILLER",
      state: "MD"
    },
    {
      city: "KIVALINA",
      state: "AK"
    },
    {
      city: "KLAMATH",
      state: "CA"
    },
    {
      city: "KLAMATH FALLS",
      state: "OR"
    },
    {
      city: "KLAMATH RIVER",
      state: "CA"
    },
    {
      city: "KLAWOCK",
      state: "AK"
    },
    {
      city: "KLEINFELTERSVILLE",
      state: "PA"
    },
    {
      city: "KLEMME",
      state: "IA"
    },
    {
      city: "KLICKITAT",
      state: "WA"
    },
    {
      city: "KLINGERSTOWN",
      state: "PA"
    },
    {
      city: "KLONDIKE",
      state: "TX"
    },
    {
      city: "KNAPP",
      state: "WI"
    },
    {
      city: "KNEELAND",
      state: "CA"
    },
    {
      city: "KNIERIM",
      state: "IA"
    },
    {
      city: "KNIFE RIVER",
      state: "MN"
    },
    {
      city: "KNIFLEY",
      state: "KY"
    },
    {
      city: "KNIGHTDALE",
      state: "NC"
    },
    {
      city: "KNIGHTS LANDING",
      state: "CA"
    },
    {
      city: "KNIGHTSEN",
      state: "CA"
    },
    {
      city: "KNIGHTSTOWN",
      state: "IN"
    },
    {
      city: "KNIGHTSVILLE",
      state: "IN"
    },
    {
      city: "KNIPPA",
      state: "TX"
    },
    {
      city: "KNOB LICK",
      state: "KY"
    },
    {
      city: "KNOB LICK",
      state: "MO"
    },
    {
      city: "KNOB NOSTER",
      state: "MO"
    },
    {
      city: "KNOBEL",
      state: "AR"
    },
    {
      city: "KNOTT",
      state: "TX"
    },
    {
      city: "KNOTTS ISLAND",
      state: "NC"
    },
    {
      city: "KNOWLESVILLE",
      state: "NY"
    },
    {
      city: "KNOX",
      state: "IN"
    },
    {
      city: "KNOX",
      state: "NY"
    },
    {
      city: "KNOX",
      state: "PA"
    },
    {
      city: "KNOX CITY",
      state: "MO"
    },
    {
      city: "KNOX CITY",
      state: "TX"
    },
    {
      city: "KNOX DALE",
      state: "PA"
    },
    {
      city: "KNOXBORO",
      state: "NY"
    },
    {
      city: "KNOXVILLE",
      state: "AL"
    },
    {
      city: "KNOXVILLE",
      state: "AR"
    },
    {
      city: "KNOXVILLE",
      state: "GA"
    },
    {
      city: "KNOXVILLE",
      state: "IA"
    },
    {
      city: "KNOXVILLE",
      state: "IL"
    },
    {
      city: "KNOXVILLE",
      state: "MD"
    },
    {
      city: "KNOXVILLE",
      state: "PA"
    },
    {
      city: "KNOXVILLE",
      state: "TN"
    },
    {
      city: "KOBUK",
      state: "AK"
    },
    {
      city: "KODAK",
      state: "TN"
    },
    {
      city: "KODIAK",
      state: "AK"
    },
    {
      city: "KOELTZTOWN",
      state: "MO"
    },
    {
      city: "KOHLER",
      state: "WI"
    },
    {
      city: "KOKOMO",
      state: "IN"
    },
    {
      city: "KOKOMO",
      state: "MS"
    },
    {
      city: "KOLOA",
      state: "HI"
    },
    {
      city: "KONAWA",
      state: "OK"
    },
    {
      city: "KONGIGANAK",
      state: "AK"
    },
    {
      city: "KOOSHAREM",
      state: "UT"
    },
    {
      city: "KOOSKIA",
      state: "ID"
    },
    {
      city: "KOOTENAI",
      state: "ID"
    },
    {
      city: "KOPPEL",
      state: "PA"
    },
    {
      city: "KOPPERL",
      state: "TX"
    },
    {
      city: "KOPPERSTON",
      state: "WV"
    },
    {
      city: "KORBEL",
      state: "CA"
    },
    {
      city: "KOSCIUSKO",
      state: "MS"
    },
    {
      city: "KOSHKONONG",
      state: "MO"
    },
    {
      city: "KOSSE",
      state: "TX"
    },
    {
      city: "KOSSUTH",
      state: "PA"
    },
    {
      city: "KOTLIK",
      state: "AK"
    },
    {
      city: "KOTZEBUE",
      state: "AK"
    },
    {
      city: "KOUNTZE",
      state: "TX"
    },
    {
      city: "KOUTS",
      state: "IN"
    },
    {
      city: "KOYUK",
      state: "AK"
    },
    {
      city: "KRAKOW",
      state: "WI"
    },
    {
      city: "KRAMER",
      state: "ND"
    },
    {
      city: "KRANZBURG",
      state: "SD"
    },
    {
      city: "KREAMER",
      state: "PA"
    },
    {
      city: "KREBS",
      state: "OK"
    },
    {
      city: "KREMLIN",
      state: "MT"
    },
    {
      city: "KREMLIN",
      state: "OK"
    },
    {
      city: "KREMMLING",
      state: "CO"
    },
    {
      city: "KRESGEVILLE",
      state: "PA"
    },
    {
      city: "KRESS",
      state: "TX"
    },
    {
      city: "KROTZ SPRINGS",
      state: "LA"
    },
    {
      city: "KRUM",
      state: "TX"
    },
    {
      city: "KRYPTON",
      state: "KY"
    },
    {
      city: "KUALAPUU",
      state: "HI"
    },
    {
      city: "KULA",
      state: "HI"
    },
    {
      city: "KULM",
      state: "ND"
    },
    {
      city: "KULPMONT",
      state: "PA"
    },
    {
      city: "KULPSVILLE",
      state: "PA"
    },
    {
      city: "KUNA",
      state: "ID"
    },
    {
      city: "KUNIA",
      state: "HI"
    },
    {
      city: "KUNKLE",
      state: "OH"
    },
    {
      city: "KUNKLETOWN",
      state: "PA"
    },
    {
      city: "KURE BEACH",
      state: "NC"
    },
    {
      city: "KURTEN",
      state: "TX"
    },
    {
      city: "KURTHWOOD",
      state: "LA"
    },
    {
      city: "KURTISTOWN",
      state: "HI"
    },
    {
      city: "KUTTAWA",
      state: "KY"
    },
    {
      city: "KUTZTOWN",
      state: "PA"
    },
    {
      city: "KWETHLUK",
      state: "AK"
    },
    {
      city: "KWIGILLINGOK",
      state: "AK"
    },
    {
      city: "KYBURZ",
      state: "CA"
    },
    {
      city: "KYKOTSMOVI VILLAGE",
      state: "AZ"
    },
    {
      city: "KYLE",
      state: "SD"
    },
    {
      city: "KYLE",
      state: "TX"
    },
    {
      city: "KYLERTOWN",
      state: "PA"
    },
    {
      city: "LA BARGE",
      state: "WY"
    },
    {
      city: "LA BELLE",
      state: "MO"
    },
    {
      city: "LA BELLE",
      state: "PA"
    },
    {
      city: "LA BLANCA",
      state: "TX"
    },
    {
      city: "LA CANADA FLINTRIDGE",
      state: "CA"
    },
    {
      city: "LA CENTER",
      state: "KY"
    },
    {
      city: "LA CENTER",
      state: "WA"
    },
    {
      city: "LA CONNER",
      state: "WA"
    },
    {
      city: "LA COSTE",
      state: "TX"
    },
    {
      city: "LA CRESCENT",
      state: "MN"
    },
    {
      city: "LA CRESCENTA",
      state: "CA"
    },
    {
      city: "LA CROSSE",
      state: "FL"
    },
    {
      city: "LA CROSSE",
      state: "IN"
    },
    {
      city: "LA CROSSE",
      state: "KS"
    },
    {
      city: "LA CROSSE",
      state: "VA"
    },
    {
      city: "LA CROSSE",
      state: "WI"
    },
    {
      city: "LA FARGE",
      state: "WI"
    },
    {
      city: "LA FARGEVILLE",
      state: "NY"
    },
    {
      city: "LA FAYETTE",
      state: "GA"
    },
    {
      city: "LA FAYETTE",
      state: "IL"
    },
    {
      city: "LA FAYETTE",
      state: "NY"
    },
    {
      city: "LA FERIA",
      state: "TX"
    },
    {
      city: "LA FOLLETTE",
      state: "TN"
    },
    {
      city: "LA FONTAINE",
      state: "IN"
    },
    {
      city: "LA FRANCE",
      state: "SC"
    },
    {
      city: "LA GRANDE",
      state: "OR"
    },
    {
      city: "LA GRANDE",
      state: "WA"
    },
    {
      city: "LA GRANGE",
      state: "CA"
    },
    {
      city: "LA GRANGE",
      state: "IL"
    },
    {
      city: "LA GRANGE",
      state: "KY"
    },
    {
      city: "LA GRANGE",
      state: "MO"
    },
    {
      city: "LA GRANGE",
      state: "NC"
    },
    {
      city: "LA GRANGE",
      state: "TN"
    },
    {
      city: "LA GRANGE",
      state: "TX"
    },
    {
      city: "LA GRANGE PARK",
      state: "IL"
    },
    {
      city: "LA HABRA",
      state: "CA"
    },
    {
      city: "LA HARPE",
      state: "IL"
    },
    {
      city: "LA HARPE",
      state: "KS"
    },
    {
      city: "LA HONDA",
      state: "CA"
    },
    {
      city: "LA JARA",
      state: "CO"
    },
    {
      city: "LA JARA",
      state: "NM"
    },
    {
      city: "LA JOLLA",
      state: "CA"
    },
    {
      city: "LA JOSE",
      state: "PA"
    },
    {
      city: "LA JOYA",
      state: "NM"
    },
    {
      city: "LA JOYA",
      state: "TX"
    },
    {
      city: "LA JUNTA",
      state: "CO"
    },
    {
      city: "LA LUZ",
      state: "NM"
    },
    {
      city: "LA MADERA",
      state: "NM"
    },
    {
      city: "LA MARQUE",
      state: "TX"
    },
    {
      city: "LA MESA",
      state: "CA"
    },
    {
      city: "LA MESA",
      state: "NM"
    },
    {
      city: "LA MIRADA",
      state: "CA"
    },
    {
      city: "LA MOILLE",
      state: "IL"
    },
    {
      city: "LA MONTE",
      state: "MO"
    },
    {
      city: "LA MOTTE",
      state: "IA"
    },
    {
      city: "LA PALMA",
      state: "CA"
    },
    {
      city: "LA PINE",
      state: "OR"
    },
    {
      city: "LA PLACE",
      state: "IL"
    },
    {
      city: "LA PLACE",
      state: "LA"
    },
    {
      city: "LA PLATA",
      state: "MD"
    },
    {
      city: "LA PLATA",
      state: "MO"
    },
    {
      city: "LA PLATA",
      state: "NM"
    },
    {
      city: "LA PLUME",
      state: "PA"
    },
    {
      city: "LA POINTE",
      state: "WI"
    },
    {
      city: "LA PORTE",
      state: "IN"
    },
    {
      city: "LA PORTE",
      state: "TX"
    },
    {
      city: "LA PORTE CITY",
      state: "IA"
    },
    {
      city: "LA PRAIRIE",
      state: "IL"
    },
    {
      city: "LA PRYOR",
      state: "TX"
    },
    {
      city: "LA PUENTE",
      state: "CA"
    },
    {
      city: "LA QUINTA",
      state: "CA"
    },
    {
      city: "LA ROSE",
      state: "IL"
    },
    {
      city: "LA RUE",
      state: "OH"
    },
    {
      city: "LA RUSSELL",
      state: "MO"
    },
    {
      city: "LA SAL",
      state: "UT"
    },
    {
      city: "LA SALLE",
      state: "CO"
    },
    {
      city: "LA SALLE",
      state: "IL"
    },
    {
      city: "LA SALLE",
      state: "MI"
    },
    {
      city: "LA SALLE",
      state: "MN"
    },
    {
      city: "LA VALLE",
      state: "WI"
    },
    {
      city: "LA VERGNE",
      state: "TN"
    },
    {
      city: "LA VERKIN",
      state: "UT"
    },
    {
      city: "LA VERNE",
      state: "CA"
    },
    {
      city: "LA VERNIA",
      state: "TX"
    },
    {
      city: "LA VETA",
      state: "CO"
    },
    {
      city: "LA VILLA",
      state: "TX"
    },
    {
      city: "LA VISTA",
      state: "NE"
    },
    {
      city: "LA WARD",
      state: "TX"
    },
    {
      city: "LABADIE",
      state: "MO"
    },
    {
      city: "LABADIEVILLE",
      state: "LA"
    },
    {
      city: "LABELLE",
      state: "FL"
    },
    {
      city: "LABOLT",
      state: "SD"
    },
    {
      city: "LAC DU FLAMBEAU",
      state: "WI"
    },
    {
      city: "LACARNE",
      state: "OH"
    },
    {
      city: "LACASSINE",
      state: "LA"
    },
    {
      city: "LACEY",
      state: "WA"
    },
    {
      city: "LACEY SPRING",
      state: "VA"
    },
    {
      city: "LACEYS SPRING",
      state: "AL"
    },
    {
      city: "LACEYVILLE",
      state: "PA"
    },
    {
      city: "LACHINE",
      state: "MI"
    },
    {
      city: "LACKAWAXEN",
      state: "PA"
    },
    {
      city: "LACKEY",
      state: "KY"
    },
    {
      city: "LACKEY",
      state: "VA"
    },
    {
      city: "LACLEDE",
      state: "ID"
    },
    {
      city: "LACLEDE",
      state: "MO"
    },
    {
      city: "LACOMBE",
      state: "LA"
    },
    {
      city: "LACON",
      state: "IL"
    },
    {
      city: "LACONA",
      state: "IA"
    },
    {
      city: "LACONA",
      state: "NY"
    },
    {
      city: "LACONIA",
      state: "IN"
    },
    {
      city: "LACONIA",
      state: "NH"
    },
    {
      city: "LACOOCHEE",
      state: "FL"
    },
    {
      city: "LACROSSE",
      state: "WA"
    },
    {
      city: "LACYGNE",
      state: "KS"
    },
    {
      city: "LADD",
      state: "IL"
    },
    {
      city: "LADDONIA",
      state: "MO"
    },
    {
      city: "LADERA RANCH",
      state: "CA"
    },
    {
      city: "LADOGA",
      state: "IN"
    },
    {
      city: "LADONIA",
      state: "TX"
    },
    {
      city: "LADORA",
      state: "IA"
    },
    {
      city: "LADSON",
      state: "SC"
    },
    {
      city: "LADY LAKE",
      state: "FL"
    },
    {
      city: "LADYSMITH",
      state: "VA"
    },
    {
      city: "LADYSMITH",
      state: "WI"
    },
    {
      city: "LAFAYETTE",
      state: "AL"
    },
    {
      city: "LAFAYETTE",
      state: "CA"
    },
    {
      city: "LAFAYETTE",
      state: "CO"
    },
    {
      city: "LAFAYETTE",
      state: "IN"
    },
    {
      city: "LAFAYETTE",
      state: "LA"
    },
    {
      city: "LAFAYETTE",
      state: "MN"
    },
    {
      city: "LAFAYETTE",
      state: "NJ"
    },
    {
      city: "LAFAYETTE",
      state: "OH"
    },
    {
      city: "LAFAYETTE",
      state: "OR"
    },
    {
      city: "LAFAYETTE",
      state: "TN"
    },
    {
      city: "LAFAYETTE HILL",
      state: "PA"
    },
    {
      city: "LAFE",
      state: "AR"
    },
    {
      city: "LAFFERTY",
      state: "OH"
    },
    {
      city: "LAFITTE",
      state: "LA"
    },
    {
      city: "LAFOX",
      state: "IL"
    },
    {
      city: "LAGRANGE",
      state: "GA"
    },
    {
      city: "LAGRANGE",
      state: "IN"
    },
    {
      city: "LAGRANGE",
      state: "ME"
    },
    {
      city: "LAGRANGE",
      state: "OH"
    },
    {
      city: "LAGRANGE",
      state: "WY"
    },
    {
      city: "LAGRANGEVILLE",
      state: "NY"
    },
    {
      city: "LAGRO",
      state: "IN"
    },
    {
      city: "LAGUNA",
      state: "NM"
    },
    {
      city: "LAGUNA BEACH",
      state: "CA"
    },
    {
      city: "LAGUNA HILLS",
      state: "CA"
    },
    {
      city: "LAGUNA NIGUEL",
      state: "CA"
    },
    {
      city: "LAGUNA PARK",
      state: "TX"
    },
    {
      city: "LAGUNA WOODS",
      state: "CA"
    },
    {
      city: "LAGUNITAS",
      state: "CA"
    },
    {
      city: "LAHAINA",
      state: "HI"
    },
    {
      city: "LAHASKA",
      state: "PA"
    },
    {
      city: "LAHMANSVILLE",
      state: "WV"
    },
    {
      city: "LAHOMA",
      state: "OK"
    },
    {
      city: "LAIE",
      state: "HI"
    },
    {
      city: "LAINGS",
      state: "OH"
    },
    {
      city: "LAINGSBURG",
      state: "MI"
    },
    {
      city: "LAIRD HILL",
      state: "TX"
    },
    {
      city: "LAIRDSVILLE",
      state: "PA"
    },
    {
      city: "LAKE",
      state: "MI"
    },
    {
      city: "LAKE",
      state: "MS"
    },
    {
      city: "LAKE",
      state: "WV"
    },
    {
      city: "LAKE ALFRED",
      state: "FL"
    },
    {
      city: "LAKE ANDES",
      state: "SD"
    },
    {
      city: "LAKE ANN",
      state: "MI"
    },
    {
      city: "LAKE ARIEL",
      state: "PA"
    },
    {
      city: "LAKE ARROWHEAD",
      state: "CA"
    },
    {
      city: "LAKE ARTHUR",
      state: "LA"
    },
    {
      city: "LAKE ARTHUR",
      state: "NM"
    },
    {
      city: "LAKE BENTON",
      state: "MN"
    },
    {
      city: "LAKE BLUFF",
      state: "IL"
    },
    {
      city: "LAKE BRONSON",
      state: "MN"
    },
    {
      city: "LAKE BUTLER",
      state: "FL"
    },
    {
      city: "LAKE CHARLES",
      state: "LA"
    },
    {
      city: "LAKE CICOTT",
      state: "IN"
    },
    {
      city: "LAKE CITY",
      state: "AR"
    },
    {
      city: "LAKE CITY",
      state: "CA"
    },
    {
      city: "LAKE CITY",
      state: "CO"
    },
    {
      city: "LAKE CITY",
      state: "FL"
    },
    {
      city: "LAKE CITY",
      state: "IA"
    },
    {
      city: "LAKE CITY",
      state: "KS"
    },
    {
      city: "LAKE CITY",
      state: "MI"
    },
    {
      city: "LAKE CITY",
      state: "MN"
    },
    {
      city: "LAKE CITY",
      state: "PA"
    },
    {
      city: "LAKE CITY",
      state: "SC"
    },
    {
      city: "LAKE CITY",
      state: "SD"
    },
    {
      city: "LAKE CITY",
      state: "TN"
    },
    {
      city: "LAKE CLEAR",
      state: "NY"
    },
    {
      city: "LAKE COMO",
      state: "FL"
    },
    {
      city: "LAKE COMO",
      state: "PA"
    },
    {
      city: "LAKE CORMORANT",
      state: "MS"
    },
    {
      city: "LAKE CREEK",
      state: "TX"
    },
    {
      city: "LAKE CRYSTAL",
      state: "MN"
    },
    {
      city: "LAKE DALLAS",
      state: "TX"
    },
    {
      city: "LAKE DELTON",
      state: "WI"
    },
    {
      city: "LAKE ELMO",
      state: "MN"
    },
    {
      city: "LAKE ELMORE",
      state: "VT"
    },
    {
      city: "LAKE ELSINORE",
      state: "CA"
    },
    {
      city: "LAKE FOREST",
      state: "CA"
    },
    {
      city: "LAKE FOREST",
      state: "IL"
    },
    {
      city: "LAKE FORK",
      state: "ID"
    },
    {
      city: "LAKE FORK",
      state: "IL"
    },
    {
      city: "LAKE GENEVA",
      state: "FL"
    },
    {
      city: "LAKE GENEVA",
      state: "WI"
    },
    {
      city: "LAKE GEORGE",
      state: "CO"
    },
    {
      city: "LAKE GEORGE",
      state: "MI"
    },
    {
      city: "LAKE GEORGE",
      state: "MN"
    },
    {
      city: "LAKE GEORGE",
      state: "NY"
    },
    {
      city: "LAKE GROVE",
      state: "NY"
    },
    {
      city: "LAKE HAMILTON",
      state: "FL"
    },
    {
      city: "LAKE HARBOR",
      state: "FL"
    },
    {
      city: "LAKE HARMONY",
      state: "PA"
    },
    {
      city: "LAKE HAVASU CITY",
      state: "AZ"
    },
    {
      city: "LAKE HELEN",
      state: "FL"
    },
    {
      city: "LAKE HIAWATHA",
      state: "NJ"
    },
    {
      city: "LAKE HILL",
      state: "NY"
    },
    {
      city: "LAKE HOPATCONG",
      state: "NJ"
    },
    {
      city: "LAKE HUBERT",
      state: "MN"
    },
    {
      city: "LAKE HUGHES",
      state: "CA"
    },
    {
      city: "LAKE HUNTINGTON",
      state: "NY"
    },
    {
      city: "LAKE IN THE HILLS",
      state: "IL"
    },
    {
      city: "LAKE ISABELLA",
      state: "CA"
    },
    {
      city: "LAKE JACKSON",
      state: "TX"
    },
    {
      city: "LAKE JUNALUSKA",
      state: "NC"
    },
    {
      city: "LAKE KATRINE",
      state: "NY"
    },
    {
      city: "LAKE LEELANAU",
      state: "MI"
    },
    {
      city: "LAKE LILLIAN",
      state: "MN"
    },
    {
      city: "LAKE LINDEN",
      state: "MI"
    },
    {
      city: "LAKE LURE",
      state: "NC"
    },
    {
      city: "LAKE LUZERNE",
      state: "NY"
    },
    {
      city: "LAKE LYNN",
      state: "PA"
    },
    {
      city: "LAKE MARY",
      state: "FL"
    },
    {
      city: "LAKE MILLS",
      state: "IA"
    },
    {
      city: "LAKE MILLS",
      state: "WI"
    },
    {
      city: "LAKE MILTON",
      state: "OH"
    },
    {
      city: "LAKE MONROE",
      state: "FL"
    },
    {
      city: "LAKE MONTEZUMA",
      state: "AZ"
    },
    {
      city: "LAKE NEBAGAMON",
      state: "WI"
    },
    {
      city: "LAKE NORDEN",
      state: "SD"
    },
    {
      city: "LAKE ODESSA",
      state: "MI"
    },
    {
      city: "LAKE ORION",
      state: "MI"
    },
    {
      city: "LAKE OSWEGO",
      state: "OR"
    },
    {
      city: "LAKE OZARK",
      state: "MO"
    },
    {
      city: "LAKE PANASOFFKEE",
      state: "FL"
    },
    {
      city: "LAKE PARK",
      state: "GA"
    },
    {
      city: "LAKE PARK",
      state: "IA"
    },
    {
      city: "LAKE PARK",
      state: "MN"
    },
    {
      city: "LAKE PEEKSKILL",
      state: "NY"
    },
    {
      city: "LAKE PLACID",
      state: "FL"
    },
    {
      city: "LAKE PLACID",
      state: "NY"
    },
    {
      city: "LAKE PLEASANT",
      state: "MA"
    },
    {
      city: "LAKE PLEASANT",
      state: "NY"
    },
    {
      city: "LAKE POWELL",
      state: "UT"
    },
    {
      city: "LAKE PRESTON",
      state: "SD"
    },
    {
      city: "LAKE PROVIDENCE",
      state: "LA"
    },
    {
      city: "LAKE SAINT LOUIS",
      state: "MO"
    },
    {
      city: "LAKE SPRING",
      state: "MO"
    },
    {
      city: "LAKE STATION",
      state: "IN"
    },
    {
      city: "LAKE STEVENS",
      state: "WA"
    },
    {
      city: "LAKE TOMAHAWK",
      state: "WI"
    },
    {
      city: "LAKE TOXAWAY",
      state: "NC"
    },
    {
      city: "LAKE VIEW",
      state: "IA"
    },
    {
      city: "LAKE VIEW",
      state: "NY"
    },
    {
      city: "LAKE VIEW",
      state: "SC"
    },
    {
      city: "LAKE VILLA",
      state: "IL"
    },
    {
      city: "LAKE VILLAGE",
      state: "AR"
    },
    {
      city: "LAKE VILLAGE",
      state: "IN"
    },
    {
      city: "LAKE WACCAMAW",
      state: "NC"
    },
    {
      city: "LAKE WALES",
      state: "FL"
    },
    {
      city: "LAKE WILSON",
      state: "MN"
    },
    {
      city: "LAKE WINOLA",
      state: "PA"
    },
    {
      city: "LAKE WORTH",
      state: "FL"
    },
    {
      city: "LAKE ZURICH",
      state: "IL"
    },
    {
      city: "LAKEBAY",
      state: "WA"
    },
    {
      city: "LAKEFIELD",
      state: "MN"
    },
    {
      city: "LAKEHEAD",
      state: "CA"
    },
    {
      city: "LAKEHURST",
      state: "NJ"
    },
    {
      city: "LAKELAND",
      state: "FL"
    },
    {
      city: "LAKELAND",
      state: "GA"
    },
    {
      city: "LAKELAND",
      state: "LA"
    },
    {
      city: "LAKELAND",
      state: "MI"
    },
    {
      city: "LAKELAND",
      state: "MN"
    },
    {
      city: "LAKEMONT",
      state: "GA"
    },
    {
      city: "LAKEMONT",
      state: "NY"
    },
    {
      city: "LAKEMORE",
      state: "OH"
    },
    {
      city: "LAKEPORT",
      state: "CA"
    },
    {
      city: "LAKESHORE",
      state: "CA"
    },
    {
      city: "LAKESHORE",
      state: "FL"
    },
    {
      city: "LAKESHORE",
      state: "MS"
    },
    {
      city: "LAKESIDE",
      state: "AZ"
    },
    {
      city: "LAKESIDE",
      state: "CA"
    },
    {
      city: "LAKESIDE",
      state: "CT"
    },
    {
      city: "LAKESIDE",
      state: "MI"
    },
    {
      city: "LAKESIDE",
      state: "MT"
    },
    {
      city: "LAKESIDE",
      state: "NE"
    },
    {
      city: "LAKESIDE",
      state: "OR"
    },
    {
      city: "LAKESIDE MARBLEHEAD",
      state: "OH"
    },
    {
      city: "LAKETON",
      state: "IN"
    },
    {
      city: "LAKETOWN",
      state: "UT"
    },
    {
      city: "LAKEVIEW",
      state: "AR"
    },
    {
      city: "LAKEVIEW",
      state: "MI"
    },
    {
      city: "LAKEVIEW",
      state: "NC"
    },
    {
      city: "LAKEVIEW",
      state: "OH"
    },
    {
      city: "LAKEVIEW",
      state: "OR"
    },
    {
      city: "LAKEVIEW",
      state: "TX"
    },
    {
      city: "LAKEVILLE",
      state: "CT"
    },
    {
      city: "LAKEVILLE",
      state: "IN"
    },
    {
      city: "LAKEVILLE",
      state: "MA"
    },
    {
      city: "LAKEVILLE",
      state: "MI"
    },
    {
      city: "LAKEVILLE",
      state: "MN"
    },
    {
      city: "LAKEVILLE",
      state: "NY"
    },
    {
      city: "LAKEVILLE",
      state: "OH"
    },
    {
      city: "LAKEVILLE",
      state: "PA"
    },
    {
      city: "LAKEWOOD",
      state: "CA"
    },
    {
      city: "LAKEWOOD",
      state: "IL"
    },
    {
      city: "LAKEWOOD",
      state: "NJ"
    },
    {
      city: "LAKEWOOD",
      state: "NM"
    },
    {
      city: "LAKEWOOD",
      state: "NY"
    },
    {
      city: "LAKEWOOD",
      state: "OH"
    },
    {
      city: "LAKEWOOD",
      state: "PA"
    },
    {
      city: "LAKEWOOD",
      state: "WA"
    },
    {
      city: "LAKEWOOD",
      state: "WI"
    },
    {
      city: "LAKIN",
      state: "KS"
    },
    {
      city: "LAKOTA",
      state: "IA"
    },
    {
      city: "LAKOTA",
      state: "ND"
    },
    {
      city: "LAMAR",
      state: "AR"
    },
    {
      city: "LAMAR",
      state: "CO"
    },
    {
      city: "LAMAR",
      state: "IN"
    },
    {
      city: "LAMAR",
      state: "MO"
    },
    {
      city: "LAMAR",
      state: "MS"
    },
    {
      city: "LAMAR",
      state: "OK"
    },
    {
      city: "LAMAR",
      state: "PA"
    },
    {
      city: "LAMAR",
      state: "SC"
    },
    {
      city: "LAMARTINE",
      state: "PA"
    },
    {
      city: "LAMBERT",
      state: "MS"
    },
    {
      city: "LAMBERT",
      state: "MT"
    },
    {
      city: "LAMBERTON",
      state: "MN"
    },
    {
      city: "LAMBERTVILLE",
      state: "MI"
    },
    {
      city: "LAMBERTVILLE",
      state: "NJ"
    },
    {
      city: "LAMBSBURG",
      state: "VA"
    },
    {
      city: "LAME DEER",
      state: "MT"
    },
    {
      city: "LAMESA",
      state: "TX"
    },
    {
      city: "LAMOILLE",
      state: "NV"
    },
    {
      city: "LAMONA",
      state: "WA"
    },
    {
      city: "LAMONI",
      state: "IA"
    },
    {
      city: "LAMONT",
      state: "CA"
    },
    {
      city: "LAMONT",
      state: "FL"
    },
    {
      city: "LAMONT",
      state: "IA"
    },
    {
      city: "LAMONT",
      state: "KS"
    },
    {
      city: "LAMONT",
      state: "MI"
    },
    {
      city: "LAMONT",
      state: "OK"
    },
    {
      city: "LAMONT",
      state: "WA"
    },
    {
      city: "LAMOURE",
      state: "ND"
    },
    {
      city: "LAMPASAS",
      state: "TX"
    },
    {
      city: "LAMPE",
      state: "MO"
    },
    {
      city: "LAMPETER",
      state: "PA"
    },
    {
      city: "LAMY",
      state: "NM"
    },
    {
      city: "LANAGAN",
      state: "MO"
    },
    {
      city: "LANAI CITY",
      state: "HI"
    },
    {
      city: "LANARK",
      state: "IL"
    },
    {
      city: "LANARK",
      state: "WV"
    },
    {
      city: "LANARK VILLAGE",
      state: "FL"
    },
    {
      city: "LANCASTER",
      state: "CA"
    },
    {
      city: "LANCASTER",
      state: "KS"
    },
    {
      city: "LANCASTER",
      state: "KY"
    },
    {
      city: "LANCASTER",
      state: "MA"
    },
    {
      city: "LANCASTER",
      state: "MN"
    },
    {
      city: "LANCASTER",
      state: "MO"
    },
    {
      city: "LANCASTER",
      state: "NH"
    },
    {
      city: "LANCASTER",
      state: "NY"
    },
    {
      city: "LANCASTER",
      state: "OH"
    },
    {
      city: "LANCASTER",
      state: "PA"
    },
    {
      city: "LANCASTER",
      state: "SC"
    },
    {
      city: "LANCASTER",
      state: "TN"
    },
    {
      city: "LANCASTER",
      state: "TX"
    },
    {
      city: "LANCASTER",
      state: "VA"
    },
    {
      city: "LANCASTER",
      state: "WI"
    },
    {
      city: "LANCE CREEK",
      state: "WY"
    },
    {
      city: "LANCING",
      state: "TN"
    },
    {
      city: "LAND O LAKES",
      state: "FL"
    },
    {
      city: "LAND O LAKES",
      state: "WI"
    },
    {
      city: "LANDENBERG",
      state: "PA"
    },
    {
      city: "LANDER",
      state: "WY"
    },
    {
      city: "LANDERS",
      state: "CA"
    },
    {
      city: "LANDING",
      state: "NJ"
    },
    {
      city: "LANDIS",
      state: "NC"
    },
    {
      city: "LANDISBURG",
      state: "PA"
    },
    {
      city: "LANDISVILLE",
      state: "NJ"
    },
    {
      city: "LANDISVILLE",
      state: "PA"
    },
    {
      city: "LANDRUM",
      state: "SC"
    },
    {
      city: "LANE",
      state: "IL"
    },
    {
      city: "LANE",
      state: "KS"
    },
    {
      city: "LANE",
      state: "OK"
    },
    {
      city: "LANE",
      state: "SC"
    },
    {
      city: "LANE",
      state: "SD"
    },
    {
      city: "LANE CITY",
      state: "TX"
    },
    {
      city: "LANESBORO",
      state: "IA"
    },
    {
      city: "LANESBORO",
      state: "MA"
    },
    {
      city: "LANESBORO",
      state: "MN"
    },
    {
      city: "LANESBORO",
      state: "PA"
    },
    {
      city: "LANESVILLE",
      state: "IN"
    },
    {
      city: "LANESVILLE",
      state: "NY"
    },
    {
      city: "LANETT",
      state: "AL"
    },
    {
      city: "LANEVILLE",
      state: "TX"
    },
    {
      city: "LANEXA",
      state: "VA"
    },
    {
      city: "LANGDON",
      state: "ND"
    },
    {
      city: "LANGELOTH",
      state: "PA"
    },
    {
      city: "LANGFORD",
      state: "SD"
    },
    {
      city: "LANGHORNE",
      state: "PA"
    },
    {
      city: "LANGLEY",
      state: "KY"
    },
    {
      city: "LANGLEY",
      state: "OK"
    },
    {
      city: "LANGLEY",
      state: "SC"
    },
    {
      city: "LANGLEY",
      state: "WA"
    },
    {
      city: "LANGLOIS",
      state: "OR"
    },
    {
      city: "LANGSTON",
      state: "AL"
    },
    {
      city: "LANGSTON",
      state: "OK"
    },
    {
      city: "LANGSVILLE",
      state: "OH"
    },
    {
      city: "LANHAM",
      state: "MD"
    },
    {
      city: "LANKIN",
      state: "ND"
    },
    {
      city: "LANNON",
      state: "WI"
    },
    {
      city: "LANOKA HARBOR",
      state: "NJ"
    },
    {
      city: "LANSDALE",
      state: "PA"
    },
    {
      city: "LANSDOWNE",
      state: "PA"
    },
    {
      city: "LANSE",
      state: "MI"
    },
    {
      city: "LANSE",
      state: "PA"
    },
    {
      city: "LANSFORD",
      state: "ND"
    },
    {
      city: "LANSFORD",
      state: "PA"
    },
    {
      city: "LANSING",
      state: "IA"
    },
    {
      city: "LANSING",
      state: "IL"
    },
    {
      city: "LANSING",
      state: "KS"
    },
    {
      city: "LANSING",
      state: "MI"
    },
    {
      city: "LANSING",
      state: "MN"
    },
    {
      city: "LANSING",
      state: "NC"
    },
    {
      city: "LANSING",
      state: "NY"
    },
    {
      city: "LANSING",
      state: "OH"
    },
    {
      city: "LANSING",
      state: "WV"
    },
    {
      city: "LAONA",
      state: "WI"
    },
    {
      city: "LAOTTO",
      state: "IN"
    },
    {
      city: "LAPAZ",
      state: "IN"
    },
    {
      city: "LAPEER",
      state: "MI"
    },
    {
      city: "LAPEL",
      state: "IN"
    },
    {
      city: "LAPINE",
      state: "AL"
    },
    {
      city: "LAPOINT",
      state: "UT"
    },
    {
      city: "LAPORTE",
      state: "CO"
    },
    {
      city: "LAPORTE",
      state: "MN"
    },
    {
      city: "LAPORTE",
      state: "PA"
    },
    {
      city: "LAPWAI",
      state: "ID"
    },
    {
      city: "LAQUEY",
      state: "MO"
    },
    {
      city: "LARAMIE",
      state: "WY"
    },
    {
      city: "LARCHMONT",
      state: "NY"
    },
    {
      city: "LARCHWOOD",
      state: "IA"
    },
    {
      city: "LAREDO",
      state: "MO"
    },
    {
      city: "LAREDO",
      state: "TX"
    },
    {
      city: "LARGO",
      state: "FL"
    },
    {
      city: "LARIMER",
      state: "PA"
    },
    {
      city: "LARIMORE",
      state: "ND"
    },
    {
      city: "LARKSPUR",
      state: "CA"
    },
    {
      city: "LARKSPUR",
      state: "CO"
    },
    {
      city: "LARNED",
      state: "KS"
    },
    {
      city: "LAROSE",
      state: "LA"
    },
    {
      city: "LARRABEE",
      state: "IA"
    },
    {
      city: "LARSEN",
      state: "WI"
    },
    {
      city: "LARSEN BAY",
      state: "AK"
    },
    {
      city: "LARSLAN",
      state: "MT"
    },
    {
      city: "LARUE",
      state: "TX"
    },
    {
      city: "LARWILL",
      state: "IN"
    },
    {
      city: "LAS ANIMAS",
      state: "CO"
    },
    {
      city: "LAS CRUCES",
      state: "NM"
    },
    {
      city: "LAS VEGAS",
      state: "NM"
    },
    {
      city: "LAS VEGAS",
      state: "NV"
    },
    {
      city: "LASARA",
      state: "TX"
    },
    {
      city: "LASCASSAS",
      state: "TN"
    },
    {
      city: "LASHMEET",
      state: "WV"
    },
    {
      city: "LASTRUP",
      state: "MN"
    },
    {
      city: "LATAH",
      state: "WA"
    },
    {
      city: "LATEXO",
      state: "TX"
    },
    {
      city: "LATHAM",
      state: "IL"
    },
    {
      city: "LATHAM",
      state: "KS"
    },
    {
      city: "LATHAM",
      state: "MO"
    },
    {
      city: "LATHAM",
      state: "NY"
    },
    {
      city: "LATHAM",
      state: "OH"
    },
    {
      city: "LATHROP",
      state: "CA"
    },
    {
      city: "LATHROP",
      state: "MO"
    },
    {
      city: "LATIMER",
      state: "IA"
    },
    {
      city: "LATON",
      state: "CA"
    },
    {
      city: "LATONIA",
      state: "KY"
    },
    {
      city: "LATROBE",
      state: "PA"
    },
    {
      city: "LATTA",
      state: "SC"
    },
    {
      city: "LATTIMER MINES",
      state: "PA"
    },
    {
      city: "LATTIMORE",
      state: "NC"
    },
    {
      city: "LATTY",
      state: "OH"
    },
    {
      city: "LAUDERDALE",
      state: "MS"
    },
    {
      city: "LAUGHLIN",
      state: "NV"
    },
    {
      city: "LAUGHLIN AFB",
      state: "TX"
    },
    {
      city: "LAUGHLINTOWN",
      state: "PA"
    },
    {
      city: "LAUPAHOEHOE",
      state: "HI"
    },
    {
      city: "LAURA",
      state: "IL"
    },
    {
      city: "LAURA",
      state: "OH"
    },
    {
      city: "LAUREL",
      state: "DE"
    },
    {
      city: "LAUREL",
      state: "FL"
    },
    {
      city: "LAUREL",
      state: "IA"
    },
    {
      city: "LAUREL",
      state: "IN"
    },
    {
      city: "LAUREL",
      state: "MD"
    },
    {
      city: "LAUREL",
      state: "MS"
    },
    {
      city: "LAUREL",
      state: "MT"
    },
    {
      city: "LAUREL",
      state: "NE"
    },
    {
      city: "LAUREL",
      state: "NY"
    },
    {
      city: "LAUREL BLOOMERY",
      state: "TN"
    },
    {
      city: "LAUREL FORK",
      state: "VA"
    },
    {
      city: "LAUREL HILL",
      state: "FL"
    },
    {
      city: "LAUREL HILL",
      state: "NC"
    },
    {
      city: "LAUREL SPRINGS",
      state: "NC"
    },
    {
      city: "LAURELTON",
      state: "PA"
    },
    {
      city: "LAURELVILLE",
      state: "OH"
    },
    {
      city: "LAURENS",
      state: "IA"
    },
    {
      city: "LAURENS",
      state: "NY"
    },
    {
      city: "LAURENS",
      state: "SC"
    },
    {
      city: "LAURIE",
      state: "MO"
    },
    {
      city: "LAURINBURG",
      state: "NC"
    },
    {
      city: "LAURYS STATION",
      state: "PA"
    },
    {
      city: "LAVA HOT SPRINGS",
      state: "ID"
    },
    {
      city: "LAVACA",
      state: "AR"
    },
    {
      city: "LAVALETTE",
      state: "WV"
    },
    {
      city: "LAVALLETTE",
      state: "NJ"
    },
    {
      city: "LAVEEN",
      state: "AZ"
    },
    {
      city: "LAVELLE",
      state: "PA"
    },
    {
      city: "LAVERNE",
      state: "OK"
    },
    {
      city: "LAVINA",
      state: "MT"
    },
    {
      city: "LAVINIA",
      state: "TN"
    },
    {
      city: "LAVON",
      state: "TX"
    },
    {
      city: "LAVONIA",
      state: "GA"
    },
    {
      city: "LAWAI",
      state: "HI"
    },
    {
      city: "LAWLER",
      state: "IA"
    },
    {
      city: "LAWLEY",
      state: "AL"
    },
    {
      city: "LAWN",
      state: "PA"
    },
    {
      city: "LAWN",
      state: "TX"
    },
    {
      city: "LAWNDALE",
      state: "CA"
    },
    {
      city: "LAWNDALE",
      state: "IL"
    },
    {
      city: "LAWNDALE",
      state: "NC"
    },
    {
      city: "LAWNSIDE",
      state: "NJ"
    },
    {
      city: "LAWRENCE",
      state: "KS"
    },
    {
      city: "LAWRENCE",
      state: "MA"
    },
    {
      city: "LAWRENCE",
      state: "MI"
    },
    {
      city: "LAWRENCE",
      state: "MS"
    },
    {
      city: "LAWRENCE",
      state: "NE"
    },
    {
      city: "LAWRENCE",
      state: "NY"
    },
    {
      city: "LAWRENCE",
      state: "PA"
    },
    {
      city: "LAWRENCE TOWNSHIP",
      state: "NJ"
    },
    {
      city: "LAWRENCEBURG",
      state: "IN"
    },
    {
      city: "LAWRENCEBURG",
      state: "KY"
    },
    {
      city: "LAWRENCEBURG",
      state: "TN"
    },
    {
      city: "LAWRENCEVILLE",
      state: "GA"
    },
    {
      city: "LAWRENCEVILLE",
      state: "IL"
    },
    {
      city: "LAWRENCEVILLE",
      state: "NY"
    },
    {
      city: "LAWRENCEVILLE",
      state: "PA"
    },
    {
      city: "LAWRENCEVILLE",
      state: "VA"
    },
    {
      city: "LAWSON",
      state: "AR"
    },
    {
      city: "LAWSON",
      state: "MO"
    },
    {
      city: "LAWSONVILLE",
      state: "NC"
    },
    {
      city: "LAWTELL",
      state: "LA"
    },
    {
      city: "LAWTEY",
      state: "FL"
    },
    {
      city: "LAWTON",
      state: "IA"
    },
    {
      city: "LAWTON",
      state: "MI"
    },
    {
      city: "LAWTON",
      state: "ND"
    },
    {
      city: "LAWTON",
      state: "OK"
    },
    {
      city: "LAWTON",
      state: "PA"
    },
    {
      city: "LAWTONS",
      state: "NY"
    },
    {
      city: "LAYLAND",
      state: "WV"
    },
    {
      city: "LAYTON",
      state: "NJ"
    },
    {
      city: "LAYTON",
      state: "UT"
    },
    {
      city: "LAYTONVILLE",
      state: "CA"
    },
    {
      city: "LAZBUDDIE",
      state: "TX"
    },
    {
      city: "LAZEAR",
      state: "CO"
    },
    {
      city: "LE CENTER",
      state: "MN"
    },
    {
      city: "LE CLAIRE",
      state: "IA"
    },
    {
      city: "LE GRAND",
      state: "CA"
    },
    {
      city: "LE GRAND",
      state: "IA"
    },
    {
      city: "LE MARS",
      state: "IA"
    },
    {
      city: "LE RAYSVILLE",
      state: "PA"
    },
    {
      city: "LE ROY",
      state: "IL"
    },
    {
      city: "LE ROY",
      state: "KS"
    },
    {
      city: "LE ROY",
      state: "MN"
    },
    {
      city: "LE ROY",
      state: "NY"
    },
    {
      city: "LE ROY",
      state: "WV"
    },
    {
      city: "LE SUEUR",
      state: "MN"
    },
    {
      city: "LEACHVILLE",
      state: "AR"
    },
    {
      city: "LEAD",
      state: "SD"
    },
    {
      city: "LEAD HILL",
      state: "AR"
    },
    {
      city: "LEADORE",
      state: "ID"
    },
    {
      city: "LEADVILLE",
      state: "CO"
    },
    {
      city: "LEADWOOD",
      state: "MO"
    },
    {
      city: "LEAF RIVER",
      state: "IL"
    },
    {
      city: "LEAGUE CITY",
      state: "TX"
    },
    {
      city: "LEAKESVILLE",
      state: "MS"
    },
    {
      city: "LEAKEY",
      state: "TX"
    },
    {
      city: "LEAMINGTON",
      state: "UT"
    },
    {
      city: "LEANDER",
      state: "TX"
    },
    {
      city: "LEARY",
      state: "GA"
    },
    {
      city: "LEASBURG",
      state: "MO"
    },
    {
      city: "LEASBURG",
      state: "NC"
    },
    {
      city: "LEAVENWORTH",
      state: "IN"
    },
    {
      city: "LEAVENWORTH",
      state: "KS"
    },
    {
      city: "LEAVENWORTH",
      state: "WA"
    },
    {
      city: "LEAVITTSBURG",
      state: "OH"
    },
    {
      city: "LEAWOOD",
      state: "KS"
    },
    {
      city: "LEBAM",
      state: "WA"
    },
    {
      city: "LEBANON",
      state: "CT"
    },
    {
      city: "LEBANON",
      state: "GA"
    },
    {
      city: "LEBANON",
      state: "IL"
    },
    {
      city: "LEBANON",
      state: "IN"
    },
    {
      city: "LEBANON",
      state: "KS"
    },
    {
      city: "LEBANON",
      state: "KY"
    },
    {
      city: "LEBANON",
      state: "ME"
    },
    {
      city: "LEBANON",
      state: "MO"
    },
    {
      city: "LEBANON",
      state: "NE"
    },
    {
      city: "LEBANON",
      state: "NH"
    },
    {
      city: "LEBANON",
      state: "NJ"
    },
    {
      city: "LEBANON",
      state: "OH"
    },
    {
      city: "LEBANON",
      state: "OK"
    },
    {
      city: "LEBANON",
      state: "OR"
    },
    {
      city: "LEBANON",
      state: "PA"
    },
    {
      city: "LEBANON",
      state: "SD"
    },
    {
      city: "LEBANON",
      state: "TN"
    },
    {
      city: "LEBANON",
      state: "VA"
    },
    {
      city: "LEBANON",
      state: "WI"
    },
    {
      city: "LEBANON JUNCTION",
      state: "KY"
    },
    {
      city: "LEBEAU",
      state: "LA"
    },
    {
      city: "LEBEC",
      state: "CA"
    },
    {
      city: "LEBLANC",
      state: "LA"
    },
    {
      city: "LEBO",
      state: "KS"
    },
    {
      city: "LEBURN",
      state: "KY"
    },
    {
      city: "LECANTO",
      state: "FL"
    },
    {
      city: "LECK KILL",
      state: "PA"
    },
    {
      city: "LECKRONE",
      state: "PA"
    },
    {
      city: "LECOMPTE",
      state: "LA"
    },
    {
      city: "LECOMPTON",
      state: "KS"
    },
    {
      city: "LEDBETTER",
      state: "KY"
    },
    {
      city: "LEDBETTER",
      state: "TX"
    },
    {
      city: "LEDERACH",
      state: "PA"
    },
    {
      city: "LEDGEWOOD",
      state: "NJ"
    },
    {
      city: "LEDYARD",
      state: "CT"
    },
    {
      city: "LEDYARD",
      state: "IA"
    },
    {
      city: "LEE",
      state: "FL"
    },
    {
      city: "LEE",
      state: "IL"
    },
    {
      city: "LEE",
      state: "MA"
    },
    {
      city: "LEE",
      state: "ME"
    },
    {
      city: "LEE",
      state: "NH"
    },
    {
      city: "LEE CENTER",
      state: "NY"
    },
    {
      city: "LEE VINING",
      state: "CA"
    },
    {
      city: "LEECHBURG",
      state: "PA"
    },
    {
      city: "LEEDEY",
      state: "OK"
    },
    {
      city: "LEEDS",
      state: "AL"
    },
    {
      city: "LEEDS",
      state: "MA"
    },
    {
      city: "LEEDS",
      state: "ME"
    },
    {
      city: "LEEDS",
      state: "ND"
    },
    {
      city: "LEEDS",
      state: "NY"
    },
    {
      city: "LEEDS",
      state: "UT"
    },
    {
      city: "LEEDS POINT",
      state: "NJ"
    },
    {
      city: "LEEPER",
      state: "PA"
    },
    {
      city: "LEES SUMMIT",
      state: "MO"
    },
    {
      city: "LEESBURG",
      state: "AL"
    },
    {
      city: "LEESBURG",
      state: "FL"
    },
    {
      city: "LEESBURG",
      state: "GA"
    },
    {
      city: "LEESBURG",
      state: "IN"
    },
    {
      city: "LEESBURG",
      state: "NJ"
    },
    {
      city: "LEESBURG",
      state: "OH"
    },
    {
      city: "LEESBURG",
      state: "TX"
    },
    {
      city: "LEESBURG",
      state: "VA"
    },
    {
      city: "LEESPORT",
      state: "PA"
    },
    {
      city: "LEESVILLE",
      state: "LA"
    },
    {
      city: "LEESVILLE",
      state: "OH"
    },
    {
      city: "LEESVILLE",
      state: "SC"
    },
    {
      city: "LEESVILLE",
      state: "TX"
    },
    {
      city: "LEETON",
      state: "MO"
    },
    {
      city: "LEETONIA",
      state: "OH"
    },
    {
      city: "LEETSDALE",
      state: "PA"
    },
    {
      city: "LEFLORE",
      state: "OK"
    },
    {
      city: "LEFOR",
      state: "ND"
    },
    {
      city: "LEFORS",
      state: "TX"
    },
    {
      city: "LEFT HAND",
      state: "WV"
    },
    {
      city: "LEGGETT",
      state: "CA"
    },
    {
      city: "LEGGETT",
      state: "TX"
    },
    {
      city: "LEHI",
      state: "UT"
    },
    {
      city: "LEHIGH",
      state: "IA"
    },
    {
      city: "LEHIGH",
      state: "KS"
    },
    {
      city: "LEHIGH",
      state: "OK"
    },
    {
      city: "LEHIGH ACRES",
      state: "FL"
    },
    {
      city: "LEHIGH VALLEY",
      state: "PA"
    },
    {
      city: "LEHIGHTON",
      state: "PA"
    },
    {
      city: "LEHMAN",
      state: "PA"
    },
    {
      city: "LEHR",
      state: "ND"
    },
    {
      city: "LEICESTER",
      state: "MA"
    },
    {
      city: "LEICESTER",
      state: "NC"
    },
    {
      city: "LEICESTER",
      state: "NY"
    },
    {
      city: "LEIGH",
      state: "NE"
    },
    {
      city: "LEIGHTON",
      state: "AL"
    },
    {
      city: "LEIGHTON",
      state: "IA"
    },
    {
      city: "LEIPSIC",
      state: "OH"
    },
    {
      city: "LEISENRING",
      state: "PA"
    },
    {
      city: "LEITCHFIELD",
      state: "KY"
    },
    {
      city: "LEITERS FORD",
      state: "IN"
    },
    {
      city: "LEIVASY",
      state: "WV"
    },
    {
      city: "LELAND",
      state: "IA"
    },
    {
      city: "LELAND",
      state: "IL"
    },
    {
      city: "LELAND",
      state: "MI"
    },
    {
      city: "LELAND",
      state: "MS"
    },
    {
      city: "LELAND",
      state: "NC"
    },
    {
      city: "LELIA LAKE",
      state: "TX"
    },
    {
      city: "LEMASTERS",
      state: "PA"
    },
    {
      city: "LEMHI",
      state: "ID"
    },
    {
      city: "LEMING",
      state: "TX"
    },
    {
      city: "LEMITAR",
      state: "NM"
    },
    {
      city: "LEMMON",
      state: "SD"
    },
    {
      city: "LEMON COVE",
      state: "CA"
    },
    {
      city: "LEMON GROVE",
      state: "CA"
    },
    {
      city: "LEMON SPRINGS",
      state: "NC"
    },
    {
      city: "LEMONT",
      state: "IL"
    },
    {
      city: "LEMONT",
      state: "PA"
    },
    {
      city: "LEMONT FURNACE",
      state: "PA"
    },
    {
      city: "LEMOORE",
      state: "CA"
    },
    {
      city: "LEMOYNE",
      state: "NE"
    },
    {
      city: "LEMOYNE",
      state: "OH"
    },
    {
      city: "LEMOYNE",
      state: "PA"
    },
    {
      city: "LEMPSTER",
      state: "NH"
    },
    {
      city: "LENA",
      state: "IL"
    },
    {
      city: "LENA",
      state: "LA"
    },
    {
      city: "LENA",
      state: "MS"
    },
    {
      city: "LENA",
      state: "WI"
    },
    {
      city: "LENAPAH",
      state: "OK"
    },
    {
      city: "LENEXA",
      state: "KS"
    },
    {
      city: "LENGBY",
      state: "MN"
    },
    {
      city: "LENHARTSVILLE",
      state: "PA"
    },
    {
      city: "LENNI",
      state: "PA"
    },
    {
      city: "LENNON",
      state: "MI"
    },
    {
      city: "LENNOX",
      state: "SD"
    },
    {
      city: "LENOIR",
      state: "NC"
    },
    {
      city: "LENOIR CITY",
      state: "TN"
    },
    {
      city: "LENORA",
      state: "KS"
    },
    {
      city: "LENORAH",
      state: "TX"
    },
    {
      city: "LENORE",
      state: "ID"
    },
    {
      city: "LENORE",
      state: "WV"
    },
    {
      city: "LENOX",
      state: "AL"
    },
    {
      city: "LENOX",
      state: "GA"
    },
    {
      city: "LENOX",
      state: "IA"
    },
    {
      city: "LENOX",
      state: "MA"
    },
    {
      city: "LENOX",
      state: "MO"
    },
    {
      city: "LENOX",
      state: "TN"
    },
    {
      city: "LENOX DALE",
      state: "MA"
    },
    {
      city: "LENOXVILLE",
      state: "PA"
    },
    {
      city: "LENZBURG",
      state: "IL"
    },
    {
      city: "LEO",
      state: "IN"
    },
    {
      city: "LEOLA",
      state: "AR"
    },
    {
      city: "LEOLA",
      state: "PA"
    },
    {
      city: "LEOLA",
      state: "SD"
    },
    {
      city: "LEOMA",
      state: "TN"
    },
    {
      city: "LEOMINSTER",
      state: "MA"
    },
    {
      city: "LEON",
      state: "IA"
    },
    {
      city: "LEON",
      state: "KS"
    },
    {
      city: "LEON",
      state: "NY"
    },
    {
      city: "LEON",
      state: "VA"
    },
    {
      city: "LEON",
      state: "WV"
    },
    {
      city: "LEONA",
      state: "TX"
    },
    {
      city: "LEONARD",
      state: "MI"
    },
    {
      city: "LEONARD",
      state: "MN"
    },
    {
      city: "LEONARD",
      state: "MO"
    },
    {
      city: "LEONARD",
      state: "ND"
    },
    {
      city: "LEONARD",
      state: "OK"
    },
    {
      city: "LEONARD",
      state: "TX"
    },
    {
      city: "LEONARDO",
      state: "NJ"
    },
    {
      city: "LEONARDSVILLE",
      state: "NY"
    },
    {
      city: "LEONARDTOWN",
      state: "MD"
    },
    {
      city: "LEONARDVILLE",
      state: "KS"
    },
    {
      city: "LEONIA",
      state: "NJ"
    },
    {
      city: "LEONIDAS",
      state: "MI"
    },
    {
      city: "LEONORE",
      state: "IL"
    },
    {
      city: "LEONVILLE",
      state: "LA"
    },
    {
      city: "LEOPOLD",
      state: "IN"
    },
    {
      city: "LEOPOLD",
      state: "MO"
    },
    {
      city: "LEOPOLIS",
      state: "WI"
    },
    {
      city: "LEOTA",
      state: "MN"
    },
    {
      city: "LEOTI",
      state: "KS"
    },
    {
      city: "LEPANTO",
      state: "AR"
    },
    {
      city: "LEQUIRE",
      state: "OK"
    },
    {
      city: "LERNA",
      state: "IL"
    },
    {
      city: "LERONA",
      state: "WV"
    },
    {
      city: "LEROY",
      state: "AL"
    },
    {
      city: "LEROY",
      state: "IN"
    },
    {
      city: "LEROY",
      state: "MI"
    },
    {
      city: "LEROY",
      state: "TX"
    },
    {
      city: "LESAGE",
      state: "WV"
    },
    {
      city: "LESLIE",
      state: "AR"
    },
    {
      city: "LESLIE",
      state: "GA"
    },
    {
      city: "LESLIE",
      state: "MI"
    },
    {
      city: "LESLIE",
      state: "MO"
    },
    {
      city: "LESTER",
      state: "AL"
    },
    {
      city: "LESTER",
      state: "IA"
    },
    {
      city: "LESTER",
      state: "WV"
    },
    {
      city: "LESTER PRAIRIE",
      state: "MN"
    },
    {
      city: "LESTERVILLE",
      state: "MO"
    },
    {
      city: "LESTERVILLE",
      state: "SD"
    },
    {
      city: "LETART",
      state: "WV"
    },
    {
      city: "LETCHER",
      state: "SD"
    },
    {
      city: "LETHA",
      state: "ID"
    },
    {
      city: "LETOHATCHEE",
      state: "AL"
    },
    {
      city: "LETTS",
      state: "IA"
    },
    {
      city: "LETTSWORTH",
      state: "LA"
    },
    {
      city: "LEUPP",
      state: "AZ"
    },
    {
      city: "LEVAN",
      state: "UT"
    },
    {
      city: "LEVANT",
      state: "KS"
    },
    {
      city: "LEVANT",
      state: "ME"
    },
    {
      city: "LEVASY",
      state: "MO"
    },
    {
      city: "LEVELLAND",
      state: "TX"
    },
    {
      city: "LEVELOCK",
      state: "AK"
    },
    {
      city: "LEVELS",
      state: "WV"
    },
    {
      city: "LEVERETT",
      state: "MA"
    },
    {
      city: "LEVERING",
      state: "MI"
    },
    {
      city: "LEVITTOWN",
      state: "NY"
    },
    {
      city: "LEVITTOWN",
      state: "PA"
    },
    {
      city: "LEWELLEN",
      state: "NE"
    },
    {
      city: "LEWES",
      state: "DE"
    },
    {
      city: "LEWIS",
      state: "CO"
    },
    {
      city: "LEWIS",
      state: "IA"
    },
    {
      city: "LEWIS",
      state: "IN"
    },
    {
      city: "LEWIS",
      state: "KS"
    },
    {
      city: "LEWIS",
      state: "NY"
    },
    {
      city: "LEWIS CENTER",
      state: "OH"
    },
    {
      city: "LEWIS RUN",
      state: "PA"
    },
    {
      city: "LEWISBERRY",
      state: "PA"
    },
    {
      city: "LEWISBURG",
      state: "KY"
    },
    {
      city: "LEWISBURG",
      state: "OH"
    },
    {
      city: "LEWISBURG",
      state: "PA"
    },
    {
      city: "LEWISBURG",
      state: "TN"
    },
    {
      city: "LEWISBURG",
      state: "WV"
    },
    {
      city: "LEWISPORT",
      state: "KY"
    },
    {
      city: "LEWISTON",
      state: "CA"
    },
    {
      city: "LEWISTON",
      state: "ID"
    },
    {
      city: "LEWISTON",
      state: "ME"
    },
    {
      city: "LEWISTON",
      state: "MI"
    },
    {
      city: "LEWISTON",
      state: "MN"
    },
    {
      city: "LEWISTON",
      state: "NE"
    },
    {
      city: "LEWISTON",
      state: "NY"
    },
    {
      city: "LEWISTON",
      state: "UT"
    },
    {
      city: "LEWISTON WOODVILLE",
      state: "NC"
    },
    {
      city: "LEWISTOWN",
      state: "IL"
    },
    {
      city: "LEWISTOWN",
      state: "MO"
    },
    {
      city: "LEWISTOWN",
      state: "MT"
    },
    {
      city: "LEWISTOWN",
      state: "OH"
    },
    {
      city: "LEWISTOWN",
      state: "PA"
    },
    {
      city: "LEWISVILLE",
      state: "AR"
    },
    {
      city: "LEWISVILLE",
      state: "ID"
    },
    {
      city: "LEWISVILLE",
      state: "IN"
    },
    {
      city: "LEWISVILLE",
      state: "MN"
    },
    {
      city: "LEWISVILLE",
      state: "NC"
    },
    {
      city: "LEWISVILLE",
      state: "OH"
    },
    {
      city: "LEWISVILLE",
      state: "PA"
    },
    {
      city: "LEWISVILLE",
      state: "TX"
    },
    {
      city: "LEXA",
      state: "AR"
    },
    {
      city: "LEXINGTON",
      state: "AL"
    },
    {
      city: "LEXINGTON",
      state: "GA"
    },
    {
      city: "LEXINGTON",
      state: "IL"
    },
    {
      city: "LEXINGTON",
      state: "IN"
    },
    {
      city: "LEXINGTON",
      state: "KY"
    },
    {
      city: "LEXINGTON",
      state: "MA"
    },
    {
      city: "LEXINGTON",
      state: "MI"
    },
    {
      city: "LEXINGTON",
      state: "MO"
    },
    {
      city: "LEXINGTON",
      state: "MS"
    },
    {
      city: "LEXINGTON",
      state: "NC"
    },
    {
      city: "LEXINGTON",
      state: "NE"
    },
    {
      city: "LEXINGTON",
      state: "NY"
    },
    {
      city: "LEXINGTON",
      state: "OK"
    },
    {
      city: "LEXINGTON",
      state: "OR"
    },
    {
      city: "LEXINGTON",
      state: "SC"
    },
    {
      city: "LEXINGTON",
      state: "TN"
    },
    {
      city: "LEXINGTON",
      state: "TX"
    },
    {
      city: "LEXINGTON",
      state: "VA"
    },
    {
      city: "LEXINGTON PARK",
      state: "MD"
    },
    {
      city: "LIBBY",
      state: "MT"
    },
    {
      city: "LIBERAL",
      state: "KS"
    },
    {
      city: "LIBERAL",
      state: "MO"
    },
    {
      city: "LIBERTY",
      state: "IL"
    },
    {
      city: "LIBERTY",
      state: "IN"
    },
    {
      city: "LIBERTY",
      state: "KS"
    },
    {
      city: "LIBERTY",
      state: "KY"
    },
    {
      city: "LIBERTY",
      state: "ME"
    },
    {
      city: "LIBERTY",
      state: "MO"
    },
    {
      city: "LIBERTY",
      state: "MS"
    },
    {
      city: "LIBERTY",
      state: "NC"
    },
    {
      city: "LIBERTY",
      state: "NE"
    },
    {
      city: "LIBERTY",
      state: "NY"
    },
    {
      city: "LIBERTY",
      state: "PA"
    },
    {
      city: "LIBERTY",
      state: "SC"
    },
    {
      city: "LIBERTY",
      state: "TN"
    },
    {
      city: "LIBERTY",
      state: "TX"
    },
    {
      city: "LIBERTY",
      state: "WV"
    },
    {
      city: "LIBERTY CENTER",
      state: "IN"
    },
    {
      city: "LIBERTY CENTER",
      state: "OH"
    },
    {
      city: "LIBERTY CORNER",
      state: "NJ"
    },
    {
      city: "LIBERTY HILL",
      state: "SC"
    },
    {
      city: "LIBERTY HILL",
      state: "TX"
    },
    {
      city: "LIBERTY LAKE",
      state: "WA"
    },
    {
      city: "LIBERTY MILLS",
      state: "IN"
    },
    {
      city: "LIBERTYTOWN",
      state: "MD"
    },
    {
      city: "LIBERTYVILLE",
      state: "IA"
    },
    {
      city: "LIBERTYVILLE",
      state: "IL"
    },
    {
      city: "LIBUSE",
      state: "LA"
    },
    {
      city: "LICKING",
      state: "MO"
    },
    {
      city: "LIDDERDALE",
      state: "IA"
    },
    {
      city: "LIDGERWOOD",
      state: "ND"
    },
    {
      city: "LIEBENTHAL",
      state: "KS"
    },
    {
      city: "LIGHTFOOT",
      state: "VA"
    },
    {
      city: "LIGHTSTREET",
      state: "PA"
    },
    {
      city: "LIGNITE",
      state: "ND"
    },
    {
      city: "LIGNUM",
      state: "VA"
    },
    {
      city: "LIGONIER",
      state: "IN"
    },
    {
      city: "LIGONIER",
      state: "PA"
    },
    {
      city: "LIGUORI",
      state: "MO"
    },
    {
      city: "LIHUE",
      state: "HI"
    },
    {
      city: "LIKELY",
      state: "CA"
    },
    {
      city: "LILBOURN",
      state: "MO"
    },
    {
      city: "LILBURN",
      state: "GA"
    },
    {
      city: "LILESVILLE",
      state: "NC"
    },
    {
      city: "LILLIAN",
      state: "AL"
    },
    {
      city: "LILLIAN",
      state: "TX"
    },
    {
      city: "LILLIE",
      state: "LA"
    },
    {
      city: "LILLINGTON",
      state: "NC"
    },
    {
      city: "LILLIWAUP",
      state: "WA"
    },
    {
      city: "LILLY",
      state: "GA"
    },
    {
      city: "LILLY",
      state: "PA"
    },
    {
      city: "LILY",
      state: "KY"
    },
    {
      city: "LILY DALE",
      state: "NY"
    },
    {
      city: "LIMA",
      state: "IL"
    },
    {
      city: "LIMA",
      state: "MT"
    },
    {
      city: "LIMA",
      state: "NY"
    },
    {
      city: "LIMA",
      state: "OH"
    },
    {
      city: "LIMAVILLE",
      state: "OH"
    },
    {
      city: "LIME RIDGE",
      state: "WI"
    },
    {
      city: "LIME SPRINGS",
      state: "IA"
    },
    {
      city: "LIMEKILN",
      state: "PA"
    },
    {
      city: "LIMEPORT",
      state: "PA"
    },
    {
      city: "LIMERICK",
      state: "ME"
    },
    {
      city: "LIMESTONE",
      state: "ME"
    },
    {
      city: "LIMESTONE",
      state: "NY"
    },
    {
      city: "LIMESTONE",
      state: "PA"
    },
    {
      city: "LIMESTONE",
      state: "TN"
    },
    {
      city: "LIMINGTON",
      state: "ME"
    },
    {
      city: "LIMON",
      state: "CO"
    },
    {
      city: "LINCH",
      state: "WY"
    },
    {
      city: "LINCOLN",
      state: "AL"
    },
    {
      city: "LINCOLN",
      state: "AR"
    },
    {
      city: "LINCOLN",
      state: "CA"
    },
    {
      city: "LINCOLN",
      state: "DE"
    },
    {
      city: "LINCOLN",
      state: "IA"
    },
    {
      city: "LINCOLN",
      state: "IL"
    },
    {
      city: "LINCOLN",
      state: "KS"
    },
    {
      city: "LINCOLN",
      state: "MA"
    },
    {
      city: "LINCOLN",
      state: "ME"
    },
    {
      city: "LINCOLN",
      state: "MI"
    },
    {
      city: "LINCOLN",
      state: "MO"
    },
    {
      city: "LINCOLN",
      state: "MT"
    },
    {
      city: "LINCOLN",
      state: "NE"
    },
    {
      city: "LINCOLN",
      state: "NH"
    },
    {
      city: "LINCOLN",
      state: "NM"
    },
    {
      city: "LINCOLN",
      state: "RI"
    },
    {
      city: "LINCOLN",
      state: "TX"
    },
    {
      city: "LINCOLN",
      state: "VA"
    },
    {
      city: "LINCOLN",
      state: "WA"
    },
    {
      city: "LINCOLN CITY",
      state: "IN"
    },
    {
      city: "LINCOLN CITY",
      state: "OR"
    },
    {
      city: "LINCOLN PARK",
      state: "MI"
    },
    {
      city: "LINCOLN PARK",
      state: "NJ"
    },
    {
      city: "LINCOLN UNIVERSITY",
      state: "PA"
    },
    {
      city: "LINCOLNDALE",
      state: "NY"
    },
    {
      city: "LINCOLNSHIRE",
      state: "IL"
    },
    {
      city: "LINCOLNTON",
      state: "GA"
    },
    {
      city: "LINCOLNTON",
      state: "NC"
    },
    {
      city: "LINCOLNVILLE",
      state: "KS"
    },
    {
      city: "LINCOLNVILLE",
      state: "ME"
    },
    {
      city: "LINCOLNVILLE CENTER",
      state: "ME"
    },
    {
      city: "LINCOLNWOOD",
      state: "IL"
    },
    {
      city: "LINCROFT",
      state: "NJ"
    },
    {
      city: "LIND",
      state: "WA"
    },
    {
      city: "LINDALE",
      state: "GA"
    },
    {
      city: "LINDALE",
      state: "TX"
    },
    {
      city: "LINDEN",
      state: "AL"
    },
    {
      city: "LINDEN",
      state: "CA"
    },
    {
      city: "LINDEN",
      state: "IA"
    },
    {
      city: "LINDEN",
      state: "IN"
    },
    {
      city: "LINDEN",
      state: "MI"
    },
    {
      city: "LINDEN",
      state: "NC"
    },
    {
      city: "LINDEN",
      state: "NJ"
    },
    {
      city: "LINDEN",
      state: "PA"
    },
    {
      city: "LINDEN",
      state: "TN"
    },
    {
      city: "LINDEN",
      state: "TX"
    },
    {
      city: "LINDEN",
      state: "VA"
    },
    {
      city: "LINDEN",
      state: "WI"
    },
    {
      city: "LINDENHURST",
      state: "NY"
    },
    {
      city: "LINDENWOOD",
      state: "IL"
    },
    {
      city: "LINDLEY",
      state: "NY"
    },
    {
      city: "LINDON",
      state: "CO"
    },
    {
      city: "LINDON",
      state: "UT"
    },
    {
      city: "LINDRITH",
      state: "NM"
    },
    {
      city: "LINDSAY",
      state: "CA"
    },
    {
      city: "LINDSAY",
      state: "MT"
    },
    {
      city: "LINDSAY",
      state: "NE"
    },
    {
      city: "LINDSAY",
      state: "OK"
    },
    {
      city: "LINDSAY",
      state: "TX"
    },
    {
      city: "LINDSBORG",
      state: "KS"
    },
    {
      city: "LINDSEY",
      state: "OH"
    },
    {
      city: "LINDSIDE",
      state: "WV"
    },
    {
      city: "LINDSTROM",
      state: "MN"
    },
    {
      city: "LINE LEXINGTON",
      state: "PA"
    },
    {
      city: "LINEBORO",
      state: "MD"
    },
    {
      city: "LINEFORK",
      state: "KY"
    },
    {
      city: "LINESVILLE",
      state: "PA"
    },
    {
      city: "LINEVILLE",
      state: "AL"
    },
    {
      city: "LINEVILLE",
      state: "IA"
    },
    {
      city: "LINGLE",
      state: "WY"
    },
    {
      city: "LINGLEVILLE",
      state: "TX"
    },
    {
      city: "LINKWOOD",
      state: "MD"
    },
    {
      city: "LINN",
      state: "KS"
    },
    {
      city: "LINN",
      state: "MO"
    },
    {
      city: "LINN",
      state: "TX"
    },
    {
      city: "LINN",
      state: "WV"
    },
    {
      city: "LINN CREEK",
      state: "MO"
    },
    {
      city: "LINN GROVE",
      state: "IA"
    },
    {
      city: "LINN GROVE",
      state: "IN"
    },
    {
      city: "LINNEUS",
      state: "MO"
    },
    {
      city: "LINTHICUM HEIGHTS",
      state: "MD"
    },
    {
      city: "LINTON",
      state: "IN"
    },
    {
      city: "LINTON",
      state: "ND"
    },
    {
      city: "LINVILLE",
      state: "NC"
    },
    {
      city: "LINVILLE",
      state: "VA"
    },
    {
      city: "LINVILLE FALLS",
      state: "NC"
    },
    {
      city: "LINWOOD",
      state: "KS"
    },
    {
      city: "LINWOOD",
      state: "MA"
    },
    {
      city: "LINWOOD",
      state: "MI"
    },
    {
      city: "LINWOOD",
      state: "NC"
    },
    {
      city: "LINWOOD",
      state: "NE"
    },
    {
      city: "LINWOOD",
      state: "NJ"
    },
    {
      city: "LINWOOD",
      state: "NY"
    },
    {
      city: "LIONVILLE",
      state: "PA"
    },
    {
      city: "LIPAN",
      state: "TX"
    },
    {
      city: "LISBON",
      state: "IA"
    },
    {
      city: "LISBON",
      state: "LA"
    },
    {
      city: "LISBON",
      state: "MD"
    },
    {
      city: "LISBON",
      state: "ME"
    },
    {
      city: "LISBON",
      state: "ND"
    },
    {
      city: "LISBON",
      state: "NH"
    },
    {
      city: "LISBON",
      state: "NY"
    },
    {
      city: "LISBON",
      state: "OH"
    },
    {
      city: "LISBON FALLS",
      state: "ME"
    },
    {
      city: "LISCO",
      state: "NE"
    },
    {
      city: "LISCOMB",
      state: "IA"
    },
    {
      city: "LISLE",
      state: "IL"
    },
    {
      city: "LISLE",
      state: "NY"
    },
    {
      city: "LISMAN",
      state: "AL"
    },
    {
      city: "LISMORE",
      state: "MN"
    },
    {
      city: "LISSIE",
      state: "TX"
    },
    {
      city: "LISTIE",
      state: "PA"
    },
    {
      city: "LITCHFIELD",
      state: "CA"
    },
    {
      city: "LITCHFIELD",
      state: "CT"
    },
    {
      city: "LITCHFIELD",
      state: "IL"
    },
    {
      city: "LITCHFIELD",
      state: "ME"
    },
    {
      city: "LITCHFIELD",
      state: "MI"
    },
    {
      city: "LITCHFIELD",
      state: "MN"
    },
    {
      city: "LITCHFIELD",
      state: "NE"
    },
    {
      city: "LITCHFIELD",
      state: "NH"
    },
    {
      city: "LITCHFIELD",
      state: "OH"
    },
    {
      city: "LITCHFIELD PARK",
      state: "AZ"
    },
    {
      city: "LITCHVILLE",
      state: "ND"
    },
    {
      city: "LITERBERRY",
      state: "IL"
    },
    {
      city: "LITHIA",
      state: "FL"
    },
    {
      city: "LITHIA SPRINGS",
      state: "GA"
    },
    {
      city: "LITHONIA",
      state: "GA"
    },
    {
      city: "LITHOPOLIS",
      state: "OH"
    },
    {
      city: "LITITZ",
      state: "PA"
    },
    {
      city: "LITTCARR",
      state: "KY"
    },
    {
      city: "LITTLE AMERICA",
      state: "WY"
    },
    {
      city: "LITTLE BIRCH",
      state: "WV"
    },
    {
      city: "LITTLE CEDAR",
      state: "IA"
    },
    {
      city: "LITTLE CHUTE",
      state: "WI"
    },
    {
      city: "LITTLE COMPTON",
      state: "RI"
    },
    {
      city: "LITTLE CREEK",
      state: "DE"
    },
    {
      city: "LITTLE DEER ISLE",
      state: "ME"
    },
    {
      city: "LITTLE EAGLE",
      state: "SD"
    },
    {
      city: "LITTLE ELM",
      state: "TX"
    },
    {
      city: "LITTLE FALLS",
      state: "MN"
    },
    {
      city: "LITTLE FALLS",
      state: "NJ"
    },
    {
      city: "LITTLE FALLS",
      state: "NY"
    },
    {
      city: "LITTLE FERRY",
      state: "NJ"
    },
    {
      city: "LITTLE GENESEE",
      state: "NY"
    },
    {
      city: "LITTLE HOCKING",
      state: "OH"
    },
    {
      city: "LITTLE LAKE",
      state: "CA"
    },
    {
      city: "LITTLE LAKE",
      state: "MI"
    },
    {
      city: "LITTLE MEADOWS",
      state: "PA"
    },
    {
      city: "LITTLE MOUNTAIN",
      state: "SC"
    },
    {
      city: "LITTLE NECK",
      state: "NY"
    },
    {
      city: "LITTLE ORLEANS",
      state: "MD"
    },
    {
      city: "LITTLE PLYMOUTH",
      state: "VA"
    },
    {
      city: "LITTLE RIVER",
      state: "AL"
    },
    {
      city: "LITTLE RIVER",
      state: "CA"
    },
    {
      city: "LITTLE RIVER",
      state: "KS"
    },
    {
      city: "LITTLE RIVER",
      state: "SC"
    },
    {
      city: "LITTLE RIVER ACADEMY",
      state: "TX"
    },
    {
      city: "LITTLE ROCK",
      state: "AR"
    },
    {
      city: "LITTLE ROCK",
      state: "IA"
    },
    {
      city: "LITTLE ROCK",
      state: "MS"
    },
    {
      city: "LITTLE ROCK",
      state: "SC"
    },
    {
      city: "LITTLE ROCK AIR FORCE BASE",
      state: "AR"
    },
    {
      city: "LITTLE SILVER",
      state: "NJ"
    },
    {
      city: "LITTLE SIOUX",
      state: "IA"
    },
    {
      city: "LITTLE SUAMICO",
      state: "WI"
    },
    {
      city: "LITTLE SWITZERLAND",
      state: "NC"
    },
    {
      city: "LITTLE VALLEY",
      state: "NY"
    },
    {
      city: "LITTLE YORK",
      state: "IL"
    },
    {
      city: "LITTLE YORK",
      state: "NY"
    },
    {
      city: "LITTLEFIELD",
      state: "AZ"
    },
    {
      city: "LITTLEFIELD",
      state: "TX"
    },
    {
      city: "LITTLEFORK",
      state: "MN"
    },
    {
      city: "LITTLEROCK",
      state: "CA"
    },
    {
      city: "LITTLEROCK",
      state: "WA"
    },
    {
      city: "LITTLESTOWN",
      state: "PA"
    },
    {
      city: "LITTLETON",
      state: "CO"
    },
    {
      city: "LITTLETON",
      state: "IL"
    },
    {
      city: "LITTLETON",
      state: "MA"
    },
    {
      city: "LITTLETON",
      state: "NC"
    },
    {
      city: "LITTLETON",
      state: "NH"
    },
    {
      city: "LITTLETON",
      state: "WV"
    },
    {
      city: "LIVE OAK",
      state: "CA"
    },
    {
      city: "LIVE OAK",
      state: "FL"
    },
    {
      city: "LIVELY",
      state: "VA"
    },
    {
      city: "LIVERMORE",
      state: "CA"
    },
    {
      city: "LIVERMORE",
      state: "CO"
    },
    {
      city: "LIVERMORE",
      state: "IA"
    },
    {
      city: "LIVERMORE",
      state: "KY"
    },
    {
      city: "LIVERMORE",
      state: "ME"
    },
    {
      city: "LIVERMORE FALLS",
      state: "ME"
    },
    {
      city: "LIVERPOOL",
      state: "IL"
    },
    {
      city: "LIVERPOOL",
      state: "NY"
    },
    {
      city: "LIVERPOOL",
      state: "PA"
    },
    {
      city: "LIVERPOOL",
      state: "TX"
    },
    {
      city: "LIVINGSTON",
      state: "AL"
    },
    {
      city: "LIVINGSTON",
      state: "CA"
    },
    {
      city: "LIVINGSTON",
      state: "IL"
    },
    {
      city: "LIVINGSTON",
      state: "KY"
    },
    {
      city: "LIVINGSTON",
      state: "LA"
    },
    {
      city: "LIVINGSTON",
      state: "MT"
    },
    {
      city: "LIVINGSTON",
      state: "NJ"
    },
    {
      city: "LIVINGSTON",
      state: "NY"
    },
    {
      city: "LIVINGSTON",
      state: "TN"
    },
    {
      city: "LIVINGSTON",
      state: "TX"
    },
    {
      city: "LIVINGSTON",
      state: "WI"
    },
    {
      city: "LIVINGSTON MANOR",
      state: "NY"
    },
    {
      city: "LIVONIA",
      state: "LA"
    },
    {
      city: "LIVONIA",
      state: "MI"
    },
    {
      city: "LIVONIA",
      state: "MO"
    },
    {
      city: "LIVONIA",
      state: "NY"
    },
    {
      city: "LIZELLA",
      state: "GA"
    },
    {
      city: "LIZEMORES",
      state: "WV"
    },
    {
      city: "LIZTON",
      state: "IN"
    },
    {
      city: "LLANO",
      state: "CA"
    },
    {
      city: "LLANO",
      state: "NM"
    },
    {
      city: "LLANO",
      state: "TX"
    },
    {
      city: "LLEWELLYN",
      state: "PA"
    },
    {
      city: "LLOYD",
      state: "FL"
    },
    {
      city: "LOA",
      state: "UT"
    },
    {
      city: "LOACHAPOKA",
      state: "AL"
    },
    {
      city: "LOAMI",
      state: "IL"
    },
    {
      city: "LOBECO",
      state: "SC"
    },
    {
      city: "LOBELVILLE",
      state: "TN"
    },
    {
      city: "LOCH SHELDRAKE",
      state: "NY"
    },
    {
      city: "LOCHGELLY",
      state: "WV"
    },
    {
      city: "LOCHMERE",
      state: "NH"
    },
    {
      city: "LOCK HAVEN",
      state: "PA"
    },
    {
      city: "LOCKBOURNE",
      state: "OH"
    },
    {
      city: "LOCKE",
      state: "NY"
    },
    {
      city: "LOCKEFORD",
      state: "CA"
    },
    {
      city: "LOCKESBURG",
      state: "AR"
    },
    {
      city: "LOCKHART",
      state: "SC"
    },
    {
      city: "LOCKHART",
      state: "TX"
    },
    {
      city: "LOCKNEY",
      state: "TX"
    },
    {
      city: "LOCKPORT",
      state: "IL"
    },
    {
      city: "LOCKPORT",
      state: "KY"
    },
    {
      city: "LOCKPORT",
      state: "LA"
    },
    {
      city: "LOCKPORT",
      state: "NY"
    },
    {
      city: "LOCKRIDGE",
      state: "IA"
    },
    {
      city: "LOCKWOOD",
      state: "CA"
    },
    {
      city: "LOCKWOOD",
      state: "MO"
    },
    {
      city: "LOCKWOOD",
      state: "NY"
    },
    {
      city: "LOCO",
      state: "OK"
    },
    {
      city: "LOCO HILLS",
      state: "NM"
    },
    {
      city: "LOCUST",
      state: "NC"
    },
    {
      city: "LOCUST DALE",
      state: "VA"
    },
    {
      city: "LOCUST FORK",
      state: "AL"
    },
    {
      city: "LOCUST GAP",
      state: "PA"
    },
    {
      city: "LOCUST GROVE",
      state: "AR"
    },
    {
      city: "LOCUST GROVE",
      state: "GA"
    },
    {
      city: "LOCUST GROVE",
      state: "OK"
    },
    {
      city: "LOCUST GROVE",
      state: "VA"
    },
    {
      city: "LOCUST HILL",
      state: "VA"
    },
    {
      city: "LOCUST VALLEY",
      state: "NY"
    },
    {
      city: "LOCUSTDALE",
      state: "PA"
    },
    {
      city: "LOCUSTVILLE",
      state: "VA"
    },
    {
      city: "LODA",
      state: "IL"
    },
    {
      city: "LODGE",
      state: "SC"
    },
    {
      city: "LODGE GRASS",
      state: "MT"
    },
    {
      city: "LODGEPOLE",
      state: "NE"
    },
    {
      city: "LODGEPOLE",
      state: "SD"
    },
    {
      city: "LODI",
      state: "CA"
    },
    {
      city: "LODI",
      state: "MO"
    },
    {
      city: "LODI",
      state: "NJ"
    },
    {
      city: "LODI",
      state: "NY"
    },
    {
      city: "LODI",
      state: "OH"
    },
    {
      city: "LODI",
      state: "WI"
    },
    {
      city: "LOG LANE VILLAGE",
      state: "CO"
    },
    {
      city: "LOGAN",
      state: "AL"
    },
    {
      city: "LOGAN",
      state: "IA"
    },
    {
      city: "LOGAN",
      state: "IL"
    },
    {
      city: "LOGAN",
      state: "KS"
    },
    {
      city: "LOGAN",
      state: "NM"
    },
    {
      city: "LOGAN",
      state: "OH"
    },
    {
      city: "LOGAN",
      state: "UT"
    },
    {
      city: "LOGAN",
      state: "WV"
    },
    {
      city: "LOGANDALE",
      state: "NV"
    },
    {
      city: "LOGANSPORT",
      state: "IN"
    },
    {
      city: "LOGANSPORT",
      state: "LA"
    },
    {
      city: "LOGANTON",
      state: "PA"
    },
    {
      city: "LOGANVILLE",
      state: "GA"
    },
    {
      city: "LOGANVILLE",
      state: "PA"
    },
    {
      city: "LOGANVILLE",
      state: "WI"
    },
    {
      city: "LOGSDEN",
      state: "OR"
    },
    {
      city: "LOHMAN",
      state: "MO"
    },
    {
      city: "LOHN",
      state: "TX"
    },
    {
      city: "LOHRVILLE",
      state: "IA"
    },
    {
      city: "LOLETA",
      state: "CA"
    },
    {
      city: "LOLITA",
      state: "TX"
    },
    {
      city: "LOLO",
      state: "MT"
    },
    {
      city: "LOMA",
      state: "CO"
    },
    {
      city: "LOMA",
      state: "MT"
    },
    {
      city: "LOMA LINDA",
      state: "CA"
    },
    {
      city: "LOMA MAR",
      state: "CA"
    },
    {
      city: "LOMAN",
      state: "MN"
    },
    {
      city: "LOMAX",
      state: "IL"
    },
    {
      city: "LOMBARD",
      state: "IL"
    },
    {
      city: "LOMETA",
      state: "TX"
    },
    {
      city: "LOMIRA",
      state: "WI"
    },
    {
      city: "LOMITA",
      state: "CA"
    },
    {
      city: "LOMPOC",
      state: "CA"
    },
    {
      city: "LONACONING",
      state: "MD"
    },
    {
      city: "LONDON",
      state: "AR"
    },
    {
      city: "LONDON",
      state: "KY"
    },
    {
      city: "LONDON",
      state: "OH"
    },
    {
      city: "LONDON",
      state: "TX"
    },
    {
      city: "LONDON",
      state: "WV"
    },
    {
      city: "LONDON MILLS",
      state: "IL"
    },
    {
      city: "LONDONDERRY",
      state: "NH"
    },
    {
      city: "LONDONDERRY",
      state: "OH"
    },
    {
      city: "LONDONDERRY",
      state: "VT"
    },
    {
      city: "LONE",
      state: "KY"
    },
    {
      city: "LONE GROVE",
      state: "OK"
    },
    {
      city: "LONE JACK",
      state: "MO"
    },
    {
      city: "LONE MOUNTAIN",
      state: "TN"
    },
    {
      city: "LONE OAK",
      state: "TX"
    },
    {
      city: "LONE PINE",
      state: "CA"
    },
    {
      city: "LONE ROCK",
      state: "IA"
    },
    {
      city: "LONE ROCK",
      state: "WI"
    },
    {
      city: "LONE STAR",
      state: "TX"
    },
    {
      city: "LONE TREE",
      state: "CO"
    },
    {
      city: "LONE TREE",
      state: "IA"
    },
    {
      city: "LONE WOLF",
      state: "OK"
    },
    {
      city: "LONEDELL",
      state: "MO"
    },
    {
      city: "LONEPINE",
      state: "MT"
    },
    {
      city: "LONETREE",
      state: "WY"
    },
    {
      city: "LONG BARN",
      state: "CA"
    },
    {
      city: "LONG BEACH",
      state: "CA"
    },
    {
      city: "LONG BEACH",
      state: "MS"
    },
    {
      city: "LONG BEACH",
      state: "NY"
    },
    {
      city: "LONG BEACH",
      state: "WA"
    },
    {
      city: "LONG BOTTOM",
      state: "OH"
    },
    {
      city: "LONG BRANCH",
      state: "NJ"
    },
    {
      city: "LONG BRANCH",
      state: "TX"
    },
    {
      city: "LONG CREEK",
      state: "OR"
    },
    {
      city: "LONG CREEK",
      state: "SC"
    },
    {
      city: "LONG EDDY",
      state: "NY"
    },
    {
      city: "LONG GREEN",
      state: "MD"
    },
    {
      city: "LONG GROVE",
      state: "IA"
    },
    {
      city: "LONG ISLAND",
      state: "KS"
    },
    {
      city: "LONG ISLAND",
      state: "ME"
    },
    {
      city: "LONG ISLAND",
      state: "VA"
    },
    {
      city: "LONG ISLAND CITY",
      state: "NY"
    },
    {
      city: "LONG KEY",
      state: "FL"
    },
    {
      city: "LONG LAKE",
      state: "MI"
    },
    {
      city: "LONG LAKE",
      state: "MN"
    },
    {
      city: "LONG LAKE",
      state: "NY"
    },
    {
      city: "LONG LAKE",
      state: "SD"
    },
    {
      city: "LONG LAKE",
      state: "WI"
    },
    {
      city: "LONG LANE",
      state: "MO"
    },
    {
      city: "LONG PINE",
      state: "NE"
    },
    {
      city: "LONG POINT",
      state: "IL"
    },
    {
      city: "LONG POND",
      state: "PA"
    },
    {
      city: "LONG PRAIRIE",
      state: "MN"
    },
    {
      city: "LONG VALLEY",
      state: "NJ"
    },
    {
      city: "LONG VALLEY",
      state: "SD"
    },
    {
      city: "LONGBOAT KEY",
      state: "FL"
    },
    {
      city: "LONGBRANCH",
      state: "WA"
    },
    {
      city: "LONGDALE",
      state: "OK"
    },
    {
      city: "LONGFORD",
      state: "KS"
    },
    {
      city: "LONGLEAF",
      state: "LA"
    },
    {
      city: "LONGMEADOW",
      state: "MA"
    },
    {
      city: "LONGMIRE",
      state: "WA"
    },
    {
      city: "LONGMONT",
      state: "CO"
    },
    {
      city: "LONGPORT",
      state: "NJ"
    },
    {
      city: "LONGS",
      state: "SC"
    },
    {
      city: "LONGTON",
      state: "KS"
    },
    {
      city: "LONGVIEW",
      state: "IL"
    },
    {
      city: "LONGVIEW",
      state: "TX"
    },
    {
      city: "LONGVIEW",
      state: "WA"
    },
    {
      city: "LONGVILLE",
      state: "LA"
    },
    {
      city: "LONGVILLE",
      state: "MN"
    },
    {
      city: "LONGWOOD",
      state: "FL"
    },
    {
      city: "LONGWOOD",
      state: "NC"
    },
    {
      city: "LONOKE",
      state: "AR"
    },
    {
      city: "LONSDALE",
      state: "AR"
    },
    {
      city: "LONSDALE",
      state: "MN"
    },
    {
      city: "LOOGOOTEE",
      state: "IN"
    },
    {
      city: "LOOKEBA",
      state: "OK"
    },
    {
      city: "LOOKOUT",
      state: "CA"
    },
    {
      city: "LOOKOUT",
      state: "KY"
    },
    {
      city: "LOOKOUT",
      state: "WV"
    },
    {
      city: "LOOKOUT MOUNTAIN",
      state: "GA"
    },
    {
      city: "LOOKOUT MOUNTAIN",
      state: "TN"
    },
    {
      city: "LOOMIS",
      state: "CA"
    },
    {
      city: "LOOMIS",
      state: "NE"
    },
    {
      city: "LOOMIS",
      state: "WA"
    },
    {
      city: "LOON LAKE",
      state: "WA"
    },
    {
      city: "LOONEYVILLE",
      state: "WV"
    },
    {
      city: "LOOP",
      state: "TX"
    },
    {
      city: "LOOSE CREEK",
      state: "MO"
    },
    {
      city: "LOPEZ",
      state: "PA"
    },
    {
      city: "LOPEZ ISLAND",
      state: "WA"
    },
    {
      city: "LORADO",
      state: "WV"
    },
    {
      city: "LORAIN",
      state: "OH"
    },
    {
      city: "LORAINE",
      state: "IL"
    },
    {
      city: "LORAINE",
      state: "TX"
    },
    {
      city: "LORANE",
      state: "OR"
    },
    {
      city: "LORANGER",
      state: "LA"
    },
    {
      city: "LORDSBURG",
      state: "NM"
    },
    {
      city: "LORE CITY",
      state: "OH"
    },
    {
      city: "LOREAUVILLE",
      state: "LA"
    },
    {
      city: "LORENA",
      state: "TX"
    },
    {
      city: "LORENZO",
      state: "TX"
    },
    {
      city: "LORETTO",
      state: "KY"
    },
    {
      city: "LORETTO",
      state: "MI"
    },
    {
      city: "LORETTO",
      state: "MN"
    },
    {
      city: "LORETTO",
      state: "PA"
    },
    {
      city: "LORETTO",
      state: "TN"
    },
    {
      city: "LORETTO",
      state: "VA"
    },
    {
      city: "LORIDA",
      state: "FL"
    },
    {
      city: "LORIMOR",
      state: "IA"
    },
    {
      city: "LORING",
      state: "MT"
    },
    {
      city: "LORIS",
      state: "SC"
    },
    {
      city: "LORMAN",
      state: "MS"
    },
    {
      city: "LORRAINE",
      state: "KS"
    },
    {
      city: "LORRAINE",
      state: "NY"
    },
    {
      city: "LORTON",
      state: "NE"
    },
    {
      city: "LORTON",
      state: "VA"
    },
    {
      city: "LOS ALAMITOS",
      state: "CA"
    },
    {
      city: "LOS ALAMOS",
      state: "CA"
    },
    {
      city: "LOS ALAMOS",
      state: "NM"
    },
    {
      city: "LOS ALTOS",
      state: "CA"
    },
    {
      city: "LOS ANGELES",
      state: "CA"
    },
    {
      city: "LOS BANOS",
      state: "CA"
    },
    {
      city: "LOS EBANOS",
      state: "TX"
    },
    {
      city: "LOS FRESNOS",
      state: "TX"
    },
    {
      city: "LOS GATOS",
      state: "CA"
    },
    {
      city: "LOS INDIOS",
      state: "TX"
    },
    {
      city: "LOS LUNAS",
      state: "NM"
    },
    {
      city: "LOS MOLINOS",
      state: "CA"
    },
    {
      city: "LOS OJOS",
      state: "NM"
    },
    {
      city: "LOS OLIVOS",
      state: "CA"
    },
    {
      city: "LOS OSOS",
      state: "CA"
    },
    {
      city: "LOSANTVILLE",
      state: "IN"
    },
    {
      city: "LOST CITY",
      state: "WV"
    },
    {
      city: "LOST CREEK",
      state: "KY"
    },
    {
      city: "LOST CREEK",
      state: "WV"
    },
    {
      city: "LOST HILLS",
      state: "CA"
    },
    {
      city: "LOST NATION",
      state: "IA"
    },
    {
      city: "LOST SPRINGS",
      state: "KS"
    },
    {
      city: "LOST SPRINGS",
      state: "WY"
    },
    {
      city: "LOSTANT",
      state: "IL"
    },
    {
      city: "LOSTINE",
      state: "OR"
    },
    {
      city: "LOTHIAN",
      state: "MD"
    },
    {
      city: "LOTT",
      state: "TX"
    },
    {
      city: "LOTTIE",
      state: "LA"
    },
    {
      city: "LOTTSBURG",
      state: "VA"
    },
    {
      city: "LOTUS",
      state: "CA"
    },
    {
      city: "LOUANN",
      state: "AR"
    },
    {
      city: "LOUDON",
      state: "NH"
    },
    {
      city: "LOUDON",
      state: "TN"
    },
    {
      city: "LOUDONVILLE",
      state: "OH"
    },
    {
      city: "LOUGHMAN",
      state: "FL"
    },
    {
      city: "LOUIN",
      state: "MS"
    },
    {
      city: "LOUISA",
      state: "KY"
    },
    {
      city: "LOUISA",
      state: "VA"
    },
    {
      city: "LOUISBURG",
      state: "KS"
    },
    {
      city: "LOUISBURG",
      state: "MO"
    },
    {
      city: "LOUISBURG",
      state: "NC"
    },
    {
      city: "LOUISE",
      state: "MS"
    },
    {
      city: "LOUISE",
      state: "TX"
    },
    {
      city: "LOUISIANA",
      state: "MO"
    },
    {
      city: "LOUISVILLE",
      state: "AL"
    },
    {
      city: "LOUISVILLE",
      state: "CO"
    },
    {
      city: "LOUISVILLE",
      state: "GA"
    },
    {
      city: "LOUISVILLE",
      state: "IL"
    },
    {
      city: "LOUISVILLE",
      state: "KY"
    },
    {
      city: "LOUISVILLE",
      state: "MS"
    },
    {
      city: "LOUISVILLE",
      state: "NE"
    },
    {
      city: "LOUISVILLE",
      state: "OH"
    },
    {
      city: "LOUISVILLE",
      state: "TN"
    },
    {
      city: "LOUP CITY",
      state: "NE"
    },
    {
      city: "LOUVIERS",
      state: "CO"
    },
    {
      city: "LOVEJOY",
      state: "GA"
    },
    {
      city: "LOVEJOY",
      state: "IL"
    },
    {
      city: "LOVELACEVILLE",
      state: "KY"
    },
    {
      city: "LOVELADY",
      state: "TX"
    },
    {
      city: "LOVELAND",
      state: "CO"
    },
    {
      city: "LOVELAND",
      state: "OH"
    },
    {
      city: "LOVELAND",
      state: "OK"
    },
    {
      city: "LOVELL",
      state: "ME"
    },
    {
      city: "LOVELL",
      state: "WY"
    },
    {
      city: "LOVELOCK",
      state: "NV"
    },
    {
      city: "LOVELY",
      state: "KY"
    },
    {
      city: "LOVES PARK",
      state: "IL"
    },
    {
      city: "LOVETTSVILLE",
      state: "VA"
    },
    {
      city: "LOVEVILLE",
      state: "MD"
    },
    {
      city: "LOVILIA",
      state: "IA"
    },
    {
      city: "LOVING",
      state: "NM"
    },
    {
      city: "LOVING",
      state: "TX"
    },
    {
      city: "LOVINGSTON",
      state: "VA"
    },
    {
      city: "LOVINGTON",
      state: "IL"
    },
    {
      city: "LOVINGTON",
      state: "NM"
    },
    {
      city: "LOW MOOR",
      state: "IA"
    },
    {
      city: "LOW MOOR",
      state: "VA"
    },
    {
      city: "LOWAKE",
      state: "TX"
    },
    {
      city: "LOWBER",
      state: "PA"
    },
    {
      city: "LOWDEN",
      state: "IA"
    },
    {
      city: "LOWELL",
      state: "AR"
    },
    {
      city: "LOWELL",
      state: "FL"
    },
    {
      city: "LOWELL",
      state: "IN"
    },
    {
      city: "LOWELL",
      state: "MA"
    },
    {
      city: "LOWELL",
      state: "MI"
    },
    {
      city: "LOWELL",
      state: "NC"
    },
    {
      city: "LOWELL",
      state: "OH"
    },
    {
      city: "LOWELL",
      state: "OR"
    },
    {
      city: "LOWELL",
      state: "VT"
    },
    {
      city: "LOWELL",
      state: "WI"
    },
    {
      city: "LOWELLVILLE",
      state: "OH"
    },
    {
      city: "LOWER BRULE",
      state: "SD"
    },
    {
      city: "LOWER KALSKAG",
      state: "AK"
    },
    {
      city: "LOWER LAKE",
      state: "CA"
    },
    {
      city: "LOWER PEACH TREE",
      state: "AL"
    },
    {
      city: "LOWER SALEM",
      state: "OH"
    },
    {
      city: "LOWER WATERFORD",
      state: "VT"
    },
    {
      city: "LOWES",
      state: "KY"
    },
    {
      city: "LOWGAP",
      state: "NC"
    },
    {
      city: "LOWLAND",
      state: "NC"
    },
    {
      city: "LOWLAND",
      state: "TN"
    },
    {
      city: "LOWMAN",
      state: "ID"
    },
    {
      city: "LOWMAN",
      state: "NY"
    },
    {
      city: "LOWMANSVILLE",
      state: "KY"
    },
    {
      city: "LOWNDES",
      state: "MO"
    },
    {
      city: "LOWNDESBORO",
      state: "AL"
    },
    {
      city: "LOWPOINT",
      state: "IL"
    },
    {
      city: "LOWRY",
      state: "MN"
    },
    {
      city: "LOWRY",
      state: "VA"
    },
    {
      city: "LOWRY CITY",
      state: "MO"
    },
    {
      city: "LOWVILLE",
      state: "NY"
    },
    {
      city: "LOXAHATCHEE",
      state: "FL"
    },
    {
      city: "LOXLEY",
      state: "AL"
    },
    {
      city: "LOYAL",
      state: "OK"
    },
    {
      city: "LOYAL",
      state: "WI"
    },
    {
      city: "LOYALHANNA",
      state: "PA"
    },
    {
      city: "LOYALL",
      state: "KY"
    },
    {
      city: "LOYALTON",
      state: "CA"
    },
    {
      city: "LOYSBURG",
      state: "PA"
    },
    {
      city: "LOYSVILLE",
      state: "PA"
    },
    {
      city: "LOZANO",
      state: "TX"
    },
    {
      city: "LU VERNE",
      state: "IA"
    },
    {
      city: "LUANA",
      state: "IA"
    },
    {
      city: "LUBBOCK",
      state: "TX"
    },
    {
      city: "LUBEC",
      state: "ME"
    },
    {
      city: "LUBLIN",
      state: "WI"
    },
    {
      city: "LUCAMA",
      state: "NC"
    },
    {
      city: "LUCAN",
      state: "MN"
    },
    {
      city: "LUCAS",
      state: "IA"
    },
    {
      city: "LUCAS",
      state: "KS"
    },
    {
      city: "LUCAS",
      state: "KY"
    },
    {
      city: "LUCAS",
      state: "OH"
    },
    {
      city: "LUCASVILLE",
      state: "OH"
    },
    {
      city: "LUCEDALE",
      state: "MS"
    },
    {
      city: "LUCERNE",
      state: "CA"
    },
    {
      city: "LUCERNE",
      state: "CO"
    },
    {
      city: "LUCERNE",
      state: "IN"
    },
    {
      city: "LUCERNE",
      state: "MO"
    },
    {
      city: "LUCERNE VALLEY",
      state: "CA"
    },
    {
      city: "LUCERNEMINES",
      state: "PA"
    },
    {
      city: "LUCIEN",
      state: "OK"
    },
    {
      city: "LUCILE",
      state: "ID"
    },
    {
      city: "LUCINDA",
      state: "PA"
    },
    {
      city: "LUCK",
      state: "WI"
    },
    {
      city: "LUCKEY",
      state: "OH"
    },
    {
      city: "LUDELL",
      state: "KS"
    },
    {
      city: "LUDINGTON",
      state: "MI"
    },
    {
      city: "LUDLOW",
      state: "CA"
    },
    {
      city: "LUDLOW",
      state: "IL"
    },
    {
      city: "LUDLOW",
      state: "MA"
    },
    {
      city: "LUDLOW",
      state: "MO"
    },
    {
      city: "LUDLOW",
      state: "PA"
    },
    {
      city: "LUDLOW",
      state: "SD"
    },
    {
      city: "LUDLOW",
      state: "VT"
    },
    {
      city: "LUDLOW FALLS",
      state: "OH"
    },
    {
      city: "LUDOWICI",
      state: "GA"
    },
    {
      city: "LUEBBERING",
      state: "MO"
    },
    {
      city: "LUEDERS",
      state: "TX"
    },
    {
      city: "LUFKIN",
      state: "TX"
    },
    {
      city: "LUGOFF",
      state: "SC"
    },
    {
      city: "LUKACHUKAI",
      state: "AZ"
    },
    {
      city: "LUKE",
      state: "MD"
    },
    {
      city: "LUKE AIR FORCE BASE",
      state: "AZ"
    },
    {
      city: "LUKEVILLE",
      state: "AZ"
    },
    {
      city: "LULA",
      state: "GA"
    },
    {
      city: "LULA",
      state: "MS"
    },
    {
      city: "LULING",
      state: "LA"
    },
    {
      city: "LULING",
      state: "TX"
    },
    {
      city: "LULU",
      state: "FL"
    },
    {
      city: "LUMBER BRIDGE",
      state: "NC"
    },
    {
      city: "LUMBER CITY",
      state: "GA"
    },
    {
      city: "LUMBERPORT",
      state: "WV"
    },
    {
      city: "LUMBERTON",
      state: "MS"
    },
    {
      city: "LUMBERTON",
      state: "NC"
    },
    {
      city: "LUMBERTON",
      state: "NJ"
    },
    {
      city: "LUMBERTON",
      state: "TX"
    },
    {
      city: "LUMBERVILLE",
      state: "PA"
    },
    {
      city: "LUMMI ISLAND",
      state: "WA"
    },
    {
      city: "LUMPKIN",
      state: "GA"
    },
    {
      city: "LUNA",
      state: "NM"
    },
    {
      city: "LUNA PIER",
      state: "MI"
    },
    {
      city: "LUND",
      state: "NV"
    },
    {
      city: "LUNENBURG",
      state: "MA"
    },
    {
      city: "LUNENBURG",
      state: "VA"
    },
    {
      city: "LUNENBURG",
      state: "VT"
    },
    {
      city: "LUPTON",
      state: "AZ"
    },
    {
      city: "LUPTON",
      state: "MI"
    },
    {
      city: "LURAY",
      state: "KS"
    },
    {
      city: "LURAY",
      state: "MO"
    },
    {
      city: "LURAY",
      state: "SC"
    },
    {
      city: "LURAY",
      state: "TN"
    },
    {
      city: "LURAY",
      state: "VA"
    },
    {
      city: "LURGAN",
      state: "PA"
    },
    {
      city: "LUSBY",
      state: "MD"
    },
    {
      city: "LUSK",
      state: "WY"
    },
    {
      city: "LUTCHER",
      state: "LA"
    },
    {
      city: "LUTHER",
      state: "IA"
    },
    {
      city: "LUTHER",
      state: "MI"
    },
    {
      city: "LUTHER",
      state: "OK"
    },
    {
      city: "LUTHERSBURG",
      state: "PA"
    },
    {
      city: "LUTHERSVILLE",
      state: "GA"
    },
    {
      city: "LUTHERVILLE TIMONIUM",
      state: "MD"
    },
    {
      city: "LUTSEN",
      state: "MN"
    },
    {
      city: "LUTTRELL",
      state: "TN"
    },
    {
      city: "LUTTS",
      state: "TN"
    },
    {
      city: "LUTZ",
      state: "FL"
    },
    {
      city: "LUVERNE",
      state: "AL"
    },
    {
      city: "LUVERNE",
      state: "MN"
    },
    {
      city: "LUVERNE",
      state: "ND"
    },
    {
      city: "LUXEMBURG",
      state: "IA"
    },
    {
      city: "LUXEMBURG",
      state: "WI"
    },
    {
      city: "LUXOR",
      state: "PA"
    },
    {
      city: "LUXORA",
      state: "AR"
    },
    {
      city: "LUZERNE",
      state: "IA"
    },
    {
      city: "LUZERNE",
      state: "MI"
    },
    {
      city: "LUZERNE",
      state: "PA"
    },
    {
      city: "LYBURN",
      state: "WV"
    },
    {
      city: "LYCOMING",
      state: "NY"
    },
    {
      city: "LYDIA",
      state: "LA"
    },
    {
      city: "LYDIA",
      state: "SC"
    },
    {
      city: "LYERLY",
      state: "GA"
    },
    {
      city: "LYFORD",
      state: "TX"
    },
    {
      city: "LYKENS",
      state: "PA"
    },
    {
      city: "LYLE",
      state: "MN"
    },
    {
      city: "LYLE",
      state: "WA"
    },
    {
      city: "LYLES",
      state: "TN"
    },
    {
      city: "LYMAN",
      state: "NE"
    },
    {
      city: "LYMAN",
      state: "SC"
    },
    {
      city: "LYMAN",
      state: "UT"
    },
    {
      city: "LYMAN",
      state: "WA"
    },
    {
      city: "LYMAN",
      state: "WY"
    },
    {
      city: "LYME",
      state: "NH"
    },
    {
      city: "LYME CENTER",
      state: "NH"
    },
    {
      city: "LYNBROOK",
      state: "NY"
    },
    {
      city: "LYNCH",
      state: "KY"
    },
    {
      city: "LYNCH",
      state: "NE"
    },
    {
      city: "LYNCH STATION",
      state: "VA"
    },
    {
      city: "LYNCHBURG",
      state: "MO"
    },
    {
      city: "LYNCHBURG",
      state: "OH"
    },
    {
      city: "LYNCHBURG",
      state: "SC"
    },
    {
      city: "LYNCHBURG",
      state: "TN"
    },
    {
      city: "LYNCHBURG",
      state: "VA"
    },
    {
      city: "LYNCO",
      state: "WV"
    },
    {
      city: "LYND",
      state: "MN"
    },
    {
      city: "LYNDEBOROUGH",
      state: "NH"
    },
    {
      city: "LYNDELL",
      state: "PA"
    },
    {
      city: "LYNDEN",
      state: "WA"
    },
    {
      city: "LYNDHURST",
      state: "NJ"
    },
    {
      city: "LYNDHURST",
      state: "VA"
    },
    {
      city: "LYNDON",
      state: "IL"
    },
    {
      city: "LYNDON",
      state: "KS"
    },
    {
      city: "LYNDON",
      state: "VT"
    },
    {
      city: "LYNDON CENTER",
      state: "VT"
    },
    {
      city: "LYNDON STATION",
      state: "WI"
    },
    {
      city: "LYNDONVILLE",
      state: "NY"
    },
    {
      city: "LYNDONVILLE",
      state: "VT"
    },
    {
      city: "LYNDORA",
      state: "PA"
    },
    {
      city: "LYNN",
      state: "AL"
    },
    {
      city: "LYNN",
      state: "AR"
    },
    {
      city: "LYNN",
      state: "IN"
    },
    {
      city: "LYNN",
      state: "MA"
    },
    {
      city: "LYNN",
      state: "NC"
    },
    {
      city: "LYNN CENTER",
      state: "IL"
    },
    {
      city: "LYNN HAVEN",
      state: "FL"
    },
    {
      city: "LYNNDYL",
      state: "UT"
    },
    {
      city: "LYNNFIELD",
      state: "MA"
    },
    {
      city: "LYNNVILLE",
      state: "IA"
    },
    {
      city: "LYNNVILLE",
      state: "IN"
    },
    {
      city: "LYNNVILLE",
      state: "TN"
    },
    {
      city: "LYNNWOOD",
      state: "WA"
    },
    {
      city: "LYNWOOD",
      state: "CA"
    },
    {
      city: "LYNX",
      state: "OH"
    },
    {
      city: "LYON",
      state: "MS"
    },
    {
      city: "LYON MOUNTAIN",
      state: "NY"
    },
    {
      city: "LYON STATION",
      state: "PA"
    },
    {
      city: "LYONS",
      state: "CO"
    },
    {
      city: "LYONS",
      state: "GA"
    },
    {
      city: "LYONS",
      state: "IL"
    },
    {
      city: "LYONS",
      state: "IN"
    },
    {
      city: "LYONS",
      state: "KS"
    },
    {
      city: "LYONS",
      state: "MI"
    },
    {
      city: "LYONS",
      state: "NE"
    },
    {
      city: "LYONS",
      state: "NJ"
    },
    {
      city: "LYONS",
      state: "NY"
    },
    {
      city: "LYONS",
      state: "OH"
    },
    {
      city: "LYONS",
      state: "OR"
    },
    {
      city: "LYONS",
      state: "SD"
    },
    {
      city: "LYONS",
      state: "TX"
    },
    {
      city: "LYONS",
      state: "WI"
    },
    {
      city: "LYONS FALLS",
      state: "NY"
    },
    {
      city: "LYSITE",
      state: "WY"
    },
    {
      city: "LYTLE",
      state: "TX"
    },
    {
      city: "LYTLE CREEK",
      state: "CA"
    },
    {
      city: "LYTTON",
      state: "IA"
    },
    {
      city: "MABANK",
      state: "TX"
    },
    {
      city: "MABEL",
      state: "MN"
    },
    {
      city: "MABELVALE",
      state: "AR"
    },
    {
      city: "MABEN",
      state: "MS"
    },
    {
      city: "MABEN",
      state: "WV"
    },
    {
      city: "MABIE",
      state: "WV"
    },
    {
      city: "MABLETON",
      state: "GA"
    },
    {
      city: "MABSCOTT",
      state: "WV"
    },
    {
      city: "MABTON",
      state: "WA"
    },
    {
      city: "MAC ARTHUR",
      state: "WV"
    },
    {
      city: "MACATAWA",
      state: "MI"
    },
    {
      city: "MACCLENNY",
      state: "FL"
    },
    {
      city: "MACCLESFIELD",
      state: "NC"
    },
    {
      city: "MACDOEL",
      state: "CA"
    },
    {
      city: "MACDONA",
      state: "TX"
    },
    {
      city: "MACEDON",
      state: "NY"
    },
    {
      city: "MACEDONIA",
      state: "IA"
    },
    {
      city: "MACEDONIA",
      state: "IL"
    },
    {
      city: "MACEDONIA",
      state: "OH"
    },
    {
      city: "MACEO",
      state: "KY"
    },
    {
      city: "MACFARLAN",
      state: "WV"
    },
    {
      city: "MACHESNEY PARK",
      state: "IL"
    },
    {
      city: "MACHIAS",
      state: "ME"
    },
    {
      city: "MACHIAS",
      state: "NY"
    },
    {
      city: "MACHIASPORT",
      state: "ME"
    },
    {
      city: "MACHIPONGO",
      state: "VA"
    },
    {
      city: "MACK",
      state: "CO"
    },
    {
      city: "MACKAY",
      state: "ID"
    },
    {
      city: "MACKEY",
      state: "IN"
    },
    {
      city: "MACKEYVILLE",
      state: "PA"
    },
    {
      city: "MACKINAC ISLAND",
      state: "MI"
    },
    {
      city: "MACKINAW",
      state: "IL"
    },
    {
      city: "MACKINAW CITY",
      state: "MI"
    },
    {
      city: "MACKS CREEK",
      state: "MO"
    },
    {
      city: "MACKS INN",
      state: "ID"
    },
    {
      city: "MACKSBURG",
      state: "IA"
    },
    {
      city: "MACKSBURG",
      state: "OH"
    },
    {
      city: "MACKSVILLE",
      state: "KS"
    },
    {
      city: "MACKVILLE",
      state: "KY"
    },
    {
      city: "MACOMB",
      state: "IL"
    },
    {
      city: "MACOMB",
      state: "MI"
    },
    {
      city: "MACOMB",
      state: "MO"
    },
    {
      city: "MACOMB",
      state: "OK"
    },
    {
      city: "MACON",
      state: "GA"
    },
    {
      city: "MACON",
      state: "IL"
    },
    {
      city: "MACON",
      state: "MO"
    },
    {
      city: "MACON",
      state: "MS"
    },
    {
      city: "MACON",
      state: "NC"
    },
    {
      city: "MACON",
      state: "TN"
    },
    {
      city: "MACUNGIE",
      state: "PA"
    },
    {
      city: "MACY",
      state: "IN"
    },
    {
      city: "MACY",
      state: "NE"
    },
    {
      city: "MAD RIVER",
      state: "CA"
    },
    {
      city: "MADAWASKA",
      state: "ME"
    },
    {
      city: "MADBURY",
      state: "NH"
    },
    {
      city: "MADDEN",
      state: "MS"
    },
    {
      city: "MADDOCK",
      state: "ND"
    },
    {
      city: "MADELIA",
      state: "MN"
    },
    {
      city: "MADERA",
      state: "CA"
    },
    {
      city: "MADERA",
      state: "PA"
    },
    {
      city: "MADILL",
      state: "OK"
    },
    {
      city: "MADISON",
      state: "AL"
    },
    {
      city: "MADISON",
      state: "AR"
    },
    {
      city: "MADISON",
      state: "CA"
    },
    {
      city: "MADISON",
      state: "CT"
    },
    {
      city: "MADISON",
      state: "FL"
    },
    {
      city: "MADISON",
      state: "GA"
    },
    {
      city: "MADISON",
      state: "IL"
    },
    {
      city: "MADISON",
      state: "IN"
    },
    {
      city: "MADISON",
      state: "KS"
    },
    {
      city: "MADISON",
      state: "MD"
    },
    {
      city: "MADISON",
      state: "ME"
    },
    {
      city: "MADISON",
      state: "MN"
    },
    {
      city: "MADISON",
      state: "MO"
    },
    {
      city: "MADISON",
      state: "MS"
    },
    {
      city: "MADISON",
      state: "NC"
    },
    {
      city: "MADISON",
      state: "NE"
    },
    {
      city: "MADISON",
      state: "NH"
    },
    {
      city: "MADISON",
      state: "NJ"
    },
    {
      city: "MADISON",
      state: "NY"
    },
    {
      city: "MADISON",
      state: "OH"
    },
    {
      city: "MADISON",
      state: "PA"
    },
    {
      city: "MADISON",
      state: "SD"
    },
    {
      city: "MADISON",
      state: "TN"
    },
    {
      city: "MADISON",
      state: "VA"
    },
    {
      city: "MADISON",
      state: "WI"
    },
    {
      city: "MADISON",
      state: "WV"
    },
    {
      city: "MADISON HEIGHTS",
      state: "MI"
    },
    {
      city: "MADISON HEIGHTS",
      state: "VA"
    },
    {
      city: "MADISON LAKE",
      state: "MN"
    },
    {
      city: "MADISONBURG",
      state: "PA"
    },
    {
      city: "MADISONVILLE",
      state: "KY"
    },
    {
      city: "MADISONVILLE",
      state: "LA"
    },
    {
      city: "MADISONVILLE",
      state: "TN"
    },
    {
      city: "MADISONVILLE",
      state: "TX"
    },
    {
      city: "MADRAS",
      state: "OR"
    },
    {
      city: "MADRID",
      state: "IA"
    },
    {
      city: "MADRID",
      state: "NE"
    },
    {
      city: "MADRID",
      state: "NY"
    },
    {
      city: "MAEYSTOWN",
      state: "IL"
    },
    {
      city: "MAGALIA",
      state: "CA"
    },
    {
      city: "MAGAZINE",
      state: "AR"
    },
    {
      city: "MAGDALENA",
      state: "NM"
    },
    {
      city: "MAGEE",
      state: "MS"
    },
    {
      city: "MAGGIE VALLEY",
      state: "NC"
    },
    {
      city: "MAGNA",
      state: "UT"
    },
    {
      city: "MAGNESS",
      state: "AR"
    },
    {
      city: "MAGNET",
      state: "NE"
    },
    {
      city: "MAGNETIC SPRINGS",
      state: "OH"
    },
    {
      city: "MAGNOLIA",
      state: "AL"
    },
    {
      city: "MAGNOLIA",
      state: "AR"
    },
    {
      city: "MAGNOLIA",
      state: "DE"
    },
    {
      city: "MAGNOLIA",
      state: "IL"
    },
    {
      city: "MAGNOLIA",
      state: "KY"
    },
    {
      city: "MAGNOLIA",
      state: "MN"
    },
    {
      city: "MAGNOLIA",
      state: "MS"
    },
    {
      city: "MAGNOLIA",
      state: "NC"
    },
    {
      city: "MAGNOLIA",
      state: "NJ"
    },
    {
      city: "MAGNOLIA",
      state: "OH"
    },
    {
      city: "MAGNOLIA",
      state: "TX"
    },
    {
      city: "MAGNOLIA SPRINGS",
      state: "AL"
    },
    {
      city: "MAHAFFEY",
      state: "PA"
    },
    {
      city: "MAHANOY CITY",
      state: "PA"
    },
    {
      city: "MAHANOY PLANE",
      state: "PA"
    },
    {
      city: "MAHASKA",
      state: "KS"
    },
    {
      city: "MAHNOMEN",
      state: "MN"
    },
    {
      city: "MAHOMET",
      state: "IL"
    },
    {
      city: "MAHOPAC",
      state: "NY"
    },
    {
      city: "MAHOPAC FALLS",
      state: "NY"
    },
    {
      city: "MAHWAH",
      state: "NJ"
    },
    {
      city: "MAIDEN",
      state: "NC"
    },
    {
      city: "MAIDEN ROCK",
      state: "WI"
    },
    {
      city: "MAIDENS",
      state: "VA"
    },
    {
      city: "MAIDSVILLE",
      state: "WV"
    },
    {
      city: "MAINE",
      state: "NY"
    },
    {
      city: "MAINESBURG",
      state: "PA"
    },
    {
      city: "MAINEVILLE",
      state: "OH"
    },
    {
      city: "MAINLAND",
      state: "PA"
    },
    {
      city: "MAITLAND",
      state: "FL"
    },
    {
      city: "MAITLAND",
      state: "MO"
    },
    {
      city: "MAIZE",
      state: "KS"
    },
    {
      city: "MAKANDA",
      state: "IL"
    },
    {
      city: "MAKAWAO",
      state: "HI"
    },
    {
      city: "MAKAWELI",
      state: "HI"
    },
    {
      city: "MAKINEN",
      state: "MN"
    },
    {
      city: "MAKOTI",
      state: "ND"
    },
    {
      city: "MALABAR",
      state: "FL"
    },
    {
      city: "MALAD CITY",
      state: "ID"
    },
    {
      city: "MALAGA",
      state: "NJ"
    },
    {
      city: "MALAGA",
      state: "NM"
    },
    {
      city: "MALAGA",
      state: "OH"
    },
    {
      city: "MALAGA",
      state: "WA"
    },
    {
      city: "MALAKOFF",
      state: "TX"
    },
    {
      city: "MALCOLM",
      state: "AL"
    },
    {
      city: "MALCOLM",
      state: "NE"
    },
    {
      city: "MALCOM",
      state: "IA"
    },
    {
      city: "MALDEN",
      state: "IL"
    },
    {
      city: "MALDEN",
      state: "MA"
    },
    {
      city: "MALDEN",
      state: "MO"
    },
    {
      city: "MALDEN",
      state: "WA"
    },
    {
      city: "MALDEN ON HUDSON",
      state: "NY"
    },
    {
      city: "MALIBU",
      state: "CA"
    },
    {
      city: "MALIN",
      state: "OR"
    },
    {
      city: "MALINTA",
      state: "OH"
    },
    {
      city: "MALJAMAR",
      state: "NM"
    },
    {
      city: "MALLARD",
      state: "IA"
    },
    {
      city: "MALLIE",
      state: "KY"
    },
    {
      city: "MALLORY",
      state: "NY"
    },
    {
      city: "MALLORY",
      state: "WV"
    },
    {
      city: "MALMO",
      state: "NE"
    },
    {
      city: "MALMSTROM AFB",
      state: "MT"
    },
    {
      city: "MALO",
      state: "WA"
    },
    {
      city: "MALONE",
      state: "FL"
    },
    {
      city: "MALONE",
      state: "KY"
    },
    {
      city: "MALONE",
      state: "NY"
    },
    {
      city: "MALONE",
      state: "TX"
    },
    {
      city: "MALONE",
      state: "WA"
    },
    {
      city: "MALONE",
      state: "WI"
    },
    {
      city: "MALOTT",
      state: "WA"
    },
    {
      city: "MALTA",
      state: "ID"
    },
    {
      city: "MALTA",
      state: "IL"
    },
    {
      city: "MALTA",
      state: "MT"
    },
    {
      city: "MALTA",
      state: "OH"
    },
    {
      city: "MALTA BEND",
      state: "MO"
    },
    {
      city: "MALVERN",
      state: "AL"
    },
    {
      city: "MALVERN",
      state: "AR"
    },
    {
      city: "MALVERN",
      state: "IA"
    },
    {
      city: "MALVERN",
      state: "OH"
    },
    {
      city: "MALVERN",
      state: "PA"
    },
    {
      city: "MALVERNE",
      state: "NY"
    },
    {
      city: "MAMARONECK",
      state: "NY"
    },
    {
      city: "MAMERS",
      state: "NC"
    },
    {
      city: "MAMMOTH",
      state: "AZ"
    },
    {
      city: "MAMMOTH",
      state: "PA"
    },
    {
      city: "MAMMOTH",
      state: "WV"
    },
    {
      city: "MAMMOTH CAVE",
      state: "KY"
    },
    {
      city: "MAMMOTH LAKES",
      state: "CA"
    },
    {
      city: "MAMMOTH SPRING",
      state: "AR"
    },
    {
      city: "MAMOU",
      state: "LA"
    },
    {
      city: "MAN",
      state: "WV"
    },
    {
      city: "MANAHAWKIN",
      state: "NJ"
    },
    {
      city: "MANAKIN SABOT",
      state: "VA"
    },
    {
      city: "MANASQUAN",
      state: "NJ"
    },
    {
      city: "MANASSA",
      state: "CO"
    },
    {
      city: "MANASSAS",
      state: "VA"
    },
    {
      city: "MANAWA",
      state: "WI"
    },
    {
      city: "MANCELONA",
      state: "MI"
    },
    {
      city: "MANCHACA",
      state: "TX"
    },
    {
      city: "MANCHAUG",
      state: "MA"
    },
    {
      city: "MANCHESTER",
      state: "CA"
    },
    {
      city: "MANCHESTER",
      state: "CT"
    },
    {
      city: "MANCHESTER",
      state: "GA"
    },
    {
      city: "MANCHESTER",
      state: "IA"
    },
    {
      city: "MANCHESTER",
      state: "IL"
    },
    {
      city: "MANCHESTER",
      state: "KY"
    },
    {
      city: "MANCHESTER",
      state: "MA"
    },
    {
      city: "MANCHESTER",
      state: "MD"
    },
    {
      city: "MANCHESTER",
      state: "ME"
    },
    {
      city: "MANCHESTER",
      state: "MI"
    },
    {
      city: "MANCHESTER",
      state: "NH"
    },
    {
      city: "MANCHESTER",
      state: "NY"
    },
    {
      city: "MANCHESTER",
      state: "OH"
    },
    {
      city: "MANCHESTER",
      state: "OK"
    },
    {
      city: "MANCHESTER",
      state: "PA"
    },
    {
      city: "MANCHESTER",
      state: "TN"
    },
    {
      city: "MANCHESTER",
      state: "VT"
    },
    {
      city: "MANCHESTER",
      state: "WA"
    },
    {
      city: "MANCHESTER CENTER",
      state: "VT"
    },
    {
      city: "MANCHESTER TOWNSHIP",
      state: "NJ"
    },
    {
      city: "MANCOS",
      state: "CO"
    },
    {
      city: "MANDAN",
      state: "ND"
    },
    {
      city: "MANDAREE",
      state: "ND"
    },
    {
      city: "MANDERSON",
      state: "SD"
    },
    {
      city: "MANDERSON",
      state: "WY"
    },
    {
      city: "MANDEVILLE",
      state: "LA"
    },
    {
      city: "MANGHAM",
      state: "LA"
    },
    {
      city: "MANGO",
      state: "FL"
    },
    {
      city: "MANGUM",
      state: "OK"
    },
    {
      city: "MANHASSET",
      state: "NY"
    },
    {
      city: "MANHATTAN",
      state: "IL"
    },
    {
      city: "MANHATTAN",
      state: "KS"
    },
    {
      city: "MANHATTAN",
      state: "MT"
    },
    {
      city: "MANHATTAN",
      state: "NV"
    },
    {
      city: "MANHATTAN BEACH",
      state: "CA"
    },
    {
      city: "MANHEIM",
      state: "PA"
    },
    {
      city: "MANILA",
      state: "AR"
    },
    {
      city: "MANILA",
      state: "UT"
    },
    {
      city: "MANILLA",
      state: "IA"
    },
    {
      city: "MANILLA",
      state: "IN"
    },
    {
      city: "MANISTEE",
      state: "MI"
    },
    {
      city: "MANISTIQUE",
      state: "MI"
    },
    {
      city: "MANITO",
      state: "IL"
    },
    {
      city: "MANITOU",
      state: "KY"
    },
    {
      city: "MANITOU BEACH",
      state: "MI"
    },
    {
      city: "MANITOU SPRINGS",
      state: "CO"
    },
    {
      city: "MANITOWISH WATERS",
      state: "WI"
    },
    {
      city: "MANITOWOC",
      state: "WI"
    },
    {
      city: "MANKATO",
      state: "KS"
    },
    {
      city: "MANKATO",
      state: "MN"
    },
    {
      city: "MANLEY",
      state: "NE"
    },
    {
      city: "MANLEY HOT SPRINGS",
      state: "AK"
    },
    {
      city: "MANLIUS",
      state: "IL"
    },
    {
      city: "MANLIUS",
      state: "NY"
    },
    {
      city: "MANLY",
      state: "IA"
    },
    {
      city: "MANNBORO",
      state: "VA"
    },
    {
      city: "MANNFORD",
      state: "OK"
    },
    {
      city: "MANNING",
      state: "IA"
    },
    {
      city: "MANNING",
      state: "ND"
    },
    {
      city: "MANNING",
      state: "OR"
    },
    {
      city: "MANNING",
      state: "SC"
    },
    {
      city: "MANNINGTON",
      state: "WV"
    },
    {
      city: "MANNS CHOICE",
      state: "PA"
    },
    {
      city: "MANNS HARBOR",
      state: "NC"
    },
    {
      city: "MANNSVILLE",
      state: "KY"
    },
    {
      city: "MANNSVILLE",
      state: "NY"
    },
    {
      city: "MANNSVILLE",
      state: "OK"
    },
    {
      city: "MANOKOTAK",
      state: "AK"
    },
    {
      city: "MANOMET",
      state: "MA"
    },
    {
      city: "MANOR",
      state: "GA"
    },
    {
      city: "MANOR",
      state: "PA"
    },
    {
      city: "MANOR",
      state: "TX"
    },
    {
      city: "MANORVILLE",
      state: "NY"
    },
    {
      city: "MANORVILLE",
      state: "PA"
    },
    {
      city: "MANQUIN",
      state: "VA"
    },
    {
      city: "MANSFIELD",
      state: "AR"
    },
    {
      city: "MANSFIELD",
      state: "GA"
    },
    {
      city: "MANSFIELD",
      state: "IL"
    },
    {
      city: "MANSFIELD",
      state: "LA"
    },
    {
      city: "MANSFIELD",
      state: "MA"
    },
    {
      city: "MANSFIELD",
      state: "MO"
    },
    {
      city: "MANSFIELD",
      state: "OH"
    },
    {
      city: "MANSFIELD",
      state: "PA"
    },
    {
      city: "MANSFIELD",
      state: "SD"
    },
    {
      city: "MANSFIELD",
      state: "TN"
    },
    {
      city: "MANSFIELD",
      state: "TX"
    },
    {
      city: "MANSFIELD",
      state: "WA"
    },
    {
      city: "MANSFIELD CENTER",
      state: "CT"
    },
    {
      city: "MANSFIELD DEPOT",
      state: "CT"
    },
    {
      city: "MANSON",
      state: "IA"
    },
    {
      city: "MANSON",
      state: "NC"
    },
    {
      city: "MANSON",
      state: "WA"
    },
    {
      city: "MANSURA",
      state: "LA"
    },
    {
      city: "MANTACHIE",
      state: "MS"
    },
    {
      city: "MANTADOR",
      state: "ND"
    },
    {
      city: "MANTECA",
      state: "CA"
    },
    {
      city: "MANTEE",
      state: "MS"
    },
    {
      city: "MANTENO",
      state: "IL"
    },
    {
      city: "MANTEO",
      state: "NC"
    },
    {
      city: "MANTER",
      state: "KS"
    },
    {
      city: "MANTI",
      state: "UT"
    },
    {
      city: "MANTOLOKING",
      state: "NJ"
    },
    {
      city: "MANTON",
      state: "CA"
    },
    {
      city: "MANTON",
      state: "MI"
    },
    {
      city: "MANTORVILLE",
      state: "MN"
    },
    {
      city: "MANTUA",
      state: "NJ"
    },
    {
      city: "MANTUA",
      state: "OH"
    },
    {
      city: "MANTUA",
      state: "UT"
    },
    {
      city: "MANVEL",
      state: "ND"
    },
    {
      city: "MANVEL",
      state: "TX"
    },
    {
      city: "MANVILLE",
      state: "NJ"
    },
    {
      city: "MANVILLE",
      state: "RI"
    },
    {
      city: "MANVILLE",
      state: "WY"
    },
    {
      city: "MANY",
      state: "LA"
    },
    {
      city: "MANY FARMS",
      state: "AZ"
    },
    {
      city: "MANZANITA",
      state: "OR"
    },
    {
      city: "MANZANOLA",
      state: "CO"
    },
    {
      city: "MAPAVILLE",
      state: "MO"
    },
    {
      city: "MAPLE",
      state: "NC"
    },
    {
      city: "MAPLE",
      state: "WI"
    },
    {
      city: "MAPLE CITY",
      state: "KS"
    },
    {
      city: "MAPLE CITY",
      state: "MI"
    },
    {
      city: "MAPLE FALLS",
      state: "WA"
    },
    {
      city: "MAPLE HEIGHTS",
      state: "OH"
    },
    {
      city: "MAPLE HILL",
      state: "KS"
    },
    {
      city: "MAPLE HILL",
      state: "NC"
    },
    {
      city: "MAPLE LAKE",
      state: "MN"
    },
    {
      city: "MAPLE MOUNT",
      state: "KY"
    },
    {
      city: "MAPLE PARK",
      state: "IL"
    },
    {
      city: "MAPLE PLAIN",
      state: "MN"
    },
    {
      city: "MAPLE RAPIDS",
      state: "MI"
    },
    {
      city: "MAPLE SHADE",
      state: "NJ"
    },
    {
      city: "MAPLE SPRINGS",
      state: "NY"
    },
    {
      city: "MAPLE VALLEY",
      state: "WA"
    },
    {
      city: "MAPLE VIEW",
      state: "NY"
    },
    {
      city: "MAPLECREST",
      state: "NY"
    },
    {
      city: "MAPLESVILLE",
      state: "AL"
    },
    {
      city: "MAPLETON",
      state: "IA"
    },
    {
      city: "MAPLETON",
      state: "IL"
    },
    {
      city: "MAPLETON",
      state: "KS"
    },
    {
      city: "MAPLETON",
      state: "ME"
    },
    {
      city: "MAPLETON",
      state: "MN"
    },
    {
      city: "MAPLETON",
      state: "ND"
    },
    {
      city: "MAPLETON",
      state: "OR"
    },
    {
      city: "MAPLETON",
      state: "UT"
    },
    {
      city: "MAPLETON DEPOT",
      state: "PA"
    },
    {
      city: "MAPLEVILLE",
      state: "RI"
    },
    {
      city: "MAPLEWOOD",
      state: "NJ"
    },
    {
      city: "MAPLEWOOD",
      state: "OH"
    },
    {
      city: "MAPLEWOOD",
      state: "WI"
    },
    {
      city: "MAPPSVILLE",
      state: "VA"
    },
    {
      city: "MAQUOKETA",
      state: "IA"
    },
    {
      city: "MAQUON",
      state: "IL"
    },
    {
      city: "MAR LIN",
      state: "PA"
    },
    {
      city: "MARAMEC",
      state: "OK"
    },
    {
      city: "MARANA",
      state: "AZ"
    },
    {
      city: "MARATHON",
      state: "FL"
    },
    {
      city: "MARATHON",
      state: "IA"
    },
    {
      city: "MARATHON",
      state: "NY"
    },
    {
      city: "MARATHON",
      state: "TX"
    },
    {
      city: "MARATHON",
      state: "WI"
    },
    {
      city: "MARATHON SHORES",
      state: "FL"
    },
    {
      city: "MARBLE",
      state: "MN"
    },
    {
      city: "MARBLE",
      state: "NC"
    },
    {
      city: "MARBLE",
      state: "PA"
    },
    {
      city: "MARBLE CANYON",
      state: "AZ"
    },
    {
      city: "MARBLE CITY",
      state: "OK"
    },
    {
      city: "MARBLE FALLS",
      state: "AR"
    },
    {
      city: "MARBLE FALLS",
      state: "TX"
    },
    {
      city: "MARBLE HILL",
      state: "GA"
    },
    {
      city: "MARBLE HILL",
      state: "MO"
    },
    {
      city: "MARBLE ROCK",
      state: "IA"
    },
    {
      city: "MARBLEHEAD",
      state: "MA"
    },
    {
      city: "MARBLEMOUNT",
      state: "WA"
    },
    {
      city: "MARBURY",
      state: "AL"
    },
    {
      city: "MARBURY",
      state: "MD"
    },
    {
      city: "MARCELINE",
      state: "MO"
    },
    {
      city: "MARCELL",
      state: "MN"
    },
    {
      city: "MARCELLA",
      state: "AR"
    },
    {
      city: "MARCELLUS",
      state: "MI"
    },
    {
      city: "MARCELLUS",
      state: "NY"
    },
    {
      city: "MARCH AIR RESERVE BASE",
      state: "CA"
    },
    {
      city: "MARCO ISLAND",
      state: "FL"
    },
    {
      city: "MARCOLA",
      state: "OR"
    },
    {
      city: "MARCUS",
      state: "IA"
    },
    {
      city: "MARCUS",
      state: "WA"
    },
    {
      city: "MARCUS HOOK",
      state: "PA"
    },
    {
      city: "MARCY",
      state: "NY"
    },
    {
      city: "MARDELA SPRINGS",
      state: "MD"
    },
    {
      city: "MARENGO",
      state: "IA"
    },
    {
      city: "MARENGO",
      state: "IL"
    },
    {
      city: "MARENGO",
      state: "IN"
    },
    {
      city: "MARENGO",
      state: "OH"
    },
    {
      city: "MARENGO",
      state: "WI"
    },
    {
      city: "MARENISCO",
      state: "MI"
    },
    {
      city: "MARFA",
      state: "TX"
    },
    {
      city: "MARGARET",
      state: "AL"
    },
    {
      city: "MARGARETTSVILLE",
      state: "NC"
    },
    {
      city: "MARGARETVILLE",
      state: "NY"
    },
    {
      city: "MARGATE",
      state: "FL"
    },
    {
      city: "MARGATE CITY",
      state: "NJ"
    },
    {
      city: "MARIA STEIN",
      state: "OH"
    },
    {
      city: "MARIAH HILL",
      state: "IN"
    },
    {
      city: "MARIANNA",
      state: "AR"
    },
    {
      city: "MARIANNA",
      state: "FL"
    },
    {
      city: "MARIANNA",
      state: "PA"
    },
    {
      city: "MARIANNA",
      state: "WV"
    },
    {
      city: "MARIBEL",
      state: "WI"
    },
    {
      city: "MARICOPA",
      state: "AZ"
    },
    {
      city: "MARICOPA",
      state: "CA"
    },
    {
      city: "MARIENTHAL",
      state: "KS"
    },
    {
      city: "MARIENVILLE",
      state: "PA"
    },
    {
      city: "MARIETTA",
      state: "GA"
    },
    {
      city: "MARIETTA",
      state: "IL"
    },
    {
      city: "MARIETTA",
      state: "MN"
    },
    {
      city: "MARIETTA",
      state: "MS"
    },
    {
      city: "MARIETTA",
      state: "NC"
    },
    {
      city: "MARIETTA",
      state: "NY"
    },
    {
      city: "MARIETTA",
      state: "OH"
    },
    {
      city: "MARIETTA",
      state: "OK"
    },
    {
      city: "MARIETTA",
      state: "PA"
    },
    {
      city: "MARIETTA",
      state: "SC"
    },
    {
      city: "MARIETTA",
      state: "TX"
    },
    {
      city: "MARILLA",
      state: "NY"
    },
    {
      city: "MARINA",
      state: "CA"
    },
    {
      city: "MARINA DEL REY",
      state: "CA"
    },
    {
      city: "MARINE",
      state: "IL"
    },
    {
      city: "MARINE CITY",
      state: "MI"
    },
    {
      city: "MARINE ON SAINT CROIX",
      state: "MN"
    },
    {
      city: "MARINETTE",
      state: "WI"
    },
    {
      city: "MARINGOUIN",
      state: "LA"
    },
    {
      city: "MARION",
      state: "AL"
    },
    {
      city: "MARION",
      state: "AR"
    },
    {
      city: "MARION",
      state: "CT"
    },
    {
      city: "MARION",
      state: "IA"
    },
    {
      city: "MARION",
      state: "IL"
    },
    {
      city: "MARION",
      state: "IN"
    },
    {
      city: "MARION",
      state: "KS"
    },
    {
      city: "MARION",
      state: "KY"
    },
    {
      city: "MARION",
      state: "LA"
    },
    {
      city: "MARION",
      state: "MA"
    },
    {
      city: "MARION",
      state: "MI"
    },
    {
      city: "MARION",
      state: "MS"
    },
    {
      city: "MARION",
      state: "MT"
    },
    {
      city: "MARION",
      state: "NC"
    },
    {
      city: "MARION",
      state: "ND"
    },
    {
      city: "MARION",
      state: "NY"
    },
    {
      city: "MARION",
      state: "OH"
    },
    {
      city: "MARION",
      state: "PA"
    },
    {
      city: "MARION",
      state: "SC"
    },
    {
      city: "MARION",
      state: "SD"
    },
    {
      city: "MARION",
      state: "TX"
    },
    {
      city: "MARION",
      state: "VA"
    },
    {
      city: "MARION",
      state: "WI"
    },
    {
      city: "MARION CENTER",
      state: "PA"
    },
    {
      city: "MARION HEIGHTS",
      state: "PA"
    },
    {
      city: "MARION JUNCTION",
      state: "AL"
    },
    {
      city: "MARION STATION",
      state: "MD"
    },
    {
      city: "MARIONVILLE",
      state: "MO"
    },
    {
      city: "MARIONVILLE",
      state: "VA"
    },
    {
      city: "MARIPOSA",
      state: "CA"
    },
    {
      city: "MARISSA",
      state: "IL"
    },
    {
      city: "MARK",
      state: "IL"
    },
    {
      city: "MARK CENTER",
      state: "OH"
    },
    {
      city: "MARKED TREE",
      state: "AR"
    },
    {
      city: "MARKESAN",
      state: "WI"
    },
    {
      city: "MARKHAM",
      state: "IL"
    },
    {
      city: "MARKHAM",
      state: "TX"
    },
    {
      city: "MARKHAM",
      state: "VA"
    },
    {
      city: "MARKLE",
      state: "IN"
    },
    {
      city: "MARKLEEVILLE",
      state: "CA"
    },
    {
      city: "MARKLETON",
      state: "PA"
    },
    {
      city: "MARKLEVILLE",
      state: "IN"
    },
    {
      city: "MARKLEYSBURG",
      state: "PA"
    },
    {
      city: "MARKS",
      state: "MS"
    },
    {
      city: "MARKSVILLE",
      state: "LA"
    },
    {
      city: "MARLAND",
      state: "OK"
    },
    {
      city: "MARLBORO",
      state: "NJ"
    },
    {
      city: "MARLBORO",
      state: "NY"
    },
    {
      city: "MARLBORO",
      state: "VT"
    },
    {
      city: "MARLBOROUGH",
      state: "CT"
    },
    {
      city: "MARLBOROUGH",
      state: "MA"
    },
    {
      city: "MARLBOROUGH",
      state: "NH"
    },
    {
      city: "MARLETTE",
      state: "MI"
    },
    {
      city: "MARLIN",
      state: "TX"
    },
    {
      city: "MARLIN",
      state: "WA"
    },
    {
      city: "MARLINTON",
      state: "WV"
    },
    {
      city: "MARLOW",
      state: "NH"
    },
    {
      city: "MARLOW",
      state: "OK"
    },
    {
      city: "MARLTON",
      state: "NJ"
    },
    {
      city: "MARMADUKE",
      state: "AR"
    },
    {
      city: "MARMARTH",
      state: "ND"
    },
    {
      city: "MARMORA",
      state: "NJ"
    },
    {
      city: "MARNE",
      state: "IA"
    },
    {
      city: "MARNE",
      state: "MI"
    },
    {
      city: "MAROA",
      state: "IL"
    },
    {
      city: "MARQUAND",
      state: "MO"
    },
    {
      city: "MARQUETTE",
      state: "IA"
    },
    {
      city: "MARQUETTE",
      state: "KS"
    },
    {
      city: "MARQUETTE",
      state: "MI"
    },
    {
      city: "MARQUETTE",
      state: "NE"
    },
    {
      city: "MARQUETTE",
      state: "WI"
    },
    {
      city: "MARQUEZ",
      state: "TX"
    },
    {
      city: "MARRERO",
      state: "LA"
    },
    {
      city: "MARRIOTTSVILLE",
      state: "MD"
    },
    {
      city: "MARROWBONE",
      state: "KY"
    },
    {
      city: "MARS",
      state: "PA"
    },
    {
      city: "MARS HILL",
      state: "ME"
    },
    {
      city: "MARS HILL",
      state: "NC"
    },
    {
      city: "MARSEILLES",
      state: "IL"
    },
    {
      city: "MARSHALL",
      state: "AK"
    },
    {
      city: "MARSHALL",
      state: "AR"
    },
    {
      city: "MARSHALL",
      state: "CA"
    },
    {
      city: "MARSHALL",
      state: "IL"
    },
    {
      city: "MARSHALL",
      state: "IN"
    },
    {
      city: "MARSHALL",
      state: "MI"
    },
    {
      city: "MARSHALL",
      state: "MN"
    },
    {
      city: "MARSHALL",
      state: "MO"
    },
    {
      city: "MARSHALL",
      state: "NC"
    },
    {
      city: "MARSHALL",
      state: "OK"
    },
    {
      city: "MARSHALL",
      state: "TX"
    },
    {
      city: "MARSHALL",
      state: "VA"
    },
    {
      city: "MARSHALL",
      state: "WA"
    },
    {
      city: "MARSHALL",
      state: "WI"
    },
    {
      city: "MARSHALLBERG",
      state: "NC"
    },
    {
      city: "MARSHALLS CREEK",
      state: "PA"
    },
    {
      city: "MARSHALLTOWN",
      state: "IA"
    },
    {
      city: "MARSHALLVILLE",
      state: "GA"
    },
    {
      city: "MARSHALLVILLE",
      state: "OH"
    },
    {
      city: "MARSHES SIDING",
      state: "KY"
    },
    {
      city: "MARSHFIELD",
      state: "MA"
    },
    {
      city: "MARSHFIELD",
      state: "MO"
    },
    {
      city: "MARSHFIELD",
      state: "VT"
    },
    {
      city: "MARSHFIELD",
      state: "WI"
    },
    {
      city: "MARSHFIELD HILLS",
      state: "MA"
    },
    {
      city: "MARSHVILLE",
      state: "NC"
    },
    {
      city: "MARSING",
      state: "ID"
    },
    {
      city: "MARSTELLER",
      state: "PA"
    },
    {
      city: "MARSTON",
      state: "MO"
    },
    {
      city: "MARSTON",
      state: "NC"
    },
    {
      city: "MARSTONS MILLS",
      state: "MA"
    },
    {
      city: "MART",
      state: "TX"
    },
    {
      city: "MARTEL",
      state: "OH"
    },
    {
      city: "MARTELL",
      state: "CA"
    },
    {
      city: "MARTELL",
      state: "NE"
    },
    {
      city: "MARTELLE",
      state: "IA"
    },
    {
      city: "MARTENSDALE",
      state: "IA"
    },
    {
      city: "MARTHA",
      state: "KY"
    },
    {
      city: "MARTHA",
      state: "OK"
    },
    {
      city: "MARTHASVILLE",
      state: "MO"
    },
    {
      city: "MARTHAVILLE",
      state: "LA"
    },
    {
      city: "MARTIN",
      state: "GA"
    },
    {
      city: "MARTIN",
      state: "KY"
    },
    {
      city: "MARTIN",
      state: "MI"
    },
    {
      city: "MARTIN",
      state: "ND"
    },
    {
      city: "MARTIN",
      state: "OH"
    },
    {
      city: "MARTIN",
      state: "PA"
    },
    {
      city: "MARTIN",
      state: "SC"
    },
    {
      city: "MARTIN",
      state: "SD"
    },
    {
      city: "MARTIN",
      state: "TN"
    },
    {
      city: "MARTIN CITY",
      state: "MT"
    },
    {
      city: "MARTINDALE",
      state: "PA"
    },
    {
      city: "MARTINDALE",
      state: "TX"
    },
    {
      city: "MARTINEZ",
      state: "CA"
    },
    {
      city: "MARTINS CREEK",
      state: "PA"
    },
    {
      city: "MARTINS FERRY",
      state: "OH"
    },
    {
      city: "MARTINSBURG",
      state: "IA"
    },
    {
      city: "MARTINSBURG",
      state: "MO"
    },
    {
      city: "MARTINSBURG",
      state: "NY"
    },
    {
      city: "MARTINSBURG",
      state: "OH"
    },
    {
      city: "MARTINSBURG",
      state: "PA"
    },
    {
      city: "MARTINSBURG",
      state: "WV"
    },
    {
      city: "MARTINSDALE",
      state: "MT"
    },
    {
      city: "MARTINSVILLE",
      state: "IL"
    },
    {
      city: "MARTINSVILLE",
      state: "IN"
    },
    {
      city: "MARTINSVILLE",
      state: "NJ"
    },
    {
      city: "MARTINSVILLE",
      state: "OH"
    },
    {
      city: "MARTINSVILLE",
      state: "TX"
    },
    {
      city: "MARTINSVILLE",
      state: "VA"
    },
    {
      city: "MARTINTON",
      state: "IL"
    },
    {
      city: "MARTVILLE",
      state: "NY"
    },
    {
      city: "MARTY",
      state: "SD"
    },
    {
      city: "MARVEL",
      state: "CO"
    },
    {
      city: "MARVELL",
      state: "AR"
    },
    {
      city: "MARVIN",
      state: "SD"
    },
    {
      city: "MARY ALICE",
      state: "KY"
    },
    {
      city: "MARY D",
      state: "PA"
    },
    {
      city: "MARY ESTHER",
      state: "FL"
    },
    {
      city: "MARYDEL",
      state: "DE"
    },
    {
      city: "MARYDEL",
      state: "MD"
    },
    {
      city: "MARYKNOLL",
      state: "NY"
    },
    {
      city: "MARYLAND",
      state: "NY"
    },
    {
      city: "MARYLAND HEIGHTS",
      state: "MO"
    },
    {
      city: "MARYLAND LINE",
      state: "MD"
    },
    {
      city: "MARYLHURST",
      state: "OR"
    },
    {
      city: "MARYNEAL",
      state: "TX"
    },
    {
      city: "MARYSVALE",
      state: "UT"
    },
    {
      city: "MARYSVILLE",
      state: "CA"
    },
    {
      city: "MARYSVILLE",
      state: "IN"
    },
    {
      city: "MARYSVILLE",
      state: "KS"
    },
    {
      city: "MARYSVILLE",
      state: "MI"
    },
    {
      city: "MARYSVILLE",
      state: "MT"
    },
    {
      city: "MARYSVILLE",
      state: "OH"
    },
    {
      city: "MARYSVILLE",
      state: "PA"
    },
    {
      city: "MARYSVILLE",
      state: "WA"
    },
    {
      city: "MARYVILLE",
      state: "IL"
    },
    {
      city: "MARYVILLE",
      state: "MO"
    },
    {
      city: "MARYVILLE",
      state: "TN"
    },
    {
      city: "MASCOT",
      state: "TN"
    },
    {
      city: "MASCOT",
      state: "VA"
    },
    {
      city: "MASCOTTE",
      state: "FL"
    },
    {
      city: "MASCOUTAH",
      state: "IL"
    },
    {
      city: "MASHANTUCKET",
      state: "CT"
    },
    {
      city: "MASHPEE",
      state: "MA"
    },
    {
      city: "MASON",
      state: "IL"
    },
    {
      city: "MASON",
      state: "MI"
    },
    {
      city: "MASON",
      state: "OH"
    },
    {
      city: "MASON",
      state: "TN"
    },
    {
      city: "MASON",
      state: "TX"
    },
    {
      city: "MASON",
      state: "WI"
    },
    {
      city: "MASON",
      state: "WV"
    },
    {
      city: "MASON CITY",
      state: "IA"
    },
    {
      city: "MASON CITY",
      state: "IL"
    },
    {
      city: "MASON CITY",
      state: "NE"
    },
    {
      city: "MASONIC HOME",
      state: "KY"
    },
    {
      city: "MASONTOWN",
      state: "PA"
    },
    {
      city: "MASONTOWN",
      state: "WV"
    },
    {
      city: "MASONVILLE",
      state: "CO"
    },
    {
      city: "MASONVILLE",
      state: "IA"
    },
    {
      city: "MASONVILLE",
      state: "NY"
    },
    {
      city: "MASPETH",
      state: "NY"
    },
    {
      city: "MASS CITY",
      state: "MI"
    },
    {
      city: "MASSAPEQUA",
      state: "NY"
    },
    {
      city: "MASSAPEQUA PARK",
      state: "NY"
    },
    {
      city: "MASSENA",
      state: "IA"
    },
    {
      city: "MASSENA",
      state: "NY"
    },
    {
      city: "MASSEY",
      state: "MD"
    },
    {
      city: "MASSILLON",
      state: "OH"
    },
    {
      city: "MASTERSON",
      state: "TX"
    },
    {
      city: "MASTIC",
      state: "NY"
    },
    {
      city: "MASTIC BEACH",
      state: "NY"
    },
    {
      city: "MASURY",
      state: "OH"
    },
    {
      city: "MATADOR",
      state: "TX"
    },
    {
      city: "MATAGORDA",
      state: "TX"
    },
    {
      city: "MATAMORAS",
      state: "PA"
    },
    {
      city: "MATAWAN",
      state: "NJ"
    },
    {
      city: "MATEWAN",
      state: "WV"
    },
    {
      city: "MATFIELD GREEN",
      state: "KS"
    },
    {
      city: "MATHENY",
      state: "WV"
    },
    {
      city: "MATHER",
      state: "CA"
    },
    {
      city: "MATHER",
      state: "PA"
    },
    {
      city: "MATHER",
      state: "WI"
    },
    {
      city: "MATHERVILLE",
      state: "IL"
    },
    {
      city: "MATHESON",
      state: "CO"
    },
    {
      city: "MATHEWS",
      state: "AL"
    },
    {
      city: "MATHEWS",
      state: "LA"
    },
    {
      city: "MATHEWS",
      state: "VA"
    },
    {
      city: "MATHIAS",
      state: "WV"
    },
    {
      city: "MATHIS",
      state: "TX"
    },
    {
      city: "MATHISTON",
      state: "MS"
    },
    {
      city: "MATINICUS",
      state: "ME"
    },
    {
      city: "MATLOCK",
      state: "IA"
    },
    {
      city: "MATLOCK",
      state: "WA"
    },
    {
      city: "MATOAKA",
      state: "WV"
    },
    {
      city: "MATTAPAN",
      state: "MA"
    },
    {
      city: "MATTAPOISETT",
      state: "MA"
    },
    {
      city: "MATTAPONI",
      state: "VA"
    },
    {
      city: "MATTAWA",
      state: "WA"
    },
    {
      city: "MATTAWAMKEAG",
      state: "ME"
    },
    {
      city: "MATTAWAN",
      state: "MI"
    },
    {
      city: "MATTAWANA",
      state: "PA"
    },
    {
      city: "MATTESON",
      state: "IL"
    },
    {
      city: "MATTHEWS",
      state: "GA"
    },
    {
      city: "MATTHEWS",
      state: "IN"
    },
    {
      city: "MATTHEWS",
      state: "MO"
    },
    {
      city: "MATTHEWS",
      state: "NC"
    },
    {
      city: "MATTITUCK",
      state: "NY"
    },
    {
      city: "MATTOON",
      state: "IL"
    },
    {
      city: "MATTOON",
      state: "WI"
    },
    {
      city: "MAUCKPORT",
      state: "IN"
    },
    {
      city: "MAUD",
      state: "OK"
    },
    {
      city: "MAUD",
      state: "TX"
    },
    {
      city: "MAUGANSVILLE",
      state: "MD"
    },
    {
      city: "MAUK",
      state: "GA"
    },
    {
      city: "MAULDIN",
      state: "SC"
    },
    {
      city: "MAUMEE",
      state: "OH"
    },
    {
      city: "MAUMELLE",
      state: "AR"
    },
    {
      city: "MAUNALOA",
      state: "HI"
    },
    {
      city: "MAUPIN",
      state: "OR"
    },
    {
      city: "MAUREPAS",
      state: "LA"
    },
    {
      city: "MAURERTOWN",
      state: "VA"
    },
    {
      city: "MAURICE",
      state: "IA"
    },
    {
      city: "MAURICE",
      state: "LA"
    },
    {
      city: "MAURICETOWN",
      state: "NJ"
    },
    {
      city: "MAURICEVILLE",
      state: "TX"
    },
    {
      city: "MAURY",
      state: "NC"
    },
    {
      city: "MAURY CITY",
      state: "TN"
    },
    {
      city: "MAUSTON",
      state: "WI"
    },
    {
      city: "MAVISDALE",
      state: "VA"
    },
    {
      city: "MAX",
      state: "MN"
    },
    {
      city: "MAX",
      state: "ND"
    },
    {
      city: "MAX",
      state: "NE"
    },
    {
      city: "MAX MEADOWS",
      state: "VA"
    },
    {
      city: "MAXATAWNY",
      state: "PA"
    },
    {
      city: "MAXBASS",
      state: "ND"
    },
    {
      city: "MAXEYS",
      state: "GA"
    },
    {
      city: "MAXIE",
      state: "VA"
    },
    {
      city: "MAXIMO",
      state: "OH"
    },
    {
      city: "MAXTON",
      state: "NC"
    },
    {
      city: "MAXWELL",
      state: "CA"
    },
    {
      city: "MAXWELL",
      state: "IA"
    },
    {
      city: "MAXWELL",
      state: "IN"
    },
    {
      city: "MAXWELL",
      state: "NE"
    },
    {
      city: "MAXWELL",
      state: "NM"
    },
    {
      city: "MAXWELL",
      state: "TX"
    },
    {
      city: "MAXWELTON",
      state: "WV"
    },
    {
      city: "MAY",
      state: "ID"
    },
    {
      city: "MAY",
      state: "OK"
    },
    {
      city: "MAY",
      state: "TX"
    },
    {
      city: "MAYBEE",
      state: "MI"
    },
    {
      city: "MAYBELL",
      state: "CO"
    },
    {
      city: "MAYBEURY",
      state: "WV"
    },
    {
      city: "MAYBROOK",
      state: "NY"
    },
    {
      city: "MAYDELLE",
      state: "TX"
    },
    {
      city: "MAYER",
      state: "AZ"
    },
    {
      city: "MAYER",
      state: "MN"
    },
    {
      city: "MAYERSVILLE",
      state: "MS"
    },
    {
      city: "MAYESVILLE",
      state: "SC"
    },
    {
      city: "MAYETTA",
      state: "KS"
    },
    {
      city: "MAYFIELD",
      state: "KS"
    },
    {
      city: "MAYFIELD",
      state: "KY"
    },
    {
      city: "MAYFIELD",
      state: "MI"
    },
    {
      city: "MAYFIELD",
      state: "NY"
    },
    {
      city: "MAYFIELD",
      state: "UT"
    },
    {
      city: "MAYFLOWER",
      state: "AR"
    },
    {
      city: "MAYHEW",
      state: "MS"
    },
    {
      city: "MAYHILL",
      state: "NM"
    },
    {
      city: "MAYKING",
      state: "KY"
    },
    {
      city: "MAYLENE",
      state: "AL"
    },
    {
      city: "MAYNARD",
      state: "AR"
    },
    {
      city: "MAYNARD",
      state: "IA"
    },
    {
      city: "MAYNARD",
      state: "MA"
    },
    {
      city: "MAYNARD",
      state: "MN"
    },
    {
      city: "MAYNARD",
      state: "OH"
    },
    {
      city: "MAYNARDVILLE",
      state: "TN"
    },
    {
      city: "MAYO",
      state: "FL"
    },
    {
      city: "MAYO",
      state: "MD"
    },
    {
      city: "MAYO",
      state: "SC"
    },
    {
      city: "MAYODAN",
      state: "NC"
    },
    {
      city: "MAYPEARL",
      state: "TX"
    },
    {
      city: "MAYPORT",
      state: "PA"
    },
    {
      city: "MAYS",
      state: "IN"
    },
    {
      city: "MAYS LANDING",
      state: "NJ"
    },
    {
      city: "MAYSEL",
      state: "WV"
    },
    {
      city: "MAYSLICK",
      state: "KY"
    },
    {
      city: "MAYSVILLE",
      state: "AR"
    },
    {
      city: "MAYSVILLE",
      state: "GA"
    },
    {
      city: "MAYSVILLE",
      state: "KY"
    },
    {
      city: "MAYSVILLE",
      state: "MO"
    },
    {
      city: "MAYSVILLE",
      state: "NC"
    },
    {
      city: "MAYSVILLE",
      state: "OK"
    },
    {
      city: "MAYSVILLE",
      state: "WV"
    },
    {
      city: "MAYTOWN",
      state: "PA"
    },
    {
      city: "MAYVIEW",
      state: "MO"
    },
    {
      city: "MAYVILLE",
      state: "MI"
    },
    {
      city: "MAYVILLE",
      state: "ND"
    },
    {
      city: "MAYVILLE",
      state: "NY"
    },
    {
      city: "MAYVILLE",
      state: "WI"
    },
    {
      city: "MAYWOOD",
      state: "CA"
    },
    {
      city: "MAYWOOD",
      state: "IL"
    },
    {
      city: "MAYWOOD",
      state: "MO"
    },
    {
      city: "MAYWOOD",
      state: "NE"
    },
    {
      city: "MAYWOOD",
      state: "NJ"
    },
    {
      city: "MAZAMA",
      state: "WA"
    },
    {
      city: "MAZEPPA",
      state: "MN"
    },
    {
      city: "MAZOMANIE",
      state: "WI"
    },
    {
      city: "MAZON",
      state: "IL"
    },
    {
      city: "MC ADENVILLE",
      state: "NC"
    },
    {
      city: "MC AFEE",
      state: "NJ"
    },
    {
      city: "MC ALISTERVILLE",
      state: "PA"
    },
    {
      city: "MC ALLISTER",
      state: "MT"
    },
    {
      city: "MC ALPIN",
      state: "FL"
    },
    {
      city: "MC ANDREWS",
      state: "KY"
    },
    {
      city: "MC ARTHUR",
      state: "OH"
    },
    {
      city: "MC BAIN",
      state: "MI"
    },
    {
      city: "MC BEE",
      state: "SC"
    },
    {
      city: "MC BRIDE",
      state: "MO"
    },
    {
      city: "MC CALL CREEK",
      state: "MS"
    },
    {
      city: "MC CALLA",
      state: "AL"
    },
    {
      city: "MC CALLSBURG",
      state: "IA"
    },
    {
      city: "MC CAMEY",
      state: "TX"
    },
    {
      city: "MC CARLEY",
      state: "MS"
    },
    {
      city: "MC CARR",
      state: "KY"
    },
    {
      city: "MC CASKILL",
      state: "AR"
    },
    {
      city: "MC CAUSLAND",
      state: "IA"
    },
    {
      city: "MC CAYSVILLE",
      state: "GA"
    },
    {
      city: "MC CLAVE",
      state: "CO"
    },
    {
      city: "MC CLELLAND",
      state: "IA"
    },
    {
      city: "MC CLELLANDTOWN",
      state: "PA"
    },
    {
      city: "MC CLELLANVILLE",
      state: "SC"
    },
    {
      city: "MC CLURE",
      state: "IL"
    },
    {
      city: "MC CLURE",
      state: "OH"
    },
    {
      city: "MC CLURE",
      state: "PA"
    },
    {
      city: "MC CLURE",
      state: "VA"
    },
    {
      city: "MC COLL",
      state: "SC"
    },
    {
      city: "MC COMB",
      state: "OH"
    },
    {
      city: "MC CONNELL",
      state: "IL"
    },
    {
      city: "MC CONNELLS",
      state: "SC"
    },
    {
      city: "MC CONNELLSBURG",
      state: "PA"
    },
    {
      city: "MC CONNELLSTOWN",
      state: "PA"
    },
    {
      city: "MC CONNELLSVILLE",
      state: "NY"
    },
    {
      city: "MC COOK",
      state: "NE"
    },
    {
      city: "MC COOL",
      state: "MS"
    },
    {
      city: "MC COOL JUNCTION",
      state: "NE"
    },
    {
      city: "MC CORMICK",
      state: "SC"
    },
    {
      city: "MC COY",
      state: "CO"
    },
    {
      city: "MC COY",
      state: "VA"
    },
    {
      city: "MC CRACKEN",
      state: "KS"
    },
    {
      city: "MC CRORY",
      state: "AR"
    },
    {
      city: "MC CUNE",
      state: "KS"
    },
    {
      city: "MC CUTCHENVILLE",
      state: "OH"
    },
    {
      city: "MC DADE",
      state: "TX"
    },
    {
      city: "MC DANIELS",
      state: "KY"
    },
    {
      city: "MC DAVID",
      state: "FL"
    },
    {
      city: "MC DERMITT",
      state: "NV"
    },
    {
      city: "MC DERMOTT",
      state: "OH"
    },
    {
      city: "MC DONALD",
      state: "KS"
    },
    {
      city: "MC DONALD",
      state: "OH"
    },
    {
      city: "MC DONALD",
      state: "PA"
    },
    {
      city: "MC DONALD",
      state: "TN"
    },
    {
      city: "MC DONOUGH",
      state: "NY"
    },
    {
      city: "MC DOUGAL",
      state: "AR"
    },
    {
      city: "MC DOWELL",
      state: "KY"
    },
    {
      city: "MC DOWELL",
      state: "VA"
    },
    {
      city: "MC ELHATTAN",
      state: "PA"
    },
    {
      city: "MC EWEN",
      state: "TN"
    },
    {
      city: "MC EWENSVILLE",
      state: "PA"
    },
    {
      city: "MC FALL",
      state: "MO"
    },
    {
      city: "MC FARLAN",
      state: "NC"
    },
    {
      city: "MC FARLAND",
      state: "CA"
    },
    {
      city: "MC FARLAND",
      state: "KS"
    },
    {
      city: "MC FARLAND",
      state: "WI"
    },
    {
      city: "MC GAHEYSVILLE",
      state: "VA"
    },
    {
      city: "MC GEHEE",
      state: "AR"
    },
    {
      city: "MC GILL",
      state: "NV"
    },
    {
      city: "MC GIRK",
      state: "MO"
    },
    {
      city: "MC GRADY",
      state: "NC"
    },
    {
      city: "MC GRANN",
      state: "PA"
    },
    {
      city: "MC GRATH",
      state: "AK"
    },
    {
      city: "MC GRATH",
      state: "MN"
    },
    {
      city: "MC GRAW",
      state: "NY"
    },
    {
      city: "MC GREGOR",
      state: "IA"
    },
    {
      city: "MC GREGOR",
      state: "TX"
    },
    {
      city: "MC GUFFEY",
      state: "OH"
    },
    {
      city: "MC HENRY",
      state: "KY"
    },
    {
      city: "MC HENRY",
      state: "MD"
    },
    {
      city: "MC HENRY",
      state: "MS"
    },
    {
      city: "MC INDOE FALLS",
      state: "VT"
    },
    {
      city: "MC INTIRE",
      state: "IA"
    },
    {
      city: "MC INTOSH",
      state: "AL"
    },
    {
      city: "MC INTOSH",
      state: "FL"
    },
    {
      city: "MC INTOSH",
      state: "SD"
    },
    {
      city: "MC INTYRE",
      state: "GA"
    },
    {
      city: "MC INTYRE",
      state: "PA"
    },
    {
      city: "MC KEAN",
      state: "PA"
    },
    {
      city: "MC KEE",
      state: "KY"
    },
    {
      city: "MC KEES ROCKS",
      state: "PA"
    },
    {
      city: "MC KENNEY",
      state: "VA"
    },
    {
      city: "MC KENZIE",
      state: "AL"
    },
    {
      city: "MC KENZIE",
      state: "TN"
    },
    {
      city: "MC KINNEY",
      state: "KY"
    },
    {
      city: "MC KINNON",
      state: "WY"
    },
    {
      city: "MC KITTRICK",
      state: "CA"
    },
    {
      city: "MC KNIGHTSTOWN",
      state: "PA"
    },
    {
      city: "MC LAIN",
      state: "MS"
    },
    {
      city: "MC LAUGHLIN",
      state: "SD"
    },
    {
      city: "MC LEAN",
      state: "IL"
    },
    {
      city: "MC LEAN",
      state: "NY"
    },
    {
      city: "MC LEAN",
      state: "VA"
    },
    {
      city: "MC LEANSBORO",
      state: "IL"
    },
    {
      city: "MC LEANSVILLE",
      state: "NC"
    },
    {
      city: "MC LEMORESVILLE",
      state: "TN"
    },
    {
      city: "MC LEOD",
      state: "MT"
    },
    {
      city: "MC LEOD",
      state: "TX"
    },
    {
      city: "MC LOUTH",
      state: "KS"
    },
    {
      city: "MC MILLAN",
      state: "MI"
    },
    {
      city: "MC NABB",
      state: "IL"
    },
    {
      city: "MC NAUGHTON",
      state: "WI"
    },
    {
      city: "MC NEAL",
      state: "AZ"
    },
    {
      city: "MC NEIL",
      state: "AR"
    },
    {
      city: "MC NEILL",
      state: "MS"
    },
    {
      city: "MC QUADY",
      state: "KY"
    },
    {
      city: "MC QUEENEY",
      state: "TX"
    },
    {
      city: "MC RAE",
      state: "AR"
    },
    {
      city: "MC RAE",
      state: "GA"
    },
    {
      city: "MC SHAN",
      state: "AL"
    },
    {
      city: "MC SHERRYSTOWN",
      state: "PA"
    },
    {
      city: "MC VEYTOWN",
      state: "PA"
    },
    {
      city: "MC WILLIAMS",
      state: "AL"
    },
    {
      city: "MCADOO",
      state: "PA"
    },
    {
      city: "MCADOO",
      state: "TX"
    },
    {
      city: "MCALESTER",
      state: "OK"
    },
    {
      city: "MCALISTER",
      state: "NM"
    },
    {
      city: "MCALLEN",
      state: "TX"
    },
    {
      city: "MCARTHUR",
      state: "CA"
    },
    {
      city: "MCBH KANEOHE BAY",
      state: "HI"
    },
    {
      city: "MCBRIDES",
      state: "MI"
    },
    {
      city: "MCCALL",
      state: "ID"
    },
    {
      city: "MCCAMMON",
      state: "ID"
    },
    {
      city: "MCCHORD AFB",
      state: "WA"
    },
    {
      city: "MCCLEARY",
      state: "WA"
    },
    {
      city: "MCCLELLAN",
      state: "CA"
    },
    {
      city: "MCCLOUD",
      state: "CA"
    },
    {
      city: "MCCLUSKY",
      state: "ND"
    },
    {
      city: "MCCOMB",
      state: "MS"
    },
    {
      city: "MCCONNELL AFB",
      state: "KS"
    },
    {
      city: "MCCONNELSVILLE",
      state: "OH"
    },
    {
      city: "MCCORDSVILLE",
      state: "IN"
    },
    {
      city: "MCCURTAIN",
      state: "OK"
    },
    {
      city: "MCCUTCHEON FIELD",
      state: "NC"
    },
    {
      city: "MCDANIEL",
      state: "MD"
    },
    {
      city: "MCDONALD",
      state: "NM"
    },
    {
      city: "MCDONOUGH",
      state: "GA"
    },
    {
      city: "MCGREGOR",
      state: "MN"
    },
    {
      city: "MCGREGOR",
      state: "ND"
    },
    {
      city: "MCGREW",
      state: "NE"
    },
    {
      city: "MCHENRY",
      state: "IL"
    },
    {
      city: "MCHENRY",
      state: "ND"
    },
    {
      city: "MCINTOSH",
      state: "MN"
    },
    {
      city: "MCINTOSH",
      state: "NM"
    },
    {
      city: "MCKEESPORT",
      state: "PA"
    },
    {
      city: "MCKENNA",
      state: "WA"
    },
    {
      city: "MCKINLEYVILLE",
      state: "CA"
    },
    {
      city: "MCKINNEY",
      state: "TX"
    },
    {
      city: "MCLEAN",
      state: "NE"
    },
    {
      city: "MCLEAN",
      state: "TX"
    },
    {
      city: "MCLEOD",
      state: "ND"
    },
    {
      city: "MCLOUD",
      state: "OK"
    },
    {
      city: "MCMECHEN",
      state: "WV"
    },
    {
      city: "MCMINNVILLE",
      state: "OR"
    },
    {
      city: "MCMINNVILLE",
      state: "TN"
    },
    {
      city: "MCPHERSON",
      state: "KS"
    },
    {
      city: "MCVILLE",
      state: "ND"
    },
    {
      city: "MEACHAM",
      state: "OR"
    },
    {
      city: "MEAD",
      state: "CO"
    },
    {
      city: "MEAD",
      state: "NE"
    },
    {
      city: "MEAD",
      state: "OK"
    },
    {
      city: "MEAD",
      state: "WA"
    },
    {
      city: "MEADE",
      state: "KS"
    },
    {
      city: "MEADOW",
      state: "SD"
    },
    {
      city: "MEADOW",
      state: "TX"
    },
    {
      city: "MEADOW",
      state: "UT"
    },
    {
      city: "MEADOW BRIDGE",
      state: "WV"
    },
    {
      city: "MEADOW GROVE",
      state: "NE"
    },
    {
      city: "MEADOW LANDS",
      state: "PA"
    },
    {
      city: "MEADOW VALLEY",
      state: "CA"
    },
    {
      city: "MEADOW VISTA",
      state: "CA"
    },
    {
      city: "MEADOWBROOK",
      state: "WV"
    },
    {
      city: "MEADOWLANDS",
      state: "MN"
    },
    {
      city: "MEADOWS OF DAN",
      state: "VA"
    },
    {
      city: "MEADOWVIEW",
      state: "VA"
    },
    {
      city: "MEADVIEW",
      state: "AZ"
    },
    {
      city: "MEADVILLE",
      state: "MO"
    },
    {
      city: "MEADVILLE",
      state: "MS"
    },
    {
      city: "MEADVILLE",
      state: "PA"
    },
    {
      city: "MEALLY",
      state: "KY"
    },
    {
      city: "MEANS",
      state: "KY"
    },
    {
      city: "MEANSVILLE",
      state: "GA"
    },
    {
      city: "MEARS",
      state: "MI"
    },
    {
      city: "MEARS",
      state: "VA"
    },
    {
      city: "MEBANE",
      state: "NC"
    },
    {
      city: "MECCA",
      state: "CA"
    },
    {
      city: "MECHANIC FALLS",
      state: "ME"
    },
    {
      city: "MECHANICSBURG",
      state: "IL"
    },
    {
      city: "MECHANICSBURG",
      state: "OH"
    },
    {
      city: "MECHANICSBURG",
      state: "PA"
    },
    {
      city: "MECHANICSTOWN",
      state: "OH"
    },
    {
      city: "MECHANICSVILLE",
      state: "IA"
    },
    {
      city: "MECHANICSVILLE",
      state: "MD"
    },
    {
      city: "MECHANICSVILLE",
      state: "PA"
    },
    {
      city: "MECHANICSVILLE",
      state: "VA"
    },
    {
      city: "MECHANICVILLE",
      state: "NY"
    },
    {
      city: "MECOSTA",
      state: "MI"
    },
    {
      city: "MEDANALES",
      state: "NM"
    },
    {
      city: "MEDARYVILLE",
      state: "IN"
    },
    {
      city: "MEDDYBEMPS",
      state: "ME"
    },
    {
      city: "MEDFIELD",
      state: "MA"
    },
    {
      city: "MEDFORD",
      state: "MA"
    },
    {
      city: "MEDFORD",
      state: "MN"
    },
    {
      city: "MEDFORD",
      state: "NJ"
    },
    {
      city: "MEDFORD",
      state: "NY"
    },
    {
      city: "MEDFORD",
      state: "OK"
    },
    {
      city: "MEDFORD",
      state: "OR"
    },
    {
      city: "MEDFORD",
      state: "WI"
    },
    {
      city: "MEDIA",
      state: "IL"
    },
    {
      city: "MEDIA",
      state: "PA"
    },
    {
      city: "MEDIAPOLIS",
      state: "IA"
    },
    {
      city: "MEDICAL LAKE",
      state: "WA"
    },
    {
      city: "MEDICINE BOW",
      state: "WY"
    },
    {
      city: "MEDICINE LAKE",
      state: "MT"
    },
    {
      city: "MEDICINE LODGE",
      state: "KS"
    },
    {
      city: "MEDICINE PARK",
      state: "OK"
    },
    {
      city: "MEDIMONT",
      state: "ID"
    },
    {
      city: "MEDINA",
      state: "ND"
    },
    {
      city: "MEDINA",
      state: "NY"
    },
    {
      city: "MEDINA",
      state: "OH"
    },
    {
      city: "MEDINA",
      state: "TN"
    },
    {
      city: "MEDINA",
      state: "TX"
    },
    {
      city: "MEDINA",
      state: "WA"
    },
    {
      city: "MEDINAH",
      state: "IL"
    },
    {
      city: "MEDON",
      state: "TN"
    },
    {
      city: "MEDORA",
      state: "IL"
    },
    {
      city: "MEDORA",
      state: "IN"
    },
    {
      city: "MEDORA",
      state: "ND"
    },
    {
      city: "MEDUSA",
      state: "NY"
    },
    {
      city: "MEDWAY",
      state: "MA"
    },
    {
      city: "MEDWAY",
      state: "ME"
    },
    {
      city: "MEDWAY",
      state: "OH"
    },
    {
      city: "MEEKER",
      state: "CO"
    },
    {
      city: "MEEKER",
      state: "OK"
    },
    {
      city: "MEETEETSE",
      state: "WY"
    },
    {
      city: "MEGARGEL",
      state: "TX"
    },
    {
      city: "MEHAMA",
      state: "OR"
    },
    {
      city: "MEHERRIN",
      state: "VA"
    },
    {
      city: "MEHOOPANY",
      state: "PA"
    },
    {
      city: "MEIGS",
      state: "GA"
    },
    {
      city: "MEKINOCK",
      state: "ND"
    },
    {
      city: "MEKORYUK",
      state: "AK"
    },
    {
      city: "MELBA",
      state: "ID"
    },
    {
      city: "MELBER",
      state: "KY"
    },
    {
      city: "MELBETA",
      state: "NE"
    },
    {
      city: "MELBOURNE",
      state: "AR"
    },
    {
      city: "MELBOURNE",
      state: "FL"
    },
    {
      city: "MELBOURNE",
      state: "IA"
    },
    {
      city: "MELBOURNE",
      state: "KY"
    },
    {
      city: "MELBOURNE BEACH",
      state: "FL"
    },
    {
      city: "MELCHER DALLAS",
      state: "IA"
    },
    {
      city: "MELCROFT",
      state: "PA"
    },
    {
      city: "MELDRIM",
      state: "GA"
    },
    {
      city: "MELFA",
      state: "VA"
    },
    {
      city: "MELISSA",
      state: "TX"
    },
    {
      city: "MELLEN",
      state: "WI"
    },
    {
      city: "MELLENVILLE",
      state: "NY"
    },
    {
      city: "MELLETTE",
      state: "SD"
    },
    {
      city: "MELLOTT",
      state: "IN"
    },
    {
      city: "MELLWOOD",
      state: "AR"
    },
    {
      city: "MELMORE",
      state: "OH"
    },
    {
      city: "MELROSE",
      state: "FL"
    },
    {
      city: "MELROSE",
      state: "IA"
    },
    {
      city: "MELROSE",
      state: "LA"
    },
    {
      city: "MELROSE",
      state: "MA"
    },
    {
      city: "MELROSE",
      state: "MN"
    },
    {
      city: "MELROSE",
      state: "MT"
    },
    {
      city: "MELROSE",
      state: "NM"
    },
    {
      city: "MELROSE",
      state: "NY"
    },
    {
      city: "MELROSE",
      state: "OH"
    },
    {
      city: "MELROSE",
      state: "WI"
    },
    {
      city: "MELROSE PARK",
      state: "IL"
    },
    {
      city: "MELSTONE",
      state: "MT"
    },
    {
      city: "MELVERN",
      state: "KS"
    },
    {
      city: "MELVILLE",
      state: "LA"
    },
    {
      city: "MELVILLE",
      state: "NY"
    },
    {
      city: "MELVIN",
      state: "AL"
    },
    {
      city: "MELVIN",
      state: "IA"
    },
    {
      city: "MELVIN",
      state: "IL"
    },
    {
      city: "MELVIN",
      state: "KY"
    },
    {
      city: "MELVIN",
      state: "MI"
    },
    {
      city: "MELVIN",
      state: "TX"
    },
    {
      city: "MELVIN VILLAGE",
      state: "NH"
    },
    {
      city: "MELVINDALE",
      state: "MI"
    },
    {
      city: "MEMPHIS",
      state: "IN"
    },
    {
      city: "MEMPHIS",
      state: "MI"
    },
    {
      city: "MEMPHIS",
      state: "MO"
    },
    {
      city: "MEMPHIS",
      state: "NE"
    },
    {
      city: "MEMPHIS",
      state: "NY"
    },
    {
      city: "MEMPHIS",
      state: "TN"
    },
    {
      city: "MEMPHIS",
      state: "TX"
    },
    {
      city: "MENA",
      state: "AR"
    },
    {
      city: "MENAHGA",
      state: "MN"
    },
    {
      city: "MENAN",
      state: "ID"
    },
    {
      city: "MENARD",
      state: "IL"
    },
    {
      city: "MENARD",
      state: "TX"
    },
    {
      city: "MENASHA",
      state: "WI"
    },
    {
      city: "MENDENHALL",
      state: "MS"
    },
    {
      city: "MENDENHALL",
      state: "PA"
    },
    {
      city: "MENDHAM",
      state: "NJ"
    },
    {
      city: "MENDOCINO",
      state: "CA"
    },
    {
      city: "MENDON",
      state: "IL"
    },
    {
      city: "MENDON",
      state: "MA"
    },
    {
      city: "MENDON",
      state: "MI"
    },
    {
      city: "MENDON",
      state: "MO"
    },
    {
      city: "MENDON",
      state: "NY"
    },
    {
      city: "MENDON",
      state: "OH"
    },
    {
      city: "MENDON",
      state: "UT"
    },
    {
      city: "MENDOTA",
      state: "CA"
    },
    {
      city: "MENDOTA",
      state: "IL"
    },
    {
      city: "MENDOTA",
      state: "MN"
    },
    {
      city: "MENDOTA",
      state: "VA"
    },
    {
      city: "MENEMSHA",
      state: "MA"
    },
    {
      city: "MENIFEE",
      state: "AR"
    },
    {
      city: "MENIFEE",
      state: "CA"
    },
    {
      city: "MENLO",
      state: "GA"
    },
    {
      city: "MENLO",
      state: "IA"
    },
    {
      city: "MENLO",
      state: "WA"
    },
    {
      city: "MENLO PARK",
      state: "CA"
    },
    {
      city: "MENNO",
      state: "SD"
    },
    {
      city: "MENO",
      state: "OK"
    },
    {
      city: "MENOKEN",
      state: "ND"
    },
    {
      city: "MENOMINEE",
      state: "MI"
    },
    {
      city: "MENOMONEE FALLS",
      state: "WI"
    },
    {
      city: "MENOMONIE",
      state: "WI"
    },
    {
      city: "MENTCLE",
      state: "PA"
    },
    {
      city: "MENTMORE",
      state: "NM"
    },
    {
      city: "MENTONE",
      state: "AL"
    },
    {
      city: "MENTONE",
      state: "CA"
    },
    {
      city: "MENTONE",
      state: "IN"
    },
    {
      city: "MENTONE",
      state: "TX"
    },
    {
      city: "MENTOR",
      state: "MN"
    },
    {
      city: "MENTOR",
      state: "OH"
    },
    {
      city: "MEQUON",
      state: "WI"
    },
    {
      city: "MER ROUGE",
      state: "LA"
    },
    {
      city: "MERAUX",
      state: "LA"
    },
    {
      city: "MERCED",
      state: "CA"
    },
    {
      city: "MERCEDES",
      state: "TX"
    },
    {
      city: "MERCER",
      state: "MO"
    },
    {
      city: "MERCER",
      state: "ND"
    },
    {
      city: "MERCER",
      state: "PA"
    },
    {
      city: "MERCER",
      state: "TN"
    },
    {
      city: "MERCER",
      state: "WI"
    },
    {
      city: "MERCER ISLAND",
      state: "WA"
    },
    {
      city: "MERCERSBURG",
      state: "PA"
    },
    {
      city: "MERCHANTVILLE",
      state: "NJ"
    },
    {
      city: "MERCURY",
      state: "NV"
    },
    {
      city: "MEREDITH",
      state: "CO"
    },
    {
      city: "MEREDITH",
      state: "NH"
    },
    {
      city: "MEREDOSIA",
      state: "IL"
    },
    {
      city: "MERETA",
      state: "TX"
    },
    {
      city: "MERIDEN",
      state: "CT"
    },
    {
      city: "MERIDEN",
      state: "IA"
    },
    {
      city: "MERIDEN",
      state: "KS"
    },
    {
      city: "MERIDEN",
      state: "NH"
    },
    {
      city: "MERIDIAN",
      state: "CA"
    },
    {
      city: "MERIDIAN",
      state: "GA"
    },
    {
      city: "MERIDIAN",
      state: "ID"
    },
    {
      city: "MERIDIAN",
      state: "MS"
    },
    {
      city: "MERIDIAN",
      state: "NY"
    },
    {
      city: "MERIDIAN",
      state: "OK"
    },
    {
      city: "MERIDIAN",
      state: "TX"
    },
    {
      city: "MERIDIANVILLE",
      state: "AL"
    },
    {
      city: "MERIGOLD",
      state: "MS"
    },
    {
      city: "MERINO",
      state: "CO"
    },
    {
      city: "MERION STATION",
      state: "PA"
    },
    {
      city: "MERIT",
      state: "TX"
    },
    {
      city: "MERKEL",
      state: "TX"
    },
    {
      city: "MERLIN",
      state: "OR"
    },
    {
      city: "MERMENTAU",
      state: "LA"
    },
    {
      city: "MERNA",
      state: "IL"
    },
    {
      city: "MERNA",
      state: "NE"
    },
    {
      city: "MEROM",
      state: "IN"
    },
    {
      city: "MERRICK",
      state: "NY"
    },
    {
      city: "MERRIFIELD",
      state: "MN"
    },
    {
      city: "MERRIFIELD",
      state: "VA"
    },
    {
      city: "MERRILL",
      state: "IA"
    },
    {
      city: "MERRILL",
      state: "MI"
    },
    {
      city: "MERRILL",
      state: "OR"
    },
    {
      city: "MERRILL",
      state: "WI"
    },
    {
      city: "MERRILLAN",
      state: "WI"
    },
    {
      city: "MERRILLVILLE",
      state: "IN"
    },
    {
      city: "MERRIMAC",
      state: "MA"
    },
    {
      city: "MERRIMAC",
      state: "WI"
    },
    {
      city: "MERRIMACK",
      state: "NH"
    },
    {
      city: "MERRIMAN",
      state: "NE"
    },
    {
      city: "MERRITT",
      state: "MI"
    },
    {
      city: "MERRITT",
      state: "NC"
    },
    {
      city: "MERRITT ISLAND",
      state: "FL"
    },
    {
      city: "MERRITTSTOWN",
      state: "PA"
    },
    {
      city: "MERRY HILL",
      state: "NC"
    },
    {
      city: "MERRYVILLE",
      state: "LA"
    },
    {
      city: "MERSHON",
      state: "GA"
    },
    {
      city: "MERTENS",
      state: "TX"
    },
    {
      city: "MERTON",
      state: "WI"
    },
    {
      city: "MERTZON",
      state: "TX"
    },
    {
      city: "MERTZTOWN",
      state: "PA"
    },
    {
      city: "MESA",
      state: "AZ"
    },
    {
      city: "MESA",
      state: "CO"
    },
    {
      city: "MESA",
      state: "ID"
    },
    {
      city: "MESA",
      state: "WA"
    },
    {
      city: "MESA VERDE NATIONAL PARK",
      state: "CO"
    },
    {
      city: "MESCALERO",
      state: "NM"
    },
    {
      city: "MESERVEY",
      state: "IA"
    },
    {
      city: "MESHOPPEN",
      state: "PA"
    },
    {
      city: "MESICK",
      state: "MI"
    },
    {
      city: "MESILLA",
      state: "NM"
    },
    {
      city: "MESILLA PARK",
      state: "NM"
    },
    {
      city: "MESOPOTAMIA",
      state: "OH"
    },
    {
      city: "MESQUITE",
      state: "NM"
    },
    {
      city: "MESQUITE",
      state: "NV"
    },
    {
      city: "MESQUITE",
      state: "TX"
    },
    {
      city: "META",
      state: "MO"
    },
    {
      city: "METAIRIE",
      state: "LA"
    },
    {
      city: "METALINE",
      state: "WA"
    },
    {
      city: "METALINE FALLS",
      state: "WA"
    },
    {
      city: "METAMORA",
      state: "IL"
    },
    {
      city: "METAMORA",
      state: "IN"
    },
    {
      city: "METAMORA",
      state: "MI"
    },
    {
      city: "METAMORA",
      state: "OH"
    },
    {
      city: "METCALF",
      state: "IL"
    },
    {
      city: "METCALFE",
      state: "MS"
    },
    {
      city: "METHOW",
      state: "WA"
    },
    {
      city: "METHUEN",
      state: "MA"
    },
    {
      city: "METLAKATLA",
      state: "AK"
    },
    {
      city: "METROPOLIS",
      state: "IL"
    },
    {
      city: "METTER",
      state: "GA"
    },
    {
      city: "METUCHEN",
      state: "NJ"
    },
    {
      city: "METZ",
      state: "MO"
    },
    {
      city: "METZ",
      state: "WV"
    },
    {
      city: "MEXIA",
      state: "AL"
    },
    {
      city: "MEXIA",
      state: "TX"
    },
    {
      city: "MEXICAN HAT",
      state: "UT"
    },
    {
      city: "MEXICAN SPRINGS",
      state: "NM"
    },
    {
      city: "MEXICO",
      state: "IN"
    },
    {
      city: "MEXICO",
      state: "ME"
    },
    {
      city: "MEXICO",
      state: "MO"
    },
    {
      city: "MEXICO",
      state: "NY"
    },
    {
      city: "MEXICO",
      state: "PA"
    },
    {
      city: "MEXICO BEACH",
      state: "FL"
    },
    {
      city: "MEYERSDALE",
      state: "PA"
    },
    {
      city: "MEYERSVILLE",
      state: "TX"
    },
    {
      city: "MI WUK VILLAGE",
      state: "CA"
    },
    {
      city: "MIAMI",
      state: "AZ"
    },
    {
      city: "MIAMI",
      state: "FL"
    },
    {
      city: "MIAMI",
      state: "IN"
    },
    {
      city: "MIAMI",
      state: "MO"
    },
    {
      city: "MIAMI",
      state: "NM"
    },
    {
      city: "MIAMI",
      state: "OK"
    },
    {
      city: "MIAMI",
      state: "TX"
    },
    {
      city: "MIAMI",
      state: "WV"
    },
    {
      city: "MIAMI BEACH",
      state: "FL"
    },
    {
      city: "MIAMI GARDENS",
      state: "FL"
    },
    {
      city: "MIAMISBURG",
      state: "OH"
    },
    {
      city: "MIAMITOWN",
      state: "OH"
    },
    {
      city: "MIAMIVILLE",
      state: "OH"
    },
    {
      city: "MICA",
      state: "WA"
    },
    {
      city: "MICANOPY",
      state: "FL"
    },
    {
      city: "MICAVILLE",
      state: "NC"
    },
    {
      city: "MICHAEL",
      state: "IL"
    },
    {
      city: "MICHIE",
      state: "TN"
    },
    {
      city: "MICHIGAMME",
      state: "MI"
    },
    {
      city: "MICHIGAN",
      state: "ND"
    },
    {
      city: "MICHIGAN CENTER",
      state: "MI"
    },
    {
      city: "MICHIGAN CITY",
      state: "IN"
    },
    {
      city: "MICHIGAN CITY",
      state: "MS"
    },
    {
      city: "MICHIGANTOWN",
      state: "IN"
    },
    {
      city: "MICKLETON",
      state: "NJ"
    },
    {
      city: "MICO",
      state: "TX"
    },
    {
      city: "MICRO",
      state: "NC"
    },
    {
      city: "MID FLORIDA",
      state: "FL"
    },
    {
      city: "MIDDLE AMANA",
      state: "IA"
    },
    {
      city: "MIDDLE BASS",
      state: "OH"
    },
    {
      city: "MIDDLE BROOK",
      state: "MO"
    },
    {
      city: "MIDDLE FALLS",
      state: "NY"
    },
    {
      city: "MIDDLE GRANVILLE",
      state: "NY"
    },
    {
      city: "MIDDLE GROVE",
      state: "NY"
    },
    {
      city: "MIDDLE HADDAM",
      state: "CT"
    },
    {
      city: "MIDDLE ISLAND",
      state: "NY"
    },
    {
      city: "MIDDLE POINT",
      state: "OH"
    },
    {
      city: "MIDDLE RIVER",
      state: "MD"
    },
    {
      city: "MIDDLE RIVER",
      state: "MN"
    },
    {
      city: "MIDDLE VILLAGE",
      state: "NY"
    },
    {
      city: "MIDDLEBORO",
      state: "MA"
    },
    {
      city: "MIDDLEBOURNE",
      state: "WV"
    },
    {
      city: "MIDDLEBRANCH",
      state: "OH"
    },
    {
      city: "MIDDLEBROOK",
      state: "VA"
    },
    {
      city: "MIDDLEBURG",
      state: "FL"
    },
    {
      city: "MIDDLEBURG",
      state: "KY"
    },
    {
      city: "MIDDLEBURG",
      state: "NC"
    },
    {
      city: "MIDDLEBURG",
      state: "OH"
    },
    {
      city: "MIDDLEBURG",
      state: "PA"
    },
    {
      city: "MIDDLEBURG",
      state: "VA"
    },
    {
      city: "MIDDLEBURGH",
      state: "NY"
    },
    {
      city: "MIDDLEBURY",
      state: "CT"
    },
    {
      city: "MIDDLEBURY",
      state: "IN"
    },
    {
      city: "MIDDLEBURY",
      state: "VT"
    },
    {
      city: "MIDDLEBURY CENTER",
      state: "PA"
    },
    {
      city: "MIDDLEFIELD",
      state: "CT"
    },
    {
      city: "MIDDLEFIELD",
      state: "MA"
    },
    {
      city: "MIDDLEFIELD",
      state: "OH"
    },
    {
      city: "MIDDLEPORT",
      state: "NY"
    },
    {
      city: "MIDDLEPORT",
      state: "OH"
    },
    {
      city: "MIDDLEPORT",
      state: "PA"
    },
    {
      city: "MIDDLESBORO",
      state: "KY"
    },
    {
      city: "MIDDLESEX",
      state: "NC"
    },
    {
      city: "MIDDLESEX",
      state: "NJ"
    },
    {
      city: "MIDDLESEX",
      state: "NY"
    },
    {
      city: "MIDDLETON",
      state: "ID"
    },
    {
      city: "MIDDLETON",
      state: "MA"
    },
    {
      city: "MIDDLETON",
      state: "MI"
    },
    {
      city: "MIDDLETON",
      state: "TN"
    },
    {
      city: "MIDDLETON",
      state: "WI"
    },
    {
      city: "MIDDLETOWN",
      state: "CA"
    },
    {
      city: "MIDDLETOWN",
      state: "CT"
    },
    {
      city: "MIDDLETOWN",
      state: "DE"
    },
    {
      city: "MIDDLETOWN",
      state: "IA"
    },
    {
      city: "MIDDLETOWN",
      state: "IL"
    },
    {
      city: "MIDDLETOWN",
      state: "IN"
    },
    {
      city: "MIDDLETOWN",
      state: "MD"
    },
    {
      city: "MIDDLETOWN",
      state: "MO"
    },
    {
      city: "MIDDLETOWN",
      state: "NJ"
    },
    {
      city: "MIDDLETOWN",
      state: "NY"
    },
    {
      city: "MIDDLETOWN",
      state: "OH"
    },
    {
      city: "MIDDLETOWN",
      state: "PA"
    },
    {
      city: "MIDDLETOWN",
      state: "RI"
    },
    {
      city: "MIDDLETOWN",
      state: "VA"
    },
    {
      city: "MIDDLETOWN SPRINGS",
      state: "VT"
    },
    {
      city: "MIDDLEVILLE",
      state: "MI"
    },
    {
      city: "MIDDLEVILLE",
      state: "NJ"
    },
    {
      city: "MIDDLEVILLE",
      state: "NY"
    },
    {
      city: "MIDFIELD",
      state: "TX"
    },
    {
      city: "MIDKIFF",
      state: "TX"
    },
    {
      city: "MIDKIFF",
      state: "WV"
    },
    {
      city: "MIDLAND",
      state: "AR"
    },
    {
      city: "MIDLAND",
      state: "GA"
    },
    {
      city: "MIDLAND",
      state: "MD"
    },
    {
      city: "MIDLAND",
      state: "MI"
    },
    {
      city: "MIDLAND",
      state: "NC"
    },
    {
      city: "MIDLAND",
      state: "OH"
    },
    {
      city: "MIDLAND",
      state: "OR"
    },
    {
      city: "MIDLAND",
      state: "PA"
    },
    {
      city: "MIDLAND",
      state: "SD"
    },
    {
      city: "MIDLAND",
      state: "TX"
    },
    {
      city: "MIDLAND",
      state: "VA"
    },
    {
      city: "MIDLAND CITY",
      state: "AL"
    },
    {
      city: "MIDLAND PARK",
      state: "NJ"
    },
    {
      city: "MIDLOTHIAN",
      state: "IL"
    },
    {
      city: "MIDLOTHIAN",
      state: "MD"
    },
    {
      city: "MIDLOTHIAN",
      state: "TX"
    },
    {
      city: "MIDLOTHIAN",
      state: "VA"
    },
    {
      city: "MIDPINES",
      state: "CA"
    },
    {
      city: "MIDVALE",
      state: "ID"
    },
    {
      city: "MIDVALE",
      state: "OH"
    },
    {
      city: "MIDVALE",
      state: "UT"
    },
    {
      city: "MIDVILLE",
      state: "GA"
    },
    {
      city: "MIDWAY",
      state: "AL"
    },
    {
      city: "MIDWAY",
      state: "AR"
    },
    {
      city: "MIDWAY",
      state: "FL"
    },
    {
      city: "MIDWAY",
      state: "GA"
    },
    {
      city: "MIDWAY",
      state: "KY"
    },
    {
      city: "MIDWAY",
      state: "PA"
    },
    {
      city: "MIDWAY",
      state: "TN"
    },
    {
      city: "MIDWAY",
      state: "TX"
    },
    {
      city: "MIDWAY",
      state: "UT"
    },
    {
      city: "MIDWAY",
      state: "WV"
    },
    {
      city: "MIDWAY CITY",
      state: "CA"
    },
    {
      city: "MIDWAY PARK",
      state: "NC"
    },
    {
      city: "MIDWEST",
      state: "WY"
    },
    {
      city: "MIFFLIN",
      state: "PA"
    },
    {
      city: "MIFFLINBURG",
      state: "PA"
    },
    {
      city: "MIFFLINTOWN",
      state: "PA"
    },
    {
      city: "MIFFLINVILLE",
      state: "PA"
    },
    {
      city: "MIKADO",
      state: "MI"
    },
    {
      city: "MIKANA",
      state: "WI"
    },
    {
      city: "MIKKALO",
      state: "OR"
    },
    {
      city: "MILACA",
      state: "MN"
    },
    {
      city: "MILAM",
      state: "TX"
    },
    {
      city: "MILAN",
      state: "GA"
    },
    {
      city: "MILAN",
      state: "IL"
    },
    {
      city: "MILAN",
      state: "IN"
    },
    {
      city: "MILAN",
      state: "KS"
    },
    {
      city: "MILAN",
      state: "MI"
    },
    {
      city: "MILAN",
      state: "MN"
    },
    {
      city: "MILAN",
      state: "MO"
    },
    {
      city: "MILAN",
      state: "NH"
    },
    {
      city: "MILAN",
      state: "NM"
    },
    {
      city: "MILAN",
      state: "OH"
    },
    {
      city: "MILAN",
      state: "PA"
    },
    {
      city: "MILAN",
      state: "TN"
    },
    {
      city: "MILANO",
      state: "TX"
    },
    {
      city: "MILANVILLE",
      state: "PA"
    },
    {
      city: "MILBANK",
      state: "SD"
    },
    {
      city: "MILBRIDGE",
      state: "ME"
    },
    {
      city: "MILBURN",
      state: "KY"
    },
    {
      city: "MILBURN",
      state: "OK"
    },
    {
      city: "MILDRED",
      state: "MT"
    },
    {
      city: "MILDRED",
      state: "PA"
    },
    {
      city: "MILES",
      state: "IA"
    },
    {
      city: "MILES",
      state: "TX"
    },
    {
      city: "MILES CITY",
      state: "MT"
    },
    {
      city: "MILESBURG",
      state: "PA"
    },
    {
      city: "MILESVILLE",
      state: "SD"
    },
    {
      city: "MILFAY",
      state: "OK"
    },
    {
      city: "MILFORD",
      state: "CA"
    },
    {
      city: "MILFORD",
      state: "CT"
    },
    {
      city: "MILFORD",
      state: "DE"
    },
    {
      city: "MILFORD",
      state: "IA"
    },
    {
      city: "MILFORD",
      state: "IL"
    },
    {
      city: "MILFORD",
      state: "IN"
    },
    {
      city: "MILFORD",
      state: "KS"
    },
    {
      city: "MILFORD",
      state: "MA"
    },
    {
      city: "MILFORD",
      state: "ME"
    },
    {
      city: "MILFORD",
      state: "MI"
    },
    {
      city: "MILFORD",
      state: "MO"
    },
    {
      city: "MILFORD",
      state: "NE"
    },
    {
      city: "MILFORD",
      state: "NH"
    },
    {
      city: "MILFORD",
      state: "NJ"
    },
    {
      city: "MILFORD",
      state: "NY"
    },
    {
      city: "MILFORD",
      state: "OH"
    },
    {
      city: "MILFORD",
      state: "PA"
    },
    {
      city: "MILFORD",
      state: "TX"
    },
    {
      city: "MILFORD",
      state: "UT"
    },
    {
      city: "MILFORD",
      state: "VA"
    },
    {
      city: "MILFORD CENTER",
      state: "OH"
    },
    {
      city: "MILFORD SQUARE",
      state: "PA"
    },
    {
      city: "MILILANI",
      state: "HI"
    },
    {
      city: "MILL CITY",
      state: "OR"
    },
    {
      city: "MILL CREEK",
      state: "CA"
    },
    {
      city: "MILL CREEK",
      state: "IN"
    },
    {
      city: "MILL CREEK",
      state: "OK"
    },
    {
      city: "MILL CREEK",
      state: "PA"
    },
    {
      city: "MILL CREEK",
      state: "WA"
    },
    {
      city: "MILL CREEK",
      state: "WV"
    },
    {
      city: "MILL HALL",
      state: "PA"
    },
    {
      city: "MILL NECK",
      state: "NY"
    },
    {
      city: "MILL RIVER",
      state: "MA"
    },
    {
      city: "MILL RUN",
      state: "PA"
    },
    {
      city: "MILL SHOALS",
      state: "IL"
    },
    {
      city: "MILL SPRING",
      state: "MO"
    },
    {
      city: "MILL SPRING",
      state: "NC"
    },
    {
      city: "MILL VALLEY",
      state: "CA"
    },
    {
      city: "MILL VILLAGE",
      state: "PA"
    },
    {
      city: "MILLADORE",
      state: "WI"
    },
    {
      city: "MILLBORO",
      state: "VA"
    },
    {
      city: "MILLBRAE",
      state: "CA"
    },
    {
      city: "MILLBROOK",
      state: "AL"
    },
    {
      city: "MILLBROOK",
      state: "IL"
    },
    {
      city: "MILLBROOK",
      state: "NY"
    },
    {
      city: "MILLBURN",
      state: "NJ"
    },
    {
      city: "MILLBURY",
      state: "MA"
    },
    {
      city: "MILLBURY",
      state: "OH"
    },
    {
      city: "MILLDALE",
      state: "CT"
    },
    {
      city: "MILLEDGEVILLE",
      state: "GA"
    },
    {
      city: "MILLEDGEVILLE",
      state: "IL"
    },
    {
      city: "MILLEDGEVILLE",
      state: "OH"
    },
    {
      city: "MILLEDGEVILLE",
      state: "TN"
    },
    {
      city: "MILLEN",
      state: "GA"
    },
    {
      city: "MILLER",
      state: "MO"
    },
    {
      city: "MILLER",
      state: "NE"
    },
    {
      city: "MILLER",
      state: "SD"
    },
    {
      city: "MILLER CITY",
      state: "OH"
    },
    {
      city: "MILLER PLACE",
      state: "NY"
    },
    {
      city: "MILLERS CREEK",
      state: "NC"
    },
    {
      city: "MILLERS FALLS",
      state: "MA"
    },
    {
      city: "MILLERS TAVERN",
      state: "VA"
    },
    {
      city: "MILLERSBURG",
      state: "IA"
    },
    {
      city: "MILLERSBURG",
      state: "IN"
    },
    {
      city: "MILLERSBURG",
      state: "KY"
    },
    {
      city: "MILLERSBURG",
      state: "MI"
    },
    {
      city: "MILLERSBURG",
      state: "OH"
    },
    {
      city: "MILLERSBURG",
      state: "PA"
    },
    {
      city: "MILLERSPORT",
      state: "OH"
    },
    {
      city: "MILLERSTOWN",
      state: "PA"
    },
    {
      city: "MILLERSVIEW",
      state: "TX"
    },
    {
      city: "MILLERSVILLE",
      state: "MD"
    },
    {
      city: "MILLERSVILLE",
      state: "MO"
    },
    {
      city: "MILLERSVILLE",
      state: "PA"
    },
    {
      city: "MILLERTON",
      state: "IA"
    },
    {
      city: "MILLERTON",
      state: "NY"
    },
    {
      city: "MILLERTON",
      state: "OK"
    },
    {
      city: "MILLERTON",
      state: "PA"
    },
    {
      city: "MILLERVILLE",
      state: "AL"
    },
    {
      city: "MILLFIELD",
      state: "OH"
    },
    {
      city: "MILLHEIM",
      state: "PA"
    },
    {
      city: "MILLHOUSEN",
      state: "IN"
    },
    {
      city: "MILLICAN",
      state: "TX"
    },
    {
      city: "MILLIGAN",
      state: "FL"
    },
    {
      city: "MILLIGAN",
      state: "NE"
    },
    {
      city: "MILLIGAN COLLEGE",
      state: "TN"
    },
    {
      city: "MILLIKEN",
      state: "CO"
    },
    {
      city: "MILLINGTON",
      state: "IL"
    },
    {
      city: "MILLINGTON",
      state: "MD"
    },
    {
      city: "MILLINGTON",
      state: "MI"
    },
    {
      city: "MILLINGTON",
      state: "NJ"
    },
    {
      city: "MILLINGTON",
      state: "TN"
    },
    {
      city: "MILLINOCKET",
      state: "ME"
    },
    {
      city: "MILLIS",
      state: "MA"
    },
    {
      city: "MILLMONT",
      state: "PA"
    },
    {
      city: "MILLPORT",
      state: "AL"
    },
    {
      city: "MILLPORT",
      state: "NY"
    },
    {
      city: "MILLRIFT",
      state: "PA"
    },
    {
      city: "MILLRY",
      state: "AL"
    },
    {
      city: "MILLS",
      state: "PA"
    },
    {
      city: "MILLS",
      state: "WY"
    },
    {
      city: "MILLS RIVER",
      state: "NC"
    },
    {
      city: "MILLSAP",
      state: "TX"
    },
    {
      city: "MILLSBORO",
      state: "DE"
    },
    {
      city: "MILLSBORO",
      state: "PA"
    },
    {
      city: "MILLSTADT",
      state: "IL"
    },
    {
      city: "MILLSTON",
      state: "WI"
    },
    {
      city: "MILLSTONE",
      state: "WV"
    },
    {
      city: "MILLSTONE TOWNSHIP",
      state: "NJ"
    },
    {
      city: "MILLTOWN",
      state: "IN"
    },
    {
      city: "MILLTOWN",
      state: "MT"
    },
    {
      city: "MILLTOWN",
      state: "NJ"
    },
    {
      city: "MILLTOWN",
      state: "WI"
    },
    {
      city: "MILLVILLE",
      state: "CA"
    },
    {
      city: "MILLVILLE",
      state: "DE"
    },
    {
      city: "MILLVILLE",
      state: "MA"
    },
    {
      city: "MILLVILLE",
      state: "MN"
    },
    {
      city: "MILLVILLE",
      state: "NJ"
    },
    {
      city: "MILLVILLE",
      state: "PA"
    },
    {
      city: "MILLVILLE",
      state: "UT"
    },
    {
      city: "MILLVILLE",
      state: "WV"
    },
    {
      city: "MILLWOOD",
      state: "GA"
    },
    {
      city: "MILLWOOD",
      state: "KY"
    },
    {
      city: "MILLWOOD",
      state: "NY"
    },
    {
      city: "MILLWOOD",
      state: "VA"
    },
    {
      city: "MILLWOOD",
      state: "WV"
    },
    {
      city: "MILMAY",
      state: "NJ"
    },
    {
      city: "MILMINE",
      state: "IL"
    },
    {
      city: "MILNER",
      state: "GA"
    },
    {
      city: "MILNESVILLE",
      state: "PA"
    },
    {
      city: "MILNOR",
      state: "ND"
    },
    {
      city: "MILO",
      state: "IA"
    },
    {
      city: "MILO",
      state: "ME"
    },
    {
      city: "MILO",
      state: "MO"
    },
    {
      city: "MILPITAS",
      state: "CA"
    },
    {
      city: "MILROY",
      state: "IN"
    },
    {
      city: "MILROY",
      state: "MN"
    },
    {
      city: "MILROY",
      state: "PA"
    },
    {
      city: "MILTON",
      state: "DE"
    },
    {
      city: "MILTON",
      state: "FL"
    },
    {
      city: "MILTON",
      state: "IA"
    },
    {
      city: "MILTON",
      state: "IL"
    },
    {
      city: "MILTON",
      state: "IN"
    },
    {
      city: "MILTON",
      state: "KS"
    },
    {
      city: "MILTON",
      state: "KY"
    },
    {
      city: "MILTON",
      state: "LA"
    },
    {
      city: "MILTON",
      state: "MA"
    },
    {
      city: "MILTON",
      state: "NC"
    },
    {
      city: "MILTON",
      state: "ND"
    },
    {
      city: "MILTON",
      state: "NH"
    },
    {
      city: "MILTON",
      state: "NY"
    },
    {
      city: "MILTON",
      state: "PA"
    },
    {
      city: "MILTON",
      state: "TN"
    },
    {
      city: "MILTON",
      state: "VT"
    },
    {
      city: "MILTON",
      state: "WA"
    },
    {
      city: "MILTON",
      state: "WI"
    },
    {
      city: "MILTON",
      state: "WV"
    },
    {
      city: "MILTON CENTER",
      state: "OH"
    },
    {
      city: "MILTON FREEWATER",
      state: "OR"
    },
    {
      city: "MILTON MILLS",
      state: "NH"
    },
    {
      city: "MILTON VILLAGE",
      state: "MA"
    },
    {
      city: "MILTONA",
      state: "MN"
    },
    {
      city: "MILTONVALE",
      state: "KS"
    },
    {
      city: "MILWAUKEE",
      state: "WI"
    },
    {
      city: "MIMBRES",
      state: "NM"
    },
    {
      city: "MIMS",
      state: "FL"
    },
    {
      city: "MINA",
      state: "NV"
    },
    {
      city: "MINATARE",
      state: "NE"
    },
    {
      city: "MINBURN",
      state: "IA"
    },
    {
      city: "MINCO",
      state: "OK"
    },
    {
      city: "MINDEN",
      state: "IA"
    },
    {
      city: "MINDEN",
      state: "LA"
    },
    {
      city: "MINDEN",
      state: "NE"
    },
    {
      city: "MINDEN",
      state: "NV"
    },
    {
      city: "MINDEN",
      state: "TX"
    },
    {
      city: "MINDEN",
      state: "WV"
    },
    {
      city: "MINDEN CITY",
      state: "MI"
    },
    {
      city: "MINDENMINES",
      state: "MO"
    },
    {
      city: "MINDORO",
      state: "WI"
    },
    {
      city: "MINE HILL",
      state: "NJ"
    },
    {
      city: "MINEOLA",
      state: "IA"
    },
    {
      city: "MINEOLA",
      state: "NY"
    },
    {
      city: "MINEOLA",
      state: "TX"
    },
    {
      city: "MINERAL",
      state: "CA"
    },
    {
      city: "MINERAL",
      state: "IL"
    },
    {
      city: "MINERAL",
      state: "VA"
    },
    {
      city: "MINERAL",
      state: "WA"
    },
    {
      city: "MINERAL BLUFF",
      state: "GA"
    },
    {
      city: "MINERAL CITY",
      state: "OH"
    },
    {
      city: "MINERAL POINT",
      state: "MO"
    },
    {
      city: "MINERAL POINT",
      state: "PA"
    },
    {
      city: "MINERAL POINT",
      state: "WI"
    },
    {
      city: "MINERAL RIDGE",
      state: "OH"
    },
    {
      city: "MINERAL SPRINGS",
      state: "AR"
    },
    {
      city: "MINERAL SPRINGS",
      state: "NC"
    },
    {
      city: "MINERAL SPRINGS",
      state: "PA"
    },
    {
      city: "MINERAL WELLS",
      state: "TX"
    },
    {
      city: "MINERAL WELLS",
      state: "WV"
    },
    {
      city: "MINERSVILLE",
      state: "PA"
    },
    {
      city: "MINERSVILLE",
      state: "UT"
    },
    {
      city: "MINERVA",
      state: "NY"
    },
    {
      city: "MINERVA",
      state: "OH"
    },
    {
      city: "MINETTO",
      state: "NY"
    },
    {
      city: "MINEVILLE",
      state: "NY"
    },
    {
      city: "MINFORD",
      state: "OH"
    },
    {
      city: "MINGO",
      state: "IA"
    },
    {
      city: "MINGO",
      state: "OH"
    },
    {
      city: "MINGO JUNCTION",
      state: "OH"
    },
    {
      city: "MINGOVILLE",
      state: "PA"
    },
    {
      city: "MINGUS",
      state: "TX"
    },
    {
      city: "MINIDOKA",
      state: "ID"
    },
    {
      city: "MINIER",
      state: "IL"
    },
    {
      city: "MINISINK HILLS",
      state: "PA"
    },
    {
      city: "MINNEAPOLIS",
      state: "KS"
    },
    {
      city: "MINNEAPOLIS",
      state: "MN"
    },
    {
      city: "MINNEAPOLIS",
      state: "NC"
    },
    {
      city: "MINNEOLA",
      state: "FL"
    },
    {
      city: "MINNEOLA",
      state: "KS"
    },
    {
      city: "MINNEOTA",
      state: "MN"
    },
    {
      city: "MINNESOTA CITY",
      state: "MN"
    },
    {
      city: "MINNESOTA LAKE",
      state: "MN"
    },
    {
      city: "MINNETONKA",
      state: "MN"
    },
    {
      city: "MINNETONKA BEACH",
      state: "MN"
    },
    {
      city: "MINNEWAUKAN",
      state: "ND"
    },
    {
      city: "MINNIE",
      state: "KY"
    },
    {
      city: "MINOA",
      state: "NY"
    },
    {
      city: "MINOCQUA",
      state: "WI"
    },
    {
      city: "MINONG",
      state: "WI"
    },
    {
      city: "MINONK",
      state: "IL"
    },
    {
      city: "MINOOKA",
      state: "IL"
    },
    {
      city: "MINOR HILL",
      state: "TN"
    },
    {
      city: "MINOT",
      state: "ME"
    },
    {
      city: "MINOT",
      state: "ND"
    },
    {
      city: "MINOT AFB",
      state: "ND"
    },
    {
      city: "MINOTOLA",
      state: "NJ"
    },
    {
      city: "MINSTER",
      state: "OH"
    },
    {
      city: "MINT SPRING",
      state: "VA"
    },
    {
      city: "MINTER",
      state: "AL"
    },
    {
      city: "MINTER CITY",
      state: "MS"
    },
    {
      city: "MINTO",
      state: "AK"
    },
    {
      city: "MINTO",
      state: "ND"
    },
    {
      city: "MINTURN",
      state: "AR"
    },
    {
      city: "MINTURN",
      state: "CO"
    },
    {
      city: "MIO",
      state: "MI"
    },
    {
      city: "MIRA LOMA",
      state: "CA"
    },
    {
      city: "MIRACLE",
      state: "KY"
    },
    {
      city: "MIRAMAR BEACH",
      state: "FL"
    },
    {
      city: "MIRAMONTE",
      state: "CA"
    },
    {
      city: "MIRANDA",
      state: "CA"
    },
    {
      city: "MIRANDO CITY",
      state: "TX"
    },
    {
      city: "MIRROR LAKE",
      state: "NH"
    },
    {
      city: "MISENHEIMER",
      state: "NC"
    },
    {
      city: "MISHAWAKA",
      state: "IN"
    },
    {
      city: "MISHICOT",
      state: "WI"
    },
    {
      city: "MISSION",
      state: "KS"
    },
    {
      city: "MISSION",
      state: "SD"
    },
    {
      city: "MISSION",
      state: "TX"
    },
    {
      city: "MISSION HILL",
      state: "SD"
    },
    {
      city: "MISSION HILLS",
      state: "CA"
    },
    {
      city: "MISSION VIEJO",
      state: "CA"
    },
    {
      city: "MISSISSIPPI STATE",
      state: "MS"
    },
    {
      city: "MISSOULA",
      state: "MT"
    },
    {
      city: "MISSOURI CITY",
      state: "MO"
    },
    {
      city: "MISSOURI CITY",
      state: "TX"
    },
    {
      city: "MISSOURI VALLEY",
      state: "IA"
    },
    {
      city: "MITCHELL",
      state: "GA"
    },
    {
      city: "MITCHELL",
      state: "IN"
    },
    {
      city: "MITCHELL",
      state: "NE"
    },
    {
      city: "MITCHELL",
      state: "OR"
    },
    {
      city: "MITCHELL",
      state: "SD"
    },
    {
      city: "MITCHELLS",
      state: "VA"
    },
    {
      city: "MITCHELLVILLE",
      state: "IA"
    },
    {
      city: "MITTIE",
      state: "LA"
    },
    {
      city: "MIZE",
      state: "KY"
    },
    {
      city: "MIZE",
      state: "MS"
    },
    {
      city: "MIZPAH",
      state: "MN"
    },
    {
      city: "MIZPAH",
      state: "NJ"
    },
    {
      city: "MOAB",
      state: "UT"
    },
    {
      city: "MOAPA",
      state: "NV"
    },
    {
      city: "MOATSVILLE",
      state: "WV"
    },
    {
      city: "MOBEETIE",
      state: "TX"
    },
    {
      city: "MOBERLY",
      state: "MO"
    },
    {
      city: "MOBILE",
      state: "AL"
    },
    {
      city: "MOBRIDGE",
      state: "SD"
    },
    {
      city: "MOCCASIN",
      state: "MT"
    },
    {
      city: "MOCKSVILLE",
      state: "NC"
    },
    {
      city: "MOCLIPS",
      state: "WA"
    },
    {
      city: "MODALE",
      state: "IA"
    },
    {
      city: "MODE",
      state: "IL"
    },
    {
      city: "MODEL",
      state: "CO"
    },
    {
      city: "MODEL CITY",
      state: "NY"
    },
    {
      city: "MODENA",
      state: "NY"
    },
    {
      city: "MODENA",
      state: "PA"
    },
    {
      city: "MODESTO",
      state: "CA"
    },
    {
      city: "MODESTO",
      state: "IL"
    },
    {
      city: "MODOC",
      state: "IL"
    },
    {
      city: "MODOC",
      state: "IN"
    },
    {
      city: "MODOC",
      state: "SC"
    },
    {
      city: "MOFFAT",
      state: "CO"
    },
    {
      city: "MOFFETT",
      state: "OK"
    },
    {
      city: "MOFFIT",
      state: "ND"
    },
    {
      city: "MOGADORE",
      state: "OH"
    },
    {
      city: "MOHALL",
      state: "ND"
    },
    {
      city: "MOHAVE VALLEY",
      state: "AZ"
    },
    {
      city: "MOHAWK",
      state: "MI"
    },
    {
      city: "MOHAWK",
      state: "NY"
    },
    {
      city: "MOHAWK",
      state: "TN"
    },
    {
      city: "MOHAWK",
      state: "WV"
    },
    {
      city: "MOHEGAN LAKE",
      state: "NY"
    },
    {
      city: "MOHLER",
      state: "WA"
    },
    {
      city: "MOHNTON",
      state: "PA"
    },
    {
      city: "MOHRSVILLE",
      state: "PA"
    },
    {
      city: "MOIRA",
      state: "NY"
    },
    {
      city: "MOJAVE",
      state: "CA"
    },
    {
      city: "MOKANE",
      state: "MO"
    },
    {
      city: "MOKELUMNE HILL",
      state: "CA"
    },
    {
      city: "MOKENA",
      state: "IL"
    },
    {
      city: "MOLALLA",
      state: "OR"
    },
    {
      city: "MOLENA",
      state: "GA"
    },
    {
      city: "MOLINA",
      state: "CO"
    },
    {
      city: "MOLINE",
      state: "IL"
    },
    {
      city: "MOLINE",
      state: "KS"
    },
    {
      city: "MOLINE",
      state: "MI"
    },
    {
      city: "MOLINO",
      state: "FL"
    },
    {
      city: "MOLLUSK",
      state: "VA"
    },
    {
      city: "MOLT",
      state: "MT"
    },
    {
      city: "MOMENCE",
      state: "IL"
    },
    {
      city: "MONA",
      state: "UT"
    },
    {
      city: "MONACA",
      state: "PA"
    },
    {
      city: "MONAHANS",
      state: "TX"
    },
    {
      city: "MONARCH",
      state: "CO"
    },
    {
      city: "MONARCH",
      state: "MT"
    },
    {
      city: "MONCKS CORNER",
      state: "SC"
    },
    {
      city: "MONCLOVA",
      state: "OH"
    },
    {
      city: "MONCURE",
      state: "NC"
    },
    {
      city: "MONDAMIN",
      state: "IA"
    },
    {
      city: "MONDOVI",
      state: "WI"
    },
    {
      city: "MONEE",
      state: "IL"
    },
    {
      city: "MONESSEN",
      state: "PA"
    },
    {
      city: "MONETA",
      state: "VA"
    },
    {
      city: "MONETT",
      state: "MO"
    },
    {
      city: "MONETTA",
      state: "SC"
    },
    {
      city: "MONETTE",
      state: "AR"
    },
    {
      city: "MONGAUP VALLEY",
      state: "NY"
    },
    {
      city: "MONGO",
      state: "IN"
    },
    {
      city: "MONHEGAN",
      state: "ME"
    },
    {
      city: "MONITOR",
      state: "WA"
    },
    {
      city: "MONKTON",
      state: "MD"
    },
    {
      city: "MONKTON",
      state: "VT"
    },
    {
      city: "MONMOUTH",
      state: "IA"
    },
    {
      city: "MONMOUTH",
      state: "IL"
    },
    {
      city: "MONMOUTH",
      state: "ME"
    },
    {
      city: "MONMOUTH",
      state: "OR"
    },
    {
      city: "MONMOUTH BEACH",
      state: "NJ"
    },
    {
      city: "MONMOUTH JUNCTION",
      state: "NJ"
    },
    {
      city: "MONO HOT SPRINGS",
      state: "CA"
    },
    {
      city: "MONOCACY STATION",
      state: "PA"
    },
    {
      city: "MONON",
      state: "IN"
    },
    {
      city: "MONONA",
      state: "IA"
    },
    {
      city: "MONONGAHELA",
      state: "PA"
    },
    {
      city: "MONPONSETT",
      state: "MA"
    },
    {
      city: "MONROE",
      state: "AR"
    },
    {
      city: "MONROE",
      state: "CT"
    },
    {
      city: "MONROE",
      state: "GA"
    },
    {
      city: "MONROE",
      state: "IA"
    },
    {
      city: "MONROE",
      state: "IN"
    },
    {
      city: "MONROE",
      state: "LA"
    },
    {
      city: "MONROE",
      state: "ME"
    },
    {
      city: "MONROE",
      state: "MI"
    },
    {
      city: "MONROE",
      state: "NC"
    },
    {
      city: "MONROE",
      state: "NE"
    },
    {
      city: "MONROE",
      state: "NH"
    },
    {
      city: "MONROE",
      state: "NY"
    },
    {
      city: "MONROE",
      state: "OH"
    },
    {
      city: "MONROE",
      state: "OK"
    },
    {
      city: "MONROE",
      state: "OR"
    },
    {
      city: "MONROE",
      state: "SD"
    },
    {
      city: "MONROE",
      state: "TN"
    },
    {
      city: "MONROE",
      state: "UT"
    },
    {
      city: "MONROE",
      state: "VA"
    },
    {
      city: "MONROE",
      state: "WA"
    },
    {
      city: "MONROE",
      state: "WI"
    },
    {
      city: "MONROE BRIDGE",
      state: "MA"
    },
    {
      city: "MONROE CENTER",
      state: "IL"
    },
    {
      city: "MONROE CITY",
      state: "IN"
    },
    {
      city: "MONROE CITY",
      state: "MO"
    },
    {
      city: "MONROE TOWNSHIP",
      state: "NJ"
    },
    {
      city: "MONROETON",
      state: "PA"
    },
    {
      city: "MONROEVILLE",
      state: "AL"
    },
    {
      city: "MONROEVILLE",
      state: "IN"
    },
    {
      city: "MONROEVILLE",
      state: "NJ"
    },
    {
      city: "MONROEVILLE",
      state: "OH"
    },
    {
      city: "MONROEVILLE",
      state: "PA"
    },
    {
      city: "MONROVIA",
      state: "CA"
    },
    {
      city: "MONROVIA",
      state: "IN"
    },
    {
      city: "MONROVIA",
      state: "MD"
    },
    {
      city: "MONSEY",
      state: "NY"
    },
    {
      city: "MONSON",
      state: "MA"
    },
    {
      city: "MONSON",
      state: "ME"
    },
    {
      city: "MONT ALTO",
      state: "PA"
    },
    {
      city: "MONT BELVIEU",
      state: "TX"
    },
    {
      city: "MONT CLARE",
      state: "PA"
    },
    {
      city: "MONT VERNON",
      state: "NH"
    },
    {
      city: "MONTAGUE",
      state: "CA"
    },
    {
      city: "MONTAGUE",
      state: "MA"
    },
    {
      city: "MONTAGUE",
      state: "MI"
    },
    {
      city: "MONTAGUE",
      state: "NJ"
    },
    {
      city: "MONTAGUE",
      state: "TX"
    },
    {
      city: "MONTALBA",
      state: "TX"
    },
    {
      city: "MONTANA MINES",
      state: "WV"
    },
    {
      city: "MONTANDON",
      state: "PA"
    },
    {
      city: "MONTARA",
      state: "CA"
    },
    {
      city: "MONTAUK",
      state: "NY"
    },
    {
      city: "MONTCALM",
      state: "WV"
    },
    {
      city: "MONTCHANIN",
      state: "DE"
    },
    {
      city: "MONTCLAIR",
      state: "CA"
    },
    {
      city: "MONTCLAIR",
      state: "NJ"
    },
    {
      city: "MONTE RIO",
      state: "CA"
    },
    {
      city: "MONTE VISTA",
      state: "CO"
    },
    {
      city: "MONTEAGLE",
      state: "TN"
    },
    {
      city: "MONTEBELLO",
      state: "CA"
    },
    {
      city: "MONTEBELLO",
      state: "VA"
    },
    {
      city: "MONTEGUT",
      state: "LA"
    },
    {
      city: "MONTELLO",
      state: "WI"
    },
    {
      city: "MONTEREY",
      state: "CA"
    },
    {
      city: "MONTEREY",
      state: "IN"
    },
    {
      city: "MONTEREY",
      state: "LA"
    },
    {
      city: "MONTEREY",
      state: "MA"
    },
    {
      city: "MONTEREY",
      state: "TN"
    },
    {
      city: "MONTEREY",
      state: "VA"
    },
    {
      city: "MONTEREY PARK",
      state: "CA"
    },
    {
      city: "MONTERVILLE",
      state: "WV"
    },
    {
      city: "MONTESANO",
      state: "WA"
    },
    {
      city: "MONTEVALLO",
      state: "AL"
    },
    {
      city: "MONTEVIDEO",
      state: "MN"
    },
    {
      city: "MONTEZUMA",
      state: "GA"
    },
    {
      city: "MONTEZUMA",
      state: "IA"
    },
    {
      city: "MONTEZUMA",
      state: "IN"
    },
    {
      city: "MONTEZUMA",
      state: "KS"
    },
    {
      city: "MONTEZUMA",
      state: "NC"
    },
    {
      city: "MONTEZUMA",
      state: "NM"
    },
    {
      city: "MONTEZUMA",
      state: "NY"
    },
    {
      city: "MONTEZUMA",
      state: "OH"
    },
    {
      city: "MONTEZUMA CREEK",
      state: "UT"
    },
    {
      city: "MONTFORT",
      state: "WI"
    },
    {
      city: "MONTGOMERY",
      state: "AL"
    },
    {
      city: "MONTGOMERY",
      state: "IL"
    },
    {
      city: "MONTGOMERY",
      state: "IN"
    },
    {
      city: "MONTGOMERY",
      state: "LA"
    },
    {
      city: "MONTGOMERY",
      state: "MI"
    },
    {
      city: "MONTGOMERY",
      state: "MN"
    },
    {
      city: "MONTGOMERY",
      state: "NY"
    },
    {
      city: "MONTGOMERY",
      state: "PA"
    },
    {
      city: "MONTGOMERY",
      state: "TX"
    },
    {
      city: "MONTGOMERY",
      state: "VT"
    },
    {
      city: "MONTGOMERY",
      state: "WV"
    },
    {
      city: "MONTGOMERY CENTER",
      state: "VT"
    },
    {
      city: "MONTGOMERY CITY",
      state: "MO"
    },
    {
      city: "MONTGOMERY CREEK",
      state: "CA"
    },
    {
      city: "MONTGOMERY VILLAGE",
      state: "MD"
    },
    {
      city: "MONTGOMERYVILLE",
      state: "PA"
    },
    {
      city: "MONTICELLO",
      state: "AR"
    },
    {
      city: "MONTICELLO",
      state: "FL"
    },
    {
      city: "MONTICELLO",
      state: "GA"
    },
    {
      city: "MONTICELLO",
      state: "IA"
    },
    {
      city: "MONTICELLO",
      state: "IL"
    },
    {
      city: "MONTICELLO",
      state: "IN"
    },
    {
      city: "MONTICELLO",
      state: "KY"
    },
    {
      city: "MONTICELLO",
      state: "ME"
    },
    {
      city: "MONTICELLO",
      state: "MN"
    },
    {
      city: "MONTICELLO",
      state: "MO"
    },
    {
      city: "MONTICELLO",
      state: "MS"
    },
    {
      city: "MONTICELLO",
      state: "NM"
    },
    {
      city: "MONTICELLO",
      state: "NY"
    },
    {
      city: "MONTICELLO",
      state: "UT"
    },
    {
      city: "MONTICELLO",
      state: "WI"
    },
    {
      city: "MONTMORENCI",
      state: "IN"
    },
    {
      city: "MONTMORENCI",
      state: "SC"
    },
    {
      city: "MONTOUR",
      state: "IA"
    },
    {
      city: "MONTOUR FALLS",
      state: "NY"
    },
    {
      city: "MONTOURSVILLE",
      state: "PA"
    },
    {
      city: "MONTPELIER",
      state: "IA"
    },
    {
      city: "MONTPELIER",
      state: "ID"
    },
    {
      city: "MONTPELIER",
      state: "IN"
    },
    {
      city: "MONTPELIER",
      state: "MS"
    },
    {
      city: "MONTPELIER",
      state: "ND"
    },
    {
      city: "MONTPELIER",
      state: "OH"
    },
    {
      city: "MONTPELIER",
      state: "VA"
    },
    {
      city: "MONTPELIER",
      state: "VT"
    },
    {
      city: "MONTPELIER STATION",
      state: "VA"
    },
    {
      city: "MONTREAL",
      state: "MO"
    },
    {
      city: "MONTREAL",
      state: "WI"
    },
    {
      city: "MONTREAT",
      state: "NC"
    },
    {
      city: "MONTROSE",
      state: "AL"
    },
    {
      city: "MONTROSE",
      state: "AR"
    },
    {
      city: "MONTROSE",
      state: "CA"
    },
    {
      city: "MONTROSE",
      state: "CO"
    },
    {
      city: "MONTROSE",
      state: "GA"
    },
    {
      city: "MONTROSE",
      state: "IA"
    },
    {
      city: "MONTROSE",
      state: "IL"
    },
    {
      city: "MONTROSE",
      state: "MI"
    },
    {
      city: "MONTROSE",
      state: "MN"
    },
    {
      city: "MONTROSE",
      state: "MO"
    },
    {
      city: "MONTROSE",
      state: "NY"
    },
    {
      city: "MONTROSE",
      state: "PA"
    },
    {
      city: "MONTROSE",
      state: "SD"
    },
    {
      city: "MONTROSE",
      state: "WV"
    },
    {
      city: "MONTROSS",
      state: "VA"
    },
    {
      city: "MONTVALE",
      state: "NJ"
    },
    {
      city: "MONTVALE",
      state: "VA"
    },
    {
      city: "MONTVERDE",
      state: "FL"
    },
    {
      city: "MONTVILLE",
      state: "CT"
    },
    {
      city: "MONTVILLE",
      state: "NJ"
    },
    {
      city: "MONTVILLE",
      state: "OH"
    },
    {
      city: "MONUMENT",
      state: "CO"
    },
    {
      city: "MONUMENT",
      state: "KS"
    },
    {
      city: "MONUMENT",
      state: "NM"
    },
    {
      city: "MONUMENT",
      state: "OR"
    },
    {
      city: "MONUMENT BEACH",
      state: "MA"
    },
    {
      city: "MONUMENT VALLEY",
      state: "UT"
    },
    {
      city: "MOODUS",
      state: "CT"
    },
    {
      city: "MOODY",
      state: "AL"
    },
    {
      city: "MOODY",
      state: "ME"
    },
    {
      city: "MOODY",
      state: "MO"
    },
    {
      city: "MOODY",
      state: "TX"
    },
    {
      city: "MOODY AFB",
      state: "GA"
    },
    {
      city: "MOODYS",
      state: "OK"
    },
    {
      city: "MOOERS",
      state: "NY"
    },
    {
      city: "MOOERS FORKS",
      state: "NY"
    },
    {
      city: "MOON",
      state: "VA"
    },
    {
      city: "MOONACHIE",
      state: "NJ"
    },
    {
      city: "MOORCROFT",
      state: "WY"
    },
    {
      city: "MOORE",
      state: "ID"
    },
    {
      city: "MOORE",
      state: "MT"
    },
    {
      city: "MOORE",
      state: "SC"
    },
    {
      city: "MOORE",
      state: "TX"
    },
    {
      city: "MOORE HAVEN",
      state: "FL"
    },
    {
      city: "MOOREFIELD",
      state: "NE"
    },
    {
      city: "MOOREFIELD",
      state: "WV"
    },
    {
      city: "MOORELAND",
      state: "IN"
    },
    {
      city: "MOORELAND",
      state: "OK"
    },
    {
      city: "MOORES HILL",
      state: "IN"
    },
    {
      city: "MOORESBORO",
      state: "NC"
    },
    {
      city: "MOORESBURG",
      state: "TN"
    },
    {
      city: "MOORESTOWN",
      state: "NJ"
    },
    {
      city: "MOORESVILLE",
      state: "AL"
    },
    {
      city: "MOORESVILLE",
      state: "IN"
    },
    {
      city: "MOORESVILLE",
      state: "MO"
    },
    {
      city: "MOORESVILLE",
      state: "NC"
    },
    {
      city: "MOORETON",
      state: "ND"
    },
    {
      city: "MOOREVILLE",
      state: "MS"
    },
    {
      city: "MOORHEAD",
      state: "IA"
    },
    {
      city: "MOORHEAD",
      state: "MN"
    },
    {
      city: "MOORHEAD",
      state: "MS"
    },
    {
      city: "MOORINGSPORT",
      state: "LA"
    },
    {
      city: "MOORLAND",
      state: "IA"
    },
    {
      city: "MOORPARK",
      state: "CA"
    },
    {
      city: "MOOSE",
      state: "WY"
    },
    {
      city: "MOOSE LAKE",
      state: "MN"
    },
    {
      city: "MOOSE PASS",
      state: "AK"
    },
    {
      city: "MOOSEHEART",
      state: "IL"
    },
    {
      city: "MOOSIC",
      state: "PA"
    },
    {
      city: "MOOSUP",
      state: "CT"
    },
    {
      city: "MORA",
      state: "LA"
    },
    {
      city: "MORA",
      state: "MN"
    },
    {
      city: "MORA",
      state: "MO"
    },
    {
      city: "MORA",
      state: "NM"
    },
    {
      city: "MORAGA",
      state: "CA"
    },
    {
      city: "MORAN",
      state: "KS"
    },
    {
      city: "MORAN",
      state: "MI"
    },
    {
      city: "MORAN",
      state: "TX"
    },
    {
      city: "MORAN",
      state: "WY"
    },
    {
      city: "MORANN",
      state: "PA"
    },
    {
      city: "MORATTICO",
      state: "VA"
    },
    {
      city: "MORAVIA",
      state: "IA"
    },
    {
      city: "MORAVIA",
      state: "NY"
    },
    {
      city: "MORAVIAN FALLS",
      state: "NC"
    },
    {
      city: "MOREAUVILLE",
      state: "LA"
    },
    {
      city: "MOREHEAD",
      state: "KY"
    },
    {
      city: "MOREHEAD CITY",
      state: "NC"
    },
    {
      city: "MOREHOUSE",
      state: "MO"
    },
    {
      city: "MORELAND",
      state: "GA"
    },
    {
      city: "MORELAND",
      state: "ID"
    },
    {
      city: "MORENCI",
      state: "AZ"
    },
    {
      city: "MORENCI",
      state: "MI"
    },
    {
      city: "MORENO VALLEY",
      state: "CA"
    },
    {
      city: "MORETOWN",
      state: "VT"
    },
    {
      city: "MORGAN",
      state: "GA"
    },
    {
      city: "MORGAN",
      state: "MN"
    },
    {
      city: "MORGAN",
      state: "PA"
    },
    {
      city: "MORGAN",
      state: "TX"
    },
    {
      city: "MORGAN",
      state: "UT"
    },
    {
      city: "MORGAN",
      state: "VT"
    },
    {
      city: "MORGAN CITY",
      state: "LA"
    },
    {
      city: "MORGAN CITY",
      state: "MS"
    },
    {
      city: "MORGAN HILL",
      state: "CA"
    },
    {
      city: "MORGAN MILL",
      state: "TX"
    },
    {
      city: "MORGANFIELD",
      state: "KY"
    },
    {
      city: "MORGANTON",
      state: "GA"
    },
    {
      city: "MORGANTON",
      state: "NC"
    },
    {
      city: "MORGANTOWN",
      state: "IN"
    },
    {
      city: "MORGANTOWN",
      state: "KY"
    },
    {
      city: "MORGANTOWN",
      state: "PA"
    },
    {
      city: "MORGANTOWN",
      state: "WV"
    },
    {
      city: "MORGANVILLE",
      state: "KS"
    },
    {
      city: "MORGANVILLE",
      state: "NJ"
    },
    {
      city: "MORGANZA",
      state: "LA"
    },
    {
      city: "MORGANZA",
      state: "MD"
    },
    {
      city: "MORIAH",
      state: "NY"
    },
    {
      city: "MORIAH CENTER",
      state: "NY"
    },
    {
      city: "MORIARTY",
      state: "NM"
    },
    {
      city: "MORICHES",
      state: "NY"
    },
    {
      city: "MORLAND",
      state: "KS"
    },
    {
      city: "MORLEY",
      state: "MI"
    },
    {
      city: "MORLEY",
      state: "MO"
    },
    {
      city: "MORMON LAKE",
      state: "AZ"
    },
    {
      city: "MORNING SUN",
      state: "IA"
    },
    {
      city: "MORNING VIEW",
      state: "KY"
    },
    {
      city: "MORO",
      state: "AR"
    },
    {
      city: "MORO",
      state: "IL"
    },
    {
      city: "MORO",
      state: "OR"
    },
    {
      city: "MOROCCO",
      state: "IN"
    },
    {
      city: "MORONGO VALLEY",
      state: "CA"
    },
    {
      city: "MORONI",
      state: "UT"
    },
    {
      city: "MORRAL",
      state: "OH"
    },
    {
      city: "MORRICE",
      state: "MI"
    },
    {
      city: "MORRILL",
      state: "KS"
    },
    {
      city: "MORRILL",
      state: "ME"
    },
    {
      city: "MORRILL",
      state: "NE"
    },
    {
      city: "MORRILTON",
      state: "AR"
    },
    {
      city: "MORRIS",
      state: "AL"
    },
    {
      city: "MORRIS",
      state: "CT"
    },
    {
      city: "MORRIS",
      state: "GA"
    },
    {
      city: "MORRIS",
      state: "IL"
    },
    {
      city: "MORRIS",
      state: "IN"
    },
    {
      city: "MORRIS",
      state: "MN"
    },
    {
      city: "MORRIS",
      state: "NY"
    },
    {
      city: "MORRIS",
      state: "OK"
    },
    {
      city: "MORRIS",
      state: "PA"
    },
    {
      city: "MORRIS CHAPEL",
      state: "TN"
    },
    {
      city: "MORRIS PLAINS",
      state: "NJ"
    },
    {
      city: "MORRIS RUN",
      state: "PA"
    },
    {
      city: "MORRISDALE",
      state: "PA"
    },
    {
      city: "MORRISON",
      state: "CO"
    },
    {
      city: "MORRISON",
      state: "IA"
    },
    {
      city: "MORRISON",
      state: "IL"
    },
    {
      city: "MORRISON",
      state: "MO"
    },
    {
      city: "MORRISON",
      state: "OK"
    },
    {
      city: "MORRISON",
      state: "TN"
    },
    {
      city: "MORRISONVILLE",
      state: "IL"
    },
    {
      city: "MORRISONVILLE",
      state: "NY"
    },
    {
      city: "MORRISONVILLE",
      state: "WI"
    },
    {
      city: "MORRISTON",
      state: "FL"
    },
    {
      city: "MORRISTOWN",
      state: "AZ"
    },
    {
      city: "MORRISTOWN",
      state: "IN"
    },
    {
      city: "MORRISTOWN",
      state: "MN"
    },
    {
      city: "MORRISTOWN",
      state: "NJ"
    },
    {
      city: "MORRISTOWN",
      state: "NY"
    },
    {
      city: "MORRISTOWN",
      state: "OH"
    },
    {
      city: "MORRISTOWN",
      state: "SD"
    },
    {
      city: "MORRISTOWN",
      state: "TN"
    },
    {
      city: "MORRISVILLE",
      state: "MO"
    },
    {
      city: "MORRISVILLE",
      state: "NC"
    },
    {
      city: "MORRISVILLE",
      state: "NY"
    },
    {
      city: "MORRISVILLE",
      state: "PA"
    },
    {
      city: "MORRISVILLE",
      state: "VT"
    },
    {
      city: "MORRO BAY",
      state: "CA"
    },
    {
      city: "MORROW",
      state: "AR"
    },
    {
      city: "MORROW",
      state: "GA"
    },
    {
      city: "MORROW",
      state: "LA"
    },
    {
      city: "MORROW",
      state: "OH"
    },
    {
      city: "MORROWVILLE",
      state: "KS"
    },
    {
      city: "MORSE",
      state: "LA"
    },
    {
      city: "MORSE",
      state: "TX"
    },
    {
      city: "MORSE BLUFF",
      state: "NE"
    },
    {
      city: "MORTON",
      state: "IL"
    },
    {
      city: "MORTON",
      state: "MN"
    },
    {
      city: "MORTON",
      state: "MS"
    },
    {
      city: "MORTON",
      state: "NY"
    },
    {
      city: "MORTON",
      state: "PA"
    },
    {
      city: "MORTON",
      state: "TX"
    },
    {
      city: "MORTON",
      state: "WA"
    },
    {
      city: "MORTON GROVE",
      state: "IL"
    },
    {
      city: "MORTONS GAP",
      state: "KY"
    },
    {
      city: "MORVEN",
      state: "GA"
    },
    {
      city: "MORVEN",
      state: "NC"
    },
    {
      city: "MOSBY",
      state: "MO"
    },
    {
      city: "MOSBY",
      state: "MT"
    },
    {
      city: "MOSCA",
      state: "CO"
    },
    {
      city: "MOSCOW",
      state: "IA"
    },
    {
      city: "MOSCOW",
      state: "ID"
    },
    {
      city: "MOSCOW",
      state: "KS"
    },
    {
      city: "MOSCOW",
      state: "MI"
    },
    {
      city: "MOSCOW",
      state: "OH"
    },
    {
      city: "MOSCOW",
      state: "PA"
    },
    {
      city: "MOSCOW",
      state: "TN"
    },
    {
      city: "MOSCOW",
      state: "TX"
    },
    {
      city: "MOSCOW",
      state: "VT"
    },
    {
      city: "MOSCOW MILLS",
      state: "MO"
    },
    {
      city: "MOSELEY",
      state: "VA"
    },
    {
      city: "MOSELLE",
      state: "MS"
    },
    {
      city: "MOSES LAKE",
      state: "WA"
    },
    {
      city: "MOSHANNON",
      state: "PA"
    },
    {
      city: "MOSHEIM",
      state: "TN"
    },
    {
      city: "MOSHERVILLE",
      state: "MI"
    },
    {
      city: "MOSIER",
      state: "OR"
    },
    {
      city: "MOSINEE",
      state: "WI"
    },
    {
      city: "MOSQUERO",
      state: "NM"
    },
    {
      city: "MOSS",
      state: "MS"
    },
    {
      city: "MOSS",
      state: "TN"
    },
    {
      city: "MOSS BEACH",
      state: "CA"
    },
    {
      city: "MOSS LANDING",
      state: "CA"
    },
    {
      city: "MOSS POINT",
      state: "MS"
    },
    {
      city: "MOSSVILLE",
      state: "IL"
    },
    {
      city: "MOSSY HEAD",
      state: "FL"
    },
    {
      city: "MOSSYROCK",
      state: "WA"
    },
    {
      city: "MOTLEY",
      state: "MN"
    },
    {
      city: "MOTT",
      state: "ND"
    },
    {
      city: "MOTTVILLE",
      state: "NY"
    },
    {
      city: "MOULTON",
      state: "AL"
    },
    {
      city: "MOULTON",
      state: "IA"
    },
    {
      city: "MOULTON",
      state: "TX"
    },
    {
      city: "MOULTONBOROUGH",
      state: "NH"
    },
    {
      city: "MOULTRIE",
      state: "GA"
    },
    {
      city: "MOUND",
      state: "MN"
    },
    {
      city: "MOUND",
      state: "TX"
    },
    {
      city: "MOUND BAYOU",
      state: "MS"
    },
    {
      city: "MOUND CITY",
      state: "IL"
    },
    {
      city: "MOUND CITY",
      state: "KS"
    },
    {
      city: "MOUND CITY",
      state: "MO"
    },
    {
      city: "MOUND CITY",
      state: "SD"
    },
    {
      city: "MOUND VALLEY",
      state: "KS"
    },
    {
      city: "MOUNDRIDGE",
      state: "KS"
    },
    {
      city: "MOUNDS",
      state: "IL"
    },
    {
      city: "MOUNDS",
      state: "OK"
    },
    {
      city: "MOUNDSVILLE",
      state: "WV"
    },
    {
      city: "MOUNDVILLE",
      state: "AL"
    },
    {
      city: "MOUNDVILLE",
      state: "MO"
    },
    {
      city: "MOUNT AETNA",
      state: "PA"
    },
    {
      city: "MOUNT AIRY",
      state: "GA"
    },
    {
      city: "MOUNT AIRY",
      state: "LA"
    },
    {
      city: "MOUNT AIRY",
      state: "MD"
    },
    {
      city: "MOUNT AIRY",
      state: "NC"
    },
    {
      city: "MOUNT ALTO",
      state: "WV"
    },
    {
      city: "MOUNT ANGEL",
      state: "OR"
    },
    {
      city: "MOUNT ARLINGTON",
      state: "NJ"
    },
    {
      city: "MOUNT AUBURN",
      state: "IA"
    },
    {
      city: "MOUNT AUBURN",
      state: "IL"
    },
    {
      city: "MOUNT AUKUM",
      state: "CA"
    },
    {
      city: "MOUNT AYR",
      state: "IA"
    },
    {
      city: "MOUNT BERRY",
      state: "GA"
    },
    {
      city: "MOUNT BETHEL",
      state: "PA"
    },
    {
      city: "MOUNT BLANCHARD",
      state: "OH"
    },
    {
      city: "MOUNT BRADDOCK",
      state: "PA"
    },
    {
      city: "MOUNT CALM",
      state: "TX"
    },
    {
      city: "MOUNT CALVARY",
      state: "WI"
    },
    {
      city: "MOUNT CARBON",
      state: "WV"
    },
    {
      city: "MOUNT CARMEL",
      state: "IL"
    },
    {
      city: "MOUNT CARMEL",
      state: "PA"
    },
    {
      city: "MOUNT CARMEL",
      state: "SC"
    },
    {
      city: "MOUNT CARMEL",
      state: "TN"
    },
    {
      city: "MOUNT CARMEL",
      state: "UT"
    },
    {
      city: "MOUNT CARROLL",
      state: "IL"
    },
    {
      city: "MOUNT CLARE",
      state: "WV"
    },
    {
      city: "MOUNT CLEMENS",
      state: "MI"
    },
    {
      city: "MOUNT CORY",
      state: "OH"
    },
    {
      city: "MOUNT CRAWFORD",
      state: "VA"
    },
    {
      city: "MOUNT CROGHAN",
      state: "SC"
    },
    {
      city: "MOUNT DESERT",
      state: "ME"
    },
    {
      city: "MOUNT DORA",
      state: "FL"
    },
    {
      city: "MOUNT EATON",
      state: "OH"
    },
    {
      city: "MOUNT EDEN",
      state: "KY"
    },
    {
      city: "MOUNT ENTERPRISE",
      state: "TX"
    },
    {
      city: "MOUNT EPHRAIM",
      state: "NJ"
    },
    {
      city: "MOUNT ERIE",
      state: "IL"
    },
    {
      city: "MOUNT FREEDOM",
      state: "NJ"
    },
    {
      city: "MOUNT GAY",
      state: "WV"
    },
    {
      city: "MOUNT GILEAD",
      state: "NC"
    },
    {
      city: "MOUNT GILEAD",
      state: "OH"
    },
    {
      city: "MOUNT GRETNA",
      state: "PA"
    },
    {
      city: "MOUNT HAMILTON",
      state: "CA"
    },
    {
      city: "MOUNT HERMON",
      state: "CA"
    },
    {
      city: "MOUNT HERMON",
      state: "KY"
    },
    {
      city: "MOUNT HERMON",
      state: "LA"
    },
    {
      city: "MOUNT HOLLY",
      state: "AR"
    },
    {
      city: "MOUNT HOLLY",
      state: "NC"
    },
    {
      city: "MOUNT HOLLY",
      state: "NJ"
    },
    {
      city: "MOUNT HOLLY",
      state: "VA"
    },
    {
      city: "MOUNT HOLLY",
      state: "VT"
    },
    {
      city: "MOUNT HOLLY SPRINGS",
      state: "PA"
    },
    {
      city: "MOUNT HOOD PARKDALE",
      state: "OR"
    },
    {
      city: "MOUNT HOPE",
      state: "AL"
    },
    {
      city: "MOUNT HOPE",
      state: "KS"
    },
    {
      city: "MOUNT HOPE",
      state: "OH"
    },
    {
      city: "MOUNT HOPE",
      state: "WI"
    },
    {
      city: "MOUNT HOPE",
      state: "WV"
    },
    {
      city: "MOUNT HOREB",
      state: "WI"
    },
    {
      city: "MOUNT IDA",
      state: "AR"
    },
    {
      city: "MOUNT JACKSON",
      state: "VA"
    },
    {
      city: "MOUNT JEWETT",
      state: "PA"
    },
    {
      city: "MOUNT JOY",
      state: "PA"
    },
    {
      city: "MOUNT JUDEA",
      state: "AR"
    },
    {
      city: "MOUNT JULIET",
      state: "TN"
    },
    {
      city: "MOUNT KISCO",
      state: "NY"
    },
    {
      city: "MOUNT LAGUNA",
      state: "CA"
    },
    {
      city: "MOUNT LAUREL",
      state: "NJ"
    },
    {
      city: "MOUNT LEMMON",
      state: "AZ"
    },
    {
      city: "MOUNT LIBERTY",
      state: "OH"
    },
    {
      city: "MOUNT LOOKOUT",
      state: "WV"
    },
    {
      city: "MOUNT MARION",
      state: "NY"
    },
    {
      city: "MOUNT MEIGS",
      state: "AL"
    },
    {
      city: "MOUNT MORRIS",
      state: "IL"
    },
    {
      city: "MOUNT MORRIS",
      state: "MI"
    },
    {
      city: "MOUNT MORRIS",
      state: "NY"
    },
    {
      city: "MOUNT MORRIS",
      state: "PA"
    },
    {
      city: "MOUNT MOURNE",
      state: "NC"
    },
    {
      city: "MOUNT NEBO",
      state: "WV"
    },
    {
      city: "MOUNT OLIVE",
      state: "AL"
    },
    {
      city: "MOUNT OLIVE",
      state: "IL"
    },
    {
      city: "MOUNT OLIVE",
      state: "MS"
    },
    {
      city: "MOUNT OLIVE",
      state: "NC"
    },
    {
      city: "MOUNT OLIVE",
      state: "WV"
    },
    {
      city: "MOUNT OLIVET",
      state: "KY"
    },
    {
      city: "MOUNT ORAB",
      state: "OH"
    },
    {
      city: "MOUNT PERRY",
      state: "OH"
    },
    {
      city: "MOUNT PLEASANT",
      state: "AR"
    },
    {
      city: "MOUNT PLEASANT",
      state: "IA"
    },
    {
      city: "MOUNT PLEASANT",
      state: "MI"
    },
    {
      city: "MOUNT PLEASANT",
      state: "MS"
    },
    {
      city: "MOUNT PLEASANT",
      state: "NC"
    },
    {
      city: "MOUNT PLEASANT",
      state: "OH"
    },
    {
      city: "MOUNT PLEASANT",
      state: "PA"
    },
    {
      city: "MOUNT PLEASANT",
      state: "SC"
    },
    {
      city: "MOUNT PLEASANT",
      state: "TN"
    },
    {
      city: "MOUNT PLEASANT",
      state: "TX"
    },
    {
      city: "MOUNT PLEASANT",
      state: "UT"
    },
    {
      city: "MOUNT PLEASANT MILLS",
      state: "PA"
    },
    {
      city: "MOUNT POCONO",
      state: "PA"
    },
    {
      city: "MOUNT PROSPECT",
      state: "IL"
    },
    {
      city: "MOUNT PULASKI",
      state: "IL"
    },
    {
      city: "MOUNT RAINIER",
      state: "MD"
    },
    {
      city: "MOUNT ROYAL",
      state: "NJ"
    },
    {
      city: "MOUNT SAINT FRANCIS",
      state: "IN"
    },
    {
      city: "MOUNT SAINT JOSEPH",
      state: "OH"
    },
    {
      city: "MOUNT SAVAGE",
      state: "MD"
    },
    {
      city: "MOUNT SHASTA",
      state: "CA"
    },
    {
      city: "MOUNT SHERMAN",
      state: "KY"
    },
    {
      city: "MOUNT SIDNEY",
      state: "VA"
    },
    {
      city: "MOUNT SINAI",
      state: "NY"
    },
    {
      city: "MOUNT SOLON",
      state: "VA"
    },
    {
      city: "MOUNT STERLING",
      state: "IA"
    },
    {
      city: "MOUNT STERLING",
      state: "IL"
    },
    {
      city: "MOUNT STERLING",
      state: "KY"
    },
    {
      city: "MOUNT STERLING",
      state: "MO"
    },
    {
      city: "MOUNT STERLING",
      state: "OH"
    },
    {
      city: "MOUNT STERLING",
      state: "WI"
    },
    {
      city: "MOUNT STORM",
      state: "WV"
    },
    {
      city: "MOUNT SUMMIT",
      state: "IN"
    },
    {
      city: "MOUNT TABOR",
      state: "NJ"
    },
    {
      city: "MOUNT TREMPER",
      state: "NY"
    },
    {
      city: "MOUNT ULLA",
      state: "NC"
    },
    {
      city: "MOUNT UNION",
      state: "IA"
    },
    {
      city: "MOUNT UNION",
      state: "PA"
    },
    {
      city: "MOUNT UPTON",
      state: "NY"
    },
    {
      city: "MOUNT VERNON",
      state: "AL"
    },
    {
      city: "MOUNT VERNON",
      state: "AR"
    },
    {
      city: "MOUNT VERNON",
      state: "GA"
    },
    {
      city: "MOUNT VERNON",
      state: "IA"
    },
    {
      city: "MOUNT VERNON",
      state: "IL"
    },
    {
      city: "MOUNT VERNON",
      state: "IN"
    },
    {
      city: "MOUNT VERNON",
      state: "KY"
    },
    {
      city: "MOUNT VERNON",
      state: "ME"
    },
    {
      city: "MOUNT VERNON",
      state: "MO"
    },
    {
      city: "MOUNT VERNON",
      state: "NY"
    },
    {
      city: "MOUNT VERNON",
      state: "OH"
    },
    {
      city: "MOUNT VERNON",
      state: "OR"
    },
    {
      city: "MOUNT VERNON",
      state: "SD"
    },
    {
      city: "MOUNT VERNON",
      state: "TX"
    },
    {
      city: "MOUNT VERNON",
      state: "VA"
    },
    {
      city: "MOUNT VERNON",
      state: "WA"
    },
    {
      city: "MOUNT VICTORIA",
      state: "MD"
    },
    {
      city: "MOUNT VICTORY",
      state: "OH"
    },
    {
      city: "MOUNT VISION",
      state: "NY"
    },
    {
      city: "MOUNT WASHINGTON",
      state: "KY"
    },
    {
      city: "MOUNT WASHINGTON",
      state: "NH"
    },
    {
      city: "MOUNT WILSON",
      state: "CA"
    },
    {
      city: "MOUNT WOLF",
      state: "PA"
    },
    {
      city: "MOUNT ZION",
      state: "GA"
    },
    {
      city: "MOUNT ZION",
      state: "WV"
    },
    {
      city: "MOUNTAIN",
      state: "ND"
    },
    {
      city: "MOUNTAIN",
      state: "WI"
    },
    {
      city: "MOUNTAIN CENTER",
      state: "CA"
    },
    {
      city: "MOUNTAIN CITY",
      state: "GA"
    },
    {
      city: "MOUNTAIN CITY",
      state: "NV"
    },
    {
      city: "MOUNTAIN CITY",
      state: "TN"
    },
    {
      city: "MOUNTAIN DALE",
      state: "NY"
    },
    {
      city: "MOUNTAIN GROVE",
      state: "MO"
    },
    {
      city: "MOUNTAIN HOME",
      state: "AR"
    },
    {
      city: "MOUNTAIN HOME",
      state: "ID"
    },
    {
      city: "MOUNTAIN HOME",
      state: "NC"
    },
    {
      city: "MOUNTAIN HOME",
      state: "TN"
    },
    {
      city: "MOUNTAIN HOME",
      state: "TX"
    },
    {
      city: "MOUNTAIN HOME",
      state: "UT"
    },
    {
      city: "MOUNTAIN HOME AFB",
      state: "ID"
    },
    {
      city: "MOUNTAIN IRON",
      state: "MN"
    },
    {
      city: "MOUNTAIN LAKE",
      state: "MN"
    },
    {
      city: "MOUNTAIN LAKES",
      state: "NJ"
    },
    {
      city: "MOUNTAIN PARK",
      state: "OK"
    },
    {
      city: "MOUNTAIN PASS",
      state: "CA"
    },
    {
      city: "MOUNTAIN PINE",
      state: "AR"
    },
    {
      city: "MOUNTAIN RANCH",
      state: "CA"
    },
    {
      city: "MOUNTAIN REST",
      state: "SC"
    },
    {
      city: "MOUNTAIN TOP",
      state: "PA"
    },
    {
      city: "MOUNTAIN VIEW",
      state: "AR"
    },
    {
      city: "MOUNTAIN VIEW",
      state: "CA"
    },
    {
      city: "MOUNTAIN VIEW",
      state: "HI"
    },
    {
      city: "MOUNTAIN VIEW",
      state: "MO"
    },
    {
      city: "MOUNTAIN VIEW",
      state: "OK"
    },
    {
      city: "MOUNTAIN VIEW",
      state: "WY"
    },
    {
      city: "MOUNTAIN VILLAGE",
      state: "AK"
    },
    {
      city: "MOUNTAINAIR",
      state: "NM"
    },
    {
      city: "MOUNTAINBURG",
      state: "AR"
    },
    {
      city: "MOUNTAINHOME",
      state: "PA"
    },
    {
      city: "MOUNTAINSIDE",
      state: "NJ"
    },
    {
      city: "MOUNTAINVILLE",
      state: "NY"
    },
    {
      city: "MOUNTLAKE TERRACE",
      state: "WA"
    },
    {
      city: "MOUNTVILLE",
      state: "PA"
    },
    {
      city: "MOUNTVILLE",
      state: "SC"
    },
    {
      city: "MOUSIE",
      state: "KY"
    },
    {
      city: "MOUTH OF WILSON",
      state: "VA"
    },
    {
      city: "MOUTHCARD",
      state: "KY"
    },
    {
      city: "MOVILLE",
      state: "IA"
    },
    {
      city: "MOWEAQUA",
      state: "IL"
    },
    {
      city: "MOWRYSTOWN",
      state: "OH"
    },
    {
      city: "MOXAHALA",
      state: "OH"
    },
    {
      city: "MOXEE",
      state: "WA"
    },
    {
      city: "MOYERS",
      state: "OK"
    },
    {
      city: "MOYIE SPRINGS",
      state: "ID"
    },
    {
      city: "MOYOCK",
      state: "NC"
    },
    {
      city: "MOZIER",
      state: "IL"
    },
    {
      city: "MT BALDY",
      state: "CA"
    },
    {
      city: "MT ZION",
      state: "IL"
    },
    {
      city: "MUD BUTTE",
      state: "SD"
    },
    {
      city: "MUDDY",
      state: "IL"
    },
    {
      city: "MUENSTER",
      state: "TX"
    },
    {
      city: "MUIR",
      state: "MI"
    },
    {
      city: "MUIR",
      state: "PA"
    },
    {
      city: "MUKILTEO",
      state: "WA"
    },
    {
      city: "MUKWONAGO",
      state: "WI"
    },
    {
      city: "MULBERRY",
      state: "AR"
    },
    {
      city: "MULBERRY",
      state: "FL"
    },
    {
      city: "MULBERRY",
      state: "IN"
    },
    {
      city: "MULBERRY",
      state: "KS"
    },
    {
      city: "MULBERRY",
      state: "TN"
    },
    {
      city: "MULBERRY GROVE",
      state: "IL"
    },
    {
      city: "MULDOON",
      state: "TX"
    },
    {
      city: "MULDRAUGH",
      state: "KY"
    },
    {
      city: "MULDROW",
      state: "OK"
    },
    {
      city: "MULE CREEK",
      state: "NM"
    },
    {
      city: "MULESHOE",
      state: "TX"
    },
    {
      city: "MULGA",
      state: "AL"
    },
    {
      city: "MULHALL",
      state: "OK"
    },
    {
      city: "MULINO",
      state: "OR"
    },
    {
      city: "MULKEYTOWN",
      state: "IL"
    },
    {
      city: "MULLAN",
      state: "ID"
    },
    {
      city: "MULLEN",
      state: "NE"
    },
    {
      city: "MULLENS",
      state: "WV"
    },
    {
      city: "MULLETT LAKE",
      state: "MI"
    },
    {
      city: "MULLICA HILL",
      state: "NJ"
    },
    {
      city: "MULLIKEN",
      state: "MI"
    },
    {
      city: "MULLIN",
      state: "TX"
    },
    {
      city: "MULLINS",
      state: "SC"
    },
    {
      city: "MULLINVILLE",
      state: "KS"
    },
    {
      city: "MULVANE",
      state: "KS"
    },
    {
      city: "MUMFORD",
      state: "NY"
    },
    {
      city: "MUMFORD",
      state: "TX"
    },
    {
      city: "MUNCIE",
      state: "IL"
    },
    {
      city: "MUNCIE",
      state: "IN"
    },
    {
      city: "MUNCY",
      state: "PA"
    },
    {
      city: "MUNCY VALLEY",
      state: "PA"
    },
    {
      city: "MUNDAY",
      state: "TX"
    },
    {
      city: "MUNDAY",
      state: "WV"
    },
    {
      city: "MUNDELEIN",
      state: "IL"
    },
    {
      city: "MUNDEN",
      state: "KS"
    },
    {
      city: "MUNDS PARK",
      state: "AZ"
    },
    {
      city: "MUNFORD",
      state: "AL"
    },
    {
      city: "MUNFORD",
      state: "TN"
    },
    {
      city: "MUNFORDVILLE",
      state: "KY"
    },
    {
      city: "MUNGER",
      state: "MI"
    },
    {
      city: "MUNICH",
      state: "ND"
    },
    {
      city: "MUNISING",
      state: "MI"
    },
    {
      city: "MUNITH",
      state: "MI"
    },
    {
      city: "MUNNSVILLE",
      state: "NY"
    },
    {
      city: "MUNROE FALLS",
      state: "OH"
    },
    {
      city: "MUNSON",
      state: "PA"
    },
    {
      city: "MUNSTER",
      state: "IN"
    },
    {
      city: "MURCHISON",
      state: "TX"
    },
    {
      city: "MURDO",
      state: "SD"
    },
    {
      city: "MURDOCK",
      state: "FL"
    },
    {
      city: "MURDOCK",
      state: "KS"
    },
    {
      city: "MURDOCK",
      state: "MN"
    },
    {
      city: "MURDOCK",
      state: "NE"
    },
    {
      city: "MURFREESBORO",
      state: "AR"
    },
    {
      city: "MURFREESBORO",
      state: "NC"
    },
    {
      city: "MURFREESBORO",
      state: "TN"
    },
    {
      city: "MURPHY",
      state: "ID"
    },
    {
      city: "MURPHY",
      state: "NC"
    },
    {
      city: "MURPHY",
      state: "OR"
    },
    {
      city: "MURPHYS",
      state: "CA"
    },
    {
      city: "MURPHYSBORO",
      state: "IL"
    },
    {
      city: "MURRAY",
      state: "IA"
    },
    {
      city: "MURRAY",
      state: "ID"
    },
    {
      city: "MURRAY",
      state: "KY"
    },
    {
      city: "MURRAY",
      state: "NE"
    },
    {
      city: "MURRAY CITY",
      state: "OH"
    },
    {
      city: "MURRAYVILLE",
      state: "GA"
    },
    {
      city: "MURRAYVILLE",
      state: "IL"
    },
    {
      city: "MURRELLS INLET",
      state: "SC"
    },
    {
      city: "MURRIETA",
      state: "CA"
    },
    {
      city: "MURRYSVILLE",
      state: "PA"
    },
    {
      city: "MURTAUGH",
      state: "ID"
    },
    {
      city: "MUSCADINE",
      state: "AL"
    },
    {
      city: "MUSCATINE",
      state: "IA"
    },
    {
      city: "MUSCLE SHOALS",
      state: "AL"
    },
    {
      city: "MUSCODA",
      state: "WI"
    },
    {
      city: "MUSCOTAH",
      state: "KS"
    },
    {
      city: "MUSE",
      state: "PA"
    },
    {
      city: "MUSELLA",
      state: "GA"
    },
    {
      city: "MUSES MILLS",
      state: "KY"
    },
    {
      city: "MUSKEGO",
      state: "WI"
    },
    {
      city: "MUSKEGON",
      state: "MI"
    },
    {
      city: "MUSKOGEE",
      state: "OK"
    },
    {
      city: "MUSSELSHELL",
      state: "MT"
    },
    {
      city: "MUSTANG",
      state: "OK"
    },
    {
      city: "MUSTOE",
      state: "VA"
    },
    {
      city: "MUTUAL",
      state: "OK"
    },
    {
      city: "MYAKKA CITY",
      state: "FL"
    },
    {
      city: "MYERS FLAT",
      state: "CA"
    },
    {
      city: "MYERSTOWN",
      state: "PA"
    },
    {
      city: "MYERSVILLE",
      state: "MD"
    },
    {
      city: "MYLO",
      state: "ND"
    },
    {
      city: "MYRA",
      state: "KY"
    },
    {
      city: "MYRA",
      state: "TX"
    },
    {
      city: "MYRTLE",
      state: "MO"
    },
    {
      city: "MYRTLE",
      state: "MS"
    },
    {
      city: "MYRTLE BEACH",
      state: "SC"
    },
    {
      city: "MYRTLE CREEK",
      state: "OR"
    },
    {
      city: "MYRTLE POINT",
      state: "OR"
    },
    {
      city: "MYRTLEWOOD",
      state: "AL"
    },
    {
      city: "MYSTIC",
      state: "CT"
    },
    {
      city: "MYSTIC",
      state: "GA"
    },
    {
      city: "MYSTIC",
      state: "IA"
    },
    {
      city: "MYTON",
      state: "UT"
    },
    {
      city: "NAALEHU",
      state: "HI"
    },
    {
      city: "NABB",
      state: "IN"
    },
    {
      city: "NACHES",
      state: "WA"
    },
    {
      city: "NACHUSA",
      state: "IL"
    },
    {
      city: "NACO",
      state: "AZ"
    },
    {
      city: "NACOGDOCHES",
      state: "TX"
    },
    {
      city: "NADA",
      state: "TX"
    },
    {
      city: "NADEAU",
      state: "MI"
    },
    {
      city: "NAGEEZI",
      state: "NM"
    },
    {
      city: "NAGS HEAD",
      state: "NC"
    },
    {
      city: "NAHANT",
      state: "MA"
    },
    {
      city: "NAHCOTTA",
      state: "WA"
    },
    {
      city: "NAHMA",
      state: "MI"
    },
    {
      city: "NAHUNTA",
      state: "GA"
    },
    {
      city: "NAKINA",
      state: "NC"
    },
    {
      city: "NAKNEK",
      state: "AK"
    },
    {
      city: "NALCREST",
      state: "FL"
    },
    {
      city: "NALLEN",
      state: "WV"
    },
    {
      city: "NAMPA",
      state: "ID"
    },
    {
      city: "NANAFALIA",
      state: "AL"
    },
    {
      city: "NANCY",
      state: "KY"
    },
    {
      city: "NANJEMOY",
      state: "MD"
    },
    {
      city: "NANKIN",
      state: "OH"
    },
    {
      city: "NANTICOKE",
      state: "MD"
    },
    {
      city: "NANTICOKE",
      state: "PA"
    },
    {
      city: "NANTUCKET",
      state: "MA"
    },
    {
      city: "NANTY GLO",
      state: "PA"
    },
    {
      city: "NANUET",
      state: "NY"
    },
    {
      city: "NAOMA",
      state: "WV"
    },
    {
      city: "NAPA",
      state: "CA"
    },
    {
      city: "NAPAKIAK",
      state: "AK"
    },
    {
      city: "NAPANOCH",
      state: "NY"
    },
    {
      city: "NAPAVINE",
      state: "WA"
    },
    {
      city: "NAPER",
      state: "NE"
    },
    {
      city: "NAPERVILLE",
      state: "IL"
    },
    {
      city: "NAPLES",
      state: "FL"
    },
    {
      city: "NAPLES",
      state: "ID"
    },
    {
      city: "NAPLES",
      state: "ME"
    },
    {
      city: "NAPLES",
      state: "NC"
    },
    {
      city: "NAPLES",
      state: "NY"
    },
    {
      city: "NAPLES",
      state: "TX"
    },
    {
      city: "NAPOLEON",
      state: "IN"
    },
    {
      city: "NAPOLEON",
      state: "MI"
    },
    {
      city: "NAPOLEON",
      state: "MO"
    },
    {
      city: "NAPOLEON",
      state: "ND"
    },
    {
      city: "NAPOLEON",
      state: "OH"
    },
    {
      city: "NAPOLEONVILLE",
      state: "LA"
    },
    {
      city: "NAPONEE",
      state: "NE"
    },
    {
      city: "NAPPANEE",
      state: "IN"
    },
    {
      city: "NARA VISA",
      state: "NM"
    },
    {
      city: "NARBERTH",
      state: "PA"
    },
    {
      city: "NARDIN",
      state: "OK"
    },
    {
      city: "NARKA",
      state: "KS"
    },
    {
      city: "NARRAGANSETT",
      state: "RI"
    },
    {
      city: "NARROWS",
      state: "VA"
    },
    {
      city: "NARROWSBURG",
      state: "NY"
    },
    {
      city: "NARVON",
      state: "PA"
    },
    {
      city: "NASELLE",
      state: "WA"
    },
    {
      city: "NASH",
      state: "OK"
    },
    {
      city: "NASH",
      state: "TX"
    },
    {
      city: "NASHOBA",
      state: "OK"
    },
    {
      city: "NASHOTAH",
      state: "WI"
    },
    {
      city: "NASHPORT",
      state: "OH"
    },
    {
      city: "NASHUA",
      state: "IA"
    },
    {
      city: "NASHUA",
      state: "MN"
    },
    {
      city: "NASHUA",
      state: "MT"
    },
    {
      city: "NASHUA",
      state: "NH"
    },
    {
      city: "NASHVILLE",
      state: "AR"
    },
    {
      city: "NASHVILLE",
      state: "GA"
    },
    {
      city: "NASHVILLE",
      state: "IL"
    },
    {
      city: "NASHVILLE",
      state: "IN"
    },
    {
      city: "NASHVILLE",
      state: "KS"
    },
    {
      city: "NASHVILLE",
      state: "MI"
    },
    {
      city: "NASHVILLE",
      state: "NC"
    },
    {
      city: "NASHVILLE",
      state: "OH"
    },
    {
      city: "NASHVILLE",
      state: "TN"
    },
    {
      city: "NASHWAUK",
      state: "MN"
    },
    {
      city: "NASON",
      state: "IL"
    },
    {
      city: "NASSAU",
      state: "DE"
    },
    {
      city: "NASSAU",
      state: "NY"
    },
    {
      city: "NASSAWADOX",
      state: "VA"
    },
    {
      city: "NATALBANY",
      state: "LA"
    },
    {
      city: "NATALIA",
      state: "TX"
    },
    {
      city: "NATCHEZ",
      state: "LA"
    },
    {
      city: "NATCHEZ",
      state: "MS"
    },
    {
      city: "NATCHITOCHES",
      state: "LA"
    },
    {
      city: "NATHALIE",
      state: "VA"
    },
    {
      city: "NATHROP",
      state: "CO"
    },
    {
      city: "NATICK",
      state: "MA"
    },
    {
      city: "NATIONAL CITY",
      state: "CA"
    },
    {
      city: "NATIONAL CITY",
      state: "MI"
    },
    {
      city: "NATIONAL PARK",
      state: "NJ"
    },
    {
      city: "NATIONAL STOCK YARDS",
      state: "IL"
    },
    {
      city: "NATOMA",
      state: "KS"
    },
    {
      city: "NATRONA HEIGHTS",
      state: "PA"
    },
    {
      city: "NATURAL BRIDGE",
      state: "AL"
    },
    {
      city: "NATURAL BRIDGE",
      state: "NY"
    },
    {
      city: "NATURAL BRIDGE",
      state: "VA"
    },
    {
      city: "NATURAL BRIDGE STATION",
      state: "VA"
    },
    {
      city: "NATURAL DAM",
      state: "AR"
    },
    {
      city: "NATURITA",
      state: "CO"
    },
    {
      city: "NAUBINWAY",
      state: "MI"
    },
    {
      city: "NAUGATUCK",
      state: "CT"
    },
    {
      city: "NAUGATUCK",
      state: "WV"
    },
    {
      city: "NAUVOO",
      state: "AL"
    },
    {
      city: "NAUVOO",
      state: "IL"
    },
    {
      city: "NAVAJO",
      state: "NM"
    },
    {
      city: "NAVAJO DAM",
      state: "NM"
    },
    {
      city: "NAVAL AIR STATION JRB",
      state: "TX"
    },
    {
      city: "NAVAL ANACOST ANNEX",
      state: "DC"
    },
    {
      city: "NAVARRE",
      state: "FL"
    },
    {
      city: "NAVARRE",
      state: "MN"
    },
    {
      city: "NAVARRE",
      state: "OH"
    },
    {
      city: "NAVARRO",
      state: "CA"
    },
    {
      city: "NAVASOTA",
      state: "TX"
    },
    {
      city: "NAVESINK",
      state: "NJ"
    },
    {
      city: "NAYLOR",
      state: "GA"
    },
    {
      city: "NAYLOR",
      state: "MO"
    },
    {
      city: "NAYTAHWAUSH",
      state: "MN"
    },
    {
      city: "NAZARETH",
      state: "KY"
    },
    {
      city: "NAZARETH",
      state: "MI"
    },
    {
      city: "NAZARETH",
      state: "PA"
    },
    {
      city: "NAZARETH",
      state: "TX"
    },
    {
      city: "NEAH BAY",
      state: "WA"
    },
    {
      city: "NEAPOLIS",
      state: "OH"
    },
    {
      city: "NEAVITT",
      state: "MD"
    },
    {
      city: "NEBO",
      state: "IL"
    },
    {
      city: "NEBO",
      state: "KY"
    },
    {
      city: "NEBO",
      state: "NC"
    },
    {
      city: "NEBO",
      state: "WV"
    },
    {
      city: "NEBRASKA CITY",
      state: "NE"
    },
    {
      city: "NECEDAH",
      state: "WI"
    },
    {
      city: "NECHE",
      state: "ND"
    },
    {
      city: "NECHES",
      state: "TX"
    },
    {
      city: "NEDERLAND",
      state: "CO"
    },
    {
      city: "NEDERLAND",
      state: "TX"
    },
    {
      city: "NEDROW",
      state: "NY"
    },
    {
      city: "NEEDHAM",
      state: "AL"
    },
    {
      city: "NEEDHAM",
      state: "IN"
    },
    {
      city: "NEEDHAM",
      state: "MA"
    },
    {
      city: "NEEDHAM HEIGHTS",
      state: "MA"
    },
    {
      city: "NEEDLES",
      state: "CA"
    },
    {
      city: "NEEDMORE",
      state: "PA"
    },
    {
      city: "NEEDVILLE",
      state: "TX"
    },
    {
      city: "NEELY",
      state: "MS"
    },
    {
      city: "NEELYTON",
      state: "PA"
    },
    {
      city: "NEELYVILLE",
      state: "MO"
    },
    {
      city: "NEENAH",
      state: "WI"
    },
    {
      city: "NEESES",
      state: "SC"
    },
    {
      city: "NEFFS",
      state: "OH"
    },
    {
      city: "NEFFS",
      state: "PA"
    },
    {
      city: "NEGAUNEE",
      state: "MI"
    },
    {
      city: "NEGLEY",
      state: "OH"
    },
    {
      city: "NEGREET",
      state: "LA"
    },
    {
      city: "NEHALEM",
      state: "OR"
    },
    {
      city: "NEHAWKA",
      state: "NE"
    },
    {
      city: "NEIHART",
      state: "MT"
    },
    {
      city: "NEILLSVILLE",
      state: "WI"
    },
    {
      city: "NEILTON",
      state: "WA"
    },
    {
      city: "NEKOMA",
      state: "ND"
    },
    {
      city: "NEKOOSA",
      state: "WI"
    },
    {
      city: "NELIGH",
      state: "NE"
    },
    {
      city: "NELLIS AFB",
      state: "NV"
    },
    {
      city: "NELLISTON",
      state: "NY"
    },
    {
      city: "NELLYSFORD",
      state: "VA"
    },
    {
      city: "NELSON",
      state: "CA"
    },
    {
      city: "NELSON",
      state: "GA"
    },
    {
      city: "NELSON",
      state: "MN"
    },
    {
      city: "NELSON",
      state: "MO"
    },
    {
      city: "NELSON",
      state: "NE"
    },
    {
      city: "NELSON",
      state: "NH"
    },
    {
      city: "NELSON",
      state: "PA"
    },
    {
      city: "NELSON",
      state: "VA"
    },
    {
      city: "NELSON",
      state: "WI"
    },
    {
      city: "NELSONIA",
      state: "VA"
    },
    {
      city: "NELSONVILLE",
      state: "OH"
    },
    {
      city: "NELSONVILLE",
      state: "WI"
    },
    {
      city: "NEMACOLIN",
      state: "PA"
    },
    {
      city: "NEMAHA",
      state: "IA"
    },
    {
      city: "NEMAHA",
      state: "NE"
    },
    {
      city: "NEMO",
      state: "SD"
    },
    {
      city: "NEMO",
      state: "TX"
    },
    {
      city: "NENANA",
      state: "AK"
    },
    {
      city: "NENZEL",
      state: "NE"
    },
    {
      city: "NEODESHA",
      state: "KS"
    },
    {
      city: "NEOGA",
      state: "IL"
    },
    {
      city: "NEOLA",
      state: "IA"
    },
    {
      city: "NEOLA",
      state: "UT"
    },
    {
      city: "NEON",
      state: "KY"
    },
    {
      city: "NEOPIT",
      state: "WI"
    },
    {
      city: "NEOSHO",
      state: "MO"
    },
    {
      city: "NEOSHO",
      state: "WI"
    },
    {
      city: "NEOSHO FALLS",
      state: "KS"
    },
    {
      city: "NEOSHO RAPIDS",
      state: "KS"
    },
    {
      city: "NEOTSU",
      state: "OR"
    },
    {
      city: "NEPHI",
      state: "UT"
    },
    {
      city: "NEPONSET",
      state: "IL"
    },
    {
      city: "NEPTUNE",
      state: "NJ"
    },
    {
      city: "NEPTUNE BEACH",
      state: "FL"
    },
    {
      city: "NERINX",
      state: "KY"
    },
    {
      city: "NERSTRAND",
      state: "MN"
    },
    {
      city: "NESBIT",
      state: "MS"
    },
    {
      city: "NESCONSET",
      state: "NY"
    },
    {
      city: "NESCOPECK",
      state: "PA"
    },
    {
      city: "NESHANIC STATION",
      state: "NJ"
    },
    {
      city: "NESHKORO",
      state: "WI"
    },
    {
      city: "NESKOWIN",
      state: "OR"
    },
    {
      city: "NESMITH",
      state: "SC"
    },
    {
      city: "NESPELEM",
      state: "WA"
    },
    {
      city: "NESQUEHONING",
      state: "PA"
    },
    {
      city: "NESS CITY",
      state: "KS"
    },
    {
      city: "NETARTS",
      state: "OR"
    },
    {
      city: "NETAWAKA",
      state: "KS"
    },
    {
      city: "NETCONG",
      state: "NJ"
    },
    {
      city: "NETT LAKE",
      state: "MN"
    },
    {
      city: "NETTIE",
      state: "WV"
    },
    {
      city: "NETTLETON",
      state: "MS"
    },
    {
      city: "NEVADA",
      state: "IA"
    },
    {
      city: "NEVADA",
      state: "MO"
    },
    {
      city: "NEVADA",
      state: "OH"
    },
    {
      city: "NEVADA",
      state: "TX"
    },
    {
      city: "NEVADA CITY",
      state: "CA"
    },
    {
      city: "NEVERSINK",
      state: "NY"
    },
    {
      city: "NEVIS",
      state: "MN"
    },
    {
      city: "NEW ALBANY",
      state: "IN"
    },
    {
      city: "NEW ALBANY",
      state: "MS"
    },
    {
      city: "NEW ALBANY",
      state: "OH"
    },
    {
      city: "NEW ALBANY",
      state: "PA"
    },
    {
      city: "NEW ALBIN",
      state: "IA"
    },
    {
      city: "NEW ALEXANDRIA",
      state: "PA"
    },
    {
      city: "NEW ALMADEN",
      state: "CA"
    },
    {
      city: "NEW ATHENS",
      state: "IL"
    },
    {
      city: "NEW ATHENS",
      state: "OH"
    },
    {
      city: "NEW AUBURN",
      state: "MN"
    },
    {
      city: "NEW AUBURN",
      state: "WI"
    },
    {
      city: "NEW AUGUSTA",
      state: "MS"
    },
    {
      city: "NEW BADEN",
      state: "IL"
    },
    {
      city: "NEW BADEN",
      state: "TX"
    },
    {
      city: "NEW BALTIMORE",
      state: "MI"
    },
    {
      city: "NEW BALTIMORE",
      state: "NY"
    },
    {
      city: "NEW BALTIMORE",
      state: "PA"
    },
    {
      city: "NEW BAVARIA",
      state: "OH"
    },
    {
      city: "NEW BEDFORD",
      state: "IL"
    },
    {
      city: "NEW BEDFORD",
      state: "MA"
    },
    {
      city: "NEW BEDFORD",
      state: "PA"
    },
    {
      city: "NEW BERLIN",
      state: "IL"
    },
    {
      city: "NEW BERLIN",
      state: "NY"
    },
    {
      city: "NEW BERLIN",
      state: "PA"
    },
    {
      city: "NEW BERLIN",
      state: "WI"
    },
    {
      city: "NEW BERLINVILLE",
      state: "PA"
    },
    {
      city: "NEW BERN",
      state: "NC"
    },
    {
      city: "NEW BETHLEHEM",
      state: "PA"
    },
    {
      city: "NEW BLAINE",
      state: "AR"
    },
    {
      city: "NEW BLOOMFIELD",
      state: "MO"
    },
    {
      city: "NEW BLOOMFIELD",
      state: "PA"
    },
    {
      city: "NEW BLOOMINGTON",
      state: "OH"
    },
    {
      city: "NEW BOSTON",
      state: "IL"
    },
    {
      city: "NEW BOSTON",
      state: "MI"
    },
    {
      city: "NEW BOSTON",
      state: "MO"
    },
    {
      city: "NEW BOSTON",
      state: "NH"
    },
    {
      city: "NEW BOSTON",
      state: "TX"
    },
    {
      city: "NEW BRAINTREE",
      state: "MA"
    },
    {
      city: "NEW BRAUNFELS",
      state: "TX"
    },
    {
      city: "NEW BREMEN",
      state: "OH"
    },
    {
      city: "NEW BRIGHTON",
      state: "PA"
    },
    {
      city: "NEW BRITAIN",
      state: "CT"
    },
    {
      city: "NEW BROCKTON",
      state: "AL"
    },
    {
      city: "NEW BRUNSWICK",
      state: "NJ"
    },
    {
      city: "NEW BUFFALO",
      state: "MI"
    },
    {
      city: "NEW BUFFALO",
      state: "PA"
    },
    {
      city: "NEW BURNSIDE",
      state: "IL"
    },
    {
      city: "NEW CAMBRIA",
      state: "KS"
    },
    {
      city: "NEW CAMBRIA",
      state: "MO"
    },
    {
      city: "NEW CANAAN",
      state: "CT"
    },
    {
      city: "NEW CANEY",
      state: "TX"
    },
    {
      city: "NEW CANTON",
      state: "IL"
    },
    {
      city: "NEW CANTON",
      state: "VA"
    },
    {
      city: "NEW CARLISLE",
      state: "IN"
    },
    {
      city: "NEW CARLISLE",
      state: "OH"
    },
    {
      city: "NEW CASTLE",
      state: "AL"
    },
    {
      city: "NEW CASTLE",
      state: "CO"
    },
    {
      city: "NEW CASTLE",
      state: "DE"
    },
    {
      city: "NEW CASTLE",
      state: "IN"
    },
    {
      city: "NEW CASTLE",
      state: "KY"
    },
    {
      city: "NEW CASTLE",
      state: "NH"
    },
    {
      city: "NEW CASTLE",
      state: "PA"
    },
    {
      city: "NEW CASTLE",
      state: "VA"
    },
    {
      city: "NEW CENTURY",
      state: "KS"
    },
    {
      city: "NEW CHURCH",
      state: "VA"
    },
    {
      city: "NEW CITY",
      state: "NY"
    },
    {
      city: "NEW COLUMBIA",
      state: "PA"
    },
    {
      city: "NEW CONCORD",
      state: "KY"
    },
    {
      city: "NEW CONCORD",
      state: "OH"
    },
    {
      city: "NEW CREEK",
      state: "WV"
    },
    {
      city: "NEW CUMBERLAND",
      state: "PA"
    },
    {
      city: "NEW CUMBERLAND",
      state: "WV"
    },
    {
      city: "NEW CUYAMA",
      state: "CA"
    },
    {
      city: "NEW DEAL",
      state: "TX"
    },
    {
      city: "NEW DERRY",
      state: "PA"
    },
    {
      city: "NEW DOUGLAS",
      state: "IL"
    },
    {
      city: "NEW DURHAM",
      state: "NH"
    },
    {
      city: "NEW EAGLE",
      state: "PA"
    },
    {
      city: "NEW EDINBURG",
      state: "AR"
    },
    {
      city: "NEW EFFINGTON",
      state: "SD"
    },
    {
      city: "NEW EGYPT",
      state: "NJ"
    },
    {
      city: "NEW ELLENTON",
      state: "SC"
    },
    {
      city: "NEW ENGLAND",
      state: "ND"
    },
    {
      city: "NEW ENTERPRISE",
      state: "PA"
    },
    {
      city: "NEW ERA",
      state: "MI"
    },
    {
      city: "NEW FAIRFIELD",
      state: "CT"
    },
    {
      city: "NEW FLORENCE",
      state: "MO"
    },
    {
      city: "NEW FLORENCE",
      state: "PA"
    },
    {
      city: "NEW FRANKEN",
      state: "WI"
    },
    {
      city: "NEW FRANKLIN",
      state: "MO"
    },
    {
      city: "NEW FREEDOM",
      state: "PA"
    },
    {
      city: "NEW FREEPORT",
      state: "PA"
    },
    {
      city: "NEW GALILEE",
      state: "PA"
    },
    {
      city: "NEW GENEVA",
      state: "PA"
    },
    {
      city: "NEW GERMANY",
      state: "MN"
    },
    {
      city: "NEW GLARUS",
      state: "WI"
    },
    {
      city: "NEW GLOUCESTER",
      state: "ME"
    },
    {
      city: "NEW GRETNA",
      state: "NJ"
    },
    {
      city: "NEW HAMPSHIRE",
      state: "OH"
    },
    {
      city: "NEW HAMPTON",
      state: "IA"
    },
    {
      city: "NEW HAMPTON",
      state: "MO"
    },
    {
      city: "NEW HAMPTON",
      state: "NH"
    },
    {
      city: "NEW HAMPTON",
      state: "NY"
    },
    {
      city: "NEW HARBOR",
      state: "ME"
    },
    {
      city: "NEW HARMONY",
      state: "IN"
    },
    {
      city: "NEW HARMONY",
      state: "UT"
    },
    {
      city: "NEW HARTFORD",
      state: "CT"
    },
    {
      city: "NEW HARTFORD",
      state: "IA"
    },
    {
      city: "NEW HARTFORD",
      state: "NY"
    },
    {
      city: "NEW HAVEN",
      state: "CT"
    },
    {
      city: "NEW HAVEN",
      state: "IL"
    },
    {
      city: "NEW HAVEN",
      state: "IN"
    },
    {
      city: "NEW HAVEN",
      state: "KY"
    },
    {
      city: "NEW HAVEN",
      state: "MI"
    },
    {
      city: "NEW HAVEN",
      state: "MO"
    },
    {
      city: "NEW HAVEN",
      state: "NY"
    },
    {
      city: "NEW HAVEN",
      state: "OH"
    },
    {
      city: "NEW HAVEN",
      state: "VT"
    },
    {
      city: "NEW HAVEN",
      state: "WV"
    },
    {
      city: "NEW HILL",
      state: "NC"
    },
    {
      city: "NEW HOLLAND",
      state: "IL"
    },
    {
      city: "NEW HOLLAND",
      state: "OH"
    },
    {
      city: "NEW HOLLAND",
      state: "PA"
    },
    {
      city: "NEW HOLLAND",
      state: "SD"
    },
    {
      city: "NEW HOLSTEIN",
      state: "WI"
    },
    {
      city: "NEW HOME",
      state: "TX"
    },
    {
      city: "NEW HOPE",
      state: "AL"
    },
    {
      city: "NEW HOPE",
      state: "KY"
    },
    {
      city: "NEW HOPE",
      state: "PA"
    },
    {
      city: "NEW HOPE",
      state: "VA"
    },
    {
      city: "NEW HUDSON",
      state: "MI"
    },
    {
      city: "NEW HYDE PARK",
      state: "NY"
    },
    {
      city: "NEW IBERIA",
      state: "LA"
    },
    {
      city: "NEW IPSWICH",
      state: "NH"
    },
    {
      city: "NEW JOHNSONVILLE",
      state: "TN"
    },
    {
      city: "NEW KENSINGTON",
      state: "PA"
    },
    {
      city: "NEW KENT",
      state: "VA"
    },
    {
      city: "NEW KINGSTON",
      state: "NY"
    },
    {
      city: "NEW KINGSTOWN",
      state: "PA"
    },
    {
      city: "NEW KNOXVILLE",
      state: "OH"
    },
    {
      city: "NEW LAGUNA",
      state: "NM"
    },
    {
      city: "NEW LEBANON",
      state: "NY"
    },
    {
      city: "NEW LEBANON",
      state: "OH"
    },
    {
      city: "NEW LEIPZIG",
      state: "ND"
    },
    {
      city: "NEW LENOX",
      state: "IL"
    },
    {
      city: "NEW LEXINGTON",
      state: "OH"
    },
    {
      city: "NEW LIBERTY",
      state: "IA"
    },
    {
      city: "NEW LIBERTY",
      state: "KY"
    },
    {
      city: "NEW LIMERICK",
      state: "ME"
    },
    {
      city: "NEW LISBON",
      state: "IN"
    },
    {
      city: "NEW LISBON",
      state: "NJ"
    },
    {
      city: "NEW LISBON",
      state: "WI"
    },
    {
      city: "NEW LLANO",
      state: "LA"
    },
    {
      city: "NEW LONDON",
      state: "CT"
    },
    {
      city: "NEW LONDON",
      state: "IA"
    },
    {
      city: "NEW LONDON",
      state: "MN"
    },
    {
      city: "NEW LONDON",
      state: "MO"
    },
    {
      city: "NEW LONDON",
      state: "NC"
    },
    {
      city: "NEW LONDON",
      state: "NH"
    },
    {
      city: "NEW LONDON",
      state: "OH"
    },
    {
      city: "NEW LONDON",
      state: "PA"
    },
    {
      city: "NEW LONDON",
      state: "TX"
    },
    {
      city: "NEW LONDON",
      state: "WI"
    },
    {
      city: "NEW LOTHROP",
      state: "MI"
    },
    {
      city: "NEW MADISON",
      state: "OH"
    },
    {
      city: "NEW MADRID",
      state: "MO"
    },
    {
      city: "NEW MANCHESTER",
      state: "WV"
    },
    {
      city: "NEW MARKET",
      state: "AL"
    },
    {
      city: "NEW MARKET",
      state: "IA"
    },
    {
      city: "NEW MARKET",
      state: "IN"
    },
    {
      city: "NEW MARKET",
      state: "MD"
    },
    {
      city: "NEW MARKET",
      state: "TN"
    },
    {
      city: "NEW MARKET",
      state: "VA"
    },
    {
      city: "NEW MARSHFIELD",
      state: "OH"
    },
    {
      city: "NEW MARTINSVILLE",
      state: "WV"
    },
    {
      city: "NEW MATAMORAS",
      state: "OH"
    },
    {
      city: "NEW MEADOWS",
      state: "ID"
    },
    {
      city: "NEW MELLE",
      state: "MO"
    },
    {
      city: "NEW MEMPHIS",
      state: "IL"
    },
    {
      city: "NEW MIDDLETOWN",
      state: "IN"
    },
    {
      city: "NEW MIDDLETOWN",
      state: "OH"
    },
    {
      city: "NEW MIDWAY",
      state: "MD"
    },
    {
      city: "NEW MILFORD",
      state: "CT"
    },
    {
      city: "NEW MILFORD",
      state: "NJ"
    },
    {
      city: "NEW MILFORD",
      state: "NY"
    },
    {
      city: "NEW MILFORD",
      state: "PA"
    },
    {
      city: "NEW MILLPORT",
      state: "PA"
    },
    {
      city: "NEW MILTON",
      state: "WV"
    },
    {
      city: "NEW MUNICH",
      state: "MN"
    },
    {
      city: "NEW MUNSTER",
      state: "WI"
    },
    {
      city: "NEW ORLEANS",
      state: "LA"
    },
    {
      city: "NEW OXFORD",
      state: "PA"
    },
    {
      city: "NEW PALESTINE",
      state: "IN"
    },
    {
      city: "NEW PALTZ",
      state: "NY"
    },
    {
      city: "NEW PARIS",
      state: "IN"
    },
    {
      city: "NEW PARIS",
      state: "OH"
    },
    {
      city: "NEW PARIS",
      state: "PA"
    },
    {
      city: "NEW PARK",
      state: "PA"
    },
    {
      city: "NEW PHILADELPHIA",
      state: "OH"
    },
    {
      city: "NEW PHILADELPHIA",
      state: "PA"
    },
    {
      city: "NEW PINE CREEK",
      state: "OR"
    },
    {
      city: "NEW PLYMOUTH",
      state: "ID"
    },
    {
      city: "NEW PLYMOUTH",
      state: "OH"
    },
    {
      city: "NEW POINT",
      state: "IN"
    },
    {
      city: "NEW POINT",
      state: "VA"
    },
    {
      city: "NEW PORT RICHEY",
      state: "FL"
    },
    {
      city: "NEW PORTLAND",
      state: "ME"
    },
    {
      city: "NEW PRAGUE",
      state: "MN"
    },
    {
      city: "NEW PRESTON MARBLE DALE",
      state: "CT"
    },
    {
      city: "NEW PROVIDENCE",
      state: "IA"
    },
    {
      city: "NEW PROVIDENCE",
      state: "NJ"
    },
    {
      city: "NEW PROVIDENCE",
      state: "PA"
    },
    {
      city: "NEW RAYMER",
      state: "CO"
    },
    {
      city: "NEW RICHLAND",
      state: "MN"
    },
    {
      city: "NEW RICHMOND",
      state: "IN"
    },
    {
      city: "NEW RICHMOND",
      state: "OH"
    },
    {
      city: "NEW RICHMOND",
      state: "WI"
    },
    {
      city: "NEW RICHMOND",
      state: "WV"
    },
    {
      city: "NEW RIEGEL",
      state: "OH"
    },
    {
      city: "NEW RINGGOLD",
      state: "PA"
    },
    {
      city: "NEW RIVER",
      state: "AZ"
    },
    {
      city: "NEW RIVER",
      state: "VA"
    },
    {
      city: "NEW ROADS",
      state: "LA"
    },
    {
      city: "NEW ROCHELLE",
      state: "NY"
    },
    {
      city: "NEW ROCKFORD",
      state: "ND"
    },
    {
      city: "NEW ROSS",
      state: "IN"
    },
    {
      city: "NEW RUSSIA",
      state: "NY"
    },
    {
      city: "NEW SALEM",
      state: "IL"
    },
    {
      city: "NEW SALEM",
      state: "MA"
    },
    {
      city: "NEW SALEM",
      state: "ND"
    },
    {
      city: "NEW SALEM",
      state: "PA"
    },
    {
      city: "NEW SALISBURY",
      state: "IN"
    },
    {
      city: "NEW SARPY",
      state: "LA"
    },
    {
      city: "NEW SHARON",
      state: "IA"
    },
    {
      city: "NEW SHARON",
      state: "ME"
    },
    {
      city: "NEW SITE",
      state: "MS"
    },
    {
      city: "NEW SMYRNA BEACH",
      state: "FL"
    },
    {
      city: "NEW SPRINGFIELD",
      state: "OH"
    },
    {
      city: "NEW STANTON",
      state: "PA"
    },
    {
      city: "NEW STRAITSVILLE",
      state: "OH"
    },
    {
      city: "NEW STUYAHOK",
      state: "AK"
    },
    {
      city: "NEW SUFFOLK",
      state: "NY"
    },
    {
      city: "NEW SUMMERFIELD",
      state: "TX"
    },
    {
      city: "NEW SWEDEN",
      state: "ME"
    },
    {
      city: "NEW TAZEWELL",
      state: "TN"
    },
    {
      city: "NEW TOWN",
      state: "MA"
    },
    {
      city: "NEW TOWN",
      state: "ND"
    },
    {
      city: "NEW TRENTON",
      state: "IN"
    },
    {
      city: "NEW TRIPOLI",
      state: "PA"
    },
    {
      city: "NEW TROY",
      state: "MI"
    },
    {
      city: "NEW ULM",
      state: "MN"
    },
    {
      city: "NEW ULM",
      state: "TX"
    },
    {
      city: "NEW UNDERWOOD",
      state: "SD"
    },
    {
      city: "NEW VERNON",
      state: "NJ"
    },
    {
      city: "NEW VIENNA",
      state: "IA"
    },
    {
      city: "NEW VIENNA",
      state: "OH"
    },
    {
      city: "NEW VINEYARD",
      state: "ME"
    },
    {
      city: "NEW VIRGINIA",
      state: "IA"
    },
    {
      city: "NEW WASHINGTON",
      state: "IN"
    },
    {
      city: "NEW WASHINGTON",
      state: "OH"
    },
    {
      city: "NEW WATERFORD",
      state: "OH"
    },
    {
      city: "NEW WAVERLY",
      state: "IN"
    },
    {
      city: "NEW WAVERLY",
      state: "TX"
    },
    {
      city: "NEW WESTON",
      state: "OH"
    },
    {
      city: "NEW WILMINGTON",
      state: "PA"
    },
    {
      city: "NEW WINDSOR",
      state: "IL"
    },
    {
      city: "NEW WINDSOR",
      state: "MD"
    },
    {
      city: "NEW WINDSOR",
      state: "NY"
    },
    {
      city: "NEW WOODSTOCK",
      state: "NY"
    },
    {
      city: "NEW YORK",
      state: "NY"
    },
    {
      city: "NEW YORK MILLS",
      state: "MN"
    },
    {
      city: "NEW YORK MILLS",
      state: "NY"
    },
    {
      city: "NEW ZION",
      state: "SC"
    },
    {
      city: "NEWALLA",
      state: "OK"
    },
    {
      city: "NEWARK",
      state: "AR"
    },
    {
      city: "NEWARK",
      state: "CA"
    },
    {
      city: "NEWARK",
      state: "DE"
    },
    {
      city: "NEWARK",
      state: "IL"
    },
    {
      city: "NEWARK",
      state: "MD"
    },
    {
      city: "NEWARK",
      state: "MO"
    },
    {
      city: "NEWARK",
      state: "NJ"
    },
    {
      city: "NEWARK",
      state: "NY"
    },
    {
      city: "NEWARK",
      state: "OH"
    },
    {
      city: "NEWARK",
      state: "TX"
    },
    {
      city: "NEWARK VALLEY",
      state: "NY"
    },
    {
      city: "NEWAYGO",
      state: "MI"
    },
    {
      city: "NEWBERG",
      state: "OR"
    },
    {
      city: "NEWBERN",
      state: "AL"
    },
    {
      city: "NEWBERN",
      state: "TN"
    },
    {
      city: "NEWBERN",
      state: "VA"
    },
    {
      city: "NEWBERRY",
      state: "FL"
    },
    {
      city: "NEWBERRY",
      state: "IN"
    },
    {
      city: "NEWBERRY",
      state: "MI"
    },
    {
      city: "NEWBERRY",
      state: "SC"
    },
    {
      city: "NEWBERRY SPRINGS",
      state: "CA"
    },
    {
      city: "NEWBORN",
      state: "GA"
    },
    {
      city: "NEWBURG",
      state: "MD"
    },
    {
      city: "NEWBURG",
      state: "MO"
    },
    {
      city: "NEWBURG",
      state: "ND"
    },
    {
      city: "NEWBURG",
      state: "PA"
    },
    {
      city: "NEWBURG",
      state: "WI"
    },
    {
      city: "NEWBURG",
      state: "WV"
    },
    {
      city: "NEWBURGH",
      state: "IN"
    },
    {
      city: "NEWBURGH",
      state: "NY"
    },
    {
      city: "NEWBURY",
      state: "MA"
    },
    {
      city: "NEWBURY",
      state: "NH"
    },
    {
      city: "NEWBURY",
      state: "OH"
    },
    {
      city: "NEWBURY",
      state: "VT"
    },
    {
      city: "NEWBURY PARK",
      state: "CA"
    },
    {
      city: "NEWBURYPORT",
      state: "MA"
    },
    {
      city: "NEWCASTLE",
      state: "CA"
    },
    {
      city: "NEWCASTLE",
      state: "ME"
    },
    {
      city: "NEWCASTLE",
      state: "NE"
    },
    {
      city: "NEWCASTLE",
      state: "OK"
    },
    {
      city: "NEWCASTLE",
      state: "TX"
    },
    {
      city: "NEWCASTLE",
      state: "UT"
    },
    {
      city: "NEWCASTLE",
      state: "WY"
    },
    {
      city: "NEWCOMB",
      state: "MD"
    },
    {
      city: "NEWCOMB",
      state: "NM"
    },
    {
      city: "NEWCOMB",
      state: "NY"
    },
    {
      city: "NEWCOMB",
      state: "TN"
    },
    {
      city: "NEWCOMERSTOWN",
      state: "OH"
    },
    {
      city: "NEWDALE",
      state: "ID"
    },
    {
      city: "NEWELL",
      state: "IA"
    },
    {
      city: "NEWELL",
      state: "NC"
    },
    {
      city: "NEWELL",
      state: "PA"
    },
    {
      city: "NEWELL",
      state: "SD"
    },
    {
      city: "NEWELL",
      state: "WV"
    },
    {
      city: "NEWELLTON",
      state: "LA"
    },
    {
      city: "NEWFANE",
      state: "NY"
    },
    {
      city: "NEWFANE",
      state: "VT"
    },
    {
      city: "NEWFIELD",
      state: "ME"
    },
    {
      city: "NEWFIELD",
      state: "NJ"
    },
    {
      city: "NEWFIELD",
      state: "NY"
    },
    {
      city: "NEWFIELDS",
      state: "NH"
    },
    {
      city: "NEWFOLDEN",
      state: "MN"
    },
    {
      city: "NEWFOUNDLAND",
      state: "NJ"
    },
    {
      city: "NEWFOUNDLAND",
      state: "PA"
    },
    {
      city: "NEWHALL",
      state: "CA"
    },
    {
      city: "NEWHALL",
      state: "IA"
    },
    {
      city: "NEWHALL",
      state: "WV"
    },
    {
      city: "NEWHEBRON",
      state: "MS"
    },
    {
      city: "NEWHOPE",
      state: "AR"
    },
    {
      city: "NEWINGTON",
      state: "CT"
    },
    {
      city: "NEWINGTON",
      state: "GA"
    },
    {
      city: "NEWINGTON",
      state: "VA"
    },
    {
      city: "NEWKIRK",
      state: "NM"
    },
    {
      city: "NEWKIRK",
      state: "OK"
    },
    {
      city: "NEWLAND",
      state: "NC"
    },
    {
      city: "NEWMAN",
      state: "CA"
    },
    {
      city: "NEWMAN",
      state: "IL"
    },
    {
      city: "NEWMAN GROVE",
      state: "NE"
    },
    {
      city: "NEWMAN LAKE",
      state: "WA"
    },
    {
      city: "NEWMANSTOWN",
      state: "PA"
    },
    {
      city: "NEWMARKET",
      state: "NH"
    },
    {
      city: "NEWNAN",
      state: "GA"
    },
    {
      city: "NEWPORT",
      state: "AR"
    },
    {
      city: "NEWPORT",
      state: "IN"
    },
    {
      city: "NEWPORT",
      state: "KY"
    },
    {
      city: "NEWPORT",
      state: "ME"
    },
    {
      city: "NEWPORT",
      state: "MI"
    },
    {
      city: "NEWPORT",
      state: "MN"
    },
    {
      city: "NEWPORT",
      state: "NC"
    },
    {
      city: "NEWPORT",
      state: "NH"
    },
    {
      city: "NEWPORT",
      state: "NJ"
    },
    {
      city: "NEWPORT",
      state: "NY"
    },
    {
      city: "NEWPORT",
      state: "OH"
    },
    {
      city: "NEWPORT",
      state: "OR"
    },
    {
      city: "NEWPORT",
      state: "PA"
    },
    {
      city: "NEWPORT",
      state: "RI"
    },
    {
      city: "NEWPORT",
      state: "TN"
    },
    {
      city: "NEWPORT",
      state: "VA"
    },
    {
      city: "NEWPORT",
      state: "VT"
    },
    {
      city: "NEWPORT",
      state: "WA"
    },
    {
      city: "NEWPORT BEACH",
      state: "CA"
    },
    {
      city: "NEWPORT CENTER",
      state: "VT"
    },
    {
      city: "NEWPORT COAST",
      state: "CA"
    },
    {
      city: "NEWPORT NEWS",
      state: "VA"
    },
    {
      city: "NEWRY",
      state: "ME"
    },
    {
      city: "NEWRY",
      state: "PA"
    },
    {
      city: "NEWRY",
      state: "SC"
    },
    {
      city: "NEWSOMS",
      state: "VA"
    },
    {
      city: "NEWTON",
      state: "AL"
    },
    {
      city: "NEWTON",
      state: "GA"
    },
    {
      city: "NEWTON",
      state: "IA"
    },
    {
      city: "NEWTON",
      state: "IL"
    },
    {
      city: "NEWTON",
      state: "KS"
    },
    {
      city: "NEWTON",
      state: "MA"
    },
    {
      city: "NEWTON",
      state: "MS"
    },
    {
      city: "NEWTON",
      state: "NC"
    },
    {
      city: "NEWTON",
      state: "NH"
    },
    {
      city: "NEWTON",
      state: "NJ"
    },
    {
      city: "NEWTON",
      state: "TX"
    },
    {
      city: "NEWTON",
      state: "UT"
    },
    {
      city: "NEWTON",
      state: "WI"
    },
    {
      city: "NEWTON",
      state: "WV"
    },
    {
      city: "NEWTON CENTER",
      state: "MA"
    },
    {
      city: "NEWTON FALLS",
      state: "NY"
    },
    {
      city: "NEWTON FALLS",
      state: "OH"
    },
    {
      city: "NEWTON GROVE",
      state: "NC"
    },
    {
      city: "NEWTON HAMILTON",
      state: "PA"
    },
    {
      city: "NEWTON HIGHLANDS",
      state: "MA"
    },
    {
      city: "NEWTON JUNCTION",
      state: "NH"
    },
    {
      city: "NEWTON LOWER FALLS",
      state: "MA"
    },
    {
      city: "NEWTON UPPER FALLS",
      state: "MA"
    },
    {
      city: "NEWTONSVILLE",
      state: "OH"
    },
    {
      city: "NEWTONVILLE",
      state: "MA"
    },
    {
      city: "NEWTONVILLE",
      state: "NJ"
    },
    {
      city: "NEWTONVILLE",
      state: "NY"
    },
    {
      city: "NEWTOWN",
      state: "CT"
    },
    {
      city: "NEWTOWN",
      state: "IN"
    },
    {
      city: "NEWTOWN",
      state: "MO"
    },
    {
      city: "NEWTOWN",
      state: "PA"
    },
    {
      city: "NEWTOWN",
      state: "VA"
    },
    {
      city: "NEWTOWN SQUARE",
      state: "PA"
    },
    {
      city: "NEWVILLE",
      state: "AL"
    },
    {
      city: "NEWVILLE",
      state: "PA"
    },
    {
      city: "NEY",
      state: "OH"
    },
    {
      city: "NEZPERCE",
      state: "ID"
    },
    {
      city: "NIAGARA",
      state: "ND"
    },
    {
      city: "NIAGARA",
      state: "WI"
    },
    {
      city: "NIAGARA FALLS",
      state: "NY"
    },
    {
      city: "NIAGARA UNIVERSITY",
      state: "NY"
    },
    {
      city: "NIANGUA",
      state: "MO"
    },
    {
      city: "NIANTIC",
      state: "CT"
    },
    {
      city: "NIANTIC",
      state: "IL"
    },
    {
      city: "NICASIO",
      state: "CA"
    },
    {
      city: "NICE",
      state: "CA"
    },
    {
      city: "NICEVILLE",
      state: "FL"
    },
    {
      city: "NICHOLASVILLE",
      state: "KY"
    },
    {
      city: "NICHOLLS",
      state: "GA"
    },
    {
      city: "NICHOLS",
      state: "FL"
    },
    {
      city: "NICHOLS",
      state: "IA"
    },
    {
      city: "NICHOLS",
      state: "NY"
    },
    {
      city: "NICHOLS",
      state: "SC"
    },
    {
      city: "NICHOLS",
      state: "WI"
    },
    {
      city: "NICHOLSON",
      state: "GA"
    },
    {
      city: "NICHOLSON",
      state: "MS"
    },
    {
      city: "NICHOLSON",
      state: "PA"
    },
    {
      city: "NICHOLVILLE",
      state: "NY"
    },
    {
      city: "NICKELSVILLE",
      state: "VA"
    },
    {
      city: "NICKERSON",
      state: "KS"
    },
    {
      city: "NICKERSON",
      state: "NE"
    },
    {
      city: "NICKTOWN",
      state: "PA"
    },
    {
      city: "NICOLAUS",
      state: "CA"
    },
    {
      city: "NICOLLET",
      state: "MN"
    },
    {
      city: "NICOMA PARK",
      state: "OK"
    },
    {
      city: "NIELSVILLE",
      state: "MN"
    },
    {
      city: "NIGHTMUTE",
      state: "AK"
    },
    {
      city: "NIKISKI",
      state: "AK"
    },
    {
      city: "NIKOLSKI",
      state: "AK"
    },
    {
      city: "NILAND",
      state: "CA"
    },
    {
      city: "NILES",
      state: "IL"
    },
    {
      city: "NILES",
      state: "MI"
    },
    {
      city: "NILES",
      state: "OH"
    },
    {
      city: "NILWOOD",
      state: "IL"
    },
    {
      city: "NIMITZ",
      state: "WV"
    },
    {
      city: "NIMROD",
      state: "MN"
    },
    {
      city: "NINDE",
      state: "VA"
    },
    {
      city: "NINE MILE FALLS",
      state: "WA"
    },
    {
      city: "NINETY SIX",
      state: "SC"
    },
    {
      city: "NINEVEH",
      state: "IN"
    },
    {
      city: "NINEVEH",
      state: "NY"
    },
    {
      city: "NINILCHIK",
      state: "AK"
    },
    {
      city: "NINNEKAH",
      state: "OK"
    },
    {
      city: "NINOLE",
      state: "HI"
    },
    {
      city: "NIOBRARA",
      state: "NE"
    },
    {
      city: "NIOTA",
      state: "IL"
    },
    {
      city: "NIOTA",
      state: "TN"
    },
    {
      city: "NIOTAZE",
      state: "KS"
    },
    {
      city: "NIPOMO",
      state: "CA"
    },
    {
      city: "NIPTON",
      state: "CA"
    },
    {
      city: "NISLAND",
      state: "SD"
    },
    {
      city: "NISSWA",
      state: "MN"
    },
    {
      city: "NITRO",
      state: "WV"
    },
    {
      city: "NIVERVILLE",
      state: "NY"
    },
    {
      city: "NIWOT",
      state: "CO"
    },
    {
      city: "NIXA",
      state: "MO"
    },
    {
      city: "NIXON",
      state: "NV"
    },
    {
      city: "NIXON",
      state: "TX"
    },
    {
      city: "NOATAK",
      state: "AK"
    },
    {
      city: "NOBLE",
      state: "IL"
    },
    {
      city: "NOBLE",
      state: "LA"
    },
    {
      city: "NOBLE",
      state: "MO"
    },
    {
      city: "NOBLE",
      state: "OK"
    },
    {
      city: "NOBLEBORO",
      state: "ME"
    },
    {
      city: "NOBLESVILLE",
      state: "IN"
    },
    {
      city: "NOBLETON",
      state: "FL"
    },
    {
      city: "NOCATEE",
      state: "FL"
    },
    {
      city: "NOCONA",
      state: "TX"
    },
    {
      city: "NODAWAY",
      state: "IA"
    },
    {
      city: "NOEL",
      state: "MO"
    },
    {
      city: "NOGAL",
      state: "NM"
    },
    {
      city: "NOGALES",
      state: "AZ"
    },
    {
      city: "NOKESVILLE",
      state: "VA"
    },
    {
      city: "NOKOMIS",
      state: "FL"
    },
    {
      city: "NOKOMIS",
      state: "IL"
    },
    {
      city: "NOLANVILLE",
      state: "TX"
    },
    {
      city: "NOLENSVILLE",
      state: "TN"
    },
    {
      city: "NOMA",
      state: "FL"
    },
    {
      city: "NOME",
      state: "AK"
    },
    {
      city: "NOME",
      state: "ND"
    },
    {
      city: "NOME",
      state: "TX"
    },
    {
      city: "NONDALTON",
      state: "AK"
    },
    {
      city: "NOOKSACK",
      state: "WA"
    },
    {
      city: "NOONAN",
      state: "ND"
    },
    {
      city: "NOORVIK",
      state: "AK"
    },
    {
      city: "NORA",
      state: "IL"
    },
    {
      city: "NORA",
      state: "VA"
    },
    {
      city: "NORA SPRINGS",
      state: "IA"
    },
    {
      city: "NORBORNE",
      state: "MO"
    },
    {
      city: "NORCATUR",
      state: "KS"
    },
    {
      city: "NORCO",
      state: "CA"
    },
    {
      city: "NORCO",
      state: "LA"
    },
    {
      city: "NORCROSS",
      state: "GA"
    },
    {
      city: "NORCROSS",
      state: "MN"
    },
    {
      city: "NORDEN",
      state: "CA"
    },
    {
      city: "NORDHEIM",
      state: "TX"
    },
    {
      city: "NORDLAND",
      state: "WA"
    },
    {
      city: "NORDMAN",
      state: "ID"
    },
    {
      city: "NORENE",
      state: "TN"
    },
    {
      city: "NORFOLK",
      state: "CT"
    },
    {
      city: "NORFOLK",
      state: "MA"
    },
    {
      city: "NORFOLK",
      state: "NE"
    },
    {
      city: "NORFOLK",
      state: "NY"
    },
    {
      city: "NORFOLK",
      state: "VA"
    },
    {
      city: "NORFORK",
      state: "AR"
    },
    {
      city: "NORGE",
      state: "VA"
    },
    {
      city: "NORLINA",
      state: "NC"
    },
    {
      city: "NORMA",
      state: "NJ"
    },
    {
      city: "NORMAL",
      state: "AL"
    },
    {
      city: "NORMAL",
      state: "IL"
    },
    {
      city: "NORMALVILLE",
      state: "PA"
    },
    {
      city: "NORMAN",
      state: "AR"
    },
    {
      city: "NORMAN",
      state: "IN"
    },
    {
      city: "NORMAN",
      state: "NC"
    },
    {
      city: "NORMAN",
      state: "OK"
    },
    {
      city: "NORMAN PARK",
      state: "GA"
    },
    {
      city: "NORMANDY",
      state: "TN"
    },
    {
      city: "NORMANDY BEACH",
      state: "NJ"
    },
    {
      city: "NORMANGEE",
      state: "TX"
    },
    {
      city: "NORMANNA",
      state: "TX"
    },
    {
      city: "NORMANTOWN",
      state: "WV"
    },
    {
      city: "NORPHLET",
      state: "AR"
    },
    {
      city: "NORRIDGEWOCK",
      state: "ME"
    },
    {
      city: "NORRIS",
      state: "IL"
    },
    {
      city: "NORRIS",
      state: "MT"
    },
    {
      city: "NORRIS",
      state: "SC"
    },
    {
      city: "NORRIS",
      state: "SD"
    },
    {
      city: "NORRIS",
      state: "TN"
    },
    {
      city: "NORRIS CITY",
      state: "IL"
    },
    {
      city: "NORRISTOWN",
      state: "PA"
    },
    {
      city: "NORTH",
      state: "SC"
    },
    {
      city: "NORTH",
      state: "VA"
    },
    {
      city: "NORTH ADAMS",
      state: "MA"
    },
    {
      city: "NORTH ADAMS",
      state: "MI"
    },
    {
      city: "NORTH AMHERST",
      state: "MA"
    },
    {
      city: "NORTH ANDOVER",
      state: "MA"
    },
    {
      city: "NORTH ANSON",
      state: "ME"
    },
    {
      city: "NORTH APOLLO",
      state: "PA"
    },
    {
      city: "NORTH ARLINGTON",
      state: "NJ"
    },
    {
      city: "NORTH ATTLEBORO",
      state: "MA"
    },
    {
      city: "NORTH AUGUSTA",
      state: "SC"
    },
    {
      city: "NORTH AURORA",
      state: "IL"
    },
    {
      city: "NORTH BABYLON",
      state: "NY"
    },
    {
      city: "NORTH BALTIMORE",
      state: "OH"
    },
    {
      city: "NORTH BANGOR",
      state: "NY"
    },
    {
      city: "NORTH BAY",
      state: "NY"
    },
    {
      city: "NORTH BEACH",
      state: "MD"
    },
    {
      city: "NORTH BEND",
      state: "NE"
    },
    {
      city: "NORTH BEND",
      state: "OH"
    },
    {
      city: "NORTH BEND",
      state: "OR"
    },
    {
      city: "NORTH BEND",
      state: "PA"
    },
    {
      city: "NORTH BEND",
      state: "WA"
    },
    {
      city: "NORTH BENNINGTON",
      state: "VT"
    },
    {
      city: "NORTH BENTON",
      state: "OH"
    },
    {
      city: "NORTH BERGEN",
      state: "NJ"
    },
    {
      city: "NORTH BERWICK",
      state: "ME"
    },
    {
      city: "NORTH BILLERICA",
      state: "MA"
    },
    {
      city: "NORTH BLENHEIM",
      state: "NY"
    },
    {
      city: "NORTH BLOOMFIELD",
      state: "OH"
    },
    {
      city: "NORTH BONNEVILLE",
      state: "WA"
    },
    {
      city: "NORTH BOSTON",
      state: "NY"
    },
    {
      city: "NORTH BRANCH",
      state: "MI"
    },
    {
      city: "NORTH BRANCH",
      state: "MN"
    },
    {
      city: "NORTH BRANCH",
      state: "NY"
    },
    {
      city: "NORTH BRANFORD",
      state: "CT"
    },
    {
      city: "NORTH BRIDGTON",
      state: "ME"
    },
    {
      city: "NORTH BROOKFIELD",
      state: "MA"
    },
    {
      city: "NORTH BROOKFIELD",
      state: "NY"
    },
    {
      city: "NORTH BRUNSWICK",
      state: "NJ"
    },
    {
      city: "NORTH BUENA VISTA",
      state: "IA"
    },
    {
      city: "NORTH CANTON",
      state: "CT"
    },
    {
      city: "NORTH CANTON",
      state: "OH"
    },
    {
      city: "NORTH CARROLLTON",
      state: "MS"
    },
    {
      city: "NORTH CARVER",
      state: "MA"
    },
    {
      city: "NORTH CHARLESTON",
      state: "SC"
    },
    {
      city: "NORTH CHATHAM",
      state: "MA"
    },
    {
      city: "NORTH CHATHAM",
      state: "NY"
    },
    {
      city: "NORTH CHELMSFORD",
      state: "MA"
    },
    {
      city: "NORTH CHICAGO",
      state: "IL"
    },
    {
      city: "NORTH CHILI",
      state: "NY"
    },
    {
      city: "NORTH CLARENDON",
      state: "VT"
    },
    {
      city: "NORTH COLLINS",
      state: "NY"
    },
    {
      city: "NORTH CONCORD",
      state: "VT"
    },
    {
      city: "NORTH CONWAY",
      state: "NH"
    },
    {
      city: "NORTH CREEK",
      state: "NY"
    },
    {
      city: "NORTH DARTMOUTH",
      state: "MA"
    },
    {
      city: "NORTH DIGHTON",
      state: "MA"
    },
    {
      city: "NORTH EAST",
      state: "MD"
    },
    {
      city: "NORTH EAST",
      state: "PA"
    },
    {
      city: "NORTH EASTHAM",
      state: "MA"
    },
    {
      city: "NORTH EASTON",
      state: "MA"
    },
    {
      city: "NORTH EGREMONT",
      state: "MA"
    },
    {
      city: "NORTH ENGLISH",
      state: "IA"
    },
    {
      city: "NORTH EVANS",
      state: "NY"
    },
    {
      city: "NORTH FAIRFIELD",
      state: "OH"
    },
    {
      city: "NORTH FALMOUTH",
      state: "MA"
    },
    {
      city: "NORTH FERRISBURGH",
      state: "VT"
    },
    {
      city: "NORTH FORK",
      state: "CA"
    },
    {
      city: "NORTH FORK",
      state: "ID"
    },
    {
      city: "NORTH FORT MYERS",
      state: "FL"
    },
    {
      city: "NORTH FRANKLIN",
      state: "CT"
    },
    {
      city: "NORTH FREEDOM",
      state: "WI"
    },
    {
      city: "NORTH GARDEN",
      state: "VA"
    },
    {
      city: "NORTH GEORGETOWN",
      state: "OH"
    },
    {
      city: "NORTH GRAFTON",
      state: "MA"
    },
    {
      city: "NORTH GRANBY",
      state: "CT"
    },
    {
      city: "NORTH GRANVILLE",
      state: "NY"
    },
    {
      city: "NORTH GREECE",
      state: "NY"
    },
    {
      city: "NORTH GROSVENORDALE",
      state: "CT"
    },
    {
      city: "NORTH HAMPTON",
      state: "NH"
    },
    {
      city: "NORTH HAMPTON",
      state: "OH"
    },
    {
      city: "NORTH HARTLAND",
      state: "VT"
    },
    {
      city: "NORTH HATFIELD",
      state: "MA"
    },
    {
      city: "NORTH HAVEN",
      state: "CT"
    },
    {
      city: "NORTH HAVEN",
      state: "ME"
    },
    {
      city: "NORTH HAVERHILL",
      state: "NH"
    },
    {
      city: "NORTH HENDERSON",
      state: "IL"
    },
    {
      city: "NORTH HERO",
      state: "VT"
    },
    {
      city: "NORTH HIGHLANDS",
      state: "CA"
    },
    {
      city: "NORTH HILLS",
      state: "CA"
    },
    {
      city: "NORTH HOLLYWOOD",
      state: "CA"
    },
    {
      city: "NORTH HOOSICK",
      state: "NY"
    },
    {
      city: "NORTH HUDSON",
      state: "NY"
    },
    {
      city: "NORTH HYDE PARK",
      state: "VT"
    },
    {
      city: "NORTH JACKSON",
      state: "OH"
    },
    {
      city: "NORTH JAVA",
      state: "NY"
    },
    {
      city: "NORTH JAY",
      state: "ME"
    },
    {
      city: "NORTH JUDSON",
      state: "IN"
    },
    {
      city: "NORTH KINGSTOWN",
      state: "RI"
    },
    {
      city: "NORTH KINGSVILLE",
      state: "OH"
    },
    {
      city: "NORTH LAKE",
      state: "WI"
    },
    {
      city: "NORTH LAKEWOOD",
      state: "WA"
    },
    {
      city: "NORTH LAS VEGAS",
      state: "NV"
    },
    {
      city: "NORTH LAWRENCE",
      state: "NY"
    },
    {
      city: "NORTH LAWRENCE",
      state: "OH"
    },
    {
      city: "NORTH LEWISBURG",
      state: "OH"
    },
    {
      city: "NORTH LIBERTY",
      state: "IA"
    },
    {
      city: "NORTH LIBERTY",
      state: "IN"
    },
    {
      city: "NORTH LIMA",
      state: "OH"
    },
    {
      city: "NORTH LITTLE ROCK",
      state: "AR"
    },
    {
      city: "NORTH LOUP",
      state: "NE"
    },
    {
      city: "NORTH MANCHESTER",
      state: "IN"
    },
    {
      city: "NORTH MARSHFIELD",
      state: "MA"
    },
    {
      city: "NORTH MATEWAN",
      state: "WV"
    },
    {
      city: "NORTH METRO",
      state: "GA"
    },
    {
      city: "NORTH MIAMI",
      state: "OK"
    },
    {
      city: "NORTH MIAMI BEACH",
      state: "FL"
    },
    {
      city: "NORTH MIDDLETOWN",
      state: "KY"
    },
    {
      city: "NORTH MONMOUTH",
      state: "ME"
    },
    {
      city: "NORTH MONTPELIER",
      state: "VT"
    },
    {
      city: "NORTH MYRTLE BEACH",
      state: "SC"
    },
    {
      city: "NORTH NEWTON",
      state: "KS"
    },
    {
      city: "NORTH NORWICH",
      state: "NY"
    },
    {
      city: "NORTH OLMSTED",
      state: "OH"
    },
    {
      city: "NORTH OXFORD",
      state: "MA"
    },
    {
      city: "NORTH PALM BEACH",
      state: "FL"
    },
    {
      city: "NORTH PALM SPRINGS",
      state: "CA"
    },
    {
      city: "NORTH PEMBROKE",
      state: "MA"
    },
    {
      city: "NORTH PITCHER",
      state: "NY"
    },
    {
      city: "NORTH PLAINS",
      state: "OR"
    },
    {
      city: "NORTH PLATTE",
      state: "NE"
    },
    {
      city: "NORTH POLE",
      state: "AK"
    },
    {
      city: "NORTH POMFRET",
      state: "VT"
    },
    {
      city: "NORTH PORT",
      state: "FL"
    },
    {
      city: "NORTH POWDER",
      state: "OR"
    },
    {
      city: "NORTH POWNAL",
      state: "VT"
    },
    {
      city: "NORTH PRAIRIE",
      state: "WI"
    },
    {
      city: "NORTH PROVIDENCE",
      state: "RI"
    },
    {
      city: "NORTH READING",
      state: "MA"
    },
    {
      city: "NORTH RICHLAND HILLS",
      state: "TX"
    },
    {
      city: "NORTH RIDGEVILLE",
      state: "OH"
    },
    {
      city: "NORTH RIM",
      state: "AZ"
    },
    {
      city: "NORTH RIVER",
      state: "NY"
    },
    {
      city: "NORTH ROBINSON",
      state: "OH"
    },
    {
      city: "NORTH ROSE",
      state: "NY"
    },
    {
      city: "NORTH ROYALTON",
      state: "OH"
    },
    {
      city: "NORTH SALEM",
      state: "IN"
    },
    {
      city: "NORTH SALEM",
      state: "NH"
    },
    {
      city: "NORTH SALEM",
      state: "NY"
    },
    {
      city: "NORTH SALT LAKE",
      state: "UT"
    },
    {
      city: "NORTH SAN JUAN",
      state: "CA"
    },
    {
      city: "NORTH SANDWICH",
      state: "NH"
    },
    {
      city: "NORTH SCITUATE",
      state: "MA"
    },
    {
      city: "NORTH SCITUATE",
      state: "RI"
    },
    {
      city: "NORTH SIOUX CITY",
      state: "SD"
    },
    {
      city: "NORTH SMITHFIELD",
      state: "RI"
    },
    {
      city: "NORTH SPRING",
      state: "WV"
    },
    {
      city: "NORTH SPRINGFIELD",
      state: "PA"
    },
    {
      city: "NORTH SPRINGFIELD",
      state: "VT"
    },
    {
      city: "NORTH STAR",
      state: "OH"
    },
    {
      city: "NORTH STONINGTON",
      state: "CT"
    },
    {
      city: "NORTH STRATFORD",
      state: "NH"
    },
    {
      city: "NORTH STREET",
      state: "MI"
    },
    {
      city: "NORTH SUTTON",
      state: "NH"
    },
    {
      city: "NORTH TAZEWELL",
      state: "VA"
    },
    {
      city: "NORTH THETFORD",
      state: "VT"
    },
    {
      city: "NORTH TONAWANDA",
      state: "NY"
    },
    {
      city: "NORTH TROY",
      state: "VT"
    },
    {
      city: "NORTH TRURO",
      state: "MA"
    },
    {
      city: "NORTH TURNER",
      state: "ME"
    },
    {
      city: "NORTH UXBRIDGE",
      state: "MA"
    },
    {
      city: "NORTH VASSALBORO",
      state: "ME"
    },
    {
      city: "NORTH VERNON",
      state: "IN"
    },
    {
      city: "NORTH VERSAILLES",
      state: "PA"
    },
    {
      city: "NORTH WALES",
      state: "PA"
    },
    {
      city: "NORTH WALPOLE",
      state: "NH"
    },
    {
      city: "NORTH WALTHAM",
      state: "MA"
    },
    {
      city: "NORTH WASHINGTON",
      state: "IA"
    },
    {
      city: "NORTH WASHINGTON",
      state: "PA"
    },
    {
      city: "NORTH WATERBORO",
      state: "ME"
    },
    {
      city: "NORTH WATERFORD",
      state: "ME"
    },
    {
      city: "NORTH WEBSTER",
      state: "IN"
    },
    {
      city: "NORTH WEYMOUTH",
      state: "MA"
    },
    {
      city: "NORTH WILKESBORO",
      state: "NC"
    },
    {
      city: "NORTH WINDHAM",
      state: "CT"
    },
    {
      city: "NORTH WOODSTOCK",
      state: "NH"
    },
    {
      city: "NORTH YARMOUTH",
      state: "ME"
    },
    {
      city: "NORTH ZULCH",
      state: "TX"
    },
    {
      city: "NORTHAMPTON",
      state: "MA"
    },
    {
      city: "NORTHAMPTON",
      state: "PA"
    },
    {
      city: "NORTHBORO",
      state: "IA"
    },
    {
      city: "NORTHBOROUGH",
      state: "MA"
    },
    {
      city: "NORTHBRIDGE",
      state: "MA"
    },
    {
      city: "NORTHBROOK",
      state: "IL"
    },
    {
      city: "NORTHEAST HARBOR",
      state: "ME"
    },
    {
      city: "NORTHERN CAMBRIA",
      state: "PA"
    },
    {
      city: "NORTHFIELD",
      state: "CT"
    },
    {
      city: "NORTHFIELD",
      state: "MA"
    },
    {
      city: "NORTHFIELD",
      state: "MN"
    },
    {
      city: "NORTHFIELD",
      state: "NJ"
    },
    {
      city: "NORTHFIELD",
      state: "OH"
    },
    {
      city: "NORTHFIELD",
      state: "VT"
    },
    {
      city: "NORTHFIELD FALLS",
      state: "VT"
    },
    {
      city: "NORTHFORD",
      state: "CT"
    },
    {
      city: "NORTHFORK",
      state: "WV"
    },
    {
      city: "NORTHOME",
      state: "MN"
    },
    {
      city: "NORTHPOINT",
      state: "PA"
    },
    {
      city: "NORTHPORT",
      state: "AL"
    },
    {
      city: "NORTHPORT",
      state: "MI"
    },
    {
      city: "NORTHPORT",
      state: "NY"
    },
    {
      city: "NORTHPORT",
      state: "WA"
    },
    {
      city: "NORTHRIDGE",
      state: "CA"
    },
    {
      city: "NORTHROP",
      state: "MN"
    },
    {
      city: "NORTHUMBERLAND",
      state: "PA"
    },
    {
      city: "NORTHVALE",
      state: "NJ"
    },
    {
      city: "NORTHVILLE",
      state: "MI"
    },
    {
      city: "NORTHVILLE",
      state: "NY"
    },
    {
      city: "NORTHVILLE",
      state: "SD"
    },
    {
      city: "NORTHWAY",
      state: "AK"
    },
    {
      city: "NORTHWOOD",
      state: "IA"
    },
    {
      city: "NORTHWOOD",
      state: "ND"
    },
    {
      city: "NORTHWOOD",
      state: "NH"
    },
    {
      city: "NORTHWOOD",
      state: "OH"
    },
    {
      city: "NORTON",
      state: "KS"
    },
    {
      city: "NORTON",
      state: "MA"
    },
    {
      city: "NORTON",
      state: "TX"
    },
    {
      city: "NORTON",
      state: "VA"
    },
    {
      city: "NORTON",
      state: "VT"
    },
    {
      city: "NORTON",
      state: "WV"
    },
    {
      city: "NORTONVILLE",
      state: "KS"
    },
    {
      city: "NORTONVILLE",
      state: "KY"
    },
    {
      city: "NORVELT",
      state: "PA"
    },
    {
      city: "NORWALK",
      state: "CA"
    },
    {
      city: "NORWALK",
      state: "CT"
    },
    {
      city: "NORWALK",
      state: "IA"
    },
    {
      city: "NORWALK",
      state: "OH"
    },
    {
      city: "NORWALK",
      state: "WI"
    },
    {
      city: "NORWAY",
      state: "IA"
    },
    {
      city: "NORWAY",
      state: "KS"
    },
    {
      city: "NORWAY",
      state: "ME"
    },
    {
      city: "NORWAY",
      state: "MI"
    },
    {
      city: "NORWAY",
      state: "SC"
    },
    {
      city: "NORWELL",
      state: "MA"
    },
    {
      city: "NORWICH",
      state: "CT"
    },
    {
      city: "NORWICH",
      state: "KS"
    },
    {
      city: "NORWICH",
      state: "ND"
    },
    {
      city: "NORWICH",
      state: "NY"
    },
    {
      city: "NORWICH",
      state: "OH"
    },
    {
      city: "NORWICH",
      state: "VT"
    },
    {
      city: "NORWOOD",
      state: "CO"
    },
    {
      city: "NORWOOD",
      state: "GA"
    },
    {
      city: "NORWOOD",
      state: "LA"
    },
    {
      city: "NORWOOD",
      state: "MA"
    },
    {
      city: "NORWOOD",
      state: "MN"
    },
    {
      city: "NORWOOD",
      state: "MO"
    },
    {
      city: "NORWOOD",
      state: "NC"
    },
    {
      city: "NORWOOD",
      state: "NJ"
    },
    {
      city: "NORWOOD",
      state: "NY"
    },
    {
      city: "NORWOOD",
      state: "PA"
    },
    {
      city: "NORWOOD",
      state: "VA"
    },
    {
      city: "NORWOOD YOUNG AMERICA",
      state: "MN"
    },
    {
      city: "NOTASULGA",
      state: "AL"
    },
    {
      city: "NOTI",
      state: "OR"
    },
    {
      city: "NOTRE DAME",
      state: "IN"
    },
    {
      city: "NOTREES",
      state: "TX"
    },
    {
      city: "NOTTAWA",
      state: "MI"
    },
    {
      city: "NOTTINGHAM",
      state: "MD"
    },
    {
      city: "NOTTINGHAM",
      state: "NH"
    },
    {
      city: "NOTTINGHAM",
      state: "PA"
    },
    {
      city: "NOTTOWAY",
      state: "VA"
    },
    {
      city: "NOTUS",
      state: "ID"
    },
    {
      city: "NOVA",
      state: "OH"
    },
    {
      city: "NOVATO",
      state: "CA"
    },
    {
      city: "NOVELTY",
      state: "MO"
    },
    {
      city: "NOVELTY",
      state: "OH"
    },
    {
      city: "NOVI",
      state: "MI"
    },
    {
      city: "NOVICE",
      state: "TX"
    },
    {
      city: "NOVINGER",
      state: "MO"
    },
    {
      city: "NOWATA",
      state: "OK"
    },
    {
      city: "NOXAPATER",
      state: "MS"
    },
    {
      city: "NOXEN",
      state: "PA"
    },
    {
      city: "NOXON",
      state: "MT"
    },
    {
      city: "NU MINE",
      state: "PA"
    },
    {
      city: "NUBIEBER",
      state: "CA"
    },
    {
      city: "NUCLA",
      state: "CO"
    },
    {
      city: "NUEVO",
      state: "CA"
    },
    {
      city: "NUIQSUT",
      state: "AK"
    },
    {
      city: "NULATO",
      state: "AK"
    },
    {
      city: "NUMIDIA",
      state: "PA"
    },
    {
      city: "NUNAM IQUA",
      state: "AK"
    },
    {
      city: "NUNAPITCHUK",
      state: "AK"
    },
    {
      city: "NUNDA",
      state: "NY"
    },
    {
      city: "NUNDA",
      state: "SD"
    },
    {
      city: "NUNEZ",
      state: "GA"
    },
    {
      city: "NUNICA",
      state: "MI"
    },
    {
      city: "NUNN",
      state: "CO"
    },
    {
      city: "NUNNELLY",
      state: "TN"
    },
    {
      city: "NUREMBERG",
      state: "PA"
    },
    {
      city: "NURSERY",
      state: "TX"
    },
    {
      city: "NUTLEY",
      state: "NJ"
    },
    {
      city: "NUTRIOSO",
      state: "AZ"
    },
    {
      city: "NUTTING LAKE",
      state: "MA"
    },
    {
      city: "NYACK",
      state: "NY"
    },
    {
      city: "NYE",
      state: "MT"
    },
    {
      city: "NYSSA",
      state: "OR"
    },
    {
      city: "O BRIEN",
      state: "FL"
    },
    {
      city: "O BRIEN",
      state: "OR"
    },
    {
      city: "O BRIEN",
      state: "TX"
    },
    {
      city: "O FALLON",
      state: "IL"
    },
    {
      city: "O FALLON",
      state: "MO"
    },
    {
      city: "O NEALS",
      state: "CA"
    },
    {
      city: "OACOMA",
      state: "SD"
    },
    {
      city: "OAK",
      state: "NE"
    },
    {
      city: "OAK BLUFFS",
      state: "MA"
    },
    {
      city: "OAK BROOK",
      state: "IL"
    },
    {
      city: "OAK CITY",
      state: "NC"
    },
    {
      city: "OAK CITY",
      state: "UT"
    },
    {
      city: "OAK CREEK",
      state: "CO"
    },
    {
      city: "OAK CREEK",
      state: "WI"
    },
    {
      city: "OAK FOREST",
      state: "IL"
    },
    {
      city: "OAK GROVE",
      state: "AR"
    },
    {
      city: "OAK GROVE",
      state: "KY"
    },
    {
      city: "OAK GROVE",
      state: "LA"
    },
    {
      city: "OAK GROVE",
      state: "MO"
    },
    {
      city: "OAK HALL",
      state: "VA"
    },
    {
      city: "OAK HARBOR",
      state: "OH"
    },
    {
      city: "OAK HARBOR",
      state: "WA"
    },
    {
      city: "OAK HILL",
      state: "AL"
    },
    {
      city: "OAK HILL",
      state: "FL"
    },
    {
      city: "OAK HILL",
      state: "NY"
    },
    {
      city: "OAK HILL",
      state: "OH"
    },
    {
      city: "OAK HILL",
      state: "WV"
    },
    {
      city: "OAK ISLAND",
      state: "MN"
    },
    {
      city: "OAK ISLAND",
      state: "NC"
    },
    {
      city: "OAK LAWN",
      state: "IL"
    },
    {
      city: "OAK PARK",
      state: "CA"
    },
    {
      city: "OAK PARK",
      state: "IL"
    },
    {
      city: "OAK PARK",
      state: "MI"
    },
    {
      city: "OAK PARK",
      state: "MN"
    },
    {
      city: "OAK RIDGE",
      state: "LA"
    },
    {
      city: "OAK RIDGE",
      state: "MO"
    },
    {
      city: "OAK RIDGE",
      state: "NC"
    },
    {
      city: "OAK RIDGE",
      state: "NJ"
    },
    {
      city: "OAK RIDGE",
      state: "PA"
    },
    {
      city: "OAK RIDGE",
      state: "TN"
    },
    {
      city: "OAK RUN",
      state: "CA"
    },
    {
      city: "OAK VALE",
      state: "MS"
    },
    {
      city: "OAK VIEW",
      state: "CA"
    },
    {
      city: "OAKBORO",
      state: "NC"
    },
    {
      city: "OAKDALE",
      state: "CA"
    },
    {
      city: "OAKDALE",
      state: "CT"
    },
    {
      city: "OAKDALE",
      state: "IL"
    },
    {
      city: "OAKDALE",
      state: "LA"
    },
    {
      city: "OAKDALE",
      state: "NE"
    },
    {
      city: "OAKDALE",
      state: "NY"
    },
    {
      city: "OAKDALE",
      state: "PA"
    },
    {
      city: "OAKDALE",
      state: "TN"
    },
    {
      city: "OAKDALE",
      state: "WI"
    },
    {
      city: "OAKES",
      state: "ND"
    },
    {
      city: "OAKESDALE",
      state: "WA"
    },
    {
      city: "OAKFIELD",
      state: "GA"
    },
    {
      city: "OAKFIELD",
      state: "ME"
    },
    {
      city: "OAKFIELD",
      state: "NY"
    },
    {
      city: "OAKFIELD",
      state: "TN"
    },
    {
      city: "OAKFIELD",
      state: "WI"
    },
    {
      city: "OAKFORD",
      state: "IL"
    },
    {
      city: "OAKFORD",
      state: "IN"
    },
    {
      city: "OAKHAM",
      state: "MA"
    },
    {
      city: "OAKHURST",
      state: "CA"
    },
    {
      city: "OAKHURST",
      state: "NJ"
    },
    {
      city: "OAKHURST",
      state: "OK"
    },
    {
      city: "OAKHURST",
      state: "TX"
    },
    {
      city: "OAKLAND",
      state: "AR"
    },
    {
      city: "OAKLAND",
      state: "CA"
    },
    {
      city: "OAKLAND",
      state: "FL"
    },
    {
      city: "OAKLAND",
      state: "IA"
    },
    {
      city: "OAKLAND",
      state: "IL"
    },
    {
      city: "OAKLAND",
      state: "KY"
    },
    {
      city: "OAKLAND",
      state: "MD"
    },
    {
      city: "OAKLAND",
      state: "ME"
    },
    {
      city: "OAKLAND",
      state: "MI"
    },
    {
      city: "OAKLAND",
      state: "MS"
    },
    {
      city: "OAKLAND",
      state: "NE"
    },
    {
      city: "OAKLAND",
      state: "NJ"
    },
    {
      city: "OAKLAND",
      state: "OR"
    },
    {
      city: "OAKLAND",
      state: "RI"
    },
    {
      city: "OAKLAND",
      state: "TN"
    },
    {
      city: "OAKLAND",
      state: "TX"
    },
    {
      city: "OAKLAND CITY",
      state: "IN"
    },
    {
      city: "OAKLAND GARDENS",
      state: "NY"
    },
    {
      city: "OAKLAND MILLS",
      state: "PA"
    },
    {
      city: "OAKLEY",
      state: "CA"
    },
    {
      city: "OAKLEY",
      state: "ID"
    },
    {
      city: "OAKLEY",
      state: "KS"
    },
    {
      city: "OAKLEY",
      state: "MI"
    },
    {
      city: "OAKLEY",
      state: "UT"
    },
    {
      city: "OAKLYN",
      state: "NJ"
    },
    {
      city: "OAKMAN",
      state: "AL"
    },
    {
      city: "OAKMAN",
      state: "GA"
    },
    {
      city: "OAKMONT",
      state: "PA"
    },
    {
      city: "OAKPARK",
      state: "VA"
    },
    {
      city: "OAKRIDGE",
      state: "OR"
    },
    {
      city: "OAKS",
      state: "OK"
    },
    {
      city: "OAKS",
      state: "PA"
    },
    {
      city: "OAKS CORNERS",
      state: "NY"
    },
    {
      city: "OAKTON",
      state: "VA"
    },
    {
      city: "OAKTOWN",
      state: "IN"
    },
    {
      city: "OAKVILLE",
      state: "CA"
    },
    {
      city: "OAKVILLE",
      state: "CT"
    },
    {
      city: "OAKVILLE",
      state: "IA"
    },
    {
      city: "OAKVILLE",
      state: "IN"
    },
    {
      city: "OAKVILLE",
      state: "TX"
    },
    {
      city: "OAKVILLE",
      state: "WA"
    },
    {
      city: "OAKWOOD",
      state: "GA"
    },
    {
      city: "OAKWOOD",
      state: "IL"
    },
    {
      city: "OAKWOOD",
      state: "OH"
    },
    {
      city: "OAKWOOD",
      state: "OK"
    },
    {
      city: "OAKWOOD",
      state: "TX"
    },
    {
      city: "OAKWOOD",
      state: "VA"
    },
    {
      city: "OARK",
      state: "AR"
    },
    {
      city: "OATMAN",
      state: "AZ"
    },
    {
      city: "OBERLIN",
      state: "KS"
    },
    {
      city: "OBERLIN",
      state: "LA"
    },
    {
      city: "OBERLIN",
      state: "OH"
    },
    {
      city: "OBERNBURG",
      state: "NY"
    },
    {
      city: "OBERON",
      state: "ND"
    },
    {
      city: "OBION",
      state: "TN"
    },
    {
      city: "OBLONG",
      state: "IL"
    },
    {
      city: "OBRIEN",
      state: "CA"
    },
    {
      city: "OCALA",
      state: "FL"
    },
    {
      city: "OCATE",
      state: "NM"
    },
    {
      city: "OCCIDENTAL",
      state: "CA"
    },
    {
      city: "OCCOQUAN",
      state: "VA"
    },
    {
      city: "OCEAN BEACH",
      state: "NY"
    },
    {
      city: "OCEAN BLUFF",
      state: "MA"
    },
    {
      city: "OCEAN CITY",
      state: "MD"
    },
    {
      city: "OCEAN CITY",
      state: "NJ"
    },
    {
      city: "OCEAN GATE",
      state: "NJ"
    },
    {
      city: "OCEAN GROVE",
      state: "NJ"
    },
    {
      city: "OCEAN ISLE BEACH",
      state: "NC"
    },
    {
      city: "OCEAN PARK",
      state: "ME"
    },
    {
      city: "OCEAN PARK",
      state: "WA"
    },
    {
      city: "OCEAN SHORES",
      state: "WA"
    },
    {
      city: "OCEAN SPRINGS",
      state: "MS"
    },
    {
      city: "OCEAN VIEW",
      state: "DE"
    },
    {
      city: "OCEAN VIEW",
      state: "HI"
    },
    {
      city: "OCEAN VIEW",
      state: "NJ"
    },
    {
      city: "OCEANA",
      state: "WV"
    },
    {
      city: "OCEANO",
      state: "CA"
    },
    {
      city: "OCEANPORT",
      state: "NJ"
    },
    {
      city: "OCEANSIDE",
      state: "CA"
    },
    {
      city: "OCEANSIDE",
      state: "NY"
    },
    {
      city: "OCEANSIDE",
      state: "OR"
    },
    {
      city: "OCEANVILLE",
      state: "NJ"
    },
    {
      city: "OCHELATA",
      state: "OK"
    },
    {
      city: "OCHEYEDAN",
      state: "IA"
    },
    {
      city: "OCHLOCKNEE",
      state: "GA"
    },
    {
      city: "OCHOPEE",
      state: "FL"
    },
    {
      city: "OCILLA",
      state: "GA"
    },
    {
      city: "OCKLAWAHA",
      state: "FL"
    },
    {
      city: "OCOEE",
      state: "FL"
    },
    {
      city: "OCOEE",
      state: "TN"
    },
    {
      city: "OCONEE",
      state: "GA"
    },
    {
      city: "OCONEE",
      state: "IL"
    },
    {
      city: "OCONOMOWOC",
      state: "WI"
    },
    {
      city: "OCONTO",
      state: "NE"
    },
    {
      city: "OCONTO",
      state: "WI"
    },
    {
      city: "OCONTO FALLS",
      state: "WI"
    },
    {
      city: "OCOTILLO",
      state: "CA"
    },
    {
      city: "OCRACOKE",
      state: "NC"
    },
    {
      city: "ODANAH",
      state: "WI"
    },
    {
      city: "ODEBOLT",
      state: "IA"
    },
    {
      city: "ODELL",
      state: "IL"
    },
    {
      city: "ODELL",
      state: "NE"
    },
    {
      city: "ODELL",
      state: "OR"
    },
    {
      city: "ODEM",
      state: "TX"
    },
    {
      city: "ODEN",
      state: "AR"
    },
    {
      city: "ODEN",
      state: "MI"
    },
    {
      city: "ODENTON",
      state: "MD"
    },
    {
      city: "ODENVILLE",
      state: "AL"
    },
    {
      city: "ODESSA",
      state: "DE"
    },
    {
      city: "ODESSA",
      state: "FL"
    },
    {
      city: "ODESSA",
      state: "MN"
    },
    {
      city: "ODESSA",
      state: "MO"
    },
    {
      city: "ODESSA",
      state: "NE"
    },
    {
      city: "ODESSA",
      state: "NY"
    },
    {
      city: "ODESSA",
      state: "TX"
    },
    {
      city: "ODESSA",
      state: "WA"
    },
    {
      city: "ODIN",
      state: "IL"
    },
    {
      city: "ODIN",
      state: "MN"
    },
    {
      city: "ODON",
      state: "IN"
    },
    {
      city: "ODONNELL",
      state: "TX"
    },
    {
      city: "ODUM",
      state: "GA"
    },
    {
      city: "OELRICHS",
      state: "SD"
    },
    {
      city: "OELWEIN",
      state: "IA"
    },
    {
      city: "OFFERLE",
      state: "KS"
    },
    {
      city: "OFFERMAN",
      state: "GA"
    },
    {
      city: "OFFUTT AFB",
      state: "NE"
    },
    {
      city: "OGALLAH",
      state: "KS"
    },
    {
      city: "OGALLALA",
      state: "NE"
    },
    {
      city: "OGDEN",
      state: "AR"
    },
    {
      city: "OGDEN",
      state: "IA"
    },
    {
      city: "OGDEN",
      state: "IL"
    },
    {
      city: "OGDEN",
      state: "KS"
    },
    {
      city: "OGDEN",
      state: "UT"
    },
    {
      city: "OGDENSBURG",
      state: "NJ"
    },
    {
      city: "OGDENSBURG",
      state: "NY"
    },
    {
      city: "OGDENSBURG",
      state: "WI"
    },
    {
      city: "OGEMA",
      state: "MN"
    },
    {
      city: "OGEMA",
      state: "WI"
    },
    {
      city: "OGILVIE",
      state: "MN"
    },
    {
      city: "OGLALA",
      state: "SD"
    },
    {
      city: "OGLESBY",
      state: "IL"
    },
    {
      city: "OGLESBY",
      state: "TX"
    },
    {
      city: "OGLETHORPE",
      state: "GA"
    },
    {
      city: "OGUNQUIT",
      state: "ME"
    },
    {
      city: "OHATCHEE",
      state: "AL"
    },
    {
      city: "OHIO",
      state: "IL"
    },
    {
      city: "OHIO CITY",
      state: "CO"
    },
    {
      city: "OHIO CITY",
      state: "OH"
    },
    {
      city: "OHIOPYLE",
      state: "PA"
    },
    {
      city: "OHIOWA",
      state: "NE"
    },
    {
      city: "OHKAY OWINGEH",
      state: "NM"
    },
    {
      city: "OHLMAN",
      state: "IL"
    },
    {
      city: "OIL CITY",
      state: "LA"
    },
    {
      city: "OIL CITY",
      state: "PA"
    },
    {
      city: "OIL SPRINGS",
      state: "KY"
    },
    {
      city: "OIL TROUGH",
      state: "AR"
    },
    {
      city: "OILMONT",
      state: "MT"
    },
    {
      city: "OILTON",
      state: "OK"
    },
    {
      city: "OILTON",
      state: "TX"
    },
    {
      city: "OILVILLE",
      state: "VA"
    },
    {
      city: "OJAI",
      state: "CA"
    },
    {
      city: "OJIBWA",
      state: "WI"
    },
    {
      city: "OJO CALIENTE",
      state: "NM"
    },
    {
      city: "OJO FELIZ",
      state: "NM"
    },
    {
      city: "OKABENA",
      state: "MN"
    },
    {
      city: "OKAHUMPKA",
      state: "FL"
    },
    {
      city: "OKANOGAN",
      state: "WA"
    },
    {
      city: "OKARCHE",
      state: "OK"
    },
    {
      city: "OKATIE",
      state: "SC"
    },
    {
      city: "OKATON",
      state: "SD"
    },
    {
      city: "OKAUCHEE",
      state: "WI"
    },
    {
      city: "OKAWVILLE",
      state: "IL"
    },
    {
      city: "OKAY",
      state: "OK"
    },
    {
      city: "OKEANA",
      state: "OH"
    },
    {
      city: "OKEECHOBEE",
      state: "FL"
    },
    {
      city: "OKEENE",
      state: "OK"
    },
    {
      city: "OKEMAH",
      state: "OK"
    },
    {
      city: "OKEMOS",
      state: "MI"
    },
    {
      city: "OKETO",
      state: "KS"
    },
    {
      city: "OKLAHOMA CITY",
      state: "OK"
    },
    {
      city: "OKLAUNION",
      state: "TX"
    },
    {
      city: "OKLEE",
      state: "MN"
    },
    {
      city: "OKMULGEE",
      state: "OK"
    },
    {
      city: "OKOBOJI",
      state: "IA"
    },
    {
      city: "OKOLONA",
      state: "AR"
    },
    {
      city: "OKOLONA",
      state: "MS"
    },
    {
      city: "OKOLONA",
      state: "OH"
    },
    {
      city: "OKTAHA",
      state: "OK"
    },
    {
      city: "OLA",
      state: "AR"
    },
    {
      city: "OLA",
      state: "ID"
    },
    {
      city: "OLALLA",
      state: "WA"
    },
    {
      city: "OLANCHA",
      state: "CA"
    },
    {
      city: "OLANTA",
      state: "PA"
    },
    {
      city: "OLANTA",
      state: "SC"
    },
    {
      city: "OLAR",
      state: "SC"
    },
    {
      city: "OLATHE",
      state: "CO"
    },
    {
      city: "OLATHE",
      state: "KS"
    },
    {
      city: "OLATON",
      state: "KY"
    },
    {
      city: "OLCOTT",
      state: "NY"
    },
    {
      city: "OLD APPLETON",
      state: "MO"
    },
    {
      city: "OLD BETHPAGE",
      state: "NY"
    },
    {
      city: "OLD BRIDGE",
      state: "NJ"
    },
    {
      city: "OLD CHATHAM",
      state: "NY"
    },
    {
      city: "OLD FIELDS",
      state: "WV"
    },
    {
      city: "OLD FORGE",
      state: "NY"
    },
    {
      city: "OLD FORGE",
      state: "PA"
    },
    {
      city: "OLD FORT",
      state: "NC"
    },
    {
      city: "OLD FORT",
      state: "OH"
    },
    {
      city: "OLD FORT",
      state: "TN"
    },
    {
      city: "OLD GLORY",
      state: "TX"
    },
    {
      city: "OLD GREENWICH",
      state: "CT"
    },
    {
      city: "OLD HARBOR",
      state: "AK"
    },
    {
      city: "OLD HICKORY",
      state: "TN"
    },
    {
      city: "OLD LYME",
      state: "CT"
    },
    {
      city: "OLD MISSION",
      state: "MI"
    },
    {
      city: "OLD MONROE",
      state: "MO"
    },
    {
      city: "OLD MYSTIC",
      state: "CT"
    },
    {
      city: "OLD OCEAN",
      state: "TX"
    },
    {
      city: "OLD ORCHARD BEACH",
      state: "ME"
    },
    {
      city: "OLD SAYBROOK",
      state: "CT"
    },
    {
      city: "OLD STATION",
      state: "CA"
    },
    {
      city: "OLD TOWN",
      state: "FL"
    },
    {
      city: "OLD TOWN",
      state: "ME"
    },
    {
      city: "OLD WASHINGTON",
      state: "OH"
    },
    {
      city: "OLD WESTBURY",
      state: "NY"
    },
    {
      city: "OLD ZIONSVILLE",
      state: "PA"
    },
    {
      city: "OLDEN",
      state: "TX"
    },
    {
      city: "OLDENBURG",
      state: "IN"
    },
    {
      city: "OLDFIELD",
      state: "MO"
    },
    {
      city: "OLDHAM",
      state: "SD"
    },
    {
      city: "OLDHAMS",
      state: "VA"
    },
    {
      city: "OLDS",
      state: "IA"
    },
    {
      city: "OLDSMAR",
      state: "FL"
    },
    {
      city: "OLDTOWN",
      state: "ID"
    },
    {
      city: "OLDTOWN",
      state: "MD"
    },
    {
      city: "OLDWICK",
      state: "NJ"
    },
    {
      city: "OLEAN",
      state: "MO"
    },
    {
      city: "OLEAN",
      state: "NY"
    },
    {
      city: "OLEMA",
      state: "CA"
    },
    {
      city: "OLEY",
      state: "PA"
    },
    {
      city: "OLGA",
      state: "WA"
    },
    {
      city: "OLIN",
      state: "IA"
    },
    {
      city: "OLIN",
      state: "NC"
    },
    {
      city: "OLIVE BRANCH",
      state: "IL"
    },
    {
      city: "OLIVE BRANCH",
      state: "MS"
    },
    {
      city: "OLIVE HILL",
      state: "KY"
    },
    {
      city: "OLIVEBRIDGE",
      state: "NY"
    },
    {
      city: "OLIVEBURG",
      state: "PA"
    },
    {
      city: "OLIVEHILL",
      state: "TN"
    },
    {
      city: "OLIVEHURST",
      state: "CA"
    },
    {
      city: "OLIVER",
      state: "GA"
    },
    {
      city: "OLIVER",
      state: "PA"
    },
    {
      city: "OLIVER SPRINGS",
      state: "TN"
    },
    {
      city: "OLIVET",
      state: "MI"
    },
    {
      city: "OLIVET",
      state: "SD"
    },
    {
      city: "OLIVIA",
      state: "MN"
    },
    {
      city: "OLIVIA",
      state: "NC"
    },
    {
      city: "OLLA",
      state: "LA"
    },
    {
      city: "OLLIE",
      state: "IA"
    },
    {
      city: "OLMITO",
      state: "TX"
    },
    {
      city: "OLMITZ",
      state: "KS"
    },
    {
      city: "OLMSTEAD",
      state: "KY"
    },
    {
      city: "OLMSTED",
      state: "IL"
    },
    {
      city: "OLMSTED FALLS",
      state: "OH"
    },
    {
      city: "OLMSTEDVILLE",
      state: "NY"
    },
    {
      city: "OLNEY",
      state: "IL"
    },
    {
      city: "OLNEY",
      state: "MD"
    },
    {
      city: "OLNEY",
      state: "MT"
    },
    {
      city: "OLNEY",
      state: "TX"
    },
    {
      city: "OLNEY SPRINGS",
      state: "CO"
    },
    {
      city: "OLPE",
      state: "KS"
    },
    {
      city: "OLSBURG",
      state: "KS"
    },
    {
      city: "OLTON",
      state: "TX"
    },
    {
      city: "OLUSTEE",
      state: "FL"
    },
    {
      city: "OLUSTEE",
      state: "OK"
    },
    {
      city: "OLYMPIA",
      state: "KY"
    },
    {
      city: "OLYMPIA",
      state: "WA"
    },
    {
      city: "OLYMPIA FIELDS",
      state: "IL"
    },
    {
      city: "OLYMPIC VALLEY",
      state: "CA"
    },
    {
      city: "OLYPHANT",
      state: "PA"
    },
    {
      city: "OMAHA",
      state: "AR"
    },
    {
      city: "OMAHA",
      state: "GA"
    },
    {
      city: "OMAHA",
      state: "IL"
    },
    {
      city: "OMAHA",
      state: "NE"
    },
    {
      city: "OMAHA",
      state: "TX"
    },
    {
      city: "OMAK",
      state: "WA"
    },
    {
      city: "OMAR",
      state: "WV"
    },
    {
      city: "OMEGA",
      state: "GA"
    },
    {
      city: "OMEGA",
      state: "OK"
    },
    {
      city: "OMENA",
      state: "MI"
    },
    {
      city: "OMER",
      state: "MI"
    },
    {
      city: "OMRO",
      state: "WI"
    },
    {
      city: "ONA",
      state: "FL"
    },
    {
      city: "ONA",
      state: "WV"
    },
    {
      city: "ONAGA",
      state: "KS"
    },
    {
      city: "ONAKA",
      state: "SD"
    },
    {
      city: "ONALASKA",
      state: "TX"
    },
    {
      city: "ONALASKA",
      state: "WA"
    },
    {
      city: "ONALASKA",
      state: "WI"
    },
    {
      city: "ONAMIA",
      state: "MN"
    },
    {
      city: "ONANCOCK",
      state: "VA"
    },
    {
      city: "ONARGA",
      state: "IL"
    },
    {
      city: "ONAWA",
      state: "IA"
    },
    {
      city: "ONAWAY",
      state: "MI"
    },
    {
      city: "ONECO",
      state: "CT"
    },
    {
      city: "ONECO",
      state: "FL"
    },
    {
      city: "ONEGO",
      state: "WV"
    },
    {
      city: "ONEIDA",
      state: "AR"
    },
    {
      city: "ONEIDA",
      state: "IL"
    },
    {
      city: "ONEIDA",
      state: "KY"
    },
    {
      city: "ONEIDA",
      state: "NY"
    },
    {
      city: "ONEIDA",
      state: "TN"
    },
    {
      city: "ONEIDA",
      state: "WI"
    },
    {
      city: "ONEILL",
      state: "NE"
    },
    {
      city: "ONEKAMA",
      state: "MI"
    },
    {
      city: "ONEMO",
      state: "VA"
    },
    {
      city: "ONEONTA",
      state: "AL"
    },
    {
      city: "ONEONTA",
      state: "NY"
    },
    {
      city: "ONG",
      state: "NE"
    },
    {
      city: "ONIA",
      state: "AR"
    },
    {
      city: "ONIDA",
      state: "SD"
    },
    {
      city: "ONLEY",
      state: "VA"
    },
    {
      city: "ONLY",
      state: "TN"
    },
    {
      city: "ONO",
      state: "PA"
    },
    {
      city: "ONONDAGA",
      state: "MI"
    },
    {
      city: "ONSET",
      state: "MA"
    },
    {
      city: "ONSLOW",
      state: "IA"
    },
    {
      city: "ONSTED",
      state: "MI"
    },
    {
      city: "ONTARIO",
      state: "CA"
    },
    {
      city: "ONTARIO",
      state: "NY"
    },
    {
      city: "ONTARIO",
      state: "OH"
    },
    {
      city: "ONTARIO",
      state: "OR"
    },
    {
      city: "ONTARIO",
      state: "WI"
    },
    {
      city: "ONTARIO CENTER",
      state: "NY"
    },
    {
      city: "ONTONAGON",
      state: "MI"
    },
    {
      city: "ONWARD",
      state: "IN"
    },
    {
      city: "ONYX",
      state: "CA"
    },
    {
      city: "OOKALA",
      state: "HI"
    },
    {
      city: "OOLITIC",
      state: "IN"
    },
    {
      city: "OOLOGAH",
      state: "OK"
    },
    {
      city: "OOLTEWAH",
      state: "TN"
    },
    {
      city: "OOSTBURG",
      state: "WI"
    },
    {
      city: "OPA LOCKA",
      state: "FL"
    },
    {
      city: "OPAL",
      state: "WY"
    },
    {
      city: "OPDYKE",
      state: "IL"
    },
    {
      city: "OPELIKA",
      state: "AL"
    },
    {
      city: "OPELOUSAS",
      state: "LA"
    },
    {
      city: "OPHEIM",
      state: "MT"
    },
    {
      city: "OPHELIA",
      state: "VA"
    },
    {
      city: "OPHIEM",
      state: "IL"
    },
    {
      city: "OPHIR",
      state: "CO"
    },
    {
      city: "OPHIR",
      state: "OR"
    },
    {
      city: "OPOLIS",
      state: "KS"
    },
    {
      city: "OPP",
      state: "AL"
    },
    {
      city: "OQUAWKA",
      state: "IL"
    },
    {
      city: "OQUOSSOC",
      state: "ME"
    },
    {
      city: "ORACLE",
      state: "AZ"
    },
    {
      city: "ORADELL",
      state: "NJ"
    },
    {
      city: "ORAL",
      state: "SD"
    },
    {
      city: "ORAN",
      state: "IA"
    },
    {
      city: "ORAN",
      state: "MO"
    },
    {
      city: "ORANGE",
      state: "CA"
    },
    {
      city: "ORANGE",
      state: "CT"
    },
    {
      city: "ORANGE",
      state: "MA"
    },
    {
      city: "ORANGE",
      state: "NJ"
    },
    {
      city: "ORANGE",
      state: "TX"
    },
    {
      city: "ORANGE",
      state: "VA"
    },
    {
      city: "ORANGE BEACH",
      state: "AL"
    },
    {
      city: "ORANGE CITY",
      state: "FL"
    },
    {
      city: "ORANGE CITY",
      state: "IA"
    },
    {
      city: "ORANGE COVE",
      state: "CA"
    },
    {
      city: "ORANGE GROVE",
      state: "TX"
    },
    {
      city: "ORANGE LAKE",
      state: "FL"
    },
    {
      city: "ORANGE PARK",
      state: "FL"
    },
    {
      city: "ORANGE SPRINGS",
      state: "FL"
    },
    {
      city: "ORANGEBURG",
      state: "NY"
    },
    {
      city: "ORANGEBURG",
      state: "SC"
    },
    {
      city: "ORANGEFIELD",
      state: "TX"
    },
    {
      city: "ORANGEVALE",
      state: "CA"
    },
    {
      city: "ORANGEVILLE",
      state: "IL"
    },
    {
      city: "ORANGEVILLE",
      state: "OH"
    },
    {
      city: "ORANGEVILLE",
      state: "PA"
    },
    {
      city: "ORANGEVILLE",
      state: "UT"
    },
    {
      city: "ORBISONIA",
      state: "PA"
    },
    {
      city: "ORCAS",
      state: "WA"
    },
    {
      city: "ORCHARD",
      state: "CO"
    },
    {
      city: "ORCHARD",
      state: "IA"
    },
    {
      city: "ORCHARD",
      state: "NE"
    },
    {
      city: "ORCHARD",
      state: "TX"
    },
    {
      city: "ORCHARD HILL",
      state: "GA"
    },
    {
      city: "ORCHARD PARK",
      state: "NY"
    },
    {
      city: "ORD",
      state: "NE"
    },
    {
      city: "ORDERVILLE",
      state: "UT"
    },
    {
      city: "ORDINARY",
      state: "VA"
    },
    {
      city: "ORDWAY",
      state: "CO"
    },
    {
      city: "ORE CITY",
      state: "TX"
    },
    {
      city: "OREANA",
      state: "IL"
    },
    {
      city: "OREFIELD",
      state: "PA"
    },
    {
      city: "OREGON",
      state: "IL"
    },
    {
      city: "OREGON",
      state: "MO"
    },
    {
      city: "OREGON",
      state: "OH"
    },
    {
      city: "OREGON",
      state: "WI"
    },
    {
      city: "OREGON CITY",
      state: "OR"
    },
    {
      city: "OREGON HOUSE",
      state: "CA"
    },
    {
      city: "OREGONIA",
      state: "OH"
    },
    {
      city: "ORELAND",
      state: "PA"
    },
    {
      city: "OREM",
      state: "UT"
    },
    {
      city: "ORESTES",
      state: "IN"
    },
    {
      city: "ORFORD",
      state: "NH"
    },
    {
      city: "ORFORDVILLE",
      state: "WI"
    },
    {
      city: "ORGAN",
      state: "NM"
    },
    {
      city: "ORGAS",
      state: "WV"
    },
    {
      city: "ORICK",
      state: "CA"
    },
    {
      city: "ORIENT",
      state: "IA"
    },
    {
      city: "ORIENT",
      state: "IL"
    },
    {
      city: "ORIENT",
      state: "ME"
    },
    {
      city: "ORIENT",
      state: "NY"
    },
    {
      city: "ORIENT",
      state: "OH"
    },
    {
      city: "ORIENT",
      state: "SD"
    },
    {
      city: "ORIENT",
      state: "WA"
    },
    {
      city: "ORIENTAL",
      state: "NC"
    },
    {
      city: "ORINDA",
      state: "CA"
    },
    {
      city: "ORION",
      state: "IL"
    },
    {
      city: "ORISKA",
      state: "ND"
    },
    {
      city: "ORISKANY",
      state: "NY"
    },
    {
      city: "ORISKANY FALLS",
      state: "NY"
    },
    {
      city: "ORKNEY SPRINGS",
      state: "VA"
    },
    {
      city: "ORLA",
      state: "TX"
    },
    {
      city: "ORLAND",
      state: "CA"
    },
    {
      city: "ORLAND",
      state: "IN"
    },
    {
      city: "ORLAND",
      state: "ME"
    },
    {
      city: "ORLAND PARK",
      state: "IL"
    },
    {
      city: "ORLANDO",
      state: "FL"
    },
    {
      city: "ORLANDO",
      state: "KY"
    },
    {
      city: "ORLANDO",
      state: "OK"
    },
    {
      city: "ORLANDO",
      state: "WV"
    },
    {
      city: "ORLEAN",
      state: "VA"
    },
    {
      city: "ORLEANS",
      state: "CA"
    },
    {
      city: "ORLEANS",
      state: "IN"
    },
    {
      city: "ORLEANS",
      state: "MA"
    },
    {
      city: "ORLEANS",
      state: "MI"
    },
    {
      city: "ORLEANS",
      state: "NE"
    },
    {
      city: "ORLEANS",
      state: "VT"
    },
    {
      city: "ORLINDA",
      state: "TN"
    },
    {
      city: "ORMA",
      state: "WV"
    },
    {
      city: "ORMOND BEACH",
      state: "FL"
    },
    {
      city: "ORMSBY",
      state: "MN"
    },
    {
      city: "ORO GRANDE",
      state: "CA"
    },
    {
      city: "OROFINO",
      state: "ID"
    },
    {
      city: "OROGRANDE",
      state: "NM"
    },
    {
      city: "ORONDO",
      state: "WA"
    },
    {
      city: "ORONO",
      state: "ME"
    },
    {
      city: "ORONOCO",
      state: "MN"
    },
    {
      city: "ORONOGO",
      state: "MO"
    },
    {
      city: "OROSI",
      state: "CA"
    },
    {
      city: "OROVADA",
      state: "NV"
    },
    {
      city: "OROVILLE",
      state: "CA"
    },
    {
      city: "OROVILLE",
      state: "WA"
    },
    {
      city: "ORR",
      state: "MN"
    },
    {
      city: "ORRICK",
      state: "MO"
    },
    {
      city: "ORRINGTON",
      state: "ME"
    },
    {
      city: "ORRS ISLAND",
      state: "ME"
    },
    {
      city: "ORRSTOWN",
      state: "PA"
    },
    {
      city: "ORRTANNA",
      state: "PA"
    },
    {
      city: "ORRUM",
      state: "NC"
    },
    {
      city: "ORRVILLE",
      state: "AL"
    },
    {
      city: "ORRVILLE",
      state: "OH"
    },
    {
      city: "ORSON",
      state: "PA"
    },
    {
      city: "ORTING",
      state: "WA"
    },
    {
      city: "ORTONVILLE",
      state: "MI"
    },
    {
      city: "ORTONVILLE",
      state: "MN"
    },
    {
      city: "ORWELL",
      state: "NY"
    },
    {
      city: "ORWELL",
      state: "OH"
    },
    {
      city: "ORWELL",
      state: "VT"
    },
    {
      city: "ORWIGSBURG",
      state: "PA"
    },
    {
      city: "OSAGE",
      state: "IA"
    },
    {
      city: "OSAGE",
      state: "MN"
    },
    {
      city: "OSAGE",
      state: "OK"
    },
    {
      city: "OSAGE",
      state: "WV"
    },
    {
      city: "OSAGE",
      state: "WY"
    },
    {
      city: "OSAGE BEACH",
      state: "MO"
    },
    {
      city: "OSAGE CITY",
      state: "KS"
    },
    {
      city: "OSAKIS",
      state: "MN"
    },
    {
      city: "OSAWATOMIE",
      state: "KS"
    },
    {
      city: "OSBORN",
      state: "MO"
    },
    {
      city: "OSBORNE",
      state: "KS"
    },
    {
      city: "OSBURN",
      state: "ID"
    },
    {
      city: "OSCAR",
      state: "LA"
    },
    {
      city: "OSCAR",
      state: "OK"
    },
    {
      city: "OSCEOLA",
      state: "AR"
    },
    {
      city: "OSCEOLA",
      state: "IA"
    },
    {
      city: "OSCEOLA",
      state: "IN"
    },
    {
      city: "OSCEOLA",
      state: "MO"
    },
    {
      city: "OSCEOLA",
      state: "NE"
    },
    {
      city: "OSCEOLA",
      state: "PA"
    },
    {
      city: "OSCEOLA",
      state: "WI"
    },
    {
      city: "OSCEOLA MILLS",
      state: "PA"
    },
    {
      city: "OSCO",
      state: "IL"
    },
    {
      city: "OSCODA",
      state: "MI"
    },
    {
      city: "OSGOOD",
      state: "IN"
    },
    {
      city: "OSGOOD",
      state: "OH"
    },
    {
      city: "OSHKOSH",
      state: "NE"
    },
    {
      city: "OSHKOSH",
      state: "WI"
    },
    {
      city: "OSHTEMO",
      state: "MI"
    },
    {
      city: "OSKALOOSA",
      state: "IA"
    },
    {
      city: "OSKALOOSA",
      state: "KS"
    },
    {
      city: "OSLO",
      state: "MN"
    },
    {
      city: "OSMOND",
      state: "NE"
    },
    {
      city: "OSNABROCK",
      state: "ND"
    },
    {
      city: "OSPREY",
      state: "FL"
    },
    {
      city: "OSSEO",
      state: "MI"
    },
    {
      city: "OSSEO",
      state: "MN"
    },
    {
      city: "OSSEO",
      state: "WI"
    },
    {
      city: "OSSIAN",
      state: "IA"
    },
    {
      city: "OSSIAN",
      state: "IN"
    },
    {
      city: "OSSINEKE",
      state: "MI"
    },
    {
      city: "OSSINING",
      state: "NY"
    },
    {
      city: "OSSIPEE",
      state: "NH"
    },
    {
      city: "OSTEEN",
      state: "FL"
    },
    {
      city: "OSTERBURG",
      state: "PA"
    },
    {
      city: "OSTERVILLE",
      state: "MA"
    },
    {
      city: "OSTRANDER",
      state: "MN"
    },
    {
      city: "OSTRANDER",
      state: "OH"
    },
    {
      city: "OSWEGATCHIE",
      state: "NY"
    },
    {
      city: "OSWEGO",
      state: "IL"
    },
    {
      city: "OSWEGO",
      state: "KS"
    },
    {
      city: "OSWEGO",
      state: "NY"
    },
    {
      city: "OSYKA",
      state: "MS"
    },
    {
      city: "OTEGO",
      state: "NY"
    },
    {
      city: "OTHELLO",
      state: "WA"
    },
    {
      city: "OTHO",
      state: "IA"
    },
    {
      city: "OTIS",
      state: "CO"
    },
    {
      city: "OTIS",
      state: "KS"
    },
    {
      city: "OTIS",
      state: "LA"
    },
    {
      city: "OTIS",
      state: "MA"
    },
    {
      city: "OTIS",
      state: "OR"
    },
    {
      city: "OTIS ORCHARDS",
      state: "WA"
    },
    {
      city: "OTISCO",
      state: "IN"
    },
    {
      city: "OTISVILLE",
      state: "MI"
    },
    {
      city: "OTISVILLE",
      state: "NY"
    },
    {
      city: "OTLEY",
      state: "IA"
    },
    {
      city: "OTO",
      state: "IA"
    },
    {
      city: "OTOE",
      state: "NE"
    },
    {
      city: "OTSEGO",
      state: "MI"
    },
    {
      city: "OTTAWA",
      state: "IL"
    },
    {
      city: "OTTAWA",
      state: "KS"
    },
    {
      city: "OTTAWA",
      state: "OH"
    },
    {
      city: "OTTAWA LAKE",
      state: "MI"
    },
    {
      city: "OTTER",
      state: "MT"
    },
    {
      city: "OTTER CREEK",
      state: "FL"
    },
    {
      city: "OTTER LAKE",
      state: "MI"
    },
    {
      city: "OTTER ROCK",
      state: "OR"
    },
    {
      city: "OTTERBEIN",
      state: "IN"
    },
    {
      city: "OTTERTAIL",
      state: "MN"
    },
    {
      city: "OTTERVILLE",
      state: "MO"
    },
    {
      city: "OTTO",
      state: "NC"
    },
    {
      city: "OTTO",
      state: "NY"
    },
    {
      city: "OTTO",
      state: "WY"
    },
    {
      city: "OTTOSEN",
      state: "IA"
    },
    {
      city: "OTTOVILLE",
      state: "OH"
    },
    {
      city: "OTTSVILLE",
      state: "PA"
    },
    {
      city: "OTTUMWA",
      state: "IA"
    },
    {
      city: "OTWAY",
      state: "OH"
    },
    {
      city: "OTWELL",
      state: "IN"
    },
    {
      city: "OUAQUAGA",
      state: "NY"
    },
    {
      city: "OURAY",
      state: "CO"
    },
    {
      city: "OUTING",
      state: "MN"
    },
    {
      city: "OUTLOOK",
      state: "MT"
    },
    {
      city: "OUTLOOK",
      state: "WA"
    },
    {
      city: "OUZINKIE",
      state: "AK"
    },
    {
      city: "OVALO",
      state: "TX"
    },
    {
      city: "OVANDO",
      state: "MT"
    },
    {
      city: "OVERBROOK",
      state: "KS"
    },
    {
      city: "OVERBROOK",
      state: "OK"
    },
    {
      city: "OVERGAARD",
      state: "AZ"
    },
    {
      city: "OVERLAND PARK",
      state: "KS"
    },
    {
      city: "OVERPECK",
      state: "OH"
    },
    {
      city: "OVERTON",
      state: "NE"
    },
    {
      city: "OVERTON",
      state: "NV"
    },
    {
      city: "OVERTON",
      state: "TX"
    },
    {
      city: "OVETT",
      state: "MS"
    },
    {
      city: "OVID",
      state: "CO"
    },
    {
      city: "OVID",
      state: "MI"
    },
    {
      city: "OVID",
      state: "NY"
    },
    {
      city: "OVIEDO",
      state: "FL"
    },
    {
      city: "OWANECO",
      state: "IL"
    },
    {
      city: "OWANKA",
      state: "SD"
    },
    {
      city: "OWASSO",
      state: "OK"
    },
    {
      city: "OWATONNA",
      state: "MN"
    },
    {
      city: "OWEGO",
      state: "NY"
    },
    {
      city: "OWEN",
      state: "WI"
    },
    {
      city: "OWENDALE",
      state: "MI"
    },
    {
      city: "OWENS CROSS ROADS",
      state: "AL"
    },
    {
      city: "OWENSBORO",
      state: "KY"
    },
    {
      city: "OWENSBURG",
      state: "IN"
    },
    {
      city: "OWENSVILLE",
      state: "IN"
    },
    {
      city: "OWENSVILLE",
      state: "MO"
    },
    {
      city: "OWENSVILLE",
      state: "OH"
    },
    {
      city: "OWENTON",
      state: "KY"
    },
    {
      city: "OWINGS",
      state: "MD"
    },
    {
      city: "OWINGS MILLS",
      state: "MD"
    },
    {
      city: "OWINGSVILLE",
      state: "KY"
    },
    {
      city: "OWLS HEAD",
      state: "ME"
    },
    {
      city: "OWLS HEAD",
      state: "NY"
    },
    {
      city: "OWOSSO",
      state: "MI"
    },
    {
      city: "OWYHEE",
      state: "NV"
    },
    {
      city: "OXBOW",
      state: "ME"
    },
    {
      city: "OXBOW",
      state: "NY"
    },
    {
      city: "OXBOW",
      state: "OR"
    },
    {
      city: "OXFORD",
      state: "AL"
    },
    {
      city: "OXFORD",
      state: "AR"
    },
    {
      city: "OXFORD",
      state: "CT"
    },
    {
      city: "OXFORD",
      state: "FL"
    },
    {
      city: "OXFORD",
      state: "GA"
    },
    {
      city: "OXFORD",
      state: "IA"
    },
    {
      city: "OXFORD",
      state: "IN"
    },
    {
      city: "OXFORD",
      state: "KS"
    },
    {
      city: "OXFORD",
      state: "MA"
    },
    {
      city: "OXFORD",
      state: "MD"
    },
    {
      city: "OXFORD",
      state: "ME"
    },
    {
      city: "OXFORD",
      state: "MI"
    },
    {
      city: "OXFORD",
      state: "MS"
    },
    {
      city: "OXFORD",
      state: "NC"
    },
    {
      city: "OXFORD",
      state: "NE"
    },
    {
      city: "OXFORD",
      state: "NJ"
    },
    {
      city: "OXFORD",
      state: "NY"
    },
    {
      city: "OXFORD",
      state: "OH"
    },
    {
      city: "OXFORD",
      state: "PA"
    },
    {
      city: "OXFORD",
      state: "WI"
    },
    {
      city: "OXFORD JUNCTION",
      state: "IA"
    },
    {
      city: "OXLY",
      state: "MO"
    },
    {
      city: "OXNARD",
      state: "CA"
    },
    {
      city: "OXON HILL",
      state: "MD"
    },
    {
      city: "OYENS",
      state: "IA"
    },
    {
      city: "OYSTER",
      state: "VA"
    },
    {
      city: "OYSTER BAY",
      state: "NY"
    },
    {
      city: "OYSTERVILLE",
      state: "WA"
    },
    {
      city: "OZARK",
      state: "AL"
    },
    {
      city: "OZARK",
      state: "AR"
    },
    {
      city: "OZARK",
      state: "IL"
    },
    {
      city: "OZARK",
      state: "MO"
    },
    {
      city: "OZAWKIE",
      state: "KS"
    },
    {
      city: "OZONA",
      state: "FL"
    },
    {
      city: "OZONA",
      state: "TX"
    },
    {
      city: "OZONE",
      state: "AR"
    },
    {
      city: "OZONE PARK",
      state: "NY"
    },
    {
      city: "PAAUILO",
      state: "HI"
    },
    {
      city: "PABLO",
      state: "MT"
    },
    {
      city: "PACE",
      state: "MS"
    },
    {
      city: "PACHUTA",
      state: "MS"
    },
    {
      city: "PACIFIC",
      state: "MO"
    },
    {
      city: "PACIFIC",
      state: "WA"
    },
    {
      city: "PACIFIC BEACH",
      state: "WA"
    },
    {
      city: "PACIFIC CITY",
      state: "OR"
    },
    {
      city: "PACIFIC GROVE",
      state: "CA"
    },
    {
      city: "PACIFIC JUNCTION",
      state: "IA"
    },
    {
      city: "PACIFIC PALISADES",
      state: "CA"
    },
    {
      city: "PACIFICA",
      state: "CA"
    },
    {
      city: "PACKWAUKEE",
      state: "WI"
    },
    {
      city: "PACKWOOD",
      state: "IA"
    },
    {
      city: "PACKWOOD",
      state: "WA"
    },
    {
      city: "PACOIMA",
      state: "CA"
    },
    {
      city: "PACOLET",
      state: "SC"
    },
    {
      city: "PACOLET MILLS",
      state: "SC"
    },
    {
      city: "PADEN",
      state: "OK"
    },
    {
      city: "PADEN CITY",
      state: "WV"
    },
    {
      city: "PADRONI",
      state: "CO"
    },
    {
      city: "PADUCAH",
      state: "KY"
    },
    {
      city: "PADUCAH",
      state: "TX"
    },
    {
      city: "PAEONIAN SPRINGS",
      state: "VA"
    },
    {
      city: "PAGE",
      state: "AZ"
    },
    {
      city: "PAGE",
      state: "ND"
    },
    {
      city: "PAGE",
      state: "NE"
    },
    {
      city: "PAGE",
      state: "WV"
    },
    {
      city: "PAGELAND",
      state: "SC"
    },
    {
      city: "PAGETON",
      state: "WV"
    },
    {
      city: "PAGOSA SPRINGS",
      state: "CO"
    },
    {
      city: "PAHALA",
      state: "HI"
    },
    {
      city: "PAHOA",
      state: "HI"
    },
    {
      city: "PAHOKEE",
      state: "FL"
    },
    {
      city: "PAHRUMP",
      state: "NV"
    },
    {
      city: "PAIA",
      state: "HI"
    },
    {
      city: "PAICINES",
      state: "CA"
    },
    {
      city: "PAIGE",
      state: "TX"
    },
    {
      city: "PAINCOURTVILLE",
      state: "LA"
    },
    {
      city: "PAINESDALE",
      state: "MI"
    },
    {
      city: "PAINESVILLE",
      state: "OH"
    },
    {
      city: "PAINT BANK",
      state: "VA"
    },
    {
      city: "PAINT LICK",
      state: "KY"
    },
    {
      city: "PAINT ROCK",
      state: "AL"
    },
    {
      city: "PAINT ROCK",
      state: "TX"
    },
    {
      city: "PAINTED POST",
      state: "NY"
    },
    {
      city: "PAINTER",
      state: "VA"
    },
    {
      city: "PAINTSVILLE",
      state: "KY"
    },
    {
      city: "PAISLEY",
      state: "FL"
    },
    {
      city: "PAISLEY",
      state: "OR"
    },
    {
      city: "PALA",
      state: "CA"
    },
    {
      city: "PALACIOS",
      state: "TX"
    },
    {
      city: "PALATINE",
      state: "IL"
    },
    {
      city: "PALATINE BRIDGE",
      state: "NY"
    },
    {
      city: "PALATKA",
      state: "FL"
    },
    {
      city: "PALCO",
      state: "KS"
    },
    {
      city: "PALENVILLE",
      state: "NY"
    },
    {
      city: "PALERMO",
      state: "CA"
    },
    {
      city: "PALERMO",
      state: "ME"
    },
    {
      city: "PALERMO",
      state: "ND"
    },
    {
      city: "PALESTINE",
      state: "AR"
    },
    {
      city: "PALESTINE",
      state: "IL"
    },
    {
      city: "PALESTINE",
      state: "OH"
    },
    {
      city: "PALESTINE",
      state: "TX"
    },
    {
      city: "PALESTINE",
      state: "WV"
    },
    {
      city: "PALISADE",
      state: "CO"
    },
    {
      city: "PALISADE",
      state: "MN"
    },
    {
      city: "PALISADE",
      state: "NE"
    },
    {
      city: "PALISADES",
      state: "NY"
    },
    {
      city: "PALISADES",
      state: "WA"
    },
    {
      city: "PALISADES PARK",
      state: "NJ"
    },
    {
      city: "PALL MALL",
      state: "TN"
    },
    {
      city: "PALM",
      state: "PA"
    },
    {
      city: "PALM BAY",
      state: "FL"
    },
    {
      city: "PALM BEACH",
      state: "FL"
    },
    {
      city: "PALM BEACH GARDENS",
      state: "FL"
    },
    {
      city: "PALM CITY",
      state: "FL"
    },
    {
      city: "PALM COAST",
      state: "FL"
    },
    {
      city: "PALM DESERT",
      state: "CA"
    },
    {
      city: "PALM HARBOR",
      state: "FL"
    },
    {
      city: "PALM SPRINGS",
      state: "CA"
    },
    {
      city: "PALMDALE",
      state: "CA"
    },
    {
      city: "PALMDALE",
      state: "FL"
    },
    {
      city: "PALMER",
      state: "AK"
    },
    {
      city: "PALMER",
      state: "IA"
    },
    {
      city: "PALMER",
      state: "IL"
    },
    {
      city: "PALMER",
      state: "KS"
    },
    {
      city: "PALMER",
      state: "MA"
    },
    {
      city: "PALMER",
      state: "MI"
    },
    {
      city: "PALMER",
      state: "NE"
    },
    {
      city: "PALMER",
      state: "TN"
    },
    {
      city: "PALMER",
      state: "TX"
    },
    {
      city: "PALMER LAKE",
      state: "CO"
    },
    {
      city: "PALMERDALE",
      state: "AL"
    },
    {
      city: "PALMERSVILLE",
      state: "TN"
    },
    {
      city: "PALMERTON",
      state: "PA"
    },
    {
      city: "PALMETTO",
      state: "FL"
    },
    {
      city: "PALMETTO",
      state: "GA"
    },
    {
      city: "PALMETTO",
      state: "LA"
    },
    {
      city: "PALMS",
      state: "MI"
    },
    {
      city: "PALMYRA",
      state: "IL"
    },
    {
      city: "PALMYRA",
      state: "IN"
    },
    {
      city: "PALMYRA",
      state: "ME"
    },
    {
      city: "PALMYRA",
      state: "MI"
    },
    {
      city: "PALMYRA",
      state: "MO"
    },
    {
      city: "PALMYRA",
      state: "NE"
    },
    {
      city: "PALMYRA",
      state: "NJ"
    },
    {
      city: "PALMYRA",
      state: "NY"
    },
    {
      city: "PALMYRA",
      state: "PA"
    },
    {
      city: "PALMYRA",
      state: "TN"
    },
    {
      city: "PALMYRA",
      state: "VA"
    },
    {
      city: "PALMYRA",
      state: "WI"
    },
    {
      city: "PALO",
      state: "IA"
    },
    {
      city: "PALO",
      state: "MI"
    },
    {
      city: "PALO ALTO",
      state: "CA"
    },
    {
      city: "PALO CEDRO",
      state: "CA"
    },
    {
      city: "PALO PINTO",
      state: "TX"
    },
    {
      city: "PALO VERDE",
      state: "AZ"
    },
    {
      city: "PALO VERDE",
      state: "CA"
    },
    {
      city: "PALOMA",
      state: "IL"
    },
    {
      city: "PALOMAR MOUNTAIN",
      state: "CA"
    },
    {
      city: "PALOS HEIGHTS",
      state: "IL"
    },
    {
      city: "PALOS HILLS",
      state: "IL"
    },
    {
      city: "PALOS PARK",
      state: "IL"
    },
    {
      city: "PALOS VERDES PENINSULA",
      state: "CA"
    },
    {
      city: "PALOUSE",
      state: "WA"
    },
    {
      city: "PAMPA",
      state: "TX"
    },
    {
      city: "PAMPLICO",
      state: "SC"
    },
    {
      city: "PAMPLIN",
      state: "VA"
    },
    {
      city: "PANA",
      state: "IL"
    },
    {
      city: "PANACA",
      state: "NV"
    },
    {
      city: "PANACEA",
      state: "FL"
    },
    {
      city: "PANAMA",
      state: "IA"
    },
    {
      city: "PANAMA",
      state: "IL"
    },
    {
      city: "PANAMA",
      state: "NE"
    },
    {
      city: "PANAMA",
      state: "NY"
    },
    {
      city: "PANAMA",
      state: "OK"
    },
    {
      city: "PANAMA CITY",
      state: "FL"
    },
    {
      city: "PANAMA CITY BEACH",
      state: "FL"
    },
    {
      city: "PANDORA",
      state: "OH"
    },
    {
      city: "PANDORA",
      state: "TX"
    },
    {
      city: "PANGBURN",
      state: "AR"
    },
    {
      city: "PANGUITCH",
      state: "UT"
    },
    {
      city: "PANHANDLE",
      state: "TX"
    },
    {
      city: "PANNA MARIA",
      state: "TX"
    },
    {
      city: "PANOLA",
      state: "AL"
    },
    {
      city: "PANOLA",
      state: "OK"
    },
    {
      city: "PANOLA",
      state: "TX"
    },
    {
      city: "PANORA",
      state: "IA"
    },
    {
      city: "PANORAMA CITY",
      state: "CA"
    },
    {
      city: "PANSEY",
      state: "AL"
    },
    {
      city: "PANTEGO",
      state: "NC"
    },
    {
      city: "PANTHER",
      state: "WV"
    },
    {
      city: "PANTHER BURN",
      state: "MS"
    },
    {
      city: "PAOLA",
      state: "KS"
    },
    {
      city: "PAOLI",
      state: "CO"
    },
    {
      city: "PAOLI",
      state: "IN"
    },
    {
      city: "PAOLI",
      state: "OK"
    },
    {
      city: "PAOLI",
      state: "PA"
    },
    {
      city: "PAONIA",
      state: "CO"
    },
    {
      city: "PAPAALOA",
      state: "HI"
    },
    {
      city: "PAPAIKOU",
      state: "HI"
    },
    {
      city: "PAPILLION",
      state: "NE"
    },
    {
      city: "PAPINEAU",
      state: "IL"
    },
    {
      city: "PARACHUTE",
      state: "CO"
    },
    {
      city: "PARADIS",
      state: "LA"
    },
    {
      city: "PARADISE",
      state: "CA"
    },
    {
      city: "PARADISE",
      state: "KS"
    },
    {
      city: "PARADISE",
      state: "MI"
    },
    {
      city: "PARADISE",
      state: "MT"
    },
    {
      city: "PARADISE",
      state: "PA"
    },
    {
      city: "PARADISE",
      state: "TX"
    },
    {
      city: "PARADISE",
      state: "UT"
    },
    {
      city: "PARADISE INN",
      state: "WA"
    },
    {
      city: "PARADISE VALLEY",
      state: "AZ"
    },
    {
      city: "PARADISE VALLEY",
      state: "NV"
    },
    {
      city: "PARADOX",
      state: "CO"
    },
    {
      city: "PARADOX",
      state: "NY"
    },
    {
      city: "PARAGON",
      state: "IN"
    },
    {
      city: "PARAGONAH",
      state: "UT"
    },
    {
      city: "PARAGOULD",
      state: "AR"
    },
    {
      city: "PARAMOUNT",
      state: "CA"
    },
    {
      city: "PARAMUS",
      state: "NJ"
    },
    {
      city: "PARCHMAN",
      state: "MS"
    },
    {
      city: "PARDEEVILLE",
      state: "WI"
    },
    {
      city: "PARIS",
      state: "AR"
    },
    {
      city: "PARIS",
      state: "ID"
    },
    {
      city: "PARIS",
      state: "IL"
    },
    {
      city: "PARIS",
      state: "KY"
    },
    {
      city: "PARIS",
      state: "ME"
    },
    {
      city: "PARIS",
      state: "MI"
    },
    {
      city: "PARIS",
      state: "MO"
    },
    {
      city: "PARIS",
      state: "MS"
    },
    {
      city: "PARIS",
      state: "OH"
    },
    {
      city: "PARIS",
      state: "TN"
    },
    {
      city: "PARIS",
      state: "TX"
    },
    {
      city: "PARIS",
      state: "VA"
    },
    {
      city: "PARISH",
      state: "NY"
    },
    {
      city: "PARISHVILLE",
      state: "NY"
    },
    {
      city: "PARK",
      state: "KS"
    },
    {
      city: "PARK CITY",
      state: "KY"
    },
    {
      city: "PARK CITY",
      state: "MT"
    },
    {
      city: "PARK CITY",
      state: "UT"
    },
    {
      city: "PARK FALLS",
      state: "WI"
    },
    {
      city: "PARK FOREST",
      state: "IL"
    },
    {
      city: "PARK HALL",
      state: "MD"
    },
    {
      city: "PARK HILL",
      state: "OK"
    },
    {
      city: "PARK HILLS",
      state: "MO"
    },
    {
      city: "PARK RAPIDS",
      state: "MN"
    },
    {
      city: "PARK RIDGE",
      state: "IL"
    },
    {
      city: "PARK RIDGE",
      state: "NJ"
    },
    {
      city: "PARK RIVER",
      state: "ND"
    },
    {
      city: "PARK VALLEY",
      state: "UT"
    },
    {
      city: "PARKDALE",
      state: "AR"
    },
    {
      city: "PARKER",
      state: "AZ"
    },
    {
      city: "PARKER",
      state: "CO"
    },
    {
      city: "PARKER",
      state: "ID"
    },
    {
      city: "PARKER",
      state: "KS"
    },
    {
      city: "PARKER",
      state: "PA"
    },
    {
      city: "PARKER",
      state: "SD"
    },
    {
      city: "PARKER",
      state: "WA"
    },
    {
      city: "PARKER CITY",
      state: "IN"
    },
    {
      city: "PARKER DAM",
      state: "CA"
    },
    {
      city: "PARKER FORD",
      state: "PA"
    },
    {
      city: "PARKERS LAKE",
      state: "KY"
    },
    {
      city: "PARKERS PRAIRIE",
      state: "MN"
    },
    {
      city: "PARKERSBURG",
      state: "IA"
    },
    {
      city: "PARKERSBURG",
      state: "IL"
    },
    {
      city: "PARKERSBURG",
      state: "WV"
    },
    {
      city: "PARKESBURG",
      state: "PA"
    },
    {
      city: "PARKHILL",
      state: "PA"
    },
    {
      city: "PARKIN",
      state: "AR"
    },
    {
      city: "PARKMAN",
      state: "OH"
    },
    {
      city: "PARKMAN",
      state: "WY"
    },
    {
      city: "PARKS",
      state: "AR"
    },
    {
      city: "PARKS",
      state: "AZ"
    },
    {
      city: "PARKSLEY",
      state: "VA"
    },
    {
      city: "PARKSTON",
      state: "SD"
    },
    {
      city: "PARKSVILLE",
      state: "KY"
    },
    {
      city: "PARKSVILLE",
      state: "NY"
    },
    {
      city: "PARKTON",
      state: "MD"
    },
    {
      city: "PARKTON",
      state: "NC"
    },
    {
      city: "PARKVILLE",
      state: "MD"
    },
    {
      city: "PARLIER",
      state: "CA"
    },
    {
      city: "PARLIN",
      state: "CO"
    },
    {
      city: "PARLIN",
      state: "NJ"
    },
    {
      city: "PARMA",
      state: "ID"
    },
    {
      city: "PARMA",
      state: "MI"
    },
    {
      city: "PARMA",
      state: "MO"
    },
    {
      city: "PARNELL",
      state: "IA"
    },
    {
      city: "PARNELL",
      state: "MO"
    },
    {
      city: "PARON",
      state: "AR"
    },
    {
      city: "PAROWAN",
      state: "UT"
    },
    {
      city: "PARRIS ISLAND",
      state: "SC"
    },
    {
      city: "PARRISH",
      state: "AL"
    },
    {
      city: "PARRISH",
      state: "FL"
    },
    {
      city: "PARROTT",
      state: "GA"
    },
    {
      city: "PARROTT",
      state: "VA"
    },
    {
      city: "PARROTTSVILLE",
      state: "TN"
    },
    {
      city: "PARRYVILLE",
      state: "PA"
    },
    {
      city: "PARSHALL",
      state: "CO"
    },
    {
      city: "PARSHALL",
      state: "ND"
    },
    {
      city: "PARSIPPANY",
      state: "NJ"
    },
    {
      city: "PARSONS",
      state: "KS"
    },
    {
      city: "PARSONS",
      state: "TN"
    },
    {
      city: "PARSONS",
      state: "WV"
    },
    {
      city: "PARSONSBURG",
      state: "MD"
    },
    {
      city: "PARSONSFIELD",
      state: "ME"
    },
    {
      city: "PARTHENON",
      state: "AR"
    },
    {
      city: "PARTLOW",
      state: "VA"
    },
    {
      city: "PARTRIDGE",
      state: "KS"
    },
    {
      city: "PARTRIDGE",
      state: "KY"
    },
    {
      city: "PASADENA",
      state: "CA"
    },
    {
      city: "PASADENA",
      state: "MD"
    },
    {
      city: "PASADENA",
      state: "TX"
    },
    {
      city: "PASCAGOULA",
      state: "MS"
    },
    {
      city: "PASCO",
      state: "WA"
    },
    {
      city: "PASCOAG",
      state: "RI"
    },
    {
      city: "PASKENTA",
      state: "CA"
    },
    {
      city: "PASO ROBLES",
      state: "CA"
    },
    {
      city: "PASS CHRISTIAN",
      state: "MS"
    },
    {
      city: "PASSADUMKEAG",
      state: "ME"
    },
    {
      city: "PASSAIC",
      state: "NJ"
    },
    {
      city: "PASSUMPSIC",
      state: "VT"
    },
    {
      city: "PATAGONIA",
      state: "AZ"
    },
    {
      city: "PATASKALA",
      state: "OH"
    },
    {
      city: "PATCH GROVE",
      state: "WI"
    },
    {
      city: "PATCHOGUE",
      state: "NY"
    },
    {
      city: "PATEROS",
      state: "WA"
    },
    {
      city: "PATERSON",
      state: "NJ"
    },
    {
      city: "PATERSON",
      state: "WA"
    },
    {
      city: "PATHFORK",
      state: "KY"
    },
    {
      city: "PATOKA",
      state: "IL"
    },
    {
      city: "PATOKA",
      state: "IN"
    },
    {
      city: "PATON",
      state: "IA"
    },
    {
      city: "PATRICK",
      state: "SC"
    },
    {
      city: "PATRICK AFB",
      state: "FL"
    },
    {
      city: "PATRICK SPRINGS",
      state: "VA"
    },
    {
      city: "PATRIOT",
      state: "IN"
    },
    {
      city: "PATRIOT",
      state: "OH"
    },
    {
      city: "PATTEN",
      state: "ME"
    },
    {
      city: "PATTERSON",
      state: "AR"
    },
    {
      city: "PATTERSON",
      state: "CA"
    },
    {
      city: "PATTERSON",
      state: "GA"
    },
    {
      city: "PATTERSON",
      state: "IA"
    },
    {
      city: "PATTERSON",
      state: "IL"
    },
    {
      city: "PATTERSON",
      state: "LA"
    },
    {
      city: "PATTERSON",
      state: "MO"
    },
    {
      city: "PATTERSON",
      state: "NC"
    },
    {
      city: "PATTERSON",
      state: "NY"
    },
    {
      city: "PATTERSONVILLE",
      state: "NY"
    },
    {
      city: "PATTISON",
      state: "MS"
    },
    {
      city: "PATTISON",
      state: "TX"
    },
    {
      city: "PATTON",
      state: "CA"
    },
    {
      city: "PATTON",
      state: "MO"
    },
    {
      city: "PATTON",
      state: "PA"
    },
    {
      city: "PATTONSBURG",
      state: "MO"
    },
    {
      city: "PATTONVILLE",
      state: "TX"
    },
    {
      city: "PATUXENT RIVER",
      state: "MD"
    },
    {
      city: "PAUL",
      state: "ID"
    },
    {
      city: "PAUL SMITHS",
      state: "NY"
    },
    {
      city: "PAULDEN",
      state: "AZ"
    },
    {
      city: "PAULDING",
      state: "MS"
    },
    {
      city: "PAULDING",
      state: "OH"
    },
    {
      city: "PAULINA",
      state: "LA"
    },
    {
      city: "PAULINE",
      state: "SC"
    },
    {
      city: "PAULLINA",
      state: "IA"
    },
    {
      city: "PAULS VALLEY",
      state: "OK"
    },
    {
      city: "PAULSBORO",
      state: "NJ"
    },
    {
      city: "PAUMA VALLEY",
      state: "CA"
    },
    {
      city: "PAUPACK",
      state: "PA"
    },
    {
      city: "PAVILION",
      state: "NY"
    },
    {
      city: "PAVILLION",
      state: "WY"
    },
    {
      city: "PAVO",
      state: "GA"
    },
    {
      city: "PAW CREEK",
      state: "NC"
    },
    {
      city: "PAW PAW",
      state: "IL"
    },
    {
      city: "PAW PAW",
      state: "MI"
    },
    {
      city: "PAW PAW",
      state: "WV"
    },
    {
      city: "PAWCATUCK",
      state: "CT"
    },
    {
      city: "PAWHUSKA",
      state: "OK"
    },
    {
      city: "PAWLET",
      state: "VT"
    },
    {
      city: "PAWLEYS ISLAND",
      state: "SC"
    },
    {
      city: "PAWLING",
      state: "NY"
    },
    {
      city: "PAWNEE",
      state: "IL"
    },
    {
      city: "PAWNEE",
      state: "OK"
    },
    {
      city: "PAWNEE",
      state: "TX"
    },
    {
      city: "PAWNEE CITY",
      state: "NE"
    },
    {
      city: "PAWNEE ROCK",
      state: "KS"
    },
    {
      city: "PAWTUCKET",
      state: "RI"
    },
    {
      city: "PAX",
      state: "WV"
    },
    {
      city: "PAXICO",
      state: "KS"
    },
    {
      city: "PAXINOS",
      state: "PA"
    },
    {
      city: "PAXTON",
      state: "FL"
    },
    {
      city: "PAXTON",
      state: "IL"
    },
    {
      city: "PAXTON",
      state: "MA"
    },
    {
      city: "PAXTON",
      state: "NE"
    },
    {
      city: "PAXTONVILLE",
      state: "PA"
    },
    {
      city: "PAYETTE",
      state: "ID"
    },
    {
      city: "PAYNE",
      state: "OH"
    },
    {
      city: "PAYNESVILLE",
      state: "MN"
    },
    {
      city: "PAYNESVILLE",
      state: "WV"
    },
    {
      city: "PAYNEVILLE",
      state: "KY"
    },
    {
      city: "PAYSON",
      state: "AZ"
    },
    {
      city: "PAYSON",
      state: "IL"
    },
    {
      city: "PAYSON",
      state: "UT"
    },
    {
      city: "PE ELL",
      state: "WA"
    },
    {
      city: "PEA RIDGE",
      state: "AR"
    },
    {
      city: "PEABODY",
      state: "KS"
    },
    {
      city: "PEABODY",
      state: "MA"
    },
    {
      city: "PEACE DALE",
      state: "RI"
    },
    {
      city: "PEACE VALLEY",
      state: "MO"
    },
    {
      city: "PEACH BOTTOM",
      state: "PA"
    },
    {
      city: "PEACH CREEK",
      state: "WV"
    },
    {
      city: "PEACH GLEN",
      state: "PA"
    },
    {
      city: "PEACH ORCHARD",
      state: "AR"
    },
    {
      city: "PEACH SPRINGS",
      state: "AZ"
    },
    {
      city: "PEACHAM",
      state: "VT"
    },
    {
      city: "PEACHLAND",
      state: "NC"
    },
    {
      city: "PEACHTREE CITY",
      state: "GA"
    },
    {
      city: "PEACHTREE CORNERS",
      state: "GA"
    },
    {
      city: "PEAK",
      state: "SC"
    },
    {
      city: "PEAKS ISLAND",
      state: "ME"
    },
    {
      city: "PEAPACK",
      state: "NJ"
    },
    {
      city: "PEARBLOSSOM",
      state: "CA"
    },
    {
      city: "PEARCE",
      state: "AZ"
    },
    {
      city: "PEARCY",
      state: "AR"
    },
    {
      city: "PEARISBURG",
      state: "VA"
    },
    {
      city: "PEARL",
      state: "IL"
    },
    {
      city: "PEARL",
      state: "MS"
    },
    {
      city: "PEARL CITY",
      state: "HI"
    },
    {
      city: "PEARL CITY",
      state: "IL"
    },
    {
      city: "PEARL RIVER",
      state: "LA"
    },
    {
      city: "PEARL RIVER",
      state: "NY"
    },
    {
      city: "PEARLAND",
      state: "TX"
    },
    {
      city: "PEARLINGTON",
      state: "MS"
    },
    {
      city: "PEARSALL",
      state: "TX"
    },
    {
      city: "PEARSON",
      state: "GA"
    },
    {
      city: "PEARSON",
      state: "WI"
    },
    {
      city: "PEASE",
      state: "MN"
    },
    {
      city: "PEASTER",
      state: "TX"
    },
    {
      city: "PEBBLE BEACH",
      state: "CA"
    },
    {
      city: "PECAN GAP",
      state: "TX"
    },
    {
      city: "PECATONICA",
      state: "IL"
    },
    {
      city: "PECK",
      state: "ID"
    },
    {
      city: "PECK",
      state: "KS"
    },
    {
      city: "PECK",
      state: "MI"
    },
    {
      city: "PECKS MILL",
      state: "WV"
    },
    {
      city: "PECKVILLE",
      state: "PA"
    },
    {
      city: "PECONIC",
      state: "NY"
    },
    {
      city: "PECOS",
      state: "NM"
    },
    {
      city: "PECOS",
      state: "TX"
    },
    {
      city: "PECULIAR",
      state: "MO"
    },
    {
      city: "PEDRICKTOWN",
      state: "NJ"
    },
    {
      city: "PEDRO",
      state: "OH"
    },
    {
      city: "PEDRO BAY",
      state: "AK"
    },
    {
      city: "PEEBLES",
      state: "OH"
    },
    {
      city: "PEEKSKILL",
      state: "NY"
    },
    {
      city: "PEEL",
      state: "AR"
    },
    {
      city: "PEERLESS",
      state: "MT"
    },
    {
      city: "PEETZ",
      state: "CO"
    },
    {
      city: "PEEVER",
      state: "SD"
    },
    {
      city: "PEGGS",
      state: "OK"
    },
    {
      city: "PEGGY",
      state: "TX"
    },
    {
      city: "PEGRAM",
      state: "TN"
    },
    {
      city: "PEKIN",
      state: "IL"
    },
    {
      city: "PEKIN",
      state: "IN"
    },
    {
      city: "PEKIN",
      state: "ND"
    },
    {
      city: "PELAHATCHIE",
      state: "MS"
    },
    {
      city: "PELHAM",
      state: "AL"
    },
    {
      city: "PELHAM",
      state: "GA"
    },
    {
      city: "PELHAM",
      state: "NC"
    },
    {
      city: "PELHAM",
      state: "NH"
    },
    {
      city: "PELHAM",
      state: "NY"
    },
    {
      city: "PELHAM",
      state: "TN"
    },
    {
      city: "PELICAN",
      state: "AK"
    },
    {
      city: "PELICAN",
      state: "LA"
    },
    {
      city: "PELICAN LAKE",
      state: "WI"
    },
    {
      city: "PELICAN RAPIDS",
      state: "MN"
    },
    {
      city: "PELION",
      state: "SC"
    },
    {
      city: "PELKIE",
      state: "MI"
    },
    {
      city: "PELL CITY",
      state: "AL"
    },
    {
      city: "PELL LAKE",
      state: "WI"
    },
    {
      city: "PELLA",
      state: "IA"
    },
    {
      city: "PELLSTON",
      state: "MI"
    },
    {
      city: "PELSOR",
      state: "AR"
    },
    {
      city: "PELZER",
      state: "SC"
    },
    {
      city: "PEMAQUID",
      state: "ME"
    },
    {
      city: "PEMBERTON",
      state: "MN"
    },
    {
      city: "PEMBERTON",
      state: "NJ"
    },
    {
      city: "PEMBERTON",
      state: "OH"
    },
    {
      city: "PEMBERVILLE",
      state: "OH"
    },
    {
      city: "PEMBINA",
      state: "ND"
    },
    {
      city: "PEMBINE",
      state: "WI"
    },
    {
      city: "PEMBROKE",
      state: "GA"
    },
    {
      city: "PEMBROKE",
      state: "KY"
    },
    {
      city: "PEMBROKE",
      state: "MA"
    },
    {
      city: "PEMBROKE",
      state: "ME"
    },
    {
      city: "PEMBROKE",
      state: "NC"
    },
    {
      city: "PEMBROKE",
      state: "VA"
    },
    {
      city: "PEMBROKE PINES",
      state: "FL"
    },
    {
      city: "PEMBROKE TOWNSHIP",
      state: "IL"
    },
    {
      city: "PEN ARGYL",
      state: "PA"
    },
    {
      city: "PENA BLANCA",
      state: "NM"
    },
    {
      city: "PENASCO",
      state: "NM"
    },
    {
      city: "PENCE SPRINGS",
      state: "WV"
    },
    {
      city: "PENCIL BLUFF",
      state: "AR"
    },
    {
      city: "PENDER",
      state: "NE"
    },
    {
      city: "PENDERGRASS",
      state: "GA"
    },
    {
      city: "PENDLETON",
      state: "IN"
    },
    {
      city: "PENDLETON",
      state: "KY"
    },
    {
      city: "PENDLETON",
      state: "NC"
    },
    {
      city: "PENDLETON",
      state: "OR"
    },
    {
      city: "PENDLETON",
      state: "SC"
    },
    {
      city: "PENDLETON",
      state: "TX"
    },
    {
      city: "PENDROY",
      state: "MT"
    },
    {
      city: "PENELOPE",
      state: "TX"
    },
    {
      city: "PENFIELD",
      state: "IL"
    },
    {
      city: "PENFIELD",
      state: "NY"
    },
    {
      city: "PENFIELD",
      state: "PA"
    },
    {
      city: "PENGILLY",
      state: "MN"
    },
    {
      city: "PENHOOK",
      state: "VA"
    },
    {
      city: "PENINSULA",
      state: "OH"
    },
    {
      city: "PENITAS",
      state: "TX"
    },
    {
      city: "PENLAND",
      state: "NC"
    },
    {
      city: "PENN",
      state: "ND"
    },
    {
      city: "PENN",
      state: "PA"
    },
    {
      city: "PENN LAIRD",
      state: "VA"
    },
    {
      city: "PENN RUN",
      state: "PA"
    },
    {
      city: "PENN VALLEY",
      state: "CA"
    },
    {
      city: "PENN YAN",
      state: "NY"
    },
    {
      city: "PENNELLVILLE",
      state: "NY"
    },
    {
      city: "PENNEY FARMS",
      state: "FL"
    },
    {
      city: "PENNGROVE",
      state: "CA"
    },
    {
      city: "PENNINGTON",
      state: "AL"
    },
    {
      city: "PENNINGTON",
      state: "MN"
    },
    {
      city: "PENNINGTON",
      state: "NJ"
    },
    {
      city: "PENNINGTON",
      state: "TX"
    },
    {
      city: "PENNINGTON GAP",
      state: "VA"
    },
    {
      city: "PENNOCK",
      state: "MN"
    },
    {
      city: "PENNS CREEK",
      state: "PA"
    },
    {
      city: "PENNS GROVE",
      state: "NJ"
    },
    {
      city: "PENNS PARK",
      state: "PA"
    },
    {
      city: "PENNSAUKEN",
      state: "NJ"
    },
    {
      city: "PENNSBORO",
      state: "WV"
    },
    {
      city: "PENNSBURG",
      state: "PA"
    },
    {
      city: "PENNSVILLE",
      state: "NJ"
    },
    {
      city: "PENNSYLVANIA FURNACE",
      state: "PA"
    },
    {
      city: "PENNVILLE",
      state: "IN"
    },
    {
      city: "PENOBSCOT",
      state: "ME"
    },
    {
      city: "PENOKEE",
      state: "KS"
    },
    {
      city: "PENROSE",
      state: "CO"
    },
    {
      city: "PENROSE",
      state: "NC"
    },
    {
      city: "PENRYN",
      state: "CA"
    },
    {
      city: "PENRYN",
      state: "PA"
    },
    {
      city: "PENSACOLA",
      state: "FL"
    },
    {
      city: "PENTRESS",
      state: "WV"
    },
    {
      city: "PENTWATER",
      state: "MI"
    },
    {
      city: "PENWELL",
      state: "TX"
    },
    {
      city: "PEOA",
      state: "UT"
    },
    {
      city: "PEORIA",
      state: "AZ"
    },
    {
      city: "PEORIA",
      state: "IL"
    },
    {
      city: "PEORIA HEIGHTS",
      state: "IL"
    },
    {
      city: "PEOSTA",
      state: "IA"
    },
    {
      city: "PEOTONE",
      state: "IL"
    },
    {
      city: "PEP",
      state: "TX"
    },
    {
      city: "PEPEEKEO",
      state: "HI"
    },
    {
      city: "PEPIN",
      state: "WI"
    },
    {
      city: "PEPPERELL",
      state: "MA"
    },
    {
      city: "PEQUABUCK",
      state: "CT"
    },
    {
      city: "PEQUANNOCK",
      state: "NJ"
    },
    {
      city: "PEQUEA",
      state: "PA"
    },
    {
      city: "PEQUOT LAKES",
      state: "MN"
    },
    {
      city: "PERALTA",
      state: "NM"
    },
    {
      city: "PERCIVAL",
      state: "IA"
    },
    {
      city: "PERCY",
      state: "IL"
    },
    {
      city: "PERDIDO",
      state: "AL"
    },
    {
      city: "PERDUE HILL",
      state: "AL"
    },
    {
      city: "PERHAM",
      state: "ME"
    },
    {
      city: "PERHAM",
      state: "MN"
    },
    {
      city: "PERIDOT",
      state: "AZ"
    },
    {
      city: "PERKASIE",
      state: "PA"
    },
    {
      city: "PERKINS",
      state: "GA"
    },
    {
      city: "PERKINS",
      state: "MI"
    },
    {
      city: "PERKINS",
      state: "MO"
    },
    {
      city: "PERKINS",
      state: "OK"
    },
    {
      city: "PERKINSTON",
      state: "MS"
    },
    {
      city: "PERKINSVILLE",
      state: "NY"
    },
    {
      city: "PERKINSVILLE",
      state: "VT"
    },
    {
      city: "PERKIOMENVILLE",
      state: "PA"
    },
    {
      city: "PERKS",
      state: "IL"
    },
    {
      city: "PERLEY",
      state: "MN"
    },
    {
      city: "PERRIN",
      state: "TX"
    },
    {
      city: "PERRINTON",
      state: "MI"
    },
    {
      city: "PERRIS",
      state: "CA"
    },
    {
      city: "PERRONVILLE",
      state: "MI"
    },
    {
      city: "PERRY",
      state: "AR"
    },
    {
      city: "PERRY",
      state: "FL"
    },
    {
      city: "PERRY",
      state: "GA"
    },
    {
      city: "PERRY",
      state: "IA"
    },
    {
      city: "PERRY",
      state: "IL"
    },
    {
      city: "PERRY",
      state: "KS"
    },
    {
      city: "PERRY",
      state: "LA"
    },
    {
      city: "PERRY",
      state: "ME"
    },
    {
      city: "PERRY",
      state: "MI"
    },
    {
      city: "PERRY",
      state: "MO"
    },
    {
      city: "PERRY",
      state: "NY"
    },
    {
      city: "PERRY",
      state: "OH"
    },
    {
      city: "PERRY",
      state: "OK"
    },
    {
      city: "PERRY HALL",
      state: "MD"
    },
    {
      city: "PERRY PARK",
      state: "KY"
    },
    {
      city: "PERRY POINT",
      state: "MD"
    },
    {
      city: "PERRYMAN",
      state: "MD"
    },
    {
      city: "PERRYOPOLIS",
      state: "PA"
    },
    {
      city: "PERRYSBURG",
      state: "NY"
    },
    {
      city: "PERRYSBURG",
      state: "OH"
    },
    {
      city: "PERRYSVILLE",
      state: "IN"
    },
    {
      city: "PERRYSVILLE",
      state: "OH"
    },
    {
      city: "PERRYTON",
      state: "TX"
    },
    {
      city: "PERRYVILLE",
      state: "AK"
    },
    {
      city: "PERRYVILLE",
      state: "AR"
    },
    {
      city: "PERRYVILLE",
      state: "KY"
    },
    {
      city: "PERRYVILLE",
      state: "MD"
    },
    {
      city: "PERRYVILLE",
      state: "MO"
    },
    {
      city: "PERSHING",
      state: "IN"
    },
    {
      city: "PERSIA",
      state: "IA"
    },
    {
      city: "PERTH",
      state: "ND"
    },
    {
      city: "PERTH AMBOY",
      state: "NJ"
    },
    {
      city: "PERU",
      state: "IA"
    },
    {
      city: "PERU",
      state: "IL"
    },
    {
      city: "PERU",
      state: "IN"
    },
    {
      city: "PERU",
      state: "KS"
    },
    {
      city: "PERU",
      state: "ME"
    },
    {
      city: "PERU",
      state: "NE"
    },
    {
      city: "PERU",
      state: "NY"
    },
    {
      city: "PERU",
      state: "VT"
    },
    {
      city: "PESCADERO",
      state: "CA"
    },
    {
      city: "PESHASTIN",
      state: "WA"
    },
    {
      city: "PESHTIGO",
      state: "WI"
    },
    {
      city: "PESOTUM",
      state: "IL"
    },
    {
      city: "PETAL",
      state: "MS"
    },
    {
      city: "PETALUMA",
      state: "CA"
    },
    {
      city: "PETERBOROUGH",
      state: "NH"
    },
    {
      city: "PETERMAN",
      state: "AL"
    },
    {
      city: "PETERSBURG",
      state: "AK"
    },
    {
      city: "PETERSBURG",
      state: "IL"
    },
    {
      city: "PETERSBURG",
      state: "IN"
    },
    {
      city: "PETERSBURG",
      state: "KY"
    },
    {
      city: "PETERSBURG",
      state: "MI"
    },
    {
      city: "PETERSBURG",
      state: "ND"
    },
    {
      city: "PETERSBURG",
      state: "NE"
    },
    {
      city: "PETERSBURG",
      state: "NY"
    },
    {
      city: "PETERSBURG",
      state: "OH"
    },
    {
      city: "PETERSBURG",
      state: "PA"
    },
    {
      city: "PETERSBURG",
      state: "TN"
    },
    {
      city: "PETERSBURG",
      state: "TX"
    },
    {
      city: "PETERSBURG",
      state: "VA"
    },
    {
      city: "PETERSBURG",
      state: "WV"
    },
    {
      city: "PETERSHAM",
      state: "MA"
    },
    {
      city: "PETERSON",
      state: "AL"
    },
    {
      city: "PETERSON",
      state: "IA"
    },
    {
      city: "PETERSON",
      state: "MN"
    },
    {
      city: "PETERSTOWN",
      state: "WV"
    },
    {
      city: "PETOSKEY",
      state: "MI"
    },
    {
      city: "PETRIFIED FOREST NATL PK",
      state: "AZ"
    },
    {
      city: "PETROLEUM",
      state: "IN"
    },
    {
      city: "PETROLEUM",
      state: "WV"
    },
    {
      city: "PETROLIA",
      state: "CA"
    },
    {
      city: "PETROLIA",
      state: "PA"
    },
    {
      city: "PETROLIA",
      state: "TX"
    },
    {
      city: "PETROS",
      state: "TN"
    },
    {
      city: "PETTIBONE",
      state: "ND"
    },
    {
      city: "PETTIGREW",
      state: "AR"
    },
    {
      city: "PETTISVILLE",
      state: "OH"
    },
    {
      city: "PETTUS",
      state: "TX"
    },
    {
      city: "PETTY",
      state: "TX"
    },
    {
      city: "PEVELY",
      state: "MO"
    },
    {
      city: "PEWAMO",
      state: "MI"
    },
    {
      city: "PEWAUKEE",
      state: "WI"
    },
    {
      city: "PEWEE VALLEY",
      state: "KY"
    },
    {
      city: "PEYTON",
      state: "CO"
    },
    {
      city: "PEYTONA",
      state: "WV"
    },
    {
      city: "PFAFFTOWN",
      state: "NC"
    },
    {
      city: "PFEIFER",
      state: "KS"
    },
    {
      city: "PFLUGERVILLE",
      state: "TX"
    },
    {
      city: "PHARR",
      state: "TX"
    },
    {
      city: "PHEBA",
      state: "MS"
    },
    {
      city: "PHELAN",
      state: "CA"
    },
    {
      city: "PHELPS",
      state: "KY"
    },
    {
      city: "PHELPS",
      state: "NY"
    },
    {
      city: "PHELPS",
      state: "WI"
    },
    {
      city: "PHENIX",
      state: "VA"
    },
    {
      city: "PHENIX CITY",
      state: "AL"
    },
    {
      city: "PHIL CAMPBELL",
      state: "AL"
    },
    {
      city: "PHILADELPHIA",
      state: "MO"
    },
    {
      city: "PHILADELPHIA",
      state: "MS"
    },
    {
      city: "PHILADELPHIA",
      state: "NY"
    },
    {
      city: "PHILADELPHIA",
      state: "PA"
    },
    {
      city: "PHILADELPHIA",
      state: "TN"
    },
    {
      city: "PHILIP",
      state: "SD"
    },
    {
      city: "PHILIPP",
      state: "MS"
    },
    {
      city: "PHILIPPI",
      state: "WV"
    },
    {
      city: "PHILIPSBURG",
      state: "MT"
    },
    {
      city: "PHILIPSBURG",
      state: "PA"
    },
    {
      city: "PHILLIPS",
      state: "ME"
    },
    {
      city: "PHILLIPS",
      state: "NE"
    },
    {
      city: "PHILLIPS",
      state: "WI"
    },
    {
      city: "PHILLIPSBURG",
      state: "KS"
    },
    {
      city: "PHILLIPSBURG",
      state: "MO"
    },
    {
      city: "PHILLIPSBURG",
      state: "NJ"
    },
    {
      city: "PHILLIPSBURG",
      state: "OH"
    },
    {
      city: "PHILLIPSPORT",
      state: "NY"
    },
    {
      city: "PHILLIPSVILLE",
      state: "CA"
    },
    {
      city: "PHILMONT",
      state: "NY"
    },
    {
      city: "PHILO",
      state: "CA"
    },
    {
      city: "PHILO",
      state: "IL"
    },
    {
      city: "PHILO",
      state: "OH"
    },
    {
      city: "PHILOMATH",
      state: "OR"
    },
    {
      city: "PHILOMONT",
      state: "VA"
    },
    {
      city: "PHILPOT",
      state: "KY"
    },
    {
      city: "PHIPPSBURG",
      state: "CO"
    },
    {
      city: "PHIPPSBURG",
      state: "ME"
    },
    {
      city: "PHLOX",
      state: "WI"
    },
    {
      city: "PHOENICIA",
      state: "NY"
    },
    {
      city: "PHOENIX",
      state: "AZ"
    },
    {
      city: "PHOENIX",
      state: "MD"
    },
    {
      city: "PHOENIX",
      state: "NY"
    },
    {
      city: "PHOENIX",
      state: "OR"
    },
    {
      city: "PHOENIXVILLE",
      state: "PA"
    },
    {
      city: "PHYLLIS",
      state: "KY"
    },
    {
      city: "PIASA",
      state: "IL"
    },
    {
      city: "PICABO",
      state: "ID"
    },
    {
      city: "PICACHO",
      state: "AZ"
    },
    {
      city: "PICACHO",
      state: "NM"
    },
    {
      city: "PICATINNY ARSENAL",
      state: "NJ"
    },
    {
      city: "PICAYUNE",
      state: "MS"
    },
    {
      city: "PICHER",
      state: "OK"
    },
    {
      city: "PICKENS",
      state: "AR"
    },
    {
      city: "PICKENS",
      state: "MS"
    },
    {
      city: "PICKENS",
      state: "SC"
    },
    {
      city: "PICKEREL",
      state: "WI"
    },
    {
      city: "PICKERING",
      state: "MO"
    },
    {
      city: "PICKERINGTON",
      state: "OH"
    },
    {
      city: "PICKETT",
      state: "WI"
    },
    {
      city: "PICKFORD",
      state: "MI"
    },
    {
      city: "PICKRELL",
      state: "NE"
    },
    {
      city: "PICKSTOWN",
      state: "SD"
    },
    {
      city: "PICKTON",
      state: "TX"
    },
    {
      city: "PICKWICK DAM",
      state: "TN"
    },
    {
      city: "PICO RIVERA",
      state: "CA"
    },
    {
      city: "PICTURE ROCKS",
      state: "PA"
    },
    {
      city: "PIE TOWN",
      state: "NM"
    },
    {
      city: "PIEDMONT",
      state: "AL"
    },
    {
      city: "PIEDMONT",
      state: "CA"
    },
    {
      city: "PIEDMONT",
      state: "MO"
    },
    {
      city: "PIEDMONT",
      state: "OH"
    },
    {
      city: "PIEDMONT",
      state: "OK"
    },
    {
      city: "PIEDMONT",
      state: "SC"
    },
    {
      city: "PIEDMONT",
      state: "SD"
    },
    {
      city: "PIEDMONT",
      state: "WV"
    },
    {
      city: "PIEDRA",
      state: "CA"
    },
    {
      city: "PIERCE",
      state: "CO"
    },
    {
      city: "PIERCE",
      state: "ID"
    },
    {
      city: "PIERCE",
      state: "NE"
    },
    {
      city: "PIERCE",
      state: "TX"
    },
    {
      city: "PIERCE CITY",
      state: "MO"
    },
    {
      city: "PIERCEFIELD",
      state: "NY"
    },
    {
      city: "PIERCETON",
      state: "IN"
    },
    {
      city: "PIERCEVILLE",
      state: "IN"
    },
    {
      city: "PIERCEVILLE",
      state: "KS"
    },
    {
      city: "PIERCY",
      state: "CA"
    },
    {
      city: "PIERMONT",
      state: "NH"
    },
    {
      city: "PIERMONT",
      state: "NY"
    },
    {
      city: "PIERPONT",
      state: "OH"
    },
    {
      city: "PIERPONT",
      state: "SD"
    },
    {
      city: "PIERRE",
      state: "SD"
    },
    {
      city: "PIERRE PART",
      state: "LA"
    },
    {
      city: "PIERREPONT MANOR",
      state: "NY"
    },
    {
      city: "PIERRON",
      state: "IL"
    },
    {
      city: "PIERSON",
      state: "FL"
    },
    {
      city: "PIERSON",
      state: "IA"
    },
    {
      city: "PIERSON",
      state: "MI"
    },
    {
      city: "PIERZ",
      state: "MN"
    },
    {
      city: "PIFFARD",
      state: "NY"
    },
    {
      city: "PIGEON",
      state: "MI"
    },
    {
      city: "PIGEON FALLS",
      state: "WI"
    },
    {
      city: "PIGEON FORGE",
      state: "TN"
    },
    {
      city: "PIGGOTT",
      state: "AR"
    },
    {
      city: "PIKE",
      state: "NH"
    },
    {
      city: "PIKE",
      state: "NY"
    },
    {
      city: "PIKE ROAD",
      state: "AL"
    },
    {
      city: "PIKESVILLE",
      state: "MD"
    },
    {
      city: "PIKETON",
      state: "OH"
    },
    {
      city: "PIKEVILLE",
      state: "KY"
    },
    {
      city: "PIKEVILLE",
      state: "NC"
    },
    {
      city: "PIKEVILLE",
      state: "TN"
    },
    {
      city: "PILGER",
      state: "NE"
    },
    {
      city: "PILGRIM",
      state: "KY"
    },
    {
      city: "PILGRIMS KNOB",
      state: "VA"
    },
    {
      city: "PILLAGER",
      state: "MN"
    },
    {
      city: "PILLOW",
      state: "PA"
    },
    {
      city: "PILLSBURY",
      state: "ND"
    },
    {
      city: "PILOT",
      state: "VA"
    },
    {
      city: "PILOT GROVE",
      state: "IA"
    },
    {
      city: "PILOT GROVE",
      state: "MO"
    },
    {
      city: "PILOT HILL",
      state: "CA"
    },
    {
      city: "PILOT KNOB",
      state: "MO"
    },
    {
      city: "PILOT MOUND",
      state: "IA"
    },
    {
      city: "PILOT MOUNTAIN",
      state: "NC"
    },
    {
      city: "PILOT POINT",
      state: "AK"
    },
    {
      city: "PILOT POINT",
      state: "TX"
    },
    {
      city: "PILOT ROCK",
      state: "OR"
    },
    {
      city: "PILOT STATION",
      state: "AK"
    },
    {
      city: "PILOTTOWN",
      state: "LA"
    },
    {
      city: "PIMA",
      state: "AZ"
    },
    {
      city: "PIMENTO",
      state: "IN"
    },
    {
      city: "PINCH",
      state: "WV"
    },
    {
      city: "PINCKARD",
      state: "AL"
    },
    {
      city: "PINCKNEY",
      state: "MI"
    },
    {
      city: "PINCKNEYVILLE",
      state: "IL"
    },
    {
      city: "PINCONNING",
      state: "MI"
    },
    {
      city: "PINDALL",
      state: "AR"
    },
    {
      city: "PINE",
      state: "AZ"
    },
    {
      city: "PINE",
      state: "CO"
    },
    {
      city: "PINE APPLE",
      state: "AL"
    },
    {
      city: "PINE BEACH",
      state: "NJ"
    },
    {
      city: "PINE BLUFF",
      state: "AR"
    },
    {
      city: "PINE BLUFFS",
      state: "WY"
    },
    {
      city: "PINE BROOK",
      state: "NJ"
    },
    {
      city: "PINE BUSH",
      state: "NY"
    },
    {
      city: "PINE CITY",
      state: "MN"
    },
    {
      city: "PINE CITY",
      state: "NY"
    },
    {
      city: "PINE FORGE",
      state: "PA"
    },
    {
      city: "PINE GROVE",
      state: "CA"
    },
    {
      city: "PINE GROVE",
      state: "LA"
    },
    {
      city: "PINE GROVE",
      state: "PA"
    },
    {
      city: "PINE GROVE",
      state: "WV"
    },
    {
      city: "PINE GROVE MILLS",
      state: "PA"
    },
    {
      city: "PINE HALL",
      state: "NC"
    },
    {
      city: "PINE HILL",
      state: "AL"
    },
    {
      city: "PINE HILL",
      state: "NY"
    },
    {
      city: "PINE ISLAND",
      state: "MN"
    },
    {
      city: "PINE ISLAND",
      state: "NY"
    },
    {
      city: "PINE KNOT",
      state: "KY"
    },
    {
      city: "PINE LAKE",
      state: "GA"
    },
    {
      city: "PINE LEVEL",
      state: "AL"
    },
    {
      city: "PINE LEVEL",
      state: "NC"
    },
    {
      city: "PINE MEADOW",
      state: "CT"
    },
    {
      city: "PINE MOUNTAIN",
      state: "GA"
    },
    {
      city: "PINE MOUNTAIN CLUB",
      state: "CA"
    },
    {
      city: "PINE MOUNTAIN VALLEY",
      state: "GA"
    },
    {
      city: "PINE PLAINS",
      state: "NY"
    },
    {
      city: "PINE PRAIRIE",
      state: "LA"
    },
    {
      city: "PINE RIDGE",
      state: "KY"
    },
    {
      city: "PINE RIDGE",
      state: "SD"
    },
    {
      city: "PINE RIVER",
      state: "MN"
    },
    {
      city: "PINE RIVER",
      state: "WI"
    },
    {
      city: "PINE TOP",
      state: "KY"
    },
    {
      city: "PINE VALLEY",
      state: "CA"
    },
    {
      city: "PINE VALLEY",
      state: "NY"
    },
    {
      city: "PINE VALLEY",
      state: "UT"
    },
    {
      city: "PINE VILLAGE",
      state: "IN"
    },
    {
      city: "PINEBLUFF",
      state: "NC"
    },
    {
      city: "PINECLIFFE",
      state: "CO"
    },
    {
      city: "PINECREST",
      state: "CA"
    },
    {
      city: "PINEDALE",
      state: "AZ"
    },
    {
      city: "PINEDALE",
      state: "WY"
    },
    {
      city: "PINEHILL",
      state: "NM"
    },
    {
      city: "PINEHURST",
      state: "GA"
    },
    {
      city: "PINEHURST",
      state: "ID"
    },
    {
      city: "PINEHURST",
      state: "MA"
    },
    {
      city: "PINEHURST",
      state: "NC"
    },
    {
      city: "PINEHURST",
      state: "TX"
    },
    {
      city: "PINELAND",
      state: "FL"
    },
    {
      city: "PINELAND",
      state: "SC"
    },
    {
      city: "PINELAND",
      state: "TX"
    },
    {
      city: "PINELLAS PARK",
      state: "FL"
    },
    {
      city: "PINEOLA",
      state: "NC"
    },
    {
      city: "PINESDALE",
      state: "MT"
    },
    {
      city: "PINETOP",
      state: "AZ"
    },
    {
      city: "PINETOPS",
      state: "NC"
    },
    {
      city: "PINETOWN",
      state: "NC"
    },
    {
      city: "PINETTA",
      state: "FL"
    },
    {
      city: "PINEVIEW",
      state: "GA"
    },
    {
      city: "PINEVILLE",
      state: "AR"
    },
    {
      city: "PINEVILLE",
      state: "KY"
    },
    {
      city: "PINEVILLE",
      state: "LA"
    },
    {
      city: "PINEVILLE",
      state: "MO"
    },
    {
      city: "PINEVILLE",
      state: "NC"
    },
    {
      city: "PINEVILLE",
      state: "PA"
    },
    {
      city: "PINEVILLE",
      state: "SC"
    },
    {
      city: "PINEVILLE",
      state: "WV"
    },
    {
      city: "PINEWOOD",
      state: "SC"
    },
    {
      city: "PINEY CREEK",
      state: "NC"
    },
    {
      city: "PINEY FLATS",
      state: "TN"
    },
    {
      city: "PINEY POINT",
      state: "MD"
    },
    {
      city: "PINEY RIVER",
      state: "VA"
    },
    {
      city: "PINEY VIEW",
      state: "WV"
    },
    {
      city: "PINEY WOODS",
      state: "MS"
    },
    {
      city: "PINGREE",
      state: "ID"
    },
    {
      city: "PINGREE",
      state: "ND"
    },
    {
      city: "PINK HILL",
      state: "NC"
    },
    {
      city: "PINNACLE",
      state: "NC"
    },
    {
      city: "PINOLA",
      state: "MS"
    },
    {
      city: "PINOLE",
      state: "CA"
    },
    {
      city: "PINON",
      state: "AZ"
    },
    {
      city: "PINON",
      state: "NM"
    },
    {
      city: "PINON HILLS",
      state: "CA"
    },
    {
      city: "PINOPOLIS",
      state: "SC"
    },
    {
      city: "PINOS ALTOS",
      state: "NM"
    },
    {
      city: "PINSON",
      state: "AL"
    },
    {
      city: "PINSON",
      state: "TN"
    },
    {
      city: "PINTO",
      state: "MD"
    },
    {
      city: "PIOCHE",
      state: "NV"
    },
    {
      city: "PIONEER",
      state: "CA"
    },
    {
      city: "PIONEER",
      state: "LA"
    },
    {
      city: "PIONEER",
      state: "OH"
    },
    {
      city: "PIONEER",
      state: "TN"
    },
    {
      city: "PIONEERTOWN",
      state: "CA"
    },
    {
      city: "PIPE CREEK",
      state: "TX"
    },
    {
      city: "PIPER CITY",
      state: "IL"
    },
    {
      city: "PIPERSVILLE",
      state: "PA"
    },
    {
      city: "PIPESTEM",
      state: "WV"
    },
    {
      city: "PIPESTONE",
      state: "MN"
    },
    {
      city: "PIPPA PASSES",
      state: "KY"
    },
    {
      city: "PIQUA",
      state: "KS"
    },
    {
      city: "PIQUA",
      state: "OH"
    },
    {
      city: "PIRTLEVILLE",
      state: "AZ"
    },
    {
      city: "PIRU",
      state: "CA"
    },
    {
      city: "PISCATAWAY",
      state: "NJ"
    },
    {
      city: "PISECO",
      state: "NY"
    },
    {
      city: "PISEK",
      state: "ND"
    },
    {
      city: "PISGAH",
      state: "AL"
    },
    {
      city: "PISGAH",
      state: "IA"
    },
    {
      city: "PISGAH FOREST",
      state: "NC"
    },
    {
      city: "PISMO BEACH",
      state: "CA"
    },
    {
      city: "PITCAIRN",
      state: "PA"
    },
    {
      city: "PITCHER",
      state: "NY"
    },
    {
      city: "PITKIN",
      state: "CO"
    },
    {
      city: "PITKIN",
      state: "LA"
    },
    {
      city: "PITMAN",
      state: "NJ"
    },
    {
      city: "PITMAN",
      state: "PA"
    },
    {
      city: "PITSBURG",
      state: "OH"
    },
    {
      city: "PITTS",
      state: "GA"
    },
    {
      city: "PITTSBORO",
      state: "IN"
    },
    {
      city: "PITTSBORO",
      state: "MS"
    },
    {
      city: "PITTSBORO",
      state: "NC"
    },
    {
      city: "PITTSBURG",
      state: "CA"
    },
    {
      city: "PITTSBURG",
      state: "IL"
    },
    {
      city: "PITTSBURG",
      state: "KS"
    },
    {
      city: "PITTSBURG",
      state: "KY"
    },
    {
      city: "PITTSBURG",
      state: "MO"
    },
    {
      city: "PITTSBURG",
      state: "NH"
    },
    {
      city: "PITTSBURG",
      state: "OK"
    },
    {
      city: "PITTSBURG",
      state: "TX"
    },
    {
      city: "PITTSBURGH",
      state: "PA"
    },
    {
      city: "PITTSFIELD",
      state: "IL"
    },
    {
      city: "PITTSFIELD",
      state: "MA"
    },
    {
      city: "PITTSFIELD",
      state: "ME"
    },
    {
      city: "PITTSFIELD",
      state: "NH"
    },
    {
      city: "PITTSFIELD",
      state: "PA"
    },
    {
      city: "PITTSFIELD",
      state: "VT"
    },
    {
      city: "PITTSFORD",
      state: "MI"
    },
    {
      city: "PITTSFORD",
      state: "NY"
    },
    {
      city: "PITTSFORD",
      state: "VT"
    },
    {
      city: "PITTSTON",
      state: "PA"
    },
    {
      city: "PITTSTOWN",
      state: "NJ"
    },
    {
      city: "PITTSVIEW",
      state: "AL"
    },
    {
      city: "PITTSVILLE",
      state: "MD"
    },
    {
      city: "PITTSVILLE",
      state: "VA"
    },
    {
      city: "PITTSVILLE",
      state: "WI"
    },
    {
      city: "PIXLEY",
      state: "CA"
    },
    {
      city: "PLACEDO",
      state: "TX"
    },
    {
      city: "PLACENTIA",
      state: "CA"
    },
    {
      city: "PLACERVILLE",
      state: "CA"
    },
    {
      city: "PLACERVILLE",
      state: "CO"
    },
    {
      city: "PLACIDA",
      state: "FL"
    },
    {
      city: "PLACITAS",
      state: "NM"
    },
    {
      city: "PLAIN",
      state: "WI"
    },
    {
      city: "PLAIN CITY",
      state: "OH"
    },
    {
      city: "PLAIN DEALING",
      state: "LA"
    },
    {
      city: "PLAINFIELD",
      state: "CT"
    },
    {
      city: "PLAINFIELD",
      state: "IA"
    },
    {
      city: "PLAINFIELD",
      state: "IL"
    },
    {
      city: "PLAINFIELD",
      state: "IN"
    },
    {
      city: "PLAINFIELD",
      state: "MA"
    },
    {
      city: "PLAINFIELD",
      state: "NH"
    },
    {
      city: "PLAINFIELD",
      state: "NJ"
    },
    {
      city: "PLAINFIELD",
      state: "OH"
    },
    {
      city: "PLAINFIELD",
      state: "PA"
    },
    {
      city: "PLAINFIELD",
      state: "VT"
    },
    {
      city: "PLAINFIELD",
      state: "WI"
    },
    {
      city: "PLAINS",
      state: "GA"
    },
    {
      city: "PLAINS",
      state: "KS"
    },
    {
      city: "PLAINS",
      state: "MT"
    },
    {
      city: "PLAINS",
      state: "TX"
    },
    {
      city: "PLAINSBORO",
      state: "NJ"
    },
    {
      city: "PLAINVIEW",
      state: "AR"
    },
    {
      city: "PLAINVIEW",
      state: "MN"
    },
    {
      city: "PLAINVIEW",
      state: "NE"
    },
    {
      city: "PLAINVIEW",
      state: "NY"
    },
    {
      city: "PLAINVIEW",
      state: "TX"
    },
    {
      city: "PLAINVILLE",
      state: "CT"
    },
    {
      city: "PLAINVILLE",
      state: "GA"
    },
    {
      city: "PLAINVILLE",
      state: "IL"
    },
    {
      city: "PLAINVILLE",
      state: "IN"
    },
    {
      city: "PLAINVILLE",
      state: "KS"
    },
    {
      city: "PLAINVILLE",
      state: "MA"
    },
    {
      city: "PLAINVILLE",
      state: "NY"
    },
    {
      city: "PLAINWELL",
      state: "MI"
    },
    {
      city: "PLAISTOW",
      state: "NH"
    },
    {
      city: "PLANADA",
      state: "CA"
    },
    {
      city: "PLANKINTON",
      state: "SD"
    },
    {
      city: "PLANO",
      state: "IA"
    },
    {
      city: "PLANO",
      state: "IL"
    },
    {
      city: "PLANO",
      state: "TX"
    },
    {
      city: "PLANT CITY",
      state: "FL"
    },
    {
      city: "PLANTATION",
      state: "FL"
    },
    {
      city: "PLANTERSVILLE",
      state: "AL"
    },
    {
      city: "PLANTERSVILLE",
      state: "MS"
    },
    {
      city: "PLANTERSVILLE",
      state: "TX"
    },
    {
      city: "PLANTSVILLE",
      state: "CT"
    },
    {
      city: "PLAQUEMINE",
      state: "LA"
    },
    {
      city: "PLATINA",
      state: "CA"
    },
    {
      city: "PLATINUM",
      state: "AK"
    },
    {
      city: "PLATO",
      state: "MN"
    },
    {
      city: "PLATO",
      state: "MO"
    },
    {
      city: "PLATTE",
      state: "SD"
    },
    {
      city: "PLATTE CENTER",
      state: "NE"
    },
    {
      city: "PLATTE CITY",
      state: "MO"
    },
    {
      city: "PLATTEKILL",
      state: "NY"
    },
    {
      city: "PLATTENVILLE",
      state: "LA"
    },
    {
      city: "PLATTER",
      state: "OK"
    },
    {
      city: "PLATTEVILLE",
      state: "CO"
    },
    {
      city: "PLATTEVILLE",
      state: "WI"
    },
    {
      city: "PLATTSBURG",
      state: "MO"
    },
    {
      city: "PLATTSBURGH",
      state: "NY"
    },
    {
      city: "PLATTSMOUTH",
      state: "NE"
    },
    {
      city: "PLAUCHEVILLE",
      state: "LA"
    },
    {
      city: "PLAYA DEL REY",
      state: "CA"
    },
    {
      city: "PLAYA VISTA",
      state: "CA"
    },
    {
      city: "PLAYAS",
      state: "NM"
    },
    {
      city: "PLAZA",
      state: "ND"
    },
    {
      city: "PLEASANT CITY",
      state: "OH"
    },
    {
      city: "PLEASANT DALE",
      state: "NE"
    },
    {
      city: "PLEASANT GARDEN",
      state: "NC"
    },
    {
      city: "PLEASANT GROVE",
      state: "AL"
    },
    {
      city: "PLEASANT GROVE",
      state: "AR"
    },
    {
      city: "PLEASANT GROVE",
      state: "CA"
    },
    {
      city: "PLEASANT GROVE",
      state: "UT"
    },
    {
      city: "PLEASANT HALL",
      state: "PA"
    },
    {
      city: "PLEASANT HILL",
      state: "CA"
    },
    {
      city: "PLEASANT HILL",
      state: "IA"
    },
    {
      city: "PLEASANT HILL",
      state: "IL"
    },
    {
      city: "PLEASANT HILL",
      state: "LA"
    },
    {
      city: "PLEASANT HILL",
      state: "MO"
    },
    {
      city: "PLEASANT HILL",
      state: "NC"
    },
    {
      city: "PLEASANT HILL",
      state: "OH"
    },
    {
      city: "PLEASANT HILL",
      state: "OR"
    },
    {
      city: "PLEASANT HILL",
      state: "TN"
    },
    {
      city: "PLEASANT HOPE",
      state: "MO"
    },
    {
      city: "PLEASANT LAKE",
      state: "IN"
    },
    {
      city: "PLEASANT LAKE",
      state: "MI"
    },
    {
      city: "PLEASANT MILLS",
      state: "IN"
    },
    {
      city: "PLEASANT MOUNT",
      state: "PA"
    },
    {
      city: "PLEASANT PLAIN",
      state: "OH"
    },
    {
      city: "PLEASANT PLAINS",
      state: "AR"
    },
    {
      city: "PLEASANT PLAINS",
      state: "IL"
    },
    {
      city: "PLEASANT PRAIRIE",
      state: "WI"
    },
    {
      city: "PLEASANT RIDGE",
      state: "MI"
    },
    {
      city: "PLEASANT SHADE",
      state: "TN"
    },
    {
      city: "PLEASANT UNITY",
      state: "PA"
    },
    {
      city: "PLEASANT VALLEY",
      state: "IA"
    },
    {
      city: "PLEASANT VALLEY",
      state: "NY"
    },
    {
      city: "PLEASANT VIEW",
      state: "CO"
    },
    {
      city: "PLEASANT VIEW",
      state: "TN"
    },
    {
      city: "PLEASANTON",
      state: "CA"
    },
    {
      city: "PLEASANTON",
      state: "KS"
    },
    {
      city: "PLEASANTON",
      state: "NE"
    },
    {
      city: "PLEASANTON",
      state: "TX"
    },
    {
      city: "PLEASANTVILLE",
      state: "IA"
    },
    {
      city: "PLEASANTVILLE",
      state: "NJ"
    },
    {
      city: "PLEASANTVILLE",
      state: "NY"
    },
    {
      city: "PLEASANTVILLE",
      state: "OH"
    },
    {
      city: "PLEASANTVILLE",
      state: "PA"
    },
    {
      city: "PLEASUREVILLE",
      state: "KY"
    },
    {
      city: "PLEDGER",
      state: "TX"
    },
    {
      city: "PLENTYWOOD",
      state: "MT"
    },
    {
      city: "PLESSIS",
      state: "NY"
    },
    {
      city: "PLEVNA",
      state: "KS"
    },
    {
      city: "PLEVNA",
      state: "MO"
    },
    {
      city: "PLEVNA",
      state: "MT"
    },
    {
      city: "PLOVER",
      state: "IA"
    },
    {
      city: "PLOVER",
      state: "WI"
    },
    {
      city: "PLUCKEMIN",
      state: "NJ"
    },
    {
      city: "PLUM",
      state: "TX"
    },
    {
      city: "PLUM BRANCH",
      state: "SC"
    },
    {
      city: "PLUM CITY",
      state: "WI"
    },
    {
      city: "PLUMERVILLE",
      state: "AR"
    },
    {
      city: "PLUMMER",
      state: "ID"
    },
    {
      city: "PLUMMER",
      state: "MN"
    },
    {
      city: "PLUMMERS LANDING",
      state: "KY"
    },
    {
      city: "PLUMSTEADVILLE",
      state: "PA"
    },
    {
      city: "PLUMTREE",
      state: "NC"
    },
    {
      city: "PLUMVILLE",
      state: "PA"
    },
    {
      city: "PLUSH",
      state: "OR"
    },
    {
      city: "PLYMOUTH",
      state: "CA"
    },
    {
      city: "PLYMOUTH",
      state: "CT"
    },
    {
      city: "PLYMOUTH",
      state: "FL"
    },
    {
      city: "PLYMOUTH",
      state: "IA"
    },
    {
      city: "PLYMOUTH",
      state: "IL"
    },
    {
      city: "PLYMOUTH",
      state: "IN"
    },
    {
      city: "PLYMOUTH",
      state: "MA"
    },
    {
      city: "PLYMOUTH",
      state: "ME"
    },
    {
      city: "PLYMOUTH",
      state: "MI"
    },
    {
      city: "PLYMOUTH",
      state: "NC"
    },
    {
      city: "PLYMOUTH",
      state: "NE"
    },
    {
      city: "PLYMOUTH",
      state: "NH"
    },
    {
      city: "PLYMOUTH",
      state: "NY"
    },
    {
      city: "PLYMOUTH",
      state: "OH"
    },
    {
      city: "PLYMOUTH",
      state: "PA"
    },
    {
      city: "PLYMOUTH",
      state: "UT"
    },
    {
      city: "PLYMOUTH",
      state: "VT"
    },
    {
      city: "PLYMOUTH",
      state: "WA"
    },
    {
      city: "PLYMOUTH",
      state: "WI"
    },
    {
      city: "PLYMOUTH MEETING",
      state: "PA"
    },
    {
      city: "PLYMPTON",
      state: "MA"
    },
    {
      city: "POCA",
      state: "WV"
    },
    {
      city: "POCAHONTAS",
      state: "AR"
    },
    {
      city: "POCAHONTAS",
      state: "IA"
    },
    {
      city: "POCAHONTAS",
      state: "IL"
    },
    {
      city: "POCAHONTAS",
      state: "MO"
    },
    {
      city: "POCAHONTAS",
      state: "TN"
    },
    {
      city: "POCAHONTAS",
      state: "VA"
    },
    {
      city: "POCASSET",
      state: "MA"
    },
    {
      city: "POCASSET",
      state: "OK"
    },
    {
      city: "POCATELLO",
      state: "ID"
    },
    {
      city: "POCOLA",
      state: "OK"
    },
    {
      city: "POCOMOKE CITY",
      state: "MD"
    },
    {
      city: "POCONO LAKE",
      state: "PA"
    },
    {
      city: "POCONO LAKE PRESERVE",
      state: "PA"
    },
    {
      city: "POCONO MANOR",
      state: "PA"
    },
    {
      city: "POCONO PINES",
      state: "PA"
    },
    {
      city: "POCONO SUMMIT",
      state: "PA"
    },
    {
      city: "POCOPSON",
      state: "PA"
    },
    {
      city: "POESTENKILL",
      state: "NY"
    },
    {
      city: "POINT",
      state: "TX"
    },
    {
      city: "POINT ARENA",
      state: "CA"
    },
    {
      city: "POINT BAKER",
      state: "AK"
    },
    {
      city: "POINT CLEAR",
      state: "AL"
    },
    {
      city: "POINT COMFORT",
      state: "TX"
    },
    {
      city: "POINT HARBOR",
      state: "NC"
    },
    {
      city: "POINT HOPE",
      state: "AK"
    },
    {
      city: "POINT LAY",
      state: "AK"
    },
    {
      city: "POINT LOOKOUT",
      state: "MO"
    },
    {
      city: "POINT LOOKOUT",
      state: "NY"
    },
    {
      city: "POINT MARION",
      state: "PA"
    },
    {
      city: "POINT MUGU NAWC",
      state: "CA"
    },
    {
      city: "POINT OF ROCKS",
      state: "MD"
    },
    {
      city: "POINT OF ROCKS",
      state: "WY"
    },
    {
      city: "POINT PLEASANT",
      state: "PA"
    },
    {
      city: "POINT PLEASANT",
      state: "WV"
    },
    {
      city: "POINT PLEASANT BEACH",
      state: "NJ"
    },
    {
      city: "POINT REYES STATION",
      state: "CA"
    },
    {
      city: "POINT ROBERTS",
      state: "WA"
    },
    {
      city: "POINTBLANK",
      state: "TX"
    },
    {
      city: "POINTE A LA HACHE",
      state: "LA"
    },
    {
      city: "POINTE AUX PINS",
      state: "MI"
    },
    {
      city: "POINTS",
      state: "WV"
    },
    {
      city: "POLACCA",
      state: "AZ"
    },
    {
      city: "POLAND",
      state: "IN"
    },
    {
      city: "POLAND",
      state: "ME"
    },
    {
      city: "POLAND",
      state: "NY"
    },
    {
      city: "POLARIS",
      state: "MT"
    },
    {
      city: "POLEBRIDGE",
      state: "MT"
    },
    {
      city: "POLK",
      state: "MO"
    },
    {
      city: "POLK",
      state: "NE"
    },
    {
      city: "POLK",
      state: "OH"
    },
    {
      city: "POLK",
      state: "PA"
    },
    {
      city: "POLK CITY",
      state: "FL"
    },
    {
      city: "POLK CITY",
      state: "IA"
    },
    {
      city: "POLKTON",
      state: "NC"
    },
    {
      city: "POLKVILLE",
      state: "NC"
    },
    {
      city: "POLLARD",
      state: "AR"
    },
    {
      city: "POLLOCK",
      state: "ID"
    },
    {
      city: "POLLOCK",
      state: "LA"
    },
    {
      city: "POLLOCK",
      state: "MO"
    },
    {
      city: "POLLOCK",
      state: "SD"
    },
    {
      city: "POLLOCK PINES",
      state: "CA"
    },
    {
      city: "POLLOCKSVILLE",
      state: "NC"
    },
    {
      city: "POLLOK",
      state: "TX"
    },
    {
      city: "POLO",
      state: "IL"
    },
    {
      city: "POLO",
      state: "MO"
    },
    {
      city: "POLSON",
      state: "MT"
    },
    {
      city: "POMARIA",
      state: "SC"
    },
    {
      city: "POMERENE",
      state: "AZ"
    },
    {
      city: "POMEROY",
      state: "IA"
    },
    {
      city: "POMEROY",
      state: "OH"
    },
    {
      city: "POMEROY",
      state: "PA"
    },
    {
      city: "POMEROY",
      state: "WA"
    },
    {
      city: "POMFRET",
      state: "CT"
    },
    {
      city: "POMFRET",
      state: "MD"
    },
    {
      city: "POMFRET CENTER",
      state: "CT"
    },
    {
      city: "POMONA",
      state: "CA"
    },
    {
      city: "POMONA",
      state: "IL"
    },
    {
      city: "POMONA",
      state: "KS"
    },
    {
      city: "POMONA",
      state: "MO"
    },
    {
      city: "POMONA",
      state: "NJ"
    },
    {
      city: "POMONA",
      state: "NY"
    },
    {
      city: "POMONA PARK",
      state: "FL"
    },
    {
      city: "POMPANO BEACH",
      state: "FL"
    },
    {
      city: "POMPEII",
      state: "MI"
    },
    {
      city: "POMPEY",
      state: "NY"
    },
    {
      city: "POMPEYS PILLAR",
      state: "MT"
    },
    {
      city: "POMPTON LAKES",
      state: "NJ"
    },
    {
      city: "POMPTON PLAINS",
      state: "NJ"
    },
    {
      city: "PONCA",
      state: "AR"
    },
    {
      city: "PONCA",
      state: "NE"
    },
    {
      city: "PONCA CITY",
      state: "OK"
    },
    {
      city: "PONCE DE LEON",
      state: "FL"
    },
    {
      city: "PONCHA SPRINGS",
      state: "CO"
    },
    {
      city: "PONCHATOULA",
      state: "LA"
    },
    {
      city: "POND CREEK",
      state: "OK"
    },
    {
      city: "POND EDDY",
      state: "NY"
    },
    {
      city: "POND GAP",
      state: "WV"
    },
    {
      city: "PONDER",
      state: "TX"
    },
    {
      city: "PONDERAY",
      state: "ID"
    },
    {
      city: "PONDEROSA",
      state: "NM"
    },
    {
      city: "PONETO",
      state: "IN"
    },
    {
      city: "PONSFORD",
      state: "MN"
    },
    {
      city: "PONTE VEDRA",
      state: "FL"
    },
    {
      city: "PONTE VEDRA BEACH",
      state: "FL"
    },
    {
      city: "PONTIAC",
      state: "IL"
    },
    {
      city: "PONTIAC",
      state: "MI"
    },
    {
      city: "PONTIAC",
      state: "MO"
    },
    {
      city: "PONTOTOC",
      state: "MS"
    },
    {
      city: "PONTOTOC",
      state: "TX"
    },
    {
      city: "PONY",
      state: "MT"
    },
    {
      city: "POOL",
      state: "WV"
    },
    {
      city: "POOLE",
      state: "KY"
    },
    {
      city: "POOLER",
      state: "GA"
    },
    {
      city: "POOLESVILLE",
      state: "MD"
    },
    {
      city: "POOLVILLE",
      state: "TX"
    },
    {
      city: "POPE",
      state: "MS"
    },
    {
      city: "POPE ARMY AIRFIELD",
      state: "NC"
    },
    {
      city: "POPE VALLEY",
      state: "CA"
    },
    {
      city: "POPLAR",
      state: "MT"
    },
    {
      city: "POPLAR",
      state: "WI"
    },
    {
      city: "POPLAR BLUFF",
      state: "MO"
    },
    {
      city: "POPLAR BRANCH",
      state: "NC"
    },
    {
      city: "POPLAR GROVE",
      state: "AR"
    },
    {
      city: "POPLAR GROVE",
      state: "IL"
    },
    {
      city: "POPLAR RIDGE",
      state: "NY"
    },
    {
      city: "POPLARVILLE",
      state: "MS"
    },
    {
      city: "POQUONOCK",
      state: "CT"
    },
    {
      city: "POQUOSON",
      state: "VA"
    },
    {
      city: "PORCUPINE",
      state: "SD"
    },
    {
      city: "PORT ALEXANDER",
      state: "AK"
    },
    {
      city: "PORT ALLEGANY",
      state: "PA"
    },
    {
      city: "PORT ALLEN",
      state: "LA"
    },
    {
      city: "PORT ALSWORTH",
      state: "AK"
    },
    {
      city: "PORT ANGELES",
      state: "WA"
    },
    {
      city: "PORT ARANSAS",
      state: "TX"
    },
    {
      city: "PORT ARTHUR",
      state: "TX"
    },
    {
      city: "PORT AUSTIN",
      state: "MI"
    },
    {
      city: "PORT BARRE",
      state: "LA"
    },
    {
      city: "PORT BOLIVAR",
      state: "TX"
    },
    {
      city: "PORT BYRON",
      state: "IL"
    },
    {
      city: "PORT BYRON",
      state: "NY"
    },
    {
      city: "PORT CARBON",
      state: "PA"
    },
    {
      city: "PORT CHARLOTTE",
      state: "FL"
    },
    {
      city: "PORT CHESTER",
      state: "NY"
    },
    {
      city: "PORT CLINTON",
      state: "OH"
    },
    {
      city: "PORT CLINTON",
      state: "PA"
    },
    {
      city: "PORT CLYDE",
      state: "ME"
    },
    {
      city: "PORT COSTA",
      state: "CA"
    },
    {
      city: "PORT CRANE",
      state: "NY"
    },
    {
      city: "PORT DEPOSIT",
      state: "MD"
    },
    {
      city: "PORT EDWARDS",
      state: "WI"
    },
    {
      city: "PORT ELIZABETH",
      state: "NJ"
    },
    {
      city: "PORT EWEN",
      state: "NY"
    },
    {
      city: "PORT GAMBLE",
      state: "WA"
    },
    {
      city: "PORT GIBSON",
      state: "MS"
    },
    {
      city: "PORT GIBSON",
      state: "NY"
    },
    {
      city: "PORT HADLOCK",
      state: "WA"
    },
    {
      city: "PORT HAYWOOD",
      state: "VA"
    },
    {
      city: "PORT HEIDEN",
      state: "AK"
    },
    {
      city: "PORT HENRY",
      state: "NY"
    },
    {
      city: "PORT HOPE",
      state: "MI"
    },
    {
      city: "PORT HUENEME",
      state: "CA"
    },
    {
      city: "PORT HUENEME CBC BASE",
      state: "CA"
    },
    {
      city: "PORT HURON",
      state: "MI"
    },
    {
      city: "PORT ISABEL",
      state: "TX"
    },
    {
      city: "PORT JEFFERSON",
      state: "NY"
    },
    {
      city: "PORT JEFFERSON",
      state: "OH"
    },
    {
      city: "PORT JEFFERSON STATION",
      state: "NY"
    },
    {
      city: "PORT JERVIS",
      state: "NY"
    },
    {
      city: "PORT KENT",
      state: "NY"
    },
    {
      city: "PORT LAVACA",
      state: "TX"
    },
    {
      city: "PORT LEYDEN",
      state: "NY"
    },
    {
      city: "PORT LIONS",
      state: "AK"
    },
    {
      city: "PORT LUDLOW",
      state: "WA"
    },
    {
      city: "PORT MANSFIELD",
      state: "TX"
    },
    {
      city: "PORT MATILDA",
      state: "PA"
    },
    {
      city: "PORT MONMOUTH",
      state: "NJ"
    },
    {
      city: "PORT MURRAY",
      state: "NJ"
    },
    {
      city: "PORT NECHES",
      state: "TX"
    },
    {
      city: "PORT NORRIS",
      state: "NJ"
    },
    {
      city: "PORT O CONNOR",
      state: "TX"
    },
    {
      city: "PORT ORANGE",
      state: "FL"
    },
    {
      city: "PORT ORCHARD",
      state: "WA"
    },
    {
      city: "PORT ORFORD",
      state: "OR"
    },
    {
      city: "PORT PENN",
      state: "DE"
    },
    {
      city: "PORT READING",
      state: "NJ"
    },
    {
      city: "PORT REPUBLIC",
      state: "MD"
    },
    {
      city: "PORT REPUBLIC",
      state: "NJ"
    },
    {
      city: "PORT REPUBLIC",
      state: "VA"
    },
    {
      city: "PORT RICHEY",
      state: "FL"
    },
    {
      city: "PORT ROYAL",
      state: "KY"
    },
    {
      city: "PORT ROYAL",
      state: "PA"
    },
    {
      city: "PORT ROYAL",
      state: "SC"
    },
    {
      city: "PORT ROYAL",
      state: "VA"
    },
    {
      city: "PORT SAINT JOE",
      state: "FL"
    },
    {
      city: "PORT SAINT LUCIE",
      state: "FL"
    },
    {
      city: "PORT SALERNO",
      state: "FL"
    },
    {
      city: "PORT SANILAC",
      state: "MI"
    },
    {
      city: "PORT SULPHUR",
      state: "LA"
    },
    {
      city: "PORT TOBACCO",
      state: "MD"
    },
    {
      city: "PORT TOWNSEND",
      state: "WA"
    },
    {
      city: "PORT TREVORTON",
      state: "PA"
    },
    {
      city: "PORT WASHINGTON",
      state: "NY"
    },
    {
      city: "PORT WASHINGTON",
      state: "OH"
    },
    {
      city: "PORT WASHINGTON",
      state: "WI"
    },
    {
      city: "PORT WENTWORTH",
      state: "GA"
    },
    {
      city: "PORT WILLIAM",
      state: "OH"
    },
    {
      city: "PORT WING",
      state: "WI"
    },
    {
      city: "PORTAGE",
      state: "IN"
    },
    {
      city: "PORTAGE",
      state: "ME"
    },
    {
      city: "PORTAGE",
      state: "MI"
    },
    {
      city: "PORTAGE",
      state: "OH"
    },
    {
      city: "PORTAGE",
      state: "PA"
    },
    {
      city: "PORTAGE",
      state: "UT"
    },
    {
      city: "PORTAGE",
      state: "WI"
    },
    {
      city: "PORTAGE DES SIOUX",
      state: "MO"
    },
    {
      city: "PORTAGEVILLE",
      state: "MO"
    },
    {
      city: "PORTAGEVILLE",
      state: "NY"
    },
    {
      city: "PORTAL",
      state: "GA"
    },
    {
      city: "PORTAL",
      state: "ND"
    },
    {
      city: "PORTALES",
      state: "NM"
    },
    {
      city: "PORTER",
      state: "ME"
    },
    {
      city: "PORTER",
      state: "MN"
    },
    {
      city: "PORTER",
      state: "OK"
    },
    {
      city: "PORTER",
      state: "TX"
    },
    {
      city: "PORTER CORNERS",
      state: "NY"
    },
    {
      city: "PORTER RANCH",
      state: "CA"
    },
    {
      city: "PORTERDALE",
      state: "GA"
    },
    {
      city: "PORTERFIELD",
      state: "WI"
    },
    {
      city: "PORTERS FALLS",
      state: "WV"
    },
    {
      city: "PORTERSVILLE",
      state: "PA"
    },
    {
      city: "PORTERVILLE",
      state: "CA"
    },
    {
      city: "PORTERVILLE",
      state: "MS"
    },
    {
      city: "PORTHILL",
      state: "ID"
    },
    {
      city: "PORTIA",
      state: "AR"
    },
    {
      city: "PORTIS",
      state: "KS"
    },
    {
      city: "PORTLAND",
      state: "AR"
    },
    {
      city: "PORTLAND",
      state: "CT"
    },
    {
      city: "PORTLAND",
      state: "IN"
    },
    {
      city: "PORTLAND",
      state: "ME"
    },
    {
      city: "PORTLAND",
      state: "MI"
    },
    {
      city: "PORTLAND",
      state: "MO"
    },
    {
      city: "PORTLAND",
      state: "ND"
    },
    {
      city: "PORTLAND",
      state: "NY"
    },
    {
      city: "PORTLAND",
      state: "OH"
    },
    {
      city: "PORTLAND",
      state: "OR"
    },
    {
      city: "PORTLAND",
      state: "PA"
    },
    {
      city: "PORTLAND",
      state: "TN"
    },
    {
      city: "PORTLAND",
      state: "TX"
    },
    {
      city: "PORTLANDVILLE",
      state: "NY"
    },
    {
      city: "PORTOLA",
      state: "CA"
    },
    {
      city: "PORTOLA VALLEY",
      state: "CA"
    },
    {
      city: "PORTSMOUTH",
      state: "IA"
    },
    {
      city: "PORTSMOUTH",
      state: "NH"
    },
    {
      city: "PORTSMOUTH",
      state: "OH"
    },
    {
      city: "PORTSMOUTH",
      state: "RI"
    },
    {
      city: "PORTSMOUTH",
      state: "VA"
    },
    {
      city: "PORTVILLE",
      state: "NY"
    },
    {
      city: "PORUM",
      state: "OK"
    },
    {
      city: "POSEN",
      state: "IL"
    },
    {
      city: "POSEN",
      state: "MI"
    },
    {
      city: "POSEY",
      state: "CA"
    },
    {
      city: "POSEYVILLE",
      state: "IN"
    },
    {
      city: "POST",
      state: "OR"
    },
    {
      city: "POST",
      state: "TX"
    },
    {
      city: "POST FALLS",
      state: "ID"
    },
    {
      city: "POST MILLS",
      state: "VT"
    },
    {
      city: "POSTON",
      state: "AZ"
    },
    {
      city: "POSTVILLE",
      state: "IA"
    },
    {
      city: "POTEAU",
      state: "OK"
    },
    {
      city: "POTECASI",
      state: "NC"
    },
    {
      city: "POTEET",
      state: "TX"
    },
    {
      city: "POTH",
      state: "TX"
    },
    {
      city: "POTLATCH",
      state: "ID"
    },
    {
      city: "POTOMAC",
      state: "IL"
    },
    {
      city: "POTOMAC",
      state: "MD"
    },
    {
      city: "POTOSI",
      state: "MO"
    },
    {
      city: "POTOSI",
      state: "WI"
    },
    {
      city: "POTRERO",
      state: "CA"
    },
    {
      city: "POTSDAM",
      state: "NY"
    },
    {
      city: "POTSDAM",
      state: "OH"
    },
    {
      city: "POTTER",
      state: "NE"
    },
    {
      city: "POTTER",
      state: "WI"
    },
    {
      city: "POTTER VALLEY",
      state: "CA"
    },
    {
      city: "POTTERSVILLE",
      state: "MO"
    },
    {
      city: "POTTERSVILLE",
      state: "NJ"
    },
    {
      city: "POTTERSVILLE",
      state: "NY"
    },
    {
      city: "POTTERVILLE",
      state: "MI"
    },
    {
      city: "POTTS CAMP",
      state: "MS"
    },
    {
      city: "POTTS GROVE",
      state: "PA"
    },
    {
      city: "POTTSBORO",
      state: "TX"
    },
    {
      city: "POTTSTOWN",
      state: "PA"
    },
    {
      city: "POTTSVILLE",
      state: "AR"
    },
    {
      city: "POTTSVILLE",
      state: "PA"
    },
    {
      city: "POTWIN",
      state: "KS"
    },
    {
      city: "POUGHKEEPSIE",
      state: "AR"
    },
    {
      city: "POUGHKEEPSIE",
      state: "NY"
    },
    {
      city: "POUGHQUAG",
      state: "NY"
    },
    {
      city: "POULAN",
      state: "GA"
    },
    {
      city: "POULSBO",
      state: "WA"
    },
    {
      city: "POULTNEY",
      state: "VT"
    },
    {
      city: "POUND",
      state: "VA"
    },
    {
      city: "POUND",
      state: "WI"
    },
    {
      city: "POUND RIDGE",
      state: "NY"
    },
    {
      city: "POUNDING MILL",
      state: "VA"
    },
    {
      city: "POWAY",
      state: "CA"
    },
    {
      city: "POWDER RIVER",
      state: "WY"
    },
    {
      city: "POWDER SPRINGS",
      state: "GA"
    },
    {
      city: "POWDER SPRINGS",
      state: "TN"
    },
    {
      city: "POWDERHORN",
      state: "CO"
    },
    {
      city: "POWDERLY",
      state: "KY"
    },
    {
      city: "POWDERLY",
      state: "TX"
    },
    {
      city: "POWDERVILLE",
      state: "MT"
    },
    {
      city: "POWELL",
      state: "MO"
    },
    {
      city: "POWELL",
      state: "OH"
    },
    {
      city: "POWELL",
      state: "TN"
    },
    {
      city: "POWELL",
      state: "TX"
    },
    {
      city: "POWELL",
      state: "WY"
    },
    {
      city: "POWELL BUTTE",
      state: "OR"
    },
    {
      city: "POWELLS POINT",
      state: "NC"
    },
    {
      city: "POWELLSVILLE",
      state: "NC"
    },
    {
      city: "POWELLTON",
      state: "WV"
    },
    {
      city: "POWELLVILLE",
      state: "MD"
    },
    {
      city: "POWER",
      state: "MT"
    },
    {
      city: "POWERS",
      state: "MI"
    },
    {
      city: "POWERS",
      state: "OR"
    },
    {
      city: "POWERS LAKE",
      state: "ND"
    },
    {
      city: "POWERS LAKE",
      state: "WI"
    },
    {
      city: "POWERSITE",
      state: "MO"
    },
    {
      city: "POWERSVILLE",
      state: "MO"
    },
    {
      city: "POWHATAN",
      state: "AR"
    },
    {
      city: "POWHATAN",
      state: "LA"
    },
    {
      city: "POWHATAN",
      state: "VA"
    },
    {
      city: "POWHATAN POINT",
      state: "OH"
    },
    {
      city: "POWHATTAN",
      state: "KS"
    },
    {
      city: "POWNAL",
      state: "ME"
    },
    {
      city: "POWNAL",
      state: "VT"
    },
    {
      city: "POY SIPPI",
      state: "WI"
    },
    {
      city: "POYEN",
      state: "AR"
    },
    {
      city: "POYNETTE",
      state: "WI"
    },
    {
      city: "POYNOR",
      state: "TX"
    },
    {
      city: "POYNTELLE",
      state: "PA"
    },
    {
      city: "PRAGUE",
      state: "NE"
    },
    {
      city: "PRAGUE",
      state: "OK"
    },
    {
      city: "PRAIRIE",
      state: "MS"
    },
    {
      city: "PRAIRIE CITY",
      state: "IA"
    },
    {
      city: "PRAIRIE CITY",
      state: "IL"
    },
    {
      city: "PRAIRIE CITY",
      state: "OR"
    },
    {
      city: "PRAIRIE CITY",
      state: "SD"
    },
    {
      city: "PRAIRIE CREEK",
      state: "IN"
    },
    {
      city: "PRAIRIE DU CHIEN",
      state: "WI"
    },
    {
      city: "PRAIRIE DU ROCHER",
      state: "IL"
    },
    {
      city: "PRAIRIE DU SAC",
      state: "WI"
    },
    {
      city: "PRAIRIE FARM",
      state: "WI"
    },
    {
      city: "PRAIRIE GROVE",
      state: "AR"
    },
    {
      city: "PRAIRIE HILL",
      state: "TX"
    },
    {
      city: "PRAIRIE HOME",
      state: "MO"
    },
    {
      city: "PRAIRIE LEA",
      state: "TX"
    },
    {
      city: "PRAIRIE VIEW",
      state: "KS"
    },
    {
      city: "PRAIRIE VIEW",
      state: "TX"
    },
    {
      city: "PRAIRIE VILLAGE",
      state: "KS"
    },
    {
      city: "PRAIRIEBURG",
      state: "IA"
    },
    {
      city: "PRAIRIETON",
      state: "IN"
    },
    {
      city: "PRAIRIEVILLE",
      state: "LA"
    },
    {
      city: "PRATHER",
      state: "CA"
    },
    {
      city: "PRATT",
      state: "KS"
    },
    {
      city: "PRATT",
      state: "WV"
    },
    {
      city: "PRATTS",
      state: "VA"
    },
    {
      city: "PRATTSBURGH",
      state: "NY"
    },
    {
      city: "PRATTSVILLE",
      state: "AR"
    },
    {
      city: "PRATTSVILLE",
      state: "NY"
    },
    {
      city: "PRATTVILLE",
      state: "AL"
    },
    {
      city: "PRAY",
      state: "MT"
    },
    {
      city: "PREBLE",
      state: "IN"
    },
    {
      city: "PREBLE",
      state: "NY"
    },
    {
      city: "PREEMPTION",
      state: "IL"
    },
    {
      city: "PREMIER",
      state: "WV"
    },
    {
      city: "PREMIUM",
      state: "KY"
    },
    {
      city: "PREMONT",
      state: "TX"
    },
    {
      city: "PRENTICE",
      state: "WI"
    },
    {
      city: "PRENTISS",
      state: "MS"
    },
    {
      city: "PRESCOTT",
      state: "AR"
    },
    {
      city: "PRESCOTT",
      state: "AZ"
    },
    {
      city: "PRESCOTT",
      state: "IA"
    },
    {
      city: "PRESCOTT",
      state: "KS"
    },
    {
      city: "PRESCOTT",
      state: "MI"
    },
    {
      city: "PRESCOTT",
      state: "WA"
    },
    {
      city: "PRESCOTT",
      state: "WI"
    },
    {
      city: "PRESCOTT VALLEY",
      state: "AZ"
    },
    {
      city: "PRESHO",
      state: "SD"
    },
    {
      city: "PRESIDIO",
      state: "TX"
    },
    {
      city: "PRESQUE ISLE",
      state: "ME"
    },
    {
      city: "PRESQUE ISLE",
      state: "MI"
    },
    {
      city: "PRESQUE ISLE",
      state: "WI"
    },
    {
      city: "PRESTO",
      state: "PA"
    },
    {
      city: "PRESTON",
      state: "CT"
    },
    {
      city: "PRESTON",
      state: "GA"
    },
    {
      city: "PRESTON",
      state: "IA"
    },
    {
      city: "PRESTON",
      state: "ID"
    },
    {
      city: "PRESTON",
      state: "KY"
    },
    {
      city: "PRESTON",
      state: "MD"
    },
    {
      city: "PRESTON",
      state: "MN"
    },
    {
      city: "PRESTON",
      state: "MO"
    },
    {
      city: "PRESTON",
      state: "MS"
    },
    {
      city: "PRESTON",
      state: "OK"
    },
    {
      city: "PRESTON",
      state: "WA"
    },
    {
      city: "PRESTON HOLLOW",
      state: "NY"
    },
    {
      city: "PRESTON PARK",
      state: "PA"
    },
    {
      city: "PRESTONSBURG",
      state: "KY"
    },
    {
      city: "PRETTY PRAIRIE",
      state: "KS"
    },
    {
      city: "PREWITT",
      state: "NM"
    },
    {
      city: "PRICE",
      state: "TX"
    },
    {
      city: "PRICE",
      state: "UT"
    },
    {
      city: "PRICEDALE",
      state: "PA"
    },
    {
      city: "PRICHARD",
      state: "WV"
    },
    {
      city: "PRIDDY",
      state: "TX"
    },
    {
      city: "PRIDE",
      state: "LA"
    },
    {
      city: "PRIDES CROSSING",
      state: "MA"
    },
    {
      city: "PRIEST RIVER",
      state: "ID"
    },
    {
      city: "PRIM",
      state: "AR"
    },
    {
      city: "PRIMGHAR",
      state: "IA"
    },
    {
      city: "PRIMM SPRINGS",
      state: "TN"
    },
    {
      city: "PRIMROSE",
      state: "NE"
    },
    {
      city: "PRINCE FREDERICK",
      state: "MD"
    },
    {
      city: "PRINCE GEORGE",
      state: "VA"
    },
    {
      city: "PRINCESS ANNE",
      state: "MD"
    },
    {
      city: "PRINCETON",
      state: "AL"
    },
    {
      city: "PRINCETON",
      state: "CA"
    },
    {
      city: "PRINCETON",
      state: "IA"
    },
    {
      city: "PRINCETON",
      state: "ID"
    },
    {
      city: "PRINCETON",
      state: "IL"
    },
    {
      city: "PRINCETON",
      state: "IN"
    },
    {
      city: "PRINCETON",
      state: "KS"
    },
    {
      city: "PRINCETON",
      state: "KY"
    },
    {
      city: "PRINCETON",
      state: "LA"
    },
    {
      city: "PRINCETON",
      state: "MA"
    },
    {
      city: "PRINCETON",
      state: "ME"
    },
    {
      city: "PRINCETON",
      state: "MN"
    },
    {
      city: "PRINCETON",
      state: "MO"
    },
    {
      city: "PRINCETON",
      state: "NC"
    },
    {
      city: "PRINCETON",
      state: "NJ"
    },
    {
      city: "PRINCETON",
      state: "OR"
    },
    {
      city: "PRINCETON",
      state: "TX"
    },
    {
      city: "PRINCETON",
      state: "WI"
    },
    {
      city: "PRINCETON",
      state: "WV"
    },
    {
      city: "PRINCETON JUNCTION",
      state: "NJ"
    },
    {
      city: "PRINCEVILLE",
      state: "HI"
    },
    {
      city: "PRINCEVILLE",
      state: "IL"
    },
    {
      city: "PRINEVILLE",
      state: "OR"
    },
    {
      city: "PRINGLE",
      state: "SD"
    },
    {
      city: "PRINSBURG",
      state: "MN"
    },
    {
      city: "PRINTER",
      state: "KY"
    },
    {
      city: "PRIOR LAKE",
      state: "MN"
    },
    {
      city: "PRITCHETT",
      state: "CO"
    },
    {
      city: "PROBERTA",
      state: "CA"
    },
    {
      city: "PROCIOUS",
      state: "WV"
    },
    {
      city: "PROCTOR",
      state: "AR"
    },
    {
      city: "PROCTOR",
      state: "MT"
    },
    {
      city: "PROCTOR",
      state: "OK"
    },
    {
      city: "PROCTOR",
      state: "TX"
    },
    {
      city: "PROCTOR",
      state: "VT"
    },
    {
      city: "PROCTOR",
      state: "WV"
    },
    {
      city: "PROCTORSVILLE",
      state: "VT"
    },
    {
      city: "PROCTORVILLE",
      state: "NC"
    },
    {
      city: "PROCTORVILLE",
      state: "OH"
    },
    {
      city: "PROGRESO",
      state: "TX"
    },
    {
      city: "PROLE",
      state: "IA"
    },
    {
      city: "PROMISE CITY",
      state: "IA"
    },
    {
      city: "PROMPTON",
      state: "PA"
    },
    {
      city: "PROPHETSTOWN",
      state: "IL"
    },
    {
      city: "PROSPECT",
      state: "CT"
    },
    {
      city: "PROSPECT",
      state: "KY"
    },
    {
      city: "PROSPECT",
      state: "NY"
    },
    {
      city: "PROSPECT",
      state: "OH"
    },
    {
      city: "PROSPECT",
      state: "OR"
    },
    {
      city: "PROSPECT",
      state: "PA"
    },
    {
      city: "PROSPECT",
      state: "TN"
    },
    {
      city: "PROSPECT",
      state: "VA"
    },
    {
      city: "PROSPECT HARBOR",
      state: "ME"
    },
    {
      city: "PROSPECT HEIGHTS",
      state: "IL"
    },
    {
      city: "PROSPECT HILL",
      state: "NC"
    },
    {
      city: "PROSPECT PARK",
      state: "PA"
    },
    {
      city: "PROSPER",
      state: "TX"
    },
    {
      city: "PROSPERITY",
      state: "PA"
    },
    {
      city: "PROSPERITY",
      state: "SC"
    },
    {
      city: "PROSPERITY",
      state: "WV"
    },
    {
      city: "PROSSER",
      state: "WA"
    },
    {
      city: "PROTECTION",
      state: "KS"
    },
    {
      city: "PROTEM",
      state: "MO"
    },
    {
      city: "PROTIVIN",
      state: "IA"
    },
    {
      city: "PROVENCAL",
      state: "LA"
    },
    {
      city: "PROVIDENCE",
      state: "KY"
    },
    {
      city: "PROVIDENCE",
      state: "NC"
    },
    {
      city: "PROVIDENCE",
      state: "RI"
    },
    {
      city: "PROVIDENCE",
      state: "UT"
    },
    {
      city: "PROVIDENCE FORGE",
      state: "VA"
    },
    {
      city: "PROVINCETOWN",
      state: "MA"
    },
    {
      city: "PROVO",
      state: "UT"
    },
    {
      city: "PRUDENCE ISLAND",
      state: "RI"
    },
    {
      city: "PRUDENVILLE",
      state: "MI"
    },
    {
      city: "PRUDHOE BAY",
      state: "AK"
    },
    {
      city: "PRUE",
      state: "OK"
    },
    {
      city: "PRYOR",
      state: "MT"
    },
    {
      city: "PRYOR",
      state: "OK"
    },
    {
      city: "PUCKETT",
      state: "MS"
    },
    {
      city: "PUEBLO",
      state: "CO"
    },
    {
      city: "PUEBLO OF ACOMA",
      state: "NM"
    },
    {
      city: "PUKALANI",
      state: "HI"
    },
    {
      city: "PUKWANA",
      state: "SD"
    },
    {
      city: "PULASKI",
      state: "GA"
    },
    {
      city: "PULASKI",
      state: "IA"
    },
    {
      city: "PULASKI",
      state: "IL"
    },
    {
      city: "PULASKI",
      state: "MS"
    },
    {
      city: "PULASKI",
      state: "NY"
    },
    {
      city: "PULASKI",
      state: "PA"
    },
    {
      city: "PULASKI",
      state: "TN"
    },
    {
      city: "PULASKI",
      state: "VA"
    },
    {
      city: "PULASKI",
      state: "WI"
    },
    {
      city: "PULLMAN",
      state: "MI"
    },
    {
      city: "PULLMAN",
      state: "WA"
    },
    {
      city: "PULLMAN",
      state: "WV"
    },
    {
      city: "PULTENEY",
      state: "NY"
    },
    {
      city: "PULTNEYVILLE",
      state: "NY"
    },
    {
      city: "PUNGOTEAGUE",
      state: "VA"
    },
    {
      city: "PUNTA GORDA",
      state: "FL"
    },
    {
      city: "PUNXSUTAWNEY",
      state: "PA"
    },
    {
      city: "PUPOSKY",
      state: "MN"
    },
    {
      city: "PURCELL",
      state: "MO"
    },
    {
      city: "PURCELL",
      state: "OK"
    },
    {
      city: "PURCELLVILLE",
      state: "VA"
    },
    {
      city: "PURCHASE",
      state: "NY"
    },
    {
      city: "PURDIN",
      state: "MO"
    },
    {
      city: "PURDON",
      state: "TX"
    },
    {
      city: "PURDUM",
      state: "NE"
    },
    {
      city: "PURDY",
      state: "MO"
    },
    {
      city: "PURDYS",
      state: "NY"
    },
    {
      city: "PURGITSVILLE",
      state: "WV"
    },
    {
      city: "PURLEAR",
      state: "NC"
    },
    {
      city: "PURLING",
      state: "NY"
    },
    {
      city: "PURMELA",
      state: "TX"
    },
    {
      city: "PURSGLOVE",
      state: "WV"
    },
    {
      city: "PURVIS",
      state: "MS"
    },
    {
      city: "PURYEAR",
      state: "TN"
    },
    {
      city: "PUT IN BAY",
      state: "OH"
    },
    {
      city: "PUTNAM",
      state: "CT"
    },
    {
      city: "PUTNAM",
      state: "IL"
    },
    {
      city: "PUTNAM",
      state: "OK"
    },
    {
      city: "PUTNAM",
      state: "TX"
    },
    {
      city: "PUTNAM HALL",
      state: "FL"
    },
    {
      city: "PUTNAM STATION",
      state: "NY"
    },
    {
      city: "PUTNAM VALLEY",
      state: "NY"
    },
    {
      city: "PUTNAMVILLE",
      state: "IN"
    },
    {
      city: "PUTNEY",
      state: "KY"
    },
    {
      city: "PUTNEY",
      state: "VT"
    },
    {
      city: "PUUNENE",
      state: "HI"
    },
    {
      city: "PUXICO",
      state: "MO"
    },
    {
      city: "PUYALLUP",
      state: "WA"
    },
    {
      city: "PYATT",
      state: "AR"
    },
    {
      city: "PYLESVILLE",
      state: "MD"
    },
    {
      city: "PYOTE",
      state: "TX"
    },
    {
      city: "QUAIL",
      state: "TX"
    },
    {
      city: "QUAIL VALLEY",
      state: "CA"
    },
    {
      city: "QUAKAKE",
      state: "PA"
    },
    {
      city: "QUAKER CITY",
      state: "OH"
    },
    {
      city: "QUAKER HILL",
      state: "CT"
    },
    {
      city: "QUAKER STREET",
      state: "NY"
    },
    {
      city: "QUAKERTOWN",
      state: "NJ"
    },
    {
      city: "QUAKERTOWN",
      state: "PA"
    },
    {
      city: "QUANAH",
      state: "TX"
    },
    {
      city: "QUANTICO",
      state: "MD"
    },
    {
      city: "QUANTICO",
      state: "VA"
    },
    {
      city: "QUAPAW",
      state: "OK"
    },
    {
      city: "QUARRYVILLE",
      state: "PA"
    },
    {
      city: "QUARTZSITE",
      state: "AZ"
    },
    {
      city: "QUASQUETON",
      state: "IA"
    },
    {
      city: "QUEBECK",
      state: "TN"
    },
    {
      city: "QUECHEE",
      state: "VT"
    },
    {
      city: "QUEEN ANNE",
      state: "MD"
    },
    {
      city: "QUEEN CITY",
      state: "MO"
    },
    {
      city: "QUEEN CITY",
      state: "TX"
    },
    {
      city: "QUEEN CREEK",
      state: "AZ"
    },
    {
      city: "QUEENS VILLAGE",
      state: "NY"
    },
    {
      city: "QUEENSBURY",
      state: "NY"
    },
    {
      city: "QUEENSTOWN",
      state: "MD"
    },
    {
      city: "QUEMADO",
      state: "NM"
    },
    {
      city: "QUEMADO",
      state: "TX"
    },
    {
      city: "QUENEMO",
      state: "KS"
    },
    {
      city: "QUENTIN",
      state: "PA"
    },
    {
      city: "QUESTA",
      state: "NM"
    },
    {
      city: "QUICKSBURG",
      state: "VA"
    },
    {
      city: "QUILCENE",
      state: "WA"
    },
    {
      city: "QUIMBY",
      state: "IA"
    },
    {
      city: "QUINAULT",
      state: "WA"
    },
    {
      city: "QUINBY",
      state: "VA"
    },
    {
      city: "QUINCY",
      state: "CA"
    },
    {
      city: "QUINCY",
      state: "FL"
    },
    {
      city: "QUINCY",
      state: "IL"
    },
    {
      city: "QUINCY",
      state: "IN"
    },
    {
      city: "QUINCY",
      state: "KY"
    },
    {
      city: "QUINCY",
      state: "MA"
    },
    {
      city: "QUINCY",
      state: "MI"
    },
    {
      city: "QUINCY",
      state: "MO"
    },
    {
      city: "QUINCY",
      state: "OH"
    },
    {
      city: "QUINCY",
      state: "PA"
    },
    {
      city: "QUINCY",
      state: "WA"
    },
    {
      city: "QUINEBAUG",
      state: "CT"
    },
    {
      city: "QUINHAGAK",
      state: "AK"
    },
    {
      city: "QUINLAN",
      state: "TX"
    },
    {
      city: "QUINN",
      state: "SD"
    },
    {
      city: "QUINNESEC",
      state: "MI"
    },
    {
      city: "QUINQUE",
      state: "VA"
    },
    {
      city: "QUINTER",
      state: "KS"
    },
    {
      city: "QUINTON",
      state: "AL"
    },
    {
      city: "QUINTON",
      state: "NJ"
    },
    {
      city: "QUINTON",
      state: "OK"
    },
    {
      city: "QUINTON",
      state: "VA"
    },
    {
      city: "QUINWOOD",
      state: "WV"
    },
    {
      city: "QUITAQUE",
      state: "TX"
    },
    {
      city: "QUITMAN",
      state: "AR"
    },
    {
      city: "QUITMAN",
      state: "GA"
    },
    {
      city: "QUITMAN",
      state: "LA"
    },
    {
      city: "QUITMAN",
      state: "MS"
    },
    {
      city: "QUITMAN",
      state: "TX"
    },
    {
      city: "QULIN",
      state: "MO"
    },
    {
      city: "QUOGUE",
      state: "NY"
    },
    {
      city: "RABUN GAP",
      state: "GA"
    },
    {
      city: "RACCOON",
      state: "KY"
    },
    {
      city: "RACELAND",
      state: "LA"
    },
    {
      city: "RACHEL",
      state: "WV"
    },
    {
      city: "RACINE",
      state: "MN"
    },
    {
      city: "RACINE",
      state: "MO"
    },
    {
      city: "RACINE",
      state: "OH"
    },
    {
      city: "RACINE",
      state: "WI"
    },
    {
      city: "RACINE",
      state: "WV"
    },
    {
      city: "RACKERBY",
      state: "CA"
    },
    {
      city: "RADCLIFF",
      state: "KY"
    },
    {
      city: "RADCLIFFE",
      state: "IA"
    },
    {
      city: "RADFORD",
      state: "VA"
    },
    {
      city: "RADIANT",
      state: "VA"
    },
    {
      city: "RADISSON",
      state: "WI"
    },
    {
      city: "RADIUM SPRINGS",
      state: "NM"
    },
    {
      city: "RADNOR",
      state: "OH"
    },
    {
      city: "RADOM",
      state: "IL"
    },
    {
      city: "RAEFORD",
      state: "NC"
    },
    {
      city: "RAGAN",
      state: "NE"
    },
    {
      city: "RAGLAND",
      state: "AL"
    },
    {
      city: "RAGLAND",
      state: "WV"
    },
    {
      city: "RAGLEY",
      state: "LA"
    },
    {
      city: "RAHWAY",
      state: "NJ"
    },
    {
      city: "RAIFORD",
      state: "FL"
    },
    {
      city: "RAIL ROAD FLAT",
      state: "CA"
    },
    {
      city: "RAILROAD",
      state: "PA"
    },
    {
      city: "RAINBOW",
      state: "TX"
    },
    {
      city: "RAINBOW CITY",
      state: "AL"
    },
    {
      city: "RAINBOW LAKE",
      state: "NY"
    },
    {
      city: "RAINELLE",
      state: "WV"
    },
    {
      city: "RAINIER",
      state: "OR"
    },
    {
      city: "RAINIER",
      state: "WA"
    },
    {
      city: "RAINSVILLE",
      state: "AL"
    },
    {
      city: "RAISIN CITY",
      state: "CA"
    },
    {
      city: "RAKE",
      state: "IA"
    },
    {
      city: "RALEIGH",
      state: "IL"
    },
    {
      city: "RALEIGH",
      state: "MS"
    },
    {
      city: "RALEIGH",
      state: "NC"
    },
    {
      city: "RALEIGH",
      state: "ND"
    },
    {
      city: "RALEIGH",
      state: "WV"
    },
    {
      city: "RALLS",
      state: "TX"
    },
    {
      city: "RALPH",
      state: "AL"
    },
    {
      city: "RALPH",
      state: "MI"
    },
    {
      city: "RALSTON",
      state: "IA"
    },
    {
      city: "RALSTON",
      state: "OK"
    },
    {
      city: "RALSTON",
      state: "PA"
    },
    {
      city: "RALSTON",
      state: "WY"
    },
    {
      city: "RAMAH",
      state: "CO"
    },
    {
      city: "RAMAH",
      state: "NM"
    },
    {
      city: "RAMER",
      state: "AL"
    },
    {
      city: "RAMER",
      state: "TN"
    },
    {
      city: "RAMEY",
      state: "PA"
    },
    {
      city: "RAMONA",
      state: "CA"
    },
    {
      city: "RAMONA",
      state: "OK"
    },
    {
      city: "RAMONA",
      state: "SD"
    },
    {
      city: "RAMSAY",
      state: "MI"
    },
    {
      city: "RAMSAY",
      state: "MT"
    },
    {
      city: "RAMSEUR",
      state: "NC"
    },
    {
      city: "RAMSEY",
      state: "IL"
    },
    {
      city: "RAMSEY",
      state: "IN"
    },
    {
      city: "RAMSEY",
      state: "NJ"
    },
    {
      city: "RANBURNE",
      state: "AL"
    },
    {
      city: "RANCHESTER",
      state: "WY"
    },
    {
      city: "RANCHITA",
      state: "CA"
    },
    {
      city: "RANCHO CORDOVA",
      state: "CA"
    },
    {
      city: "RANCHO CUCAMONGA",
      state: "CA"
    },
    {
      city: "RANCHO MIRAGE",
      state: "CA"
    },
    {
      city: "RANCHO PALOS VERDES",
      state: "CA"
    },
    {
      city: "RANCHO SANTA FE",
      state: "CA"
    },
    {
      city: "RANCHO SANTA MARGARITA",
      state: "CA"
    },
    {
      city: "RANCHOS DE TAOS",
      state: "NM"
    },
    {
      city: "RANCOCAS",
      state: "NJ"
    },
    {
      city: "RANDALIA",
      state: "IA"
    },
    {
      city: "RANDALL",
      state: "IA"
    },
    {
      city: "RANDALL",
      state: "KS"
    },
    {
      city: "RANDALL",
      state: "MN"
    },
    {
      city: "RANDALLSTOWN",
      state: "MD"
    },
    {
      city: "RANDLE",
      state: "WA"
    },
    {
      city: "RANDLEMAN",
      state: "NC"
    },
    {
      city: "RANDLETT",
      state: "OK"
    },
    {
      city: "RANDLETT",
      state: "UT"
    },
    {
      city: "RANDOLPH",
      state: "AL"
    },
    {
      city: "RANDOLPH",
      state: "IA"
    },
    {
      city: "RANDOLPH",
      state: "KS"
    },
    {
      city: "RANDOLPH",
      state: "MA"
    },
    {
      city: "RANDOLPH",
      state: "ME"
    },
    {
      city: "RANDOLPH",
      state: "MN"
    },
    {
      city: "RANDOLPH",
      state: "MS"
    },
    {
      city: "RANDOLPH",
      state: "NE"
    },
    {
      city: "RANDOLPH",
      state: "NH"
    },
    {
      city: "RANDOLPH",
      state: "NJ"
    },
    {
      city: "RANDOLPH",
      state: "NY"
    },
    {
      city: "RANDOLPH",
      state: "OH"
    },
    {
      city: "RANDOLPH",
      state: "TX"
    },
    {
      city: "RANDOLPH",
      state: "UT"
    },
    {
      city: "RANDOLPH",
      state: "VA"
    },
    {
      city: "RANDOLPH",
      state: "VT"
    },
    {
      city: "RANDOLPH",
      state: "WI"
    },
    {
      city: "RANDOLPH CENTER",
      state: "VT"
    },
    {
      city: "RANDOM LAKE",
      state: "WI"
    },
    {
      city: "RANDSBURG",
      state: "CA"
    },
    {
      city: "RANGE",
      state: "AL"
    },
    {
      city: "RANGELEY",
      state: "ME"
    },
    {
      city: "RANGELY",
      state: "CO"
    },
    {
      city: "RANGER",
      state: "GA"
    },
    {
      city: "RANGER",
      state: "TX"
    },
    {
      city: "RANGER",
      state: "WV"
    },
    {
      city: "RANIER",
      state: "MN"
    },
    {
      city: "RANKIN",
      state: "IL"
    },
    {
      city: "RANKIN",
      state: "TX"
    },
    {
      city: "RANSOM",
      state: "IL"
    },
    {
      city: "RANSOM",
      state: "KS"
    },
    {
      city: "RANSOM",
      state: "KY"
    },
    {
      city: "RANSOM CANYON",
      state: "TX"
    },
    {
      city: "RANSOMVILLE",
      state: "NY"
    },
    {
      city: "RANSON",
      state: "WV"
    },
    {
      city: "RANTOUL",
      state: "IL"
    },
    {
      city: "RANTOUL",
      state: "KS"
    },
    {
      city: "RAPELJE",
      state: "MT"
    },
    {
      city: "RAPHINE",
      state: "VA"
    },
    {
      city: "RAPID CITY",
      state: "MI"
    },
    {
      city: "RAPID CITY",
      state: "SD"
    },
    {
      city: "RAPID RIVER",
      state: "MI"
    },
    {
      city: "RAPIDAN",
      state: "VA"
    },
    {
      city: "RAPIDS CITY",
      state: "IL"
    },
    {
      city: "RAPPAHANNOCK ACADEMY",
      state: "VA"
    },
    {
      city: "RAQUETTE LAKE",
      state: "NY"
    },
    {
      city: "RARDEN",
      state: "OH"
    },
    {
      city: "RARITAN",
      state: "IL"
    },
    {
      city: "RARITAN",
      state: "NJ"
    },
    {
      city: "RATCLIFF",
      state: "AR"
    },
    {
      city: "RATCLIFF",
      state: "TX"
    },
    {
      city: "RATHDRUM",
      state: "ID"
    },
    {
      city: "RATLIFF CITY",
      state: "OK"
    },
    {
      city: "RATON",
      state: "NM"
    },
    {
      city: "RATTAN",
      state: "OK"
    },
    {
      city: "RAVALLI",
      state: "MT"
    },
    {
      city: "RAVEN",
      state: "KY"
    },
    {
      city: "RAVEN",
      state: "VA"
    },
    {
      city: "RAVENA",
      state: "NY"
    },
    {
      city: "RAVENCLIFF",
      state: "WV"
    },
    {
      city: "RAVENDALE",
      state: "CA"
    },
    {
      city: "RAVENDEN",
      state: "AR"
    },
    {
      city: "RAVENDEN SPRINGS",
      state: "AR"
    },
    {
      city: "RAVENEL",
      state: "SC"
    },
    {
      city: "RAVENNA",
      state: "KY"
    },
    {
      city: "RAVENNA",
      state: "MI"
    },
    {
      city: "RAVENNA",
      state: "NE"
    },
    {
      city: "RAVENNA",
      state: "OH"
    },
    {
      city: "RAVENNA",
      state: "TX"
    },
    {
      city: "RAVENSDALE",
      state: "WA"
    },
    {
      city: "RAVENSWOOD",
      state: "WV"
    },
    {
      city: "RAVENWOOD",
      state: "MO"
    },
    {
      city: "RAVIA",
      state: "OK"
    },
    {
      city: "RAWL",
      state: "WV"
    },
    {
      city: "RAWLINGS",
      state: "MD"
    },
    {
      city: "RAWLINGS",
      state: "VA"
    },
    {
      city: "RAWLINS",
      state: "WY"
    },
    {
      city: "RAWSON",
      state: "OH"
    },
    {
      city: "RAY",
      state: "MI"
    },
    {
      city: "RAY",
      state: "ND"
    },
    {
      city: "RAY",
      state: "OH"
    },
    {
      city: "RAY BROOK",
      state: "NY"
    },
    {
      city: "RAY CITY",
      state: "GA"
    },
    {
      city: "RAYLAND",
      state: "OH"
    },
    {
      city: "RAYLE",
      state: "GA"
    },
    {
      city: "RAYMOND",
      state: "CA"
    },
    {
      city: "RAYMOND",
      state: "IA"
    },
    {
      city: "RAYMOND",
      state: "IL"
    },
    {
      city: "RAYMOND",
      state: "KS"
    },
    {
      city: "RAYMOND",
      state: "ME"
    },
    {
      city: "RAYMOND",
      state: "MN"
    },
    {
      city: "RAYMOND",
      state: "MS"
    },
    {
      city: "RAYMOND",
      state: "MT"
    },
    {
      city: "RAYMOND",
      state: "NE"
    },
    {
      city: "RAYMOND",
      state: "NH"
    },
    {
      city: "RAYMOND",
      state: "OH"
    },
    {
      city: "RAYMOND",
      state: "SD"
    },
    {
      city: "RAYMOND",
      state: "WA"
    },
    {
      city: "RAYMONDVILLE",
      state: "MO"
    },
    {
      city: "RAYMONDVILLE",
      state: "NY"
    },
    {
      city: "RAYMONDVILLE",
      state: "TX"
    },
    {
      city: "RAYMORE",
      state: "MO"
    },
    {
      city: "RAYNE",
      state: "LA"
    },
    {
      city: "RAYNESFORD",
      state: "MT"
    },
    {
      city: "RAYNHAM",
      state: "MA"
    },
    {
      city: "RAYNHAM CENTER",
      state: "MA"
    },
    {
      city: "RAYSAL",
      state: "WV"
    },
    {
      city: "RAYVILLE",
      state: "LA"
    },
    {
      city: "RAYVILLE",
      state: "MO"
    },
    {
      city: "RAYWICK",
      state: "KY"
    },
    {
      city: "RAYWOOD",
      state: "TX"
    },
    {
      city: "REA",
      state: "MO"
    },
    {
      city: "READER",
      state: "WV"
    },
    {
      city: "READFIELD",
      state: "ME"
    },
    {
      city: "READFIELD",
      state: "WI"
    },
    {
      city: "READING",
      state: "KS"
    },
    {
      city: "READING",
      state: "MA"
    },
    {
      city: "READING",
      state: "MI"
    },
    {
      city: "READING",
      state: "MN"
    },
    {
      city: "READING",
      state: "PA"
    },
    {
      city: "READING",
      state: "VT"
    },
    {
      city: "READING CENTER",
      state: "NY"
    },
    {
      city: "READINGTON",
      state: "NJ"
    },
    {
      city: "READLYN",
      state: "IA"
    },
    {
      city: "READS LANDING",
      state: "MN"
    },
    {
      city: "READSBORO",
      state: "VT"
    },
    {
      city: "READSTOWN",
      state: "WI"
    },
    {
      city: "READVILLE",
      state: "MA"
    },
    {
      city: "READYVILLE",
      state: "TN"
    },
    {
      city: "REAGAN",
      state: "TN"
    },
    {
      city: "REAGAN",
      state: "TX"
    },
    {
      city: "REALITOS",
      state: "TX"
    },
    {
      city: "REAMSTOWN",
      state: "PA"
    },
    {
      city: "REARDAN",
      state: "WA"
    },
    {
      city: "REASNOR",
      state: "IA"
    },
    {
      city: "REBECCA",
      state: "GA"
    },
    {
      city: "REBERSBURG",
      state: "PA"
    },
    {
      city: "REBUCK",
      state: "PA"
    },
    {
      city: "RECLUSE",
      state: "WY"
    },
    {
      city: "RECTOR",
      state: "AR"
    },
    {
      city: "RECTOR",
      state: "PA"
    },
    {
      city: "RECTORTOWN",
      state: "VA"
    },
    {
      city: "RED ASH",
      state: "VA"
    },
    {
      city: "RED BANK",
      state: "NJ"
    },
    {
      city: "RED BANKS",
      state: "MS"
    },
    {
      city: "RED BAY",
      state: "AL"
    },
    {
      city: "RED BLUFF",
      state: "CA"
    },
    {
      city: "RED BOILING SPRINGS",
      state: "TN"
    },
    {
      city: "RED BUD",
      state: "IL"
    },
    {
      city: "RED CLIFF",
      state: "CO"
    },
    {
      city: "RED CLOUD",
      state: "NE"
    },
    {
      city: "RED CREEK",
      state: "NY"
    },
    {
      city: "RED DEVIL",
      state: "AK"
    },
    {
      city: "RED FEATHER LAKES",
      state: "CO"
    },
    {
      city: "RED HILL",
      state: "PA"
    },
    {
      city: "RED HOOK",
      state: "NY"
    },
    {
      city: "RED HOUSE",
      state: "VA"
    },
    {
      city: "RED HOUSE",
      state: "WV"
    },
    {
      city: "RED JACKET",
      state: "WV"
    },
    {
      city: "RED LAKE FALLS",
      state: "MN"
    },
    {
      city: "RED LEVEL",
      state: "AL"
    },
    {
      city: "RED LION",
      state: "PA"
    },
    {
      city: "RED LODGE",
      state: "MT"
    },
    {
      city: "RED MOUNTAIN",
      state: "CA"
    },
    {
      city: "RED OAK",
      state: "GA"
    },
    {
      city: "RED OAK",
      state: "IA"
    },
    {
      city: "RED OAK",
      state: "NC"
    },
    {
      city: "RED OAK",
      state: "OK"
    },
    {
      city: "RED OAK",
      state: "TX"
    },
    {
      city: "RED OAK",
      state: "VA"
    },
    {
      city: "RED RIVER",
      state: "NM"
    },
    {
      city: "RED ROCK",
      state: "AZ"
    },
    {
      city: "RED ROCK",
      state: "OK"
    },
    {
      city: "RED ROCK",
      state: "TX"
    },
    {
      city: "RED SPRINGS",
      state: "NC"
    },
    {
      city: "RED VALLEY",
      state: "AZ"
    },
    {
      city: "RED WING",
      state: "MN"
    },
    {
      city: "REDAN",
      state: "GA"
    },
    {
      city: "REDBIRD",
      state: "OK"
    },
    {
      city: "REDBY",
      state: "MN"
    },
    {
      city: "REDCREST",
      state: "CA"
    },
    {
      city: "REDDELL",
      state: "LA"
    },
    {
      city: "REDDICK",
      state: "FL"
    },
    {
      city: "REDDICK",
      state: "IL"
    },
    {
      city: "REDDING",
      state: "CA"
    },
    {
      city: "REDDING",
      state: "CT"
    },
    {
      city: "REDDING",
      state: "IA"
    },
    {
      city: "REDDING CENTER",
      state: "CT"
    },
    {
      city: "REDDING RIDGE",
      state: "CT"
    },
    {
      city: "REDFIELD",
      state: "AR"
    },
    {
      city: "REDFIELD",
      state: "IA"
    },
    {
      city: "REDFIELD",
      state: "KS"
    },
    {
      city: "REDFIELD",
      state: "NY"
    },
    {
      city: "REDFIELD",
      state: "SD"
    },
    {
      city: "REDFORD",
      state: "MI"
    },
    {
      city: "REDFORD",
      state: "MO"
    },
    {
      city: "REDFORD",
      state: "NY"
    },
    {
      city: "REDFOX",
      state: "KY"
    },
    {
      city: "REDGRANITE",
      state: "WI"
    },
    {
      city: "REDKEY",
      state: "IN"
    },
    {
      city: "REDLAKE",
      state: "MN"
    },
    {
      city: "REDLANDS",
      state: "CA"
    },
    {
      city: "REDMON",
      state: "IL"
    },
    {
      city: "REDMOND",
      state: "OR"
    },
    {
      city: "REDMOND",
      state: "UT"
    },
    {
      city: "REDMOND",
      state: "WA"
    },
    {
      city: "REDONDO BEACH",
      state: "CA"
    },
    {
      city: "REDROCK",
      state: "NM"
    },
    {
      city: "REDSTONE",
      state: "MT"
    },
    {
      city: "REDVALE",
      state: "CO"
    },
    {
      city: "REDWATER",
      state: "TX"
    },
    {
      city: "REDWAY",
      state: "CA"
    },
    {
      city: "REDWOOD",
      state: "MS"
    },
    {
      city: "REDWOOD",
      state: "NY"
    },
    {
      city: "REDWOOD CITY",
      state: "CA"
    },
    {
      city: "REDWOOD ESTATES",
      state: "CA"
    },
    {
      city: "REDWOOD FALLS",
      state: "MN"
    },
    {
      city: "REDWOOD VALLEY",
      state: "CA"
    },
    {
      city: "REE HEIGHTS",
      state: "SD"
    },
    {
      city: "REED",
      state: "KY"
    },
    {
      city: "REED CITY",
      state: "MI"
    },
    {
      city: "REED POINT",
      state: "MT"
    },
    {
      city: "REEDER",
      state: "ND"
    },
    {
      city: "REEDERS",
      state: "PA"
    },
    {
      city: "REEDLEY",
      state: "CA"
    },
    {
      city: "REEDS",
      state: "MO"
    },
    {
      city: "REEDS SPRING",
      state: "MO"
    },
    {
      city: "REEDSBURG",
      state: "WI"
    },
    {
      city: "REEDSPORT",
      state: "OR"
    },
    {
      city: "REEDSVILLE",
      state: "OH"
    },
    {
      city: "REEDSVILLE",
      state: "PA"
    },
    {
      city: "REEDSVILLE",
      state: "WI"
    },
    {
      city: "REEDSVILLE",
      state: "WV"
    },
    {
      city: "REEDVILLE",
      state: "VA"
    },
    {
      city: "REEDY",
      state: "WV"
    },
    {
      city: "REELSVILLE",
      state: "IN"
    },
    {
      city: "REESE",
      state: "MI"
    },
    {
      city: "REESEVILLE",
      state: "WI"
    },
    {
      city: "REEVES",
      state: "LA"
    },
    {
      city: "REEVESVILLE",
      state: "SC"
    },
    {
      city: "REFORM",
      state: "AL"
    },
    {
      city: "REFTON",
      state: "PA"
    },
    {
      city: "REFUGIO",
      state: "TX"
    },
    {
      city: "REGAN",
      state: "ND"
    },
    {
      city: "REGENT",
      state: "ND"
    },
    {
      city: "REGINA",
      state: "KY"
    },
    {
      city: "REGINA",
      state: "NM"
    },
    {
      city: "REGISTER",
      state: "GA"
    },
    {
      city: "REGO PARK",
      state: "NY"
    },
    {
      city: "REHOBETH",
      state: "MD"
    },
    {
      city: "REHOBOTH",
      state: "MA"
    },
    {
      city: "REHOBOTH",
      state: "NM"
    },
    {
      city: "REHOBOTH BEACH",
      state: "DE"
    },
    {
      city: "REHRERSBURG",
      state: "PA"
    },
    {
      city: "REIDSVILLE",
      state: "GA"
    },
    {
      city: "REIDSVILLE",
      state: "NC"
    },
    {
      city: "REIDVILLE",
      state: "SC"
    },
    {
      city: "REINBECK",
      state: "IA"
    },
    {
      city: "REINHOLDS",
      state: "PA"
    },
    {
      city: "REISTERSTOWN",
      state: "MD"
    },
    {
      city: "REKLAW",
      state: "TX"
    },
    {
      city: "RELIANCE",
      state: "SD"
    },
    {
      city: "RELIANCE",
      state: "TN"
    },
    {
      city: "RELIANCE",
      state: "WY"
    },
    {
      city: "REMBERT",
      state: "SC"
    },
    {
      city: "REMBRANDT",
      state: "IA"
    },
    {
      city: "REMER",
      state: "MN"
    },
    {
      city: "REMINGTON",
      state: "IN"
    },
    {
      city: "REMINGTON",
      state: "VA"
    },
    {
      city: "REMLAP",
      state: "AL"
    },
    {
      city: "REMSEN",
      state: "IA"
    },
    {
      city: "REMSEN",
      state: "NY"
    },
    {
      city: "REMSENBURG",
      state: "NY"
    },
    {
      city: "REMUS",
      state: "MI"
    },
    {
      city: "RENA LARA",
      state: "MS"
    },
    {
      city: "RENAULT",
      state: "IL"
    },
    {
      city: "RENFREW",
      state: "PA"
    },
    {
      city: "RENFRO VALLEY",
      state: "KY"
    },
    {
      city: "RENICK",
      state: "WV"
    },
    {
      city: "RENNER",
      state: "SD"
    },
    {
      city: "RENO",
      state: "NV"
    },
    {
      city: "RENO",
      state: "OH"
    },
    {
      city: "RENO",
      state: "PA"
    },
    {
      city: "RENOVO",
      state: "PA"
    },
    {
      city: "RENSSELAER",
      state: "IN"
    },
    {
      city: "RENSSELAER",
      state: "NY"
    },
    {
      city: "RENSSELAER FALLS",
      state: "NY"
    },
    {
      city: "RENSSELAERVILLE",
      state: "NY"
    },
    {
      city: "RENTON",
      state: "WA"
    },
    {
      city: "RENTZ",
      state: "GA"
    },
    {
      city: "RENVILLE",
      state: "MN"
    },
    {
      city: "RENWICK",
      state: "IA"
    },
    {
      city: "REPRESA",
      state: "CA"
    },
    {
      city: "REPTON",
      state: "AL"
    },
    {
      city: "REPUBLIC",
      state: "KS"
    },
    {
      city: "REPUBLIC",
      state: "MI"
    },
    {
      city: "REPUBLIC",
      state: "MO"
    },
    {
      city: "REPUBLIC",
      state: "OH"
    },
    {
      city: "REPUBLIC",
      state: "PA"
    },
    {
      city: "REPUBLIC",
      state: "WA"
    },
    {
      city: "REPUBLICAN CITY",
      state: "NE"
    },
    {
      city: "RESACA",
      state: "GA"
    },
    {
      city: "RESCUE",
      state: "CA"
    },
    {
      city: "RESCUE",
      state: "VA"
    },
    {
      city: "RESEDA",
      state: "CA"
    },
    {
      city: "RESERVE",
      state: "LA"
    },
    {
      city: "RESERVE",
      state: "MT"
    },
    {
      city: "RESERVE",
      state: "NM"
    },
    {
      city: "RESTON",
      state: "VA"
    },
    {
      city: "RETSOF",
      state: "NY"
    },
    {
      city: "REUBENS",
      state: "ID"
    },
    {
      city: "REVA",
      state: "SD"
    },
    {
      city: "REVA",
      state: "VA"
    },
    {
      city: "REVELO",
      state: "KY"
    },
    {
      city: "REVERE",
      state: "MA"
    },
    {
      city: "REVERE",
      state: "MN"
    },
    {
      city: "REVERE",
      state: "MO"
    },
    {
      city: "REVERE",
      state: "PA"
    },
    {
      city: "REVILLO",
      state: "SD"
    },
    {
      city: "REVLOC",
      state: "PA"
    },
    {
      city: "REW",
      state: "PA"
    },
    {
      city: "REWEY",
      state: "WI"
    },
    {
      city: "REX",
      state: "GA"
    },
    {
      city: "REX",
      state: "NC"
    },
    {
      city: "REXBURG",
      state: "ID"
    },
    {
      city: "REXFORD",
      state: "KS"
    },
    {
      city: "REXFORD",
      state: "MT"
    },
    {
      city: "REXFORD",
      state: "NY"
    },
    {
      city: "REXMONT",
      state: "PA"
    },
    {
      city: "REXVILLE",
      state: "NY"
    },
    {
      city: "REYDON",
      state: "OK"
    },
    {
      city: "REYNO",
      state: "AR"
    },
    {
      city: "REYNOLDS",
      state: "GA"
    },
    {
      city: "REYNOLDS",
      state: "IL"
    },
    {
      city: "REYNOLDS",
      state: "IN"
    },
    {
      city: "REYNOLDS",
      state: "MO"
    },
    {
      city: "REYNOLDS",
      state: "ND"
    },
    {
      city: "REYNOLDS",
      state: "NE"
    },
    {
      city: "REYNOLDS STATION",
      state: "KY"
    },
    {
      city: "REYNOLDSBURG",
      state: "OH"
    },
    {
      city: "REYNOLDSVILLE",
      state: "PA"
    },
    {
      city: "REYNOLDSVILLE",
      state: "WV"
    },
    {
      city: "RHAME",
      state: "ND"
    },
    {
      city: "RHEEMS",
      state: "PA"
    },
    {
      city: "RHINE",
      state: "GA"
    },
    {
      city: "RHINEBECK",
      state: "NY"
    },
    {
      city: "RHINECLIFF",
      state: "NY"
    },
    {
      city: "RHINEHART",
      state: "LA"
    },
    {
      city: "RHINELAND",
      state: "MO"
    },
    {
      city: "RHINELANDER",
      state: "WI"
    },
    {
      city: "RHOADESVILLE",
      state: "VA"
    },
    {
      city: "RHODELIA",
      state: "KY"
    },
    {
      city: "RHODES",
      state: "IA"
    },
    {
      city: "RHODES",
      state: "MI"
    },
    {
      city: "RHODESDALE",
      state: "MD"
    },
    {
      city: "RHODHISS",
      state: "NC"
    },
    {
      city: "RHODODENDRON",
      state: "OR"
    },
    {
      city: "RHOME",
      state: "TX"
    },
    {
      city: "RIALTO",
      state: "CA"
    },
    {
      city: "RIB LAKE",
      state: "WI"
    },
    {
      city: "RIBERA",
      state: "NM"
    },
    {
      city: "RICE",
      state: "MN"
    },
    {
      city: "RICE",
      state: "TX"
    },
    {
      city: "RICE",
      state: "VA"
    },
    {
      city: "RICE",
      state: "WA"
    },
    {
      city: "RICE LAKE",
      state: "WI"
    },
    {
      city: "RICEBORO",
      state: "GA"
    },
    {
      city: "RICES LANDING",
      state: "PA"
    },
    {
      city: "RICEVILLE",
      state: "IA"
    },
    {
      city: "RICEVILLE",
      state: "TN"
    },
    {
      city: "RICH CREEK",
      state: "VA"
    },
    {
      city: "RICH HILL",
      state: "MO"
    },
    {
      city: "RICH SQUARE",
      state: "NC"
    },
    {
      city: "RICHARDS",
      state: "MO"
    },
    {
      city: "RICHARDS",
      state: "TX"
    },
    {
      city: "RICHARDSON",
      state: "TX"
    },
    {
      city: "RICHARDSVILLE",
      state: "VA"
    },
    {
      city: "RICHARDTON",
      state: "ND"
    },
    {
      city: "RICHBORO",
      state: "PA"
    },
    {
      city: "RICHBURG",
      state: "NY"
    },
    {
      city: "RICHBURG",
      state: "SC"
    },
    {
      city: "RICHEY",
      state: "MT"
    },
    {
      city: "RICHEYVILLE",
      state: "PA"
    },
    {
      city: "RICHFIELD",
      state: "ID"
    },
    {
      city: "RICHFIELD",
      state: "KS"
    },
    {
      city: "RICHFIELD",
      state: "NC"
    },
    {
      city: "RICHFIELD",
      state: "OH"
    },
    {
      city: "RICHFIELD",
      state: "PA"
    },
    {
      city: "RICHFIELD",
      state: "UT"
    },
    {
      city: "RICHFIELD",
      state: "WI"
    },
    {
      city: "RICHFIELD SPRINGS",
      state: "NY"
    },
    {
      city: "RICHFORD",
      state: "NY"
    },
    {
      city: "RICHFORD",
      state: "VT"
    },
    {
      city: "RICHGROVE",
      state: "CA"
    },
    {
      city: "RICHLAND",
      state: "GA"
    },
    {
      city: "RICHLAND",
      state: "IA"
    },
    {
      city: "RICHLAND",
      state: "IN"
    },
    {
      city: "RICHLAND",
      state: "MI"
    },
    {
      city: "RICHLAND",
      state: "MO"
    },
    {
      city: "RICHLAND",
      state: "MS"
    },
    {
      city: "RICHLAND",
      state: "MT"
    },
    {
      city: "RICHLAND",
      state: "NJ"
    },
    {
      city: "RICHLAND",
      state: "NY"
    },
    {
      city: "RICHLAND",
      state: "OR"
    },
    {
      city: "RICHLAND",
      state: "PA"
    },
    {
      city: "RICHLAND",
      state: "SC"
    },
    {
      city: "RICHLAND",
      state: "TX"
    },
    {
      city: "RICHLAND",
      state: "WA"
    },
    {
      city: "RICHLAND CENTER",
      state: "WI"
    },
    {
      city: "RICHLAND SPRINGS",
      state: "TX"
    },
    {
      city: "RICHLANDS",
      state: "NC"
    },
    {
      city: "RICHLANDS",
      state: "VA"
    },
    {
      city: "RICHLANDTOWN",
      state: "PA"
    },
    {
      city: "RICHMOND",
      state: "CA"
    },
    {
      city: "RICHMOND",
      state: "IL"
    },
    {
      city: "RICHMOND",
      state: "IN"
    },
    {
      city: "RICHMOND",
      state: "KS"
    },
    {
      city: "RICHMOND",
      state: "KY"
    },
    {
      city: "RICHMOND",
      state: "MA"
    },
    {
      city: "RICHMOND",
      state: "ME"
    },
    {
      city: "RICHMOND",
      state: "MI"
    },
    {
      city: "RICHMOND",
      state: "MN"
    },
    {
      city: "RICHMOND",
      state: "MO"
    },
    {
      city: "RICHMOND",
      state: "OH"
    },
    {
      city: "RICHMOND",
      state: "TX"
    },
    {
      city: "RICHMOND",
      state: "UT"
    },
    {
      city: "RICHMOND",
      state: "VA"
    },
    {
      city: "RICHMOND",
      state: "VT"
    },
    {
      city: "RICHMOND DALE",
      state: "OH"
    },
    {
      city: "RICHMOND HILL",
      state: "GA"
    },
    {
      city: "RICHMOND HILL",
      state: "NY"
    },
    {
      city: "RICHMONDVILLE",
      state: "NY"
    },
    {
      city: "RICHTON",
      state: "MS"
    },
    {
      city: "RICHTON PARK",
      state: "IL"
    },
    {
      city: "RICHVALE",
      state: "CA"
    },
    {
      city: "RICHVIEW",
      state: "IL"
    },
    {
      city: "RICHVILLE",
      state: "MI"
    },
    {
      city: "RICHVILLE",
      state: "MN"
    },
    {
      city: "RICHVILLE",
      state: "NY"
    },
    {
      city: "RICHWOOD",
      state: "MN"
    },
    {
      city: "RICHWOOD",
      state: "NJ"
    },
    {
      city: "RICHWOOD",
      state: "OH"
    },
    {
      city: "RICHWOOD",
      state: "WV"
    },
    {
      city: "RICHWOODS",
      state: "MO"
    },
    {
      city: "RICKMAN",
      state: "TN"
    },
    {
      city: "RICKREALL",
      state: "OR"
    },
    {
      city: "RICO",
      state: "CO"
    },
    {
      city: "RIDDLE",
      state: "OR"
    },
    {
      city: "RIDDLESBURG",
      state: "PA"
    },
    {
      city: "RIDDLETON",
      state: "TN"
    },
    {
      city: "RIDERWOOD",
      state: "MD"
    },
    {
      city: "RIDGE",
      state: "MD"
    },
    {
      city: "RIDGE",
      state: "NY"
    },
    {
      city: "RIDGE FARM",
      state: "IL"
    },
    {
      city: "RIDGE SPRING",
      state: "SC"
    },
    {
      city: "RIDGECREST",
      state: "CA"
    },
    {
      city: "RIDGECREST",
      state: "NC"
    },
    {
      city: "RIDGEDALE",
      state: "MO"
    },
    {
      city: "RIDGEFIELD",
      state: "CT"
    },
    {
      city: "RIDGEFIELD",
      state: "NJ"
    },
    {
      city: "RIDGEFIELD",
      state: "WA"
    },
    {
      city: "RIDGEFIELD PARK",
      state: "NJ"
    },
    {
      city: "RIDGELAND",
      state: "MS"
    },
    {
      city: "RIDGELAND",
      state: "SC"
    },
    {
      city: "RIDGELAND",
      state: "WI"
    },
    {
      city: "RIDGELEY",
      state: "WV"
    },
    {
      city: "RIDGELY",
      state: "MD"
    },
    {
      city: "RIDGELY",
      state: "TN"
    },
    {
      city: "RIDGETOP",
      state: "TN"
    },
    {
      city: "RIDGEVILLE",
      state: "IN"
    },
    {
      city: "RIDGEVILLE",
      state: "SC"
    },
    {
      city: "RIDGEVILLE CORNERS",
      state: "OH"
    },
    {
      city: "RIDGEWAY",
      state: "IA"
    },
    {
      city: "RIDGEWAY",
      state: "MO"
    },
    {
      city: "RIDGEWAY",
      state: "NC"
    },
    {
      city: "RIDGEWAY",
      state: "OH"
    },
    {
      city: "RIDGEWAY",
      state: "SC"
    },
    {
      city: "RIDGEWAY",
      state: "VA"
    },
    {
      city: "RIDGEWAY",
      state: "WI"
    },
    {
      city: "RIDGEWAY",
      state: "WV"
    },
    {
      city: "RIDGEWOOD",
      state: "NJ"
    },
    {
      city: "RIDGEWOOD",
      state: "NY"
    },
    {
      city: "RIDGWAY",
      state: "CO"
    },
    {
      city: "RIDGWAY",
      state: "IL"
    },
    {
      city: "RIDGWAY",
      state: "PA"
    },
    {
      city: "RIDLEY PARK",
      state: "PA"
    },
    {
      city: "RIDOTT",
      state: "IL"
    },
    {
      city: "RIEGELSVILLE",
      state: "PA"
    },
    {
      city: "RIEGELWOOD",
      state: "NC"
    },
    {
      city: "RIENZI",
      state: "MS"
    },
    {
      city: "RIESEL",
      state: "TX"
    },
    {
      city: "RIFLE",
      state: "CO"
    },
    {
      city: "RIFTON",
      state: "NY"
    },
    {
      city: "RIGA",
      state: "MI"
    },
    {
      city: "RIGBY",
      state: "ID"
    },
    {
      city: "RIGGINS",
      state: "ID"
    },
    {
      city: "RILEY",
      state: "IN"
    },
    {
      city: "RILEY",
      state: "KS"
    },
    {
      city: "RILEY",
      state: "OR"
    },
    {
      city: "RILEYVILLE",
      state: "VA"
    },
    {
      city: "RILLITO",
      state: "AZ"
    },
    {
      city: "RILLTON",
      state: "PA"
    },
    {
      city: "RIMERSBURG",
      state: "PA"
    },
    {
      city: "RIMFOREST",
      state: "CA"
    },
    {
      city: "RIMROCK",
      state: "AZ"
    },
    {
      city: "RINARD",
      state: "IL"
    },
    {
      city: "RINCON",
      state: "GA"
    },
    {
      city: "RINCON",
      state: "NM"
    },
    {
      city: "RINDGE",
      state: "NH"
    },
    {
      city: "RINER",
      state: "VA"
    },
    {
      city: "RINEYVILLE",
      state: "KY"
    },
    {
      city: "RINGGOLD",
      state: "GA"
    },
    {
      city: "RINGGOLD",
      state: "LA"
    },
    {
      city: "RINGGOLD",
      state: "PA"
    },
    {
      city: "RINGGOLD",
      state: "TX"
    },
    {
      city: "RINGGOLD",
      state: "VA"
    },
    {
      city: "RINGLE",
      state: "WI"
    },
    {
      city: "RINGLING",
      state: "MT"
    },
    {
      city: "RINGLING",
      state: "OK"
    },
    {
      city: "RINGOES",
      state: "NJ"
    },
    {
      city: "RINGOLD",
      state: "OK"
    },
    {
      city: "RINGSTED",
      state: "IA"
    },
    {
      city: "RINGTOWN",
      state: "PA"
    },
    {
      city: "RINGWOOD",
      state: "IL"
    },
    {
      city: "RINGWOOD",
      state: "NJ"
    },
    {
      city: "RINGWOOD",
      state: "OK"
    },
    {
      city: "RIO",
      state: "IL"
    },
    {
      city: "RIO",
      state: "WI"
    },
    {
      city: "RIO",
      state: "WV"
    },
    {
      city: "RIO DELL",
      state: "CA"
    },
    {
      city: "RIO FRIO",
      state: "TX"
    },
    {
      city: "RIO GRANDE",
      state: "NJ"
    },
    {
      city: "RIO GRANDE",
      state: "OH"
    },
    {
      city: "RIO GRANDE CITY",
      state: "TX"
    },
    {
      city: "RIO HONDO",
      state: "TX"
    },
    {
      city: "RIO LINDA",
      state: "CA"
    },
    {
      city: "RIO MEDINA",
      state: "TX"
    },
    {
      city: "RIO NIDO",
      state: "CA"
    },
    {
      city: "RIO OSO",
      state: "CA"
    },
    {
      city: "RIO RANCHO",
      state: "NM"
    },
    {
      city: "RIO RICO",
      state: "AZ"
    },
    {
      city: "RIO VERDE",
      state: "AZ"
    },
    {
      city: "RIO VISTA",
      state: "CA"
    },
    {
      city: "RIO VISTA",
      state: "TX"
    },
    {
      city: "RION",
      state: "SC"
    },
    {
      city: "RIPARIUS",
      state: "NY"
    },
    {
      city: "RIPLEY",
      state: "MS"
    },
    {
      city: "RIPLEY",
      state: "NY"
    },
    {
      city: "RIPLEY",
      state: "OH"
    },
    {
      city: "RIPLEY",
      state: "OK"
    },
    {
      city: "RIPLEY",
      state: "TN"
    },
    {
      city: "RIPLEY",
      state: "WV"
    },
    {
      city: "RIPON",
      state: "CA"
    },
    {
      city: "RIPON",
      state: "WI"
    },
    {
      city: "RIPPEY",
      state: "IA"
    },
    {
      city: "RIPPLEMEAD",
      state: "VA"
    },
    {
      city: "RIPPON",
      state: "WV"
    },
    {
      city: "RIPTON",
      state: "VT"
    },
    {
      city: "RIRIE",
      state: "ID"
    },
    {
      city: "RISCO",
      state: "MO"
    },
    {
      city: "RISING CITY",
      state: "NE"
    },
    {
      city: "RISING FAWN",
      state: "GA"
    },
    {
      city: "RISING STAR",
      state: "TX"
    },
    {
      city: "RISING SUN",
      state: "IN"
    },
    {
      city: "RISING SUN",
      state: "MD"
    },
    {
      city: "RISINGSUN",
      state: "OH"
    },
    {
      city: "RISON",
      state: "AR"
    },
    {
      city: "RITTMAN",
      state: "OH"
    },
    {
      city: "RITZVILLE",
      state: "WA"
    },
    {
      city: "RIVA",
      state: "MD"
    },
    {
      city: "RIVER",
      state: "KY"
    },
    {
      city: "RIVER EDGE",
      state: "NJ"
    },
    {
      city: "RIVER FALLS",
      state: "AL"
    },
    {
      city: "RIVER FALLS",
      state: "WI"
    },
    {
      city: "RIVER FOREST",
      state: "IL"
    },
    {
      city: "RIVER GROVE",
      state: "IL"
    },
    {
      city: "RIVER PINES",
      state: "CA"
    },
    {
      city: "RIVER RANCH",
      state: "FL"
    },
    {
      city: "RIVER ROUGE",
      state: "MI"
    },
    {
      city: "RIVERBANK",
      state: "CA"
    },
    {
      city: "RIVERDALE",
      state: "CA"
    },
    {
      city: "RIVERDALE",
      state: "GA"
    },
    {
      city: "RIVERDALE",
      state: "IL"
    },
    {
      city: "RIVERDALE",
      state: "MD"
    },
    {
      city: "RIVERDALE",
      state: "MI"
    },
    {
      city: "RIVERDALE",
      state: "ND"
    },
    {
      city: "RIVERDALE",
      state: "NE"
    },
    {
      city: "RIVERDALE",
      state: "NJ"
    },
    {
      city: "RIVERHEAD",
      state: "NY"
    },
    {
      city: "RIVERSIDE",
      state: "AL"
    },
    {
      city: "RIVERSIDE",
      state: "CA"
    },
    {
      city: "RIVERSIDE",
      state: "CT"
    },
    {
      city: "RIVERSIDE",
      state: "IA"
    },
    {
      city: "RIVERSIDE",
      state: "IL"
    },
    {
      city: "RIVERSIDE",
      state: "MI"
    },
    {
      city: "RIVERSIDE",
      state: "MO"
    },
    {
      city: "RIVERSIDE",
      state: "NJ"
    },
    {
      city: "RIVERSIDE",
      state: "PA"
    },
    {
      city: "RIVERSIDE",
      state: "RI"
    },
    {
      city: "RIVERSIDE",
      state: "TX"
    },
    {
      city: "RIVERSIDE",
      state: "UT"
    },
    {
      city: "RIVERSIDE",
      state: "WA"
    },
    {
      city: "RIVERTON",
      state: "CT"
    },
    {
      city: "RIVERTON",
      state: "IA"
    },
    {
      city: "RIVERTON",
      state: "IL"
    },
    {
      city: "RIVERTON",
      state: "KS"
    },
    {
      city: "RIVERTON",
      state: "NE"
    },
    {
      city: "RIVERTON",
      state: "NJ"
    },
    {
      city: "RIVERTON",
      state: "UT"
    },
    {
      city: "RIVERTON",
      state: "WV"
    },
    {
      city: "RIVERTON",
      state: "WY"
    },
    {
      city: "RIVERVIEW",
      state: "FL"
    },
    {
      city: "RIVERVIEW",
      state: "MI"
    },
    {
      city: "RIVES",
      state: "TN"
    },
    {
      city: "RIVES JUNCTION",
      state: "MI"
    },
    {
      city: "RIVESVILLE",
      state: "WV"
    },
    {
      city: "RIVIERA",
      state: "TX"
    },
    {
      city: "RIXEYVILLE",
      state: "VA"
    },
    {
      city: "RIXFORD",
      state: "PA"
    },
    {
      city: "ROACH",
      state: "MO"
    },
    {
      city: "ROACHDALE",
      state: "IN"
    },
    {
      city: "ROAN MOUNTAIN",
      state: "TN"
    },
    {
      city: "ROANN",
      state: "IN"
    },
    {
      city: "ROANOKE",
      state: "AL"
    },
    {
      city: "ROANOKE",
      state: "IL"
    },
    {
      city: "ROANOKE",
      state: "IN"
    },
    {
      city: "ROANOKE",
      state: "LA"
    },
    {
      city: "ROANOKE",
      state: "TX"
    },
    {
      city: "ROANOKE",
      state: "VA"
    },
    {
      city: "ROANOKE RAPIDS",
      state: "NC"
    },
    {
      city: "ROANS PRAIRIE",
      state: "TX"
    },
    {
      city: "ROARING BRANCH",
      state: "PA"
    },
    {
      city: "ROARING GAP",
      state: "NC"
    },
    {
      city: "ROARING RIVER",
      state: "NC"
    },
    {
      city: "ROARING SPRING",
      state: "PA"
    },
    {
      city: "ROARING SPRINGS",
      state: "TX"
    },
    {
      city: "ROARK",
      state: "KY"
    },
    {
      city: "ROBARDS",
      state: "KY"
    },
    {
      city: "ROBBINS",
      state: "CA"
    },
    {
      city: "ROBBINS",
      state: "IL"
    },
    {
      city: "ROBBINS",
      state: "NC"
    },
    {
      city: "ROBBINS",
      state: "TN"
    },
    {
      city: "ROBBINSTON",
      state: "ME"
    },
    {
      city: "ROBBINSVILLE",
      state: "NC"
    },
    {
      city: "ROBELINE",
      state: "LA"
    },
    {
      city: "ROBERSONVILLE",
      state: "NC"
    },
    {
      city: "ROBERT",
      state: "LA"
    },
    {
      city: "ROBERT LEE",
      state: "TX"
    },
    {
      city: "ROBERTA",
      state: "GA"
    },
    {
      city: "ROBERTS",
      state: "ID"
    },
    {
      city: "ROBERTS",
      state: "IL"
    },
    {
      city: "ROBERTS",
      state: "MT"
    },
    {
      city: "ROBERTS",
      state: "WI"
    },
    {
      city: "ROBERTSDALE",
      state: "AL"
    },
    {
      city: "ROBERTSDALE",
      state: "PA"
    },
    {
      city: "ROBERTSON",
      state: "WY"
    },
    {
      city: "ROBERTSVILLE",
      state: "MO"
    },
    {
      city: "ROBERTSVILLE",
      state: "OH"
    },
    {
      city: "ROBESONIA",
      state: "PA"
    },
    {
      city: "ROBINS",
      state: "IA"
    },
    {
      city: "ROBINSON",
      state: "IL"
    },
    {
      city: "ROBINSON",
      state: "KS"
    },
    {
      city: "ROBINSON",
      state: "ND"
    },
    {
      city: "ROBINSON",
      state: "PA"
    },
    {
      city: "ROBINSON CREEK",
      state: "KY"
    },
    {
      city: "ROBINSONVILLE",
      state: "MS"
    },
    {
      city: "ROBSON",
      state: "WV"
    },
    {
      city: "ROBSTOWN",
      state: "TX"
    },
    {
      city: "ROBY",
      state: "MO"
    },
    {
      city: "ROBY",
      state: "TX"
    },
    {
      city: "ROCA",
      state: "NE"
    },
    {
      city: "ROCHDALE",
      state: "MA"
    },
    {
      city: "ROCHELLE",
      state: "GA"
    },
    {
      city: "ROCHELLE",
      state: "IL"
    },
    {
      city: "ROCHELLE",
      state: "TX"
    },
    {
      city: "ROCHELLE",
      state: "VA"
    },
    {
      city: "ROCHELLE PARK",
      state: "NJ"
    },
    {
      city: "ROCHEPORT",
      state: "MO"
    },
    {
      city: "ROCHERT",
      state: "MN"
    },
    {
      city: "ROCHESTER",
      state: "IL"
    },
    {
      city: "ROCHESTER",
      state: "IN"
    },
    {
      city: "ROCHESTER",
      state: "KY"
    },
    {
      city: "ROCHESTER",
      state: "MA"
    },
    {
      city: "ROCHESTER",
      state: "MI"
    },
    {
      city: "ROCHESTER",
      state: "MN"
    },
    {
      city: "ROCHESTER",
      state: "NH"
    },
    {
      city: "ROCHESTER",
      state: "NY"
    },
    {
      city: "ROCHESTER",
      state: "PA"
    },
    {
      city: "ROCHESTER",
      state: "TX"
    },
    {
      city: "ROCHESTER",
      state: "VT"
    },
    {
      city: "ROCHESTER",
      state: "WA"
    },
    {
      city: "ROCHESTER",
      state: "WI"
    },
    {
      city: "ROCHESTER MILLS",
      state: "PA"
    },
    {
      city: "ROCIADA",
      state: "NM"
    },
    {
      city: "ROCK",
      state: "KS"
    },
    {
      city: "ROCK",
      state: "MI"
    },
    {
      city: "ROCK",
      state: "WV"
    },
    {
      city: "ROCK CAVE",
      state: "WV"
    },
    {
      city: "ROCK CITY",
      state: "IL"
    },
    {
      city: "ROCK CITY FALLS",
      state: "NY"
    },
    {
      city: "ROCK CREEK",
      state: "MN"
    },
    {
      city: "ROCK CREEK",
      state: "OH"
    },
    {
      city: "ROCK CREEK",
      state: "WV"
    },
    {
      city: "ROCK FALLS",
      state: "IA"
    },
    {
      city: "ROCK FALLS",
      state: "IL"
    },
    {
      city: "ROCK FALLS",
      state: "WI"
    },
    {
      city: "ROCK GLEN",
      state: "PA"
    },
    {
      city: "ROCK HALL",
      state: "MD"
    },
    {
      city: "ROCK HILL",
      state: "NY"
    },
    {
      city: "ROCK HILL",
      state: "SC"
    },
    {
      city: "ROCK ISLAND",
      state: "IL"
    },
    {
      city: "ROCK ISLAND",
      state: "TN"
    },
    {
      city: "ROCK ISLAND",
      state: "TX"
    },
    {
      city: "ROCK ISLAND",
      state: "WA"
    },
    {
      city: "ROCK POINT",
      state: "AZ"
    },
    {
      city: "ROCK PORT",
      state: "MO"
    },
    {
      city: "ROCK RAPIDS",
      state: "IA"
    },
    {
      city: "ROCK RIVER",
      state: "WY"
    },
    {
      city: "ROCK SPRING",
      state: "GA"
    },
    {
      city: "ROCK SPRINGS",
      state: "WI"
    },
    {
      city: "ROCK SPRINGS",
      state: "WY"
    },
    {
      city: "ROCK STREAM",
      state: "NY"
    },
    {
      city: "ROCK TAVERN",
      state: "NY"
    },
    {
      city: "ROCK VALLEY",
      state: "IA"
    },
    {
      city: "ROCK VIEW",
      state: "WV"
    },
    {
      city: "ROCKAWAY",
      state: "NJ"
    },
    {
      city: "ROCKAWAY BEACH",
      state: "MO"
    },
    {
      city: "ROCKAWAY BEACH",
      state: "OR"
    },
    {
      city: "ROCKAWAY PARK",
      state: "NY"
    },
    {
      city: "ROCKBRIDGE",
      state: "IL"
    },
    {
      city: "ROCKBRIDGE",
      state: "MO"
    },
    {
      city: "ROCKBRIDGE",
      state: "OH"
    },
    {
      city: "ROCKBRIDGE BATHS",
      state: "VA"
    },
    {
      city: "ROCKDALE",
      state: "TX"
    },
    {
      city: "ROCKFALL",
      state: "CT"
    },
    {
      city: "ROCKFIELD",
      state: "IN"
    },
    {
      city: "ROCKFIELD",
      state: "KY"
    },
    {
      city: "ROCKFORD",
      state: "AL"
    },
    {
      city: "ROCKFORD",
      state: "IA"
    },
    {
      city: "ROCKFORD",
      state: "IL"
    },
    {
      city: "ROCKFORD",
      state: "MI"
    },
    {
      city: "ROCKFORD",
      state: "MN"
    },
    {
      city: "ROCKFORD",
      state: "OH"
    },
    {
      city: "ROCKFORD",
      state: "TN"
    },
    {
      city: "ROCKFORD",
      state: "WA"
    },
    {
      city: "ROCKHILL FURNACE",
      state: "PA"
    },
    {
      city: "ROCKHOLDS",
      state: "KY"
    },
    {
      city: "ROCKHOUSE",
      state: "KY"
    },
    {
      city: "ROCKINGHAM",
      state: "NC"
    },
    {
      city: "ROCKLAKE",
      state: "ND"
    },
    {
      city: "ROCKLAND",
      state: "DE"
    },
    {
      city: "ROCKLAND",
      state: "ID"
    },
    {
      city: "ROCKLAND",
      state: "MA"
    },
    {
      city: "ROCKLAND",
      state: "ME"
    },
    {
      city: "ROCKLAND",
      state: "MI"
    },
    {
      city: "ROCKLAND",
      state: "WI"
    },
    {
      city: "ROCKLEDGE",
      state: "FL"
    },
    {
      city: "ROCKLEDGE",
      state: "GA"
    },
    {
      city: "ROCKLIN",
      state: "CA"
    },
    {
      city: "ROCKMART",
      state: "GA"
    },
    {
      city: "ROCKPORT",
      state: "IL"
    },
    {
      city: "ROCKPORT",
      state: "IN"
    },
    {
      city: "ROCKPORT",
      state: "KY"
    },
    {
      city: "ROCKPORT",
      state: "MA"
    },
    {
      city: "ROCKPORT",
      state: "ME"
    },
    {
      city: "ROCKPORT",
      state: "TX"
    },
    {
      city: "ROCKPORT",
      state: "WA"
    },
    {
      city: "ROCKPORT",
      state: "WV"
    },
    {
      city: "ROCKSPRINGS",
      state: "TX"
    },
    {
      city: "ROCKTON",
      state: "IL"
    },
    {
      city: "ROCKTON",
      state: "PA"
    },
    {
      city: "ROCKVALE",
      state: "CO"
    },
    {
      city: "ROCKVALE",
      state: "TN"
    },
    {
      city: "ROCKVILLE",
      state: "IN"
    },
    {
      city: "ROCKVILLE",
      state: "MD"
    },
    {
      city: "ROCKVILLE",
      state: "MN"
    },
    {
      city: "ROCKVILLE",
      state: "MO"
    },
    {
      city: "ROCKVILLE",
      state: "NE"
    },
    {
      city: "ROCKVILLE",
      state: "RI"
    },
    {
      city: "ROCKVILLE",
      state: "UT"
    },
    {
      city: "ROCKVILLE",
      state: "VA"
    },
    {
      city: "ROCKVILLE CENTRE",
      state: "NY"
    },
    {
      city: "ROCKWALL",
      state: "TX"
    },
    {
      city: "ROCKWELL",
      state: "IA"
    },
    {
      city: "ROCKWELL",
      state: "NC"
    },
    {
      city: "ROCKWELL CITY",
      state: "IA"
    },
    {
      city: "ROCKWOOD",
      state: "IL"
    },
    {
      city: "ROCKWOOD",
      state: "ME"
    },
    {
      city: "ROCKWOOD",
      state: "MI"
    },
    {
      city: "ROCKWOOD",
      state: "PA"
    },
    {
      city: "ROCKWOOD",
      state: "TN"
    },
    {
      city: "ROCKWOOD",
      state: "TX"
    },
    {
      city: "ROCKY",
      state: "OK"
    },
    {
      city: "ROCKY COMFORT",
      state: "MO"
    },
    {
      city: "ROCKY FACE",
      state: "GA"
    },
    {
      city: "ROCKY FORD",
      state: "CO"
    },
    {
      city: "ROCKY FORD",
      state: "GA"
    },
    {
      city: "ROCKY GAP",
      state: "VA"
    },
    {
      city: "ROCKY HILL",
      state: "CT"
    },
    {
      city: "ROCKY HILL",
      state: "KY"
    },
    {
      city: "ROCKY HILL",
      state: "NJ"
    },
    {
      city: "ROCKY MOUNT",
      state: "MO"
    },
    {
      city: "ROCKY MOUNT",
      state: "NC"
    },
    {
      city: "ROCKY MOUNT",
      state: "VA"
    },
    {
      city: "ROCKY POINT",
      state: "NC"
    },
    {
      city: "ROCKY POINT",
      state: "NY"
    },
    {
      city: "ROCKY RIDGE",
      state: "MD"
    },
    {
      city: "ROCKY RIDGE",
      state: "OH"
    },
    {
      city: "ROCKY RIVER",
      state: "OH"
    },
    {
      city: "RODANTHE",
      state: "NC"
    },
    {
      city: "RODEO",
      state: "CA"
    },
    {
      city: "RODEO",
      state: "NM"
    },
    {
      city: "RODERFIELD",
      state: "WV"
    },
    {
      city: "RODESSA",
      state: "LA"
    },
    {
      city: "RODMAN",
      state: "NY"
    },
    {
      city: "RODNEY",
      state: "IA"
    },
    {
      city: "RODNEY",
      state: "MI"
    },
    {
      city: "ROE",
      state: "AR"
    },
    {
      city: "ROEBLING",
      state: "NJ"
    },
    {
      city: "ROEBUCK",
      state: "SC"
    },
    {
      city: "ROFF",
      state: "OK"
    },
    {
      city: "ROGERS",
      state: "AR"
    },
    {
      city: "ROGERS",
      state: "CT"
    },
    {
      city: "ROGERS",
      state: "MN"
    },
    {
      city: "ROGERS",
      state: "ND"
    },
    {
      city: "ROGERS",
      state: "NE"
    },
    {
      city: "ROGERS",
      state: "NM"
    },
    {
      city: "ROGERS",
      state: "OH"
    },
    {
      city: "ROGERS",
      state: "TX"
    },
    {
      city: "ROGERS CITY",
      state: "MI"
    },
    {
      city: "ROGERSON",
      state: "ID"
    },
    {
      city: "ROGERSVILLE",
      state: "AL"
    },
    {
      city: "ROGERSVILLE",
      state: "MO"
    },
    {
      city: "ROGERSVILLE",
      state: "PA"
    },
    {
      city: "ROGERSVILLE",
      state: "TN"
    },
    {
      city: "ROGGEN",
      state: "CO"
    },
    {
      city: "ROGUE RIVER",
      state: "OR"
    },
    {
      city: "ROHNERT PARK",
      state: "CA"
    },
    {
      city: "ROHRERSVILLE",
      state: "MD"
    },
    {
      city: "ROHWER",
      state: "AR"
    },
    {
      city: "ROLAND",
      state: "AR"
    },
    {
      city: "ROLAND",
      state: "IA"
    },
    {
      city: "ROLAND",
      state: "OK"
    },
    {
      city: "ROLESVILLE",
      state: "NC"
    },
    {
      city: "ROLETTE",
      state: "ND"
    },
    {
      city: "ROLFE",
      state: "IA"
    },
    {
      city: "ROLL",
      state: "AZ"
    },
    {
      city: "ROLLA",
      state: "KS"
    },
    {
      city: "ROLLA",
      state: "MO"
    },
    {
      city: "ROLLA",
      state: "ND"
    },
    {
      city: "ROLLING FORK",
      state: "MS"
    },
    {
      city: "ROLLING MEADOWS",
      state: "IL"
    },
    {
      city: "ROLLING PRAIRIE",
      state: "IN"
    },
    {
      city: "ROLLINGBAY",
      state: "WA"
    },
    {
      city: "ROLLINGSTONE",
      state: "MN"
    },
    {
      city: "ROLLINS",
      state: "MT"
    },
    {
      city: "ROLLINS FORK",
      state: "VA"
    },
    {
      city: "ROLLINSFORD",
      state: "NH"
    },
    {
      city: "ROLLINSVILLE",
      state: "CO"
    },
    {
      city: "ROMA",
      state: "TX"
    },
    {
      city: "ROMANCE",
      state: "AR"
    },
    {
      city: "ROMAYOR",
      state: "TX"
    },
    {
      city: "ROME",
      state: "GA"
    },
    {
      city: "ROME",
      state: "IL"
    },
    {
      city: "ROME",
      state: "MS"
    },
    {
      city: "ROME",
      state: "NY"
    },
    {
      city: "ROME",
      state: "OH"
    },
    {
      city: "ROME",
      state: "PA"
    },
    {
      city: "ROME CITY",
      state: "IN"
    },
    {
      city: "ROMEO",
      state: "CO"
    },
    {
      city: "ROMEO",
      state: "MI"
    },
    {
      city: "ROMEOVILLE",
      state: "IL"
    },
    {
      city: "ROMNEY",
      state: "IN"
    },
    {
      city: "ROMNEY",
      state: "WV"
    },
    {
      city: "ROMULUS",
      state: "MI"
    },
    {
      city: "ROMULUS",
      state: "NY"
    },
    {
      city: "RONALD",
      state: "WA"
    },
    {
      city: "RONAN",
      state: "MT"
    },
    {
      city: "RONCEVERTE",
      state: "WV"
    },
    {
      city: "RONDA",
      state: "NC"
    },
    {
      city: "RONKONKOMA",
      state: "NY"
    },
    {
      city: "RONKS",
      state: "PA"
    },
    {
      city: "ROODHOUSE",
      state: "IL"
    },
    {
      city: "ROOPVILLE",
      state: "GA"
    },
    {
      city: "ROOSEVELT",
      state: "AZ"
    },
    {
      city: "ROOSEVELT",
      state: "MN"
    },
    {
      city: "ROOSEVELT",
      state: "NJ"
    },
    {
      city: "ROOSEVELT",
      state: "NY"
    },
    {
      city: "ROOSEVELT",
      state: "OK"
    },
    {
      city: "ROOSEVELT",
      state: "UT"
    },
    {
      city: "ROOSEVELT",
      state: "WA"
    },
    {
      city: "ROOSEVELTOWN",
      state: "NY"
    },
    {
      city: "ROOTSTOWN",
      state: "OH"
    },
    {
      city: "ROPER",
      state: "NC"
    },
    {
      city: "ROPESVILLE",
      state: "TX"
    },
    {
      city: "ROSALIA",
      state: "KS"
    },
    {
      city: "ROSALIA",
      state: "WA"
    },
    {
      city: "ROSAMOND",
      state: "CA"
    },
    {
      city: "ROSAMOND",
      state: "IL"
    },
    {
      city: "ROSANKY",
      state: "TX"
    },
    {
      city: "ROSBURG",
      state: "WA"
    },
    {
      city: "ROSCOE",
      state: "IL"
    },
    {
      city: "ROSCOE",
      state: "MN"
    },
    {
      city: "ROSCOE",
      state: "MO"
    },
    {
      city: "ROSCOE",
      state: "MT"
    },
    {
      city: "ROSCOE",
      state: "NY"
    },
    {
      city: "ROSCOE",
      state: "PA"
    },
    {
      city: "ROSCOE",
      state: "SD"
    },
    {
      city: "ROSCOE",
      state: "TX"
    },
    {
      city: "ROSCOMMON",
      state: "MI"
    },
    {
      city: "ROSE",
      state: "NY"
    },
    {
      city: "ROSE",
      state: "OK"
    },
    {
      city: "ROSE BUD",
      state: "AR"
    },
    {
      city: "ROSE CITY",
      state: "MI"
    },
    {
      city: "ROSE CREEK",
      state: "MN"
    },
    {
      city: "ROSE HILL",
      state: "IA"
    },
    {
      city: "ROSE HILL",
      state: "KS"
    },
    {
      city: "ROSE HILL",
      state: "MS"
    },
    {
      city: "ROSE HILL",
      state: "NC"
    },
    {
      city: "ROSE HILL",
      state: "VA"
    },
    {
      city: "ROSEAU",
      state: "MN"
    },
    {
      city: "ROSEBOOM",
      state: "NY"
    },
    {
      city: "ROSEBORO",
      state: "NC"
    },
    {
      city: "ROSEBUD",
      state: "MO"
    },
    {
      city: "ROSEBUD",
      state: "MT"
    },
    {
      city: "ROSEBUD",
      state: "SD"
    },
    {
      city: "ROSEBUD",
      state: "TX"
    },
    {
      city: "ROSEBURG",
      state: "OR"
    },
    {
      city: "ROSEBUSH",
      state: "MI"
    },
    {
      city: "ROSEDALE",
      state: "IN"
    },
    {
      city: "ROSEDALE",
      state: "LA"
    },
    {
      city: "ROSEDALE",
      state: "MD"
    },
    {
      city: "ROSEDALE",
      state: "MS"
    },
    {
      city: "ROSEDALE",
      state: "NY"
    },
    {
      city: "ROSEDALE",
      state: "VA"
    },
    {
      city: "ROSEDALE",
      state: "WV"
    },
    {
      city: "ROSEGLEN",
      state: "ND"
    },
    {
      city: "ROSELAND",
      state: "FL"
    },
    {
      city: "ROSELAND",
      state: "LA"
    },
    {
      city: "ROSELAND",
      state: "NE"
    },
    {
      city: "ROSELAND",
      state: "NJ"
    },
    {
      city: "ROSELAND",
      state: "VA"
    },
    {
      city: "ROSELAWN",
      state: "IN"
    },
    {
      city: "ROSELLE",
      state: "IL"
    },
    {
      city: "ROSELLE",
      state: "NJ"
    },
    {
      city: "ROSELLE PARK",
      state: "NJ"
    },
    {
      city: "ROSEMARY BEACH",
      state: "FL"
    },
    {
      city: "ROSEMEAD",
      state: "CA"
    },
    {
      city: "ROSEMONT",
      state: "NJ"
    },
    {
      city: "ROSEMOUNT",
      state: "MN"
    },
    {
      city: "ROSENBERG",
      state: "TX"
    },
    {
      city: "ROSENDALE",
      state: "MO"
    },
    {
      city: "ROSENDALE",
      state: "NY"
    },
    {
      city: "ROSENDALE",
      state: "WI"
    },
    {
      city: "ROSENHAYN",
      state: "NJ"
    },
    {
      city: "ROSEPINE",
      state: "LA"
    },
    {
      city: "ROSEVILLE",
      state: "CA"
    },
    {
      city: "ROSEVILLE",
      state: "IL"
    },
    {
      city: "ROSEVILLE",
      state: "MI"
    },
    {
      city: "ROSEVILLE",
      state: "OH"
    },
    {
      city: "ROSEWOOD",
      state: "OH"
    },
    {
      city: "ROSHARON",
      state: "TX"
    },
    {
      city: "ROSHOLT",
      state: "SD"
    },
    {
      city: "ROSHOLT",
      state: "WI"
    },
    {
      city: "ROSICLARE",
      state: "IL"
    },
    {
      city: "ROSIE",
      state: "AR"
    },
    {
      city: "ROSLINDALE",
      state: "MA"
    },
    {
      city: "ROSLYN",
      state: "NY"
    },
    {
      city: "ROSLYN",
      state: "SD"
    },
    {
      city: "ROSLYN",
      state: "WA"
    },
    {
      city: "ROSLYN HEIGHTS",
      state: "NY"
    },
    {
      city: "ROSMAN",
      state: "NC"
    },
    {
      city: "ROSS",
      state: "CA"
    },
    {
      city: "ROSS",
      state: "ND"
    },
    {
      city: "ROSS",
      state: "OH"
    },
    {
      city: "ROSS",
      state: "TX"
    },
    {
      city: "ROSSBURG",
      state: "OH"
    },
    {
      city: "ROSSER",
      state: "TX"
    },
    {
      city: "ROSSFORD",
      state: "OH"
    },
    {
      city: "ROSSITER",
      state: "PA"
    },
    {
      city: "ROSSTON",
      state: "AR"
    },
    {
      city: "ROSSTON",
      state: "OK"
    },
    {
      city: "ROSSTON",
      state: "TX"
    },
    {
      city: "ROSSVILLE",
      state: "GA"
    },
    {
      city: "ROSSVILLE",
      state: "IL"
    },
    {
      city: "ROSSVILLE",
      state: "IN"
    },
    {
      city: "ROSSVILLE",
      state: "KS"
    },
    {
      city: "ROSSVILLE",
      state: "PA"
    },
    {
      city: "ROSSVILLE",
      state: "TN"
    },
    {
      city: "ROSWELL",
      state: "GA"
    },
    {
      city: "ROSWELL",
      state: "NM"
    },
    {
      city: "ROTAN",
      state: "TX"
    },
    {
      city: "ROTHBURY",
      state: "MI"
    },
    {
      city: "ROTHSAY",
      state: "MN"
    },
    {
      city: "ROTHSCHILD",
      state: "WI"
    },
    {
      city: "ROTHVILLE",
      state: "MO"
    },
    {
      city: "ROTONDA WEST",
      state: "FL"
    },
    {
      city: "ROTTERDAM JUNCTION",
      state: "NY"
    },
    {
      city: "ROUGEMONT",
      state: "NC"
    },
    {
      city: "ROUGH AND READY",
      state: "CA"
    },
    {
      city: "ROUGON",
      state: "LA"
    },
    {
      city: "ROULETTE",
      state: "PA"
    },
    {
      city: "ROUND HILL",
      state: "VA"
    },
    {
      city: "ROUND LAKE",
      state: "IL"
    },
    {
      city: "ROUND LAKE",
      state: "MN"
    },
    {
      city: "ROUND LAKE",
      state: "NY"
    },
    {
      city: "ROUND MOUNTAIN",
      state: "CA"
    },
    {
      city: "ROUND MOUNTAIN",
      state: "NV"
    },
    {
      city: "ROUND MOUNTAIN",
      state: "TX"
    },
    {
      city: "ROUND O",
      state: "SC"
    },
    {
      city: "ROUND POND",
      state: "ME"
    },
    {
      city: "ROUND ROCK",
      state: "TX"
    },
    {
      city: "ROUND TOP",
      state: "NY"
    },
    {
      city: "ROUND TOP",
      state: "TX"
    },
    {
      city: "ROUNDHEAD",
      state: "OH"
    },
    {
      city: "ROUNDHILL",
      state: "KY"
    },
    {
      city: "ROUNDUP",
      state: "MT"
    },
    {
      city: "ROUSES POINT",
      state: "NY"
    },
    {
      city: "ROUSEVILLE",
      state: "PA"
    },
    {
      city: "ROUSSEAU",
      state: "KY"
    },
    {
      city: "ROUZERVILLE",
      state: "PA"
    },
    {
      city: "ROVER",
      state: "AR"
    },
    {
      city: "ROWAN",
      state: "IA"
    },
    {
      city: "ROWE",
      state: "MA"
    },
    {
      city: "ROWE",
      state: "NM"
    },
    {
      city: "ROWE",
      state: "VA"
    },
    {
      city: "ROWENA",
      state: "TX"
    },
    {
      city: "ROWESVILLE",
      state: "SC"
    },
    {
      city: "ROWLAND",
      state: "NC"
    },
    {
      city: "ROWLAND",
      state: "PA"
    },
    {
      city: "ROWLAND HEIGHTS",
      state: "CA"
    },
    {
      city: "ROWLESBURG",
      state: "WV"
    },
    {
      city: "ROWLETT",
      state: "TX"
    },
    {
      city: "ROWLEY",
      state: "IA"
    },
    {
      city: "ROWLEY",
      state: "MA"
    },
    {
      city: "ROXANA",
      state: "IL"
    },
    {
      city: "ROXANA",
      state: "KY"
    },
    {
      city: "ROXBORO",
      state: "NC"
    },
    {
      city: "ROXBURY",
      state: "CT"
    },
    {
      city: "ROXBURY",
      state: "KS"
    },
    {
      city: "ROXBURY",
      state: "MA"
    },
    {
      city: "ROXBURY",
      state: "ME"
    },
    {
      city: "ROXBURY",
      state: "NY"
    },
    {
      city: "ROXBURY",
      state: "PA"
    },
    {
      city: "ROXBURY",
      state: "VT"
    },
    {
      city: "ROXBURY CROSSING",
      state: "MA"
    },
    {
      city: "ROXIE",
      state: "MS"
    },
    {
      city: "ROXOBEL",
      state: "NC"
    },
    {
      city: "ROXTON",
      state: "TX"
    },
    {
      city: "ROY",
      state: "MT"
    },
    {
      city: "ROY",
      state: "NM"
    },
    {
      city: "ROY",
      state: "UT"
    },
    {
      city: "ROY",
      state: "WA"
    },
    {
      city: "ROYAL",
      state: "AR"
    },
    {
      city: "ROYAL",
      state: "IA"
    },
    {
      city: "ROYAL",
      state: "IL"
    },
    {
      city: "ROYAL",
      state: "NE"
    },
    {
      city: "ROYAL CENTER",
      state: "IN"
    },
    {
      city: "ROYAL CITY",
      state: "WA"
    },
    {
      city: "ROYAL OAK",
      state: "MD"
    },
    {
      city: "ROYAL OAK",
      state: "MI"
    },
    {
      city: "ROYAL PALM BEACH",
      state: "FL"
    },
    {
      city: "ROYALSTON",
      state: "MA"
    },
    {
      city: "ROYALTON",
      state: "IL"
    },
    {
      city: "ROYALTON",
      state: "KY"
    },
    {
      city: "ROYALTON",
      state: "MN"
    },
    {
      city: "ROYERSFORD",
      state: "PA"
    },
    {
      city: "ROYSE CITY",
      state: "TX"
    },
    {
      city: "ROYSTON",
      state: "GA"
    },
    {
      city: "ROZEL",
      state: "KS"
    },
    {
      city: "ROZET",
      state: "WY"
    },
    {
      city: "RUBICON",
      state: "WI"
    },
    {
      city: "RUBY",
      state: "AK"
    },
    {
      city: "RUBY",
      state: "NY"
    },
    {
      city: "RUBY",
      state: "SC"
    },
    {
      city: "RUBY",
      state: "VA"
    },
    {
      city: "RUCKERSVILLE",
      state: "VA"
    },
    {
      city: "RUDD",
      state: "IA"
    },
    {
      city: "RUDOLPH",
      state: "OH"
    },
    {
      city: "RUDOLPH",
      state: "WI"
    },
    {
      city: "RUDY",
      state: "AR"
    },
    {
      city: "RUDYARD",
      state: "MI"
    },
    {
      city: "RUDYARD",
      state: "MT"
    },
    {
      city: "RUETER",
      state: "MO"
    },
    {
      city: "RUFE",
      state: "OK"
    },
    {
      city: "RUFFIN",
      state: "NC"
    },
    {
      city: "RUFFIN",
      state: "SC"
    },
    {
      city: "RUFFS DALE",
      state: "PA"
    },
    {
      city: "RUFUS",
      state: "OR"
    },
    {
      city: "RUGBY",
      state: "ND"
    },
    {
      city: "RUGBY",
      state: "TN"
    },
    {
      city: "RUIDOSO",
      state: "NM"
    },
    {
      city: "RUIDOSO DOWNS",
      state: "NM"
    },
    {
      city: "RULE",
      state: "TX"
    },
    {
      city: "RULEVILLE",
      state: "MS"
    },
    {
      city: "RULO",
      state: "NE"
    },
    {
      city: "RUMELY",
      state: "MI"
    },
    {
      city: "RUMFORD",
      state: "ME"
    },
    {
      city: "RUMFORD",
      state: "RI"
    },
    {
      city: "RUMNEY",
      state: "NH"
    },
    {
      city: "RUMSEY",
      state: "CA"
    },
    {
      city: "RUMSEY",
      state: "KY"
    },
    {
      city: "RUMSON",
      state: "NJ"
    },
    {
      city: "RUNGE",
      state: "TX"
    },
    {
      city: "RUNNELLS",
      state: "IA"
    },
    {
      city: "RUNNEMEDE",
      state: "NJ"
    },
    {
      city: "RUNNING SPRINGS",
      state: "CA"
    },
    {
      city: "RUPERT",
      state: "GA"
    },
    {
      city: "RUPERT",
      state: "ID"
    },
    {
      city: "RUPERT",
      state: "VT"
    },
    {
      city: "RUPERT",
      state: "WV"
    },
    {
      city: "RURAL HALL",
      state: "NC"
    },
    {
      city: "RURAL RETREAT",
      state: "VA"
    },
    {
      city: "RURAL RIDGE",
      state: "PA"
    },
    {
      city: "RURAL VALLEY",
      state: "PA"
    },
    {
      city: "RUSH",
      state: "CO"
    },
    {
      city: "RUSH",
      state: "KY"
    },
    {
      city: "RUSH",
      state: "NY"
    },
    {
      city: "RUSH CENTER",
      state: "KS"
    },
    {
      city: "RUSH CITY",
      state: "MN"
    },
    {
      city: "RUSH HILL",
      state: "MO"
    },
    {
      city: "RUSH SPRINGS",
      state: "OK"
    },
    {
      city: "RUSH VALLEY",
      state: "UT"
    },
    {
      city: "RUSHFORD",
      state: "MN"
    },
    {
      city: "RUSHFORD",
      state: "NY"
    },
    {
      city: "RUSHLAND",
      state: "PA"
    },
    {
      city: "RUSHMORE",
      state: "MN"
    },
    {
      city: "RUSHSYLVANIA",
      state: "OH"
    },
    {
      city: "RUSHVILLE",
      state: "IL"
    },
    {
      city: "RUSHVILLE",
      state: "IN"
    },
    {
      city: "RUSHVILLE",
      state: "MO"
    },
    {
      city: "RUSHVILLE",
      state: "NE"
    },
    {
      city: "RUSHVILLE",
      state: "NY"
    },
    {
      city: "RUSHVILLE",
      state: "OH"
    },
    {
      city: "RUSK",
      state: "TX"
    },
    {
      city: "RUSKIN",
      state: "FL"
    },
    {
      city: "RUSKIN",
      state: "NE"
    },
    {
      city: "RUSO",
      state: "ND"
    },
    {
      city: "RUSSELL",
      state: "AR"
    },
    {
      city: "RUSSELL",
      state: "IA"
    },
    {
      city: "RUSSELL",
      state: "IL"
    },
    {
      city: "RUSSELL",
      state: "KS"
    },
    {
      city: "RUSSELL",
      state: "KY"
    },
    {
      city: "RUSSELL",
      state: "MA"
    },
    {
      city: "RUSSELL",
      state: "MN"
    },
    {
      city: "RUSSELL",
      state: "NY"
    },
    {
      city: "RUSSELL",
      state: "PA"
    },
    {
      city: "RUSSELL SPRINGS",
      state: "KY"
    },
    {
      city: "RUSSELLS POINT",
      state: "OH"
    },
    {
      city: "RUSSELLTON",
      state: "PA"
    },
    {
      city: "RUSSELLVILLE",
      state: "AL"
    },
    {
      city: "RUSSELLVILLE",
      state: "AR"
    },
    {
      city: "RUSSELLVILLE",
      state: "IN"
    },
    {
      city: "RUSSELLVILLE",
      state: "KY"
    },
    {
      city: "RUSSELLVILLE",
      state: "MO"
    },
    {
      city: "RUSSELLVILLE",
      state: "OH"
    },
    {
      city: "RUSSELLVILLE",
      state: "SC"
    },
    {
      city: "RUSSELLVILLE",
      state: "TN"
    },
    {
      city: "RUSSIA",
      state: "OH"
    },
    {
      city: "RUSSIAN MISSION",
      state: "AK"
    },
    {
      city: "RUSSIAVILLE",
      state: "IN"
    },
    {
      city: "RUSTBURG",
      state: "VA"
    },
    {
      city: "RUSTON",
      state: "LA"
    },
    {
      city: "RUTH",
      state: "MI"
    },
    {
      city: "RUTH",
      state: "MS"
    },
    {
      city: "RUTH",
      state: "NV"
    },
    {
      city: "RUTHER GLEN",
      state: "VA"
    },
    {
      city: "RUTHERFORD",
      state: "CA"
    },
    {
      city: "RUTHERFORD",
      state: "NJ"
    },
    {
      city: "RUTHERFORD",
      state: "TN"
    },
    {
      city: "RUTHERFORD COLLEGE",
      state: "NC"
    },
    {
      city: "RUTHERFORDTON",
      state: "NC"
    },
    {
      city: "RUTHTON",
      state: "MN"
    },
    {
      city: "RUTHVEN",
      state: "IA"
    },
    {
      city: "RUTLAND",
      state: "IA"
    },
    {
      city: "RUTLAND",
      state: "IL"
    },
    {
      city: "RUTLAND",
      state: "MA"
    },
    {
      city: "RUTLAND",
      state: "ND"
    },
    {
      city: "RUTLAND",
      state: "OH"
    },
    {
      city: "RUTLAND",
      state: "SD"
    },
    {
      city: "RUTLAND",
      state: "VT"
    },
    {
      city: "RUTLEDGE",
      state: "AL"
    },
    {
      city: "RUTLEDGE",
      state: "GA"
    },
    {
      city: "RUTLEDGE",
      state: "MO"
    },
    {
      city: "RUTLEDGE",
      state: "TN"
    },
    {
      city: "RYAN",
      state: "IA"
    },
    {
      city: "RYAN",
      state: "OK"
    },
    {
      city: "RYDAL",
      state: "GA"
    },
    {
      city: "RYDER",
      state: "ND"
    },
    {
      city: "RYE",
      state: "CO"
    },
    {
      city: "RYE",
      state: "NH"
    },
    {
      city: "RYE",
      state: "NY"
    },
    {
      city: "RYE",
      state: "TX"
    },
    {
      city: "RYE BEACH",
      state: "NH"
    },
    {
      city: "RYEGATE",
      state: "MT"
    },
    {
      city: "RYLAND",
      state: "AL"
    },
    {
      city: "S COFFEYVILLE",
      state: "OK"
    },
    {
      city: "SABAEL",
      state: "NY"
    },
    {
      city: "SABATTUS",
      state: "ME"
    },
    {
      city: "SABETHA",
      state: "KS"
    },
    {
      city: "SABILLASVILLE",
      state: "MD"
    },
    {
      city: "SABIN",
      state: "MN"
    },
    {
      city: "SABINA",
      state: "OH"
    },
    {
      city: "SABINAL",
      state: "TX"
    },
    {
      city: "SABINE PASS",
      state: "TX"
    },
    {
      city: "SABINSVILLE",
      state: "PA"
    },
    {
      city: "SABULA",
      state: "IA"
    },
    {
      city: "SAC CITY",
      state: "IA"
    },
    {
      city: "SACATON",
      state: "AZ"
    },
    {
      city: "SACHSE",
      state: "TX"
    },
    {
      city: "SACKETS HARBOR",
      state: "NY"
    },
    {
      city: "SACO",
      state: "ME"
    },
    {
      city: "SACO",
      state: "MT"
    },
    {
      city: "SACRAMENTO",
      state: "CA"
    },
    {
      city: "SACRAMENTO",
      state: "KY"
    },
    {
      city: "SACRAMENTO",
      state: "NM"
    },
    {
      city: "SACRAMENTO",
      state: "PA"
    },
    {
      city: "SACRED HEART",
      state: "MN"
    },
    {
      city: "SADDLE BROOK",
      state: "NJ"
    },
    {
      city: "SADDLE RIVER",
      state: "NJ"
    },
    {
      city: "SADDLESTRING",
      state: "WY"
    },
    {
      city: "SADIEVILLE",
      state: "KY"
    },
    {
      city: "SADLER",
      state: "TX"
    },
    {
      city: "SADORUS",
      state: "IL"
    },
    {
      city: "SADSBURYVILLE",
      state: "PA"
    },
    {
      city: "SAEGERTOWN",
      state: "PA"
    },
    {
      city: "SAFETY HARBOR",
      state: "FL"
    },
    {
      city: "SAFFELL",
      state: "AR"
    },
    {
      city: "SAFFORD",
      state: "AL"
    },
    {
      city: "SAFFORD",
      state: "AZ"
    },
    {
      city: "SAG HARBOR",
      state: "NY"
    },
    {
      city: "SAGAMORE",
      state: "MA"
    },
    {
      city: "SAGAMORE",
      state: "PA"
    },
    {
      city: "SAGAMORE BEACH",
      state: "MA"
    },
    {
      city: "SAGAPONACK",
      state: "NY"
    },
    {
      city: "SAGE",
      state: "AR"
    },
    {
      city: "SAGINAW",
      state: "AL"
    },
    {
      city: "SAGINAW",
      state: "MI"
    },
    {
      city: "SAGINAW",
      state: "MN"
    },
    {
      city: "SAGINAW",
      state: "MO"
    },
    {
      city: "SAGLE",
      state: "ID"
    },
    {
      city: "SAGOLA",
      state: "MI"
    },
    {
      city: "SAGUACHE",
      state: "CO"
    },
    {
      city: "SAHUARITA",
      state: "AZ"
    },
    {
      city: "SAILOR SPRINGS",
      state: "IL"
    },
    {
      city: "SAINT AGATHA",
      state: "ME"
    },
    {
      city: "SAINT ALBANS",
      state: "ME"
    },
    {
      city: "SAINT ALBANS",
      state: "MO"
    },
    {
      city: "SAINT ALBANS",
      state: "NY"
    },
    {
      city: "SAINT ALBANS",
      state: "VT"
    },
    {
      city: "SAINT ALBANS",
      state: "WV"
    },
    {
      city: "SAINT ALBANS BAY",
      state: "VT"
    },
    {
      city: "SAINT AMANT",
      state: "LA"
    },
    {
      city: "SAINT ANN",
      state: "MO"
    },
    {
      city: "SAINT ANNE",
      state: "IL"
    },
    {
      city: "SAINT ANSGAR",
      state: "IA"
    },
    {
      city: "SAINT ANTHONY",
      state: "IA"
    },
    {
      city: "SAINT ANTHONY",
      state: "ID"
    },
    {
      city: "SAINT ANTHONY",
      state: "IN"
    },
    {
      city: "SAINT ANTHONY",
      state: "ND"
    },
    {
      city: "SAINT AUGUSTINE",
      state: "FL"
    },
    {
      city: "SAINT AUGUSTINE",
      state: "IL"
    },
    {
      city: "SAINT BENEDICT",
      state: "LA"
    },
    {
      city: "SAINT BENEDICT",
      state: "OR"
    },
    {
      city: "SAINT BENEDICT",
      state: "PA"
    },
    {
      city: "SAINT BERNARD",
      state: "LA"
    },
    {
      city: "SAINT BERNICE",
      state: "IN"
    },
    {
      city: "SAINT BONAVENTURE",
      state: "NY"
    },
    {
      city: "SAINT BONIFACE",
      state: "PA"
    },
    {
      city: "SAINT BONIFACIUS",
      state: "MN"
    },
    {
      city: "SAINT CATHARINE",
      state: "KY"
    },
    {
      city: "SAINT CHARLES",
      state: "AR"
    },
    {
      city: "SAINT CHARLES",
      state: "IA"
    },
    {
      city: "SAINT CHARLES",
      state: "ID"
    },
    {
      city: "SAINT CHARLES",
      state: "IL"
    },
    {
      city: "SAINT CHARLES",
      state: "KY"
    },
    {
      city: "SAINT CHARLES",
      state: "MI"
    },
    {
      city: "SAINT CHARLES",
      state: "MN"
    },
    {
      city: "SAINT CHARLES",
      state: "MO"
    },
    {
      city: "SAINT CHARLES",
      state: "VA"
    },
    {
      city: "SAINT CLAIR",
      state: "MI"
    },
    {
      city: "SAINT CLAIR",
      state: "MN"
    },
    {
      city: "SAINT CLAIR",
      state: "MO"
    },
    {
      city: "SAINT CLAIR",
      state: "PA"
    },
    {
      city: "SAINT CLAIR SHORES",
      state: "MI"
    },
    {
      city: "SAINT CLAIRSVILLE",
      state: "OH"
    },
    {
      city: "SAINT CLOUD",
      state: "FL"
    },
    {
      city: "SAINT CLOUD",
      state: "MN"
    },
    {
      city: "SAINT CLOUD",
      state: "WI"
    },
    {
      city: "SAINT CROIX",
      state: "IN"
    },
    {
      city: "SAINT CROIX FALLS",
      state: "WI"
    },
    {
      city: "SAINT DAVID",
      state: "AZ"
    },
    {
      city: "SAINT DAVID",
      state: "IL"
    },
    {
      city: "SAINT DAVID",
      state: "ME"
    },
    {
      city: "SAINT DONATUS",
      state: "IA"
    },
    {
      city: "SAINT EDWARD",
      state: "NE"
    },
    {
      city: "SAINT ELIZABETH",
      state: "MO"
    },
    {
      city: "SAINT ELMO",
      state: "AL"
    },
    {
      city: "SAINT ELMO",
      state: "IL"
    },
    {
      city: "SAINT FRANCIS",
      state: "AR"
    },
    {
      city: "SAINT FRANCIS",
      state: "KS"
    },
    {
      city: "SAINT FRANCIS",
      state: "KY"
    },
    {
      city: "SAINT FRANCIS",
      state: "ME"
    },
    {
      city: "SAINT FRANCIS",
      state: "MN"
    },
    {
      city: "SAINT FRANCIS",
      state: "SD"
    },
    {
      city: "SAINT FRANCIS",
      state: "WI"
    },
    {
      city: "SAINT FRANCISVILLE",
      state: "IL"
    },
    {
      city: "SAINT FRANCISVILLE",
      state: "LA"
    },
    {
      city: "SAINT GABRIEL",
      state: "LA"
    },
    {
      city: "SAINT GEORGE",
      state: "GA"
    },
    {
      city: "SAINT GEORGE",
      state: "KS"
    },
    {
      city: "SAINT GEORGE",
      state: "SC"
    },
    {
      city: "SAINT GEORGE",
      state: "UT"
    },
    {
      city: "SAINT GEORGE ISLAND",
      state: "AK"
    },
    {
      city: "SAINT GEORGES",
      state: "DE"
    },
    {
      city: "SAINT GERMAIN",
      state: "WI"
    },
    {
      city: "SAINT HEDWIG",
      state: "TX"
    },
    {
      city: "SAINT HELEN",
      state: "MI"
    },
    {
      city: "SAINT HELENA",
      state: "CA"
    },
    {
      city: "SAINT HELENA",
      state: "NE"
    },
    {
      city: "SAINT HELENA ISLAND",
      state: "SC"
    },
    {
      city: "SAINT HELENS",
      state: "OR"
    },
    {
      city: "SAINT HENRY",
      state: "OH"
    },
    {
      city: "SAINT HILAIRE",
      state: "MN"
    },
    {
      city: "SAINT IGNACE",
      state: "MI"
    },
    {
      city: "SAINT IGNATIUS",
      state: "MT"
    },
    {
      city: "SAINT INIGOES",
      state: "MD"
    },
    {
      city: "SAINT JACOB",
      state: "IL"
    },
    {
      city: "SAINT JAMES",
      state: "LA"
    },
    {
      city: "SAINT JAMES",
      state: "MD"
    },
    {
      city: "SAINT JAMES",
      state: "MN"
    },
    {
      city: "SAINT JAMES",
      state: "MO"
    },
    {
      city: "SAINT JAMES",
      state: "NY"
    },
    {
      city: "SAINT JAMES CITY",
      state: "FL"
    },
    {
      city: "SAINT JO",
      state: "TX"
    },
    {
      city: "SAINT JOE",
      state: "AR"
    },
    {
      city: "SAINT JOE",
      state: "IN"
    },
    {
      city: "SAINT JOHN",
      state: "IN"
    },
    {
      city: "SAINT JOHN",
      state: "KS"
    },
    {
      city: "SAINT JOHN",
      state: "ND"
    },
    {
      city: "SAINT JOHN",
      state: "WA"
    },
    {
      city: "SAINT JOHNS",
      state: "AZ"
    },
    {
      city: "SAINT JOHNS",
      state: "FL"
    },
    {
      city: "SAINT JOHNS",
      state: "MI"
    },
    {
      city: "SAINT JOHNS",
      state: "PA"
    },
    {
      city: "SAINT JOHNSBURY",
      state: "VT"
    },
    {
      city: "SAINT JOHNSBURY CENTER",
      state: "VT"
    },
    {
      city: "SAINT JOHNSVILLE",
      state: "NY"
    },
    {
      city: "SAINT JOSEPH",
      state: "IL"
    },
    {
      city: "SAINT JOSEPH",
      state: "LA"
    },
    {
      city: "SAINT JOSEPH",
      state: "MI"
    },
    {
      city: "SAINT JOSEPH",
      state: "MN"
    },
    {
      city: "SAINT JOSEPH",
      state: "MO"
    },
    {
      city: "SAINT JOSEPH",
      state: "TN"
    },
    {
      city: "SAINT LANDRY",
      state: "LA"
    },
    {
      city: "SAINT LAWRENCE",
      state: "SD"
    },
    {
      city: "SAINT LEO",
      state: "FL"
    },
    {
      city: "SAINT LEONARD",
      state: "MD"
    },
    {
      city: "SAINT LIBORY",
      state: "IL"
    },
    {
      city: "SAINT LIBORY",
      state: "NE"
    },
    {
      city: "SAINT LOUIS",
      state: "MI"
    },
    {
      city: "SAINT LOUIS",
      state: "MO"
    },
    {
      city: "SAINT LOUIS",
      state: "OK"
    },
    {
      city: "SAINT LOUISVILLE",
      state: "OH"
    },
    {
      city: "SAINT LUCAS",
      state: "IA"
    },
    {
      city: "SAINT MARIE",
      state: "MT"
    },
    {
      city: "SAINT MARIES",
      state: "ID"
    },
    {
      city: "SAINT MARKS",
      state: "FL"
    },
    {
      city: "SAINT MARTIN",
      state: "MN"
    },
    {
      city: "SAINT MARTINVILLE",
      state: "LA"
    },
    {
      city: "SAINT MARY",
      state: "KY"
    },
    {
      city: "SAINT MARY",
      state: "MO"
    },
    {
      city: "SAINT MARY OF THE WOODS",
      state: "IN"
    },
    {
      city: "SAINT MARYS",
      state: "AK"
    },
    {
      city: "SAINT MARYS",
      state: "GA"
    },
    {
      city: "SAINT MARYS",
      state: "IA"
    },
    {
      city: "SAINT MARYS",
      state: "KS"
    },
    {
      city: "SAINT MARYS",
      state: "OH"
    },
    {
      city: "SAINT MARYS",
      state: "PA"
    },
    {
      city: "SAINT MARYS",
      state: "WV"
    },
    {
      city: "SAINT MARYS CITY",
      state: "MD"
    },
    {
      city: "SAINT MATTHEWS",
      state: "SC"
    },
    {
      city: "SAINT MEINRAD",
      state: "IN"
    },
    {
      city: "SAINT MICHAEL",
      state: "AK"
    },
    {
      city: "SAINT MICHAEL",
      state: "MN"
    },
    {
      city: "SAINT MICHAEL",
      state: "ND"
    },
    {
      city: "SAINT MICHAEL",
      state: "PA"
    },
    {
      city: "SAINT MICHAELS",
      state: "AZ"
    },
    {
      city: "SAINT MICHAELS",
      state: "MD"
    },
    {
      city: "SAINT NAZIANZ",
      state: "WI"
    },
    {
      city: "SAINT OLAF",
      state: "IA"
    },
    {
      city: "SAINT ONGE",
      state: "SD"
    },
    {
      city: "SAINT PARIS",
      state: "OH"
    },
    {
      city: "SAINT PAUL",
      state: "AR"
    },
    {
      city: "SAINT PAUL",
      state: "IA"
    },
    {
      city: "SAINT PAUL",
      state: "IN"
    },
    {
      city: "SAINT PAUL",
      state: "KS"
    },
    {
      city: "SAINT PAUL",
      state: "MN"
    },
    {
      city: "SAINT PAUL",
      state: "NE"
    },
    {
      city: "SAINT PAUL",
      state: "OR"
    },
    {
      city: "SAINT PAUL",
      state: "VA"
    },
    {
      city: "SAINT PAUL ISLAND",
      state: "AK"
    },
    {
      city: "SAINT PAUL PARK",
      state: "MN"
    },
    {
      city: "SAINT PAULS",
      state: "NC"
    },
    {
      city: "SAINT PETER",
      state: "IL"
    },
    {
      city: "SAINT PETER",
      state: "MN"
    },
    {
      city: "SAINT PETERS",
      state: "MO"
    },
    {
      city: "SAINT PETERS",
      state: "PA"
    },
    {
      city: "SAINT PETERSBURG",
      state: "FL"
    },
    {
      city: "SAINT PETERSBURG",
      state: "PA"
    },
    {
      city: "SAINT REGIS",
      state: "MT"
    },
    {
      city: "SAINT REGIS FALLS",
      state: "NY"
    },
    {
      city: "SAINT ROBERT",
      state: "MO"
    },
    {
      city: "SAINT ROSE",
      state: "LA"
    },
    {
      city: "SAINT SIMONS ISLAND",
      state: "GA"
    },
    {
      city: "SAINT STEPHEN",
      state: "MN"
    },
    {
      city: "SAINT STEPHEN",
      state: "SC"
    },
    {
      city: "SAINT STEPHENS",
      state: "AL"
    },
    {
      city: "SAINT STEPHENS",
      state: "WY"
    },
    {
      city: "SAINT STEPHENS CHURCH",
      state: "VA"
    },
    {
      city: "SAINT THOMAS",
      state: "MO"
    },
    {
      city: "SAINT THOMAS",
      state: "ND"
    },
    {
      city: "SAINT THOMAS",
      state: "PA"
    },
    {
      city: "SAINT VINCENT",
      state: "MN"
    },
    {
      city: "SAINT XAVIER",
      state: "MT"
    },
    {
      city: "SAINTE GENEVIEVE",
      state: "MO"
    },
    {
      city: "SAINTE MARIE",
      state: "IL"
    },
    {
      city: "SALADO",
      state: "TX"
    },
    {
      city: "SALAMANCA",
      state: "NY"
    },
    {
      city: "SALAMONIA",
      state: "IN"
    },
    {
      city: "SALCHA",
      state: "AK"
    },
    {
      city: "SALE CITY",
      state: "GA"
    },
    {
      city: "SALE CREEK",
      state: "TN"
    },
    {
      city: "SALEM",
      state: "AL"
    },
    {
      city: "SALEM",
      state: "AR"
    },
    {
      city: "SALEM",
      state: "CT"
    },
    {
      city: "SALEM",
      state: "FL"
    },
    {
      city: "SALEM",
      state: "IA"
    },
    {
      city: "SALEM",
      state: "IL"
    },
    {
      city: "SALEM",
      state: "IN"
    },
    {
      city: "SALEM",
      state: "KY"
    },
    {
      city: "SALEM",
      state: "MA"
    },
    {
      city: "SALEM",
      state: "MI"
    },
    {
      city: "SALEM",
      state: "MO"
    },
    {
      city: "SALEM",
      state: "NE"
    },
    {
      city: "SALEM",
      state: "NH"
    },
    {
      city: "SALEM",
      state: "NJ"
    },
    {
      city: "SALEM",
      state: "NM"
    },
    {
      city: "SALEM",
      state: "NY"
    },
    {
      city: "SALEM",
      state: "OH"
    },
    {
      city: "SALEM",
      state: "OR"
    },
    {
      city: "SALEM",
      state: "SC"
    },
    {
      city: "SALEM",
      state: "SD"
    },
    {
      city: "SALEM",
      state: "UT"
    },
    {
      city: "SALEM",
      state: "VA"
    },
    {
      city: "SALEM",
      state: "WI"
    },
    {
      city: "SALEM",
      state: "WV"
    },
    {
      city: "SALEMBURG",
      state: "NC"
    },
    {
      city: "SALESVILLE",
      state: "OH"
    },
    {
      city: "SALFORD",
      state: "PA"
    },
    {
      city: "SALFORDVILLE",
      state: "PA"
    },
    {
      city: "SALIDA",
      state: "CA"
    },
    {
      city: "SALIDA",
      state: "CO"
    },
    {
      city: "SALINA",
      state: "KS"
    },
    {
      city: "SALINA",
      state: "OK"
    },
    {
      city: "SALINA",
      state: "PA"
    },
    {
      city: "SALINA",
      state: "UT"
    },
    {
      city: "SALINAS",
      state: "CA"
    },
    {
      city: "SALINE",
      state: "LA"
    },
    {
      city: "SALINE",
      state: "MI"
    },
    {
      city: "SALINEVILLE",
      state: "OH"
    },
    {
      city: "SALISBURY",
      state: "CT"
    },
    {
      city: "SALISBURY",
      state: "MA"
    },
    {
      city: "SALISBURY",
      state: "MD"
    },
    {
      city: "SALISBURY",
      state: "MO"
    },
    {
      city: "SALISBURY",
      state: "NC"
    },
    {
      city: "SALISBURY",
      state: "NH"
    },
    {
      city: "SALISBURY",
      state: "PA"
    },
    {
      city: "SALISBURY",
      state: "VT"
    },
    {
      city: "SALISBURY CENTER",
      state: "NY"
    },
    {
      city: "SALISBURY MILLS",
      state: "NY"
    },
    {
      city: "SALIX",
      state: "IA"
    },
    {
      city: "SALIX",
      state: "PA"
    },
    {
      city: "SALKUM",
      state: "WA"
    },
    {
      city: "SALLEY",
      state: "SC"
    },
    {
      city: "SALLIS",
      state: "MS"
    },
    {
      city: "SALLISAW",
      state: "OK"
    },
    {
      city: "SALMON",
      state: "ID"
    },
    {
      city: "SALOL",
      state: "MN"
    },
    {
      city: "SALOME",
      state: "AZ"
    },
    {
      city: "SALSBURY COVE",
      state: "ME"
    },
    {
      city: "SALT FLAT",
      state: "TX"
    },
    {
      city: "SALT LAKE CITY",
      state: "UT"
    },
    {
      city: "SALT LICK",
      state: "KY"
    },
    {
      city: "SALT POINT",
      state: "NY"
    },
    {
      city: "SALT ROCK",
      state: "WV"
    },
    {
      city: "SALTER PATH",
      state: "NC"
    },
    {
      city: "SALTERS",
      state: "SC"
    },
    {
      city: "SALTILLO",
      state: "MS"
    },
    {
      city: "SALTILLO",
      state: "PA"
    },
    {
      city: "SALTILLO",
      state: "TN"
    },
    {
      city: "SALTILLO",
      state: "TX"
    },
    {
      city: "SALTON CITY",
      state: "CA"
    },
    {
      city: "SALTSBURG",
      state: "PA"
    },
    {
      city: "SALTVILLE",
      state: "VA"
    },
    {
      city: "SALUDA",
      state: "NC"
    },
    {
      city: "SALUDA",
      state: "SC"
    },
    {
      city: "SALUDA",
      state: "VA"
    },
    {
      city: "SALVISA",
      state: "KY"
    },
    {
      city: "SALVO",
      state: "NC"
    },
    {
      city: "SALYER",
      state: "CA"
    },
    {
      city: "SALYERSVILLE",
      state: "KY"
    },
    {
      city: "SAMARIA",
      state: "MI"
    },
    {
      city: "SAMBURG",
      state: "TN"
    },
    {
      city: "SAMMAMISH",
      state: "WA"
    },
    {
      city: "SAMOA",
      state: "CA"
    },
    {
      city: "SAMSON",
      state: "AL"
    },
    {
      city: "SAN ACACIA",
      state: "NM"
    },
    {
      city: "SAN ANDREAS",
      state: "CA"
    },
    {
      city: "SAN ANGELO",
      state: "TX"
    },
    {
      city: "SAN ANSELMO",
      state: "CA"
    },
    {
      city: "SAN ANTONIO",
      state: "FL"
    },
    {
      city: "SAN ANTONIO",
      state: "NM"
    },
    {
      city: "SAN ANTONIO",
      state: "TX"
    },
    {
      city: "SAN ARDO",
      state: "CA"
    },
    {
      city: "SAN AUGUSTINE",
      state: "TX"
    },
    {
      city: "SAN BENITO",
      state: "TX"
    },
    {
      city: "SAN BERNARDINO",
      state: "CA"
    },
    {
      city: "SAN BRUNO",
      state: "CA"
    },
    {
      city: "SAN CARLOS",
      state: "AZ"
    },
    {
      city: "SAN CARLOS",
      state: "CA"
    },
    {
      city: "SAN CLEMENTE",
      state: "CA"
    },
    {
      city: "SAN CRISTOBAL",
      state: "NM"
    },
    {
      city: "SAN DIEGO",
      state: "CA"
    },
    {
      city: "SAN DIEGO",
      state: "TX"
    },
    {
      city: "SAN DIMAS",
      state: "CA"
    },
    {
      city: "SAN ELIZARIO",
      state: "TX"
    },
    {
      city: "SAN FELIPE",
      state: "TX"
    },
    {
      city: "SAN FERNANDO",
      state: "CA"
    },
    {
      city: "SAN FIDEL",
      state: "NM"
    },
    {
      city: "SAN FRANCISCO",
      state: "CA"
    },
    {
      city: "SAN GABRIEL",
      state: "CA"
    },
    {
      city: "SAN GERONIMO",
      state: "CA"
    },
    {
      city: "SAN GREGORIO",
      state: "CA"
    },
    {
      city: "SAN ISIDRO",
      state: "TX"
    },
    {
      city: "SAN JACINTO",
      state: "CA"
    },
    {
      city: "SAN JOAQUIN",
      state: "CA"
    },
    {
      city: "SAN JON",
      state: "NM"
    },
    {
      city: "SAN JOSE",
      state: "CA"
    },
    {
      city: "SAN JOSE",
      state: "IL"
    },
    {
      city: "SAN JOSE",
      state: "NM"
    },
    {
      city: "SAN JUAN",
      state: "TX"
    },
    {
      city: "SAN JUAN BAUTISTA",
      state: "CA"
    },
    {
      city: "SAN JUAN CAPISTRANO",
      state: "CA"
    },
    {
      city: "SAN LEANDRO",
      state: "CA"
    },
    {
      city: "SAN LORENZO",
      state: "CA"
    },
    {
      city: "SAN LUCAS",
      state: "CA"
    },
    {
      city: "SAN LUIS",
      state: "AZ"
    },
    {
      city: "SAN LUIS",
      state: "CO"
    },
    {
      city: "SAN LUIS OBISPO",
      state: "CA"
    },
    {
      city: "SAN LUIS REY",
      state: "CA"
    },
    {
      city: "SAN MANUEL",
      state: "AZ"
    },
    {
      city: "SAN MARCOS",
      state: "CA"
    },
    {
      city: "SAN MARCOS",
      state: "TX"
    },
    {
      city: "SAN MARINO",
      state: "CA"
    },
    {
      city: "SAN MARTIN",
      state: "CA"
    },
    {
      city: "SAN MATEO",
      state: "CA"
    },
    {
      city: "SAN MATEO",
      state: "FL"
    },
    {
      city: "SAN MIGUEL",
      state: "CA"
    },
    {
      city: "SAN MIGUEL",
      state: "NM"
    },
    {
      city: "SAN PABLO",
      state: "CA"
    },
    {
      city: "SAN PATRICIO",
      state: "NM"
    },
    {
      city: "SAN PEDRO",
      state: "CA"
    },
    {
      city: "SAN PERLITA",
      state: "TX"
    },
    {
      city: "SAN PIERRE",
      state: "IN"
    },
    {
      city: "SAN QUENTIN",
      state: "CA"
    },
    {
      city: "SAN RAFAEL",
      state: "CA"
    },
    {
      city: "SAN RAFAEL",
      state: "NM"
    },
    {
      city: "SAN RAMON",
      state: "CA"
    },
    {
      city: "SAN SABA",
      state: "TX"
    },
    {
      city: "SAN SIMEON",
      state: "CA"
    },
    {
      city: "SAN SIMON",
      state: "AZ"
    },
    {
      city: "SAN TAN VALLEY",
      state: "AZ"
    },
    {
      city: "SAN YGNACIO",
      state: "TX"
    },
    {
      city: "SAN YSIDRO",
      state: "CA"
    },
    {
      city: "SAN YSIDRO",
      state: "NM"
    },
    {
      city: "SANBORN",
      state: "IA"
    },
    {
      city: "SANBORN",
      state: "MN"
    },
    {
      city: "SANBORN",
      state: "ND"
    },
    {
      city: "SANBORN",
      state: "NY"
    },
    {
      city: "SANBORNTON",
      state: "NH"
    },
    {
      city: "SANBORNVILLE",
      state: "NH"
    },
    {
      city: "SAND COULEE",
      state: "MT"
    },
    {
      city: "SAND CREEK",
      state: "MI"
    },
    {
      city: "SAND CREEK",
      state: "WI"
    },
    {
      city: "SAND FORK",
      state: "WV"
    },
    {
      city: "SAND LAKE",
      state: "MI"
    },
    {
      city: "SAND LAKE",
      state: "NY"
    },
    {
      city: "SAND POINT",
      state: "AK"
    },
    {
      city: "SAND SPRINGS",
      state: "OK"
    },
    {
      city: "SANDBORN",
      state: "IN"
    },
    {
      city: "SANDERS",
      state: "AZ"
    },
    {
      city: "SANDERS",
      state: "KY"
    },
    {
      city: "SANDERSON",
      state: "FL"
    },
    {
      city: "SANDERSON",
      state: "TX"
    },
    {
      city: "SANDERSVILLE",
      state: "GA"
    },
    {
      city: "SANDERSVILLE",
      state: "MS"
    },
    {
      city: "SANDGAP",
      state: "KY"
    },
    {
      city: "SANDHILL",
      state: "MS"
    },
    {
      city: "SANDIA",
      state: "TX"
    },
    {
      city: "SANDIA PARK",
      state: "NM"
    },
    {
      city: "SANDISFIELD",
      state: "MA"
    },
    {
      city: "SANDOVAL",
      state: "IL"
    },
    {
      city: "SANDOWN",
      state: "NH"
    },
    {
      city: "SANDPOINT",
      state: "ID"
    },
    {
      city: "SANDSTON",
      state: "VA"
    },
    {
      city: "SANDSTONE",
      state: "MN"
    },
    {
      city: "SANDSTONE",
      state: "WV"
    },
    {
      city: "SANDUSKY",
      state: "MI"
    },
    {
      city: "SANDUSKY",
      state: "NY"
    },
    {
      city: "SANDUSKY",
      state: "OH"
    },
    {
      city: "SANDWICH",
      state: "IL"
    },
    {
      city: "SANDWICH",
      state: "MA"
    },
    {
      city: "SANDY",
      state: "OR"
    },
    {
      city: "SANDY",
      state: "UT"
    },
    {
      city: "SANDY CREEK",
      state: "NY"
    },
    {
      city: "SANDY HOOK",
      state: "CT"
    },
    {
      city: "SANDY HOOK",
      state: "KY"
    },
    {
      city: "SANDY HOOK",
      state: "MS"
    },
    {
      city: "SANDY HOOK",
      state: "VA"
    },
    {
      city: "SANDY LAKE",
      state: "PA"
    },
    {
      city: "SANDY LEVEL",
      state: "VA"
    },
    {
      city: "SANDY RIDGE",
      state: "NC"
    },
    {
      city: "SANDY RIDGE",
      state: "PA"
    },
    {
      city: "SANDY SPRING",
      state: "MD"
    },
    {
      city: "SANDY SPRINGS",
      state: "SC"
    },
    {
      city: "SANDYVILLE",
      state: "OH"
    },
    {
      city: "SANDYVILLE",
      state: "WV"
    },
    {
      city: "SANFORD",
      state: "CO"
    },
    {
      city: "SANFORD",
      state: "FL"
    },
    {
      city: "SANFORD",
      state: "ME"
    },
    {
      city: "SANFORD",
      state: "MI"
    },
    {
      city: "SANFORD",
      state: "NC"
    },
    {
      city: "SANFORD",
      state: "TX"
    },
    {
      city: "SANFORD",
      state: "VA"
    },
    {
      city: "SANGER",
      state: "CA"
    },
    {
      city: "SANGER",
      state: "TX"
    },
    {
      city: "SANGERFIELD",
      state: "NY"
    },
    {
      city: "SANGERVILLE",
      state: "ME"
    },
    {
      city: "SANIBEL",
      state: "FL"
    },
    {
      city: "SANOSTEE",
      state: "NM"
    },
    {
      city: "SANTA",
      state: "ID"
    },
    {
      city: "SANTA ANA",
      state: "CA"
    },
    {
      city: "SANTA ANNA",
      state: "TX"
    },
    {
      city: "SANTA BARBARA",
      state: "CA"
    },
    {
      city: "SANTA CLARA",
      state: "CA"
    },
    {
      city: "SANTA CLARA",
      state: "NM"
    },
    {
      city: "SANTA CLARA",
      state: "UT"
    },
    {
      city: "SANTA CLARITA",
      state: "CA"
    },
    {
      city: "SANTA CLAUS",
      state: "IN"
    },
    {
      city: "SANTA CRUZ",
      state: "CA"
    },
    {
      city: "SANTA CRUZ",
      state: "NM"
    },
    {
      city: "SANTA ELENA",
      state: "TX"
    },
    {
      city: "SANTA FE",
      state: "MO"
    },
    {
      city: "SANTA FE",
      state: "NM"
    },
    {
      city: "SANTA FE",
      state: "TN"
    },
    {
      city: "SANTA FE",
      state: "TX"
    },
    {
      city: "SANTA FE SPRINGS",
      state: "CA"
    },
    {
      city: "SANTA MARGARITA",
      state: "CA"
    },
    {
      city: "SANTA MARIA",
      state: "CA"
    },
    {
      city: "SANTA MARIA",
      state: "TX"
    },
    {
      city: "SANTA MONICA",
      state: "CA"
    },
    {
      city: "SANTA PAULA",
      state: "CA"
    },
    {
      city: "SANTA ROSA",
      state: "CA"
    },
    {
      city: "SANTA ROSA",
      state: "NM"
    },
    {
      city: "SANTA ROSA",
      state: "TX"
    },
    {
      city: "SANTA ROSA BEACH",
      state: "FL"
    },
    {
      city: "SANTA TERESA",
      state: "NM"
    },
    {
      city: "SANTA YNEZ",
      state: "CA"
    },
    {
      city: "SANTA YSABEL",
      state: "CA"
    },
    {
      city: "SANTAQUIN",
      state: "UT"
    },
    {
      city: "SANTEE",
      state: "CA"
    },
    {
      city: "SANTEE",
      state: "SC"
    },
    {
      city: "SANTIAGO",
      state: "MN"
    },
    {
      city: "SANTO",
      state: "TX"
    },
    {
      city: "SANTO DOMINGO PUEBLO",
      state: "NM"
    },
    {
      city: "SAPELLO",
      state: "NM"
    },
    {
      city: "SAPPHIRE",
      state: "NC"
    },
    {
      city: "SAPULPA",
      state: "OK"
    },
    {
      city: "SARAGOSA",
      state: "TX"
    },
    {
      city: "SARAH",
      state: "MS"
    },
    {
      city: "SARAHSVILLE",
      state: "OH"
    },
    {
      city: "SARALAND",
      state: "AL"
    },
    {
      city: "SARANAC",
      state: "MI"
    },
    {
      city: "SARANAC",
      state: "NY"
    },
    {
      city: "SARANAC LAKE",
      state: "NY"
    },
    {
      city: "SARASOTA",
      state: "FL"
    },
    {
      city: "SARATOGA",
      state: "AR"
    },
    {
      city: "SARATOGA",
      state: "CA"
    },
    {
      city: "SARATOGA",
      state: "IN"
    },
    {
      city: "SARATOGA",
      state: "NC"
    },
    {
      city: "SARATOGA",
      state: "TX"
    },
    {
      city: "SARATOGA",
      state: "WY"
    },
    {
      city: "SARATOGA SPRINGS",
      state: "NY"
    },
    {
      city: "SARATOGA SPRINGS",
      state: "UT"
    },
    {
      city: "SARCOXIE",
      state: "MO"
    },
    {
      city: "SARDINIA",
      state: "NY"
    },
    {
      city: "SARDINIA",
      state: "OH"
    },
    {
      city: "SARDIS",
      state: "AL"
    },
    {
      city: "SARDIS",
      state: "GA"
    },
    {
      city: "SARDIS",
      state: "MS"
    },
    {
      city: "SARDIS",
      state: "OH"
    },
    {
      city: "SARDIS",
      state: "TN"
    },
    {
      city: "SAREPTA",
      state: "LA"
    },
    {
      city: "SARGEANT",
      state: "MN"
    },
    {
      city: "SARGENT",
      state: "GA"
    },
    {
      city: "SARGENT",
      state: "NE"
    },
    {
      city: "SARGENTS",
      state: "CO"
    },
    {
      city: "SARGENTVILLE",
      state: "ME"
    },
    {
      city: "SARITA",
      state: "TX"
    },
    {
      city: "SARLES",
      state: "ND"
    },
    {
      city: "SARONA",
      state: "WI"
    },
    {
      city: "SARONVILLE",
      state: "NE"
    },
    {
      city: "SARTELL",
      state: "MN"
    },
    {
      city: "SARVER",
      state: "PA"
    },
    {
      city: "SASABE",
      state: "AZ"
    },
    {
      city: "SASAKWA",
      state: "OK"
    },
    {
      city: "SASSAFRAS",
      state: "KY"
    },
    {
      city: "SASSAMANSVILLE",
      state: "PA"
    },
    {
      city: "SASSER",
      state: "GA"
    },
    {
      city: "SATANTA",
      state: "KS"
    },
    {
      city: "SATARTIA",
      state: "MS"
    },
    {
      city: "SATELLITE BEACH",
      state: "FL"
    },
    {
      city: "SATIN",
      state: "TX"
    },
    {
      city: "SATSOP",
      state: "WA"
    },
    {
      city: "SATSUMA",
      state: "AL"
    },
    {
      city: "SATSUMA",
      state: "FL"
    },
    {
      city: "SAUCIER",
      state: "MS"
    },
    {
      city: "SAUGATUCK",
      state: "MI"
    },
    {
      city: "SAUGERTIES",
      state: "NY"
    },
    {
      city: "SAUGUS",
      state: "MA"
    },
    {
      city: "SAUK CENTRE",
      state: "MN"
    },
    {
      city: "SAUK CITY",
      state: "WI"
    },
    {
      city: "SAUK RAPIDS",
      state: "MN"
    },
    {
      city: "SAUKVILLE",
      state: "WI"
    },
    {
      city: "SAULSBURY",
      state: "TN"
    },
    {
      city: "SAULSVILLE",
      state: "WV"
    },
    {
      city: "SAULT SAINTE MARIE",
      state: "MI"
    },
    {
      city: "SAUNDERSTOWN",
      state: "RI"
    },
    {
      city: "SAUNEMIN",
      state: "IL"
    },
    {
      city: "SAUQUOIT",
      state: "NY"
    },
    {
      city: "SAUSALITO",
      state: "CA"
    },
    {
      city: "SAUTEE NACOOCHEE",
      state: "GA"
    },
    {
      city: "SAVAGE",
      state: "MD"
    },
    {
      city: "SAVAGE",
      state: "MN"
    },
    {
      city: "SAVAGE",
      state: "MT"
    },
    {
      city: "SAVANNA",
      state: "IL"
    },
    {
      city: "SAVANNA",
      state: "OK"
    },
    {
      city: "SAVANNAH",
      state: "GA"
    },
    {
      city: "SAVANNAH",
      state: "MO"
    },
    {
      city: "SAVANNAH",
      state: "NY"
    },
    {
      city: "SAVANNAH",
      state: "OH"
    },
    {
      city: "SAVANNAH",
      state: "TN"
    },
    {
      city: "SAVERTON",
      state: "MO"
    },
    {
      city: "SAVERY",
      state: "WY"
    },
    {
      city: "SAVONA",
      state: "NY"
    },
    {
      city: "SAVONBURG",
      state: "KS"
    },
    {
      city: "SAVOONGA",
      state: "AK"
    },
    {
      city: "SAVOY",
      state: "IL"
    },
    {
      city: "SAVOY",
      state: "MA"
    },
    {
      city: "SAVOY",
      state: "TX"
    },
    {
      city: "SAWYER",
      state: "KS"
    },
    {
      city: "SAWYER",
      state: "MI"
    },
    {
      city: "SAWYER",
      state: "MN"
    },
    {
      city: "SAWYER",
      state: "ND"
    },
    {
      city: "SAWYER",
      state: "OK"
    },
    {
      city: "SAWYERVILLE",
      state: "AL"
    },
    {
      city: "SAXAPAHAW",
      state: "NC"
    },
    {
      city: "SAXE",
      state: "VA"
    },
    {
      city: "SAXEVILLE",
      state: "WI"
    },
    {
      city: "SAXIS",
      state: "VA"
    },
    {
      city: "SAXON",
      state: "WI"
    },
    {
      city: "SAXONBURG",
      state: "PA"
    },
    {
      city: "SAXTON",
      state: "PA"
    },
    {
      city: "SAXTONS RIVER",
      state: "VT"
    },
    {
      city: "SAYBROOK",
      state: "IL"
    },
    {
      city: "SAYLORSBURG",
      state: "PA"
    },
    {
      city: "SAYNER",
      state: "WI"
    },
    {
      city: "SAYRE",
      state: "OK"
    },
    {
      city: "SAYRE",
      state: "PA"
    },
    {
      city: "SAYREVILLE",
      state: "NJ"
    },
    {
      city: "SAYVILLE",
      state: "NY"
    },
    {
      city: "SCALES MOUND",
      state: "IL"
    },
    {
      city: "SCALY MOUNTAIN",
      state: "NC"
    },
    {
      city: "SCAMMON",
      state: "KS"
    },
    {
      city: "SCAMMON BAY",
      state: "AK"
    },
    {
      city: "SCANDIA",
      state: "KS"
    },
    {
      city: "SCANDIA",
      state: "MN"
    },
    {
      city: "SCANDINAVIA",
      state: "WI"
    },
    {
      city: "SCAPPOOSE",
      state: "OR"
    },
    {
      city: "SCARBOROUGH",
      state: "ME"
    },
    {
      city: "SCARBRO",
      state: "WV"
    },
    {
      city: "SCARSDALE",
      state: "NY"
    },
    {
      city: "SCARVILLE",
      state: "IA"
    },
    {
      city: "SCENERY HILL",
      state: "PA"
    },
    {
      city: "SCENIC",
      state: "SD"
    },
    {
      city: "SCHAEFFERSTOWN",
      state: "PA"
    },
    {
      city: "SCHAGHTICOKE",
      state: "NY"
    },
    {
      city: "SCHALLER",
      state: "IA"
    },
    {
      city: "SCHAUMBURG",
      state: "IL"
    },
    {
      city: "SCHELL CITY",
      state: "MO"
    },
    {
      city: "SCHELLER",
      state: "IL"
    },
    {
      city: "SCHELLSBURG",
      state: "PA"
    },
    {
      city: "SCHENECTADY",
      state: "NY"
    },
    {
      city: "SCHENEVUS",
      state: "NY"
    },
    {
      city: "SCHENLEY",
      state: "PA"
    },
    {
      city: "SCHERERVILLE",
      state: "IN"
    },
    {
      city: "SCHERTZ",
      state: "TX"
    },
    {
      city: "SCHILLER PARK",
      state: "IL"
    },
    {
      city: "SCHLATER",
      state: "MS"
    },
    {
      city: "SCHLESWIG",
      state: "IA"
    },
    {
      city: "SCHLEY",
      state: "VA"
    },
    {
      city: "SCHNECKSVILLE",
      state: "PA"
    },
    {
      city: "SCHNEIDER",
      state: "IN"
    },
    {
      city: "SCHNELLVILLE",
      state: "IN"
    },
    {
      city: "SCHODACK LANDING",
      state: "NY"
    },
    {
      city: "SCHOENCHEN",
      state: "KS"
    },
    {
      city: "SCHOFIELD",
      state: "WI"
    },
    {
      city: "SCHOFIELD BARRACKS",
      state: "HI"
    },
    {
      city: "SCHOHARIE",
      state: "NY"
    },
    {
      city: "SCHOOLCRAFT",
      state: "MI"
    },
    {
      city: "SCHOOLEYS MOUNTAIN",
      state: "NJ"
    },
    {
      city: "SCHRIEVER",
      state: "LA"
    },
    {
      city: "SCHROEDER",
      state: "MN"
    },
    {
      city: "SCHROON LAKE",
      state: "NY"
    },
    {
      city: "SCHULENBURG",
      state: "TX"
    },
    {
      city: "SCHULTER",
      state: "OK"
    },
    {
      city: "SCHURZ",
      state: "NV"
    },
    {
      city: "SCHUYLER",
      state: "NE"
    },
    {
      city: "SCHUYLER",
      state: "VA"
    },
    {
      city: "SCHUYLER FALLS",
      state: "NY"
    },
    {
      city: "SCHUYLER LAKE",
      state: "NY"
    },
    {
      city: "SCHUYLERVILLE",
      state: "NY"
    },
    {
      city: "SCHUYLKILL HAVEN",
      state: "PA"
    },
    {
      city: "SCHWENKSVILLE",
      state: "PA"
    },
    {
      city: "SCHWERTNER",
      state: "TX"
    },
    {
      city: "SCIENCE HILL",
      state: "KY"
    },
    {
      city: "SCIO",
      state: "NY"
    },
    {
      city: "SCIO",
      state: "OH"
    },
    {
      city: "SCIO",
      state: "OR"
    },
    {
      city: "SCIOTA",
      state: "IL"
    },
    {
      city: "SCIOTA",
      state: "PA"
    },
    {
      city: "SCIPIO",
      state: "IN"
    },
    {
      city: "SCIPIO",
      state: "UT"
    },
    {
      city: "SCIPIO CENTER",
      state: "NY"
    },
    {
      city: "SCITUATE",
      state: "MA"
    },
    {
      city: "SCOBEY",
      state: "MS"
    },
    {
      city: "SCOBEY",
      state: "MT"
    },
    {
      city: "SCOOBA",
      state: "MS"
    },
    {
      city: "SCOTCH PLAINS",
      state: "NJ"
    },
    {
      city: "SCOTIA",
      state: "CA"
    },
    {
      city: "SCOTIA",
      state: "NE"
    },
    {
      city: "SCOTIA",
      state: "SC"
    },
    {
      city: "SCOTLAND",
      state: "AR"
    },
    {
      city: "SCOTLAND",
      state: "CT"
    },
    {
      city: "SCOTLAND",
      state: "GA"
    },
    {
      city: "SCOTLAND",
      state: "IN"
    },
    {
      city: "SCOTLAND",
      state: "MD"
    },
    {
      city: "SCOTLAND",
      state: "PA"
    },
    {
      city: "SCOTLAND",
      state: "SD"
    },
    {
      city: "SCOTLAND",
      state: "TX"
    },
    {
      city: "SCOTLAND NECK",
      state: "NC"
    },
    {
      city: "SCOTRUN",
      state: "PA"
    },
    {
      city: "SCOTT",
      state: "AR"
    },
    {
      city: "SCOTT",
      state: "LA"
    },
    {
      city: "SCOTT",
      state: "MS"
    },
    {
      city: "SCOTT",
      state: "OH"
    },
    {
      city: "SCOTT AIR FORCE BASE",
      state: "IL"
    },
    {
      city: "SCOTT CITY",
      state: "KS"
    },
    {
      city: "SCOTT CITY",
      state: "MO"
    },
    {
      city: "SCOTT DEPOT",
      state: "WV"
    },
    {
      city: "SCOTTDALE",
      state: "GA"
    },
    {
      city: "SCOTTDALE",
      state: "PA"
    },
    {
      city: "SCOTTOWN",
      state: "OH"
    },
    {
      city: "SCOTTS",
      state: "MI"
    },
    {
      city: "SCOTTS",
      state: "NC"
    },
    {
      city: "SCOTTS HILL",
      state: "TN"
    },
    {
      city: "SCOTTS MILLS",
      state: "OR"
    },
    {
      city: "SCOTTS VALLEY",
      state: "CA"
    },
    {
      city: "SCOTTSBLUFF",
      state: "NE"
    },
    {
      city: "SCOTTSBORO",
      state: "AL"
    },
    {
      city: "SCOTTSBURG",
      state: "IN"
    },
    {
      city: "SCOTTSBURG",
      state: "NY"
    },
    {
      city: "SCOTTSBURG",
      state: "OR"
    },
    {
      city: "SCOTTSBURG",
      state: "VA"
    },
    {
      city: "SCOTTSDALE",
      state: "AZ"
    },
    {
      city: "SCOTTSMOOR",
      state: "FL"
    },
    {
      city: "SCOTTSVILLE",
      state: "KY"
    },
    {
      city: "SCOTTSVILLE",
      state: "NY"
    },
    {
      city: "SCOTTSVILLE",
      state: "TX"
    },
    {
      city: "SCOTTSVILLE",
      state: "VA"
    },
    {
      city: "SCOTTVILLE",
      state: "MI"
    },
    {
      city: "SCOTTVILLE",
      state: "NC"
    },
    {
      city: "SCRANTON",
      state: "AR"
    },
    {
      city: "SCRANTON",
      state: "IA"
    },
    {
      city: "SCRANTON",
      state: "KS"
    },
    {
      city: "SCRANTON",
      state: "NC"
    },
    {
      city: "SCRANTON",
      state: "ND"
    },
    {
      city: "SCRANTON",
      state: "PA"
    },
    {
      city: "SCRANTON",
      state: "SC"
    },
    {
      city: "SCREVEN",
      state: "GA"
    },
    {
      city: "SCRIBNER",
      state: "NE"
    },
    {
      city: "SCROGGINS",
      state: "TX"
    },
    {
      city: "SCUDDY",
      state: "KY"
    },
    {
      city: "SCURRY",
      state: "TX"
    },
    {
      city: "SEA CLIFF",
      state: "NY"
    },
    {
      city: "SEA GIRT",
      state: "NJ"
    },
    {
      city: "SEA ISLAND",
      state: "GA"
    },
    {
      city: "SEA ISLE CITY",
      state: "NJ"
    },
    {
      city: "SEABECK",
      state: "WA"
    },
    {
      city: "SEABOARD",
      state: "NC"
    },
    {
      city: "SEABROOK",
      state: "NH"
    },
    {
      city: "SEABROOK",
      state: "SC"
    },
    {
      city: "SEABROOK",
      state: "TX"
    },
    {
      city: "SEADRIFT",
      state: "TX"
    },
    {
      city: "SEAFORD",
      state: "DE"
    },
    {
      city: "SEAFORD",
      state: "NY"
    },
    {
      city: "SEAFORD",
      state: "VA"
    },
    {
      city: "SEAGOVILLE",
      state: "TX"
    },
    {
      city: "SEAGRAVES",
      state: "TX"
    },
    {
      city: "SEAGROVE",
      state: "NC"
    },
    {
      city: "SEAHURST",
      state: "WA"
    },
    {
      city: "SEAL BEACH",
      state: "CA"
    },
    {
      city: "SEAL COVE",
      state: "ME"
    },
    {
      city: "SEAL HARBOR",
      state: "ME"
    },
    {
      city: "SEAL ROCK",
      state: "OR"
    },
    {
      city: "SEALE",
      state: "AL"
    },
    {
      city: "SEALEVEL",
      state: "NC"
    },
    {
      city: "SEALSTON",
      state: "VA"
    },
    {
      city: "SEALY",
      state: "TX"
    },
    {
      city: "SEAMAN",
      state: "OH"
    },
    {
      city: "SEARCHLIGHT",
      state: "NV"
    },
    {
      city: "SEARCY",
      state: "AR"
    },
    {
      city: "SEARS",
      state: "MI"
    },
    {
      city: "SEARSBORO",
      state: "IA"
    },
    {
      city: "SEARSMONT",
      state: "ME"
    },
    {
      city: "SEARSPORT",
      state: "ME"
    },
    {
      city: "SEASIDE",
      state: "CA"
    },
    {
      city: "SEASIDE",
      state: "OR"
    },
    {
      city: "SEASIDE HEIGHTS",
      state: "NJ"
    },
    {
      city: "SEASIDE PARK",
      state: "NJ"
    },
    {
      city: "SEATON",
      state: "IL"
    },
    {
      city: "SEATONVILLE",
      state: "IL"
    },
    {
      city: "SEATTLE",
      state: "WA"
    },
    {
      city: "SEAVIEW",
      state: "WA"
    },
    {
      city: "SEBAGO",
      state: "ME"
    },
    {
      city: "SEBASCO ESTATES",
      state: "ME"
    },
    {
      city: "SEBASTIAN",
      state: "FL"
    },
    {
      city: "SEBASTIAN",
      state: "TX"
    },
    {
      city: "SEBASTOPOL",
      state: "CA"
    },
    {
      city: "SEBASTOPOL",
      state: "MS"
    },
    {
      city: "SEBEC",
      state: "ME"
    },
    {
      city: "SEBEKA",
      state: "MN"
    },
    {
      city: "SEBEWAING",
      state: "MI"
    },
    {
      city: "SEBREE",
      state: "KY"
    },
    {
      city: "SEBRING",
      state: "FL"
    },
    {
      city: "SEBRING",
      state: "OH"
    },
    {
      city: "SECAUCUS",
      state: "NJ"
    },
    {
      city: "SECOND MESA",
      state: "AZ"
    },
    {
      city: "SECONDCREEK",
      state: "WV"
    },
    {
      city: "SECOR",
      state: "IL"
    },
    {
      city: "SECRETARY",
      state: "MD"
    },
    {
      city: "SECTION",
      state: "AL"
    },
    {
      city: "SEDALIA",
      state: "CO"
    },
    {
      city: "SEDALIA",
      state: "IN"
    },
    {
      city: "SEDALIA",
      state: "KY"
    },
    {
      city: "SEDALIA",
      state: "MO"
    },
    {
      city: "SEDALIA",
      state: "NC"
    },
    {
      city: "SEDALIA",
      state: "OH"
    },
    {
      city: "SEDAN",
      state: "KS"
    },
    {
      city: "SEDGEWICKVILLE",
      state: "MO"
    },
    {
      city: "SEDGWICK",
      state: "CO"
    },
    {
      city: "SEDGWICK",
      state: "KS"
    },
    {
      city: "SEDGWICK",
      state: "ME"
    },
    {
      city: "SEDLEY",
      state: "VA"
    },
    {
      city: "SEDONA",
      state: "AZ"
    },
    {
      city: "SEDRO WOOLLEY",
      state: "WA"
    },
    {
      city: "SEEKONK",
      state: "MA"
    },
    {
      city: "SEELEY",
      state: "CA"
    },
    {
      city: "SEELEY LAKE",
      state: "MT"
    },
    {
      city: "SEELYVILLE",
      state: "IN"
    },
    {
      city: "SEFFNER",
      state: "FL"
    },
    {
      city: "SEGUIN",
      state: "TX"
    },
    {
      city: "SEIAD VALLEY",
      state: "CA"
    },
    {
      city: "SEIBERT",
      state: "CO"
    },
    {
      city: "SEILING",
      state: "OK"
    },
    {
      city: "SEKIU",
      state: "WA"
    },
    {
      city: "SELAH",
      state: "WA"
    },
    {
      city: "SELAWIK",
      state: "AK"
    },
    {
      city: "SELBY",
      state: "SD"
    },
    {
      city: "SELBYVILLE",
      state: "DE"
    },
    {
      city: "SELDEN",
      state: "KS"
    },
    {
      city: "SELDEN",
      state: "NY"
    },
    {
      city: "SELDOVIA",
      state: "AK"
    },
    {
      city: "SELFRIDGE",
      state: "ND"
    },
    {
      city: "SELIGMAN",
      state: "AZ"
    },
    {
      city: "SELIGMAN",
      state: "MO"
    },
    {
      city: "SELINSGROVE",
      state: "PA"
    },
    {
      city: "SELKIRK",
      state: "NY"
    },
    {
      city: "SELLERS",
      state: "SC"
    },
    {
      city: "SELLERSBURG",
      state: "IN"
    },
    {
      city: "SELLERSVILLE",
      state: "PA"
    },
    {
      city: "SELLS",
      state: "AZ"
    },
    {
      city: "SELMA",
      state: "AL"
    },
    {
      city: "SELMA",
      state: "CA"
    },
    {
      city: "SELMA",
      state: "IN"
    },
    {
      city: "SELMA",
      state: "NC"
    },
    {
      city: "SELMA",
      state: "OR"
    },
    {
      city: "SELMA",
      state: "VA"
    },
    {
      city: "SELMAN CITY",
      state: "TX"
    },
    {
      city: "SELMER",
      state: "TN"
    },
    {
      city: "SELTZER",
      state: "PA"
    },
    {
      city: "SEMINARY",
      state: "MS"
    },
    {
      city: "SEMINOLE",
      state: "AL"
    },
    {
      city: "SEMINOLE",
      state: "FL"
    },
    {
      city: "SEMINOLE",
      state: "OK"
    },
    {
      city: "SEMINOLE",
      state: "PA"
    },
    {
      city: "SEMINOLE",
      state: "TX"
    },
    {
      city: "SEMMES",
      state: "AL"
    },
    {
      city: "SEMORA",
      state: "NC"
    },
    {
      city: "SENATH",
      state: "MO"
    },
    {
      city: "SENATOBIA",
      state: "MS"
    },
    {
      city: "SENECA",
      state: "IL"
    },
    {
      city: "SENECA",
      state: "KS"
    },
    {
      city: "SENECA",
      state: "MO"
    },
    {
      city: "SENECA",
      state: "NE"
    },
    {
      city: "SENECA",
      state: "OR"
    },
    {
      city: "SENECA",
      state: "PA"
    },
    {
      city: "SENECA",
      state: "SC"
    },
    {
      city: "SENECA",
      state: "SD"
    },
    {
      city: "SENECA",
      state: "WI"
    },
    {
      city: "SENECA CASTLE",
      state: "NY"
    },
    {
      city: "SENECA FALLS",
      state: "NY"
    },
    {
      city: "SENECA ROCKS",
      state: "WV"
    },
    {
      city: "SENECAVILLE",
      state: "OH"
    },
    {
      city: "SENEY",
      state: "MI"
    },
    {
      city: "SENOIA",
      state: "GA"
    },
    {
      city: "SENTINEL",
      state: "OK"
    },
    {
      city: "SENTINEL BUTTE",
      state: "ND"
    },
    {
      city: "SEQUATCHIE",
      state: "TN"
    },
    {
      city: "SEQUIM",
      state: "WA"
    },
    {
      city: "SEQUOIA NATIONAL PARK",
      state: "CA"
    },
    {
      city: "SERAFINA",
      state: "NM"
    },
    {
      city: "SERENA",
      state: "IL"
    },
    {
      city: "SERGEANT BLUFF",
      state: "IA"
    },
    {
      city: "SERGEANTSVILLE",
      state: "NJ"
    },
    {
      city: "SERVIA",
      state: "IN"
    },
    {
      city: "SESSER",
      state: "IL"
    },
    {
      city: "SETH",
      state: "WV"
    },
    {
      city: "SEVEN MILE",
      state: "OH"
    },
    {
      city: "SEVEN SPRINGS",
      state: "NC"
    },
    {
      city: "SEVEN VALLEYS",
      state: "PA"
    },
    {
      city: "SEVERANCE",
      state: "CO"
    },
    {
      city: "SEVERANCE",
      state: "NY"
    },
    {
      city: "SEVERN",
      state: "MD"
    },
    {
      city: "SEVERN",
      state: "NC"
    },
    {
      city: "SEVERNA PARK",
      state: "MD"
    },
    {
      city: "SEVERY",
      state: "KS"
    },
    {
      city: "SEVIERVILLE",
      state: "TN"
    },
    {
      city: "SEVILLE",
      state: "FL"
    },
    {
      city: "SEVILLE",
      state: "OH"
    },
    {
      city: "SEWANEE",
      state: "TN"
    },
    {
      city: "SEWARD",
      state: "AK"
    },
    {
      city: "SEWARD",
      state: "IL"
    },
    {
      city: "SEWARD",
      state: "NE"
    },
    {
      city: "SEWARD",
      state: "PA"
    },
    {
      city: "SEWAREN",
      state: "NJ"
    },
    {
      city: "SEWELL",
      state: "NJ"
    },
    {
      city: "SEWICKLEY",
      state: "PA"
    },
    {
      city: "SEXTONS CREEK",
      state: "KY"
    },
    {
      city: "SEYMOUR",
      state: "CT"
    },
    {
      city: "SEYMOUR",
      state: "IA"
    },
    {
      city: "SEYMOUR",
      state: "IL"
    },
    {
      city: "SEYMOUR",
      state: "IN"
    },
    {
      city: "SEYMOUR",
      state: "MO"
    },
    {
      city: "SEYMOUR",
      state: "TN"
    },
    {
      city: "SEYMOUR",
      state: "TX"
    },
    {
      city: "SEYMOUR",
      state: "WI"
    },
    {
      city: "SHABBONA",
      state: "IL"
    },
    {
      city: "SHACKLEFORDS",
      state: "VA"
    },
    {
      city: "SHADE",
      state: "OH"
    },
    {
      city: "SHADE GAP",
      state: "PA"
    },
    {
      city: "SHADY COVE",
      state: "OR"
    },
    {
      city: "SHADY DALE",
      state: "GA"
    },
    {
      city: "SHADY GROVE",
      state: "FL"
    },
    {
      city: "SHADY GROVE",
      state: "PA"
    },
    {
      city: "SHADY POINT",
      state: "OK"
    },
    {
      city: "SHADY SIDE",
      state: "MD"
    },
    {
      city: "SHADY SPRING",
      state: "WV"
    },
    {
      city: "SHADY VALLEY",
      state: "TN"
    },
    {
      city: "SHADYSIDE",
      state: "OH"
    },
    {
      city: "SHAFER",
      state: "MN"
    },
    {
      city: "SHAFTER",
      state: "CA"
    },
    {
      city: "SHAFTSBURG",
      state: "MI"
    },
    {
      city: "SHAFTSBURY",
      state: "VT"
    },
    {
      city: "SHAGELUK",
      state: "AK"
    },
    {
      city: "SHAKOPEE",
      state: "MN"
    },
    {
      city: "SHAKTOOLIK",
      state: "AK"
    },
    {
      city: "SHALIMAR",
      state: "FL"
    },
    {
      city: "SHALLOTTE",
      state: "NC"
    },
    {
      city: "SHALLOWATER",
      state: "TX"
    },
    {
      city: "SHAMBAUGH",
      state: "IA"
    },
    {
      city: "SHAMOKIN",
      state: "PA"
    },
    {
      city: "SHAMOKIN DAM",
      state: "PA"
    },
    {
      city: "SHAMROCK",
      state: "TX"
    },
    {
      city: "SHANDAKEN",
      state: "NY"
    },
    {
      city: "SHANDON",
      state: "CA"
    },
    {
      city: "SHANDON",
      state: "OH"
    },
    {
      city: "SHANKS",
      state: "WV"
    },
    {
      city: "SHANKSVILLE",
      state: "PA"
    },
    {
      city: "SHANNOCK",
      state: "RI"
    },
    {
      city: "SHANNON",
      state: "AL"
    },
    {
      city: "SHANNON",
      state: "GA"
    },
    {
      city: "SHANNON",
      state: "IL"
    },
    {
      city: "SHANNON",
      state: "MS"
    },
    {
      city: "SHANNON",
      state: "NC"
    },
    {
      city: "SHANNON CITY",
      state: "IA"
    },
    {
      city: "SHAPLEIGH",
      state: "ME"
    },
    {
      city: "SHARON",
      state: "CT"
    },
    {
      city: "SHARON",
      state: "GA"
    },
    {
      city: "SHARON",
      state: "KS"
    },
    {
      city: "SHARON",
      state: "MA"
    },
    {
      city: "SHARON",
      state: "MS"
    },
    {
      city: "SHARON",
      state: "ND"
    },
    {
      city: "SHARON",
      state: "OK"
    },
    {
      city: "SHARON",
      state: "PA"
    },
    {
      city: "SHARON",
      state: "SC"
    },
    {
      city: "SHARON",
      state: "TN"
    },
    {
      city: "SHARON",
      state: "VT"
    },
    {
      city: "SHARON",
      state: "WI"
    },
    {
      city: "SHARON CENTER",
      state: "OH"
    },
    {
      city: "SHARON GROVE",
      state: "KY"
    },
    {
      city: "SHARON HILL",
      state: "PA"
    },
    {
      city: "SHARON SPRINGS",
      state: "KS"
    },
    {
      city: "SHARON SPRINGS",
      state: "NY"
    },
    {
      city: "SHARPES",
      state: "FL"
    },
    {
      city: "SHARPLES",
      state: "WV"
    },
    {
      city: "SHARPS",
      state: "VA"
    },
    {
      city: "SHARPS CHAPEL",
      state: "TN"
    },
    {
      city: "SHARPSBURG",
      state: "GA"
    },
    {
      city: "SHARPSBURG",
      state: "IA"
    },
    {
      city: "SHARPSBURG",
      state: "KY"
    },
    {
      city: "SHARPSBURG",
      state: "MD"
    },
    {
      city: "SHARPSBURG",
      state: "NC"
    },
    {
      city: "SHARPSVILLE",
      state: "IN"
    },
    {
      city: "SHARPSVILLE",
      state: "PA"
    },
    {
      city: "SHARPTOWN",
      state: "MD"
    },
    {
      city: "SHARTLESVILLE",
      state: "PA"
    },
    {
      city: "SHASTA",
      state: "CA"
    },
    {
      city: "SHASTA LAKE",
      state: "CA"
    },
    {
      city: "SHATTUCK",
      state: "OK"
    },
    {
      city: "SHAUCK",
      state: "OH"
    },
    {
      city: "SHAVER LAKE",
      state: "CA"
    },
    {
      city: "SHAVERTOWN",
      state: "PA"
    },
    {
      city: "SHAW",
      state: "MS"
    },
    {
      city: "SHAW AFB",
      state: "SC"
    },
    {
      city: "SHAW ISLAND",
      state: "WA"
    },
    {
      city: "SHAWANEE",
      state: "TN"
    },
    {
      city: "SHAWANESE",
      state: "PA"
    },
    {
      city: "SHAWANO",
      state: "WI"
    },
    {
      city: "SHAWBORO",
      state: "NC"
    },
    {
      city: "SHAWMUT",
      state: "ME"
    },
    {
      city: "SHAWMUT",
      state: "MT"
    },
    {
      city: "SHAWNEE",
      state: "CO"
    },
    {
      city: "SHAWNEE",
      state: "KS"
    },
    {
      city: "SHAWNEE",
      state: "OH"
    },
    {
      city: "SHAWNEE",
      state: "OK"
    },
    {
      city: "SHAWNEE ON DELAWARE",
      state: "PA"
    },
    {
      city: "SHAWNEETOWN",
      state: "IL"
    },
    {
      city: "SHAWSVILLE",
      state: "VA"
    },
    {
      city: "SHAWVILLE",
      state: "PA"
    },
    {
      city: "SHEAKLEYVILLE",
      state: "PA"
    },
    {
      city: "SHEBOYGAN",
      state: "WI"
    },
    {
      city: "SHEBOYGAN FALLS",
      state: "WI"
    },
    {
      city: "SHEDD",
      state: "OR"
    },
    {
      city: "SHEEP SPRINGS",
      state: "NM"
    },
    {
      city: "SHEFFIELD",
      state: "AL"
    },
    {
      city: "SHEFFIELD",
      state: "IA"
    },
    {
      city: "SHEFFIELD",
      state: "IL"
    },
    {
      city: "SHEFFIELD",
      state: "MA"
    },
    {
      city: "SHEFFIELD",
      state: "PA"
    },
    {
      city: "SHEFFIELD",
      state: "TX"
    },
    {
      city: "SHEFFIELD",
      state: "VT"
    },
    {
      city: "SHEFFIELD LAKE",
      state: "OH"
    },
    {
      city: "SHELBIANA",
      state: "KY"
    },
    {
      city: "SHELBINA",
      state: "MO"
    },
    {
      city: "SHELBURN",
      state: "IN"
    },
    {
      city: "SHELBURNE",
      state: "VT"
    },
    {
      city: "SHELBURNE FALLS",
      state: "MA"
    },
    {
      city: "SHELBY",
      state: "AL"
    },
    {
      city: "SHELBY",
      state: "IA"
    },
    {
      city: "SHELBY",
      state: "IN"
    },
    {
      city: "SHELBY",
      state: "MI"
    },
    {
      city: "SHELBY",
      state: "MS"
    },
    {
      city: "SHELBY",
      state: "MT"
    },
    {
      city: "SHELBY",
      state: "NC"
    },
    {
      city: "SHELBY",
      state: "NE"
    },
    {
      city: "SHELBY",
      state: "OH"
    },
    {
      city: "SHELBY GAP",
      state: "KY"
    },
    {
      city: "SHELBYVILLE",
      state: "IL"
    },
    {
      city: "SHELBYVILLE",
      state: "IN"
    },
    {
      city: "SHELBYVILLE",
      state: "KY"
    },
    {
      city: "SHELBYVILLE",
      state: "MI"
    },
    {
      city: "SHELBYVILLE",
      state: "MO"
    },
    {
      city: "SHELBYVILLE",
      state: "TN"
    },
    {
      city: "SHELBYVILLE",
      state: "TX"
    },
    {
      city: "SHELDAHL",
      state: "IA"
    },
    {
      city: "SHELDON",
      state: "IA"
    },
    {
      city: "SHELDON",
      state: "IL"
    },
    {
      city: "SHELDON",
      state: "MO"
    },
    {
      city: "SHELDON",
      state: "ND"
    },
    {
      city: "SHELDON",
      state: "SC"
    },
    {
      city: "SHELDON",
      state: "VT"
    },
    {
      city: "SHELDON",
      state: "WI"
    },
    {
      city: "SHELDON SPRINGS",
      state: "VT"
    },
    {
      city: "SHELDONVILLE",
      state: "MA"
    },
    {
      city: "SHELL",
      state: "WY"
    },
    {
      city: "SHELL KNOB",
      state: "MO"
    },
    {
      city: "SHELL LAKE",
      state: "WI"
    },
    {
      city: "SHELL ROCK",
      state: "IA"
    },
    {
      city: "SHELLEY",
      state: "ID"
    },
    {
      city: "SHELLMAN",
      state: "GA"
    },
    {
      city: "SHELLSBURG",
      state: "IA"
    },
    {
      city: "SHELLY",
      state: "MN"
    },
    {
      city: "SHELOCTA",
      state: "PA"
    },
    {
      city: "SHELTER ISLAND",
      state: "NY"
    },
    {
      city: "SHELTER ISLAND HEIGHTS",
      state: "NY"
    },
    {
      city: "SHELTON",
      state: "CT"
    },
    {
      city: "SHELTON",
      state: "NE"
    },
    {
      city: "SHELTON",
      state: "WA"
    },
    {
      city: "SHENANDOAH",
      state: "IA"
    },
    {
      city: "SHENANDOAH",
      state: "PA"
    },
    {
      city: "SHENANDOAH",
      state: "VA"
    },
    {
      city: "SHENANDOAH JUNCTION",
      state: "WV"
    },
    {
      city: "SHENOROCK",
      state: "NY"
    },
    {
      city: "SHEPHERD",
      state: "MI"
    },
    {
      city: "SHEPHERD",
      state: "MT"
    },
    {
      city: "SHEPHERD",
      state: "TX"
    },
    {
      city: "SHEPHERDSTOWN",
      state: "WV"
    },
    {
      city: "SHEPHERDSVILLE",
      state: "KY"
    },
    {
      city: "SHEPPARD AFB",
      state: "TX"
    },
    {
      city: "SHEPPTON",
      state: "PA"
    },
    {
      city: "SHERBORN",
      state: "MA"
    },
    {
      city: "SHERBURN",
      state: "MN"
    },
    {
      city: "SHERBURNE",
      state: "NY"
    },
    {
      city: "SHERIDAN",
      state: "AR"
    },
    {
      city: "SHERIDAN",
      state: "CA"
    },
    {
      city: "SHERIDAN",
      state: "IL"
    },
    {
      city: "SHERIDAN",
      state: "IN"
    },
    {
      city: "SHERIDAN",
      state: "ME"
    },
    {
      city: "SHERIDAN",
      state: "MI"
    },
    {
      city: "SHERIDAN",
      state: "MO"
    },
    {
      city: "SHERIDAN",
      state: "MT"
    },
    {
      city: "SHERIDAN",
      state: "NY"
    },
    {
      city: "SHERIDAN",
      state: "OR"
    },
    {
      city: "SHERIDAN",
      state: "TX"
    },
    {
      city: "SHERIDAN",
      state: "WY"
    },
    {
      city: "SHERIDAN LAKE",
      state: "CO"
    },
    {
      city: "SHERMAN",
      state: "CT"
    },
    {
      city: "SHERMAN",
      state: "IL"
    },
    {
      city: "SHERMAN",
      state: "ME"
    },
    {
      city: "SHERMAN",
      state: "MS"
    },
    {
      city: "SHERMAN",
      state: "NY"
    },
    {
      city: "SHERMAN",
      state: "TX"
    },
    {
      city: "SHERMAN OAKS",
      state: "CA"
    },
    {
      city: "SHERMANS DALE",
      state: "PA"
    },
    {
      city: "SHERRARD",
      state: "IL"
    },
    {
      city: "SHERRILL",
      state: "AR"
    },
    {
      city: "SHERRILL",
      state: "IA"
    },
    {
      city: "SHERRILL",
      state: "NY"
    },
    {
      city: "SHERRILLS FORD",
      state: "NC"
    },
    {
      city: "SHERRODSVILLE",
      state: "OH"
    },
    {
      city: "SHERWOOD",
      state: "AR"
    },
    {
      city: "SHERWOOD",
      state: "MD"
    },
    {
      city: "SHERWOOD",
      state: "MI"
    },
    {
      city: "SHERWOOD",
      state: "ND"
    },
    {
      city: "SHERWOOD",
      state: "OH"
    },
    {
      city: "SHERWOOD",
      state: "OR"
    },
    {
      city: "SHERWOOD",
      state: "TN"
    },
    {
      city: "SHERWOOD",
      state: "WI"
    },
    {
      city: "SHEVLIN",
      state: "MN"
    },
    {
      city: "SHEYENNE",
      state: "ND"
    },
    {
      city: "SHICKLEY",
      state: "NE"
    },
    {
      city: "SHICKSHINNY",
      state: "PA"
    },
    {
      city: "SHIDLER",
      state: "OK"
    },
    {
      city: "SHIELDS",
      state: "ND"
    },
    {
      city: "SHILOH",
      state: "GA"
    },
    {
      city: "SHILOH",
      state: "NC"
    },
    {
      city: "SHILOH",
      state: "NJ"
    },
    {
      city: "SHILOH",
      state: "OH"
    },
    {
      city: "SHILOH",
      state: "TN"
    },
    {
      city: "SHINER",
      state: "TX"
    },
    {
      city: "SHINGLE SPRINGS",
      state: "CA"
    },
    {
      city: "SHINGLEHOUSE",
      state: "PA"
    },
    {
      city: "SHINGLETON",
      state: "MI"
    },
    {
      city: "SHINGLETOWN",
      state: "CA"
    },
    {
      city: "SHINNSTON",
      state: "WV"
    },
    {
      city: "SHIOCTON",
      state: "WI"
    },
    {
      city: "SHIPMAN",
      state: "IL"
    },
    {
      city: "SHIPMAN",
      state: "VA"
    },
    {
      city: "SHIPPENSBURG",
      state: "PA"
    },
    {
      city: "SHIPPENVILLE",
      state: "PA"
    },
    {
      city: "SHIPPINGPORT",
      state: "PA"
    },
    {
      city: "SHIPROCK",
      state: "NM"
    },
    {
      city: "SHIPSHEWANA",
      state: "IN"
    },
    {
      city: "SHIRLAND",
      state: "IL"
    },
    {
      city: "SHIRLEY",
      state: "AR"
    },
    {
      city: "SHIRLEY",
      state: "IL"
    },
    {
      city: "SHIRLEY",
      state: "IN"
    },
    {
      city: "SHIRLEY",
      state: "MA"
    },
    {
      city: "SHIRLEY",
      state: "NY"
    },
    {
      city: "SHIRLEY BASIN",
      state: "WY"
    },
    {
      city: "SHIRLEY MILLS",
      state: "ME"
    },
    {
      city: "SHIRLEYSBURG",
      state: "PA"
    },
    {
      city: "SHIRO",
      state: "TX"
    },
    {
      city: "SHISHMAREF",
      state: "AK"
    },
    {
      city: "SHOALS",
      state: "IN"
    },
    {
      city: "SHOBONIER",
      state: "IL"
    },
    {
      city: "SHOEMAKERSVILLE",
      state: "PA"
    },
    {
      city: "SHOHOLA",
      state: "PA"
    },
    {
      city: "SHOKAN",
      state: "NY"
    },
    {
      city: "SHONGALOO",
      state: "LA"
    },
    {
      city: "SHONTO",
      state: "AZ"
    },
    {
      city: "SHOREHAM",
      state: "NY"
    },
    {
      city: "SHOREHAM",
      state: "VT"
    },
    {
      city: "SHOREWOOD",
      state: "IL"
    },
    {
      city: "SHORT CREEK",
      state: "WV"
    },
    {
      city: "SHORT HILLS",
      state: "NJ"
    },
    {
      city: "SHORTER",
      state: "AL"
    },
    {
      city: "SHORTERVILLE",
      state: "AL"
    },
    {
      city: "SHORTSVILLE",
      state: "NY"
    },
    {
      city: "SHORTT GAP",
      state: "VA"
    },
    {
      city: "SHOSHONE",
      state: "CA"
    },
    {
      city: "SHOSHONE",
      state: "ID"
    },
    {
      city: "SHOSHONI",
      state: "WY"
    },
    {
      city: "SHOUP",
      state: "ID"
    },
    {
      city: "SHOW LOW",
      state: "AZ"
    },
    {
      city: "SHOWELL",
      state: "MD"
    },
    {
      city: "SHREVE",
      state: "OH"
    },
    {
      city: "SHREVEPORT",
      state: "LA"
    },
    {
      city: "SHREWSBURY",
      state: "MA"
    },
    {
      city: "SHREWSBURY",
      state: "NJ"
    },
    {
      city: "SHREWSBURY",
      state: "PA"
    },
    {
      city: "SHRUB OAK",
      state: "NY"
    },
    {
      city: "SHUBERT",
      state: "NE"
    },
    {
      city: "SHUBUTA",
      state: "MS"
    },
    {
      city: "SHULLSBURG",
      state: "WI"
    },
    {
      city: "SHUMWAY",
      state: "IL"
    },
    {
      city: "SHUNK",
      state: "PA"
    },
    {
      city: "SHUQUALAK",
      state: "MS"
    },
    {
      city: "SHUSHAN",
      state: "NY"
    },
    {
      city: "SHUTESBURY",
      state: "MA"
    },
    {
      city: "SIASCONSET",
      state: "MA"
    },
    {
      city: "SIBLEY",
      state: "IA"
    },
    {
      city: "SIBLEY",
      state: "IL"
    },
    {
      city: "SIBLEY",
      state: "LA"
    },
    {
      city: "SIBLEY",
      state: "MO"
    },
    {
      city: "SIBLEY",
      state: "MS"
    },
    {
      city: "SICILY ISLAND",
      state: "LA"
    },
    {
      city: "SICKLERVILLE",
      state: "NJ"
    },
    {
      city: "SIDE LAKE",
      state: "MN"
    },
    {
      city: "SIDELL",
      state: "IL"
    },
    {
      city: "SIDMAN",
      state: "PA"
    },
    {
      city: "SIDNEY",
      state: "AR"
    },
    {
      city: "SIDNEY",
      state: "IA"
    },
    {
      city: "SIDNEY",
      state: "IL"
    },
    {
      city: "SIDNEY",
      state: "KY"
    },
    {
      city: "SIDNEY",
      state: "MI"
    },
    {
      city: "SIDNEY",
      state: "MT"
    },
    {
      city: "SIDNEY",
      state: "NE"
    },
    {
      city: "SIDNEY",
      state: "NY"
    },
    {
      city: "SIDNEY",
      state: "OH"
    },
    {
      city: "SIDNEY",
      state: "TX"
    },
    {
      city: "SIDNEY CENTER",
      state: "NY"
    },
    {
      city: "SIDON",
      state: "MS"
    },
    {
      city: "SIEPER",
      state: "LA"
    },
    {
      city: "SIERRA BLANCA",
      state: "TX"
    },
    {
      city: "SIERRA CITY",
      state: "CA"
    },
    {
      city: "SIERRA MADRE",
      state: "CA"
    },
    {
      city: "SIERRA VISTA",
      state: "AZ"
    },
    {
      city: "SIERRAVILLE",
      state: "CA"
    },
    {
      city: "SIGEL",
      state: "IL"
    },
    {
      city: "SIGEL",
      state: "PA"
    },
    {
      city: "SIGNAL HILL",
      state: "CA"
    },
    {
      city: "SIGNAL MOUNTAIN",
      state: "TN"
    },
    {
      city: "SIGOURNEY",
      state: "IA"
    },
    {
      city: "SIGURD",
      state: "UT"
    },
    {
      city: "SIKES",
      state: "LA"
    },
    {
      city: "SIKESTON",
      state: "MO"
    },
    {
      city: "SILAS",
      state: "AL"
    },
    {
      city: "SILER",
      state: "KY"
    },
    {
      city: "SILER CITY",
      state: "NC"
    },
    {
      city: "SILETZ",
      state: "OR"
    },
    {
      city: "SILEX",
      state: "MO"
    },
    {
      city: "SILOAM",
      state: "GA"
    },
    {
      city: "SILOAM",
      state: "NC"
    },
    {
      city: "SILOAM SPRINGS",
      state: "AR"
    },
    {
      city: "SILSBEE",
      state: "TX"
    },
    {
      city: "SILT",
      state: "CO"
    },
    {
      city: "SILVA",
      state: "MO"
    },
    {
      city: "SILVANA",
      state: "WA"
    },
    {
      city: "SILVER",
      state: "TX"
    },
    {
      city: "SILVER BAY",
      state: "MN"
    },
    {
      city: "SILVER BAY",
      state: "NY"
    },
    {
      city: "SILVER CITY",
      state: "IA"
    },
    {
      city: "SILVER CITY",
      state: "MS"
    },
    {
      city: "SILVER CITY",
      state: "NM"
    },
    {
      city: "SILVER CITY",
      state: "NV"
    },
    {
      city: "SILVER CREEK",
      state: "GA"
    },
    {
      city: "SILVER CREEK",
      state: "MN"
    },
    {
      city: "SILVER CREEK",
      state: "MS"
    },
    {
      city: "SILVER CREEK",
      state: "NE"
    },
    {
      city: "SILVER CREEK",
      state: "NY"
    },
    {
      city: "SILVER CREEK",
      state: "WA"
    },
    {
      city: "SILVER GATE",
      state: "MT"
    },
    {
      city: "SILVER GROVE",
      state: "KY"
    },
    {
      city: "SILVER LAKE",
      state: "IN"
    },
    {
      city: "SILVER LAKE",
      state: "KS"
    },
    {
      city: "SILVER LAKE",
      state: "MN"
    },
    {
      city: "SILVER LAKE",
      state: "NH"
    },
    {
      city: "SILVER LAKE",
      state: "NY"
    },
    {
      city: "SILVER LAKE",
      state: "OR"
    },
    {
      city: "SILVER LAKE",
      state: "WI"
    },
    {
      city: "SILVER PLUME",
      state: "CO"
    },
    {
      city: "SILVER POINT",
      state: "TN"
    },
    {
      city: "SILVER SPRING",
      state: "MD"
    },
    {
      city: "SILVER SPRING",
      state: "PA"
    },
    {
      city: "SILVER SPRINGS",
      state: "FL"
    },
    {
      city: "SILVER SPRINGS",
      state: "NV"
    },
    {
      city: "SILVER SPRINGS",
      state: "NY"
    },
    {
      city: "SILVER STAR",
      state: "MT"
    },
    {
      city: "SILVERADO",
      state: "CA"
    },
    {
      city: "SILVERDALE",
      state: "PA"
    },
    {
      city: "SILVERDALE",
      state: "WA"
    },
    {
      city: "SILVERHILL",
      state: "AL"
    },
    {
      city: "SILVERLAKE",
      state: "WA"
    },
    {
      city: "SILVERPEAK",
      state: "NV"
    },
    {
      city: "SILVERSTREET",
      state: "SC"
    },
    {
      city: "SILVERTHORNE",
      state: "CO"
    },
    {
      city: "SILVERTON",
      state: "CO"
    },
    {
      city: "SILVERTON",
      state: "ID"
    },
    {
      city: "SILVERTON",
      state: "OR"
    },
    {
      city: "SILVERTON",
      state: "TX"
    },
    {
      city: "SILVERWOOD",
      state: "MI"
    },
    {
      city: "SILVIS",
      state: "IL"
    },
    {
      city: "SIMI VALLEY",
      state: "CA"
    },
    {
      city: "SIMLA",
      state: "CO"
    },
    {
      city: "SIMMESPORT",
      state: "LA"
    },
    {
      city: "SIMMS",
      state: "MT"
    },
    {
      city: "SIMMS",
      state: "TX"
    },
    {
      city: "SIMON",
      state: "WV"
    },
    {
      city: "SIMONTON",
      state: "TX"
    },
    {
      city: "SIMPSON",
      state: "IL"
    },
    {
      city: "SIMPSON",
      state: "KS"
    },
    {
      city: "SIMPSON",
      state: "LA"
    },
    {
      city: "SIMPSON",
      state: "NC"
    },
    {
      city: "SIMPSON",
      state: "WV"
    },
    {
      city: "SIMPSONVILLE",
      state: "KY"
    },
    {
      city: "SIMPSONVILLE",
      state: "MD"
    },
    {
      city: "SIMPSONVILLE",
      state: "SC"
    },
    {
      city: "SIMS",
      state: "IL"
    },
    {
      city: "SIMS",
      state: "NC"
    },
    {
      city: "SIMSBORO",
      state: "LA"
    },
    {
      city: "SIMSBURY",
      state: "CT"
    },
    {
      city: "SINAI",
      state: "SD"
    },
    {
      city: "SINCLAIR",
      state: "ME"
    },
    {
      city: "SINCLAIR",
      state: "WY"
    },
    {
      city: "SINCLAIRVILLE",
      state: "NY"
    },
    {
      city: "SINGER",
      state: "LA"
    },
    {
      city: "SINGERS GLEN",
      state: "VA"
    },
    {
      city: "SINKING SPRING",
      state: "OH"
    },
    {
      city: "SINKS GROVE",
      state: "WV"
    },
    {
      city: "SINNAMAHONING",
      state: "PA"
    },
    {
      city: "SINTON",
      state: "TX"
    },
    {
      city: "SIOUX CENTER",
      state: "IA"
    },
    {
      city: "SIOUX CITY",
      state: "IA"
    },
    {
      city: "SIOUX FALLS",
      state: "SD"
    },
    {
      city: "SIOUX RAPIDS",
      state: "IA"
    },
    {
      city: "SIPESVILLE",
      state: "PA"
    },
    {
      city: "SIPSEY",
      state: "AL"
    },
    {
      city: "SIREN",
      state: "WI"
    },
    {
      city: "SISSETON",
      state: "SD"
    },
    {
      city: "SISTER BAY",
      state: "WI"
    },
    {
      city: "SISTERS",
      state: "OR"
    },
    {
      city: "SISTERSVILLE",
      state: "WV"
    },
    {
      city: "SITKA",
      state: "AK"
    },
    {
      city: "SITKA",
      state: "KY"
    },
    {
      city: "SIX LAKES",
      state: "MI"
    },
    {
      city: "SIX MILE",
      state: "SC"
    },
    {
      city: "SIX MILE RUN",
      state: "PA"
    },
    {
      city: "SIXES",
      state: "OR"
    },
    {
      city: "SKAGWAY",
      state: "AK"
    },
    {
      city: "SKAMOKAWA",
      state: "WA"
    },
    {
      city: "SKANDIA",
      state: "MI"
    },
    {
      city: "SKANEATELES",
      state: "NY"
    },
    {
      city: "SKANEATELES FALLS",
      state: "NY"
    },
    {
      city: "SKANEE",
      state: "MI"
    },
    {
      city: "SKELLYTOWN",
      state: "TX"
    },
    {
      city: "SKELTON",
      state: "WV"
    },
    {
      city: "SKIATOOK",
      state: "OK"
    },
    {
      city: "SKIDMORE",
      state: "MO"
    },
    {
      city: "SKIDMORE",
      state: "TX"
    },
    {
      city: "SKILLMAN",
      state: "NJ"
    },
    {
      city: "SKIPPACK",
      state: "PA"
    },
    {
      city: "SKIPPERS",
      state: "VA"
    },
    {
      city: "SKIPPERVILLE",
      state: "AL"
    },
    {
      city: "SKIPWITH",
      state: "VA"
    },
    {
      city: "SKOKIE",
      state: "IL"
    },
    {
      city: "SKOWHEGAN",
      state: "ME"
    },
    {
      city: "SKULL VALLEY",
      state: "AZ"
    },
    {
      city: "SKWENTNA",
      state: "AK"
    },
    {
      city: "SKYFOREST",
      state: "CA"
    },
    {
      city: "SKYKOMISH",
      state: "WA"
    },
    {
      city: "SKYLAND",
      state: "NC"
    },
    {
      city: "SKYTOP",
      state: "PA"
    },
    {
      city: "SLAB FORK",
      state: "WV"
    },
    {
      city: "SLADE",
      state: "KY"
    },
    {
      city: "SLAGLE",
      state: "LA"
    },
    {
      city: "SLANESVILLE",
      state: "WV"
    },
    {
      city: "SLATE HILL",
      state: "NY"
    },
    {
      city: "SLATE RUN",
      state: "PA"
    },
    {
      city: "SLATE SPRING",
      state: "MS"
    },
    {
      city: "SLATEDALE",
      state: "PA"
    },
    {
      city: "SLATER",
      state: "CO"
    },
    {
      city: "SLATER",
      state: "IA"
    },
    {
      city: "SLATER",
      state: "MO"
    },
    {
      city: "SLATER",
      state: "SC"
    },
    {
      city: "SLATERSVILLE",
      state: "RI"
    },
    {
      city: "SLATERVILLE SPRINGS",
      state: "NY"
    },
    {
      city: "SLATINGTON",
      state: "PA"
    },
    {
      city: "SLATON",
      state: "TX"
    },
    {
      city: "SLATYFORK",
      state: "WV"
    },
    {
      city: "SLAUGHTER",
      state: "LA"
    },
    {
      city: "SLAUGHTERS",
      state: "KY"
    },
    {
      city: "SLAYTON",
      state: "MN"
    },
    {
      city: "SLEDGE",
      state: "MS"
    },
    {
      city: "SLEEPY EYE",
      state: "MN"
    },
    {
      city: "SLEETMUTE",
      state: "AK"
    },
    {
      city: "SLEMP",
      state: "KY"
    },
    {
      city: "SLICK",
      state: "OK"
    },
    {
      city: "SLICKVILLE",
      state: "PA"
    },
    {
      city: "SLIDELL",
      state: "LA"
    },
    {
      city: "SLIDELL",
      state: "TX"
    },
    {
      city: "SLIGO",
      state: "PA"
    },
    {
      city: "SLINGER",
      state: "WI"
    },
    {
      city: "SLINGERLANDS",
      state: "NY"
    },
    {
      city: "SLIPPERY ROCK",
      state: "PA"
    },
    {
      city: "SLOAN",
      state: "IA"
    },
    {
      city: "SLOAN",
      state: "NV"
    },
    {
      city: "SLOANSVILLE",
      state: "NY"
    },
    {
      city: "SLOATSBURG",
      state: "NY"
    },
    {
      city: "SLOCOMB",
      state: "AL"
    },
    {
      city: "SLOCUM",
      state: "RI"
    },
    {
      city: "SLOUGHHOUSE",
      state: "CA"
    },
    {
      city: "SLOVAN",
      state: "PA"
    },
    {
      city: "SMACKOVER",
      state: "AR"
    },
    {
      city: "SMALLWOOD",
      state: "NY"
    },
    {
      city: "SMARR",
      state: "GA"
    },
    {
      city: "SMARTSVILLE",
      state: "CA"
    },
    {
      city: "SMARTT",
      state: "TN"
    },
    {
      city: "SMELTERVILLE",
      state: "ID"
    },
    {
      city: "SMETHPORT",
      state: "PA"
    },
    {
      city: "SMICKSBURG",
      state: "PA"
    },
    {
      city: "SMILEY",
      state: "TX"
    },
    {
      city: "SMITH",
      state: "NV"
    },
    {
      city: "SMITH CENTER",
      state: "KS"
    },
    {
      city: "SMITH LAKE",
      state: "NM"
    },
    {
      city: "SMITH MILLS",
      state: "KY"
    },
    {
      city: "SMITH RIVER",
      state: "CA"
    },
    {
      city: "SMITHBORO",
      state: "IL"
    },
    {
      city: "SMITHBORO",
      state: "NY"
    },
    {
      city: "SMITHBURG",
      state: "WV"
    },
    {
      city: "SMITHDALE",
      state: "MS"
    },
    {
      city: "SMITHERS",
      state: "WV"
    },
    {
      city: "SMITHFIELD",
      state: "IL"
    },
    {
      city: "SMITHFIELD",
      state: "KY"
    },
    {
      city: "SMITHFIELD",
      state: "ME"
    },
    {
      city: "SMITHFIELD",
      state: "NC"
    },
    {
      city: "SMITHFIELD",
      state: "NE"
    },
    {
      city: "SMITHFIELD",
      state: "OH"
    },
    {
      city: "SMITHFIELD",
      state: "PA"
    },
    {
      city: "SMITHFIELD",
      state: "RI"
    },
    {
      city: "SMITHFIELD",
      state: "UT"
    },
    {
      city: "SMITHFIELD",
      state: "VA"
    },
    {
      city: "SMITHFIELD",
      state: "WV"
    },
    {
      city: "SMITHLAND",
      state: "IA"
    },
    {
      city: "SMITHLAND",
      state: "KY"
    },
    {
      city: "SMITHMILL",
      state: "PA"
    },
    {
      city: "SMITHS CREEK",
      state: "MI"
    },
    {
      city: "SMITHS GROVE",
      state: "KY"
    },
    {
      city: "SMITHS STATION",
      state: "AL"
    },
    {
      city: "SMITHSBURG",
      state: "MD"
    },
    {
      city: "SMITHSHIRE",
      state: "IL"
    },
    {
      city: "SMITHTON",
      state: "IL"
    },
    {
      city: "SMITHTON",
      state: "MO"
    },
    {
      city: "SMITHTON",
      state: "PA"
    },
    {
      city: "SMITHTOWN",
      state: "NY"
    },
    {
      city: "SMITHVILLE",
      state: "AR"
    },
    {
      city: "SMITHVILLE",
      state: "GA"
    },
    {
      city: "SMITHVILLE",
      state: "IN"
    },
    {
      city: "SMITHVILLE",
      state: "MO"
    },
    {
      city: "SMITHVILLE",
      state: "MS"
    },
    {
      city: "SMITHVILLE",
      state: "OH"
    },
    {
      city: "SMITHVILLE",
      state: "OK"
    },
    {
      city: "SMITHVILLE",
      state: "TN"
    },
    {
      city: "SMITHVILLE",
      state: "TX"
    },
    {
      city: "SMITHVILLE",
      state: "WV"
    },
    {
      city: "SMITHVILLE FLATS",
      state: "NY"
    },
    {
      city: "SMOAKS",
      state: "SC"
    },
    {
      city: "SMOCK",
      state: "PA"
    },
    {
      city: "SMOKERUN",
      state: "PA"
    },
    {
      city: "SMOKETOWN",
      state: "PA"
    },
    {
      city: "SMOOT",
      state: "WV"
    },
    {
      city: "SMOOT",
      state: "WY"
    },
    {
      city: "SMYER",
      state: "TX"
    },
    {
      city: "SMYRNA",
      state: "DE"
    },
    {
      city: "SMYRNA",
      state: "GA"
    },
    {
      city: "SMYRNA",
      state: "MI"
    },
    {
      city: "SMYRNA",
      state: "NC"
    },
    {
      city: "SMYRNA",
      state: "NY"
    },
    {
      city: "SMYRNA",
      state: "SC"
    },
    {
      city: "SMYRNA",
      state: "TN"
    },
    {
      city: "SMYRNA MILLS",
      state: "ME"
    },
    {
      city: "SNEADS",
      state: "FL"
    },
    {
      city: "SNEADS FERRY",
      state: "NC"
    },
    {
      city: "SNEEDVILLE",
      state: "TN"
    },
    {
      city: "SNELLING",
      state: "CA"
    },
    {
      city: "SNELLVILLE",
      state: "GA"
    },
    {
      city: "SNOHOMISH",
      state: "WA"
    },
    {
      city: "SNOOK",
      state: "TX"
    },
    {
      city: "SNOQUALMIE",
      state: "WA"
    },
    {
      city: "SNOQUALMIE PASS",
      state: "WA"
    },
    {
      city: "SNOVER",
      state: "MI"
    },
    {
      city: "SNOW CAMP",
      state: "NC"
    },
    {
      city: "SNOW HILL",
      state: "MD"
    },
    {
      city: "SNOW HILL",
      state: "NC"
    },
    {
      city: "SNOW SHOE",
      state: "PA"
    },
    {
      city: "SNOWFLAKE",
      state: "AZ"
    },
    {
      city: "SNOWMASS",
      state: "CO"
    },
    {
      city: "SNOWMASS VILLAGE",
      state: "CO"
    },
    {
      city: "SNOWSHOE",
      state: "WV"
    },
    {
      city: "SNOWVILLE",
      state: "UT"
    },
    {
      city: "SNYDER",
      state: "CO"
    },
    {
      city: "SNYDER",
      state: "NE"
    },
    {
      city: "SNYDER",
      state: "OK"
    },
    {
      city: "SNYDER",
      state: "TX"
    },
    {
      city: "SNYDERTOWN",
      state: "PA"
    },
    {
      city: "SOAP LAKE",
      state: "WA"
    },
    {
      city: "SOBIESKI",
      state: "WI"
    },
    {
      city: "SOCIAL CIRCLE",
      state: "GA"
    },
    {
      city: "SOCIETY HILL",
      state: "SC"
    },
    {
      city: "SOCORRO",
      state: "NM"
    },
    {
      city: "SOD",
      state: "WV"
    },
    {
      city: "SODA SPRINGS",
      state: "CA"
    },
    {
      city: "SODA SPRINGS",
      state: "ID"
    },
    {
      city: "SODDY DAISY",
      state: "TN"
    },
    {
      city: "SODUS",
      state: "MI"
    },
    {
      city: "SODUS",
      state: "NY"
    },
    {
      city: "SODUS POINT",
      state: "NY"
    },
    {
      city: "SOLANA BEACH",
      state: "CA"
    },
    {
      city: "SOLDIER",
      state: "IA"
    },
    {
      city: "SOLDIER",
      state: "KS"
    },
    {
      city: "SOLDIER",
      state: "KY"
    },
    {
      city: "SOLDIERS GROVE",
      state: "WI"
    },
    {
      city: "SOLDOTNA",
      state: "AK"
    },
    {
      city: "SOLEBURY",
      state: "PA"
    },
    {
      city: "SOLEDAD",
      state: "CA"
    },
    {
      city: "SOLEN",
      state: "ND"
    },
    {
      city: "SOLGOHACHIA",
      state: "AR"
    },
    {
      city: "SOLOMON",
      state: "AZ"
    },
    {
      city: "SOLOMON",
      state: "KS"
    },
    {
      city: "SOLOMONS",
      state: "MD"
    },
    {
      city: "SOLON",
      state: "IA"
    },
    {
      city: "SOLON",
      state: "ME"
    },
    {
      city: "SOLON",
      state: "OH"
    },
    {
      city: "SOLON SPRINGS",
      state: "WI"
    },
    {
      city: "SOLSBERRY",
      state: "IN"
    },
    {
      city: "SOLSVILLE",
      state: "NY"
    },
    {
      city: "SOLVANG",
      state: "CA"
    },
    {
      city: "SOLWAY",
      state: "MN"
    },
    {
      city: "SOMERDALE",
      state: "NJ"
    },
    {
      city: "SOMERDALE",
      state: "OH"
    },
    {
      city: "SOMERS",
      state: "CT"
    },
    {
      city: "SOMERS",
      state: "IA"
    },
    {
      city: "SOMERS",
      state: "MT"
    },
    {
      city: "SOMERS",
      state: "NY"
    },
    {
      city: "SOMERS",
      state: "WI"
    },
    {
      city: "SOMERS POINT",
      state: "NJ"
    },
    {
      city: "SOMERSET",
      state: "CA"
    },
    {
      city: "SOMERSET",
      state: "CO"
    },
    {
      city: "SOMERSET",
      state: "IN"
    },
    {
      city: "SOMERSET",
      state: "KY"
    },
    {
      city: "SOMERSET",
      state: "MA"
    },
    {
      city: "SOMERSET",
      state: "MI"
    },
    {
      city: "SOMERSET",
      state: "NJ"
    },
    {
      city: "SOMERSET",
      state: "OH"
    },
    {
      city: "SOMERSET",
      state: "PA"
    },
    {
      city: "SOMERSET",
      state: "TX"
    },
    {
      city: "SOMERSET",
      state: "VA"
    },
    {
      city: "SOMERSET",
      state: "WI"
    },
    {
      city: "SOMERSET CENTER",
      state: "MI"
    },
    {
      city: "SOMERSVILLE",
      state: "CT"
    },
    {
      city: "SOMERSWORTH",
      state: "NH"
    },
    {
      city: "SOMERTON",
      state: "AZ"
    },
    {
      city: "SOMERVILLE",
      state: "AL"
    },
    {
      city: "SOMERVILLE",
      state: "IN"
    },
    {
      city: "SOMERVILLE",
      state: "MA"
    },
    {
      city: "SOMERVILLE",
      state: "NJ"
    },
    {
      city: "SOMERVILLE",
      state: "OH"
    },
    {
      city: "SOMERVILLE",
      state: "TN"
    },
    {
      city: "SOMERVILLE",
      state: "TX"
    },
    {
      city: "SOMES BAR",
      state: "CA"
    },
    {
      city: "SOMIS",
      state: "CA"
    },
    {
      city: "SOMONAUK",
      state: "IL"
    },
    {
      city: "SONDHEIMER",
      state: "LA"
    },
    {
      city: "SONOITA",
      state: "AZ"
    },
    {
      city: "SONOMA",
      state: "CA"
    },
    {
      city: "SONORA",
      state: "CA"
    },
    {
      city: "SONORA",
      state: "KY"
    },
    {
      city: "SONORA",
      state: "TX"
    },
    {
      city: "SONTAG",
      state: "MS"
    },
    {
      city: "SONYEA",
      state: "NY"
    },
    {
      city: "SOPCHOPPY",
      state: "FL"
    },
    {
      city: "SOPER",
      state: "OK"
    },
    {
      city: "SOPERTON",
      state: "GA"
    },
    {
      city: "SOPHIA",
      state: "NC"
    },
    {
      city: "SOPHIA",
      state: "WV"
    },
    {
      city: "SOQUEL",
      state: "CA"
    },
    {
      city: "SORENTO",
      state: "IL"
    },
    {
      city: "SORRENTO",
      state: "FL"
    },
    {
      city: "SORRENTO",
      state: "LA"
    },
    {
      city: "SORRENTO",
      state: "ME"
    },
    {
      city: "SOSO",
      state: "MS"
    },
    {
      city: "SOUDAN",
      state: "MN"
    },
    {
      city: "SOUDERTON",
      state: "PA"
    },
    {
      city: "SOULSBYVILLE",
      state: "CA"
    },
    {
      city: "SOUND BEACH",
      state: "NY"
    },
    {
      city: "SOUR LAKE",
      state: "TX"
    },
    {
      city: "SOURIS",
      state: "ND"
    },
    {
      city: "SOUTH ACWORTH",
      state: "NH"
    },
    {
      city: "SOUTH AMANA",
      state: "IA"
    },
    {
      city: "SOUTH AMBOY",
      state: "NJ"
    },
    {
      city: "SOUTH BARRE",
      state: "MA"
    },
    {
      city: "SOUTH BARRE",
      state: "VT"
    },
    {
      city: "SOUTH BAY",
      state: "FL"
    },
    {
      city: "SOUTH BEACH",
      state: "OR"
    },
    {
      city: "SOUTH BELOIT",
      state: "IL"
    },
    {
      city: "SOUTH BEND",
      state: "IN"
    },
    {
      city: "SOUTH BEND",
      state: "NE"
    },
    {
      city: "SOUTH BEND",
      state: "TX"
    },
    {
      city: "SOUTH BEND",
      state: "WA"
    },
    {
      city: "SOUTH BERWICK",
      state: "ME"
    },
    {
      city: "SOUTH BETHLEHEM",
      state: "NY"
    },
    {
      city: "SOUTH BLOOMINGVILLE",
      state: "OH"
    },
    {
      city: "SOUTH BOARDMAN",
      state: "MI"
    },
    {
      city: "SOUTH BOSTON",
      state: "VA"
    },
    {
      city: "SOUTH BOUND BROOK",
      state: "NJ"
    },
    {
      city: "SOUTH BRANCH",
      state: "MI"
    },
    {
      city: "SOUTH BRISTOL",
      state: "ME"
    },
    {
      city: "SOUTH BRITAIN",
      state: "CT"
    },
    {
      city: "SOUTH BURLINGTON",
      state: "VT"
    },
    {
      city: "SOUTH BUTLER",
      state: "NY"
    },
    {
      city: "SOUTH BYRON",
      state: "NY"
    },
    {
      city: "SOUTH CAIRO",
      state: "NY"
    },
    {
      city: "SOUTH CANAAN",
      state: "PA"
    },
    {
      city: "SOUTH CARROLLTON",
      state: "KY"
    },
    {
      city: "SOUTH CARVER",
      state: "MA"
    },
    {
      city: "SOUTH CASCO",
      state: "ME"
    },
    {
      city: "SOUTH CHARLESTON",
      state: "OH"
    },
    {
      city: "SOUTH CHARLESTON",
      state: "WV"
    },
    {
      city: "SOUTH CHATHAM",
      state: "MA"
    },
    {
      city: "SOUTH CHINA",
      state: "ME"
    },
    {
      city: "SOUTH CLE ELUM",
      state: "WA"
    },
    {
      city: "SOUTH COLBY",
      state: "WA"
    },
    {
      city: "SOUTH COLTON",
      state: "NY"
    },
    {
      city: "SOUTH DARTMOUTH",
      state: "MA"
    },
    {
      city: "SOUTH DAYTON",
      state: "NY"
    },
    {
      city: "SOUTH DEERFIELD",
      state: "MA"
    },
    {
      city: "SOUTH DENNIS",
      state: "MA"
    },
    {
      city: "SOUTH DENNIS",
      state: "NJ"
    },
    {
      city: "SOUTH DOS PALOS",
      state: "CA"
    },
    {
      city: "SOUTH EASTON",
      state: "MA"
    },
    {
      city: "SOUTH EGREMONT",
      state: "MA"
    },
    {
      city: "SOUTH EL MONTE",
      state: "CA"
    },
    {
      city: "SOUTH ELGIN",
      state: "IL"
    },
    {
      city: "SOUTH ENGLISH",
      state: "IA"
    },
    {
      city: "SOUTH FALLSBURG",
      state: "NY"
    },
    {
      city: "SOUTH FORK",
      state: "CO"
    },
    {
      city: "SOUTH FORK",
      state: "PA"
    },
    {
      city: "SOUTH FREEPORT",
      state: "ME"
    },
    {
      city: "SOUTH FULTON",
      state: "TN"
    },
    {
      city: "SOUTH GARDINER",
      state: "ME"
    },
    {
      city: "SOUTH GATE",
      state: "CA"
    },
    {
      city: "SOUTH GIBSON",
      state: "PA"
    },
    {
      city: "SOUTH GLASTONBURY",
      state: "CT"
    },
    {
      city: "SOUTH GLENS FALLS",
      state: "NY"
    },
    {
      city: "SOUTH GRAFTON",
      state: "MA"
    },
    {
      city: "SOUTH GREENFIELD",
      state: "MO"
    },
    {
      city: "SOUTH HACKENSACK",
      state: "NJ"
    },
    {
      city: "SOUTH HADLEY",
      state: "MA"
    },
    {
      city: "SOUTH HAMILTON",
      state: "MA"
    },
    {
      city: "SOUTH HARWICH",
      state: "MA"
    },
    {
      city: "SOUTH HAVEN",
      state: "KS"
    },
    {
      city: "SOUTH HAVEN",
      state: "MI"
    },
    {
      city: "SOUTH HAVEN",
      state: "MN"
    },
    {
      city: "SOUTH HEART",
      state: "ND"
    },
    {
      city: "SOUTH HEIGHTS",
      state: "PA"
    },
    {
      city: "SOUTH HERO",
      state: "VT"
    },
    {
      city: "SOUTH HILL",
      state: "VA"
    },
    {
      city: "SOUTH HOLLAND",
      state: "IL"
    },
    {
      city: "SOUTH HOUSTON",
      state: "TX"
    },
    {
      city: "SOUTH HUTCHINSON",
      state: "KS"
    },
    {
      city: "SOUTH INTERNATIONAL FALLS",
      state: "MN"
    },
    {
      city: "SOUTH JAMESPORT",
      state: "NY"
    },
    {
      city: "SOUTH JORDAN",
      state: "UT"
    },
    {
      city: "SOUTH KENT",
      state: "CT"
    },
    {
      city: "SOUTH KORTRIGHT",
      state: "NY"
    },
    {
      city: "SOUTH LAKE TAHOE",
      state: "CA"
    },
    {
      city: "SOUTH LANCASTER",
      state: "MA"
    },
    {
      city: "SOUTH LEBANON",
      state: "OH"
    },
    {
      city: "SOUTH LEE",
      state: "MA"
    },
    {
      city: "SOUTH LIMA",
      state: "NY"
    },
    {
      city: "SOUTH LONDONDERRY",
      state: "VT"
    },
    {
      city: "SOUTH LYME",
      state: "CT"
    },
    {
      city: "SOUTH LYON",
      state: "MI"
    },
    {
      city: "SOUTH MILFORD",
      state: "IN"
    },
    {
      city: "SOUTH MILLS",
      state: "NC"
    },
    {
      city: "SOUTH MILWAUKEE",
      state: "WI"
    },
    {
      city: "SOUTH MONTROSE",
      state: "PA"
    },
    {
      city: "SOUTH MOUNTAIN",
      state: "PA"
    },
    {
      city: "SOUTH NEW BERLIN",
      state: "NY"
    },
    {
      city: "SOUTH NEWBURY",
      state: "NH"
    },
    {
      city: "SOUTH NEWFANE",
      state: "VT"
    },
    {
      city: "SOUTH ORANGE",
      state: "NJ"
    },
    {
      city: "SOUTH ORLEANS",
      state: "MA"
    },
    {
      city: "SOUTH OTSELIC",
      state: "NY"
    },
    {
      city: "SOUTH OZONE PARK",
      state: "NY"
    },
    {
      city: "SOUTH PADRE ISLAND",
      state: "TX"
    },
    {
      city: "SOUTH PARIS",
      state: "ME"
    },
    {
      city: "SOUTH PARK",
      state: "PA"
    },
    {
      city: "SOUTH PASADENA",
      state: "CA"
    },
    {
      city: "SOUTH PEKIN",
      state: "IL"
    },
    {
      city: "SOUTH PITTSBURG",
      state: "TN"
    },
    {
      city: "SOUTH PLAINFIELD",
      state: "NJ"
    },
    {
      city: "SOUTH PLYMOUTH",
      state: "NY"
    },
    {
      city: "SOUTH POINT",
      state: "OH"
    },
    {
      city: "SOUTH POMFRET",
      state: "VT"
    },
    {
      city: "SOUTH PORTLAND",
      state: "ME"
    },
    {
      city: "SOUTH PORTSMOUTH",
      state: "KY"
    },
    {
      city: "SOUTH PRAIRIE",
      state: "WA"
    },
    {
      city: "SOUTH RANGE",
      state: "MI"
    },
    {
      city: "SOUTH RANGE",
      state: "WI"
    },
    {
      city: "SOUTH RICHMOND HILL",
      state: "NY"
    },
    {
      city: "SOUTH RIVER",
      state: "NJ"
    },
    {
      city: "SOUTH ROCKWOOD",
      state: "MI"
    },
    {
      city: "SOUTH ROXANA",
      state: "IL"
    },
    {
      city: "SOUTH ROYALTON",
      state: "VT"
    },
    {
      city: "SOUTH RYEGATE",
      state: "VT"
    },
    {
      city: "SOUTH SAINT PAUL",
      state: "MN"
    },
    {
      city: "SOUTH SALEM",
      state: "NY"
    },
    {
      city: "SOUTH SALEM",
      state: "OH"
    },
    {
      city: "SOUTH SAN FRANCISCO",
      state: "CA"
    },
    {
      city: "SOUTH SEAVILLE",
      state: "NJ"
    },
    {
      city: "SOUTH SHORE",
      state: "KY"
    },
    {
      city: "SOUTH SHORE",
      state: "SD"
    },
    {
      city: "SOUTH SIOUX CITY",
      state: "NE"
    },
    {
      city: "SOUTH SOLON",
      state: "OH"
    },
    {
      city: "SOUTH STERLING",
      state: "PA"
    },
    {
      city: "SOUTH STRAFFORD",
      state: "VT"
    },
    {
      city: "SOUTH SUTTON",
      state: "NH"
    },
    {
      city: "SOUTH TAMWORTH",
      state: "NH"
    },
    {
      city: "SOUTH THOMASTON",
      state: "ME"
    },
    {
      city: "SOUTH VIENNA",
      state: "OH"
    },
    {
      city: "SOUTH WALES",
      state: "NY"
    },
    {
      city: "SOUTH WALPOLE",
      state: "MA"
    },
    {
      city: "SOUTH WAYNE",
      state: "WI"
    },
    {
      city: "SOUTH WEBSTER",
      state: "OH"
    },
    {
      city: "SOUTH WELLFLEET",
      state: "MA"
    },
    {
      city: "SOUTH WEST CITY",
      state: "MO"
    },
    {
      city: "SOUTH WEYMOUTH",
      state: "MA"
    },
    {
      city: "SOUTH WHITLEY",
      state: "IN"
    },
    {
      city: "SOUTH WILLIAMSON",
      state: "KY"
    },
    {
      city: "SOUTH WILMINGTON",
      state: "IL"
    },
    {
      city: "SOUTH WINDHAM",
      state: "CT"
    },
    {
      city: "SOUTH WINDHAM",
      state: "ME"
    },
    {
      city: "SOUTH WINDSOR",
      state: "CT"
    },
    {
      city: "SOUTH WOODSTOCK",
      state: "CT"
    },
    {
      city: "SOUTH WOODSTOCK",
      state: "VT"
    },
    {
      city: "SOUTH YARMOUTH",
      state: "MA"
    },
    {
      city: "SOUTHAMPTON",
      state: "MA"
    },
    {
      city: "SOUTHAMPTON",
      state: "NY"
    },
    {
      city: "SOUTHAMPTON",
      state: "PA"
    },
    {
      city: "SOUTHARD",
      state: "OK"
    },
    {
      city: "SOUTHAVEN",
      state: "MS"
    },
    {
      city: "SOUTHBOROUGH",
      state: "MA"
    },
    {
      city: "SOUTHBRIDGE",
      state: "MA"
    },
    {
      city: "SOUTHBURY",
      state: "CT"
    },
    {
      city: "SOUTHEASTERN",
      state: "PA"
    },
    {
      city: "SOUTHERN MD FACILITY",
      state: "MD"
    },
    {
      city: "SOUTHERN PINES",
      state: "NC"
    },
    {
      city: "SOUTHFIELD",
      state: "MA"
    },
    {
      city: "SOUTHFIELD",
      state: "MI"
    },
    {
      city: "SOUTHFIELDS",
      state: "NY"
    },
    {
      city: "SOUTHGATE",
      state: "MI"
    },
    {
      city: "SOUTHINGTON",
      state: "CT"
    },
    {
      city: "SOUTHINGTON",
      state: "OH"
    },
    {
      city: "SOUTHLAKE",
      state: "TX"
    },
    {
      city: "SOUTHMAYD",
      state: "TX"
    },
    {
      city: "SOUTHMONT",
      state: "NC"
    },
    {
      city: "SOUTHOLD",
      state: "NY"
    },
    {
      city: "SOUTHPORT",
      state: "CT"
    },
    {
      city: "SOUTHPORT",
      state: "ME"
    },
    {
      city: "SOUTHPORT",
      state: "NC"
    },
    {
      city: "SOUTHSIDE",
      state: "TN"
    },
    {
      city: "SOUTHSIDE",
      state: "WV"
    },
    {
      city: "SOUTHWEST",
      state: "PA"
    },
    {
      city: "SOUTHWEST HARBOR",
      state: "ME"
    },
    {
      city: "SOUTHWICK",
      state: "MA"
    },
    {
      city: "SOUTHWORTH",
      state: "WA"
    },
    {
      city: "SPADE",
      state: "TX"
    },
    {
      city: "SPALDING",
      state: "MI"
    },
    {
      city: "SPALDING",
      state: "NE"
    },
    {
      city: "SPANAWAY",
      state: "WA"
    },
    {
      city: "SPANGLE",
      state: "WA"
    },
    {
      city: "SPANGLER",
      state: "PA"
    },
    {
      city: "SPANISH FORK",
      state: "UT"
    },
    {
      city: "SPANISH FORT",
      state: "AL"
    },
    {
      city: "SPANISHBURG",
      state: "WV"
    },
    {
      city: "SPARKILL",
      state: "NY"
    },
    {
      city: "SPARKMAN",
      state: "AR"
    },
    {
      city: "SPARKS",
      state: "GA"
    },
    {
      city: "SPARKS",
      state: "NE"
    },
    {
      city: "SPARKS",
      state: "NV"
    },
    {
      city: "SPARKS",
      state: "OK"
    },
    {
      city: "SPARKS GLENCOE",
      state: "MD"
    },
    {
      city: "SPARLAND",
      state: "IL"
    },
    {
      city: "SPARR",
      state: "FL"
    },
    {
      city: "SPARROW BUSH",
      state: "NY"
    },
    {
      city: "SPARROWS POINT",
      state: "MD"
    },
    {
      city: "SPARTA",
      state: "GA"
    },
    {
      city: "SPARTA",
      state: "IL"
    },
    {
      city: "SPARTA",
      state: "KY"
    },
    {
      city: "SPARTA",
      state: "MI"
    },
    {
      city: "SPARTA",
      state: "MO"
    },
    {
      city: "SPARTA",
      state: "NC"
    },
    {
      city: "SPARTA",
      state: "NJ"
    },
    {
      city: "SPARTA",
      state: "OH"
    },
    {
      city: "SPARTA",
      state: "TN"
    },
    {
      city: "SPARTA",
      state: "VA"
    },
    {
      city: "SPARTA",
      state: "WI"
    },
    {
      city: "SPARTANBURG",
      state: "SC"
    },
    {
      city: "SPARTANSBURG",
      state: "PA"
    },
    {
      city: "SPAVINAW",
      state: "OK"
    },
    {
      city: "SPEARFISH",
      state: "SD"
    },
    {
      city: "SPEARMAN",
      state: "TX"
    },
    {
      city: "SPEARSVILLE",
      state: "LA"
    },
    {
      city: "SPEARVILLE",
      state: "KS"
    },
    {
      city: "SPECULATOR",
      state: "NY"
    },
    {
      city: "SPEED",
      state: "NC"
    },
    {
      city: "SPEEDWELL",
      state: "TN"
    },
    {
      city: "SPEEDWELL",
      state: "VA"
    },
    {
      city: "SPEER",
      state: "IL"
    },
    {
      city: "SPENCER",
      state: "IA"
    },
    {
      city: "SPENCER",
      state: "ID"
    },
    {
      city: "SPENCER",
      state: "IN"
    },
    {
      city: "SPENCER",
      state: "MA"
    },
    {
      city: "SPENCER",
      state: "NC"
    },
    {
      city: "SPENCER",
      state: "NE"
    },
    {
      city: "SPENCER",
      state: "NY"
    },
    {
      city: "SPENCER",
      state: "OH"
    },
    {
      city: "SPENCER",
      state: "OK"
    },
    {
      city: "SPENCER",
      state: "SD"
    },
    {
      city: "SPENCER",
      state: "TN"
    },
    {
      city: "SPENCER",
      state: "VA"
    },
    {
      city: "SPENCER",
      state: "WI"
    },
    {
      city: "SPENCER",
      state: "WV"
    },
    {
      city: "SPENCERPORT",
      state: "NY"
    },
    {
      city: "SPENCERTOWN",
      state: "NY"
    },
    {
      city: "SPENCERVILLE",
      state: "IN"
    },
    {
      city: "SPENCERVILLE",
      state: "MD"
    },
    {
      city: "SPENCERVILLE",
      state: "OH"
    },
    {
      city: "SPEONK",
      state: "NY"
    },
    {
      city: "SPERRY",
      state: "IA"
    },
    {
      city: "SPERRY",
      state: "OK"
    },
    {
      city: "SPERRYVILLE",
      state: "VA"
    },
    {
      city: "SPICELAND",
      state: "IN"
    },
    {
      city: "SPICER",
      state: "MN"
    },
    {
      city: "SPICEWOOD",
      state: "TX"
    },
    {
      city: "SPICKARD",
      state: "MO"
    },
    {
      city: "SPILLVILLE",
      state: "IA"
    },
    {
      city: "SPINDALE",
      state: "NC"
    },
    {
      city: "SPINNERSTOWN",
      state: "PA"
    },
    {
      city: "SPIRIT LAKE",
      state: "IA"
    },
    {
      city: "SPIRIT LAKE",
      state: "ID"
    },
    {
      city: "SPIRITWOOD",
      state: "ND"
    },
    {
      city: "SPIRO",
      state: "OK"
    },
    {
      city: "SPIVEY",
      state: "KS"
    },
    {
      city: "SPLENDORA",
      state: "TX"
    },
    {
      city: "SPOFFORD",
      state: "NH"
    },
    {
      city: "SPOKANE",
      state: "MO"
    },
    {
      city: "SPOKANE",
      state: "WA"
    },
    {
      city: "SPOONER",
      state: "WI"
    },
    {
      city: "SPOTSWOOD",
      state: "NJ"
    },
    {
      city: "SPOTSYLVANIA",
      state: "VA"
    },
    {
      city: "SPOTTSVILLE",
      state: "KY"
    },
    {
      city: "SPOUT SPRING",
      state: "VA"
    },
    {
      city: "SPRAGGS",
      state: "PA"
    },
    {
      city: "SPRAGUE",
      state: "NE"
    },
    {
      city: "SPRAGUE",
      state: "WA"
    },
    {
      city: "SPRAGUE RIVER",
      state: "OR"
    },
    {
      city: "SPRAGUEVILLE",
      state: "IA"
    },
    {
      city: "SPRAKERS",
      state: "NY"
    },
    {
      city: "SPRANKLE MILLS",
      state: "PA"
    },
    {
      city: "SPRAY",
      state: "OR"
    },
    {
      city: "SPRECKELS",
      state: "CA"
    },
    {
      city: "SPRING",
      state: "TX"
    },
    {
      city: "SPRING ARBOR",
      state: "MI"
    },
    {
      city: "SPRING BRANCH",
      state: "TX"
    },
    {
      city: "SPRING BROOK",
      state: "NY"
    },
    {
      city: "SPRING CHURCH",
      state: "PA"
    },
    {
      city: "SPRING CITY",
      state: "PA"
    },
    {
      city: "SPRING CITY",
      state: "TN"
    },
    {
      city: "SPRING CITY",
      state: "UT"
    },
    {
      city: "SPRING CREEK",
      state: "NV"
    },
    {
      city: "SPRING CREEK",
      state: "PA"
    },
    {
      city: "SPRING CREEK",
      state: "TN"
    },
    {
      city: "SPRING DALE",
      state: "WV"
    },
    {
      city: "SPRING GLEN",
      state: "NY"
    },
    {
      city: "SPRING GLEN",
      state: "PA"
    },
    {
      city: "SPRING GREEN",
      state: "WI"
    },
    {
      city: "SPRING GROVE",
      state: "IL"
    },
    {
      city: "SPRING GROVE",
      state: "MN"
    },
    {
      city: "SPRING GROVE",
      state: "PA"
    },
    {
      city: "SPRING GROVE",
      state: "VA"
    },
    {
      city: "SPRING HILL",
      state: "FL"
    },
    {
      city: "SPRING HILL",
      state: "KS"
    },
    {
      city: "SPRING HILL",
      state: "TN"
    },
    {
      city: "SPRING HOPE",
      state: "NC"
    },
    {
      city: "SPRING HOUSE",
      state: "PA"
    },
    {
      city: "SPRING LAKE",
      state: "MI"
    },
    {
      city: "SPRING LAKE",
      state: "MN"
    },
    {
      city: "SPRING LAKE",
      state: "NC"
    },
    {
      city: "SPRING LAKE",
      state: "NJ"
    },
    {
      city: "SPRING MILLS",
      state: "PA"
    },
    {
      city: "SPRING MOUNT",
      state: "PA"
    },
    {
      city: "SPRING PARK",
      state: "MN"
    },
    {
      city: "SPRING RUN",
      state: "PA"
    },
    {
      city: "SPRING VALLEY",
      state: "CA"
    },
    {
      city: "SPRING VALLEY",
      state: "IL"
    },
    {
      city: "SPRING VALLEY",
      state: "MN"
    },
    {
      city: "SPRING VALLEY",
      state: "NY"
    },
    {
      city: "SPRING VALLEY",
      state: "OH"
    },
    {
      city: "SPRING VALLEY",
      state: "WI"
    },
    {
      city: "SPRINGBORO",
      state: "OH"
    },
    {
      city: "SPRINGBORO",
      state: "PA"
    },
    {
      city: "SPRINGBROOK",
      state: "IA"
    },
    {
      city: "SPRINGBROOK",
      state: "WI"
    },
    {
      city: "SPRINGDALE",
      state: "AR"
    },
    {
      city: "SPRINGDALE",
      state: "PA"
    },
    {
      city: "SPRINGDALE",
      state: "UT"
    },
    {
      city: "SPRINGDALE",
      state: "WA"
    },
    {
      city: "SPRINGER",
      state: "NM"
    },
    {
      city: "SPRINGER",
      state: "OK"
    },
    {
      city: "SPRINGERTON",
      state: "IL"
    },
    {
      city: "SPRINGERVILLE",
      state: "AZ"
    },
    {
      city: "SPRINGFIELD",
      state: "AR"
    },
    {
      city: "SPRINGFIELD",
      state: "CO"
    },
    {
      city: "SPRINGFIELD",
      state: "GA"
    },
    {
      city: "SPRINGFIELD",
      state: "ID"
    },
    {
      city: "SPRINGFIELD",
      state: "IL"
    },
    {
      city: "SPRINGFIELD",
      state: "KY"
    },
    {
      city: "SPRINGFIELD",
      state: "LA"
    },
    {
      city: "SPRINGFIELD",
      state: "MA"
    },
    {
      city: "SPRINGFIELD",
      state: "ME"
    },
    {
      city: "SPRINGFIELD",
      state: "MN"
    },
    {
      city: "SPRINGFIELD",
      state: "MO"
    },
    {
      city: "SPRINGFIELD",
      state: "NE"
    },
    {
      city: "SPRINGFIELD",
      state: "NH"
    },
    {
      city: "SPRINGFIELD",
      state: "NJ"
    },
    {
      city: "SPRINGFIELD",
      state: "OH"
    },
    {
      city: "SPRINGFIELD",
      state: "OR"
    },
    {
      city: "SPRINGFIELD",
      state: "PA"
    },
    {
      city: "SPRINGFIELD",
      state: "SC"
    },
    {
      city: "SPRINGFIELD",
      state: "SD"
    },
    {
      city: "SPRINGFIELD",
      state: "TN"
    },
    {
      city: "SPRINGFIELD",
      state: "VA"
    },
    {
      city: "SPRINGFIELD",
      state: "VT"
    },
    {
      city: "SPRINGFIELD",
      state: "WI"
    },
    {
      city: "SPRINGFIELD",
      state: "WV"
    },
    {
      city: "SPRINGFIELD CENTER",
      state: "NY"
    },
    {
      city: "SPRINGFIELD GARDENS",
      state: "NY"
    },
    {
      city: "SPRINGHILL",
      state: "LA"
    },
    {
      city: "SPRINGLAKE",
      state: "TX"
    },
    {
      city: "SPRINGPORT",
      state: "IN"
    },
    {
      city: "SPRINGPORT",
      state: "MI"
    },
    {
      city: "SPRINGS",
      state: "PA"
    },
    {
      city: "SPRINGTOWN",
      state: "PA"
    },
    {
      city: "SPRINGTOWN",
      state: "TX"
    },
    {
      city: "SPRINGVALE",
      state: "ME"
    },
    {
      city: "SPRINGVIEW",
      state: "NE"
    },
    {
      city: "SPRINGVILLE",
      state: "AL"
    },
    {
      city: "SPRINGVILLE",
      state: "CA"
    },
    {
      city: "SPRINGVILLE",
      state: "IA"
    },
    {
      city: "SPRINGVILLE",
      state: "IN"
    },
    {
      city: "SPRINGVILLE",
      state: "NY"
    },
    {
      city: "SPRINGVILLE",
      state: "PA"
    },
    {
      city: "SPRINGVILLE",
      state: "TN"
    },
    {
      city: "SPRINGVILLE",
      state: "UT"
    },
    {
      city: "SPRINGWATER",
      state: "NY"
    },
    {
      city: "SPRUCE",
      state: "MI"
    },
    {
      city: "SPRUCE CREEK",
      state: "PA"
    },
    {
      city: "SPRUCE HEAD",
      state: "ME"
    },
    {
      city: "SPRUCE PINE",
      state: "AL"
    },
    {
      city: "SPRUCE PINE",
      state: "NC"
    },
    {
      city: "SPUR",
      state: "TX"
    },
    {
      city: "SPURGEON",
      state: "IN"
    },
    {
      city: "SPURGER",
      state: "TX"
    },
    {
      city: "SPURLOCKVILLE",
      state: "WV"
    },
    {
      city: "SQUAW LAKE",
      state: "MN"
    },
    {
      city: "SQUAW VALLEY",
      state: "CA"
    },
    {
      city: "SQUIRE",
      state: "WV"
    },
    {
      city: "SQUIRES",
      state: "MO"
    },
    {
      city: "SQUIRREL ISLAND",
      state: "ME"
    },
    {
      city: "ST COLUMBANS",
      state: "NE"
    },
    {
      city: "STAATSBURG",
      state: "NY"
    },
    {
      city: "STACY",
      state: "MN"
    },
    {
      city: "STACY",
      state: "NC"
    },
    {
      city: "STACYVILLE",
      state: "IA"
    },
    {
      city: "STACYVILLE",
      state: "ME"
    },
    {
      city: "STAFFORD",
      state: "CT"
    },
    {
      city: "STAFFORD",
      state: "KS"
    },
    {
      city: "STAFFORD",
      state: "NY"
    },
    {
      city: "STAFFORD",
      state: "OH"
    },
    {
      city: "STAFFORD",
      state: "TX"
    },
    {
      city: "STAFFORD",
      state: "VA"
    },
    {
      city: "STAFFORD SPRINGS",
      state: "CT"
    },
    {
      city: "STAFFORDSVILLE",
      state: "KY"
    },
    {
      city: "STAFFORDVILLE",
      state: "CT"
    },
    {
      city: "STAHLSTOWN",
      state: "PA"
    },
    {
      city: "STALEY",
      state: "NC"
    },
    {
      city: "STAMBAUGH",
      state: "KY"
    },
    {
      city: "STAMBAUGH",
      state: "MI"
    },
    {
      city: "STAMFORD",
      state: "CT"
    },
    {
      city: "STAMFORD",
      state: "NE"
    },
    {
      city: "STAMFORD",
      state: "NY"
    },
    {
      city: "STAMFORD",
      state: "TX"
    },
    {
      city: "STAMFORD",
      state: "VT"
    },
    {
      city: "STAMPING GROUND",
      state: "KY"
    },
    {
      city: "STAMPS",
      state: "AR"
    },
    {
      city: "STANAFORD",
      state: "WV"
    },
    {
      city: "STANARDSVILLE",
      state: "VA"
    },
    {
      city: "STANBERRY",
      state: "MO"
    },
    {
      city: "STANCHFIELD",
      state: "MN"
    },
    {
      city: "STANDARD",
      state: "CA"
    },
    {
      city: "STANDARD",
      state: "IL"
    },
    {
      city: "STANDISH",
      state: "CA"
    },
    {
      city: "STANDISH",
      state: "ME"
    },
    {
      city: "STANDISH",
      state: "MI"
    },
    {
      city: "STANFIELD",
      state: "AZ"
    },
    {
      city: "STANFIELD",
      state: "NC"
    },
    {
      city: "STANFIELD",
      state: "OR"
    },
    {
      city: "STANFORD",
      state: "CA"
    },
    {
      city: "STANFORD",
      state: "IL"
    },
    {
      city: "STANFORD",
      state: "IN"
    },
    {
      city: "STANFORD",
      state: "KY"
    },
    {
      city: "STANFORD",
      state: "MT"
    },
    {
      city: "STANFORDVILLE",
      state: "NY"
    },
    {
      city: "STANHOPE",
      state: "IA"
    },
    {
      city: "STANHOPE",
      state: "NJ"
    },
    {
      city: "STANLEY",
      state: "IA"
    },
    {
      city: "STANLEY",
      state: "ID"
    },
    {
      city: "STANLEY",
      state: "NC"
    },
    {
      city: "STANLEY",
      state: "ND"
    },
    {
      city: "STANLEY",
      state: "NM"
    },
    {
      city: "STANLEY",
      state: "NY"
    },
    {
      city: "STANLEY",
      state: "VA"
    },
    {
      city: "STANLEY",
      state: "WI"
    },
    {
      city: "STANLEYTOWN",
      state: "VA"
    },
    {
      city: "STANTON",
      state: "AL"
    },
    {
      city: "STANTON",
      state: "CA"
    },
    {
      city: "STANTON",
      state: "IA"
    },
    {
      city: "STANTON",
      state: "KY"
    },
    {
      city: "STANTON",
      state: "MI"
    },
    {
      city: "STANTON",
      state: "MO"
    },
    {
      city: "STANTON",
      state: "ND"
    },
    {
      city: "STANTON",
      state: "NE"
    },
    {
      city: "STANTON",
      state: "NJ"
    },
    {
      city: "STANTON",
      state: "TN"
    },
    {
      city: "STANTON",
      state: "TX"
    },
    {
      city: "STANTONSBURG",
      state: "NC"
    },
    {
      city: "STANTONVILLE",
      state: "TN"
    },
    {
      city: "STANVILLE",
      state: "KY"
    },
    {
      city: "STANWOOD",
      state: "IA"
    },
    {
      city: "STANWOOD",
      state: "MI"
    },
    {
      city: "STANWOOD",
      state: "WA"
    },
    {
      city: "STAPLEHURST",
      state: "NE"
    },
    {
      city: "STAPLES",
      state: "MN"
    },
    {
      city: "STAPLES",
      state: "TX"
    },
    {
      city: "STAPLETON",
      state: "AL"
    },
    {
      city: "STAPLETON",
      state: "GA"
    },
    {
      city: "STAPLETON",
      state: "NE"
    },
    {
      city: "STAR",
      state: "ID"
    },
    {
      city: "STAR",
      state: "MS"
    },
    {
      city: "STAR",
      state: "NC"
    },
    {
      city: "STAR",
      state: "TX"
    },
    {
      city: "STAR CITY",
      state: "AR"
    },
    {
      city: "STAR CITY",
      state: "IN"
    },
    {
      city: "STAR JUNCTION",
      state: "PA"
    },
    {
      city: "STAR LAKE",
      state: "NY"
    },
    {
      city: "STAR LAKE",
      state: "WI"
    },
    {
      city: "STAR PRAIRIE",
      state: "WI"
    },
    {
      city: "STAR TANNERY",
      state: "VA"
    },
    {
      city: "STARBUCK",
      state: "MN"
    },
    {
      city: "STARBUCK",
      state: "WA"
    },
    {
      city: "STARFORD",
      state: "PA"
    },
    {
      city: "STARK",
      state: "KS"
    },
    {
      city: "STARK CITY",
      state: "MO"
    },
    {
      city: "STARKE",
      state: "FL"
    },
    {
      city: "STARKS",
      state: "LA"
    },
    {
      city: "STARKSBORO",
      state: "VT"
    },
    {
      city: "STARKVILLE",
      state: "MS"
    },
    {
      city: "STARKWEATHER",
      state: "ND"
    },
    {
      city: "STARLIGHT",
      state: "PA"
    },
    {
      city: "STARR",
      state: "SC"
    },
    {
      city: "STARRUCCA",
      state: "PA"
    },
    {
      city: "START",
      state: "LA"
    },
    {
      city: "STARTEX",
      state: "SC"
    },
    {
      city: "STARTUP",
      state: "WA"
    },
    {
      city: "STATE CENTER",
      state: "IA"
    },
    {
      city: "STATE COLLEGE",
      state: "PA"
    },
    {
      city: "STATE FARM",
      state: "VA"
    },
    {
      city: "STATE LINE",
      state: "IN"
    },
    {
      city: "STATE LINE",
      state: "MS"
    },
    {
      city: "STATE LINE",
      state: "PA"
    },
    {
      city: "STATE PARK",
      state: "SC"
    },
    {
      city: "STATE ROAD",
      state: "NC"
    },
    {
      city: "STATE UNIVERSITY",
      state: "AR"
    },
    {
      city: "STATELINE",
      state: "NV"
    },
    {
      city: "STATEN ISLAND",
      state: "NY"
    },
    {
      city: "STATENVILLE",
      state: "GA"
    },
    {
      city: "STATESBORO",
      state: "GA"
    },
    {
      city: "STATESVILLE",
      state: "NC"
    },
    {
      city: "STATHAM",
      state: "GA"
    },
    {
      city: "STAUNTON",
      state: "IL"
    },
    {
      city: "STAUNTON",
      state: "IN"
    },
    {
      city: "STAUNTON",
      state: "VA"
    },
    {
      city: "STAYTON",
      state: "OR"
    },
    {
      city: "STEAMBOAT ROCK",
      state: "IA"
    },
    {
      city: "STEAMBOAT SPRINGS",
      state: "CO"
    },
    {
      city: "STEAMBURG",
      state: "NY"
    },
    {
      city: "STEARNS",
      state: "KY"
    },
    {
      city: "STEBBINS",
      state: "AK"
    },
    {
      city: "STEDMAN",
      state: "NC"
    },
    {
      city: "STEEDMAN",
      state: "MO"
    },
    {
      city: "STEELE",
      state: "AL"
    },
    {
      city: "STEELE",
      state: "KY"
    },
    {
      city: "STEELE",
      state: "MO"
    },
    {
      city: "STEELE",
      state: "ND"
    },
    {
      city: "STEELE CITY",
      state: "NE"
    },
    {
      city: "STEELES TAVERN",
      state: "VA"
    },
    {
      city: "STEELEVILLE",
      state: "IL"
    },
    {
      city: "STEELVILLE",
      state: "MO"
    },
    {
      city: "STEEN",
      state: "MN"
    },
    {
      city: "STEENS",
      state: "MS"
    },
    {
      city: "STEEP FALLS",
      state: "ME"
    },
    {
      city: "STEGER",
      state: "IL"
    },
    {
      city: "STEHEKIN",
      state: "WA"
    },
    {
      city: "STEILACOOM",
      state: "WA"
    },
    {
      city: "STEINAUER",
      state: "NE"
    },
    {
      city: "STEINHATCHEE",
      state: "FL"
    },
    {
      city: "STELLA",
      state: "MO"
    },
    {
      city: "STELLA",
      state: "NC"
    },
    {
      city: "STELLA",
      state: "NE"
    },
    {
      city: "STELLA NIAGARA",
      state: "NY"
    },
    {
      city: "STEM",
      state: "NC"
    },
    {
      city: "STENDAL",
      state: "IN"
    },
    {
      city: "STENNIS SPACE CENTER",
      state: "MS"
    },
    {
      city: "STEPHAN",
      state: "SD"
    },
    {
      city: "STEPHEN",
      state: "MN"
    },
    {
      city: "STEPHENS",
      state: "AR"
    },
    {
      city: "STEPHENS",
      state: "GA"
    },
    {
      city: "STEPHENS CITY",
      state: "VA"
    },
    {
      city: "STEPHENSON",
      state: "MI"
    },
    {
      city: "STEPHENSON",
      state: "VA"
    },
    {
      city: "STEPHENSON",
      state: "WV"
    },
    {
      city: "STEPHENSPORT",
      state: "KY"
    },
    {
      city: "STEPHENTOWN",
      state: "NY"
    },
    {
      city: "STEPHENVILLE",
      state: "TX"
    },
    {
      city: "STEPTOE",
      state: "WA"
    },
    {
      city: "STERLING",
      state: "AK"
    },
    {
      city: "STERLING",
      state: "CO"
    },
    {
      city: "STERLING",
      state: "CT"
    },
    {
      city: "STERLING",
      state: "IL"
    },
    {
      city: "STERLING",
      state: "KS"
    },
    {
      city: "STERLING",
      state: "MA"
    },
    {
      city: "STERLING",
      state: "MI"
    },
    {
      city: "STERLING",
      state: "ND"
    },
    {
      city: "STERLING",
      state: "NE"
    },
    {
      city: "STERLING",
      state: "NY"
    },
    {
      city: "STERLING",
      state: "OH"
    },
    {
      city: "STERLING",
      state: "OK"
    },
    {
      city: "STERLING",
      state: "PA"
    },
    {
      city: "STERLING",
      state: "UT"
    },
    {
      city: "STERLING",
      state: "VA"
    },
    {
      city: "STERLING CITY",
      state: "TX"
    },
    {
      city: "STERLING FOREST",
      state: "NY"
    },
    {
      city: "STERLING HEIGHTS",
      state: "MI"
    },
    {
      city: "STERLINGTON",
      state: "LA"
    },
    {
      city: "STERRETT",
      state: "AL"
    },
    {
      city: "STETSON",
      state: "ME"
    },
    {
      city: "STETSONVILLE",
      state: "WI"
    },
    {
      city: "STEUBEN",
      state: "ME"
    },
    {
      city: "STEUBENVILLE",
      state: "OH"
    },
    {
      city: "STEVENS",
      state: "PA"
    },
    {
      city: "STEVENS POINT",
      state: "WI"
    },
    {
      city: "STEVENSBURG",
      state: "VA"
    },
    {
      city: "STEVENSON",
      state: "AL"
    },
    {
      city: "STEVENSON",
      state: "CT"
    },
    {
      city: "STEVENSON",
      state: "MD"
    },
    {
      city: "STEVENSON",
      state: "WA"
    },
    {
      city: "STEVENSON RANCH",
      state: "CA"
    },
    {
      city: "STEVENSVILLE",
      state: "MD"
    },
    {
      city: "STEVENSVILLE",
      state: "MI"
    },
    {
      city: "STEVENSVILLE",
      state: "MT"
    },
    {
      city: "STEVENSVILLE",
      state: "PA"
    },
    {
      city: "STEVENSVILLE",
      state: "VA"
    },
    {
      city: "STEVINSON",
      state: "CA"
    },
    {
      city: "STEWARD",
      state: "IL"
    },
    {
      city: "STEWARDSON",
      state: "IL"
    },
    {
      city: "STEWART",
      state: "MN"
    },
    {
      city: "STEWART",
      state: "MS"
    },
    {
      city: "STEWART",
      state: "OH"
    },
    {
      city: "STEWART",
      state: "TN"
    },
    {
      city: "STEWARTS POINT",
      state: "CA"
    },
    {
      city: "STEWARTSTOWN",
      state: "PA"
    },
    {
      city: "STEWARTSVILLE",
      state: "MO"
    },
    {
      city: "STEWARTSVILLE",
      state: "NJ"
    },
    {
      city: "STEWARTVILLE",
      state: "MN"
    },
    {
      city: "STICKNEY",
      state: "SD"
    },
    {
      city: "STIGLER",
      state: "OK"
    },
    {
      city: "STILESVILLE",
      state: "IN"
    },
    {
      city: "STILL POND",
      state: "MD"
    },
    {
      city: "STILL RIVER",
      state: "MA"
    },
    {
      city: "STILLMAN VALLEY",
      state: "IL"
    },
    {
      city: "STILLMORE",
      state: "GA"
    },
    {
      city: "STILLWATER",
      state: "ME"
    },
    {
      city: "STILLWATER",
      state: "MN"
    },
    {
      city: "STILLWATER",
      state: "NJ"
    },
    {
      city: "STILLWATER",
      state: "NY"
    },
    {
      city: "STILLWATER",
      state: "OK"
    },
    {
      city: "STILLWATER",
      state: "PA"
    },
    {
      city: "STILWELL",
      state: "KS"
    },
    {
      city: "STILWELL",
      state: "OK"
    },
    {
      city: "STINESVILLE",
      state: "IN"
    },
    {
      city: "STINNETT",
      state: "KY"
    },
    {
      city: "STINNETT",
      state: "TX"
    },
    {
      city: "STINSON BEACH",
      state: "CA"
    },
    {
      city: "STIRLING",
      state: "NJ"
    },
    {
      city: "STIRLING CITY",
      state: "CA"
    },
    {
      city: "STIRUM",
      state: "ND"
    },
    {
      city: "STITES",
      state: "ID"
    },
    {
      city: "STITTVILLE",
      state: "NY"
    },
    {
      city: "STITZER",
      state: "WI"
    },
    {
      city: "STOCKBRIDGE",
      state: "GA"
    },
    {
      city: "STOCKBRIDGE",
      state: "MA"
    },
    {
      city: "STOCKBRIDGE",
      state: "MI"
    },
    {
      city: "STOCKBRIDGE",
      state: "VT"
    },
    {
      city: "STOCKBRIDGE",
      state: "WI"
    },
    {
      city: "STOCKDALE",
      state: "OH"
    },
    {
      city: "STOCKDALE",
      state: "PA"
    },
    {
      city: "STOCKDALE",
      state: "TX"
    },
    {
      city: "STOCKERTOWN",
      state: "PA"
    },
    {
      city: "STOCKETT",
      state: "MT"
    },
    {
      city: "STOCKHOLM",
      state: "ME"
    },
    {
      city: "STOCKHOLM",
      state: "NJ"
    },
    {
      city: "STOCKHOLM",
      state: "SD"
    },
    {
      city: "STOCKHOLM",
      state: "WI"
    },
    {
      city: "STOCKLAND",
      state: "IL"
    },
    {
      city: "STOCKPORT",
      state: "IA"
    },
    {
      city: "STOCKPORT",
      state: "OH"
    },
    {
      city: "STOCKTON",
      state: "AL"
    },
    {
      city: "STOCKTON",
      state: "CA"
    },
    {
      city: "STOCKTON",
      state: "GA"
    },
    {
      city: "STOCKTON",
      state: "IA"
    },
    {
      city: "STOCKTON",
      state: "IL"
    },
    {
      city: "STOCKTON",
      state: "KS"
    },
    {
      city: "STOCKTON",
      state: "MD"
    },
    {
      city: "STOCKTON",
      state: "MN"
    },
    {
      city: "STOCKTON",
      state: "MO"
    },
    {
      city: "STOCKTON",
      state: "NJ"
    },
    {
      city: "STOCKTON",
      state: "NY"
    },
    {
      city: "STOCKTON",
      state: "UT"
    },
    {
      city: "STOCKTON SPRINGS",
      state: "ME"
    },
    {
      city: "STOCKWELL",
      state: "IN"
    },
    {
      city: "STODDARD",
      state: "NH"
    },
    {
      city: "STODDARD",
      state: "WI"
    },
    {
      city: "STOKES",
      state: "NC"
    },
    {
      city: "STOKESDALE",
      state: "NC"
    },
    {
      city: "STOLLINGS",
      state: "WV"
    },
    {
      city: "STONE",
      state: "KY"
    },
    {
      city: "STONE CREEK",
      state: "OH"
    },
    {
      city: "STONE HARBOR",
      state: "NJ"
    },
    {
      city: "STONE LAKE",
      state: "WI"
    },
    {
      city: "STONE MOUNTAIN",
      state: "GA"
    },
    {
      city: "STONE PARK",
      state: "IL"
    },
    {
      city: "STONE RIDGE",
      state: "NY"
    },
    {
      city: "STONEBORO",
      state: "PA"
    },
    {
      city: "STONEFORT",
      state: "IL"
    },
    {
      city: "STONEHAM",
      state: "CO"
    },
    {
      city: "STONEHAM",
      state: "MA"
    },
    {
      city: "STONEHAM",
      state: "ME"
    },
    {
      city: "STONEVILLE",
      state: "MS"
    },
    {
      city: "STONEVILLE",
      state: "NC"
    },
    {
      city: "STONEWALL",
      state: "LA"
    },
    {
      city: "STONEWALL",
      state: "MS"
    },
    {
      city: "STONEWALL",
      state: "NC"
    },
    {
      city: "STONEWALL",
      state: "OK"
    },
    {
      city: "STONEWALL",
      state: "TX"
    },
    {
      city: "STONEY FORK",
      state: "KY"
    },
    {
      city: "STONINGTON",
      state: "CT"
    },
    {
      city: "STONINGTON",
      state: "IL"
    },
    {
      city: "STONINGTON",
      state: "ME"
    },
    {
      city: "STONY BROOK",
      state: "NY"
    },
    {
      city: "STONY CREEK",
      state: "NY"
    },
    {
      city: "STONY CREEK",
      state: "VA"
    },
    {
      city: "STONY POINT",
      state: "NC"
    },
    {
      city: "STONY POINT",
      state: "NY"
    },
    {
      city: "STONY RIDGE",
      state: "OH"
    },
    {
      city: "STONYFORD",
      state: "CA"
    },
    {
      city: "STOPOVER",
      state: "KY"
    },
    {
      city: "STORDEN",
      state: "MN"
    },
    {
      city: "STORM LAKE",
      state: "IA"
    },
    {
      city: "STORMVILLE",
      state: "NY"
    },
    {
      city: "STORRS MANSFIELD",
      state: "CT"
    },
    {
      city: "STORY",
      state: "AR"
    },
    {
      city: "STORY",
      state: "WY"
    },
    {
      city: "STORY CITY",
      state: "IA"
    },
    {
      city: "STOTTS CITY",
      state: "MO"
    },
    {
      city: "STOTTVILLE",
      state: "NY"
    },
    {
      city: "STOUGHTON",
      state: "MA"
    },
    {
      city: "STOUGHTON",
      state: "WI"
    },
    {
      city: "STOUT",
      state: "IA"
    },
    {
      city: "STOUT",
      state: "OH"
    },
    {
      city: "STOUTLAND",
      state: "MO"
    },
    {
      city: "STOUTSVILLE",
      state: "MO"
    },
    {
      city: "STOUTSVILLE",
      state: "OH"
    },
    {
      city: "STOVALL",
      state: "NC"
    },
    {
      city: "STOVER",
      state: "MO"
    },
    {
      city: "STOW",
      state: "MA"
    },
    {
      city: "STOW",
      state: "NY"
    },
    {
      city: "STOW",
      state: "OH"
    },
    {
      city: "STOWE",
      state: "VT"
    },
    {
      city: "STOWELL",
      state: "TX"
    },
    {
      city: "STOY",
      state: "IL"
    },
    {
      city: "STOYSTOWN",
      state: "PA"
    },
    {
      city: "STRABANE",
      state: "PA"
    },
    {
      city: "STRAFFORD",
      state: "MO"
    },
    {
      city: "STRAFFORD",
      state: "NH"
    },
    {
      city: "STRAFFORD",
      state: "VT"
    },
    {
      city: "STRANDBURG",
      state: "SD"
    },
    {
      city: "STRANDQUIST",
      state: "MN"
    },
    {
      city: "STRANG",
      state: "NE"
    },
    {
      city: "STRANG",
      state: "OK"
    },
    {
      city: "STRASBURG",
      state: "CO"
    },
    {
      city: "STRASBURG",
      state: "IL"
    },
    {
      city: "STRASBURG",
      state: "MO"
    },
    {
      city: "STRASBURG",
      state: "ND"
    },
    {
      city: "STRASBURG",
      state: "OH"
    },
    {
      city: "STRASBURG",
      state: "PA"
    },
    {
      city: "STRASBURG",
      state: "VA"
    },
    {
      city: "STRATFORD",
      state: "CA"
    },
    {
      city: "STRATFORD",
      state: "CT"
    },
    {
      city: "STRATFORD",
      state: "IA"
    },
    {
      city: "STRATFORD",
      state: "NJ"
    },
    {
      city: "STRATFORD",
      state: "NY"
    },
    {
      city: "STRATFORD",
      state: "OK"
    },
    {
      city: "STRATFORD",
      state: "SD"
    },
    {
      city: "STRATFORD",
      state: "TX"
    },
    {
      city: "STRATFORD",
      state: "VA"
    },
    {
      city: "STRATFORD",
      state: "WA"
    },
    {
      city: "STRATFORD",
      state: "WI"
    },
    {
      city: "STRATHAM",
      state: "NH"
    },
    {
      city: "STRATHCONA",
      state: "MN"
    },
    {
      city: "STRATHMERE",
      state: "NJ"
    },
    {
      city: "STRATHMORE",
      state: "CA"
    },
    {
      city: "STRATTANVILLE",
      state: "PA"
    },
    {
      city: "STRATTON",
      state: "CO"
    },
    {
      city: "STRATTON",
      state: "ME"
    },
    {
      city: "STRATTON",
      state: "NE"
    },
    {
      city: "STRATTON",
      state: "OH"
    },
    {
      city: "STRAUGHN",
      state: "IN"
    },
    {
      city: "STRAUSSTOWN",
      state: "PA"
    },
    {
      city: "STRAWBERRY",
      state: "AR"
    },
    {
      city: "STRAWBERRY",
      state: "CA"
    },
    {
      city: "STRAWBERRY PLAINS",
      state: "TN"
    },
    {
      city: "STRAWBERRY POINT",
      state: "IA"
    },
    {
      city: "STRAWBERRY VALLEY",
      state: "CA"
    },
    {
      city: "STRAWN",
      state: "IL"
    },
    {
      city: "STRAWN",
      state: "TX"
    },
    {
      city: "STREAMWOOD",
      state: "IL"
    },
    {
      city: "STREATOR",
      state: "IL"
    },
    {
      city: "STREET",
      state: "MD"
    },
    {
      city: "STREETER",
      state: "ND"
    },
    {
      city: "STREETMAN",
      state: "TX"
    },
    {
      city: "STREETSBORO",
      state: "OH"
    },
    {
      city: "STRINGER",
      state: "MS"
    },
    {
      city: "STRINGTOWN",
      state: "OK"
    },
    {
      city: "STROH",
      state: "IN"
    },
    {
      city: "STROMSBURG",
      state: "NE"
    },
    {
      city: "STRONG",
      state: "AR"
    },
    {
      city: "STRONG",
      state: "ME"
    },
    {
      city: "STRONG CITY",
      state: "KS"
    },
    {
      city: "STRONGHURST",
      state: "IL"
    },
    {
      city: "STRONGSTOWN",
      state: "PA"
    },
    {
      city: "STRONGSVILLE",
      state: "OH"
    },
    {
      city: "STROUD",
      state: "OK"
    },
    {
      city: "STROUDSBURG",
      state: "PA"
    },
    {
      city: "STRUM",
      state: "WI"
    },
    {
      city: "STRUNK",
      state: "KY"
    },
    {
      city: "STRUTHERS",
      state: "OH"
    },
    {
      city: "STRYKER",
      state: "MT"
    },
    {
      city: "STRYKER",
      state: "OH"
    },
    {
      city: "STRYKERSVILLE",
      state: "NY"
    },
    {
      city: "STUART",
      state: "FL"
    },
    {
      city: "STUART",
      state: "IA"
    },
    {
      city: "STUART",
      state: "NE"
    },
    {
      city: "STUART",
      state: "OK"
    },
    {
      city: "STUART",
      state: "VA"
    },
    {
      city: "STUARTS DRAFT",
      state: "VA"
    },
    {
      city: "STUDIO CITY",
      state: "CA"
    },
    {
      city: "STUDLEY",
      state: "VA"
    },
    {
      city: "STUMP CREEK",
      state: "PA"
    },
    {
      city: "STUMPY POINT",
      state: "NC"
    },
    {
      city: "STURBRIDGE",
      state: "MA"
    },
    {
      city: "STURDIVANT",
      state: "MO"
    },
    {
      city: "STURGEON",
      state: "MO"
    },
    {
      city: "STURGEON",
      state: "PA"
    },
    {
      city: "STURGEON BAY",
      state: "WI"
    },
    {
      city: "STURGEON LAKE",
      state: "MN"
    },
    {
      city: "STURGIS",
      state: "KY"
    },
    {
      city: "STURGIS",
      state: "MI"
    },
    {
      city: "STURGIS",
      state: "MS"
    },
    {
      city: "STURGIS",
      state: "SD"
    },
    {
      city: "STURKIE",
      state: "AR"
    },
    {
      city: "STURTEVANT",
      state: "WI"
    },
    {
      city: "STUTTGART",
      state: "AR"
    },
    {
      city: "STUYVESANT",
      state: "NY"
    },
    {
      city: "STUYVESANT FALLS",
      state: "NY"
    },
    {
      city: "SUAMICO",
      state: "WI"
    },
    {
      city: "SUBIACO",
      state: "AR"
    },
    {
      city: "SUBLETTE",
      state: "IL"
    },
    {
      city: "SUBLETTE",
      state: "KS"
    },
    {
      city: "SUBLIME",
      state: "TX"
    },
    {
      city: "SUBLIMITY",
      state: "OR"
    },
    {
      city: "SUBURB MARYLAND FAC",
      state: "MD"
    },
    {
      city: "SUCCASUNNA",
      state: "NJ"
    },
    {
      city: "SUCCESS",
      state: "AR"
    },
    {
      city: "SUCCESS",
      state: "MO"
    },
    {
      city: "SUCHES",
      state: "GA"
    },
    {
      city: "SUDAN",
      state: "TX"
    },
    {
      city: "SUDBURY",
      state: "MA"
    },
    {
      city: "SUDLERSVILLE",
      state: "MD"
    },
    {
      city: "SUFFERN",
      state: "NY"
    },
    {
      city: "SUFFIELD",
      state: "CT"
    },
    {
      city: "SUFFOLK",
      state: "VA"
    },
    {
      city: "SUGAR CITY",
      state: "CO"
    },
    {
      city: "SUGAR CITY",
      state: "ID"
    },
    {
      city: "SUGAR GROVE",
      state: "IL"
    },
    {
      city: "SUGAR GROVE",
      state: "NC"
    },
    {
      city: "SUGAR GROVE",
      state: "OH"
    },
    {
      city: "SUGAR GROVE",
      state: "PA"
    },
    {
      city: "SUGAR GROVE",
      state: "VA"
    },
    {
      city: "SUGAR GROVE",
      state: "WV"
    },
    {
      city: "SUGAR HILL",
      state: "NH"
    },
    {
      city: "SUGAR LAND",
      state: "TX"
    },
    {
      city: "SUGAR LOAF",
      state: "NY"
    },
    {
      city: "SUGAR RUN",
      state: "PA"
    },
    {
      city: "SUGAR TREE",
      state: "TN"
    },
    {
      city: "SUGAR VALLEY",
      state: "GA"
    },
    {
      city: "SUGARCREEK",
      state: "OH"
    },
    {
      city: "SUGARLOAF",
      state: "CA"
    },
    {
      city: "SUGARLOAF",
      state: "PA"
    },
    {
      city: "SUGARTOWN",
      state: "LA"
    },
    {
      city: "SUISUN CITY",
      state: "CA"
    },
    {
      city: "SUITLAND",
      state: "MD"
    },
    {
      city: "SULA",
      state: "MT"
    },
    {
      city: "SULLIGENT",
      state: "AL"
    },
    {
      city: "SULLIVAN",
      state: "IL"
    },
    {
      city: "SULLIVAN",
      state: "IN"
    },
    {
      city: "SULLIVAN",
      state: "KY"
    },
    {
      city: "SULLIVAN",
      state: "ME"
    },
    {
      city: "SULLIVAN",
      state: "MO"
    },
    {
      city: "SULLIVAN",
      state: "NH"
    },
    {
      city: "SULLIVAN",
      state: "OH"
    },
    {
      city: "SULLIVAN",
      state: "WI"
    },
    {
      city: "SULLIVAN CITY",
      state: "TX"
    },
    {
      city: "SULLIVANS ISLAND",
      state: "SC"
    },
    {
      city: "SULLY",
      state: "IA"
    },
    {
      city: "SULPHUR",
      state: "IN"
    },
    {
      city: "SULPHUR",
      state: "KY"
    },
    {
      city: "SULPHUR",
      state: "LA"
    },
    {
      city: "SULPHUR",
      state: "OK"
    },
    {
      city: "SULPHUR BLUFF",
      state: "TX"
    },
    {
      city: "SULPHUR ROCK",
      state: "AR"
    },
    {
      city: "SULPHUR SPRINGS",
      state: "AR"
    },
    {
      city: "SULPHUR SPRINGS",
      state: "IN"
    },
    {
      city: "SULPHUR SPRINGS",
      state: "OH"
    },
    {
      city: "SULPHUR SPRINGS",
      state: "TX"
    },
    {
      city: "SULTAN",
      state: "WA"
    },
    {
      city: "SULTANA",
      state: "CA"
    },
    {
      city: "SUMAS",
      state: "WA"
    },
    {
      city: "SUMATRA",
      state: "FL"
    },
    {
      city: "SUMAVA RESORTS",
      state: "IN"
    },
    {
      city: "SUMERCO",
      state: "WV"
    },
    {
      city: "SUMERDUCK",
      state: "VA"
    },
    {
      city: "SUMITON",
      state: "AL"
    },
    {
      city: "SUMMER LAKE",
      state: "OR"
    },
    {
      city: "SUMMER SHADE",
      state: "KY"
    },
    {
      city: "SUMMERDALE",
      state: "AL"
    },
    {
      city: "SUMMERDALE",
      state: "PA"
    },
    {
      city: "SUMMERFIELD",
      state: "FL"
    },
    {
      city: "SUMMERFIELD",
      state: "IL"
    },
    {
      city: "SUMMERFIELD",
      state: "KS"
    },
    {
      city: "SUMMERFIELD",
      state: "LA"
    },
    {
      city: "SUMMERFIELD",
      state: "NC"
    },
    {
      city: "SUMMERFIELD",
      state: "OH"
    },
    {
      city: "SUMMERFIELD",
      state: "TX"
    },
    {
      city: "SUMMERHILL",
      state: "PA"
    },
    {
      city: "SUMMERLAND",
      state: "CA"
    },
    {
      city: "SUMMERLAND KEY",
      state: "FL"
    },
    {
      city: "SUMMERS",
      state: "AR"
    },
    {
      city: "SUMMERSVILLE",
      state: "KY"
    },
    {
      city: "SUMMERSVILLE",
      state: "MO"
    },
    {
      city: "SUMMERSVILLE",
      state: "WV"
    },
    {
      city: "SUMMERTON",
      state: "SC"
    },
    {
      city: "SUMMERTOWN",
      state: "TN"
    },
    {
      city: "SUMMERVILLE",
      state: "GA"
    },
    {
      city: "SUMMERVILLE",
      state: "OR"
    },
    {
      city: "SUMMERVILLE",
      state: "PA"
    },
    {
      city: "SUMMERVILLE",
      state: "SC"
    },
    {
      city: "SUMMIT",
      state: "AR"
    },
    {
      city: "SUMMIT",
      state: "MS"
    },
    {
      city: "SUMMIT",
      state: "NJ"
    },
    {
      city: "SUMMIT",
      state: "NY"
    },
    {
      city: "SUMMIT",
      state: "SD"
    },
    {
      city: "SUMMIT",
      state: "UT"
    },
    {
      city: "SUMMIT ARGO",
      state: "IL"
    },
    {
      city: "SUMMIT HILL",
      state: "PA"
    },
    {
      city: "SUMMIT LAKE",
      state: "WI"
    },
    {
      city: "SUMMIT POINT",
      state: "WV"
    },
    {
      city: "SUMMIT STATION",
      state: "OH"
    },
    {
      city: "SUMMIT STATION",
      state: "PA"
    },
    {
      city: "SUMMITVILLE",
      state: "IN"
    },
    {
      city: "SUMMITVILLE",
      state: "NY"
    },
    {
      city: "SUMMITVILLE",
      state: "OH"
    },
    {
      city: "SUMMITVILLE",
      state: "TN"
    },
    {
      city: "SUMNER",
      state: "GA"
    },
    {
      city: "SUMNER",
      state: "IA"
    },
    {
      city: "SUMNER",
      state: "IL"
    },
    {
      city: "SUMNER",
      state: "ME"
    },
    {
      city: "SUMNER",
      state: "MI"
    },
    {
      city: "SUMNER",
      state: "MO"
    },
    {
      city: "SUMNER",
      state: "MS"
    },
    {
      city: "SUMNER",
      state: "NE"
    },
    {
      city: "SUMNER",
      state: "TX"
    },
    {
      city: "SUMNER",
      state: "WA"
    },
    {
      city: "SUMNEYTOWN",
      state: "PA"
    },
    {
      city: "SUMPTER",
      state: "OR"
    },
    {
      city: "SUMRALL",
      state: "MS"
    },
    {
      city: "SUMTER",
      state: "SC"
    },
    {
      city: "SUMTERVILLE",
      state: "FL"
    },
    {
      city: "SUN",
      state: "LA"
    },
    {
      city: "SUN CITY",
      state: "AZ"
    },
    {
      city: "SUN CITY",
      state: "CA"
    },
    {
      city: "SUN CITY",
      state: "FL"
    },
    {
      city: "SUN CITY CENTER",
      state: "FL"
    },
    {
      city: "SUN CITY WEST",
      state: "AZ"
    },
    {
      city: "SUN PRAIRIE",
      state: "WI"
    },
    {
      city: "SUN RIVER",
      state: "MT"
    },
    {
      city: "SUN VALLEY",
      state: "AZ"
    },
    {
      city: "SUN VALLEY",
      state: "CA"
    },
    {
      city: "SUN VALLEY",
      state: "ID"
    },
    {
      city: "SUN VALLEY",
      state: "NV"
    },
    {
      city: "SUNAPEE",
      state: "NH"
    },
    {
      city: "SUNBRIGHT",
      state: "TN"
    },
    {
      city: "SUNBURG",
      state: "MN"
    },
    {
      city: "SUNBURST",
      state: "MT"
    },
    {
      city: "SUNBURY",
      state: "NC"
    },
    {
      city: "SUNBURY",
      state: "OH"
    },
    {
      city: "SUNBURY",
      state: "PA"
    },
    {
      city: "SUNCOOK",
      state: "NH"
    },
    {
      city: "SUNDANCE",
      state: "WY"
    },
    {
      city: "SUNDERLAND",
      state: "MA"
    },
    {
      city: "SUNDERLAND",
      state: "MD"
    },
    {
      city: "SUNDOWN",
      state: "TX"
    },
    {
      city: "SUNFIELD",
      state: "MI"
    },
    {
      city: "SUNFLOWER",
      state: "AL"
    },
    {
      city: "SUNFLOWER",
      state: "MS"
    },
    {
      city: "SUNLAND",
      state: "CA"
    },
    {
      city: "SUNLAND PARK",
      state: "NM"
    },
    {
      city: "SUNMAN",
      state: "IN"
    },
    {
      city: "SUNNY SIDE",
      state: "GA"
    },
    {
      city: "SUNNYSIDE",
      state: "NY"
    },
    {
      city: "SUNNYSIDE",
      state: "UT"
    },
    {
      city: "SUNNYSIDE",
      state: "WA"
    },
    {
      city: "SUNNYVALE",
      state: "CA"
    },
    {
      city: "SUNNYVALE",
      state: "TX"
    },
    {
      city: "SUNOL",
      state: "CA"
    },
    {
      city: "SUNRAY",
      state: "TX"
    },
    {
      city: "SUNRISE BEACH",
      state: "MO"
    },
    {
      city: "SUNSET",
      state: "LA"
    },
    {
      city: "SUNSET",
      state: "ME"
    },
    {
      city: "SUNSET",
      state: "SC"
    },
    {
      city: "SUNSET",
      state: "TX"
    },
    {
      city: "SUNSET BEACH",
      state: "CA"
    },
    {
      city: "SUNSET BEACH",
      state: "NC"
    },
    {
      city: "SUNSHINE",
      state: "LA"
    },
    {
      city: "SUNSPOT",
      state: "NM"
    },
    {
      city: "SUPAI",
      state: "AZ"
    },
    {
      city: "SUPERIOR",
      state: "AZ"
    },
    {
      city: "SUPERIOR",
      state: "IA"
    },
    {
      city: "SUPERIOR",
      state: "MT"
    },
    {
      city: "SUPERIOR",
      state: "NE"
    },
    {
      city: "SUPERIOR",
      state: "WI"
    },
    {
      city: "SUPERIOR",
      state: "WY"
    },
    {
      city: "SUPPLY",
      state: "NC"
    },
    {
      city: "SUQUAMISH",
      state: "WA"
    },
    {
      city: "SURFSIDE",
      state: "CA"
    },
    {
      city: "SURGOINSVILLE",
      state: "TN"
    },
    {
      city: "SURING",
      state: "WI"
    },
    {
      city: "SURPRISE",
      state: "AZ"
    },
    {
      city: "SURPRISE",
      state: "NE"
    },
    {
      city: "SURPRISE",
      state: "NY"
    },
    {
      city: "SURRENCY",
      state: "GA"
    },
    {
      city: "SURREY",
      state: "ND"
    },
    {
      city: "SURRY",
      state: "ME"
    },
    {
      city: "SURRY",
      state: "VA"
    },
    {
      city: "SURVEYOR",
      state: "WV"
    },
    {
      city: "SUSAN",
      state: "VA"
    },
    {
      city: "SUSANVILLE",
      state: "CA"
    },
    {
      city: "SUSQUEHANNA",
      state: "PA"
    },
    {
      city: "SUSSEX",
      state: "NJ"
    },
    {
      city: "SUSSEX",
      state: "VA"
    },
    {
      city: "SUSSEX",
      state: "WI"
    },
    {
      city: "SUTERSVILLE",
      state: "PA"
    },
    {
      city: "SUTHERLAND",
      state: "IA"
    },
    {
      city: "SUTHERLAND",
      state: "NE"
    },
    {
      city: "SUTHERLAND",
      state: "VA"
    },
    {
      city: "SUTHERLAND SPRINGS",
      state: "TX"
    },
    {
      city: "SUTHERLIN",
      state: "OR"
    },
    {
      city: "SUTHERLIN",
      state: "VA"
    },
    {
      city: "SUTTER",
      state: "CA"
    },
    {
      city: "SUTTER",
      state: "IL"
    },
    {
      city: "SUTTER CREEK",
      state: "CA"
    },
    {
      city: "SUTTON",
      state: "AK"
    },
    {
      city: "SUTTON",
      state: "MA"
    },
    {
      city: "SUTTON",
      state: "ND"
    },
    {
      city: "SUTTON",
      state: "NE"
    },
    {
      city: "SUTTON",
      state: "VT"
    },
    {
      city: "SUTTON",
      state: "WV"
    },
    {
      city: "SUTTONS BAY",
      state: "MI"
    },
    {
      city: "SUWANEE",
      state: "GA"
    },
    {
      city: "SUWANNEE",
      state: "FL"
    },
    {
      city: "SWAIN",
      state: "NY"
    },
    {
      city: "SWAINSBORO",
      state: "GA"
    },
    {
      city: "SWALEDALE",
      state: "IA"
    },
    {
      city: "SWAMPSCOTT",
      state: "MA"
    },
    {
      city: "SWAN",
      state: "IA"
    },
    {
      city: "SWAN LAKE",
      state: "NY"
    },
    {
      city: "SWAN RIVER",
      state: "MN"
    },
    {
      city: "SWAN VALLEY",
      state: "ID"
    },
    {
      city: "SWANNANOA",
      state: "NC"
    },
    {
      city: "SWANQUARTER",
      state: "NC"
    },
    {
      city: "SWANS ISLAND",
      state: "ME"
    },
    {
      city: "SWANSBORO",
      state: "NC"
    },
    {
      city: "SWANSEA",
      state: "MA"
    },
    {
      city: "SWANSEA",
      state: "SC"
    },
    {
      city: "SWANTON",
      state: "MD"
    },
    {
      city: "SWANTON",
      state: "NE"
    },
    {
      city: "SWANTON",
      state: "OH"
    },
    {
      city: "SWANTON",
      state: "VT"
    },
    {
      city: "SWANVILLE",
      state: "MN"
    },
    {
      city: "SWANZEY",
      state: "NH"
    },
    {
      city: "SWARTHMORE",
      state: "PA"
    },
    {
      city: "SWARTSWOOD",
      state: "NJ"
    },
    {
      city: "SWARTZ",
      state: "LA"
    },
    {
      city: "SWARTZ CREEK",
      state: "MI"
    },
    {
      city: "SWATARA",
      state: "MN"
    },
    {
      city: "SWAYZEE",
      state: "IN"
    },
    {
      city: "SWEA CITY",
      state: "IA"
    },
    {
      city: "SWEDESBORO",
      state: "NJ"
    },
    {
      city: "SWEDESBURG",
      state: "IA"
    },
    {
      city: "SWEEDEN",
      state: "KY"
    },
    {
      city: "SWEENY",
      state: "TX"
    },
    {
      city: "SWEET",
      state: "ID"
    },
    {
      city: "SWEET BRIAR",
      state: "VA"
    },
    {
      city: "SWEET GRASS",
      state: "MT"
    },
    {
      city: "SWEET HOME",
      state: "AR"
    },
    {
      city: "SWEET HOME",
      state: "OR"
    },
    {
      city: "SWEET HOME",
      state: "TX"
    },
    {
      city: "SWEET SPRINGS",
      state: "MO"
    },
    {
      city: "SWEET VALLEY",
      state: "PA"
    },
    {
      city: "SWEET WATER",
      state: "AL"
    },
    {
      city: "SWEETSER",
      state: "IN"
    },
    {
      city: "SWEETWATER",
      state: "OK"
    },
    {
      city: "SWEETWATER",
      state: "TN"
    },
    {
      city: "SWEETWATER",
      state: "TX"
    },
    {
      city: "SWENGEL",
      state: "PA"
    },
    {
      city: "SWEPSONVILLE",
      state: "NC"
    },
    {
      city: "SWIFTON",
      state: "AR"
    },
    {
      city: "SWIFTWATER",
      state: "PA"
    },
    {
      city: "SWINK",
      state: "CO"
    },
    {
      city: "SWISHER",
      state: "IA"
    },
    {
      city: "SWISS",
      state: "WV"
    },
    {
      city: "SWISSHOME",
      state: "OR"
    },
    {
      city: "SWITZ CITY",
      state: "IN"
    },
    {
      city: "SWITZER",
      state: "WV"
    },
    {
      city: "SWOOPE",
      state: "VA"
    },
    {
      city: "SWORDS CREEK",
      state: "VA"
    },
    {
      city: "SYBERTSVILLE",
      state: "PA"
    },
    {
      city: "SYCAMORE",
      state: "AL"
    },
    {
      city: "SYCAMORE",
      state: "GA"
    },
    {
      city: "SYCAMORE",
      state: "IL"
    },
    {
      city: "SYCAMORE",
      state: "KS"
    },
    {
      city: "SYCAMORE",
      state: "OH"
    },
    {
      city: "SYCAMORE",
      state: "PA"
    },
    {
      city: "SYCAMORE",
      state: "SC"
    },
    {
      city: "SYDNEY",
      state: "FL"
    },
    {
      city: "SYKESTON",
      state: "ND"
    },
    {
      city: "SYKESVILLE",
      state: "MD"
    },
    {
      city: "SYKESVILLE",
      state: "PA"
    },
    {
      city: "SYLACAUGA",
      state: "AL"
    },
    {
      city: "SYLMAR",
      state: "CA"
    },
    {
      city: "SYLVA",
      state: "NC"
    },
    {
      city: "SYLVAN BEACH",
      state: "NY"
    },
    {
      city: "SYLVAN GROVE",
      state: "KS"
    },
    {
      city: "SYLVANIA",
      state: "AL"
    },
    {
      city: "SYLVANIA",
      state: "GA"
    },
    {
      city: "SYLVANIA",
      state: "OH"
    },
    {
      city: "SYLVANIA",
      state: "PA"
    },
    {
      city: "SYLVESTER",
      state: "GA"
    },
    {
      city: "SYLVESTER",
      state: "TX"
    },
    {
      city: "SYLVESTER",
      state: "WV"
    },
    {
      city: "SYLVIA",
      state: "KS"
    },
    {
      city: "SYMSONIA",
      state: "KY"
    },
    {
      city: "SYOSSET",
      state: "NY"
    },
    {
      city: "SYRACUSE",
      state: "IN"
    },
    {
      city: "SYRACUSE",
      state: "KS"
    },
    {
      city: "SYRACUSE",
      state: "MO"
    },
    {
      city: "SYRACUSE",
      state: "NE"
    },
    {
      city: "SYRACUSE",
      state: "NY"
    },
    {
      city: "SYRACUSE",
      state: "OH"
    },
    {
      city: "SYRACUSE",
      state: "UT"
    },
    {
      city: "SYRIA",
      state: "VA"
    },
    {
      city: "TABERG",
      state: "NY"
    },
    {
      city: "TABERNASH",
      state: "CO"
    },
    {
      city: "TABIONA",
      state: "UT"
    },
    {
      city: "TABLE GROVE",
      state: "IL"
    },
    {
      city: "TABLE ROCK",
      state: "NE"
    },
    {
      city: "TABOR",
      state: "IA"
    },
    {
      city: "TABOR",
      state: "SD"
    },
    {
      city: "TABOR CITY",
      state: "NC"
    },
    {
      city: "TACNA",
      state: "AZ"
    },
    {
      city: "TACOMA",
      state: "WA"
    },
    {
      city: "TACONIC",
      state: "CT"
    },
    {
      city: "TACONITE",
      state: "MN"
    },
    {
      city: "TAD",
      state: "WV"
    },
    {
      city: "TAFT",
      state: "CA"
    },
    {
      city: "TAFT",
      state: "TN"
    },
    {
      city: "TAFT",
      state: "TX"
    },
    {
      city: "TAFTON",
      state: "PA"
    },
    {
      city: "TAFTSVILLE",
      state: "VT"
    },
    {
      city: "TAFTVILLE",
      state: "CT"
    },
    {
      city: "TAHLEQUAH",
      state: "OK"
    },
    {
      city: "TAHOE CITY",
      state: "CA"
    },
    {
      city: "TAHOE VISTA",
      state: "CA"
    },
    {
      city: "TAHOKA",
      state: "TX"
    },
    {
      city: "TAHOLAH",
      state: "WA"
    },
    {
      city: "TAHOMA",
      state: "CA"
    },
    {
      city: "TAHUYA",
      state: "WA"
    },
    {
      city: "TAIBAN",
      state: "NM"
    },
    {
      city: "TAKOMA PARK",
      state: "MD"
    },
    {
      city: "TAKOTNA",
      state: "AK"
    },
    {
      city: "TALALA",
      state: "OK"
    },
    {
      city: "TALBOTT",
      state: "TN"
    },
    {
      city: "TALBOTTON",
      state: "GA"
    },
    {
      city: "TALCO",
      state: "TX"
    },
    {
      city: "TALCOTT",
      state: "WV"
    },
    {
      city: "TALENT",
      state: "OR"
    },
    {
      city: "TALIHINA",
      state: "OK"
    },
    {
      city: "TALKEETNA",
      state: "AK"
    },
    {
      city: "TALKING ROCK",
      state: "GA"
    },
    {
      city: "TALL TIMBERS",
      state: "MD"
    },
    {
      city: "TALLADEGA",
      state: "AL"
    },
    {
      city: "TALLAHASSEE",
      state: "FL"
    },
    {
      city: "TALLAPOOSA",
      state: "GA"
    },
    {
      city: "TALLAPOOSA",
      state: "MO"
    },
    {
      city: "TALLASSEE",
      state: "AL"
    },
    {
      city: "TALLASSEE",
      state: "TN"
    },
    {
      city: "TALLEVAST",
      state: "FL"
    },
    {
      city: "TALLMADGE",
      state: "OH"
    },
    {
      city: "TALLMAN",
      state: "NY"
    },
    {
      city: "TALLMANSVILLE",
      state: "WV"
    },
    {
      city: "TALLULA",
      state: "IL"
    },
    {
      city: "TALLULAH",
      state: "LA"
    },
    {
      city: "TALLULAH FALLS",
      state: "GA"
    },
    {
      city: "TALMAGE",
      state: "CA"
    },
    {
      city: "TALMAGE",
      state: "KS"
    },
    {
      city: "TALMAGE",
      state: "NE"
    },
    {
      city: "TALMAGE",
      state: "PA"
    },
    {
      city: "TALMAGE",
      state: "UT"
    },
    {
      city: "TALMO",
      state: "GA"
    },
    {
      city: "TALMOON",
      state: "MN"
    },
    {
      city: "TALOGA",
      state: "OK"
    },
    {
      city: "TALPA",
      state: "TX"
    },
    {
      city: "TAMA",
      state: "IA"
    },
    {
      city: "TAMAQUA",
      state: "PA"
    },
    {
      city: "TAMARACK",
      state: "MN"
    },
    {
      city: "TAMAROA",
      state: "IL"
    },
    {
      city: "TAMASSEE",
      state: "SC"
    },
    {
      city: "TAMIMENT",
      state: "PA"
    },
    {
      city: "TAMMS",
      state: "IL"
    },
    {
      city: "TAMPA",
      state: "FL"
    },
    {
      city: "TAMPA",
      state: "KS"
    },
    {
      city: "TAMPICO",
      state: "IL"
    },
    {
      city: "TAMWORTH",
      state: "NH"
    },
    {
      city: "TANACROSS",
      state: "AK"
    },
    {
      city: "TANANA",
      state: "AK"
    },
    {
      city: "TANEYTOWN",
      state: "MD"
    },
    {
      city: "TANEYVILLE",
      state: "MO"
    },
    {
      city: "TANGENT",
      state: "OR"
    },
    {
      city: "TANGERINE",
      state: "FL"
    },
    {
      city: "TANGIER",
      state: "VA"
    },
    {
      city: "TANGIPAHOA",
      state: "LA"
    },
    {
      city: "TANNER",
      state: "AL"
    },
    {
      city: "TANNERSVILLE",
      state: "NY"
    },
    {
      city: "TANNERSVILLE",
      state: "PA"
    },
    {
      city: "TANNERSVILLE",
      state: "VA"
    },
    {
      city: "TAOPI",
      state: "MN"
    },
    {
      city: "TAOS",
      state: "NM"
    },
    {
      city: "TAOS SKI VALLEY",
      state: "NM"
    },
    {
      city: "TAPPAHANNOCK",
      state: "VA"
    },
    {
      city: "TAPPAN",
      state: "NY"
    },
    {
      city: "TAPPEN",
      state: "ND"
    },
    {
      city: "TAR HEEL",
      state: "NC"
    },
    {
      city: "TARAWA TERRACE",
      state: "NC"
    },
    {
      city: "TARBORO",
      state: "NC"
    },
    {
      city: "TARENTUM",
      state: "PA"
    },
    {
      city: "TARIFFVILLE",
      state: "CT"
    },
    {
      city: "TARKIO",
      state: "MO"
    },
    {
      city: "TARLTON",
      state: "OH"
    },
    {
      city: "TARPLEY",
      state: "TX"
    },
    {
      city: "TARPON SPRINGS",
      state: "FL"
    },
    {
      city: "TARRS",
      state: "PA"
    },
    {
      city: "TARRYTOWN",
      state: "GA"
    },
    {
      city: "TARRYTOWN",
      state: "NY"
    },
    {
      city: "TARZAN",
      state: "TX"
    },
    {
      city: "TARZANA",
      state: "CA"
    },
    {
      city: "TASLEY",
      state: "VA"
    },
    {
      city: "TASWELL",
      state: "IN"
    },
    {
      city: "TATAMY",
      state: "PA"
    },
    {
      city: "TATE",
      state: "GA"
    },
    {
      city: "TATEVILLE",
      state: "KY"
    },
    {
      city: "TATITLEK",
      state: "AK"
    },
    {
      city: "TATUM",
      state: "NM"
    },
    {
      city: "TATUM",
      state: "SC"
    },
    {
      city: "TATUM",
      state: "TX"
    },
    {
      city: "TAUNTON",
      state: "MA"
    },
    {
      city: "TAUNTON",
      state: "MN"
    },
    {
      city: "TAVARES",
      state: "FL"
    },
    {
      city: "TAVERNIER",
      state: "FL"
    },
    {
      city: "TAWAS CITY",
      state: "MI"
    },
    {
      city: "TAYLOR",
      state: "AR"
    },
    {
      city: "TAYLOR",
      state: "AZ"
    },
    {
      city: "TAYLOR",
      state: "LA"
    },
    {
      city: "TAYLOR",
      state: "MI"
    },
    {
      city: "TAYLOR",
      state: "MO"
    },
    {
      city: "TAYLOR",
      state: "MS"
    },
    {
      city: "TAYLOR",
      state: "ND"
    },
    {
      city: "TAYLOR",
      state: "NE"
    },
    {
      city: "TAYLOR",
      state: "PA"
    },
    {
      city: "TAYLOR",
      state: "TX"
    },
    {
      city: "TAYLOR",
      state: "WI"
    },
    {
      city: "TAYLOR RIDGE",
      state: "IL"
    },
    {
      city: "TAYLOR SPRINGS",
      state: "IL"
    },
    {
      city: "TAYLORS",
      state: "SC"
    },
    {
      city: "TAYLORS FALLS",
      state: "MN"
    },
    {
      city: "TAYLORS ISLAND",
      state: "MD"
    },
    {
      city: "TAYLORSTOWN",
      state: "PA"
    },
    {
      city: "TAYLORSVILLE",
      state: "CA"
    },
    {
      city: "TAYLORSVILLE",
      state: "GA"
    },
    {
      city: "TAYLORSVILLE",
      state: "IN"
    },
    {
      city: "TAYLORSVILLE",
      state: "KY"
    },
    {
      city: "TAYLORSVILLE",
      state: "MS"
    },
    {
      city: "TAYLORSVILLE",
      state: "NC"
    },
    {
      city: "TAYLORVILLE",
      state: "IL"
    },
    {
      city: "TAZEWELL",
      state: "TN"
    },
    {
      city: "TAZEWELL",
      state: "VA"
    },
    {
      city: "TCHULA",
      state: "MS"
    },
    {
      city: "TEA",
      state: "SD"
    },
    {
      city: "TEACHEY",
      state: "NC"
    },
    {
      city: "TEAGUE",
      state: "TX"
    },
    {
      city: "TEANECK",
      state: "NJ"
    },
    {
      city: "TEASDALE",
      state: "UT"
    },
    {
      city: "TEAYS",
      state: "WV"
    },
    {
      city: "TEBBETTS",
      state: "MO"
    },
    {
      city: "TECATE",
      state: "CA"
    },
    {
      city: "TECHNY",
      state: "IL"
    },
    {
      city: "TECOPA",
      state: "CA"
    },
    {
      city: "TECUMSEH",
      state: "KS"
    },
    {
      city: "TECUMSEH",
      state: "MI"
    },
    {
      city: "TECUMSEH",
      state: "MO"
    },
    {
      city: "TECUMSEH",
      state: "NE"
    },
    {
      city: "TECUMSEH",
      state: "OK"
    },
    {
      city: "TEEC NOS POS",
      state: "AZ"
    },
    {
      city: "TEHACHAPI",
      state: "CA"
    },
    {
      city: "TEHAMA",
      state: "CA"
    },
    {
      city: "TEHUACANA",
      state: "TX"
    },
    {
      city: "TEKAMAH",
      state: "NE"
    },
    {
      city: "TEKOA",
      state: "WA"
    },
    {
      city: "TEKONSHA",
      state: "MI"
    },
    {
      city: "TELEPHONE",
      state: "TX"
    },
    {
      city: "TELFERNER",
      state: "TX"
    },
    {
      city: "TELFORD",
      state: "PA"
    },
    {
      city: "TELFORD",
      state: "TN"
    },
    {
      city: "TELL CITY",
      state: "IN"
    },
    {
      city: "TELLER",
      state: "AK"
    },
    {
      city: "TELLICO PLAINS",
      state: "TN"
    },
    {
      city: "TELLURIDE",
      state: "CO"
    },
    {
      city: "TELOGIA",
      state: "FL"
    },
    {
      city: "TEMECULA",
      state: "CA"
    },
    {
      city: "TEMPE",
      state: "AZ"
    },
    {
      city: "TEMPERANCE",
      state: "MI"
    },
    {
      city: "TEMPERANCEVILLE",
      state: "VA"
    },
    {
      city: "TEMPLE",
      state: "GA"
    },
    {
      city: "TEMPLE",
      state: "ME"
    },
    {
      city: "TEMPLE",
      state: "NH"
    },
    {
      city: "TEMPLE",
      state: "OK"
    },
    {
      city: "TEMPLE",
      state: "PA"
    },
    {
      city: "TEMPLE",
      state: "TX"
    },
    {
      city: "TEMPLE BAR MARINA",
      state: "AZ"
    },
    {
      city: "TEMPLE CITY",
      state: "CA"
    },
    {
      city: "TEMPLE HILLS",
      state: "MD"
    },
    {
      city: "TEMPLETON",
      state: "CA"
    },
    {
      city: "TEMPLETON",
      state: "IA"
    },
    {
      city: "TEMPLETON",
      state: "IN"
    },
    {
      city: "TEMPLETON",
      state: "MA"
    },
    {
      city: "TEMPLETON",
      state: "PA"
    },
    {
      city: "TEN MILE",
      state: "TN"
    },
    {
      city: "TEN SLEEP",
      state: "WY"
    },
    {
      city: "TENAFLY",
      state: "NJ"
    },
    {
      city: "TENAHA",
      state: "TX"
    },
    {
      city: "TENAKEE SPRINGS",
      state: "AK"
    },
    {
      city: "TENANTS HARBOR",
      state: "ME"
    },
    {
      city: "TENDOY",
      state: "ID"
    },
    {
      city: "TENINO",
      state: "WA"
    },
    {
      city: "TENMILE",
      state: "OR"
    },
    {
      city: "TENNENT",
      state: "NJ"
    },
    {
      city: "TENNESSEE",
      state: "IL"
    },
    {
      city: "TENNESSEE COLONY",
      state: "TX"
    },
    {
      city: "TENNESSEE RIDGE",
      state: "TN"
    },
    {
      city: "TENNGA",
      state: "GA"
    },
    {
      city: "TENNILLE",
      state: "GA"
    },
    {
      city: "TENNYSON",
      state: "IN"
    },
    {
      city: "TENNYSON",
      state: "TX"
    },
    {
      city: "TENSED",
      state: "ID"
    },
    {
      city: "TENSTRIKE",
      state: "MN"
    },
    {
      city: "TERLINGUA",
      state: "TX"
    },
    {
      city: "TERLTON",
      state: "OK"
    },
    {
      city: "TERMO",
      state: "CA"
    },
    {
      city: "TERRA ALTA",
      state: "WV"
    },
    {
      city: "TERRA BELLA",
      state: "CA"
    },
    {
      city: "TERRA CEIA",
      state: "FL"
    },
    {
      city: "TERRACE PARK",
      state: "OH"
    },
    {
      city: "TERRAL",
      state: "OK"
    },
    {
      city: "TERRE HAUTE",
      state: "IN"
    },
    {
      city: "TERRE HILL",
      state: "PA"
    },
    {
      city: "TERREBONNE",
      state: "OR"
    },
    {
      city: "TERRELL",
      state: "NC"
    },
    {
      city: "TERRELL",
      state: "TX"
    },
    {
      city: "TERRETON",
      state: "ID"
    },
    {
      city: "TERRIL",
      state: "IA"
    },
    {
      city: "TERRY",
      state: "MS"
    },
    {
      city: "TERRY",
      state: "MT"
    },
    {
      city: "TERRYVILLE",
      state: "CT"
    },
    {
      city: "TESCOTT",
      state: "KS"
    },
    {
      city: "TESUQUE",
      state: "NM"
    },
    {
      city: "TETERBORO",
      state: "NJ"
    },
    {
      city: "TETON",
      state: "ID"
    },
    {
      city: "TETON VILLAGE",
      state: "WY"
    },
    {
      city: "TETONIA",
      state: "ID"
    },
    {
      city: "TEUTOPOLIS",
      state: "IL"
    },
    {
      city: "TEWKSBURY",
      state: "MA"
    },
    {
      city: "TEXARKANA",
      state: "AR"
    },
    {
      city: "TEXARKANA",
      state: "TX"
    },
    {
      city: "TEXAS CITY",
      state: "TX"
    },
    {
      city: "TEXHOMA",
      state: "OK"
    },
    {
      city: "TEXHOMA",
      state: "TX"
    },
    {
      city: "TEXICO",
      state: "IL"
    },
    {
      city: "TEXICO",
      state: "NM"
    },
    {
      city: "TEXLINE",
      state: "TX"
    },
    {
      city: "TEXOLA",
      state: "OK"
    },
    {
      city: "THACKERVILLE",
      state: "OK"
    },
    {
      city: "THATCHER",
      state: "AZ"
    },
    {
      city: "THATCHER",
      state: "ID"
    },
    {
      city: "THAWVILLE",
      state: "IL"
    },
    {
      city: "THAXTON",
      state: "MS"
    },
    {
      city: "THAXTON",
      state: "VA"
    },
    {
      city: "THAYER",
      state: "IA"
    },
    {
      city: "THAYER",
      state: "IL"
    },
    {
      city: "THAYER",
      state: "IN"
    },
    {
      city: "THAYER",
      state: "KS"
    },
    {
      city: "THAYER",
      state: "MO"
    },
    {
      city: "THAYNE",
      state: "WY"
    },
    {
      city: "THE COLONY",
      state: "TX"
    },
    {
      city: "THE DALLES",
      state: "OR"
    },
    {
      city: "THE LAKES",
      state: "NV"
    },
    {
      city: "THE PLAINS",
      state: "OH"
    },
    {
      city: "THE PLAINS",
      state: "VA"
    },
    {
      city: "THE ROCK",
      state: "GA"
    },
    {
      city: "THE SEA RANCH",
      state: "CA"
    },
    {
      city: "THE VILLAGES",
      state: "FL"
    },
    {
      city: "THEBES",
      state: "IL"
    },
    {
      city: "THEDFORD",
      state: "NE"
    },
    {
      city: "THELMA",
      state: "KY"
    },
    {
      city: "THENDARA",
      state: "NY"
    },
    {
      city: "THEODORE",
      state: "AL"
    },
    {
      city: "THEODOSIA",
      state: "MO"
    },
    {
      city: "THERESA",
      state: "NY"
    },
    {
      city: "THERESA",
      state: "WI"
    },
    {
      city: "THERIOT",
      state: "LA"
    },
    {
      city: "THERMAL",
      state: "CA"
    },
    {
      city: "THERMOPOLIS",
      state: "WY"
    },
    {
      city: "THETFORD",
      state: "VT"
    },
    {
      city: "THETFORD CENTER",
      state: "VT"
    },
    {
      city: "THIBODAUX",
      state: "LA"
    },
    {
      city: "THICKET",
      state: "TX"
    },
    {
      city: "THIEF RIVER FALLS",
      state: "MN"
    },
    {
      city: "THIELLS",
      state: "NY"
    },
    {
      city: "THOMAS",
      state: "OK"
    },
    {
      city: "THOMAS",
      state: "WV"
    },
    {
      city: "THOMASBORO",
      state: "IL"
    },
    {
      city: "THOMASTON",
      state: "AL"
    },
    {
      city: "THOMASTON",
      state: "CT"
    },
    {
      city: "THOMASTON",
      state: "GA"
    },
    {
      city: "THOMASTON",
      state: "ME"
    },
    {
      city: "THOMASTON",
      state: "TX"
    },
    {
      city: "THOMASTOWN",
      state: "MS"
    },
    {
      city: "THOMASVILLE",
      state: "AL"
    },
    {
      city: "THOMASVILLE",
      state: "GA"
    },
    {
      city: "THOMASVILLE",
      state: "NC"
    },
    {
      city: "THOMASVILLE",
      state: "PA"
    },
    {
      city: "THOMPSON",
      state: "CT"
    },
    {
      city: "THOMPSON",
      state: "IA"
    },
    {
      city: "THOMPSON",
      state: "MO"
    },
    {
      city: "THOMPSON",
      state: "ND"
    },
    {
      city: "THOMPSON",
      state: "OH"
    },
    {
      city: "THOMPSON",
      state: "PA"
    },
    {
      city: "THOMPSON",
      state: "UT"
    },
    {
      city: "THOMPSON FALLS",
      state: "MT"
    },
    {
      city: "THOMPSON RIDGE",
      state: "NY"
    },
    {
      city: "THOMPSONS",
      state: "TX"
    },
    {
      city: "THOMPSONS STATION",
      state: "TN"
    },
    {
      city: "THOMPSONTOWN",
      state: "PA"
    },
    {
      city: "THOMPSONVILLE",
      state: "IL"
    },
    {
      city: "THOMPSONVILLE",
      state: "MI"
    },
    {
      city: "THOMPSONVILLE",
      state: "NY"
    },
    {
      city: "THOMSON",
      state: "GA"
    },
    {
      city: "THOMSON",
      state: "IL"
    },
    {
      city: "THONOTOSASSA",
      state: "FL"
    },
    {
      city: "THOR",
      state: "IA"
    },
    {
      city: "THOREAU",
      state: "NM"
    },
    {
      city: "THORN HILL",
      state: "TN"
    },
    {
      city: "THORNBURG",
      state: "VA"
    },
    {
      city: "THORNDALE",
      state: "PA"
    },
    {
      city: "THORNDALE",
      state: "TX"
    },
    {
      city: "THORNDIKE",
      state: "MA"
    },
    {
      city: "THORNDIKE",
      state: "ME"
    },
    {
      city: "THORNE BAY",
      state: "AK"
    },
    {
      city: "THORNFIELD",
      state: "MO"
    },
    {
      city: "THORNTON",
      state: "AR"
    },
    {
      city: "THORNTON",
      state: "CA"
    },
    {
      city: "THORNTON",
      state: "CO"
    },
    {
      city: "THORNTON",
      state: "IA"
    },
    {
      city: "THORNTON",
      state: "IL"
    },
    {
      city: "THORNTON",
      state: "KY"
    },
    {
      city: "THORNTON",
      state: "NH"
    },
    {
      city: "THORNTON",
      state: "PA"
    },
    {
      city: "THORNTON",
      state: "TX"
    },
    {
      city: "THORNTON",
      state: "WA"
    },
    {
      city: "THORNTON",
      state: "WV"
    },
    {
      city: "THORNTOWN",
      state: "IN"
    },
    {
      city: "THORNVILLE",
      state: "OH"
    },
    {
      city: "THORNWOOD",
      state: "NY"
    },
    {
      city: "THOROFARE",
      state: "NJ"
    },
    {
      city: "THORP",
      state: "WA"
    },
    {
      city: "THORP",
      state: "WI"
    },
    {
      city: "THORPE",
      state: "WV"
    },
    {
      city: "THORSBY",
      state: "AL"
    },
    {
      city: "THOUSAND ISLAND PARK",
      state: "NY"
    },
    {
      city: "THOUSAND OAKS",
      state: "CA"
    },
    {
      city: "THOUSAND PALMS",
      state: "CA"
    },
    {
      city: "THOUSANDSTICKS",
      state: "KY"
    },
    {
      city: "THRALL",
      state: "TX"
    },
    {
      city: "THREE BRIDGES",
      state: "NJ"
    },
    {
      city: "THREE FORKS",
      state: "MT"
    },
    {
      city: "THREE LAKES",
      state: "WI"
    },
    {
      city: "THREE MILE BAY",
      state: "NY"
    },
    {
      city: "THREE OAKS",
      state: "MI"
    },
    {
      city: "THREE RIVERS",
      state: "CA"
    },
    {
      city: "THREE RIVERS",
      state: "MA"
    },
    {
      city: "THREE RIVERS",
      state: "MI"
    },
    {
      city: "THREE RIVERS",
      state: "TX"
    },
    {
      city: "THREE SPRINGS",
      state: "PA"
    },
    {
      city: "THROCKMORTON",
      state: "TX"
    },
    {
      city: "THURMAN",
      state: "IA"
    },
    {
      city: "THURMAN",
      state: "OH"
    },
    {
      city: "THURMOND",
      state: "NC"
    },
    {
      city: "THURMOND",
      state: "WV"
    },
    {
      city: "THURMONT",
      state: "MD"
    },
    {
      city: "THURSTON",
      state: "NE"
    },
    {
      city: "THURSTON",
      state: "OH"
    },
    {
      city: "TIBBIE",
      state: "AL"
    },
    {
      city: "TICHNOR",
      state: "AR"
    },
    {
      city: "TICKFAW",
      state: "LA"
    },
    {
      city: "TICONDEROGA",
      state: "NY"
    },
    {
      city: "TIDEWATER",
      state: "OR"
    },
    {
      city: "TIDIOUTE",
      state: "PA"
    },
    {
      city: "TIE PLANT",
      state: "MS"
    },
    {
      city: "TIE SIDING",
      state: "WY"
    },
    {
      city: "TIERRA AMARILLA",
      state: "NM"
    },
    {
      city: "TIETON",
      state: "WA"
    },
    {
      city: "TIFF",
      state: "MO"
    },
    {
      city: "TIFF CITY",
      state: "MO"
    },
    {
      city: "TIFFIN",
      state: "IA"
    },
    {
      city: "TIFFIN",
      state: "OH"
    },
    {
      city: "TIFTON",
      state: "GA"
    },
    {
      city: "TIGER",
      state: "GA"
    },
    {
      city: "TIGERTON",
      state: "WI"
    },
    {
      city: "TIGERVILLE",
      state: "SC"
    },
    {
      city: "TIGNALL",
      state: "GA"
    },
    {
      city: "TIGRETT",
      state: "TN"
    },
    {
      city: "TIJERAS",
      state: "NM"
    },
    {
      city: "TILDEN",
      state: "IL"
    },
    {
      city: "TILDEN",
      state: "NE"
    },
    {
      city: "TILDEN",
      state: "TX"
    },
    {
      city: "TILGHMAN",
      state: "MD"
    },
    {
      city: "TILINE",
      state: "KY"
    },
    {
      city: "TILLAMOOK",
      state: "OR"
    },
    {
      city: "TILLAR",
      state: "AR"
    },
    {
      city: "TILLATOBA",
      state: "MS"
    },
    {
      city: "TILLEDA",
      state: "WI"
    },
    {
      city: "TILLER",
      state: "OR"
    },
    {
      city: "TILLERY",
      state: "NC"
    },
    {
      city: "TILLMAN",
      state: "SC"
    },
    {
      city: "TILLSON",
      state: "NY"
    },
    {
      city: "TILLY",
      state: "AR"
    },
    {
      city: "TILTON",
      state: "IL"
    },
    {
      city: "TILTON",
      state: "NH"
    },
    {
      city: "TILTONSVILLE",
      state: "OH"
    },
    {
      city: "TIMBER",
      state: "OR"
    },
    {
      city: "TIMBER LAKE",
      state: "SD"
    },
    {
      city: "TIMBERLAKE",
      state: "NC"
    },
    {
      city: "TIMBERON",
      state: "NM"
    },
    {
      city: "TIMBERVILLE",
      state: "VA"
    },
    {
      city: "TIMBO",
      state: "AR"
    },
    {
      city: "TIMEWELL",
      state: "IL"
    },
    {
      city: "TIMMONSVILLE",
      state: "SC"
    },
    {
      city: "TIMNATH",
      state: "CO"
    },
    {
      city: "TIMPSON",
      state: "TX"
    },
    {
      city: "TINA",
      state: "MO"
    },
    {
      city: "TINGLEY",
      state: "IA"
    },
    {
      city: "TINLEY PARK",
      state: "IL"
    },
    {
      city: "TINSLEY",
      state: "MS"
    },
    {
      city: "TINTAH",
      state: "MN"
    },
    {
      city: "TIOGA",
      state: "LA"
    },
    {
      city: "TIOGA",
      state: "ND"
    },
    {
      city: "TIOGA",
      state: "PA"
    },
    {
      city: "TIOGA",
      state: "TX"
    },
    {
      city: "TIOGA",
      state: "WV"
    },
    {
      city: "TIOGA CENTER",
      state: "NY"
    },
    {
      city: "TIONA",
      state: "PA"
    },
    {
      city: "TIONESTA",
      state: "PA"
    },
    {
      city: "TIPLERSVILLE",
      state: "MS"
    },
    {
      city: "TIPP CITY",
      state: "OH"
    },
    {
      city: "TIPPECANOE",
      state: "IN"
    },
    {
      city: "TIPPECANOE",
      state: "OH"
    },
    {
      city: "TIPTON",
      state: "CA"
    },
    {
      city: "TIPTON",
      state: "IA"
    },
    {
      city: "TIPTON",
      state: "IN"
    },
    {
      city: "TIPTON",
      state: "KS"
    },
    {
      city: "TIPTON",
      state: "MI"
    },
    {
      city: "TIPTON",
      state: "MO"
    },
    {
      city: "TIPTON",
      state: "OK"
    },
    {
      city: "TIPTON",
      state: "PA"
    },
    {
      city: "TIPTONVILLE",
      state: "TN"
    },
    {
      city: "TIRE HILL",
      state: "PA"
    },
    {
      city: "TIRO",
      state: "OH"
    },
    {
      city: "TISCH MILLS",
      state: "WI"
    },
    {
      city: "TISHOMINGO",
      state: "MS"
    },
    {
      city: "TISHOMINGO",
      state: "OK"
    },
    {
      city: "TISKILWA",
      state: "IL"
    },
    {
      city: "TITONKA",
      state: "IA"
    },
    {
      city: "TITUS",
      state: "AL"
    },
    {
      city: "TITUSVILLE",
      state: "FL"
    },
    {
      city: "TITUSVILLE",
      state: "NJ"
    },
    {
      city: "TITUSVILLE",
      state: "PA"
    },
    {
      city: "TIVERTON",
      state: "RI"
    },
    {
      city: "TIVOLI",
      state: "NY"
    },
    {
      city: "TIVOLI",
      state: "TX"
    },
    {
      city: "TOANO",
      state: "VA"
    },
    {
      city: "TOBACCOVILLE",
      state: "NC"
    },
    {
      city: "TOBIAS",
      state: "NE"
    },
    {
      city: "TOBYHANNA",
      state: "PA"
    },
    {
      city: "TOCCOA",
      state: "GA"
    },
    {
      city: "TOCCOA FALLS",
      state: "GA"
    },
    {
      city: "TOCCOPOLA",
      state: "MS"
    },
    {
      city: "TODD",
      state: "NC"
    },
    {
      city: "TODD",
      state: "PA"
    },
    {
      city: "TODDVILLE",
      state: "IA"
    },
    {
      city: "TODDVILLE",
      state: "MD"
    },
    {
      city: "TOETERVILLE",
      state: "IA"
    },
    {
      city: "TOFTE",
      state: "MN"
    },
    {
      city: "TOGIAK",
      state: "AK"
    },
    {
      city: "TOHATCHI",
      state: "NM"
    },
    {
      city: "TOIVOLA",
      state: "MI"
    },
    {
      city: "TOK",
      state: "AK"
    },
    {
      city: "TOKELAND",
      state: "WA"
    },
    {
      city: "TOKSOOK BAY",
      state: "AK"
    },
    {
      city: "TOLAR",
      state: "TX"
    },
    {
      city: "TOLEDO",
      state: "IA"
    },
    {
      city: "TOLEDO",
      state: "IL"
    },
    {
      city: "TOLEDO",
      state: "OH"
    },
    {
      city: "TOLEDO",
      state: "OR"
    },
    {
      city: "TOLEDO",
      state: "WA"
    },
    {
      city: "TOLLAND",
      state: "CT"
    },
    {
      city: "TOLLESBORO",
      state: "KY"
    },
    {
      city: "TOLLESON",
      state: "AZ"
    },
    {
      city: "TOLLEY",
      state: "ND"
    },
    {
      city: "TOLLHOUSE",
      state: "CA"
    },
    {
      city: "TOLNA",
      state: "ND"
    },
    {
      city: "TOLONO",
      state: "IL"
    },
    {
      city: "TOLOVANA PARK",
      state: "OR"
    },
    {
      city: "TOLSTOY",
      state: "SD"
    },
    {
      city: "TOLUCA",
      state: "IL"
    },
    {
      city: "TOLUCA LAKE",
      state: "CA"
    },
    {
      city: "TOM BEAN",
      state: "TX"
    },
    {
      city: "TOMAH",
      state: "WI"
    },
    {
      city: "TOMAHAWK",
      state: "KY"
    },
    {
      city: "TOMAHAWK",
      state: "WI"
    },
    {
      city: "TOMALES",
      state: "CA"
    },
    {
      city: "TOMBALL",
      state: "TX"
    },
    {
      city: "TOMBSTONE",
      state: "AZ"
    },
    {
      city: "TOME",
      state: "NM"
    },
    {
      city: "TOMKINS COVE",
      state: "NY"
    },
    {
      city: "TOMPKINSVILLE",
      state: "KY"
    },
    {
      city: "TOMS BROOK",
      state: "VA"
    },
    {
      city: "TOMS RIVER",
      state: "NJ"
    },
    {
      city: "TONALEA",
      state: "AZ"
    },
    {
      city: "TONASKET",
      state: "WA"
    },
    {
      city: "TONAWANDA",
      state: "NY"
    },
    {
      city: "TONEY",
      state: "AL"
    },
    {
      city: "TONGANOXIE",
      state: "KS"
    },
    {
      city: "TONICA",
      state: "IL"
    },
    {
      city: "TONKAWA",
      state: "OK"
    },
    {
      city: "TONOPAH",
      state: "AZ"
    },
    {
      city: "TONOPAH",
      state: "NV"
    },
    {
      city: "TONTITOWN",
      state: "AR"
    },
    {
      city: "TONTO BASIN",
      state: "AZ"
    },
    {
      city: "TONTOGANY",
      state: "OH"
    },
    {
      city: "TONY",
      state: "WI"
    },
    {
      city: "TOOELE",
      state: "UT"
    },
    {
      city: "TOOMSBORO",
      state: "GA"
    },
    {
      city: "TOOMSUBA",
      state: "MS"
    },
    {
      city: "TOONE",
      state: "TN"
    },
    {
      city: "TOPANGA",
      state: "CA"
    },
    {
      city: "TOPAWA",
      state: "AZ"
    },
    {
      city: "TOPAZ",
      state: "CA"
    },
    {
      city: "TOPEKA",
      state: "IL"
    },
    {
      city: "TOPEKA",
      state: "IN"
    },
    {
      city: "TOPEKA",
      state: "KS"
    },
    {
      city: "TOPINABEE",
      state: "MI"
    },
    {
      city: "TOPMOST",
      state: "KY"
    },
    {
      city: "TOPOCK",
      state: "AZ"
    },
    {
      city: "TOPONAS",
      state: "CO"
    },
    {
      city: "TOPPENISH",
      state: "WA"
    },
    {
      city: "TOPPING",
      state: "VA"
    },
    {
      city: "TOPSFIELD",
      state: "MA"
    },
    {
      city: "TOPSFIELD",
      state: "ME"
    },
    {
      city: "TOPSHAM",
      state: "ME"
    },
    {
      city: "TOPSHAM",
      state: "VT"
    },
    {
      city: "TOPTON",
      state: "NC"
    },
    {
      city: "TOPTON",
      state: "PA"
    },
    {
      city: "TOQUERVILLE",
      state: "UT"
    },
    {
      city: "TORNADO",
      state: "WV"
    },
    {
      city: "TORNILLO",
      state: "TX"
    },
    {
      city: "TORONTO",
      state: "KS"
    },
    {
      city: "TORONTO",
      state: "OH"
    },
    {
      city: "TORONTO",
      state: "SD"
    },
    {
      city: "TORRANCE",
      state: "CA"
    },
    {
      city: "TORRANCE",
      state: "PA"
    },
    {
      city: "TORREON",
      state: "NM"
    },
    {
      city: "TORREY",
      state: "UT"
    },
    {
      city: "TORRINGTON",
      state: "CT"
    },
    {
      city: "TORRINGTON",
      state: "WY"
    },
    {
      city: "TOSTON",
      state: "MT"
    },
    {
      city: "TOTOWA",
      state: "NJ"
    },
    {
      city: "TOTZ",
      state: "KY"
    },
    {
      city: "TOUCHET",
      state: "WA"
    },
    {
      city: "TOUGALOO",
      state: "MS"
    },
    {
      city: "TOUGHKENAMON",
      state: "PA"
    },
    {
      city: "TOULON",
      state: "IL"
    },
    {
      city: "TOUTLE",
      state: "WA"
    },
    {
      city: "TOVEY",
      state: "IL"
    },
    {
      city: "TOW",
      state: "TX"
    },
    {
      city: "TOWACO",
      state: "NJ"
    },
    {
      city: "TOWANDA",
      state: "IL"
    },
    {
      city: "TOWANDA",
      state: "KS"
    },
    {
      city: "TOWANDA",
      state: "PA"
    },
    {
      city: "TOWAOC",
      state: "CO"
    },
    {
      city: "TOWER",
      state: "MI"
    },
    {
      city: "TOWER",
      state: "MN"
    },
    {
      city: "TOWER CITY",
      state: "ND"
    },
    {
      city: "TOWER CITY",
      state: "PA"
    },
    {
      city: "TOWER HILL",
      state: "IL"
    },
    {
      city: "TOWN CREEK",
      state: "AL"
    },
    {
      city: "TOWNER",
      state: "ND"
    },
    {
      city: "TOWNLEY",
      state: "AL"
    },
    {
      city: "TOWNSEND",
      state: "DE"
    },
    {
      city: "TOWNSEND",
      state: "GA"
    },
    {
      city: "TOWNSEND",
      state: "MA"
    },
    {
      city: "TOWNSEND",
      state: "MT"
    },
    {
      city: "TOWNSEND",
      state: "TN"
    },
    {
      city: "TOWNSEND",
      state: "VA"
    },
    {
      city: "TOWNSEND",
      state: "WI"
    },
    {
      city: "TOWNSHEND",
      state: "VT"
    },
    {
      city: "TOWNSHIP OF WASHINGTON",
      state: "NJ"
    },
    {
      city: "TOWNSVILLE",
      state: "NC"
    },
    {
      city: "TOWNVILLE",
      state: "PA"
    },
    {
      city: "TOWNVILLE",
      state: "SC"
    },
    {
      city: "TOWSON",
      state: "MD"
    },
    {
      city: "TOXEY",
      state: "AL"
    },
    {
      city: "TOYAH",
      state: "TX"
    },
    {
      city: "TOYAHVALE",
      state: "TX"
    },
    {
      city: "TRABUCO CANYON",
      state: "CA"
    },
    {
      city: "TRACY",
      state: "CA"
    },
    {
      city: "TRACY",
      state: "IA"
    },
    {
      city: "TRACY",
      state: "MN"
    },
    {
      city: "TRACY CITY",
      state: "TN"
    },
    {
      city: "TRACYS LANDING",
      state: "MD"
    },
    {
      city: "TRACYTON",
      state: "WA"
    },
    {
      city: "TRADE",
      state: "TN"
    },
    {
      city: "TRAER",
      state: "IA"
    },
    {
      city: "TRAFALGAR",
      state: "IN"
    },
    {
      city: "TRAFFORD",
      state: "AL"
    },
    {
      city: "TRAFFORD",
      state: "PA"
    },
    {
      city: "TRAIL",
      state: "MN"
    },
    {
      city: "TRAIL",
      state: "OR"
    },
    {
      city: "TRAIL CITY",
      state: "SD"
    },
    {
      city: "TRAM",
      state: "KY"
    },
    {
      city: "TRANQUILITY",
      state: "NJ"
    },
    {
      city: "TRANQUILLITY",
      state: "CA"
    },
    {
      city: "TRANSFER",
      state: "PA"
    },
    {
      city: "TRANSYLVANIA",
      state: "LA"
    },
    {
      city: "TRAPHILL",
      state: "NC"
    },
    {
      city: "TRAPPE",
      state: "MD"
    },
    {
      city: "TRAPPER CREEK",
      state: "AK"
    },
    {
      city: "TRASKWOOD",
      state: "AR"
    },
    {
      city: "TRAVELERS REST",
      state: "SC"
    },
    {
      city: "TRAVER",
      state: "CA"
    },
    {
      city: "TRAVERSE CITY",
      state: "MI"
    },
    {
      city: "TRAVIS AFB",
      state: "CA"
    },
    {
      city: "TREADWELL",
      state: "NY"
    },
    {
      city: "TREBLOC",
      state: "MS"
    },
    {
      city: "TREECE",
      state: "KS"
    },
    {
      city: "TREGO",
      state: "MT"
    },
    {
      city: "TREGO",
      state: "WI"
    },
    {
      city: "TREICHLERS",
      state: "PA"
    },
    {
      city: "TREMONT",
      state: "IL"
    },
    {
      city: "TREMONT",
      state: "MS"
    },
    {
      city: "TREMONT",
      state: "PA"
    },
    {
      city: "TREMONT CITY",
      state: "OH"
    },
    {
      city: "TREMONTON",
      state: "UT"
    },
    {
      city: "TREMPEALEAU",
      state: "WI"
    },
    {
      city: "TRENARY",
      state: "MI"
    },
    {
      city: "TRENT",
      state: "SD"
    },
    {
      city: "TRENT",
      state: "TX"
    },
    {
      city: "TRENTON",
      state: "AL"
    },
    {
      city: "TRENTON",
      state: "FL"
    },
    {
      city: "TRENTON",
      state: "GA"
    },
    {
      city: "TRENTON",
      state: "IL"
    },
    {
      city: "TRENTON",
      state: "KY"
    },
    {
      city: "TRENTON",
      state: "MI"
    },
    {
      city: "TRENTON",
      state: "MO"
    },
    {
      city: "TRENTON",
      state: "NC"
    },
    {
      city: "TRENTON",
      state: "ND"
    },
    {
      city: "TRENTON",
      state: "NE"
    },
    {
      city: "TRENTON",
      state: "NJ"
    },
    {
      city: "TRENTON",
      state: "OH"
    },
    {
      city: "TRENTON",
      state: "SC"
    },
    {
      city: "TRENTON",
      state: "TN"
    },
    {
      city: "TRENTON",
      state: "TX"
    },
    {
      city: "TRES PIEDRAS",
      state: "NM"
    },
    {
      city: "TRES PINOS",
      state: "CA"
    },
    {
      city: "TRESCKOW",
      state: "PA"
    },
    {
      city: "TREVETT",
      state: "ME"
    },
    {
      city: "TREVILIANS",
      state: "VA"
    },
    {
      city: "TREVOR",
      state: "WI"
    },
    {
      city: "TREVORTON",
      state: "PA"
    },
    {
      city: "TREXLERTOWN",
      state: "PA"
    },
    {
      city: "TREYNOR",
      state: "IA"
    },
    {
      city: "TREZEVANT",
      state: "TN"
    },
    {
      city: "TRIADELPHIA",
      state: "WV"
    },
    {
      city: "TRIANGLE",
      state: "VA"
    },
    {
      city: "TRIBES HILL",
      state: "NY"
    },
    {
      city: "TRIBUNE",
      state: "KS"
    },
    {
      city: "TRIDELL",
      state: "UT"
    },
    {
      city: "TRILBY",
      state: "FL"
    },
    {
      city: "TRILLA",
      state: "IL"
    },
    {
      city: "TRIMBLE",
      state: "MO"
    },
    {
      city: "TRIMBLE",
      state: "OH"
    },
    {
      city: "TRIMBLE",
      state: "TN"
    },
    {
      city: "TRIMONT",
      state: "MN"
    },
    {
      city: "TRINIDAD",
      state: "CA"
    },
    {
      city: "TRINIDAD",
      state: "CO"
    },
    {
      city: "TRINIDAD",
      state: "TX"
    },
    {
      city: "TRINITY",
      state: "AL"
    },
    {
      city: "TRINITY",
      state: "NC"
    },
    {
      city: "TRINITY",
      state: "TX"
    },
    {
      city: "TRINITY CENTER",
      state: "CA"
    },
    {
      city: "TRINWAY",
      state: "OH"
    },
    {
      city: "TRION",
      state: "GA"
    },
    {
      city: "TRIPLER ARMY MEDICAL CENTER",
      state: "HI"
    },
    {
      city: "TRIPLETT",
      state: "MO"
    },
    {
      city: "TRIPOLI",
      state: "IA"
    },
    {
      city: "TRIPOLI",
      state: "WI"
    },
    {
      city: "TRIPP",
      state: "SD"
    },
    {
      city: "TRIUMPH",
      state: "IL"
    },
    {
      city: "TRIVOLI",
      state: "IL"
    },
    {
      city: "TRONA",
      state: "CA"
    },
    {
      city: "TROPIC",
      state: "UT"
    },
    {
      city: "TROUP",
      state: "TX"
    },
    {
      city: "TROUPSBURG",
      state: "NY"
    },
    {
      city: "TROUT",
      state: "LA"
    },
    {
      city: "TROUT CREEK",
      state: "MI"
    },
    {
      city: "TROUT CREEK",
      state: "MT"
    },
    {
      city: "TROUT CREEK",
      state: "NY"
    },
    {
      city: "TROUT LAKE",
      state: "MI"
    },
    {
      city: "TROUT LAKE",
      state: "WA"
    },
    {
      city: "TROUT RUN",
      state: "PA"
    },
    {
      city: "TROUTDALE",
      state: "OR"
    },
    {
      city: "TROUTDALE",
      state: "VA"
    },
    {
      city: "TROUTMAN",
      state: "NC"
    },
    {
      city: "TROUTVILLE",
      state: "PA"
    },
    {
      city: "TROUTVILLE",
      state: "VA"
    },
    {
      city: "TROY",
      state: "AL"
    },
    {
      city: "TROY",
      state: "ID"
    },
    {
      city: "TROY",
      state: "IL"
    },
    {
      city: "TROY",
      state: "IN"
    },
    {
      city: "TROY",
      state: "KS"
    },
    {
      city: "TROY",
      state: "ME"
    },
    {
      city: "TROY",
      state: "MI"
    },
    {
      city: "TROY",
      state: "MO"
    },
    {
      city: "TROY",
      state: "MT"
    },
    {
      city: "TROY",
      state: "NC"
    },
    {
      city: "TROY",
      state: "NH"
    },
    {
      city: "TROY",
      state: "NY"
    },
    {
      city: "TROY",
      state: "OH"
    },
    {
      city: "TROY",
      state: "PA"
    },
    {
      city: "TROY",
      state: "SC"
    },
    {
      city: "TROY",
      state: "TN"
    },
    {
      city: "TROY",
      state: "TX"
    },
    {
      city: "TROY",
      state: "VA"
    },
    {
      city: "TROY",
      state: "VT"
    },
    {
      city: "TROY",
      state: "WV"
    },
    {
      city: "TROY GROVE",
      state: "IL"
    },
    {
      city: "TROY MILLS",
      state: "IA"
    },
    {
      city: "TRUCHAS",
      state: "NM"
    },
    {
      city: "TRUCKEE",
      state: "CA"
    },
    {
      city: "TRUESDALE",
      state: "MO"
    },
    {
      city: "TRUFANT",
      state: "MI"
    },
    {
      city: "TRUMAN",
      state: "MN"
    },
    {
      city: "TRUMANN",
      state: "AR"
    },
    {
      city: "TRUMANSBURG",
      state: "NY"
    },
    {
      city: "TRUMBAUERSVILLE",
      state: "PA"
    },
    {
      city: "TRUMBULL",
      state: "CT"
    },
    {
      city: "TRUMBULL",
      state: "NE"
    },
    {
      city: "TRURO",
      state: "IA"
    },
    {
      city: "TRURO",
      state: "MA"
    },
    {
      city: "TRUSSVILLE",
      state: "AL"
    },
    {
      city: "TRUTH OR CONSEQUENCES",
      state: "NM"
    },
    {
      city: "TRUXTON",
      state: "MO"
    },
    {
      city: "TRUXTON",
      state: "NY"
    },
    {
      city: "TRYON",
      state: "NC"
    },
    {
      city: "TRYON",
      state: "NE"
    },
    {
      city: "TRYON",
      state: "OK"
    },
    {
      city: "TSAILE",
      state: "AZ"
    },
    {
      city: "TUALATIN",
      state: "OR"
    },
    {
      city: "TUBA CITY",
      state: "AZ"
    },
    {
      city: "TUBAC",
      state: "AZ"
    },
    {
      city: "TUCKAHOE",
      state: "NJ"
    },
    {
      city: "TUCKAHOE",
      state: "NY"
    },
    {
      city: "TUCKASEGEE",
      state: "NC"
    },
    {
      city: "TUCKER",
      state: "AR"
    },
    {
      city: "TUCKER",
      state: "GA"
    },
    {
      city: "TUCKERMAN",
      state: "AR"
    },
    {
      city: "TUCKERTON",
      state: "NJ"
    },
    {
      city: "TUCSON",
      state: "AZ"
    },
    {
      city: "TUCUMCARI",
      state: "NM"
    },
    {
      city: "TUJUNGA",
      state: "CA"
    },
    {
      city: "TULARE",
      state: "CA"
    },
    {
      city: "TULARE",
      state: "SD"
    },
    {
      city: "TULAROSA",
      state: "NM"
    },
    {
      city: "TULELAKE",
      state: "CA"
    },
    {
      city: "TULETA",
      state: "TX"
    },
    {
      city: "TULIA",
      state: "TX"
    },
    {
      city: "TULLAHOMA",
      state: "TN"
    },
    {
      city: "TULLOS",
      state: "LA"
    },
    {
      city: "TULLY",
      state: "NY"
    },
    {
      city: "TULSA",
      state: "OK"
    },
    {
      city: "TULUKSAK",
      state: "AK"
    },
    {
      city: "TUMACACORI",
      state: "AZ"
    },
    {
      city: "TUMBLING SHOALS",
      state: "AR"
    },
    {
      city: "TUMTUM",
      state: "WA"
    },
    {
      city: "TUMWATER",
      state: "WA"
    },
    {
      city: "TUNAS",
      state: "MO"
    },
    {
      city: "TUNBRIDGE",
      state: "VT"
    },
    {
      city: "TUNICA",
      state: "LA"
    },
    {
      city: "TUNICA",
      state: "MS"
    },
    {
      city: "TUNKHANNOCK",
      state: "PA"
    },
    {
      city: "TUNNEL",
      state: "NY"
    },
    {
      city: "TUNNEL HILL",
      state: "GA"
    },
    {
      city: "TUNNELTON",
      state: "IN"
    },
    {
      city: "TUNNELTON",
      state: "WV"
    },
    {
      city: "TUNTUTULIAK",
      state: "AK"
    },
    {
      city: "TUNUNAK",
      state: "AK"
    },
    {
      city: "TUOLUMNE",
      state: "CA"
    },
    {
      city: "TUPELO",
      state: "AR"
    },
    {
      city: "TUPELO",
      state: "MS"
    },
    {
      city: "TUPELO",
      state: "OK"
    },
    {
      city: "TUPMAN",
      state: "CA"
    },
    {
      city: "TUPPER LAKE",
      state: "NY"
    },
    {
      city: "TUPPERS PLAINS",
      state: "OH"
    },
    {
      city: "TURBEVILLE",
      state: "SC"
    },
    {
      city: "TURBOTVILLE",
      state: "PA"
    },
    {
      city: "TURIN",
      state: "GA"
    },
    {
      city: "TURIN",
      state: "NY"
    },
    {
      city: "TURKEY",
      state: "NC"
    },
    {
      city: "TURKEY",
      state: "TX"
    },
    {
      city: "TURKEY CITY",
      state: "PA"
    },
    {
      city: "TURKEY CREEK",
      state: "LA"
    },
    {
      city: "TURLOCK",
      state: "CA"
    },
    {
      city: "TURNER",
      state: "ME"
    },
    {
      city: "TURNER",
      state: "MI"
    },
    {
      city: "TURNER",
      state: "MT"
    },
    {
      city: "TURNER",
      state: "OR"
    },
    {
      city: "TURNERS",
      state: "MO"
    },
    {
      city: "TURNERS FALLS",
      state: "MA"
    },
    {
      city: "TURNERS STATION",
      state: "KY"
    },
    {
      city: "TURNERVILLE",
      state: "GA"
    },
    {
      city: "TURNEY",
      state: "MO"
    },
    {
      city: "TURON",
      state: "KS"
    },
    {
      city: "TURPIN",
      state: "OK"
    },
    {
      city: "TURRELL",
      state: "AR"
    },
    {
      city: "TURTLE CREEK",
      state: "PA"
    },
    {
      city: "TURTLE CREEK",
      state: "WV"
    },
    {
      city: "TURTLE LAKE",
      state: "ND"
    },
    {
      city: "TURTLE LAKE",
      state: "WI"
    },
    {
      city: "TURTLEPOINT",
      state: "PA"
    },
    {
      city: "TURTLETOWN",
      state: "TN"
    },
    {
      city: "TURTON",
      state: "SD"
    },
    {
      city: "TUSCALOOSA",
      state: "AL"
    },
    {
      city: "TUSCARAWAS",
      state: "OH"
    },
    {
      city: "TUSCARORA",
      state: "MD"
    },
    {
      city: "TUSCARORA",
      state: "PA"
    },
    {
      city: "TUSCOLA",
      state: "IL"
    },
    {
      city: "TUSCOLA",
      state: "TX"
    },
    {
      city: "TUSCUMBIA",
      state: "AL"
    },
    {
      city: "TUSCUMBIA",
      state: "MO"
    },
    {
      city: "TUSKAHOMA",
      state: "OK"
    },
    {
      city: "TUSKEGEE",
      state: "AL"
    },
    {
      city: "TUSKEGEE INSTITUTE",
      state: "AL"
    },
    {
      city: "TUSSY",
      state: "OK"
    },
    {
      city: "TUSTIN",
      state: "CA"
    },
    {
      city: "TUSTIN",
      state: "MI"
    },
    {
      city: "TUTHILL",
      state: "SD"
    },
    {
      city: "TUTOR KEY",
      state: "KY"
    },
    {
      city: "TUTTLE",
      state: "ND"
    },
    {
      city: "TUTTLE",
      state: "OK"
    },
    {
      city: "TUTWILER",
      state: "MS"
    },
    {
      city: "TUXEDO",
      state: "NC"
    },
    {
      city: "TUXEDO PARK",
      state: "NY"
    },
    {
      city: "TWAIN",
      state: "CA"
    },
    {
      city: "TWAIN HARTE",
      state: "CA"
    },
    {
      city: "TWELVE MILE",
      state: "IN"
    },
    {
      city: "TWENTYNINE PALMS",
      state: "CA"
    },
    {
      city: "TWIG",
      state: "MN"
    },
    {
      city: "TWIN BRIDGES",
      state: "CA"
    },
    {
      city: "TWIN BRIDGES",
      state: "MT"
    },
    {
      city: "TWIN BROOKS",
      state: "SD"
    },
    {
      city: "TWIN CITY",
      state: "GA"
    },
    {
      city: "TWIN FALLS",
      state: "ID"
    },
    {
      city: "TWIN LAKE",
      state: "MI"
    },
    {
      city: "TWIN LAKES",
      state: "CO"
    },
    {
      city: "TWIN LAKES",
      state: "MN"
    },
    {
      city: "TWIN LAKES",
      state: "WI"
    },
    {
      city: "TWIN MOUNTAIN",
      state: "NH"
    },
    {
      city: "TWIN OAKS",
      state: "OK"
    },
    {
      city: "TWIN PEAKS",
      state: "CA"
    },
    {
      city: "TWIN ROCKS",
      state: "PA"
    },
    {
      city: "TWIN VALLEY",
      state: "MN"
    },
    {
      city: "TWINING",
      state: "MI"
    },
    {
      city: "TWINSBURG",
      state: "OH"
    },
    {
      city: "TWISP",
      state: "WA"
    },
    {
      city: "TWO BUTTES",
      state: "CO"
    },
    {
      city: "TWO DOT",
      state: "MT"
    },
    {
      city: "TWO HARBORS",
      state: "MN"
    },
    {
      city: "TWO RIVERS",
      state: "AK"
    },
    {
      city: "TWO RIVERS",
      state: "WI"
    },
    {
      city: "TY TY",
      state: "GA"
    },
    {
      city: "TYASKIN",
      state: "MD"
    },
    {
      city: "TYBEE ISLAND",
      state: "GA"
    },
    {
      city: "TYE",
      state: "TX"
    },
    {
      city: "TYGH VALLEY",
      state: "OR"
    },
    {
      city: "TYLER",
      state: "AL"
    },
    {
      city: "TYLER",
      state: "MN"
    },
    {
      city: "TYLER",
      state: "TX"
    },
    {
      city: "TYLER HILL",
      state: "PA"
    },
    {
      city: "TYLERSBURG",
      state: "PA"
    },
    {
      city: "TYLERSPORT",
      state: "PA"
    },
    {
      city: "TYLERTON",
      state: "MD"
    },
    {
      city: "TYLERTOWN",
      state: "MS"
    },
    {
      city: "TYNAN",
      state: "TX"
    },
    {
      city: "TYNDALL",
      state: "SD"
    },
    {
      city: "TYNER",
      state: "IN"
    },
    {
      city: "TYNER",
      state: "KY"
    },
    {
      city: "TYNER",
      state: "NC"
    },
    {
      city: "TYNGSBORO",
      state: "MA"
    },
    {
      city: "TYONEK",
      state: "AK"
    },
    {
      city: "TYRINGHAM",
      state: "MA"
    },
    {
      city: "TYRO",
      state: "KS"
    },
    {
      city: "TYRO",
      state: "VA"
    },
    {
      city: "TYRONE",
      state: "GA"
    },
    {
      city: "TYRONE",
      state: "NM"
    },
    {
      city: "TYRONE",
      state: "NY"
    },
    {
      city: "TYRONE",
      state: "OK"
    },
    {
      city: "TYRONE",
      state: "PA"
    },
    {
      city: "TYRONZA",
      state: "AR"
    },
    {
      city: "UBLY",
      state: "MI"
    },
    {
      city: "UCON",
      state: "ID"
    },
    {
      city: "UDALL",
      state: "KS"
    },
    {
      city: "UDALL",
      state: "MO"
    },
    {
      city: "UEHLING",
      state: "NE"
    },
    {
      city: "UHRICHSVILLE",
      state: "OH"
    },
    {
      city: "UKIAH",
      state: "CA"
    },
    {
      city: "UKIAH",
      state: "OR"
    },
    {
      city: "ULEDI",
      state: "PA"
    },
    {
      city: "ULEN",
      state: "MN"
    },
    {
      city: "ULLIN",
      state: "IL"
    },
    {
      city: "ULM",
      state: "AR"
    },
    {
      city: "ULM",
      state: "MT"
    },
    {
      city: "ULMAN",
      state: "MO"
    },
    {
      city: "ULMER",
      state: "SC"
    },
    {
      city: "ULSTER",
      state: "PA"
    },
    {
      city: "ULSTER PARK",
      state: "NY"
    },
    {
      city: "ULYSSES",
      state: "KS"
    },
    {
      city: "ULYSSES",
      state: "KY"
    },
    {
      city: "ULYSSES",
      state: "NE"
    },
    {
      city: "ULYSSES",
      state: "PA"
    },
    {
      city: "UMATILLA",
      state: "FL"
    },
    {
      city: "UMATILLA",
      state: "OR"
    },
    {
      city: "UMBARGER",
      state: "TX"
    },
    {
      city: "UMPIRE",
      state: "AR"
    },
    {
      city: "UMPQUA",
      state: "OR"
    },
    {
      city: "UNA",
      state: "SC"
    },
    {
      city: "UNADILLA",
      state: "GA"
    },
    {
      city: "UNADILLA",
      state: "NE"
    },
    {
      city: "UNADILLA",
      state: "NY"
    },
    {
      city: "UNALAKLEET",
      state: "AK"
    },
    {
      city: "UNALASKA",
      state: "AK"
    },
    {
      city: "UNCASVILLE",
      state: "CT"
    },
    {
      city: "UNCLE SAM",
      state: "LA"
    },
    {
      city: "UNDERHILL",
      state: "VT"
    },
    {
      city: "UNDERHILL CENTER",
      state: "VT"
    },
    {
      city: "UNDERWOOD",
      state: "IA"
    },
    {
      city: "UNDERWOOD",
      state: "IN"
    },
    {
      city: "UNDERWOOD",
      state: "MN"
    },
    {
      city: "UNDERWOOD",
      state: "ND"
    },
    {
      city: "UNDERWOOD",
      state: "WA"
    },
    {
      city: "UNEEDA",
      state: "WV"
    },
    {
      city: "UNICOI",
      state: "TN"
    },
    {
      city: "UNION",
      state: "IA"
    },
    {
      city: "UNION",
      state: "IL"
    },
    {
      city: "UNION",
      state: "KY"
    },
    {
      city: "UNION",
      state: "ME"
    },
    {
      city: "UNION",
      state: "MI"
    },
    {
      city: "UNION",
      state: "MO"
    },
    {
      city: "UNION",
      state: "MS"
    },
    {
      city: "UNION",
      state: "NE"
    },
    {
      city: "UNION",
      state: "NH"
    },
    {
      city: "UNION",
      state: "NJ"
    },
    {
      city: "UNION",
      state: "OR"
    },
    {
      city: "UNION",
      state: "SC"
    },
    {
      city: "UNION",
      state: "WA"
    },
    {
      city: "UNION",
      state: "WV"
    },
    {
      city: "UNION BRIDGE",
      state: "MD"
    },
    {
      city: "UNION CENTER",
      state: "SD"
    },
    {
      city: "UNION CENTER",
      state: "WI"
    },
    {
      city: "UNION CHURCH",
      state: "MS"
    },
    {
      city: "UNION CITY",
      state: "CA"
    },
    {
      city: "UNION CITY",
      state: "GA"
    },
    {
      city: "UNION CITY",
      state: "IN"
    },
    {
      city: "UNION CITY",
      state: "MI"
    },
    {
      city: "UNION CITY",
      state: "NJ"
    },
    {
      city: "UNION CITY",
      state: "OH"
    },
    {
      city: "UNION CITY",
      state: "OK"
    },
    {
      city: "UNION CITY",
      state: "PA"
    },
    {
      city: "UNION CITY",
      state: "TN"
    },
    {
      city: "UNION DALE",
      state: "PA"
    },
    {
      city: "UNION FURNACE",
      state: "OH"
    },
    {
      city: "UNION GROVE",
      state: "AL"
    },
    {
      city: "UNION GROVE",
      state: "NC"
    },
    {
      city: "UNION GROVE",
      state: "WI"
    },
    {
      city: "UNION HALL",
      state: "VA"
    },
    {
      city: "UNION HILL",
      state: "IL"
    },
    {
      city: "UNION HILL",
      state: "NY"
    },
    {
      city: "UNION LAKE",
      state: "MI"
    },
    {
      city: "UNION MILLS",
      state: "IN"
    },
    {
      city: "UNION MILLS",
      state: "NC"
    },
    {
      city: "UNION PIER",
      state: "MI"
    },
    {
      city: "UNION POINT",
      state: "GA"
    },
    {
      city: "UNION SPRINGS",
      state: "AL"
    },
    {
      city: "UNION SPRINGS",
      state: "NY"
    },
    {
      city: "UNION STAR",
      state: "KY"
    },
    {
      city: "UNION STAR",
      state: "MO"
    },
    {
      city: "UNIONDALE",
      state: "IN"
    },
    {
      city: "UNIONDALE",
      state: "NY"
    },
    {
      city: "UNIONTOWN",
      state: "AL"
    },
    {
      city: "UNIONTOWN",
      state: "AR"
    },
    {
      city: "UNIONTOWN",
      state: "KS"
    },
    {
      city: "UNIONTOWN",
      state: "KY"
    },
    {
      city: "UNIONTOWN",
      state: "MO"
    },
    {
      city: "UNIONTOWN",
      state: "OH"
    },
    {
      city: "UNIONTOWN",
      state: "PA"
    },
    {
      city: "UNIONTOWN",
      state: "WA"
    },
    {
      city: "UNIONVILLE",
      state: "CT"
    },
    {
      city: "UNIONVILLE",
      state: "IA"
    },
    {
      city: "UNIONVILLE",
      state: "IN"
    },
    {
      city: "UNIONVILLE",
      state: "MD"
    },
    {
      city: "UNIONVILLE",
      state: "MI"
    },
    {
      city: "UNIONVILLE",
      state: "MO"
    },
    {
      city: "UNIONVILLE",
      state: "NY"
    },
    {
      city: "UNIONVILLE",
      state: "OH"
    },
    {
      city: "UNIONVILLE",
      state: "PA"
    },
    {
      city: "UNIONVILLE",
      state: "TN"
    },
    {
      city: "UNIONVILLE",
      state: "VA"
    },
    {
      city: "UNIONVILLE CENTER",
      state: "OH"
    },
    {
      city: "UNIOPOLIS",
      state: "OH"
    },
    {
      city: "UNITED",
      state: "PA"
    },
    {
      city: "UNITY",
      state: "ME"
    },
    {
      city: "UNITY",
      state: "OR"
    },
    {
      city: "UNITY",
      state: "WI"
    },
    {
      city: "UNITYVILLE",
      state: "PA"
    },
    {
      city: "UNIVERSAL",
      state: "IN"
    },
    {
      city: "UNIVERSAL CITY",
      state: "CA"
    },
    {
      city: "UNIVERSAL CITY",
      state: "TX"
    },
    {
      city: "UNIVERSITY",
      state: "MS"
    },
    {
      city: "UNIVERSITY CENTER",
      state: "MI"
    },
    {
      city: "UNIVERSITY PARK",
      state: "IA"
    },
    {
      city: "UNIVERSITY PARK",
      state: "IL"
    },
    {
      city: "UNIVERSITY PARK",
      state: "PA"
    },
    {
      city: "UNIVERSITY PLACE",
      state: "WA"
    },
    {
      city: "UPATOI",
      state: "GA"
    },
    {
      city: "UPHAM",
      state: "ND"
    },
    {
      city: "UPLAND",
      state: "CA"
    },
    {
      city: "UPLAND",
      state: "IN"
    },
    {
      city: "UPLAND",
      state: "NE"
    },
    {
      city: "UPPER BLACK EDDY",
      state: "PA"
    },
    {
      city: "UPPER DARBY",
      state: "PA"
    },
    {
      city: "UPPER FAIRMOUNT",
      state: "MD"
    },
    {
      city: "UPPER FALLS",
      state: "MD"
    },
    {
      city: "UPPER JAY",
      state: "NY"
    },
    {
      city: "UPPER LAKE",
      state: "CA"
    },
    {
      city: "UPPER MARLBORO",
      state: "MD"
    },
    {
      city: "UPPER SANDUSKY",
      state: "OH"
    },
    {
      city: "UPPER TRACT",
      state: "WV"
    },
    {
      city: "UPPERCO",
      state: "MD"
    },
    {
      city: "UPPERGLADE",
      state: "WV"
    },
    {
      city: "UPPERSTRASBURG",
      state: "PA"
    },
    {
      city: "UPPERVILLE",
      state: "VA"
    },
    {
      city: "UPSALA",
      state: "MN"
    },
    {
      city: "UPSON",
      state: "WI"
    },
    {
      city: "UPTON",
      state: "KY"
    },
    {
      city: "UPTON",
      state: "MA"
    },
    {
      city: "UPTON",
      state: "NY"
    },
    {
      city: "UPTON",
      state: "WY"
    },
    {
      city: "URANIA",
      state: "LA"
    },
    {
      city: "URBANA",
      state: "IA"
    },
    {
      city: "URBANA",
      state: "IL"
    },
    {
      city: "URBANA",
      state: "IN"
    },
    {
      city: "URBANA",
      state: "MO"
    },
    {
      city: "URBANA",
      state: "OH"
    },
    {
      city: "URBANDALE",
      state: "IA"
    },
    {
      city: "URBANNA",
      state: "VA"
    },
    {
      city: "URIAH",
      state: "AL"
    },
    {
      city: "URICH",
      state: "MO"
    },
    {
      city: "URSA",
      state: "IL"
    },
    {
      city: "URSINA",
      state: "PA"
    },
    {
      city: "USAF ACADEMY",
      state: "CO"
    },
    {
      city: "USK",
      state: "WA"
    },
    {
      city: "UTE",
      state: "IA"
    },
    {
      city: "UTE PARK",
      state: "NM"
    },
    {
      city: "UTICA",
      state: "IL"
    },
    {
      city: "UTICA",
      state: "KS"
    },
    {
      city: "UTICA",
      state: "KY"
    },
    {
      city: "UTICA",
      state: "MI"
    },
    {
      city: "UTICA",
      state: "MN"
    },
    {
      city: "UTICA",
      state: "MS"
    },
    {
      city: "UTICA",
      state: "NE"
    },
    {
      city: "UTICA",
      state: "NY"
    },
    {
      city: "UTICA",
      state: "OH"
    },
    {
      city: "UTICA",
      state: "PA"
    },
    {
      city: "UTICA",
      state: "SD"
    },
    {
      city: "UTOPIA",
      state: "TX"
    },
    {
      city: "UVALDA",
      state: "GA"
    },
    {
      city: "UVALDE",
      state: "TX"
    },
    {
      city: "UWCHLAND",
      state: "PA"
    },
    {
      city: "UXBRIDGE",
      state: "MA"
    },
    {
      city: "VACAVILLE",
      state: "CA"
    },
    {
      city: "VACHERIE",
      state: "LA"
    },
    {
      city: "VADER",
      state: "WA"
    },
    {
      city: "VADITO",
      state: "NM"
    },
    {
      city: "VADO",
      state: "NM"
    },
    {
      city: "VAIDEN",
      state: "MS"
    },
    {
      city: "VAIL",
      state: "AZ"
    },
    {
      city: "VAIL",
      state: "CO"
    },
    {
      city: "VAIL",
      state: "IA"
    },
    {
      city: "VAILS GATE",
      state: "NY"
    },
    {
      city: "VALATIE",
      state: "NY"
    },
    {
      city: "VALDERS",
      state: "WI"
    },
    {
      city: "VALDESE",
      state: "NC"
    },
    {
      city: "VALDEZ",
      state: "AK"
    },
    {
      city: "VALDEZ",
      state: "NM"
    },
    {
      city: "VALDOSTA",
      state: "GA"
    },
    {
      city: "VALE",
      state: "NC"
    },
    {
      city: "VALE",
      state: "OR"
    },
    {
      city: "VALE",
      state: "SD"
    },
    {
      city: "VALENCIA",
      state: "CA"
    },
    {
      city: "VALENCIA",
      state: "PA"
    },
    {
      city: "VALENTINE",
      state: "AZ"
    },
    {
      city: "VALENTINE",
      state: "NE"
    },
    {
      city: "VALENTINE",
      state: "TX"
    },
    {
      city: "VALENTINES",
      state: "VA"
    },
    {
      city: "VALERA",
      state: "TX"
    },
    {
      city: "VALHALLA",
      state: "NY"
    },
    {
      city: "VALHERMOSO SPRINGS",
      state: "AL"
    },
    {
      city: "VALIER",
      state: "IL"
    },
    {
      city: "VALIER",
      state: "MT"
    },
    {
      city: "VALIER",
      state: "PA"
    },
    {
      city: "VALLE CRUCIS",
      state: "NC"
    },
    {
      city: "VALLECITO",
      state: "CA"
    },
    {
      city: "VALLECITOS",
      state: "NM"
    },
    {
      city: "VALLEJO",
      state: "CA"
    },
    {
      city: "VALLES MINES",
      state: "MO"
    },
    {
      city: "VALLEY",
      state: "AL"
    },
    {
      city: "VALLEY",
      state: "NE"
    },
    {
      city: "VALLEY",
      state: "WA"
    },
    {
      city: "VALLEY BEND",
      state: "WV"
    },
    {
      city: "VALLEY CENTER",
      state: "CA"
    },
    {
      city: "VALLEY CENTER",
      state: "KS"
    },
    {
      city: "VALLEY CITY",
      state: "ND"
    },
    {
      city: "VALLEY CITY",
      state: "OH"
    },
    {
      city: "VALLEY COTTAGE",
      state: "NY"
    },
    {
      city: "VALLEY FALLS",
      state: "KS"
    },
    {
      city: "VALLEY FALLS",
      state: "NY"
    },
    {
      city: "VALLEY FARMS",
      state: "AZ"
    },
    {
      city: "VALLEY FORD",
      state: "CA"
    },
    {
      city: "VALLEY FORGE",
      state: "PA"
    },
    {
      city: "VALLEY GROVE",
      state: "WV"
    },
    {
      city: "VALLEY HEAD",
      state: "AL"
    },
    {
      city: "VALLEY HEAD",
      state: "WV"
    },
    {
      city: "VALLEY LEE",
      state: "MD"
    },
    {
      city: "VALLEY MILLS",
      state: "TX"
    },
    {
      city: "VALLEY PARK",
      state: "MO"
    },
    {
      city: "VALLEY PARK",
      state: "MS"
    },
    {
      city: "VALLEY SPRINGS",
      state: "AR"
    },
    {
      city: "VALLEY SPRINGS",
      state: "CA"
    },
    {
      city: "VALLEY SPRINGS",
      state: "SD"
    },
    {
      city: "VALLEY STREAM",
      state: "NY"
    },
    {
      city: "VALLEY VIEW",
      state: "PA"
    },
    {
      city: "VALLEY VIEW",
      state: "TX"
    },
    {
      city: "VALLEY VILLAGE",
      state: "CA"
    },
    {
      city: "VALLEYFORD",
      state: "WA"
    },
    {
      city: "VALLIANT",
      state: "OK"
    },
    {
      city: "VALLONIA",
      state: "IN"
    },
    {
      city: "VALMEYER",
      state: "IL"
    },
    {
      city: "VALMY",
      state: "NV"
    },
    {
      city: "VALPARAISO",
      state: "FL"
    },
    {
      city: "VALPARAISO",
      state: "IN"
    },
    {
      city: "VALPARAISO",
      state: "NE"
    },
    {
      city: "VALRICO",
      state: "FL"
    },
    {
      city: "VALYERMO",
      state: "CA"
    },
    {
      city: "VAN",
      state: "TX"
    },
    {
      city: "VAN",
      state: "WV"
    },
    {
      city: "VAN ALSTYNE",
      state: "TX"
    },
    {
      city: "VAN BUREN",
      state: "AR"
    },
    {
      city: "VAN BUREN",
      state: "IN"
    },
    {
      city: "VAN BUREN",
      state: "ME"
    },
    {
      city: "VAN BUREN",
      state: "MO"
    },
    {
      city: "VAN BUREN",
      state: "OH"
    },
    {
      city: "VAN BUREN POINT",
      state: "NY"
    },
    {
      city: "VAN DYNE",
      state: "WI"
    },
    {
      city: "VAN ETTEN",
      state: "NY"
    },
    {
      city: "VAN HORN",
      state: "TX"
    },
    {
      city: "VAN HORNE",
      state: "IA"
    },
    {
      city: "VAN HORNESVILLE",
      state: "NY"
    },
    {
      city: "VAN LEAR",
      state: "KY"
    },
    {
      city: "VAN METER",
      state: "IA"
    },
    {
      city: "VAN NUYS",
      state: "CA"
    },
    {
      city: "VAN ORIN",
      state: "IL"
    },
    {
      city: "VAN TASSELL",
      state: "WY"
    },
    {
      city: "VAN VLECK",
      state: "TX"
    },
    {
      city: "VAN VLEET",
      state: "MS"
    },
    {
      city: "VAN VOORHIS",
      state: "PA"
    },
    {
      city: "VAN WERT",
      state: "IA"
    },
    {
      city: "VAN WERT",
      state: "OH"
    },
    {
      city: "VAN WYCK",
      state: "SC"
    },
    {
      city: "VANCE",
      state: "AL"
    },
    {
      city: "VANCE",
      state: "MS"
    },
    {
      city: "VANCE",
      state: "SC"
    },
    {
      city: "VANCEBORO",
      state: "ME"
    },
    {
      city: "VANCEBORO",
      state: "NC"
    },
    {
      city: "VANCEBURG",
      state: "KY"
    },
    {
      city: "VANCLEAVE",
      state: "MS"
    },
    {
      city: "VANCLEVE",
      state: "KY"
    },
    {
      city: "VANCOURT",
      state: "TX"
    },
    {
      city: "VANCOUVER",
      state: "WA"
    },
    {
      city: "VANDALIA",
      state: "IL"
    },
    {
      city: "VANDALIA",
      state: "MI"
    },
    {
      city: "VANDALIA",
      state: "MO"
    },
    {
      city: "VANDALIA",
      state: "MT"
    },
    {
      city: "VANDALIA",
      state: "OH"
    },
    {
      city: "VANDEMERE",
      state: "NC"
    },
    {
      city: "VANDERBILT",
      state: "MI"
    },
    {
      city: "VANDERBILT",
      state: "PA"
    },
    {
      city: "VANDERBILT",
      state: "TX"
    },
    {
      city: "VANDERGRIFT",
      state: "PA"
    },
    {
      city: "VANDERPOOL",
      state: "TX"
    },
    {
      city: "VANDERVOORT",
      state: "AR"
    },
    {
      city: "VANDERWAGEN",
      state: "NM"
    },
    {
      city: "VANDIVER",
      state: "AL"
    },
    {
      city: "VANDUSER",
      state: "MO"
    },
    {
      city: "VANLEER",
      state: "TN"
    },
    {
      city: "VANLUE",
      state: "OH"
    },
    {
      city: "VANNDALE",
      state: "AR"
    },
    {
      city: "VANSANT",
      state: "VA"
    },
    {
      city: "VANTAGE",
      state: "WA"
    },
    {
      city: "VANZANT",
      state: "MO"
    },
    {
      city: "VARDAMAN",
      state: "MS"
    },
    {
      city: "VARINA",
      state: "IA"
    },
    {
      city: "VARNA",
      state: "IL"
    },
    {
      city: "VARNADO",
      state: "LA"
    },
    {
      city: "VARNELL",
      state: "GA"
    },
    {
      city: "VARNEY",
      state: "KY"
    },
    {
      city: "VARNEY",
      state: "WV"
    },
    {
      city: "VARNVILLE",
      state: "SC"
    },
    {
      city: "VARYSBURG",
      state: "NY"
    },
    {
      city: "VASHON",
      state: "WA"
    },
    {
      city: "VASS",
      state: "NC"
    },
    {
      city: "VASSALBORO",
      state: "ME"
    },
    {
      city: "VASSAR",
      state: "KS"
    },
    {
      city: "VASSAR",
      state: "MI"
    },
    {
      city: "VAUGHAN",
      state: "MS"
    },
    {
      city: "VAUGHAN",
      state: "NC"
    },
    {
      city: "VAUGHN",
      state: "MT"
    },
    {
      city: "VAUGHN",
      state: "NM"
    },
    {
      city: "VAUGHN",
      state: "WA"
    },
    {
      city: "VAUGHNSVILLE",
      state: "OH"
    },
    {
      city: "VAUXHALL",
      state: "NJ"
    },
    {
      city: "VEBLEN",
      state: "SD"
    },
    {
      city: "VEEDERSBURG",
      state: "IN"
    },
    {
      city: "VEGA",
      state: "TX"
    },
    {
      city: "VEGUITA",
      state: "NM"
    },
    {
      city: "VELARDE",
      state: "NM"
    },
    {
      city: "VELMA",
      state: "OK"
    },
    {
      city: "VELPEN",
      state: "IN"
    },
    {
      city: "VELVA",
      state: "ND"
    },
    {
      city: "VENANGO",
      state: "NE"
    },
    {
      city: "VENANGO",
      state: "PA"
    },
    {
      city: "VENEDOCIA",
      state: "OH"
    },
    {
      city: "VENETA",
      state: "OR"
    },
    {
      city: "VENETIA",
      state: "PA"
    },
    {
      city: "VENETIE",
      state: "AK"
    },
    {
      city: "VENICE",
      state: "CA"
    },
    {
      city: "VENICE",
      state: "FL"
    },
    {
      city: "VENICE",
      state: "IL"
    },
    {
      city: "VENICE",
      state: "LA"
    },
    {
      city: "VENTNOR CITY",
      state: "NJ"
    },
    {
      city: "VENTRESS",
      state: "LA"
    },
    {
      city: "VENTURA",
      state: "CA"
    },
    {
      city: "VENTURA",
      state: "IA"
    },
    {
      city: "VENUS",
      state: "FL"
    },
    {
      city: "VENUS",
      state: "PA"
    },
    {
      city: "VENUS",
      state: "TX"
    },
    {
      city: "VERA",
      state: "OK"
    },
    {
      city: "VERADALE",
      state: "WA"
    },
    {
      city: "VERBANK",
      state: "NY"
    },
    {
      city: "VERBENA",
      state: "AL"
    },
    {
      city: "VERDEN",
      state: "OK"
    },
    {
      city: "VERDI",
      state: "NV"
    },
    {
      city: "VERDIGRE",
      state: "NE"
    },
    {
      city: "VERDON",
      state: "NE"
    },
    {
      city: "VERDUGO CITY",
      state: "CA"
    },
    {
      city: "VERDUNVILLE",
      state: "WV"
    },
    {
      city: "VERGAS",
      state: "MN"
    },
    {
      city: "VERGENNES",
      state: "IL"
    },
    {
      city: "VERGENNES",
      state: "VT"
    },
    {
      city: "VERIBEST",
      state: "TX"
    },
    {
      city: "VERMILION",
      state: "IL"
    },
    {
      city: "VERMILION",
      state: "OH"
    },
    {
      city: "VERMILLION",
      state: "KS"
    },
    {
      city: "VERMILLION",
      state: "MN"
    },
    {
      city: "VERMILLION",
      state: "SD"
    },
    {
      city: "VERMONT",
      state: "IL"
    },
    {
      city: "VERMONTVILLE",
      state: "MI"
    },
    {
      city: "VERMONTVILLE",
      state: "NY"
    },
    {
      city: "VERNAL",
      state: "UT"
    },
    {
      city: "VERNALIS",
      state: "CA"
    },
    {
      city: "VERNDALE",
      state: "MN"
    },
    {
      city: "VERNER",
      state: "WV"
    },
    {
      city: "VERNON",
      state: "AL"
    },
    {
      city: "VERNON",
      state: "AZ"
    },
    {
      city: "VERNON",
      state: "CO"
    },
    {
      city: "VERNON",
      state: "FL"
    },
    {
      city: "VERNON",
      state: "IL"
    },
    {
      city: "VERNON",
      state: "IN"
    },
    {
      city: "VERNON",
      state: "MI"
    },
    {
      city: "VERNON",
      state: "NJ"
    },
    {
      city: "VERNON",
      state: "NY"
    },
    {
      city: "VERNON",
      state: "TX"
    },
    {
      city: "VERNON",
      state: "UT"
    },
    {
      city: "VERNON",
      state: "VT"
    },
    {
      city: "VERNON CENTER",
      state: "MN"
    },
    {
      city: "VERNON CENTER",
      state: "NY"
    },
    {
      city: "VERNON HILL",
      state: "VA"
    },
    {
      city: "VERNON HILLS",
      state: "IL"
    },
    {
      city: "VERNON ROCKVILLE",
      state: "CT"
    },
    {
      city: "VERNONIA",
      state: "OR"
    },
    {
      city: "VERO BEACH",
      state: "FL"
    },
    {
      city: "VERONA",
      state: "IL"
    },
    {
      city: "VERONA",
      state: "KY"
    },
    {
      city: "VERONA",
      state: "MO"
    },
    {
      city: "VERONA",
      state: "MS"
    },
    {
      city: "VERONA",
      state: "ND"
    },
    {
      city: "VERONA",
      state: "NJ"
    },
    {
      city: "VERONA",
      state: "NY"
    },
    {
      city: "VERONA",
      state: "OH"
    },
    {
      city: "VERONA",
      state: "PA"
    },
    {
      city: "VERONA",
      state: "VA"
    },
    {
      city: "VERONA",
      state: "WI"
    },
    {
      city: "VERONA BEACH",
      state: "NY"
    },
    {
      city: "VERPLANCK",
      state: "NY"
    },
    {
      city: "VERSAILLES",
      state: "CT"
    },
    {
      city: "VERSAILLES",
      state: "IL"
    },
    {
      city: "VERSAILLES",
      state: "IN"
    },
    {
      city: "VERSAILLES",
      state: "KY"
    },
    {
      city: "VERSAILLES",
      state: "MO"
    },
    {
      city: "VERSAILLES",
      state: "NY"
    },
    {
      city: "VERSAILLES",
      state: "OH"
    },
    {
      city: "VERSHIRE",
      state: "VT"
    },
    {
      city: "VESPER",
      state: "WI"
    },
    {
      city: "VEST",
      state: "KY"
    },
    {
      city: "VESTA",
      state: "MN"
    },
    {
      city: "VESTA",
      state: "VA"
    },
    {
      city: "VESTABURG",
      state: "MI"
    },
    {
      city: "VESTABURG",
      state: "PA"
    },
    {
      city: "VESTAL",
      state: "NY"
    },
    {
      city: "VESUVIUS",
      state: "VA"
    },
    {
      city: "VETERAN",
      state: "WY"
    },
    {
      city: "VEVAY",
      state: "IN"
    },
    {
      city: "VEYO",
      state: "UT"
    },
    {
      city: "VIAN",
      state: "OK"
    },
    {
      city: "VIBORG",
      state: "SD"
    },
    {
      city: "VIBURNUM",
      state: "MO"
    },
    {
      city: "VICCO",
      state: "KY"
    },
    {
      city: "VICHY",
      state: "MO"
    },
    {
      city: "VICI",
      state: "OK"
    },
    {
      city: "VICKERY",
      state: "OH"
    },
    {
      city: "VICKSBURG",
      state: "MI"
    },
    {
      city: "VICKSBURG",
      state: "MS"
    },
    {
      city: "VICKSBURG",
      state: "PA"
    },
    {
      city: "VICTOR",
      state: "CA"
    },
    {
      city: "VICTOR",
      state: "CO"
    },
    {
      city: "VICTOR",
      state: "IA"
    },
    {
      city: "VICTOR",
      state: "ID"
    },
    {
      city: "VICTOR",
      state: "MT"
    },
    {
      city: "VICTOR",
      state: "NY"
    },
    {
      city: "VICTOR",
      state: "WV"
    },
    {
      city: "VICTORIA",
      state: "IL"
    },
    {
      city: "VICTORIA",
      state: "KS"
    },
    {
      city: "VICTORIA",
      state: "MN"
    },
    {
      city: "VICTORIA",
      state: "MS"
    },
    {
      city: "VICTORIA",
      state: "TX"
    },
    {
      city: "VICTORIA",
      state: "VA"
    },
    {
      city: "VICTORVILLE",
      state: "CA"
    },
    {
      city: "VICTORY MILLS",
      state: "NY"
    },
    {
      city: "VIDA",
      state: "MT"
    },
    {
      city: "VIDA",
      state: "OR"
    },
    {
      city: "VIDAL",
      state: "CA"
    },
    {
      city: "VIDALIA",
      state: "GA"
    },
    {
      city: "VIDALIA",
      state: "LA"
    },
    {
      city: "VIDOR",
      state: "TX"
    },
    {
      city: "VIENNA",
      state: "GA"
    },
    {
      city: "VIENNA",
      state: "IL"
    },
    {
      city: "VIENNA",
      state: "MD"
    },
    {
      city: "VIENNA",
      state: "ME"
    },
    {
      city: "VIENNA",
      state: "MO"
    },
    {
      city: "VIENNA",
      state: "NJ"
    },
    {
      city: "VIENNA",
      state: "OH"
    },
    {
      city: "VIENNA",
      state: "SD"
    },
    {
      city: "VIENNA",
      state: "VA"
    },
    {
      city: "VIENNA",
      state: "WV"
    },
    {
      city: "VIEWTOWN",
      state: "VA"
    },
    {
      city: "VIKING",
      state: "MN"
    },
    {
      city: "VILAS",
      state: "CO"
    },
    {
      city: "VILAS",
      state: "NC"
    },
    {
      city: "VILLA GRANDE",
      state: "CA"
    },
    {
      city: "VILLA GROVE",
      state: "CO"
    },
    {
      city: "VILLA GROVE",
      state: "IL"
    },
    {
      city: "VILLA MARIA",
      state: "PA"
    },
    {
      city: "VILLA PARK",
      state: "CA"
    },
    {
      city: "VILLA PARK",
      state: "IL"
    },
    {
      city: "VILLA RICA",
      state: "GA"
    },
    {
      city: "VILLA RIDGE",
      state: "IL"
    },
    {
      city: "VILLA RIDGE",
      state: "MO"
    },
    {
      city: "VILLAGE",
      state: "VA"
    },
    {
      city: "VILLAGE MILLS",
      state: "TX"
    },
    {
      city: "VILLAGE OF NAGOG WOODS",
      state: "MA"
    },
    {
      city: "VILLANOVA",
      state: "PA"
    },
    {
      city: "VILLANUEVA",
      state: "NM"
    },
    {
      city: "VILLARD",
      state: "MN"
    },
    {
      city: "VILLAS",
      state: "NJ"
    },
    {
      city: "VILLE PLATTE",
      state: "LA"
    },
    {
      city: "VILLISCA",
      state: "IA"
    },
    {
      city: "VILONIA",
      state: "AR"
    },
    {
      city: "VINA",
      state: "AL"
    },
    {
      city: "VINA",
      state: "CA"
    },
    {
      city: "VINALHAVEN",
      state: "ME"
    },
    {
      city: "VINCENNES",
      state: "IN"
    },
    {
      city: "VINCENT",
      state: "AL"
    },
    {
      city: "VINCENT",
      state: "IA"
    },
    {
      city: "VINCENT",
      state: "KY"
    },
    {
      city: "VINCENT",
      state: "OH"
    },
    {
      city: "VINCENTOWN",
      state: "NJ"
    },
    {
      city: "VINE GROVE",
      state: "KY"
    },
    {
      city: "VINEBURG",
      state: "CA"
    },
    {
      city: "VINEGAR BEND",
      state: "AL"
    },
    {
      city: "VINELAND",
      state: "NJ"
    },
    {
      city: "VINEMONT",
      state: "AL"
    },
    {
      city: "VINEYARD HAVEN",
      state: "MA"
    },
    {
      city: "VINING",
      state: "MN"
    },
    {
      city: "VINITA",
      state: "OK"
    },
    {
      city: "VINSON",
      state: "OK"
    },
    {
      city: "VINTON",
      state: "CA"
    },
    {
      city: "VINTON",
      state: "IA"
    },
    {
      city: "VINTON",
      state: "LA"
    },
    {
      city: "VINTON",
      state: "OH"
    },
    {
      city: "VINTON",
      state: "VA"
    },
    {
      city: "VINTONDALE",
      state: "PA"
    },
    {
      city: "VIOLA",
      state: "AR"
    },
    {
      city: "VIOLA",
      state: "DE"
    },
    {
      city: "VIOLA",
      state: "ID"
    },
    {
      city: "VIOLA",
      state: "IL"
    },
    {
      city: "VIOLA",
      state: "KS"
    },
    {
      city: "VIOLA",
      state: "TN"
    },
    {
      city: "VIOLA",
      state: "WI"
    },
    {
      city: "VIOLET",
      state: "LA"
    },
    {
      city: "VIOLET HILL",
      state: "AR"
    },
    {
      city: "VIPER",
      state: "KY"
    },
    {
      city: "VIRDEN",
      state: "IL"
    },
    {
      city: "VIRGIE",
      state: "KY"
    },
    {
      city: "VIRGIL",
      state: "KS"
    },
    {
      city: "VIRGIL",
      state: "SD"
    },
    {
      city: "VIRGILINA",
      state: "VA"
    },
    {
      city: "VIRGIN",
      state: "UT"
    },
    {
      city: "VIRGINIA",
      state: "IL"
    },
    {
      city: "VIRGINIA",
      state: "MN"
    },
    {
      city: "VIRGINIA",
      state: "NE"
    },
    {
      city: "VIRGINIA BEACH",
      state: "VA"
    },
    {
      city: "VIRGINIA CITY",
      state: "MT"
    },
    {
      city: "VIRGINIA CITY",
      state: "NV"
    },
    {
      city: "VIRGINIA STATE UNIVERSITY",
      state: "VA"
    },
    {
      city: "VIRGINVILLE",
      state: "PA"
    },
    {
      city: "VIROQUA",
      state: "WI"
    },
    {
      city: "VISALIA",
      state: "CA"
    },
    {
      city: "VISTA",
      state: "CA"
    },
    {
      city: "VIVIAN",
      state: "LA"
    },
    {
      city: "VIVIAN",
      state: "SD"
    },
    {
      city: "VOCA",
      state: "TX"
    },
    {
      city: "VOLANT",
      state: "PA"
    },
    {
      city: "VOLBORG",
      state: "MT"
    },
    {
      city: "VOLCANO",
      state: "CA"
    },
    {
      city: "VOLCANO",
      state: "HI"
    },
    {
      city: "VOLGA",
      state: "IA"
    },
    {
      city: "VOLGA",
      state: "SD"
    },
    {
      city: "VOLGA",
      state: "WV"
    },
    {
      city: "VOLIN",
      state: "SD"
    },
    {
      city: "VOLTAIRE",
      state: "ND"
    },
    {
      city: "VOLUNTOWN",
      state: "CT"
    },
    {
      city: "VON ORMY",
      state: "TX"
    },
    {
      city: "VONA",
      state: "CO"
    },
    {
      city: "VONORE",
      state: "TN"
    },
    {
      city: "VOORHEES",
      state: "NJ"
    },
    {
      city: "VOORHEESVILLE",
      state: "NY"
    },
    {
      city: "VOSS",
      state: "TX"
    },
    {
      city: "VOSSBURG",
      state: "MS"
    },
    {
      city: "VOTAW",
      state: "TX"
    },
    {
      city: "VOWINCKEL",
      state: "PA"
    },
    {
      city: "VREDENBURGH",
      state: "AL"
    },
    {
      city: "VULCAN",
      state: "MI"
    },
    {
      city: "VULCAN",
      state: "MO"
    },
    {
      city: "WABAN",
      state: "MA"
    },
    {
      city: "WABASH",
      state: "AR"
    },
    {
      city: "WABASH",
      state: "IN"
    },
    {
      city: "WABASHA",
      state: "MN"
    },
    {
      city: "WABASSO",
      state: "FL"
    },
    {
      city: "WABASSO",
      state: "MN"
    },
    {
      city: "WABENO",
      state: "WI"
    },
    {
      city: "WACCABUC",
      state: "NY"
    },
    {
      city: "WACHAPREAGUE",
      state: "VA"
    },
    {
      city: "WACISSA",
      state: "FL"
    },
    {
      city: "WACO",
      state: "GA"
    },
    {
      city: "WACO",
      state: "KY"
    },
    {
      city: "WACO",
      state: "NC"
    },
    {
      city: "WACO",
      state: "NE"
    },
    {
      city: "WACO",
      state: "TX"
    },
    {
      city: "WACONIA",
      state: "MN"
    },
    {
      city: "WADDELL",
      state: "AZ"
    },
    {
      city: "WADDINGTON",
      state: "NY"
    },
    {
      city: "WADDY",
      state: "KY"
    },
    {
      city: "WADE",
      state: "NC"
    },
    {
      city: "WADENA",
      state: "IA"
    },
    {
      city: "WADENA",
      state: "MN"
    },
    {
      city: "WADESBORO",
      state: "NC"
    },
    {
      city: "WADESVILLE",
      state: "IN"
    },
    {
      city: "WADING RIVER",
      state: "NY"
    },
    {
      city: "WADLEY",
      state: "AL"
    },
    {
      city: "WADLEY",
      state: "GA"
    },
    {
      city: "WADMALAW ISLAND",
      state: "SC"
    },
    {
      city: "WADSWORTH",
      state: "IL"
    },
    {
      city: "WADSWORTH",
      state: "NV"
    },
    {
      city: "WADSWORTH",
      state: "OH"
    },
    {
      city: "WADSWORTH",
      state: "TX"
    },
    {
      city: "WAELDER",
      state: "TX"
    },
    {
      city: "WAGARVILLE",
      state: "AL"
    },
    {
      city: "WAGENER",
      state: "SC"
    },
    {
      city: "WAGGONER",
      state: "IL"
    },
    {
      city: "WAGNER",
      state: "SD"
    },
    {
      city: "WAGON MOUND",
      state: "NM"
    },
    {
      city: "WAGONER",
      state: "OK"
    },
    {
      city: "WAGONTOWN",
      state: "PA"
    },
    {
      city: "WAGRAM",
      state: "NC"
    },
    {
      city: "WAHIAWA",
      state: "HI"
    },
    {
      city: "WAHKIACUS",
      state: "WA"
    },
    {
      city: "WAHKON",
      state: "MN"
    },
    {
      city: "WAHOO",
      state: "NE"
    },
    {
      city: "WAHPETON",
      state: "ND"
    },
    {
      city: "WAIALUA",
      state: "HI"
    },
    {
      city: "WAIANAE",
      state: "HI"
    },
    {
      city: "WAIKOLOA",
      state: "HI"
    },
    {
      city: "WAILUKU",
      state: "HI"
    },
    {
      city: "WAIMANALO",
      state: "HI"
    },
    {
      city: "WAIMEA",
      state: "HI"
    },
    {
      city: "WAINSCOTT",
      state: "NY"
    },
    {
      city: "WAINWRIGHT",
      state: "AK"
    },
    {
      city: "WAINWRIGHT",
      state: "OK"
    },
    {
      city: "WAIPAHU",
      state: "HI"
    },
    {
      city: "WAITE",
      state: "ME"
    },
    {
      city: "WAITE PARK",
      state: "MN"
    },
    {
      city: "WAITSBURG",
      state: "WA"
    },
    {
      city: "WAITSFIELD",
      state: "VT"
    },
    {
      city: "WAKA",
      state: "TX"
    },
    {
      city: "WAKARUSA",
      state: "IN"
    },
    {
      city: "WAKARUSA",
      state: "KS"
    },
    {
      city: "WAKE",
      state: "VA"
    },
    {
      city: "WAKE FOREST",
      state: "NC"
    },
    {
      city: "WAKEENEY",
      state: "KS"
    },
    {
      city: "WAKEFIELD",
      state: "KS"
    },
    {
      city: "WAKEFIELD",
      state: "LA"
    },
    {
      city: "WAKEFIELD",
      state: "MA"
    },
    {
      city: "WAKEFIELD",
      state: "MI"
    },
    {
      city: "WAKEFIELD",
      state: "NE"
    },
    {
      city: "WAKEFIELD",
      state: "RI"
    },
    {
      city: "WAKEFIELD",
      state: "VA"
    },
    {
      city: "WAKEMAN",
      state: "OH"
    },
    {
      city: "WAKITA",
      state: "OK"
    },
    {
      city: "WAKONDA",
      state: "SD"
    },
    {
      city: "WALBRIDGE",
      state: "OH"
    },
    {
      city: "WALBURG",
      state: "TX"
    },
    {
      city: "WALCOTT",
      state: "AR"
    },
    {
      city: "WALCOTT",
      state: "IA"
    },
    {
      city: "WALCOTT",
      state: "ND"
    },
    {
      city: "WALCOTT",
      state: "WY"
    },
    {
      city: "WALDEN",
      state: "CO"
    },
    {
      city: "WALDEN",
      state: "NY"
    },
    {
      city: "WALDENBURG",
      state: "AR"
    },
    {
      city: "WALDO",
      state: "AR"
    },
    {
      city: "WALDO",
      state: "FL"
    },
    {
      city: "WALDO",
      state: "KS"
    },
    {
      city: "WALDO",
      state: "OH"
    },
    {
      city: "WALDO",
      state: "WI"
    },
    {
      city: "WALDOBORO",
      state: "ME"
    },
    {
      city: "WALDORF",
      state: "MD"
    },
    {
      city: "WALDORF",
      state: "MN"
    },
    {
      city: "WALDPORT",
      state: "OR"
    },
    {
      city: "WALDRON",
      state: "AR"
    },
    {
      city: "WALDRON",
      state: "IN"
    },
    {
      city: "WALDRON",
      state: "MI"
    },
    {
      city: "WALDRON",
      state: "MO"
    },
    {
      city: "WALDWICK",
      state: "NJ"
    },
    {
      city: "WALES",
      state: "AK"
    },
    {
      city: "WALES",
      state: "MA"
    },
    {
      city: "WALES",
      state: "ND"
    },
    {
      city: "WALES",
      state: "UT"
    },
    {
      city: "WALES",
      state: "WI"
    },
    {
      city: "WALES CENTER",
      state: "NY"
    },
    {
      city: "WALESKA",
      state: "GA"
    },
    {
      city: "WALFORD",
      state: "IA"
    },
    {
      city: "WALHALLA",
      state: "MI"
    },
    {
      city: "WALHALLA",
      state: "ND"
    },
    {
      city: "WALHALLA",
      state: "SC"
    },
    {
      city: "WALHONDING",
      state: "OH"
    },
    {
      city: "WALKER",
      state: "IA"
    },
    {
      city: "WALKER",
      state: "KS"
    },
    {
      city: "WALKER",
      state: "LA"
    },
    {
      city: "WALKER",
      state: "MN"
    },
    {
      city: "WALKER",
      state: "MO"
    },
    {
      city: "WALKER",
      state: "WV"
    },
    {
      city: "WALKER VALLEY",
      state: "NY"
    },
    {
      city: "WALKERSVILLE",
      state: "MD"
    },
    {
      city: "WALKERSVILLE",
      state: "WV"
    },
    {
      city: "WALKERTON",
      state: "IN"
    },
    {
      city: "WALKERTON",
      state: "VA"
    },
    {
      city: "WALKERTOWN",
      state: "NC"
    },
    {
      city: "WALKERVILLE",
      state: "MI"
    },
    {
      city: "WALL",
      state: "SD"
    },
    {
      city: "WALL",
      state: "TX"
    },
    {
      city: "WALL LAKE",
      state: "IA"
    },
    {
      city: "WALLA WALLA",
      state: "WA"
    },
    {
      city: "WALLACE",
      state: "CA"
    },
    {
      city: "WALLACE",
      state: "ID"
    },
    {
      city: "WALLACE",
      state: "IN"
    },
    {
      city: "WALLACE",
      state: "KS"
    },
    {
      city: "WALLACE",
      state: "MI"
    },
    {
      city: "WALLACE",
      state: "NC"
    },
    {
      city: "WALLACE",
      state: "NE"
    },
    {
      city: "WALLACE",
      state: "SC"
    },
    {
      city: "WALLACE",
      state: "SD"
    },
    {
      city: "WALLACE",
      state: "WV"
    },
    {
      city: "WALLAGRASS",
      state: "ME"
    },
    {
      city: "WALLAND",
      state: "TN"
    },
    {
      city: "WALLBACK",
      state: "WV"
    },
    {
      city: "WALLBURG",
      state: "NC"
    },
    {
      city: "WALLED LAKE",
      state: "MI"
    },
    {
      city: "WALLER",
      state: "TX"
    },
    {
      city: "WALLING",
      state: "TN"
    },
    {
      city: "WALLINGFORD",
      state: "CT"
    },
    {
      city: "WALLINGFORD",
      state: "IA"
    },
    {
      city: "WALLINGFORD",
      state: "KY"
    },
    {
      city: "WALLINGFORD",
      state: "PA"
    },
    {
      city: "WALLINGFORD",
      state: "VT"
    },
    {
      city: "WALLINGTON",
      state: "NJ"
    },
    {
      city: "WALLINS CREEK",
      state: "KY"
    },
    {
      city: "WALLIS",
      state: "TX"
    },
    {
      city: "WALLISVILLE",
      state: "TX"
    },
    {
      city: "WALLKILL",
      state: "NY"
    },
    {
      city: "WALLOON LAKE",
      state: "MI"
    },
    {
      city: "WALLOPS ISLAND",
      state: "VA"
    },
    {
      city: "WALLOWA",
      state: "OR"
    },
    {
      city: "WALLPACK CENTER",
      state: "NJ"
    },
    {
      city: "WALLS",
      state: "MS"
    },
    {
      city: "WALLSBURG",
      state: "UT"
    },
    {
      city: "WALLULA",
      state: "WA"
    },
    {
      city: "WALNUT",
      state: "CA"
    },
    {
      city: "WALNUT",
      state: "IA"
    },
    {
      city: "WALNUT",
      state: "IL"
    },
    {
      city: "WALNUT",
      state: "KS"
    },
    {
      city: "WALNUT",
      state: "MS"
    },
    {
      city: "WALNUT BOTTOM",
      state: "PA"
    },
    {
      city: "WALNUT COVE",
      state: "NC"
    },
    {
      city: "WALNUT CREEK",
      state: "CA"
    },
    {
      city: "WALNUT CREEK",
      state: "OH"
    },
    {
      city: "WALNUT GROVE",
      state: "AL"
    },
    {
      city: "WALNUT GROVE",
      state: "CA"
    },
    {
      city: "WALNUT GROVE",
      state: "MN"
    },
    {
      city: "WALNUT GROVE",
      state: "MO"
    },
    {
      city: "WALNUT GROVE",
      state: "MS"
    },
    {
      city: "WALNUT HILL",
      state: "IL"
    },
    {
      city: "WALNUT RIDGE",
      state: "AR"
    },
    {
      city: "WALNUT SHADE",
      state: "MO"
    },
    {
      city: "WALNUT SPRINGS",
      state: "TX"
    },
    {
      city: "WALNUTPORT",
      state: "PA"
    },
    {
      city: "WALPOLE",
      state: "MA"
    },
    {
      city: "WALPOLE",
      state: "ME"
    },
    {
      city: "WALPOLE",
      state: "NH"
    },
    {
      city: "WALSENBURG",
      state: "CO"
    },
    {
      city: "WALSH",
      state: "CO"
    },
    {
      city: "WALSH",
      state: "IL"
    },
    {
      city: "WALSHVILLE",
      state: "IL"
    },
    {
      city: "WALSTON",
      state: "PA"
    },
    {
      city: "WALSTONBURG",
      state: "NC"
    },
    {
      city: "WALTERBORO",
      state: "SC"
    },
    {
      city: "WALTERS",
      state: "OK"
    },
    {
      city: "WALTERSBURG",
      state: "PA"
    },
    {
      city: "WALTERVILLE",
      state: "OR"
    },
    {
      city: "WALTHALL",
      state: "MS"
    },
    {
      city: "WALTHAM",
      state: "MA"
    },
    {
      city: "WALTHAM",
      state: "MN"
    },
    {
      city: "WALTHILL",
      state: "NE"
    },
    {
      city: "WALTHOURVILLE",
      state: "GA"
    },
    {
      city: "WALTON",
      state: "IN"
    },
    {
      city: "WALTON",
      state: "KS"
    },
    {
      city: "WALTON",
      state: "KY"
    },
    {
      city: "WALTON",
      state: "NE"
    },
    {
      city: "WALTON",
      state: "NY"
    },
    {
      city: "WALTON",
      state: "OR"
    },
    {
      city: "WALTON",
      state: "WV"
    },
    {
      city: "WALTONVILLE",
      state: "IL"
    },
    {
      city: "WALWORTH",
      state: "NY"
    },
    {
      city: "WALWORTH",
      state: "WI"
    },
    {
      city: "WAMEGO",
      state: "KS"
    },
    {
      city: "WAMPSVILLE",
      state: "NY"
    },
    {
      city: "WAMPUM",
      state: "PA"
    },
    {
      city: "WAMSUTTER",
      state: "WY"
    },
    {
      city: "WANA",
      state: "WV"
    },
    {
      city: "WANAKENA",
      state: "NY"
    },
    {
      city: "WANAMINGO",
      state: "MN"
    },
    {
      city: "WANAQUE",
      state: "NJ"
    },
    {
      city: "WANATAH",
      state: "IN"
    },
    {
      city: "WANBLEE",
      state: "SD"
    },
    {
      city: "WANCHESE",
      state: "NC"
    },
    {
      city: "WANDA",
      state: "MN"
    },
    {
      city: "WANETTE",
      state: "OK"
    },
    {
      city: "WANN",
      state: "OK"
    },
    {
      city: "WANNASKA",
      state: "MN"
    },
    {
      city: "WANTAGH",
      state: "NY"
    },
    {
      city: "WAPAKONETA",
      state: "OH"
    },
    {
      city: "WAPANUCKA",
      state: "OK"
    },
    {
      city: "WAPATO",
      state: "WA"
    },
    {
      city: "WAPELLA",
      state: "IL"
    },
    {
      city: "WAPELLO",
      state: "IA"
    },
    {
      city: "WAPITI",
      state: "WY"
    },
    {
      city: "WAPPAPELLO",
      state: "MO"
    },
    {
      city: "WAPPINGERS FALLS",
      state: "NY"
    },
    {
      city: "WAPWALLOPEN",
      state: "PA"
    },
    {
      city: "WAR",
      state: "WV"
    },
    {
      city: "WARBA",
      state: "MN"
    },
    {
      city: "WARD",
      state: "AL"
    },
    {
      city: "WARD",
      state: "AR"
    },
    {
      city: "WARD",
      state: "CO"
    },
    {
      city: "WARD",
      state: "SC"
    },
    {
      city: "WARD COVE",
      state: "AK"
    },
    {
      city: "WARDA",
      state: "TX"
    },
    {
      city: "WARDELL",
      state: "MO"
    },
    {
      city: "WARDEN",
      state: "WA"
    },
    {
      city: "WARDENSVILLE",
      state: "WV"
    },
    {
      city: "WARDSBORO",
      state: "VT"
    },
    {
      city: "WARDTOWN",
      state: "VA"
    },
    {
      city: "WARDVILLE",
      state: "OK"
    },
    {
      city: "WARE",
      state: "MA"
    },
    {
      city: "WARE NECK",
      state: "VA"
    },
    {
      city: "WARE SHOALS",
      state: "SC"
    },
    {
      city: "WAREHAM",
      state: "MA"
    },
    {
      city: "WARETOWN",
      state: "NJ"
    },
    {
      city: "WARFIELD",
      state: "KY"
    },
    {
      city: "WARFIELD",
      state: "VA"
    },
    {
      city: "WARFORDSBURG",
      state: "PA"
    },
    {
      city: "WARING",
      state: "TX"
    },
    {
      city: "WARM SPRINGS",
      state: "AR"
    },
    {
      city: "WARM SPRINGS",
      state: "GA"
    },
    {
      city: "WARM SPRINGS",
      state: "MT"
    },
    {
      city: "WARM SPRINGS",
      state: "OR"
    },
    {
      city: "WARM SPRINGS",
      state: "VA"
    },
    {
      city: "WARMINSTER",
      state: "PA"
    },
    {
      city: "WARNE",
      state: "NC"
    },
    {
      city: "WARNER",
      state: "NH"
    },
    {
      city: "WARNER",
      state: "OK"
    },
    {
      city: "WARNER",
      state: "SD"
    },
    {
      city: "WARNER ROBINS",
      state: "GA"
    },
    {
      city: "WARNER SPRINGS",
      state: "CA"
    },
    {
      city: "WARNERS",
      state: "NY"
    },
    {
      city: "WARNERVILLE",
      state: "NY"
    },
    {
      city: "WARNOCK",
      state: "OH"
    },
    {
      city: "WARREN",
      state: "AR"
    },
    {
      city: "WARREN",
      state: "IL"
    },
    {
      city: "WARREN",
      state: "IN"
    },
    {
      city: "WARREN",
      state: "MA"
    },
    {
      city: "WARREN",
      state: "ME"
    },
    {
      city: "WARREN",
      state: "MI"
    },
    {
      city: "WARREN",
      state: "MN"
    },
    {
      city: "WARREN",
      state: "NH"
    },
    {
      city: "WARREN",
      state: "NJ"
    },
    {
      city: "WARREN",
      state: "OH"
    },
    {
      city: "WARREN",
      state: "OR"
    },
    {
      city: "WARREN",
      state: "PA"
    },
    {
      city: "WARREN",
      state: "RI"
    },
    {
      city: "WARREN",
      state: "TX"
    },
    {
      city: "WARREN",
      state: "VT"
    },
    {
      city: "WARREN CENTER",
      state: "PA"
    },
    {
      city: "WARRENDALE",
      state: "PA"
    },
    {
      city: "WARRENS",
      state: "WI"
    },
    {
      city: "WARRENSBURG",
      state: "IL"
    },
    {
      city: "WARRENSBURG",
      state: "MO"
    },
    {
      city: "WARRENSBURG",
      state: "NY"
    },
    {
      city: "WARRENSVILLE",
      state: "NC"
    },
    {
      city: "WARRENTON",
      state: "GA"
    },
    {
      city: "WARRENTON",
      state: "MO"
    },
    {
      city: "WARRENTON",
      state: "NC"
    },
    {
      city: "WARRENTON",
      state: "OR"
    },
    {
      city: "WARRENTON",
      state: "VA"
    },
    {
      city: "WARRENVILLE",
      state: "IL"
    },
    {
      city: "WARRENVILLE",
      state: "SC"
    },
    {
      city: "WARRINGTON",
      state: "PA"
    },
    {
      city: "WARRIOR",
      state: "AL"
    },
    {
      city: "WARRIORS MARK",
      state: "PA"
    },
    {
      city: "WARROAD",
      state: "MN"
    },
    {
      city: "WARSAW",
      state: "IL"
    },
    {
      city: "WARSAW",
      state: "IN"
    },
    {
      city: "WARSAW",
      state: "KY"
    },
    {
      city: "WARSAW",
      state: "MN"
    },
    {
      city: "WARSAW",
      state: "MO"
    },
    {
      city: "WARSAW",
      state: "NC"
    },
    {
      city: "WARSAW",
      state: "NY"
    },
    {
      city: "WARSAW",
      state: "OH"
    },
    {
      city: "WARSAW",
      state: "VA"
    },
    {
      city: "WARTBURG",
      state: "TN"
    },
    {
      city: "WARTHEN",
      state: "GA"
    },
    {
      city: "WARTRACE",
      state: "TN"
    },
    {
      city: "WARWICK",
      state: "GA"
    },
    {
      city: "WARWICK",
      state: "MA"
    },
    {
      city: "WARWICK",
      state: "MD"
    },
    {
      city: "WARWICK",
      state: "ND"
    },
    {
      city: "WARWICK",
      state: "NY"
    },
    {
      city: "WARWICK",
      state: "RI"
    },
    {
      city: "WASCO",
      state: "CA"
    },
    {
      city: "WASCO",
      state: "IL"
    },
    {
      city: "WASCO",
      state: "OR"
    },
    {
      city: "WASCOTT",
      state: "WI"
    },
    {
      city: "WASECA",
      state: "MN"
    },
    {
      city: "WASHBURN",
      state: "IL"
    },
    {
      city: "WASHBURN",
      state: "ME"
    },
    {
      city: "WASHBURN",
      state: "MO"
    },
    {
      city: "WASHBURN",
      state: "ND"
    },
    {
      city: "WASHBURN",
      state: "TN"
    },
    {
      city: "WASHBURN",
      state: "WI"
    },
    {
      city: "WASHINGTON",
      state: "AR"
    },
    {
      city: "WASHINGTON",
      state: "CA"
    },
    {
      city: "WASHINGTON",
      state: "CT"
    },
    {
      city: "WASHINGTON",
      state: "DC"
    },
    {
      city: "WASHINGTON",
      state: "GA"
    },
    {
      city: "WASHINGTON",
      state: "IA"
    },
    {
      city: "WASHINGTON",
      state: "IL"
    },
    {
      city: "WASHINGTON",
      state: "IN"
    },
    {
      city: "WASHINGTON",
      state: "KS"
    },
    {
      city: "WASHINGTON",
      state: "KY"
    },
    {
      city: "WASHINGTON",
      state: "LA"
    },
    {
      city: "WASHINGTON",
      state: "ME"
    },
    {
      city: "WASHINGTON",
      state: "MI"
    },
    {
      city: "WASHINGTON",
      state: "MO"
    },
    {
      city: "WASHINGTON",
      state: "MS"
    },
    {
      city: "WASHINGTON",
      state: "NC"
    },
    {
      city: "WASHINGTON",
      state: "NE"
    },
    {
      city: "WASHINGTON",
      state: "NH"
    },
    {
      city: "WASHINGTON",
      state: "NJ"
    },
    {
      city: "WASHINGTON",
      state: "OK"
    },
    {
      city: "WASHINGTON",
      state: "PA"
    },
    {
      city: "WASHINGTON",
      state: "TX"
    },
    {
      city: "WASHINGTON",
      state: "UT"
    },
    {
      city: "WASHINGTON",
      state: "VA"
    },
    {
      city: "WASHINGTON",
      state: "VT"
    },
    {
      city: "WASHINGTON",
      state: "WV"
    },
    {
      city: "WASHINGTON BORO",
      state: "PA"
    },
    {
      city: "WASHINGTON COURT HOUSE",
      state: "OH"
    },
    {
      city: "WASHINGTON CROSSING",
      state: "PA"
    },
    {
      city: "WASHINGTON DEPOT",
      state: "CT"
    },
    {
      city: "WASHINGTON GROVE",
      state: "MD"
    },
    {
      city: "WASHINGTON ISLAND",
      state: "WI"
    },
    {
      city: "WASHINGTON MILLS",
      state: "NY"
    },
    {
      city: "WASHINGTON NAVY YARD",
      state: "DC"
    },
    {
      city: "WASHINGTONVILLE",
      state: "NY"
    },
    {
      city: "WASHINGTONVILLE",
      state: "OH"
    },
    {
      city: "WASHINGTONVILLE",
      state: "PA"
    },
    {
      city: "WASHOE VALLEY",
      state: "NV"
    },
    {
      city: "WASHOUGAL",
      state: "WA"
    },
    {
      city: "WASHTA",
      state: "IA"
    },
    {
      city: "WASHTUCNA",
      state: "WA"
    },
    {
      city: "WASILLA",
      state: "AK"
    },
    {
      city: "WASKISH",
      state: "MN"
    },
    {
      city: "WASKOM",
      state: "TX"
    },
    {
      city: "WASOLA",
      state: "MO"
    },
    {
      city: "WASSAIC",
      state: "NY"
    },
    {
      city: "WASTA",
      state: "SD"
    },
    {
      city: "WATAGA",
      state: "IL"
    },
    {
      city: "WATAUGA",
      state: "SD"
    },
    {
      city: "WATAUGA",
      state: "TN"
    },
    {
      city: "WATCHUNG",
      state: "NJ"
    },
    {
      city: "WATER MILL",
      state: "NY"
    },
    {
      city: "WATER VALLEY",
      state: "KY"
    },
    {
      city: "WATER VALLEY",
      state: "MS"
    },
    {
      city: "WATER VALLEY",
      state: "TX"
    },
    {
      city: "WATER VIEW",
      state: "VA"
    },
    {
      city: "WATERBORO",
      state: "ME"
    },
    {
      city: "WATERBURY",
      state: "CT"
    },
    {
      city: "WATERBURY",
      state: "NE"
    },
    {
      city: "WATERBURY",
      state: "VT"
    },
    {
      city: "WATERBURY CENTER",
      state: "VT"
    },
    {
      city: "WATERFALL",
      state: "PA"
    },
    {
      city: "WATERFLOW",
      state: "NM"
    },
    {
      city: "WATERFORD",
      state: "CA"
    },
    {
      city: "WATERFORD",
      state: "CT"
    },
    {
      city: "WATERFORD",
      state: "ME"
    },
    {
      city: "WATERFORD",
      state: "MI"
    },
    {
      city: "WATERFORD",
      state: "MS"
    },
    {
      city: "WATERFORD",
      state: "NY"
    },
    {
      city: "WATERFORD",
      state: "OH"
    },
    {
      city: "WATERFORD",
      state: "PA"
    },
    {
      city: "WATERFORD",
      state: "VA"
    },
    {
      city: "WATERFORD",
      state: "WI"
    },
    {
      city: "WATERFORD WORKS",
      state: "NJ"
    },
    {
      city: "WATERLOO",
      state: "AL"
    },
    {
      city: "WATERLOO",
      state: "IA"
    },
    {
      city: "WATERLOO",
      state: "IL"
    },
    {
      city: "WATERLOO",
      state: "IN"
    },
    {
      city: "WATERLOO",
      state: "NE"
    },
    {
      city: "WATERLOO",
      state: "NY"
    },
    {
      city: "WATERLOO",
      state: "OH"
    },
    {
      city: "WATERLOO",
      state: "SC"
    },
    {
      city: "WATERLOO",
      state: "WI"
    },
    {
      city: "WATERMAN",
      state: "IL"
    },
    {
      city: "WATERPORT",
      state: "NY"
    },
    {
      city: "WATERPROOF",
      state: "LA"
    },
    {
      city: "WATERS",
      state: "MI"
    },
    {
      city: "WATERSMEET",
      state: "MI"
    },
    {
      city: "WATERTOWN",
      state: "CT"
    },
    {
      city: "WATERTOWN",
      state: "MA"
    },
    {
      city: "WATERTOWN",
      state: "MN"
    },
    {
      city: "WATERTOWN",
      state: "NY"
    },
    {
      city: "WATERTOWN",
      state: "SD"
    },
    {
      city: "WATERTOWN",
      state: "TN"
    },
    {
      city: "WATERTOWN",
      state: "WI"
    },
    {
      city: "WATERVILLE",
      state: "IA"
    },
    {
      city: "WATERVILLE",
      state: "KS"
    },
    {
      city: "WATERVILLE",
      state: "ME"
    },
    {
      city: "WATERVILLE",
      state: "MN"
    },
    {
      city: "WATERVILLE",
      state: "NY"
    },
    {
      city: "WATERVILLE",
      state: "OH"
    },
    {
      city: "WATERVILLE",
      state: "PA"
    },
    {
      city: "WATERVILLE",
      state: "VT"
    },
    {
      city: "WATERVILLE",
      state: "WA"
    },
    {
      city: "WATERVILLE VALLEY",
      state: "NH"
    },
    {
      city: "WATERVLIET",
      state: "MI"
    },
    {
      city: "WATERVLIET",
      state: "NY"
    },
    {
      city: "WATFORD CITY",
      state: "ND"
    },
    {
      city: "WATHENA",
      state: "KS"
    },
    {
      city: "WATKINS",
      state: "CO"
    },
    {
      city: "WATKINS",
      state: "IA"
    },
    {
      city: "WATKINS",
      state: "MN"
    },
    {
      city: "WATKINS GLEN",
      state: "NY"
    },
    {
      city: "WATKINSVILLE",
      state: "GA"
    },
    {
      city: "WATONGA",
      state: "OK"
    },
    {
      city: "WATROUS",
      state: "NM"
    },
    {
      city: "WATSEKA",
      state: "IL"
    },
    {
      city: "WATSON",
      state: "AR"
    },
    {
      city: "WATSON",
      state: "IL"
    },
    {
      city: "WATSON",
      state: "LA"
    },
    {
      city: "WATSON",
      state: "MN"
    },
    {
      city: "WATSON",
      state: "MO"
    },
    {
      city: "WATSON",
      state: "OK"
    },
    {
      city: "WATSONTOWN",
      state: "PA"
    },
    {
      city: "WATSONVILLE",
      state: "CA"
    },
    {
      city: "WATTON",
      state: "MI"
    },
    {
      city: "WATTS",
      state: "OK"
    },
    {
      city: "WATTSBURG",
      state: "PA"
    },
    {
      city: "WATTSVILLE",
      state: "AL"
    },
    {
      city: "WATTSVILLE",
      state: "VA"
    },
    {
      city: "WAUBAY",
      state: "SD"
    },
    {
      city: "WAUBUN",
      state: "MN"
    },
    {
      city: "WAUCHULA",
      state: "FL"
    },
    {
      city: "WAUCOMA",
      state: "IA"
    },
    {
      city: "WAUCONDA",
      state: "IL"
    },
    {
      city: "WAUCONDA",
      state: "WA"
    },
    {
      city: "WAUKAU",
      state: "WI"
    },
    {
      city: "WAUKEE",
      state: "IA"
    },
    {
      city: "WAUKEGAN",
      state: "IL"
    },
    {
      city: "WAUKENA",
      state: "CA"
    },
    {
      city: "WAUKESHA",
      state: "WI"
    },
    {
      city: "WAUKOMIS",
      state: "OK"
    },
    {
      city: "WAUKON",
      state: "IA"
    },
    {
      city: "WAUNA",
      state: "WA"
    },
    {
      city: "WAUNAKEE",
      state: "WI"
    },
    {
      city: "WAUNETA",
      state: "NE"
    },
    {
      city: "WAUPACA",
      state: "WI"
    },
    {
      city: "WAUPUN",
      state: "WI"
    },
    {
      city: "WAUREGAN",
      state: "CT"
    },
    {
      city: "WAURIKA",
      state: "OK"
    },
    {
      city: "WAUSA",
      state: "NE"
    },
    {
      city: "WAUSAU",
      state: "FL"
    },
    {
      city: "WAUSAU",
      state: "WI"
    },
    {
      city: "WAUSAUKEE",
      state: "WI"
    },
    {
      city: "WAUSEON",
      state: "OH"
    },
    {
      city: "WAUTOMA",
      state: "WI"
    },
    {
      city: "WAUZEKA",
      state: "WI"
    },
    {
      city: "WAVELAND",
      state: "IN"
    },
    {
      city: "WAVELAND",
      state: "MS"
    },
    {
      city: "WAVERLEY",
      state: "MA"
    },
    {
      city: "WAVERLY",
      state: "AL"
    },
    {
      city: "WAVERLY",
      state: "FL"
    },
    {
      city: "WAVERLY",
      state: "GA"
    },
    {
      city: "WAVERLY",
      state: "IA"
    },
    {
      city: "WAVERLY",
      state: "IL"
    },
    {
      city: "WAVERLY",
      state: "KS"
    },
    {
      city: "WAVERLY",
      state: "KY"
    },
    {
      city: "WAVERLY",
      state: "MN"
    },
    {
      city: "WAVERLY",
      state: "MO"
    },
    {
      city: "WAVERLY",
      state: "NE"
    },
    {
      city: "WAVERLY",
      state: "NY"
    },
    {
      city: "WAVERLY",
      state: "OH"
    },
    {
      city: "WAVERLY",
      state: "PA"
    },
    {
      city: "WAVERLY",
      state: "TN"
    },
    {
      city: "WAVERLY",
      state: "VA"
    },
    {
      city: "WAVERLY",
      state: "WA"
    },
    {
      city: "WAVERLY",
      state: "WV"
    },
    {
      city: "WAVERLY HALL",
      state: "GA"
    },
    {
      city: "WAVES",
      state: "NC"
    },
    {
      city: "WAWAKA",
      state: "IN"
    },
    {
      city: "WAWARSING",
      state: "NY"
    },
    {
      city: "WAXAHACHIE",
      state: "TX"
    },
    {
      city: "WAXHAW",
      state: "NC"
    },
    {
      city: "WAYCROSS",
      state: "GA"
    },
    {
      city: "WAYLAND",
      state: "IA"
    },
    {
      city: "WAYLAND",
      state: "KY"
    },
    {
      city: "WAYLAND",
      state: "MA"
    },
    {
      city: "WAYLAND",
      state: "MI"
    },
    {
      city: "WAYLAND",
      state: "MO"
    },
    {
      city: "WAYLAND",
      state: "NY"
    },
    {
      city: "WAYLAND",
      state: "OH"
    },
    {
      city: "WAYMART",
      state: "PA"
    },
    {
      city: "WAYNE",
      state: "IL"
    },
    {
      city: "WAYNE",
      state: "ME"
    },
    {
      city: "WAYNE",
      state: "MI"
    },
    {
      city: "WAYNE",
      state: "NE"
    },
    {
      city: "WAYNE",
      state: "NJ"
    },
    {
      city: "WAYNE",
      state: "NY"
    },
    {
      city: "WAYNE",
      state: "OH"
    },
    {
      city: "WAYNE",
      state: "OK"
    },
    {
      city: "WAYNE",
      state: "PA"
    },
    {
      city: "WAYNE",
      state: "WV"
    },
    {
      city: "WAYNE CITY",
      state: "IL"
    },
    {
      city: "WAYNESBORO",
      state: "GA"
    },
    {
      city: "WAYNESBORO",
      state: "MS"
    },
    {
      city: "WAYNESBORO",
      state: "PA"
    },
    {
      city: "WAYNESBORO",
      state: "TN"
    },
    {
      city: "WAYNESBORO",
      state: "VA"
    },
    {
      city: "WAYNESBURG",
      state: "KY"
    },
    {
      city: "WAYNESBURG",
      state: "OH"
    },
    {
      city: "WAYNESBURG",
      state: "PA"
    },
    {
      city: "WAYNESFIELD",
      state: "OH"
    },
    {
      city: "WAYNESVILLE",
      state: "GA"
    },
    {
      city: "WAYNESVILLE",
      state: "IL"
    },
    {
      city: "WAYNESVILLE",
      state: "MO"
    },
    {
      city: "WAYNESVILLE",
      state: "NC"
    },
    {
      city: "WAYNESVILLE",
      state: "OH"
    },
    {
      city: "WAYNETOWN",
      state: "IN"
    },
    {
      city: "WAYNOKA",
      state: "OK"
    },
    {
      city: "WAYSIDE",
      state: "MS"
    },
    {
      city: "WAYSIDE",
      state: "TX"
    },
    {
      city: "WAYSIDE",
      state: "WV"
    },
    {
      city: "WAYZATA",
      state: "MN"
    },
    {
      city: "WEARE",
      state: "NH"
    },
    {
      city: "WEATHERBY",
      state: "MO"
    },
    {
      city: "WEATHERFORD",
      state: "OK"
    },
    {
      city: "WEATHERFORD",
      state: "TX"
    },
    {
      city: "WEATHERLY",
      state: "PA"
    },
    {
      city: "WEATOGUE",
      state: "CT"
    },
    {
      city: "WEAUBLEAU",
      state: "MO"
    },
    {
      city: "WEAVER",
      state: "AL"
    },
    {
      city: "WEAVERVILLE",
      state: "CA"
    },
    {
      city: "WEAVERVILLE",
      state: "NC"
    },
    {
      city: "WEBB",
      state: "AL"
    },
    {
      city: "WEBB",
      state: "IA"
    },
    {
      city: "WEBB",
      state: "MS"
    },
    {
      city: "WEBB CITY",
      state: "MO"
    },
    {
      city: "WEBBER",
      state: "KS"
    },
    {
      city: "WEBBERS FALLS",
      state: "OK"
    },
    {
      city: "WEBBERVILLE",
      state: "MI"
    },
    {
      city: "WEBBVILLE",
      state: "KY"
    },
    {
      city: "WEBER CITY",
      state: "VA"
    },
    {
      city: "WEBSTER",
      state: "FL"
    },
    {
      city: "WEBSTER",
      state: "IN"
    },
    {
      city: "WEBSTER",
      state: "KY"
    },
    {
      city: "WEBSTER",
      state: "MA"
    },
    {
      city: "WEBSTER",
      state: "MN"
    },
    {
      city: "WEBSTER",
      state: "NC"
    },
    {
      city: "WEBSTER",
      state: "ND"
    },
    {
      city: "WEBSTER",
      state: "NY"
    },
    {
      city: "WEBSTER",
      state: "PA"
    },
    {
      city: "WEBSTER",
      state: "SD"
    },
    {
      city: "WEBSTER",
      state: "TX"
    },
    {
      city: "WEBSTER",
      state: "WI"
    },
    {
      city: "WEBSTER CITY",
      state: "IA"
    },
    {
      city: "WEBSTER SPRINGS",
      state: "WV"
    },
    {
      city: "WEBSTERVILLE",
      state: "VT"
    },
    {
      city: "WEDDERBURN",
      state: "OR"
    },
    {
      city: "WEDGEFIELD",
      state: "SC"
    },
    {
      city: "WEDOWEE",
      state: "AL"
    },
    {
      city: "WEDRON",
      state: "IL"
    },
    {
      city: "WEED",
      state: "CA"
    },
    {
      city: "WEEDSPORT",
      state: "NY"
    },
    {
      city: "WEEDVILLE",
      state: "PA"
    },
    {
      city: "WEEHAWKEN",
      state: "NJ"
    },
    {
      city: "WEEKSBURY",
      state: "KY"
    },
    {
      city: "WEEMS",
      state: "VA"
    },
    {
      city: "WEEPING WATER",
      state: "NE"
    },
    {
      city: "WEESATCHE",
      state: "TX"
    },
    {
      city: "WEIDMAN",
      state: "MI"
    },
    {
      city: "WEIKERT",
      state: "PA"
    },
    {
      city: "WEIMAR",
      state: "CA"
    },
    {
      city: "WEIMAR",
      state: "TX"
    },
    {
      city: "WEINER",
      state: "AR"
    },
    {
      city: "WEINERT",
      state: "TX"
    },
    {
      city: "WEIPPE",
      state: "ID"
    },
    {
      city: "WEIR",
      state: "KS"
    },
    {
      city: "WEIR",
      state: "MS"
    },
    {
      city: "WEIR",
      state: "TX"
    },
    {
      city: "WEIRSDALE",
      state: "FL"
    },
    {
      city: "WEIRTON",
      state: "WV"
    },
    {
      city: "WEISER",
      state: "ID"
    },
    {
      city: "WELAKA",
      state: "FL"
    },
    {
      city: "WELCH",
      state: "MN"
    },
    {
      city: "WELCH",
      state: "OK"
    },
    {
      city: "WELCH",
      state: "TX"
    },
    {
      city: "WELCH",
      state: "WV"
    },
    {
      city: "WELCHES",
      state: "OR"
    },
    {
      city: "WELCOME",
      state: "MD"
    },
    {
      city: "WELCOME",
      state: "MN"
    },
    {
      city: "WELCOME",
      state: "NC"
    },
    {
      city: "WELD",
      state: "ME"
    },
    {
      city: "WELDA",
      state: "KS"
    },
    {
      city: "WELDON",
      state: "CA"
    },
    {
      city: "WELDON",
      state: "IA"
    },
    {
      city: "WELDON",
      state: "IL"
    },
    {
      city: "WELDON",
      state: "NC"
    },
    {
      city: "WELDONA",
      state: "CO"
    },
    {
      city: "WELEETKA",
      state: "OK"
    },
    {
      city: "WELLBORN",
      state: "FL"
    },
    {
      city: "WELLBORN",
      state: "TX"
    },
    {
      city: "WELLERSBURG",
      state: "PA"
    },
    {
      city: "WELLESLEY",
      state: "MA"
    },
    {
      city: "WELLESLEY HILLS",
      state: "MA"
    },
    {
      city: "WELLESLEY ISLAND",
      state: "NY"
    },
    {
      city: "WELLFLEET",
      state: "MA"
    },
    {
      city: "WELLFLEET",
      state: "NE"
    },
    {
      city: "WELLFORD",
      state: "SC"
    },
    {
      city: "WELLING",
      state: "OK"
    },
    {
      city: "WELLINGTON",
      state: "AL"
    },
    {
      city: "WELLINGTON",
      state: "CO"
    },
    {
      city: "WELLINGTON",
      state: "FL"
    },
    {
      city: "WELLINGTON",
      state: "IL"
    },
    {
      city: "WELLINGTON",
      state: "KS"
    },
    {
      city: "WELLINGTON",
      state: "KY"
    },
    {
      city: "WELLINGTON",
      state: "MO"
    },
    {
      city: "WELLINGTON",
      state: "NV"
    },
    {
      city: "WELLINGTON",
      state: "OH"
    },
    {
      city: "WELLINGTON",
      state: "TX"
    },
    {
      city: "WELLINGTON",
      state: "UT"
    },
    {
      city: "WELLMAN",
      state: "IA"
    },
    {
      city: "WELLMAN",
      state: "TX"
    },
    {
      city: "WELLPINIT",
      state: "WA"
    },
    {
      city: "WELLS",
      state: "ME"
    },
    {
      city: "WELLS",
      state: "MI"
    },
    {
      city: "WELLS",
      state: "MN"
    },
    {
      city: "WELLS",
      state: "NV"
    },
    {
      city: "WELLS",
      state: "NY"
    },
    {
      city: "WELLS",
      state: "TX"
    },
    {
      city: "WELLS",
      state: "VT"
    },
    {
      city: "WELLS BRIDGE",
      state: "NY"
    },
    {
      city: "WELLS RIVER",
      state: "VT"
    },
    {
      city: "WELLSBORO",
      state: "PA"
    },
    {
      city: "WELLSBURG",
      state: "IA"
    },
    {
      city: "WELLSBURG",
      state: "NY"
    },
    {
      city: "WELLSBURG",
      state: "WV"
    },
    {
      city: "WELLSTON",
      state: "MI"
    },
    {
      city: "WELLSTON",
      state: "OH"
    },
    {
      city: "WELLSTON",
      state: "OK"
    },
    {
      city: "WELLSVILLE",
      state: "KS"
    },
    {
      city: "WELLSVILLE",
      state: "MO"
    },
    {
      city: "WELLSVILLE",
      state: "NY"
    },
    {
      city: "WELLSVILLE",
      state: "OH"
    },
    {
      city: "WELLSVILLE",
      state: "PA"
    },
    {
      city: "WELLSVILLE",
      state: "UT"
    },
    {
      city: "WELLTON",
      state: "AZ"
    },
    {
      city: "WELSH",
      state: "LA"
    },
    {
      city: "WELTON",
      state: "IA"
    },
    {
      city: "WENATCHEE",
      state: "WA"
    },
    {
      city: "WENDEL",
      state: "CA"
    },
    {
      city: "WENDEL",
      state: "PA"
    },
    {
      city: "WENDELL",
      state: "ID"
    },
    {
      city: "WENDELL",
      state: "MA"
    },
    {
      city: "WENDELL",
      state: "MN"
    },
    {
      city: "WENDELL",
      state: "NC"
    },
    {
      city: "WENDELL DEPOT",
      state: "MA"
    },
    {
      city: "WENDEN",
      state: "AZ"
    },
    {
      city: "WENDOVER",
      state: "UT"
    },
    {
      city: "WENHAM",
      state: "MA"
    },
    {
      city: "WENONA",
      state: "IL"
    },
    {
      city: "WENONAH",
      state: "NJ"
    },
    {
      city: "WENTWORTH",
      state: "MO"
    },
    {
      city: "WENTWORTH",
      state: "NC"
    },
    {
      city: "WENTWORTH",
      state: "NH"
    },
    {
      city: "WENTWORTH",
      state: "SD"
    },
    {
      city: "WENTZVILLE",
      state: "MO"
    },
    {
      city: "WEOGUFKA",
      state: "AL"
    },
    {
      city: "WEOTT",
      state: "CA"
    },
    {
      city: "WERNERSVILLE",
      state: "PA"
    },
    {
      city: "WESKAN",
      state: "KS"
    },
    {
      city: "WESLACO",
      state: "TX"
    },
    {
      city: "WESLEY",
      state: "AR"
    },
    {
      city: "WESLEY",
      state: "IA"
    },
    {
      city: "WESLEY",
      state: "ME"
    },
    {
      city: "WESLEY CHAPEL",
      state: "FL"
    },
    {
      city: "WESSINGTON",
      state: "SD"
    },
    {
      city: "WESSINGTON SPRINGS",
      state: "SD"
    },
    {
      city: "WESSON",
      state: "MS"
    },
    {
      city: "WEST",
      state: "MS"
    },
    {
      city: "WEST",
      state: "TX"
    },
    {
      city: "WEST ALEXANDER",
      state: "PA"
    },
    {
      city: "WEST ALEXANDRIA",
      state: "OH"
    },
    {
      city: "WEST ALTON",
      state: "MO"
    },
    {
      city: "WEST AUGUSTA",
      state: "VA"
    },
    {
      city: "WEST BABYLON",
      state: "NY"
    },
    {
      city: "WEST BADEN SPRINGS",
      state: "IN"
    },
    {
      city: "WEST BALDWIN",
      state: "ME"
    },
    {
      city: "WEST BARNSTABLE",
      state: "MA"
    },
    {
      city: "WEST BEND",
      state: "IA"
    },
    {
      city: "WEST BEND",
      state: "WI"
    },
    {
      city: "WEST BERLIN",
      state: "NJ"
    },
    {
      city: "WEST BETHEL",
      state: "ME"
    },
    {
      city: "WEST BLOCTON",
      state: "AL"
    },
    {
      city: "WEST BLOOMFIELD",
      state: "MI"
    },
    {
      city: "WEST BLOOMFIELD",
      state: "NY"
    },
    {
      city: "WEST BOOTHBAY HARBOR",
      state: "ME"
    },
    {
      city: "WEST BOXFORD",
      state: "MA"
    },
    {
      city: "WEST BOYLSTON",
      state: "MA"
    },
    {
      city: "WEST BRANCH",
      state: "IA"
    },
    {
      city: "WEST BRANCH",
      state: "MI"
    },
    {
      city: "WEST BRIDGEWATER",
      state: "MA"
    },
    {
      city: "WEST BROOKFIELD",
      state: "MA"
    },
    {
      city: "WEST BROOKLYN",
      state: "IL"
    },
    {
      city: "WEST BURKE",
      state: "VT"
    },
    {
      city: "WEST BURLINGTON",
      state: "IA"
    },
    {
      city: "WEST BURLINGTON",
      state: "NY"
    },
    {
      city: "WEST CAMP",
      state: "NY"
    },
    {
      city: "WEST CHARLESTON",
      state: "VT"
    },
    {
      city: "WEST CHATHAM",
      state: "MA"
    },
    {
      city: "WEST CHAZY",
      state: "NY"
    },
    {
      city: "WEST CHESTER",
      state: "IA"
    },
    {
      city: "WEST CHESTER",
      state: "OH"
    },
    {
      city: "WEST CHESTER",
      state: "PA"
    },
    {
      city: "WEST CHESTERFIELD",
      state: "MA"
    },
    {
      city: "WEST CHESTERFIELD",
      state: "NH"
    },
    {
      city: "WEST CHICAGO",
      state: "IL"
    },
    {
      city: "WEST CLARKSVILLE",
      state: "NY"
    },
    {
      city: "WEST COLLEGE CORNER",
      state: "IN"
    },
    {
      city: "WEST COLUMBIA",
      state: "SC"
    },
    {
      city: "WEST COLUMBIA",
      state: "TX"
    },
    {
      city: "WEST COLUMBIA",
      state: "WV"
    },
    {
      city: "WEST CONCORD",
      state: "MN"
    },
    {
      city: "WEST CORNWALL",
      state: "CT"
    },
    {
      city: "WEST COVINA",
      state: "CA"
    },
    {
      city: "WEST COXSACKIE",
      state: "NY"
    },
    {
      city: "WEST CREEK",
      state: "NJ"
    },
    {
      city: "WEST DANVILLE",
      state: "VT"
    },
    {
      city: "WEST DAVENPORT",
      state: "NY"
    },
    {
      city: "WEST DECATUR",
      state: "PA"
    },
    {
      city: "WEST DENNIS",
      state: "MA"
    },
    {
      city: "WEST DES MOINES",
      state: "IA"
    },
    {
      city: "WEST DOVER",
      state: "VT"
    },
    {
      city: "WEST DUMMERSTON",
      state: "VT"
    },
    {
      city: "WEST EATON",
      state: "NY"
    },
    {
      city: "WEST EDMESTON",
      state: "NY"
    },
    {
      city: "WEST ELIZABETH",
      state: "PA"
    },
    {
      city: "WEST ELKTON",
      state: "OH"
    },
    {
      city: "WEST END",
      state: "NC"
    },
    {
      city: "WEST ENFIELD",
      state: "ME"
    },
    {
      city: "WEST FAIRLEE",
      state: "VT"
    },
    {
      city: "WEST FALLS",
      state: "NY"
    },
    {
      city: "WEST FALMOUTH",
      state: "MA"
    },
    {
      city: "WEST FARGO",
      state: "ND"
    },
    {
      city: "WEST FARMINGTON",
      state: "ME"
    },
    {
      city: "WEST FARMINGTON",
      state: "OH"
    },
    {
      city: "WEST FINLEY",
      state: "PA"
    },
    {
      city: "WEST FORK",
      state: "AR"
    },
    {
      city: "WEST FORKS",
      state: "ME"
    },
    {
      city: "WEST FRANKFORT",
      state: "IL"
    },
    {
      city: "WEST FRIENDSHIP",
      state: "MD"
    },
    {
      city: "WEST FULTON",
      state: "NY"
    },
    {
      city: "WEST GLACIER",
      state: "MT"
    },
    {
      city: "WEST GRANBY",
      state: "CT"
    },
    {
      city: "WEST GREEN",
      state: "GA"
    },
    {
      city: "WEST GREENWICH",
      state: "RI"
    },
    {
      city: "WEST GROTON",
      state: "MA"
    },
    {
      city: "WEST GROVE",
      state: "PA"
    },
    {
      city: "WEST HALIFAX",
      state: "VT"
    },
    {
      city: "WEST HAMLIN",
      state: "WV"
    },
    {
      city: "WEST HARRISON",
      state: "IN"
    },
    {
      city: "WEST HARRISON",
      state: "NY"
    },
    {
      city: "WEST HARTFORD",
      state: "CT"
    },
    {
      city: "WEST HARTFORD",
      state: "VT"
    },
    {
      city: "WEST HARTLAND",
      state: "CT"
    },
    {
      city: "WEST HARWICH",
      state: "MA"
    },
    {
      city: "WEST HATFIELD",
      state: "MA"
    },
    {
      city: "WEST HAVEN",
      state: "CT"
    },
    {
      city: "WEST HAVERSTRAW",
      state: "NY"
    },
    {
      city: "WEST HELENA",
      state: "AR"
    },
    {
      city: "WEST HEMPSTEAD",
      state: "NY"
    },
    {
      city: "WEST HENRIETTA",
      state: "NY"
    },
    {
      city: "WEST HICKORY",
      state: "PA"
    },
    {
      city: "WEST HILLS",
      state: "CA"
    },
    {
      city: "WEST HOLLYWOOD",
      state: "CA"
    },
    {
      city: "WEST HURLEY",
      state: "NY"
    },
    {
      city: "WEST HYANNISPORT",
      state: "MA"
    },
    {
      city: "WEST ISLIP",
      state: "NY"
    },
    {
      city: "WEST JEFFERSON",
      state: "NC"
    },
    {
      city: "WEST JEFFERSON",
      state: "OH"
    },
    {
      city: "WEST JORDAN",
      state: "UT"
    },
    {
      city: "WEST KENNEBUNK",
      state: "ME"
    },
    {
      city: "WEST KILL",
      state: "NY"
    },
    {
      city: "WEST KINGSTON",
      state: "RI"
    },
    {
      city: "WEST LAFAYETTE",
      state: "IN"
    },
    {
      city: "WEST LAFAYETTE",
      state: "OH"
    },
    {
      city: "WEST LEBANON",
      state: "IN"
    },
    {
      city: "WEST LEBANON",
      state: "NH"
    },
    {
      city: "WEST LEBANON",
      state: "NY"
    },
    {
      city: "WEST LEBANON",
      state: "PA"
    },
    {
      city: "WEST LEISENRING",
      state: "PA"
    },
    {
      city: "WEST LEYDEN",
      state: "NY"
    },
    {
      city: "WEST LIBERTY",
      state: "IA"
    },
    {
      city: "WEST LIBERTY",
      state: "IL"
    },
    {
      city: "WEST LIBERTY",
      state: "KY"
    },
    {
      city: "WEST LIBERTY",
      state: "OH"
    },
    {
      city: "WEST LIBERTY",
      state: "WV"
    },
    {
      city: "WEST LINN",
      state: "OR"
    },
    {
      city: "WEST LONG BRANCH",
      state: "NJ"
    },
    {
      city: "WEST MANCHESTER",
      state: "OH"
    },
    {
      city: "WEST MANSFIELD",
      state: "OH"
    },
    {
      city: "WEST MCLEAN",
      state: "VA"
    },
    {
      city: "WEST MEDFORD",
      state: "MA"
    },
    {
      city: "WEST MEMPHIS",
      state: "AR"
    },
    {
      city: "WEST MIDDLESEX",
      state: "PA"
    },
    {
      city: "WEST MIDDLETON",
      state: "IN"
    },
    {
      city: "WEST MIDDLETOWN",
      state: "PA"
    },
    {
      city: "WEST MIFFLIN",
      state: "PA"
    },
    {
      city: "WEST MILFORD",
      state: "NJ"
    },
    {
      city: "WEST MILFORD",
      state: "WV"
    },
    {
      city: "WEST MILTON",
      state: "OH"
    },
    {
      city: "WEST MILTON",
      state: "PA"
    },
    {
      city: "WEST MINERAL",
      state: "KS"
    },
    {
      city: "WEST MINOT",
      state: "ME"
    },
    {
      city: "WEST MONROE",
      state: "LA"
    },
    {
      city: "WEST MONROE",
      state: "NY"
    },
    {
      city: "WEST MYSTIC",
      state: "CT"
    },
    {
      city: "WEST NEW YORK",
      state: "NJ"
    },
    {
      city: "WEST NEWBURY",
      state: "MA"
    },
    {
      city: "WEST NEWBURY",
      state: "VT"
    },
    {
      city: "WEST NEWFIELD",
      state: "ME"
    },
    {
      city: "WEST NEWTON",
      state: "IN"
    },
    {
      city: "WEST NEWTON",
      state: "MA"
    },
    {
      city: "WEST NEWTON",
      state: "PA"
    },
    {
      city: "WEST NOTTINGHAM",
      state: "NH"
    },
    {
      city: "WEST NYACK",
      state: "NY"
    },
    {
      city: "WEST OLIVE",
      state: "MI"
    },
    {
      city: "WEST ONEONTA",
      state: "NY"
    },
    {
      city: "WEST ORANGE",
      state: "NJ"
    },
    {
      city: "WEST OSSIPEE",
      state: "NH"
    },
    {
      city: "WEST PADUCAH",
      state: "KY"
    },
    {
      city: "WEST PALM BEACH",
      state: "FL"
    },
    {
      city: "WEST PARIS",
      state: "ME"
    },
    {
      city: "WEST PARK",
      state: "NY"
    },
    {
      city: "WEST PAWLET",
      state: "VT"
    },
    {
      city: "WEST PETERBOROUGH",
      state: "NH"
    },
    {
      city: "WEST PITTSBURG",
      state: "PA"
    },
    {
      city: "WEST PLAINS",
      state: "MO"
    },
    {
      city: "WEST POINT",
      state: "AR"
    },
    {
      city: "WEST POINT",
      state: "CA"
    },
    {
      city: "WEST POINT",
      state: "GA"
    },
    {
      city: "WEST POINT",
      state: "IA"
    },
    {
      city: "WEST POINT",
      state: "IL"
    },
    {
      city: "WEST POINT",
      state: "KY"
    },
    {
      city: "WEST POINT",
      state: "MS"
    },
    {
      city: "WEST POINT",
      state: "NE"
    },
    {
      city: "WEST POINT",
      state: "NY"
    },
    {
      city: "WEST POINT",
      state: "OH"
    },
    {
      city: "WEST POINT",
      state: "PA"
    },
    {
      city: "WEST POINT",
      state: "TX"
    },
    {
      city: "WEST POINT",
      state: "VA"
    },
    {
      city: "WEST POLAND",
      state: "ME"
    },
    {
      city: "WEST PORTSMOUTH",
      state: "OH"
    },
    {
      city: "WEST RICHLAND",
      state: "WA"
    },
    {
      city: "WEST RIVER",
      state: "MD"
    },
    {
      city: "WEST ROCKPORT",
      state: "ME"
    },
    {
      city: "WEST ROXBURY",
      state: "MA"
    },
    {
      city: "WEST RUPERT",
      state: "VT"
    },
    {
      city: "WEST RUTLAND",
      state: "VT"
    },
    {
      city: "WEST SACRAMENTO",
      state: "CA"
    },
    {
      city: "WEST SALEM",
      state: "IL"
    },
    {
      city: "WEST SALEM",
      state: "OH"
    },
    {
      city: "WEST SALEM",
      state: "WI"
    },
    {
      city: "WEST SALISBURY",
      state: "PA"
    },
    {
      city: "WEST SAND LAKE",
      state: "NY"
    },
    {
      city: "WEST SAYVILLE",
      state: "NY"
    },
    {
      city: "WEST SHOKAN",
      state: "NY"
    },
    {
      city: "WEST SIMSBURY",
      state: "CT"
    },
    {
      city: "WEST SOMERSET",
      state: "KY"
    },
    {
      city: "WEST SPRINGFIELD",
      state: "MA"
    },
    {
      city: "WEST SPRINGFIELD",
      state: "PA"
    },
    {
      city: "WEST STEWARTSTOWN",
      state: "NH"
    },
    {
      city: "WEST STOCKBRIDGE",
      state: "MA"
    },
    {
      city: "WEST STOCKHOLM",
      state: "NY"
    },
    {
      city: "WEST SUFFIELD",
      state: "CT"
    },
    {
      city: "WEST SUNBURY",
      state: "PA"
    },
    {
      city: "WEST SWANZEY",
      state: "NH"
    },
    {
      city: "WEST TERRE HAUTE",
      state: "IN"
    },
    {
      city: "WEST TISBURY",
      state: "MA"
    },
    {
      city: "WEST TOPSHAM",
      state: "VT"
    },
    {
      city: "WEST TOWNSEND",
      state: "MA"
    },
    {
      city: "WEST TOWNSHEND",
      state: "VT"
    },
    {
      city: "WEST UNION",
      state: "IA"
    },
    {
      city: "WEST UNION",
      state: "IL"
    },
    {
      city: "WEST UNION",
      state: "MN"
    },
    {
      city: "WEST UNION",
      state: "OH"
    },
    {
      city: "WEST UNION",
      state: "SC"
    },
    {
      city: "WEST UNION",
      state: "WV"
    },
    {
      city: "WEST UNITY",
      state: "OH"
    },
    {
      city: "WEST VALLEY",
      state: "NY"
    },
    {
      city: "WEST VALLEY CITY",
      state: "UT"
    },
    {
      city: "WEST VAN LEAR",
      state: "KY"
    },
    {
      city: "WEST WARDSBORO",
      state: "VT"
    },
    {
      city: "WEST WAREHAM",
      state: "MA"
    },
    {
      city: "WEST WARREN",
      state: "MA"
    },
    {
      city: "WEST WARWICK",
      state: "RI"
    },
    {
      city: "WEST WENDOVER",
      state: "NV"
    },
    {
      city: "WEST WILLOW",
      state: "PA"
    },
    {
      city: "WEST WINFIELD",
      state: "NY"
    },
    {
      city: "WEST YARMOUTH",
      state: "MA"
    },
    {
      city: "WEST YELLOWSTONE",
      state: "MT"
    },
    {
      city: "WEST YORK",
      state: "IL"
    },
    {
      city: "WESTBORO",
      state: "MO"
    },
    {
      city: "WESTBORO",
      state: "WI"
    },
    {
      city: "WESTBOROUGH",
      state: "MA"
    },
    {
      city: "WESTBROOK",
      state: "CT"
    },
    {
      city: "WESTBROOK",
      state: "ME"
    },
    {
      city: "WESTBROOK",
      state: "MN"
    },
    {
      city: "WESTBROOK",
      state: "TX"
    },
    {
      city: "WESTBROOKVILLE",
      state: "NY"
    },
    {
      city: "WESTBURY",
      state: "NY"
    },
    {
      city: "WESTBY",
      state: "MT"
    },
    {
      city: "WESTBY",
      state: "WI"
    },
    {
      city: "WESTCHESTER",
      state: "IL"
    },
    {
      city: "WESTCLIFFE",
      state: "CO"
    },
    {
      city: "WESTDALE",
      state: "NY"
    },
    {
      city: "WESTERLO",
      state: "NY"
    },
    {
      city: "WESTERLY",
      state: "RI"
    },
    {
      city: "WESTERN",
      state: "NE"
    },
    {
      city: "WESTERN GROVE",
      state: "AR"
    },
    {
      city: "WESTERN SPRINGS",
      state: "IL"
    },
    {
      city: "WESTERNPORT",
      state: "MD"
    },
    {
      city: "WESTERNVILLE",
      state: "NY"
    },
    {
      city: "WESTERVILLE",
      state: "NE"
    },
    {
      city: "WESTERVILLE",
      state: "OH"
    },
    {
      city: "WESTFALL",
      state: "OR"
    },
    {
      city: "WESTFIELD",
      state: "IA"
    },
    {
      city: "WESTFIELD",
      state: "IL"
    },
    {
      city: "WESTFIELD",
      state: "IN"
    },
    {
      city: "WESTFIELD",
      state: "MA"
    },
    {
      city: "WESTFIELD",
      state: "ME"
    },
    {
      city: "WESTFIELD",
      state: "NC"
    },
    {
      city: "WESTFIELD",
      state: "NJ"
    },
    {
      city: "WESTFIELD",
      state: "NY"
    },
    {
      city: "WESTFIELD",
      state: "PA"
    },
    {
      city: "WESTFIELD",
      state: "VT"
    },
    {
      city: "WESTFIELD",
      state: "WI"
    },
    {
      city: "WESTFIELD CENTER",
      state: "OH"
    },
    {
      city: "WESTFIR",
      state: "OR"
    },
    {
      city: "WESTFORD",
      state: "MA"
    },
    {
      city: "WESTFORD",
      state: "NY"
    },
    {
      city: "WESTFORD",
      state: "VT"
    },
    {
      city: "WESTGATE",
      state: "IA"
    },
    {
      city: "WESTHAMPTON",
      state: "NY"
    },
    {
      city: "WESTHAMPTON BEACH",
      state: "NY"
    },
    {
      city: "WESTHOFF",
      state: "TX"
    },
    {
      city: "WESTHOPE",
      state: "ND"
    },
    {
      city: "WESTLAKE",
      state: "LA"
    },
    {
      city: "WESTLAKE",
      state: "OH"
    },
    {
      city: "WESTLAKE",
      state: "OR"
    },
    {
      city: "WESTLAKE VILLAGE",
      state: "CA"
    },
    {
      city: "WESTLAND",
      state: "MI"
    },
    {
      city: "WESTLAND",
      state: "PA"
    },
    {
      city: "WESTLEY",
      state: "CA"
    },
    {
      city: "WESTMINSTER",
      state: "CA"
    },
    {
      city: "WESTMINSTER",
      state: "CO"
    },
    {
      city: "WESTMINSTER",
      state: "MA"
    },
    {
      city: "WESTMINSTER",
      state: "MD"
    },
    {
      city: "WESTMINSTER",
      state: "SC"
    },
    {
      city: "WESTMINSTER",
      state: "TX"
    },
    {
      city: "WESTMINSTER",
      state: "VT"
    },
    {
      city: "WESTMINSTER STATION",
      state: "VT"
    },
    {
      city: "WESTMONT",
      state: "IL"
    },
    {
      city: "WESTMORELAND",
      state: "KS"
    },
    {
      city: "WESTMORELAND",
      state: "NH"
    },
    {
      city: "WESTMORELAND",
      state: "NY"
    },
    {
      city: "WESTMORELAND",
      state: "TN"
    },
    {
      city: "WESTMORELAND CITY",
      state: "PA"
    },
    {
      city: "WESTMORLAND",
      state: "CA"
    },
    {
      city: "WESTON",
      state: "CO"
    },
    {
      city: "WESTON",
      state: "CT"
    },
    {
      city: "WESTON",
      state: "GA"
    },
    {
      city: "WESTON",
      state: "ID"
    },
    {
      city: "WESTON",
      state: "MA"
    },
    {
      city: "WESTON",
      state: "MI"
    },
    {
      city: "WESTON",
      state: "MO"
    },
    {
      city: "WESTON",
      state: "NE"
    },
    {
      city: "WESTON",
      state: "OH"
    },
    {
      city: "WESTON",
      state: "OR"
    },
    {
      city: "WESTON",
      state: "PA"
    },
    {
      city: "WESTON",
      state: "TX"
    },
    {
      city: "WESTON",
      state: "VT"
    },
    {
      city: "WESTON",
      state: "WV"
    },
    {
      city: "WESTON",
      state: "WY"
    },
    {
      city: "WESTONS MILLS",
      state: "NY"
    },
    {
      city: "WESTOVER",
      state: "AL"
    },
    {
      city: "WESTOVER",
      state: "MD"
    },
    {
      city: "WESTOVER",
      state: "PA"
    },
    {
      city: "WESTPHALIA",
      state: "IA"
    },
    {
      city: "WESTPHALIA",
      state: "IN"
    },
    {
      city: "WESTPHALIA",
      state: "KS"
    },
    {
      city: "WESTPHALIA",
      state: "MI"
    },
    {
      city: "WESTPHALIA",
      state: "MO"
    },
    {
      city: "WESTPOINT",
      state: "IN"
    },
    {
      city: "WESTPOINT",
      state: "TN"
    },
    {
      city: "WESTPORT",
      state: "CA"
    },
    {
      city: "WESTPORT",
      state: "CT"
    },
    {
      city: "WESTPORT",
      state: "IN"
    },
    {
      city: "WESTPORT",
      state: "KY"
    },
    {
      city: "WESTPORT",
      state: "MA"
    },
    {
      city: "WESTPORT",
      state: "NY"
    },
    {
      city: "WESTPORT",
      state: "PA"
    },
    {
      city: "WESTPORT",
      state: "SD"
    },
    {
      city: "WESTPORT",
      state: "WA"
    },
    {
      city: "WESTPORT POINT",
      state: "MA"
    },
    {
      city: "WESTSIDE",
      state: "IA"
    },
    {
      city: "WESTTOWN",
      state: "NY"
    },
    {
      city: "WESTTOWN",
      state: "PA"
    },
    {
      city: "WESTVIEW",
      state: "KY"
    },
    {
      city: "WESTVILLE",
      state: "FL"
    },
    {
      city: "WESTVILLE",
      state: "IL"
    },
    {
      city: "WESTVILLE",
      state: "IN"
    },
    {
      city: "WESTVILLE",
      state: "NJ"
    },
    {
      city: "WESTVILLE",
      state: "OH"
    },
    {
      city: "WESTVILLE",
      state: "OK"
    },
    {
      city: "WESTVILLE",
      state: "SC"
    },
    {
      city: "WESTWEGO",
      state: "LA"
    },
    {
      city: "WESTWOOD",
      state: "CA"
    },
    {
      city: "WESTWOOD",
      state: "MA"
    },
    {
      city: "WESTWOOD",
      state: "NJ"
    },
    {
      city: "WETHERSFIELD",
      state: "CT"
    },
    {
      city: "WETMORE",
      state: "CO"
    },
    {
      city: "WETMORE",
      state: "KS"
    },
    {
      city: "WETMORE",
      state: "MI"
    },
    {
      city: "WETUMKA",
      state: "OK"
    },
    {
      city: "WETUMPKA",
      state: "AL"
    },
    {
      city: "WEVER",
      state: "IA"
    },
    {
      city: "WEVERTOWN",
      state: "NY"
    },
    {
      city: "WEWAHITCHKA",
      state: "FL"
    },
    {
      city: "WEWOKA",
      state: "OK"
    },
    {
      city: "WEXFORD",
      state: "PA"
    },
    {
      city: "WEYANOKE",
      state: "LA"
    },
    {
      city: "WEYAUWEGA",
      state: "WI"
    },
    {
      city: "WEYERHAEUSER",
      state: "WI"
    },
    {
      city: "WEYERS CAVE",
      state: "VA"
    },
    {
      city: "WEYMOUTH",
      state: "MA"
    },
    {
      city: "WHALEYVILLE",
      state: "MD"
    },
    {
      city: "WHARNCLIFFE",
      state: "WV"
    },
    {
      city: "WHARTON",
      state: "NJ"
    },
    {
      city: "WHARTON",
      state: "OH"
    },
    {
      city: "WHARTON",
      state: "TX"
    },
    {
      city: "WHARTON",
      state: "WV"
    },
    {
      city: "WHAT CHEER",
      state: "IA"
    },
    {
      city: "WHATELY",
      state: "MA"
    },
    {
      city: "WHATLEY",
      state: "AL"
    },
    {
      city: "WHEAT RIDGE",
      state: "CO"
    },
    {
      city: "WHEATCROFT",
      state: "KY"
    },
    {
      city: "WHEATFIELD",
      state: "IN"
    },
    {
      city: "WHEATLAND",
      state: "CA"
    },
    {
      city: "WHEATLAND",
      state: "IA"
    },
    {
      city: "WHEATLAND",
      state: "IN"
    },
    {
      city: "WHEATLAND",
      state: "MO"
    },
    {
      city: "WHEATLAND",
      state: "ND"
    },
    {
      city: "WHEATLAND",
      state: "OK"
    },
    {
      city: "WHEATLAND",
      state: "PA"
    },
    {
      city: "WHEATLAND",
      state: "WY"
    },
    {
      city: "WHEATLEY",
      state: "AR"
    },
    {
      city: "WHEATON",
      state: "IL"
    },
    {
      city: "WHEATON",
      state: "MN"
    },
    {
      city: "WHEATON",
      state: "MO"
    },
    {
      city: "WHEELER",
      state: "IL"
    },
    {
      city: "WHEELER",
      state: "IN"
    },
    {
      city: "WHEELER",
      state: "MI"
    },
    {
      city: "WHEELER",
      state: "MS"
    },
    {
      city: "WHEELER",
      state: "OR"
    },
    {
      city: "WHEELER",
      state: "TX"
    },
    {
      city: "WHEELER",
      state: "WI"
    },
    {
      city: "WHEELER ARMY AIRFIELD",
      state: "HI"
    },
    {
      city: "WHEELERSBURG",
      state: "OH"
    },
    {
      city: "WHEELING",
      state: "IL"
    },
    {
      city: "WHEELING",
      state: "MO"
    },
    {
      city: "WHEELING",
      state: "WV"
    },
    {
      city: "WHEELOCK",
      state: "TX"
    },
    {
      city: "WHEELWRIGHT",
      state: "KY"
    },
    {
      city: "WHEELWRIGHT",
      state: "MA"
    },
    {
      city: "WHELEN SPRINGS",
      state: "AR"
    },
    {
      city: "WHICK",
      state: "KY"
    },
    {
      city: "WHIGHAM",
      state: "GA"
    },
    {
      city: "WHIPPANY",
      state: "NJ"
    },
    {
      city: "WHIPPLE",
      state: "OH"
    },
    {
      city: "WHISKEYTOWN",
      state: "CA"
    },
    {
      city: "WHITAKERS",
      state: "NC"
    },
    {
      city: "WHITE",
      state: "GA"
    },
    {
      city: "WHITE",
      state: "PA"
    },
    {
      city: "WHITE",
      state: "SD"
    },
    {
      city: "WHITE BIRD",
      state: "ID"
    },
    {
      city: "WHITE BLUFF",
      state: "TN"
    },
    {
      city: "WHITE CASTLE",
      state: "LA"
    },
    {
      city: "WHITE CITY",
      state: "KS"
    },
    {
      city: "WHITE CITY",
      state: "OR"
    },
    {
      city: "WHITE CLOUD",
      state: "KS"
    },
    {
      city: "WHITE CLOUD",
      state: "MI"
    },
    {
      city: "WHITE COTTAGE",
      state: "OH"
    },
    {
      city: "WHITE DEER",
      state: "PA"
    },
    {
      city: "WHITE DEER",
      state: "TX"
    },
    {
      city: "WHITE EARTH",
      state: "ND"
    },
    {
      city: "WHITE HALL",
      state: "AR"
    },
    {
      city: "WHITE HALL",
      state: "IL"
    },
    {
      city: "WHITE HALL",
      state: "MD"
    },
    {
      city: "WHITE HALL",
      state: "VA"
    },
    {
      city: "WHITE HAVEN",
      state: "PA"
    },
    {
      city: "WHITE HEATH",
      state: "IL"
    },
    {
      city: "WHITE HORSE BEACH",
      state: "MA"
    },
    {
      city: "WHITE HOUSE",
      state: "TN"
    },
    {
      city: "WHITE LAKE",
      state: "MI"
    },
    {
      city: "WHITE LAKE",
      state: "NY"
    },
    {
      city: "WHITE LAKE",
      state: "SD"
    },
    {
      city: "WHITE LAKE",
      state: "WI"
    },
    {
      city: "WHITE MARSH",
      state: "MD"
    },
    {
      city: "WHITE MARSH",
      state: "VA"
    },
    {
      city: "WHITE MILLS",
      state: "KY"
    },
    {
      city: "WHITE MILLS",
      state: "PA"
    },
    {
      city: "WHITE MOUNTAIN",
      state: "AK"
    },
    {
      city: "WHITE MOUNTAIN LAKE",
      state: "AZ"
    },
    {
      city: "WHITE OAK",
      state: "GA"
    },
    {
      city: "WHITE OAK",
      state: "NC"
    },
    {
      city: "WHITE OAK",
      state: "TX"
    },
    {
      city: "WHITE OAK",
      state: "WV"
    },
    {
      city: "WHITE OWL",
      state: "SD"
    },
    {
      city: "WHITE PIGEON",
      state: "MI"
    },
    {
      city: "WHITE PINE",
      state: "MI"
    },
    {
      city: "WHITE PINE",
      state: "TN"
    },
    {
      city: "WHITE PLAINS",
      state: "GA"
    },
    {
      city: "WHITE PLAINS",
      state: "KY"
    },
    {
      city: "WHITE PLAINS",
      state: "MD"
    },
    {
      city: "WHITE PLAINS",
      state: "NY"
    },
    {
      city: "WHITE PLAINS",
      state: "VA"
    },
    {
      city: "WHITE POST",
      state: "VA"
    },
    {
      city: "WHITE RIVER",
      state: "SD"
    },
    {
      city: "WHITE RIVER JUNCTION",
      state: "VT"
    },
    {
      city: "WHITE ROCK",
      state: "SC"
    },
    {
      city: "WHITE SALMON",
      state: "WA"
    },
    {
      city: "WHITE SANDS MISSILE RANGE",
      state: "NM"
    },
    {
      city: "WHITE SPRINGS",
      state: "FL"
    },
    {
      city: "WHITE STONE",
      state: "SC"
    },
    {
      city: "WHITE STONE",
      state: "VA"
    },
    {
      city: "WHITE SULPHUR SPRINGS",
      state: "MT"
    },
    {
      city: "WHITE SULPHUR SPRINGS",
      state: "NY"
    },
    {
      city: "WHITE SULPHUR SPRINGS",
      state: "WV"
    },
    {
      city: "WHITE SWAN",
      state: "WA"
    },
    {
      city: "WHITECLAY",
      state: "NE"
    },
    {
      city: "WHITEFACE",
      state: "TX"
    },
    {
      city: "WHITEFIELD",
      state: "ME"
    },
    {
      city: "WHITEFIELD",
      state: "NH"
    },
    {
      city: "WHITEFIELD",
      state: "OK"
    },
    {
      city: "WHITEFISH",
      state: "MT"
    },
    {
      city: "WHITEFORD",
      state: "MD"
    },
    {
      city: "WHITEHALL",
      state: "MI"
    },
    {
      city: "WHITEHALL",
      state: "MT"
    },
    {
      city: "WHITEHALL",
      state: "NY"
    },
    {
      city: "WHITEHALL",
      state: "PA"
    },
    {
      city: "WHITEHALL",
      state: "WI"
    },
    {
      city: "WHITEHOUSE",
      state: "NJ"
    },
    {
      city: "WHITEHOUSE",
      state: "OH"
    },
    {
      city: "WHITEHOUSE",
      state: "TX"
    },
    {
      city: "WHITEHOUSE STATION",
      state: "NJ"
    },
    {
      city: "WHITELAND",
      state: "IN"
    },
    {
      city: "WHITELAW",
      state: "WI"
    },
    {
      city: "WHITEMAN AIR FORCE BASE",
      state: "MO"
    },
    {
      city: "WHITEOAK",
      state: "MO"
    },
    {
      city: "WHITERIVER",
      state: "AZ"
    },
    {
      city: "WHITEROCKS",
      state: "UT"
    },
    {
      city: "WHITES CITY",
      state: "NM"
    },
    {
      city: "WHITES CREEK",
      state: "TN"
    },
    {
      city: "WHITESBORO",
      state: "NJ"
    },
    {
      city: "WHITESBORO",
      state: "NY"
    },
    {
      city: "WHITESBORO",
      state: "TX"
    },
    {
      city: "WHITESBURG",
      state: "GA"
    },
    {
      city: "WHITESBURG",
      state: "KY"
    },
    {
      city: "WHITESBURG",
      state: "TN"
    },
    {
      city: "WHITESIDE",
      state: "MO"
    },
    {
      city: "WHITESTONE",
      state: "NY"
    },
    {
      city: "WHITESTOWN",
      state: "IN"
    },
    {
      city: "WHITESVILLE",
      state: "KY"
    },
    {
      city: "WHITESVILLE",
      state: "NY"
    },
    {
      city: "WHITESVILLE",
      state: "WV"
    },
    {
      city: "WHITETAIL",
      state: "MT"
    },
    {
      city: "WHITETHORN",
      state: "CA"
    },
    {
      city: "WHITETOP",
      state: "VA"
    },
    {
      city: "WHITEVILLE",
      state: "NC"
    },
    {
      city: "WHITEVILLE",
      state: "TN"
    },
    {
      city: "WHITEWATER",
      state: "CA"
    },
    {
      city: "WHITEWATER",
      state: "CO"
    },
    {
      city: "WHITEWATER",
      state: "KS"
    },
    {
      city: "WHITEWATER",
      state: "MO"
    },
    {
      city: "WHITEWATER",
      state: "MT"
    },
    {
      city: "WHITEWATER",
      state: "WI"
    },
    {
      city: "WHITEWOOD",
      state: "SD"
    },
    {
      city: "WHITEWOOD",
      state: "VA"
    },
    {
      city: "WHITEWRIGHT",
      state: "TX"
    },
    {
      city: "WHITFIELD",
      state: "MS"
    },
    {
      city: "WHITING",
      state: "IA"
    },
    {
      city: "WHITING",
      state: "IN"
    },
    {
      city: "WHITING",
      state: "KS"
    },
    {
      city: "WHITING",
      state: "ME"
    },
    {
      city: "WHITING",
      state: "VT"
    },
    {
      city: "WHITINGHAM",
      state: "VT"
    },
    {
      city: "WHITINSVILLE",
      state: "MA"
    },
    {
      city: "WHITLASH",
      state: "MT"
    },
    {
      city: "WHITLEY CITY",
      state: "KY"
    },
    {
      city: "WHITLEYVILLE",
      state: "TN"
    },
    {
      city: "WHITMAN",
      state: "MA"
    },
    {
      city: "WHITMAN",
      state: "NE"
    },
    {
      city: "WHITMAN",
      state: "WV"
    },
    {
      city: "WHITMER",
      state: "WV"
    },
    {
      city: "WHITMIRE",
      state: "SC"
    },
    {
      city: "WHITMORE",
      state: "CA"
    },
    {
      city: "WHITMORE LAKE",
      state: "MI"
    },
    {
      city: "WHITNEY",
      state: "NE"
    },
    {
      city: "WHITNEY",
      state: "PA"
    },
    {
      city: "WHITNEY",
      state: "TX"
    },
    {
      city: "WHITNEY POINT",
      state: "NY"
    },
    {
      city: "WHITSETT",
      state: "NC"
    },
    {
      city: "WHITSETT",
      state: "TX"
    },
    {
      city: "WHITTAKER",
      state: "MI"
    },
    {
      city: "WHITTEMORE",
      state: "IA"
    },
    {
      city: "WHITTEMORE",
      state: "MI"
    },
    {
      city: "WHITTIER",
      state: "AK"
    },
    {
      city: "WHITTIER",
      state: "CA"
    },
    {
      city: "WHITTIER",
      state: "NC"
    },
    {
      city: "WHITTINGTON",
      state: "IL"
    },
    {
      city: "WHITWELL",
      state: "TN"
    },
    {
      city: "WIBAUX",
      state: "MT"
    },
    {
      city: "WICHITA",
      state: "KS"
    },
    {
      city: "WICHITA FALLS",
      state: "TX"
    },
    {
      city: "WICKATUNK",
      state: "NJ"
    },
    {
      city: "WICKENBURG",
      state: "AZ"
    },
    {
      city: "WICKES",
      state: "AR"
    },
    {
      city: "WICKETT",
      state: "TX"
    },
    {
      city: "WICKHAVEN",
      state: "PA"
    },
    {
      city: "WICKLIFFE",
      state: "KY"
    },
    {
      city: "WICKLIFFE",
      state: "OH"
    },
    {
      city: "WICOMICO",
      state: "VA"
    },
    {
      city: "WICOMICO CHURCH",
      state: "VA"
    },
    {
      city: "WICONISCO",
      state: "PA"
    },
    {
      city: "WIDEMAN",
      state: "AR"
    },
    {
      city: "WIDENER",
      state: "AR"
    },
    {
      city: "WIGGINS",
      state: "CO"
    },
    {
      city: "WIGGINS",
      state: "MS"
    },
    {
      city: "WIKIEUP",
      state: "AZ"
    },
    {
      city: "WILBER",
      state: "NE"
    },
    {
      city: "WILBERFORCE",
      state: "OH"
    },
    {
      city: "WILBRAHAM",
      state: "MA"
    },
    {
      city: "WILBUR",
      state: "OR"
    },
    {
      city: "WILBUR",
      state: "WA"
    },
    {
      city: "WILBURN",
      state: "AR"
    },
    {
      city: "WILBURTON",
      state: "OK"
    },
    {
      city: "WILBURTON",
      state: "PA"
    },
    {
      city: "WILCOX",
      state: "NE"
    },
    {
      city: "WILCOX",
      state: "PA"
    },
    {
      city: "WILD ROSE",
      state: "WI"
    },
    {
      city: "WILDER",
      state: "ID"
    },
    {
      city: "WILDER",
      state: "TN"
    },
    {
      city: "WILDER",
      state: "VT"
    },
    {
      city: "WILDERSVILLE",
      state: "TN"
    },
    {
      city: "WILDERVILLE",
      state: "OR"
    },
    {
      city: "WILDOMAR",
      state: "CA"
    },
    {
      city: "WILDORADO",
      state: "TX"
    },
    {
      city: "WILDROSE",
      state: "ND"
    },
    {
      city: "WILDWOOD",
      state: "FL"
    },
    {
      city: "WILDWOOD",
      state: "GA"
    },
    {
      city: "WILDWOOD",
      state: "NJ"
    },
    {
      city: "WILDWOOD",
      state: "PA"
    },
    {
      city: "WILEY",
      state: "CO"
    },
    {
      city: "WILEY",
      state: "GA"
    },
    {
      city: "WILEY FORD",
      state: "WV"
    },
    {
      city: "WILKES BARRE",
      state: "PA"
    },
    {
      city: "WILKESBORO",
      state: "NC"
    },
    {
      city: "WILKESON",
      state: "WA"
    },
    {
      city: "WILKESVILLE",
      state: "OH"
    },
    {
      city: "WILKINSON",
      state: "IN"
    },
    {
      city: "WILKINSON",
      state: "WV"
    },
    {
      city: "WILLACOOCHEE",
      state: "GA"
    },
    {
      city: "WILLAMINA",
      state: "OR"
    },
    {
      city: "WILLARD",
      state: "KY"
    },
    {
      city: "WILLARD",
      state: "MO"
    },
    {
      city: "WILLARD",
      state: "MT"
    },
    {
      city: "WILLARD",
      state: "NC"
    },
    {
      city: "WILLARD",
      state: "NM"
    },
    {
      city: "WILLARD",
      state: "NY"
    },
    {
      city: "WILLARD",
      state: "OH"
    },
    {
      city: "WILLARD",
      state: "UT"
    },
    {
      city: "WILLARD",
      state: "WI"
    },
    {
      city: "WILLARDS",
      state: "MD"
    },
    {
      city: "WILLCOX",
      state: "AZ"
    },
    {
      city: "WILLERNIE",
      state: "MN"
    },
    {
      city: "WILLET",
      state: "NY"
    },
    {
      city: "WILLIAMS",
      state: "AZ"
    },
    {
      city: "WILLIAMS",
      state: "CA"
    },
    {
      city: "WILLIAMS",
      state: "IA"
    },
    {
      city: "WILLIAMS",
      state: "IN"
    },
    {
      city: "WILLIAMS",
      state: "MN"
    },
    {
      city: "WILLIAMS",
      state: "OR"
    },
    {
      city: "WILLIAMS",
      state: "SC"
    },
    {
      city: "WILLIAMS BAY",
      state: "WI"
    },
    {
      city: "WILLIAMSBURG",
      state: "IA"
    },
    {
      city: "WILLIAMSBURG",
      state: "IN"
    },
    {
      city: "WILLIAMSBURG",
      state: "KS"
    },
    {
      city: "WILLIAMSBURG",
      state: "KY"
    },
    {
      city: "WILLIAMSBURG",
      state: "MA"
    },
    {
      city: "WILLIAMSBURG",
      state: "MI"
    },
    {
      city: "WILLIAMSBURG",
      state: "MO"
    },
    {
      city: "WILLIAMSBURG",
      state: "NM"
    },
    {
      city: "WILLIAMSBURG",
      state: "OH"
    },
    {
      city: "WILLIAMSBURG",
      state: "PA"
    },
    {
      city: "WILLIAMSBURG",
      state: "VA"
    },
    {
      city: "WILLIAMSBURG",
      state: "WV"
    },
    {
      city: "WILLIAMSFIELD",
      state: "IL"
    },
    {
      city: "WILLIAMSFIELD",
      state: "OH"
    },
    {
      city: "WILLIAMSON",
      state: "GA"
    },
    {
      city: "WILLIAMSON",
      state: "IA"
    },
    {
      city: "WILLIAMSON",
      state: "NY"
    },
    {
      city: "WILLIAMSON",
      state: "WV"
    },
    {
      city: "WILLIAMSPORT",
      state: "IN"
    },
    {
      city: "WILLIAMSPORT",
      state: "KY"
    },
    {
      city: "WILLIAMSPORT",
      state: "MD"
    },
    {
      city: "WILLIAMSPORT",
      state: "OH"
    },
    {
      city: "WILLIAMSPORT",
      state: "PA"
    },
    {
      city: "WILLIAMSPORT",
      state: "TN"
    },
    {
      city: "WILLIAMSTON",
      state: "MI"
    },
    {
      city: "WILLIAMSTON",
      state: "NC"
    },
    {
      city: "WILLIAMSTON",
      state: "SC"
    },
    {
      city: "WILLIAMSTOWN",
      state: "KY"
    },
    {
      city: "WILLIAMSTOWN",
      state: "MA"
    },
    {
      city: "WILLIAMSTOWN",
      state: "MO"
    },
    {
      city: "WILLIAMSTOWN",
      state: "NJ"
    },
    {
      city: "WILLIAMSTOWN",
      state: "NY"
    },
    {
      city: "WILLIAMSTOWN",
      state: "PA"
    },
    {
      city: "WILLIAMSTOWN",
      state: "VT"
    },
    {
      city: "WILLIAMSTOWN",
      state: "WV"
    },
    {
      city: "WILLIAMSVILLE",
      state: "IL"
    },
    {
      city: "WILLIAMSVILLE",
      state: "MO"
    },
    {
      city: "WILLIAMSVILLE",
      state: "VA"
    },
    {
      city: "WILLIAMSVILLE",
      state: "VT"
    },
    {
      city: "WILLIFORD",
      state: "AR"
    },
    {
      city: "WILLIMANTIC",
      state: "CT"
    },
    {
      city: "WILLINGBORO",
      state: "NJ"
    },
    {
      city: "WILLINGTON",
      state: "CT"
    },
    {
      city: "WILLIS",
      state: "MI"
    },
    {
      city: "WILLIS",
      state: "TX"
    },
    {
      city: "WILLIS",
      state: "VA"
    },
    {
      city: "WILLIS WHARF",
      state: "VA"
    },
    {
      city: "WILLISBURG",
      state: "KY"
    },
    {
      city: "WILLISTON",
      state: "FL"
    },
    {
      city: "WILLISTON",
      state: "NC"
    },
    {
      city: "WILLISTON",
      state: "ND"
    },
    {
      city: "WILLISTON",
      state: "OH"
    },
    {
      city: "WILLISTON",
      state: "SC"
    },
    {
      city: "WILLISTON",
      state: "TN"
    },
    {
      city: "WILLISTON",
      state: "VT"
    },
    {
      city: "WILLISTON PARK",
      state: "NY"
    },
    {
      city: "WILLISVILLE",
      state: "AR"
    },
    {
      city: "WILLISVILLE",
      state: "IL"
    },
    {
      city: "WILLITS",
      state: "CA"
    },
    {
      city: "WILLMAR",
      state: "MN"
    },
    {
      city: "WILLOUGHBY",
      state: "OH"
    },
    {
      city: "WILLOW",
      state: "AK"
    },
    {
      city: "WILLOW",
      state: "NY"
    },
    {
      city: "WILLOW",
      state: "OK"
    },
    {
      city: "WILLOW BEACH",
      state: "AZ"
    },
    {
      city: "WILLOW CITY",
      state: "ND"
    },
    {
      city: "WILLOW CITY",
      state: "TX"
    },
    {
      city: "WILLOW CREEK",
      state: "CA"
    },
    {
      city: "WILLOW CREEK",
      state: "MT"
    },
    {
      city: "WILLOW GROVE",
      state: "PA"
    },
    {
      city: "WILLOW HILL",
      state: "IL"
    },
    {
      city: "WILLOW HILL",
      state: "PA"
    },
    {
      city: "WILLOW ISLAND",
      state: "NE"
    },
    {
      city: "WILLOW LAKE",
      state: "SD"
    },
    {
      city: "WILLOW RIVER",
      state: "MN"
    },
    {
      city: "WILLOW SPRING",
      state: "NC"
    },
    {
      city: "WILLOW SPRINGS",
      state: "IL"
    },
    {
      city: "WILLOW SPRINGS",
      state: "MO"
    },
    {
      city: "WILLOW STREET",
      state: "PA"
    },
    {
      city: "WILLOW WOOD",
      state: "OH"
    },
    {
      city: "WILLOWBROOK",
      state: "IL"
    },
    {
      city: "WILLOWS",
      state: "CA"
    },
    {
      city: "WILLS POINT",
      state: "TX"
    },
    {
      city: "WILLSBORO",
      state: "NY"
    },
    {
      city: "WILLSEYVILLE",
      state: "NY"
    },
    {
      city: "WILLSHIRE",
      state: "OH"
    },
    {
      city: "WILMAR",
      state: "AR"
    },
    {
      city: "WILMER",
      state: "AL"
    },
    {
      city: "WILMER",
      state: "TX"
    },
    {
      city: "WILMERDING",
      state: "PA"
    },
    {
      city: "WILMETTE",
      state: "IL"
    },
    {
      city: "WILMINGTON",
      state: "CA"
    },
    {
      city: "WILMINGTON",
      state: "DE"
    },
    {
      city: "WILMINGTON",
      state: "IL"
    },
    {
      city: "WILMINGTON",
      state: "MA"
    },
    {
      city: "WILMINGTON",
      state: "NC"
    },
    {
      city: "WILMINGTON",
      state: "NY"
    },
    {
      city: "WILMINGTON",
      state: "OH"
    },
    {
      city: "WILMINGTON",
      state: "VT"
    },
    {
      city: "WILMONT",
      state: "MN"
    },
    {
      city: "WILMORE",
      state: "KS"
    },
    {
      city: "WILMORE",
      state: "KY"
    },
    {
      city: "WILMORE",
      state: "PA"
    },
    {
      city: "WILMOT",
      state: "AR"
    },
    {
      city: "WILMOT",
      state: "NH"
    },
    {
      city: "WILMOT",
      state: "OH"
    },
    {
      city: "WILMOT",
      state: "SD"
    },
    {
      city: "WILMOT",
      state: "WI"
    },
    {
      city: "WILSALL",
      state: "MT"
    },
    {
      city: "WILSEY",
      state: "KS"
    },
    {
      city: "WILSEYVILLE",
      state: "CA"
    },
    {
      city: "WILSON",
      state: "AR"
    },
    {
      city: "WILSON",
      state: "KS"
    },
    {
      city: "WILSON",
      state: "LA"
    },
    {
      city: "WILSON",
      state: "MI"
    },
    {
      city: "WILSON",
      state: "NC"
    },
    {
      city: "WILSON",
      state: "NY"
    },
    {
      city: "WILSON",
      state: "OK"
    },
    {
      city: "WILSON",
      state: "TX"
    },
    {
      city: "WILSON",
      state: "WI"
    },
    {
      city: "WILSON",
      state: "WY"
    },
    {
      city: "WILSON CREEK",
      state: "WA"
    },
    {
      city: "WILSONS",
      state: "VA"
    },
    {
      city: "WILSONS MILLS",
      state: "NC"
    },
    {
      city: "WILSONVILLE",
      state: "AL"
    },
    {
      city: "WILSONVILLE",
      state: "IL"
    },
    {
      city: "WILSONVILLE",
      state: "NE"
    },
    {
      city: "WILSONVILLE",
      state: "OR"
    },
    {
      city: "WILTON",
      state: "AL"
    },
    {
      city: "WILTON",
      state: "AR"
    },
    {
      city: "WILTON",
      state: "CA"
    },
    {
      city: "WILTON",
      state: "CT"
    },
    {
      city: "WILTON",
      state: "IA"
    },
    {
      city: "WILTON",
      state: "ME"
    },
    {
      city: "WILTON",
      state: "ND"
    },
    {
      city: "WILTON",
      state: "NH"
    },
    {
      city: "WILTON",
      state: "WI"
    },
    {
      city: "WIMAUMA",
      state: "FL"
    },
    {
      city: "WIMBERLEY",
      state: "TX"
    },
    {
      city: "WIMBLEDON",
      state: "ND"
    },
    {
      city: "WINAMAC",
      state: "IN"
    },
    {
      city: "WINBURNE",
      state: "PA"
    },
    {
      city: "WINCHENDON",
      state: "MA"
    },
    {
      city: "WINCHENDON SPRINGS",
      state: "MA"
    },
    {
      city: "WINCHESTER",
      state: "AR"
    },
    {
      city: "WINCHESTER",
      state: "CA"
    },
    {
      city: "WINCHESTER",
      state: "ID"
    },
    {
      city: "WINCHESTER",
      state: "IL"
    },
    {
      city: "WINCHESTER",
      state: "IN"
    },
    {
      city: "WINCHESTER",
      state: "KS"
    },
    {
      city: "WINCHESTER",
      state: "KY"
    },
    {
      city: "WINCHESTER",
      state: "MA"
    },
    {
      city: "WINCHESTER",
      state: "NH"
    },
    {
      city: "WINCHESTER",
      state: "OH"
    },
    {
      city: "WINCHESTER",
      state: "OR"
    },
    {
      city: "WINCHESTER",
      state: "TN"
    },
    {
      city: "WINCHESTER",
      state: "VA"
    },
    {
      city: "WINCHESTER CENTER",
      state: "CT"
    },
    {
      city: "WIND GAP",
      state: "PA"
    },
    {
      city: "WIND RIDGE",
      state: "PA"
    },
    {
      city: "WINDBER",
      state: "PA"
    },
    {
      city: "WINDER",
      state: "GA"
    },
    {
      city: "WINDERMERE",
      state: "FL"
    },
    {
      city: "WINDFALL",
      state: "IN"
    },
    {
      city: "WINDHAM",
      state: "CT"
    },
    {
      city: "WINDHAM",
      state: "ME"
    },
    {
      city: "WINDHAM",
      state: "NH"
    },
    {
      city: "WINDHAM",
      state: "NY"
    },
    {
      city: "WINDHAM",
      state: "OH"
    },
    {
      city: "WINDOM",
      state: "KS"
    },
    {
      city: "WINDOM",
      state: "MN"
    },
    {
      city: "WINDOM",
      state: "TX"
    },
    {
      city: "WINDOW ROCK",
      state: "AZ"
    },
    {
      city: "WINDSOR",
      state: "CA"
    },
    {
      city: "WINDSOR",
      state: "CO"
    },
    {
      city: "WINDSOR",
      state: "CT"
    },
    {
      city: "WINDSOR",
      state: "IL"
    },
    {
      city: "WINDSOR",
      state: "KY"
    },
    {
      city: "WINDSOR",
      state: "MA"
    },
    {
      city: "WINDSOR",
      state: "ME"
    },
    {
      city: "WINDSOR",
      state: "MO"
    },
    {
      city: "WINDSOR",
      state: "NC"
    },
    {
      city: "WINDSOR",
      state: "NJ"
    },
    {
      city: "WINDSOR",
      state: "NY"
    },
    {
      city: "WINDSOR",
      state: "OH"
    },
    {
      city: "WINDSOR",
      state: "PA"
    },
    {
      city: "WINDSOR",
      state: "SC"
    },
    {
      city: "WINDSOR",
      state: "VA"
    },
    {
      city: "WINDSOR",
      state: "VT"
    },
    {
      city: "WINDSOR",
      state: "WI"
    },
    {
      city: "WINDSOR HEIGHTS",
      state: "IA"
    },
    {
      city: "WINDSOR HEIGHTS",
      state: "WV"
    },
    {
      city: "WINDSOR LOCKS",
      state: "CT"
    },
    {
      city: "WINDSOR MILL",
      state: "MD"
    },
    {
      city: "WINDTHORST",
      state: "TX"
    },
    {
      city: "WINDYVILLE",
      state: "MO"
    },
    {
      city: "WINESBURG",
      state: "OH"
    },
    {
      city: "WINFALL",
      state: "NC"
    },
    {
      city: "WINFIELD",
      state: "AL"
    },
    {
      city: "WINFIELD",
      state: "IA"
    },
    {
      city: "WINFIELD",
      state: "IL"
    },
    {
      city: "WINFIELD",
      state: "KS"
    },
    {
      city: "WINFIELD",
      state: "MO"
    },
    {
      city: "WINFIELD",
      state: "PA"
    },
    {
      city: "WINFIELD",
      state: "TN"
    },
    {
      city: "WINFIELD",
      state: "TX"
    },
    {
      city: "WINFIELD",
      state: "WV"
    },
    {
      city: "WINFRED",
      state: "SD"
    },
    {
      city: "WING",
      state: "AL"
    },
    {
      city: "WING",
      state: "ND"
    },
    {
      city: "WINGATE",
      state: "IN"
    },
    {
      city: "WINGATE",
      state: "NC"
    },
    {
      city: "WINGATE",
      state: "TX"
    },
    {
      city: "WINGDALE",
      state: "NY"
    },
    {
      city: "WINGER",
      state: "MN"
    },
    {
      city: "WINGETT RUN",
      state: "OH"
    },
    {
      city: "WINGINA",
      state: "VA"
    },
    {
      city: "WINGO",
      state: "KY"
    },
    {
      city: "WINIFRED",
      state: "MT"
    },
    {
      city: "WINIFREDE",
      state: "WV"
    },
    {
      city: "WINIGAN",
      state: "MO"
    },
    {
      city: "WINK",
      state: "TX"
    },
    {
      city: "WINKELMAN",
      state: "AZ"
    },
    {
      city: "WINLOCK",
      state: "WA"
    },
    {
      city: "WINN",
      state: "ME"
    },
    {
      city: "WINN",
      state: "MI"
    },
    {
      city: "WINNABOW",
      state: "NC"
    },
    {
      city: "WINNEBAGO",
      state: "IL"
    },
    {
      city: "WINNEBAGO",
      state: "MN"
    },
    {
      city: "WINNEBAGO",
      state: "NE"
    },
    {
      city: "WINNEBAGO",
      state: "WI"
    },
    {
      city: "WINNECONNE",
      state: "WI"
    },
    {
      city: "WINNEMUCCA",
      state: "NV"
    },
    {
      city: "WINNER",
      state: "SD"
    },
    {
      city: "WINNETKA",
      state: "CA"
    },
    {
      city: "WINNETKA",
      state: "IL"
    },
    {
      city: "WINNETOON",
      state: "NE"
    },
    {
      city: "WINNETT",
      state: "MT"
    },
    {
      city: "WINNFIELD",
      state: "LA"
    },
    {
      city: "WINNIE",
      state: "TX"
    },
    {
      city: "WINNISQUAM",
      state: "NH"
    },
    {
      city: "WINNSBORO",
      state: "LA"
    },
    {
      city: "WINNSBORO",
      state: "SC"
    },
    {
      city: "WINNSBORO",
      state: "TX"
    },
    {
      city: "WINONA",
      state: "KS"
    },
    {
      city: "WINONA",
      state: "MN"
    },
    {
      city: "WINONA",
      state: "MO"
    },
    {
      city: "WINONA",
      state: "MS"
    },
    {
      city: "WINONA",
      state: "OH"
    },
    {
      city: "WINONA",
      state: "TX"
    },
    {
      city: "WINONA",
      state: "WV"
    },
    {
      city: "WINONA LAKE",
      state: "IN"
    },
    {
      city: "WINOOSKI",
      state: "VT"
    },
    {
      city: "WINSIDE",
      state: "NE"
    },
    {
      city: "WINSLOW",
      state: "AR"
    },
    {
      city: "WINSLOW",
      state: "AZ"
    },
    {
      city: "WINSLOW",
      state: "IL"
    },
    {
      city: "WINSLOW",
      state: "IN"
    },
    {
      city: "WINSLOW",
      state: "NE"
    },
    {
      city: "WINSLOW",
      state: "NJ"
    },
    {
      city: "WINSTED",
      state: "CT"
    },
    {
      city: "WINSTED",
      state: "MN"
    },
    {
      city: "WINSTON",
      state: "GA"
    },
    {
      city: "WINSTON",
      state: "MO"
    },
    {
      city: "WINSTON",
      state: "MT"
    },
    {
      city: "WINSTON",
      state: "NM"
    },
    {
      city: "WINSTON",
      state: "OR"
    },
    {
      city: "WINSTON SALEM",
      state: "NC"
    },
    {
      city: "WINSTONVILLE",
      state: "MS"
    },
    {
      city: "WINTER",
      state: "WI"
    },
    {
      city: "WINTER BEACH",
      state: "FL"
    },
    {
      city: "WINTER GARDEN",
      state: "FL"
    },
    {
      city: "WINTER HARBOR",
      state: "ME"
    },
    {
      city: "WINTER HAVEN",
      state: "FL"
    },
    {
      city: "WINTER PARK",
      state: "CO"
    },
    {
      city: "WINTER PARK",
      state: "FL"
    },
    {
      city: "WINTER SPRINGS",
      state: "FL"
    },
    {
      city: "WINTERHAVEN",
      state: "CA"
    },
    {
      city: "WINTERPORT",
      state: "ME"
    },
    {
      city: "WINTERS",
      state: "CA"
    },
    {
      city: "WINTERS",
      state: "TX"
    },
    {
      city: "WINTERSET",
      state: "IA"
    },
    {
      city: "WINTERTHUR",
      state: "DE"
    },
    {
      city: "WINTERVILLE",
      state: "GA"
    },
    {
      city: "WINTERVILLE",
      state: "MS"
    },
    {
      city: "WINTERVILLE",
      state: "NC"
    },
    {
      city: "WINTHROP",
      state: "AR"
    },
    {
      city: "WINTHROP",
      state: "IA"
    },
    {
      city: "WINTHROP",
      state: "MA"
    },
    {
      city: "WINTHROP",
      state: "ME"
    },
    {
      city: "WINTHROP",
      state: "MN"
    },
    {
      city: "WINTHROP",
      state: "NY"
    },
    {
      city: "WINTHROP",
      state: "WA"
    },
    {
      city: "WINTHROP HARBOR",
      state: "IL"
    },
    {
      city: "WINTON",
      state: "CA"
    },
    {
      city: "WINTON",
      state: "MN"
    },
    {
      city: "WINTON",
      state: "NC"
    },
    {
      city: "WIOTA",
      state: "IA"
    },
    {
      city: "WIRTZ",
      state: "VA"
    },
    {
      city: "WISCASSET",
      state: "ME"
    },
    {
      city: "WISCONSIN DELLS",
      state: "WI"
    },
    {
      city: "WISCONSIN RAPIDS",
      state: "WI"
    },
    {
      city: "WISDOM",
      state: "MT"
    },
    {
      city: "WISE",
      state: "NC"
    },
    {
      city: "WISE",
      state: "VA"
    },
    {
      city: "WISE RIVER",
      state: "MT"
    },
    {
      city: "WISHEK",
      state: "ND"
    },
    {
      city: "WISHON",
      state: "CA"
    },
    {
      city: "WISHRAM",
      state: "WA"
    },
    {
      city: "WISNER",
      state: "LA"
    },
    {
      city: "WISNER",
      state: "NE"
    },
    {
      city: "WISTER",
      state: "OK"
    },
    {
      city: "WITHEE",
      state: "WI"
    },
    {
      city: "WITHERBEE",
      state: "NY"
    },
    {
      city: "WITMER",
      state: "PA"
    },
    {
      city: "WITT",
      state: "IL"
    },
    {
      city: "WITTEN",
      state: "SD"
    },
    {
      city: "WITTENBERG",
      state: "WI"
    },
    {
      city: "WITTENSVILLE",
      state: "KY"
    },
    {
      city: "WITTER",
      state: "AR"
    },
    {
      city: "WITTER SPRINGS",
      state: "CA"
    },
    {
      city: "WITTMAN",
      state: "MD"
    },
    {
      city: "WITTMANN",
      state: "AZ"
    },
    {
      city: "WIXOM",
      state: "MI"
    },
    {
      city: "WOBURN",
      state: "MA"
    },
    {
      city: "WODEN",
      state: "IA"
    },
    {
      city: "WODEN",
      state: "TX"
    },
    {
      city: "WOFFORD HEIGHTS",
      state: "CA"
    },
    {
      city: "WOLBACH",
      state: "NE"
    },
    {
      city: "WOLCOTT",
      state: "CO"
    },
    {
      city: "WOLCOTT",
      state: "CT"
    },
    {
      city: "WOLCOTT",
      state: "IN"
    },
    {
      city: "WOLCOTT",
      state: "NY"
    },
    {
      city: "WOLCOTT",
      state: "VT"
    },
    {
      city: "WOLCOTTVILLE",
      state: "IN"
    },
    {
      city: "WOLF",
      state: "WY"
    },
    {
      city: "WOLF CREEK",
      state: "MT"
    },
    {
      city: "WOLF CREEK",
      state: "OR"
    },
    {
      city: "WOLF LAKE",
      state: "IL"
    },
    {
      city: "WOLF LAKE",
      state: "MN"
    },
    {
      city: "WOLF POINT",
      state: "MT"
    },
    {
      city: "WOLFCREEK",
      state: "WV"
    },
    {
      city: "WOLFE",
      state: "WV"
    },
    {
      city: "WOLFE CITY",
      state: "TX"
    },
    {
      city: "WOLFEBORO",
      state: "NH"
    },
    {
      city: "WOLFEBORO FALLS",
      state: "NH"
    },
    {
      city: "WOLFFORTH",
      state: "TX"
    },
    {
      city: "WOLFLAKE",
      state: "IN"
    },
    {
      city: "WOLFORD",
      state: "ND"
    },
    {
      city: "WOLFTOWN",
      state: "VA"
    },
    {
      city: "WOLSEY",
      state: "SD"
    },
    {
      city: "WOLVERINE",
      state: "MI"
    },
    {
      city: "WOLVERTON",
      state: "MN"
    },
    {
      city: "WOMELSDORF",
      state: "PA"
    },
    {
      city: "WONALANCET",
      state: "NH"
    },
    {
      city: "WONDER LAKE",
      state: "IL"
    },
    {
      city: "WONEWOC",
      state: "WI"
    },
    {
      city: "WOOD",
      state: "SD"
    },
    {
      city: "WOOD DALE",
      state: "IL"
    },
    {
      city: "WOOD LAKE",
      state: "MN"
    },
    {
      city: "WOOD LAKE",
      state: "NE"
    },
    {
      city: "WOOD RIDGE",
      state: "NJ"
    },
    {
      city: "WOOD RIVER",
      state: "IL"
    },
    {
      city: "WOOD RIVER",
      state: "NE"
    },
    {
      city: "WOOD RIVER JUNCTION",
      state: "RI"
    },
    {
      city: "WOODACRE",
      state: "CA"
    },
    {
      city: "WOODBERRY FOREST",
      state: "VA"
    },
    {
      city: "WOODBINE",
      state: "GA"
    },
    {
      city: "WOODBINE",
      state: "IA"
    },
    {
      city: "WOODBINE",
      state: "KS"
    },
    {
      city: "WOODBINE",
      state: "KY"
    },
    {
      city: "WOODBINE",
      state: "MD"
    },
    {
      city: "WOODBINE",
      state: "NJ"
    },
    {
      city: "WOODBOURNE",
      state: "NY"
    },
    {
      city: "WOODBRIDGE",
      state: "CA"
    },
    {
      city: "WOODBRIDGE",
      state: "NJ"
    },
    {
      city: "WOODBRIDGE",
      state: "VA"
    },
    {
      city: "WOODBURN",
      state: "IN"
    },
    {
      city: "WOODBURN",
      state: "KY"
    },
    {
      city: "WOODBURN",
      state: "OR"
    },
    {
      city: "WOODBURY",
      state: "CT"
    },
    {
      city: "WOODBURY",
      state: "GA"
    },
    {
      city: "WOODBURY",
      state: "NY"
    },
    {
      city: "WOODBURY",
      state: "PA"
    },
    {
      city: "WOODBURY",
      state: "TN"
    },
    {
      city: "WOODBURY",
      state: "VT"
    },
    {
      city: "WOODBURY HEIGHTS",
      state: "NJ"
    },
    {
      city: "WOODCLIFF LAKE",
      state: "NJ"
    },
    {
      city: "WOODFORD",
      state: "VA"
    },
    {
      city: "WOODFORD",
      state: "WI"
    },
    {
      city: "WOODGATE",
      state: "NY"
    },
    {
      city: "WOODHAVEN",
      state: "NY"
    },
    {
      city: "WOODHULL",
      state: "IL"
    },
    {
      city: "WOODHULL",
      state: "NY"
    },
    {
      city: "WOODINVILLE",
      state: "WA"
    },
    {
      city: "WOODLAKE",
      state: "CA"
    },
    {
      city: "WOODLAKE",
      state: "TX"
    },
    {
      city: "WOODLAND",
      state: "AL"
    },
    {
      city: "WOODLAND",
      state: "CA"
    },
    {
      city: "WOODLAND",
      state: "GA"
    },
    {
      city: "WOODLAND",
      state: "MI"
    },
    {
      city: "WOODLAND",
      state: "MS"
    },
    {
      city: "WOODLAND",
      state: "NC"
    },
    {
      city: "WOODLAND",
      state: "PA"
    },
    {
      city: "WOODLAND",
      state: "WA"
    },
    {
      city: "WOODLAND",
      state: "WI"
    },
    {
      city: "WOODLAND HILLS",
      state: "CA"
    },
    {
      city: "WOODLAND MILLS",
      state: "TN"
    },
    {
      city: "WOODLAND PARK",
      state: "CO"
    },
    {
      city: "WOODLAWN",
      state: "IL"
    },
    {
      city: "WOODLAWN",
      state: "TN"
    },
    {
      city: "WOODLAWN",
      state: "TX"
    },
    {
      city: "WOODLAWN",
      state: "VA"
    },
    {
      city: "WOODLEAF",
      state: "NC"
    },
    {
      city: "WOODLYN",
      state: "PA"
    },
    {
      city: "WOODMAN",
      state: "WI"
    },
    {
      city: "WOODMERE",
      state: "NY"
    },
    {
      city: "WOODRIDGE",
      state: "IL"
    },
    {
      city: "WOODRIDGE",
      state: "NY"
    },
    {
      city: "WOODROW",
      state: "CO"
    },
    {
      city: "WOODRUFF",
      state: "AZ"
    },
    {
      city: "WOODRUFF",
      state: "SC"
    },
    {
      city: "WOODRUFF",
      state: "UT"
    },
    {
      city: "WOODRUFF",
      state: "WI"
    },
    {
      city: "WOODS CROSS",
      state: "UT"
    },
    {
      city: "WOODS CROSS ROADS",
      state: "VA"
    },
    {
      city: "WOODS HOLE",
      state: "MA"
    },
    {
      city: "WOODSBORO",
      state: "MD"
    },
    {
      city: "WOODSBORO",
      state: "TX"
    },
    {
      city: "WOODSFIELD",
      state: "OH"
    },
    {
      city: "WOODSIDE",
      state: "DE"
    },
    {
      city: "WOODSIDE",
      state: "NY"
    },
    {
      city: "WOODSON",
      state: "AR"
    },
    {
      city: "WOODSON",
      state: "IL"
    },
    {
      city: "WOODSON",
      state: "TX"
    },
    {
      city: "WOODSTOCK",
      state: "AL"
    },
    {
      city: "WOODSTOCK",
      state: "CT"
    },
    {
      city: "WOODSTOCK",
      state: "GA"
    },
    {
      city: "WOODSTOCK",
      state: "IL"
    },
    {
      city: "WOODSTOCK",
      state: "MD"
    },
    {
      city: "WOODSTOCK",
      state: "MN"
    },
    {
      city: "WOODSTOCK",
      state: "NH"
    },
    {
      city: "WOODSTOCK",
      state: "NY"
    },
    {
      city: "WOODSTOCK",
      state: "OH"
    },
    {
      city: "WOODSTOCK",
      state: "VA"
    },
    {
      city: "WOODSTOCK",
      state: "VT"
    },
    {
      city: "WOODSTOCK VALLEY",
      state: "CT"
    },
    {
      city: "WOODSTON",
      state: "KS"
    },
    {
      city: "WOODSTOWN",
      state: "NJ"
    },
    {
      city: "WOODSVILLE",
      state: "NH"
    },
    {
      city: "WOODVILLE",
      state: "AL"
    },
    {
      city: "WOODVILLE",
      state: "FL"
    },
    {
      city: "WOODVILLE",
      state: "MA"
    },
    {
      city: "WOODVILLE",
      state: "MS"
    },
    {
      city: "WOODVILLE",
      state: "OH"
    },
    {
      city: "WOODVILLE",
      state: "TX"
    },
    {
      city: "WOODVILLE",
      state: "VA"
    },
    {
      city: "WOODVILLE",
      state: "WI"
    },
    {
      city: "WOODWARD",
      state: "IA"
    },
    {
      city: "WOODWARD",
      state: "OK"
    },
    {
      city: "WOODWARD",
      state: "PA"
    },
    {
      city: "WOODWAY",
      state: "TX"
    },
    {
      city: "WOODWORTH",
      state: "LA"
    },
    {
      city: "WOODWORTH",
      state: "ND"
    },
    {
      city: "WOODY CREEK",
      state: "CO"
    },
    {
      city: "WOOLDRIDGE",
      state: "MO"
    },
    {
      city: "WOOLFORD",
      state: "MD"
    },
    {
      city: "WOOLRICH",
      state: "PA"
    },
    {
      city: "WOOLSTOCK",
      state: "IA"
    },
    {
      city: "WOOLWICH",
      state: "ME"
    },
    {
      city: "WOOLWINE",
      state: "VA"
    },
    {
      city: "WOONSOCKET",
      state: "RI"
    },
    {
      city: "WOONSOCKET",
      state: "SD"
    },
    {
      city: "WOOSTER",
      state: "AR"
    },
    {
      city: "WOOSTER",
      state: "OH"
    },
    {
      city: "WOOSUNG",
      state: "IL"
    },
    {
      city: "WOOTON",
      state: "KY"
    },
    {
      city: "WORCESTER",
      state: "MA"
    },
    {
      city: "WORCESTER",
      state: "NY"
    },
    {
      city: "WORCESTER",
      state: "PA"
    },
    {
      city: "WORCESTER",
      state: "VT"
    },
    {
      city: "WORDEN",
      state: "IL"
    },
    {
      city: "WORDEN",
      state: "MT"
    },
    {
      city: "WORLAND",
      state: "WY"
    },
    {
      city: "WORLEY",
      state: "ID"
    },
    {
      city: "WORTH",
      state: "IL"
    },
    {
      city: "WORTHAM",
      state: "TX"
    },
    {
      city: "WORTHING",
      state: "SD"
    },
    {
      city: "WORTHINGTON",
      state: "IA"
    },
    {
      city: "WORTHINGTON",
      state: "IN"
    },
    {
      city: "WORTHINGTON",
      state: "KY"
    },
    {
      city: "WORTHINGTON",
      state: "MA"
    },
    {
      city: "WORTHINGTON",
      state: "MN"
    },
    {
      city: "WORTHINGTON",
      state: "MO"
    },
    {
      city: "WORTHINGTON",
      state: "PA"
    },
    {
      city: "WORTHINGTON",
      state: "WV"
    },
    {
      city: "WORTHINGTON SPRINGS",
      state: "FL"
    },
    {
      city: "WORTHVILLE",
      state: "KY"
    },
    {
      city: "WORTHVILLE",
      state: "PA"
    },
    {
      city: "WORTON",
      state: "MD"
    },
    {
      city: "WOXALL",
      state: "PA"
    },
    {
      city: "WRANGELL",
      state: "AK"
    },
    {
      city: "WRAY",
      state: "CO"
    },
    {
      city: "WRAY",
      state: "GA"
    },
    {
      city: "WREN",
      state: "OH"
    },
    {
      city: "WRENS",
      state: "GA"
    },
    {
      city: "WRENSHALL",
      state: "MN"
    },
    {
      city: "WRENTHAM",
      state: "MA"
    },
    {
      city: "WRIGHT",
      state: "AR"
    },
    {
      city: "WRIGHT",
      state: "KS"
    },
    {
      city: "WRIGHT",
      state: "MN"
    },
    {
      city: "WRIGHT",
      state: "WY"
    },
    {
      city: "WRIGHT CITY",
      state: "MO"
    },
    {
      city: "WRIGHT CITY",
      state: "OK"
    },
    {
      city: "WRIGHTS",
      state: "IL"
    },
    {
      city: "WRIGHTSTOWN",
      state: "NJ"
    },
    {
      city: "WRIGHTSTOWN",
      state: "WI"
    },
    {
      city: "WRIGHTSVILLE",
      state: "AR"
    },
    {
      city: "WRIGHTSVILLE",
      state: "GA"
    },
    {
      city: "WRIGHTSVILLE",
      state: "PA"
    },
    {
      city: "WRIGHTSVILLE BEACH",
      state: "NC"
    },
    {
      city: "WRIGHTWOOD",
      state: "CA"
    },
    {
      city: "WRIGLEY",
      state: "KY"
    },
    {
      city: "WURTSBORO",
      state: "NY"
    },
    {
      city: "WYACONDA",
      state: "MO"
    },
    {
      city: "WYALUSING",
      state: "PA"
    },
    {
      city: "WYANDANCH",
      state: "NY"
    },
    {
      city: "WYANDOTTE",
      state: "MI"
    },
    {
      city: "WYANDOTTE",
      state: "OK"
    },
    {
      city: "WYANET",
      state: "IL"
    },
    {
      city: "WYANO",
      state: "PA"
    },
    {
      city: "WYARNO",
      state: "WY"
    },
    {
      city: "WYATT",
      state: "IN"
    },
    {
      city: "WYATT",
      state: "MO"
    },
    {
      city: "WYCKOFF",
      state: "NJ"
    },
    {
      city: "WYCOMBE",
      state: "PA"
    },
    {
      city: "WYE MILLS",
      state: "MD"
    },
    {
      city: "WYKOFF",
      state: "MN"
    },
    {
      city: "WYLIE",
      state: "TX"
    },
    {
      city: "WYLLIESBURG",
      state: "VA"
    },
    {
      city: "WYMORE",
      state: "NE"
    },
    {
      city: "WYNANTSKILL",
      state: "NY"
    },
    {
      city: "WYNCOTE",
      state: "PA"
    },
    {
      city: "WYNDMERE",
      state: "ND"
    },
    {
      city: "WYNNBURG",
      state: "TN"
    },
    {
      city: "WYNNE",
      state: "AR"
    },
    {
      city: "WYNNEWOOD",
      state: "OK"
    },
    {
      city: "WYNNEWOOD",
      state: "PA"
    },
    {
      city: "WYNONA",
      state: "OK"
    },
    {
      city: "WYNOT",
      state: "NE"
    },
    {
      city: "WYOCENA",
      state: "WI"
    },
    {
      city: "WYOMING",
      state: "IA"
    },
    {
      city: "WYOMING",
      state: "IL"
    },
    {
      city: "WYOMING",
      state: "MI"
    },
    {
      city: "WYOMING",
      state: "MN"
    },
    {
      city: "WYOMING",
      state: "NY"
    },
    {
      city: "WYOMING",
      state: "PA"
    },
    {
      city: "WYOMING",
      state: "RI"
    },
    {
      city: "WYSOX",
      state: "PA"
    },
    {
      city: "WYTHEVILLE",
      state: "VA"
    },
    {
      city: "WYTOPITLOCK",
      state: "ME"
    },
    {
      city: "XENIA",
      state: "IL"
    },
    {
      city: "XENIA",
      state: "OH"
    },
    {
      city: "YACHATS",
      state: "OR"
    },
    {
      city: "YACOLT",
      state: "WA"
    },
    {
      city: "YADKINVILLE",
      state: "NC"
    },
    {
      city: "YAKIMA",
      state: "WA"
    },
    {
      city: "YAKUTAT",
      state: "AK"
    },
    {
      city: "YALAHA",
      state: "FL"
    },
    {
      city: "YALE",
      state: "IA"
    },
    {
      city: "YALE",
      state: "IL"
    },
    {
      city: "YALE",
      state: "MI"
    },
    {
      city: "YALE",
      state: "OK"
    },
    {
      city: "YALE",
      state: "SD"
    },
    {
      city: "YALE",
      state: "VA"
    },
    {
      city: "YAMHILL",
      state: "OR"
    },
    {
      city: "YAMPA",
      state: "CO"
    },
    {
      city: "YANCEY",
      state: "TX"
    },
    {
      city: "YANCEYVILLE",
      state: "NC"
    },
    {
      city: "YANKEETOWN",
      state: "FL"
    },
    {
      city: "YANKTON",
      state: "SD"
    },
    {
      city: "YANTIC",
      state: "CT"
    },
    {
      city: "YANTIS",
      state: "TX"
    },
    {
      city: "YAPHANK",
      state: "NY"
    },
    {
      city: "YARMOUTH",
      state: "IA"
    },
    {
      city: "YARMOUTH",
      state: "ME"
    },
    {
      city: "YARMOUTH PORT",
      state: "MA"
    },
    {
      city: "YARNELL",
      state: "AZ"
    },
    {
      city: "YATAHEY",
      state: "NM"
    },
    {
      city: "YATES CENTER",
      state: "KS"
    },
    {
      city: "YATES CITY",
      state: "IL"
    },
    {
      city: "YATESBORO",
      state: "PA"
    },
    {
      city: "YATESVILLE",
      state: "GA"
    },
    {
      city: "YAWKEY",
      state: "WV"
    },
    {
      city: "YAZOO CITY",
      state: "MS"
    },
    {
      city: "YEADDISS",
      state: "KY"
    },
    {
      city: "YEAGERTOWN",
      state: "PA"
    },
    {
      city: "YELLOW JACKET",
      state: "CO"
    },
    {
      city: "YELLOW PINE",
      state: "ID"
    },
    {
      city: "YELLOW SPRING",
      state: "WV"
    },
    {
      city: "YELLOW SPRINGS",
      state: "OH"
    },
    {
      city: "YELLOWSTONE NATIONAL PARK",
      state: "WY"
    },
    {
      city: "YELLVILLE",
      state: "AR"
    },
    {
      city: "YELM",
      state: "WA"
    },
    {
      city: "YEMASSEE",
      state: "SC"
    },
    {
      city: "YEOMAN",
      state: "IN"
    },
    {
      city: "YERINGTON",
      state: "NV"
    },
    {
      city: "YERMO",
      state: "CA"
    },
    {
      city: "YESO",
      state: "NM"
    },
    {
      city: "YETTEM",
      state: "CA"
    },
    {
      city: "YOAKUM",
      state: "TX"
    },
    {
      city: "YODER",
      state: "CO"
    },
    {
      city: "YODER",
      state: "IN"
    },
    {
      city: "YODER",
      state: "KS"
    },
    {
      city: "YODER",
      state: "WY"
    },
    {
      city: "YOLO",
      state: "CA"
    },
    {
      city: "YOLYN",
      state: "WV"
    },
    {
      city: "YONCALLA",
      state: "OR"
    },
    {
      city: "YONKERS",
      state: "NY"
    },
    {
      city: "YORBA LINDA",
      state: "CA"
    },
    {
      city: "YORK",
      state: "AL"
    },
    {
      city: "YORK",
      state: "ME"
    },
    {
      city: "YORK",
      state: "ND"
    },
    {
      city: "YORK",
      state: "NE"
    },
    {
      city: "YORK",
      state: "NY"
    },
    {
      city: "YORK",
      state: "PA"
    },
    {
      city: "YORK",
      state: "SC"
    },
    {
      city: "YORK BEACH",
      state: "ME"
    },
    {
      city: "YORK HARBOR",
      state: "ME"
    },
    {
      city: "YORK HAVEN",
      state: "PA"
    },
    {
      city: "YORK NEW SALEM",
      state: "PA"
    },
    {
      city: "YORK SPRINGS",
      state: "PA"
    },
    {
      city: "YORKLYN",
      state: "DE"
    },
    {
      city: "YORKSHIRE",
      state: "NY"
    },
    {
      city: "YORKSHIRE",
      state: "OH"
    },
    {
      city: "YORKTOWN",
      state: "IN"
    },
    {
      city: "YORKTOWN",
      state: "TX"
    },
    {
      city: "YORKTOWN",
      state: "VA"
    },
    {
      city: "YORKTOWN HEIGHTS",
      state: "NY"
    },
    {
      city: "YORKVILLE",
      state: "CA"
    },
    {
      city: "YORKVILLE",
      state: "IL"
    },
    {
      city: "YORKVILLE",
      state: "NY"
    },
    {
      city: "YORKVILLE",
      state: "OH"
    },
    {
      city: "YORKVILLE",
      state: "TN"
    },
    {
      city: "YOSEMITE",
      state: "KY"
    },
    {
      city: "YOSEMITE NATIONAL PARK",
      state: "CA"
    },
    {
      city: "YOUNG",
      state: "AZ"
    },
    {
      city: "YOUNG AMERICA",
      state: "MN"
    },
    {
      city: "YOUNG HARRIS",
      state: "GA"
    },
    {
      city: "YOUNGSTOWN",
      state: "FL"
    },
    {
      city: "YOUNGSTOWN",
      state: "NY"
    },
    {
      city: "YOUNGSTOWN",
      state: "OH"
    },
    {
      city: "YOUNGSTOWN",
      state: "PA"
    },
    {
      city: "YOUNGSVILLE",
      state: "LA"
    },
    {
      city: "YOUNGSVILLE",
      state: "NC"
    },
    {
      city: "YOUNGSVILLE",
      state: "NY"
    },
    {
      city: "YOUNGSVILLE",
      state: "PA"
    },
    {
      city: "YOUNGTOWN",
      state: "AZ"
    },
    {
      city: "YOUNGWOOD",
      state: "PA"
    },
    {
      city: "YOUNTVILLE",
      state: "CA"
    },
    {
      city: "YPSILANTI",
      state: "MI"
    },
    {
      city: "YPSILANTI",
      state: "ND"
    },
    {
      city: "YREKA",
      state: "CA"
    },
    {
      city: "YUBA CITY",
      state: "CA"
    },
    {
      city: "YUCAIPA",
      state: "CA"
    },
    {
      city: "YUCCA",
      state: "AZ"
    },
    {
      city: "YUCCA VALLEY",
      state: "CA"
    },
    {
      city: "YUKON",
      state: "MO"
    },
    {
      city: "YUKON",
      state: "OK"
    },
    {
      city: "YUKON",
      state: "PA"
    },
    {
      city: "YULAN",
      state: "NY"
    },
    {
      city: "YULEE",
      state: "FL"
    },
    {
      city: "YUMA",
      state: "AZ"
    },
    {
      city: "YUMA",
      state: "CO"
    },
    {
      city: "YUMA",
      state: "TN"
    },
    {
      city: "YUTAN",
      state: "NE"
    },
    {
      city: "ZACHARY",
      state: "LA"
    },
    {
      city: "ZACHOW",
      state: "WI"
    },
    {
      city: "ZAHL",
      state: "ND"
    },
    {
      city: "ZALESKI",
      state: "OH"
    },
    {
      city: "ZALMA",
      state: "MO"
    },
    {
      city: "ZAMORA",
      state: "CA"
    },
    {
      city: "ZANESFIELD",
      state: "OH"
    },
    {
      city: "ZANESVILLE",
      state: "IN"
    },
    {
      city: "ZANESVILLE",
      state: "OH"
    },
    {
      city: "ZANONI",
      state: "MO"
    },
    {
      city: "ZAP",
      state: "ND"
    },
    {
      city: "ZAPATA",
      state: "TX"
    },
    {
      city: "ZAREPHATH",
      state: "NJ"
    },
    {
      city: "ZAVALLA",
      state: "TX"
    },
    {
      city: "ZEARING",
      state: "IA"
    },
    {
      city: "ZEBULON",
      state: "GA"
    },
    {
      city: "ZEBULON",
      state: "NC"
    },
    {
      city: "ZEELAND",
      state: "MI"
    },
    {
      city: "ZEELAND",
      state: "ND"
    },
    {
      city: "ZEIGLER",
      state: "IL"
    },
    {
      city: "ZELIENOPLE",
      state: "PA"
    },
    {
      city: "ZELLWOOD",
      state: "FL"
    },
    {
      city: "ZENDA",
      state: "KS"
    },
    {
      city: "ZENDA",
      state: "WI"
    },
    {
      city: "ZEPHYR",
      state: "TX"
    },
    {
      city: "ZEPHYR COVE",
      state: "NV"
    },
    {
      city: "ZEPHYRHILLS",
      state: "FL"
    },
    {
      city: "ZIEGLERVILLE",
      state: "PA"
    },
    {
      city: "ZILLAH",
      state: "WA"
    },
    {
      city: "ZIMMERMAN",
      state: "MN"
    },
    {
      city: "ZION",
      state: "IL"
    },
    {
      city: "ZION GROVE",
      state: "PA"
    },
    {
      city: "ZIONHILL",
      state: "PA"
    },
    {
      city: "ZIONSVILLE",
      state: "IN"
    },
    {
      city: "ZIONSVILLE",
      state: "PA"
    },
    {
      city: "ZIONVILLE",
      state: "NC"
    },
    {
      city: "ZIRCONIA",
      state: "NC"
    },
    {
      city: "ZOAR",
      state: "OH"
    },
    {
      city: "ZOE",
      state: "KY"
    },
    {
      city: "ZOLFO SPRINGS",
      state: "FL"
    },
    {
      city: "ZORTMAN",
      state: "MT"
    },
    {
      city: "ZULLINGER",
      state: "PA"
    },
    {
      city: "ZUMBRO FALLS",
      state: "MN"
    },
    {
      city: "ZUMBROTA",
      state: "MN"
    },
    {
      city: "ZUNI",
      state: "NM"
    },
    {
      city: "ZUNI",
      state: "VA"
    },
    {
      city: "ZURICH",
      state: "MT"
    },
    {
      city: "ZWINGLE",
      state: "IA"
    },
    {
      city: "ZWOLLE",
      state: "LA"
    }
  ];



  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL + '/api/';
  }
  GetZipcodes() {
    return this.http.get('/assets/zip.json');
  }
  
  GetRecords(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getrecords',data);
  }
  GetRecordsByDomains(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getrecordsbydomains', data);
  }
  SendrApi(data): Observable<any> {
    return this.http.post(this.baseUrl + 'sendrapi', data);
  }
  GetSuppressedData(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getsuppresseddata', data);
  }
  ImportSuppressedData(data): Observable<any> {
    return this.http.post(this.baseUrl + 'importsuppresseddata', data);
  }
  ImportClickersOpeners(data): Observable<any> {
    return this.http.post(this.baseUrl + 'importclickersopeners', data);
  }
  GetAllRecordsOpenClick(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getallrecordsopenclick', data);
  }
  
  ExportSuppressedData(data): Observable<any> {
    return this.http.post(this.baseUrl + 'exportsuppresseddata', data);
  }
  GeExportedRecords(): Observable<any> {
    return this.http.get(this.baseUrl + 'getexportedrecords');
  }
  GeExportedRecordsByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getexportedrecordsbymaster', data);
  }

  
  
  GetAllRecords(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getallrecords',data);
  }
  InsertCsvData(data): Observable<any> {
    return this.http.post(this.baseUrl + 'InsertCsvData', data);
  }
  getstats(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getstats', data);
  }
  getvendors(): Observable<any> {
    return this.http.get(this.baseUrl + 'getvendors');
  }
  getdomains(): Observable<any> {
    return this.http.get(this.baseUrl + 'getdomains');
  }
  getdayvalue(): Observable<any> {
    return this.http.get(this.baseUrl + 'getdayvalue');
  }
  DeleteRecords(): Observable<any> {
    return this.http.get(this.baseUrl + 'deleterecords');
  }
  DeleteRecordsByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'deleterecordsbymaster', data);
  }
  getdayvalueByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getdayvaluebymaster', data);
  }
  getvendorcolumns(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getvendorcolumns', data);
  }
  
  
  CreateTable(data): Observable<any> {
    return this.http.post(this.baseUrl + 'CreateTable', data);
  }
  
  PostCsvData(data): Observable<any> {
    return this.http.post(this.baseUrl + 'PostCsvData', data);
  }
  DeleteDuplicate(data): Observable<any> {
    return this.http.post(this.baseUrl + 'DeleteDuplicate', data);
  }
  GetImportedCsvs(): Observable<any> {
    return this.http.get(this.baseUrl + 'GetImportedCsvs');
  }
  GetTableColumns(data): Observable<any> {
    return this.http.post(this.baseUrl + 'GetTableColumns', data);
  }
  GeClickersOpeners(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getclickersopeners', data);
  }
  

  CombineTables(data): Observable<any> {
    return this.http.post(this.baseUrl + 'CombineTables', data);
  }
  SubtractTables(data): Observable<any> {
    return this.http.post(this.baseUrl + 'SubtractTables', data);
  }
  
  ExportCsv(data): Observable<any> {
    return this.http.post(this.baseUrl + 'ExportCsv', data);
  }
  CheckDomains(): Observable<any> {
    return this.http.get(this.baseUrl + 'checkdomains');
  }
  Getalldomains(): Observable<any> {
    return this.http.get(this.baseUrl + 'getalldomains');
  }
  GetAllDomainsByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getalldomainsbymaster', data);
  }
  GetTotalDomains(): Observable<any> {
    return this.http.get(this.baseUrl + 'gettotaldomains');
  }
  GetTotalDomainsByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'gettotaldomainsbymaster', data);
  }
  GetNonExportedCount(): Observable<any> {
    return this.http.get(this.baseUrl + 'getnonexportedcount');
  }
  GetNonExportedCountByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getnonexportedcountbymaster',data);
  }
  GetAllMasters(): Observable<any> {
    return this.http.get(this.baseUrl + 'getallmasters');
  }
  GetExportedCount(): Observable<any> {
    return this.http.get(this.baseUrl + 'getexportedcount');
  }
  GetExportedCountByMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getexportedcountbymaster', data);
  }
  GetSuppressionCount(): Observable<any> {
    return this.http.get(this.baseUrl + 'getsuppressioncount');
  }

  UpdateRealtimePosting(data): Observable<any> {
    return this.http.post(this.baseUrl + 'updaterealtimeposting', data);
  }
  UpdateRealtimePostingCount(data): Observable<any> {
    return this.http.post(this.baseUrl + 'updaterealtimepostingcount', data);
  }
  StartRealtimePosting(data): Observable<any> {
    return this.http.post(this.baseUrl + 'startrealtimeposting', data);
  }
  GetRealtimePostingById(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getrealtimepostingbyid', data);
  }

  GetRealtimePostingLogs(): Observable<any> {
    return this.http.get(this.baseUrl + 'getrealtimepostinglogs');
  }
  GetErrorLogs(data): Observable<any> {
    return this.http.post(this.baseUrl + 'geterrorlogs', data);
  }
  DeleteErrorLog(data): Observable<any> {
    return this.http.post(this.baseUrl + 'deleteerrorlog', data);
  }
  
}

export class CombineTables {
  constructor(
    public column: string,
    public selectedValue: string,
  ) { }
}
