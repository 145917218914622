<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}} Total: ({{totalRec}}) 
      </div>
    </div>
  </div>
</div>
<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>

    <div class="row margin-left1">
      <div class="position-relative form-group width50">
        <label for="exampleText">Emails</label><textarea [(ngModel)]="emails" [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
        <button type="button" class="mt-1 btn btn-primary" (click)="AddEmails()">Submit</button>
        <button type="button" class="mt-1 btn btn-primary margin-left2" (click)="ExportSuppressedData()">Export</button>
      </div>
    </div>

    <div class="row margin-top36">
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">CSV File</label>
        <input #fileUploadSimple [accept]="'.csv'" type="file" class="form-control-file"
               (change)="importDataFromCSV($event)">
      </div>
    </div>

    <p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
             [value]="this.mappedData" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
             responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
             styleClass="p-datatable-offlineconsultants" [paginator]="true"
             [ngClass]="_csvService.propertyNames.length > 0 ? 'heightauto' : 'height0'"
             currentPageReportTemplate="{first} to {last} of {totalRecords}">
      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn>Master</th>
          <th pFrozenColumn>Mapped Column</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-Items>
        <tr>
          <td>{{Items.column}}</td>
          <td>
            <select [(ngModel)]="Items.selectedValue" [ngModelOptions]="{standalone: true}">
              <option value=""></option>
              <option *ngFor="let p of _csvService.propertyNames" [value]="p">
                {{p}}
              </option>
            </select>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="row margin-left1">
      <div class="position-relative form-group width50">
        <button type="button" class="mt-1 btn btn-primary" (click)="ImportSuppressions()">Import</button>
      </div>
    </div>
  </div>
</div>



<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
