import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { SelectColumns } from 'src/app/services/csv.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../../services/utility.service';
@Component({
  selector: 'app-domains-filters-custom',
  templateUrl: './domains-filters-custom.component.html',
  styleUrls: ['./domains-filters-custom.component.sass']
})
export class DomainsFiltersCustomComponent implements OnInit {
  domainslength = 0;
  day :any;
  totalRec = 0;
  amountofdata = 10;
  alldomain = '';
  alldomains: any;
  modaltype = '';
  checkdomains: any;

  closeResult = '';
  showingrecords = '';
  heading = 'Domains Filter Custom';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  


  selectedColumns: SelectColumns[] = [];
  selectedCols: SelectColumns[] = [];
  all_columns: any;

  from = '';
  to = '';
  totalrec = 0;
  totalrecTxt = '';
  records = [];
  exportedCount = 0;
  nonexportedCount = 0;
  totaldomainsdata: any;
  totaldomains = 0;
  countrec = 0;

  master = 'tbl_master_2022';
  masters: any;
  masterImportTbl = '';
  constructor(public utilityService:UtilityService,private SpinnerService: NgxSpinnerService, private modalService: NgbModal, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    var m_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun', 'Jul',
      'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = new Date();
    var n = m_names[d.getMonth()];
    var year = new Date().getFullYear();
    this.masterImportTbl = 'tbl_master_' + n + year;

    this.DeleteRecordsByMaster();
    this.GetExportedCountByMaster();
    this.GetNonExportedCount();
    this.GetAllMasters();
  }
  GetAllMasters() {
    this.SpinnerService.show();
    this.csvUpdateService.GetAllMasters()
      .subscribe(
        response => {
          this.masters = response;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  ChangeMaster(event: any) {
    this.countrec = 0;
    this.records = [];
    this.totalRec = 0;
    this.GetNonExportedCount();
    this.GetExportedCountByMaster();
    this.DeleteRecordsByMaster();
  }

  GetNonExportedCount() {
    this.SpinnerService.show();
    const data = {
      master: this.master
    }
    this.csvUpdateService.GetNonExportedCountByMaster(data)
      .subscribe(
        response => {
          this.nonexportedCount = response[0].cnt;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  GetExportedCountByMaster() {
    this.SpinnerService.show();
    const data = {
      master: this.master
    }
    this.csvUpdateService.GetExportedCountByMaster(data)
      .subscribe(
        response => {
          this.exportedCount = response[0].cnt;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  QuantityChange(event) {
    this.UpdateTotalRec();
  }
  FixedQuantity(event) {
    this.checkdomains.forEach(element => {
      if (element.counter > this.countrec) {
        element.qty = this.countrec;
      }
      else {
        element.qty = element.counter;
      }
    })

    var count = 0;
    this.totaldomainsdata.forEach(element => {
      if (element.totalrec > this.countrec) {
        count = count + this.countrec;
      }
      else {
        count = count + element.totalrec;
      }
    })

    
    this.totalRec = count;
  }
  UpdateTotalRec() {
    var count = 0;
    if (this.domainslength != 0) {
      count = this.domainslength;
    }
    this.checkdomains.forEach(element => {
      if (element.qty != '') {
        count = count + Number(element.qty);
      }
      
    })
    this.totalRec = count;
  }
  
  getdayvalueByMaster() {
    const data = {
      master: this.master
    }
    this.SpinnerService.show();
    this.csvUpdateService.getdayvalueByMaster(data)
      .subscribe(
        response => {
          this.day=response[0].day;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  GetAllDomainsByMaster() {
    this.SpinnerService.show();
    const data = {
      master: this.master
    }
    this.csvUpdateService.GetAllDomainsByMaster(data)
      .subscribe(
        response => {
          this.checkdomains = response;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  GetTotalDomainsByMaster() {
    const data = {
      master: this.master
    }
    this.csvUpdateService.GetTotalDomainsByMaster(data)
      .subscribe(
        response => {
          this.totaldomainsdata = response;
          this.totaldomains = response.length;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  
  
  
  LoadModal(content: any, type: string) {
    this.modaltype = type;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: "myCustomModalClass",
      size: 'md'
    };
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  DeleteRecordsByMaster() {
    const data = {
      master: this.master
    }
    this.csvUpdateService.DeleteRecordsByMaster(data)
      .subscribe(
        response => {
          this.GetAllDomainsByMaster();
          this.GetTotalDomainsByMaster();
          this.getdayvalueByMaster();
        },
        error => {
        });
  }
  GetRecords() {
    var checkdomains = this.checkdomains.filter(x => x.qty > 0);
    if (this.totalRec == 0) {
      this.toastr.error("Records to show must be greater than 0.", 'Error!');
    }
    else {
      const userid = localStorage.getItem("token");

      const data = {
        master: this.master,
        checkdomains: checkdomains,
        amountofdata: this.amountofdata,
        alldomain: this.alldomain.toLowerCase(),
        userid: userid,
        type: 'filter',
        day: this.day,
        domainslength: this.domainslength,
        totalRec: this.totalRec,
        countrec: this.countrec
      };
      this.SpinnerService.show();
      this.csvUpdateService.GetRecordsByDomains(data)
        .subscribe(
          items => {
            this.selectedCols = items.selectedCols;
            this.SpinnerService.hide();
            this.records = items.recordsets[0];
            this.totalrec = items.recordsets[0].length;
            if (this.totalrec > 10) {
              this.showingrecords = "showing first 10 records as sample";
            }
            else {
              this.showingrecords = "showing all " + this.totalrec + " records";
            }
          },
          error => {
            console.log(error);
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
  
  ExportCsv() {
    var checkdomains = this.checkdomains.filter(x => x.qty > 0);
    if (this.totalRec == 0) {
      this.toastr.error("Records to show must be greater than 0.", 'Error!');
    }
    else {
      const userid = localStorage.getItem("token");
      const data = {
        master: this.master,
        checkdomains: checkdomains,
        amountofdata: this.amountofdata,
        alldomain: this.alldomain.toLowerCase(),
        userid: userid,
        type: 'export',
        day: this.day,
        domainslength: this.domainslength,
        totalRec: this.totalRec,
        countrec: this.countrec
      };
      this.modalService.dismissAll();
      this.SpinnerService.show();
      this.csvUpdateService.GetRecordsByDomains(data)
        .subscribe(
          records => {
            var items = records.recordsets[0];
            this.SpinnerService.hide();
            let csv

            var l = items.length;
            if (l > 0) {
              l = 1;
            }
            // Loop the array of objects
            for (let row = 0; row < 1; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0

              // If this is the first row, generate the headings
              if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {
                  // This is to not add a comma at the last cell
                  // The '\r\n' adds a new line
                  csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                  keysCounter++
                }
                csv = csv.replace("undefined", '');
              }
              keysCounter = 0
            }
            for (let row = 0; row < items.length; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0


              for (let key in items[row]) {
                csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }

              keysCounter = 0
            }
            var link = document.createElement('a');
            link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
            link.target = '_blank';
            link.download = 'export.csv';
            link.click();
            this.GetExportedCountByMaster();
            this.GetNonExportedCount();
          },
          error => {
            console.log(error);
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
  GeExportedRecordsByMaster() {
    const data = {
      master: this.master
    }
    this.SpinnerService.show();
    this.csvUpdateService.GeExportedRecordsByMaster(data)
      .subscribe(
        items => {
          this.SpinnerService.hide();
          let csv

          var l = items.length;
          if (l > 0) {
            l = 1;
          }
          // Loop the array of objects
          for (let row = 0; row < 1; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if (row === 0) {

              // Loop each property of the object
              for (let key in items[row]) {
                // This is to not add a comma at the last cell
                // The '\r\n' adds a new line
                csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }
              csv = csv.replace("undefined", '');
            }
            keysCounter = 0
          }
          for (let row = 0; row < items.length; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0


            for (let key in items[row]) {
              csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
              keysCounter++
            }

            keysCounter = 0
          }
          var link = document.createElement('a');
          link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
          link.target = '_blank';
          link.download = 'export.csv';
          link.click();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });
  }

}
