import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from '../../services/csv-update.service';
import { CombineTables, CsvService, SuppressionImport } from '../../services/csv.service';

@Component({
  selector: 'app-suppressions',
  templateUrl: './suppressions.component.html',
  styleUrls: ['./suppressions.component.sass']
})
export class SuppressionsComponent implements OnInit {
  heading = 'Suppressions';
  filename = '';
  file: any;
  mappedData: SuppressionImport[] = [];
  maintable_columns: any;
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  emails = '';
  totalRec = 0;
  cols: string[] = [];
  constructor(public _csvService: CsvService,private SpinnerService: NgxSpinnerService, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.GetTableColumns();
    this.GetSuppressionCount();
  }
  GetSuppressionCount() {
    this.SpinnerService.show();
    this.csvUpdateService.GetSuppressionCount()
      .subscribe(
        response => {
          this.totalRec = response[0].cnt;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  GetTableColumns() {
    const data = {
      table: 'aaall_master_suppression.dbo.Suppressions',
    };
    this.csvUpdateService.GetTableColumns(data)
      .subscribe(
        response => {
          this.mappedData = [];
          this.maintable_columns = response;
          this.maintable_columns.forEach((row) => {
            this.mappedData.push(
              {
                column: row.name,
                selectedValue: '',
                index:0
              }
            );
          })
        },
        error => {
        });
  }
  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this._csvService.importDataFromCSV(fileContent);
    this.cols = this._csvService.propertyNames;
  }
  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.filename = this.file.name;
    let fileContent = await file.text();
    return fileContent;
  }
  AddEmails() {
     if (this.emails == '' ) {
      this.toastr.error("Please enter emails.", 'Error!');
    }
    else {
      const userid = localStorage.getItem("token");
      const data = {
        emails: this.emails,
        userid: userid,
      };
      this.SpinnerService.show();
      this.csvUpdateService.GetSuppressedData(data)
        .subscribe(
          records => {
            this.SpinnerService.hide();
            this.GetSuppressionCount();
            this.toastr.success('Suppressions updated successfully!', 'Success!');
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
  ExportSuppressedData() {
    const userid = localStorage.getItem("token");
    const data = {
      emails: this.emails,
      userid: userid,
    };
    this.SpinnerService.show();
    this.csvUpdateService.ExportSuppressedData(data)
      .subscribe(
        records => {
          this.SpinnerService.hide();
          var items = records.recordsets[0];
          let csv

          var l = items.length;
          if (l > 0) {
            l = 1;
          }
          // Loop the array of objects
          for (let row = 0; row < 1; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if (row === 0) {

              // Loop each property of the object
              for (let key in items[row]) {
                console.log(key);
                // This is to not add a comma at the last cell
                // The '\r\n' adds a new line
                csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }
              csv = csv.replace("undefined", '');
            }
            keysCounter = 0
          }
          for (let row = 0; row < items.length; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0


            for (let key in items[row]) {
              csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
              keysCounter++
            }

            keysCounter = 0
          }
          var link = document.createElement('a');
          link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
          link.target = '_blank';
          link.download = 'export.csv';
          link.click();
          this.toastr.success('Suppressions exported successfully!', 'Success!');
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });
  }
  ImportSuppressions() {
    var mappedData = this.mappedData.filter(item => item.selectedValue == '');
    if (this.filename == '') {
      this.toastr.error("Select csv file.", 'Error!');
    }
    else if ( mappedData.length > 0) {
      this.toastr.error("Select column for mapping.", 'Error!');
    }
    
    else {
      this.mappedData.forEach((row) => {
        if (row.selectedValue != '') {
          row.index = this.cols.indexOf(row.selectedValue);
        }
      })
      const userid = localStorage.getItem("token");
      const data = {
        userid: userid,
        recs: this._csvService.dataStr,
        mappedData: this.mappedData,
      };
      this.SpinnerService.show();
      this.csvUpdateService.ImportSuppressedData(data)
        .subscribe(
          records => {
            this.SpinnerService.hide();
            this.toastr.success('Suppressions imported successfully!', 'Success!');
            this.GetSuppressionCount();
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
}
