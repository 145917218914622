<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
      </div>
    </div>
  </div>
</div>

<div class="main-card mb-3 card">
  <div class="card-body cardbody">
    <div class="position-relative form-group">
      <div>
        
      </div>
    </div>
  </div>
</div>

<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview"
         [value]="records" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
         responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
         styleClass="p-datatable-offlineconsultants" [paginator]="true"
         [ngClass]="records.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="{first} to {last} of {totalRecords}">

  <ng-template pTemplate="header">
    <tr>
      <th>Start Time</th>
      <th>End Time</th>
      <th>Table</th>
      <th>Day</th>
      <th>Listid</th>
      <th>Total Records</th>
      <th>Min Sec</th>
      <th>Max Sec</th>
      <th>Processed</th>
      <th></th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-Items>
    <tr>
      <td>{{Items.dtstart}}</td>
      <td>{{Items.dtend}}</td>
      <td>{{Items.datasource}}</td>
      <td>{{Items.day}}</td>
      <td>{{Items.listid}}</td>
      <td>{{Items.totalrecords}}</td>
      <td>{{Items.minsec}}</td>
      <td>{{Items.maxsec}}</td>
      <td>{{Items.recprocessed}}</td>
      <td>
        <div *ngIf="!Items.finished">
          <button type="button" class="mt-1 btn btn-primary margin5" (click)="FinishedRealtimePosting(Items.guid)">Stop</button>
        </div>
        <button type="button" class="mt-1 btn btn-primary margin5" (click)="GetErrorLogs(Items.guid)">View API Errors</button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>


<br />
<br />
<br />
<h1 *ngIf="errorlogs.length > 0">API Error Logs:</h1>
<p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview"
         [value]="errorlogs" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
         responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
         styleClass="p-datatable-offlineconsultants" [paginator]="true"
         [ngClass]="errorlogs.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="{first} to {last} of {totalRecords}">
  <ng-template pTemplate="header">
    <tr>
      <th>Listid</th>
      <th>Record</th>
      <th>Response</th>
      <th>Time</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-Items>
    <tr>
      <td>{{Items.list}}</td>
      <td>{{Items.body}}</td>
      <td>{{Items.error}}</td>
      <td>{{Items.dt}}</td>
      <td><button type="button" class="mt-1 btn btn-danger margin5" (click)="DeleteErrorLog(Items.id)">Delete</button></td>

    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>



<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
