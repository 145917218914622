<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
      </div>
    </div>
  </div>
</div>
<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>
    <div class="row">
      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Number Of Records</label>
        <input name="amountofdata"
               id="amountofdata" placeholder="" [(ngModel)]="amountofdata" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
      </div>

      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Number Of Clicks</label>
        <select class="mb-2 form-control-md form-control" [(ngModel)]="noofopen">
          <option [value]=""></option>
          <option *ngFor="let v of opensclickArr" [value]="v">
            {{v}}
          </option>
        </select>
      </div>
      <div class="col-md-4 floatleft position-relative form-group">
        <label for="exampleFile">Domain Group</label>
        <ngx-autocomplete [searchKeyword]="'domain'" [inputId]="'searchZip'"
                          [placeholderValue]="'Enter Domain'" [entries]="alldomains" (selectedValue)="selectDomain($event)">
        </ngx-autocomplete>
        <div class="font-icon-wrapper font-icon-sm " *ngIf="selecteddomains.length > 0"><span class="spanselected" *ngFor="let z of selecteddomains; index as i;">{{z}}<i (click)="RemoveDomain(z)" class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span></div>
      </div>
      <div class="col-md-3 floatleft position-relative form-group margintop8">
        <label for="exampleEmail">From</label>
        <input name="from" placeholder="" [(ngModel)]="from" [ngModelOptions]="{standalone: true}" type="date"
               class="form-control">
      </div>
      <div class="col-md-3 floatleft position-relative form-group">
        <label for="exampleEmail">To</label>
        <input name="to" placeholder="" [(ngModel)]="to" [ngModelOptions]="{standalone: true}" type="date"
               class="form-control">
      </div>
      <div class="col-md-3 floatleft position-relative form-group paddingtop25 margintop8">
        <button type="button" class="mt-1 btn btn-primary" (click)="ExportCsv()">Filter</button>
      </div>
    </div>
  </div>
</div>
<div class="clearboth"></div>
<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
