<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
      </div>
    </div>
  </div>
</div>


<h2 class="floatleft margintop5" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">
  Total records: {{totalrec}}
</h2>
<div class="clearboth"></div>

<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview" [columns]="selectedCols" [value]="records"
         [scrollable]="true" scrollHeight="400px" scrollDirection="both" responsiveLayout="stack" dataKey="id" [rows]="10"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-offlineconsultants"
         [paginator]="false" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="">

  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of selectedCols; index as i;">{{col}}</th>
    </tr>

  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="selectedCols">
    <tr>
      <td *ngFor="let col of selectedCols">
        {{rowData[col]}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<br />
<br />



<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
