import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { SelectColumns } from 'src/app/services/csv.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-export-csv',
  templateUrl: './export-csv.component.html',
  styleUrls: ['./export-csv.component.sass']
})
export class ExportOpenersComponent implements OnInit {
  amountofdata = 10;
  noofopen = '';
  alldomains: any;
  selecteddomains = [];
  opensclickArr = ['1', '2', '5', '10', '10+'];
  domvisibility = 'vis_hidden';
  modaltype = '';
  checkdomains: any;

  closeResult = '';
  showingrecords = '';
  heading = 'Export CSV';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  cities: any;
  selectedcities = [];

  states: any;
  selectedstates = [];

  zips: any;


  vendorVal = '';

  selectedColumns: SelectColumns[] = [];
  selectedCols: SelectColumns[] = [];
  all_columns: any;

  from = '';
  to = '';
  totalrec = 0;
  records = [];
  constructor(private SpinnerService: NgxSpinnerService, private modalService: NgbModal, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }


  ngOnInit(): void {
    this.csvUpdateService.GetZipcodes()
      .subscribe(
        response => {
          this.zips = response;
        },
        error => {
        });

    this.totalrec = 0;
    this.GetDomains();
  }

  ChangeDomainType(event) {
    var v = event.target.value.toLowerCase();
    if (v == 'custom') {
      this.domvisibility = 'vis_visible';
    }
    else {
      this.domvisibility = 'vis_hidden';
    }
  }
  

  RemoveDomain(val) {
    this.selecteddomains = this.selecteddomains.filter(item => item != val);
  }
  selectDomain(event) {
    var len = this.selecteddomains.filter(item => item == event.domain).length;
    if (len == 0) {
      this.selecteddomains.push(event.domain);
    }
  }

  RemoveState(val) {
    this.selectedstates = this.selectedstates.filter(item => item != val);
  }
  selectState(event) {
    var len = this.selectedstates.filter(item => item == event.state).length;
    if (len == 0) {
      this.selectedstates.push(event.state);
    }
  }

  RemoveCity(val) {
    this.selectedcities = this.selectedcities.filter(item => item != val);
  }
  selectCity(event) {
    var len = this.selectedcities.filter(item => item == event.city).length;
    if (len == 0) {
      this.selectedcities.push(event.city);
    }
  }

  GetDomains() {
    this.csvUpdateService.getdomains()
      .subscribe(
        response => {
          this.alldomains = response;
        },
        error => {
        });
  }

  ExportCsv() {
    const userid = localStorage.getItem("token");
    const data = {
      amountofdata: this.amountofdata,
      noofopen: this.noofopen,
      noofclick:'',
      selecteddomains: this.selecteddomains,
      userid: userid,
      from: this.from,
      to: this.to,
      type: 'open'
    };
    this.SpinnerService.show();
    this.csvUpdateService.GetAllRecordsOpenClick(data)
      .subscribe(
        records => {
          var items = records.recordsets[0];
          if (items.length == 0) {
            this.toastr.error('No record found.', 'Error!');
            this.SpinnerService.hide();
          }
          else {
            let csv

            var l = items.length;
            if (l > 0) {
              l = 1;
            }
            // Loop the array of objects
            for (let row = 0; row < 1; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0

              // If this is the first row, generate the headings
              if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {
                  console.log(key);
                  // This is to not add a comma at the last cell
                  // The '\r\n' adds a new line
                  csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                  keysCounter++
                }
                csv = csv.replace("undefined", '');
              }
              keysCounter = 0
            }
            for (let row = 0; row < items.length; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0


              for (let key in items[row]) {
                csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }

              keysCounter = 0
            }
            var link = document.createElement('a');
            link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
            link.target = '_blank';
            link.download = 'export.csv';
            link.click();
            this.SpinnerService.hide();
          }
         
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });

  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }


}
