import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from '../../../services/csv-update.service';

@Component({
  selector: 'app-show-openers',
  templateUrl: './show-openers.component.html',
  styleUrls: ['./show-openers.component.sass']
})
export class ShowOpenersComponent implements OnInit {
  heading = 'Show Openers';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  totalrec = 0;
  records = [];
  showingrecords = '';
  selectedCols = ['Email', 'First_name', 'Last_name', 'Address', 'City', 'State', 'Zip', 'Day', 'Domaingroup'];
  constructor(private SpinnerService: NgxSpinnerService, private modalService: NgbModal, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.GeClickersOpeners();
  }

  GeClickersOpeners() {
    const userid = localStorage.getItem("token");
    const data = {
      userid: userid,
      type: 'open',
    };
    this.SpinnerService.show();
    this.csvUpdateService.GeClickersOpeners(data)
      .subscribe(
        items => {
          debugger;
          this.SpinnerService.hide();
          this.records = items;
          this.totalrec = items.length;
          this.showingrecords = "showing all " + this.totalrec + " records";
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });

  }

}
