<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
    <div class="app-header__logo" style="background: #fff;">
      <a *ngIf="getItem() != 'consultant'" routerLink="/csv-parser" routerLinkActive="active-item"><img class="logoimage" /> <span class="webtitle">WarmUp Database</span></a>
    </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="dashboardsMenu" *ngIf="isLoggedInUser && getItem() == 'admin'">
                <ng-template ngbPanelHeader>
                  <button routerLink="/csv-parser" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-plane"></i>
                    <span class="vsm-title">Csv Parser</span>
                  </button>

                  <!--<button routerLink="/data-filter-custom" routerLinkActive="active-item" class="vsm-link">
    <i class="vsm-icon pe-7s-graph2"></i>
    <span class="vsm-title">Data Filter Custom</span>
  </button>-->
                  <button routerLink="/domains-filter-custom" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Domains Filter Custom</span>
                  </button>
                  <button routerLink="/suppressions" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Suppressions</span>
                  </button>
                  <button routerLink="/realtime-posting" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Realtime Posting</span>
                  </button>

                  <button routerLink="/logs" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Logs</span>
                  </button>
                  <button routerLink="/realtime-posting-logs" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Realtime Posting Logs</span>
                  </button>
                </ng-template>
              </ngb-panel>

              <!--<ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-helm"></i>
                    <span class="vsm-title">Openers</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/show-openers" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Show Openers
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/add-openers" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Add Openers
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/openers/export-csv" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Export CSV
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>-->


              <!--<ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-helm"></i>
                    <span class="vsm-title">Clickers</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/show-clickers" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Show Clickers
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/add-clickers" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Add Clickers
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/clickers/export-csv" class="vsm-link pl-1">
                          <i class="fas fa-object-group mr-2" style="color:#041c54"></i>&nbsp;Export CSV
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>-->


              <ngb-panel  >
                <ng-template ngbPanelHeader>
                  <button (click)="Logout($event)" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-power"></i>
                    <span class="vsm-title">Logout</span>
                  </button>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>

            <div class="vsm-item" *ngIf="!isLoggedInUser">
              <a routerLink="/sign-in" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-graph2"></i>
                <span class="vsm-title">Login</span>
              </a>
            </div>
            
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
