

export class CsvService {
  propertyNames: string[] = [];
  dataStr: string[] = [];
  csvPrev: string[] = [];

  public saveDataInCSV(data: Array<any>): string {
    if (data.length == 0) {
      return '';
    }

    let propertyNames = Object.keys(data[0]);
    let rowWithPropertyNames = propertyNames.join(',') + '\n';

    let csvContent = rowWithPropertyNames;

    let rows: string[] = [];

    data.forEach((item) => {
      let values: string[] = [];

      propertyNames.forEach((key) => {
        let val: any = item[key];

        if (val !== undefined && val !== null) {
          val = new String(val);
        } else {
          val = '';
        }
        values.push(val);
      });
      rows.push(values.join(','));
    });
    csvContent += rows.join('\n');

    return csvContent;
  }
   public isNumeric(val: any): boolean {
  return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
}
  public importDataFromCSV(csvText: string): void {
    this.propertyNames = [];
    var propertyNames = csvText.slice(0, csvText.indexOf('\n')).split(',');
    var ind = propertyNames.indexOf('Vendor');
    if (ind >= 0) {
      propertyNames.splice(ind, 1); 
    }
    let headerExist = true;
    var em = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
    var lnk = /^(http|https|ftp):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    for (let index = 0; index < propertyNames.length; index++) {
      let value = propertyNames[index];
      if (em.test(value) == true) {
        headerExist = false;
      }
      if (lnk.test(value) == true) {
        headerExist = false;
      }
      if (this.isNumeric(value) == true) {
        headerExist = false;
      }
    }
    if (headerExist) {
      this.propertyNames = propertyNames;
    }
    else {
      for (let index = 0; index < propertyNames.length; index++) {
        var n = index + 1;
        this.propertyNames.push('column' + n);
      }
    }
    let dataRows = [];
    let dataRowsNew = [];
    if (headerExist) {
       dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');
    }
    else {
       dataRows = csvText.split('\n');
    }
    if (ind >= 0) {
      for (let index = 0; index < dataRows.length; index++) {
        var dt = dataRows[index].split(',');
        dt.splice(ind, 1);
        dataRowsNew.push(dt.toString());
      }
      this.dataStr = dataRowsNew;
    }
    else {
      this.dataStr = dataRows;
    }
    this.csvPrev = [];
    var len = 5;
    if(this.dataStr.length < 5)
    {
      len=this.dataStr.length;
    }
    for (let index = 0; index < len; index++) {
      this.csvPrev.push(this.dataStr[index]);
    }
  }

  public importDataFromCSVByType(csvText: string, obj: any): Array<any> {
    const propertyNames = csvText.slice(0, csvText.indexOf('\n')).split(',');
    const dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');


    let dataArray: any[] = [];
    dataRows.forEach((row) => {
      let values = row.split(',');

      let dataObj: any = new Object();
      for (let index = 0; index < propertyNames.length; index++) {
        const propertyName: string = propertyNames[index];

        let value: any = values[index];
        if (value === '') {
          value = null;
        }


        if (typeof obj[propertyName] === 'undefined') {
          dataObj[propertyName] = undefined;
        }
        else if (typeof obj[propertyName] === 'boolean') {
          dataObj[propertyName] = value.toLowerCase() === 'true';
        }
        else if (typeof obj[propertyName] === 'number') {
          dataObj[propertyName] = Number(value);
        }
        else if (typeof obj[propertyName] === 'string') {
          dataObj[propertyName] = value;
        }
        else if (typeof obj[propertyName] === 'object') {
          console.error("do no have algorithm to convert object");
        }
      }

      dataArray.push(dataObj);
    });

    return dataArray;
  }
}
export class CombineTables {
  constructor(
    public column: string,
    public selectedValue: string,
  ) { }
}

export class SelectColumns {
  constructor(
    public column: string,
    public selectedValue: Boolean,
  ) { }
}
export class SuppressionImport {
  constructor(
    public column: string,
    public selectedValue: string,
    public index: number,
  ) { }
}
