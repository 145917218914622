<!-- <div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <span style="font-size: 15px;" class="pl-2" [innerHtml]="websitecopyrighttext">{{websiteName}}</span>
    </div>
  </div>
</div> -->
<footer class="page-footer font-small blue" style="background-color:#dcdddf;">
  <div class="footer-copyright text-center py-2">
    <span style="font-size: 13px;" class="pl-2">&#169;&#160;WarmUp Database</span>
  </div>
</footer>
