import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from '../../../services/csv-update.service';
import { CsvService } from '../../../services/csv.service';
@Component({
  selector: 'app-add-clickers',
  templateUrl: './add-clickers.component.html',
  styleUrls: ['./add-clickers.component.sass']
})
export class AddClickersComponent implements OnInit {
  heading = 'Add Clickers';
  filename = '';
  file: any;
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  cols: string[] = [];
  constructor(public _csvService: CsvService, private SpinnerService: NgxSpinnerService, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this._csvService.importDataFromCSV(fileContent);
    this.cols = this._csvService.propertyNames;
  }
  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.filename = this.file.name;
    let fileContent = await file.text();
    return fileContent;
  }
  ImportClickersOpeners() {
    var index = 0;
    var emailCol = -1;
    this.cols.forEach((row) => {
      if (row.toLowerCase().includes("email")) {
        emailCol = index;
      }
      index++;
    })

    if (this.filename == '') {
      this.toastr.error("Select csv file.", 'Error!');
    }
    else if (emailCol == -1) {
      this.toastr.error("Csv does not contains email column.", 'Error!');
    }

    else {

      const userid = localStorage.getItem("token");
      const data = {
        userid: userid,
        recs: this._csvService.dataStr,
        type: 'click',
        emailCol: emailCol
      };
      this.SpinnerService.show();
      this.csvUpdateService.ImportClickersOpeners(data)
        .subscribe(
          records => {
            this.SpinnerService.hide();
            this.toastr.success('Clickers imported successfully!', 'Success!');
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
}
