<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
      </div>
    </div>
  </div>
</div>
<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>
    <div class="row">
      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Data Source</label>
        <select class="mb-2 form-control-md form-control" [(ngModel)]="master" (change)="ChangeMaster($event)">
          <option *ngFor="let v of masters" [value]="v.tbl">
            {{v.tbl}}
          </option>
        </select>
      </div>
      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Day</label>
        <input placeholder="" [(ngModel)]="day" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
      </div>
      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Domains length</label>
        <input (keyup)="QuantityChange($event)"
               placeholder="" [(ngModel)]="domainslength" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
      </div>

      <div class="col-md-1">
        <label class="optLabel">OR</label>
      </div>
      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Emails per domain</label>
        <input (keyup)="FixedQuantity($event)"
               placeholder="" [(ngModel)]="countrec" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
      </div>
    </div>
    <div class="row">
      <h2>Total data exported:({{exportedCount}})</h2><button type="button" class="mt-1 btn btn-primary margin5" (click)="GeExportedRecordsByMaster()">Export all this data</button><h2>Remaining to export:({{nonexportedCount}})</h2>
    </div>
    <div class="row">
      <h2>Records to show: ({{totalRec}})</h2><button type="button" class="mt-1 btn btn-primary margin5" (click)="GetRecords()">Filter</button>
      <div class="domainsDiv">
        <table id="tblColumns">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Domain</th>
              <th scope="col">Counter</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of checkdomains; index as i;" [ngClass]="t.show == '1' ? '' : 'vis_hidden'">
              <td data-label="No">{{i+1}}</td>
              <td data-label="Domain">{{t.domain}}</td>
              <td data-label="Counter">{{t.counter}}</td>
              <td data-label="Counter">
                <input name=qty{{i}}
                       placeholder="" [(ngModel)]="t.qty" (change)="QuantityChange($event)" (keyup)="QuantityChange($event)" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
              </td>
            </tr>

          </tbody>
        </table>
      </div>

    </div>
    <div class="row">
      <div class="col-md-3 floatleft position-relative form-group paddingtop25 margintop8">
        <button type="button" class="mt-1 btn btn-primary" (click)="GetRecords()">Filter</button>
      </div>
    </div>

  </div>
</div>


<table class="res-table" *ngIf="apicount > 0">
  <tbody>
    <tr>
      <td *ngIf="s > 0">
        {{s}} leads posted through API
      </td>
    </tr>
    <tr>
      <td *ngIf="f > 0">
        {{f}} leads failed
      </td>
    </tr>

  </tbody>
</table>

<div class="row margintop20">
  <h2 class="floatleft margintop34" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">
    Total records: {{totalrec}}
  </h2>
  <div class="col-md-2 floatleft position-relative form-group" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">
    <label for="exampleFile">List id:</label>
    <input placeholder="" [(ngModel)]="listid" [ngModelOptions]="{standalone: true}" type="text" class="form-control">
  </div>
  <div class="col-md-2 floatleft position-relative form-group" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">
    <label for="exampleFile">Records to process:</label>
    <input placeholder="" [(ngModel)]="recordstoprocess" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
  </div>
  <div class="col-md-2 floatleft position-relative form-group" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">
    <label for="exampleFile">Min(seconds):</label>
    <input placeholder="" [(ngModel)]="minsec" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
  </div>
  <div class="col-md-2 floatleft position-relative form-group" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">
    <label for="exampleFile">Max(seconds):</label>
    <input placeholder="" [(ngModel)]="maxsec" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
  </div>
  <div class="margintop34">
    <button type="button" class="mt-1 btn btn-success marginleft3"
            [ngClass]="records.length > 0 ? 'heightauto' : 'height0'" (click)="LoadModal(mymodal,'export')">
      Process Through API
    </button>
  </div>
</div>


<div class="clearboth"></div>

<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview" [columns]="selectedCols" [value]="records"
         [scrollable]="true" scrollHeight="400px" scrollDirection="both" responsiveLayout="stack" dataKey="id" [rows]="10"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-offlineconsultants"
         [paginator]="false" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="">

  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of selectedCols; index as i;">{{col}}</th>
    </tr>

  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="selectedCols">
    <tr>
      <td *ngFor="let col of selectedCols">
        {{rowData[col]}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<br />
<br />
<ng-template class="modalField" #mymodal let-modal>
  <div *ngIf="modaltype =='export'" class="modal-dialog" role="document">
    <div class="modal-content whitebg">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <!-- close btn btn-round -->
        <button _ngcontent-c1="" aria-label="Close" class="closebtn" type="button"
                (click)="modal.dismiss('Cross click')">
          <span _ngcontent-c1="" class="closespan" aria-hidden="true">×</span>
        </button>
      </div>
      <!--end of modal head-->
      <div class="modal-body">
        <p>This data will be marked as used for today and will not be available for future, are you sure?</p>
        <div>
        </div>
      </div>
      <!--end of modal body-->
      <div class="modal-footer">
        <button role="button" class="btn btn-danger" type="button" (click)="ExportCsv()">
          Confirm
        </button>
      </div>
    </div>
  </div>

</ng-template>


<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
