import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CombineTables, CsvService } from 'src/app/services/csv.service';
import { interval, Subscription } from 'rxjs';
import * as moment from 'moment';
import { UtilityService } from '../../services/utility.service';
@Component({
  selector: 'app-domain-csv-files',
  templateUrl: './domain-csv-files.component.html',
  styleUrls: ['./domain-csv-files.component.sass']
})
export class DomainCsvFilesComponent implements OnInit {
  domains = 0;
  checkdomains: any;
  faPlus = faPlus;
  heading = 'Csv Parser';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  constructor(private utilityService: UtilityService, private userService: UserService, private http: HttpClient, private SpinnerService: NgxSpinnerService, private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.GetExportedAndNonExportedDomains();
  }
  CreateDomainFiles() {
    this.SpinnerService.show();
    const userid = localStorage.getItem("token");

    const data = {
      userid: userid,
      domains: this.domains
    };
    this.utilityService.CreateDomainFiles(data)
      .subscribe(
        response => {
          console.log(response);
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  
  GetExportedAndNonExportedDomains() {
    this.SpinnerService.show();
    this.utilityService.GetExportedAndNonExportedDomains()
      .subscribe(
        response => {
          this.checkdomains = response;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
}
