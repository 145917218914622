<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
            </div>
        </div>
    </div>
</div>
<div class="mb-3 card">
    <div class="card-body">
        <h5 class="card-title"></h5>
        <div class="row">
          <div class="col-md-3 floatleft position-relative form-group margintop8">
            <button type="button" class="mt-1 btn btn-primary" (click)="CheckDomainsModal(mymodal)">Check Domains</button>
          </div>
            
          </div>
          <div class="row">
            <div class="col-md-2 floatleft position-relative form-group margintop8">
              <label for="exampleFile">Amount Of Data</label>
              <input name="amountofdata"
                     id="amountofdata" placeholder="" [(ngModel)]="amountofdata" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
            </div>

            <div class="col-md-2 floatleft position-relative form-group margintop8">
              <label for="exampleFile">Domain</label>
              <select class="mb-2 form-control-md form-control" [(ngModel)]="alldomain" (change)="ChangeDomainType($event)">
                <option [value]=""></option>
                <option *ngFor="let v of domainTypes" [value]="v">
                  {{v}}
                </option>
              </select>
            </div>
            <div class="col-md-4 floatleft position-relative form-group" [ngClass]="domvisibility">
              <label for="exampleFile">Custom(multiple domains upto 5 max)</label>
              <ngx-autocomplete [searchKeyword]="'domain'" [inputId]="'searchZip'"
                                [placeholderValue]="'Enter Domain'" [entries]="alldomains" (selectedValue)="selectDomain($event)">
              </ngx-autocomplete>
              <div class="font-icon-wrapper font-icon-sm " *ngIf="selecteddomains.length > 0"><span class="spanselected" *ngFor="let z of selecteddomains; index as i;">{{z}}<i (click)="RemoveDomain(z)" class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span></div>
            </div>
            <!-- <div class="col-md-3 floatleft position-relative form-group">
        <label for="exampleFile">Zip</label>
        <ngx-autocomplete [searchKeyword]="'zip'" [inputId]="'searchZip'" [placeholderValue]="'Enter Zip'"
            [entries]="zips" (selectedValue)="selectZip($event)">
        </ngx-autocomplete>
        <div class="font-icon-wrapper font-icon-sm " *ngIf="selectedzips.length > 0"><span class="spanselected"
                *ngFor="let z of selectedzips; index as i;">{{z}}<i (click)="RemoveZip(z)"
                    class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span></div>
    </div>
    <div class="col-md-3 floatleft position-relative form-group">
        <label for="exampleFile">City</label>
        <ngx-autocomplete [searchKeyword]="'city'" [inputId]="'searchCity'"
            [placeholderValue]="'Enter City Name'" [entries]="cities" (selectedValue)="selectCity($event)">
        </ngx-autocomplete>
        <div class="font-icon-wrapper font-icon-sm " *ngIf="selectedcities.length > 0"><span
                class="spanselected" *ngFor="let z of selectedcities; index as i;">{{z}}<i
                    (click)="RemoveCity(z)" class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span>
        </div>
    </div>

    <div class="col-md-3 floatleft position-relative form-group">
        <label for="exampleFile">State</label>
        <ngx-autocomplete [searchKeyword]="'state'" [inputId]="'searchState'"
            [placeholderValue]="'Enter State Name'" [entries]="states" (selectedValue)="selectState($event)">
        </ngx-autocomplete>
        <div class="font-icon-wrapper font-icon-sm " *ngIf="selectedstates.length > 0"><span
                class="spanselected" *ngFor="let z of selectedstates; index as i;">{{z}}<i
                    (click)="RemoveState(z)" class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span>
        </div>
    </div> -->
            <div class="col-md-3 floatleft position-relative form-group paddingtop25 margintop8">
              <button type="button" class="mt-1 btn btn-primary" (click)="GetRecords()">Filter</button>
            </div>
          </div>
          <div class="row">
            

            <!-- <div class="col-md-3 floatleft position-relative form-group">
        <label for="exampleEmail">From</label>
        <input name="from" placeholder="" [(ngModel)]="from" [ngModelOptions]="{standalone: true}" type="date"
            class="form-control">
    </div>
    <div class="col-md-3 floatleft position-relative form-group">
        <label for="exampleEmail">To</label>
        <input name="to" placeholder="" [(ngModel)]="to" [ngModelOptions]="{standalone: true}" type="date"
            class="form-control">
    </div> -->

          </div>
        </div>
</div>
<h2 class="floatleft margintop5" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">Total records: {{totalrec}}
</h2><button type="button" class="mt-1 btn btn-success marginleft3"
    [ngClass]="records.length > 0 ? 'heightauto' : 'height0'" (click)="LoadModal(mymodal,'export')">Export</button>
<div class="clearboth"></div>

<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview" [columns]="selectedCols" [value]="records"
         [scrollable]="true" scrollHeight="400px" scrollDirection="both" responsiveLayout="stack" dataKey="id" [rows]="10"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-offlineconsultants"
         [paginator]="false" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="">

  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of selectedCols; index as i;">{{col}}</th>
    </tr>

  </ng-template>
  
  <ng-template pTemplate="body" let-rowData let-columns="selectedCols">
    <tr>
      <td *ngFor="let col of selectedCols">
        {{rowData[col]}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<br />
<br />
<ng-template class="modalField" #mymodal let-modal>
  <div *ngIf="modaltype =='export'" class="modal-dialog" role="document">
    <div class="modal-content whitebg">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <!-- close btn btn-round -->
        <button _ngcontent-c1="" aria-label="Close" class="closebtn" type="button"
                (click)="modal.dismiss('Cross click')">
          <span _ngcontent-c1="" class="closespan" aria-hidden="true">×</span>
        </button>
      </div>
      <!--end of modal head-->
      <div class="modal-body">
        <p>This data will be marked as used for today and will not be available for future, are you sure?</p>
        <div>
        </div>
      </div>
      <!--end of modal body-->
      <div class="modal-footer">
        <button role="button" class="btn btn-danger" type="button" (click)="ExportCsv()">
          Confirm
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="modaltype =='check domains'" class="modal-dialog"  role="document">
    <div class="modal-content whitebg">
      <div class="modal-header">
        <h5 class="modal-title">Check Domains</h5>
        <!-- close btn btn-round -->
        <button _ngcontent-c1="" aria-label="Close" class="closebtn" type="button"
                (click)="modal.dismiss('Cross click')">
          <span _ngcontent-c1="" class="closespan" aria-hidden="true">×</span>
        </button>
      </div>
      <!--end of modal head-->
      <div class="modal-body">
        <table id="tblColumns">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Domain</th>
              <th scope="col">Counter</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of checkdomains; index as i;">
              <td data-label="No">{{i+1}}</td>
              <td data-label="Domain">{{t.domain}}</td>
              <td data-label="Counter">{{t.counter}}</td>
            </tr>

          </tbody>
        </table>
        <div>
        </div>
      </div>
      <!--end of modal body-->
      <div class="modal-footer">
        <button role="button" class="btn btn-success" type="button" (click)="HideModal()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ngx-spinner  bdOpacity = 0.1
              bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen] = "false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
