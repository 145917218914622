<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}} 
      </div>
    </div>
  </div>
</div>
<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>

    <div class="row margin-top36">
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">CSV File</label>
        <input #fileUploadSimple [accept]="'.csv'" type="file" class="form-control-file"
               (change)="importDataFromCSV($event)">
      </div>
    </div>

    
    <div class="row margin-left1">
      <div class="position-relative form-group width50">
        <button type="button" class="mt-1 btn btn-primary" (click)="ImportClickersOpeners()">Import</button>
      </div>
    </div>
  </div>
</div>



<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
