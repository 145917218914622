import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogsService } from '../../services/logs.service';
@Component({
  selector: 'app-realtime-posting-logs',
  templateUrl: './realtime-posting-logs.component.html',
  styleUrls: ['./realtime-posting-logs.component.sass']
})
export class RealtimePostingLogsComponent implements OnInit {
  showingrecords = '';
  heading = 'Realtime Posting Logs';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  hideExecutionTime = true;
  records = [];
  errorlogs = [];
  guid = '';
  constructor(private SpinnerService: NgxSpinnerService, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.GetRealtimePostingLogs();
  }
 
  GetRealtimePostingLogs() {
    this.SpinnerService.show();
    this.csvUpdateService.GetRealtimePostingLogs()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.records = response;

          var min = 12 * 1000;
          var max = 21 * 1000;

          var a = Math.round(Math.random() * (max - min + 1) + min);
          setTimeout(() => this.GetRealtimePostingLogs(), a);
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });
  }
  GetErrorLogs(guid) {
    this.guid = guid;
    const data = {
      guid: guid
    }
    this.SpinnerService.show();
    this.csvUpdateService.GetErrorLogs(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.errorlogs = response;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  DeleteErrorLog(id) {
    const data = {
      id: id
    }
    this.SpinnerService.show();
    this.csvUpdateService.DeleteErrorLog(data)
      .subscribe(
        response => {
          this.GetErrorLogs(this.guid);
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  FinishedRealtimePosting(guid) {
    const data = {
      guid: guid
    }
    this.csvUpdateService.UpdateRealtimePosting(data)
      .subscribe(
        items => {
          this.GetRealtimePostingLogs();
        },
        error => {
          console.log(error);
        });
  }

}

